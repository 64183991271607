import { daysToHours, timeDiff } from '@csp/csp-common-date-util';
import { PatientAccountStatus, PatientAppStatusType, PatientStatus } from '@csp/csp-common-model';
import { DmdpUserStatusType } from '@csp/dmdp-api-user-dto';

const fromAppAndDmdpStatus = (
  appStatus: PatientStatus<PatientAppStatusType>,
  dmdpStatus: DmdpUserStatusType,
  temporaryPasswordExpiresInDays: number,
): PatientAccountStatus => {
  if (appStatus.status === PatientAppStatusType.INACTIVE) {
    return PatientAccountStatus.NO_ACCOUNT;
  } else if (appStatus.status === PatientAppStatusType.INVITED && dmdpStatus === DmdpUserStatusType.REGISTERED) {
    if (timeDiff(appStatus.timestamp.unixTimeMillis).moreThanHours(daysToHours(temporaryPasswordExpiresInDays))) {
      return PatientAccountStatus.INVITATION_EXPIRED;
    } else {
      return PatientAccountStatus.INVITATION_SENT;
    }
  } else if (appStatus.status === PatientAppStatusType.INVITED && dmdpStatus === DmdpUserStatusType.VERIFIED) {
    return PatientAccountStatus.VERIFIED;
  } else if (appStatus.status === PatientAppStatusType.ACTIVE) {
    return PatientAccountStatus.ACTIVATED;
  } else if (appStatus.status === PatientAppStatusType.RETIRED || appStatus.status === PatientAppStatusType.ALUMNI) {
    return PatientAccountStatus.INACTIVATED;
  } else {
    return PatientAccountStatus.UNKNOWN;
  }
};

export const PatientAccountStatusMapper = {
  fromAppAndDmdpStatus,
};
