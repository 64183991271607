import { ApiOptions, ObservationId, RestOptions, UserId } from '@csp/csp-common-model';
import { RpmAlgorithmCode } from '@csp/csp-common-rpm-model';
import { isDefined } from '@csp/csp-common-util';
import { toRestOptions } from '@csp/csp-fe-auth';
import {
  AssessmentIncludeFieldV1,
  AssessmentQueryCriterionV1,
  AssessmentQueryOperatorV1,
  AssessmentQueryV1,
  AssessmentV1,
} from '@csp/dmdp-api-assessment-dto';
import { AssessmentRestServiceV1 } from '@csp/dmdp-api-client';
import { largePage, Page, pageWithOneItem } from '@csp/dmdp-api-common-dto';
import { isEmpty, orderBy } from 'lodash';
import { Assessment } from '../model/Assessment';
import { AssessmentAggregateGroup } from '../model/AssessmentAggregateGroup';
import { AssessmentId } from '../model/AssessmentId';
import { AssessmentRefKey } from '../model/AssessmentRefKey';
import { RpmAssessment } from '../model/RpmAssessment';
import { RpmAssessments } from '../model/RpmAssessments';
import { RpmAssessmentType } from '../model/RpmAssessmentType';
import { RpmAssessmentAggregateQuery } from './RpmAssessmentAggregateQuery';
import { RpmAssessmentQuery } from './RpmAssessmentQuery';

const queryAggregatedAssessments = async (
  algorithmCodes: readonly RpmAlgorithmCode[],
  apiOptions?: ApiOptions,
): Promise<AssessmentAggregateGroup[]> => {
  const query = RpmAssessmentAggregateQuery.allByAlgorithmCodesQuery(algorithmCodes);

  const { groups } = await AssessmentRestServiceV1.aggregateAssessments(toRestOptions(apiOptions), query);

  const sortedGroups = orderBy(groups, ['fields.customStatusValue', 'maxAssessmentTimestamp'], ['asc', 'desc']);

  return sortedGroups.map(AssessmentAggregateGroup.from);
};

const queryAllNotViewed = async (
  algorithmCodes: readonly RpmAlgorithmCode[],
  page: Page,
  apiOptions?: ApiOptions,
): Promise<RpmAssessments> => {
  const query: AssessmentQueryV1 = {
    criterion: {
      operator: AssessmentQueryOperatorV1.AND,
      criteria: [
        RpmAssessmentQuery.rpmAssessmentTypesQuery(),
        RpmAssessmentQuery.algorithmCodesQuery(algorithmCodes),
        RpmAssessmentQuery.algorithmResultNotOkQuery(),
        RpmAssessmentQuery.noViewedStatusQuery(),
      ],
    },
    sort: RpmAssessmentQuery.latestCreatedAtAndNotViewedStatusFirstSortQuery(),
  };
  const { assessments = [], paging } = await AssessmentRestServiceV1.getAssessments(
    toRestOptions(apiOptions),
    query,
    page,
  );

  return {
    assessments: assessments.map(Assessment.from).map(RpmAssessment.from),
    paging,
  };
};

/**
 * Query all rpm event assessments by observation ids. (Threshold events columns in device request detail page)
 * @param observationIds
 * @param apiOptions
 */
const queryByObservationIds = async (
  observationIds: readonly ObservationId[],
  apiOptions?: ApiOptions,
): Promise<RpmAssessment[]> => {
  const query: AssessmentQueryV1 = {
    criterion: {
      operator: AssessmentQueryOperatorV1.AND,
      criteria: [
        RpmAssessmentQuery.assessmentTypesQuery(RpmAssessmentType.RPM_DEVICE_EVENT),
        RpmAssessmentQuery.algorithmResultNotOkQuery(),
        RpmAssessmentQuery.refIdsQuery(AssessmentRefKey.DEVICE_OBSERVATION_ID, observationIds),
      ],
    },
    sort: [RpmAssessmentQuery.latestCreatedAtSortQuery()],
  };

  const { assessments = [] } = await AssessmentRestServiceV1.getAssessments(
    toRestOptions(apiOptions),
    query,
    largePage(),
  );

  return assessments.map(Assessment.from).map(RpmAssessment.from);
};

/**
 * Query all rpm event assessments by questionnaire response ids. Optionally you can specify algorithm codes to filter
 * on.
 */
const queryByQuestionnaireResponseIds = async (
  questionnaireResponseIds: readonly string[],
  algorithmCodes?: readonly RpmAlgorithmCode[],
  apiOptions?: ApiOptions,
): Promise<RpmAssessment[]> => {
  const query: AssessmentQueryV1 = {
    criterion: {
      operator: AssessmentQueryOperatorV1.AND,
      criteria: [
        RpmAssessmentQuery.assessmentTypesQuery(RpmAssessmentType.RPM_QUESTIONNAIRE_EVENT),
        algorithmCodes ? RpmAssessmentQuery.algorithmCodesQuery(algorithmCodes) : undefined,
        RpmAssessmentQuery.algorithmResultNotOkQuery(),
        RpmAssessmentQuery.refIdsQuery(AssessmentRefKey.QUESTIONNAIRE_RESPONSE_ID, questionnaireResponseIds),
      ].filter(isDefined),
    },
    sort: [RpmAssessmentQuery.latestCreatedAtSortQuery()],
  };

  const { assessments = [] } = await AssessmentRestServiceV1.getAssessments(
    toRestOptions(apiOptions),
    query,
    largePage(),
  );

  return assessments.map(Assessment.from).map(RpmAssessment.from);
};

/**
 * Used to show the badge number in the patient list page. We only need to fetch 100 assessments to get the total
 * since we "99+" in the badge when there are more than 99 assessments.
 */
const queryTotalNrNotViewed = async (
  algorithmCodes: readonly RpmAlgorithmCode[],
  apiOptions?: ApiOptions,
): Promise<number> => {
  const query: AssessmentQueryV1 = {
    criterion: {
      operator: AssessmentQueryOperatorV1.AND,
      criteria: [
        RpmAssessmentQuery.rpmAssessmentTypesQuery(),
        RpmAssessmentQuery.noViewedStatusQuery(),
        RpmAssessmentQuery.algorithmResultNotOkQuery(),
        RpmAssessmentQuery.algorithmCodesQuery(algorithmCodes),
      ],
    },
    fields: [
      AssessmentIncludeFieldV1.ASSESSMENT_ID,
      AssessmentIncludeFieldV1.OUTCOMES,
      AssessmentIncludeFieldV1.USER_ID,
    ],
  };

  const { assessments } = await AssessmentRestServiceV1.getAssessments(toRestOptions(apiOptions), query, {
    count: false,
    limit: 100,
  });

  return assessments?.length ?? 0;
};

const getByAssessmentId = async (assessmentId: AssessmentId, apiOptions?: ApiOptions): Promise<RpmAssessment> => {
  const rawAssessment = await AssessmentRestServiceV1.getAssessmentById(toRestOptions(apiOptions), assessmentId);
  const assessment = Assessment.from(rawAssessment);
  return RpmAssessment.from(assessment);
};

const queryLatestByAlgorithmCodeAndStatus = async (
  restOptions: RestOptions,
  algorithmCode: RpmAlgorithmCode,
  patientId: UserId,
  statusQueryFactory: () => AssessmentQueryCriterionV1,
): Promise<AssessmentV1[]> => {
  const query: AssessmentQueryV1 = {
    criterion: {
      operator: AssessmentQueryOperatorV1.AND,
      criteria: [
        RpmAssessmentQuery.rpmAssessmentTypesQuery(),
        RpmAssessmentQuery.algorithmCodesQuery([algorithmCode]),
        RpmAssessmentQuery.algorithmResultNotOkQuery(),
        RpmAssessmentQuery.userIdsQuery(patientId),
        statusQueryFactory(),
      ],
    },
    sort: [RpmAssessmentQuery.latestCreatedAtSortQuery()],
  };

  const { assessments = [] } = await AssessmentRestServiceV1.getAssessments(restOptions, query, pageWithOneItem());

  return assessments;
};

/**
 * Query latest not viewed, or else viewed, assessment by algorithm code, for the selected patient.
 */
const queryLatestByPatientIdAndAlgorithmCode = async (
  patientId: UserId,
  algorithmCodes: readonly RpmAlgorithmCode[],
  apiOptions?: ApiOptions,
): Promise<RpmAssessment[]> => {
  const restOptions = toRestOptions(apiOptions);

  const assessments: AssessmentV1[] = [];

  for (const algorithmCode of algorithmCodes) {
    const notViewedAssessments = await queryLatestByAlgorithmCodeAndStatus(
      restOptions,
      algorithmCode,
      patientId,
      RpmAssessmentQuery.noViewedStatusQuery,
    );

    if (isEmpty(notViewedAssessments)) {
      const viewedAssessments = await queryLatestByAlgorithmCodeAndStatus(
        restOptions,
        algorithmCode,
        patientId,
        RpmAssessmentQuery.statusViewedQuery,
      );
      assessments.push(...viewedAssessments);
    } else {
      assessments.push(...notViewedAssessments);
    }
  }

  return assessments.map(Assessment.from).map(RpmAssessment.from);
};

const queryAllByUserId = async (
  patientId: UserId,
  algorithmCodes: readonly RpmAlgorithmCode[],
  page: Page,
  apiOptions?: ApiOptions,
): Promise<RpmAssessments> => {
  const query: AssessmentQueryV1 = {
    criterion: {
      operator: AssessmentQueryOperatorV1.AND,
      criteria: [
        RpmAssessmentQuery.rpmAssessmentTypesQuery(),
        RpmAssessmentQuery.algorithmCodesQuery(algorithmCodes),
        RpmAssessmentQuery.algorithmResultNotOkQuery(),
        RpmAssessmentQuery.userIdsQuery(patientId),
        {
          operator: AssessmentQueryOperatorV1.OR,
          criteria: [RpmAssessmentQuery.noViewedStatusQuery(), RpmAssessmentQuery.statusViewedQuery()],
        },
      ],
    },
    sort: RpmAssessmentQuery.latestCreatedAtAndNotViewedStatusFirstSortQuery(),
  };

  const { assessments = [], paging } = await AssessmentRestServiceV1.getAssessments(
    toRestOptions(apiOptions),
    query,
    page,
  );

  return {
    assessments: assessments.map(Assessment.from).map(RpmAssessment.from),
    paging,
  };
};

export const RpmAssessmentService = {
  getByAssessmentId,
  queryAggregatedAssessments,
  queryAllByUserId,
  queryAllNotViewed,
  queryByObservationIds,
  queryByQuestionnaireResponseIds,
  queryLatestByPatientIdAndAlgorithmCode,
  queryTotalNrNotViewed,
};
