export type StudyRescreenConfig = {
  readonly enabled: boolean;
  readonly reuseEcode: boolean;
};

const DEFAULT_CONFIG: StudyRescreenConfig = {
  enabled: false,
  reuseEcode: false,
};

export const StudyRescreenConfig = {
  DEFAULT_CONFIG,
};
