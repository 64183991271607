/* eslint-disable */
export const UnifyWebPatientEcoaStudySchema = {
  title: 'UnifyWebPatientEcoaStudy',
  type: 'object',
  properties: {
    title: { type: 'string' },
    ecoaInformations: {
      title: 'About questionnaire learn more',
      helper: 'Information about questionnaire section',
      type: 'array',
      items: {
        title: 'Information about specific Ecoa',
        type: 'object',
        properties: {
          questionnaireCode: {
            type: 'string',
            title: 'Questionnaire Code',
            helper:
              'Identifies the specific questionnaire. Used as key, not shown to user. Example: experience_diary_v1.0, EQ-5D-5L',
          },
          descriptionBodyMd: {
            type: 'string',
            title: 'Questionnaire description',
            helper: 'Study specific questionnaire description. Markdown is supported',
          },
          scheduleBodyMd: {
            type: 'string',
            title: 'Questionnaire schedule',
            helper:
              'Study specific questionnaire schedule information. Markdown is supported. Example: The questionnaire is scheduled once per month (each 28 days, with the window -/+ 7 days).',
          },
          thresholdBodyMd: {
            type: 'string',
            title: 'Questionnaire threshold',
            helper: 'Study specific questionnaire threshold. Markdown is supported',
          },
          complianceCalculationBodyMd: {
            type: 'string',
            title: 'Questionnaire compliance calculation',
            helper:
              "Study specific compliance calculation. Markdown is supported. if complianceCalculationBodyMd isn't defined on study-specific, it will be use complianceCalculationBodyMd from UnifyWebPatientEcoa template, which use to all questionnaires.",
          },
        },
      },
      mock: [
        {
          questionnaireCode: 'EQ-5D-5L',
          descriptionBodyMd:
            'Patients are asked to complete EQ-5D-5L questionnaire to provide their overall status of health.',
          scheduleBodyMd: 'The questionnaire is scheduled once per month (each 28 days, with the window -/+ 7 days).',
        },
        {
          questionnaireCode: 'EXAMPLE_QUESTIONNAIRE_V1-1',
          descriptionBodyMd: 'This is a test questionnaire with tpe Ecoa using version 2 of the API',
          scheduleBodyMd: 'Experimental questionnaire V2',
          thresholdBodyMd:
            'A threshold explanation for the specific questionnaire, visible to web users in the portal on the learn more section.',
          complianceCalculationBodyMd:
            'An explanation on how compliance is calculated, visible to web users in the portal on the learn more section. Total compliance is calculated according to the following formula. Number of completed questionnaires / number of maximum expected questionnaires from start of study for the patient until last time the web received responses from the app. Recent time period calculates compliance in the same way, but for tha last X day/week/months completed time period instead of the total period.',
        },
        {
          questionnaireCode: 'experience_diary_v1.0',
          descriptionBodyMd: 'This is a test questionnaire with tpe Diary using version 2 of the API.',
          scheduleBodyMd: 'Experimental questionnaire V2 diary',
          thresholdBodyMd:
            'A threshold explanation for the specific questionnaire, visible to web users in the portal on the learn more section.',
          complianceCalculationBodyMd:
            'An explanation on how compliance is calculated, visible to web users in the portal on the learn more section. Total compliance is calculated according to the following formula. Number of completed questionnaires / number of maximum expected questionnaires from start of study for the patient until last time the web received responses from the app. Recent time period calculates compliance in the same way, but for tha last X day/week/months completed time period instead of the total period.',
        },
      ],
    },
    learnMore: {
      type: 'object',
      title: 'Study specific ecoa patient learn more drawer',
      properties: {
        labelsAndDescriptions: {
          title: 'Study specific ecoa patient learn more content items',
          type: 'array',
          items: {
            title: 'Study specific ecoa patient learn more content item',
            type: 'object',
            properties: {
              label: { type: 'string', title: 'Study specific learn more content item label' },
              bodyMd: {
                type: 'string',
                title: 'Study specific learn more content item description. Markdown is supported',
              },
            },
          },
          mock: [{ label: 'Study specific learn more title', bodyMd: 'Study specific learn more description' }],
        },
      },
    },
  },
  description: 'unify:webpatientecoastudy',
  $schema: 'http://json-schema.org/draft-07/schema#',
};
