import { DeviceConfigV2, DeviceConfigV2Schema, GenericConfigDeviceV2, RoleOverride } from '@csp/config-schemas';
import { Maybe } from '@csp/csp-common-model';
import { JsonValidationService, isNotEmpty } from '@csp/csp-common-util';
import {
  DeviceConfigOverride,
  DeviceModelConfigPropertiesV2,
  DeviceModelNumberType,
  DeviceObservationType,
  DeviceStudyConfigProperties,
  EnabledDevicesConfig,
  ObservationContextType,
} from '@csp/device-catalog';
import { RoleType } from '@csp/dmdp-api-common-dto';
import { memoize, merge, uniq, values } from 'lodash';
import { StudyDeviceConfig } from '../model/StudyDeviceConfig';

type DeviceStudyConfigPropertiesKeys = keyof DeviceStudyConfigProperties;

const getDeviceConfig = (
  deviceConfigV2: DeviceConfigV2,
  modelNumber: DeviceModelNumberType,
): NonNullable<GenericConfigDeviceV2['baseConfig']> | undefined => deviceConfigV2?.devices?.[modelNumber]?.baseConfig;

const hasRoleToOverride = (roles: RoleType[], roleOverride: RoleOverride<DeviceStudyConfigProperties>): boolean =>
  roleOverride.roles.some(role => roles.includes(role));

const resolveConfigValue = <T extends DeviceStudyConfigPropertiesKeys>(
  key: T,
  baseConfig: DeviceStudyConfigProperties,
  roleOverrides: RoleOverride<DeviceStudyConfigProperties>[],
): Maybe<DeviceStudyConfigProperties[T]> =>
  roleOverrides.reduce((prevValue, { config }) => config[key] ?? prevValue, baseConfig[key]);

const resolveStudyConfig = (deviceConfigV2: DeviceConfigV2, roles: RoleType[]): DeviceStudyConfigProperties => {
  const { config, roleOverrides = [] } = deviceConfigV2.study;
  return Object.keys(config).reduce((configResult, key) => {
    const matchingRoleOverrides = roleOverrides.filter(roleOverride => hasRoleToOverride(roles, roleOverride));
    return {
      ...configResult,
      [key]: resolveConfigValue(key as DeviceStudyConfigPropertiesKeys, config, matchingRoleOverrides),
    };
  }, config);
};

const resolveConfig = (
  deviceConfigV2: DeviceConfigV2,
  modelNumber: DeviceModelNumberType,
  roles: RoleType[],
  observationType: DeviceObservationType,
  observationContextType: ObservationContextType,
  configOverride?: DeviceConfigOverride,
): DeviceModelConfigPropertiesV2 & DeviceStudyConfigProperties => {
  const resolvedStudyConfig = resolveStudyConfig(deviceConfigV2, roles);
  const deviceConfig = getDeviceConfig(deviceConfigV2, modelNumber);
  const observationConfig = deviceConfig?.deviceObservationTypeConfig[observationType];
  const contextConfig = observationConfig?.contextTypeConfig[observationContextType];

  return merge(
    resolvedStudyConfig,
    deviceConfig?.deviceGenericConfig,
    merge(observationConfig?.observationConfig, configOverride?.observationConfig),
    merge(contextConfig?.contextConfig, configOverride?.contextConfig),
  );
};

const resolveProperties =
  (deviceConfigV2: DeviceConfigV2) =>
  (
    modelNumber: DeviceModelNumberType,
    roles: RoleType[],
    observationType: DeviceObservationType,
    observationContextType: ObservationContextType,
    configOverride?: DeviceConfigOverride,
  ): Maybe<DeviceModelConfigPropertiesV2 & DeviceStudyConfigProperties> => {
    const resolvedConfig = resolveConfig(
      deviceConfigV2,
      modelNumber,
      roles,
      observationType,
      observationContextType,
      configOverride,
    );
    return isNotEmpty(resolvedConfig) ? resolvedConfig : undefined;
  };

const toDeviceConfig = (deviceConfigV2: DeviceConfigV2): StudyDeviceConfig => {
  JsonValidationService.validateJsonNonStrict('DeviceConfigV2.json', DeviceConfigV2Schema, deviceConfigV2);

  const memoizedProperties = memoize(
    resolveProperties(deviceConfigV2),
    (
      modelNumber: DeviceModelNumberType,
      roles: RoleType[],
      observationType: DeviceObservationType,
      observationContextType: ObservationContextType,
      configOverride?: DeviceConfigOverride,
    ) =>
      `${modelNumber}-${observationType}-${observationContextType}-${roles.join(',')}-${JSON.stringify(
        configOverride,
      )}`,
  );

  return {
    resolveProperties: memoizedProperties,
  };
};

const toEnabledDevicesConfig = (deviceConfig: DeviceConfigV2): EnabledDevicesConfig => {
  // uniq because some devices support the same type of measurement
  const observationTypes: DeviceObservationType[] = uniq(
    values(deviceConfig.devices).flatMap(device =>
      values(device.baseConfig.deviceObservationTypeConfig).map(observation => observation.type),
    ),
  );

  const deviceModels: DeviceModelNumberType[] = values(deviceConfig.devices).flatMap(device => device.modelNumber);

  return {
    observationTypes,
    deviceModels,
  };
};

export const DeviceConfigServiceV1 = {
  toDeviceConfig,
  toEnabledDevicesConfig,
};
