export enum HealthEventCriteriaFieldV1 {
  HEALTH_EVENT_ID = 'HEALTH_EVENT_ID',
  TIMESTAMP = 'TIMESTAMP',
  USER_ID = 'USER_ID',
  EVENT_ID = 'EVENT_ID',
  TYPE = 'TYPE',
  REFS_KEY = 'REFS_KEY',
  REFS_VALUE = 'REFS_VALUE',
  STATUS = 'STATUS',
}
