import Joi from 'joi';

export type RequestGroupMembershipV1 = {
  groupKey: string;
  order?: number;
};

const requestGroupMembershipV1Schema = Joi.object<RequestGroupMembershipV1>({
  groupKey: Joi.string().required().token().uppercase(),
  order: Joi.number(),
});

export const RequestGroupMembershipV1 = {
  validationSchema: requestGroupMembershipV1Schema,
};
