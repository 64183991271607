/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * DayOfWeek https://www.hl7.org/fhir/valueset-days-of-week.html
 */
export enum QuestionnaireDayOfWeekV2 {
    MON = 'MON',
    TUE = 'TUE',
    WED = 'WED',
    THU = 'THU',
    FRI = 'FRI',
    SAT = 'SAT',
    SUN = 'SUN',
}