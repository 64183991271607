import { QuestionnaireRequestDetailsV2 } from '@csp/dmdp-api-questionnaire-dto';
import { QuestionnaireRequestDetailV1 } from '@csp/dto';

export type QuestionnaireRequestDetails = {
  /** @deprecated - Use QuestionnaireRequest.questionnaireCode instead */
  questionnaireCode: string;
};

const from = ({
  questionnaireCode,
}: QuestionnaireRequestDetailsV2 | QuestionnaireRequestDetailV1): QuestionnaireRequestDetails => ({
  questionnaireCode,
});

export const QuestionnaireRequestDetails = {
  from,
};
