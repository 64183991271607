import { FC } from 'react';
import { NavTab } from '@csp/web-common';
import { Box } from '@csp/csp-web-ui';
import { WebHcpPatientListNavigationTabBlock } from '@csp/csp-web-content-definitions';
import { CspPath } from '../../../CspPath';
import { NavTabsComponent } from '../component/NavTabsComponent';
import { useSelectedNavTab } from '../hooks/useSelectedNavTab';

type Props = {
  navTabTexts: WebHcpPatientListNavigationTabBlock;
  subNavs?: NavTab<CspPath>[];
};

export const PatientNavSubTabsContainer: FC<Props> = ({ navTabTexts, subNavs = [] }) => {
  const mainNavTabs: NavTab<CspPath>[] = [
    {
      label: navTabTexts.textLabel.patients.label,
      path: CspPath.PATIENT_LIST_STANDARD,
    },
    ...subNavs,
  ];
  const selectedTab = useSelectedNavTab(mainNavTabs, CspPath.PATIENT_LIST_STANDARD);

  return (
    <>
      <Box height="0.5rem" bgcolor="transparent.grey" />
      <NavTabsComponent isSubTab tabs={mainNavTabs} selectedPath={selectedTab} />
    </>
  );
};
