// USED FOR: Common Scheduling API
// OPERATES ON: Requests
import { eq } from 'lodash/fp';
import { Time, TimeStr, ZonedDateTime } from '@csp/csp-common-model';
import { zonedSetTime, zonedStartOfDay } from '@csp/csp-common-date-util';
import { RequestCode } from '../model/codes/RequestCode';
import { ScheduleCode } from '../model/codes/ScheduleCode';
import {
  CustomActionKeyAcceptedValues,
  CustomActionMetaKeys,
  CustomActionTimeKeyAcceptedValue,
} from '../model/custom-action/CustomActionMeta';
import { GenericRequest } from '../model/schedulingModels/GenericRequest';
import { RequestRef } from '../model/schedulingModels/RequestRef';
import { RequestEndActionOptions } from '../model/custom-action/RequestEndActionOptions';
import { RequestStartActionOptions } from '../model/custom-action/RequestStartActionOptions';

/**
 *   timeOverride - a TimeStr [09:15] representing the time to override input datetime with
 */
export type TimeOverrideOptions = { timeOverride?: string };

/**
 *
 * @param datetime - ZonedDateTime to override with time
 * @param timeOverride - time to override with
 */
export const timeOverrideIfApplicable = (datetime: ZonedDateTime, timeOverride?: TimeStr): ZonedDateTime => {
  // If no time override is specified default to 00:00 on current date
  if (!timeOverride) {
    return zonedStartOfDay(datetime);
  } else {
    const time = Time.from(timeOverride);
    return zonedSetTime(datetime, time.hours, time.minutes);
  }
};

const extractTimeOverride = (time: unknown): CustomActionTimeKeyAcceptedValue => {
  if (time) {
    return time as string;
  } else {
    return undefined;
  }
};

/**
 * Evaluates type of start action on request.
 *
 * Two cases is supported:
 *  - Start action meta on request is set to RANDOMIZATION => Evaluates to RANDOMIZATION start action type.
 *  - Start action meta on request is set to SCREENING => Evaluates to SCREENING start action type.
 *
 * Type UNKNOWN is returned for all other cases. (If no StartAction meta is present or if an unknown value is set)
 *
 * @param request of type {@link GenericRequest} which maps to a schedule API Request.
 * @returns the type if supporten {@link RequestStartActionOptions}
 * @deprecated Use RequestActionDateUtil instead
 */

export const getScheduleRequestStartActionType = (request: GenericRequest): RequestStartActionOptions => {
  const startActionMeta = request.startAction?.customAction?.meta;
  const fallbackStartActionOptions: RequestStartActionOptions = {
    requestStartActionType: 'UNKNOWN',
  };

  if (!startActionMeta) {
    return fallbackStartActionOptions;
  } else {
    const timeOverride = extractTimeOverride(startActionMeta[CustomActionMetaKeys.TIME]);

    switch (startActionMeta[CustomActionMetaKeys.DATE]) {
      case CustomActionKeyAcceptedValues.DATE.RANDOMIZATION: {
        return {
          requestStartActionType: 'RANDOMIZATION',
          timeOverride,
        };
      }
      case CustomActionKeyAcceptedValues.DATE.SCREENING: {
        return {
          requestStartActionType: 'SCREENING',
          timeOverride,
        };
      }
      case CustomActionKeyAcceptedValues.DATE.ACTIVATION: {
        return {
          requestStartActionType: 'ACTIVATION',
          timeOverride,
        };
      }
      default: {
        return fallbackStartActionOptions;
      }
    }
  }
};

/**
 * Evaluates type of end action on request.
 *
 * Two cases is supported:
 *  - End action meta on request is set to RANDOMIZATION => Evaluates to RANDOMIZATION end action type.
 *  - Start action meta on request is set to SCREENING => Evaluates to SCREENING end action type.
 *
 * Type UNKNOWN is returned for all other cases. (If no StartAction meta is present or if an unknown value is set)
 *
 * @param request of type {@link GenericRequest} which maps to a schedule API Request.
 * @returns the type if supporten {@link RequestEndActionOptions}
 * @deprecated Use RequestActionDateUtil instead
 */

export const getScheduleRequestEndActionType = (request: GenericRequest): RequestEndActionOptions => {
  const endActionMeta = request.endAction?.customAction?.meta;
  const fallbackEndActionOptions: RequestEndActionOptions = {
    requestEndActionType: 'UNKNOWN',
  };

  if (!endActionMeta) {
    return fallbackEndActionOptions;
  }

  const timeOverride = extractTimeOverride(endActionMeta[CustomActionMetaKeys.TIME]);

  switch (endActionMeta[CustomActionMetaKeys.DATE]) {
    case CustomActionKeyAcceptedValues.DATE.RANDOMIZATION: {
      return {
        requestEndActionType: 'RANDOMIZATION',
        timeOverride,
      };
    }
    case CustomActionKeyAcceptedValues.DATE.SCREENING: {
      return {
        requestEndActionType: 'SCREENING',
        timeOverride,
      };
    }
    case CustomActionKeyAcceptedValues.DATE.ACTIVATION: {
      return {
        requestEndActionType: 'ACTIVATION',
        timeOverride,
      };
    }
    default: {
      return fallbackEndActionOptions;
    }
  }
};

/**
 * Finds all requestRefs that matches the scheduleCode and requestCode of the Request passed in.
 *
 * @param codedRequest The request to match codes against
 * @param requestRefs The existing requestRefs to filter by match of codes.
 * @returns A list of {@link RequestRef} that matches.
 */
export const getScheduleRequestRefsMatchingRequest = (
  scheduleCode: ScheduleCode,
  requestCode: RequestCode,
  requestRefs: RequestRef[],
): RequestRef[] =>
  requestRefs.filter(
    existingRequest => eq(existingRequest.requestCode, requestCode) && eq(existingRequest.scheduleCode, scheduleCode),
  );

/**
 * Finds the next index to be for the given requestCode by matching the given RequestRefs with the same code and extracting the repeat index of those.
 *
 * @param requestCode To find next index for
 * @param existingRequestRefs The list of existing RequestRefs to search for the specific RequestCode in.
 * @returns The next repeatIndex in the serie for given requestCode.
 */
export const findScheduleRequestNextRepeatIndex = (
  requestCode: RequestCode,
  existingRequestRefs: Pick<RequestRef, 'requestCode' | 'repeatIndex'>[],
): number => {
  const validPreviousRequestsIndexes: number[] = existingRequestRefs
    .filter(requestRef => requestRef.requestCode === requestCode)
    .map(requestRef => requestRef.repeatIndex ?? 0);

  const indexOfLastBooked =
    validPreviousRequestsIndexes.length > 0 ? Math.max(...validPreviousRequestsIndexes) : undefined;

  return indexOfLastBooked !== undefined ? indexOfLastBooked + 1 : 0;
};
