import { ErrorPage, OnClick } from '@csp/csp-web-ui';
import { ErrorComponentProps } from '@csp/web-common';
import { FC } from 'react';

export const ApplicationErrorPage: FC<ErrorComponentProps> = ({ errorId }) => {
  const handleReloadPage: OnClick = (): void => window.location.reload();

  return (
    <ErrorPage
      // eslint-disable-next-line
      renderLogoContainer={props => <a {...props} href="/" />}
      illustration="inviteNotValid"
      altText="Failed to initialize application"
      label="Failed to initialize application"
      errorId={errorId}
      actionLabel="Reload page"
      action={handleReloadPage}
    />
  );
};
