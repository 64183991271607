/* eslint-disable */
export const UnifyAppStudyHelpQuestionMock = {
  title: 'title',
  categoryHeader: 'categoryHeader',
  order: 0,
  header: 'header',
  body: 'body',
  categoryId: 'categoryId',
  pdfDownloadButtonText: 'pdfDownloadButtonText',
  pdf: {},
};
