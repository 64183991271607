export enum UserCriteriaFieldType {
  USER_ID = 'USER_ID',
  EVENT_ID = 'EVENT_ID',
  STATUS = 'STATUS',
  GENDER = 'GENDER',
  BIRTH_YEAR = 'BIRTH_YEAR',
  GIVEN_NAME = 'GIVEN_NAME',
  FAMILY_NAME = 'FAMILY_NAME',
  ROLE = 'ROLE',
  CONTACT_POINT = 'CONTACT_POINT',
  OCCASION = 'OCCASION',
  ACCESS_REF = 'ACCESS_REF',
  META_TYPE = 'META_TYPE',
  META_VALUE = 'META_VALUE',
  TAG = 'TAG',
  CUSTOM_STATUS_TYPE = 'CUSTOM_STATUS_TYPE',
  CUSTOM_STATUS_VALUE = 'CUSTOM_STATUS_VALUE',
  EXTERNAL_ID_KEY = 'EXTERNAL_ID_KEY',
  EXTERNAL_ID_VALUE = 'EXTERNAL_ID_VALUE',
  STUDY_ECODE = 'STUDY_ECODE',
  STUDY_SCREENED_TIMESTAMP = 'STUDY_SCREENED_TIMESTAMP',
  STUDY_RANDOMIZED_TIMESTAMP = 'STUDY_RANDOMIZED_TIMESTAMP',
}
