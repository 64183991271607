import {
  QuestionnaireResponseAggregateGroupResultFieldsV2,
  QuestionnaireResponseAggregateGroupResultV2,
  QuestionnaireResponseAggregateResultV2,
  QuestionnaireResponseStatusType,
} from '@csp/dmdp-api-questionnaire-dto';
import { fromTimezoneStrOrUndefined } from '@csp/csp-common-date-util';
import { CountValue, Maybe, MetricInfo, StateAssert, ZonedDateTime } from '@csp/csp-common-model';
import { ScheduleRef } from '@csp/csp-common-scheduling';
import { isDefined } from '@csp/csp-common-util';
import { ScheduleRequestMetricCriterion } from '../../model/ScheduleRequestMetricCriterion';
import { ScheduleRequestMetricInfo } from '../../model/ScheduleRequestMetricInfo';
import { ScheduleRequestReviewCount } from '../../model/ScheduleRequestReviewCount';

const matchRequestRefAndStatus = (
  scheduleRef: ScheduleRef,
  fields: QuestionnaireResponseAggregateGroupResultFieldsV2,
  status: QuestionnaireResponseStatusType,
): boolean => ScheduleRef.matchesRequestRef(scheduleRef, fields) && fields.currentStatusValue === status;

const getLastSync = (
  lastSyncCompleted: Maybe<ZonedDateTime>,
  lastSyncMissed: Maybe<ZonedDateTime>,
): Maybe<ZonedDateTime> => {
  if (lastSyncCompleted && lastSyncMissed) {
    return lastSyncCompleted.unixTimeMillis > lastSyncMissed.unixTimeMillis ? lastSyncCompleted : lastSyncMissed;
  } else {
    return lastSyncCompleted ?? lastSyncMissed;
  }
};

const sumNumberReducer = (sum: number, current: number): number => sum + current;

const findGroupByStatus = (
  groups: QuestionnaireResponseAggregateGroupResultV2[],
  status: QuestionnaireResponseStatusType,
): Maybe<QuestionnaireResponseAggregateGroupResultV2> =>
  groups.find(group => group.fields.currentStatusValue === status);

const toScheduleRequestMetricInfos = (
  criteria: ScheduleRequestMetricCriterion[],
  totalResultV2s: QuestionnaireResponseAggregateGroupResultV2[],
  recentResultV2s: QuestionnaireResponseAggregateGroupResultV2[],
): ScheduleRequestMetricInfo[] =>
  criteria.map(crit => {
    const totalResultV2Completed = totalResultV2s.filter(res =>
      matchRequestRefAndStatus(crit.scheduleRef, res.fields, QuestionnaireResponseStatusType.COMPLETED),
    );
    const totalResultV2Missed = totalResultV2s.filter(res =>
      matchRequestRefAndStatus(crit.scheduleRef, res.fields, QuestionnaireResponseStatusType.MISSED),
    );
    const recentResultV2Completed = crit.recentPeriod
      ? recentResultV2s.filter(res =>
          matchRequestRefAndStatus(crit.scheduleRef, res.fields, QuestionnaireResponseStatusType.COMPLETED),
        )
      : undefined;

    const recentResultV2Missed = crit.recentPeriod
      ? recentResultV2s.filter(res =>
          matchRequestRefAndStatus(crit.scheduleRef, res.fields, QuestionnaireResponseStatusType.MISSED),
        )
      : undefined;

    const lastSyncCompleted = totalResultV2Completed
      .map(aggregateResult => fromTimezoneStrOrUndefined(aggregateResult.maxCurrentStatusCreatedAt))
      .filter(isDefined)
      .reduce(getLastSync, undefined);

    const lastSyncMissed = totalResultV2Missed
      .map(aggregateResult => fromTimezoneStrOrUndefined(aggregateResult.maxCurrentStatusCreatedAt))
      .filter(isDefined)
      .reduce(getLastSync, undefined);

    const totalCompletedCount = totalResultV2Completed
      .map(aggregateResult => aggregateResult.count)
      .filter(isDefined)
      .reduce(sumNumberReducer, 0);

    const totalMissedCount = totalResultV2Missed
      .map(aggregateResult => aggregateResult.count)
      .filter(isDefined)
      .reduce(sumNumberReducer, 0);

    const recentCompletedCount =
      recentResultV2Completed
        ?.map(aggregateResult => aggregateResult.count)
        .filter(isDefined)
        .reduce(sumNumberReducer, 0) ?? 0;

    const recentMissedCount =
      recentResultV2Missed
        ?.map(aggregateResult => aggregateResult.count)
        .filter(isDefined)
        .reduce(sumNumberReducer, 0) ?? 0;

    return {
      scheduleRef: crit.scheduleRef,
      metricInfo: MetricInfo.from({
        totalActual: totalCompletedCount,
        totalExpected: totalCompletedCount + totalMissedCount,
        recentActual: recentCompletedCount,
        recentExpected: recentCompletedCount + recentMissedCount,
        recentPeriod: crit.recentPeriod,
        threshold: crit.minComplianceThreshold,
        lastSync: getLastSync(lastSyncCompleted, lastSyncMissed),
      }),
    };
  });

const toScheduleRequestReviewCount = (
  scheduleRef: ScheduleRef,
  groupsV2: QuestionnaireResponseAggregateGroupResultV2[],
): ScheduleRequestReviewCount => {
  const groupV2s = groupsV2.filter(({ fields }) => ScheduleRef.matchesRequestRef(scheduleRef, fields));
  if (groupV2s.length) {
    const count = groupV2s
      .map(({ count }) => {
        StateAssert.isDefined(count, 'Count must be part of result. Please update the query');
        return count;
      })
      .reduce(sumNumberReducer);
    return {
      scheduleRef,
      reviewNeededCount: count,
      isReviewNeeded: count > 0,
    };
  } else {
    return {
      scheduleRef,
      reviewNeededCount: 0,
      isReviewNeeded: false,
    };
  }
};

const toScheduleRequestReviewCounts = (
  scheduleRefs: ScheduleRef[],
  resultV2: QuestionnaireResponseAggregateResultV2,
): ScheduleRequestReviewCount[] =>
  scheduleRefs.map(requestRef => toScheduleRequestReviewCount(requestRef, resultV2.groups));

const toCountValue = (resultV2: QuestionnaireResponseAggregateResultV2): CountValue => {
  const completedCount = findGroupByStatus(resultV2.groups, QuestionnaireResponseStatusType.COMPLETED)?.count ?? 0;
  const missedCount = findGroupByStatus(resultV2.groups, QuestionnaireResponseStatusType.MISSED)?.count ?? 0;
  const totalCount = completedCount + missedCount;

  return CountValue.from(totalCount, completedCount, missedCount);
};

export const QuestionnaireV2AggregateResultMapper = {
  toScheduleRequestMetricInfos,
  toScheduleRequestReviewCounts,
  toCountValue,
};
