export const CONTENT_PAGE_SIZE = 100;

export type ContentPageV1 = {
  skip: number;
  limit: number;
};

export const firstContentPage = (): ContentPageV1 => ({
  skip: 0,
  limit: CONTENT_PAGE_SIZE,
});
