import { Maybe, ZonedDateTime } from '@csp/csp-common-model';
import { ScheduleWindowInterval } from '../model/ScheduleWindowInterval';

const calcIntersection = (
  interval1: ScheduleWindowInterval,
  interval2: ScheduleWindowInterval,
): Maybe<ScheduleWindowInterval> => {
  const leftLimit =
    interval1.windowStart.unixTimeMillis > interval2.windowStart.unixTimeMillis
      ? interval1.windowStart
      : interval2.windowStart;
  const rightLimit =
    (interval1.windowEnd?.unixTimeMillis ?? Number.MAX_VALUE) <
    (interval2.windowEnd?.unixTimeMillis ?? Number.MAX_VALUE)
      ? interval1.windowEnd
      : interval2.windowEnd;

  return leftLimit.unixTimeMillis < (rightLimit?.unixTimeMillis ?? Number.MAX_VALUE)
    ? {
        windowStart: leftLimit,
        windowEnd: rightLimit,
      }
    : undefined;
};

const intersectionOfIntervalLists = (
  intervals1: ScheduleWindowInterval[],
  intervals2: ScheduleWindowInterval[],
): ScheduleWindowInterval[] => {
  const intersectedIntervals: ScheduleWindowInterval[] = [];
  for (const interval1 of intervals1) {
    for (const interval2 of intervals2) {
      const intersection = calcIntersection(interval1, interval2);
      if (intersection) {
        intersectedIntervals.push(intersection);
      }
    }
  }
  return intersectedIntervals;
};

const updateWindowEnd = (interval: ScheduleWindowInterval, windowEnd: ZonedDateTime): ScheduleWindowInterval => ({
  ...interval,
  windowEnd,
});

export const ScheduleIntervalService = {
  intersection: calcIntersection,
  intersectionOfIntervalLists,
  updateWindowEnd,
};
