import { DmdpTokenService, IdpStateService } from '@csp/csp-fe-auth';

const signOut = async (): Promise<void> => {
  DmdpTokenService.clearToken();
  await IdpStateService.signOut();
};

export const SignOutService = {
  signOut,
};
