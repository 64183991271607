import { useContext } from 'react';
import { QueryContext } from './QueryContext';
import { QueryContextState } from './QueryContextState';

export const useQueryContext = (): QueryContextState => {
  const queryContext = useContext(QueryContext);
  if (queryContext) {
    return queryContext;
  } else {
    throw new Error('Use only from children of QueryContext');
  }
};
