export enum ValidationType {
  EMAIL = 'EMAIL',
  PATTERN = 'PATTERN',
  MIN_LENGTH = 'MIN_LENGTH',
  MAX_LENGTH = 'MAX_LENGTH',
  REQUIRED = 'REQUIRED',
  PHONE_NUMBER = 'PHONE_NUMBER',
  DATE = 'DATE',
  BIRTH_DATE = 'BIRTH_DATE',
  IN_PAST = 'IN_PAST',
  IN_FUTURE = 'IN_FUTURE',
  NOT_PAST = 'NOT_PAST',
  NOT_PAST_TIME = 'NOT_PAST_TIME',
  MIN_DATE = 'MIN_DATE',
  MIN_TIME = 'MIN_TIME',
  MAX_TIME = 'MAX_TIME',
  MAX_DATE = 'MAX_DATE',
  TIME = 'TIME',
  DATE_TIME = 'DATE_TIME',
  TIME_STEP = 'TIME_STEP',
  NUMERIC_STEP = 'NUMERIC_STEP',
  NUMBER_OF_DECIMALS = 'NUMBER_OF_DECIMALS',
  ONE_OF = 'ONE_OF',
  CORRECT_HUMAN_MEASUREMENT = 'CORRECT_HUMAN_MEASUREMENT',
  CUSTOM = 'CUSTOM',
  MIN_ARRAY_LENGTH = 'MIN_ARRAY_LENGTH',
  MAX_ARRAY_LENGTH = 'MAX_ARRAY_LENGTH',
  MIN_VALUE = 'MIN_VALUE',
  MAX_VALUE = 'MAX_VALUE',
  EQUALITY = 'EQUALITY',
  VALID_NUMBER = 'VALID_NUMBER',
}
