import { Badge as MuiBadge, BadgeProps as MuiBadgeProps } from '@mui/material';
import { FC } from 'react';

type Props = MuiBadgeProps & {
  overlay?: boolean;
};

export const Badge: FC<Props> = ({ showZero = true, sx, badgeContent, children, overlay = true, ...props }) => {
  const badgeContentIsZero = badgeContent === 0;
  return (
    <MuiBadge
      sx={{
        position: overlay ? 'relative' : 'unset',
        display: overlay ? 'inline-flex' : 'unset',
        '& .MuiBadge-badge': {
          position: overlay ? 'absolute' : 'unset',
          padding: overlay ? '0 6px' : '2px 6px',
          display: overlay ? 'flex' : 'unset',
          backgroundColor: badgeContentIsZero ? 'grey.500' : 'primary',
          color: 'common.white',
        },
        ...sx,
      }}
      showZero={showZero}
      badgeContent={badgeContent}
      color={badgeContentIsZero ? 'default' : 'primary'}
      {...props}
    >
      {children}
    </MuiBadge>
  );
};
