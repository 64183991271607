/* eslint-disable */
export const UnifyScheduleVisitsMock = {
  schedules: [
    {
      scheduleCode: 'STUDY_PROTOCOL_APPOINTMENTS',
      visits: [
        {
          requestCode: 'VISIT_1',
          title: '[M] Randomization Visit',
          visitType: '[M] Randomization',
          hcpDescriptionMd:
            'HCP DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_1 - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          patientDescriptionMd:
            'PATIENT DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_1 - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          preActivitiesMd:
            'PATIENT PRE-ACTIVITIES for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_1 - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          allowedVersions: [{ version: 'v1.0' }, { version: 'v1.0a' }],
          visitConfirmationLearnMore: {
            labelsAndDescriptions: [
              {
                label: '[M]Label 1',
                bodyMd:
                  "[M]Here's a line for us to start with.\n\nThis line is separated from the one above by two newlines, so it will be a *separate paragraph*.\n\nThis line is also a separate paragraph, but...\nThis line is only separated by a single newline, so it's a separate line in the *same paragraph*.",
              },
              {
                label: '[M]Label 2',
                bodyMd:
                  "[M]Here's a line for us to start with.\n\nThis line is separated from the one above by two newlines, so it will be a *separate paragraph*.\n\nThis line is also a separate paragraph, but...\nThis line is only separated by a single newline, so it's a separate line in the *same paragraph*.",
              },
            ],
          },
          bioSampleKitDescriptions: [{ body: 'Tumor kit' }],
        },
        {
          requestCode: 'VISIT_2',
          title: '[M] Visit 2',
          visitType: '[M] Routine',
          hcpDescriptionMd:
            'HCP DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_2 - versions [v1.0] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          patientDescriptionMd:
            'PATIENT DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_2 - versions [v1.0] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          preActivitiesMd:
            'PATIENT PRE-ACTIVITIES for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_2 - versions [v1.0] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          allowedVersions: [{ version: 'v1.0' }],
        },
        {
          requestCode: 'VISIT_2',
          title: '[M] Visit 2',
          visitType: '[M] Routine',
          hcpDescriptionMd:
            'HCP DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_2 - versions [v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          patientDescriptionMd:
            'PATIENT DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_2 - versions [v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          preActivitiesMd:
            'PATIENT PRE-ACTIVITIES for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_2 - versions [v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          allowedVersions: [{ version: 'v1.0a' }],
        },
        {
          requestCode: 'VISIT_2A',
          title: '[M] Visit 2A (Lab Test)',
          visitType: '[M] Lab Test',
          hcpDescriptionMd:
            'HCP DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_2A - versions [v1.0] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          patientDescriptionMd:
            'PATIENT DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_2A - versions [v1.0] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          preActivitiesMd:
            'PATIENT PRE-ACTIVITIES for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_2A - versions [v1.0] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          allowedVersions: [{ version: 'v1.0' }],
        },
        {
          requestCode: 'VISIT_2A',
          title: '[M] Visit 2A (Lab Test)',
          visitType: '[M] Lab Test',
          hcpDescriptionMd:
            'HCP DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_2A - versions [v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          patientDescriptionMd:
            'PATIENT DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_2A - versions [v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          preActivitiesMd:
            'PATIENT PRE-ACTIVITIES for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_2A - versions [v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          allowedVersions: [{ version: 'v1.0a' }],
        },
        {
          requestCode: 'VISIT_3',
          title: '[M] Visit 3',
          visitType: '[M] Routine',
          hcpDescriptionMd:
            'HCP DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_3 - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          patientDescriptionMd:
            'PATIENT DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_3 - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          preActivitiesMd:
            'PATIENT PRE-ACTIVITIES for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_3 - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          allowedVersions: [{ version: 'v1.0' }, { version: 'v1.0a' }],
        },
        {
          requestCode: 'VISIT_3A',
          title: '[M] Visit 3A (Lab Test)',
          visitType: '[M] Routine',
          hcpDescriptionMd:
            'HCP DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_3A - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          patientDescriptionMd:
            'PATIENT DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_3A - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          preActivitiesMd:
            'PATIENT PRE-ACTIVITIES for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_3A - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          allowedVersions: [{ version: 'v1.0' }, { version: 'v1.0a' }],
        },
        {
          requestCode: 'VISIT_4',
          title: '[M]Visit 4',
          visitType: '[M] Re-occuring Routine',
          hcpDescriptionMd:
            'HCP DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_4 - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          patientDescriptionMd:
            'PATIENT DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_4 - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          preActivitiesMd:
            'PATIENT PRE-ACTIVITIES for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_4 - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          allowedVersions: [{ version: 'v1.0' }, { version: 'v1.0a' }],
          bioSampleKitDescriptions: [{ body: 'BRCA KNOWN' }, { body: 'ctDNA' }],
        },
        {
          requestCode: 'VISIT_4_Plus',
          title: '[M] Visit 4',
          visitType: '[M] Re-occuring Routine',
          hcpDescriptionMd:
            'HCP DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_4_Plus - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          patientDescriptionMd:
            'PATIENT DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_4_Plus - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          preActivitiesMd:
            'PATIENT PRE-ACTIVITIES for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_4_Plus - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          allowedVersions: [{ version: 'v1.0' }, { version: 'v1.0a' }],
        },
        {
          requestCode: 'VISIT_4A_Plus',
          title: '[M] Visit 4A (Lab Test)',
          visitType: '[M] Re-occuring Routine',
          hcpDescriptionMd:
            'HCP DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_4A_Plus - versions [v1.0] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          patientDescriptionMd:
            'PATIENT DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_4A_Plus - versions [v1.0] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          preActivitiesMd:
            'PATIENT PRE-ACTIVITIES for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_4A_Plus - versions [v1.0] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          allowedVersions: [{ version: 'v1.0' }],
        },
        {
          requestCode: 'VISIT_4A_Plus',
          title: '[M] Visit 4A (Lab Test)',
          visitType: '[M] Re-occuring Routine',
          hcpDescriptionMd:
            'HCP DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_4A_Plus - versions [v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          patientDescriptionMd:
            'PATIENT DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_4A_Plus - versions [v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          preActivitiesMd:
            'PATIENT PRE-ACTIVITIES for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_4A_Plus - versions [v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          allowedVersions: [{ version: 'v1.0a' }],
        },
        {
          requestCode: 'PTDV',
          title: '[M] PTDV',
          visitType: '[M] Study Closure',
          hcpDescriptionMd:
            'HCP DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode PTDV - versions [v1.0] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          patientDescriptionMd:
            'PATIENT DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode PTDV - versions [v1.0] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          preActivitiesMd:
            'PATIENT PRE-ACTIVITIES for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode PTDV - versions [v1.0] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          allowedVersions: [{ version: 'v1.0' }],
        },
        {
          requestCode: 'PTDV',
          title: '[M] PTDV',
          visitType: '[M] Study Closure',
          hcpDescriptionMd:
            'HCP DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode PTDV - versions [v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          patientDescriptionMd:
            'PATIENT DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode PTDV - versions [v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          preActivitiesMd:
            'PATIENT PRE-ACTIVITIES for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode PTDV - versions [v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          allowedVersions: [{ version: 'v1.0a' }],
        },
        {
          requestCode: 'Study_Closure_Visit',
          title: '[M] Study Closure Visit',
          visitType: '[M] Study Closure',
          hcpDescriptionMd:
            'HCP DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode Study_Closure_Visit - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          patientDescriptionMd:
            'PATIENT DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode Study_Closure_Visit - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          preActivitiesMd:
            'PATIENT PRE-ACTIVITIES for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode Study_Closure_Visit - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
          allowedVersions: [{ version: 'v1.0' }, { version: 'v1.0a' }],
        },
      ],
    },
  ],
};
