import { Maybe, ZonedDateTime } from '@csp/csp-common-model';
import { orderBy } from 'lodash';
import { RequestInitiatorType } from '../initiation-config/model/RequestInitiatorType';
import { RequestGroupKey } from '../request-group/model/RequestGroupKey';
import { RequestWhileUtil } from '../util/RequestWhileUtil';
import { RequestUserStatus, RequestUserStatuses } from './RequestUserStatus';
import { RequestVersionStatus } from './RequestVersionStatus';
import { RequestWithVersionInfo } from './RequestWithVersionInfo';
import { ScheduleRef } from './ScheduleRef';
import { FixedAction } from './schedulingModels/FixedAction';
import { GenericRequest } from './schedulingModels/GenericRequest';
import { RequestWhileCriterion } from './schedulingModels/RequestWhileCriterion';

export type ScheduleRequestInfo<Request extends GenericRequest = GenericRequest> = {
  ref: ScheduleRef;
  requestUserStatus: RequestUserStatus;
  requestVersionStatus: RequestVersionStatus;
  startDate?: ZonedDateTime;
  endDate?: ZonedDateTime;
  request: RequestWithVersionInfo<Request>;
  fixedStartAction?: FixedAction;
  fixedEndAction?: FixedAction;
  hasRequestGroupsByKeys(groupKeys: RequestGroupKey[]): boolean;
  hasRequestUserStatus(statuses: RequestUserStatus[]): boolean;
  hasRequestVersionStatus(statuses: RequestVersionStatus[]): boolean;
  hasMatchingRequestWhileCriteria(criteriaToMatch: RequestWhileCriterion[]): boolean;
  isInitiatedBy(initiator: RequestInitiatorType): boolean;
};

const from = <Request extends GenericRequest>(
  request: RequestWithVersionInfo<Request>,
  requestVersionStatus: RequestVersionStatus,
  startDate?: ZonedDateTime,
  endDate?: ZonedDateTime,
): ScheduleRequestInfo<Request> => {
  const requestUserStatus = RequestUserStatuses.from(requestVersionStatus, startDate, endDate);

  const hasRequestUserStatus = (statuses: RequestUserStatus[]): boolean =>
    statuses.length === 0 || statuses.includes(requestUserStatus);

  const hasRequestGroupsByKeys = (groupKeys: RequestGroupKey[]): boolean =>
    !!(groupKeys.length === 0 || request.groups?.includesOneOf(groupKeys));

  const hasRequestVersionStatus = (statuses: RequestVersionStatus[]): boolean =>
    statuses.length === 0 || statuses.includes(requestVersionStatus);

  const isInitiatedBy = (initiatedBy: RequestInitiatorType): boolean =>
    request.initiationConfig?.initiator === initiatedBy;

  const hasMatchingRequestWhileCriteria = (criteriaToMatch: RequestWhileCriterion[]): boolean =>
    !!RequestWhileUtil.filterRequestsWithMatchingCriteria(criteriaToMatch, [request]).length;

  return {
    ref: ScheduleRef.fromRequest(request),
    startDate,
    endDate,
    requestUserStatus,
    requestVersionStatus,
    request,
    fixedStartAction: request.startAction?.fixedAction,
    fixedEndAction: request.endAction?.fixedAction,
    hasRequestUserStatus,
    hasRequestGroupsByKeys,
    hasRequestVersionStatus,
    hasMatchingRequestWhileCriteria,
    isInitiatedBy,
  };
};

const evalRequestUserStatus = (requestInfos: ScheduleRequestInfo[]): RequestUserStatus => {
  const statuses = requestInfos.map(requestInfo => requestInfo.requestUserStatus);
  if (statuses.includes(RequestUserStatus.ONGOING)) {
    return RequestUserStatus.ONGOING;
  } else if (statuses.includes(RequestUserStatus.DONE)) {
    return RequestUserStatus.DONE;
  } else if (statuses.includes(RequestUserStatus.EXPIRED)) {
    return RequestUserStatus.EXPIRED;
  } else if (statuses.includes(RequestUserStatus.UPCOMING)) {
    return RequestUserStatus.UPCOMING;
  } else {
    return RequestUserStatus.NOT_APPLICABLE;
  }
};

const evalRequestVersionStatus = (requestInfos: ScheduleRequestInfo[]): RequestVersionStatus => {
  const evalRequestVersionInternal = (requestInfos: ScheduleRequestInfo[]): RequestVersionStatus => {
    const statuses = requestInfos.map(requestInfo => requestInfo.requestVersionStatus);
    if (statuses.includes(RequestVersionStatus.ACTIVE)) {
      return RequestVersionStatus.ACTIVE;
    } else if (statuses.includes(RequestVersionStatus.EXPIRED)) {
      return RequestVersionStatus.EXPIRED;
    } else if (statuses.includes(RequestVersionStatus.FUTURE)) {
      return RequestVersionStatus.FUTURE;
    } else {
      // Superseded can never happen, because if there are
      // superseded requests in a schedule it means that there are
      // newer requests that are either expired or active and would
      // return further up.
      //
      // If we ended up here it is either because there we no request
      // infos to begin, in which case the status doesn't matter.
      return RequestVersionStatus.ACTIVE;
    }
  };

  // We are first and foremost interested in the requests that are
  // applicable to the patient. This solves the following scenario:
  // 1. S1 -> V1 -> R1 is ACTIVE and ONGOING
  // 2. S1 -> V2 -> R2 is activated instead after a protocol amendment.
  //    R2 is NOT_APPLICABLE to the patient.
  // 3. When merging R1 and R2 we want the request info to have
  //    verion status EXPIRED because all requests in the new version (V2) are
  //    NOT_APPLICABLE
  //
  // If all requests are not applicable then we use those to evaluate the version status.
  const applicableRequestInfos = requestInfos.filter(
    requestInfo => requestInfo.requestUserStatus !== RequestUserStatus.NOT_APPLICABLE,
  );
  return applicableRequestInfos.length
    ? evalRequestVersionInternal(applicableRequestInfos)
    : evalRequestVersionInternal(requestInfos);
};

const merge = <Request extends GenericRequest>(
  allRequestInfos: ScheduleRequestInfo<Request>[],
): Maybe<ScheduleRequestInfo<Request>> => {
  const requestUserStatus = evalRequestUserStatus(allRequestInfos);
  const requestVersionStatus = evalRequestVersionStatus(allRequestInfos);

  // When merging request infos we must look at all requests when evaluating the start/end date
  // and the reference (to fetch metrics and responses), but only the latest (non-future) version when evaluating
  // other configurations.
  //
  // If for instance a schedule had a on-demand request in an
  // expired version of the schedule but removed in the current, this API should return false for isInitiatedBy('HCP').
  //
  // We do this by looking only on the request infos that have the same status as the group as a whole, i.e.:
  // * If the group is ongoing, it is hcp initiated if any of the ongoing request infos are hcp initiated.
  const relevantRequestInfos = allRequestInfos.filter(
    requestInfo =>
      requestInfo.requestUserStatus === requestUserStatus && requestInfo.requestVersionStatus === requestVersionStatus,
  );
  const firstRequestInfo = relevantRequestInfos[0];

  if (firstRequestInfo) {
    const requestInfosWithStartDate = allRequestInfos.filter(req => !!req.startDate);
    const requestInfoThatStartedFirst = orderBy(
      requestInfosWithStartDate,
      requestInfo => requestInfo.startDate?.unixTimeMillis,
      'asc',
    )[0];
    const requestWithLatestEndDate = orderBy(
      requestInfosWithStartDate,
      requestInfo => requestInfo.endDate?.unixTimeMillis,
      'desc',
    )[0];
    const requestWithLatestEndAction =
      requestInfosWithStartDate.find(request => !request.fixedEndAction) ?? requestWithLatestEndDate;

    const hasRequestUserStatus = (statuses: RequestUserStatus[]): boolean =>
      statuses.length === 0 || statuses.includes(requestUserStatus);

    const hasRequestGroupsByKeys = (groupKeys: RequestGroupKey[]): boolean =>
      !!(groupKeys.length === 0 || relevantRequestInfos.some(req => req.request.groups?.includesOneOf(groupKeys)));

    const hasRequestVersionStatus = (statuses: RequestVersionStatus[]): boolean =>
      statuses.length === 0 || statuses.includes(requestVersionStatus);

    const isInitiatedBy = (initiator: RequestInitiatorType): boolean =>
      relevantRequestInfos.some(requestInfo => requestInfo.isInitiatedBy(initiator));

    const hasMatchingRequestWhileCriteria = (matchingCriterionTemplates: RequestWhileCriterion[]): boolean =>
      !!RequestWhileUtil.filterRequestsWithMatchingCriteria(
        matchingCriterionTemplates,
        relevantRequestInfos.map(requestInfo => requestInfo.request),
      ).length;

    const scheduleRef = ScheduleRef.fromSchedule(
      firstRequestInfo.request.scheduleCode,
      allRequestInfos.map(reqInfo => reqInfo.request),
    );

    const requestInitiatedByHcp = relevantRequestInfos.find(request => request.isInitiatedBy(RequestInitiatorType.HCP));

    return {
      requestVersionStatus,
      requestUserStatus,
      fixedStartAction: requestInfoThatStartedFirst?.fixedStartAction,
      fixedEndAction: requestWithLatestEndAction?.fixedEndAction,
      startDate: requestInfoThatStartedFirst?.startDate,
      endDate: requestWithLatestEndDate?.endDate,
      // The embedded request is a mix of all the requests this object is made of.
      request: {
        ...firstRequestInfo.request,
        // Using the on-demand request as reference maintains the contract for when this is used to send messages.
        initiationConfig: requestInitiatedByHcp?.request.initiationConfig ?? firstRequestInfo.request.initiationConfig,
        versionCode: requestInitiatedByHcp?.request.versionCode ?? firstRequestInfo.request.versionCode,
        requestCode: requestInitiatedByHcp?.request.requestCode ?? firstRequestInfo.request.requestCode,
        startAction: requestInfoThatStartedFirst?.request.startAction,
        endAction: requestWithLatestEndAction?.request.endAction,
      },
      ref: scheduleRef,
      hasRequestUserStatus,
      hasRequestGroupsByKeys,
      hasRequestVersionStatus,
      hasMatchingRequestWhileCriteria,
      isInitiatedBy,
    };
  } else {
    return undefined;
  }
};

export const ScheduleRequestInfo = {
  from,
  merge,
};
