import { createMockCreator } from '@csp/test-common-utils';
import { ValidationType } from '../../type/ValidationType';
import { InputValidator } from '../InputValidator';
import { InputValue } from '../InputValue';

const validator: InputValidator = value =>
  value === 'error' ? { message: 'message', type: ValidationType.REQUIRED } : null;

export const inputValueMock: InputValue = {
  touched: false,
  errors: [{ message: 'error', type: ValidationType.REQUIRED }],
  value: 'value',
  validators: [validator],
};

export const createInputValueMock = createMockCreator(inputValueMock);
