import { ScheduleVersionInfo } from './ScheduleVersionInfo';
import { GenericRequest } from './schedulingModels/GenericRequest';
import { GenericVersion } from './schedulingModels/GenericVersion';

export type RequestWithVersionInfo<Request extends GenericRequest = GenericRequest> = Request & {
  versionInfo: ScheduleVersionInfo;
};

const from = <Version extends GenericVersion, Request extends GenericRequest>(
  version: Version,
  request: Request,
): RequestWithVersionInfo<Request> => ({
  ...request,
  versionInfo: ScheduleVersionInfo.fromVersion(version),
});

export const RequestWithVersionInfo = {
  from,
};
