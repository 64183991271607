import { CspError } from '@csp/csp-common-model';
import { UserV1 } from '@csp/dmdp-api-user-dto';
import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import debug from 'debug';

const log = debug('Common:User:Ducks');

export const DEFAULT_LANG = 'en';
export const DEFAULT_COUNTRY = 'GB';
export const DEFAULT_LOCALE = `${DEFAULT_LANG}_${DEFAULT_COUNTRY}`;

export interface LocaleState {
  overrideLang?: string;
  langCode: string;
  countryCode: string;
}

export const initialState: LocaleState = {
  langCode: DEFAULT_LANG,
  countryCode: DEFAULT_COUNTRY,
};

const getLanguageFromUser = (user: UserV1, overrideLang?: string): string =>
  (overrideLang || user?.localization?.langCode || DEFAULT_LANG).toLowerCase();

const getCountryFromUser = (user: UserV1): string => (user?.localization?.countryCode || DEFAULT_COUNTRY).toUpperCase();

const getLocale = (state: LocaleState): string => `${state.langCode}_${state.countryCode}`;

const VALID_LOCALE_REGEX = /^[a-z]{2}_[A-Z]{2}$/;

const setLocale: CaseReducer<LocaleState, PayloadAction<string>> = (state, action) => {
  const currentLocale = getLocale(state);
  const locale = action.payload;
  if (!VALID_LOCALE_REGEX.test(locale)) {
    throw CspError.badState(`Invalid locale provided: ${locale}`);
  }

  const langCode = state.overrideLang || locale.slice(0, 2);
  const countryCode = locale.slice(3);
  const newState = {
    ...state,
    langCode,
    countryCode,
  };

  const newLocale = getLocale(newState);
  log(`Update locale from ${currentLocale} to ${newLocale}`);
  return newState;
};

const setOverrideLang: CaseReducer<LocaleState, PayloadAction<string>> = (state, action) => {
  state.overrideLang = action.payload;
};

const unsetOverrideLang: CaseReducer<LocaleState> = state => {
  state.overrideLang = undefined;
};

const setLocaleFromUser: CaseReducer<LocaleState, PayloadAction<UserV1>> = (state, action) => {
  const countryCode = getCountryFromUser(action.payload);
  const langCode = getLanguageFromUser(action.payload, state.overrideLang);
  return setLocale(state, { type: '', payload: `${langCode}_${countryCode}` });
};

const slice = createSlice({
  name: 'locale',
  initialState,
  reducers: {
    setOverrideLang,
    unsetOverrideLang,
    setLocaleFromUser,
    setLocale,
  },
});

export const localeReducer = slice.reducer;
export const localeActions = slice.actions;
