import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import MuiButton from '@mui/material/Button';
import { spacing } from '@mui/system';
import { FC } from 'react';
import { ButtonProps } from './model/ButtonProps';

const StyledButton = styled(MuiButton, { shouldForwardProp: prop => prop !== 'noWrap' })<ButtonProps>`
  ${spacing}
  white-space: ${({ noWrap }): SerializedStyles => css(noWrap ? 'nowrap' : undefined)};
`;

export const Button: FC<ButtonProps> = ({ disabled, children, ...rest }) => (
  <StyledButton disabled={disabled} {...rest}>
    {children}
  </StyledButton>
);
