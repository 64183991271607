import React from 'react';
import { ReactComponent as applicationError } from '../assets/application-error.svg';
import { ReactComponent as inviteNotValid } from '../assets/invite-not-valid.svg';
import { ReactComponent as error } from '../assets/error.svg';
import { ReactComponent as emailSent } from '../assets/email-sent.svg';

export const illustrationTypes = ['applicationError', 'inviteNotValid', 'error', 'emailSent'] as const;

export type IllustrationType = typeof illustrationTypes[number];

type SVGPropsWithTitle = React.SVGProps<SVGSVGElement> & {
  title?: string;
};

type SvgsType = { [key: string]: React.FunctionComponent<SVGPropsWithTitle> };

export const svgs: SvgsType = {
  applicationError,
  inviteNotValid,
  error,
  emailSent,
};
