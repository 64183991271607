import { RawAssessmentConsequenceActivityCardConfigV1 } from '@csp/config-schemas';
import { AssessmentConsequenceActivityCardConfig, Maybe } from '@csp/csp-common-model';
import { AssessmentConsequenceActivityCardValidationService } from './AssessmentConsequenceActivityCardValidationService';

const toAssessmentConsequenceActivityCardConfig = (
  config: Maybe<RawAssessmentConsequenceActivityCardConfigV1>,
): Maybe<AssessmentConsequenceActivityCardConfig> => {
  if (!config) {
    return undefined;
  }
  AssessmentConsequenceActivityCardValidationService.validateAssessmentConsequenceActivityCardConfig(config);

  return {
    ttlInSeconds: config.ttlInSeconds,
  };
};

export const AssessmentConsequenceActivityCardService = {
  toAssessmentConsequenceActivityCardConfig,
};
