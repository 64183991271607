import { ContentRole } from '@csp/csp-common-content-utils';
import { ClientType } from '@csp/csp-common-model';
import { contentstackConfig } from '../config/contentstackConfig';
import { CachePolicy, ContentstackConfig } from '../config/model/ContentstackConfig';
import { ContentCacheService } from '../model/ContentCacheService';
import { ContentPackageType } from '../model/ContentPackageType';

export const contentDeliveryUrl = (config?: ContentstackConfig): string =>
  config?.baseUrl || contentstackConfig().baseUrl;

export const getContentLocale = (config?: ContentstackConfig): Lowercase<string> =>
  (config?.locale.toLocaleLowerCase() as Lowercase<string>) ||
  (contentstackConfig().locale.toLowerCase() as Lowercase<string>);

export const getContentClientType = (config?: ContentstackConfig): ClientType =>
  config?.clientType || contentstackConfig().clientType;

export const getCacheProvider = (config?: ContentstackConfig): ContentCacheService | undefined =>
  config?.cacheProvider || contentstackConfig().cacheProvider;

export const getCacheTimeoutInSeconds = (config?: ContentstackConfig): number | undefined =>
  config?.cacheTimeoutInSeconds || contentstackConfig().cacheTimeoutInSeconds;

export const getAllowContentPreview = (config?: ContentstackConfig): boolean =>
  config?.allowContentPreview || contentstackConfig().allowContentPreview;

export const getForceContentPreview = (config?: ContentstackConfig): boolean =>
  config?.forceContentPreview || contentstackConfig().forceContentPreview;

export const getCachePolicy = (config?: ContentstackConfig): CachePolicy =>
  config?.cachePolicy || contentstackConfig().cachePolicy;

export const getRole = (config?: ContentstackConfig): ContentRole => config?.role || contentstackConfig().role;

export const getContentVersion = (packageType: ContentPackageType, config?: ContentstackConfig): string =>
  config?.packageVersions[packageType] || contentstackConfig().packageVersions[packageType];
