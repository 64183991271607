import { fromTimezoneStr, nowToZonedDateTimeCurrentZone, zeroZonedDateTime } from '@csp/csp-common-date-util';
import {
  PatientAppStatusType,
  PatientAppStatusTypes,
  PatientFinalContactStatusType,
  PatientMedicationStatusType,
  PatientMedicationStatusTypes,
  PatientSkipStatusType,
  PatientSkipStatusTypeValues,
  PatientStatus,
  PatientStatuses,
  PatientStatusType,
  PatientStudyStatusType,
  PatientStudyStatusTypeValues,
  PatientVitalStatusType,
  RtsmStatus,
  RtsmStatusMapping,
  StudyPatientStatusMappings,
  ZonedDateTime,
} from '@csp/csp-common-model';
import { CustomStatusV1 } from '@csp/dmdp-api-common-dto';
import { DmdpUserStatusType } from '@csp/dmdp-api-user-dto';
import { cloneDeep } from 'lodash';
import { PatientStatusRuleService } from '../service/PatientStatusRuleService';

const toPatientStatus = <T>(
  type: PatientStatusType,
  customStatusesV1: CustomStatusV1[],
  defaultStatus: T,
): PatientStatus<T> => {
  const rtsmStatusV1: CustomStatusV1 | undefined = customStatusesV1.find(status => status.type === type);
  const localTimestampOrZero = rtsmStatusV1?.localTimestamp
    ? fromTimezoneStr(rtsmStatusV1.localTimestamp)
    : zeroZonedDateTime;
  const status = (rtsmStatusV1?.value as unknown as T) || defaultStatus;
  return {
    status,
    timestamp: localTimestampOrZero,
  };
};

const isAppStatusEditable = (prevPatientStatuses: PatientStatuses): boolean =>
  prevPatientStatuses.appStatus.status !== PatientAppStatusType.INACTIVE;

const fromDmdpStatus = (dmdpStatus: DmdpUserStatusType, prevPatientStatuses: PatientStatuses): PatientStatuses => {
  if (
    dmdpStatus === DmdpUserStatusType.ACTIVE &&
    prevPatientStatuses.appStatus.status !== PatientAppStatusType.ACTIVE &&
    isAppStatusEditable(prevPatientStatuses)
  ) {
    const newPatientStatuses = cloneDeep(prevPatientStatuses);
    newPatientStatuses.appStatus.status = PatientAppStatusType.ACTIVE;
    newPatientStatuses.appStatus.timestamp = nowToZonedDateTimeCurrentZone();
    return newPatientStatuses;
  } else {
    return prevPatientStatuses;
  }
};

const mutatePatientStatuses = (
  prevPatientStatuses: PatientStatuses,
  draftPatientStatuses: PatientStatuses,
  timestamp: ZonedDateTime,
  studyStatus: PatientStudyStatusType | PatientSkipStatusType,
  medicationStatus: PatientMedicationStatusType | PatientSkipStatusType,
  appStatus: PatientAppStatusType | PatientSkipStatusType,
  mappings: StudyPatientStatusMappings,
): void => {
  if (
    mappings.isPatientStudyStatusTransitionAllowed(
      prevPatientStatuses.studyStatus.status,
      studyStatus as PatientStudyStatusType,
    ) ||
    prevPatientStatuses.studyStatus.status === studyStatus ||
    PatientSkipStatusTypeValues.includes(studyStatus)
  ) {
    if (PatientStudyStatusTypeValues.isPatientStudyStatus(studyStatus)) {
      draftPatientStatuses.studyStatus.status = studyStatus;
      draftPatientStatuses.studyStatus.timestamp = timestamp;
    }

    if (
      PatientMedicationStatusTypes.excludeUnknownAsString.includes(medicationStatus) &&
      mappings.isPatientMedicationStatusTransitionAllowed(
        prevPatientStatuses.medicationStatus.status,
        medicationStatus as PatientMedicationStatusType,
      )
    ) {
      draftPatientStatuses.medicationStatus.status = medicationStatus as PatientMedicationStatusType;
      draftPatientStatuses.medicationStatus.timestamp = timestamp;
    }

    if (PatientAppStatusTypes.isPatientAppStatus(appStatus) && isAppStatusEditable(prevPatientStatuses)) {
      draftPatientStatuses.appStatus.status = appStatus;
      draftPatientStatuses.appStatus.timestamp = timestamp;
    }
  }
};

const toNewPatientStatuses = (
  newRtsmStatus: RtsmStatus,
  timestamp: ZonedDateTime,
  prevPatientStatuses: PatientStatuses,
  mappings: StudyPatientStatusMappings,
  rtsmStatusMapping: RtsmStatusMapping,
): PatientStatuses => {
  const newStudyStatus: PatientStudyStatusType | PatientSkipStatusType = rtsmStatusMapping.studyStatus;

  const mergedStudyStatusMapping = PatientStatusRuleService.mergeRtsmStatusMapping(rtsmStatusMapping);

  const newPatientStatuses = cloneDeep(prevPatientStatuses);
  newPatientStatuses.rtsmStatus.status = newRtsmStatus;
  newPatientStatuses.rtsmStatus.timestamp = timestamp;

  mutatePatientStatuses(
    prevPatientStatuses,
    newPatientStatuses,
    timestamp,
    newStudyStatus,
    mergedStudyStatusMapping.medicationStatus,
    mergedStudyStatusMapping.appStatus,
    mappings,
  );

  return newPatientStatuses;
};

const fromRtsmStatus = (
  newRtsmStatus: RtsmStatus,
  timestamp: ZonedDateTime,
  prevPatientStatuses: PatientStatuses,
  mappings: StudyPatientStatusMappings,
): PatientStatuses[] => {
  const newPatientStatuses: PatientStatuses[] = [];
  const rtsmStatusMappings = mappings.getRtsmStatusMappings(newRtsmStatus);

  let prevPatientStatusToUse = prevPatientStatuses;
  for (const rtsmStatusMapping of rtsmStatusMappings.orderedMappings) {
    const newPatientStatus = toNewPatientStatuses(
      newRtsmStatus,
      timestamp,
      prevPatientStatusToUse,
      mappings,
      rtsmStatusMapping,
    );
    newPatientStatuses.push(newPatientStatus);
    prevPatientStatusToUse = newPatientStatus;
  }
  return newPatientStatuses;
};

const fromStudyStatus = (
  newStudyStatus: PatientStudyStatusType,
  timestamp: ZonedDateTime,
  prevPatientStatuses: PatientStatuses,
  mappings: StudyPatientStatusMappings,
): PatientStatuses => {
  const mergedStatusMappings = PatientStatusRuleService.mergeStudyStatusMapping(
    newStudyStatus,
    mappings.getStudyStatusMapping(newStudyStatus),
  );

  const newPatientStatuses = cloneDeep(prevPatientStatuses);
  mutatePatientStatuses(
    prevPatientStatuses,
    newPatientStatuses,
    timestamp,
    newStudyStatus,
    mergedStatusMappings.medicationStatus,
    mergedStatusMappings.appStatus,
    mappings,
  );

  return newPatientStatuses;
};

const fromMedicationStatus = (
  newMedicationStatus: PatientMedicationStatusType,
  timestamp: ZonedDateTime,
  prevPatientStatuses: PatientStatuses,
  mappings: StudyPatientStatusMappings,
): PatientStatuses => {
  if (
    mappings.isPatientMedicationStatusTransitionAllowed(
      prevPatientStatuses.medicationStatus.status,
      newMedicationStatus,
    )
  ) {
    const newPatientStatuses = cloneDeep(prevPatientStatuses);
    newPatientStatuses.medicationStatus.status = newMedicationStatus;
    newPatientStatuses.medicationStatus.timestamp = timestamp;
    return newPatientStatuses;
  } else {
    return prevPatientStatuses;
  }
};

const toPatientStatuses = (customStatusesV1: CustomStatusV1[]): PatientStatuses => ({
  rtsmStatus: toPatientStatus<RtsmStatus>(PatientStatusType.RTSM, customStatusesV1, 'UNKNOWN'),
  studyStatus: toPatientStatus<PatientStudyStatusType>(
    PatientStatusType.STUDY,
    customStatusesV1,
    PatientStudyStatusType.UNKNOWN,
  ),
  medicationStatus: toPatientStatus<PatientMedicationStatusType>(
    PatientStatusType.MEDICATION,
    customStatusesV1,
    PatientMedicationStatusType.UNKNOWN,
  ),
  appStatus: toPatientStatus<PatientAppStatusType>(
    PatientStatusType.APP,
    customStatusesV1,
    PatientAppStatusType.UNKNOWN,
  ),
  vitalStatus: toPatientStatus<PatientVitalStatusType>(
    PatientStatusType.VITAL,
    customStatusesV1,
    PatientVitalStatusType.UNKNOWN,
  ),
  finalContactStatus: toPatientStatus<PatientFinalContactStatusType>(
    PatientStatusType.FINAL_CONTACT,
    customStatusesV1,
    PatientFinalContactStatusType.UNKNOWN,
  ),
});

export const PatientStatusMapper = {
  fromDmdpStatus,
  fromRtsmStatus,
  fromMedicationStatus,
  fromStudyStatus,
  toPatientStatuses,
  toPatientStatus,
};
