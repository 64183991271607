import { flow, keys, omit } from 'lodash/fp';

export enum RoleType {
  TENANT_ADMIN = 'TENANT_ADMIN',
  ORG_ADMIN = 'ORG_ADMIN',
  ANALYST = 'ANALYST',
  PRACTITIONER = 'PRACTITIONER',
  PATIENT = 'PATIENT',
  CRA = 'CRA',
  PI = 'PI',
  INVESTIGATOR = 'INVESTIGATOR',
  STUDY_ASST = 'STUDY_ASST',
  READ_ALL = 'READ_ALL',
  SERVICE_CSA = 'SERVICE_CSA',
  SSTM = 'SSTM',
  GSTM = 'GSTM',
  SERVICE_STUDY_ADMIN = 'SERVICE_STUDY_ADMIN',
}
export const USER_ROLE_TYPE_NAMES: RoleType[] = Object.values(RoleType);

export const CSP_PERMISSION_ROLES: RoleType[] = [
  RoleType.CRA,
  RoleType.PI,
  RoleType.INVESTIGATOR,
  RoleType.STUDY_ASST,
  RoleType.READ_ALL,
  RoleType.SSTM,
  RoleType.GSTM,
];

export const HCP_ROLES: RoleType[] = [RoleType.PI, RoleType.INVESTIGATOR, RoleType.STUDY_ASST, RoleType.READ_ALL];

export const allUnifyRolesExcluding = (roles: RoleType[]): RoleType[] => flow(omit(roles), keys)(RoleType);
