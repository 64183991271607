import { largePage, Page, QueryUserIdsV1, QueryV1 } from '@csp/dmdp-api-common-dto';
import { HealthEventInV1, HealthEventStatusInV1, HealthEventsV1, HealthEventV1 } from '@csp/dmdp-api-healthevent-dto';
import { RestOptions } from '@csp/csp-common-model';
import { parseJsonWithBigInt } from '@csp/csp-common-axios';
import { journalpodUrl } from '../../dmdp-config/dmdpConfig';

const healthEventUrl = (): string => `${journalpodUrl()}/healthevent/v1`;

const query = async (
  { axios, signal }: RestOptions,
  query: QueryUserIdsV1,
  page: Page = largePage(),
): Promise<HealthEventsV1> => {
  const { data } = await axios.post(`${healthEventUrl()}/query`, query, {
    params: page,
    transformResponse: parseJsonWithBigInt,
    signal,
  });

  return data;
};

const queryMine = async (
  { axios, signal }: RestOptions,
  query: QueryV1,
  page: Page = largePage(),
): Promise<HealthEventsV1> => {
  const { data } = await axios.post(`${healthEventUrl()}/query/mine`, query, {
    params: page,
    transformResponse: parseJsonWithBigInt,
    signal,
  });

  return data;
};

const createHealthEvent = async (
  { axios, signal }: RestOptions,
  healthEventBaseV1: HealthEventInV1,
): Promise<HealthEventV1> => {
  const { data } = await axios.post(`${healthEventUrl()}`, healthEventBaseV1, {
    transformResponse: parseJsonWithBigInt,
    signal,
  });

  return data;
};

const updateStatusOfHealthEvent = async (
  { axios, signal }: RestOptions,
  healthEventId: string,
  status: HealthEventStatusInV1,
): Promise<void> => {
  await axios.put(`${healthEventUrl()}/${healthEventId}/status`, status, {
    signal,
  });
};

const getByHealthEventId = async ({ axios, signal }: RestOptions, healthEventId: string): Promise<HealthEventV1> => {
  const { data } = await axios.get(`${healthEventUrl()}/${healthEventId}`, {
    transformResponse: parseJsonWithBigInt,
    signal,
  });

  return data;
};

export const HealthEventRestServiceV1 = {
  query,
  queryMine,
  createHealthEvent,
  updateStatusOfHealthEvent,
  getByHealthEventId,
};
