import { ContentRole } from '@csp/csp-common-content-utils';
import { ClientType, ObjectType } from '@csp/csp-common-model';
import { ContentCacheService } from '../../model/ContentCacheService';
import { ContentPackageType } from '../../model/ContentPackageType';

/**
 * CACHE_FIRST - Check if the package exists in the cache, if it does and has not expired
 * return it without fetching anything from the network
 * CACHE_AND_NETWORK - Check if the package exists in the cache, if it does include its etag in the request toward
 * the backend. If the package has not changed, fetch it from the cache. If it has, fetch it from the network.
 * NETWORK_ONLY - Fetch the package from the network, will not store the package to the cache.
 */
export enum CachePolicy {
  CACHE_FIRST = 'CACHE_FIRST',
  CACHE_AND_NETWORK = 'CACHE_AND_NETWORK',
  NETWORK_ONLY = 'NETWORK_ONLY',
}

export type ContentstackConfig = {
  baseUrl: string;
  clientType: ClientType;
  locale: string;
  packageVersions: Record<ContentPackageType, string>;
  allowContentPreview: boolean;
  forceContentPreview: boolean;
  allowMockedContent?: boolean;
  validateContent: boolean;
  hideSchemaValidationMessages?: boolean;
  cacheTimeoutInSeconds?: number;
  mockContent: Record<ContentPackageType, ObjectType>;
  cacheProvider?: ContentCacheService;
  cachePolicy: CachePolicy;
  role: ContentRole;
};

const empty = (): ContentstackConfig => ({
  baseUrl: 'https://unify-apig-dev02.dev-dmdp-sysops02.dmdp-dev.astrazeneca.com',
  clientType: ClientType.HCP_WEB,
  locale: 'en_gb',
  allowContentPreview: false,
  forceContentPreview: false,
  packageVersions: {
    public: '9_0',
    generic: '9_0',
    study: '1_0',
  },
  allowMockedContent: false,
  validateContent: false,
  hideSchemaValidationMessages: true,
  mockContent: {
    public: {},
    generic: {},
    study: {},
  },
  cacheProvider: undefined,
  role: undefined,
  cachePolicy: CachePolicy.CACHE_AND_NETWORK,
});

export const ContentstackConfig = {
  empty,
};
