/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Location type of appointment
 */
export enum LocationTypeV1 {
    ADDRESS = 'ADDRESS',
    LOCATION = 'LOCATION',
    ORG = 'ORG',
    PHONE = 'PHONE',
    VIDEO = 'VIDEO',
    HOME_VISIT = 'HOME_VISIT',
}