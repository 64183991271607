import { orderBy } from 'lodash';
import { Maybe, ZonedDateTime } from '@csp/csp-common-model';
import { first, last } from 'lodash/fp';
import { RequestWithVersionInfo } from '../model/RequestWithVersionInfo';
import { ScheduleRequestInfo } from '../model/ScheduleRequestInfo';
import { GenericSchedule } from '../model/schedulingModels/GenericSchedule';
import { GenericVersion } from '../model/schedulingModels/GenericVersion';
import { GenericRequest } from '../model/schedulingModels/GenericRequest';
import { DayOneDates } from '../model/DayOneDates';
import { RequestActionDateUtil } from '../util/RequestActionDateUtil';
import { RequestVersionStatuses } from '../model/RequestVersionStatus';
import { ScheduleWindowInterval } from '../model/ScheduleWindowInterval';
import { ScheduleVersionInfo } from '../model/ScheduleVersionInfo';
import { ScheduleIntervalService } from '../service/ScheduleIntervalService';
import { RequestWhileService } from '../service/RequestWhileService';
import { WhileCriteriaUserValues } from '../model/WhileCriteriaUserValues';

const getUserOngoingInterval = (
  allVersionInfos: ScheduleVersionInfo[],
  startActionTimestamp?: ZonedDateTime,
  endActionTimestamp?: ZonedDateTime,
  matchingWhileIntervals?: ScheduleWindowInterval[],
): Maybe<ScheduleWindowInterval> => {
  const allVersionIntervals: ScheduleWindowInterval[] = orderBy(
    allVersionInfos,
    info => info.activeFrom.unixTimeMillis,
  ).map(info => ({ windowStart: info.activeFrom, windowEnd: info.activeTo }));
  if (startActionTimestamp) {
    const startEndActionInterval: ScheduleWindowInterval = {
      windowStart: startActionTimestamp,
      windowEnd: endActionTimestamp,
    };
    const userRequestIntervals = matchingWhileIntervals
      ? ScheduleIntervalService.intersectionOfIntervalLists([startEndActionInterval], matchingWhileIntervals)
      : [startEndActionInterval];

    const allOngoingIntervals = ScheduleIntervalService.intersectionOfIntervalLists(
      allVersionIntervals,
      userRequestIntervals,
    );

    const firstInterval = first(allOngoingIntervals);
    if (firstInterval) {
      return {
        windowStart: firstInterval.windowStart,
        windowEnd: last(allOngoingIntervals)?.windowEnd,
      };
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};

const fromRequestWithVersionInfo = <Request extends GenericRequest>(
  requestWithVersionInfo: RequestWithVersionInfo<Request>,
  dayOneDates: DayOneDates,
  userValues: WhileCriteriaUserValues,
  allRequestsWithVersionInfo: RequestWithVersionInfo<Request>[],
): ScheduleRequestInfo<Request> => {
  const startActionOffsetTimestamp = RequestActionDateUtil.getStartActionOffsetTimestamp(
    requestWithVersionInfo,
    dayOneDates,
  );
  const endActionOffsetTimestamp = RequestActionDateUtil.getEndActionOffsetTimestamp(
    requestWithVersionInfo,
    dayOneDates,
  );

  const allVersionInfos = allRequestsWithVersionInfo
    .filter(
      request =>
        request.scheduleCode === requestWithVersionInfo.scheduleCode &&
        request.requestCode === requestWithVersionInfo.requestCode,
    )
    .map(request => request.versionInfo);

  const matchingWhileIntervals =
    requestWithVersionInfo.while && RequestWhileService.getMatchingIntervals(requestWithVersionInfo.while, userValues);

  const userOngoingInterval = getUserOngoingInterval(
    allVersionInfos,
    startActionOffsetTimestamp,
    endActionOffsetTimestamp,
    matchingWhileIntervals,
  );

  const requestVersionStatus = RequestVersionStatuses.from(requestWithVersionInfo, allRequestsWithVersionInfo);

  return ScheduleRequestInfo.from(
    requestWithVersionInfo,
    requestVersionStatus,
    userOngoingInterval?.windowStart,
    userOngoingInterval?.windowEnd,
  );
};

const fromScheduleAndStartActionDates = <Request extends GenericRequest, Version extends GenericVersion<Request>>(
  schedule: GenericSchedule<Version>,
  dayOneDates: DayOneDates,
  userValues: WhileCriteriaUserValues,
): ScheduleRequestInfo<Request>[] => {
  const versionsDescending = orderBy(
    schedule.activeVersionWindows,
    version => version.activeFrom.unixTimeMillis,
    'desc',
  );
  const flattenedScheduleRequests = versionsDescending.flatMap(version =>
    version.requests.map(request => RequestWithVersionInfo.from(version, request)),
  );
  const requestInfos = flattenedScheduleRequests.map(request =>
    fromRequestWithVersionInfo(request, dayOneDates, userValues, flattenedScheduleRequests),
  );

  if (schedule.groupByScheduleCode) {
    // Merge all requestInfos into 1 requestInfo
    const groupedRequest = ScheduleRequestInfo.merge(requestInfos);
    return groupedRequest ? [groupedRequest] : [];
  } else {
    return requestInfos;
  }
};

const from = <
  Request extends GenericRequest,
  Version extends GenericVersion<Request>,
  Schedule extends GenericSchedule<Version>,
>(
  schedules: Schedule[],
  dayOneDates: DayOneDates,
  userValues: WhileCriteriaUserValues,
): ScheduleRequestInfo<Request>[] =>
  schedules.flatMap(schedule => fromScheduleAndStartActionDates<Request, Version>(schedule, dayOneDates, userValues));

export const ScheduleRequestInfoMapper = {
  from,
};
