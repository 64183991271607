import ListItemIcon from '@mui/material/ListItemIcon';
import MuiMenuItem from '@mui/material/MenuItem/MenuItem';
import { FC } from 'react';
import { Box, SxProps } from '@mui/material';
import { TextProps } from '../../typography/model/TextProps';
import { Body1, Body3, Body2 } from '../../typography/TextStyles';
import { OnClick } from '../../../common/model/EventModels';
import { BrandedIconProps } from '../../brandedIcon/model/BrandedIconProps';
import { BrandedIcon } from '../../brandedIcon/BrandedIcon';

type Props = {
  onClick?: OnClick;
  iconProps?: BrandedIconProps;
  title: string;
  subtitle?: string;
  titleBody?: 'body1' | 'body2';
  titleProps?: TextProps;
  disabled?: boolean;
  sx?: SxProps;
  subtitleLineBreak?: number;
};

export const MenuItem: FC<Props> = ({
  title,
  subtitle,
  onClick,
  disabled,
  iconProps,
  sx,
  subtitleLineBreak = 2,
  titleBody = 'body1',
  titleProps,
}) => {
  const BodyStyled = titleBody === 'body1' ? Body1 : Body2;
  return (
    <MuiMenuItem onClick={onClick} sx={{ ...sx, alignItems: subtitle ? 'flex-start' : undefined }}>
      {iconProps && (
        <ListItemIcon>
          <BrandedIcon size="large" {...iconProps} />
        </ListItemIcon>
      )}
      <Box>
        <BodyStyled noWrap {...titleProps}>
          {title}
        </BodyStyled>
        {subtitle && (
          <Body3 whiteSpace="normal" lineBreak={subtitleLineBreak} color={disabled ? undefined : 'textSecondary'}>
            {subtitle}
          </Body3>
        )}
      </Box>
    </MuiMenuItem>
  );
};
