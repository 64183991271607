export type MobileConfig = {
  androidStoreLink: string;
  iosStoreLink: string;
};

const empty = (): MobileConfig => ({
  androidStoreLink: '',
  iosStoreLink: '',
});

export const MobileConfig = {
  empty,
};
