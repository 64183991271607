/* eslint-disable */
export const UnifyWebTechnicalSupportMock = {
  title: 'title',
  drawerHeader: '[M]Support',
  header: '[M]Do you have techincal issues?',
  description: '[M]Please contact the technical support to get help or visit the FAQs',
  contactInformation: {
    label: '[M]CONTACT',
    phoneNumber: '[M]1-800-236-9933',
    email: '[M]supportemail@astrazeneca.com',
  },
  openingHours: {
    label: '[M]AVAILABILITY',
    weekdays: '[M]Monday - Friday 8 a.m. - 6 p.m.',
    weekend: '[M]Saturday - Sunday 2 p.m. - 6 p.m.',
  },
  trainingMaterials: {
    header: '[M]Do you need Unify training materials',
    description: '[M]Find below the Unify user guide and user training for the study',
    versionPublishedLabel: '[M]Published on',
    linkText: '[M]View',
  },
};
