import { FC } from 'react';
import { Tab as MuiTab, TabProps } from '@mui/material';
import { Property } from 'csstype';
import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';

type Props = TabProps & {
  flexDirection?: Property.FlexDirection;
  isSubTab?: boolean;
};

const StyledTab = styled(MuiTab, {
  shouldForwardProp: prop => prop !== 'isSubTab',
})<Props>`
  ${({ theme: { breakpoints, spacing }, flexDirection, isSubTab }): SerializedStyles => css`
    &.MuiTab-root {
      font-size: ${isSubTab ? '0.875rem' : '1rem'};
      margin-right: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
      min-width: 0;

      &:not(:first-of-type) {
        margin-left: 1rem;
      }

      ${breakpoints.down('sm')} {
        min-width: 5.75rem;
      }
    }
    .MuiTab-iconWrapper {
      margin-right: ${spacing(2)};
    }
    .MuiTab-wrapper {
      flex-direction: ${flexDirection || 'row'};
      ${breakpoints.down('sm')} {
        font-size: 0.875rem;
      }
    }
  `}
`;

export const Tab: FC<Props> = ({ iconPosition = 'start', isSubTab, ...props }) => (
  <StyledTab iconPosition={iconPosition} isSubTab={isSubTab} wrapped {...props} />
);
