import { ApiOptions, OrgId, StateAssert } from '@csp/csp-common-model';
import { QuestionnaireRequestType, QuestionnaireScheduleCachedQueryService } from '@csp/csp-common-questionnaire';
import { OrgService } from '@csp/csp-common-user';
import { ScheduleRequestComplianceRef } from '../../model/ScheduleRequestComplianceRef';
import { ScheduleRequestComplianceSummary } from '../../model/ScheduleRequestComplianceSummary';

const getRequestComplianceRefs = async (
  onlySiteIds?: OrgId[],
  apiOptions?: ApiOptions,
): Promise<ScheduleRequestComplianceSummary> => {
  const siteIds = onlySiteIds?.length ? onlySiteIds : await OrgService.fetchAllSiteIds(apiOptions);

  const allQuestionnaireSchedules =
    await QuestionnaireScheduleCachedQueryService.getAllActivatedQuestionnaireSchedulesForOrganizationIds(
      siteIds,
      apiOptions,
    );

  const requestsWithCompliance: ScheduleRequestComplianceRef[] = allQuestionnaireSchedules
    .getAllRequestsWithOrgId()
    .flatMap(({ requests, orgId }) =>
      requests
        .filter(request => request.requestType === QuestionnaireRequestType.COMPLIANCE)
        .map(({ scheduleCode, requestCode, config }) => {
          StateAssert.isTrue(
            config.minComplianceThreshold !== undefined,
            'Only request with compliance should end up here',
          );

          return {
            siteId: orgId,
            requestRef: {
              scheduleCode,
              requestCode,
            },
            minComplianceThreshold: config.minComplianceThreshold,
          };
        }),
    );

  return {
    requestsWithCompliance,
    allQuestionnaireSchedules: allQuestionnaireSchedules.getAllSchedules(),
  };
};

export const QuestionnaireV2MetricService = {
  getRequestComplianceRefs,
};
