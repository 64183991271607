export enum StudyStatus {
  ACTNOREC = 'ACTNOREC',
  ACTREC = 'ACTREC',
  ARCH = 'ARCH',
  CANCELLED = 'CANCELLED',
  CAND = 'CAND',
  CLOSED = 'CLOSED',
  CLOSING = 'CLOSING',
  CLOSINGTE = 'CLOSINGTE',
  DELETED = 'DELETED',
  INMIGR = 'INMIGR',
  ONHOLD = 'ONHOLD',
  OPENFORREC = 'OPENFORREC',
  INIT = 'INIT',
  POST = 'POST',
  TERM = 'TERM',
  UNKNOWN = 'UNKNOWN',
}

export const RESPONSE_STATUS_TO_STUDY_STATUS_MAP: { [key in StudyStatus]: StudyStatus } = {
  ACTNOREC: StudyStatus.ACTNOREC,
  ACTREC: StudyStatus.ACTREC,
  ARCH: StudyStatus.ARCH,
  CANCELLED: StudyStatus.CANCELLED,
  CAND: StudyStatus.CAND,
  CLOSED: StudyStatus.CLOSED,
  CLOSING: StudyStatus.CLOSING,
  CLOSINGTE: StudyStatus.CLOSINGTE,
  DELETED: StudyStatus.DELETED,
  INMIGR: StudyStatus.INMIGR,
  ONHOLD: StudyStatus.ONHOLD,
  OPENFORREC: StudyStatus.OPENFORREC,
  INIT: StudyStatus.INIT,
  POST: StudyStatus.POST,
  TERM: StudyStatus.TERM,
  UNKNOWN: StudyStatus.UNKNOWN,
};

export const VISIBLE_STUDY_STATUSES: StudyStatus[] = [
  StudyStatus.ACTNOREC,
  StudyStatus.ACTREC,
  StudyStatus.ARCH,
  StudyStatus.CANCELLED,
  StudyStatus.CLOSED,
  StudyStatus.CLOSING,
  StudyStatus.CLOSINGTE,
  StudyStatus.INMIGR,
  StudyStatus.ONHOLD,
  StudyStatus.OPENFORREC,
  StudyStatus.INIT,
  StudyStatus.POST,
];
