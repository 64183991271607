import { fromTimezoneStr, fromTimezoneStrOrUndefined } from '@csp/csp-common-date-util';
import { DeviceObservationAttributes } from '@csp/device-catalog';
import { DeviceObservationV1, ValueV1 } from '@csp/dmdp-api-device-dto';
import { DeviceObservation, DeviceObservationValue } from '../model/DeviceObservation';
import { DeviceObservationRequestRef } from '../model/DeviceObservationRequestRef';
import { DeviceObservationStatus } from '../model/DeviceObservationStatus';
import { DeviceObservationRefs } from './DeviceObservationRefs';

const toDeviceObservationStatus = (status?: string): DeviceObservationStatus => {
  switch (status) {
    case 'UNSCHEDULED':
      return 'UNSCHEDULED';
    case 'SCHEDULED':
      return 'SCHEDULED';
    case 'MISSED':
      return 'MISSED';
    default:
      return 'MISSED';
  }
};

const isDefinedDeviceObservation = (value: ValueV1): boolean => !!DeviceObservationAttributes[value.name];

const deviceObservationValuesOf = (values: ValueV1[]): DeviceObservationValue[] =>
  values.filter(isDefinedDeviceObservation).map(value => value as DeviceObservationValue);

const from = ({
  deviceObservationId,
  eventId,
  refs = [],
  scheduleRequestRef,
  scheduleRequestStatus,
  sensorTimestamp: sensorTimestampStr,
  sessionCode,
  synchronizationTimestamp,
  userDeviceId,
  userId,
  values = [],
}: DeviceObservationV1): DeviceObservation => {
  const status = toDeviceObservationStatus(scheduleRequestStatus);
  return {
    correlationId: DeviceObservationRefs.getCorrelationId(refs),
    eventId,
    id: deviceObservationId,
    missed: status === 'MISSED',
    observationType: DeviceObservationRefs.getObservationType(refs),
    scheduleRequestRef: DeviceObservationRequestRef.from(scheduleRequestRef),
    sensorTimestamp: fromTimezoneStrOrUndefined(sensorTimestampStr),
    sessionCode,
    status,
    synchronizationTimestamp: fromTimezoneStr(synchronizationTimestamp),
    userDeviceId,
    userId,
    values: deviceObservationValuesOf(values),
  };
};

export const DeviceObservationMapper = {
  from,
};
