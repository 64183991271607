/* eslint-disable */
export const UnifyAppFaqQuestionSchema = {
  title: 'UnifyAppFaqQuestion',
  type: 'object',
  properties: {
    title: { type: 'string' },
    categoryHeader: { type: 'string' },
    header: { type: 'string' },
    body: { type: 'string' },
    category: { type: 'string' },
    pdfDownloadButtonText: { type: 'string' },
    pdf: { type: 'object', _attachment: { id: 'pdfAttachment' }, properties: {} },
  },
  _parent: 'n:node',
  description: 'unify:appfaqquestion',
  $schema: 'http://json-schema.org/draft-04/schema#',
  _doc: 'ef3288f93b6926251d2a',
};
