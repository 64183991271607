import { ErrorMessages } from '@csp/csp-common-form';
import { CallbackWith, CspError, UnknownError } from '@csp/csp-common-model';
import { useMemo } from 'react';
import { isError } from '@csp/csp-common-util';
import { useErrorMessages } from './useErrorMessages';

type GetText = CallbackWith<UnknownError, string>;

const getErrorMessage =
  (errorMessages: ErrorMessages): GetText =>
  (error: UnknownError): string => {
    const unexpectedErrorMessage = errorMessages.unexpected ?? '';

    if (CspError.isCspError(error)) {
      return errorMessages[error.type] ?? unexpectedErrorMessage;
    } else if (unexpectedErrorMessage) {
      return unexpectedErrorMessage;
    } else {
      return isError(error) ? error.message : '';
    }
  };

export const useGetErrorMessage = (additionalErrorMessages?: ErrorMessages): GetText => {
  const errorMessages = useErrorMessages(additionalErrorMessages);
  return useMemo(() => getErrorMessage(errorMessages), [errorMessages]);
};
