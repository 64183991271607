/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AssessmentSortFieldV1 {
    EVENT_ID = 'EVENT_ID',
    USER_ID = 'USER_ID',
    ASSESSMENT_ID = 'ASSESSMENT_ID',
    ASSESSMENT_CODE = 'ASSESSMENT_CODE',
    ASSESSMENT_TYPE = 'ASSESSMENT_TYPE',
    ALGORITHM_CODE = 'ALGORITHM_CODE',
    CREATED_AT = 'CREATED_AT',
    OUTCOME_VALUE = 'OUTCOME_VALUE',
    CUSTOM_STATUS_VALUE = 'CUSTOM_STATUS_VALUE',
}