/* eslint-disable */
export const UnifyWebPatientEcoaStudyMock = {
  title: 'title',
  ecoaInformations: [
    {
      questionnaireCode: 'EQ-5D-5L',
      descriptionBodyMd:
        'Patients are asked to complete EQ-5D-5L questionnaire to provide their overall status of health.',
      scheduleBodyMd: 'The questionnaire is scheduled once per month (each 28 days, with the window -/+ 7 days).',
    },
    {
      questionnaireCode: 'EXAMPLE_QUESTIONNAIRE_V1-1',
      descriptionBodyMd: 'This is a test questionnaire with tpe Ecoa using version 2 of the API',
      scheduleBodyMd: 'Experimental questionnaire V2',
      thresholdBodyMd:
        'A threshold explanation for the specific questionnaire, visible to web users in the portal on the learn more section.',
      complianceCalculationBodyMd:
        'An explanation on how compliance is calculated, visible to web users in the portal on the learn more section. Total compliance is calculated according to the following formula. Number of completed questionnaires / number of maximum expected questionnaires from start of study for the patient until last time the web received responses from the app. Recent time period calculates compliance in the same way, but for tha last X day/week/months completed time period instead of the total period.',
    },
    {
      questionnaireCode: 'experience_diary_v1.0',
      descriptionBodyMd: 'This is a test questionnaire with tpe Diary using version 2 of the API.',
      scheduleBodyMd: 'Experimental questionnaire V2 diary',
      thresholdBodyMd:
        'A threshold explanation for the specific questionnaire, visible to web users in the portal on the learn more section.',
      complianceCalculationBodyMd:
        'An explanation on how compliance is calculated, visible to web users in the portal on the learn more section. Total compliance is calculated according to the following formula. Number of completed questionnaires / number of maximum expected questionnaires from start of study for the patient until last time the web received responses from the app. Recent time period calculates compliance in the same way, but for tha last X day/week/months completed time period instead of the total period.',
    },
  ],
  learnMore: {
    labelsAndDescriptions: [
      { label: 'Study specific learn more title', bodyMd: 'Study specific learn more description' },
    ],
  },
};
