import { ReportsConfigV1, ReportsConfigV1Schema } from '@csp/config-schemas';
import { ReportsConfig } from '@csp/csp-common-model';
import { JsonValidationService } from '@csp/csp-common-util';

const toReportsConfig = (reportsConfigV1: ReportsConfigV1): ReportsConfig => {
  JsonValidationService.validateJsonNonStrict('ReportsConfigV1.json', ReportsConfigV1Schema, reportsConfigV1);

  return {
    patientReportedDataVisualizationsReportUrl: reportsConfigV1?.patientReportedDataVisualizations?.externalReportUrl,
    userDetailsReportUrl: reportsConfigV1?.userDetails?.externalReportUrl,
    dataChangeSummaryReportUrl: reportsConfigV1?.dataChangeSummary?.externalReportUrl,
  };
};

export const ReportsConfigService = {
  toReportsConfig,
};
