import { ContentConfigService, ContentLocaleType } from '@csp/csp-common-content';
import { CspError, CspErrorType } from '@csp/csp-common-model';
import { JSONSchema } from '@csp/csp-common-util';
import { CmsContentArguments, CmsContentStatus } from '@csp/csp-fe-content';
import { cloneDeep, isArray, isObject, memoize, mergeWith } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InitializationService } from '../../../system/service/InitializationService';
import { useUserLocale } from '../../userpod/user/hook/useUserLocale';
import { CmsHookConfig } from '../model/CmsQueryHookConfig';
import { cmsActions } from '../redux/cmsReducer';
import { getCmsState, getContentForQuery } from '../redux/cmsSelectors';
import { getCmsKey } from '../redux/cmsUtils';
import { memoizedValidateSchema } from '../utils/validateCmsSchema';
import { memoizedToDebugContent } from '../utils/toDebugContent';

export type CmsBaseType = {
  _doc?: string;
  _system?: {
    modified_on: {
      iso_8601: string;
    };
    changeset: string;
  };
  $schema?: string;
};

export type CmsHookResult<T> = {
  status: CmsContentStatus;
  loading: boolean;
  ready: boolean;
  data?: T;
};

const mergeCustomizer = (mockedValue: unknown, fetchedValue: unknown): unknown => {
  if (isArray(mockedValue) && isArray(fetchedValue)) {
    return fetchedValue;
  }
  if (isObject(mockedValue)) {
    return mergeWith(mockedValue, fetchedValue, mergeCustomizer);
  }
  return fetchedValue || mockedValue;
};

const memoizedMergeData = memoize(
  (_: CmsContentArguments, defaultData, cmsData) => mergeWith(cloneDeep(defaultData), cmsData, mergeCustomizer),
  getCmsKey,
);

const rtlLocales = ['he_ZZ', 'he_IL'];
// TODO (Sebastian B): This whole conversion function can be removed if the new CMS system can handle \u200F
/**
 * Provided CMS data will be modified to remove extra escaping '\' from '\\u200F' if present, turning \\u200F into \u200F
 * '\u200F' is used as an RTL mark to make RTL sentences be intepreted as RTL even if they start with LTR characters.
 * Ex. '[hebrew characters] Unify [Hebrew characters]' will work without this, but 'Unify [Hebrew characters]' will not.
 * Adding '\u200F' in the beginning will solve this issue (Ex. '\u200FUnify [Hebrew characters]')
 * A limitation in the current CMS system causes '\u200F' to be converted to '\\u200F' making this script necessary.
 * @param data CMS data containing all strings
 * @param locale The logged in User's current locale (will determine if the data will need to be modified at all)
 * @returns A \u200F modified version of the CMS data, if an update is necessary, and the original data it not
 */
const memoizeParseRtlMarks = memoize((data: CmsContentArguments, locale = '') => {
  if (data && rtlLocales.indexOf(locale) !== -1) {
    try {
      data = JSON.parse(JSON.stringify(data).replace(/\\\\u200F/g, '\\u200F'));
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }
  return data;
}, getCmsKey);

export const useCmsQuery = <T>(
  contentType: string,
  {
    locale,
    limit,
    customProperties,
    returnAllRows,
    tag,
    defaultData,
    cacheOnly,
    contentSourceType,
    enabled,
    debug,
  }: CmsHookConfig<T>,
  schema?: unknown,
): CmsHookResult<T> => {
  // Allow enabled to be undefined and if so default to enabled=true
  const evaluatedEnabled = enabled !== false;
  const dispatch = useDispatch();
  const userLocale = useUserLocale();

  // TODO Make these defaults dynamically configurable, be the same for all queries but allow the user switch
  // language/study etc
  const cmsContentArguments = useMemo<CmsContentArguments>(
    () => ({
      contentSourceType,
      contentType,
      limit,
      customProperties,
      locale: locale || userLocale || ContentLocaleType.EN_GB,
      returnAllRows: !!returnAllRows,
    }),
    [contentSourceType, contentType, customProperties, limit, locale, returnAllRows, userLocale],
  );

  if (!InitializationService.hasBeenInitialized()) {
    console.warn(`InitializationService.init has not been run before query ${cmsContentArguments}`);
  }

  const stateData = useSelector(state =>
    getContentForQuery(getCmsState(InitializationService.commonSelector(state)), cmsContentArguments),
  );

  useEffect(() => {
    const shouldRunQuery = !stateData && !cacheOnly && evaluatedEnabled;

    if (shouldRunQuery) {
      dispatch(cmsActions.getCmsContent({ cmsContentArguments, tag }));
    } else if (!stateData && cacheOnly && evaluatedEnabled) {
      throw new CspError(
        CspErrorType.NOT_FOUND,
        `Could not find content type: ${cmsContentArguments.contentType} in cache, using cacheKey: ${getCmsKey(
          cmsContentArguments,
        )}`,
      );
    }
  }, [cacheOnly, cmsContentArguments, dispatch, stateData, tag, evaluatedEnabled]);

  if (!evaluatedEnabled) {
    return { status: CmsContentStatus.DISABLED, data: undefined, loading: false, ready: false };
  }

  let status = stateData?.status || CmsContentStatus.PENDING;
  let data = stateData?.data;

  if (schema !== undefined && data !== undefined) {
    memoizedValidateSchema(schema as JSONSchema, data);
  }

  const allowMockedContent = ContentConfigService.get().allowMockedContent;
  // Merge cms response on mock data when request has finished
  if (
    allowMockedContent &&
    defaultData &&
    (status === CmsContentStatus.SUCCESS || status === CmsContentStatus.FAILED)
  ) {
    data = memoizedMergeData(cmsContentArguments, defaultData, stateData?.data);
    status = CmsContentStatus.MOCKED;
  }

  // TODO (Sebastian B): This can be removed if the new CMS system can handle \u200F
  if (data) {
    data = memoizeParseRtlMarks(data, userLocale);
  }

  if (debug && data) {
    data = memoizedToDebugContent(data, contentType);
  }

  return {
    status,
    data,
    loading: status === CmsContentStatus.PENDING,
    ready: !!data,
  };
};
