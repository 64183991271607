import {
  BrandedIconSize,
  FlexColumn,
  FlexRow,
  H3,
  IconButton,
  MaxWidth,
  SystemProps,
  useMediaQ,
} from '@csp/csp-web-ui';
import styled from '@emotion/styled';
import { FC, ReactNode } from 'react';
import { BackAndCloseButtonProps } from '../model/BackAndCloseButtonProps';
import { HeaderNavBackButton } from './HeaderNavBackButton';
import { HeaderNavCloseButton } from './HeaderNavCloseButton';

const ImageDiv = styled.div`
  margin: auto;
`;

const Subtitle = styled.div`
  color: ${({ theme: { palette } }): string => `${palette.grey[500]}`};
  line-height: 0.85;
`;

type LogoProp = { size: BrandedIconSize };

type SizingAndBreakpoints = {
  fontSize?: string;
  smDown?: boolean;
  smUp?: boolean;
  width?: MaxWidth | string | number;
} & Pick<SystemProps, 'ml'>;

const Logo: FC<LogoProp> = ({ size }) => <IconButton disabled iconProps={{ name: 'unifyLogoOnly', size }} />;

type Props = BackAndCloseButtonProps & {
  title?: ReactNode;
  subtitle?: ReactNode;
};

const HeaderNavTitle: FC<Props & LogoProp & SizingAndBreakpoints> = ({
  fontSize,
  ml,
  onBackButtonClick,
  onCloseButtonClick,
  replaceBackButtonWithCloseButton = false,
  replaceLogoWithBackButton,
  size,
  smDown,
  smUp,
  subtitle,
  title,
  width,
}) => (
  <FlexRow>
    <ImageDiv>
      {replaceLogoWithBackButton ? (
        replaceBackButtonWithCloseButton ? (
          <HeaderNavCloseButton onCloseButtonClick={onCloseButtonClick} size={size} />
        ) : (
          <HeaderNavBackButton onBackButtonClick={onBackButtonClick} size={size} />
        )
      ) : (
        <Logo size={size} />
      )}
    </ImageDiv>
    <FlexColumn ml={ml} flexWrap="nowrap" justifyContent="center">
      {smUp ? <Subtitle>{subtitle}</Subtitle> : null}
      <H3 style={{ fontSize: smDown ? '1rem' : fontSize }} width={width} noWrap>
        {title}
      </H3>
    </FlexColumn>
  </FlexRow>
);

export const HeaderNavTitleBox: FC<Props> = props => {
  const smDown = useMediaQ('sm', 'down');
  const smUp = useMediaQ('sm', 'up');

  return (
    <HeaderNavTitle
      ml={{ sm: 7, xs: 1 }}
      size={smDown ? 'large' : 'xl'}
      smDown={smDown}
      smUp={smUp}
      width={{ xs: '7rem', sm: '15rem', md: '37rem', lg: '55rem' }}
      {...props}
    />
  );
};

export const HeaderNavTitleBoxLarge: FC<Props> = props => (
  <HeaderNavTitle ml={7} size="xl" smDown={false} smUp={true} fontSize="1.5rem" width="55rem" {...props} />
);
