import MuiBox from '@mui/material/Box';
import { FC, Ref, forwardRef } from 'react';
import { BoxProps } from './model/BoxProps';

export const FlexRow: FC<BoxProps> = props => <MuiBox display="flex" {...props} />;

export const FlexColumn: FC<BoxProps> = props => <MuiBox display="flex" flexDirection="column" {...props} />;

export const Box = forwardRef((props: BoxProps, ref: Ref<unknown>) => <MuiBox ref={ref} {...props} />);
Box.displayName = 'Box';
