import { CmsHookResult, useCmsQuery } from '@csp/common';
import { UnifyWebTrainingDocument, UnifyWebTrainingDocumentSchema } from '@csp/csp-content-schema';
import { toHookConfig } from '../../util/toHookConfig';

export const useCmsWebTrainingDocuments = (): CmsHookResult<UnifyWebTrainingDocument[]> =>
  useCmsQuery<UnifyWebTrainingDocument[]>(
    'unify:webtrainingdocument',
    toHookConfig({ defaultData: [], returnAllRows: true }),
    UnifyWebTrainingDocumentSchema,
  );
