import { RpmAssessmentService } from '@csp/csp-common-assessment';
import { minutesToMillis } from '@csp/csp-common-date-util';
import { Maybe } from '@csp/csp-common-model';
import { isNotEmpty } from '@csp/csp-common-util';
import { useCallback } from 'react';
import { useQueryContext } from '../../../../common/context/query/useQueryContext';
import { useQuery } from '../../../../common/query/hooks/useQuery';
import { useRpmConfigAlgorithmCodes } from '../../../patient-rpm-events/rpm-events-shared/hook/useRpmConfigAlgorithmCodes';

const cacheKey = 'rpm-events-not-viewed';

type Out = {
  clearData(): void;
  totalRpmEventsNotViewed: Maybe<number>;
};

export const useQueryTotalRpmEventsNotViewed = (): Out => {
  const { clearData: clearDataByCacheKey } = useQueryContext();
  const algorithmCodes = useRpmConfigAlgorithmCodes();

  const {
    data: totalNotViewed,
    error,
    isLoadingInitial,
  } = useQuery(
    { cacheKey, cacheParams: [...algorithmCodes] },
    () => RpmAssessmentService.queryTotalNrNotViewed(algorithmCodes),
    {
      staleTimeMillis: minutesToMillis(30),
      enabled: isNotEmpty(algorithmCodes),
    },
  );

  const clearData = useCallback(() => clearDataByCacheKey(cacheKey), [clearDataByCacheKey]);

  return {
    clearData,
    totalRpmEventsNotViewed: error || isLoadingInitial || totalNotViewed === 0 ? undefined : totalNotViewed,
  };
};
