import { DeviceObservationName } from './DeviceObservationName';
import { DeviceSpirometryObservationName } from './observation-value-names/DeviceSpirometryObservationValueNames';

export const DeviceSpirometryObservationAttributes: Readonly<Record<string, DeviceSpirometryObservationName>> = {
  FVC_Curve: 'FVC_Curve',
  LLNValues: 'LLNValues',
  VT_Curve: 'VT_Curve',
  ampliFlow: 'ampliFlow',
  fef2575_Ls: 'fef2575_Ls',
  fef25_Ls: 'fef25_Ls',
  fef50_Ls: 'fef50_Ls',
  fef7585_Ls: 'fef7585_Ls',
  fef75_Ls: 'fef75_Ls',
  fet_cs: 'fet_cs',
  fev05_L: 'fev05_L',
  fev05_fvc_perc: 'fev05_fvc_perc',
  fev075_L: 'fev075_L',
  fev075_fvc_perc: 'fev075_fvc_perc',
  fev1_L: 'fev1_L',
  fev1_cL: 'fev1_cL',
  fev1_fev6_perc: 'fev1_fev6_perc',
  fev1_fvc_pcnt: 'fev1_fvc_pcnt',
  fev2_L: 'fev2_L',
  fev2_fvc_perc: 'fev2_fvc_perc',
  fev3_L: 'fev3_L',
  fev3_fvc_perc: 'fev3_fvc_perc',
  fev6_L: 'fev6_L',
  fev6_fvc_perc: 'fev6_fvc_perc',
  fif25_Ls: 'fif25_Ls',
  fif75_Ls: 'fif75_Ls',
  fiv1_L: 'fiv1_L',
  fiv1_fivc_perc: 'fiv1_fivc_perc',
  fivc_L: 'fivc_L',
  fvc_L: 'fvc_L',
  highResolutionResult: 'highResolutionResult',
  pef_Ls: 'pef_Ls',
  pif_Ls: 'pif_Ls',
  qualityCode: 'qualityCode',
  qualityReport: 'qualityReport',
  rawData: 'rawData',
  stepVol_mL: 'stepVol_mL',
  targetValues: 'targetValues',
  targetValuesPercent: 'targetValuesPercent',
  testType: 'testType',
  zScoreValues: 'zScoreValues',
};

export const DeviceObservationAttributes: Readonly<Record<string, DeviceObservationName>> = {
  ...DeviceSpirometryObservationAttributes,
  ACTUATION: 'ACTUATION',
  AVERAGE_RESPIRATION_RATE_FROM_PLETH: 'AVERAGE_RESPIRATION_RATE_FROM_PLETH',
  AVG_PULSE_RATE: 'AVG_PULSE_RATE',
  AVG_SPO2: 'AVG_SPO2',
  BMI: 'BMI',
  DIASTOLIC_PRESSURE: 'DIASTOLIC_PRESSURE',
  DURATION: 'DURATION',
  GROSS_WEIGHT: 'GROSS_WEIGHT',
  HEART_RATE: 'HEART_RATE',
  HEIGHT: 'HEIGHT',
  LAST_PULSE_RATE: 'LAST_PULSE_RATE',
  LAST_RESPIRATION_RATE_FROM_PLETH: 'LAST_RESPIRATION_RATE_FROM_PLETH',
  LAST_SPO2: 'LAST_SPO2',
  MAX_PULSE_RATE: 'MAX_PULSE_RATE',
  MAX_RESPIRATION_RATE_FROM_PLETH: 'MAX_RESPIRATION_RATE_FROM_PLETH',
  MAX_SPO2: 'MAX_SPO2',
  MEAN_ARTERIAL_PRESSURE: 'MEAN_ARTERIAL_PRESSURE',
  MIN_PULSE_RATE: 'MIN_PULSE_RATE',
  MIN_RESPIRATION_RATE_FROM_PLETH: 'MIN_RESPIRATION_RATE_FROM_PLETH',
  MIN_SPO2: 'MIN_SPO2',
  NET_WEIGHT: 'NET_WEIGHT',
  PERFUSION_INDEX: 'PERFUSION_INDEX',
  PULSE_RATE: 'PULSE_RATE',
  SPO2: 'SPO2',
  SYSTOLIC_PRESSURE: 'SYSTOLIC_PRESSURE',
  TARE_WEIGHT: 'TARE_WEIGHT',
  eVol_mL: 'eVol_mL',
  pefTime_ms: 'pefTime_ms',
  pef_cLs: 'pef_cLs',
  timestamp: 'timestamp',
  heart_rate: 'heart_rate',
  respiration_rate: 'respiration_rate',
  rmssd: 'rmssd',
  flash: 'flash',
  ecg: 'ecg',
};
