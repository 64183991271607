/* eslint-disable */
export const UnifyWebPatientTrainingSchema = {
  title: 'UnifyWebPatientTraining',
  type: 'object',
  properties: {
    viewMoreLabel: { title: 'View More Label', type: 'string', mock: 'View more (${nrOfTrainingItems})' },
    title: { type: 'string', mock: 'Training Status' },
    outdatedLabel: { title: 'Outdated Label', type: 'string', mock: 'Older Version' },
    completionTime: { title: 'Completion Time Label', type: 'string', mock: 'Completion Time' },
    noData: { title: 'No Data Label', type: 'string', mock: 'No Data' },
    genericTrainingTitle: { title: 'Generic Training Title', type: 'string', mock: 'Patient Unify training' },
    trainingType: { title: 'Training Type', type: 'string', mock: 'Type' },
    trainingTitle: { title: 'Training Title', type: 'string', mock: 'Name' },
  },
  description: 'unify:webpatienttraining',
  $schema: 'http://json-schema.org/draft-07/schema#',
};
