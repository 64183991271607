import { RequestUserStatus, RequestVersionStatus } from '@csp/csp-common-scheduling';
import { DeviceObservationScheduleRequestInfo } from '../model/DeviceObservationScheduleRequestInfo';

const VISIBLE_DEVICE_REQUEST_VERSIONS_STATUSES: RequestVersionStatus[] = [
  RequestVersionStatus.ACTIVE,
  RequestVersionStatus.EXPIRED,
];
const VISIBLE_DEVICE_USER_REQUEST_STATUSES: RequestUserStatus[] = [
  RequestUserStatus.ONGOING,
  RequestUserStatus.DONE,
  RequestUserStatus.EXPIRED,
];

const hasVisibleStatus = ({ requestUserStatus, requestVersionStatus }: DeviceObservationScheduleRequestInfo): boolean =>
  VISIBLE_DEVICE_USER_REQUEST_STATUSES.includes(requestUserStatus) &&
  VISIBLE_DEVICE_REQUEST_VERSIONS_STATUSES.includes(requestVersionStatus);

const filterOnlyVisibleStatuses = (
  requests: DeviceObservationScheduleRequestInfo[],
): DeviceObservationScheduleRequestInfo[] => requests.filter(hasVisibleStatus);

export const DeviceRequestUtil = {
  filterOnlyVisibleStatuses,
};
