import { createAxios } from '@csp/csp-common-axios';
import { AxiosInstance, AxiosResponse } from 'axios';
import { notModifiedErrorResponseInterceptor } from '../util/notModifiedErrorResponseInterceptor';

export class AxiosPublicService {
  private static readonly instance: AxiosPublicService = new AxiosPublicService();

  constructor(private readonly axiosInstance: AxiosInstance = createAxios()) {
    this.axiosInstance.interceptors.response.use((resp: AxiosResponse) => resp, notModifiedErrorResponseInterceptor);
  }

  static axios(): AxiosInstance {
    return AxiosPublicService.instance.axiosInstance;
  }
}
