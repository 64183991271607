import { CspError } from '../fault/error/CspError';
import { TimeStr } from './TimeStr';

export type Time = {
  hours: number;
  minutes: number;
};

const validationRegex = /^\d{1,2}:\d{1,2}$/;

const compare = (a: Time, b: Time): number => {
  if (a.hours > b.hours) {
    return 1;
  } else if (a.hours < b.hours) {
    return -1;
  } else {
    if (a.minutes > b.minutes) {
      return 1;
    } else if (a.minutes < b.minutes) {
      return -1;
    } else {
      return 0;
    }
  }
};

const from = (time: TimeStr): Time => {
  if (!validationRegex.test(time)) {
    throw CspError.badRequest(`Invalid TimeStr not on format 'hh:mm' for '${time}'`);
  }

  const [hours, minutes] = time.split(':').map(t => parseInt(t, 10));

  if (hours == null || hours > 23 || hours < 0) {
    throw CspError.badRequest(`Invalid TimeStr '${time}' for hours '${hours}'`);
  }

  if (minutes == null || minutes > 59 || minutes < 0) {
    throw CspError.badRequest(`Invalid TimeStr '${time}' for minutes '${minutes}'`);
  }

  return {
    hours,
    minutes,
  };
};

export const Time = {
  from,
  compare,
};
