import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { CspPath } from '../../../CspPath';
import { BioSampleCodeInputContextProvider } from '../../hbs/context/hbs-biosample-code-input-context/BioSampleCodeInputContextProvider';
import { PathUtil } from '../util/PathUtil';

const BioSampleCollectPage = React.lazy(
  () => import(/* webpackPrefetch: true */ '../../hbs/hbs-collection/page/BioSampleCollectPage'),
);
const BioSampleAliquotPage = React.lazy(
  () => import(/* webpackPrefetch: true */ '../../hbs/hbs-collection/page/BioSampleAliquotPage'),
);
const BioSampleProcessPage = React.lazy(
  () => import(/* webpackPrefetch: true */ '../../hbs/hbs-processing/page/BioSampleProcessPage'),
);
const BioSampleShippingPage = React.lazy(
  () => import(/* webpackPrefetch: true */ '../../hbs/hbs-shipping/page/BioSampleShippingPage'),
);

const toRelativePath = (absolutePath: CspPath): string => PathUtil.toRelativePath(CspPath.HBS, absolutePath);

export const BioSamplingRoutes: FC = () => (
  <BioSampleCodeInputContextProvider>
    <Routes>
      <Route path={toRelativePath(CspPath.HBS_COLLECT_SAMPLES)} element={<BioSampleCollectPage />} />
      <Route path={toRelativePath(CspPath.HBS_ALIQUOT_SAMPLES)} element={<BioSampleAliquotPage />} />
      <Route path={toRelativePath(CspPath.HBS_PROCESS_SAMPLES)} element={<BioSampleProcessPage />} />
      <Route path={toRelativePath(CspPath.HBS_SHIP_SAMPLES)} element={<BioSampleShippingPage />} />
    </Routes>
  </BioSampleCodeInputContextProvider>
);
