import { CspError, ErrorArg, toErrorInfo } from '@csp/csp-common-model';
import { omitUndefined } from '@csp/csp-common-util';
import { TelemetryService } from '@csp/csp-web-telemetry';

const handleError = ({ error, verbose, context }: ErrorArg): void => {
  if (!CspError.isCancelled(error) && !CspError.isHttpCommunication(error)) {
    const errorInfo = toErrorInfo({ error });
    if (errorInfo.reason) {
      if (verbose) {
        console.debug(errorInfo.reason);
      } else {
        console.debug(errorInfo.reasonMessage);
      }
    }
    TelemetryService.trackException({
      error,
      context: {
        extra: {
          ...context,
        },
        tags: omitUndefined({
          errorCode: errorInfo.code,
          errorId: errorInfo.id,
        }),
      },
    });
  }
};

export const ErrorService = { handleError };
