/* eslint-disable */
export const UnifyWebDevicesSchema = {
  description: 'unify:webdevices',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'UnifyWebDevices',
  type: 'object',
  properties: {
    noDevices: { type: 'string', title: 'No devices text', mock: 'No devices.' },
    noAppUser: { type: 'string', title: 'Text shown when no app user', mock: 'This patient is not a Unify app user' },
    emptyPage: {
      type: 'object',
      title: 'Empty Page for navigating to feature toggled devices tab',
      properties: {
        disabledInCountry: {
          type: 'object',
          title: 'device overview card disabled in country',
          properties: {
            title: {
              type: 'string',
              title: 'Devices disabled in country text',
              mock: 'Devices are disabled in your country',
            },
          },
        },
        disabledOnSite: {
          type: 'object',
          title: 'Devices disabled on site text',
          properties: {
            title: {
              type: 'string',
              title: 'Devices disabled on site text',
              mock: 'Devices are disabled on your site',
            },
          },
        },
      },
    },
    navigation: {
      type: 'object',
      title: 'Texts for device details navigation bar',
      properties: { title: { type: 'string', title: 'Device details text', mock: 'Device details' } },
    },
    requestList: {
      type: 'object',
      title: 'Texts specific for list of device requests',
      properties: {
        ongoingRequestsTitle: { type: 'string', title: 'List section header Ongoing requests', mock: 'Ongoing' },
        expiredRequestsTitle: { type: 'string', title: 'List section header Expired requests', mock: 'Expired' },
      },
    },
    learnMore: {
      type: 'object',
      title: 'General texts for Learn More Devices',
      properties: {
        titles: {
          type: 'object',
          title: 'Learn more titles',
          properties: {
            drawerTitle: { type: 'string', title: 'Title in Learn More drawer', mock: 'Learn More' },
            descriptionTitle: { type: 'string', title: 'Title for description section', mock: 'Description' },
            scheduleInfoTitle: { type: 'string', title: 'Title for schedule info section', mock: 'Schedule' },
          },
        },
        buttons: {
          type: 'object',
          title: 'Learn more buttons',
          properties: {
            learnMoreButton: { type: 'string', title: 'Text for the Learn More open button', mock: 'Learn More' },
          },
        },
      },
    },
    scheduleInfo: {
      type: 'object',
      title: 'device schedule information',
      properties: {
        enum: {
          type: 'object',
          title: 'device schedule information enumerations',
          properties: {
            deviceActionTypes: {
              type: 'object',
              title: 'device start and end action types enum DEPRECATED',
              description:
                'enumeration of the device activation triggers. When patient reaches this phase or event, the device schedule will start for the patient',
              properties: {
                RANDOMIZATION: { type: 'string', title: 'Randomization action type DEPRECATED', mock: 'Randomization' },
                SCREENING: { type: 'string', title: 'Screening action type DEPRECATED', mock: 'Screening' },
                ACTIVATION: {
                  type: 'string',
                  title: 'Activation action type DEPRECATED',
                  description: 'ACTIVATION occurs when the patient has enrolled in the app',
                  mock: 'Activation',
                },
              },
            },
            actionOffsetUnitOfTime: {
              type: 'object',
              title: 'device request action offsets unit of time enumeration',
              properties: {
                S: { type: 'string', title: 'Unit of time: seconds', mock: 'seconds' },
                MIN: { type: 'string', title: 'Unit of time: minutes', mock: 'minutes' },
                H: { type: 'string', title: 'Unit of time: hours', mock: 'hours' },
                D: { type: 'string', title: 'Unit of time: days', mock: 'days' },
                WK: { type: 'string', title: 'Unit of time: weeks', mock: 'weeks' },
                MO: { type: 'string', title: 'Unit of time: months', mock: 'months' },
                A: { type: 'string', title: 'Unit of time: years', mock: 'years' },
              },
            },
          },
        },
      },
    },
    deviceCard: {
      type: 'object',
      title: 'Texts for device cards in list / single device details page.',
      properties: {
        modelNameLabel: { type: 'string', title: 'The model label in the single device details card', mock: 'Model' },
        batteryPercentageLabel: {
          type: 'string',
          title: 'The battery percentage label in the single device details card',
          mock: 'Battery level',
        },
        firmwareLabel: {
          type: 'string',
          title: 'The firmware label in the single device details card',
          mock: 'Firmware',
        },
        lastSyncLabel: { type: 'string', title: 'Label for the last synchronization timestamp', mock: 'Last synced' },
        unregisterLabel: {
          type: 'string',
          title: 'Label for the unregister action on device card',
          mock: 'Unregister',
        },
        unregisterDialog: {
          type: 'object',
          title: 'Unregister device confirmation dialog texts',
          properties: {
            modalHeader: {
              type: 'string',
              title: 'Header for the unregister device confirmation dialog',
              mock: 'Are you sure?',
            },
            modalDescription: {
              type: 'string',
              title: 'Description for the unregister device confirmation dialog',
              mock: 'By unregistering this device you are confirming it has been returned by the patient and is no longer in use.',
            },
            confirmUnregister: { type: 'string', title: 'Confirm Unregister button label', mock: 'Unregister' },
            cancelUnregister: { type: 'string', title: 'Cancel Unregister button label', mock: 'Go Back' },
            alert: {
              type: 'object',
              title: 'Alert unregister device',
              properties: {
                unhandledError: {
                  type: 'string',
                  title: 'Unexpected error occurred',
                  mock: 'Unexpected error occurred',
                },
              },
            },
            snackbar: {
              type: 'object',
              title: 'Snackbar unregister device texts',
              properties: {
                unregisterDeviceSuccess: {
                  type: 'string',
                  title: 'Success message when the device has been unregistered',
                  helper: 'Snackbar message after the patient device has been successfully unregistered',
                  mock: 'You set ${deviceName} to Returned',
                },
              },
            },
          },
        },
        userDevicesList: {
          type: 'object',
          title: 'UserDevices list texts',
          properties: {
            modelHeader: { type: 'string', title: 'Header title for the model name', mock: 'Model' },
            serialNumberHeader: { type: 'string', title: 'Header title for the serial number', mock: 'Serial Number' },
            batteryLevelHeader: { type: 'string', title: 'Header title for the battery level', mock: 'Battery level' },
            firmwareHeader: { type: 'string', title: 'Header title for the firmware', mock: 'Firmware' },
            statusHeader: { type: 'string', title: 'Header title for the status', mock: 'Status' },
          },
        },
        button: {
          type: 'object',
          title: 'Buttons for device request card',
          properties: {
            seeDetails: {
              type: 'string',
              title: 'See details button text to navigate to device request details',
              mock: 'See details',
            },
          },
        },
        dataUse: {
          type: 'object',
          title: 'Device data use card',
          properties: {
            title: { type: 'string', title: 'Data use card title', mock: 'Data use' },
            text: {
              type: 'string',
              title: 'Data use card text',
              mock: 'The summary information provided below is not intended to aid in treatment or diagnosis and should not be used to inform clinical decision making.',
            },
          },
        },
        deviceRequestStatus: {
          type: 'object',
          title: 'device schedule request status',
          properties: {
            enum: {
              type: 'object',
              title: 'device request status types',
              properties: {
                DONE: { type: 'string', title: 'Device request has ended', mock: 'Ended' },
                UPCOMING: { type: 'string', title: 'Request is scheduled to start in the future', mock: 'Upcoming' },
                ONGOING: { type: 'string', title: 'Request is currently ongoing', mock: 'Ongoing' },
                NOT_APPLICABLE: {
                  type: 'string',
                  title: 'Request is not scheduled ',
                  description: 'This happens if the start action for the schedule has not been reached',
                  mock: 'Not applicable',
                },
              },
            },
          },
        },
        statusTooltips: {
          type: 'object',
          title: 'status tooltips in devices tab',
          properties: {
            REGISTERED: {
              type: 'string',
              title: 'Registered device status tooltip',
              mock: 'Notifications are being sent to the patient to take measurements according to the schedule defined in the protocol.',
            },
            RETURNED: {
              type: 'string',
              title: 'Returned device status tooltip',
              mock: 'The device has been returned by the patient and reminders to take measurements have been stopped. To restart measurements provide the patient with a device and ask them to pair their device to the Unify App.',
            },
            NOT_REGISTERED: {
              type: 'string',
              title: 'Not Registered device status tooltip',
              mock: 'Notifications are not being sent to the patient to take measurement according to the schedule defined in the protocol. Pair the device to the Unify App. If this fails to register the device review the FAQs and try again or contact the technical support desk.',
            },
          },
        },
        deviceStatus: {
          type: 'object',
          title: 'Texts for device status in devices tab',
          properties: {
            REGISTERED: { type: 'string', title: 'Registered device status', mock: 'Registered' },
            RETURNED: { type: 'string', title: 'Returned device status', mock: 'Returned' },
            NOT_REGISTERED: { type: 'string', title: 'Not Registered device status', mock: 'Not registered' },
          },
        },
        batteryStatus: {
          type: 'object',
          title: 'Texts for battery status in devices tab',
          properties: {
            HIGH: { type: 'string', title: 'battery status text when battery level is high', mock: 'High' },
            MEDIUM: { type: 'string', title: 'battery status text when battery level is medium', mock: 'Medium' },
            LOW: { type: 'string', title: 'battery status text when battery level is low', mock: 'Low' },
            NOT_SUPPORTED: {
              type: 'string',
              title: 'battery status text when battery level is not supported',
              mock: 'Not supported',
            },
          },
        },
        disabledInCountry: {
          type: 'object',
          title: 'Devices Disabled in country texts',
          properties: {
            title: { type: 'string', title: 'Devices card header', mock: 'Devices' },
            body: {
              type: 'string',
              title: 'Devices disabled in country text',
              mock: 'Devices are disabled in your country',
            },
          },
        },
        disabledOnSite: {
          type: 'object',
          title: 'Devices disabled on site card texts',
          properties: {
            title: { type: 'string', title: 'Devices card header', mock: 'Devices' },
            body: {
              type: 'string',
              title: 'Devices are disabled on site text',
              mock: 'Devices are disabled on your site',
            },
          },
        },
        doubleBlindedMessage: {
          type: 'string',
          title: 'Message on device card when double blinded measurements',
          mock: 'Measurements for double blinded devices are hidden.',
        },
        title: {
          type: 'object',
          title: 'Titles in device list',
          properties: {
            lastSynced: { type: 'string', title: 'Title for last synced', mock: 'Last synced' },
            startDate: { type: 'string', title: 'Title for start date', mock: 'Start date' },
            endDate: { type: 'string', title: 'Title for end date', mock: 'End date' },
            status: { type: 'string', title: 'Title for status', mock: 'Status' },
            startAction: { type: 'string', title: 'Title for start action', mock: 'Start action' },
            endAction: { type: 'string', title: 'Title for end action', mock: 'End action' },
          },
        },
        connectDeviceWarning: {
          type: 'string',
          title: 'Warning telling to ask the patient to pair device when non active',
          mock: 'Please ask the patient to pair their device with Unify.',
        },
        noConnectedDeviceText: {
          type: 'string',
          title: 'Card text when no active device exists',
          mock: 'No device connected.',
        },
        viewAllUserDevices: { type: 'string', title: 'Expand list of user devices', mock: 'view all devices' },
        requestInitiation: {
          type: 'object',
          title: 'Device Request initiation texts',
          properties: {
            initiatedByTitle: { type: 'string', title: "'Initiated by' title", mock: 'Initiated by' },
            hcpInitiatedRequestLabel: {
              type: 'string',
              title: 'Label for HCP initiated request',
              mock: 'HCP Initiated',
            },
            patientScheduleRequestLabel: {
              type: 'string',
              title: 'Label for Patient schedule request',
              mock: 'Patient schedule',
            },
            initiationFailure: {
              type: 'string',
              title: 'Snackbar text for when initiation fails',
              mock: 'Failed to start activity. Please contact support.',
            },
            pendingRequest: {
              type: 'string',
              title: 'Text on label for when request is pending',
              mock: 'Pending request',
            },
          },
        },
      },
    },
    deviceObservations: {
      type: 'object',
      title: 'Device observation texts',
      properties: {
        second: { type: 'string', title: 'seconds in singular', mock: 'second' },
        seconds: { type: 'string', title: 'seconds in plural', mock: 'seconds' },
        observationDurationTemplate: {
          type: 'string',
          title: 'Observation duration format',
          mock: '${minutes} ${minutesText} and ${seconds} ${secondsText}',
        },
        observationWindowDateTimeTemplate: {
          type: 'string',
          title: 'Observation Window Date Time Format',
          mock: '${startDate} ${startTime} - ${endDate} ${endTime}',
        },
        verifiedLabel: { type: 'string', title: 'Verified label in the observation card', mock: 'Verified' },
        notVerifiedLabel: { type: 'string', title: 'Not verified label in the observation card', mock: 'Not verified' },
        measurementHiddenLabel: {
          type: 'string',
          title: 'Text to display when user has no permission to view the measurement readings.',
          mock: 'Measurement hidden',
        },
        statusTooltip: {
          type: 'string',
          title: 'Tooltip for the statuses in device observation cards',
          mock: 'The status is dependent on the schedule of assessments. Unscheduled measurements are additional measurements.',
        },
        verificationTooltip: {
          type: 'string',
          title: 'Tooltip for the verification field in device observation cards',
          mock: 'Verification status is determined by the patient. The patient enters their unique pin code in the Application to verify the result.',
        },
        missingTimeStampTooltip: {
          type: 'string',
          title: 'Tooltip for when the timestamp of an observation is missing',
          mock: 'Device has not recorded any timestamp for this observation.',
        },
        missingTimeStampReplacement: {
          type: 'string',
          title: 'Replacement text instead of timestamp when the timestamp of an observation is missing',
          mock: 'No data synced',
        },
        observationConfigurationError: {
          type: 'string',
          title: 'Error when invalid device configuration for observations',
          mock: 'Invalid device configuration. Please contact support.',
        },
        pagination: {
          type: 'object',
          title: 'Texts for device observations pagination',
          properties: {
            countInfoMiddle: { type: 'string', title: 'Count info middle text', mock: 'of' },
            countInfoText: { type: 'string', title: 'Count info text', mock: 'readings shown' },
            loadMoreButton: { type: 'string', title: 'Load more text', mock: 'Load more' },
          },
        },
        noObservationsLabel: {
          type: 'string',
          title: 'Text to display when there are no device observations.',
          mock: 'No measurements have been recorded. Please ask the patient to pair their device using the patient Application and take a measurement.',
        },
        observationStatus: {
          type: 'object',
          title: 'Device observations statuses',
          properties: {
            SCHEDULED: { type: 'string', title: 'Scheduled observations status text', mock: 'Scheduled' },
            UNSCHEDULED: { type: 'string', title: 'Unscheduled observations status text', mock: 'Unscheduled' },
            MISSED: { type: 'string', title: 'Missed observations status text', mock: 'Missed' },
          },
        },
        observationSort: {
          type: 'object',
          title: 'Device observations sort options',
          properties: {
            title: { type: 'string', title: 'The title in the select box of sorting options', mock: 'Sort by' },
            ascending: { type: 'string', title: 'Text for sorting in ascending order', mock: 'Most recent last' },
            descending: { type: 'string', title: 'Text for sorting in descending order', mock: 'Most recent first' },
          },
        },
        observationFilter: {
          type: 'object',
          title: 'Device observations filter options',
          properties: {
            emptyResult: {
              type: 'string',
              title: 'The text displayed when no data matches selected filters',
              mock: 'No filtered data',
            },
            startDateFilter: {
              type: 'string',
              title: 'The label on the start date input for filtering',
              mock: 'Start date',
            },
            endDateFilter: { type: 'string', title: 'The label on the end date input for filtering', mock: 'End date' },
            statusFilter: {
              type: 'string',
              title: 'Text on select box for filter by observation status',
              mock: 'Status',
            },
            scheduledStatus: {
              type: 'string',
              title: 'Scheduled observations status text in filter list',
              mock: 'Scheduled',
            },
            unscheduleStatus: {
              type: 'string',
              title: 'Unscheduled observations status text in filter list',
              mock: 'Unscheduled',
            },
            missedStatus: { type: 'string', title: 'Missed observations status text in filter list', mock: 'Missed' },
            allStatusesFilter: {
              type: 'string',
              title: 'The option to select All statuses in filter list',
              mock: 'All',
            },
            clearAllFilters: { type: 'string', title: 'Text on clear all filters button', mock: 'Clear All' },
          },
        },
      },
    },
    deviceSessions: {
      type: 'object',
      title: 'Device sessions texts',
      properties: {
        spirometrySessionTable: {
          type: 'object',
          title: 'Spirometry session data table',
          properties: {
            tableHeaders: {
              type: 'object',
              title: 'Spiro table data column headers',
              properties: {
                actual: { type: 'string', title: 'Actual values column headers', mock: 'Actual' },
                predicted: { type: 'string', title: 'Predicted values column headers', mock: 'Pred' },
                predictedPercent: { type: 'string', title: 'Predicted percent values column headers', mock: 'Pred %' },
                lln: { type: 'string', title: 'LLN values column headers', mock: 'LLN' },
                zScore: { type: 'string', title: 'Z Score values column headers', mock: 'Z Score' },
              },
            },
            tableRows: {
              type: 'object',
              title: 'Spiro table data rows',
              properties: {
                fev1: {
                  type: 'object',
                  title: 'FEV1 row data',
                  properties: {
                    rowTitle: { type: 'string', title: 'Row title for FEV1 values', mock: 'FEV1 (L)' },
                    unit: { type: 'string', title: 'The unit for the actual and pred values', mock: 'L' },
                  },
                },
                fvc: {
                  type: 'object',
                  title: 'FVC row data',
                  properties: {
                    rowTitle: { type: 'string', title: 'Row title for FVC values', mock: 'FVC (L)' },
                    unit: { type: 'string', title: 'The unit for the actual and pred values', mock: 'L' },
                  },
                },
                fev1_fvc: {
                  type: 'object',
                  title: 'FEV1/FVC row data',
                  properties: {
                    rowTitle: { type: 'string', title: 'Row title for FEV1/FVC values', mock: 'FEV1 / FVC' },
                  },
                },
                fef2575: {
                  type: 'object',
                  title: 'FEF2575 row data',
                  properties: {
                    rowTitle: { type: 'string', title: 'Row title for FEF2575 values', mock: 'FEF2575 (L/s)' },
                    unit: { type: 'string', title: 'The unit for the actual and pred values', mock: 'L/s' },
                  },
                },
                pef: {
                  type: 'object',
                  title: 'PEF row data',
                  properties: {
                    rowTitle: { type: 'string', title: 'Row title for PEF values', mock: 'PEF (L/s)' },
                    unit: { type: 'string', title: 'The unit for the actual and pred values', mock: 'L/s' },
                  },
                },
                fivc: {
                  type: 'object',
                  title: 'FIVC row data',
                  properties: {
                    rowTitle: { type: 'string', title: 'Row title for FIVC values', mock: 'FIVC (L)' },
                    unit: { type: 'string', title: 'The unit for the actual and pred values', mock: 'L' },
                  },
                },
                pif: {
                  type: 'object',
                  title: 'PIF row data',
                  properties: {
                    rowTitle: { type: 'string', title: 'Row title for PIF values', mock: 'PIF (L/s)' },
                    unit: { type: 'string', title: 'The unit for the actual and pred values', mock: 'L/s' },
                  },
                },
              },
            },
          },
        },
        sessionsList: {
          type: 'object',
          title: 'Text used in session lists',
          properties: {
            title: {
              type: 'string',
              title: 'Device sessions list title texts',
              mock: 'All sessions (only best data shown here)',
            },
            missedSession: {
              type: 'string',
              title: 'Missed session information text',
              mock: 'Patient missed to complete this session',
            },
            emptySession: {
              type: 'string',
              title: 'Empty session information text',
              mock: 'No measurements yet collected for this session',
            },
          },
        },
        reviewSessionStatus: {
          type: 'object',
          title: 'Device sessions statuses',
          properties: {
            ACCEPTED: { type: 'string', title: 'Accepted sessions status text', mock: 'ACCEPTED' },
            REJECTED: { type: 'string', title: 'Rejected sessions status text', mock: 'REJECTED' },
            TO_BE_REVIEWED: { type: 'string', title: 'To be reviewed sessions status text', mock: 'TO_BE_REVIEWED' },
          },
        },
        sessionFilter: {
          type: 'object',
          title: 'Device session filter options',
          properties: {
            reviewStatusFilter: {
              type: 'string',
              title: 'Text on select box for filter by session review status',
              mock: 'Review Status',
            },
            acceptedStatus: { type: 'string', title: 'Accepted status text in filter list', mock: 'Accepted' },
            rejectedStatus: { type: 'string', title: 'Rejected session status text in filter list', mock: 'Rejected' },
            toBeReviewedStatus: {
              type: 'string',
              title: 'to be reviewed status text in filter list',
              mock: 'To be reviewed',
            },
            allReviewStatusesFilter: {
              type: 'string',
              title: 'The option to select All review statuses in filter list',
              mock: 'All',
            },
          },
        },
        sessionLegend: {
          type: 'object',
          properties: {
            item: {
              type: 'string',
              title: 'Legend item text',
              helper: 'Text to be displayed on each legend item',
              mock: 'Test ${testNumber}',
            },
            bestMarked: {
              type: 'string',
              title: 'Best marked text',
              helper: 'Text to be displayed within brackets after item when best marked',
              mock: 'best',
            },
          },
        },
        exportSession: {
          type: 'object',
          properties: {
            title: { type: 'string', title: 'Export session card title', mock: 'Export Session Data' },
            body: {
              type: 'string',
              title: 'Export session card title',
              mock: 'I would like to export a PDF report containing all session data.',
            },
            button: { type: 'string', title: 'Export session card button label', mock: 'Export Data' },
          },
        },
        comments: {
          type: 'object',
          title: 'Device Session comments',
          properties: {
            commentForm: {
              type: 'object',
              title: 'Comment Form',
              properties: {
                infoAlert: {
                  type: 'string',
                  title: 'Info Alert text',
                  mock: 'Notes for all tests will be available once the session is completed.',
                },
                inputPlaceHolder: {
                  type: 'string',
                  title: 'Input placeholder text',
                  mock: 'Write your test notes here.',
                },
                snackbar: {
                  type: 'object',
                  title: 'Comment snackbar texts',
                  properties: {
                    addCommentSuccess: {
                      type: 'string',
                      title: 'Snackbar success notification text',
                      mock: 'Your note was successfully added',
                    },
                  },
                },
                inputHelper: {
                  type: 'string',
                  title: 'Text input helper text',
                  mock: '${characterCount} / 4096 characters',
                },
                button: { type: 'string', title: 'Submit form button text', mock: 'Save' },
              },
            },
            cardHeader: {
              type: 'string',
              title: 'Comment card header text',
              mock: 'Test ${testNumber} - Operator Comments',
            },
            emptyCardContent: {
              type: 'string',
              title: 'Comment card empty content text ',
              mock: 'No notes has been collected during this test.',
            },
            viewMore: {
              type: 'string',
              title: 'View More label text',
              helper: 'when there are more than one comments to display',
              mock: 'View more comments (${additionalCommentsLength})',
            },
            timestampToolTip: {
              type: 'string',
              title: 'Timestamp tooltip text',
              mock: 'The time that the comment was added.',
            },
          },
        },
        navigation: {
          type: 'object',
          properties: {
            title: {
              type: 'string',
              title: 'Session details page title',
              helper: 'Text to be displayed on session details page as the title on the navigation.',
              mock: '${sessionTestType} Session Details ',
            },
            sessionTestType: {
              type: 'string',
              title: 'Session test type text',
              helper: 'Text to be included in the session details page navigation',
              mock: 'Spirometry',
            },
          },
        },
        spirometryGraph: {
          type: 'object',
          properties: {
            flowVolumeGraph: {
              type: 'object',
              properties: {
                title: { type: 'string', title: 'Flow volume graph title', mock: 'Flow volume graph' },
                xAxisLabel: { type: 'string', title: 'Volume (L)', mock: 'Volume (L)' },
                yAxisLabel: { type: 'string', title: 'Flow (L/s)', mock: 'Flow (L/s)' },
              },
            },
            volumeTimeGraph: {
              type: 'object',
              properties: {
                title: { type: 'string', title: 'Volume time graph title', mock: 'Volume time graph' },
                xAxisLabel: { type: 'string', title: 'Time (s)', mock: 'Time (s)' },
                yAxisLabel: { type: 'string', title: 'Volume (L)', mock: 'Volume (L)' },
              },
            },
          },
        },
        sessionDetails: {
          type: 'object',
          title: 'Session details',
          properties: {
            header: { type: 'string', title: 'Session details card title', mock: 'Session details' },
            timestampLabel: { type: 'string', title: 'Session details timestamp label', mock: 'Session started at' },
          },
        },
        sessionAcceptReject: {
          type: 'object',
          title: 'Session accept/reject texts',
          properties: {
            markBestTitle: {
              type: 'string',
              title: 'Title over mark as best',
              mock: 'Mark test as the best data from the session',
            },
            markBestSwitchLabel: {
              type: 'string',
              title: 'Text for when activating swith for mark best',
              mock: 'Mark this test as the best data and accept the spirometry session',
            },
            rejectTitle: { type: 'string', title: 'Title for reject session card', mock: 'Reject session data' },
            rejectInfo: {
              type: 'string',
              title: 'Info section for reject session card',
              mock: 'I have reviewed the entire spirometry session and I want to reject all data.',
            },
            rejectSwitchLabel: {
              type: 'string',
              title: 'Text for when activating swith for reject session',
              mock: 'Reject this session',
            },
            rejectSessionInfoLabel: {
              type: 'string',
              title: 'Info text when session is rejected',
              mock: 'This session has been rejected and cannot be re-approved',
            },
            markBestDialog: {
              type: 'object',
              title: 'Text for mark best dialog',
              properties: {
                cardTitle: {
                  type: 'string',
                  title: 'Title for mark as best dialog',
                  mock: 'Mark test as the best data from the session',
                },
                dialogDescriptionMd: {
                  type: 'string',
                  title: 'Description in mark best dialog',
                  mock: 'Are you sure that **TEST ${testNumber}** provided the best data from the spirometry session?',
                },
                checkBoxText: {
                  type: 'string',
                  title: 'Confirm checkbox text',
                  mock: 'All data has been reviwed and I accept the session.',
                },
                dialogConfirmButton: { type: 'string', title: 'Text on confirm button', mock: 'Confirm' },
                dialogCancelButton: { type: 'string', title: 'Text on cancel button', mock: 'Cancel' },
              },
            },
            rejectSessionDialog: {
              type: 'object',
              title: 'Text for mark best dialog',
              properties: {
                cardTitle: {
                  type: 'string',
                  title: 'Title for reject session dialog',
                  mock: 'Reject spriometry session data',
                },
                dialogDescriptionMd: {
                  type: 'string',
                  title: 'Description in reject session dialog',
                  mock: 'Are you sure you want to reject all data from this session? <br/> <br/> Please note this action cannot be reversed once confirmed.',
                },
                dialogConfirmButton: { type: 'string', title: 'Text on confirm button', mock: 'Confirm' },
                dialogCancelButton: { type: 'string', title: 'Text on cancel button', mock: 'Cancel' },
              },
            },
          },
        },
      },
    },
    devicesInUse: {
      type: 'object',
      title: 'Devices in use card texts',
      properties: {
        noDevices: {
          type: 'string',
          title: 'No Devices Registered',
          mock: 'No devices are registered. Please ask the patient to pair their device using the patient mobile app.',
        },
        cardTitle: { type: 'string', title: 'Title for the devices in use card', mock: 'Devices' },
        viewMore: {
          type: 'string',
          title: 'Text for CTA to expand more devices in use if available',
          mock: 'View more',
        },
        viewDetailsPage: {
          type: 'string',
          title: 'Button text to navigate to device details page',
          mock: 'View details',
        },
      },
    },
    sensorReadingDetails: {
      type: 'object',
      title: 'Sensor reading details screen texts',
      properties: {
        measurementLabelPageTitle: {
          type: 'string',
          title: 'The measurement label in page title',
          mock: 'Measurement',
        },
        statusLabel: { type: 'string', title: 'The Status Label for sensor reading information', mock: 'Status' },
        timeAndDateLabel: {
          type: 'string',
          title: 'The time and date label for sensor reading information',
          mock: 'Time and date of submission',
        },
        durationLabel: { type: 'string', title: 'The duration label for sensor reading information', mock: 'Duration' },
      },
    },
    observationLabels: {
      type: 'array',
      title: 'Device Observations Labels',
      items: {
        title: 'Device Observation Label',
        type: 'object',
        required: ['name', 'label'],
        properties: {
          name: {
            title: 'Device Observation Name',
            helper: 'Ex SYSTOLIC_PRESSURE, PULSE_RATE, MIN_SPO2, MIN_RESPIRATION_RATE_FROM_PLETH',
            type: 'string',
          },
          label: { title: 'Label', helper: 'Label to use in list on Device Details Page', type: 'string' },
          labelInGroupedObservation: {
            title: 'Label to use in grouped observation',
            helper: 'Label to use for this observation in a grouped observation on the Observation Details Page',
            type: 'string',
          },
        },
      },
      mock: [
        { name: 'SYSTOLIC_PRESSURE', label: 'SYS' },
        { name: 'DIASTOLIC_PRESSURE', label: 'DIA' },
        { name: 'PULSE_RATE', label: 'Pulse' },
        { name: 'DURATION', label: 'Duration' },
        { name: 'SPO2', label: 'SpO2' },
        { name: 'MIN_SPO2', label: 'SpO2', labelInGroupedObservation: 'Min' },
        { name: 'AVG_SPO2', label: 'SpO2', labelInGroupedObservation: 'Avg' },
        { name: 'MAX_SPO2', label: 'SpO2', labelInGroupedObservation: 'Max' },
        { name: 'LAST_SPO2', label: 'SpO2', labelInGroupedObservation: 'Last' },
        { name: 'MIN_PULSE_RATE', label: 'Pulse', labelInGroupedObservation: 'Min' },
        { name: 'AVG_PULSE_RATE', label: 'Pulse', labelInGroupedObservation: 'Avg' },
        { name: 'MAX_PULSE_RATE', label: 'Pulse', labelInGroupedObservation: 'Max' },
        { name: 'LAST_PULSE_RATE', label: 'Pulse', labelInGroupedObservation: 'Last' },
        { name: 'MIN_RESPIRATION_RATE_FROM_PLETH', label: 'RR', labelInGroupedObservation: 'Min' },
        { name: 'AVERAGE_RESPIRATION_RATE_FROM_PLETH', label: 'RR', labelInGroupedObservation: 'Avg' },
        { name: 'MAX_RESPIRATION_RATE_FROM_PLETH', label: 'RR', labelInGroupedObservation: 'Max' },
        { name: 'LAST_RESPIRATION_RATE_FROM_PLETH', label: 'RR', labelInGroupedObservation: 'Last' },
        { name: 'ACTUATION', label: 'actuation' },
        { name: 'HEART_RATE', label: 'Heart Rate' },
        { name: 'PERFUSION_INDEX', label: 'Perfusion Index' },
      ],
    },
    groupedObservationLabels: {
      title: 'List of grouped observations',
      type: 'array',
      items: {
        type: 'object',
        required: ['name'],
        properties: {
          name: { title: 'Group Name', type: 'string' },
          label: { type: 'string', title: 'Group Name Label' },
        },
      },
      mock: [
        { name: 'SPO2', label: 'SpO2' },
        { name: 'PULSE_RATE', label: 'Pulse' },
        { name: 'RESPIRATION_RATE', label: 'RR' },
      ],
    },
    liveSpiroMetrySession: {
      type: 'object',
      properties: {
        sessionTitle: { type: 'string', title: 'Spirometry live session title', mock: 'Spirometry Test' },
        alerts: {
          type: 'object',
          properties: {
            noRefreshInfo: {
              type: 'string',
              title: 'Alert text to be displayed as information to HCP to not to close or refresh the page',
              mock: 'Do not close this tab or refresh the page during the session, otherwise the test will need to be restarted.',
            },
          },
        },
        popUps: {
          type: 'object',
          properties: {
            closeSession: {
              type: 'object',
              properties: {
                header: { type: 'string', title: 'Close session popup header', mock: 'Close the session' },
                description: {
                  type: 'string',
                  title: 'Close session popup description',
                  mock: 'Are you sure you want to close the spirometry session? Once the session has been closed you cannot add further comments or tests.',
                },
                checkbox: {
                  type: 'string',
                  title: 'Close session popup checkbox text',
                  mock: 'I understand and confirm I want to close the session',
                },
                confirm: { type: 'string', title: 'Close session popup confirm button label', mock: 'Close' },
                goBack: { type: 'string', title: 'Close session popup go back button label', mock: 'Go back' },
              },
            },
            maxNumTests: {
              type: 'object',
              properties: {
                header: {
                  type: 'string',
                  title: 'Maximum number of tests reached popup header title',
                  mock: 'Maximum number of tests reached',
                },
                description: {
                  type: 'string',
                  title: 'Maximum number of tests reached popup description',
                  mock: 'Your patient has completed the maximum number of allowed tests. Please end the session.',
                },
                confirm: { type: 'string', title: 'Close popup confirm button label', mock: 'Close' },
              },
            },
            timoutWarning: {
              type: 'object',
              properties: {
                header: { type: 'string', title: 'Timeout warning popup header title', mock: 'Timeout warning' },
                description: {
                  type: 'string',
                  title: 'No data received popup description',
                  mock: 'We have not received any data from your patient recently.\n\nDo you want to end the session?',
                },
                confirm: { type: 'string', title: 'Close popup confirm button label', mock: 'End session' },
                goBack: { type: 'string', title: 'Close popup go back button label', mock: 'Go back' },
              },
            },
            startSession: {
              type: 'object',
              properties: {
                header: { type: 'string', title: 'Start session popup header', mock: 'Start the session' },
                ecode: { type: 'string', title: 'ecode title', mock: 'E-code' },
                testType: {
                  type: 'object',
                  properties: {
                    title: { type: 'string', title: 'test type title', mock: 'Test' },
                    value: {
                      type: 'string',
                      title: 'test type value',
                      helper: 'Text to indicate the type of test to be performed ',
                      mock: 'Spirometry Test',
                    },
                  },
                },
                descriptionMd: {
                  type: 'string',
                  title: 'Start Spirometry session popup description',
                  mock: 'You are about to start a remote spirometry session with the patient.\n\n Before continuing, please check:\n\n - You have a good internet connection.\n - You have already started the video call with the patient.\n - You have confirmed with the patient they are able to do the test. \n\nDo you want to continue? \n\nThis will prompt the patient to start the spirometry session in the patient app.',
                },
                confirm: { type: 'string', title: 'Start session popup confirm button label', mock: 'Continue' },
                cancel: { type: 'string', title: 'Close session popup go back button label', mock: 'Cancel' },
                button: {
                  type: 'string',
                  title: 'Close session popup go back button label',
                  mock: 'Start the spirometry session',
                },
                pendingHeader: { type: 'string', title: 'title when request is already pending', mock: 'Error' },
                pendingDescriptionMd: {
                  type: 'string',
                  title: 'Information when request is already pending',
                  mock: 'A test request has already been sent to the patient. You cannot send a new request until the current request has expired.',
                },
                pendingDateInformation: {
                  type: 'string',
                  title: 'Text ',
                  mock: 'The pending request will expire on ${dateTime}',
                },
                pendingCloseButton: { type: 'string', title: 'Label on close button when pending request', mock: 'Ok' },
              },
            },
            viewSessionReport: {
              type: 'object',
              properties: {
                header: { type: 'string', title: 'View Session report popup header', mock: 'View report' },
                descriptionMd: {
                  type: 'string',
                  title: 'View Session report popup description',
                  mock: 'Once you view the report you will not be able to restart the session or add new comments.',
                },
                confirm: { type: 'string', title: 'View Session report confirm button label', mock: 'View report' },
                cancel: { type: 'string', title: 'Close session popup go back button label', mock: 'Go back' },
              },
            },
            missingPatientInfo: {
              type: 'object',
              properties: {
                header: {
                  type: 'string',
                  title: 'Missing patient info popup header',
                  mock: 'Patient information missing',
                },
                descriptionMd: {
                  type: 'string',
                  title: 'Missing patient info popup description',
                  mock: 'Some patient information is missing or incorrect. <br/> Please check the Patient overview and complete or update patient information before starting the spirometry session.',
                },
                close: { type: 'string', title: 'Missing patient close button label', mock: 'Close' },
              },
            },
          },
        },
        faqDrawer: {
          type: 'object',
          properties: {
            drawerHeader: { type: 'string', title: 'Header of FAQ drawer', mock: 'Help' },
            drawerSubTitle: {
              type: 'string',
              title: 'Subtitle of FAQ drawer',
              mock: 'Frequently Asked Questions (FAQs)',
            },
            faqItems: {
              type: 'array',
              title: 'FAQ Items in drawer',
              items: {
                title: 'FAQ Item',
                type: 'object',
                properties: {
                  question: { title: 'The FAQ question', type: 'string' },
                  answer: { title: 'The FAQ anwser', type: 'string' },
                },
              },
              mock: [
                { question: 'Info 1', answer: 'Here is a simple answer to a simple qustion' },
                { question: 'Info 2', answer: 'Here is a simple answer to a simple qustion' },
                { question: 'Info 3', answer: 'Here is a simple answer to a simple qustion' },
              ],
            },
          },
        },
        sessionReport: {
          type: 'object',
          properties: {
            title: { type: 'string', title: 'Session report title', mock: 'Session Report' },
            body: {
              type: 'string',
              title: 'Text to be shown as the body of the session report card section.',
              mock: 'Once the patient has completed 3 acceptable tests (or the maximum number of allowed tests) you can view the report.',
            },
            viewReportButton: {
              type: 'string',
              title: 'Button text on navigating to session report',
              mock: 'Save and View Session Report',
            },
          },
        },
        sessionWarnings: {
          type: 'object',
          properties: {
            notAvailable: { type: 'string', title: 'Text when warning is notAvailable', mock: 'Unknown warning' },
            dontHesitate: { type: 'string', title: 'Text when warning is dontHesitate', mock: 'Hesitation' },
            blowOutFaster: {
              type: 'string',
              title: 'Text when warning is blowOutFaster',
              mock: 'Did not blow out fast enough',
            },
            blowOutLonger: {
              type: 'string',
              title: 'Text when warning is blowOutLonger',
              mock: 'Did not blow out long enough',
            },
            abruptEnd: { type: 'string', title: 'Text when warning is abruptEnd', mock: 'Abrupt ending' },
            dontStartTooEarly: {
              type: 'string',
              title: 'Text when warning is dontStartTooEarly',
              mock: 'Started too early',
            },
            avoidCoughing: { type: 'string', title: 'Text when warning is avoidCoughing', mock: 'Caoughing detected' },
            hesitationAtMaxVolume: {
              type: 'string',
              title: 'Text when warning is hesitationAtMaxVolume',
              mock: 'Hesitation at max volume',
            },
            slowFilling: { type: 'string', title: 'Text when warning is slowFilling', mock: 'Slow filling' },
            lowFinalInspiration: {
              type: 'string',
              title: 'Text when warning is lowFinalInspiration',
              mock: 'Low final inspiration',
            },
            incompleteInspirationPriorToFvc: {
              type: 'string',
              title: 'Text when warning is incompleteInspirationPriorToFvc',
              mock: 'Incomplete inspiration prior to FVC',
            },
            lowForcedExpirationVolume: {
              type: 'string',
              title: 'Text when warning is lowForcedExpirationVolume',
              mock: 'Low forced expiration volume',
            },
            goodBlow: { type: 'string', title: 'Text when warning is goodBlow', mock: 'Good blow' },
            viewAllWarnings: {
              type: 'string',
              title: 'Dropdown text for viewing all warnings',
              mock: 'View all warnings (${numberOfWarnings})',
            },
          },
        },
        sessionManeuvers: {
          type: 'object',
          properties: {
            sessionManeuversSectionTitle: {
              type: 'string',
              title: 'Session maneuvers section title',
              mock: 'Test ${testNumber} data',
            },
            noMeasurements: {
              type: 'string',
              title: 'Text to be displayed when there are no measurements available yet.',
              mock: 'No data has been collected from your patient yet. Please wait.',
            },
          },
        },
        sessionAcceptability: {
          type: 'object',
          properties: {
            fev1AcceptabilityHeader: {
              type: 'string',
              title: 'Text for the header over the acceptability of FEV 1',
              mock: 'FEV 1 acceptability Criteria',
            },
            fvcAcceptabilityHeader: {
              type: 'string',
              title: 'Text for the header over the acceptability of FEV 1',
              mock: 'FVC acceptability Criteria',
            },
            acceptabilityCriteriaValues: {
              type: 'object',
              properties: {
                notApplicable: {
                  type: 'string',
                  title: 'Text for acceptability criteria notApplicable',
                  mock: 'Not Applicable',
                },
                acceptable: { type: 'string', title: 'Text for acceptability criteria acceptable', mock: 'Acceptable' },
                notAcceptable: {
                  type: 'string',
                  title: 'Text for acceptability criteria notAcceptable',
                  mock: 'Not Acceptable',
                },
                notAcceptableAndUsable: {
                  type: 'string',
                  title: 'Text for acceptability criteria notAcceptableAndUsable',
                  mock: 'Usable',
                },
                notAcceptableAndNotUsable: {
                  type: 'string',
                  title: 'Text for acceptability criteria notAcceptableAndNotUsable',
                  mock: 'Not Usable',
                },
                unknown: { type: 'string', title: 'Text for acceptability criteria unknown', mock: 'UKNOWN' },
              },
            },
          },
        },
        spirometryGraph: {
          type: 'object',
          properties: {
            spirometryGraphCard: {
              type: 'object',
              properties: {
                cardTitle: {
                  type: 'string',
                  title: 'Spirometry graph card title',
                  mock: 'Spirometry session - Test ${testNumber}',
                },
                cardSubTitle: {
                  type: 'string',
                  title: 'Spirometry graph card subtitle',
                  mock: 'started at ${sessionStartTime}',
                },
                cardActionButton: {
                  type: 'string',
                  title: 'Button text on card action to view Frequently asked questions',
                  mock: 'View FAQs',
                },
              },
            },
          },
        },
        sessionSummary: {
          type: 'object',
          title: 'Session Summary texts',
          properties: {
            sessionHeader: { type: 'string', title: 'Section header for session summary', mock: 'Session Summary' },
            columnHeader: {
              type: 'string',
              title: 'Column header for session summary table',
              mock: 'Test ${testNumber}',
            },
          },
        },
      },
    },
  },
};
