import { CspError } from '@csp/csp-common-model';
import Joi from 'joi';
import { RequestGroupMembershipV1 } from './RequestGroupMembershipV1';

export type RequestGroupConfigV1 = {
  memberOf: RequestGroupMembershipV1[];
};

const requestGroupConfigV1Schema = Joi.object<RequestGroupConfigV1>({
  memberOf: Joi.array().items(RequestGroupMembershipV1.validationSchema),
});

function assertRequestGroupConfigV1(value: unknown): asserts value is RequestGroupConfigV1 {
  const validationResult = requestGroupConfigV1Schema.validate(value, { stripUnknown: true, convert: false });
  if (validationResult.error) {
    throw CspError.badState(validationResult.error.message);
  }
}

export const RequestGroupConfigV1: {
  assert(value: unknown): asserts value is RequestGroupConfigV1;
} = {
  assert: assertRequestGroupConfigV1,
};
