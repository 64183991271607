import { CspBaseConfig } from '../model/CspBaseConfig';
import { CspPodConfigs } from '../model/CspPodConfigs';

type CspPodConfig = {
  podConfigs: CspPodConfigs;
  baseConfig: CspBaseConfig;
};

const singleton: CspPodConfig = {
  podConfigs: CspPodConfigs.empty(),
  baseConfig: CspBaseConfig.empty(),
};

const setCspBaseConfig = (cspBaseConfig: CspBaseConfig): void => {
  singleton.baseConfig = cspBaseConfig;
};

const getCspBaseConfig = (): CspBaseConfig => singleton.baseConfig;

const setCspPodConfigs = (podConfigs: CspPodConfigs): void => {
  singleton.podConfigs = podConfigs;
};

const getCspPodConfigs = (): CspPodConfigs => singleton.podConfigs;

export const CspConfigService = {
  getCspPodConfigs,
  setCspPodConfigs,
  getCspBaseConfig,
  setCspBaseConfig,
};
