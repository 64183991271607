import { QuestionnaireActivationV2 } from '@csp/dmdp-api-questionnaire-dto';
import { QuestionnaireActivationV1 } from '@csp/dto';

export type QuestionnaireActivation = {
  activationId: string;
  versionCode: string;
  windowStart: string;
  windowEnd?: string;
};

const from = ({
  activationId,
  versionCode,
  windowStart,
  windowEnd,
}: QuestionnaireActivationV2 | QuestionnaireActivationV1): QuestionnaireActivation => ({
  activationId,
  versionCode,
  windowStart,
  windowEnd,
});

export const QuestionnaireActivation = {
  from,
};
