import { parseJsonWithBigInt } from '@csp/csp-common-axios';
import { RestOptions } from '@csp/csp-common-model';
import { largePage, Page } from '@csp/dmdp-api-common-dto';
import {
  DataChangeInV1,
  DataChangeQueryV1,
  DataChangeReviewStatusInV1,
  DataChangesV1,
  DataChangeV1,
} from '@csp/dmdp-api-data-change-dto';
import { datachangerecordUrl } from '../../dmdp-config/dmdpConfig';

const datachangeUrl = (): string => `${datachangerecordUrl()}/data-change-record/v1/data-changes`;

const queryDataChanges = async (
  { axios, signal }: RestOptions,
  query: DataChangeQueryV1,
  page: Page = largePage(),
): Promise<DataChangesV1> => {
  const { data } = await axios.post<DataChangesV1>(`${datachangeUrl()}:query`, query, {
    transformResponse: parseJsonWithBigInt,
    params: page,
    signal,
  });

  return data;
};

const getDataChangeById = async ({ axios, signal }: RestOptions, id: string): Promise<DataChangeV1> => {
  const { data } = await axios.get<DataChangeV1>(`${datachangeUrl()}/${id}`, {
    transformResponse: parseJsonWithBigInt,
    signal,
  });

  return data;
};

const createDataChange = async (
  { axios, signal }: RestOptions,
  dataChangeInV1: DataChangeInV1,
): Promise<DataChangeV1> => {
  const { data } = await axios.post(datachangeUrl(), dataChangeInV1, { signal });

  return data;
};

const deleteDataChangeById = async ({ axios, signal }: RestOptions, id: string): Promise<void> => {
  await axios.delete(`${datachangeUrl()}/${id}`, {
    signal,
  });
};

const updateReviewStatus = async (
  { axios, signal }: RestOptions,
  id: string,
  reviewStatusInV1: DataChangeReviewStatusInV1,
): Promise<void> => {
  await axios.put(`${datachangeUrl()}/${id}/review-status`, reviewStatusInV1, {
    signal,
  });
};

export const DataChangeRestServiceV1 = {
  queryDataChanges,
  getDataChangeById,
  createDataChange,
  deleteDataChangeById,
  updateReviewStatus,
};
