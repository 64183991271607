import { CspErrorType } from './CspErrorType';
import { UnknownError } from './UnknownError';

export class CspError extends Error {
  static isCspError(error: unknown): error is CspError {
    return error instanceof CspError;
  }

  static unauthorized(message?: string): CspError {
    return new CspError(CspErrorType.UNAUTHORIZED, message);
  }

  static notFound(message?: string): CspError {
    return new CspError(CspErrorType.NOT_FOUND, message);
  }

  static gone(message?: string): CspError {
    return new CspError(CspErrorType.GONE, message);
  }

  static badState(message?: string): CspError {
    return new CspError(CspErrorType.BAD_STATE, message);
  }

  static badRequest(message?: string): CspError {
    return new CspError(CspErrorType.BAD_REQUEST, message);
  }

  static conflict(message?: string): CspError {
    return new CspError(CspErrorType.CONFLICT, message);
  }

  static internal(message?: string): CspError {
    return new CspError(CspErrorType.SERVER_ERROR, message);
  }

  static unhandledClientError(message?: string): CspError {
    return new CspError(CspErrorType.UNHANDLED_CLIENT_ERROR, message);
  }

  static forbidden(message?: string): CspError {
    return new CspError(CspErrorType.FORBIDDEN, message);
  }

  static cancelled(message?: string): CspError {
    return new CspError(CspErrorType.CANCELLED, message);
  }

  static timeout(message?: string): CspError {
    return new CspError(CspErrorType.TIMEOUT, message);
  }

  static tooManyRequests(message?: string): CspError {
    return new CspError(CspErrorType.TOO_MANY_REQUESTS, message);
  }

  static authenticationFailed(message?: string): CspError {
    return new CspError(CspErrorType.AUTHENTICATION_FAILED, message);
  }

  static httpCommunication(message?: string): CspError {
    return new CspError(CspErrorType.HTTP_COMMUNICATION, message);
  }

  // These methods safely checks the error type of unknown error.

  static isErrorOfType(error: UnknownError, errorType: CspErrorType): error is CspError {
    return this.isCspError(error) && error.type === errorType;
  }

  static isUnauthorized(error: UnknownError): error is CspError {
    return this.isErrorOfType(error, CspErrorType.UNAUTHORIZED);
  }

  static isAuthenticationFailed(error: UnknownError): error is CspError {
    return this.isErrorOfType(error, CspErrorType.AUTHENTICATION_FAILED);
  }

  static isNotFound(error: UnknownError): error is CspError {
    return this.isErrorOfType(error, CspErrorType.NOT_FOUND);
  }

  static isGone(error: UnknownError): error is CspError {
    return this.isErrorOfType(error, CspErrorType.GONE);
  }

  static isBadState(error: UnknownError): error is CspError {
    return this.isErrorOfType(error, CspErrorType.BAD_STATE);
  }

  static isBadRequest(error: UnknownError): error is CspError {
    return this.isErrorOfType(error, CspErrorType.BAD_REQUEST);
  }

  static isConflict(error: UnknownError): error is CspError {
    return this.isErrorOfType(error, CspErrorType.CONFLICT);
  }

  static isServerError(error: UnknownError): error is CspError {
    return this.isErrorOfType(error, CspErrorType.SERVER_ERROR);
  }

  static isTryLater(error: UnknownError): error is CspError {
    return this.isErrorOfType(error, CspErrorType.TRY_LATER);
  }

  static isExpired(error: UnknownError): error is CspError {
    return this.isErrorOfType(error, CspErrorType.EXPIRED);
  }

  static isMalformed(error: UnknownError): error is CspError {
    return this.isErrorOfType(error, CspErrorType.MALFORMED);
  }

  static isForbidden(error: UnknownError): error is CspError {
    return this.isErrorOfType(error, CspErrorType.FORBIDDEN);
  }

  static isHttpCommunication(error: UnknownError): error is CspError {
    return this.isErrorOfType(error, CspErrorType.HTTP_COMMUNICATION);
  }

  static isCancelled(error: UnknownError): error is CspError {
    return this.isErrorOfType(error, CspErrorType.CANCELLED);
  }

  static isTimeout(error: UnknownError): error is CspError {
    return this.isErrorOfType(error, CspErrorType.TIMEOUT);
  }

  static isTooManyRequests(error: UnknownError): error is CspError {
    return this.isErrorOfType(error, CspErrorType.TOO_MANY_REQUESTS);
  }

  type: CspErrorType;

  constructor(type: CspErrorType, message?: string) {
    super(message);
    this.type = type;

    Object.setPrototypeOf(this, CspError.prototype);
  }
}
