import { RestOptions } from '@csp/csp-common-model';
import {
  DeviceObservationActivationInV1,
  DeviceObservationActivationsV1,
  DeviceObservationActivationV1,
  DeviceObservationActivationWindowEndV1,
  DeviceObservationActiveSchedulesV1,
} from '@csp/dmdp-api-device-dto';
import { devicePodUrl } from '../../dmdp-config/dmdpConfig';

const deviceOrganizationsUrl = (): string => `${devicePodUrl()}/device/v1/organizations`;

const getObservationActivationsForOrganization = async (
  { axios, signal }: RestOptions,
  organizationId: string,
  scheduleCode: string,
): Promise<DeviceObservationActivationsV1> => {
  const resp = await axios.get<DeviceObservationActivationsV1>(
    `${deviceOrganizationsUrl()}/${organizationId}/schedules/${scheduleCode}/activations`,
    {
      signal,
    },
  );
  return resp.data;
};

const activateObservationScheduleForOrganization = async (
  { axios, signal }: RestOptions,
  organizationId: string,
  scheduleCode: string,
  activation: DeviceObservationActivationInV1,
): Promise<DeviceObservationActivationV1> => {
  const resp = await axios.post<DeviceObservationActivationV1>(
    `${deviceOrganizationsUrl()}/${organizationId}/schedules/${scheduleCode}/activations`,
    activation,
    {
      signal,
    },
  );
  return resp.data;
};

const deleteObservationActivationForOrganization = async (
  { axios, signal }: RestOptions,
  organizationId: string,
  scheduleCode: string,
  activationId: string,
): Promise<void> => {
  const resp = await axios.delete(
    `${deviceOrganizationsUrl()}/${organizationId}/schedules/${scheduleCode}/activations/${activationId}`,
    {
      signal,
    },
  );
  return resp.data;
};

const updateObservationActivationWindowEndForOrganization = async (
  { axios, signal }: RestOptions,
  organizationId: string,
  scheduleCode: string,
  activationId: string,
  activationWindowEnd: DeviceObservationActivationWindowEndV1,
): Promise<void> => {
  const resp = await axios.put(
    `${deviceOrganizationsUrl()}/${organizationId}/schedules/${scheduleCode}/activations/${activationId}/window-end`,
    activationWindowEnd,
    {
      signal,
    },
  );
  return resp.data;
};

const getObservationActiveSchedulesByOrganizationStructure = async (
  { axios, signal }: RestOptions,
  organizationId: string,
): Promise<DeviceObservationActiveSchedulesV1> => {
  const resp = await axios.get<DeviceObservationActiveSchedulesV1>(
    `${deviceOrganizationsUrl()}/${organizationId}/active-schedules`,
    {
      signal,
    },
  );
  return resp.data;
};

export const DeviceOrganizationsRestServiceV1 = {
  getObservationActivationsForOrganization,
  activateObservationScheduleForOrganization,
  deleteObservationActivationForOrganization,
  updateObservationActivationWindowEndForOrganization,
  getObservationActiveSchedulesByOrganizationStructure,
};
