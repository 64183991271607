/* eslint-disable */
export const UnifyPodEmailMock = {
  title: 'title',
  healthEvent: [
    {
      type: 'ONCE',
      text: {
        subject: 'Action needed: Potential health event needs review in Unify study ${studyCode}',
        bodyTopSectionMd:
          'A new potential health event has been reported.\nPlease review this event in Unify and determine whether it is related to study or not.',
        bodyGreetingMd: 'Sincerely,\nUnify Team',
        bodyFooterSectionMd:
          '__Note__: This is an automatically generated email, which you cannot reply to. If you have questions please contact appropriate team.',
      },
      button: { unifyLink: 'Visit unify portal to review this events' },
      title: {
        bodyTitle: 'Unify Health Events',
        colSubject: 'Subject',
        colTimestamp: 'Reported at',
        colStatus: 'Status ',
        colSiteId: 'Site Id',
        colSiteName: 'Site name',
        colStudy: 'Study',
      },
    },
    {
      type: 'REPEATEDLY',
      text: {
        subject: 'Potential health event needs review in Unify study ${studyCode}',
        bodyTopSectionMd:
          'The following health events have been reported more than ${days} days ago and not yet been solved by responsible study site.\nPlease contact the responsible site staff to ensure these events are resolved as soon as possible',
        bodyGreetingMd: 'Sincerely,\nUnify Team',
        bodyFooterSectionMd:
          '__Note__: This is an automatically generated email, which you cannot reply to. If you have questions please contact appropriate team.',
      },
      button: { unifyLink: 'Visit unify portal to review this events' },
      title: {
        bodyTitle: 'Unify Health Events',
        colSubject: 'Subject',
        colTimestamp: 'Reported at',
        colStatus: 'Status ',
        colSiteId: 'Site Id',
        colSiteName: 'Site name',
        colStudy: 'Study',
      },
    },
  ],
  rpmEvent: {
    text: {
      subject: 'Reported event',
      bodyTopSectionMd: 'A new event has been reported.\nPlease review this event in Unify and take action as needed.',
      bodyGreetingMd: 'Sincerely,\nUnify Team',
      bodyFooterSectionMd:
        '__Note__: This is an automatically generated email, which you cannot reply to. If you have questions please contact appropriate team.',
    },
    button: { unifyLink: 'Visit unify portal to review this events' },
    title: {
      bodyTitle: 'New notices to view',
      colSubject: 'Subject',
      colEventType: 'Notice type',
      colTimestamp: 'Recieved on',
      colStatus: 'Status ',
      colSiteId: 'Site Id',
      colSiteName: 'Site name',
      colStudy: 'Study',
    },
  },
  complianceNotification: {
    text: {
      subject: 'New compliance notices for study ${studyCode}.',
      bodyTopSectionMd:
        'Compliance for the following patient(s) is outside of the agreed threshold for questionnaires or medical devices. Please contact all of these patients to check if they need support.',
      bodySectionMd: 'Number of patients: __${numPatients}__',
      bodyGreetingMd: 'From,\nUnify Team',
      bodyFooterSectionMd:
        '__Note__: This is an automatically generated email, which you cannot reply to. If you have questions please contact appropriate team.',
    },
    noticeTypes: [{ type: 'COMPLIANCE_BELOW_THRESHOLD', title: 'Compliance below threshold' }],
    title: {
      bodyTitle: 'New compliance notices',
      colPatientId: 'Patient',
      colNoticeType: 'Notice type',
      colLastReceivedData: 'Last recieved data',
      colSiteId: 'Site Id',
      colSiteName: 'Site name',
      colStudy: 'Study',
    },
    button: { unifyLink: 'Visit Unify for more information' },
  },
  singleSymptomEventNotice: {
    text: {
      subject: 'New urgent symptom notice for study ${studyCode}.',
      bodyTopSectionMd:
        'A symptom for this patient has been reported which requires your attention. Please visit the Unify portal to view the full symptom notice for this study.',
      bodyGreetingMd: 'From,\nUnify Team',
      bodyFooterSectionMd:
        '__Note__: This is an automatically generated email, which you cannot reply to. If you have questions please contact appropriate team.',
    },
    title: {
      bodyTitle: 'New urgent symptom notice',
      colPatientId: 'Patient',
      colNoticeType: 'Notice type',
      colReceivedOn: 'Received on',
      colSiteId: 'Site Id',
      colSiteName: 'Site name',
      colStudy: 'Study',
    },
    button: { unifyLink: 'Visit Unify to view notice' },
  },
};
