import { GenericSchedule, ScheduleConfig, ScheduleConfigMapper } from '@csp/csp-common-scheduling';
import { QuestionnaireActiveScheduleV2, QuestionnaireActiveVersionWindowV2 } from '@csp/dmdp-api-questionnaire-dto';
import { QuestionnaireScheduleV1, QuestionnaireVersionWindowV1 } from '@csp/dto';
import { QuestionnaireVersion } from './QuestionnaireVersion';

export type QuestionnaireSchedule = GenericSchedule<QuestionnaireVersion>;

const isQuestionnaireActiveScheduleV2 = (
  schedule: QuestionnaireActiveScheduleV2 | QuestionnaireScheduleV1,
): schedule is QuestionnaireActiveScheduleV2 => 'meta' in schedule;

const from = (schedule: QuestionnaireActiveScheduleV2 | QuestionnaireScheduleV1): QuestionnaireSchedule => {
  const config = isQuestionnaireActiveScheduleV2(schedule)
    ? ScheduleConfigMapper.from(schedule.meta)
    : { ...ScheduleConfig.default };

  return {
    scheduleCode: schedule.scheduleCode,
    activeVersionWindows: schedule.activeVersionWindows.map(
      (version: QuestionnaireActiveVersionWindowV2 | QuestionnaireVersionWindowV1) =>
        QuestionnaireVersion.from(schedule.scheduleCode, version),
    ),
    ...config,
  };
};

export const QuestionnaireSchedule = {
  from,
};
