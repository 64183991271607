import { ObjectType } from '@csp/csp-common-model';
import { DeviceModelConfigPropertiesV2 } from '../device-config-properties/DeviceModelConfigPropertiesV2';
import {
  GenericDeviceObservationContext,
  ObservationContextConfigMap,
  ObservationContextType,
} from './ObservationContextConfig';

export enum DeviceObservation {
  OMRON_BP_MEASUREMENT = 'OMRON_BP_MEASUREMENT',
  MASIMO_9809_PULSE_OX_TREND = 'MASIMO_9809_PULSE_OX_TREND',
  MASIMO_9809_PULSE_OX_STREAM = 'MASIMO_9809_PULSE_OX_STREAM',
  MASIMO_9909_PULSE_OX_TREND = 'MASIMO_9909_PULSE_OX_TREND',
  MASIMO_9909_PULSE_OX_STREAM = 'MASIMO_9909_PULSE_OX_STREAM',
  MIR_PEAK_FLOW_FEV1 = 'MIR_PEAK_FLOW_FEV1',
  MIR_FVC_PLUS = 'MIR_FVC_PLUS',
  ADHERIUM_EVOCAPXS_LOG_RECORD = 'ADHERIUM_EVOCAPXS_LOG_RECORD',
  MARSDEN_WEIGHT_MEASUREMENT = 'MARSDEN_WEIGHT_MEASUREMENT',
  VIVALNK_ECG = 'VIVALNK_ECG',
}

export type DeviceObservationType = `${DeviceObservation}`;

/**
 * All observation config related props available for picking
 */
type ConfigProperties = Pick<
  DeviceModelConfigPropertiesV2,
  | 'verifiedData'
  | 'minObservationsWithinSession'
  | 'maxObservationsWithinSession'
  | 'enableScheduledTasks'
  | 'enableManualMeasurements'
  | 'trendRecordMinimumDurationSeconds'
  | 'observationsSessionMirTurbineType'
  | 'enableHighResolutionData'
  | 'ecgMeasurementMinimumDurationSeconds'
  | 'highResolutionCurveTimeoutSeconds'
>;

/**
 * Required config props that should be populated for every observation type
 */
export type ObservationConfigRequiredProperties = Required<
  Pick<
    ConfigProperties,
    'verifiedData' | 'minObservationsWithinSession' | 'maxObservationsWithinSession' | 'enableScheduledTasks'
  >
>;

/**
 * Additional config props that can be picked per observation type
 */
export type ObservationConfigAdditionalProperties = Omit<ConfigProperties, keyof ObservationConfigRequiredProperties>;

/**
 * Config props that can be overridden on request level
 */
export type ObservationConfigOverridableProperties = Pick<
  ConfigProperties,
  | 'verifiedData'
  | 'minObservationsWithinSession'
  | 'maxObservationsWithinSession'
  | 'enableScheduledTasks'
  | 'trendRecordMinimumDurationSeconds'
  | 'observationsSessionMirTurbineType'
  | 'enableHighResolutionData'
  | 'ecgMeasurementMinimumDurationSeconds'
  | 'highResolutionCurveTimeoutSeconds'
>;

type ContextConfigType = Partial<Record<ObservationContextType, GenericDeviceObservationContext>>;
export type GenericDeviceObservation<
  Type extends DeviceObservationType = DeviceObservationType,
  ObservationConfig extends ObservationConfigAdditionalProperties = ObservationConfigAdditionalProperties,
  ContextConfig extends ContextConfigType = ContextConfigType,
> = {
  type: Type;
  observationConfig: ObservationConfigRequiredProperties & ObservationConfig;
  contextTypeConfig: ContextConfig;
};

/*
 * Build Omron Blood Pressure Observation
 */
type OmronBloodPressureObservationConfigAdditionalProperties = Required<
  Pick<ObservationConfigAdditionalProperties, 'enableManualMeasurements'>
>;
type OmronBloodPressureObservationContextConfig = Pick<ObservationContextConfigMap, 'DEFAULT'>;
export type OmronBloodPressureObservation = GenericDeviceObservation<
  'OMRON_BP_MEASUREMENT',
  OmronBloodPressureObservationConfigAdditionalProperties,
  OmronBloodPressureObservationContextConfig
>;

/*
 * Build Masimo Pulse Ox Trend Observation types
 */
type Masimo9809PulseOxTrendObservationConfigAdditionalProperties = Required<
  Pick<ObservationConfigAdditionalProperties, 'enableManualMeasurements' | 'trendRecordMinimumDurationSeconds'>
>;
type Masimo9809PulseOxTrendObservationContextConfig = Pick<
  ObservationContextConfigMap,
  'DEFAULT' | 'PULSE_OX_WALKING' | 'PULSE_OX_AT_REST'
>;
export type Masimo9809PulseOxTrendObservation = GenericDeviceObservation<
  'MASIMO_9809_PULSE_OX_TREND',
  Masimo9809PulseOxTrendObservationConfigAdditionalProperties,
  Masimo9809PulseOxTrendObservationContextConfig
>;

type Masimo9909PulseOxTrendObservationConfigAdditionalProperties = Required<
  Pick<ObservationConfigAdditionalProperties, 'enableManualMeasurements' | 'trendRecordMinimumDurationSeconds'>
>;
type Masimo9909PulseOxTrendObservationContextConfig = Pick<
  ObservationContextConfigMap,
  'DEFAULT' | 'PULSE_OX_WALKING' | 'PULSE_OX_AT_REST'
>;
export type Masimo9909PulseOxTrendObservation = GenericDeviceObservation<
  'MASIMO_9909_PULSE_OX_TREND',
  Masimo9909PulseOxTrendObservationConfigAdditionalProperties,
  Masimo9909PulseOxTrendObservationContextConfig
>;

/*
 * Build Masimo Pulse Ox Stream Observation types
 */
type Masimo9809PulseOxStreamObservationConfigAdditionalProperties = Required<
  Pick<ObservationConfigAdditionalProperties, 'enableManualMeasurements' | 'trendRecordMinimumDurationSeconds'>
>;
type Masimo9809PulseOxStreamObservationContextConfig = Pick<
  ObservationContextConfigMap,
  'DEFAULT' | 'PULSE_OX_WALKING' | 'PULSE_OX_AT_REST'
>;
export type Masimo9809PulseOxStreamObservation = GenericDeviceObservation<
  'MASIMO_9809_PULSE_OX_STREAM',
  Masimo9809PulseOxStreamObservationConfigAdditionalProperties,
  Masimo9809PulseOxStreamObservationContextConfig
>;

type Masimo9909PulseOxStreamObservationConfigAdditionalProperties = Required<
  Pick<ObservationConfigAdditionalProperties, 'enableManualMeasurements' | 'trendRecordMinimumDurationSeconds'>
>;
type Masimo9909PulseOxStreamObservationContextConfig = Pick<
  ObservationContextConfigMap,
  'DEFAULT' | 'PULSE_OX_WALKING' | 'PULSE_OX_AT_REST'
>;
export type Masimo9909PulseOxStreamObservation = GenericDeviceObservation<
  'MASIMO_9909_PULSE_OX_STREAM',
  Masimo9909PulseOxStreamObservationConfigAdditionalProperties,
  Masimo9909PulseOxStreamObservationContextConfig
>;

/*
 * Build MIR PeakFlowFev1 Observation
 */
type MIRPeakFlowFev1ObservationConfigAdditionalProperties = Required<
  Pick<ObservationConfigAdditionalProperties, 'enableManualMeasurements' | 'observationsSessionMirTurbineType'>
>;
type MIRPeakFlowFev1ObservationContextConfig = Pick<ObservationContextConfigMap, 'DEFAULT' | 'COMPEX'>;
export type MIRPeakFlowFev1Observation = GenericDeviceObservation<
  'MIR_PEAK_FLOW_FEV1',
  MIRPeakFlowFev1ObservationConfigAdditionalProperties,
  MIRPeakFlowFev1ObservationContextConfig
>;

/*
 * Build MIR FVCPlus Observation
 */
type MIRFvcPlusObservationConfigAdditionalProperties = Required<
  Pick<
    ObservationConfigAdditionalProperties,
    'observationsSessionMirTurbineType' | 'enableHighResolutionData' | 'highResolutionCurveTimeoutSeconds'
  >
>;
type MIRFvcPlusObservationContextConfig = Pick<ObservationContextConfigMap, 'SPIROMETRY' | 'ON_SITE_SPIROMETRY'>;
export type MIRFvcPlusObservation = GenericDeviceObservation<
  'MIR_FVC_PLUS',
  MIRFvcPlusObservationConfigAdditionalProperties,
  MIRFvcPlusObservationContextConfig
>;

/*
 * Build Adherium Evocap Xs Observation
 */
type AdheriumEvocapXsObservationConfigAdditionalProperties = ObjectType;
type AdheriumEvocapXsObservationContextConfig = Pick<ObservationContextConfigMap, 'DEFAULT'>;
export type AdheriumEvocapXsObservation = GenericDeviceObservation<
  'ADHERIUM_EVOCAPXS_LOG_RECORD',
  AdheriumEvocapXsObservationConfigAdditionalProperties,
  AdheriumEvocapXsObservationContextConfig
>;

/*
 * Build Marsden M-550 Observation
 */
type MarsdenM550ObservationConfigAdditionalProperties = ObjectType;
type MarsdenM550ObservationContextConfig = Pick<ObservationContextConfigMap, 'DEFAULT'>;
export type MarsdenM550Observation = GenericDeviceObservation<
  'MARSDEN_WEIGHT_MEASUREMENT',
  MarsdenM550ObservationConfigAdditionalProperties,
  MarsdenM550ObservationContextConfig
>;

/*
 * Build VivaLNK VV330 C8 Observation
 */
type VivaLNKvv330C8ObservationConfigAdditionalProperties = Required<
  Pick<ObservationConfigAdditionalProperties, 'ecgMeasurementMinimumDurationSeconds' | 'enableManualMeasurements'>
>;
type VivaLNKvv330C8ObservationContextConfig = Pick<ObservationContextConfigMap, 'DEFAULT'>;
export type VivaLNKvv330C8Observation = GenericDeviceObservation<
  'VIVALNK_ECG',
  VivaLNKvv330C8ObservationConfigAdditionalProperties,
  VivaLNKvv330C8ObservationContextConfig
>;

export type ObservationTypeConfig =
  | OmronBloodPressureObservation
  | Masimo9809PulseOxTrendObservation
  | Masimo9809PulseOxStreamObservation
  | Masimo9909PulseOxTrendObservation
  | Masimo9909PulseOxStreamObservation
  | MIRPeakFlowFev1Observation
  | MIRFvcPlusObservation
  | AdheriumEvocapXsObservation
  | MarsdenM550Observation
  | VivaLNKvv330C8Observation;

export type ToObservationTypeConfig<T extends ObservationTypeConfig['type']> = Extract<
  ObservationTypeConfig,
  { type: T }
>;

export type ObservationTypeConfigMap = {
  [K in ObservationTypeConfig['type']]: ToObservationTypeConfig<K>;
};
