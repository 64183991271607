import { DmdpPodConfigs } from '../model/DmdpPodConfigs';
import { M2mConfig } from '../model/M2mConfig';
import { MqttConfig } from '../model/MqttConfig';

type PodConfigs = {
  dmdpBaseUrl: string;
  dmdpPodConfigs: DmdpPodConfigs;
  m2mConfig: M2mConfig;
  mqttConfig: MqttConfig;
};

const singleton: PodConfigs = {
  dmdpBaseUrl: '',
  dmdpPodConfigs: DmdpPodConfigs.empty(),
  m2mConfig: M2mConfig.empty(),
  mqttConfig: MqttConfig.empty(),
};

const setDmdpBaseUrl = (dmdpBaseUrl: string): void => {
  singleton.dmdpBaseUrl = dmdpBaseUrl;
};

const getDmdpBaseUrl = (): string => singleton.dmdpBaseUrl;

const setDmdpPodConfigs = (dmdpPodConfigs: DmdpPodConfigs): void => {
  singleton.dmdpPodConfigs = dmdpPodConfigs;
};

const getDmdpPodConfigs = (): DmdpPodConfigs => singleton.dmdpPodConfigs;

const setM2mConfig = (m2mConfig: M2mConfig): void => {
  singleton.m2mConfig = m2mConfig;
};

const getM2mConfig = (): M2mConfig => singleton.m2mConfig;

const getMqttConfig = (): MqttConfig => singleton.mqttConfig;

const setMqttConfig = (mqttConfig: MqttConfig): void => {
  singleton.mqttConfig = mqttConfig;
};

export const DmdpConfigService = {
  getDmdpPodConfigs,
  setDmdpPodConfigs,
  getDmdpBaseUrl,
  setDmdpBaseUrl,
  getM2mConfig,
  setM2mConfig,
  getMqttConfig,
  setMqttConfig,
};
