import { ApiOptions } from '@csp/csp-common-model';
import { AxiosPublicService, toRestOptions } from '@csp/csp-fe-auth';
import { BulkAssetSignedUrls, BulkAssets } from '../model/BulkAssetSignedUrls';
import { ContentPackageParams } from '../model/ContentPackageParams';
import { SignedAssetUrlResponse } from '../model/SignedAssetUrlResponse';
import { getSignedUrlExpirationDateFromAssetResponse } from '../utils/signedUrlPackageUtils';
import { AssetDeliveryRestServiceV1 } from './AssetDeliveryRestServiceV1';

const getSignedAssetUrl = async (
  contentPackageDescriptor: ContentPackageParams,
  assetRelativePath: string,
  apiOptions?: ApiOptions,
): Promise<NonNullable<SignedAssetUrlResponse>> => {
  let restOptions = toRestOptions(apiOptions);
  if (contentPackageDescriptor.packageType === 'public') {
    restOptions = toRestOptions(apiOptions, AxiosPublicService.axios());
  }

  return AssetDeliveryRestServiceV1.getSignedAssetUrl(restOptions, contentPackageDescriptor, assetRelativePath);
};

const fetchAssetUrls = async (
  contentPackageDescriptor: ContentPackageParams,
  assetRelativePaths: string[],
  apiOptions?: ApiOptions,
): Promise<BulkAssets> => {
  let restOptions = toRestOptions(apiOptions);
  if (contentPackageDescriptor.packageType === 'public') {
    restOptions = toRestOptions(apiOptions, AxiosPublicService.axios());
  }

  const signedUrls: BulkAssetSignedUrls = await AssetDeliveryRestServiceV1.getBulkSignedAssetUrl(
    restOptions,
    contentPackageDescriptor,
    assetRelativePaths,
  );

  const expirationDate = getSignedUrlExpirationDateFromAssetResponse(signedUrls);

  return { signedUrls, expirationDate };
};

export const AssetDeliveryServiceV1 = {
  getSignedAssetUrl,
  fetchAssetUrls,
};
