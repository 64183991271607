/* eslint-disable */
export const UnifyAppOutofAppNotificationsSchema = {
  title: 'UnifyAppOutofAppNotifications',
  type: 'object',
  properties: {
    title: { type: 'string' },
    notificationId: { type: 'string', required: true },
    notificationheader: { type: 'string' },
    notificationbody: { type: 'string' },
    notificationbutton: { type: 'string' },
  },
  _parent: 'n:node',
  description: 'unify:appoutofappnotifications',
  $schema: 'http://json-schema.org/draft-04/schema#',
  _doc: '899aa2f822459cea1264',
};
