import './polyfills';
import './wdyr';
import '@csp-misc/vendor';
import '@csp-misc/web-ui-css';
import { CssBaseline, StylesProvider, theme, ThemeProvider } from '@csp/csp-web-ui';
import { CacheProvider, ThemeProvider as StyledThemeProvider } from '@emotion/react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from '@csp/web-common';
import createCache from '@emotion/cache';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { ApplicationErrorPage } from './common/component/ApplicationErrorPage';

const emotionCache = createCache({
  key: 'unify',
  ...(process.env.NX_DISABLE_CSS_VENDOR_PREFIX === 'active' && { stylisPlugins: [] }),
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <CacheProvider value={emotionCache}>
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <StyledThemeProvider theme={theme}>
          <ErrorBoundary ErrorComponent={ApplicationErrorPage}>
            <App />
          </ErrorBoundary>
        </StyledThemeProvider>
      </ThemeProvider>
    </StylesProvider>
  </CacheProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
