import {
  ApiOptions,
  RestOptions,
  StateAssert,
  TenantId,
  User,
  ZoomIdType,
  ZoomMeetingId,
  ZoomUserId,
} from '@csp/csp-common-model';
import { DmdpTokenService, toRestOptions } from '@csp/csp-fe-auth';
import { UserRestServiceV1, ZoomProxyRestServiceV1 } from '@csp/dmdp-api-client';
import { ZoomMeetingMapper } from '../mapper/ZoomMeetingMapper';
import { ZoomUserMapper } from '../mapper/ZoomUserMapper';
import { ZoomMeeting } from '../model/ZoomMeeting';
import { ZoomMeetingOptions } from '../model/ZoomMeetingOptions';
import { ZoomUser } from '../model/ZoomUser';
import { ZoomConfigService } from './ZoomConfigService';

const EMAIL_SUFFIX = '@isv.unifytrialspro.com';

const createZoomUser = async (
  { tenantId, user }: { tenantId: TenantId; user: User },
  restOptions: RestOptions,
): Promise<ZoomUser> => {
  // <tenantId>.<userId>.<currentTime> - email is both unique an queryable
  const email = `${[tenantId, user.userId, Date.now()].join('.')}${EMAIL_SUFFIX}`;
  const zoomUserV2 = await ZoomProxyRestServiceV1.createUser(
    restOptions,
    ZoomConfigService.getZoomClientId(),
    ZoomUserMapper.toZoomUserInV2(email),
  );

  return {
    userId: zoomUserV2.id,
    email: zoomUserV2.email,
  };
};

const getZoomUserIdCreateIfNeeded = async (
  tenantId: TenantId,
  user: User,
  apiOptions?: ApiOptions,
): Promise<ZoomUserId> => {
  if (user.zoomUserId) {
    return user.zoomUserId;
  } else {
    const restOptions = toRestOptions(apiOptions);
    const { userId: zoomUserId } = await createZoomUser({ tenantId, user }, restOptions);
    await UserRestServiceV1.upsertExternalId(restOptions, user.userId, ZoomIdType.ZOOM_USER_ID, {
      value: zoomUserId,
    });
    return zoomUserId;
  }
};

const createMeeting = async (
  zoomUserId: ZoomUserId,
  options: ZoomMeetingOptions,
  apiOptions?: ApiOptions,
): Promise<ZoomMeeting> => {
  const v2 = await ZoomProxyRestServiceV1.createMeeting(
    toRestOptions(apiOptions),
    ZoomConfigService.getZoomClientId(),
    zoomUserId,
    ZoomMeetingMapper.toZoomMeetingInV2(options),
  );

  return ZoomMeetingMapper.toZoomMeeting(v2);
};

const deleteMeeting = async (meetingId: ZoomMeetingId, apiOptions?: ApiOptions): Promise<void> =>
  ZoomProxyRestServiceV1.deleteMeeting(toRestOptions(apiOptions), ZoomConfigService.getZoomClientId(), meetingId);

const getMeeting = async (meetingId: ZoomMeetingId, apiOptions?: ApiOptions): Promise<ZoomMeeting> => {
  const v2 = await ZoomProxyRestServiceV1.getMeetingById(
    toRestOptions(apiOptions),
    ZoomConfigService.getZoomClientId(),
    meetingId,
  );

  return ZoomMeetingMapper.toZoomMeeting(v2);
};

const createMeetingForUser = async (
  {
    tenantId,
    user,
    replaceMeetingId,
    meetingOptions,
  }: { tenantId: TenantId; user: User; replaceMeetingId?: ZoomMeetingId; meetingOptions: ZoomMeetingOptions },
  apiOptions?: ApiOptions,
): Promise<ZoomMeeting> => {
  StateAssert.notNull(tenantId, 'User must be logged in');
  if (replaceMeetingId) {
    try {
      await deleteMeeting(replaceMeetingId, apiOptions);
    } catch (e) {
      // Don't fail on delete meeting - meeting will be removed automatically
      console.debug('Failed to delete existing meeting');
    }
  }
  const zoomUserId = await getZoomUserIdCreateIfNeeded(tenantId, user, apiOptions);
  return createMeeting(zoomUserId, meetingOptions, apiOptions);
};

const createMeetingForUserAndCurrentTenant = async (
  {
    user,
    replaceMeetingId,
    meetingOptions,
  }: { user: User; replaceMeetingId?: ZoomMeetingId; meetingOptions: ZoomMeetingOptions },
  apiOptions?: ApiOptions,
): Promise<ZoomMeeting> => {
  const tenantId = DmdpTokenService.getToken()?.getTenantId();
  StateAssert.notNull(tenantId, 'User must be logged in');
  return createMeetingForUser({ tenantId, user, replaceMeetingId, meetingOptions }, apiOptions);
};

export const ZoomService = {
  createMeetingForUser,
  createMeetingForUserAndCurrentTenant,
  deleteMeeting,
  getMeeting,

  // Exported for test
  createMeeting,
  getZoomUserIdCreateIfNeeded,
};
