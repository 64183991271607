/* eslint-disable */
export const UnifyWebDevicesMasimoStreamMock = {
  pageErrors: { noCorrelationId: '[M]No correlation id on the trend record.' },
  observationDetails: {
    title: '[M]Details',
    noStreamData: '[M]No streamed data collected during this measurement',
    measurementVerificationLabel: '[M]Measurement verification status',
  },
  observationValueGraphs: { enum: { graphName: { SPO2: '[M]Spo2', HEART_RATE: '[M]Pulse', RR: '[M]RR' } } },
  observationGraphsCard: { cardActionButton: '[M]View FAQs' },
  faqDrawer: {
    drawerHeader: '[M]Help',
    drawerSubTitle: '[M]Frequently Asked Questions (FAQs)',
    faqItems: [
      {
        question: 'Info 1',
        answer: "Here's some body text to explain in more detail what you want to elaborate on fromthe subtitle",
      },
      {
        question: 'Info 2',
        answer: "Here's some body text to explain in more detail what you want to elaborate on fromthe subtitle",
      },
      {
        question: 'Info 3',
        answer: "Here's some body text to explain in more detail what you want to elaborate on fromthe subtitle",
      },
    ],
  },
};
