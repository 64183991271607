import { toErrorInfo } from '@csp/csp-common-model';
import { ErrorService } from '@csp/web-common';
import { useQuery as useQueryInternal } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { useGetErrorMessage } from '../../hooks/useGetErrorMessage';
import { QueryKey } from '../models/QueryKey';
import { UseQueryState } from '../models/UseQueryState';

type QueryFunction<T> = () => Promise<T>;

type QueryOptions<TData> = {
  onSuccess?(data: TData): void;
  enabled?: boolean;
  staleTimeMillis?: number;
  keepPreviousData?: boolean;
  refetchOnMount?: 'always' | boolean;
  refetchOnWindowFocus?: 'always' | boolean;
};

export const useQuery = <TData = unknown>(
  queryKey: QueryKey,
  queryFn: QueryFunction<TData>,
  options?: QueryOptions<TData>,
): UseQueryState<TData> => {
  const getErrorMessage = useGetErrorMessage();
  const queryKeyInternal = [queryKey.cacheKey, ...(queryKey.cacheParams ?? [])];

  const {
    error: queryError,
    data,
    isLoading,
    refetch,
    isFetching,
    remove,
  } = useQueryInternal(queryKeyInternal, queryFn, {
    enabled: options?.enabled,
    keepPreviousData: options?.keepPreviousData,
    staleTime: options?.staleTimeMillis ?? 0,
    refetchOnMount: options?.refetchOnMount ?? true,
    refetchOnWindowFocus: options?.refetchOnWindowFocus ?? true,
    onSuccess: options?.onSuccess,
    onError: error => {
      ErrorService.handleError({ error });
    },
  });

  const refresh = useCallback(async (): Promise<void> => {
    await refetch();
  }, [refetch]);

  const errorInfo = useMemo(
    () => (queryError ? toErrorInfo({ error: queryError, message: getErrorMessage(queryError) }) : undefined),
    [getErrorMessage, queryError],
  );

  return {
    data,
    isLoadingInitial: isLoading && isFetching,
    isRefreshing: isFetching,
    refresh,
    error: errorInfo,
    clear: remove,
  };
};
