export type ScheduleConfig = {
  groupByScheduleCode: boolean;
};

const defaultConfig: ScheduleConfig = {
  groupByScheduleCode: false,
};

export const ScheduleConfig = {
  default: defaultConfig,
};
