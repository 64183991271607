export type CspBaseConfig = {
  baseUrl: string;
};

const empty = (): CspBaseConfig => ({
  baseUrl: '',
});

export const CspBaseConfig = {
  empty,
};
