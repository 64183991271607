import { Body1, Box, Label2, Link } from '@csp/csp-web-ui';
import { FC } from 'react';
import { ContactInformationProps } from '../model/ContactInformationProps';

export const ContactInformation: FC<ContactInformationProps> = ({ contactLabel, email, phoneNumber }) => (
  <Box mb={3}>
    <Label2 color="textSecondary" fontWeight={700} mb={1}>
      {contactLabel}
    </Label2>
    <Body1 mb={0.5}>{phoneNumber}</Body1>
    <Link href={`mailto:${email}`}>{email}</Link>
  </Box>
);
