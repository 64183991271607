import { PatientPauseStatusMapper } from '@csp/csp-common-custom-status';
import { fromTimezoneStrOrUndefined } from '@csp/csp-common-date-util';
import {
  Maybe,
  Patient,
  PatientStatusType,
  PatientStudyStatusType,
  Site,
  User,
  ZonedDateTime,
} from '@csp/csp-common-model';
import { UserFactory } from '@csp/csp-common-user';
import { UserV1 } from '@csp/dmdp-api-user-dto';
import { PatientStatusRuleService, PatientStatusService } from '@csp/csp-common-patient-status';
import { StudyConfigService } from '@csp/csp-common-config';
import { CognitoService } from '../../auth/cognito/service/CognitoService';
import { PatientRpmEventMetaService } from '../meta/patient/service/PatientRpmEventStatusMetaService';
import { PatientAppInfoService } from '../meta/patient/service/PatientAppInfoService';
import { PatientComplianceMetaService } from '../meta/patient/service/PatientComplianceMetaService';
import { PatientHealthEventService } from '../meta/patient/service/PatientHealthEventService';
import { PatientInfoService } from '../meta/patient/service/PatientInfoService';
import { PatientMedicationMetaService } from '../meta/patient/service/PatientMedicationMetaService';
import { PatientOccasionService } from '../meta/patient/service/PatientOccasionService';
import { PatientVisitMetaService } from '../meta/patient/service/PatientVisitMetaService';
import { PatientRescreenMetaFactory } from './PatientRescreenMetaFactory';

const getPatientSite = (user: User, sites?: Site[]): Maybe<Site> => sites?.find(site => site.orgId === user.firstOrgId);

const mutateCustomStatusesWithStudyTimestamp = (
  user: User,
  screenedTimestamp: Maybe<ZonedDateTime>,
  randomizedTimestamp: Maybe<ZonedDateTime>,
): void => {
  user.customStatuses.mutateOldestStatusTimestamp(
    PatientStatusType.STUDY,
    PatientStudyStatusType.SCREENED,
    screenedTimestamp,
  );

  user.customStatuses.mutateOldestStatusTimestamp(
    PatientStatusType.STUDY,
    PatientStudyStatusType.RANDOMIZED,
    randomizedTimestamp,
  );
};

const from = (userV1: UserV1, sites: Site[] = []): Patient => {
  const user = UserFactory.from(userV1);
  const info = PatientInfoService.getPatientInfo(userV1);
  const occasions = PatientOccasionService.getPatientOccasions(user, userV1.occasions);
  const statuses = PatientStatusService.getPatientStatus(userV1);
  const isReadonly = PatientStatusService.isPatientReadonly(statuses);
  const patientAccountInfo = PatientStatusService.getPatientAccountInfo(
    user,
    statuses,
    CognitoService.getTemporaryPasswordExpiresInDays(),
  );
  const accountStatus = patientAccountInfo.status;
  const isAccountPending = patientAccountInfo.isPending;
  const isAccountActive = patientAccountInfo.isActive;
  const hasNoAccount = patientAccountInfo.hasNoAccount;
  const wasCreatedWithNoAccount = patientAccountInfo.wasCreatedWithNoAccount;
  const isInvitationExpired = patientAccountInfo.isInvitationExpired;
  const compliance = PatientComplianceMetaService.getPatientCompliance(userV1);
  const rpmEventStatus = PatientRpmEventMetaService.getPatientRpmEventStatus(userV1);
  const visits = PatientVisitMetaService.getPatientVisits(userV1);
  const healthEvent = PatientHealthEventService.getPatientHealthEvent(userV1);
  const medications = PatientMedicationMetaService.getMedication(userV1);

  const ecode = userV1.study?.ecode || 'No E-code';
  const randomizedTimestamp = fromTimezoneStrOrUndefined(userV1.study?.randomizedTimestamp);
  const screenedTimestamp = fromTimezoneStrOrUndefined(userV1.study?.screenedTimestamp);
  const appInfo = PatientAppInfoService.getPatientAppInfo(userV1);
  const site = getPatientSite(user, sites);
  const features = StudyConfigService.getStudyConfig()?.featureConfig.getSiteFeatureFlags(
    // Note: Using localization.countryCode only works because patients can only belong to 1 site.
    // We set the countryCode to the site's countryCode when enrolling the patient. This means we
    // cannot move patient to another country org without also updating it's localization.
    user.localization.countryCode,
    site?.siteNumber,
  );
  const rescreenInfo = PatientRescreenMetaFactory.fromUserV1(userV1);
  const isReadyForAdministration = !isReadonly && isAccountActive;
  const isReadyForMedication = !!features?.medication.isEnabled && isReadyForAdministration;
  const canBookVisit = PatientStatusRuleService.isBookableByStatus(statuses) && !!features?.visits.isEnabled;

  const canBookCloseoutVisit =
    PatientStatusRuleService.isCloseoutBookableByStatus(statuses) &&
    !!features?.visits.isEnabled &&
    !!features?.closeOutTracker.isEnabled;

  const canConfirmVisit = PatientStatusRuleService.isVisitConfirmationByStatus(statuses);

  const pauseStatuses = PatientPauseStatusMapper.toPauseStatuses(user.customStatuses);

  mutateCustomStatusesWithStudyTimestamp(user, screenedTimestamp, randomizedTimestamp);

  const isAnonymous = user.firstAccount?.isAnonymous ?? false;

  const temporaryPassword = undefined;

  return {
    ...user,
    accountStatus,
    appInfo,
    canBookCloseoutVisit,
    canBookVisit,
    canConfirmVisit,
    compliance,
    ecode,
    temporaryPassword,
    features,
    hasNoAccount,
    healthEvent,
    info,
    isAccountActive,
    isAccountPending,
    isAnonymous,
    isInvitationExpired,
    isReadonly,
    isReadyForAdministration,
    isReadyForMedication,
    medications,
    occasions,
    pauseStatuses,
    randomizedTimestamp,
    rescreenInfo,
    rpmEventStatus,
    screenedTimestamp,
    site,
    statuses,
    visits,
    wasCreatedWithNoAccount,
  };
};

export const PatientFactory = { from };
