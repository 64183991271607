import { ApiOptions, Maybe } from '@csp/csp-common-model';
import { OrgSchedulesMapper, OrgsSchedules } from '@csp/csp-common-scheduling';
import { toRestOptions } from '@csp/csp-fe-auth';
import { QuestionnaireRestServiceV2 } from '@csp/dmdp-api-client';
import { QuestionnaireRequest } from '../model/QuestionnaireRequest';
import { QuestionnaireRequestInfo } from '../model/QuestionnaireRequestInfo';
import { QuestionnaireSchedule } from '../model/QuestionnaireSchedule';
import { QuestionnaireType } from '../type/QuestionnaireType';

const filterSchedule = (
  schedule: QuestionnaireSchedule,
  excludeType?: Maybe<QuestionnaireType>,
): QuestionnaireSchedule => {
  if (!excludeType) {
    return schedule;
  }
  return {
    ...schedule,
    activeVersionWindows: schedule.activeVersionWindows.map(versionWindow => ({
      ...versionWindow,
      requests: versionWindow.requests.filter(request => request.config.type !== excludeType),
    })),
  };
};

const getAllActivatedQuestionnaireSchedulesForOrganizationId = async (
  organizationId: string,
  apiOptions?: ApiOptions,
  excludeType?: Maybe<QuestionnaireType>,
): Promise<QuestionnaireSchedule[]> => {
  const questionnaireActiveSchedulesV2 = await QuestionnaireRestServiceV2.getActiveSchedulesByOrganizationId(
    toRestOptions(apiOptions),
    organizationId,
  );
  const { activeSchedules = [] } = questionnaireActiveSchedulesV2;
  return activeSchedules.map(QuestionnaireSchedule.from).map(schedule => filterSchedule(schedule, excludeType));
};

const queryActivatedQuestionnaireSchedulesForOrganizationIds = async (
  organizationIds: string[],
  apiOptions?: ApiOptions,
): Promise<OrgsSchedules<QuestionnaireSchedule, QuestionnaireRequest>> => {
  const questionnaireActiveScheduleOrganizationsV2 =
    await QuestionnaireRestServiceV2.queryActiveSchedulesByOrganizationIds(toRestOptions(apiOptions), {
      orgIds: organizationIds,
    });

  return OrgsSchedules.from<QuestionnaireSchedule, QuestionnaireRequest>(
    OrgSchedulesMapper.fromV1s(questionnaireActiveScheduleOrganizationsV2, QuestionnaireSchedule.from),
  );
};

const getRequestByCodeAndVersion = async (
  scheduleCode: string,
  versionCode: string,
  requestCode: string,
  apiOptions?: ApiOptions,
): Promise<QuestionnaireRequestInfo> => {
  const questionnaireActiveScheduleV2 = await QuestionnaireRestServiceV2.getScheduleByCodeAndVersion(
    toRestOptions(apiOptions),
    scheduleCode,
    versionCode,
    requestCode,
  );
  return QuestionnaireRequestInfo.from(
    questionnaireActiveScheduleV2.scheduleCode,
    questionnaireActiveScheduleV2.version.versionCode,
    questionnaireActiveScheduleV2.version.request,
  );
};

const getAllRequestsIncludingInactive = async (apiOptions?: ApiOptions): Promise<QuestionnaireRequestInfo[]> => {
  const questionnaireSchedules = await QuestionnaireRestServiceV2.getAllSchedules(toRestOptions(apiOptions));
  return questionnaireSchedules.schedules.flatMap(schedule =>
    schedule.versions.flatMap(version =>
      version.requests.map(request =>
        QuestionnaireRequestInfo.from(schedule.scheduleCode, version.versionCode, request),
      ),
    ),
  );
};

export const QuestionnaireScheduleQueryService = {
  getAllActivatedQuestionnaireSchedulesForOrganizationId,
  getRequestByCodeAndVersion,
  getAllRequestsIncludingInactive,
  queryActivatedQuestionnaireSchedulesForOrganizationIds,
};
