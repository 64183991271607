import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryContextProvider } from '../../../common/context/query/QueryContextProvider';
import { StudyContextContent } from '../../../common/context/study/StudyContextContent';
import { NavigationContextProvider } from '../context/NavigationContextProvider';

export const StudyNavPages: FC = () => (
  <StudyContextContent>
    <QueryContextProvider>
      <NavigationContextProvider>
        <Outlet />
        {process.env.NX_REACT_QUERY_DEV_TOOLS === 'active' && <ReactQueryDevtools initialIsOpen={false} />}
      </NavigationContextProvider>
    </QueryContextProvider>
  </StudyContextContent>
);
