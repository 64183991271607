import { EnumUtil } from '@csp/csp-common-enum-util';
import { Maybe, ObjectType, StateAssert } from '@csp/csp-common-model';
import { RpmAlgorithmCode, RpmEventAlgorithmType, RpmEventConfig } from '@csp/csp-common-rpm-model';
import joi from 'joi';
import { RpmEventConfigV1 } from '../meta/RpmEventConfigV1';
import { RPM_EVENT_V1_META_NAME, RpmEventMetaV1 } from '../meta/RpmEventMetaV1';

const isSupportedVersionCode = (algorithmCode: RpmAlgorithmCode, versionCode: string): boolean => {
  switch (algorithmCode) {
    case RpmAlgorithmCode.GI_TOX:
      return versionCode === '1';
    case RpmAlgorithmCode.MARSDEN_WEIGHT_SCALE:
      return versionCode === '1';
    case RpmAlgorithmCode.MASIMO_SPO2:
      return versionCode === '1';
    case RpmAlgorithmCode.MIR_PEF:
      return versionCode === '1';
    case RpmAlgorithmCode.COMPARE_NUMERIC_WITH_THRESHOLD:
      return versionCode === '1';
    case RpmAlgorithmCode.COMPARE_OCCURRENCES_WITH_THRESHOLD:
      return versionCode === '1';
    default:
      return false;
  }
};

const isSupportedAlgorithm = (algorithm: RpmEventAlgorithmType): boolean => {
  if (!EnumUtil.is(algorithm.algorithmCode, RpmAlgorithmCode)) {
    return false;
  }
  return isSupportedVersionCode(algorithm.algorithmCode, algorithm.versionCode);
};

const giToxV1PayloadSchema = joi
  .array()
  .items(
    joi
      .object({
        questionCode: joi.string().required(),
        answerOptionCodes: joi.array().items(joi.string().required()).required(),
      })
      .required(),
  )
  .required();

const marsdenWeightScaleV1PayloadSchema = joi
  .object({
    threshold: joi.number().required(),
    unit: joi.string().required(),
    daysBack: joi.number().required(),
  })
  .required();

const masimoPulseOximeterPayloadSchema = joi
  .object({
    threshold: joi.number().required(),
  })
  .required();

const mirPefPayloadSchema = joi
  .object({
    threshold: joi.number().required(),
    unit: joi.string().required(),
    runInDays: joi.number().required(),
    consecutiveEvents: joi.number().required(),
    consecutiveDays: joi.number().required(),
  })
  .required();

const compareNumericWithThresholdPayloadSchema = joi
  .object({
    questionCode: joi.string().required(),
    threshold: joi.number().required(),
  })
  .required();

const compareOccurrencesWithThresholdPayloadSchema = joi
  .object({
    questionCode: joi.string().required(),
    answerCodesToConsider: joi.array().required(),
    threshold: joi.number().required(),
    evaluationWindowSeconds: joi.number().required(),
    timeQuestionCode: joi.string().required(),
  })
  .required();

const assertPayload = (algorithm: RpmEventAlgorithmType): void => {
  switch (algorithm.algorithmCode) {
    case RpmAlgorithmCode.GI_TOX:
      joi.assert(algorithm.payload, giToxV1PayloadSchema, { allowUnknown: true });
      break;

    case RpmAlgorithmCode.MARSDEN_WEIGHT_SCALE:
      joi.assert(algorithm.payload, marsdenWeightScaleV1PayloadSchema, { allowUnknown: true });
      break;

    case RpmAlgorithmCode.MASIMO_SPO2:
      joi.assert(algorithm.payload, masimoPulseOximeterPayloadSchema, { allowUnknown: true });
      break;

    case RpmAlgorithmCode.MIR_PEF:
      joi.assert(algorithm.payload, mirPefPayloadSchema, { allowUnknown: true });
      break;

    case RpmAlgorithmCode.COMPARE_NUMERIC_WITH_THRESHOLD:
      joi.assert(algorithm.payload, compareNumericWithThresholdPayloadSchema, { allowUnknown: true });
      break;

    case RpmAlgorithmCode.COMPARE_OCCURRENCES_WITH_THRESHOLD:
      joi.assert(algorithm.payload, compareOccurrencesWithThresholdPayloadSchema, { allowUnknown: true });
      break;
  }
};

const getRpmEventConfigFromMeta = (config: unknown): RpmEventConfig => {
  const { algorithms } = config as RpmEventConfigV1;

  StateAssert.isTrue(
    Array.isArray(algorithms),
    `algorithms must be an array in RpmEventConfig but got ${typeof algorithms}`,
  );

  const supportedAlgorithms = algorithms.filter(isSupportedAlgorithm);
  supportedAlgorithms.forEach(assertPayload);

  return {
    algorithms: supportedAlgorithms,
  };
};

const fromRpmEventConfigMetaV1 = (meta: RpmEventMetaV1 | Maybe<ObjectType>): Maybe<RpmEventConfig> => {
  const configV1 = meta?.[RPM_EVENT_V1_META_NAME];
  return configV1 ? getRpmEventConfigFromMeta(configV1) : undefined;
};

export const RpmEventConfigMapper = { fromRpmEventConfigMetaV1 };
