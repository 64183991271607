import { negate, values } from 'lodash/fp';
import { BaseForm } from '../model/BaseForm';
import { InputError } from '../model/InputError';
import { FormState } from '../model/FormState';
import { InputService } from './InputService';

const isAllValid = <T extends BaseForm>(model: T): boolean => {
  const inValidValues = Object.values(model).filter(value => {
    let inValid = false;
    let errors: InputError[] = [];
    if (InputService.notTouched(value)) {
      errors = InputService.validateValue(value, {});
    } else {
      errors = value.errors;
    }
    if (errors != null && errors.length > 0) {
      inValid = true;
    }
    return inValid;
  });
  return inValidValues.length === 0;
};

const isAnyInvalid = negate(isAllValid);

const isAnyTouched = <T extends BaseForm>(form: T): boolean => values(form).filter(InputService.touched).length > 0;

const checkFormState = <T extends BaseForm>(form: T): FormState => {
  const isDirty = isAnyTouched(form);
  const isValid = !isAnyInvalid(form);

  return {
    isDirty,
    isValid,
    isSubmittable: isDirty && isValid,
  };
};

export const FormStateService = {
  isAllValid,
  isAnyInvalid,
  checkFormState,
};
