import { RoleType } from '@csp/dmdp-api-common-dto';
import { CRARole, HCPRoles, supportUserRoles } from '../models/ContentstackAudienceRole';

export type Role = 'CRA' | 'HCP';

const includesHCPRoles = (audience: string[], target: string[]): boolean => target.some(v => audience.includes(v));

export const getContentstackAudienceRole = (userRoles: RoleType[]): Role[] => {
  const isCRA = userRoles.includes(CRARole);
  const isHCP = includesHCPRoles(userRoles, HCPRoles);
  const isSupportUser = userRoles.some(role => supportUserRoles.includes(role));

  if (isSupportUser || (isCRA && isHCP)) {
    return ['CRA', 'HCP'];
  } else if (isHCP) {
    return ['HCP'];
  } else if (isCRA) {
    return ['CRA'];
  } else {
    return [];
  }
};
