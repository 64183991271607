import {
  CloseoutTrackerStatusType,
  FeatureFlagsSummary,
  Language,
  Maybe,
  Org,
  OrgId,
  RESPONSE_STATUS_TO_STUDY_STATUS_MAP,
  Study,
  StudyStatus,
  StudySyncConfig,
  User,
  VISIBLE_STUDY_STATUSES,
} from '@csp/csp-common-model';
import { OrgMapper, StudyOrgMapper } from '@csp/csp-common-user';
import { isNotEmpty, LanguageUtil } from '@csp/csp-common-util';
import { OrgV1 } from '@csp/dmdp-api-org-dto';
import { StudyInfoV1, StudySetupV1, StudyV1 } from '@csp/dmdp-api-study-dto';
import { StudyConfigService } from '@csp/csp-common-config';
import debug from 'debug';
import { StudyStatusService } from '../service/StudyStatusService';

const log = debug('Common:Study:model');

const from = (
  tenantId: string,
  studyOrgV1: OrgV1,
  siteAndCountryOrgV1s: OrgV1[],
  { dcode, name, status }: StudyInfoV1 | StudyV1,
  user: User,
  studySetupV1?: StudySetupV1,
): Study => {
  let siteIdsWithCompliance: OrgId[] = [];
  const orgs: Org[] = [...siteAndCountryOrgV1s.map(OrgMapper.fromOrgV1), OrgMapper.fromOrgV1(studyOrgV1)];
  const studyOrg = StudyOrgMapper.fromOrgV1s([studyOrgV1, ...siteAndCountryOrgV1s]);
  const studyName = name ?? studyOrg.name;

  const studySyncConfig: StudySyncConfig | undefined = studySetupV1
    ? {
        metricsSyncEnabled: studySetupV1.automaticMetricsSyncEnabled,
        studySubjectSyncEnabled: studySetupV1.automaticStudySubjectSyncEnabled,
        studySyncEnabled: studySetupV1.automaticStudySyncEnabled,
      }
    : undefined;

  const getSupportedLanguagesBySite = (siteId: string, defaultLangCode?: string): Language[] => {
    const site = studyOrg.getSiteById(siteId);
    if (site) {
      const supportedLangCodes = studyOrg.getCountryForSite(site)?.langCodes || [];
      return supportedLangCodes.map(langCode => LanguageUtil.getLanguageByCode(langCode, defaultLangCode));
    } else {
      return defaultLangCode ? [LanguageUtil.getLanguageByCode(defaultLangCode)] : [];
    }
  };

  const mutateSiteIdsWithCompliance = (siteIds: OrgId[]): void => {
    siteIdsWithCompliance = siteIds;
  };

  const getSiteIdsWithCompliance = (): OrgId[] => siteIdsWithCompliance;
  const hasCompliance = (): boolean => !!getSiteIdsWithCompliance().length;

  const statuses = StudyStatusService.getStudyOrgStatuses(studyOrg);
  const isCloseoutPeriodActive = StudyStatusService.isCloseoutPeriodActive(statuses);
  const isCloseoutPeriodEnabled = statuses.closeoutTrackerStatus.status === CloseoutTrackerStatusType.ACTIVE;

  const study: Study = {
    get features(): Maybe<FeatureFlagsSummary> {
      /**
       * We might not have the study configuration at study creation time,
       * so defer fetching of study feature flags until the property is accessed
       */
      return StudyConfigService.getStudyConfig()?.featureConfig.getFeatureFlagsSummary(studyOrg);
    },
    get hasRpm(): boolean {
      return isNotEmpty(StudyConfigService.getStudyConfig()?.rpmConfig?.algorithms);
    },
    ...studyOrg,
    studyStatus: RESPONSE_STATUS_TO_STUDY_STATUS_MAP[status as StudyStatus] ?? StudyStatus.UNKNOWN,
    userInfo: { userId: user.userId, displayName: user.displayName, dmdpStatus: user.dmdpStatus },
    name: studyName,
    dcode,
    studySyncConfig,
    tenantId,
    orgs,
    getSupportedLanguagesBySite,
    mutateSiteIdsWithCompliance,
    getSiteIdsWithCompliance,
    hasCompliance,
    statuses,
    isCloseoutPeriodActive,
    isCloseoutPeriodEnabled,
  };

  log('Study.from output', study);

  return study;
};

const isStudyVisible = (studyStatus: StudyStatus): boolean => VISIBLE_STUDY_STATUSES.includes(studyStatus);

const fromStudyInfoV1 = (
  tenantId: string,
  studyOrgV1: OrgV1,
  siteAndCountryOrgV1s: OrgV1[],
  studyInfoV1: StudyInfoV1,
  user: User,
): Study => from(tenantId, studyOrgV1, siteAndCountryOrgV1s, studyInfoV1, user);

const fromStudyV1 = (
  tenantId: string,
  studyOrgV1: OrgV1,
  siteAndCountryOrgV1s: OrgV1[],
  studyV1: StudyV1,
  user: User,
): Study => from(tenantId, studyOrgV1, siteAndCountryOrgV1s, studyV1, user, studyV1.studySetup);

export const StudyFactory = {
  fromStudyInfoV1,
  fromStudyV1,
  isStudyVisible,
};
