import Ajv from 'ajv';
import { JsonValidationError, ObjectType } from '@csp/csp-common-model';
import { ObjectUtil } from '../util/ObjectUtil';

const ADDITIONAL_ALLOWED_KEYWORDS = [
  '_attachment',
  '_doc',
  'allowOptionalEmpty',
  'helper',
  'label',
  'mock',
  'placeholder',
  'size',
];

const strictValidator = new Ajv({
  allErrors: true,
  keywords: ADDITIONAL_ALLOWED_KEYWORDS,
  strict: true,
});

const nonStrictValidator = new Ajv({
  allErrors: true,
  keywords: ADDITIONAL_ALLOWED_KEYWORDS,
  removeAdditional: true,
  strict: false,
  useDefaults: true,
});

/**
 * Strict validation of a JSON schema. [Ajv Strict mode](https://github.com/ajv-validator/ajv/blob/master/docs/strict-mode.md) will be enabled.
 *
 * Will also throw on the following cases:
 * - When additional properties not defined in the schema are present.
 * - Non-matching enum values are found in the data.
 *
 * Use this method when you want to be really strict about the schema and data.
 */
const validateJson = (schemaName: string, schema: ObjectType, json: unknown, validator = strictValidator): void => {
  const schemaModule = (schema?.default as ObjectType) || schema;
  if (!schemaModule || !schemaModule['$schema']) {
    throw new JsonValidationError('Not a valid schema, missing "$schema" key', schemaName, schemaModule, json);
  }

  // To get the validate function from Ajv the schema goes through a
  // compilation step which is quite time consuming. Because of this Ajv
  // caches the compiled schema with the schema object reference as key by
  // default (depending on what method you use, docs are a bit unclear).
  //
  // However, this function can not rely on the schema having a stable
  // reference between calls, because we dynamically modify it to make it
  // more or less strict. In older version of Ajv the serialized version of
  // the schema was used as cache key, so to mimic that behavior we stringify
  // it and use that as cache key instead.
  const schemaKey = JSON.stringify(schema);
  let validate = validator.getSchema(schemaKey);
  if (!validate) {
    validate = validator.addSchema(schema, schemaKey).getSchema(schemaKey);
  }
  const valid = !!validate && validate(json);

  if (!valid && validate?.errors) {
    throw new JsonValidationError('Configuration not valid', schemaName, schemaModule, json, validate.errors);
  }
};

/**
 * Non-strict validation of a JSON schema. [Ajv Strict mode](https://github.com/ajv-validator/ajv/blob/master/docs/strict-mode.md) will be disabled.
 *
 * Will also allow the following:
 * - Additional properties not defined in the schema.
 * - Non-matching enum values in the data.
 *
 * Use this method when you want to allow some mismatches between the schema and data.
 */
const validateJsonNonStrict = (schemaName: string, schema: ObjectType, json: unknown): void => {
  const cleanedSchema = ObjectUtil.omitRecursively(schema, ['enum']);

  validateJson(schemaName, cleanedSchema, json, nonStrictValidator);
};

export const JsonValidationService = {
  validateJson,
  validateJsonNonStrict,
};
