import { ApiOptions, Maybe } from '@csp/csp-common-model';
import { toRestOptions } from '@csp/csp-fe-auth';
import { DeviceSessionsRestServiceV1 } from '@csp/dmdp-api-client';
import { Page } from '@csp/dmdp-api-common-dto';
import { DeviceSessionInV1, DeviceSessionQueryV1, DeviceSessionV1 } from '@csp/dmdp-api-device-dto';
import { map } from 'lodash/fp';
import { DeviceSession } from '../model/DeviceSession';
import { SessionsQueryResponse } from '../model/SessionsQueryResponse';

const toDeviceSessions = map<DeviceSessionV1, DeviceSession>(DeviceSession.from);

const createDeviceSession = async (
  deviceSessionInV1: DeviceSessionInV1,
  apiOptions?: ApiOptions,
): Promise<DeviceSession> => {
  const sessionV1 = await DeviceSessionsRestServiceV1.createDeviceSession(toRestOptions(apiOptions), deviceSessionInV1);
  return DeviceSession.from(sessionV1);
};

const getSessionById = async (deviceSessionId: string, apiOptions?: ApiOptions): Promise<Maybe<DeviceSession>> => {
  const sessionV1 = await DeviceSessionsRestServiceV1.getDeviceSession(toRestOptions(apiOptions), deviceSessionId);
  return sessionV1 ? DeviceSession.from(sessionV1) : undefined;
};

const queryUserDeviceSessions = async (
  query: DeviceSessionQueryV1,
  page: Page,
  apiOptions?: ApiOptions,
): Promise<SessionsQueryResponse> => {
  const { sessions, paging = {} } = await DeviceSessionsRestServiceV1.queryDeviceSessions(
    toRestOptions(apiOptions),
    query,
    page,
  );

  return {
    sessions: toDeviceSessions(sessions),
    paging,
  };
};

export const DeviceSessionService = {
  createDeviceSession,
  getSessionById,
  queryUserDeviceSessions,
};
