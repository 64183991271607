import { StudyConfigService } from '@csp/csp-common-config';
import { DeviceObservationRequest, DeviceObservationScheduleRequestInfo } from '@csp/csp-common-devices';
import { EnumUtil } from '@csp/csp-common-enum-util';
import { Maybe, MetricPeriod, MetricPeriodUnitType, StudyPeriodConfig } from '@csp/csp-common-model';
import { ScheduleRequestMetricCriterion } from '../../model/ScheduleRequestMetricCriterion';

const getDaysPerMonthFromStudyConfig = (): number =>
  StudyConfigService.getStudyConfig()?.periodConfig.getNumberOfDaysByUnit('MONTH') ??
  StudyPeriodConfig.DEFAULT_DAYS_IN_MONTH;

const toHistoricalMetricPeriodOrUndefined = (request: DeviceObservationRequest): Maybe<MetricPeriod> =>
  request.historicalMetricPeriod
    ? {
        unit: EnumUtil.fromStringOrError<MetricPeriodUnitType>(
          request.historicalMetricPeriod.unit,
          MetricPeriodUnitType,
        ),
        amount: request.historicalMetricPeriod.amount,
      }
    : undefined;

const toMetricCriteria = (
  deviceObservationRequests: DeviceObservationScheduleRequestInfo[],
): ScheduleRequestMetricCriterion[] => {
  const daysPerMonth = getDaysPerMonthFromStudyConfig();

  return deviceObservationRequests.map(({ ref, request }) =>
    ScheduleRequestMetricCriterion.from({
      recentPeriod: toHistoricalMetricPeriodOrUndefined(request),
      daysPerMonth,
      scheduleRef: ref,
      minComplianceThreshold: request.minComplianceThreshold,
    }),
  );
};

export const DeviceMetricCriteriaMapper = {
  toMetricCriteria,
};
