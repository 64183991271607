/* eslint-disable */
export const UnifyHFE_V1Mock = {
  title: 'title',
  version: '1',
  HFE01: {
    questionnaireItemType: 'QUESTION',
    questionType: 'RADIO_BUTTON',
    headerMd: '[M]Have you been hospitalized due to heart failure within the last four weeks?',
    optional: false,
    answers: {
      '1': { text: '[M]Yes', type: 'TEXT_WITH_NAVIGATION', freeTextAnswerSupport: false, nextId: 'HFE02' },
      '2': { text: '[M]No', type: 'TEXT_WITH_NAVIGATION', freeTextAnswerSupport: false },
    },
  },
  HFE02: {
    questionnaireItemType: 'QUESTION',
    questionType: 'RADIO_BUTTON',
    headerMd: '[M]Did your hospitalisation include an overnight stay?',
    optional: false,
    answers: {
      '1': {
        text: '[M]Yes',
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'HFE01',
        nextId: 'HFE03',
      },
      '2': {
        text: '[M]No',
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'HFE01',
        nextId: 'HFE03',
      },
    },
  },
  HFE03: {
    questionnaireItemType: 'QUESTION',
    questionType: 'RADIO_BUTTON',
    headerMd: '[M]Prior to the hospitalisation, did you experience shortness of breath?',
    optional: false,
    answers: {
      '1': {
        text: '[M]Yes',
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'HFE02',
        nextId: 'HFE04',
      },
      '2': {
        text: '[M]No',
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'HFE02',
        nextId: 'HFE04',
      },
    },
  },
  HFE04: {
    questionnaireItemType: 'QUESTION',
    questionType: 'RADIO_BUTTON',
    headerMd: '[M]Prior to the hospitalisation, did you experience fatigue?',
    optional: false,
    answers: {
      '1': {
        text: '[M]Yes',
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'HFE03',
        nextId: 'HFE05',
      },
      '2': {
        text: '[M]No',
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'HFE05',
        nextId: 'HFE05',
      },
    },
  },
  HFE05: {
    questionnaireItemType: 'QUESTION',
    questionType: 'RADIO_BUTTON',
    headerMd:
      '[M]Prior to the hospitalisation, did you experience a limited ability to perform daily physical activities?',
    optional: false,
    answers: {
      '1': {
        text: '[M]Yes',
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'HFE04',
        nextId: 'HFE06',
      },
      '2': {
        text: '[M]No',
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'HFE04',
        nextId: 'HFE06',
      },
    },
  },
  HFE06: {
    questionnaireItemType: 'QUESTION',
    questionType: 'RADIO_BUTTON',
    headerMd:
      '[M]Did you receive additional diuretic treatment (water pill or as injection) to ease your symptoms during your hospitalisation?',
    optional: false,
    answers: {
      '1': {
        text: '[M]Yes',
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'HFE05',
        nextId: 'HFE07',
      },
      '2': {
        text: '[M]No',
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'HFE05',
        nextId: 'HFE07',
      },
      '3': {
        text: "[M]Don't know",
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'HFE05',
        nextId: 'HFE07',
      },
    },
  },
  HFE07: {
    questionnaireItemType: 'QUESTION',
    questionType: 'RADIO_BUTTON',
    headerMd: '[M]Was the main reason for your symptoms that led to the hospitalisation a diagnosis of heart failure?',
    optional: false,
    answers: {
      '1': { text: '[M]Yes', type: 'TEXT_WITH_NAVIGATION', freeTextAnswerSupport: false, previousId: 'HFE06' },
      '2': { text: '[M]No', type: 'TEXT_WITH_NAVIGATION', freeTextAnswerSupport: false, previousId: 'HFE06' },
      '3': { text: "[M]Don't know", type: 'TEXT_WITH_NAVIGATION', freeTextAnswerSupport: false, previousId: 'HFE06' },
    },
  },
};
