/* eslint-disable */
export const UnifyStudyRequestTagsMock = {
  tagGroups: [
    {
      tagGroupKey: 'LOCATION',
      tagGroupName: '[M] Location',
      tags: [
        { tagCode: 'F2F', name: '[M] Face to Face' },
        { tagCode: 'REMOTE', name: '[M] Remote' },
        { tagCode: 'HYBRID', name: '[M] Hybrid' },
      ],
    },
  ],
};
