export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type ActivatorCardContent = {
  __typename?: 'ActivatorCardContent';
  articleId: Scalars['String'];
  imageUrl: Scalars['String'];
  title: Scalars['String'];
};

export type ActivatorDateTime = {
  __typename?: 'ActivatorDateTime';
  windowEnd: Scalars['Float'];
  windowStart: Scalars['Float'];
  zone: Scalars['String'];
};

export type ActivatorDefinition = {
  __typename?: 'ActivatorDefinition';
  cardContent: ActivatorCardContent;
  type: Scalars['String'];
};

export type ActivatorList = {
  __typename?: 'ActivatorList';
  activator: ActivatorDefinition;
  dateTime: ActivatorDateTime;
};

export type ActivatorTimeline = {
  __typename?: 'ActivatorTimeline';
  activators: Array<Maybe<ActivatorList>>;
  id: Scalars['ID'];
  questionnaireCode: Scalars['ID'];
};

export type ActivatorsTimelineSearchRoot = {
  __typename?: 'ActivatorsTimelineSearchRoot';
  getActivatorTimeline: ActivatorTimeline;
};

export type ActivatorsTimelineSearchRootGetActivatorTimelineArgs = {
  input: GetActivatorTimelineQueryInV1;
};

export type AdvancedNumericRatingScaleDefinitionV1 = QuestionDefinitionV1 & {
  __typename?: 'AdvancedNumericRatingScaleDefinitionV1';
  advancedNumericRatingScaleNextQuestionBranches: Array<QuestionAdvancedNumericRatingScaleNextQuestionBranchV1>;
  baseUnit?: Maybe<Scalars['String']>;
  bodyMd?: Maybe<Scalars['String']>;
  conditionalSubBranches?: Maybe<Array<QuestionConditionalSubBranchV1>>;
  defaultNextQuestionCode: Scalars['String'];
  errorValueTooHigh?: Maybe<Scalars['String']>;
  errorValueTooLow?: Maybe<Scalars['String']>;
  heading: Scalars['String'];
  headingMd?: Maybe<Scalars['String']>;
  invalidFormatMessage?: Maybe<Scalars['String']>;
  jsonMeta?: Maybe<Scalars['String']>;
  keyboardEntryDescriptionMd?: Maybe<Scalars['String']>;
  keyboardEntryHeaderMd?: Maybe<Scalars['String']>;
  keyboardEntryNumberBoxHeader?: Maybe<OptionalLabelledControlV1>;
  mandatory: Scalars['Boolean'];
  maxLabelMd?: Maybe<Scalars['String']>;
  meta?: Maybe<AdvancedNumericRatingScaleQuestionDefinitionMetaV1>;
  minLabelMd?: Maybe<Scalars['String']>;
  numberBoxHeader?: Maybe<OptionalLabelledControlV1>;
  numberOfDecimals: Scalars['Int'];
  questionCode: Scalars['String'];
  ranges: Array<AdvancedNumericRatingScaleRangeV1>;
  sliderAccessibilityHint?: Maybe<Scalars['String']>;
  widgetType: WidgetTypeV1;
};

export type AdvancedNumericRatingScaleQuestionDefinitionConfigV1 = {
  __typename?: 'AdvancedNumericRatingScaleQuestionDefinitionConfigV1';
  isNumberBoxEditable: Scalars['Boolean'];
  isNumberBoxVisible: Scalars['Boolean'];
  isSliderVisible: Scalars['Boolean'];
  orientation: DeviceOrientationV2;
};

export type AdvancedNumericRatingScaleQuestionDefinitionMetaV1 = {
  __typename?: 'AdvancedNumericRatingScaleQuestionDefinitionMetaV1';
  CONFIG_V1?: Maybe<AdvancedNumericRatingScaleQuestionDefinitionConfigV1>;
};

export type AdvancedNumericRatingScaleRangeV1 = {
  __typename?: 'AdvancedNumericRatingScaleRangeV1';
  unit?: Maybe<Scalars['String']>;
  unitMaxValue: Scalars['Float'];
  unitMinValue: Scalars['Float'];
  unitOffset?: Maybe<Scalars['Float']>;
  unitScale?: Maybe<Scalars['Float']>;
  unitStep?: Maybe<Scalars['Float']>;
};

export type AlgorithmConfig = {
  algorithmCode: Scalars['String'];
  recommender: RecommenderConfigV1;
  solver: SolverConfigV1;
};

export enum AnsweredConsentStatusTypeV1 {
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
}

export type AnsweredConsentStatusV1 = {
  __typename?: 'AnsweredConsentStatusV1';
  status: AnsweredConsentStatusTypeV1;
  statusUpdateTimestamp: Scalars['String'];
};

export type AnsweredConsentTypeV1 = {
  __typename?: 'AnsweredConsentTypeV1';
  approvalId?: Maybe<Scalars['String']>;
  artifact: ArtifactV1;
  consentStatus: AnsweredConsentStatusV1;
  document: ArtifactConsentDocumentV1;
  id: Scalars['ID'];
  mandatory: Scalars['Boolean'];
};

export type AnsweredConsentsGenericContentV1 = {
  __typename?: 'AnsweredConsentsGenericContentV1';
  acceptButton: ConsentSystemTextsButtonV1;
  acceptedDate: Scalars['String'];
  acceptedLabel: Scalars['String'];
  acceptedTodayLabel: Scalars['String'];
  acceptedYesterdayLabel: Scalars['String'];
  changeConsentButton: ConsentSystemTextsButtonV1;
  closeDeclineAlertButton: ConsentSystemTextsButtonV1;
  declineButton: ConsentSystemTextsButtonV1;
  declinedDate: Scalars['String'];
  declinedLabel: Scalars['String'];
  declinedTodayLabel: Scalars['String'];
  declinedYesterdayLabel: Scalars['String'];
  withdrawAlertBody: Scalars['String'];
  withdrawAlertHeader: Scalars['String'];
  withdrawAlertNoButton: ConsentSystemTextsButtonV1;
  withdrawAlertYesButton: ConsentSystemTextsButtonV1;
  withdrawButton: ConsentSystemTextsButtonV1;
};

export type AnsweredConsentsV1 = {
  __typename?: 'AnsweredConsentsV1';
  consents: Array<AnsweredConsentTypeV1>;
  content: AnsweredConsentsGenericContentV1;
  id: Scalars['ID'];
};

export type AppointmentNotificationPayloadV1 = {
  __typename?: 'AppointmentNotificationPayloadV1';
  appointmentId: Scalars['String'];
};

export type ApprovedConsentFormV1 = {
  __typename?: 'ApprovedConsentFormV1';
  document: ArtifactConsentDocumentV1;
  id: Scalars['ID'];
};

export type ApprovedConsentsV1 = {
  __typename?: 'ApprovedConsentsV1';
  approvedConsents: Array<ApprovedConsentFormV1>;
  id: Scalars['ID'];
};

export type ArticleNotificationPayloadV1 = {
  __typename?: 'ArticleNotificationPayloadV1';
  articleId: Scalars['String'];
};

export type ArtifactConsentDocumentV1 = {
  __typename?: 'ArtifactConsentDocumentV1';
  bodyMd: Scalars['String'];
  declineAlertWarningText: Scalars['String'];
  header: Scalars['String'];
  readMoreButtonText: Scalars['String'];
  readMoreButtonTextAccessibilityHint: Scalars['String'];
  readMoreButtonTextAccessibilityLabel: Scalars['String'];
  subHeader: Scalars['String'];
};

export type ArtifactV1 = {
  __typename?: 'ArtifactV1';
  artifactCode: Scalars['String'];
  documentReference: Scalars['String'];
};

export type AssessmentConsequenceActivityCardConfigV1 = {
  __typename?: 'AssessmentConsequenceActivityCardConfigV1';
  ttlInSeconds?: Maybe<Scalars['Int']>;
};

export type BooleanQuestionAnswer = {
  __typename?: 'BooleanQuestionAnswer';
  accessibilityHint?: Maybe<Scalars['String']>;
  accessibilityLabel?: Maybe<Scalars['String']>;
  text: Scalars['String'];
};

export type BooleanQuestionDefinitionV1 = QuestionDefinitionV1 & {
  __typename?: 'BooleanQuestionDefinitionV1';
  bodyMd?: Maybe<Scalars['String']>;
  booleanQuestionNextQuestionBranches: Array<BooleanQuestionNextQuestionBranches>;
  conditionalSubBranches?: Maybe<Array<QuestionConditionalSubBranchV1>>;
  defaultNextQuestionCode?: Maybe<Scalars['String']>;
  falseAnswer: BooleanQuestionAnswer;
  heading: Scalars['String'];
  headingMd?: Maybe<Scalars['String']>;
  jsonMeta?: Maybe<Scalars['String']>;
  mandatory: Scalars['Boolean'];
  questionCode: Scalars['String'];
  scrollDownHint: ScrollDownHintV1;
  trueAnswer: BooleanQuestionAnswer;
  widgetType: WidgetTypeV1;
};

export type BooleanQuestionNextQuestionBranches = {
  __typename?: 'BooleanQuestionNextQuestionBranches';
  customTriggers: Array<CustomTriggerV1>;
  nextQuestionCode: Scalars['String'];
  value: Scalars['Boolean'];
};

export type CircleAreaV1 = {
  __typename?: 'CircleAreaV1';
  coordinate: CoordinateV1;
  radius: Scalars['Int'];
};

export type CloseAlert = {
  __typename?: 'CloseAlert';
  body: Scalars['String'];
  heading: Scalars['String'];
  noButton: OptionalLabelledControlV1;
  yesButton: OptionalLabelledControlV1;
};

export type ConsentConfigV1 = {
  __typename?: 'ConsentConfigV1';
  mandatory: Scalars['Boolean'];
  onFeature: Array<ConsentOnFeatureV1>;
};

export type ConsentFeatureOnboardingTypeV1 = ConsentOnFeatureV1 & {
  __typename?: 'ConsentFeatureOnboardingTypeV1';
  type: ConsentFeatureTypeV1;
};

export type ConsentFeatureTelehealthTypeV1 = ConsentOnFeatureV1 & {
  __typename?: 'ConsentFeatureTelehealthTypeV1';
  type: ConsentFeatureTypeV1;
};

export enum ConsentFeatureTypeV1 {
  ON_ONBOARDING = 'ON_ONBOARDING',
  TELEHEALTH = 'TELEHEALTH',
}

export type ConsentFormV1 = PendingConsentV1 & {
  __typename?: 'ConsentFormV1';
  artifact: ArtifactV1;
  config: ConsentConfigV1;
  consentType: ConsentTypeV1;
  document: ArtifactConsentDocumentV1;
  id: Scalars['ID'];
};

export type ConsentOnFeatureV1 = {
  type: ConsentFeatureTypeV1;
};

export type ConsentSearchRoot = {
  __typename?: 'ConsentSearchRoot';
  getAllAnsweredConsents: AnsweredConsentsV1;
  getApprovedConsents: ApprovedConsentsV1;
  getPendingConsents: PendingConsentsV1;
};

export type ConsentSearchRootGetAllAnsweredConsentsArgs = {
  input: GetAllAnsweredConsentsQueryInV1;
};

export type ConsentSearchRootGetPendingConsentsArgs = {
  input: GetPendingConsentsQueryInV1;
};

export type ConsentSystemTextsButtonV1 = {
  __typename?: 'ConsentSystemTextsButtonV1';
  accessibilityHint: Scalars['String'];
  accessibilityLabel: Scalars['String'];
  label: Scalars['String'];
};

export type ConsentSystemTextsDeclineAlertV1 = {
  __typename?: 'ConsentSystemTextsDeclineAlertV1';
  closeButton: ConsentSystemTextsButtonV1;
  title: Scalars['String'];
};

export type ConsentSystemTextsV1 = {
  __typename?: 'ConsentSystemTextsV1';
  acceptButton: ConsentSystemTextsButtonV1;
  declineAlert: ConsentSystemTextsDeclineAlertV1;
  declineButton: ConsentSystemTextsButtonV1;
};

export enum ConsentTypeV1 {
  CONSENT_FORM_V1 = 'CONSENT_FORM_V1',
}

export type ControlV1 = {
  __typename?: 'ControlV1';
  accessibilityHint: Scalars['String'];
  accessibilityLabel: Scalars['String'];
};

export type CoordinateInV1 = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type CoordinateInputV1 = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type CoordinateV1 = {
  __typename?: 'CoordinateV1';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type CopingTipAlgorithmConfig = {
  __typename?: 'CopingTipAlgorithmConfig';
  algorithmCode: Scalars['String'];
  algorithmVersion: Scalars['String'];
  config: AlgorithmConfig;
  nrOfCopingTipsToBePresented: Scalars['Int'];
};

export enum CopingTipAlgorithmVersion {
  V1 = 'V1',
}

export type CopingTipConfigV1 = {
  __typename?: 'CopingTipConfigV1';
  algorithmConfig: CopingTipAlgorithmConfig;
  copingTips: Array<CopingTipV1>;
};

export enum CopingTipModuleV1 {
  GI_TOX = 'GI_TOX',
  SYMPTOM_TRACKER = 'SYMPTOM_TRACKER',
}

export type CopingTipPriority = {
  __typename?: 'CopingTipPriority';
  priority: Scalars['Int'];
  symptomGroup: Scalars['String'];
};

export type CopingTipV1 = {
  __typename?: 'CopingTipV1';
  code: Scalars['String'];
  copingTipPriorities?: Maybe<Array<CopingTipPriority>>;
};

export type CounterDefinitionV1 = QuestionDefinitionV1 & {
  __typename?: 'CounterDefinitionV1';
  allowDirectKeyboardInput: Scalars['Boolean'];
  bodyMd?: Maybe<Scalars['String']>;
  conditionalSubBranches?: Maybe<Array<QuestionConditionalSubBranchV1>>;
  counterInputAccessibilityHint?: Maybe<Scalars['String']>;
  counterInputAccessibilityLabel?: Maybe<Scalars['String']>;
  counterNextQuestionBranches: Array<CounterNextQuestionBranchingV1>;
  decreaseButtonAccessibilityHint?: Maybe<Scalars['String']>;
  decreaseButtonAccessibilityLabel?: Maybe<Scalars['String']>;
  defaultNextQuestionCode?: Maybe<Scalars['String']>;
  errorValueTooHigh?: Maybe<Scalars['String']>;
  errorValueTooLow?: Maybe<Scalars['String']>;
  heading: Scalars['String'];
  headingMd?: Maybe<Scalars['String']>;
  increaseButtonAccessibilityHint?: Maybe<Scalars['String']>;
  increaseButtonAccessibilityLabel?: Maybe<Scalars['String']>;
  initialValue: Scalars['Float'];
  inputLabel: Scalars['String'];
  invalidFormatMessage?: Maybe<Scalars['String']>;
  jsonMeta?: Maybe<Scalars['String']>;
  mandatory: Scalars['Boolean'];
  maxValueInclusiveFloat: Scalars['Float'];
  minValueInclusiveFloat: Scalars['Float'];
  numberOfDecimals: Scalars['Int'];
  questionCode: Scalars['String'];
  step: Scalars['Float'];
  stepUi?: Maybe<Scalars['Float']>;
  widgetType: WidgetTypeV1;
};

export type CounterNextQuestionBranchingV1 = {
  __typename?: 'CounterNextQuestionBranchingV1';
  maxValueInclusiveFloat: Scalars['Float'];
  minValueInclusiveFloat: Scalars['Float'];
  nextQuestionCode: Scalars['String'];
};

export type CreateQuestionnaireResponsesBatchInV2 = {
  questionnaireBatchItems: Array<QuestionnaireBatchItem>;
};

export type CustomFunctionAreAllSelectedParametersV1 = {
  __typename?: 'CustomFunctionAreAllSelectedParametersV1';
  answerCodes: Array<Scalars['String']>;
  questionCode: Scalars['String'];
};

export type CustomFunctionIsAnySelectedParametersV1 = {
  __typename?: 'CustomFunctionIsAnySelectedParametersV1';
  answerCodes: Array<Scalars['String']>;
  questionCode: Scalars['String'];
};

export type CustomFunctionIsBetweenParametersV1 = {
  __typename?: 'CustomFunctionIsBetweenParametersV1';
  max: Scalars['Float'];
  min: Scalars['Float'];
  questionCode: Scalars['String'];
};

export type CustomFunctionIsEqualParametersV1 = {
  __typename?: 'CustomFunctionIsEqualParametersV1';
  questionCode: Scalars['String'];
  value: Scalars['Float'];
};

export type CustomFunctionIsGreaterOrEqualParametersV1 = {
  __typename?: 'CustomFunctionIsGreaterOrEqualParametersV1';
  questionCode: Scalars['String'];
  value: Scalars['Float'];
};

export type CustomFunctionIsGreaterThanParametersV1 = {
  __typename?: 'CustomFunctionIsGreaterThanParametersV1';
  questionCode: Scalars['String'];
  value: Scalars['Float'];
};

export type CustomFunctionIsLessOrEqualParametersV1 = {
  __typename?: 'CustomFunctionIsLessOrEqualParametersV1';
  questionCode: Scalars['String'];
  value: Scalars['Float'];
};

export type CustomFunctionIsLessThanParametersV1 = {
  __typename?: 'CustomFunctionIsLessThanParametersV1';
  questionCode: Scalars['String'];
  value: Scalars['Float'];
};

export type CustomFunctionIsSelectedParametersV1 = {
  __typename?: 'CustomFunctionIsSelectedParametersV1';
  answerCode: Scalars['String'];
  questionCode: Scalars['String'];
};

export type CustomFunctionIsSkippedParametersV1 = {
  __typename?: 'CustomFunctionIsSkippedParametersV1';
  questionCode: Scalars['String'];
};

export type CustomFunctionIsSumGreaterOrEqualParametersV1 = {
  __typename?: 'CustomFunctionIsSumGreaterOrEqualParametersV1';
  questionCode: Scalars['String'];
  value: Scalars['Float'];
};

export type CustomFunctionIsSumGreaterThanParametersV1 = {
  __typename?: 'CustomFunctionIsSumGreaterThanParametersV1';
  questionCode: Scalars['String'];
  value: Scalars['Float'];
};

export type CustomFunctionIsSumLessOrEqualParametersV1 = {
  __typename?: 'CustomFunctionIsSumLessOrEqualParametersV1';
  questionCode: Scalars['String'];
  value: Scalars['Float'];
};

export type CustomFunctionIsSumLessThanParametersV1 = {
  __typename?: 'CustomFunctionIsSumLessThanParametersV1';
  questionCode: Scalars['String'];
  value: Scalars['Float'];
};

export enum CustomFunctionNameV1 {
  ARE_ALL_SELECTED = 'ARE_ALL_SELECTED',
  IS_ANY_SELECTED = 'IS_ANY_SELECTED',
  IS_BETWEEN = 'IS_BETWEEN',
  IS_EQUAL = 'IS_EQUAL',
  IS_GREATER_OR_EQUAL = 'IS_GREATER_OR_EQUAL',
  IS_GREATER_THAN = 'IS_GREATER_THAN',
  IS_LESS_OR_EQUAL = 'IS_LESS_OR_EQUAL',
  IS_LESS_THAN = 'IS_LESS_THAN',
  IS_SELECTED = 'IS_SELECTED',
  IS_SKIPPED = 'IS_SKIPPED',
  IS_SUM_GREATER_OR_EQUAL = 'IS_SUM_GREATER_OR_EQUAL',
  IS_SUM_GREATER_THAN = 'IS_SUM_GREATER_THAN',
  IS_SUM_LESS_OR_EQUAL = 'IS_SUM_LESS_OR_EQUAL',
  IS_SUM_LESS_THAN = 'IS_SUM_LESS_THAN',
}

export type CustomFunctionParametersV1 =
  | CustomFunctionAreAllSelectedParametersV1
  | CustomFunctionIsAnySelectedParametersV1
  | CustomFunctionIsBetweenParametersV1
  | CustomFunctionIsEqualParametersV1
  | CustomFunctionIsGreaterOrEqualParametersV1
  | CustomFunctionIsGreaterThanParametersV1
  | CustomFunctionIsLessOrEqualParametersV1
  | CustomFunctionIsLessThanParametersV1
  | CustomFunctionIsSelectedParametersV1
  | CustomFunctionIsSkippedParametersV1
  | CustomFunctionIsSumGreaterOrEqualParametersV1
  | CustomFunctionIsSumGreaterThanParametersV1
  | CustomFunctionIsSumLessOrEqualParametersV1
  | CustomFunctionIsSumLessThanParametersV1;

export enum CustomTriggerCodeV1 {
  CREATE_HEALTH_EVENT = 'CREATE_HEALTH_EVENT',
}

export type CustomTriggerV1 = {
  triggerCode: CustomTriggerCodeV1;
};

export type DateDefinitionV1 = QuestionDefinitionV1 & {
  __typename?: 'DateDefinitionV1';
  bodyMd?: Maybe<Scalars['String']>;
  clearButton: LabelledControlV1;
  closeButton: ControlV1;
  conditionalSubBranches?: Maybe<Array<QuestionConditionalSubBranchV1>>;
  confirmButton: LabelledControlV1;
  dateSelector: ControlV1;
  defaultNextQuestionCode?: Maybe<Scalars['String']>;
  editButton: LabelledControlV1;
  heading: Scalars['String'];
  headingMd?: Maybe<Scalars['String']>;
  helperText?: Maybe<Scalars['String']>;
  jsonMeta?: Maybe<Scalars['String']>;
  mandatory: Scalars['Boolean'];
  maxDateOffset?: Maybe<DayOffsetV1>;
  minDateOffset?: Maybe<DayOffsetV1>;
  questionCode: Scalars['String'];
  selectButton: LabelledControlV1;
  valueLabel?: Maybe<Scalars['String']>;
  widgetType: WidgetTypeV1;
};

export type DateTimeDefinitionV1 = QuestionDefinitionV1 & {
  __typename?: 'DateTimeDefinitionV1';
  bodyMd?: Maybe<Scalars['String']>;
  clearButton: LabelledControlV1;
  closeButton: ControlV1;
  conditionalSubBranches?: Maybe<Array<QuestionConditionalSubBranchV1>>;
  confirmButton: LabelledControlV1;
  dateHelperText?: Maybe<Scalars['String']>;
  dateSelector: ControlV1;
  dateValueLabel: Scalars['String'];
  defaultNextQuestionCode?: Maybe<Scalars['String']>;
  editButton: LabelledControlV1;
  heading: Scalars['String'];
  headingMd?: Maybe<Scalars['String']>;
  jsonMeta?: Maybe<Scalars['String']>;
  mandatory: Scalars['Boolean'];
  maxDateOffset?: Maybe<DayOffsetV1>;
  maxTime?: Maybe<Scalars['String']>;
  minDateOffset?: Maybe<DayOffsetV1>;
  minTime?: Maybe<Scalars['String']>;
  questionCode: Scalars['String'];
  restrictFuture?: Maybe<Scalars['Boolean']>;
  restrictPast?: Maybe<Scalars['Boolean']>;
  selectButton: LabelledControlV1;
  stepMinutes?: Maybe<Scalars['Int']>;
  stepUIMinutes?: Maybe<Scalars['Int']>;
  timeHelperText?: Maybe<Scalars['String']>;
  timeSelector: ControlV1;
  timeValueLabel: Scalars['String'];
  widgetType: WidgetTypeV1;
};

export type DayOffsetV1 = {
  __typename?: 'DayOffsetV1';
  unit: UnitOfDateV1;
  value: Scalars['Int'];
};

export type DeviceModelReferenceV1 = {
  __typename?: 'DeviceModelReferenceV1';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type DeviceObservationNotificationPayloadV1 = {
  __typename?: 'DeviceObservationNotificationPayloadV1';
  deviceModelReferences: Array<DeviceModelReferenceV1>;
  repeatIndex: Scalars['Int'];
  requestCode: Scalars['String'];
  scheduleCode: Scalars['String'];
  versionCode: Scalars['String'];
};

export enum DeviceOrientationV2 {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

export type EnabledDevices = {
  __typename?: 'EnabledDevices';
  deviceModels: Array<Scalars['String']>;
  observationTypes: Array<Scalars['String']>;
};

export type EndOfSubBranchDefinitionV1 = QuestionDefinitionV1 & {
  __typename?: 'EndOfSubBranchDefinitionV1';
  bodyMd?: Maybe<Scalars['String']>;
  defaultNextQuestionCode?: Maybe<Scalars['String']>;
  heading: Scalars['String'];
  headingMd?: Maybe<Scalars['String']>;
  jsonMeta?: Maybe<Scalars['String']>;
  questionCode: Scalars['String'];
  widgetType: WidgetTypeV1;
};

export type ErrorV1 = {
  __typename?: 'ErrorV1';
  errorId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type ExternalIdV1 = {
  __typename?: 'ExternalIdV1';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type ExternalIdV1Input = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type FeatureConfigSearchRoot = {
  __typename?: 'FeatureConfigSearchRoot';
  flatAppFeatureConfigByCountry?: Maybe<FlatFeatureConfigV1>;
};

export type FeatureConfigSearchRootFlatAppFeatureConfigByCountryArgs = {
  countryCode: Scalars['String'];
};

export type FeatureConfigSearchRootV2 = {
  __typename?: 'FeatureConfigSearchRootV2';
  getConfig: MobileFeatureConfigV2;
};

export type FeatureConfigSearchRootV2GetConfigArgs = {
  input: MobileConfigQueryInV1;
};

export type FlatFeatureConfigV1 = {
  __typename?: 'FlatFeatureConfigV1';
  countries: Scalars['Boolean'];
  devices: Scalars['Boolean'];
  eCOA: Scalars['Boolean'];
  generalEnablePatientPinCode: Scalars['Boolean'];
  geolocation: Scalars['Boolean'];
  healthEvent: Scalars['Boolean'];
  id: Scalars['ID'];
  medication: Scalars['Boolean'];
  notifications: Scalars['Boolean'];
  notificationsContent: Scalars['Boolean'];
  notificationsECOA: Scalars['Boolean'];
  notificationsHealthEvent: Scalars['Boolean'];
  notificationsPatientVisit: Scalars['Boolean'];
  notificationsSPFQ: Scalars['Boolean'];
  questionnaires: Scalars['Boolean'];
  rpmEventFeedback: Scalars['Boolean'];
  spfq: Scalars['Boolean'];
  studyHelp: Scalars['Boolean'];
  studyHelpHelpByPhone: Scalars['Boolean'];
};

export type FreeTextDefinitionV1 = QuestionDefinitionV1 & {
  __typename?: 'FreeTextDefinitionV1';
  bodyMd?: Maybe<Scalars['String']>;
  charactersRemainingLabel?: Maybe<Scalars['String']>;
  conditionalSubBranches?: Maybe<Array<QuestionConditionalSubBranchV1>>;
  defaultNextQuestionCode?: Maybe<Scalars['String']>;
  errorMandatory: Scalars['String'];
  errorMaxCharacterCountReached: Scalars['String'];
  heading: Scalars['String'];
  headingMd?: Maybe<Scalars['String']>;
  inputAccessibilityHint: Scalars['String'];
  inputAccessibilityLabel: Scalars['String'];
  inputLabel: Scalars['String'];
  jsonMeta?: Maybe<Scalars['String']>;
  mandatory: Scalars['Boolean'];
  maxCharacterCountInclusive: Scalars['Int'];
  minCharacterCountInclusive: Scalars['Int'];
  questionCode: Scalars['String'];
  widgetType: WidgetTypeV1;
};

export type GeoFenceMetaV1 = {
  __typename?: 'GeoFenceMetaV1';
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type GeofenceEventInV1 = {
  coordinate: CoordinateInputV1;
  eventType: GeofenceEventTypeV1;
  geofenceDefinitionId: Scalars['String'];
  referenceTimestamp: Scalars['String'];
  refs: Array<GeofenceEventRefsInV1>;
};

export type GeofenceEventMutationRoot = {
  __typename?: 'GeofenceEventMutationRoot';
  createV1?: Maybe<GeofenceEventV1>;
};

export type GeofenceEventMutationRootCreateV1Args = {
  geofenceEventInV1: GeofenceEventInV1;
};

export type GeofenceEventRefsInV1 = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type GeofenceEventRefsV1 = {
  __typename?: 'GeofenceEventRefsV1';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type GeofenceEventRoot = {
  __typename?: 'GeofenceEventRoot';
  byId?: Maybe<GeofenceEventV1>;
};

export type GeofenceEventRootByIdArgs = {
  id: Scalars['ID'];
};

export enum GeofenceEventTypeV1 {
  ENTRY = 'ENTRY',
  EXIT = 'EXIT',
}

export type GeofenceEventV1 = {
  __typename?: 'GeofenceEventV1';
  coordinate: CoordinateV1;
  eventType: GeofenceEventTypeV1;
  geofenceDefinitionId: Scalars['String'];
  id: Scalars['ID'];
  referenceTimestamp: Scalars['String'];
  refs: Array<Maybe<GeofenceEventRefsV1>>;
};

export type GeofenceSearchRoot = {
  __typename?: 'GeofenceSearchRoot';
  nearCenterV1: GeofencesV1;
};

export type GeofenceSearchRootNearCenterV1Args = {
  center: CoordinateInV1;
  limit: Scalars['Int'];
  radius: Scalars['Int'];
};

export enum GeofenceTypeV1 {
  CIRCLE = 'CIRCLE',
}

export type GeofenceV1 = {
  __typename?: 'GeofenceV1';
  area: CircleAreaV1;
  id: Scalars['ID'];
  meta?: Maybe<Array<GeoFenceMetaV1>>;
  refs?: Maybe<Array<ExternalIdV1>>;
  type: GeofenceTypeV1;
};

export type GeofencesV1 = {
  __typename?: 'GeofencesV1';
  fences: Array<GeofenceV1>;
  parentFence: GeofenceV1;
};

export type GetActivatorTimelineQueryInV1 = {
  questionnaireCode: Scalars['ID'];
};

export type GetAllAnsweredConsentsQueryInV1 = {
  skippedConsents: Array<SkippedConsentInputV1>;
};

export type GetPendingConsentsQueryInV1 = {
  onFeatures?: InputMaybe<Array<ConsentFeatureTypeV1>>;
};

export type GiToxConfigV1 = AlgorithmConfig & {
  __typename?: 'GiToxConfigV1';
  algorithmCode: Scalars['String'];
  recommender: RecommenderConfigV1;
  solver: SolverConfigV1;
};

export type HealthEventCustomTriggerV1 = CustomTriggerV1 & {
  __typename?: 'HealthEventCustomTriggerV1';
  healthEventType: Scalars['String'];
  triggerCode: CustomTriggerCodeV1;
};

export type HealthEventInV1 = {
  healthEventReferences: Array<HealthEventReference>;
  healthEventType: Scalars['String'];
  localTimestamp: Scalars['String'];
  status: HealthEventStatusV1;
};

export type HealthEventMutationRootV1 = {
  __typename?: 'HealthEventMutationRootV1';
  createV1?: Maybe<HealthEventV1>;
};

export type HealthEventMutationRootV1CreateV1Args = {
  input: HealthEventInV1;
};

export type HealthEventReference = {
  referenceType: HealthEventReferenceType;
  value: Scalars['String'];
};

export enum HealthEventReferenceType {
  CLIENT_GENERATED_QUESTIONNAIRE_REF = 'CLIENT_GENERATED_QUESTIONNAIRE_REF',
  QUESTIONNAIRE_RESPONSE_ID = 'QUESTIONNAIRE_RESPONSE_ID',
}

export enum HealthEventStatusType {
  NEW = 'NEW',
  VIEWED = 'VIEWED',
}

export enum HealthEventStatusV1 {
  NEW = 'NEW',
  NO_ENDPOINT = 'NO_ENDPOINT',
  ONGOING = 'ONGOING',
  POT_ENDPOINT = 'POT_ENDPOINT',
  VIEWED = 'VIEWED',
}

export type HealthEventV1 = {
  __typename?: 'HealthEventV1';
  eventId: Scalars['String'];
  healthEventId: Scalars['String'];
  modifiedBy?: Maybe<Scalars['String']>;
  status: HealthEventStatusV1;
  timestamp: Scalars['String'];
  type: Scalars['String'];
  userId: Scalars['String'];
};

export type HealthScaleDefinitionV1 = QuestionDefinitionV1 & {
  __typename?: 'HealthScaleDefinitionV1';
  bestHealthLabel: Scalars['String'];
  bodyMd?: Maybe<Scalars['String']>;
  conditionalSubBranches?: Maybe<Array<QuestionConditionalSubBranchV1>>;
  currentHealthDefaultLabel: Scalars['String'];
  currentHealthLabel: Scalars['String'];
  defaultNextQuestionCode?: Maybe<Scalars['String']>;
  heading: Scalars['String'];
  headingMd?: Maybe<Scalars['String']>;
  healthScaleNextQuestionBranches: Array<QuestionHealthScaleNextQuestionBranchV2>;
  jsonMeta?: Maybe<Scalars['String']>;
  mandatory: Scalars['Boolean'];
  maxAccessibilityLabel?: Maybe<Scalars['String']>;
  maxValueInclusive: Scalars['Int'];
  minAccessibilityLabel?: Maybe<Scalars['String']>;
  minValueInclusive: Scalars['Int'];
  questionCode: Scalars['String'];
  sliderAccessibilityHint?: Maybe<Scalars['String']>;
  sliderAccessibilityLabel?: Maybe<Scalars['String']>;
  stepSize: Scalars['Int'];
  widgetType: WidgetTypeV1;
  worstHealthLabel: Scalars['String'];
};

export type HomeSupplyEventNotificationPayloadV1 = {
  __typename?: 'HomeSupplyEventNotificationPayloadV1';
  eventCode: Scalars['String'];
  messageId: Scalars['String'];
  shipmentNumber: Scalars['String'];
};

export type InstructionDefinitionV1 = QuestionDefinitionV1 & {
  __typename?: 'InstructionDefinitionV1';
  attachmentAboveAccessibilityLabel?: Maybe<Scalars['String']>;
  attachmentAboveText1?: Maybe<Scalars['String']>;
  attachmentBelowAccessibilityLabel?: Maybe<Scalars['String']>;
  attachmentBelowText1?: Maybe<Scalars['String']>;
  bodyMd?: Maybe<Scalars['String']>;
  conditionalSubBranches?: Maybe<Array<QuestionConditionalSubBranchV1>>;
  defaultNextQuestionCode?: Maybe<Scalars['String']>;
  heading: Scalars['String'];
  headingMd?: Maybe<Scalars['String']>;
  jsonMeta?: Maybe<Scalars['String']>;
  questionCode: Scalars['String'];
  subHeadingMd?: Maybe<Scalars['String']>;
  widgetType: WidgetTypeV1;
};

export type IntroductionDefinitionV1 = QuestionDefinitionV1 & {
  __typename?: 'IntroductionDefinitionV1';
  attachmentAboveAccessibilityLabel?: Maybe<Scalars['String']>;
  attachmentAboveText1?: Maybe<Scalars['String']>;
  attachmentBelowAccessibilityLabel?: Maybe<Scalars['String']>;
  attachmentBelowText1?: Maybe<Scalars['String']>;
  bodyMd?: Maybe<Scalars['String']>;
  conditionalSubBranches?: Maybe<Array<QuestionConditionalSubBranchV1>>;
  defaultNextQuestionCode?: Maybe<Scalars['String']>;
  heading: Scalars['String'];
  headingMd?: Maybe<Scalars['String']>;
  jsonMeta?: Maybe<Scalars['String']>;
  questionCode: Scalars['String'];
  subHeadingMd?: Maybe<Scalars['String']>;
  widgetType: WidgetTypeV1;
};

export type IsoZonedDateTime = {
  __typename?: 'IsoZonedDateTime';
  timestampIso: Scalars['String'];
  timezone: Scalars['String'];
};

export type IsoZonedDateTimeInput = {
  timestampIso: Scalars['String'];
  timezone: Scalars['String'];
};

export type LabelledControlV1 = {
  __typename?: 'LabelledControlV1';
  accessibilityHint: Scalars['String'];
  accessibilityLabel: Scalars['String'];
  label: Scalars['String'];
};

export enum MobileAppVersionV1 {
  V1_2 = 'V1_2',
  V2_0 = 'V2_0',
  V2_1 = 'V2_1',
  V3_0 = 'V3_0',
  V3_1 = 'V3_1',
  V4_0 = 'V4_0',
  V5_0 = 'V5_0',
  V6_0 = 'V6_0',
  V7_0 = 'V7_0',
  V8_0 = 'V8_0',
  V9_0 = 'V9_0',
}

export type MobileConfigQueryInV1 = {
  appVersion: MobileAppVersionV1;
};

export type MobileConfigSearchRootV1 = {
  __typename?: 'MobileConfigSearchRootV1';
  getConfig: MobileConfigV1;
};

export type MobileConfigSearchRootV1GetConfigArgs = {
  input: MobileConfigQueryInV1;
};

export type MobileConfigV1 = {
  __typename?: 'MobileConfigV1';
  featureConfig: MobileFeatureConfigV2;
  id: Scalars['ID'];
  studyConfig: MobileStudyConfigV1;
};

export enum MobileConfigValueV1 {
  DISABLED = 'DISABLED',
  DISABLED_ON_COUNTRY = 'DISABLED_ON_COUNTRY',
  DISABLED_ON_SITE = 'DISABLED_ON_SITE',
  ENABLED = 'ENABLED',
}

export type MobileDevicesConfigV1 = {
  __typename?: 'MobileDevicesConfigV1';
  enabledDevices: EnabledDevices;
};

export type MobileFeatureConfigV2 = {
  __typename?: 'MobileFeatureConfigV2';
  devices: MobileConfigValueV1;
  eCOA: MobileConfigValueV1;
  general: MobileGeneralConfigV2;
  geolocation: MobileConfigValueV1;
  healthEvent: MobileConfigValueV1;
  homeSupply: MobileHomeSupplyConfigV2;
  id: Scalars['ID'];
  medication: MobileConfigValueV1;
  notifications: MobileNotificationConfigV2;
  rpmEventFeedback: MobileConfigValueV1;
  spfq: MobileConfigValueV1;
  staticMedicationReminder: MobileConfigValueV1;
  studyHelp: MobileStudyHelpConfigV2;
  visits: MobileVisitsConfigV2;
};

export type MobileGeneralConfigV2 = {
  __typename?: 'MobileGeneralConfigV2';
  genericApp: MobileConfigValueV1;
  patientPinCode: MobileConfigValueV1;
};

export type MobileHomeSupplyConfigV2 = {
  __typename?: 'MobileHomeSupplyConfigV2';
  homeSupply: MobileConfigValueV1;
  scanning: MobileConfigValueV1;
};

export type MobileNotificationConfigV2 = {
  __typename?: 'MobileNotificationConfigV2';
  SPFQNotification: MobileConfigValueV1;
  contentNotification: MobileConfigValueV1;
  eCOANotification: MobileConfigValueV1;
  healthEventNotification: MobileConfigValueV1;
  medicationNotification: MobileConfigValueV1;
  notificationDaily: MobileConfigValueV1;
  notificationFourWeekly: MobileConfigValueV1;
  notificationWeekly: MobileConfigValueV1;
  patientVisitNotification: MobileConfigValueV1;
};

export type MobileStudyConfigV1 = {
  __typename?: 'MobileStudyConfigV1';
  assessmentConsequenceActivityCardConfig?: Maybe<AssessmentConsequenceActivityCardConfigV1>;
  devicesConfig: MobileDevicesConfigV1;
  multipleDevicesSignInConfig: MultipleDevicesSignInConfigV1;
  patientCopingTipConfig?: Maybe<CopingTipConfigV1>;
  questionnaireConfig: QuestionnaireConfigV1;
  recommendedResourcesConfig: RecommendedResourcesConfigV1;
  secondAuthenticationConfig: SecondAuthenticationConfigV1;
};

export type MobileStudyHelpConfigV2 = {
  __typename?: 'MobileStudyHelpConfigV2';
  helpByPhone: MobileConfigValueV1;
};

export type MobileVisitsConfigV2 = {
  __typename?: 'MobileVisitsConfigV2';
  visits: MobileConfigValueV1;
};

export type MultipleDevicesSignInConfigV1 = {
  __typename?: 'MultipleDevicesSignInConfigV1';
  patientMultipleDevicesSignIn: Scalars['Boolean'];
};

export type Mutation = {
  __typename?: 'Mutation';
  geofenceEvent: GeofenceEventMutationRoot;
  healthEventV1: HealthEventMutationRootV1;
  questionnaireResponseV1: QuestionnaireResponseMutationRootV1;
};

export type NavigationNotificationPayloadV1 = {
  __typename?: 'NavigationNotificationPayloadV1';
  screenName: Scalars['String'];
};

export type NotificationActionPayloadV1 =
  | AppointmentNotificationPayloadV1
  | ArticleNotificationPayloadV1
  | DeviceObservationNotificationPayloadV1
  | HomeSupplyEventNotificationPayloadV1
  | NavigationNotificationPayloadV1
  | QuestionnaireNotificationPayloadV1
  | TriggeredDeviceObservationNotificationPayloadV1
  | TriggeredQuestionnaireNotificationPayloadV1;

export enum NotificationActionTypeV1 {
  NAVIGATE = 'NAVIGATE',
  NOTHING = 'NOTHING',
  OPEN_DEVICE_MEASUREMENT = 'OPEN_DEVICE_MEASUREMENT',
  OPEN_QUESTIONNAIRE = 'OPEN_QUESTIONNAIRE',
  OPEN_SUPPLIES = 'OPEN_SUPPLIES',
  OPEN_VISIT = 'OPEN_VISIT',
}

export type NotificationActionV1 = {
  __typename?: 'NotificationActionV1';
  payload?: Maybe<NotificationActionPayloadV1>;
  type: NotificationActionTypeV1;
};

export enum NotificationDomainV1 {
  DEVICE_MEASUREMENT = 'DEVICE_MEASUREMENT',
  DEVICE_MEASUREMENT_MESSAGE = 'DEVICE_MEASUREMENT_MESSAGE',
  GENERIC = 'GENERIC',
  HOME_SUPPLY_EVENT = 'HOME_SUPPLY_EVENT',
  QUESTIONNAIRE = 'QUESTIONNAIRE',
  QUESTIONNAIRE_MESSAGE = 'QUESTIONNAIRE_MESSAGE',
  UNSCHEDULED_VISIT = 'UNSCHEDULED_VISIT',
  VISIT = 'VISIT',
}

export type NotificationTimelineSearchRootV1 = {
  __typename?: 'NotificationTimelineSearchRootV1';
  betweenDatesV1: NotificationTimelineV1;
};

export type NotificationTimelineSearchRootV1BetweenDatesV1Args = {
  from: ZonedDateTimeInput;
  to: ZonedDateTimeInput;
};

export type NotificationTimelineSearchRootV2 = {
  __typename?: 'NotificationTimelineSearchRootV2';
  betweenDatesV2: NotificationTimelineV2;
};

export type NotificationTimelineSearchRootV2BetweenDatesV2Args = {
  domains: Array<NotificationDomainV1>;
  from: ZonedDateTimeInput;
  maxNumberOfNotifications?: InputMaybe<Scalars['Int']>;
  to: ZonedDateTimeInput;
};

export type NotificationTimelineV1 = {
  __typename?: 'NotificationTimelineV1';
  id: Scalars['ID'];
  notifications: Array<SchedulableNotificationV1>;
};

export type NotificationTimelineV2 = {
  __typename?: 'NotificationTimelineV2';
  id: Scalars['ID'];
  notifications: Array<SchedulableNotificationV2>;
};

export enum NotificationUnitOfTimeV1 {
  D = 'D',
  H = 'H',
  MIN = 'MIN',
}

export type NumericRatingScaleDefinitionV1 = QuestionDefinitionV1 & {
  __typename?: 'NumericRatingScaleDefinitionV1';
  bodyMd?: Maybe<Scalars['String']>;
  conditionalSubBranches?: Maybe<Array<QuestionConditionalSubBranchV1>>;
  defaultNextQuestionCode?: Maybe<Scalars['String']>;
  heading: Scalars['String'];
  headingMd?: Maybe<Scalars['String']>;
  jsonMeta?: Maybe<Scalars['String']>;
  mandatory: Scalars['Boolean'];
  maxLabel: Scalars['String'];
  maxValueInclusive: Scalars['Int'];
  minLabel: Scalars['String'];
  minValueInclusive: Scalars['Int'];
  numericRatingScaleNextQuestionBranches: Array<QuestionNumericRatingScaleNextQuestionBranchV2>;
  orientation?: Maybe<DeviceOrientationV2>;
  questionCode: Scalars['String'];
  sliderAccessibilityHint?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  widgetType: WidgetTypeV1;
};

export type OfflineError = {
  __typename?: 'OfflineError';
  buttonLabel: Scalars['String'];
  description: Scalars['String'];
  title: Scalars['String'];
};

export type OptionalControlV1 = {
  __typename?: 'OptionalControlV1';
  accessibilityHint?: Maybe<Scalars['String']>;
  accessibilityLabel?: Maybe<Scalars['String']>;
};

export type OptionalLabelledControlV1 = {
  __typename?: 'OptionalLabelledControlV1';
  accessibilityHint?: Maybe<Scalars['String']>;
  accessibilityLabel?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type PatientCopingTipConfigV1 = {
  __typename?: 'PatientCopingTipConfigV1';
  module: PatientCopingTipModuleV1;
};

export enum PatientCopingTipModuleV1 {
  GI_TOX = 'GI_TOX',
  SYMPTOM_TRACKER = 'SYMPTOM_TRACKER',
}

export type PendingConsentV1 = {
  artifact: ArtifactV1;
  config: ConsentConfigV1;
  consentType: ConsentTypeV1;
  id: Scalars['ID'];
};

export type PendingConsentsV1 = {
  __typename?: 'PendingConsentsV1';
  id: Scalars['ID'];
  pendingConsents: Array<PendingConsentV1>;
  systemTexts: ConsentSystemTextsV1;
};

export type PluralisedTemplateStringV1 = {
  __typename?: 'PluralisedTemplateStringV1';
  few?: Maybe<Scalars['String']>;
  many?: Maybe<Scalars['String']>;
  one?: Maybe<Scalars['String']>;
  other: Scalars['String'];
  two?: Maybe<Scalars['String']>;
  zero?: Maybe<Scalars['String']>;
};

export type PublicMobileConfigSearchRootV1 = {
  __typename?: 'PublicMobileConfigSearchRootV1';
  getConfig: PublicMobileConfigV1;
  id: Scalars['String'];
};

export type PublicMobileConfigV1 = {
  __typename?: 'PublicMobileConfigV1';
  id: Scalars['String'];
  zoomConfig: ZoomConfig;
};

export type Query = {
  __typename?: 'Query';
  activatorTimeline: ActivatorsTimelineSearchRoot;
  consent: ConsentSearchRoot;
  featureConfig?: Maybe<FeatureConfigSearchRoot>;
  featureConfigV2?: Maybe<FeatureConfigSearchRootV2>;
  geofence: GeofenceSearchRoot;
  geofenceEvent?: Maybe<GeofenceEventRoot>;
  id: Scalars['String'];
  mobileConfigV1: MobileConfigSearchRootV1;
  notificationTimelineV1: NotificationTimelineSearchRootV1;
  notificationTimelineV2: NotificationTimelineSearchRootV2;
  publicMobileConfigV1: PublicMobileConfigSearchRootV1;
  questionnairePresentationV1: QuestionnairePresentationSearchRootV1;
  questionnaireResponseV1: QuestionnaireResponseQueryRootV1;
  questionnaireScheduleDefinitionV1: QuestionnaireActiveSchedulesV1SearchRoot;
};

export type QuestionAdvancedNumericRatingScaleNextQuestionBranchV1 = {
  __typename?: 'QuestionAdvancedNumericRatingScaleNextQuestionBranchV1';
  maxValue?: Maybe<Scalars['Float']>;
  maxValueExclusive?: Maybe<Scalars['Float']>;
  minValue: Scalars['Float'];
  nextQuestionCode: Scalars['String'];
};

export enum QuestionBranchCriteriaOperatorV1 {
  AND = 'AND',
  NOT = 'NOT',
  OR = 'OR',
}

export type QuestionBranchCriteriaV1 = {
  __typename?: 'QuestionBranchCriteriaV1';
  answerOptionCodes?: Maybe<Array<Scalars['String']>>;
  criteria?: Maybe<Array<QuestionBranchCriteriaV1>>;
  operator: QuestionBranchCriteriaOperatorV1;
};

export type QuestionConditionalSubBranchCriteriaV1 = {
  __typename?: 'QuestionConditionalSubBranchCriteriaV1';
  criteria?: Maybe<Array<QuestionConditionalSubBranchCriteriaV1>>;
  operands?: Maybe<Array<QuestionCustomFunctionV1>>;
  operator?: Maybe<QuestionBranchCriteriaOperatorV1>;
};

export type QuestionConditionalSubBranchV1 = {
  __typename?: 'QuestionConditionalSubBranchV1';
  criteria: QuestionConditionalSubBranchCriteriaV1;
  nextQuestionCode: Scalars['String'];
};

export type QuestionCustomFunctionV1 = {
  __typename?: 'QuestionCustomFunctionV1';
  functionName?: Maybe<CustomFunctionNameV1>;
  jsonMeta?: Maybe<Scalars['String']>;
  parameters?: Maybe<CustomFunctionParametersV1>;
};

export type QuestionDefinitionV1 = {
  bodyMd?: Maybe<Scalars['String']>;
  defaultNextQuestionCode?: Maybe<Scalars['String']>;
  heading: Scalars['String'];
  headingMd?: Maybe<Scalars['String']>;
  jsonMeta?: Maybe<Scalars['String']>;
  questionCode: Scalars['String'];
  widgetType: WidgetTypeV1;
};

export type QuestionHealthScaleNextQuestionBranchV2 = {
  __typename?: 'QuestionHealthScaleNextQuestionBranchV2';
  maxValueInclusive: Scalars['Int'];
  minValueInclusive: Scalars['Int'];
  nextQuestionCode: Scalars['String'];
};

export type QuestionNumericRatingScaleNextQuestionBranchV2 = {
  __typename?: 'QuestionNumericRatingScaleNextQuestionBranchV2';
  maxValueInclusive: Scalars['Int'];
  minValueInclusive: Scalars['Int'];
  nextQuestionCode: Scalars['String'];
};

export type QuestionSubBranchV1 = {
  __typename?: 'QuestionSubBranchV1';
  criteria: QuestionBranchCriteriaV1;
  nextQuestionCode: Scalars['String'];
};

export type QuestionTimeNextQuestionBranchV1 = {
  __typename?: 'QuestionTimeNextQuestionBranchV1';
  maxTimeInclusive: Scalars['String'];
  minTimeInclusive: Scalars['String'];
  nextQuestionCode: Scalars['String'];
};

export type QuestionVerbalRatingScaleNextQuestionBranchV2 = {
  __typename?: 'QuestionVerbalRatingScaleNextQuestionBranchV2';
  answerOptionCodes: Array<Scalars['String']>;
  nextQuestionCode: Scalars['String'];
};

export type QuestionVisualAnalogScaleNextQuestionBranchV2 = {
  __typename?: 'QuestionVisualAnalogScaleNextQuestionBranchV2';
  maxValueInclusive: Scalars['Int'];
  minValueInclusive: Scalars['Int'];
  nextQuestionCode: Scalars['String'];
};

export type QuestionnaireActivationRefV1 = {
  __typename?: 'QuestionnaireActivationRefV1';
  type: Scalars['String'];
  value: Scalars['String'];
};

export type QuestionnaireActivationV1 = {
  __typename?: 'QuestionnaireActivationV1';
  activationId: Scalars['String'];
  versionCode: Scalars['String'];
  windowEnd?: Maybe<Scalars['String']>;
  windowStart: Scalars['String'];
};

export type QuestionnaireActiveSchedulesV1SearchRoot = {
  __typename?: 'QuestionnaireActiveSchedulesV1SearchRoot';
  byOrganizationIdV1: QuestionnaireScheduleDefinitionV1;
};

export type QuestionnaireActiveSchedulesV1SearchRootByOrganizationIdV1Args = {
  organizationId: Scalars['String'];
};

export enum QuestionnaireApiVersion {
  V1 = 'V1',
  V2 = 'V2',
}

export type QuestionnaireBatchItem = {
  correlationId: Scalars['String'];
  questionnaireResponse: QuestionnaireResponseInV1;
};

export type QuestionnaireCardTextV1 = {
  __typename?: 'QuestionnaireCardTextV1';
  category: Scalars['String'];
  dateHeader: Scalars['String'];
  dateTodayLabel: Scalars['String'];
  header: Scalars['String'];
  languageVersion: Scalars['String'];
  offlineError: OfflineError;
  questionnaireCode: Scalars['ID'];
  startButton: StartButtonText;
  subHeader: Scalars['String'];
  timeHeader: Scalars['String'];
};

export type QuestionnaireConfigMetaV1 = {
  __typename?: 'QuestionnaireConfigMetaV1';
  historicalMetricPeriod?: Maybe<QuestionnaireMetricPeriodV1>;
  minComplianceThreshold?: Maybe<Scalars['Float']>;
  mobileAppGraphicalPresentation: QuestionnaireMobileAppGraphicalPresentationV1;
  patientReview: Scalars['Boolean'];
  requireHcpReview: Scalars['Boolean'];
  requireSecondAuth: Scalars['Boolean'];
  selfReport: Scalars['Boolean'];
  type: QuestionnaireTypeV1;
};

export type QuestionnaireConfigV1 = {
  __typename?: 'QuestionnaireConfigV1';
  apiVersion: QuestionnaireApiVersion;
};

export type QuestionnaireCustomActionMetaV1 = {
  __typename?: 'QuestionnaireCustomActionMetaV1';
  DATE: Scalars['String'];
  TIME?: Maybe<Scalars['String']>;
};

export type QuestionnaireCustomActionV1 = {
  __typename?: 'QuestionnaireCustomActionV1';
  meta: QuestionnaireCustomActionMetaV1;
};

export enum QuestionnaireDayOfWeekV1 {
  FRI = 'FRI',
  MON = 'MON',
  SAT = 'SAT',
  SUN = 'SUN',
  THU = 'THU',
  TUE = 'TUE',
  WED = 'WED',
}

export type QuestionnaireDefinitionConfigV1 = {
  __typename?: 'QuestionnaireDefinitionConfigV1';
  widgetVersionConfig?: Maybe<WidgetVersionConfigV1>;
};

export type QuestionnaireDefinitionMetaV1 = {
  __typename?: 'QuestionnaireDefinitionMetaV1';
  CONFIG_V1?: Maybe<QuestionnaireDefinitionConfigV1>;
};

export type QuestionnaireDefinitionV1 = {
  __typename?: 'QuestionnaireDefinitionV1';
  firstQuestionCode: Scalars['String'];
  languageVersion: Scalars['String'];
  meta?: Maybe<QuestionnaireDefinitionMetaV1>;
  pageTitle: Scalars['String'];
  questionDefinitions: Array<QuestionDefinitionV1>;
  questionnaireCode: Scalars['ID'];
};

export type QuestionnaireDurationV1 = {
  __typename?: 'QuestionnaireDurationV1';
  unit: QuestionnaireUnitOfTimeV1;
  value: Scalars['Int'];
};

export type QuestionnaireFixedActionV1 = {
  __typename?: 'QuestionnaireFixedActionV1';
  subType?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export enum QuestionnaireMetricPeriodUnitTypeV1 {
  DAY = 'DAY',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  YEAR = 'YEAR',
}

export type QuestionnaireMetricPeriodV1 = {
  __typename?: 'QuestionnaireMetricPeriodV1';
  amount: Scalars['Int'];
  unit: QuestionnaireMetricPeriodUnitTypeV1;
};

export type QuestionnaireMobileAppGraphicalPresentationV1 = {
  __typename?: 'QuestionnaireMobileAppGraphicalPresentationV1';
  showProgressbar: Scalars['Boolean'];
};

export type QuestionnaireNotificationPayloadV1 = {
  __typename?: 'QuestionnaireNotificationPayloadV1';
  questionnaireCode: Scalars['String'];
  repeatIndex: Scalars['Int'];
  requestCode: Scalars['String'];
  scheduleCode: Scalars['String'];
  versionCode: Scalars['String'];
};

export type QuestionnairePresentationSearchRootV1 = {
  __typename?: 'QuestionnairePresentationSearchRootV1';
  byCodeV1: QuestionnairePresentationV1;
};

export type QuestionnairePresentationSearchRootV1ByCodeV1Args = {
  questionnaireCode: Scalars['String'];
};

export type QuestionnairePresentationV1 = {
  __typename?: 'QuestionnairePresentationV1';
  questionnaireDefinition: QuestionnaireDefinitionV1;
  submitCelebration: SubmitCelebrationV1;
  systemTexts: SystemTextsV1;
};

export type QuestionnaireRelatedActionV1 = {
  __typename?: 'QuestionnaireRelatedActionV1';
  customAction?: Maybe<QuestionnaireCustomActionV1>;
  fixedAction?: Maybe<QuestionnaireFixedActionV1>;
  fixedTime?: Maybe<Scalars['String']>;
  offset?: Maybe<QuestionnaireTimeOffsetV1>;
};

export type QuestionnaireRepeatV1 = {
  __typename?: 'QuestionnaireRepeatV1';
  daysOfWeek?: Maybe<Array<QuestionnaireDayOfWeekV1>>;
  frequency?: Maybe<Scalars['Int']>;
  frequencyMax?: Maybe<Scalars['Int']>;
  period?: Maybe<Scalars['Int']>;
  periodUnit?: Maybe<QuestionnaireUnitOfTimeV1>;
  timesOfDay?: Maybe<Array<Scalars['String']>>;
};

export type QuestionnaireRequestDetailV1 = {
  __typename?: 'QuestionnaireRequestDetailV1';
  questionnaireCode: Scalars['String'];
};

export type QuestionnaireRequestGroupConfigV1 = {
  __typename?: 'QuestionnaireRequestGroupConfigV1';
  memberOf: Array<QuestionnaireRequestGroupMembershipV1>;
};

export type QuestionnaireRequestGroupMembershipV1 = {
  __typename?: 'QuestionnaireRequestGroupMembershipV1';
  groupKey: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
};

export type QuestionnaireRequestMetaV1 = {
  __typename?: 'QuestionnaireRequestMetaV1';
  CONFIG_V1: QuestionnaireConfigMetaV1;
  GROUP_CONFIG_V1?: Maybe<QuestionnaireRequestGroupConfigV1>;
  INITIATION_CONFIG_V1?: Maybe<RequestInitiationConfigV1>;
  PATIENT_COPING_TIP_CONFIG_V1?: Maybe<PatientCopingTipConfigV1>;
};

export type QuestionnaireRequestV1 = {
  __typename?: 'QuestionnaireRequestV1';
  details?: Maybe<QuestionnaireRequestDetailV1>;
  endAction: QuestionnaireRelatedActionV1;
  meta?: Maybe<QuestionnaireRequestMetaV1>;
  requestCode: Scalars['String'];
  startAction: QuestionnaireRelatedActionV1;
  timing?: Maybe<QuestionnaireTimingV1>;
  while?: Maybe<RequestWhileV1>;
};

export type QuestionnaireResponseAnswerV1 = {
  __typename?: 'QuestionnaireResponseAnswerV1';
  answerGiven: Scalars['Boolean'];
  jsonPayload: Scalars['String'];
  questionCode: Scalars['String'];
  questionText: Scalars['String'];
  timestamp: IsoZonedDateTime;
  widgetType: WidgetTypeV1;
};

export type QuestionnaireResponseAnswerV1Input = {
  answerGiven: Scalars['Boolean'];
  jsonPayload?: InputMaybe<Scalars['String']>;
  questionCode: Scalars['String'];
  questionText: Scalars['String'];
  timestamp: IsoZonedDateTimeInput;
  widgetType: WidgetTypeV1;
};

export type QuestionnaireResponseIdOrErrorV2 = {
  __typename?: 'QuestionnaireResponseIdOrErrorV2';
  correlationId: Scalars['String'];
  error?: Maybe<ErrorV1>;
  questionnaireResponseId?: Maybe<Scalars['String']>;
};

export type QuestionnaireResponseIdsOrErrorsV1 = {
  __typename?: 'QuestionnaireResponseIdsOrErrorsV1';
  questionnaireResponseIdOrErrors: Array<QuestionnaireResponseIdOrErrorV2>;
};

export type QuestionnaireResponseInV1 = {
  answers: Array<QuestionnaireResponseAnswerV1Input>;
  externalIds: Array<ExternalIdV1Input>;
  meta: QuestionnaireResponseMeta;
  periodInfo?: InputMaybe<QuestionnaireResponsePeriodInfoV1Input>;
  questionnaireCode: Scalars['ID'];
  scheduleInfo: ScheduleInfo;
  statusInfo: QuestionnaireStatusInfoV1Input;
  visitId?: InputMaybe<Scalars['String']>;
};

export type QuestionnaireResponseMeta = {
  isRightToLeft: Scalars['Boolean'];
  questionnaireLanguage: Scalars['String'];
  questionnaireLanguageVersion: Scalars['String'];
};

export type QuestionnaireResponseMutationRootV1 = {
  __typename?: 'QuestionnaireResponseMutationRootV1';
  createBatchV1: QuestionnaireResponseIdsOrErrorsV1;
  createV1: QuestionnaireResponseV1;
  updateV1: UpdatedQuestionnaireResponseV1;
};

export type QuestionnaireResponseMutationRootV1CreateBatchV1Args = {
  input: CreateQuestionnaireResponsesBatchInV2;
};

export type QuestionnaireResponseMutationRootV1CreateV1Args = {
  input: QuestionnaireResponseInV1;
};

export type QuestionnaireResponseMutationRootV1UpdateV1Args = {
  input: UpdateQuestionnaireResponseInV1;
};

export type QuestionnaireResponsePeriodInfoV1Input = {
  windowEndTimestamp?: InputMaybe<IsoZonedDateTimeInput>;
  windowReferenceTimestamp?: InputMaybe<IsoZonedDateTimeInput>;
  windowStartTimestamp?: InputMaybe<IsoZonedDateTimeInput>;
};

export type QuestionnaireResponseQueryRootV1 = {
  __typename?: 'QuestionnaireResponseQueryRootV1';
  byQuestionnaireCodeV1: Array<QuestionnaireResponseV1>;
};

export type QuestionnaireResponseQueryRootV1ByQuestionnaireCodeV1Args = {
  questionnaireCode: Scalars['String'];
};

export enum QuestionnaireResponseStatusV1Type {
  COMPLETED = 'COMPLETED',
  DECLINED = 'DECLINED',
  INCOMPLETE = 'INCOMPLETE',
  MISSED = 'MISSED',
}

export type QuestionnaireResponseV1 = {
  __typename?: 'QuestionnaireResponseV1';
  answers?: Maybe<Array<Maybe<QuestionnaireResponseAnswerV1>>>;
  questionnaireResponseId: Scalars['ID'];
};

export type QuestionnaireScheduleDefinitionV1 = {
  __typename?: 'QuestionnaireScheduleDefinitionV1';
  activeSchedules: Array<QuestionnaireScheduleV1>;
  questionnaireCardTexts: Array<QuestionnaireCardTextV1>;
};

export type QuestionnaireScheduleV1 = {
  __typename?: 'QuestionnaireScheduleV1';
  activeVersionWindows: Array<QuestionnaireVersionWindowV1>;
  scheduleCode: Scalars['String'];
};

export type QuestionnaireStatusInfoV1Input = {
  status: QuestionnaireResponseStatusV1Type;
  timestamp: IsoZonedDateTimeInput;
};

export type QuestionnaireTimeOffsetV1 = {
  __typename?: 'QuestionnaireTimeOffsetV1';
  unit: QuestionnaireUnitOfTimeV1;
  value: Scalars['Int'];
};

export type QuestionnaireTimingV1 = {
  __typename?: 'QuestionnaireTimingV1';
  repeat: QuestionnaireRepeatV1;
  windowAfter?: Maybe<QuestionnaireDurationV1>;
  windowBefore?: Maybe<QuestionnaireDurationV1>;
};

export enum QuestionnaireTypeV1 {
  CLINRO = 'CLINRO',
  DIARY = 'DIARY',
  ECOA = 'ECOA',
  ENGAGEMENT = 'ENGAGEMENT',
  HEALTH_EVENT = 'HEALTH_EVENT',
  SPFQ = 'SPFQ',
  UNKNOWN = 'UNKNOWN',
}

export enum QuestionnaireUnitOfTimeV1 {
  A = 'A',
  D = 'D',
  H = 'H',
  MIN = 'MIN',
  MO = 'MO',
  S = 'S',
  WK = 'WK',
}

export type QuestionnaireVersionV1 = {
  __typename?: 'QuestionnaireVersionV1';
  requests: Array<QuestionnaireRequestV1>;
  versionCode: Scalars['String'];
};

export type QuestionnaireVersionWindowV1 = {
  __typename?: 'QuestionnaireVersionWindowV1';
  activation: QuestionnaireActivationV1;
  activationRef: QuestionnaireActivationRefV1;
  activeFrom: Scalars['String'];
  activeTo?: Maybe<Scalars['String']>;
  version: QuestionnaireVersionV1;
};

export type RecommendedResourcesConfigV1 = {
  __typename?: 'RecommendedResourcesConfigV1';
  recommendedResourcesActivated: Scalars['Boolean'];
};

export type RecommenderConfigV1 = {
  __typename?: 'RecommenderConfigV1';
  historyGain: Scalars['Float'];
  importanceFactorGroup: Scalars['Float'];
  importanceFactorPriority: Scalars['Float'];
  maxDaysInHistory: Scalars['Int'];
  priorityGain: Scalars['Float'];
  symptomGroupPrefixFlag: Scalars['String'];
  symptomGroupPriorities: Array<Scalars['String']>;
};

export enum ReminderNotificationTriggerActionTypeV1 {
  REFERENCE_TIMESTAMP = 'REFERENCE_TIMESTAMP',
  WINDOW_END = 'WINDOW_END',
  WINDOW_START = 'WINDOW_START',
}

export type RequestInitiationConfigV1 = {
  __typename?: 'RequestInitiationConfigV1';
  initiator: RequestInitiatorTypeV1;
  ttlSeconds?: Maybe<Scalars['Int']>;
};

export enum RequestInitiatorTypeV1 {
  HCP = 'HCP',
}

export enum RequestWhileCriterionTypeV1 {
  USER_CUSTOM_STATUS = 'USER_CUSTOM_STATUS',
}

export type RequestWhileCriterionV1 = {
  __typename?: 'RequestWhileCriterionV1';
  operator?: Maybe<RequestWhileOperatorTypeV1>;
  subType: Scalars['String'];
  type: RequestWhileCriterionTypeV1;
  value: Array<Scalars['String']>;
};

export enum RequestWhileOperatorTypeV1 {
  IN = 'IN',
  NOT_IN = 'NOT_IN',
}

export type RequestWhileV1 = {
  __typename?: 'RequestWhileV1';
  criteria?: Maybe<Array<RequestWhileCriterionV1>>;
};

export type ReviewScreenSystemTextsV1 = {
  __typename?: 'ReviewScreenSystemTextsV1';
  editButton?: Maybe<OptionalControlV1>;
  unansweredText: Scalars['String'];
};

export type SchedulableNotificationContentV1 = {
  __typename?: 'SchedulableNotificationContentV1';
  body: Scalars['String'];
  header: Scalars['String'];
};

export type SchedulableNotificationV1 = {
  __typename?: 'SchedulableNotificationV1';
  action: NotificationActionV1;
  content: SchedulableNotificationContentV1;
  dateTime: ZonedDateTime;
  notificationId: Scalars['String'];
};

export type SchedulableNotificationV2 = {
  __typename?: 'SchedulableNotificationV2';
  action: NotificationActionV1;
  content: SchedulableNotificationContentV1;
  dateTime: ZonedDateTime;
  notificationDomain: NotificationDomainV1;
  notificationId: Scalars['String'];
};

export type ScheduleInfo = {
  repeatIndex: Scalars['Int'];
  requestCode: Scalars['String'];
  scheduleCode: Scalars['String'];
  versionCode: Scalars['String'];
};

export type ScrollDownHintV1 = {
  __typename?: 'ScrollDownHintV1';
  accessibilityHint: Scalars['String'];
  accessibilityLabel: Scalars['String'];
  text: Scalars['String'];
};

export type SecondAuthenticationConfigV1 = {
  __typename?: 'SecondAuthenticationConfigV1';
  patientSecondAuthenticationSessionInMinutes: Scalars['Int'];
};

export type SkippedConsentInputV1 = {
  artifactCode: Scalars['String'];
  skippedAt: Scalars['String'];
};

export type SolverConfigV1 = {
  __typename?: 'SolverConfigV1';
  mapRule: Scalars['String'];
};

export type StartButtonText = {
  __typename?: 'StartButtonText';
  accessibilityHint: Scalars['String'];
  accessibilityLabel: Scalars['String'];
  label: Scalars['String'];
};

export type SubmitCelebrationV1 = {
  __typename?: 'SubmitCelebrationV1';
  successfulSubmissionPopupDescription?: Maybe<Scalars['String']>;
  successfulSubmissionPopupTitle: Scalars['String'];
};

export type SubmitDefinitionV1 = QuestionDefinitionV1 & {
  __typename?: 'SubmitDefinitionV1';
  attachmentAboveAccessibilityLabel?: Maybe<Scalars['String']>;
  attachmentAboveText1?: Maybe<Scalars['String']>;
  attachmentBelowAccessibilityLabel?: Maybe<Scalars['String']>;
  attachmentBelowText1?: Maybe<Scalars['String']>;
  bodyMd?: Maybe<Scalars['String']>;
  defaultNextQuestionCode?: Maybe<Scalars['String']>;
  heading: Scalars['String'];
  headingMd?: Maybe<Scalars['String']>;
  jsonMeta?: Maybe<Scalars['String']>;
  questionCode: Scalars['String'];
  subHeadingMd?: Maybe<Scalars['String']>;
  widgetType: WidgetTypeV1;
};

export type SymptomTrackerConfigV1 = AlgorithmConfig & {
  __typename?: 'SymptomTrackerConfigV1';
  algorithmCode: Scalars['String'];
  recommender: RecommenderConfigV1;
  solver: SolverConfigV1;
};

export type SystemTextsV1 = {
  __typename?: 'SystemTextsV1';
  abortButtonAccessibilityHint: Scalars['String'];
  abortButtonAccessibilityLabel: Scalars['String'];
  abortQuestionnairePopupCancelButtonLabel: Scalars['String'];
  abortQuestionnairePopupConfirmButtonLabel: Scalars['String'];
  abortQuestionnairePopupDescription: Scalars['String'];
  abortQuestionnairePopupHeader: Scalars['String'];
  cancelButtonLabel: Scalars['String'];
  clearButtonLabel?: Maybe<Scalars['String']>;
  closeAlert?: Maybe<CloseAlert>;
  closeButton?: Maybe<ControlV1>;
  confirmButton?: Maybe<LabelledControlV1>;
  editButton?: Maybe<LabelledControlV1>;
  nextButtonAccessibilityHint: Scalars['String'];
  nextButtonAccessibilityLabel: Scalars['String'];
  nextButtonLabel: Scalars['String'];
  previousButtonAccessibilityHint: Scalars['String'];
  previousButtonAccessibilityLabel: Scalars['String'];
  previousButtonLabel?: Maybe<Scalars['String']>;
  reviewScreen?: Maybe<ReviewScreenSystemTextsV1>;
  scrollDownHint?: Maybe<OptionalLabelledControlV1>;
  selectButton?: Maybe<LabelledControlV1>;
  skipQuestionPopupDescription: Scalars['String'];
  skipQuestionPopupHeader: Scalars['String'];
  startButtonAccessibilityHint: Scalars['String'];
  startButtonAccessibilityLabel: Scalars['String'];
  startButtonLabel: Scalars['String'];
  startHint?: Maybe<LabelledControlV1>;
  submitButtonAccessibilityHint: Scalars['String'];
  submitButtonAccessibilityLabel: Scalars['String'];
  submitButtonLabel: Scalars['String'];
  submitQuestionnaireFailureCancelButtonLabel: Scalars['String'];
  submitQuestionnaireFailureDescription: Scalars['String'];
  submitQuestionnaireFailureRetryButtonLabel: Scalars['String'];
  submitQuestionnaireFailureTitle: Scalars['String'];
  submitQuestionnaireSuccessfullyButtonLabel: Scalars['String'];
  submitWindowPassedErrorButtonLabel: Scalars['String'];
  submitWindowPassedErrorDescription: Scalars['String'];
  submitWindowPassedErrorTitle: Scalars['String'];
  verbalRatingScaleTree?: Maybe<VerbalRatingScaleTreeSystemTextsV1>;
  yesButtonLabel: Scalars['String'];
};

export type TimeDefinitionV1 = QuestionDefinitionV1 & {
  __typename?: 'TimeDefinitionV1';
  bodyMd?: Maybe<Scalars['String']>;
  clearButton: LabelledControlV1;
  closeButton: ControlV1;
  conditionalSubBranches?: Maybe<Array<QuestionConditionalSubBranchV1>>;
  confirmButton: LabelledControlV1;
  defaultNextQuestionCode?: Maybe<Scalars['String']>;
  editButton: LabelledControlV1;
  heading: Scalars['String'];
  headingMd?: Maybe<Scalars['String']>;
  helperText?: Maybe<Scalars['String']>;
  jsonMeta?: Maybe<Scalars['String']>;
  mandatory: Scalars['Boolean'];
  maxTime?: Maybe<Scalars['String']>;
  minTime?: Maybe<Scalars['String']>;
  questionCode: Scalars['String'];
  selectButton: LabelledControlV1;
  stepMinutes?: Maybe<Scalars['Int']>;
  stepUIMinutes?: Maybe<Scalars['Int']>;
  timeNextQuestionBranches: Array<QuestionTimeNextQuestionBranchV1>;
  timeSelector: ControlV1;
  valueLabel?: Maybe<Scalars['String']>;
  widgetType: WidgetTypeV1;
};

export type TriggeredDeviceObservationNotificationPayloadV1 = {
  __typename?: 'TriggeredDeviceObservationNotificationPayloadV1';
  deviceModelReferences: Array<DeviceModelReferenceV1>;
  messageId: Scalars['String'];
  requestCode: Scalars['String'];
  scheduleCode: Scalars['String'];
  versionCode: Scalars['String'];
};

export type TriggeredQuestionnaireNotificationPayloadV1 = {
  __typename?: 'TriggeredQuestionnaireNotificationPayloadV1';
  messageId: Scalars['String'];
  questionnaireCode: Scalars['String'];
  requestCode: Scalars['String'];
  scheduleCode: Scalars['String'];
  versionCode: Scalars['String'];
};

export type TutorialStepV1 = {
  __typename?: 'TutorialStepV1';
  bodyMd: Scalars['String'];
  name: Scalars['String'];
  stepNumber: Scalars['Int'];
};

export enum UnitOfDateV1 {
  A = 'A',
  D = 'D',
  MO = 'MO',
  WK = 'WK',
}

export type UpdateQuestionnaireResponseInV1 = {
  answers: Array<QuestionnaireResponseAnswerV1Input>;
  questionnaireResponseId: Scalars['String'];
  statusInfo: QuestionnaireStatusInfoV1Input;
};

export type UpdatedQuestionnaireResponseV1 = {
  __typename?: 'UpdatedQuestionnaireResponseV1';
  questionnaireResponseId: Scalars['ID'];
};

export type VerbalRatingScaleAnswerV1 = {
  __typename?: 'VerbalRatingScaleAnswerV1';
  accessibilityHint?: Maybe<Scalars['String']>;
  accessibilityImageLabel?: Maybe<Scalars['String']>;
  accessibilityLabel?: Maybe<Scalars['String']>;
  answerCode: Scalars['String'];
  imageSrc?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  textMd?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export type VerbalRatingScaleConfigV1 = {
  __typename?: 'VerbalRatingScaleConfigV1';
  selectionDisplay?: Maybe<VerbalRatingScaleSelectionDisplay>;
  version?: Maybe<WidgetVersionV1>;
};

export type VerbalRatingScaleDefinitionV1 = QuestionDefinitionV1 & {
  __typename?: 'VerbalRatingScaleDefinitionV1';
  answerOptions: Array<VerbalRatingScaleAnswerV1>;
  bodyMd?: Maybe<Scalars['String']>;
  conditionalSubBranches?: Maybe<Array<QuestionConditionalSubBranchV1>>;
  defaultNextQuestionCode?: Maybe<Scalars['String']>;
  heading: Scalars['String'];
  headingMd?: Maybe<Scalars['String']>;
  jsonMeta?: Maybe<Scalars['String']>;
  mandatory: Scalars['Boolean'];
  questionCode: Scalars['String'];
  scrollDownHint: ScrollDownHintV1;
  verbalRatingScaleNextQuestionBranches: Array<QuestionVerbalRatingScaleNextQuestionBranchV2>;
  widgetType: WidgetTypeV1;
};

export type VerbalRatingScaleMultiChoiceAnswerV1 = {
  __typename?: 'VerbalRatingScaleMultiChoiceAnswerV1';
  accessibilityHint?: Maybe<Scalars['String']>;
  accessibilityImageLabel?: Maybe<Scalars['String']>;
  accessibilityLabel?: Maybe<Scalars['String']>;
  answerCode: Scalars['String'];
  exclusiveChoice?: Maybe<Scalars['Boolean']>;
  imageSrc?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  textMd?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export type VerbalRatingScaleMultiChoiceDefinitionV1 = QuestionDefinitionV1 & {
  __typename?: 'VerbalRatingScaleMultiChoiceDefinitionV1';
  answerOptions: Array<VerbalRatingScaleMultiChoiceAnswerV1>;
  bodyMd?: Maybe<Scalars['String']>;
  conditionalSubBranches?: Maybe<Array<QuestionConditionalSubBranchV1>>;
  defaultNextQuestionCode?: Maybe<Scalars['String']>;
  heading: Scalars['String'];
  headingMd?: Maybe<Scalars['String']>;
  jsonMeta?: Maybe<Scalars['String']>;
  mandatory: Scalars['Boolean'];
  maxAnswerCount?: Maybe<Scalars['Int']>;
  minAnswerCount?: Maybe<Scalars['Int']>;
  questionCode: Scalars['String'];
  scrollDownHint: ScrollDownHintV1;
  subBranchQuestions?: Maybe<Array<QuestionSubBranchV1>>;
  widgetType: WidgetTypeV1;
};

export enum VerbalRatingScaleSelectionDisplay {
  CHECKBOX = 'checkbox',
  LINESHADING = 'lineShading',
  RADIO = 'radio',
}

export type VerbalRatingScaleTreeDefinitionV1 = QuestionDefinitionV1 & {
  __typename?: 'VerbalRatingScaleTreeDefinitionV1';
  bodyMd?: Maybe<Scalars['String']>;
  conditionalSubBranches?: Maybe<Array<QuestionConditionalSubBranchV1>>;
  defaultNextQuestionCode?: Maybe<Scalars['String']>;
  entries: Array<VerbalRatingScaleTreeEntryV1>;
  heading: Scalars['String'];
  headingMd?: Maybe<Scalars['String']>;
  jsonMeta?: Maybe<Scalars['String']>;
  mandatory: Scalars['Boolean'];
  maxAnswerCount?: Maybe<Scalars['Int']>;
  minAnswerCount?: Maybe<Scalars['Int']>;
  questionCode: Scalars['String'];
  selectedCountLabelTs?: Maybe<PluralisedTemplateStringV1>;
  selectedLabel: Scalars['String'];
  subBranchQuestions?: Maybe<Array<QuestionSubBranchV1>>;
  widgetType: WidgetTypeV1;
};

export type VerbalRatingScaleTreeEntryV1 = {
  __typename?: 'VerbalRatingScaleTreeEntryV1';
  answer?: Maybe<VerbalRatingScaleMultiChoiceAnswerV1>;
  childEntries?: Maybe<Array<VerbalRatingScaleTreeEntryV1>>;
  entryCode: Scalars['String'];
  jsonMeta?: Maybe<Scalars['String']>;
  leftOrientationLabel?: Maybe<OptionalLabelledControlV1>;
  rightOrientationLabel?: Maybe<OptionalLabelledControlV1>;
  text?: Maybe<Scalars['String']>;
  textMd?: Maybe<Scalars['String']>;
};

export type VerbalRatingScaleTreeSystemTextsV1 = {
  __typename?: 'VerbalRatingScaleTreeSystemTextsV1';
  listViewTabButton: LabelledControlV1;
  oppositeSideButton: ControlV1;
  visualViewTabButton: LabelledControlV1;
  zoomInButton: ControlV1;
  zoomOutButton: ControlV1;
};

export type VerbalRatingScaleTreeTutorialTextsV1 = {
  __typename?: 'VerbalRatingScaleTreeTutorialTextsV1';
  dontShowAgainText?: Maybe<LabelledControlV1>;
  lastButtonLabel: LabelledControlV1;
  nextButtonLabel: LabelledControlV1;
  steps: VerbalRatingScaleTutorialSteps;
  stepsLabelTs: PluralisedTemplateStringV1;
};

export type VerbalRatingScaleTutorialSteps = {
  __typename?: 'VerbalRatingScaleTutorialSteps';
  oppositeSideButton: TutorialStepV1;
  selectionEntry: TutorialStepV1;
  tabBar: TutorialStepV1;
  zoomInButton: TutorialStepV1;
  zoomOutButton: TutorialStepV1;
};

export type VisualAnalogScaleConfigV1 = {
  __typename?: 'VisualAnalogScaleConfigV1';
  version?: Maybe<WidgetVersionV1>;
};

export type VisualAnalogScaleDefinitionV1 = QuestionDefinitionV1 & {
  __typename?: 'VisualAnalogScaleDefinitionV1';
  bodyMd?: Maybe<Scalars['String']>;
  clearButtonTexts?: Maybe<OptionalLabelledControlV1>;
  closeButtonTexts?: Maybe<OptionalControlV1>;
  conditionalSubBranches?: Maybe<Array<QuestionConditionalSubBranchV1>>;
  confirmButtonTexts?: Maybe<OptionalLabelledControlV1>;
  defaultNextQuestionCode?: Maybe<Scalars['String']>;
  editButtonTexts?: Maybe<OptionalLabelledControlV1>;
  errorValueTooHigh?: Maybe<Scalars['String']>;
  errorValueTooLow?: Maybe<Scalars['String']>;
  heading: Scalars['String'];
  headingMd?: Maybe<Scalars['String']>;
  invalidFormatMessage?: Maybe<Scalars['String']>;
  jsonMeta?: Maybe<Scalars['String']>;
  keyboardEntryDescriptionMd?: Maybe<Scalars['String']>;
  keyboardEntryHeaderMd?: Maybe<Scalars['String']>;
  keyboardEntryNumberBoxHeader?: Maybe<OptionalLabelledControlV1>;
  mandatory: Scalars['Boolean'];
  maxAccessibilityLabel?: Maybe<Scalars['String']>;
  maxLabel: Scalars['String'];
  maxLabelMd?: Maybe<Scalars['String']>;
  maxValue: Scalars['Int'];
  meta?: Maybe<VisualAnalogScaleQuestionDefinitionMetaV1>;
  minAccessibilityLabel?: Maybe<Scalars['String']>;
  minLabel: Scalars['String'];
  minLabelMd?: Maybe<Scalars['String']>;
  minValue: Scalars['Int'];
  numberBoxHeader?: Maybe<OptionalLabelledControlV1>;
  orientation?: Maybe<DeviceOrientationV2>;
  questionCode: Scalars['String'];
  selectButtonTexts?: Maybe<OptionalLabelledControlV1>;
  selectedValueLabel?: Maybe<Scalars['String']>;
  sliderAccessibilityHint?: Maybe<Scalars['String']>;
  sliderAccessibilityLabel?: Maybe<Scalars['String']>;
  visualAnalogScaleNextQuestionBranches: Array<QuestionVisualAnalogScaleNextQuestionBranchV2>;
  widgetType: WidgetTypeV1;
};

export type VisualAnalogScaleQuestionDefinitionConfigV1 = {
  __typename?: 'VisualAnalogScaleQuestionDefinitionConfigV1';
  isNumberBoxEditable: Scalars['Boolean'];
  isNumberBoxVisible: Scalars['Boolean'];
  orientation: DeviceOrientationV2;
};

export type VisualAnalogScaleQuestionDefinitionMetaV1 = {
  __typename?: 'VisualAnalogScaleQuestionDefinitionMetaV1';
  CONFIG_V1?: Maybe<VisualAnalogScaleQuestionDefinitionConfigV1>;
};

export enum WidgetTypeV1 {
  ADVANCED_NUMERIC_RATING_SCALE_V1 = 'ADVANCED_NUMERIC_RATING_SCALE_V1',
  BOOLEAN_QUESTION_V1 = 'BOOLEAN_QUESTION_V1',
  COUNTER_V1 = 'COUNTER_V1',
  DATE_TIME_V1 = 'DATE_TIME_V1',
  DATE_V1 = 'DATE_V1',
  END_OF_SUB_BRANCH_V1 = 'END_OF_SUB_BRANCH_V1',
  FREE_TEXT_V1 = 'FREE_TEXT_V1',
  HEALTH_SCALE_V1 = 'HEALTH_SCALE_V1',
  INSTRUCTION_V1 = 'INSTRUCTION_V1',
  INTRODUCTION_V1 = 'INTRODUCTION_V1',
  NUMERIC_RATING_SCALE_V1 = 'NUMERIC_RATING_SCALE_V1',
  REVIEW_SCREEN_V1 = 'REVIEW_SCREEN_V1',
  SUBMIT_V1 = 'SUBMIT_V1',
  TIME_V1 = 'TIME_V1',
  VERBAL_RATING_SCALE_MULTI_CHOICE_V1 = 'VERBAL_RATING_SCALE_MULTI_CHOICE_V1',
  VERBAL_RATING_SCALE_TREE_V1 = 'VERBAL_RATING_SCALE_TREE_V1',
  VERBAL_RATING_SCALE_V1 = 'VERBAL_RATING_SCALE_V1',
  VISUAL_ANALOG_SCALE_V1 = 'VISUAL_ANALOG_SCALE_V1',
}

export type WidgetVersionConfigV1 = {
  __typename?: 'WidgetVersionConfigV1';
  boolean?: Maybe<VerbalRatingScaleConfigV1>;
  verbalRatingScale?: Maybe<VerbalRatingScaleConfigV1>;
  verbalRatingScaleMultiChoice?: Maybe<VerbalRatingScaleConfigV1>;
  visualAnalogScale?: Maybe<VisualAnalogScaleConfigV1>;
};

export enum WidgetVersionV1 {
  V1 = 'V1',
  V2 = 'V2',
}

export type ZonedDateTime = {
  __typename?: 'ZonedDateTime';
  unixTimeMillis: Scalars['Float'];
  zone: Scalars['String'];
};

export type ZonedDateTimeInput = {
  unixTimeMillis: Scalars['Float'];
  zone: Scalars['String'];
};

export type ZoomConfig = {
  __typename?: 'ZoomConfig';
  apiKey: Scalars['String'];
  clientId: Scalars['String'];
  sdkAppKey: Scalars['String'];
};
