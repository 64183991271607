import { CmsHookResult, useCmsQuery } from '@csp/common';
import {
  UnifyWebRemotePatientMonitoring,
  UnifyWebRemotePatientMonitoringMock,
  UnifyWebRemotePatientMonitoringSchema,
} from '@csp/csp-content-schema';
import { toHookConfig } from '../../util/toHookConfig';

export const useCmsRemotePatientMonitoring = (): CmsHookResult<UnifyWebRemotePatientMonitoring> =>
  useCmsQuery<UnifyWebRemotePatientMonitoring>(
    'unify:webremotepatientmonitoring',
    toHookConfig({ defaultData: UnifyWebRemotePatientMonitoringMock as UnifyWebRemotePatientMonitoring }),
    UnifyWebRemotePatientMonitoringSchema,
  );
