import { GenericSchedule } from '@csp/csp-common-scheduling';
import { AppointmentActiveScheduleV1 } from '@csp/dmdp-api-appointment-dto';
import { VisitLocationType } from '@csp/csp-common-model';
import { DefaultScheduleCodes } from '../type/DefaultScheduleCodes';
import { VisitVersion } from './VisitVersion';

export type VisitSchedule = GenericSchedule<VisitVersion>;

const from = (appointmentActiveScheduleV1: AppointmentActiveScheduleV1): VisitSchedule => ({
  scheduleCode: appointmentActiveScheduleV1.scheduleCode,
  activeVersionWindows: appointmentActiveScheduleV1.activeVersionWindows.map(window =>
    VisitVersion.fromV1(appointmentActiveScheduleV1.scheduleCode, window),
  ),
});

const toDefaultVisitSchedule = (supportedVisitLocationTypes: VisitLocationType[]): VisitSchedule => ({
  scheduleCode: DefaultScheduleCodes.DEFAULT_SCHEDULE_CODE,
  activeVersionWindows: [VisitVersion.toDefaultVisitVersion(supportedVisitLocationTypes)],
});

export const VisitSchedule = {
  from,
  toDefaultVisitSchedule,
};
