import {
  DataChangeQueryFilter,
  DataChangeQueryFilterCriteria,
  EMPTY_DATA_CHANGE_QUERY_FILTER_CRITERIA,
  PartialDataChangeQueryFilter,
} from '@csp/csp-common-dcf';
import { Maybe } from '@csp/csp-common-model';
import { isEmpty, omitBy } from 'lodash';

const toFilterCount = (filter: DataChangeQueryFilter): number => {
  const criteriaWithValues = omitBy(filter.criteria, isEmpty);
  return Object.keys(criteriaWithValues).length;
};

const toQueryFilterCriteria = (
  partialFilterCriteria: Maybe<Partial<DataChangeQueryFilterCriteria>>,
): DataChangeQueryFilterCriteria => ({
  ...EMPTY_DATA_CHANGE_QUERY_FILTER_CRITERIA,
  ...partialFilterCriteria,
});

const toQueryFilter = (key: string, partialFilter: PartialDataChangeQueryFilter): DataChangeQueryFilter => ({
  key,
  pageSize: 50,
  count: true,
  ...partialFilter,
  criteria: toQueryFilterCriteria(partialFilter.criteria),
});

export const DataChangeQueryFilterUtil = { toFilterCount, toQueryFilterCriteria, toQueryFilter };
