import { ZoomMeetingId } from '@csp/csp-common-model';
import { /*ZoomErrorInfo,*/ ZoomService } from '@csp/csp-common-zoom';
import { TelemetryService } from '@csp/csp-web-telemetry';
import { ZoomContextState } from '../model/ZoomContextState';
/*import { useMediaQuery, useTheme } from '@csp/csp-web-ui';
import { EmbeddedClient } from '@zoomus/websdk/embedded';
import { useCallback, useState } from 'react';
import { useBoolean, useErrorInfo } from '@csp/fe-hooks';
import { useNavigate } from 'react-router-dom';
import { ZoomMeetingService } from '../../service/ZoomMeetingService';
import { useGenericEntry } from '../../../../common/hooks/useGenericEntry'; */

export const useZoomContextProvider = (): ZoomContextState => {
  //TODO:[Saman M]: remove comment out codes when @zoomus/websdk package is installed
  /*const { breakpoints } = useTheme();
  const navigate = useNavigate();
  const smDown = useMediaQuery(breakpoints.down('sm'));
  const [currentMeetingId, setCurrentMeetingId] = useState<string>();
  const [isMeetingStarted, setMeetingStarted, setNotMeetingStarted] = useBoolean();
  const [embeddedClient, setEmbeddedClient] = useState<typeof EmbeddedClient>();
  const patientVisitTexts = useGenericEntry('webHcpPatientVisit');
  const { errorInfo, setErrorInfo, clearErrorInfo } = useErrorInfo();

  const startZoomMeetingInternal = async (userName: string, zoomMeetingId: ZoomMeetingId): Promise<void> => {
    const client = await ZoomMeetingService.startMeeting(embeddedClient, zoomMeetingId, userName, smDown);
    setMeetingStarted();
    setCurrentMeetingId(`${zoomMeetingId}`);
    setEmbeddedClient(client);
  };

  const handleStartError = (error: unknown): void =>
    setErrorInfo(toErrorInfo({ message: patientVisitsTexts?.alert.unhandledError, error }));

  const startZoomMeeting = async (userName: string, zoomMeetingId: ZoomMeetingId): Promise<void> => {
    setNotMeetingStarted();
    clearErrorInfo();
    try {
      await startZoomMeetingInternal(userName, zoomMeetingId);
    } catch (error) {
      // Always give user a way out from illegal Zoom state
      const zoomErrorInfo = ZoomErrorInfo.fromError(error);
      if (zoomErrorInfo?.isDuplicateJoin) {
        // Zoom Sdk need to be refreshed to get out of this state v2.4.0
        navigate(0);
      } else {
        // Try to end and start meeting again when unexpected error occur
        try {
          await embeddedClient?.endMeeting();
          await startZoomMeetingInternal(userName, zoomMeetingId);
        } catch (error2) {
          handleStartError(error2);
        }
      }
    }
  };

  const endMeeting = useCallback(async () => {
    try {
      await embeddedClient?.endMeeting();
      setNotMeetingStarted();
    } catch {
      // Don't fail on end meeting
    }
  }, [embeddedClient, setNotMeetingStarted]);

    const isCurrentMeeting = (meetingId: string): boolean => currentMeetingId === meetingId;

  */

  const startNativeZoomMeeting = async (zoomMeetingId: ZoomMeetingId): Promise<void> => {
    const zoomMeeting = await ZoomService.getMeeting(zoomMeetingId);
    // This is work around to avoid extra steps when starting a Zoom video meeting
    // in Firefox, Safari and Edge. This should be replaced by:
    // 1. Hosting the Zoom video client on Unify server
    // 2. Integrate and use Zoom video SDK component view
    const startUrl = zoomMeeting.startUrl;
    let startUrlToUse = startUrl;
    try {
      if (startUrl) {
        const zakToken = new URL(startUrl).searchParams.get('zak');
        startUrlToUse = zakToken ? `https://zoom.us/wc/${zoomMeetingId}/start?zak=${zakToken}` : startUrl;
      }
    } catch (error) {
      TelemetryService.trackException({ error });
    }
    window.open(startUrlToUse, '_blank');
  };

  return {
    startNativeZoomMeeting,
    /* startZoomMeeting,
    endMeeting,
    errorInfo,
    isMeetingStarted,
    isCurrentMeeting, */
  };
};
