import { UserPreferencesMetaV1 } from '../../dto/UserMetaV1';
import { UserPreferencesV1 } from './UserPreferencesV1';

export const USER_PREFERENCES_V1_META_TYPE = 'USER_PREFERENCES_V1';

export const createUserPreferencesMetaV1 = (patientCloseOutTrackerV1: UserPreferencesV1): UserPreferencesMetaV1 => ({
  type: USER_PREFERENCES_V1_META_TYPE,
  name: USER_PREFERENCES_V1_META_TYPE,
  data: patientCloseOutTrackerV1,
});
