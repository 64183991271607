import { hoursToSeconds } from '@csp/csp-common-date-util';
import { MemCacheService } from '@csp/csp-common-memcache';
import { ApiOptions, OrgId, ZonedDateTime } from '@csp/csp-common-model';
import {
  findScheduleVersionsActiveNow,
  OrgSchedulesMapper,
  OrgsSchedules,
  ScheduleCachedService,
} from '@csp/csp-common-scheduling';
import { toRestOptions } from '@csp/csp-fe-auth';
import { DeviceOrganizationsRestServiceV1, DeviceSchedulesRestServiceV1 } from '@csp/dmdp-api-client';
import { DeviceObservationSchedule } from '../model/DeviceObservationSchedule';
import { DeviceObservationVersion } from '../model/DeviceObservationVersion';
import { DeviceObservationRequest } from '../model/DeviceObservationRequest';

const cacheKeyOf = (orgId: OrgId): string[] => ['device.schedule', orgId];

const getCachedActiveSchedules = async (
  orgId: string,
  apiOptions?: ApiOptions,
): Promise<DeviceObservationSchedule[]> => {
  let deviceObservationSchedules = MemCacheService.getValue<DeviceObservationSchedule[]>(cacheKeyOf(orgId));
  if (!deviceObservationSchedules) {
    const { activeSchedules = [] } =
      await DeviceOrganizationsRestServiceV1.getObservationActiveSchedulesByOrganizationStructure(
        toRestOptions(apiOptions),
        orgId,
      );
    deviceObservationSchedules = activeSchedules.map(DeviceObservationSchedule.from);
    MemCacheService.setValue(cacheKeyOf(orgId), hoursToSeconds(1), deviceObservationSchedules);
  }
  return deviceObservationSchedules;
};

const getActivatedDeviceSchedulesForOrganizationIds = async (
  orgIds: OrgId[],
  apiOptions?: ApiOptions,
): Promise<OrgsSchedules<DeviceObservationSchedule, DeviceObservationRequest>> => {
  const deviceActiveScheduleOrganizationsV2 = await DeviceSchedulesRestServiceV1.queryActiveSchedulesByOrganizationIds(
    toRestOptions(apiOptions),
    { orgIds },
  );

  return OrgsSchedules.from<DeviceObservationSchedule, DeviceObservationRequest>(
    OrgSchedulesMapper.fromV1s(deviceActiveScheduleOrganizationsV2, DeviceObservationSchedule.from),
  );
};

const getCachedActivatedDeviceSchedulesForOrganizationIds = async (
  organizationIds: OrgId[],
  apiOptions?: ApiOptions,
): Promise<OrgsSchedules<DeviceObservationSchedule, DeviceObservationRequest>> =>
  ScheduleCachedService.getAllActivatedSchedulesForOrganizationIds(
    'device',
    organizationIds,
    getActivatedDeviceSchedulesForOrganizationIds,
    apiOptions,
  );

const findDeviceObservationVersionWindowsActiveAt = (
  dateTime: ZonedDateTime,
  schedules: DeviceObservationSchedule[],
): DeviceObservationVersion[] => findScheduleVersionsActiveNow(schedules, dateTime);

export const DeviceObservationScheduleService = {
  findDeviceObservationVersionWindowsActiveAt,
  getActivatedDeviceSchedulesForOrganizationIds,
  getCachedActivatedDeviceSchedulesForOrganizationIds,
  getCachedActiveSchedules,
};
