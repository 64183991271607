import { Maybe } from '@csp/csp-common-model';
import { ContentCacheObject } from '../model/ContentCacheService';
import { ContentPackageType } from '../model/ContentPackageType';
import { UnifyContentPackage } from '../types/UnifyContentPackage';
import { ContentstackConfig } from '../config/model/ContentstackConfig';
import { getCacheProvider, getCacheTimeoutInSeconds } from './configUtil';

const cacheHasExpired = (lastUpdated: number, cacheTimeoutInSeconds: number | undefined): boolean => {
  if (cacheTimeoutInSeconds === 0) {
    return true;
  } else {
    return cacheTimeoutInSeconds !== undefined && lastUpdated + cacheTimeoutInSeconds * 1000 < new Date().getTime();
  }
};

export const getCachedPackage = (
  packageType: ContentPackageType,
  config?: ContentstackConfig,
): Maybe<ContentCacheObject> => {
  const cacheProvider = getCacheProvider(config);
  if (cacheProvider) {
    const contentPackage = cacheProvider.get(packageType);
    const cacheTimeoutInSeconds = getCacheTimeoutInSeconds(config);
    if (contentPackage && cacheHasExpired(contentPackage.lastUpdated, cacheTimeoutInSeconds)) {
      return { ...contentPackage, expired: true };
    } else {
      return contentPackage;
    }
  }
  return undefined;
};

export const setCachedPackage = async (
  packageType: ContentPackageType,
  etag: string | undefined,
  content: UnifyContentPackage,
  signedUrlExpirationTimeUnixMillis?: number,
  config?: ContentstackConfig,
): Promise<void> => {
  const cacheProvider = getCacheProvider(config);
  if (cacheProvider && etag) {
    await cacheProvider.set(packageType, {
      content,
      etag,
      type: packageType,
      lastUpdated: new Date().getTime(),
      signedUrlExpirationTimeUnixMillis,
      expired: false,
    });
  }
};
