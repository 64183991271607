import { ContentRole } from '@csp/csp-common-content-utils';
import { ObjectType, RestOptions } from '@csp/csp-common-model';
import { ResponseObject } from '@hapi/hapi';
import { ContentPackageType } from '../../model/ContentPackageType';
import { contentDeliveryUrl } from '../../utils/configUtil';
import { ContentQueryParameters } from '../model/ContentQueryParameters';

const baseUrl = (): string => `${contentDeliveryUrl()}/preview-content/v1`;

const getPreviewContent = async (
  { axios, signal }: RestOptions,
  packageType: Extract<ContentPackageType, 'public' | 'generic'>,
  clientType: string,
  locale: string,
  queryParameters?: ContentQueryParameters,
): Promise<NonNullable<ObjectType>> => {
  const url = `${baseUrl()}/${packageType}/${clientType}/${locale}/content.json`;
  const { data } = await axios.get(url, { signal, params: queryParameters });
  return data;
};

const getPreviewSchema = async (
  { axios, signal }: RestOptions,
  packageType: ContentPackageType,
  queryParameters?: ContentQueryParameters,
): Promise<ResponseObject> => {
  const url = `${baseUrl()}/${packageType}/schema.json`;
  const { data } = await axios.get(url, { signal, params: queryParameters });
  return data;
};

const getStudyPreviewContent = async (
  { axios, signal }: RestOptions,
  clientType: string,
  role: ContentRole,
  locale: string,
  queryParameters?: ContentQueryParameters,
): Promise<NonNullable<ObjectType>> => {
  const url = `${baseUrl()}/study/${clientType}/${role}/${locale}/content.json`;
  const { data } = await axios.get(url, { signal, params: queryParameters });
  return data;
};

const servePreviewAsset = async (
  { axios, signal }: RestOptions,
  packageType: ContentPackageType,
  clientType: string,
  locale: string,
  assetId: string,
  filename: string,
  queryParameters?: ContentQueryParameters,
): Promise<ResponseObject> => {
  const url = `${baseUrl}/${packageType}/${clientType}/${locale}/assets/${assetId}/${filename}`;
  const { data } = await axios.get(url, { signal, params: queryParameters });
  return data;
};

export const ContentPreviewRestServiceV1 = {
  getPreviewContent,
  getPreviewSchema,
  getStudyPreviewContent,
  servePreviewAsset,
};
