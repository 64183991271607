export enum BioSampleValueType {
  TWO_D_CLASSICAL_CONDITIONALLY_REPROGRAMMED_CELLS = 'TWO_D_CLASSICAL_CONDITIONALLY_REPROGRAMMED_CELLS',
  TWO_D_MODIFIED_CONDITIONALLY_REPROGRAMMED_CELLS = 'TWO_D_MODIFIED_CONDITIONALLY_REPROGRAMMED_CELLS',
  THREE_D_ORGANOID = 'THREE_D_ORGANOID',
  THREE_D_AIR_LIQUID_INTERFACE_ORGANOID = 'THREE_D_AIR_LIQUID_INTERFACE_ORGANOID',
  THREE_D_NEUROSPHERE = 'THREE_D_NEUROSPHERE',
  ADHERENT_CELL_LINE = 'ADHERENT_CELL_LINE',
  AMNIOTIC_FLUID = 'AMNIOTIC_FLUID',
  ASCITES_FLUID = 'ASCITES_FLUID',
  BILE = 'BILE',
  BIOFLUID = 'BIOFLUID',
  BIOFLUID_COLLECTED_FROM_ORGANISM = 'BIOFLUID_COLLECTED_FROM_ORGANISM',
  BIOSPECIMEN = 'BIOSPECIMEN',
  BIOSPECIMEN_COLLECTED_FROM_ORGANISM = 'BIOSPECIMEN_COLLECTED_FROM_ORGANISM',
  BLOOD = 'BLOOD',
  BONE_MARROW = 'BONE_MARROW',
  BONE_MARROW_ASPIRATE = 'BONE_MARROW_ASPIRATE',
  BREAST_MILK = 'BREAST_MILK',
  BRONCHOALVEOLAR_LAVAGE_FLUID = 'BRONCHOALVEOLAR_LAVAGE_FLUID',
  BRUSH_BIOPSY = 'BRUSH_BIOPSY',
  CEREBROSPINAL_FLUID = 'CEREBROSPINAL_FLUID',
  CELL_CULTURE_DERIVED_SAMPLE = 'CELL_CULTURE_DERIVED_SAMPLE',
  CELL_LINE = 'CELL_LINE',
  CELL_SPECIMEN = 'CELL_SPECIMEN',
  EFFUSION_FLUID = 'EFFUSION_FLUID',
  EXHALATION_FLUID = 'EXHALATION_FLUID',
  EXUDATE_FLUID = 'EXUDATE_FLUID',
  GASTRIC_FLUID = 'GASTRIC_FLUID',
  INDUCED_SPUTUM_SPECIMEN = 'INDUCED_SPUTUM_SPECIMEN',
  LACRIMAL_FLUID = 'LACRIMAL_FLUID',
  LIQUID_SUSPENSION_CELL_LINE = 'LIQUID_SUSPENSION_CELL_LINE',
  MID_STREAM_URINE = 'MID_STREAM_URINE',
  MIDDLE_EAR_FLUID = 'MIDDLE_EAR_FLUID',
  MUCOSA = 'MUCOSA',
  NASAL_LAVAGE = 'NASAL_LAVAGE',
  PERICARDIAL_FLUID = 'PERICARDIAL_FLUID',
  PLEURAL_FLUID = 'PLEURAL_FLUID',
  PRIMARY_CELL_CULTURE = 'PRIMARY_CELL_CULTURE',
  PROSTATIC_FLUID = 'PROSTATIC_FLUID',
  PUS = 'PUS',
  SALIVA = 'SALIVA',
  SECRETION = 'SECRETION',
  SEMEN = 'SEMEN',
  SHAVE_EXCISION_ON_CARTILAGE_SPECIMEN = 'SHAVE_EXCISION_ON_CARTILAGE_SPECIMEN',
  SHAVE_EXCISION_ON_JOINT_CARTILAGE_SPECIMEN = 'SHAVE_EXCISION_ON_JOINT_CARTILAGE_SPECIMEN',
  SHAVE_EXCISION_ON_SKIN_SPECIMEN = 'SHAVE_EXCISION_ON_SKIN_SPECIMEN',
  SINUS_SECRETION = 'SINUS_SECRETION',
  SOLID_SPECIMEN_COLLECTED_FROM_ORGANISM = 'SOLID_SPECIMEN_COLLECTED_FROM_ORGANISM',
  SOLID_TISSUE_SAMPLE = 'SOLID_TISSUE_SAMPLE',
  SPONTANEOUS_SPUTUM_SPECIMEN = 'SPONTANEOUS_SPUTUM_SPECIMEN',
  SPUTUM = 'SPUTUM',
  STOOL_SPECIMEN = 'STOOL_SPECIMEN',
  SYNOVIAL_FLUID = 'SYNOVIAL_FLUID',
  TISSUE_FRAGMENTS = 'TISSUE_FRAGMENTS',
  TRACHEAL_ASPIRATE = 'TRACHEAL_ASPIRATE',
  UMBILICAL_BLOOD = 'UMBILICAL_BLOOD',
  UMBILICAL_ARTERY_BLOOD = 'UMBILICAL_ARTERY_BLOOD',
  UMBILICAL_VENOUS_BLOOD = 'UMBILICAL_VENOUS_BLOOD',
  URINE = 'URINE',
  WHOLE_BLOOD = 'WHOLE_BLOOD',
  WOUND_SECRETION = 'WOUND_SECRETION',
  VOMIT = 'VOMIT',
  OTHER = 'OTHER',
  UNKNOWN = 'UNKNOWN',
}
