import { fromTimezoneStr, fromTimezoneStrOrUndefined } from '@csp/csp-common-date-util';
import { Maybe, ZonedDateTime, ZonedDateTimeStr } from '@csp/csp-common-model';
import { VersionCode } from './codes/VersionCode';

export type ScheduleVersionInfo = {
  versionCode: VersionCode;
  activeFrom: ZonedDateTime;
  activeTo?: ZonedDateTime;
};

const fromVersion = ({
  versionCode,
  activeFrom,
  activeTo,
}: {
  versionCode: VersionCode;
  activeFrom: ZonedDateTime;
  activeTo?: ZonedDateTime;
}): ScheduleVersionInfo => ({
  versionCode,
  activeFrom,
  activeTo,
});

const fromApi = (
  versionCode: VersionCode,
  activeFromStr: ZonedDateTimeStr,
  activeToStr: Maybe<ZonedDateTimeStr>,
): ScheduleVersionInfo => {
  const activeFrom = fromTimezoneStr(activeFromStr);
  const activeTo = fromTimezoneStrOrUndefined(activeToStr);

  return fromVersion({ versionCode, activeFrom, activeTo });
};

export const ScheduleVersionInfo = {
  fromApi,
  fromVersion,
};
