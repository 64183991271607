import { Maybe } from '@csp/csp-common-model';
import { DmdpPodConfigs, DmdpPodUrl } from './model/DmdpPodConfigs';

export const createDmdpPodConfigs = (baseUrl: string, messagingpodemail?: Maybe<DmdpPodUrl>): DmdpPodConfigs => ({
  ...DmdpPodConfigs.empty(),
  applicationpod: {
    baseUrl: `${baseUrl}/applicationpod`,
  },
  auditpod: {
    baseUrl: `${baseUrl}/auditpod`,
  },
  biosampling: {
    baseUrl: `${baseUrl}/biosampling`,
  },
  cmspod: {
    baseUrl: `${baseUrl}/cmspod`,
  },
  contentpod: {
    baseUrl: `${baseUrl}/contentpod`,
  },
  datachangerecord: {
    baseUrl: `${baseUrl}/datachangerecord`,
  },
  devicepod: {
    baseUrl: `${baseUrl}/devicepod`,
  },
  journalpod: {
    baseUrl: `${baseUrl}/journalpod`,
  },
  medicationpod: {
    baseUrl: `${baseUrl}/medicationpod`,
  },
  messagingpod: {
    baseUrl: `${baseUrl}/messagingpod`,
  },
  messagingpodemail: {
    baseUrl: messagingpodemail || `${baseUrl}/messagingpod`,
  },
  overreaderpod: {
    baseUrl: `${baseUrl}/overreaderpod`,
  },
  rdspod: {
    baseUrl: `${baseUrl}/rdspod`,
  },
  resourcepod: {
    baseUrl: `${baseUrl}/resourcepod`,
  },
  studypod: {
    baseUrl: `${baseUrl}/studypod`,
  },
  userpod: {
    baseUrl: `${baseUrl}/userpod`,
  },
  workflowpod: {
    baseUrl: `${baseUrl}/workflowpod`,
  },
});
