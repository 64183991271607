/* eslint-disable */
export const UnifyWebPatientStudyMock = {
  title: 'title',
  patientHealthEvent: {
    viewHealthEvent: {
      text: {
        information: [
          {
            header: 'Study criteria',
            bodyMd:
              'Time to the first occurrence of any of the components of this composite:\n\n* CV death\n* Hypertensive heart failure (HHF)',
          },
          {
            header: 'Guidance Information',
            bodyMd:
              '* Complete the report in the eCRF\n* Upload any documentation to sharepoint\n* Book a patient visit if required',
          },
          {
            header: 'How to follow up',
            bodyMd: 'If you need to follow up with the patient you can book an unscheduled visit here',
          },
        ],
      },
    },
  },
  ecoa: {
    ecoaList: {
      schedule: {
        title: { header: '[M]Schedule' },
        text: {
          bodyMd:
            '[M]The study protocol requires the patient completes the EQ-5D-5L questionnaire to provide their overall status of health. ',
        },
      },
      text: {
        information: [
          {
            header: 'eCOA description',
            bodyMd:
              'The study protocol requires the patient to complete the EQ-5D-5L questionnaire to provide their overall status of health.',
          },
          {
            header: 'Schedule',
            bodyMd:
              'The questionnaire is scheduled once per month, with an available date range in which the inquiry will be open to the patient',
          },
          { header: 'Threshold', bodyMd: 'If the percentage is below 85%, the patient is considered not compliant' },
        ],
      },
    },
  },
};
