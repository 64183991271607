import { FC } from 'react';
import { BoxProps } from './model/BoxProps';
import { Box } from './Box';

export const CenteredBox: FC<BoxProps> = ({ children, ...props }) => (
  <Box sx={{ height: '100vh', display: 'flex', backgroundSize: 'cover', alignItems: 'center' }}>
    <Box sx={{ margin: '0 auto', verticalAlign: 'middle' }} {...props}>
      {children}
    </Box>
  </Box>
);
