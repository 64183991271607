import {
  QuestionnaireActiveSchedulesOrganizationsV2,
  QuestionnaireActiveScheduleV2,
} from '@csp/dmdp-api-questionnaire-dto';
import { OrgId } from '@csp/csp-common-model';
import {
  DeviceObservationActiveSchedulesOrganizationsV1,
  DeviceObservationActiveScheduleV1,
} from '@csp/dmdp-api-device-dto';
import { ConsentActiveSchedulesOrganizationsV1, ConsentActiveScheduleV1 } from '@csp/dmdp-api-consent-dto';
import { AppointmentActiveSchedulesOrganizationsV1, AppointmentActiveScheduleV1 } from '@csp/dmdp-api-appointment-dto';
import { OrgSchedules } from '../model/OrgSchedules';
import { GenericSchedule } from '../model/schedulingModels/GenericSchedule';

type Func1<Return extends GenericSchedule> = (input: QuestionnaireActiveScheduleV2) => Return;
type Func2<Return extends GenericSchedule> = (input: DeviceObservationActiveScheduleV1) => Return;
type Func3<Return extends GenericSchedule> = (input: AppointmentActiveScheduleV1) => Return;
type Func4<Return extends GenericSchedule> = (input: ConsentActiveScheduleV1) => Return;

type Dto =
  | QuestionnaireActiveSchedulesOrganizationsV2
  | DeviceObservationActiveSchedulesOrganizationsV1
  | AppointmentActiveSchedulesOrganizationsV1
  | ConsentActiveSchedulesOrganizationsV1;

const fromV1s = <Return extends GenericSchedule>(
  dto: Dto,
  mapFunc: Func1<Return> | Func2<Return> | Func3<Return> | Func4<Return>,
): OrgSchedules<Return>[] => {
  const schedulesByOrgId = new Map<OrgId, Return[]>();

  dto.activeSchedulesOrganizations.forEach(schedulesOrgsV2 => {
    schedulesOrgsV2.orgIds.forEach(orgId => {
      if (!schedulesByOrgId.has(orgId)) {
        schedulesByOrgId.set(orgId, []);
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (schedulesByOrgId.get(orgId) ?? []).push((mapFunc as unknown)(schedulesOrgsV2.activeSchedule));
    });
  });

  return Array.from(schedulesByOrgId, ([orgId, schedules]) => ({
    orgId,
    schedules,
  }));
};

export const OrgSchedulesMapper = {
  fromV1s,
};
