import { checkAndClearJustificationMarkers, notIncludes } from '@csp/csp-common-util';
import styled from '@emotion/styled';
import { Box, SxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { palette, PaletteProps } from '@mui/system';
import { Property } from 'csstype';
import { FC, JSX } from 'react';
import ReactMarkdown, { Components } from 'react-markdown';
import { LiProps as MarkdownLiProps } from 'react-markdown/lib/ast-to-react';
import { PluggableList } from 'react-markdown/lib/react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize';
import { Theme } from '../../theme';
import { Image } from '../images/Image';
import { TextProps } from '../typography/model/TextProps';
import { Body1, Body2, Body3, H1, H2, H3, H4, H5, H6 } from '../typography/TextStyles';
import { Body } from './model/Body';
import { MarkdownColor } from './model/MarkdownColor';
import { underlineMarkdown } from './util/underlineMarkdown';

const getBodyComponent = (body: Body): FC<TextProps> => {
  if (body === 'body1') {
    return Body1;
  } else if (body === 'body2') {
    return Body2;
  } else {
    return Body3;
  }
};

const markdownColor = ({ palette }: Theme, color: MarkdownColor): string => {
  const ColorsMap: Record<MarkdownColor, string> = {
    primary: palette.text.primary,
    secondary: palette.text.secondary,
    infoDark: palette.info.dark,
    warningDark: palette.warning.dark,
    errorDark: palette.error.dark,
    successDark: palette.success.dark,
  };
  return ColorsMap[color];
};

const renderLi = ({ children }: MarkdownLiProps, body: Body): JSX.Element => {
  const Body = getBodyComponent(body);
  return (
    <li>
      <Body>{children}</Body>
    </li>
  );
};

const renderHeading = ({ children, ...props }: TextProps, Heading: FC<TextProps>): JSX.Element => (
  <Heading gutterBottom {...props}>
    {children}
  </Heading>
);

const renderBody = ({ children, ...props }: TextProps, body: Body, paragraph: boolean): JSX.Element => {
  const Body = getBodyComponent(body);
  return (
    <Body paragraph={paragraph} {...props}>
      {children}
    </Body>
  );
};

type Props = {
  [key: string]: unknown;
  children: string;
  components?: Components;
};

export type MarkdownProps = Props & {
  color?: MarkdownColor;
  whiteSpace?: Property.WhiteSpace;
  body?: Body;
  span?: Body;
  sx?: SxProps;
  paragraph?: boolean;
  /** String put before rest of the markdown to create a header with #### for example */
  prefix?: string;
};

const WrappedLinks = styled.a`
  overflow-wrap: break-word;
`;

type StyleProps = PaletteProps & {
  color: string;
  whiteSpace: Property.WhiteSpace;
  alignment: Property.TextAlign;
};

const MarkdownStyled = styled(Box, {
  shouldForwardProp: notIncludes(['color', 'whiteSpace', 'alignment']),
})<StyleProps>`
  ${palette}
  ${({ color }): string => `
    color: ${color};
  `}
  ${({ whiteSpace }): string => `
    white-space: ${whiteSpace};
  `}
  ${({ alignment }): string => `
    text-align: ${alignment};
  `}
`;

const sanitizeSchema = { ...defaultSchema, tagNames: [...(defaultSchema.tagNames ?? []), 'u'] };
/** Plugins used to enable html in markdown but sanitizes eveything except u tags */
const plugins: PluggableList = [rehypeRaw, [rehypeSanitize, sanitizeSchema]];

export const Markdown: FC<MarkdownProps> = ({
  color = 'primary',
  whiteSpace = 'normal',
  body = 'body2',
  span = 'body2',
  sx,
  paragraph = true,
  children,
  components,
  prefix = '',
}) => {
  const theme = useTheme();
  const { mdStr, alignment } = checkAndClearJustificationMarkers(children);

  return (
    <MarkdownStyled color={markdownColor(theme, color)} sx={sx} whiteSpace={whiteSpace} alignment={alignment}>
      <ReactMarkdown
        rehypePlugins={plugins}
        components={{
          h1: props => renderHeading(props, H1),
          h2: props => renderHeading(props, H2),
          h3: props => renderHeading(props, H3),
          h4: props => renderHeading(props, H4),
          h5: props => renderHeading(props, H5),
          h6: props => renderHeading(props, H6),
          li: props => renderLi(props, body),
          p: props => renderBody(props, body, paragraph),
          span: props => renderBody(props, span, paragraph),
          small: ({ children, ...props }) => (
            <Body3 component="small" {...props}>
              {children}
            </Body3>
          ),
          a: ({ children, ...props }) => <WrappedLinks {...props}>{children}</WrappedLinks>,
          img: Image,
          ...components,
        }}
      >
        {`${prefix} ${underlineMarkdown(mdStr)}`}
      </ReactMarkdown>
    </MarkdownStyled>
  );
};
