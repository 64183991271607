import { FC } from 'react';
import { FlexRow } from '../../layout/box/Box';
import { Spinner } from './Spinner';
import { SpinnerProps } from './model/SpinnerProps';

type Props = SpinnerProps & {
  isLoading?: boolean;
  p?: string | number;
};

export const Loading: FC<Props> = ({ isLoading, size, p = '20vh 0', children, ...boxProp }) =>
  isLoading ? (
    <FlexRow justifyContent="center" p={p} flex={1} {...boxProp}>
      <Spinner size={size} />
    </FlexRow>
  ) : (
    <>{children}</>
  );
