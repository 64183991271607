import { Grid } from '@csp/csp-web-ui';
import { FC, PropsWithChildren } from 'react';
//TODO:[Saman M]: remove comment out codes when @zoomus/websdk package is installed
//import { useZoomContext } from '../../../feature/video-meeting/zoom/context/hook/useZoomContext';

export const MainContent: FC<PropsWithChildren> = ({ children }) => {
  //const { isMeetingStarted } = useZoomContext();
  const isMeetingStarted = false;
  return (
    <Grid
      item
      xs={12}
      sx={{
        maxWidth: {
          sm: undefined,
          md: isMeetingStarted ? '85%' : undefined,
          lg: isMeetingStarted ? '85%' : '120rem',
        },
        margin: { md: undefined, lg: isMeetingStarted ? undefined : ' 0 auto' },
        pt: { xs: isMeetingStarted ? '35rem' : 2, sm: isMeetingStarted ? '33rem' : 2, md: 2 },
        pb: { xs: 2.5, sm: 4 },
        px: { xs: 2.5, sm: 5 },
        width: '100%',
      }}
    >
      {children}
    </Grid>
  );
};
