import { CspError, Maybe, VisitLocationType, VisitVariantType } from '@csp/csp-common-model';
import {
  Duration,
  GenericRequest,
  GenericRequestMapper,
  ReminderNotificationMetaMapper,
  RequestAction,
  RequestGroupMapper,
  RequestRef,
  RequestSideEffectConfig,
  RequestSideEffectConfigMapper,
  RequestSideEffectMetaV1,
  RequestWhile,
  RequestWhileCriterionType,
  RequestWhileOperator,
  ScheduleCode,
  Timing,
  UnitOfTime,
  VersionCode,
} from '@csp/csp-common-scheduling';
import {
  AppointmentDurationV1,
  AppointmentRequestV1,
  AppointmentTimingV1,
  AppointmentUnitOfTimeV1,
} from '@csp/dmdp-api-appointment-dto';
import { VisitRequestMetaMapper } from '../meta/mapper/VisitRequestMetaMapper';
import { VisitConfig } from '../meta/model/VisitConfig';
import { DEFAULT_UNSCHEDULED_REQUEST_REF } from '../type/DefaultScheduleCodes';
import { VisitRequestDetails } from './VisitRequestDetails';

export type VisitRequest = GenericRequest<VisitRequestDetails> &
  VisitConfig & {
    details: VisitRequestDetails; // change details to required inherited from Generic
    sideEffects?: RequestSideEffectConfig;
  };

const toUnitOfTime = (appointmentUnitOfTimeV1: AppointmentUnitOfTimeV1): UnitOfTime => {
  switch (appointmentUnitOfTimeV1) {
    case AppointmentUnitOfTimeV1.D:
      return UnitOfTime.D;
    default:
      throw CspError.badState(`Unsupported unit of time '${appointmentUnitOfTimeV1}'`);
  }
};

const toDuration = (appointmentDurationV1?: AppointmentDurationV1): Maybe<Duration> =>
  appointmentDurationV1
    ? {
        unit: toUnitOfTime(appointmentDurationV1.unit),
        value: appointmentDurationV1.value,
      }
    : undefined;

const toTiming = ({ windowAfter, windowBefore, repeat }: AppointmentTimingV1): Timing => ({
  // Visits only supports some properties of timing
  windowAfter: toDuration(windowAfter),
  windowBefore: toDuration(windowBefore),
  repeat: repeat
    ? {
        period: repeat.period,
        periodUnit: repeat.periodUnit ? toUnitOfTime(repeat.periodUnit) : undefined,
      }
    : undefined,
});

/**
 * When Visit was first implemented "endAction" and "while" didn't exist,
 * but we still wanted screening phase visits to dissapear when entering the randomization phase.
 *
 * To solve this we hard-coded that when we left the phase specified in the startAction->customAction, the
 * request is filtered out. From 3.1 and onwards you should instead configure the request to stop when you want
 * it to (with while criteria).
 *
 * However, we must still be backwards compatible to the old behavior. For requests that have a custom start
 * action configured, set a while criteria to that same status.
 *
 * Example:
 * customAction: SCREENING
 * => whileCriteria: SCREENED.
 */
const toRequestWhile = ({
  startAction,
  endAction,
  while: requestWileV1,
}: AppointmentRequestV1): Maybe<RequestWhile> => {
  const fixedStartAction =
    startAction?.customAction && GenericRequestMapper.customActionDateToFixedAction(startAction.customAction);
  // Also check that we don't have any end action or while criteria already configured for extra safety
  if (fixedStartAction && !endAction && !requestWileV1) {
    // Should only end up here for legacy requests.
    return {
      criteria: [
        {
          operator: RequestWhileOperator.IN,
          type: RequestWhileCriterionType.USER_CUSTOM_STATUS,
          subType: fixedStartAction.subType,
          values: [fixedStartAction.value],
        },
      ],
    };
  } else {
    return GenericRequestMapper.toRequestWhile(requestWileV1);
  }
};

const from = (
  scheduleCode: ScheduleCode,
  versionCode: VersionCode,
  appointmentRequestV1: AppointmentRequestV1,
  strict = false,
): VisitRequest => {
  const { requestCode, details, endAction: endActionV1, startAction, timing: timingV1, meta } = appointmentRequestV1;

  const timing = timingV1 ? toTiming(timingV1) : undefined;
  const endAction: RequestAction = {
    fixedAction: endActionV1?.customAction
      ? GenericRequestMapper.customActionDateToFixedAction(endActionV1.customAction)
      : GenericRequestMapper.toFixedAction(endActionV1?.fixedAction),
    offset: toDuration(endActionV1?.offset),
    fixedTime: endActionV1?.fixedTime,
  };
  const isTimeboundRequest = !!timing;

  const config = VisitRequestMetaMapper.toConfigFromMetaV1(meta, isTimeboundRequest, strict);
  const sideEffectConfig = RequestSideEffectConfigMapper.fromRequestSideEffectMetaV1(
    meta as Maybe<RequestSideEffectMetaV1>,
    strict,
  );

  return {
    requestCode,
    scheduleCode,
    versionCode,
    details: details ? VisitRequestDetails.from(details) : { visitLocationTypes: [] },
    endAction,
    startAction: {
      fixedAction: startAction?.customAction
        ? GenericRequestMapper.customActionDateToFixedAction(startAction.customAction)
        : GenericRequestMapper.toFixedAction(startAction?.fixedAction),
      offset: toDuration(startAction?.offset),
      fixedTime: startAction?.fixedTime,
    },
    while: toRequestWhile(appointmentRequestV1),
    timing,
    notificationConfig: ReminderNotificationMetaMapper.toNotificationConfigFromMetaV1(meta),
    groups: RequestGroupMapper.toRequestGroupMemberships(meta, strict),
    ...config,
    sideEffects: sideEffectConfig,
  };
};

const toDefaultUnscheduledRequest = (supportedVisitLocationTypes: VisitLocationType[]): VisitRequest => ({
  ...DEFAULT_UNSCHEDULED_REQUEST_REF,
  details: {
    visitKind: 'UNSCHEDULED',
    visitLocationTypes: supportedVisitLocationTypes,
  },
  preventBookingAfterWindow: false,
  preventBookingBeforeWindow: false,
  requireConfirmation: false,
  hbs: {
    enabled: false,
    sampleTypes: [],
    analyteTypes: [],
    processedSampleTypes: [],
  },
  variant: VisitVariantType.UNSCHEDULED,
});

/**
 * Used if the request for a booked visit is *completely* removed. This should
 * never happen in real world and is a big configuration error. Should still try
 * to handle it gently.
 *
 * Benefit of doing this is that we can assume all visits have an request in code.
 */
const toUnknownVisitRequest = (requestRef?: RequestRef): VisitRequest => ({
  scheduleCode: requestRef?.scheduleCode ?? '-',
  versionCode: requestRef?.versionCode ?? '-',
  requestCode: requestRef?.requestCode ?? '-',
  details: {
    visitKind: 'UNKNOWN',
    visitLocationTypes: [],
  },
  preventBookingAfterWindow: false,
  preventBookingBeforeWindow: false,
  requireConfirmation: false,
  hbs: {
    enabled: false,
    sampleTypes: [],
    analyteTypes: [],
    processedSampleTypes: [],
  },
  variant: VisitVariantType.UNKNOWN,
});

const unscheduledVariantComparator = (request1: VisitRequest, request2: VisitRequest): boolean =>
  request1.variant === VisitVariantType.UNSCHEDULED && request2.variant === VisitVariantType.UNSCHEDULED;

export const VisitRequest = {
  from,
  toDefaultUnscheduledRequest,
  toUnknownVisitRequest,
  unscheduledVariantComparator,
};
