import { FC, PropsWithChildren } from 'react';
import { Notifications } from '../component/Notifications';
import { useNotificationsContextProvider } from '../hooks/useNotificationsContextProvider';
import { NotificationsContext } from './NotificationsContext';

export const NotificationsContextProvider: FC<PropsWithChildren> = ({ children }) => (
  <NotificationsContext.Provider value={useNotificationsContextProvider()}>
    <Notifications />
    {children}
  </NotificationsContext.Provider>
);
