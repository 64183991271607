import { TemporaryDrawer } from '@csp/csp-web-ui';
import { useBoolean } from '@csp/fe-hooks';
import { FC } from 'react';
import { useCmsWebTechnicalSupport } from '../../cms/hook/unify/useCmsWebTechnicalSupport';
import { useCmsWebTrainingDocuments } from '../../cms/hook/unify/useCmsWebTrainingDocuments';
import { useCmsWebVideoTrainingConsentDocument } from '../../cms/hook/unify/useCmsWebVideoTrainingConsentDocument';
import { useVideoTrainingConsent } from '../../consent/hook/useVideoTrainingConsent';
import { HelpIconButton } from '../../navigation/component/HeaderNavToolsIcons';
import { TechnicalSupportContainer } from './TechnicalSupportContainer';

export const TechnicalSupportDrawerContainer: FC = () => {
  const { approval } = useVideoTrainingConsent();
  const cmsData = useCmsWebTechnicalSupport()?.data;
  const cmsDocumentsData = useCmsWebTrainingDocuments()?.data;
  const cmsVideoData = useCmsWebVideoTrainingConsentDocument(approval?.consentType, approval?.version)?.data;
  const [isOpen, setOpen, setClose] = useBoolean();

  return (
    <>
      <TemporaryDrawer
        handleClose={setClose}
        header={cmsData?.drawerHeader}
        open={isOpen}
        onClose={setClose}
        id="support-drawer"
      >
        <TechnicalSupportContainer
          showTrainingMaterials={!!approval}
          header={cmsData?.header}
          description={cmsData?.description}
          contactInformation={{
            contactLabel: cmsData?.contactInformation.label,
            phoneNumber: cmsData?.contactInformation.phoneNumber,
            email: cmsData?.contactInformation.email,
          }}
          openingHours={{
            availabilityLabel: cmsData?.openingHours.label,
            weekdays: cmsData?.openingHours.weekdays,
            weekend: cmsData?.openingHours.weekend,
          }}
          trainingMaterials={{
            header: cmsData?.trainingMaterials.header,
            description: cmsData?.trainingMaterials.description,
            versionPublishedLabel: cmsData?.trainingMaterials.versionPublishedLabel,
            linkText: cmsData?.trainingMaterials.linkText,
            documents: cmsDocumentsData,
            video: cmsVideoData,
          }}
        />
      </TemporaryDrawer>

      <HelpIconButton onClick={setOpen} data-testid="support-drawer-button" />
    </>
  );
};
