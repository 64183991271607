import { Maybe, ZonedDateTime } from '@csp/csp-common-model';

export type ScheduleWindowInterval = {
  windowStart: ZonedDateTime;
  windowEnd?: ZonedDateTime;
};

const from = (windowStart: ZonedDateTime, windowEnd: Maybe<ZonedDateTime>): ScheduleWindowInterval => ({
  windowStart,
  windowEnd,
});

export const ScheduleWindowInterval = {
  from,
};
