/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum UserDeviceCriteriaFieldV1 {
    DEVICE_MODEL_ID = 'DEVICE_MODEL_ID',
    USER_ID = 'USER_ID',
    SERIAL_NUMBER = 'SERIAL_NUMBER',
    VALID_FROM = 'VALID_FROM',
    VALID_TO = 'VALID_TO',
    USER_DEVICE_ID = 'USER_DEVICE_ID',
    EVENT_ID = 'EVENT_ID',
    DEVICE_MODEL_INFO_MANUFACTURER = 'DEVICE_MODEL_INFO_MANUFACTURER',
    DEVICE_MODEL_INFO_MODEL_NUMBER = 'DEVICE_MODEL_INFO_MODEL_NUMBER',
    DEVICE_MODEL_INFO_MODEL_CODE = 'DEVICE_MODEL_INFO_MODEL_CODE',
    DEVICE_MODEL_INFO_NAME = 'DEVICE_MODEL_INFO_NAME',
}