import { nowToZonedDateTimeCurrentZone, timeDiff } from '@csp/csp-common-date-util';
import { EnumUtil } from '@csp/csp-common-enum-util';
import {
  CustomStatus,
  CustomStatuses,
  CustomStatusValue,
  Maybe,
  PatientPauseStatus,
  PatientPauseStatuses,
  PatientPauseStatusType,
  PatientPauseStatusValueType,
  PauseInterval,
} from '@csp/csp-common-model';

const supportedPauseStatuses = EnumUtil.asStrings(PatientPauseStatusType);

const emptyPauseStatus = (statusType: PatientPauseStatusType): PatientPauseStatus => ({
  type: statusType,
  isPausedNow: false,
  scheduledPause: undefined,
  hasFuturePauseScheduled: false,
  previousIntervals: [],
  daysToFutureScheduledPause: undefined,
});

const toPauseIntervals = (oldestFirstStatusValues: CustomStatusValue[]): PauseInterval[] => {
  const pauseIntervals: PauseInterval[] = [];
  let pauseInterval: Maybe<PauseInterval> = undefined;
  oldestFirstStatusValues.forEach(customStatusValue => {
    if (customStatusValue.value === PatientPauseStatusValueType.PAUSED) {
      pauseInterval = {
        author: customStatusValue.createdUserId,
        pauseReason: customStatusValue.reasonText,
        pauseTimestamp: customStatusValue.timestamp,
        resumeTimestamp: undefined,
      };
      pauseIntervals.push(pauseInterval);
    } else if (pauseInterval && customStatusValue.value === PatientPauseStatusValueType.ONGOING) {
      pauseInterval.resumeTimestamp = customStatusValue.timestamp;
      pauseInterval.author = customStatusValue.createdUserId; // Use last changed by
    }
  });
  return pauseIntervals.reverse();
};

const matchPreviousInterval = (interval: PauseInterval, nowMillis: number): boolean =>
  !!(interval.resumeTimestamp && interval.resumeTimestamp.unixTimeMillis < nowMillis);

const toPauseStatus = (customStatus: CustomStatus): PatientPauseStatus => {
  const isPausedNow = customStatus.getSequentialValueByNow()?.value === PatientPauseStatusValueType.PAUSED;

  const pauseIntervals = toPauseIntervals(customStatus.getStatusValuesInSequenceOldestFirst());

  const now = nowToZonedDateTimeCurrentZone().unixTimeMillis;
  const previousIntervals = pauseIntervals.filter(interval => matchPreviousInterval(interval, now));
  const scheduledIntervals = pauseIntervals.filter(interval => !matchPreviousInterval(interval, now));

  const scheduledPause = scheduledIntervals[0];
  const hasFuturePauseScheduled = Number(scheduledPause?.pauseTimestamp.unixTimeMillis) > now;
  const daysToFutureScheduledPause = hasFuturePauseScheduled
    ? timeDiff(Number(scheduledPause?.pauseTimestamp.unixTimeMillis)).asCeiledDays
    : undefined;

  return {
    type: EnumUtil.fromStringOrError<PatientPauseStatusType>(customStatus.type, PatientPauseStatusType),
    previousIntervals,
    isPausedNow,
    scheduledPause,
    hasFuturePauseScheduled,
    daysToFutureScheduledPause,
  };
};

const toPauseStatuses = (customStatuses: CustomStatuses): PatientPauseStatuses => {
  const statuses: PatientPauseStatus[] = customStatuses.statuses
    .filter(status => supportedPauseStatuses.includes(status.type))
    .map(toPauseStatus);

  const getByType = (statusType: PatientPauseStatusType): PatientPauseStatus =>
    statuses.find(status => status.type === statusType) ?? emptyPauseStatus(statusType);

  const isPausedNowByAnyStatus = (): boolean =>
    EnumUtil.asArray<PatientPauseStatusType>(PatientPauseStatusType).some(
      statusType => getByType(statusType).isPausedNow,
    );

  return {
    statuses,
    getByType,
    isPausedNowByAnyStatus,
  };
};

export const PatientPauseStatusMapper = {
  toPauseStatuses,
};
