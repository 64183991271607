/* eslint-disable */
export const UnifyEQ5D5L_V1Mock = {
  title: 'title',
  version: '1',
  copyright:
    '[M]© EuroQol Research Foundation. EQ-5D™ is a trade mark of the EuroQol Research Foundation. UK (English) v2.1',
  startItem: 'EQ5D_INFO',
  EQ5D_TRAINING: {
    questionnaireItemType: 'TRAINING',
    headerMd: '[M]Questionnaire training',
    subheaderMd: '',
    body: '',
    video: { url: 'https://via.placeholder.com/300' },
    nextId: 'EQ5D_INFO',
  },
  EQ5D_INFO: {
    questionnaireItemType: 'QUESTIONNAIRE_INFO',
    headerMd: '[M]Health questionnaire',
    subheaderMd: '[M]Health questionnaire',
    nextId: 'EQ5D0201_INSTRUCTION',
  },
  EQ5D0201_INSTRUCTION: {
    questionnaireItemType: 'INSTRUCTION',
    body: '[M]Please select the one box that describes your health TODAY',
    previousId: 'EQ5D_INFO',
    nextId: 'EQ5D0201',
  },
  EQ5D0201: {
    questionnaireItemType: 'QUESTION',
    questionType: 'RADIO_BUTTON',
    headerMd: '[M]MOBILITY',
    optional: false,
    answers: {
      '1': {
        text: '[M]I have no problems in walking about',
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'EQ5D0201_INSTRUCTION',
        nextId: 'EQ5D0202',
      },
      '2': {
        text: '[M]I have slight problems in walking about',
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'EQ5D0201_INSTRUCTION',
        nextId: 'EQ5D0202',
      },
      '3': {
        text: '[M]I have moderate problems in walking about',
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'EQ5D0201_INSTRUCTION',
        nextId: 'EQ5D0202',
      },
      '4': {
        text: '[M]I have severe problems in walking about',
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'EQ5D0201_INSTRUCTION',
        nextId: 'EQ5D0202',
      },
      '5': {
        text: '[M]I am unable to walk about',
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'EQ5D0201_INSTRUCTION',
        nextId: 'EQ5D0202',
      },
    },
  },
  EQ5D0202: {
    questionnaireItemType: 'QUESTION',
    questionType: 'RADIO_BUTTON',
    headerMd: '[M]SELF CARE',
    optional: false,
    answers: {
      '1': {
        text: '[M]I have no problems in walking about',
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'EQ5D0201',
        nextId: 'EQ5D0203',
      },
      '2': {
        text: '[M]I have slight problems in walking about',
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'EQ5D0201',
        nextId: 'EQ5D0203',
      },
      '3': {
        text: '[M]I have moderate problems in walking about',
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'EQ5D0201',
        nextId: 'EQ5D0203',
      },
      '4': {
        text: '[M]I have severe problems in walking about',
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'EQ5D0201',
        nextId: 'EQ5D0203',
      },
      '5': {
        text: '[M]I am unable to walk about',
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'EQ5D0201',
        nextId: 'EQ5D0203',
      },
    },
  },
  EQ5D0203: {
    questionnaireItemType: 'QUESTION',
    questionType: 'RADIO_BUTTON',
    headerMd: '[M]USUAL ACTIVITIES',
    subHeader: '[M](e.g. work, study, housework, family or leisure activities)',
    optional: false,
    answers: {
      '1': {
        text: '[M]I have no problems in walking about',
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'EQ5D0202',
        nextId: 'EQ5D0204',
      },
      '2': {
        text: '[M]I have slight problems in walking about',
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'EQ5D0202',
        nextId: 'EQ5D0204',
      },
      '3': {
        text: '[M]I have moderate problems in walking about',
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'EQ5D0202',
        nextId: 'EQ5D0204',
      },
      '4': {
        text: '[M]I have severe problems in walking about',
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'EQ5D0202',
        nextId: 'EQ5D0204',
      },
      '5': {
        text: '[M]I am unable to walk about',
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'EQ5D0202',
        nextId: 'EQ5D0204',
      },
    },
  },
  EQ5D0204: {
    questionnaireItemType: 'QUESTION',
    questionType: 'RADIO_BUTTON',
    headerMd: '[M]PAIN / DISCOMFORT',
    optional: false,
    answers: {
      '1': {
        text: '[M]I have no problems in walking about',
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'EQ5D0203',
        nextId: 'EQ5D0205',
      },
      '2': {
        text: '[M]I have slight problems in walking about',
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'EQ5D0203',
        nextId: 'EQ5D0205',
      },
      '3': {
        text: '[M]I have moderate problems in walking about',
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'EQ5D0203',
        nextId: 'EQ5D0205',
      },
      '4': {
        text: '[M]I have severe problems in walking about',
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'EQ5D0203',
        nextId: 'EQ5D0205',
      },
      '5': {
        text: '[M]I am unable to walk about',
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'EQ5D0203',
        nextId: 'EQ5D0205',
      },
    },
  },
  EQ5D0205: {
    questionnaireItemType: 'QUESTION',
    questionType: 'RADIO_BUTTON',
    headerMd: '[M]ANXIETY / DEPRESSION',
    optional: false,
    answers: {
      '1': {
        text: '[M]I have no problems in walking about',
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'EQ5D0204',
        nextId: 'EQ5D0206_INSTRUCTION',
      },
      '2': {
        text: '[M]I have slight problems in walking about',
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'EQ5D0204',
        nextId: 'EQ5D0206_INSTRUCTION',
      },
      '3': {
        text: '[M]I have moderate problems in walking about',
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'EQ5D0204',
        nextId: 'EQ5D0206_INSTRUCTION',
      },
      '4': {
        text: '[M]I have severe problems in walking about',
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'EQ5D0204',
        nextId: 'EQ5D0206_INSTRUCTION',
      },
      '5': {
        text: '[M]I am unable to walk about',
        type: 'TEXT_WITH_NAVIGATION',
        freeTextAnswerSupport: false,
        previousId: 'EQ5D0204',
        nextId: 'EQ5D0206_INSTRUCTION',
      },
    },
  },
  EQ5D0206_INSTRUCTION: {
    questionnaireItemType: 'INSTRUCTION',
    headerMd: '[M]On the following screen we would like to know how good or bad your health is today',
    previousId: 'EQ5D0205',
    nextId: 'EQ5D0206',
    sections: {
      EQ5D0206_SECTION01: { body: '[M]You will see a scale numbered from 0 to 100' },
      EQ5D0206_SECTION02: { body: '[M]100 means the best health you can imagine', underlinedWords: '[M]best' },
      EQ5D0206_SECTION03: { body: '[M]0 means the worst health you can imagine', underlinedWords: '[M]worst' },
    },
  },
  EQ5D0206: {
    questionnaireItemType: 'QUESTION',
    questionType: 'SCALE_SLIDER',
    headerMd: '[M]HEALTH SCALE',
    subHeader: '[M]Please indicate on the scale how your health is TODAY',
    previousId: 'EQ5D0206_INSTRUCTION',
    nextId: 'EQ5D_completion',
    optional: false,
    scale: {
      type: 'SCALE',
      minValue: 0,
      maxValue: 100,
      step: 1,
      minValueLabel: '[M]Minimum value label',
      maxValueLabel: '[M]Maximum value label',
      currentValueLabel: '[M]YOUR HEALTH TODAY',
    },
  },
  EQ5D_completion: {
    questionnaireItemType: 'COMPLETION',
    headerMd: '[M]You have now completed the questionnaire',
    body: '[M]If you would like to change any of your answers, you may do so by pressing the "Previous" button prior to finalizing \nthe questionnaire.\n\nPlease finalize the questionnaire by pressing the ”Finalize" button. \nOnce you press ”Finalize", you will not be able to go back to review or change your answers.',
    previousId: 'EQ5D0206',
  },
};
