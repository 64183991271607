export const CustomActionMetaKeys = { DATE: 'DATE', TIME: 'TIME', RESOLUTION: 'RESOLUTION' } as const;
export type CustomActionMetaKey = typeof CustomActionMetaKeys[keyof typeof CustomActionMetaKeys];

export const CustomActionKeyAcceptedValues = {
  DATE: { RANDOMIZATION: 'RANDOMIZATION', SCREENING: 'SCREENING', ACTIVATION: 'ACTIVATION' } as const,
  RESOLUTION: { DATETIME: 'DATETIME', DATE: 'DATE' } as const,
};
export type CustomActionDateKeyAcceptedValue =
  typeof CustomActionKeyAcceptedValues.DATE[keyof typeof CustomActionKeyAcceptedValues.DATE];

export type CustomActionResolutionKeyAcceptedValue =
  typeof CustomActionKeyAcceptedValues.RESOLUTION[keyof typeof CustomActionKeyAcceptedValues.RESOLUTION];

export type CustomActionTimeKeyAcceptedValue = string | undefined;
