import { useContext } from 'react';
import { NotificationsContext } from '../context/NotificationsContext';
import { NotificationsContextState } from '../model/NotificationsContextState';

export const useNotificationsContext = (): NotificationsContextState => {
  const ctx = useContext(NotificationsContext);
  if (ctx) {
    return ctx;
  } else {
    throw new Error('Must be child of NotificationsContext');
  }
};
