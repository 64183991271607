import { DataChangeType } from './DataChangeType';

export enum DataChangeReason {
  ENTRY_ERROR = 'ENTRY_ERROR',
  NEW_INFO = 'NEW_INFO',
  CHANGED_INFO = 'CHANGED_INFO',
  PATIENT_REQUESTED_CHANGE = 'PATIENT_REQUESTED_CHANGE',
  OTHER = 'OTHER',
}

const typeDataChangeReasons = {
  [DataChangeType.MODIFY_SINGLE_ANSWER]: [
    DataChangeReason.ENTRY_ERROR,
    DataChangeReason.NEW_INFO,
    DataChangeReason.CHANGED_INFO,
    DataChangeReason.OTHER,
  ],
  [DataChangeType.INVALIDATE_RESPONSE]: [
    DataChangeReason.ENTRY_ERROR,
    DataChangeReason.NEW_INFO,
    DataChangeReason.CHANGED_INFO,
    DataChangeReason.PATIENT_REQUESTED_CHANGE,
    DataChangeReason.OTHER,
  ],
  [DataChangeType.CHANGE_ASSOCIATED_VISIT]: [
    DataChangeReason.ENTRY_ERROR,
    DataChangeReason.NEW_INFO,
    DataChangeReason.CHANGED_INFO,
    DataChangeReason.PATIENT_REQUESTED_CHANGE,
    DataChangeReason.OTHER,
  ],
  [DataChangeType.MODIFY_LOCAL_TIMESTAMP]: [
    DataChangeReason.ENTRY_ERROR,
    DataChangeReason.NEW_INFO,
    DataChangeReason.CHANGED_INFO,
    DataChangeReason.PATIENT_REQUESTED_CHANGE,
    DataChangeReason.OTHER,
  ],
  [DataChangeType.RESTORE_INVALIDATED_RESPONSE]: [
    DataChangeReason.ENTRY_ERROR,
    DataChangeReason.NEW_INFO,
    DataChangeReason.CHANGED_INFO,
    DataChangeReason.PATIENT_REQUESTED_CHANGE,
    DataChangeReason.OTHER,
  ],
};

export const getDataChangeReasonsFromType = (type: DataChangeType): DataChangeReason[] => typeDataChangeReasons[type];
