import { useLocation, matchPath } from 'react-router-dom';
import { useMemo } from 'react';
import { NavTab } from '@csp/web-common';
import { CspPath } from '../../../CspPath';

type MatchConfig = {
  /**
   * Should match the path exactly, defaults to false.
   */
  exact?: boolean;
};

/**
 * Returns the current CspPath based on the current location in React Router.
 *
 * Based on the input `navTabs`, this hook will return the path of the nav tab that matches the current location.
 * If no match is found, the fallback path is returned.
 * If the config 'exact' is set to false, it will return the first match, which might not be the most specific match.
 * It is therefore recommended to either toggle the 'exact'
 * flag to true or to order the path of nav tabs from most specific to least specific.
 *
 * @param navTabs - Set of nav tabs to match against the current location.
 * @param fallbackNavTab - CspPath to return if no match is found.
 * @param matchConfig - Configuration for matching.
 *
 * @returns The matching CspPath, or the fallback CspPath if no match is found.
 *
 */
export const useSelectedNavTab = (
  navTabs: NavTab<CspPath>[],
  fallbackNavTab: CspPath,
  matchConfig: MatchConfig = { exact: false },
): CspPath => {
  const { pathname } = useLocation();

  return useMemo(
    () =>
      navTabs.find(
        (navTab: NavTab<CspPath>): boolean =>
          !!matchPath({ path: navTab?.path ?? '', end: matchConfig.exact }, pathname),
      )?.path ?? fallbackNavTab,
    [fallbackNavTab, pathname, navTabs, matchConfig],
  );
};
