import { Maybe } from '@csp/csp-common-model';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { refreshStudyThunk } from '../../../feature/security/ducks/principalContextActions';
import { getStudySelector, getUserSelector } from '../../../feature/security/ducks/principalContextSelectors';
import { clearSelectedStudyId } from '../../../feature/studies/ducks/studiesActions';
import { getStudyConfigStateSelector } from '../../../feature/study-config/ducks/studyConfigSelectors';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { StudyContextState } from './StudyContextState';

export const useStudyContextProvider = (): Maybe<StudyContextState> => {
  const studyConfig = useSelector(getStudyConfigStateSelector).config;
  const study = useSelector(getStudySelector);
  const user = useSelector(getUserSelector);
  const dispatch = useAppDispatch();

  const reloadStudy = useCallback(async (): Promise<void> => {
    if (study && user) {
      dispatch(refreshStudyThunk({ study, user }));
    }
  }, [dispatch, study, user]);

  const clearSelectedStudy = useCallback(() => dispatch(clearSelectedStudyId()), [dispatch]);

  const isLoading = !studyConfig || !study;

  return isLoading
    ? undefined
    : {
        study,
        reloadStudy,
        clearSelectedStudy,
        studyConfig,
      };
};
