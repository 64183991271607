import { fromTimezoneStr } from '@csp/csp-common-date-util';
import { UserId, ZonedDateTime } from '@csp/csp-common-model';
import { CustomStatusV1 } from '@csp/dmdp-api-assessment-dto';

export type AssessmentCustomStatus = {
  createdAt: ZonedDateTime;
  createdBy: UserId;
  localTimestamp?: string;
  type: string;
  value: string;
};

const from = ({ createdBy, createdAt, localTimestamp, type, value }: CustomStatusV1): AssessmentCustomStatus => ({
  createdAt: fromTimezoneStr(createdAt),
  createdBy,
  localTimestamp,
  type,
  value,
});

export const AssessmentCustomStatus = {
  from,
};
