import { ConsentOutcomeAndArtifact, ConsentService } from '@csp/common';
import { ConsentType } from '@csp/dmdp-api-user-dto';
import { isNotEmpty } from '@csp/csp-common-util';
import { map, sortBy } from 'lodash';
import { ConsentApproval } from '../model/ConsentApproval';

export const toConsentApproval = ({ outcome, artifact }: ConsentOutcomeAndArtifact): ConsentApproval => {
  const document = artifact.documents[0];

  if (!document) {
    throw new Error(`Found no documents in artifact ${artifact.artifactCode}`);
  }

  const approveConsent = async (): Promise<void> => {
    await ConsentService.approveConsentV1(artifact.artifactCode, document.reference);
  };

  return {
    artifact,
    consentType: artifact.type as ConsentType,
    hasApproval: isNotEmpty(outcome.approvalIds),
    approveConsent,
    version: document.version ?? '1.0',
  };
};

export const toConsentApprovals = (outcomesAndArtifacts: ConsentOutcomeAndArtifact[]): ConsentApproval[] => {
  const approvals = map(outcomesAndArtifacts, toConsentApproval);
  return sortBy<ConsentApproval>(approvals, c => c.consentType);
};
