/* eslint-disable @typescript-eslint/no-explicit-any */
import { memoizedToDebugContent } from '@csp/common';
import { ContentDeliveryService, ContentstackConfigService } from '@csp/csp-common-content-delivery-client';
import { CspError } from '@csp/csp-common-model';
import {
  LearnMoreContentModel,
  LearnMoreContentModelMock,
  LearnMoreIdentifier,
  StudyWebContentPackage,
  SystemLinkContentModel,
} from '@csp/csp-web-content-definitions';
import { isDebugCmsModeActive } from '../util/debugCmsUtil';

let studyPackage: StudyWebContentPackage | undefined = undefined;

const init = async (): Promise<void> => {
  const isDebugEnabled = isDebugCmsModeActive();
  const unifyStudyPackage = await ContentDeliveryService.fetchContentPackage('study');
  studyPackage = unifyStudyPackage as unknown as StudyWebContentPackage;
  if (isDebugEnabled) {
    studyPackage = memoizedToDebugContent<any>(studyPackage, '', '[CS]');
  }
};

const get = (): StudyWebContentPackage => {
  if (!studyPackage) {
    throw CspError.badState('Must call StudyContentService.init() before trying to access study content');
  }
  return studyPackage;
};

const getLearnMore = (identifier: LearnMoreIdentifier): LearnMoreContentModel => {
  const learnMore = get().learn_more.find(learnMore => learnMore.identifier === identifier);
  if (learnMore === undefined) {
    const errorMessage = `Learn more content entry with identifier ${identifier} does not exist in the study package`;
    if (ContentstackConfigService.get().allowMockedContent) {
      console.debug(`${errorMessage}. Using mock instead`);
      return LearnMoreContentModelMock.create();
    } else {
      throw CspError.badState(errorMessage);
    }
  }
  return learnMore;
};

const getSystemLinks = (): SystemLinkContentModel[] => get().system_link;

export const StudyContentService = {
  init,
  get,
  getLearnMore,
  getSystemLinks,
};
