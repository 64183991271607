import { Country, StateAssert } from '@csp/csp-common-model';
import { CountryUtil } from '@csp/csp-common-util';
import { OrgV1 } from '@csp/dmdp-api-org-dto';
import { OrgMapper } from './OrgMapper';

const fromOrgV1 = (orgV1: OrgV1): Country => {
  StateAssert.notNull(orgV1.country, 'Expected country to be set');

  const countryCode = orgV1.country.countryCode;
  const countryName = CountryUtil.getCountryByCode(orgV1.country.countryCode).name;
  const orgId = orgV1.orgId;
  const langCodes = orgV1.country.langCodes;

  if (countryCode === '') {
    console.debug(`Country ${countryName} (OrgId:${orgId}) has no CountryCode`);
  }

  return {
    ...OrgMapper.fromOrgV1(orgV1),
    countryCode,
    langCodes,
    countryName,
  };
};

export const CountryMapper = {
  fromOrgV1,
};
