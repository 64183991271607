/* eslint-disable */
export const UnifyWebStudyPatientMedicationMock = {
  drawer: {
    title: { learnMore: '[M]Learn more' },
    learnMorePrescribedMedication: { title: { availablePrescriptions: '[M]Available prescriptions' } },
    learnMoreInfoShared: {
      alert: {
        disclaimer: {
          title: '[M]Disclaimer',
          message:
            '[M]The patient app doesn’t replace direct communication with the patient. Never rely solely on the patient app to communicate any medication related issue with the patient.',
        },
      },
      title: { mainContentTitle: '[M]Information shared with the patient app' },
      texts: [
        {
          header: 'Prerequesites',
          bodyMd:
            'Only patients who use the patient app after having accepted the invitation to the study (Unify App user status “Yes”) are receiving medication related information through the patient app. ',
        },
        {
          header: 'What is shown to the patient?',
          bodyMd:
            'A medication card is shown to the patient on their home screen inside the patient app. This card contains medication name, timing, number of dosages and dosing instructions as specified by the physician in the Unify web portal.',
          note: {
            title: 'Note',
            message:
              'Patients have the ability to turn off reminders through the operating system on their phone. Never rely on reminders to replace direct patient communication and personal check-ins.',
          },
          medicationCard: {
            cardLabel: 'Medication',
            nameOfMedicine: 'Iressa',
            medicationInstruction: 'Take once daily. Swallow with water.',
            dosageLabel: 'Dosage',
            dosageText: '1 pill',
            timeLabel: 'Time',
            timeText: '10:00',
          },
        },
        {
          header: 'Patient Reminders',
          bodyMd:
            'While medication is “ongoing”, reminders are sent to the patient to help ensure compliance. Refer to “Pausing of medication” to learn more.',
        },
        {
          header: 'Pausing of medication',
          bodyMd:
            'If you are using Unify as a physician, you may pause medication manually, which will be reflected on the medication card in the patient app. Pausing medication also pauses patient reminders.',
        },
        {
          header: 'Push notifications',
          bodyMd:
            'Any change to the medication such as start and stop of medication, the pausing of medication, triggers a push notification informing the patient about the change. Regardless of a notification being sent, make sure to have spoken to the patient before making changes to medication.',
          note: {
            title: 'Note',
            message:
              'Patients have the ability to turn off push notifications through the operating system on their phone. Never rely on notifications alone to communicate medication changes to your patients.',
          },
        },
      ],
    },
  },
};
