import { svgs } from '@csp-misc/web-ui-icons';
import styled from '@emotion/styled';
import { Theme, useTheme } from '@mui/material/styles';
import { compose, display, DisplayProps, sizing, SizingProps, spacing, SpacingProps } from '@mui/system';
import { forwardRef } from 'react';
import { RealNumberUnit } from '../../common/model/RealNumberUnit';
import { Box } from '../layout/box/Box';
import { BrandedIconColor } from './model/BrandedIconColor';
import { BrandedIconProps } from './model/BrandedIconProps';
import { BrandedIconSize } from './model/BrandedIconSize';

const sizeMap: Record<BrandedIconSize, RealNumberUnit> = {
  small: '16px',
  medium: '20px',
  large: '24px',
  xl: '32px',
  xxl: '44px',
  xxxl: '94px',
  100: '100%',
};

const colorsMap = ({ palette }: Theme, color: string): string => {
  const ColorsMap: Record<BrandedIconColor, string> = {
    black: palette.common.black,
    darkBlue: palette.graph.blue.main,
    darkGrey: palette.grey[700],
    default: palette.icon.main,
    disabled: palette.icon.disabled,
    error: palette.error.main,
    grey: palette.grey[400],
    info: palette.info.main,
    lightBlue: palette.graph.blue.light,
    orange: palette.graph.orange.main,
    primary: palette.primary.main,
    purple: palette.secondary.main,
    secondary: palette.secondary.main,
    success: palette.success.main,
    teal: palette.graph.teal.main,
    textSecondary: palette.text.secondary,
    warning: palette.warning.main,
    white: palette.common.white,
    yellow: palette.graph.yellow.main,
  };
  return ColorsMap[color as BrandedIconColor];
};

type Sized = {
  size?: BrandedIconSize;
};

type Props = SpacingProps & SizingProps & DisplayProps & Sized;

const BrandedIcon = forwardRef<HTMLSpanElement, BrandedIconProps>(function BrandedIcon(
  { name, color = 'default', size = 'medium', style, ...rest },
  ref,
) {
  const theme = useTheme();
  const SelectedIcon = svgs[name];
  if (!SelectedIcon) {
    return null;
  }
  return (
    <Box component="span" display="inline-flex" ref={ref} {...rest}>
      <SelectedIcon
        style={style}
        fill={colorsMap(theme, color) || color}
        width={sizeMap[size]}
        height={sizeMap[size]}
      />
    </Box>
  );
});

const WithMuiSystemBrandedIcon = styled(BrandedIcon)<Props>`
  ${compose(spacing, sizing, display)}
  ${({ size = 'medium' }: Sized): string => `
    min-width: ${sizeMap[size]};
  `}
`;

export { WithMuiSystemBrandedIcon as BrandedIcon };
