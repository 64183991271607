import { LocationAddressV1 } from '@csp/dmdp-api-appointment-dto';

const from = (address: LocationAddressV1): Address => ({
  city: address.city,
  country: address.country,
  district: address.district,
  lines: address.lines,
  postalCode: address.postalCode,
  state: address.state,
});

export type Address = {
  city?: string;
  country?: string;
  district?: string;
  lines?: string[];
  postalCode?: string;
  state?: string;
};

export const Address = {
  from,
};
