import { PreconditionsV1, PreconditionV1 } from '@csp/config-schemas';
import { EnumUtil } from '@csp/csp-common-enum-util';
import {
  CspError,
  CustomStatusValueLookup,
  Maybe,
  StateAssert,
  StatusCondition,
  StatusConditionOperatorType,
  StatusConditions,
  StatusConditionsOperatorType,
} from '@csp/csp-common-model';

const toCondition = (v1: PreconditionV1): StatusCondition => {
  const { type, values } = v1;
  const operator = EnumUtil.fromStringOrError<StatusConditionOperatorType>(v1.operator, StatusConditionOperatorType);

  StateAssert.isTrue(!!type, 'Status type must be set in condition');
  StateAssert.isTrue(!!values?.length, `At least one value must be supplied to the condition. Type: ${type}`);

  const isConditionFulfilled = (customStatuses: CustomStatusValueLookup): boolean => {
    const currentValue = customStatuses.getValueByType(type);
    const isValueMatch = currentValue ? values.includes(currentValue) : false;
    switch (operator) {
      case StatusConditionOperatorType.IN:
        return isValueMatch;
      case StatusConditionOperatorType.NOT_IN:
        return !isValueMatch;
      default:
        return false;
    }
  };

  return {
    type,
    operator,
    values,
    isConditionFulfilled,
  };
};

const isConditionsFulfilled = (
  currentStatuses: CustomStatusValueLookup,
  conditions: Maybe<StatusConditions[]>,
  condition: Maybe<StatusCondition>,
  operator: Maybe<StatusConditionsOperatorType>,
): boolean => {
  if (condition) {
    return condition.isConditionFulfilled(currentStatuses);
  } else if (conditions && operator) {
    if (operator === StatusConditionsOperatorType.AND) {
      return conditions.map(cond => cond.isConditionsFulfilled(currentStatuses)).filter(isMeet => !isMeet).length === 0;
    } else if (operator === StatusConditionsOperatorType.OR) {
      return conditions.some(cond => cond.isConditionsFulfilled(currentStatuses));
    } else {
      throw CspError.badState(`Unsupported operator: ${operator}`);
    }
  } else {
    return false;
  }
};

const fromV1 = (v1: PreconditionsV1): StatusConditions => {
  const condition = v1.condition ? toCondition(v1.condition) : undefined;

  const operator = v1.operator
    ? EnumUtil.fromStringOrError<StatusConditionsOperatorType>(v1.operator, StatusConditionsOperatorType)
    : undefined;
  const conditions = v1.conditions?.length ? v1.conditions.map(nextV1 => fromV1(nextV1)) : undefined;

  StateAssert.isFalse(
    !!condition && !!operator && !!conditions,
    `Single condition cannot be set together with multi conditions. Either set single or multiple. Condition type: ${condition?.type}`,
  );
  StateAssert.isFalse(
    !condition && !operator && !conditions,
    'No conditions are set. Either set single or multiple must be set',
  );
  StateAssert.isFalse(
    !operator && !!conditions,
    'Multiple conditions must always be set in combination of an operator, eg AND, OR',
  );
  StateAssert.isFalse(
    !!operator && !conditions?.length,
    'Conditions must always be set and contain at least one condition when operator is defined',
  );

  return {
    condition,
    operator,
    conditions,
    isConditionsFulfilled: (currentStatuses: CustomStatusValueLookup) =>
      isConditionsFulfilled(currentStatuses, conditions, condition, operator),
  };
};

export const StatusConditionsMapper = {
  fromV1,
};
