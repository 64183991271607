import { nowToZonedDateTimeCurrentZone } from '@csp/csp-common-date-util';
import { CspError, CustomStatusIn, Maybe } from '@csp/csp-common-model';
import { isDefined } from '@csp/csp-common-util';
import { PatientStatusUpdatePayloadV1 } from '../meta/payload/PatientStatusUpdatePayloadV1';
import { RequestSideEffectConfigV1 } from '../meta/RequestSideEffectConfigV1';
import { RequestSideEffectMetaV1, SIDE_EFFECT_CONFIG_V1_META_NAME } from '../meta/RequestSideEffectMetaV1';
import { RequestSideEffectTypeV1 } from '../meta/RequestSideEffectTypeV1';
import { RequestSideEffectV1 } from '../meta/RequestSideEffectV1';
import { RequestSideEffect } from '../model/RequestSideEffect';
import { RequestSideEffectConfig } from '../model/RequestSideEffectConfig';
import { RequestSideEffectType } from '../model/RequestSideEffectType';
import { SideEffectOptions } from '../model/SideEffectOptions';

const throwBadStateIfStrict = (msg: string, strict: boolean): undefined => {
  if (strict) {
    throw CspError.badState(msg);
  } else {
    return undefined;
  }
};

const toPatientStatusUpdatePayload = (sideEffectV1: RequestSideEffectV1, strict: boolean): Maybe<RequestSideEffect> => {
  const unknownPayload = sideEffectV1.payload;
  if (PatientStatusUpdatePayloadV1.isPatientStatusUpdatePayload(unknownPayload)) {
    return {
      type: RequestSideEffectType.PATIENT_STATUS_UPDATE,
      payload: unknownPayload,
    };
  } else {
    return throwBadStateIfStrict(
      `invalid request side effect payload:\n ${JSON.stringify(sideEffectV1, null, 2)}`,
      strict,
    );
  }
};

const toRequestSideEffect = (sideEffectV1: RequestSideEffectV1, strict: boolean): Maybe<RequestSideEffect> => {
  switch (sideEffectV1.type) {
    case RequestSideEffectTypeV1.PATIENT_STATUS_UPDATE_V1:
      return toPatientStatusUpdatePayload(sideEffectV1, strict);
    default:
      return throwBadStateIfStrict(`invalid request side effect ${sideEffectV1.type}`, strict);
  }
};

const getUpdatedStatuses = (
  sideEffects: RequestSideEffect[],
  sideEffectOptions: Maybe<SideEffectOptions>,
): Array<CustomStatusIn[]> => {
  const patientStatusUpdates = sideEffects.filter(
    sideEffect => sideEffect.type === RequestSideEffectType.PATIENT_STATUS_UPDATE,
  );

  return patientStatusUpdates.map(patientStatusUpdate =>
    patientStatusUpdate.payload.updates.map(statusUpdate => ({
      type: statusUpdate.statusType,
      value: statusUpdate.statusValue,
      timestamp: sideEffectOptions?.completedTimestamp ?? nowToZonedDateTimeCurrentZone(),
    })),
  );
};

const fromRequestSideEffectConfigV1 = (
  requestSideEffectConfigV1: RequestSideEffectConfigV1,
  strict: boolean,
): RequestSideEffectConfig => {
  const onTaskComplete =
    requestSideEffectConfigV1.onTaskComplete?.map(hook => toRequestSideEffect(hook, strict)).filter(isDefined) ?? [];

  return {
    onTaskComplete,
    getUpdatedStatuses: (sideEffectOptions: Maybe<SideEffectOptions>) =>
      getUpdatedStatuses(onTaskComplete, sideEffectOptions),
  };
};

const fromRequestSideEffectMetaV1 = (
  meta: Maybe<RequestSideEffectMetaV1>,
  strict = false,
): Maybe<RequestSideEffectConfig> => {
  const requestSideEffectConfigV1 = meta?.[SIDE_EFFECT_CONFIG_V1_META_NAME];

  if (requestSideEffectConfigV1) {
    return fromRequestSideEffectConfigV1(requestSideEffectConfigV1, strict);
  } else {
    return undefined;
  }
};

const emptyConfig = (): RequestSideEffectConfig =>
  fromRequestSideEffectConfigV1(
    {
      onTaskComplete: [],
    },
    false,
  );

export const RequestSideEffectConfigMapper = {
  fromRequestSideEffectMetaV1,
  fromRequestSideEffectConfigV1,
  emptyConfig,
};
