import { merge } from 'lodash';
import { ContentPackageType } from '../../model/ContentPackageType';
import { ContentstackConfigService } from '../../config/service/ContentstackConfigService';
import { UnifyContentPackage } from '../../types/UnifyContentPackage';

export const applyMockedContent = (
  packageType: ContentPackageType,
  contentPackage: UnifyContentPackage = {},
): UnifyContentPackage => {
  const allowMockedContent = ContentstackConfigService.get().allowMockedContent;
  const mockedContent = ContentstackConfigService.get().mockContent;
  if (allowMockedContent && mockedContent) {
    console.debug(
      `[ContentDeliveryService]: Serving (potentially partially) mocked content for package '${packageType}'.`,
    );
    return merge(mockedContent[packageType], contentPackage);
  } else {
    return contentPackage;
  }
};
