import { Auth } from '@aws-amplify/auth';
import { StateAssert } from '@csp/csp-common-model';
import { IdpStateService } from '@csp/csp-fe-auth';
import { IdpType } from '@csp/dmdp-api-user-dto';
import { CognitoUser } from 'amazon-cognito-identity-js';
import 'crypto-js/lib-typedarrays';
import { addMinutes } from 'date-fns';
import { UsernamePassword } from '../../model/UsernamePassword';
import { CognitoConfig } from '../model/CognitoConfig';
import { CognitoSession } from '../model/CognitoSession';
import { CognitoUserExt } from '../model/CognitoUserExt';
import { handleCognitoError } from '../util/CognitoExceptionUtil';

type State = {
  logoutCallback: () => Promise<void>;
  temporaryPasswordExpiresInDays: number;
  hcpUserInviteExpiresInDays: number;
};

const state: State = {
  logoutCallback: async (): Promise<void> => Promise.resolve(),
  temporaryPasswordExpiresInDays: 7,
  hcpUserInviteExpiresInDays: 90,
};

const signOut = async (global = false): Promise<void> => {
  await Auth.signOut({
    global,
  });
};

const refreshToken = async (compareTimeInMinutes: number): Promise<string> => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const cognitoSession = await Auth.currentSession();
    const expirationTime = new Date(cognitoSession.getAccessToken().getExpiration() * 1000);

    if (expirationTime < addMinutes(Date.now(), compareTimeInMinutes)) {
      return cognitoUser.refreshSession(cognitoSession.getRefreshToken(), (err: unknown, session: unknown) => {
        if (err) {
          throw new Error('Could not refresh the access token');
        } else {
          return session;
        }
      });
    }

    return cognitoSession.getIdToken().getJwtToken();
  } catch (e) {
    return Promise.reject(handleCognitoError(e));
  }
};

const getIdpToken = async (): Promise<string> => {
  try {
    const cognitoSession = await Auth.currentSession();
    return cognitoSession.getIdToken().getJwtToken();
  } catch (e) {
    return Promise.reject(handleCognitoError(e));
  }
};

const getAuthorization = async (): Promise<string> => `BEARER ${await getIdpToken()}`;

const setCognitoAsIdp = (): void => {
  IdpStateService.setIdpState({
    idp: IdpType.COGNITO,
    getAuthorization,
    signOut: state.logoutCallback,
  });
};

const forgotPasswordRequest = async (username: string): Promise<void> => {
  try {
    await Auth.forgotPassword(username.toLowerCase());
  } catch (e) {
    return Promise.reject(handleCognitoError(e));
  }
};

const forgotPasswordConfirmRequest = async (credentials: UsernamePassword, code: string): Promise<void> => {
  try {
    await Auth.forgotPasswordSubmit(credentials.username.toLowerCase(), code, credentials.password);
  } catch (e) {
    return Promise.reject(handleCognitoError(e));
  }
};

const login = async (credentials: UsernamePassword): Promise<CognitoSession> => {
  try {
    const user: CognitoUserExt = await Auth.signIn(credentials.username.toLowerCase(), credentials.password);

    let signedIn = false;
    if (!user.challengeName) {
      signedIn = true;
      setCognitoAsIdp();
    }

    return {
      signedIn,
      user,
    };
  } catch (e) {
    return Promise.reject(handleCognitoError(e));
  }
};

const azLogin = async (customState?: string): Promise<void> => {
  // Workaround - Amplify has not custom provider defined in enum
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  await Auth.federatedSignIn({
    provider: 'az-pingid',
    ...(customState ? { customState } : {}),
  });
};

const exostarLogin = async (customState?: string): Promise<void> => {
  // Workaround - Amplify has not custom provider defined in enum
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  await Auth.federatedSignIn({
    provider: 'az-pingid-exostar',
    ...(customState ? { customState } : {}),
  });
};

const completeNewPasswordAndLogin = async (user: CognitoUser, newPassword: string): Promise<CognitoSession> => {
  try {
    await Auth.completeNewPassword(user, newPassword, {});
    return login({
      username: user.getUsername(),
      password: newPassword,
    });
  } catch (e) {
    return Promise.reject(handleCognitoError(e));
  }
};

const loadIdpTokenIfAvailable = async (): Promise<void> => {
  try {
    if (await getIdpToken()) {
      setCognitoAsIdp();
    }
  } catch (e) {
    // Ignore
  }
};

const getTemporaryPasswordExpiresInDays = (): number => state.temporaryPasswordExpiresInDays;

const getHcpUserInviteExpiresInDays = (): number => state.hcpUserInviteExpiresInDays;

const init = async ({ options, signOut }: CognitoConfig): Promise<void> => {
  await setConfiguration({ options, signOut });
  await loadIdpTokenIfAvailable();
};

const setConfiguration = async ({ options, signOut }: CognitoConfig): Promise<void> => {
  StateAssert.notNull(options, 'Aws Cognito configuration is missing. Please check the setup');
  const { temporaryPasswordExpiresInDays, hcpUserInviteExpiresInDays, ...rest } = options;
  await Auth.configure(rest);
  state.logoutCallback = async (): Promise<void> => {
    if (options.storage) {
      options.storage?.clear();
    }
    if (signOut) {
      await signOut();
    }
  };

  state.temporaryPasswordExpiresInDays = temporaryPasswordExpiresInDays || 7;
  state.hcpUserInviteExpiresInDays = hcpUserInviteExpiresInDays || 90;
};

export const CognitoService = {
  azLogin,
  completeNewPasswordAndLogin,
  exostarLogin,
  forgotPasswordConfirmRequest,
  forgotPasswordRequest,
  getTemporaryPasswordExpiresInDays,
  getHcpUserInviteExpiresInDays,
  init,
  loadIdpTokenIfAvailable,
  login,
  refreshToken,
  signOut,
  setConfiguration,
};
