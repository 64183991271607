export enum PatientAccountStatus {
  /**
   * For some invalid combinations
   */
  UNKNOWN = 'UNKNOWN',
  /**
   * AppStatus: INACTIVE
   * Boost status: REGISTERED
   */
  NO_ACCOUNT = 'NO_ACCOUNT',
  /**
   * AppStatus: INVITED
   * Boost status: REGISTERED
   */
  INVITATION_SENT = 'INVITATION_SENT',
  /**
   * AppStatus: INVITED + timestamp > 7 days
   * Boost status: REGISTERED
   */
  INVITATION_EXPIRED = 'INVITATION_EXPIRED',
  /**
   * AppStatus: INVITED
   * Boost status: VERIFIED
   */
  VERIFIED = 'VERIFIED',
  /**
   * AppStatus: ACTIVE
   * Boost status: ACTIVE
   */
  ACTIVATED = 'ACTIVATED',
  /**
   * AppStatus: RETIRED || ALUMNI
   * Boost status: INACTIVE
   */
  INACTIVATED = 'INACTIVATED',
}
