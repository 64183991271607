import { ApiOptions, RestOptions } from '@csp/csp-common-model';
import { AxiosInstance } from 'axios';
import { axiosDmdp } from '../service/AxiosDmdpService';

/**
 * This method will extend the apiOptions with all the required rest properties, if they do not already exist.
 * It should be used in services, to convert ingoing `ApiOptions` object to the right format for the underlying Rest
 * service.
 *
 * Most importantly, it will tack on an `axiosDmdp()` instance.
 *
 * @param apiOptions the options to convert to partial `RestOptions`.
 * @param axios is the `axiosDmdp()` instance as the default one to use.
 *
 * @see {@link axiosDmdp}
 * @see {@link RestOptions}
 */
export const toRestOptions = (apiOptions?: ApiOptions, axios: AxiosInstance = axiosDmdp()): RestOptions => ({
  axios,
  ...apiOptions,
});
