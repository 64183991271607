import { Study } from '@csp/csp-common-model';
import { CaseReducer, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { loginPrincipalThunk, signOutPrincipalThunk } from '../../security/ducks/principalContextActions';
import { Principal } from '../../security/model/Principal';
import { StudiesState } from '../model/StudiesState';
import { clearSelectedStudyId, setSelectedStudyId, studiesThunk } from './studiesActions';

type RejectedAction = ReturnType<typeof studiesThunk.rejected>;

const initialState: StudiesState = { studies: [], studiesCount: 0 };

const selectFirstStudyId = (studies: Study[]): string | undefined =>
  studies.length === 1 && studies[0] ? studies[0].tenantId : undefined;

const isLoadingReducer: CaseReducer<StudiesState> = draft => {
  draft.isLoading = true;
  draft.studies = [];
  draft.error = undefined;
};

const studiesFulfilledReducer: CaseReducer<StudiesState, PayloadAction<Study[]>> = (draft, { payload }) => {
  draft.studies = payload;
  draft.selectedStudyId = draft.selectedStudyId || selectFirstStudyId(payload);
  draft.studiesCount = payload.length;
  draft.isLoading = false;
  draft.error = undefined;
};

const setSelectedStudyByIdReducer: CaseReducer<StudiesState, PayloadAction<string>> = (draft, { payload }) => {
  draft.selectedStudyId = payload;
  draft.isLoading = false;
  draft.error = undefined;
};

const selectStudyReducer: CaseReducer<StudiesState, PayloadAction<Principal>> = (draft, { payload }) =>
  setSelectedStudyByIdReducer(draft, setSelectedStudyId(payload.study.tenantId));

const rejectedReducer: CaseReducer<StudiesState, RejectedAction> = (draft, { payload }) => {
  draft.error = payload as Error;
  draft.isLoading = false;
};

const resetReducer: CaseReducer<StudiesState> = draft => {
  draft.isLoading = false;
  draft.selectedStudyId = undefined;
  draft.studies = [];
  draft.studiesCount = 0;
  draft.error = undefined;
};

const clearSelectedStudyReducer: CaseReducer<StudiesState> = draft => {
  draft.isLoading = false;
  draft.selectedStudyId = undefined;
  draft.error = undefined;
};

export const studiesReducer = createReducer(initialState, builder =>
  builder
    .addCase(studiesThunk.pending, isLoadingReducer)
    .addCase(studiesThunk.fulfilled, studiesFulfilledReducer)
    .addCase(studiesThunk.rejected, rejectedReducer)
    .addCase(loginPrincipalThunk.fulfilled, selectStudyReducer)
    .addCase(signOutPrincipalThunk.fulfilled, resetReducer)
    .addCase(clearSelectedStudyId, clearSelectedStudyReducer)
    .addCase(setSelectedStudyId, setSelectedStudyByIdReducer),
);

const persistConfig: PersistConfig<StudiesState> = {
  key: 'studies',
  storage,
  version: 0,
  whitelist: ['selectedStudyId', 'studiesCount'],
};

export const studies = persistReducer<StudiesState>(persistConfig, studiesReducer);
