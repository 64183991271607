/**
 * Converts minutes to seconds.
 *
 * @return {number} - number of seconds in number of minutes.
 */
export const minutesToSeconds = (minutes: number): number => minutes * 60;

/**
 * Converts hours to seconds.
 *
 * @return {number} - number of seconds in number of hours.
 */
export const hoursToSeconds = (hours: number): number => hours * 60 * 60;

/**
 * Converts days to hours.
 *
 * @return {number} - number of hours in number of days.
 */
export const daysToHours = (days: number): number => days * 24;

/**
 * Converts days to seconds.
 *
 * @return {number} - number of seconds in number of days.
 */
export const daysToSeconds = (days: number): number => days * 24 * 60 * 60;

/**
 * Calculates the number of milliseconds representing the number of seconds passed in.
 *
 * @return {number} - milliseconds representing nr of secods
 */
export const secondsToMillis = (seconds: number): number => seconds * 1000;

/**
 * Calculates the number of milliseconds representing the number of days passed in.
 *
 * @return {number} - milliseconds representing nr of days
 */
export const daysToMillis = (days: number): number => secondsToMillis(daysToSeconds(days));

/**
 * Calculates the number of milliseconds representing the number of minutes passed in.
 *
 * @return {number} - milliseconds representing nr of minutes
 */
export const minutesToMillis = (minutes: number): number => secondsToMillis(minutesToSeconds(minutes));

/**
 * Calculates the number of minutes representing the number of seconds passed in.
 *
 * @return {number} - minutes representing nr of seconds
 */
export const secondsToMinutesRounded = (seconds: number): number => {
  const minutes = seconds / 60;
  return Math.round(minutes);
};
