import { GenericPackageResourceContent } from '@csp/csp-web-content-definitions';

export const getWebGenericResourceContentEntry = <T extends GenericPackageResourceContent, EntryKey extends keyof T>(
  content: T,
  entryIdentifier: EntryKey,
): T[EntryKey] => content[entryIdentifier];

export const getWebGenericResourceContentBlock = <
  T extends GenericPackageResourceContent,
  EntryKey extends keyof T,
  BlockKey extends keyof T[EntryKey],
>(
  content: T,
  entryIdentifier: EntryKey,
  blockIdentifier: BlockKey,
): T[EntryKey][BlockKey] => getWebGenericResourceContentEntry(content, entryIdentifier)[blockIdentifier];
