import { Alert, AlertColor, Slide, Snackbar as MuiSnackbar, SnackbarOrigin, SnackbarProps } from '@mui/material';
import { FC } from 'react';

const defaultLocation: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

type In = SnackbarProps & { location?: SnackbarOrigin; severity?: AlertColor };

export const Snackbar: FC<In> = ({ autoHideDuration = 5000, location = defaultLocation, severity, ...props }) => (
  <MuiSnackbar anchorOrigin={location} autoHideDuration={autoHideDuration} {...props} TransitionComponent={Slide}>
    {severity && <Alert severity={severity}>{props.message}</Alert>}
  </MuiSnackbar>
);
