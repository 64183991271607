import { Maybe } from '@csp/csp-common-model';
import { isDefined, isNotEmpty } from '@csp/csp-common-util';
import { min } from 'lodash';
import traverse from 'traverse';
import { BulkAssetSignedUrls } from '../model/BulkAssetSignedUrls';
import { UnifyContentPackage } from '../../types/UnifyContentPackage';

const getSignedUrlsFromPackage = (contentPackage: UnifyContentPackage): string[] => {
  const signedUrls: string[] = [];

  traverse(contentPackage).forEach(function (value) {
    if (this.key === 'url') {
      signedUrls.push(value);
    }
  });

  return signedUrls;
};

const getOneHourExpireTimeInUnixMillis = (): number => new Date(Date.now() + 60 * 60 * 1000).getTime();

const extractAllExpireTimes = (signedUrls: string[]): number[] =>
  Object.values(signedUrls)
    .filter(isNotEmpty)
    .map(url => url.match(/X-Amz-Expires=(\d+)/)?.[1])
    .filter(isDefined)
    .map(str => parseInt(str));

export const getSignedUrlExpirationDate = (signedUrls: string[]): Maybe<number> => {
  if (Object.values(signedUrls).length === 0) {
    return undefined;
  }

  const expireTimes = extractAllExpireTimes(signedUrls);
  if (expireTimes.every(Number.isNaN)) {
    return getOneHourExpireTimeInUnixMillis();
  }
  const expiresInSeconds = min(expireTimes);
  if (expiresInSeconds === undefined) {
    return getOneHourExpireTimeInUnixMillis();
  }
  return new Date(Date.now() + expiresInSeconds * 1000).getTime();
};

export const getSignedUrlExpirationDateFromAssetResponse = (signedUrls: BulkAssetSignedUrls): Maybe<number> =>
  getSignedUrlExpirationDate(Object.values(signedUrls));

export const getSignedUrlExpirationDateFromContentPackage = (contentPackage: UnifyContentPackage): Maybe<number> =>
  getSignedUrlExpirationDate(getSignedUrlsFromPackage(contentPackage));
