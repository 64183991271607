/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum QuestionnaireResponseAggregateCriteriaFieldV2 {
    USER_ID = 'USER_ID',
    CURRENT_STATUS_VALUE = 'CURRENT_STATUS_VALUE',
    CURRENT_STATUS_TIMESTAMP = 'CURRENT_STATUS_TIMESTAMP',
    EVENT_ID = 'EVENT_ID',
    SCHEDULE_REQUEST_REF_REQUEST_CODE = 'SCHEDULE_REQUEST_REF_REQUEST_CODE',
    SCHEDULE_REQUEST_REF_VERSION_CODE = 'SCHEDULE_REQUEST_REF_VERSION_CODE',
    SCHEDULE_REQUEST_REF_SCHEDULE_CODE = 'SCHEDULE_REQUEST_REF_SCHEDULE_CODE',
    SCHEDULE_REQUEST_REF_REF_TIMESTAMP = 'SCHEDULE_REQUEST_REF_REF_TIMESTAMP',
    REVIEW_COUNT = 'REVIEW_COUNT',
}