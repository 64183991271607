/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DeviceObservationIncludeFieldV1 {
    USER_DEVICE_ID = 'USER_DEVICE_ID',
    DEVICE_MODEL_CODE = 'DEVICE_MODEL_CODE',
    REFS = 'REFS',
    META = 'META',
    SENSOR_TIMESTAMP = 'SENSOR_TIMESTAMP',
    SYNCHRONIZATION_TIMESTAMP = 'SYNCHRONIZATION_TIMESTAMP',
    SCHEDULE_REQUEST_REF = 'SCHEDULE_REQUEST_REF',
    SCHEDULE_REQUEST_STATUS = 'SCHEDULE_REQUEST_STATUS',
    VALUES = 'VALUES',
    DEVICE_OBSERVATION_ID = 'DEVICE_OBSERVATION_ID',
    USER_ID = 'USER_ID',
    EVENT_ID = 'EVENT_ID',
    AUDIT = 'AUDIT',
    SESSION_CODE = 'SESSION_CODE',
}