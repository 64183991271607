export enum PermissionType {
  MANAGE_SITES = 'MANAGE_SITES',
  SITE_TEAM_ADMIN = 'SITE_TEAM_ADMIN',
  MANAGE_SITE_LOCATIONS = 'MANAGE_SITE_LOCATIONS',
  MANAGE_SITE_PERSONNEL = 'MANAGE_SITE_PERSONNEL',
  READ_PATIENT = 'READ_PATIENT',
  VIEW_PATIENT_CONTACT_POINT = 'VIEW_PATIENT_CONTACT_POINT',
  VIEW_PATIENT_ACCOUNT = 'VIEW_PATIENT_ACCOUNT',
  MANAGE_PATIENT = 'MANAGE_PATIENT',
  MANAGE_VISIT = 'MANAGE_VISIT',
  MANAGE_MEDICATION = 'MANAGE_MEDICATION',
  VIEW_QUESTIONNAIRE_RESPONSE = 'VIEW_QUESTIONNAIRE_RESPONSE',
  MANAGE_QUESTIONNAIRE_RESPONSE = 'MANAGE_QUESTIONNAIRE_RESPONSE',
  VIEW_HEALTH_EVENT_RESPONSE = 'VIEW_HEALTH_EVENT_RESPONSE',
  MANAGE_HEALTH_EVENT = 'MANAGE_HEALTH_EVENT',
  VIEW_OTHER_SITES_METRICS = 'VIEW_OTHER_SITES_METRICS',
  CAN_SKIP_CONSENT_APPROVALS = 'CAN_SKIP_CONSENT_APPROVALS',
  CAN_VIEW_ALL_STUDY_DOCUMENTS = 'CAN_VIEW_ALL_STUDY_DOCUMENTS',
  CAN_DEBUG_CMS = 'CAN_DEBUG_CMS',
  CAN_APPROVE_EC_STATUS = 'CAN_APPROVE_EC_STATUS',
  CAN_START_SPIROMETRY_SESSION = 'CAN_START_SPIROMETRY_SESSION',
  CAN_MANAGE_SPIROMETRY_SESSION = 'CAN_MANAGE_SPIROMETRY_SESSION',
  MANAGE_STUDY_CLOSE_OUT_TRACKER = 'MANAGE_STUDY_CLOSE_OUT_TRACKER',
  CAN_EXPORT_CLOSEOUT_DATA = 'CAN_EXPORT_CLOSEOUT_DATA',
  CAN_COMPILE_QUESTIONNAIRE = 'CAN_COMPILE_QUESTIONNAIRE',
  VIEW_DATA_CHANGE_EDIT = 'VIEW_DATA_CHANGE_EDIT',
  MANAGE_DATA_CHANGE_EDIT = 'MANAGE_DATA_CHANGE_EDIT',
  MANAGE_HBS = 'MANAGE_HBS',
  VIEW_USER_DETAILS_REPORT = 'VIEW_USER_DETAILS_REPORT',
  CAN_REVIEW_DATA_CHANGE = 'CAN_REVIEW_DATA_CHANGE',
  MANAGE_RPM_EVENT = 'MANAGE_RPM_EVENT',
  MANAGE_REQUEST_TAGS = 'MANAGE_REQUEST_TAGS',
}
