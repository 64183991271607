import { getLocale, InitializationService } from '@csp/common';
import { ObjectType, toErrorInfo, TrackExceptionCallback, UnknownError } from '@csp/csp-common-model';
import { omitUndefined } from '@csp/csp-common-util';
import { TelemetryService } from '@csp/csp-web-telemetry';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getUserSelector } from '../../feature/security/ducks/principalContextSelectors';

export const useTrackException = (): TrackExceptionCallback => {
  const user = useSelector(getUserSelector);
  const locale = useSelector(state => getLocale(InitializationService.commonSelector(state)));

  return useCallback(
    (error: UnknownError, context?: ObjectType) => {
      if (error) {
        const { roles, orgIds } = user ?? {};
        const { code: errorCode, id: errorId } = toErrorInfo({ error });

        const extra = omitUndefined({ context, orgIds, roles, locale });
        const tags = omitUndefined({
          errorCode,
          errorId,
        });

        TelemetryService.trackException({
          error,
          context: { extra, tags },
        });
      }
    },
    [locale, user],
  );
};
