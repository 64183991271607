import { CustomStatus, CustomStatuses, Org } from '@csp/csp-common-model';
import { uniqBy } from 'lodash/fp';
import { CustomStatusMapper } from '../mapper/CustomStatusMapper';

const uniqByType = uniqBy<CustomStatus>(customStatus => customStatus.type);

const concatUniqWithLeadingPriority = (...customStatusesList: CustomStatuses[]): CustomStatuses =>
  CustomStatusMapper.from(uniqByType(customStatusesList.flatMap(customStatus => customStatus.statuses)));

const getMergedCustomStatusesFromOrgs = (orgs: Org[]): CustomStatuses =>
  concatUniqWithLeadingPriority(...orgs.map(org => org.customStatuses));

export const CustomStatusService = {
  getMergedCustomStatusesFromOrgs,

  /** Testing */
  concatUniqWithLeadingPriority,
};
