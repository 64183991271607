import { useOnMount } from '@csp/fe-hooks';
import { useState } from 'react';
import { PublicContentService } from '../../common/service/PublicContentService';

type Out = {
  isPubContentInitialized: boolean;
};

export const useFetchPubContentOnMount = (): Out => {
  const [isPubContentInitialized, setIsPubContentInitialized] = useState(false);
  const [error, setError] = useState<unknown>();

  if (error) {
    throw error;
  }

  useOnMount(async () => {
    try {
      await PublicContentService.init();
    } catch (err) {
      setError(err);
    }
    setIsPubContentInitialized(true);
  });

  return {
    isPubContentInitialized,
  };
};
