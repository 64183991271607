import { Maybe, ObjectType } from '@csp/csp-common-model';
import { remove } from 'lodash';
import { SignoutHandler } from './SignoutHandler';

type MessageType = 'sign-out' | 'other';

type Message = {
  type: MessageType;
  data?: ObjectType;
};

export type BroadcastChannelHandler = (this: BroadcastChannel, ev: MessageEvent) => void;

const channels: BroadcastChannel[] = [];
const BroadcastChannelApiAvailable = 'BroadcastChannel' in window;

const defaultHandler: BroadcastChannelHandler = ({ data }) => {
  switch ((data as Message).type) {
    case 'sign-out':
      SignoutHandler();
      break;
  }
};

const getChannel = (channelName: string): Maybe<BroadcastChannel> => {
  const channel = channels.find(channel => channel.name === channelName);

  if (!channel && BroadcastChannelApiAvailable) {
    console.warn(`Could not retrieve broadcast channel - channel ${channelName} does not exist`);
  }

  return channel;
};

const addListener = (channelName: string, handler: BroadcastChannelHandler): void => {
  getChannel(channelName)?.addEventListener('message', handler);
};

const removeListener = (channelName: string, handler: BroadcastChannelHandler): void => {
  getChannel(channelName)?.removeEventListener('message', handler);
};

const createChannel = (channelName: string): void => {
  if (BroadcastChannelApiAvailable) {
    const channel = new BroadcastChannel(channelName);

    channel.addEventListener('message', defaultHandler);
    channels.push(channel);
  }
};

const removeChannel = (channelName: string): void => {
  remove(channels, channel => channel.name === channelName);
};

const closeChannel = (channelName: string): void => {
  getChannel(channelName)?.close();
  removeChannel(channelName);
};

/*
 * Sends a message to all other documents (tabs, windows, frames and iFrames) of the same origin.
 *
 * It does not send a message to the current document and cannot be used for communicating within a document.
 *
 * @param channelName A name for the channel, used as identifier
 * @param message
 * */
const postMessage = (channelName: string, message: Message): void => {
  getChannel(channelName)?.postMessage(message);
};

export const BroadcastChannelService = {
  createChannel,
  closeChannel,
  addListener,
  removeListener,
  postMessage,
};
