/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DeviceSessionAggregateGroupFieldV1 {
    USER_ID = 'USER_ID',
    SESSION_STATUS = 'SESSION_STATUS',
    SCHEDULE_REQUEST_REF_REQUEST_CODE = 'SCHEDULE_REQUEST_REF_REQUEST_CODE',
    SCHEDULE_REQUEST_REF_VERSION_CODE = 'SCHEDULE_REQUEST_REF_VERSION_CODE',
    SCHEDULE_REQUEST_REF_SCHEDULE_CODE = 'SCHEDULE_REQUEST_REF_SCHEDULE_CODE',
    SCHEDULE_REQUEST_STATUS = 'SCHEDULE_REQUEST_STATUS',
}