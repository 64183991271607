import { AxiosError } from 'axios';
import { OrgId, UserId, RestOptions, CspError } from '@csp/csp-common-model';
import {
  ConsentActiveSchedulesV1,
  ConsentApprovalInV1,
  ConsentApprovalQueryV1,
  ConsentApprovalsV1,
  ConsentApprovalV1,
  ConsentArtifactCriteriaFieldV1,
  ConsentArtifactQueryTypeV1,
  ConsentArtifactQueryV1,
  ConsentArtifactsV1,
  ConsentArtifactV1,
  ConsentRequestOutcomesV1,
  ConsentActiveSchedulesOrganizationsV1,
  ConsentActiveSchedulesOrganizationsQueryV1,
} from '@csp/dmdp-api-consent-dto';
import { userpodUrl } from '../../dmdp-config/dmdpConfig';

const consentUrl = (): string => `${userpodUrl()}/consent/v1`;

const getActiveSchedulesByOrgId = async (
  { axios, signal }: RestOptions,
  orgId: OrgId,
): Promise<ConsentActiveSchedulesV1> => {
  const url = `${consentUrl()}/organizations/${orgId}/active-schedules`;
  const response = await axios.get<ConsentActiveSchedulesV1>(url, {
    signal,
  });

  return response.data;
};

const queryActivatedSchedulesByOrgIds = async (
  { axios, signal }: RestOptions,
  queryV2: ConsentActiveSchedulesOrganizationsQueryV1,
): Promise<ConsentActiveSchedulesOrganizationsV1> => {
  const { data } = await axios.post<ConsentActiveSchedulesOrganizationsV1>(
    `${consentUrl()}/active-schedules:query-by-organizations`,
    queryV2,
    {
      signal,
    },
  );
  return data;
};

const getRequestOutcomesV1 = async (
  { axios, signal }: RestOptions,
  userId: UserId = 'self',
): Promise<ConsentRequestOutcomesV1> => {
  const url = `${consentUrl()}/users/${userId}/active-schedules/outcomes`;
  const response = await axios.get<ConsentRequestOutcomesV1>(url, {
    signal,
  });

  return response.data;
};

const getArtifactV1: (apiOptions: RestOptions, artifactCode: string) => Promise<ConsentArtifactV1 | undefined> = async (
  { axios, signal },
  artifactCode,
) => {
  const url = `${consentUrl()}/artifacts:query`;
  const query: ConsentArtifactQueryV1 = {
    criterion: {
      fieldCriterion: {
        field: ConsentArtifactCriteriaFieldV1.ARTIFACT_CODE,
        type: ConsentArtifactQueryTypeV1.IN,
        values: [artifactCode],
      },
    },
  };

  try {
    const response = await axios.post<ConsentArtifactsV1>(url, query, {
      signal,
    });
    return response.data.artifacts[0];
  } catch (e) {
    throw new Error(
      `ConsentRestService.getArtifactV1() => could not find artifact with artifact code "${artifactCode}". ${
        (e as Error).message
      }`,
    );
  }
};

const queryApprovalsV1 = async (
  { axios, signal }: RestOptions,
  query: ConsentApprovalQueryV1,
): Promise<ConsentApprovalsV1> => {
  const url = `${consentUrl()}/approvals:query`;
  const response = await axios.post<ConsentApprovalsV1>(url, query, {
    signal,
  });

  return response.data;
};

const postApprovalV1 = async (
  { axios, signal }: RestOptions,
  consent: ConsentApprovalInV1,
): Promise<ConsentApprovalV1> => {
  const url = `${consentUrl()}/approvals`;
  const response = await axios.post<ConsentApprovalV1>(url, consent, {
    signal,
  });

  return response.data;
};

const queryArtifacts = async (
  { axios, signal }: RestOptions,
  query: ConsentArtifactQueryV1,
): Promise<ConsentArtifactsV1> => {
  const url = `${consentUrl()}/artifacts:query`;
  const response = await axios.post<ConsentArtifactsV1>(url, query, {
    signal,
  });

  return response.data;
};

const getArtifacts = async ({ axios, signal }: RestOptions): Promise<ConsentArtifactsV1> => {
  const url = `${consentUrl()}/artifacts`;
  const { data } = await axios.get<ConsentArtifactsV1>(url, {
    signal,
  });

  return {
    ...data,
    artifacts: data.artifacts.sort((a, b) => a.artifactCode.localeCompare(b.artifactCode)),
  };
};

const withdrawApprovalV1 = async ({ axios, signal }: RestOptions, approvalId: string): Promise<ConsentApprovalV1> => {
  const url = `${consentUrl()}/approvals/${approvalId}/withdraw`;

  try {
    const response = await axios.put<ConsentApprovalV1>(
      url,
      { reason: 'Withdrawn' },
      {
        signal,
      },
    );
    return response.data;
  } catch (e) {
    const error = e as AxiosError;

    if (error.response?.status === 409) {
      throw CspError.conflict();
    }

    throw CspError.internal();
  }
};

export const ConsentRestServiceV1 = {
  getActiveSchedulesByOrgId,
  queryActivatedSchedulesByOrgIds,
  getRequestOutcomesV1,
  getArtifactV1,
  queryApprovalsV1,
  postApprovalV1,
  queryArtifacts,
  getArtifacts,
  withdrawApprovalV1,
};
