/* eslint-disable */
export const UnifyWebStudyMetricsMock = {
  drawer: {
    buttons: { learnMore: '[M]Learn more' },
    texts: {
      learnMoreHeader: '[M]Learn more',
      learnMoreContentMd:
        "[M]\n\n#####How to read the recruitment data \n <br/> **Invited Unify app users** \n\n The accumulated actual number of Unify app users with Unify app user status 'Invited+Yes+Retired+Alumni'. \n\n <br/> **Active Unify app users** \n\n The accumulated actual number of Unify app users with Unify app user status 'Invited+Yes+Retired+Alumni'. \n\n <br/> **Screened** \n\n The accumulated actual number of Unify app users with Unify app user status 'Invited+Yes+Retired+Alumni'. \n\n  <br/> **Screen failed** \n\n The accumulated actual number of Unify app users with Unify app user status 'Invited+Yes+Retired+Alumni'. \n\n <br/> **Randomized** \n\n The accumulated actual number of Unify app users with Unify app user status 'Invited+Yes+Retired+Alumni'. \n\n <br/> **Withdrawn** \n\n The accumulated actual number of Unify app users with Unify app user status 'Invited+Yes+Retired+Alumni'. \n\n <br/> **Potential Lost to Follow Up** \n\n The accumulated actual number of Unify app users with Unify app user status 'Invited+Yes+Retired+Alumni'. \n\n <br/> **Completed** \n\n The accumulated actual number of Unify app users with Unify app user status 'Invited+Yes+Retired+Alumni'.",
    },
  },
  recruitmentStatus: {
    texts: {
      title: '[M]Recruitment status',
      metricFilters: '[M]Metrics filters',
      lastSync: '[M]Last sync: ${lastSyncAt}',
      emptyResult: '[M]No metrics available',
      metricTypeLabels: {
        SCREENED_TOTAL: '[M]Screened',
        RANDOMIZED_TOTAL: '[M]Randomized',
        IN_SCREENING_TOTAL: '[M]In screening',
        SCREEN_FAILED_TOTAL: '[M]Screen failed',
        COMPLETED: '[M]Completed',
        DROP_OUT_RATE: '[M]Dropout rate',
        INVITED_UNIFY_APP_USERS: '[M]Invited Unify App Users',
        ACTIVE_UNIFY_APP_USERS: '[M]Active Unify App Users',
      },
    },
    errors: { notFound: '[M]Selected organisation is not part of study, e.g. not synced with VCV.' },
  },
  multiSiteComparison: { texts: { title: '[M]Multi-sites comparison', dropdownLabel: '[M]Data points' } },
};
