import { createMockCreator } from '@csp/test-common-utils';
import { BaseForm } from '../../model/BaseForm';
import { InputValue } from '../../model/InputValue';
import { createInputValueMock } from '../../model/mocks/InputValue.mock';

interface MockedSimpleForm extends BaseForm {
  title: InputValue;
  description: InputValue;
  phone: InputValue;
}

const mockedSimpleForm: MockedSimpleForm = {
  title: createInputValueMock({ value: 'title' }),
  description: createInputValueMock({ value: 'description' }),
  phone: createInputValueMock({ value: 'phone' }),
};

export const createSimpleFormMock = createMockCreator(mockedSimpleForm);
