import { fromTimezoneStrOrUndefined } from '@csp/csp-common-date-util';
import { Maybe, ZonedDateTime } from '@csp/csp-common-model';
import { RequestCode, ScheduleCode, VersionCode } from '@csp/csp-common-scheduling';
import { DeviceObservationScheduleRequestRefV1 } from '@csp/dmdp-api-device-dto';

export type DeviceObservationRequestRef = {
  scheduleCode: ScheduleCode;
  versionCode: VersionCode;
  requestCode: RequestCode;
  repeatIndex?: number;
  windowStart?: ZonedDateTime;
  windowEnd?: ZonedDateTime;
  refTimestamp?: ZonedDateTime;
};

const from = (requestRefV1?: DeviceObservationScheduleRequestRefV1): Maybe<DeviceObservationRequestRef> => {
  if (requestRefV1) {
    const { scheduleCode, versionCode, requestCode, repeatIndex, windowStart, windowEnd, refTimestamp } = requestRefV1;

    return {
      scheduleCode,
      versionCode,
      requestCode,
      repeatIndex,
      windowStart: fromTimezoneStrOrUndefined(windowStart),
      windowEnd: fromTimezoneStrOrUndefined(windowEnd),
      refTimestamp: fromTimezoneStrOrUndefined(refTimestamp),
    };
  } else {
    return undefined;
  }
};

export const DeviceObservationRequestRef = {
  from,
};
