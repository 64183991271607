import { FC } from 'react';
import { SkeletonProps } from './model/SkeletonProps';
import { Skeleton } from './Skeleton';

type Props = SkeletonProps & {
  isLoading?: boolean;
};

export const LoadingSkeleton: FC<Props> = ({ isLoading, children, ...props }) =>
  isLoading ? <Skeleton animation="wave" height={10} {...props} /> : <>{children}</>;
