import { CspError, Maybe } from '@csp/csp-common-model';
import { template } from 'lodash';
import { RequestInitiatorType } from '../initiation-config/model/RequestInitiatorType';
import { ScheduleValidationErrorMessage } from '../model/ScheduleValidationErrorMessage';
import { Duration } from '../model/schedulingModels/Duration';
import { GenericRequest } from '../model/schedulingModels/GenericRequest';
import { Repeat } from '../model/schedulingModels/Repeat';
import { Timing } from '../model/schedulingModels/Timing';
import { UnitOfTime } from '../model/schedulingModels/UnitOfTime';
import { SUPPORTED_REPEAT_PROPS } from '../type/SchedulingConst';

const hasRepeatedDaysOfWeek = (timing: Timing): boolean =>
  !!timing.repeat?.daysOfWeek && timing.repeat.daysOfWeek.length > 0;

const hasRepeatedTimesOfDay = (timing: Timing): boolean =>
  !!timing.repeat?.timesOfDay && timing.repeat.timesOfDay.length > 0;

const hasWindowBeforeUnit = (timing: Timing): boolean =>
  !!timing.windowBefore &&
  (timing.windowBefore.unit === UnitOfTime.H ||
    timing.windowBefore.unit === UnitOfTime.MIN ||
    timing.windowBefore.unit === UnitOfTime.S);

const hasWindowAfterUnit = (timing: Timing): boolean =>
  !!timing.windowAfter &&
  (timing.windowAfter.unit === UnitOfTime.H ||
    timing.windowAfter.unit === UnitOfTime.MIN ||
    timing.windowAfter.unit === UnitOfTime.S);

const hasInvalidCombinationOfDaysOfWeekAndWindowBeforeOrAfter = (timing: Timing): boolean => {
  if (hasRepeatedDaysOfWeek(timing) && !hasRepeatedTimesOfDay(timing)) {
    // Time of day is not defined, we will assume whole day is covered => D:00:00 - D+1:00:00
    // Does not support offset units less than a day in magnitude
    if (hasWindowBeforeUnit(timing) || hasWindowAfterUnit(timing)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

type RepeatProps = keyof Repeat;
const getUnsupportedRepeatProps = (timing: Timing): string[] => {
  const repeat = timing.repeat ?? {};

  return Object.keys(repeat).filter(prop => !SUPPORTED_REPEAT_PROPS.includes(prop as RepeatProps));
};

const hasConflictingTimeOfDayProperties = (timing: Timing): boolean => {
  const repeat = timing.repeat ?? {};
  const { timesOfDay, period, periodUnit, frequency } = repeat;

  const timeOfDayExists = timesOfDay !== undefined ? timesOfDay.length > 0 : false;
  const periodIsDefined = period !== undefined;
  const frequencyIsDefined = frequency !== undefined;

  if (timeOfDayExists && periodIsDefined) {
    // Using time of day in combination with period is not allowed according to FHIR,
    // but to be backward compatible with existing faulty timing configurations we need to
    // allow this combination if period is exactly 1 Day (which is the implicit period when using timeOfDay)
    return !(periodUnit === UnitOfTime.D && period === 1);
  } else if (timeOfDayExists && frequencyIsDefined) {
    // For backwards compatibility, we allow frequency 1 when time of day is defined (which is the implicit frequency)
    return frequency !== 1;
  } else {
    return false;
  }
};

const isDurationNegative = (duration: Maybe<Duration>): boolean => (duration ? duration.value < 0 : false);

const validateRequest = (request: GenericRequest): void => {
  validateRequestPropCombinations(request);
  validateTiming(request.timing ?? {});
};

const validateRequestPropCombinations = (request: GenericRequest): void => {
  if (request.timing && request.initiationConfig?.initiator === RequestInitiatorType.HCP) {
    throw CspError.badState(ScheduleValidationErrorMessage.INVALID_COMBINATION_OF_INITIATION_AND_TIMING);
  }
};

const validateTiming = (timing: Timing): void => {
  const unsupportedProps = getUnsupportedRepeatProps(timing);
  if (hasConflictingTimeOfDayProperties(timing)) {
    throw CspError.badState(ScheduleValidationErrorMessage.TIME_OF_DAY_CONFLICT);
  }
  if (isDurationNegative(timing.windowBefore)) {
    throw CspError.badState(ScheduleValidationErrorMessage.WINDOW_BEFORE_IS_NEGATIVE);
  }
  if (isDurationNegative(timing.windowAfter)) {
    throw CspError.badState(ScheduleValidationErrorMessage.WINDOW_AFTER_IS_NEGATIVE);
  }
  if (unsupportedProps.length) {
    throw CspError.badState(
      template(ScheduleValidationErrorMessage.UNSUPPORTED_REPEAT_PROPS_TEMPLATE)({
        props: unsupportedProps.join(', '),
      }),
    );
  }

  if (hasInvalidCombinationOfDaysOfWeekAndWindowBeforeOrAfter(timing)) {
    throw CspError.badState(ScheduleValidationErrorMessage.INVALID_COMBINATION_OF_WINDOWS_WITHOUT_TIME_OF_DAY);
  }
};

export const ScheduleValidationService = {
  validateRequest,
  validateTiming,
};
