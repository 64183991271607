import { VisitVariantType } from './VisitVariantType';

export enum VisitType {
  SCHEDULED = 'SCHEDULED',
  UNSCHEDULED = 'UNSCHEDULED',
  UNKNOWN = 'UNKNOWN',
}

const fromVisitVariant = (visitVariant: VisitVariantType): VisitType => {
  if (visitVariant === VisitVariantType.UNSCHEDULED) {
    return VisitType.UNSCHEDULED;
  } else if (visitVariant === VisitVariantType.UNKNOWN) {
    return VisitType.UNKNOWN;
  } else {
    return VisitType.SCHEDULED;
  }
};

export const VisitTypes = {
  fromVisitVariant,
};
