export enum BioAnalyteValueType {
  DNA = 'DNA',
  CDNA = 'CDNA',
  CFDNA = 'CFDNA',
  CTDNA = 'CTDNA',
  GDNA = 'GDNA',
  GERMLINEDNA = 'GERMLINEDNA',
  MTDNA = 'MTDNA',
  OLIGONUCLEOTIDE = 'OLIGONUCLEOTIDE',
  LIPID = 'LIPID',
  PHOSPHOLIPID = 'PHOSPHOLIPID',
  METABOLITE = 'METABOLITE',
  PEPTIDE = 'PEPTIDE',
  PROTEIN = 'PROTEIN',
  CYTOPLASMIC_PROTEIN = 'CYTOPLASMIC_PROTEIN',
  EXTRACELLULAR_PROTEIN = 'EXTRACELLULAR_PROTEIN',
  PHOSPHOPROTEIN = 'PHOSPHOPROTEIN',
  PROTEIN_COMPLEX = 'PROTEIN_COMPLEX',
  RNA = 'RNA',
  ANTISENSE_RNA = 'ANTISENSE_RNA',
  CFRNA = 'CFRNA',
  CRNA = 'CRNA',
  MIRNA = 'MIRNA',
  MRNA = 'MRNA',
  POLY_A_RNA = 'POLY_A_RNA',
  RRNA = 'RRNA',
  SHRNA = 'SHRNA',
  SIRNA = 'SIRNA',
  TOTAL_RNA = 'TOTAL_RNA',
  TRNA = 'TRNA',
  OTHER = 'OTHER',
  UNKNOWN = 'UNKNOWN',
}
