import { OrgId, StateAssert } from '@csp/csp-common-model';
import { uniqBy } from 'lodash';
import { RequestGroups } from '../request-group/model/RequestGroups';
import { RequestGroupMapper } from '../request-group/mapper/RequestGroupMapper';
import { findScheduleVersionsActiveNow } from '../util/scheduleUtil';
import { GenericRequest } from './schedulingModels/GenericRequest';
import { GenericSchedule } from './schedulingModels/GenericSchedule';
import { OrgSchedules } from './OrgSchedules';

export type OrgsSchedules<T extends GenericSchedule, S extends GenericRequest> = {
  getSchedulesByOrgId(orgId: OrgId): T[];
  getRequestsByOrgId(orgId: OrgId): S[];
  getAllRequestsWithOrgId(): { requests: S[]; orgId: OrgId }[];
  getAllRequests(): S[];
  getActiveRequests(): S[];
  getAllSchedules(): T[];
  getRequestGroups(): RequestGroups<S>;
  orgSchedules: Readonly<OrgSchedules<T>[]>;
};

const from = <T extends GenericSchedule, S extends GenericRequest>(
  orgSchedules: OrgSchedules<T>[],
): OrgsSchedules<T, S> => {
  StateAssert.isTrue(
    orgSchedules.length === uniqBy(orgSchedules, schedule => schedule.orgId).length,
    'Org can only exists once',
  );

  const getSchedulesByOrgId = (orgId: OrgId): T[] => {
    const orgSchedule = orgSchedules.find(schedule => schedule.orgId === orgId);
    return orgSchedule ? orgSchedule.schedules : [];
  };

  const getRequestsByOrgId = (orgId: OrgId): S[] =>
    getSchedulesByOrgId(orgId).flatMap(schedule => schedule.activeVersionWindows.flatMap(win => win.requests)) as S[];

  const getAllRequestsWithOrgId = (): { requests: S[]; orgId: string }[] =>
    orgSchedules.map(orgSchedule => ({
      orgId: orgSchedule.orgId,
      requests: orgSchedule.schedules.flatMap(schedule =>
        schedule.activeVersionWindows.flatMap(win => win.requests),
      ) as S[],
    }));

  const getAllRequests = (): S[] =>
    orgSchedules.flatMap(orgSchedule =>
      orgSchedule.schedules.flatMap(schedule => schedule.activeVersionWindows.flatMap(win => win.requests)),
    ) as S[];

  const getActiveRequests = (): S[] =>
    findScheduleVersionsActiveNow(getAllSchedules()).flatMap(version => version.requests) as S[];

  const getAllSchedules = (): T[] => orgSchedules.flatMap(orgSchedule => orgSchedule.schedules);

  const getRequestGroups = (): RequestGroups<S> => RequestGroupMapper.from(getAllRequests());

  return {
    getSchedulesByOrgId,
    getRequestsByOrgId,
    getAllRequestsWithOrgId,
    getAllRequests,
    getActiveRequests,
    getAllSchedules,
    getRequestGroups,
    orgSchedules,
  };
};

export const OrgsSchedules = {
  from,
};
