import { DeviceObservationRequestService } from '@csp/csp-common-devices';
import { ApiOptions, CountValue, Patient, UserId } from '@csp/csp-common-model';
import { isNotEmpty } from '@csp/csp-common-util';
import { toRestOptions } from '@csp/csp-fe-auth';
import { DeviceSessionAggregationRestServiceV1 } from '@csp/dmdp-api-client';
import { DeviceSessionAggregateV1 } from '@csp/dmdp-api-device-dto';
import { chunk, isEmpty } from 'lodash/fp';
import { ScheduleRequestDateUntilCriteria } from '../../model/ScheduleRequestDateUntilCriteria';
import { ScheduleRequestMetricCriterion } from '../../model/ScheduleRequestMetricCriterion';
import { ScheduleRequestMetricInfo } from '../../model/ScheduleRequestMetricInfo';
import { DeviceMetricCriteriaMapper } from '../mapper/DeviceMetricCriteriaMapper';
import { DeviceSessionAggregateQueryMapperV1 } from '../mapper/DeviceSessionAggregateQueryMapperV1';
import { DeviceSessionAggregateResultMapperV1 } from '../mapper/DeviceSessionAggregateResultMapperV1';

/**
 * Fetches metric info for all configured device requests, with 20 criteria per request.
 * This is a limitation in the Boost backend.
 */
const chunksOf = chunk(20);

const getMetricInfo = async (
  userId: UserId,
  criteria: ScheduleRequestMetricCriterion[],
  apiOptions?: ApiOptions,
): Promise<ScheduleRequestMetricInfo[]> => {
  if (isEmpty(criteria)) {
    return [];
  } else {
    const aggregateQueries: DeviceSessionAggregateV1[] = [
      DeviceSessionAggregateQueryMapperV1.createMetricCountAggregateQuery(userId, criteria, true),
    ];

    const recentPeriodCriteria = criteria.filter(c => !!c.recentPeriod);
    if (isNotEmpty(recentPeriodCriteria)) {
      const recentPeriodQuery = DeviceSessionAggregateQueryMapperV1.createMetricCountAggregateQuery(
        userId,
        recentPeriodCriteria,
        false,
      );
      aggregateQueries.push(recentPeriodQuery);
    }

    const restOptions = toRestOptions(apiOptions);

    const results = aggregateQueries.map(query => DeviceSessionAggregationRestServiceV1.aggregate(restOptions, query));
    const [totalResultV1, recentResultV1] = await Promise.all(results);

    return DeviceSessionAggregateResultMapperV1.toScheduleRequestMetricInfos(
      criteria,
      totalResultV1?.groups ?? [],
      recentResultV1?.groups ?? [],
    );
  }
};

const getMetricInfosForPatient = async (
  patient: Patient,
  apiOptions?: ApiOptions,
): Promise<ScheduleRequestMetricInfo[]> => {
  const deviceRequestInfos = await DeviceObservationRequestService.getActiveRequestsWithInfo(
    patient,
    toRestOptions(apiOptions),
  );

  const criteria = DeviceMetricCriteriaMapper.toMetricCriteria(deviceRequestInfos);

  const promises = chunksOf(criteria).map(criteria =>
    DeviceSessionAggregationService.getMetricInfo(patient.userId, criteria, apiOptions),
  );

  const metricInfoChunks = await Promise.all(promises);

  return metricInfoChunks.flat();
};

const getCountValueUntilDate = async (
  userId: UserId,
  criteria: ScheduleRequestDateUntilCriteria,
  apiOptions?: ApiOptions,
): Promise<CountValue> => {
  const queryV1 = DeviceSessionAggregateQueryMapperV1.createMetricCountUntilDateAggregateQuery(userId, criteria);
  const aggregateResultV1 = await DeviceSessionAggregationRestServiceV1.aggregate(toRestOptions(apiOptions), queryV1);

  return DeviceSessionAggregateResultMapperV1.toCountValue(aggregateResultV1);
};

export const DeviceSessionAggregationService = {
  getMetricInfo,
  getMetricInfosForPatient,
  getCountValueUntilDate,
};
