import { Loading } from '@csp/csp-web-ui';
import { FC, PropsWithChildren } from 'react';
import { StudyContextProvider } from './StudyContextProvider';
import { useStudyContextProvider } from './useStudyContextProvider';

export const StudyContextContent: FC<PropsWithChildren> = ({ children }) => {
  const state = useStudyContextProvider();

  return (
    <Loading isLoading={!state}>
      {state && <StudyContextProvider state={state}>{children}</StudyContextProvider>}
    </Loading>
  );
};
