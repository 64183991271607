import { ApiOptions, ObjectType, StateAssert } from '@csp/csp-common-model';
import { JsonValidationService, ObjectUtil } from '@csp/csp-common-util';
import { toRestOptions } from '@csp/csp-fe-auth';
import { ContentRestServiceV1 } from '@csp/dmdp-api-client';
import { CONTENT_PAGE_SIZE, ContentParamsV1, ContentResponseV1, firstContentPage } from '@csp/dmdp-api-content-dto';
import type { ContentConfigV1 } from '../config/model/ContentConfigV1';
import { ContentCriteria } from '../model/ContentCriteria';
import { ContentUtil } from '../util/ContentUtil';

const MAX_NUM_RECURSION = 50;

const getLimit = (limit?: number): number => (limit ? limit : CONTENT_PAGE_SIZE);

const fetchAllContentInternal = async <T extends ObjectType>(
  { contentType, locale, matchProperties, limit }: ContentCriteria,
  contentConfig: ContentConfigV1,
  apiOptions?: ApiOptions,
): Promise<T[]> => {
  const contentParams: ContentParamsV1 = {
    clientType: contentConfig.clientType,
    clientVersion: contentConfig.clientVersion,
    contentType,
    locale,
  };

  const fetchedContents: T[] = [];

  let contentPage = firstContentPage();
  contentPage.limit = getLimit(limit);
  let fetchNext = true;
  let count = 0;

  while (fetchNext && count < MAX_NUM_RECURSION) {
    const fetchedContentResult: ContentResponseV1<T> = await ContentRestServiceV1.getContent(
      toRestOptions(apiOptions),
      matchProperties ?? [],
      contentParams,
      contentPage,
    );
    count++;

    if (fetchedContentResult?.rows) {
      fetchedContents.push(...fetchedContentResult.rows);
    }

    if (
      fetchedContentResult &&
      fetchedContentResult.totalRows > fetchedContentResult.offset + fetchedContentResult.size
    ) {
      contentPage = {
        skip: fetchedContentResult.offset + fetchedContentResult.size,
        limit: getLimit(limit),
      };
    } else {
      fetchNext = false;
    }
  }

  return fetchedContents;
};

const getContents = async <T extends ObjectType>(
  criteria: ContentCriteria,
  contentConfig: ContentConfigV1,
  mockedContents?: T[],
  apiOptions?: ApiOptions,
): Promise<T[]> => {
  const { contentType, schema } = criteria;
  const fetchedContents: T[] = await fetchAllContentInternal(criteria, contentConfig, toRestOptions(apiOptions));

  if (contentConfig.validateContent) {
    const cleanedSchema = ObjectUtil.omitRecursively(schema, ['enum']);

    fetchedContents.forEach(content => JsonValidationService.validateJson(contentType, cleanedSchema, content));
  }

  return contentConfig.allowMockedContent && mockedContents
    ? ContentUtil.mergeContent(mockedContents, fetchedContents)
    : fetchedContents;
};

const getContent = async <T extends ObjectType>(
  criteria: ContentCriteria,
  contentConfiguration: ContentConfigV1,
  mockedContent?: T,
  apiOptions?: ApiOptions,
): Promise<T> => {
  const content = (
    await getContents(criteria, contentConfiguration, mockedContent ? [mockedContent] : [], toRestOptions(apiOptions))
  )[0];
  StateAssert.notNull(content, `No content found for:  ${JSON.stringify(criteria)}`);
  return content;
};

export const ContentService = {
  getContent,
  getContents,
  fetchAllContentInternal,
};
