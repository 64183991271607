import { CspError, Maybe, ObjectType } from '@csp/csp-common-model';
import { getUnifyVersion } from '@csp/csp-common-unify-version';
import { StorageService } from '@csp/web-common';
import { isEmpty, isString } from 'lodash';
import { ContentCacheProvider } from '../model/ContentCacheProvider';
import { storedUnifyVersionMatches } from '../util/storedUnifyVersionMatches';

const DEFAULT_LOCAL_STORAGE_CACHE_KEY = 'unify-content-cache';

const getStorage = (): Storage => localStorage;

export const LocalStorageCacheProviderFactory = <K extends string, V extends ObjectType | string | number>(
  cacheKey = DEFAULT_LOCAL_STORAGE_CACHE_KEY,
): ContentCacheProvider<K, V> => {
  let cache: Partial<Record<K, V>> = {};
  const get = (key: K): Maybe<V> => cache[key];
  const set = async (key: K, value: V): Promise<void> => {
    cache[key] = value;
    await saveToDisk();
  };
  const loadFromDisk = async (): Promise<void> => {
    if (!isEmpty(cache)) {
      throw CspError.badState('Cache not empty during hydration');
    }
    try {
      if (!storedUnifyVersionMatches()) {
        getStorage().removeItem(cacheKey);
        StorageService.localStorage.setItem('unify-release-version', getUnifyVersion());
        return;
      }
      const cacheStr = getStorage().getItem(cacheKey);
      if (isString(cacheStr)) {
        cache = JSON.parse(cacheStr);
      }
    } catch (e) {
      // ignore error
      console.log(e);
    }
  };
  const saveToDisk = async (): Promise<void> => {
    getStorage().setItem(cacheKey, JSON.stringify(cache));
  };

  return {
    get,
    set,
    loadFromDisk,
    saveToDisk,
  };
};
