import {
  PatientAppStatusType,
  PatientMedicationStatusType,
  PatientMedicationStatusTypes,
  PatientSkipStatusType,
  PatientStatuses,
  PatientStudyStatusType,
  PatientStudyStatusTypeValues,
  RtsmStatusMapping,
  StudyStatusMapping,
} from '@csp/csp-common-model';
import { PatientStatusTransition } from '../model/PatientStatusTransition';

const EMPTY_STUDY_STATUS_MAPPING: Readonly<StudyStatusMapping> = {
  medicationStatus: PatientSkipStatusType.IGNORE,
  appStatus: PatientSkipStatusType.IGNORE,
};

const DEFAULT_STUDY_STATUS_MAPPINGS: Readonly<Map<PatientStudyStatusType, StudyStatusMapping>> = new Map([
  [
    PatientStudyStatusType.SCREENED,
    {
      medicationStatus: PatientMedicationStatusType.IN_SCREENING,
      appStatus: PatientSkipStatusType.IGNORE,
    },
  ],
  [
    PatientStudyStatusType.SCREEN_FAILED,
    {
      medicationStatus: PatientMedicationStatusType.NO_TREATMENT,
      appStatus: PatientAppStatusType.RETIRED,
    },
  ],
  [
    PatientStudyStatusType.PRE_SCREENED,
    {
      medicationStatus: PatientSkipStatusType.IGNORE,
      appStatus: PatientSkipStatusType.IGNORE,
    },
  ],
  [
    PatientStudyStatusType.COMPLETED,
    {
      medicationStatus: PatientMedicationStatusType.NO_TREATMENT,
      appStatus: PatientAppStatusType.ALUMNI,
    },
  ],
  [
    PatientStudyStatusType.RANDOMIZED,
    {
      medicationStatus: PatientMedicationStatusType.IN_TREATMENT,
      appStatus: PatientSkipStatusType.IGNORE,
    },
  ],
  [
    PatientStudyStatusType.EARLY_WITHDRAWAL,
    {
      medicationStatus: PatientMedicationStatusType.NO_TREATMENT,
      appStatus: PatientAppStatusType.RETIRED,
    },
  ],
  [
    PatientStudyStatusType.LOST_TO_FOLLOW_UP,
    {
      medicationStatus: PatientMedicationStatusType.NO_TREATMENT,
      appStatus: PatientAppStatusType.RETIRED,
    },
  ],
  [
    PatientStudyStatusType.DECEASED,
    {
      medicationStatus: PatientMedicationStatusType.NO_TREATMENT,
      appStatus: PatientAppStatusType.RETIRED,
    },
  ],
  [
    PatientStudyStatusType.FOLLOW_UP,
    {
      medicationStatus: PatientSkipStatusType.IGNORE,
      appStatus: PatientSkipStatusType.IGNORE,
    },
  ],
  [
    PatientStudyStatusType.POTENTIAL_LOST_TO_FOLLOW_UP,
    {
      medicationStatus: PatientSkipStatusType.IGNORE,
      appStatus: PatientSkipStatusType.IGNORE,
    },
  ],
  [
    PatientStudyStatusType.RE_SCREENED,
    {
      medicationStatus: PatientSkipStatusType.IGNORE,
      appStatus: PatientSkipStatusType.IGNORE,
    },
  ],
]);

const STUDY_STATUS_TRANSITIONS: Readonly<Map<PatientStudyStatusType, PatientStudyStatusType[]>> = new Map([
  [
    PatientStudyStatusType.PRE_SCREENED,
    [PatientStudyStatusType.EARLY_WITHDRAWAL, PatientStudyStatusType.DECEASED, PatientStudyStatusType.SCREENED].sort(),
  ],
  [
    PatientStudyStatusType.SCREENED,
    [
      PatientStudyStatusType.EARLY_WITHDRAWAL,
      PatientStudyStatusType.DECEASED,
      PatientStudyStatusType.SCREEN_FAILED,
      PatientStudyStatusType.RANDOMIZED,
    ].sort(),
  ],
  [PatientStudyStatusType.SCREEN_FAILED, []],
  [
    PatientStudyStatusType.RANDOMIZED,
    [
      PatientStudyStatusType.EARLY_WITHDRAWAL,
      PatientStudyStatusType.DECEASED,
      PatientStudyStatusType.LOST_TO_FOLLOW_UP,
      PatientStudyStatusType.COMPLETED,
    ].sort(),
  ],
  [PatientStudyStatusType.COMPLETED, []],
  [PatientStudyStatusType.LOST_TO_FOLLOW_UP, []],
  [PatientStudyStatusType.RE_SCREENED, []],
  [PatientStudyStatusType.POTENTIAL_LOST_TO_FOLLOW_UP, []],
  [PatientStudyStatusType.FOLLOW_UP, []],
]);

const MEDICATION_STATUS_TRANSITIONS: Readonly<Map<PatientMedicationStatusType, PatientMedicationStatusType[]>> =
  new Map([
    [
      PatientMedicationStatusType.IN_SCREENING,
      [PatientMedicationStatusType.IN_TREATMENT, PatientMedicationStatusType.NO_TREATMENT],
    ],
    [
      PatientMedicationStatusType.NO_TREATMENT,
      [PatientMedicationStatusType.IN_TREATMENT, PatientMedicationStatusType.UNBLINDED].sort(),
    ],
    [
      PatientMedicationStatusType.IN_TREATMENT,
      [
        PatientMedicationStatusType.NO_TREATMENT,
        PatientMedicationStatusType.UNBLINDED,
        PatientMedicationStatusType.TREATMENT_COMPLETED,
        PatientMedicationStatusType.TREATMENT_DISCONTINUED,
      ].sort(),
    ],
    [
      PatientMedicationStatusType.TREATMENT_COMPLETED,
      [PatientMedicationStatusType.FOLLOW_UP, PatientMedicationStatusType.SURVIVAL_FOLLOW_UP].sort(),
    ],
    [
      PatientMedicationStatusType.TREATMENT_DISCONTINUED,
      [PatientMedicationStatusType.FOLLOW_UP, PatientMedicationStatusType.SURVIVAL_FOLLOW_UP].sort(),
    ],
    [PatientMedicationStatusType.FOLLOW_UP, [PatientMedicationStatusType.SURVIVAL_FOLLOW_UP].sort()],
    [PatientMedicationStatusType.SURVIVAL_FOLLOW_UP, []],
    [PatientMedicationStatusType.UNBLINDED, []],
  ]);

const STUDY_END_STATUSES: Readonly<Array<PatientStudyStatusType>> = [
  PatientStudyStatusType.DECEASED,
  PatientStudyStatusType.LOST_TO_FOLLOW_UP,
  PatientStudyStatusType.EARLY_WITHDRAWAL,
  PatientStudyStatusType.COMPLETED,
  PatientStudyStatusType.SCREEN_FAILED,
];

const STUDY_ACTIVE_STATUSES = [PatientStudyStatusType.SCREENED, PatientStudyStatusType.RANDOMIZED];

const MEDICATION_END_STATUSES: Readonly<Array<PatientMedicationStatusType>> = [
  PatientMedicationStatusType.TREATMENT_DISCONTINUED,
  PatientMedicationStatusType.TREATMENT_COMPLETED,
  PatientMedicationStatusType.NO_TREATMENT,
];

const STUDY_INITIAL_STATUSES: Readonly<Array<PatientStudyStatusType>> = [
  PatientStudyStatusType.PRE_SCREENED,
  PatientStudyStatusType.SCREENED,
  PatientStudyStatusType.RANDOMIZED,
];

const MEDICATION_INITIAL_STATUSES: Readonly<Array<PatientMedicationStatusType>> = [
  PatientMedicationStatusType.IN_SCREENING,
  PatientMedicationStatusType.IN_TREATMENT,
  PatientMedicationStatusType.NO_TREATMENT,
];

const STUDY_STATUSES_SUPPORTING_VISIT_CONFIRMATION = [
  PatientStudyStatusType.RANDOMIZED,
  PatientStudyStatusType.SCREENED,
];

const FOLLOW_UP_STATUSES: Readonly<PatientMedicationStatusType[]> = PatientMedicationStatusTypes.followUpStatuses;

const APP_STATUSES_SUPPORTING_VISIT_BOOKING = [
  PatientAppStatusType.ACTIVE,
  PatientAppStatusType.INACTIVE,
  PatientAppStatusType.INVITED,
];

const STUDY_STATUSES_SUPPORTING_VISIT_BOOKING_CLOSE_OUT = [
  PatientStudyStatusType.RANDOMIZED,
  PatientStudyStatusType.SCREENED,
];

const getPatientStudyStatusTransitions = (
  from: PatientStudyStatusType,
  onlyInclude: PatientStudyStatusType[],
): PatientStudyStatusType[] => {
  const studyStatuses = STUDY_STATUS_TRANSITIONS.get(from) ?? [];
  return studyStatuses.filter(status => onlyInclude.includes(status));
};

const getManualPatientStudyStatusTransitions = (
  from: PatientStudyStatusType,
  onlyInclude: PatientStudyStatusType[],
  isAutoStatusUpdateEnabled: boolean,
  statusesSyncedFromExternalSource: PatientStudyStatusType[],
): PatientStudyStatusType[] =>
  getPatientStudyStatusTransitions(from, onlyInclude).filter(status =>
    // If auto sync is enabled, the status to transition to can not be mapped in config to be handled automatically.
    isAutoStatusUpdateEnabled ? !statusesSyncedFromExternalSource.includes(status) : true,
  );

const getValidPreviousPatientStudyStatuses = (from: PatientStudyStatusType): PatientStudyStatusType[] => {
  const validPrevStudyStatuses: PatientStudyStatusType[] = [];
  for (const [studyStatus, nextStudyStatuses] of STUDY_STATUS_TRANSITIONS.entries()) {
    if (nextStudyStatuses.includes(from)) {
      validPrevStudyStatuses.push(studyStatus);
    }
  }
  return validPrevStudyStatuses;
};

const getValidPreviousMedicationStatuses = (from: PatientMedicationStatusType): PatientMedicationStatusType[] => {
  const validPrevMedicationStatuses: PatientMedicationStatusType[] = [];
  for (const [medicationStatus, nextMedicationStatuses] of MEDICATION_STATUS_TRANSITIONS.entries()) {
    if (nextMedicationStatuses.includes(from)) {
      validPrevMedicationStatuses.push(medicationStatus);
    }
  }
  return validPrevMedicationStatuses;
};

const getDefaultStudyStatusMapping = (studyStatus: PatientStudyStatusType): StudyStatusMapping =>
  DEFAULT_STUDY_STATUS_MAPPINGS.get(studyStatus) ?? EMPTY_STUDY_STATUS_MAPPING;

const isEndStudyStatus = (studyStatus: PatientStudyStatusType): boolean => STUDY_END_STATUSES.includes(studyStatus);

const isEndMedicationStatus = (medicationStatus: PatientMedicationStatusType): boolean =>
  MEDICATION_END_STATUSES.includes(medicationStatus);

const isFollowUpStatus = (medicationStatus: PatientMedicationStatusType): boolean =>
  FOLLOW_UP_STATUSES.includes(medicationStatus);

const getPatientMedicationStatusTransitions = (
  from: PatientMedicationStatusType,
  onlyInclude: PatientMedicationStatusType[],
  customAllowedTransitions: PatientStatusTransition<PatientMedicationStatusType>[] = [],
): PatientMedicationStatusType[] => {
  const toMedicationStatuses = MEDICATION_STATUS_TRANSITIONS.get(from) ?? [];
  const toAllowedMedicationStatuses = customAllowedTransitions
    .filter(allowedTransition => allowedTransition.from === from)
    .flatMap(allowedTransition => allowedTransition.to);

  const toMedicationStatusesToUse = customAllowedTransitions.length
    ? toMedicationStatuses.filter(toStatus => toAllowedMedicationStatuses.includes(toStatus))
    : toMedicationStatuses;

  return toMedicationStatusesToUse.filter(toStatus => onlyInclude.includes(toStatus));
};

const mergeStudyStatusMapping = (
  studyStatus: PatientStudyStatusType,
  studyStatusMapping: StudyStatusMapping,
): StudyStatusMapping => ({
  medicationStatus:
    studyStatusMapping.medicationStatus !== PatientSkipStatusType.IGNORE
      ? studyStatusMapping.medicationStatus
      : getDefaultStudyStatusMapping(studyStatus).medicationStatus,
  appStatus:
    studyStatusMapping.appStatus !== PatientSkipStatusType.IGNORE
      ? studyStatusMapping.appStatus
      : getDefaultStudyStatusMapping(studyStatus).appStatus,
});

const mergeRtsmStatusMapping = (rtsmStatusMapping: RtsmStatusMapping): StudyStatusMapping => {
  const mergedStudyStatusMapping = {
    medicationStatus:
      rtsmStatusMapping.medicationStatus !== PatientSkipStatusType.IGNORE
        ? rtsmStatusMapping.medicationStatus
        : rtsmStatusMapping.studyStatusMapping.medicationStatus,
    appStatus:
      rtsmStatusMapping.appStatus !== PatientSkipStatusType.IGNORE
        ? rtsmStatusMapping.appStatus
        : rtsmStatusMapping.studyStatusMapping.appStatus,
  };

  if (PatientStudyStatusTypeValues.isPatientStudyStatus(rtsmStatusMapping.studyStatus)) {
    return mergeStudyStatusMapping(rtsmStatusMapping.studyStatus, mergedStudyStatusMapping);
  } else {
    return mergedStudyStatusMapping;
  }
};

const isInitialStudyStatus = (studyStatus: PatientStudyStatusType): boolean =>
  STUDY_INITIAL_STATUSES.includes(studyStatus);

const isInitialMedicationStatus = (medicationStatus: PatientMedicationStatusType): boolean =>
  MEDICATION_INITIAL_STATUSES.includes(medicationStatus);

const isBookableByStatus = ({ appStatus }: PatientStatuses): boolean =>
  APP_STATUSES_SUPPORTING_VISIT_BOOKING.includes(appStatus.status);

const isCloseoutBookableByStatus = ({ studyStatus, appStatus }: PatientStatuses): boolean =>
  STUDY_STATUSES_SUPPORTING_VISIT_BOOKING_CLOSE_OUT.includes(studyStatus.status) &&
  APP_STATUSES_SUPPORTING_VISIT_BOOKING.includes(appStatus.status);

const isVisitConfirmationByStatus = ({ studyStatus }: PatientStatuses): boolean =>
  STUDY_STATUSES_SUPPORTING_VISIT_CONFIRMATION.includes(studyStatus.status);

export const PatientStatusRuleService = {
  getManualPatientStudyStatusTransitions,
  getPatientMedicationStatusTransitions,
  getPatientStudyStatusTransitions,
  getValidPreviousPatientStudyStatuses,
  getValidPreviousMedicationStatuses,
  isEndStudyStatus,
  isEndMedicationStatus,
  endMedicationStatuses: MEDICATION_END_STATUSES,
  followUpStatuses: FOLLOW_UP_STATUSES,
  studyActiveStatuses: STUDY_ACTIVE_STATUSES,
  isBookableByStatus,
  isCloseoutBookableByStatus,
  isVisitConfirmationByStatus,
  isFollowUpStatus,
  isInitialStudyStatus,
  isInitialMedicationStatus,
  mergeRtsmStatusMapping,
  mergeStudyStatusMapping,
};
