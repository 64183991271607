import { Maybe } from '@csp/csp-common-model';

export const getMeta = <M extends { type: string }, T extends M['type'], R extends Extract<M, { type: T }>>(
  metas: Maybe<M[]>,
  type: T,
): R | undefined => {
  for (const meta of metas ?? []) {
    if (meta.type === type) {
      return meta as unknown as R;
    }
  }
  return undefined;
};
