/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AssessmentAggregateIncludeFieldV1 {
    COUNT = 'COUNT',
    MIN_ASSESSMENT_TIMESTAMP = 'MIN_ASSESSMENT_TIMESTAMP',
    MAX_ASSESSMENT_TIMESTAMP = 'MAX_ASSESSMENT_TIMESTAMP',
    MIN_EVENT_ID = 'MIN_EVENT_ID',
    MAX_EVENT_ID = 'MAX_EVENT_ID',
}