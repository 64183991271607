export enum CspErrorType {
  AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED',
  BAD_REQUEST = 'BAD_REQUEST',
  BAD_STATE = 'BAD_STATE',
  CANCELLED = 'CANCELLED',
  CONFLICT = 'CONFLICT',
  EXPIRED = 'EXPIRED',
  FORBIDDEN = 'FORBIDDEN',
  GONE = 'GONE',
  HTTP_COMMUNICATION = 'HTTP_COMMUNICATION',
  INVALID_CONFIGURATION = 'INVALID_CONFIGURATION',
  MALFORMED = 'MALFORMED',
  NETWORK_ERROR = 'NETWORK_ERROR',
  NOT_FOUND = 'NOT_FOUND',
  SERVER_ERROR = 'SERVER_ERROR',
  TIMEOUT = 'TIMEOUT',
  TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS',
  TRY_LATER = 'TRY_LATER',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNHANDLED_CLIENT_ERROR = 'UNHANDLED_CLIENT_ERROR',
  USER_NOT_ALLOWED = 'USER_NOT_ALLOWED',
}
