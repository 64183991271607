import { EnumUtil } from '@csp/csp-common-enum-util';
import { BioAnalyteValueType, BioSampleProcessedValueType, BioSampleValueType } from '@csp/csp-common-hbs-model';
import { Maybe, StateAssert, VisitVariantType } from '@csp/csp-common-model';
import { VisitConfig } from '../model/VisitConfig';
import { VISIT_CONFIG_V1_META_NAME, VisitConfigMetaV1 } from '../model/VisitConfigMetaV1';

const toConfigFromMetaV1 = (
  meta: Maybe<Record<string, unknown>>,
  isTimeBoundRequest: boolean,
  strict = false,
): VisitConfig => {
  const configMetaV1 = meta ? (meta[VISIT_CONFIG_V1_META_NAME] as Maybe<VisitConfigMetaV1>) : undefined;

  const defaultVariant = isTimeBoundRequest ? VisitVariantType.TIME_BOUND : VisitVariantType.ANY_TIME;
  const variantV1 = configMetaV1?.variant ?? defaultVariant;
  const variant = strict
    ? EnumUtil.fromStringOrError<VisitVariantType>(variantV1, VisitVariantType)
    : EnumUtil.fromStringOrDefault(variantV1, VisitVariantType, defaultVariant);

  strict &&
    configMetaV1?.hbs &&
    StateAssert.notNull(
      configMetaV1.hbs.enabled,
      'Meta hbs is not correctly configured. Missing property "enabled"Error when enabled property is missing',
    );

  const sampleTypes = (configMetaV1?.hbs?.sampleTypes ?? []).map(type =>
    strict
      ? EnumUtil.fromStringOrError<BioSampleValueType>(type.value, BioSampleValueType)
      : EnumUtil.fromStringOrDefault<BioSampleValueType>(type.value, BioSampleValueType, BioSampleValueType.UNKNOWN),
  );

  const processedSampleTypes = (configMetaV1?.hbs?.processedSampleTypes ?? []).map(type =>
    strict
      ? EnumUtil.fromStringOrError<BioSampleProcessedValueType>(type.value, BioSampleProcessedValueType)
      : EnumUtil.fromStringOrDefault<BioSampleProcessedValueType>(
          type.value,
          BioSampleProcessedValueType,
          BioSampleProcessedValueType.UNKNOWN,
        ),
  );

  const analyteTypes = (configMetaV1?.hbs?.analyteTypes ?? []).map(type =>
    strict
      ? EnumUtil.fromStringOrError<BioAnalyteValueType>(type.value, BioAnalyteValueType)
      : EnumUtil.fromStringOrDefault<BioAnalyteValueType>(type.value, BioAnalyteValueType, BioAnalyteValueType.UNKNOWN),
  );

  return {
    variant,
    requireConfirmation: configMetaV1?.requireConfirmation ?? false,
    preventBookingAfterWindow: configMetaV1?.preventBookingAfterWindow ?? false,
    preventBookingBeforeWindow: configMetaV1?.preventBookingBeforeWindow ?? false,
    hbs: {
      enabled: configMetaV1?.hbs?.enabled ?? false,
      sampleTypes,
      processedSampleTypes,
      analyteTypes,
    },
  };
};

export const VisitRequestMetaMapper = {
  toConfigFromMetaV1,
};
