import { ZonedDateTime, HealthEventStatusType } from '@csp/csp-common-model';
import { HealthEventV1 } from '@csp/dmdp-api-healthevent-dto';
import { fromTimezoneStrOrUndefined } from '@csp/csp-common-date-util';

export type HealthEventStatus = {
  value: HealthEventStatusType;
  timestamp?: ZonedDateTime;
};

const fromV1 = ({ currentStatus, previousStatuses, status }: HealthEventV1): HealthEventStatus => {
  // 1. We want to use the users localTimestamp but it is optional in the PUT api, so we need a fallback
  // 2. We use the db timestamp createdAt as first fallback, but this property has not existed from the start, so it does not exist for older health events
  // 3. We use the previous status' end timestamp as third fallback, but there this might be the the first status, so it is also optional.
  const statusTimestamp = currentStatus?.localTimestamp ?? currentStatus?.createdAt ?? previousStatuses?.[0]?.endTime;
  return {
    value: HealthEventStatusType.from(currentStatus?.status ?? status),
    timestamp: fromTimezoneStrOrUndefined(statusTimestamp),
  };
};

export const HealthEventStatus = {
  fromV1,
};
