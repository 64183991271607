import styled from '@emotion/styled';
import MuiDivider from '@mui/material/Divider';
import { DividerProps } from '@mui/material/Divider/Divider';
import { spacing, SpacingProps } from '@mui/system';
import { FC } from 'react';

const StyledDivider = styled(MuiDivider)<SpacingProps>`
  ${spacing}
`;

export const Divider: FC<DividerProps & SpacingProps> = props => <StyledDivider {...props} />;
