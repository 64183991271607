import { Maybe, ObjectType } from '@csp/csp-common-model';
import { NotificationUnitOfTime } from '../model/notification-config/NotificationUnitOfTime';
import { ReminderNotification } from '../model/notification-config/ReminderNotification';
import {
  ReminderNotificationConfigMeta,
  REMINDER_NOTIFICATION_CONFIG_V1_ALLOW_BUNDLING,
  REMINDER_NOTIFICATION_CONFIG_V1_NAME,
  REMINDER_NOTIFICATION_CONFIG_V1_REMINDERS_ACTION,
  REMINDER_NOTIFICATION_CONFIG_V1_REMINDERS_ACTION_PAYLOAD,
  REMINDER_NOTIFICATION_CONFIG_V1_REMINDERS_ACTION_TYPE,
  REMINDER_NOTIFICATION_CONFIG_V1_REMINDERS_NAME,
  REMINDER_NOTIFICATION_CONFIG_V1_REMINDERS_NOTIFICATION_ID,
  REMINDER_NOTIFICATION_CONFIG_V1_REMINDERS_OFFSET,
  REMINDER_NOTIFICATION_CONFIG_V1_REMINDERS_OFFSET_UNIT,
  REMINDER_NOTIFICATION_CONFIG_V1_REMINDERS_OFFSET_VALUE,
  REMINDER_NOTIFICATION_CONFIG_V1_REMINDERS_TRIGGER_ACTION,
} from '../model/notification-config/ReminderNotificationConfigMeta';
import { ReminderNotificationTriggerActionType } from '../model/notification-config/ReminderNotificationTriggerActionType';

const toNotificationConfigFromMetaV1 = (meta: Maybe<ObjectType>): Maybe<ReminderNotificationConfigMeta> => {
  const notificationConfigMeta = meta ? (meta[REMINDER_NOTIFICATION_CONFIG_V1_NAME] as ObjectType) : undefined;
  if (!notificationConfigMeta) {
    return undefined;
  }

  const notificationReminders = notificationConfigMeta[REMINDER_NOTIFICATION_CONFIG_V1_REMINDERS_NAME] as ObjectType[];
  if (!notificationReminders) {
    throw new Error(
      `Not a valid ReminderNotificationConfigMeta, missing: ${REMINDER_NOTIFICATION_CONFIG_V1_REMINDERS_NAME}`,
    );
  }

  const reminders: ReminderNotification[] = notificationReminders.map(reminder => {
    const action = reminder[REMINDER_NOTIFICATION_CONFIG_V1_REMINDERS_ACTION] as ObjectType;
    if (!action) {
      throw new Error(
        `Not a valid ReminderNotificationConfigMeta, missing: ${REMINDER_NOTIFICATION_CONFIG_V1_REMINDERS_ACTION} in reminder`,
      );
    }
    const payload = action[REMINDER_NOTIFICATION_CONFIG_V1_REMINDERS_ACTION_PAYLOAD] as ObjectType | undefined;
    const type = action[REMINDER_NOTIFICATION_CONFIG_V1_REMINDERS_ACTION_TYPE] as string;
    if (!type) {
      throw new Error(
        `Not a valid ReminderNotificationConfigMeta, missing: ${REMINDER_NOTIFICATION_CONFIG_V1_REMINDERS_ACTION_TYPE} in reminder action`,
      );
    }
    const notificationId = reminder[REMINDER_NOTIFICATION_CONFIG_V1_REMINDERS_NOTIFICATION_ID] as string;
    if (!notificationId) {
      throw new Error(
        `Not a valid ReminderNotificationConfigMeta, missing: ${REMINDER_NOTIFICATION_CONFIG_V1_REMINDERS_NOTIFICATION_ID} in reminder`,
      );
    }
    const offset = reminder[REMINDER_NOTIFICATION_CONFIG_V1_REMINDERS_OFFSET] as ObjectType;
    if (!offset) {
      throw new Error(
        `Not a valid ReminderNotificationConfigMeta, missing: ${REMINDER_NOTIFICATION_CONFIG_V1_REMINDERS_OFFSET} in reminder`,
      );
    }
    const value = offset[REMINDER_NOTIFICATION_CONFIG_V1_REMINDERS_OFFSET_VALUE] as number;
    if (value == undefined) {
      throw new Error(
        `Not a valid ReminderNotificationConfigMeta, missing: ${REMINDER_NOTIFICATION_CONFIG_V1_REMINDERS_OFFSET_VALUE} in reminder offset`,
      );
    }
    const offsetUnitString = offset[REMINDER_NOTIFICATION_CONFIG_V1_REMINDERS_OFFSET_UNIT] as string;
    if (!offsetUnitString) {
      throw new Error(
        `Not a valid ReminderNotificationConfigMeta, missing: ${REMINDER_NOTIFICATION_CONFIG_V1_REMINDERS_OFFSET_UNIT} in reminder offset`,
      );
    }
    const unit = NotificationUnitOfTime[offsetUnitString as keyof typeof NotificationUnitOfTime];
    if (!unit) {
      throw new Error(
        `Not a valid ReminderNotificationConfigMeta, ${REMINDER_NOTIFICATION_CONFIG_V1_REMINDERS_OFFSET_UNIT} has unsupported value: ${offsetUnitString}`,
      );
    }
    const triggerActionString = reminder[REMINDER_NOTIFICATION_CONFIG_V1_REMINDERS_TRIGGER_ACTION] as string;
    if (!triggerActionString) {
      throw new Error(
        `Not a valid ReminderNotificationConfigMeta, missing: ${REMINDER_NOTIFICATION_CONFIG_V1_REMINDERS_TRIGGER_ACTION} in reminder`,
      );
    }
    const triggerAction =
      ReminderNotificationTriggerActionType[triggerActionString as keyof typeof ReminderNotificationTriggerActionType];
    if (!triggerAction) {
      throw new Error(
        `Not a valid ReminderNotificationConfigMeta, ${REMINDER_NOTIFICATION_CONFIG_V1_REMINDERS_TRIGGER_ACTION} has unsupported value: ${triggerActionString}`,
      );
    }

    const allowBundling = reminder[REMINDER_NOTIFICATION_CONFIG_V1_ALLOW_BUNDLING] as boolean;

    const notificationConfig: ReminderNotification = {
      action: {
        type,
      },
      notificationId,
      offset: {
        value,
        unit,
      },
      triggerAction,
      allowBundling,
    };
    if (payload) {
      notificationConfig.action.payload = payload;
    }

    return notificationConfig;
  });

  return {
    reminders,
  };
};

export const ReminderNotificationMetaMapper = {
  toNotificationConfigFromMetaV1,
};
