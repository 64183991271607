/* eslint-disable */
export const UnifyWebOnboardingSchema = {
  title: 'UnifyWebOnboarding',
  type: 'object',
  properties: {
    title: { type: 'string' },
    consent: {
      title: 'Consent',
      type: 'object',
      properties: {
        viewConsentPageSubHeader: {
          title: 'View consent - page sub header',
          type: 'string',
          helper: 'Will be shown instead of the default sub header when the user is viewing the consent',
          placeholder: 'Accepted version',
          mock: 'Accepted version',
        },
      },
    },
    eula: {
      title: 'EULA Screen',
      type: 'object',
      properties: {
        updatedConsentPageHeader: {
          title: 'Reconsent - page header',
          type: 'string',
          helper: 'Will be shown instead of the default header when the user needs to reconsent to the EULA',
          placeholder: 'We have updated our End-user license agreement (EULA)',
          mock: 'We have updated our End-user license agreement (EULA)',
        },
        agreementWarning: {
          title: 'Agreement warning',
          type: 'string',
          helper: 'Prompt text for user that they need to read and accept EULA to continue',
          placeholder: 'Please read and agree to our EULA. This step is required to use our services',
          mock: 'Please read and agree to our EULA. This step is required to use our services',
        },
        declineButton: {
          title: 'Decline button',
          type: 'string',
          helper: 'Decline',
          placeholder: 'Decline',
          mock: 'Decline',
        },
        declineButtonAccessibilityLabel: {
          title: 'Decline button accessibility label',
          type: 'string',
          helper: 'Decline End-user license agreement',
          placeholder: 'Decline End-user license agreement',
          mock: 'Decline End-user license agreement',
        },
        acceptButton: {
          title: 'Accept button',
          type: 'string',
          helper: 'Accept',
          placeholder: 'Accept',
          mock: 'Accept',
        },
        acceptButtonAccessibilityLabel: {
          title: 'Accept button accessibility label',
          type: 'string',
          helper: 'Accept End-user license agreement',
          placeholder: 'Accept End-user license agreement',
          mock: 'Accept End-user license agreement',
        },
        error: {
          title: 'Error',
          type: 'string',
          helper: 'An unknown error occurred when accepting the EULA, please try again',
          placeholder: 'An unknown error occurred when accepting the EULA, please try again',
          mock: 'An unknown error occurred when accepting the EULA, please try again',
        },
      },
    },
    privacyPolicy: {
      title: 'Privacy Policy Screen',
      type: 'object',
      properties: {
        updatedConsentPageHeader: {
          title: 'Reconsent - page header',
          type: 'string',
          helper: 'Will be shown instead of the default header when the user needs to reconsent to the Privacy Policy',
          placeholder: 'We have updated our Privacy Policy',
          mock: 'We have updated our Privacy Policy',
        },
        agreementWarning: {
          title: 'Agreement warning',
          type: 'string',
          helper: 'Prompt text for user that they need to read and accept Privacy Policy to continue',
          placeholder: 'Please read and agree to our Privacy Policy. This step is required to use our services',
          mock: 'Please read and agree to our Privacy Policy. This step is required to use our services',
        },
        declineButton: {
          title: 'Decline button',
          type: 'string',
          helper: 'Decline',
          placeholder: 'Decline',
          mock: 'Decline',
        },
        declineButtonAccessibilityLabel: {
          title: 'Decline button accessibility label',
          type: 'string',
          helper: 'Decline to our Privacy Policy',
          placeholder: 'Decline to our Privacy Policy',
          mock: 'Decline to our Privacy Policy',
        },
        acceptButton: {
          title: 'Accept button',
          type: 'string',
          helper: 'Accept',
          placeholder: 'Accept',
          mock: 'Accept',
        },
        acceptButtonAccessibilityLabel: {
          title: 'Accept button accessibility label',
          type: 'string',
          helper: 'Accept our Privacy Policy',
          placeholder: 'Accept our Privacy Policy',
          mock: 'Accept our Privacy Policy',
        },
        error: {
          title: 'Error',
          type: 'string',
          helper: 'An unknown error occurred when accepting Privacy Policy, please try again',
          placeholder: 'An unknown error occurred when accepting Privacy Policy, please try again',
          mock: 'An unknown error occurred when accepting Privacy Policy, please try again',
        },
      },
    },
    closeoutTracker: {
      title: 'Close Out Tracker',
      type: 'object',
      properties: {
        modalHeader: {
          title: 'Header for modal',
          type: 'string',
          helper: 'Header text for the modal that is shown when the Close Out Tracker is live',
          placeholder: 'Close Out Tracker is now live',
          mock: 'Close Out Tracker is now live',
        },
        modalDescription: {
          title: 'Description for modal',
          type: 'string',
          helper:
            'Closeout Tracker is now available under the patient tab. Site team users should start booking Study Closure Visit (SCV) with patients.',
          placeholder:
            'Closeout Tracker is now available under the patient tab. Site team users should start booking Study Closure Visit (SCV) with patients.',
          mock: 'Closeout Tracker is now available under the patient tab. Site team users should start booking Study Closure Visit (SCV) with patients.',
        },
        confirmButtonLabel: {
          title: 'Label for confirm button',
          type: 'string',
          helper: 'Ok',
          placeholder: 'Ok',
          mock: 'Ok',
        },
      },
    },
  },
  description: 'unify:webonboarding',
  $schema: 'http://json-schema.org/draft-07/schema#',
};
