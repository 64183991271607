export enum CognitoExceptionType {
  CodeMismatchException = 'CodeMismatchException',
  ExpiredCodeException = 'ExpiredCodeException',
  InvalidPasswordException = 'InvalidPasswordException',
  LimitExceededException = 'LimitExceededException',
  NotAuthorizedException = 'NotAuthorizedException',
  TooManyFailedAttemptsException = 'TooManyFailedAttemptsException',
  TooManyRequestsException = 'TooManyRequestsException',
  UserNotConfirmedException = 'UserNotConfirmedException',
  UserNotFoundException = 'UserNotFoundException',
  NetworkError = 'NetworkError',
}
