/* eslint-disable */
export const UnifyAppVideoTrainingConsentDocumentMock = {
  title: 'title',
  consentType: 'consentType',
  version: '[M]1.0',
  context: [],
  countryCodes: [],
  header: '[M]Training title',
  subHeader: '[M]Training sub header',
  video: { video: { url: 'https://via.placeholder.com/300' }, coverImage: { url: 'https://via.placeholder.com/300' } },
  consentCheckboxAccessibilityLabel: '[M]Confirmation of training performed',
  consentCheckboxLabel: "[M]I've performed training for ...",
  category: 'APP',
};
