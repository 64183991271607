/* eslint-disable */
export const UnifyWebPatientHealthEventStudyMock = {
  title: 'title',
  patientHealthEvent: {
    viewHealthEvent: {
      text: {
        information: [
          {
            header: 'Study Definition of Primary Endpoint',
            bodyMd: 'Health event is defined as hospitalisation for heart failure.',
          },
          {
            header: 'Guidance Information',
            bodyMd:
              'If this is a reportable event according to the Clinical Study Protocol please complete the report in the eCRF. Please complete the Transmittal form and upload the source document to the Ethical portal for adjudication.',
          },
          {
            header: 'How to Follow Up',
            bodyMd:
              'Please book an un-scheduled visit (phone call or on-site visit) with the patient to follow up on the reported health event if needed.',
          },
        ],
      },
    },
  },
};
