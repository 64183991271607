import { UnitOfTime } from '../model/schedulingModels/UnitOfTime';
import { Duration } from '../model/schedulingModels/Duration';
import { Repeat } from '../model/schedulingModels/Repeat';
import { DayOfWeek } from '../model/DayOfWeek';

const DEFAULT_ACTION_OFFSET: Duration = {
  value: 0,
  unit: UnitOfTime.S,
};

const DEFAULT_DAYS_OF_WEEK: DayOfWeek[] = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

export class SchedulingConst {
  static DEFAULT_ACTION_OFFSET = DEFAULT_ACTION_OFFSET;
  static DEFAULT_DAYS_OF_WEEK = DEFAULT_DAYS_OF_WEEK;
}

/**
 * These are the properties of repeat that are currently handled by
 * @see {SchedulingService}, or does not affect the request window intervals.
 * The rest are either still unsupported or unknown if they have any affect on a
 * schedules rollout.
 */
export const SUPPORTED_REPEAT_PROPS: (keyof Repeat)[] = [
  'daysOfWeek',
  'duration',
  'durationUnit',
  'frequency',
  'frequencyMax',
  'period',
  'periodUnit',
  'timesOfDay',
];
