import { TypographyOptions } from '@mui/material/styles/createTypography';
const lexiaDaMa = `Lexia DaMa, Helvetica Neue, Arial, sans-serif`;
const helvetica = `Helvetica Neue, Arial, sans-serif`;

// 1rem = 16px
export const typography: TypographyOptions = {
  fontFamily: 'Helvetica Neue',
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontFamily: lexiaDaMa,
    fontWeight: 400,
    fontSize: '3rem',
    lineHeight: 1.25,
    letterSpacing: '0px',
  },
  h2: {
    fontFamily: helvetica,
    fontWeight: 500,
    fontSize: '2.25rem',
    lineHeight: 1.167,
    letterSpacing: '0px',
  },
  h3: {
    fontFamily: lexiaDaMa,
    fontWeight: 400,
    fontSize: '2rem',
    lineHeight: 1.167,
    letterSpacing: '0px',
  },
  h4: {
    fontFamily: lexiaDaMa,
    fontWeight: 400,
    fontSize: '2rem',
    lineHeight: 1.255,
    letterSpacing: '0.0315em',
  },
  h5: {
    fontFamily: helvetica,
    fontWeight: 700,
    fontSize: '1.5rem',
    lineHeight: 1.5,
    letterSpacing: '0.0315em',
  },
  h6: {
    fontFamily: lexiaDaMa,
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.625,
    letterSpacing: '0.0315em',
  },
  subtitle1: {
    fontFamily: helvetica,
    fontWeight: 400,
    fontSize: '1.5rem',
    lineHeight: 1.35,
    letterSpacing: '0.0315em',
  },
  subtitle2: {
    fontFamily: helvetica,
    fontWeight: 400,
    fontSize: '1.25rem',
    lineHeight: 1.625,
    letterSpacing: '0.0315em',
  },
  body1: {
    fontFamily: helvetica,
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.45,
    letterSpacing: '0.0315em',
  },
  body2: {
    fontFamily: helvetica,
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: 1.45,
    letterSpacing: '0.0315em', //0.5px
  },
  caption: {
    fontFamily: helvetica,
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: 1.45,
    letterSpacing: '0.0315em',
  },
  button: {
    fontFamily: helvetica,
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: 1.167,
    letterSpacing: '0.0315em',
    textTransform: 'uppercase',
  },
  overline: {
    fontFamily: helvetica,
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 1.5,
    letterSpacing: '0.0315em',
  },
};
