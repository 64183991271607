import { zeroZonedDateTime } from '@csp/csp-common-date-util';
import {
  CloseoutTrackerStatusType,
  CustomStatuses,
  StudyOrgStatus,
  StudyOrgStatuses,
  StudyOrgStatusType,
} from '@csp/csp-common-model';

const toStudyOrgStatus = <T>(
  type: StudyOrgStatusType,
  customStatuses: CustomStatuses,
  defaultStatus: T,
): StudyOrgStatus<T> => {
  const customStatus = customStatuses.getByType(type);
  const status = (customStatus?.value as unknown as T) ?? defaultStatus;
  const timestamp = customStatus?.timestamp ?? zeroZonedDateTime;

  return {
    status,
    timestamp,
  };
};

const toStudyOrgStatuses = (customStatuses: CustomStatuses): StudyOrgStatuses => ({
  closeoutTrackerStatus: toStudyOrgStatus<CloseoutTrackerStatusType>(
    StudyOrgStatusType.STUDY_CLOSE_OUT_PERIOD,
    customStatuses,
    CloseoutTrackerStatusType.INACTIVE,
  ),
});

export const StudyStatusMapper = {
  toStudyOrgStatus,
  toStudyOrgStatuses,
};
