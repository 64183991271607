import type { Property } from 'csstype';
import removeMd from 'remove-markdown';
import { TEXT_JUSTIFY_MARKER } from './constants';

// ***groupToMatch***
const regex = /\*\*\*([^*]*)\*\*\*/;

/**
 * Removes '***' formatting from markdown string
 * so 'Some ***text*** here' => 'Some text here'
 * @param mdStr - markdown string to clean
 */
const cleanTripleStars = (mdStr: string): string => {
  let str = '';
  let curr = mdStr;
  let match;
  while ((match = regex.exec(curr)) !== null && match[0]) {
    const matchStr = match[0];
    const beforeMatch = curr.substring(0, match.index);
    str += beforeMatch;
    const matchWithoutMarkdownFormatting = match[1];
    str += matchWithoutMarkdownFormatting;
    // Set curr to everything after match
    curr = curr.substring(match.index + matchStr.length);
  }
  if (curr.length > 0) {
    str += curr;
  }
  return str;
};

/**
 * Removes double plus '++' sign at the beginning, at the end
 *  or at beginning and at the end of a string.
 *  '++doublePlusWord++' => 'doublePlusWord'
 *  and returns alignment that should be applied to markdown
 * @param mdStr - markdown string to check and remove aligment markers from
 * @param defaultAlignment - default alignment to return if no alignment markers are detected
 */
export const checkAndClearJustificationMarkers = (
  mdStr: string,
  defaultAlignment: Property.TextAlign = 'left',
): { mdStr: string; alignment: Property.TextAlign } => {
  let alignment = defaultAlignment;
  if (mdStr.startsWith(TEXT_JUSTIFY_MARKER)) {
    mdStr = mdStr.slice(TEXT_JUSTIFY_MARKER.length);
    if (mdStr.endsWith(TEXT_JUSTIFY_MARKER)) {
      mdStr = mdStr.slice(0, -TEXT_JUSTIFY_MARKER.length);
      alignment = 'center';
    } else {
      alignment = 'left';
    }
  } else if (mdStr.endsWith(TEXT_JUSTIFY_MARKER)) {
    mdStr = mdStr.slice(0, -TEXT_JUSTIFY_MARKER.length);
    alignment = 'right';
  }

  return { mdStr, alignment };
};

/**
 * Removes markdown formatting from a string returning plaintext
 * @param mdStr - markdown string to remove formatting from
 */
export const cleanMarkdown = (mdStr: string): string => {
  const str = cleanTripleStars(mdStr);
  const resultStr = checkAndClearJustificationMarkers(str).mdStr;
  return removeMd(resultStr);
};
