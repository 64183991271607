import { CmsHookResult, useStudySpecificCmsQuery } from '@csp/common';
import { isDefined } from '@csp/csp-common-util';
import {
  UnifyWebVideoTrainingConsentDocument,
  UnifyWebVideoTrainingConsentDocumentSchema,
} from '@csp/csp-content-schema';
import { ConsentType } from '@csp/dmdp-api-user-dto';
import { toHookConfig } from '../../util/toHookConfig';

export const useCmsWebVideoTrainingConsentDocument = (
  consentType?: ConsentType,
  version?: string,
): CmsHookResult<UnifyWebVideoTrainingConsentDocument> => {
  const customProperties = [
    consentType ? { key: 'consentType', value: consentType } : undefined,
    version ? { key: 'version', value: version } : undefined,
  ];

  return useStudySpecificCmsQuery<UnifyWebVideoTrainingConsentDocument>(
    'unify:webvideotrainingconsentdocument',
    toHookConfig({
      customProperties: customProperties.filter(isDefined),
      returnAllRows: false,
    }),
    UnifyWebVideoTrainingConsentDocumentSchema,
  );
};
