/**
 * Util type for manage error information
 */
import { isError, isObject } from 'lodash';
import { CspErrorType } from './CspErrorType';

export type ErrorInfo = {
  /**
   * Dmdp error code
   */
  code?: string;

  /**
   * Dmdp error Id
   */
  id?: string;

  /**
   * Dmdp error Id formatted
   */
  displayId?: string;

  /**
   * Error type
   */
  type?: CspErrorType;

  /**
   * User readable message
   */
  message: string;

  /**
   * Unknown error as  Error
   */
  reason?: Error;

  /**
   * Origin error message
   */
  reasonMessage?: string;

  /**
   * Origin error item
   */
  origin: unknown;
};

type WithErrorProps = {
  message?: string;
  type?: CspErrorType; // CspError
  errorCode?: string; // DmdpError
  errorId?: string; // DmdpError
  displayErrorId?: string; // DmdpError
};

const getReasonMessage = (error: unknown): string => {
  if (isError(error)) {
    return error.message;
  } else if (isObject(error)) {
    return JSON.stringify(error);
  } else {
    return String(error);
  }
};

export const toErrorInfo = ({ message = '', error }: { message?: string; error?: unknown }): ErrorInfo => {
  const reason = isError(error) ? error : new Error(String(error));
  const errWithProps = error as WithErrorProps;

  return {
    message,
    reason,
    reasonMessage: getReasonMessage(error),
    code: errWithProps?.errorCode,
    id: errWithProps?.errorId,
    displayId: errWithProps?.displayErrorId,
    type: errWithProps?.type,
    origin: error,
  };
};
