import { ApiOptions, Maybe, OrgId } from '@csp/csp-common-model';
import {
  findScheduleVersionsActiveNow,
  OrgSchedules,
  OrgSchedulesMapper,
  OrgsSchedules,
  RequestRef,
  ScheduleCachedService,
  ScheduleVersionRequestRef,
} from '@csp/csp-common-scheduling';
import { toRestOptions } from '@csp/csp-fe-auth';
import { AppointmentRestServiceV1 } from '@csp/dmdp-api-client';
import { StudyConfigService } from '@csp/csp-common-config';
import { VisitRequest } from '../model/VisitRequest';
import { VisitSchedule } from '../model/VisitSchedule';

const getDefaultVisitSchedule = async (apiOptions?: ApiOptions): Promise<VisitSchedule> => {
  /**
   * This is a client side migration of unscheduled visit config that
   * has been put in patient config. This code can be removed when all studies have migrated to
   * configuring the unscheduled visit as a schedule request.
   */
  const studyConfig = await StudyConfigService.fetchStudyConfig(apiOptions);
  return VisitSchedule.toDefaultVisitSchedule(studyConfig.visitConfig.supportedUnscheduledVisitLocationTypes);
};

const getVisitSchedules = async (orgId: string, apiOptions?: ApiOptions): Promise<VisitSchedule[]> => {
  const { activeSchedules = [] } = await AppointmentRestServiceV1.getActiveSchedulesByOrgId(
    toRestOptions(apiOptions),
    orgId,
  );

  return activeSchedules.map(VisitSchedule.from);
};

const getActiveRequestsByOrgId = async (orgId: string, apiOptions?: ApiOptions): Promise<VisitRequest[]> => {
  const visitSchedules = await getVisitSchedules(orgId, apiOptions);
  const defaultSchedule = await getDefaultVisitSchedule(apiOptions);
  const allSchedules = [...visitSchedules, defaultSchedule];
  return findScheduleVersionsActiveNow(allSchedules).flatMap(({ requests }) => requests);
};

const getCachedActiveRequestsByOrgId = async (orgId: string, apiOptions?: ApiOptions): Promise<VisitRequest[]> => {
  const orgSchedules = await getCachedActivatedVisitSchedulesForOrganizationIds([orgId], apiOptions);
  return orgSchedules.getActiveRequests();
};

const getActivatedVisitSchedulesForOrganizationIds = async (
  organizationIds: OrgId[],
  apiOptions?: ApiOptions,
): Promise<OrgsSchedules<VisitSchedule, VisitRequest>> => {
  const activeSchedulesQueryPromise = AppointmentRestServiceV1.queryActiveSchedulesByOrganizationIds(
    toRestOptions(apiOptions),
    { orgIds: organizationIds },
  );
  const defaultSchedulePromise = getDefaultVisitSchedule(apiOptions);

  const [appointmentActiveScheduleOrganizationsV1, defaultSchedule] = await Promise.all([
    activeSchedulesQueryPromise,
    defaultSchedulePromise,
  ]);

  const orgsSchedules = OrgsSchedules.from<VisitSchedule, VisitRequest>(
    OrgSchedulesMapper.fromV1s(appointmentActiveScheduleOrganizationsV1, VisitSchedule.from),
  );

  const orgSchedulesWithDefault = orgsSchedules.orgSchedules.map(
    (orgSchedule): OrgSchedules<VisitSchedule> => ({
      schedules: [...orgSchedule.schedules, defaultSchedule],
      orgId: orgSchedule.orgId,
    }),
  );
  return OrgsSchedules.from<VisitSchedule, VisitRequest>(orgSchedulesWithDefault);
};

const getCachedActivatedVisitSchedulesForOrganizationIds = async (
  organizationIds: OrgId[],
  apiOptions?: ApiOptions,
): Promise<OrgsSchedules<VisitSchedule, VisitRequest>> =>
  ScheduleCachedService.getAllActivatedSchedulesForOrganizationIds(
    'appointment',
    organizationIds,
    getActivatedVisitSchedulesForOrganizationIds,
    apiOptions,
  );

const getCachedActivatedRequestsByOrgIds = async (
  orgId: string[],
  apiOptions?: ApiOptions,
): Promise<VisitRequest[]> => {
  const getCachedVisitSchedules = await getCachedActivatedVisitSchedulesForOrganizationIds(orgId, apiOptions);

  return getCachedVisitSchedules.getAllRequests();
};

const getRequestByRequestRef = async (
  orgId: string,
  requestRef: RequestRef,
  apiOptions?: ApiOptions,
): Promise<Maybe<VisitRequest>> => {
  const visitRequests = await getCachedActivatedRequestsByOrgIds([orgId], apiOptions);
  return visitRequests.find(req => ScheduleVersionRequestRef.isSameRequestRef(req, requestRef));
};

export const VisitScheduleService = {
  getActiveRequestsByOrgId,
  getCachedActiveRequestsByOrgId,
  getCachedActivatedRequestsByOrgIds,
  getCachedActivatedVisitSchedulesForOrganizationIds,
  getRequestByRequestRef,
};
