import { fromTimezoneStrOrUndefined } from '@csp/csp-common-date-util';
import { CountValue, Maybe, MetricInfo, ZonedDateTime } from '@csp/csp-common-model';
import { ScheduleRef } from '@csp/csp-common-scheduling';
import {
  DeviceSessionAggregateGroupResultFieldsV1,
  DeviceSessionAggregateGroupResultV1,
  DeviceSessionAggregateResultV1,
} from '@csp/dmdp-api-device-dto';
import { ScheduleRequestMetricCriterion } from '../../model/ScheduleRequestMetricCriterion';
import { ScheduleRequestMetricInfo } from '../../model/ScheduleRequestMetricInfo';
import { DeviceScheduleRequestStatusType } from '../model/DeviceScheduleRequestStatusTypes';
import { DeviceSessionStatus } from '../model/DeviceSessionStatus';

const matchRequestRefAndStatus = (
  scheduleRef: ScheduleRef,
  fields: DeviceSessionAggregateGroupResultFieldsV1,
  status?: DeviceScheduleRequestStatusType,
): boolean => ScheduleRef.matchesRequestRef(scheduleRef, fields) && fields.scheduleRequestStatus === status;

const getLastSync = (
  lastSyncScheduled: Maybe<ZonedDateTime>,
  lastSyncMissed: Maybe<ZonedDateTime>,
): Maybe<ZonedDateTime> => {
  if (lastSyncScheduled && lastSyncMissed) {
    return lastSyncScheduled.unixTimeMillis > lastSyncMissed.unixTimeMillis ? lastSyncScheduled : lastSyncMissed;
  } else {
    return lastSyncScheduled ?? lastSyncMissed;
  }
};

const findGroupByStatus = (
  groups: DeviceSessionAggregateGroupResultV1[],
  scheduleRequestStatus: DeviceScheduleRequestStatusType,
  sessionStatus?: DeviceSessionStatus,
): Maybe<DeviceSessionAggregateGroupResultV1> =>
  groups.find(group =>
    sessionStatus
      ? group.fields.scheduleRequestStatus === scheduleRequestStatus && group.fields.sessionStatus === sessionStatus
      : group.fields.scheduleRequestStatus === scheduleRequestStatus,
  );

const toScheduleRequestMetricInfos = (
  criteria: ScheduleRequestMetricCriterion[],
  totalResultV1s: DeviceSessionAggregateGroupResultV1[],
  recentResultV1s: DeviceSessionAggregateGroupResultV1[],
): ScheduleRequestMetricInfo[] =>
  criteria.map(({ scheduleRef, recentPeriod, minComplianceThreshold }) => {
    const totalResultScheduled = totalResultV1s.find(({ fields }) =>
      matchRequestRefAndStatus(scheduleRef, fields, DeviceScheduleRequestStatusType.SCHEDULED),
    );

    const totalResultMissed = totalResultV1s.find(({ fields }) =>
      matchRequestRefAndStatus(scheduleRef, fields, DeviceScheduleRequestStatusType.MISSED),
    );

    const recentResultV1Scheduled = recentPeriod
      ? recentResultV1s.find(({ fields }) =>
          matchRequestRefAndStatus(scheduleRef, fields, DeviceScheduleRequestStatusType.SCHEDULED),
        )
      : undefined;

    const recentResultV1Missed = recentPeriod
      ? recentResultV1s.find(({ fields }) =>
          matchRequestRefAndStatus(scheduleRef, fields, DeviceScheduleRequestStatusType.MISSED),
        )
      : undefined;

    const lastSyncScheduled = fromTimezoneStrOrUndefined(totalResultScheduled?.maxSessionTimestamp);
    const lastSyncMissed = fromTimezoneStrOrUndefined(totalResultMissed?.maxSessionTimestamp);

    return {
      scheduleRef,
      metricInfo: MetricInfo.from({
        totalActual: totalResultScheduled?.count ?? 0,
        totalExpected: (totalResultScheduled?.count ?? 0) + (totalResultMissed?.count ?? 0),
        recentActual: recentResultV1Scheduled?.count ?? 0,
        recentExpected: (recentResultV1Scheduled?.count ?? 0) + (recentResultV1Missed?.count ?? 0),
        recentPeriod,
        threshold: minComplianceThreshold,
        lastSync: getLastSync(lastSyncScheduled, lastSyncMissed),
      }),
    };
  });

const toCountValue = (result: DeviceSessionAggregateResultV1): CountValue => {
  const completedCount =
    findGroupByStatus(result.groups, DeviceScheduleRequestStatusType.SCHEDULED, DeviceSessionStatus.COMPLETE)?.count ??
    0;
  const missedCount = findGroupByStatus(result.groups, DeviceScheduleRequestStatusType.MISSED)?.count ?? 0;
  const totalCount = completedCount + missedCount;

  return CountValue.from(totalCount, completedCount, missedCount);
};

export const DeviceSessionAggregateResultMapperV1 = {
  toScheduleRequestMetricInfos,
  toCountValue,
};
