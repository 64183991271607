import { CspError } from '@csp/csp-common-model';
import { DmdpToken } from '@csp/dmdp-api-user-dto';
import { createListener } from '@csp/csp-common-axios';
import { JwtRestServiceV1 } from '@csp/dmdp-api-client';
import { axiosIdp } from './AxiosIdpService';

const state: { dmdpToken?: DmdpToken; pending?: boolean; enabled: boolean } = { enabled: true };

const setListeners = createListener<void>('DmdpTokenService.onSet');
const clearListeners = createListener<void>('DmdpTokenService.onClear');

const setToken = (token: DmdpToken): void => {
  state.dmdpToken = token;
  setListeners.trigger();
};

const getToken = (): DmdpToken | undefined => {
  if (state.enabled) {
    return state.dmdpToken;
  } else {
    // If you come here from debugging, did you forget propagate apiOptions to the rest service?
    throw CspError.unhandledClientError('Attempted to get token from disabled token service.');
  }
};

const clearToken = (): void => {
  state.dmdpToken = undefined;
  clearListeners.trigger();
};

const getTokenAsync = async (): Promise<DmdpToken> =>
  getToken() ||
  (await new Promise<DmdpToken>((resolve, reject) => {
    const listener = (): void => {
      setListeners.delete(listener);
      const token = getToken();
      token ? resolve(token) : reject(new Error('DmdpToken is undefined'));
    };
    setListeners.add(listener);
  }));

async function getTokenForTenantId(tenantId: string, ttlSecs: number | undefined): Promise<DmdpToken> {
  return await JwtRestServiceV1.issueDmdpTokenByIdpToken({ axios: axiosIdp() }, tenantId, ttlSecs);
}

/** Will issue DMDP token */
const login = async (tenantId: string, ttlSecs?: number): Promise<DmdpToken> => {
  const newDmdpToken: DmdpToken = await getTokenForTenantId(tenantId, ttlSecs);
  setToken(newDmdpToken);
  return newDmdpToken;
};

const reLogin = async (ttlSecs?: number): Promise<DmdpToken> => {
  const tId = state.dmdpToken?.getTenantId();
  if (tId) {
    return await login(tId, ttlSecs);
  } else {
    throw CspError.authenticationFailed();
  }
};

/**
 * Opt-out of usage. Will throw error if anyone tries
 * to get token. Can be called by backend apps that
 * should not rely on this FE lib.
 */
const disableService = (): void => {
  state.enabled = false;
};

export const DmdpTokenService = {
  getToken,
  setToken,
  getTokenAsync,
  clearToken,
  login,
  reLogin,
  getTokenForTenantId,
  disableService,
  addOnSetListener: setListeners.add,
  addOnClearListener: clearListeners.add,
  removeOnSetListener: setListeners.delete,
  removeOnClearListener: clearListeners.delete,
};
