import { Site, Study, User } from '@csp/csp-common-model';
import { CaseReducer, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { clearSelectedStudyId } from '../../studies/ducks/studiesActions';
import { Principal } from '../model/Principal';
import { PrincipalContextState } from '../model/PrincipalContextState';
import {
  loginPrincipalThunk,
  refreshSitesThunk,
  refreshStudyThunk,
  refreshUserThunk,
  signOutPrincipalThunk,
} from './principalContextActions';

const initialState: PrincipalContextState = {
  principal: null,
  err: null,
};

const updatePrincipalReducer: CaseReducer<PrincipalContextState, PayloadAction<Principal>> = (draft, { payload }) => {
  draft.principal = payload;
  draft.err = null;
};

const updateStudyReducer: CaseReducer<PrincipalContextState, PayloadAction<Study>> = (draft, { payload }) => {
  if (draft.principal) {
    draft.principal.study = payload;
    draft.err = null;
  }
};

const fulfilledSignOutReducer: CaseReducer<PrincipalContextState> = draft => {
  draft.principal = null;
  draft.err = null;
};

export const updateUserReducer: CaseReducer<PrincipalContextState, PayloadAction<User>> = (draft, { payload }) => {
  if (draft.principal) {
    draft.principal.user = payload;
    draft.err = null;
  }
};

export const updateSitesReducer: CaseReducer<PrincipalContextState, PayloadAction<Site[]>> = (draft, { payload }) => {
  if (draft.principal?.study.sites) {
    draft.principal.study.sites = payload;
    draft.err = null;
  }
};

type RejectedAction =
  | ReturnType<typeof loginPrincipalThunk.rejected>
  | ReturnType<typeof refreshSitesThunk.rejected>
  | ReturnType<typeof refreshUserThunk.rejected>
  | ReturnType<typeof refreshStudyThunk.rejected>;

const rejectedReducer: CaseReducer<PrincipalContextState, RejectedAction> = (draft, { payload }) => {
  draft.principal = null;
  draft.err = payload as Error;
};

const resetReducer: CaseReducer<PrincipalContextState> = draft => {
  draft.principal = null;
  draft.err = null;
};

const principalContextReducer = createReducer(initialState, builder =>
  builder
    .addCase(loginPrincipalThunk.fulfilled, updatePrincipalReducer)
    .addCase(loginPrincipalThunk.rejected, rejectedReducer)
    .addCase(refreshStudyThunk.fulfilled, updateStudyReducer)
    .addCase(refreshStudyThunk.rejected, rejectedReducer)
    .addCase(refreshSitesThunk.fulfilled, updateSitesReducer)
    .addCase(refreshSitesThunk.rejected, rejectedReducer)
    .addCase(refreshUserThunk.fulfilled, updateUserReducer)
    .addCase(refreshUserThunk.rejected, rejectedReducer)
    .addCase(signOutPrincipalThunk.fulfilled, fulfilledSignOutReducer)
    .addCase(signOutPrincipalThunk.rejected, rejectedReducer)
    .addCase(clearSelectedStudyId, resetReducer),
);

const persistConfig: PersistConfig<PrincipalContextState> = {
  key: 'principalContext',
  storage,
  whitelist: [],
};

export const principalContext = persistReducer(persistConfig, principalContextReducer);
