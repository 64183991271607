import { ApiOptions, Maybe, ObjectType } from '@csp/csp-common-model';
import { toRestOptions } from '@csp/csp-fe-auth';
import { ConsentRestServiceV1 } from '@csp/dmdp-api-client';
import { RoleType } from '@csp/dmdp-api-common-dto';
import {
  ConsentApprovalCriteriaFieldV1,
  ConsentApprovalIncludeFieldV1,
  ConsentApprovalInV1,
  ConsentApprovalQueryOperatorV1,
  ConsentApprovalQueryTypeV1,
  ConsentApprovalQueryV1,
  ConsentApprovalV1,
  ConsentArtifactCriteriaFieldV1,
  ConsentArtifactQueryTypeV1,
  ConsentArtifactV1,
  ConsentRequestOutcomeV1,
} from '@csp/dmdp-api-consent-dto';
import { ConsentType } from '@csp/dmdp-api-user-dto';
import { first } from 'lodash';
import { ConsentOutcomeAndArtifact } from '../model/ConsentOutcomeAndArtifact';
import { ConsentOutcomeWithArtifact } from '../model/ConsentOutcomesWithArtifacts';
import { CspConsentClientType } from '../type/CspConsentClientType';
import { mapUserRolesToVideoTrainingConsentTypes } from '../util/mapUserRolesToVideoTrainingConsentTypes';

type ConsentConfig = {
  clientType: CspConsentClientType;
};

type ConsentConfigObject = {
  consentConfig: ConsentConfig;
};

const defaultConsentConfig: ConsentConfigObject = { consentConfig: { clientType: CspConsentClientType.APP } };
const config: ConsentConfigObject = defaultConsentConfig;

const setConsentServiceConfig = (consentConfig: ConsentConfig): void => {
  config.consentConfig = consentConfig;
};

const getConsentServiceConfig = (): ConsentConfig => config.consentConfig;

const getConsentRequestOutcomes = async (
  consentType?: ConsentType,
  countryCode?: string,
  includeArtifacts?: boolean,
  apiOptions?: ApiOptions,
): Promise<ConsentRequestOutcomeV1[] | ConsentOutcomeWithArtifact[]> => {
  const clientType = getConsentServiceConfig().clientType;
  const { consentRequestOutcomes } = await ConsentRestServiceV1.getRequestOutcomesV1(toRestOptions(apiOptions));
  const matchingArtifacts: ConsentArtifactV1[] = [];

  if (!consentRequestOutcomes.length) {
    throw new Error('ConsentService.getConsentRequestOutcomes() => no active consent outcomes found for study');
  }

  const artifacts: ConsentArtifactV1[] = includeArtifacts
    ? (
        await ConsentRestServiceV1.queryArtifacts(toRestOptions(apiOptions), {
          criterion: {
            fieldCriterion: {
              field: ConsentArtifactCriteriaFieldV1.ARTIFACT_CODE,
              type: ConsentArtifactQueryTypeV1.IN,
              values: consentRequestOutcomes.map(outcome => outcome.artifactCode),
            },
          },
        })
      ).artifacts
    : [];

  type OutcomesFilterPredicate = (outcome: ConsentRequestOutcomeV1) => Promise<boolean>;

  const outcomesFilterPredicate: OutcomesFilterPredicate = async outcome => {
    const clientTypeMatch = outcome.artifactTags?.indexOf(clientType) !== -1;
    const consentTypeMatch = consentType ? outcome.artifactType === consentType : true;

    if (!countryCode) {
      if (includeArtifacts) {
        const artifact = artifacts.find(artifact => artifact.artifactCode === outcome.artifactCode);

        if (artifact) {
          matchingArtifacts.push(artifact);
        }
      }
      if (consentType && consentTypeMatch && clientTypeMatch) {
        return true;
      } else {
        return !consentType && clientTypeMatch;
      }
    }

    if (clientTypeMatch && consentTypeMatch) {
      const artifact = artifacts.find(artifact => artifact.artifactCode === outcome.artifactCode);
      const countryMatch = countryCode === artifact?.meta?.country;
      const skipCountryCheck = artifact?.meta?.country === 'ALL';
      const artifactMatch = countryMatch || skipCountryCheck;

      if (artifactMatch) {
        matchingArtifacts.push(artifact);
      }

      return artifactMatch;
    } else {
      return false;
    }
  };

  type OutcomesFilter = (
    arr: ConsentRequestOutcomeV1[],
    predicate: OutcomesFilterPredicate,
  ) => Promise<ConsentRequestOutcomeV1[]>;

  const outcomesFilter: OutcomesFilter = async (outcomes, predicate) =>
    outcomes.reduce(
      async (memo: Promise<ConsentRequestOutcomeV1[]>, e) => ((await predicate(e)) ? [...(await memo), e] : await memo),
      Promise.resolve([]),
    );

  const filteredOutcomes = await outcomesFilter(consentRequestOutcomes, outcomesFilterPredicate);

  if (!filteredOutcomes.length) {
    throw new Error(
      `ConsentService.getConsentRequestOutcomes() => no consent outcomes matched filter (${clientType}, ${consentType}, ${countryCode})`,
    );
  }

  if (includeArtifacts) {
    return filteredOutcomes.map(outcome => {
      const artifact: ConsentArtifactV1 | undefined = matchingArtifacts.find(
        artifact => artifact.artifactCode === outcome.artifactCode,
      );

      if (!artifact) {
        throw new Error(
          `ConsentService.getActiveConsents() => no matching artifact for outcome with artifactCode (${outcome.artifactCode})`,
        );
      }

      return { ...outcome, ...{ artifact } };
    });
  }

  return filteredOutcomes;
};

const getActiveConsentsV1 = async (
  consentType?: ConsentType,
  countryCode?: string,
  apiOptions?: ApiOptions,
): Promise<ConsentRequestOutcomeV1[]> => await getConsentRequestOutcomes(consentType, countryCode, false, apiOptions);

const getActiveOutcomesWithArtifacts = async (
  consentType?: ConsentType,
  countryCode?: string,
  apiOptions?: ApiOptions,
): Promise<ConsentOutcomeWithArtifact[]> => await getConsentRequestOutcomes(consentType, countryCode, true, apiOptions);

const getActiveConsentArtifactV1 = async (
  consentType: ConsentType,
  countryCode?: string,
  apiOptions?: ApiOptions,
): Promise<Maybe<ConsentArtifactV1>> => {
  const outcomes = await getActiveOutcomesWithArtifacts(consentType, countryCode, apiOptions);
  const outcome = first(outcomes);

  if (!outcome) {
    throw new Error('ConsentService.getActiveConsentArtifactV1() => no active consent outcome found for study');
  }

  return outcome.artifact;
};

const toPreviousApprovalsOfTypesQuery = (consentTypes: ConsentType[], userId: string): ConsentApprovalQueryV1 => ({
  fields: [
    ConsentApprovalIncludeFieldV1.USER_ID,
    ConsentApprovalIncludeFieldV1.ARTIFACT_CODE,
    ConsentApprovalIncludeFieldV1.ARTIFACT_TYPE,
  ],
  criterion: {
    operator: ConsentApprovalQueryOperatorV1.AND,
    criteria: [
      {
        fieldCriterion: {
          field: ConsentApprovalCriteriaFieldV1.ARTIFACT_TYPE,
          type: ConsentApprovalQueryTypeV1.IN,
          values: [...consentTypes],
        },
      },
      {
        fieldCriterion: {
          field: ConsentApprovalCriteriaFieldV1.USER_ID,
          type: ConsentApprovalQueryTypeV1.IN,
          values: [userId],
        },
      },
    ],
  },
});

const hasPreviousApprovalsOfType = async (
  consentType: ConsentType,
  userId: string,
  apiOptions?: ApiOptions,
): Promise<boolean> => {
  const query = toPreviousApprovalsOfTypesQuery([consentType], userId);
  const result = await ConsentRestServiceV1.queryApprovalsV1(toRestOptions(apiOptions), query);

  return result.approvals.some(approval => approval.artifactType === consentType);
};

const hasPreviousApprovalsOfTypes = async (
  consentTypes: ConsentType[],
  userId: string,
  apiOptions?: ApiOptions,
): Promise<boolean> => {
  const query = toPreviousApprovalsOfTypesQuery(consentTypes, userId);
  const result = await ConsentRestServiceV1.queryApprovalsV1(toRestOptions(apiOptions), query);

  return result.approvals.some(approval => consentTypes.includes(approval.artifactType as ConsentType));
};

const getPreviousAndCurrentApprovals = async (
  consentTypes: ConsentType[],
  userId: string,
  apiOptions?: ApiOptions,
): Promise<ConsentApprovalV1[]> => {
  const query = toPreviousApprovalsOfTypesQuery(consentTypes, userId);
  const result = await ConsentRestServiceV1.queryApprovalsV1(toRestOptions(apiOptions), query);

  return result.approvals;
};

const approveConsentV1 = async (
  artifactCode: string,
  documentReference: string,
  meta?: ObjectType,
  apiOptions?: ApiOptions,
): Promise<void> => {
  const consent: ConsentApprovalInV1 = { artifactCode, documentReference, meta };
  await ConsentRestServiceV1.postApprovalV1(toRestOptions(apiOptions), consent);
};

const getActiveConsentVersionV1 = async (
  consentType: ConsentType,
  countryCode?: string,
  apiOptions?: ApiOptions,
): Promise<string> => {
  const artifact = await getActiveConsentArtifactV1(consentType, countryCode, apiOptions);

  if (!artifact?.documents.length || !artifact?.documents[0]?.version) {
    throw new Error(
      `ConsentService.getActiveConsentVersion() => could not find (document) version for consent of type "${consentType}"`,
    );
  }

  return artifact.documents[0].version;
};

const getActiveOutcomeAndArtifact = async (
  consentType: ConsentType,
  countryCode?: string,
  apiOptions?: ApiOptions,
): Promise<ConsentOutcomeAndArtifact> => {
  const outcomes = await getActiveOutcomesWithArtifacts(consentType, countryCode, apiOptions);
  const outcome = first(outcomes);
  const artifact = outcome?.artifact;

  if (!outcome) {
    throw new Error(`Found no outcome for consentType ${consentType} and countryCode ${countryCode}`);
  }

  if (!artifact) {
    throw new Error(`Found no artifact for consentType ${consentType} and countryCode ${countryCode}`);
  }

  return {
    outcome,
    artifact,
  };
};

const getActiveOutcomesAndArtifacts = async (
  consentTypes: ConsentType[],
  countryCode?: string,
  apiOptions?: ApiOptions,
): Promise<ConsentOutcomeAndArtifact[]> => {
  const clientType = getConsentServiceConfig().clientType;
  const { consentRequestOutcomes } = await ConsentRestServiceV1.getRequestOutcomesV1(toRestOptions(apiOptions));
  const artifactCodes = consentRequestOutcomes.map(outcome => outcome.artifactCode);

  const { artifacts } = await ConsentRestServiceV1.queryArtifacts(toRestOptions(apiOptions), {
    criterion: {
      fieldCriterion: {
        field: ConsentArtifactCriteriaFieldV1.ARTIFACT_CODE,
        type: ConsentArtifactQueryTypeV1.IN,
        values: artifactCodes,
      },
    },
  });

  const matchingConsentRequestOutcomes = consentRequestOutcomes.filter(outcome => {
    const artifact = artifacts.find(artifact => artifact.artifactCode === outcome.artifactCode);
    const clientTypeMatch = outcome.artifactTags?.includes(clientType);
    const consentTypeMatch = consentTypes.includes(outcome.artifactType as ConsentType);
    const countryCodeMatch = countryCode && artifact?.meta?.country === countryCode;
    const skipCountryCheck = !countryCode || artifact?.meta?.country === 'ALL';

    return (countryCodeMatch || skipCountryCheck) && clientTypeMatch && consentTypeMatch;
  });

  return matchingConsentRequestOutcomes.map(outcome => {
    const artifact = artifacts.find(artifact => artifact.artifactCode === outcome.artifactCode);

    if (!artifact) {
      throw new Error(`Found no matching artifact for outcome ${outcome.artifactCode}`);
    }

    return {
      outcome,
      artifact,
    };
  });
};

const getActiveVideoTrainingOutcomesAndArtifacts = async (
  roles: RoleType[],
  countryCode?: string,
  apiOptions?: ApiOptions,
): Promise<ConsentOutcomeAndArtifact[]> => {
  const consentTypes = mapUserRolesToVideoTrainingConsentTypes(roles);

  return getActiveOutcomesAndArtifacts(consentTypes, countryCode, apiOptions);
};

export const ConsentService = {
  getConsentServiceConfig,
  setConsentServiceConfig,

  getActiveConsentsV1,
  getActiveConsentArtifactV1,

  getActiveOutcomesWithArtifacts,
  getActiveOutcomeAndArtifact,
  getActiveOutcomesAndArtifacts,

  getPreviousAndCurrentApprovals,
  hasPreviousApprovalsOfType,
  hasPreviousApprovalsOfTypes,
  approveConsentV1,
  getActiveConsentVersionV1,

  getActiveVideoTrainingOutcomesAndArtifacts,
};
