import { fromTimezoneStr } from '@csp/csp-common-date-util';
import { Maybe, RtsmStatusInfo, RtsmStatuses } from '@csp/csp-common-model';
import { StudySubjectStatusV1 } from '@csp/dmdp-api-study-dto';

const from = (current: StudySubjectStatusV1, previous: Maybe<StudySubjectStatusV1[]>): Maybe<RtsmStatuses> => {
  const previousRtsmStatusInfos: RtsmStatusInfo[] =
    previous?.map(prevStatus => ({
      rtsmStatus: prevStatus.value,
      syncedAt: fromTimezoneStr(prevStatus.createdAt),
      displayText: prevStatus.description,
      timestamp: fromTimezoneStr(prevStatus.localTimestamp),
    })) ?? [];

  const currentRtsmStatusInfo: RtsmStatusInfo = {
    rtsmStatus: current.value,
    syncedAt: fromTimezoneStr(current.createdAt),
    displayText: current.description,
    timestamp: fromTimezoneStr(current.localTimestamp),
  };

  const toListCurrentFirst = (): RtsmStatusInfo[] => [currentRtsmStatusInfo, ...previousRtsmStatusInfos];

  return {
    current: current.value,
    currentStatusDisplayText: current.description,
    timestamp: fromTimezoneStr(current.localTimestamp),
    // createdAt is set from the rtsm sync date in Boost.
    syncedAt: fromTimezoneStr(current.createdAt),
    previous: previousRtsmStatusInfos,
    toListCurrentFirst,
  };
};

export const RtsmStatusesMapper = {
  from,
};
