import { nowToTimezoneStrCurrentZone } from '@csp/csp-common-date-util';
import { ApiOptions, Country, CspError, CspErrorType, OrgId, Site } from '@csp/csp-common-model';
import { toRestOptions } from '@csp/csp-fe-auth';
import { OrgRestServiceV1 } from '@csp/dmdp-api-client';
import { CriteriaOperatorType, CriteriaType, CustomStatusInV1, QueryV1, largePage } from '@csp/dmdp-api-common-dto';
import {
  ContactPointV1,
  OrgMetaV1,
  OrgStatusType,
  OrgType,
  OrgV1,
  OrganizationCriteriaFieldV1,
} from '@csp/dmdp-api-org-dto';
import { ContactPointInV1 } from '@csp/dmdp-api-user-dto';
import { CountryMapper } from '../mapper/CountryMapper';
import { SiteMapper } from '../mapper/SiteMapper';
import { OrgQueryFactory } from './OrgQueryFactory';

const fetchByQuery = async (query: QueryV1, apiOptions?: ApiOptions): Promise<OrgV1[]> => {
  const { orgs = [] } = await OrgRestServiceV1.query(toRestOptions(apiOptions), query, largePage());
  return orgs;
};

const fetchAllByQueryRecursively = async (
  fetchCount: number,
  orgV1s: OrgV1[],
  apiOptions: ApiOptions,
  query: QueryV1,
  nextPage?: string,
): Promise<void> => {
  const { paging, orgs = [] } = await OrgRestServiceV1.query(toRestOptions(apiOptions), query, {
    limit: 5000,
    count: false,
    next: nextPage,
  });
  orgV1s.push(...orgs);

  if (paging?.next && fetchCount < 10 && orgs.length) {
    fetchCount++;
    await fetchAllByQueryRecursively(fetchCount, orgV1s, apiOptions, query, paging.next);
  }
};

const fetchAllByQuery = async (query: QueryV1, apiOptions?: ApiOptions): Promise<OrgV1[]> => {
  const orgV1s: OrgV1[] = [];
  await fetchAllByQueryRecursively(0, orgV1s, toRestOptions(apiOptions), query);
  return orgV1s;
};

const fetchOrganisationsByTypeAndId = async (
  orgTypes: OrgType[],
  orgIds: string[],
  apiOptions?: ApiOptions,
): Promise<OrgV1[]> => {
  if (orgTypes.length === 0) {
    throw new Error('Org type must be set when query organisations by type.');
  }
  if (orgIds.length === 0) {
    throw new CspError(CspErrorType.TRY_LATER, 'This study has currently no sites connected to it. Please try later.');
  }
  const query: QueryV1 = {
    criterion: {
      operator: CriteriaOperatorType.AND,
      criteria: [
        {
          fieldCriterion: {
            type: CriteriaType.IN,
            field: OrganizationCriteriaFieldV1.ORG_ID,
            values: orgIds,
          },
        },
        {
          fieldCriterion: {
            type: CriteriaType.IN,
            field: OrganizationCriteriaFieldV1.TYPE,
            values: orgTypes,
          },
        },
      ],
    },
  };

  return await fetchByQuery(query, toRestOptions(apiOptions));
};

const fetchOrgsByIds = async (orgIds: string[], apiOptions?: ApiOptions): Promise<OrgV1[]> =>
  await fetchOrganisationsByTypeAndId([OrgType.TREE, OrgType.COUNTRY], orgIds, toRestOptions(apiOptions));

const fetchOrgsIncludingParentsByIds = async (
  orgIds: OrgId[],
  branchParentIds: OrgId[],
  apiOptions?: ApiOptions,
): Promise<OrgV1[]> => {
  if (orgIds.length === 0) {
    return [];
  }
  const query: QueryV1 = {
    criterion: {
      operator: CriteriaOperatorType.AND,
      criteria: [
        {
          operator: CriteriaOperatorType.OR,
          criteria: [
            {
              fieldCriterion: {
                field: OrganizationCriteriaFieldV1.ORG_ID,
                type: CriteriaType.IN,
                values: [...new Set([...orgIds, ...branchParentIds])],
              },
            },
            {
              fieldCriterion: {
                field: OrganizationCriteriaFieldV1.BRANCH_PARENT_IDS,
                type: CriteriaType.IN,
                values: orgIds,
              },
            },
          ],
        },
        {
          fieldCriterion: {
            type: CriteriaType.EQ,
            field: OrganizationCriteriaFieldV1.STATUS,
            values: [OrgStatusType.ACTIVE],
          },
        },
      ],
    },
  };

  return await fetchByQuery(query, toRestOptions(apiOptions));
};

const fetchSitesByParentIds = async (parentIds: OrgId[], apiOptions?: ApiOptions): Promise<Site[]> => {
  const query: QueryV1 = {
    criterion: {
      operator: CriteriaOperatorType.AND,
      criteria: [
        {
          fieldCriterion: {
            type: CriteriaType.IN,
            field: OrganizationCriteriaFieldV1.BRANCH_PARENT_IDS,
            values: [...parentIds],
          },
        },
        {
          fieldCriterion: {
            type: CriteriaType.EQ,
            field: OrganizationCriteriaFieldV1.TYPE,
            values: [OrgType.PRACTICE],
          },
        },
        {
          fieldCriterion: {
            type: CriteriaType.EQ,
            field: OrganizationCriteriaFieldV1.STATUS,
            values: [OrgStatusType.ACTIVE],
          },
        },
      ],
    },
  };

  const orgs = await fetchByQuery(query, toRestOptions(apiOptions));
  return orgs.map(SiteMapper.from);
};

const fetchSitesByIds = async (orgIds: OrgId[], apiOptions?: ApiOptions): Promise<Site[]> => {
  const orgs = await fetchOrganisationsByTypeAndId([OrgType.PRACTICE], orgIds, toRestOptions(apiOptions));
  return orgs.map(SiteMapper.from);
};

const fetchCountriesByIds = async (orgIds: OrgId[], apiOptions?: ApiOptions): Promise<Country[]> => {
  const orgs = await fetchOrganisationsByTypeAndId([OrgType.COUNTRY], orgIds, toRestOptions(apiOptions));
  return orgs.map(CountryMapper.fromOrgV1);
};

const fetchAllSiteIds = async (apiOptions?: ApiOptions): Promise<OrgId[]> => {
  const sites = await OrgService.fetchAllByQuery(OrgQueryFactory.siteIdQuery(), apiOptions);
  return sites.map(site => site.orgId);
};

const fetchOrganisationsByType = async (orgTypes: OrgType[], apiOptions?: ApiOptions): Promise<OrgV1[]> => {
  if (orgTypes.length === 0) {
    throw new Error('Org type must be set when query organisations by type.');
  }
  const query: QueryV1 = {
    criterion: {
      operator: CriteriaOperatorType.AND,
      fieldCriterion: {
        type: CriteriaType.IN,
        field: OrganizationCriteriaFieldV1.TYPE,
        values: orgTypes,
      },
    },
  };

  return await fetchByQuery(query, toRestOptions(apiOptions));
};

const upsertContactPoint = async (
  contactPointInput: ContactPointInV1,
  orgId: string,
  locationId: string,
  contactPointUpdateId?: string,
  apiOptions?: ApiOptions,
): Promise<ContactPointV1 | void> =>
  contactPointUpdateId
    ? OrgRestServiceV1.updateContactPoint(
        toRestOptions(apiOptions),
        contactPointInput,
        orgId,
        locationId,
        contactPointUpdateId,
      )
    : OrgRestServiceV1.addContactPoint(toRestOptions(apiOptions), contactPointInput, orgId, locationId);

const upsertCustomStatus = async (
  orgId: OrgId,
  customStatus: CustomStatusInV1,
  apiOptions?: ApiOptions,
): Promise<void> => {
  const { type, ...customStatusValue } = customStatus;

  await OrgRestServiceV1.upsertCustomStatus(toRestOptions(apiOptions), orgId, type, {
    localTimestamp: nowToTimezoneStrCurrentZone(),
    ...customStatusValue,
  });
};

const upsertCustomStatusForOrgs = async (
  orgIds: OrgId[],
  customStatus: CustomStatusInV1,
  apiOptions?: ApiOptions,
): Promise<void> => {
  for (const orgId of orgIds) {
    await upsertCustomStatus(orgId, customStatus, apiOptions);
  }
};

const upsertMeta = async (orgId: string, orgMetaV1: OrgMetaV1, apiOptions?: ApiOptions): Promise<void> => {
  await OrgRestServiceV1.upsertMeta(toRestOptions(apiOptions), orgId, orgMetaV1);
};

export const OrgService = {
  fetchByQuery,
  fetchAllByQuery,
  fetchOrgsByIds,
  fetchOrgsIncludingParentsByIds,
  fetchAllSiteIds,
  fetchSitesByIds,
  fetchSitesByParentIds,
  fetchCountriesByIds,
  fetchOrganisationsByType,
  fetchOrganisationsByTypeAndId,
  upsertContactPoint,
  upsertCustomStatus,
  upsertCustomStatusForOrgs,
  upsertMeta,
};
