/* eslint-disable */
export const UnifyWebRemotePatientMonitoringMock = {
  rpmEvents: { tab: '[M]Data events', noData: '[M]No data reported' },
  rpmModules: [
    {
      algorithmCode: 'GI_TOX',
      headerTitle: 'Digestive symptoms',
      detailsHeader: 'Digestive symptoms ${suffix}',
      tracker: {
        disclaimerBodyMd:
          'This remote patient monitoring solution only captures the following gastrointestinal symptoms: change in appetite, nausea, vomiting, and constipation, as well as symptoms associated with dehydration. This patient might experience additional symptoms that are not reported here. Please follow up with your patient if you would like to learn more about their symptoms. In the absence of medical follow up, this data does not constitute a reportable safety event.',
        cards: [
          {
            cardCode: 'GI_TOX_CARD',
            charts: [
              {
                chartCode: 'GI_TOX_OVERVIEW_CHART',
                chartTitle: 'Symptoms overview',
                parameters: [
                  { parameterCode: 'APPETITE', parameterLabel: 'Change in appetite' },
                  { parameterCode: 'NAUSEA', parameterLabel: 'Nausea' },
                  { parameterCode: 'VOMITING', parameterLabel: 'Vomiting' },
                  { parameterCode: 'CONSTIPATION', parameterLabel: 'Constipation' },
                  { parameterCode: 'DIARRHEA', parameterLabel: 'Diarrhea' },
                  { parameterCode: 'NONE', parameterLabel: 'None' },
                ],
              },
              {
                chartCode: 'GI_TOX_MEDICATION_CHART',
                parameters: [{ parameterCode: 'MEDICATION', parameterLabel: 'Medication taken' }],
              },
            ],
          },
        ],
      },
    },
    {
      algorithmCode: 'MARSDEN_WEIGHT_SCALE',
      headerTitle: 'Weight events',
      tracker: { disclaimerBodyMd: 'Disclaimer markdown for weight events', cards: [] },
    },
    {
      algorithmCode: 'MIR_PEF',
      headerTitle: 'MIR PEF events',
      tracker: { disclaimerBodyMd: 'Disclaimer markdown for weight events', cards: [] },
    },
    {
      algorithmCode: 'MASIMO_SPO2',
      headerTitle: 'Masimo SPO2 events',
      tracker: { disclaimerBodyMd: 'Disclaimer markdown for weight events', cards: [] },
    },
  ],
  symptomEventDetails: {
    learnMore: [
      {
        header: 'Lorem ipsum dolor sit amet',
        bodyMd:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et do',
      },
      {
        header: 'Lorem ipsum dolor sit amet 2',
        bodyMd:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et do',
      },
      {
        header: 'Lorem ipsum dolor sit amet 3',
        bodyMd:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et do',
      },
    ],
  },
};
