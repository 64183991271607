import { QueryClientConfig, QueryClientProvider } from '@tanstack/react-query';
import { FC, PropsWithChildren } from 'react';
import { QueryContext } from './QueryContext';
import { useQueryContextProvider } from './useQueryContextProvider';

type Props = PropsWithChildren & {
  queryClientConfig?: QueryClientConfig;
};

export const QueryContextProvider: FC<Props> = ({ children, queryClientConfig }) => {
  const state = useQueryContextProvider(queryClientConfig);
  return (
    <QueryContext.Provider value={state}>
      <QueryClientProvider client={state.queryClient}>{children}</QueryClientProvider>
    </QueryContext.Provider>
  );
};
