// eslint-disable-next-line @typescript-eslint/ban-types
const is = (val: string, o: {}): boolean => {
  const values = Object.values(o);
  return values.includes(val);
};

// eslint-disable-next-line @typescript-eslint/ban-types
const fromStringOrError = <T>(val: string, o: {}): T => {
  const values = Object.values(o);
  if (values.includes(val)) {
    return val as unknown as T;
  } else {
    throw new Error(`Enum match failed on: ${val}. Available: ${values.join(', ')}`);
  }
};

// eslint-disable-next-line @typescript-eslint/ban-types
const fromString = <T>(val: string, o: {}): T | undefined => {
  try {
    return fromStringOrError(val, o);
  } catch {
    return undefined;
  }
};

// eslint-disable-next-line @typescript-eslint/ban-types
const fromMaybeString = <T>(val: string | undefined, o: {}): T | undefined => (val ? fromString(val, o) : undefined);

// eslint-disable-next-line @typescript-eslint/ban-types
const fromStringOrDefault = <T>(val: string, o: {}, defaultValue: T): T => {
  try {
    return fromStringOrError(val, o);
  } catch {
    return defaultValue;
  }
};

// eslint-disable-next-line @typescript-eslint/ban-types
const fromMaybeStringOrDefault = <T>(val: string | undefined, o: {}, defaultValue: T): T =>
  val ? fromStringOrDefault(val, o, defaultValue) : defaultValue;

// eslint-disable-next-line @typescript-eslint/ban-types
const asStrings = (enu: {}): string[] => Object.values(enu).map(v => String(v));

// eslint-disable-next-line @typescript-eslint/ban-types
const asArray = <T>(enu: {}): T[] => Object.keys(enu) as unknown as T[];

export const EnumUtil = {
  fromStringOrError,
  fromString,
  fromMaybeString,
  fromStringOrDefault,
  fromMaybeStringOrDefault,
  asStrings,
  asArray,
  is,
};
