import { Body1, Box, BrandedIcon, Caption1, IconTextButton, Link } from '@csp/csp-web-ui';
import { FC } from 'react';
import { SystemLinkItemProps } from '../model/SystemLinkItemProps';

export const SystemLinkItem: FC<SystemLinkItemProps> = ({
  systemName,
  studyName,
  description,
  url,
  buttonLabel,
  linkProps,
  ...rest
}) => (
  <Box
    pb={{ xs: 1, md: 1.5 }}
    mb={{ xs: 2, md: 3 }}
    border={1}
    borderTop={0}
    borderLeft={0}
    borderRight={0}
    borderColor="grey.200"
  >
    <Caption1 fontWeight={700} mb={1} component="h6">
      {systemName}
      {studyName && (
        <Box component="span" color="text.secondary" fontWeight="fontWeightRegular">
          <Box mx={1.5} component="span">
            |
          </Box>
          {studyName}
        </Box>
      )}
    </Caption1>
    <Body1 mb={{ xs: 1, md: 1.5 }}>{description}</Body1>
    <Link href={url} {...linkProps}>
      <IconTextButton {...rest} ml={-2} endIcon={<BrandedIcon name="newTab" size="small" color="primary" />}>
        {buttonLabel}
      </IconTextButton>
    </Link>
  </Box>
);
