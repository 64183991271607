import { useClearStudySpecificCmsContent } from '@csp/common';
import { Callback, CallbackAsync } from '@csp/csp-common-model';
import { noop } from 'lodash';
import { useCallback } from 'react';
import { useAppDispatch } from '../../../common/hooks/useAppDispatch';
import { useConsentContext } from '../../consent/context/hook/useConsentContext';
import { signOutPrincipalThunk } from '../ducks/principalContextActions';

type SignOutHook = {
  signOut: CallbackAsync;
};

export const useSignOut = (onAfterSignOutCallback: Callback = noop): SignOutHook => {
  const dispatch = useAppDispatch();
  const clearStudySpecificCmsContent = useClearStudySpecificCmsContent();
  const { resetConsentApprovals } = useConsentContext();

  const signOut: CallbackAsync = useCallback(async () => {
    await dispatch(signOutPrincipalThunk());

    clearStudySpecificCmsContent();
    resetConsentApprovals();
    onAfterSignOutCallback();
  }, [onAfterSignOutCallback, dispatch, clearStudySpecificCmsContent, resetConsentApprovals]);

  return { signOut };
};
