import { Loading, SpinnerPage } from '@csp/csp-web-ui';
import { useOnMount } from '@csp/fe-hooks';
import { CognitoContext, ErrorBoundary, getAppVersion, useCognitoContextProvider } from '@csp/web-common';
import { FC, useState } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { CspRoutes } from './CspRoutes';
import { ConsentContextProvider } from './feature/consent/context/ConsentContextProvider';
import { persistor, store } from './state/store';
import { WebInitializationService } from './system/service/WebInitializationService';
import { getUrlSearchParams } from './common/util/browser/getUrlSearchParams';
import { ApplicationErrorPage } from './common/component/ApplicationErrorPage';
import { AutomaticLogoutContextProvider } from './feature/security/context/AutomaticLogoutContextProvider';
import { AuthContextProvider } from './feature/auth/context/AuthContextProvider';

enum ViewState {
  LOADING,
  FAILURE,
  DONE,
}

export const App: FC = () => {
  const [viewState, setViewState] = useState(ViewState.LOADING);
  const cognitoContext = useCognitoContextProvider(true);
  const urlSearchParams = getUrlSearchParams();

  useOnMount(async () => {
    try {
      await WebInitializationService.init(getAppVersion(), urlSearchParams);
      setViewState(ViewState.DONE);
    } catch (e) {
      console.warn(e);
      setViewState(ViewState.FAILURE);
    }
  });

  switch (viewState) {
    case ViewState.LOADING:
      return <SpinnerPage />;
    case ViewState.FAILURE:
      return <ApplicationErrorPage />;
    case ViewState.DONE:
      return (
        <Provider store={store}>
          <AuthContextProvider>
            <AutomaticLogoutContextProvider>
              <PersistGate loading={<Loading isLoading={true} />} persistor={persistor}>
                <CognitoContext.Provider value={cognitoContext}>
                  <ConsentContextProvider>
                    <ErrorBoundary ErrorComponent={ApplicationErrorPage}>
                      <CspRoutes />
                    </ErrorBoundary>
                  </ConsentContextProvider>
                </CognitoContext.Provider>
              </PersistGate>
            </AutomaticLogoutContextProvider>
          </AuthContextProvider>
        </Provider>
      );
  }
};
