import { CspError, CspErrorType, DmdpError, UnknownError } from '@csp/csp-common-model';
import { ErrorV1 } from '@csp/dmdp-api-common-dto';
import {
  default as Axios,
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  AxiosResponseHeaders,
  AxiosResponseTransformer,
  isAxiosError,
} from 'axios';
import jsonBigInt from 'json-bigint';
import { isString, startsWith } from 'lodash';
import { HttpStatusConst } from '../type/HttpStatusConst';
import { JsonUtil } from './JsonUtil';

const httpErrorMap: Map<HttpStatusConst, CspErrorType> = new Map([
  [HttpStatusConst.BAD_REQUEST, CspErrorType.BAD_REQUEST],
  [HttpStatusConst.UNAUTHORIZED, CspErrorType.UNAUTHORIZED],
  [HttpStatusConst.FORBIDDEN, CspErrorType.FORBIDDEN],
  [HttpStatusConst.NOT_FOUND, CspErrorType.NOT_FOUND],
  [HttpStatusConst.CONFLICT, CspErrorType.CONFLICT],
  [HttpStatusConst.INTERNAL_SERVER_ERROR, CspErrorType.SERVER_ERROR],
  [HttpStatusConst.SERVICE_UNAVAILABLE, CspErrorType.SERVER_ERROR],
]);

export const createAxios = (): AxiosInstance => {
  const axios = Axios.create();
  axios.defaults.headers.common['If-Modified-Since'] = '0';
  axios.defaults.headers.common['Cache-Control'] = 'no-store, no-cache, must-revalidate';
  /* Uncomment to log out and in from the Axios client
  axios.interceptors.request.use(request => {
    console.log('Request: ', JSON.stringify(request, null, 2));
    return request;
  });
  axios.interceptors.response.use(response => {
    console.log('Response data: ', JSON.stringify(response.data, null, 2));
    return response;
  });*/
  return axios;
};

export const createAxiosWithJwtToken = (jwt: string): AxiosInstance => {
  const axios = createAxios();
  axios.defaults.headers.common['Authorization'] = `BEARER ${jwt}`;
  return axios;
};

export const handleAxiosError = (error: AxiosError): Promise<never> => {
  const errorV1 = JsonUtil.safelyCastUnknownResponseBody<ErrorV1>(error);
  if (error.response) {
    const status = error.response?.status;
    const cspError = httpErrorMap.get(status) ?? CspErrorType.UNHANDLED_CLIENT_ERROR;
    return Promise.reject(DmdpError.from(cspError, errorV1));
  } else if (Axios.isCancel(error)) {
    return Promise.reject(new CspError(CspErrorType.CANCELLED, `Reason: Request was cancelled`));
  } else {
    return Promise.reject(new CspError(CspErrorType.HTTP_COMMUNICATION, `Reason: ${errorV1?.message}`));
  }
};

const handleAxiosResponse = (err: AxiosError): Promise<AxiosResponse> => {
  const status = err.response?.status;
  if (status && status >= HttpStatusConst.BAD_REQUEST && status < HttpStatusConst.INTERNAL_SERVER_ERROR) {
    const errorV1: ErrorV1 | undefined = JsonUtil.safelyCastUnknownResponseBody<ErrorV1>(err);
    return Promise.reject(new CspError(CspErrorType.USER_NOT_ALLOWED, errorV1?.message));
  } else {
    return handleAxiosError(err);
  }
};

export const handleNotAuthorizedOrThrow = (error: UnknownError): Promise<AxiosResponse> => {
  if (CspError.isNotFound(error)) {
    return Promise.reject(CspError.unauthorized());
  } else if (isAxiosError(error)) {
    return handleAxiosError(error);
  } else {
    throw error;
  }
};

export const handleAxiosErrorOrThrow = (error: UnknownError): Promise<AxiosResponse> => {
  if (isAxiosError(error)) {
    return handleAxiosResponse(error);
  } else {
    throw error;
  }
};

export const isJsonString = (data: unknown, headers: AxiosResponseHeaders): data is string =>
  isString(data) && startsWith(headers['content-type'], 'application/json');

export const parseJsonWithBigInt: AxiosResponseTransformer = (data: unknown, headers: AxiosResponseHeaders) => {
  if (isJsonString(data, headers)) {
    return jsonBigInt({ storeAsString: true }).parse(data);
  } else {
    return data;
  }
};
