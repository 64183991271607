import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { CspPath, DEFAULT_AUTHENTICATED_PATH } from '../../../CspPath';
import { useAuthContext } from '../context/hook/useAuthContext';
import { AutomaticLogoutRedirect } from './AutomaticLogoutRedirect';
import { UserSetup } from './UserSetup';

export const AuthenticatedRoute: FC = () => {
  const { isNotAuthenticated } = useAuthContext();

  if (isNotAuthenticated) {
    return <Navigate to={CspPath.LOGIN} />;
  }

  return (
    <UserSetup>
      <AutomaticLogoutRedirect />
      <Outlet />
    </UserSetup>
  );
};

export const UnauthenticatedRoute: FC = () => {
  const { isAuthenticated } = useAuthContext();

  if (isAuthenticated) {
    return <Navigate to={DEFAULT_AUTHENTICATED_PATH} />;
  }

  return <Outlet />;
};
