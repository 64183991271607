import { StudyInfoV1, StudyV1 } from '@csp/dmdp-api-study-dto';
import { RestOptions } from '@csp/csp-common-model';
import { studypodUrl } from '../../dmdp-config/dmdpConfig';

const studyUrl = (): string => `${studypodUrl()}/study/v1`;

const getStudy = async ({ axios, signal }: RestOptions): Promise<StudyV1> => {
  const resp = await axios.get<StudyV1>(studyUrl(), {
    signal,
  });
  return resp.data;
};

const getStudyInfo = async ({ axios, signal }: RestOptions): Promise<StudyInfoV1> => {
  const resp = await axios.get<StudyInfoV1>(`${studyUrl()}/info`, {
    signal,
  });
  return resp.data;
};

export const StudyRestServiceV1 = {
  getStudy,
  getStudyInfo,
};
