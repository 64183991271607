import { CspError, User } from '@csp/csp-common-model';
import { PrincipalContextSlice } from '../model/PrincipalContextSlice';

export const getCurrentUserSelector = ({ principalContext: { principal } }: PrincipalContextSlice): User => {
  if (principal) {
    return principal.user;
  } else {
    throw CspError.badState('User not found in this context.');
  }
};
