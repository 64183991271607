/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AssessmentAggregateGroupFieldV1 {
    USER_ID = 'USER_ID',
    ASSESSMENT_ALGORITHM_CODE = 'ASSESSMENT_ALGORITHM_CODE',
    CUSTOM_STATUS_TYPE = 'CUSTOM_STATUS_TYPE',
    CUSTOM_STATUS_VALUE = 'CUSTOM_STATUS_VALUE',
    OUTCOME_KEY = 'OUTCOME_KEY',
    CREATED_AT = 'CREATED_AT',
    EVENT_ID = 'EVENT_ID',
}