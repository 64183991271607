import { useCallback, useState } from 'react';
import { Toggle } from '../model/Toggle';
import { ToggleOptions } from '../model/ToggleOptions';

export const useToggle = (initialValue = false, { onOff, onOn, onToggle }: ToggleOptions = {}): Toggle => {
  const [isOn, setIsOn] = useState(initialValue);

  const setOn = useCallback((): void => {
    setIsOn(true);
    if (onOn) {
      onOn();
    }
    if (onToggle) {
      onToggle(true);
    }
  }, [onOn, onToggle, setIsOn]);

  const setOff = useCallback((): void => {
    setIsOn(false);
    if (onOff) {
      onOff();
    }
    if (onToggle) {
      onToggle(false);
    }
  }, [onOff, onToggle, setIsOn]);

  const toggle = useCallback((): void => {
    const newValue = !isOn;
    if (newValue) {
      setOn();
    } else {
      setOff();
    }
  }, [isOn, setOff, setOn]);

  const set = useCallback((state: boolean): void => setIsOn(state), []);

  return {
    isOn,
    isOff: !isOn,
    setOn,
    setOff,
    toggle,
    set,
  };
};
