import { createETagHeader } from '@csp/csp-common-axios';
import { RestOptions } from '@csp/csp-common-model';
import { contentDeliveryUrl } from '../../utils/configUtil';
import { BulkAssetSignedUrls } from '../model/BulkAssetSignedUrls';
import { ContentPackageParams } from '../model/ContentPackageParams';
import { SignedAssetUrlResponse } from '../model/SignedAssetUrlResponse';

const getBaseUrl = (): string => `${contentDeliveryUrl()}/content-delivery/v1`;

const getSignedAssetUrl = async (
  { axios, signal }: RestOptions,
  { packageType, clientType, version, locale, role }: ContentPackageParams,
  assetRelativePath: string,
  eTag?: string,
): Promise<NonNullable<SignedAssetUrlResponse>> => {
  const url =
    packageType === 'study'
      ? `${getBaseUrl()}/${packageType}/${clientType}/${role?.toLowerCase()}/${version}/${locale}/${assetRelativePath}`
      : `${getBaseUrl()}/${packageType}/${clientType}/${version}/${locale}/${assetRelativePath}`;
  const response = await axios.get<SignedAssetUrlResponse>(url, {
    headers: eTag ? createETagHeader(eTag) : undefined,
    signal,
  });
  return { ...response.data, eTag: response.headers.etag, status: response.status };
};

const getBulkSignedAssetUrl = async (
  { axios, signal }: RestOptions,
  { packageType, clientType, version, locale, role }: ContentPackageParams,
  assetRelativePaths: string[],
): Promise<NonNullable<BulkAssetSignedUrls>> => {
  const url =
    packageType === 'study'
      ? `${getBaseUrl()}/${packageType}/${clientType}/${role?.toLowerCase()}/${version}/${locale}/assets`
      : `${getBaseUrl()}/${packageType}/${clientType}/${version}/${locale}/assets`;
  const response = await axios.post<BulkAssetSignedUrls>(
    url,
    { assets: assetRelativePaths },
    {
      signal,
    },
  );
  return response.data;
};

export const AssetDeliveryRestServiceV1 = {
  getSignedAssetUrl,
  getBulkSignedAssetUrl,
};
