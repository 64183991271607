import { PatientMedicationsMetaV1 } from '../../dto/UserMetaV1';
import { PatientMedicationsV1 } from './PatientMedicationsV1';

export const PATIENT_MEDICATION_V1_META_TYPE = 'PATIENT_MEDICATION_V1';

export const createPatientMedicationMetaV1 = (
  patientMedicationsV1: PatientMedicationsV1,
): PatientMedicationsMetaV1 => ({
  type: PATIENT_MEDICATION_V1_META_TYPE,
  name: PATIENT_MEDICATION_V1_META_TYPE,
  data: patientMedicationsV1,
});
