import {
  DataChangeItemsPage,
  DataChangeQueryFilter,
  DataChangeService,
  EMPTY_DATA_CHANGE_QUERY_FILTER_CRITERIA,
} from '@csp/csp-common-dcf';
import { CallbackWith } from '@csp/csp-common-model';
import { OnClick } from '@csp/csp-web-ui';
import { isEmpty, isEqual } from 'lodash';
import { useCallback, useState } from 'react';
import { useQueryContext } from '../../../../common/context/query/useQueryContext';
import { useStudyContext } from '../../../../common/context/study/useStudyContext';
import { usePagedQuery } from '../../../../common/query/hooks/usePagedQuery';
import { DataChangeQueryState } from '../model/DataChangeQueryState';
import { DataChangeQueryFilterUtil } from '../util/DataChangeQueryFilterUtil';

type DataChangeFilterState = {
  isFilterExpanded: boolean;
  filter: DataChangeQueryFilter;
  filterCount: number;
};

export type UseDataChangeQueryHook = DataChangeQueryState & {
  onClearFilter: OnClick;
  filterCount: number;
};

const toInitialFilterState = (initialFilter: DataChangeQueryFilter): DataChangeFilterState => ({
  isFilterExpanded: false,
  filter: initialFilter,
  filterCount: DataChangeQueryFilterUtil.toFilterCount(initialFilter),
});

export const useDataChangeQuery = (initialFilter: DataChangeQueryFilter): UseDataChangeQueryHook => {
  const { setParam, getParam } = useQueryContext();
  const { study } = useStudyContext();

  const cacheKey = initialFilter.key;

  const [filterState, setFilterState] = useState<DataChangeFilterState>(
    getParam(cacheKey) ?? toInitialFilterState(initialFilter),
  );

  const query = async (nextCursor?: string): Promise<DataChangeItemsPage> =>
    DataChangeService.getDataChangesByFilter(filterState.filter, study, nextCursor);

  const {
    data,
    error,
    count: totalCount,
    isLoadingInitial,
    isLoadingNext,
    hasNext,
    loadNext,
    refresh,
  } = usePagedQuery(
    {
      cacheKey,
      cacheParams: [filterState.filter],
    },
    query,
  );

  const hasNoDataChanges = isEmpty(data) && totalCount === 0 && isEqual(filterState.filter, initialFilter);

  const handleFilterUpdate: CallbackWith<DataChangeQueryFilter> = useCallback(
    (filter: DataChangeQueryFilter) => {
      if (!isEqual(filterState.filter, filter)) {
        const newFilterCount = DataChangeQueryFilterUtil.toFilterCount(filter);

        const newFilterState: DataChangeFilterState = {
          ...filterState,
          filterCount: newFilterCount,
          filter,
        };

        setParam(cacheKey, newFilterState);
        setFilterState(newFilterState);
      }
    },
    [cacheKey, filterState, setParam],
  );

  const handleClearFilter = useCallback(() => {
    const newFilterState: DataChangeFilterState = {
      ...filterState,
      filterCount: 0,
      filter: {
        ...initialFilter,
        criteria: EMPTY_DATA_CHANGE_QUERY_FILTER_CRITERIA,
      },
    };

    setParam(cacheKey, newFilterState);
    setFilterState(newFilterState);
  }, [cacheKey, filterState, initialFilter, setParam]);

  const handleFilterToggle = useCallback(() => {
    const newFilterState: DataChangeFilterState = {
      ...filterState,
      isFilterExpanded: !filterState.isFilterExpanded,
    };

    setParam(cacheKey, newFilterState);
    setFilterState(newFilterState);
  }, [cacheKey, filterState, setParam]);

  return {
    data,
    error,
    hasNoDataChanges,

    isLoading: isLoadingInitial && !isLoadingNext,
    isLoadingMore: isLoadingNext,
    hasMore: hasNext,
    loadMore: loadNext,

    currentFilter: filterState.filter,
    filterCount: filterState.filterCount,
    isFilterExpanded: filterState.isFilterExpanded,
    onFilterToggle: handleFilterToggle,
    onFilterUpdate: handleFilterUpdate,
    onClearFilter: handleClearFilter,

    refresh,
    totalCount,
  };
};
