import { PatientVisitsMetaV1 } from '../../dto/UserMetaV1';
import { PatientVisitsV1 } from './PatientVisitsV1';

export const PATIENT_VISIT_V1_META_TYPE = 'PATIENT_VISIT_V1';

export const createPatientVisitsMetaV1 = (patientVisitsV1: PatientVisitsV1): PatientVisitsMetaV1 => ({
  type: PATIENT_VISIT_V1_META_TYPE,
  name: PATIENT_VISIT_V1_META_TYPE,
  data: patientVisitsV1,
});
