import { MobileConfig } from '../model/MobileConfig';

const singleton: { config: MobileConfig } = {
  config: MobileConfig.empty(),
};

const getIosStoreLink = (): string => singleton.config.iosStoreLink;

const getAndroidStoreLink = (): string => singleton.config.androidStoreLink;

const set = (config: MobileConfig): void => {
  singleton.config = config;
};

const get = (): MobileConfig => singleton.config;

export const MobileConfigService = {
  getIosStoreLink,
  getAndroidStoreLink,
  set,
  get,
};
