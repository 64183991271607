export type CountValue = {
  expected: number;
  actual: number;
  missed: number;
};

const from = (total: number, actual: number, missed?: number): CountValue => ({
  expected: total,
  actual,
  missed: missed ?? total - actual,
});

export const CountValue = {
  from,
};
