import { EnumUtil } from '@csp/csp-common-enum-util';
import { HCP_ROLES, RoleType } from '@csp/dmdp-api-common-dto';
import { ConsentType, VIDEO_TRAINING_UNIFY_STUDY_INTRO_PREFIX } from '@csp/dmdp-api-user-dto';
import { uniq } from 'lodash';

export const mapUserRolesToVideoTrainingConsentTypes = (roles: RoleType[]): ConsentType[] => {
  const consentTypes: ConsentType[] = [];

  roles.forEach(role => {
    if (HCP_ROLES.includes(role)) {
      consentTypes.push(ConsentType.VIDEO_TRAINING_UNIFY_STUDY_INTRO_HCP);
    } else if ([RoleType.CRA, RoleType.GSTM, RoleType.SSTM].includes(role)) {
      const consentType = EnumUtil.fromString<ConsentType>(
        `${VIDEO_TRAINING_UNIFY_STUDY_INTRO_PREFIX}_${role}`,
        ConsentType,
      );

      if (consentType) {
        consentTypes.push(consentType);
      }
    }
  });

  return uniq(consentTypes);
};
