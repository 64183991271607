/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DeviceObservationCriteriaFieldV1 {
    USER_DEVICE_ID = 'USER_DEVICE_ID',
    DEVICE_MODEL_CODE = 'DEVICE_MODEL_CODE',
    REFS_KEY = 'REFS_KEY',
    REFS_VALUE = 'REFS_VALUE',
    SENSOR_TIMESTAMP = 'SENSOR_TIMESTAMP',
    SYNCHRONIZATION_TIMESTAMP = 'SYNCHRONIZATION_TIMESTAMP',
    SESSION_CODE = 'SESSION_CODE',
    DEVICE_OBSERVATION_ID = 'DEVICE_OBSERVATION_ID',
    USER_ID = 'USER_ID',
    EVENT_ID = 'EVENT_ID',
    SCHEDULE_REQUEST_STATUS = 'SCHEDULE_REQUEST_STATUS',
    SCHEDULE_REQUEST_REF_SCHEDULE_CODE = 'SCHEDULE_REQUEST_REF_SCHEDULE_CODE',
    SCHEDULE_REQUEST_REF_VERSION_CODE = 'SCHEDULE_REQUEST_REF_VERSION_CODE',
    SCHEDULE_REQUEST_REF_REQUEST_CODE = 'SCHEDULE_REQUEST_REF_REQUEST_CODE',
}