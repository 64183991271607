import { FC } from 'react';
import { Box } from '../../layout/box/Box';
import { CenteredBox } from '../../layout/box/CenteredBox';
import { Subtitle2 } from '../../typography/TextStyles';
import { Page } from '../../layout/page/Page';
import { Spinner } from './Spinner';

type Props = {
  message?: string;
};

export const SpinnerPage: FC<Props> = ({ message }) => (
  <Page>
    <CenteredBox data-testid="spinner-page">
      <Spinner />
      {message && (
        <Box m={3}>
          <Subtitle2 align="center" color="textSecondary">
            {message}
          </Subtitle2>
        </Box>
      )}
    </CenteredBox>
  </Page>
);
