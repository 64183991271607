import { FlexRow, Grid } from '@csp/csp-web-ui';
import styled from '@emotion/styled';
import { FC, ReactNode } from 'react';
import { notIncludes } from '@csp/csp-common-util';
import { BackAndCloseButtonProps } from '../model/BackAndCloseButtonProps';
import { HeaderConstants } from '../model/HeaderConstants';
import { HeaderNavTitleBox, HeaderNavTitleBoxLarge } from './HeaderNavTitleBox';
import { HeaderNavToolsBox, HeaderNavToolsBoxProps } from './HeaderNavToolsBox';
import { HeaderNav, HeaderNavLarge } from './HeaderNav';

type IsPdfView = { isPdfView?: boolean };

export type HeaderNavBarProps = BackAndCloseButtonProps &
  IsPdfView & {
    title: ReactNode;
    subtitle?: ReactNode;
    children?: ReactNode;
    toolsBoxProps?: HeaderNavToolsBoxProps;
  };

const StickyGrid = styled(Grid, {
  shouldForwardProp: notIncludes(['isPdfView']),
})<IsPdfView>`
  ${({ theme: { palette, breakpoints, shadows }, isPdfView }): string => `
    background-color: ${palette.background.paper};
    position: sticky;
    top: -${HeaderConstants.NAV_BAR_HEIGHT + HeaderConstants.UNIFY_BAR_HEIGHT}rem;
    z-index: 2;
    box-shadow: ${isPdfView ? undefined : shadows[4]};
    ${breakpoints.down('sm')} {
      top: -${HeaderConstants.NAV_BAR_HEIGHT_SM + HeaderConstants.UNIFY_BAR_HEIGHT}rem;
    }
  `}
`;

const UnifyBar = styled(FlexRow)`
  ${({ theme: { palette } }): string => `
    height: ${HeaderConstants.UNIFY_BAR_HEIGHT}rem;
    width: 100%;
    background: ${palette.primary.dark};
  `}
`;

const HeaderNavWithBorder = styled(HeaderNavLarge)`
  ${({ theme: { palette } }): string => `
    border-bottom: 1px solid ${palette.grey[200]};
  `}
`;

const HeaderBoxLarge = styled(FlexRow)`
  height: ${HeaderConstants.NAV_BAR_HEIGHT}rem;
`;

const HeaderBox = styled(HeaderBoxLarge)`
  ${({ theme: { breakpoints } }): string => `
    ${breakpoints.down('sm')} {
      height: ${HeaderConstants.NAV_BAR_HEIGHT_SM}rem;
    }
  `}
`;

const Header: FC<HeaderNavBarProps> = ({ children, toolsBoxProps, ...titleBoxProps }) => (
  <HeaderNav>
    <HeaderBox alignItems="center" justifyContent="space-between">
      <HeaderNavTitleBox {...titleBoxProps} />
      <HeaderNavToolsBox {...toolsBoxProps} />
    </HeaderBox>
    {children}
  </HeaderNav>
);

const HeaderLarge: FC<HeaderNavBarProps> = ({ children, toolsBoxProps, ...titleBoxProps }) => (
  <HeaderNavWithBorder>
    <HeaderBoxLarge alignItems="center" justifyContent="space-between">
      <HeaderNavTitleBoxLarge {...titleBoxProps} />
      <HeaderNavToolsBox {...toolsBoxProps} />
    </HeaderBoxLarge>
    {children}
  </HeaderNavWithBorder>
);

export const HeaderNavBar: FC<HeaderNavBarProps> = ({ children, isPdfView, ...props }) => (
  <StickyGrid item xs={12} isPdfView={isPdfView}>
    <UnifyBar />
    {isPdfView ? <HeaderLarge {...props} /> : <Header {...props} />}
    {children}
  </StickyGrid>
);
