import { CompliantStateType } from '@csp/dmdp-api-user-dto';
import { ZonedDateTime } from '../../../datetime/ZonedDateTime';
import { ComplianceMetricItem } from './ComplianceMetricItem';

export type Compliance = {
  state: CompliantStateType;
  updatedAt: ZonedDateTime;
  metrics: ComplianceMetricItem[];
};

export const emptyCompliance: Compliance = {
  state: CompliantStateType.NO_DATA,
  updatedAt: {
    unixTimeMillis: 0,
    zone: 'UTC',
  },
  metrics: [],
};
