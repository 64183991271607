import { ScheduleCode, ScheduleVersionInfo } from '@csp/csp-common-scheduling';
import { QuestionnaireRequestV2 } from '@csp/dmdp-api-questionnaire-dto';
import { QuestionnaireRequestV1 } from '@csp/dto';
import { QuestionnaireRequestInfo } from './QuestionnaireRequestInfo';

export type QuestionnaireRequest = QuestionnaireRequestInfo & {
  versionInfo: ScheduleVersionInfo;
};

const from = (
  scheduleCode: ScheduleCode,
  scheduleVersionInfo: ScheduleVersionInfo,
  requestVx: QuestionnaireRequestV2 | QuestionnaireRequestV1,
): QuestionnaireRequest => {
  const request = QuestionnaireRequestInfo.from(scheduleCode, scheduleVersionInfo.versionCode, requestVx);
  return {
    versionInfo: scheduleVersionInfo,
    ...request,
    minComplianceThreshold: request.config.minComplianceThreshold,
  };
};

export const QuestionnaireRequest = {
  from,
};
