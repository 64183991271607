import { RestOptions } from '@csp/csp-common-model';
import {
  AppointmentActiveSchedulesOrganizationsQueryV1,
  AppointmentActiveSchedulesOrganizationsV1,
  AppointmentActiveSchedulesV1,
  AppointmentDescriptionInV1,
  AppointmentInV1,
  AppointmentLocationV1,
  AppointmentQueryV1,
  AppointmentStartTimestampInV1,
  AppointmentsV1,
  AppointmentV1,
  CustomStatusInV1,
} from '@csp/dmdp-api-appointment-dto';
import { largePage, Page } from '@csp/dmdp-api-common-dto';
import { parseJsonWithBigInt } from '@csp/csp-common-axios';
import { workflowpodUrl } from '../dmdp-config/dmdpConfig';

const appointmentUrl = (): string => `${workflowpodUrl()}/appointment/v1`;

/**
 * Get active schedule windows by organization structure
 * Get active schedule windows based on the supplied organization and its parents.
 *
 * The window start and -end parameters defines the timespan to get active schedules for.
 * If not specified, window start will default to beginning of time/epoch and window end will default to eternity.
 *
 * Tip: To only get the currently active window you can supply window start and -end set to current timestamp.
 */
const getActiveSchedulesByOrgId = async (
  { axios, signal }: RestOptions,
  orgId: string,
  scheduleCodes?: Array<string>,
): Promise<AppointmentActiveSchedulesV1> => {
  const response = await axios.get<AppointmentActiveSchedulesV1>(
    `${appointmentUrl()}/organizations/${orgId}/active-schedules`,
    { params: { scheduleCodes }, signal },
  );

  return response.data;
};

const queryActiveSchedulesByOrganizationIds = async (
  { axios, signal }: RestOptions,
  queryV1: AppointmentActiveSchedulesOrganizationsQueryV1,
): Promise<AppointmentActiveSchedulesOrganizationsV1> => {
  const { data } = await axios.post<AppointmentActiveSchedulesOrganizationsV1>(
    `${appointmentUrl()}/active-schedules:query-by-organizations`,
    queryV1,
    {
      transformResponse: parseJsonWithBigInt,
      signal,
    },
  );
  return data;
};

/**
 * Query and page for appointments.
 *
 * @param apiOptions
 * @param query
 * @param page
 * @result AppointmentsV1 OK
 */
const query = async (
  { axios, signal }: RestOptions,
  query: AppointmentQueryV1,
  page: Page = largePage(),
): Promise<AppointmentsV1> => {
  const response = await axios.post<AppointmentsV1>(`${appointmentUrl()}/appointments:query`, query, {
    params: page,
    signal,
  });

  return response.data;
};

/**
 * Get appointment by ID.
 */
const getById = async ({ axios, signal }: RestOptions, appointmentId: string): Promise<AppointmentV1> => {
  const response = await axios.get<AppointmentV1>(`${appointmentUrl()}/appointments/${appointmentId}`, {
    signal,
  });
  return response.data;
};

/**
 * Delete appointment by ID. Deleting an appointment means it is will be cancelled.
 *
 * @param apiOptions
 * @param appointmentId
 */
const deleteById = async ({ axios, signal }: RestOptions, appointmentId: string): Promise<void> => {
  await axios.delete(`${appointmentUrl()}/appointments/${appointmentId}`, {
    signal,
  });
};

/**
 * Create a new appointment in the same tenant as the current dmdpToken.
 */
const create = async ({ axios, signal }: RestOptions, appointmentInV1: AppointmentInV1): Promise<AppointmentV1> => {
  const resp = await axios.post(`${appointmentUrl()}/appointments`, appointmentInV1, {
    signal,
  });
  return resp.data;
};

/**
 * Update appointment description of existing appointment.
 */
const updateDescription = async (
  { axios, signal }: RestOptions,
  appointmentId: string,
  appointmentDescriptionInV1: AppointmentDescriptionInV1,
): Promise<void> => {
  await axios.put(`${appointmentUrl()}/appointments/${appointmentId}/description`, appointmentDescriptionInV1, {
    signal,
  });
};

/**
 * Update appointment location of existing appointment.
 */
const updateLocation = async (
  { axios, signal }: RestOptions,
  appointmentId: string,
  appointmentLocationV1: AppointmentLocationV1,
): Promise<void> => {
  await axios.put(`${appointmentUrl()}/appointments/${appointmentId}/location`, appointmentLocationV1, {
    signal,
  });
};

/**
 * Update appointment start timestamp of existing appointment.
 */
const updateStartTimestamp = async (
  { axios, signal }: RestOptions,
  appointmentId: string,
  appointmentStartTimestampInV1: AppointmentStartTimestampInV1,
): Promise<void> => {
  await axios.put(`${appointmentUrl()}/appointments/${appointmentId}/start-timestamp`, appointmentStartTimestampInV1, {
    signal,
  });
};

const updateCustomStatusType = async (
  { axios, signal }: RestOptions,
  appointmentId: string,
  customStatusInV1: CustomStatusInV1,
): Promise<void> => {
  await axios.put(
    `${appointmentUrl()}/appointments/${appointmentId}/customStatus/${customStatusInV1.type}`,
    { value: customStatusInV1.value, localTimestamp: customStatusInV1.localTimestamp },
    {
      signal,
    },
  );
};

export const AppointmentRestServiceV1 = {
  create,
  deleteById,
  getActiveSchedulesByOrgId,
  getById,
  query,
  queryActiveSchedulesByOrganizationIds,
  updateCustomStatusType,
  updateDescription,
  updateLocation,
  updateStartTimestamp,
};
