import { WebHcpNavigationTabsTabsBlock } from '@csp/csp-web-content-definitions';
import { NavTab } from '@csp/web-common';
import { FC } from 'react';
import { CspPath } from '../../../CspPath';
import { NavTabsComponent } from '../component/NavTabsComponent';
import { useSelectedNavTab } from '../hooks/useSelectedNavTab';

type Props = {
  tabs: WebHcpNavigationTabsTabsBlock;
};

export const MainNavTabsContainer: FC<Props> = ({ tabs }) => {
  const mainNavTabs: NavTab<CspPath>[] = [
    {
      icon: 'studyProgress',
      label: tabs.button.overview.label,
      path: CspPath.STUDY_OVERVIEW,
    },
    {
      icon: 'patients',
      label: tabs.button.patients.label,
      path: CspPath.PATIENT_LIST,
    },
    {
      icon: 'team',
      label: tabs.button.teams.label,
      path: CspPath.TEAM_MEMBER_LIST,
    },
    {
      icon: 'site',
      label: tabs.button.sites.label,
      path: CspPath.SITES,
    },
    {
      icon: 'studyStatus',
      label: tabs.button.reports.label,
      path: CspPath.REPORTS,
    },
  ];
  const selectedTab = useSelectedNavTab(mainNavTabs, CspPath.STUDY_OVERVIEW);
  return <NavTabsComponent tabs={mainNavTabs} selectedPath={selectedTab} />;
};
