/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Unit of time UCUM https://www.hl7.org/fhir/valueset-units-of-time.html
 */
export enum AppointmentUnitOfTimeV1 {
    S = 'S',
    MIN = 'MIN',
    H = 'H',
    D = 'D',
    WK = 'WK',
    MO = 'MO',
    A = 'A',
}