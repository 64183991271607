import { RestOptions, UserId } from '@csp/csp-common-model';
import {
  CriteriaType,
  CustomStatusesInV1,
  CustomStatusValueInV1,
  largePage,
  Page,
  QueryV1,
} from '@csp/dmdp-api-common-dto';
import {
  ContactPointInV1,
  EcodeV1,
  ExternalIdValueV1,
  HumanNameV1,
  IdpAccountInV1,
  LocalizationV1,
  MetricsV1,
  OccasionV1,
  PersonalV1,
  RandomizedTimestampInV1,
  ScreenedTimestampInV1,
  UserCriteriaFieldType,
  UserMetaV1,
  UsersV1,
  UserV1,
} from '@csp/dmdp-api-user-dto';
import { IdpAccountAnonymousInV1 } from '@csp/dmdp-api-user-mgmt-dto';
import { userpodUrl } from '../../dmdp-config/dmdpConfig';

const userUrl = (): string => `${userpodUrl()}/user/v1`;

const getMine = async ({ axios, signal }: RestOptions): Promise<UserV1> => {
  const resp = await axios.get(`${userUrl()}/mine`, {
    signal,
  });
  return resp.data;
};

const updateMyHumanName = async ({ axios, signal }: RestOptions, humanName: HumanNameV1): Promise<void> => {
  await axios.put(`${userUrl()}/name`, humanName, {
    signal,
  });
};

const updateMyContactPoint = async (
  { axios, signal }: RestOptions,
  contactPointId: string,
  contactPointInV1: ContactPointInV1,
): Promise<void> => {
  const url = `${userUrl()}/contact/${contactPointId}`;
  await axios.put(url, contactPointInV1, {
    signal,
  });
};

const updateMyPersonal = async ({ axios, signal }: RestOptions, personal: PersonalV1): Promise<void> => {
  await axios.put(`${userUrl()}/personal`, personal, {
    signal,
  });
};

const addMyContactPoint = async ({ axios, signal }: RestOptions, contactPointInV1: ContactPointInV1): Promise<void> => {
  const url = `${userUrl()}/contact`;
  await axios.post(url, contactPointInV1, {
    signal,
  });
};

const deleteMyContactPoint = async ({ axios, signal }: RestOptions, contactPointId: string): Promise<void> => {
  const url = `${userUrl()}/contact/${contactPointId}`;
  await axios.delete(url, {
    signal,
  });
};

const upsertMyCustomStatus = async (
  { axios, signal }: RestOptions,
  type: string,
  statusInV1: CustomStatusValueInV1,
): Promise<void> => {
  const url = `${userUrl()}/customStatus/${type}`;
  await axios.put(url, statusInV1, {
    signal,
  });
};

const upsertMyCustomStatuses = async (
  { axios, signal }: RestOptions,
  statusesInV1: CustomStatusesInV1,
): Promise<void> => {
  const url = `${userUrl()}/customStatus`;
  await axios.put(url, statusesInV1, {
    signal,
  });
};

const deleteMyCustomStatus = async ({ axios, signal }: RestOptions, type: string): Promise<void> => {
  const url = `${userUrl()}/customStatus/${type}`;
  await axios.delete(url, {
    signal,
  });
};

const updateMyMeta = async ({ axios, signal }: RestOptions, userMetaV1: UserMetaV1): Promise<void> => {
  const url = `${userUrl()}/meta`;
  await axios.put(url, userMetaV1, {
    signal,
  });
};

const addMyMeta = async ({ axios, signal }: RestOptions, userMetaV1: UserMetaV1): Promise<void> => {
  const url = `${userUrl()}/meta`;
  await axios.post(url, userMetaV1, {
    signal,
  });
};

const deleteMyMeta = async ({ axios, signal }: RestOptions, metaType: string): Promise<void> => {
  const url = `${userUrl()}/meta/${metaType}`;
  await axios.delete(url, {
    signal,
  });
};

const addOrUpdateMyMeta = async (
  apiOptions: RestOptions,
  mySelfUserV1: UserV1,
  userMetaV1: UserMetaV1,
): Promise<void> => {
  const existingMeta = mySelfUserV1.metas
    ? mySelfUserV1.metas.find(existingMetaV1 => existingMetaV1.type === userMetaV1.type)
    : undefined;
  return existingMeta ? updateMyMeta(apiOptions, userMetaV1) : addMyMeta(apiOptions, userMetaV1);
};

const getUserById = async ({ axios, signal }: RestOptions, userId: UserId): Promise<UserV1> => {
  const resp = await axios.get(`${userUrl()}/${userId}`, {
    signal,
  });
  return resp.data;
};

const getUsersByIds = async ({ axios, signal }: RestOptions, userIds: UserId[]): Promise<UsersV1> => {
  const query: QueryV1 = {
    criterion: {
      fieldCriterion: {
        field: UserCriteriaFieldType.USER_ID,
        type: CriteriaType.IN,
        values: userIds,
      },
    },
  };
  const resp = await axios.post<UsersV1>(`${userUrl()}/query`, query, {
    signal,
  });
  return resp.data;
};

const updateHumanName = async (
  { axios, signal }: RestOptions,
  userId: UserId,
  humanName: HumanNameV1,
): Promise<void> => {
  await axios.put(`${userUrl()}/${userId}/name`, humanName, {
    signal,
  });
};

const updateEcode = async ({ axios, signal }: RestOptions, userId: UserId, ecodeV1: EcodeV1): Promise<void> => {
  await axios.put(`${userUrl()}/${userId}/study/ecode`, ecodeV1, {
    signal,
  });
};

const deleteEcode = async ({ axios, signal }: RestOptions, userId: UserId): Promise<void> => {
  await axios.delete(`${userUrl()}/${userId}/study/ecode`, {
    signal,
  });
};

const updateRandomizedTimestamp = async (
  { axios, signal }: RestOptions,
  userId: UserId,
  randomizedTimestampInV1: RandomizedTimestampInV1,
): Promise<void> => {
  await axios.put(`${userUrl()}/${userId}/study/randomizedTimestamp`, randomizedTimestampInV1, {
    signal,
  });
};

const updateScreenedTimestamp = async (
  { axios, signal }: RestOptions,
  userId: UserId,
  screenedTimestampInV1: ScreenedTimestampInV1,
): Promise<void> => {
  await axios.put(`${userUrl()}/${userId}/study/screenedTimestamp`, screenedTimestampInV1, {
    signal,
  });
};

const updatePersonal = async ({ axios, signal }: RestOptions, userId: UserId, personal: PersonalV1): Promise<void> => {
  await axios.put(`${userUrl()}/${userId}/personal`, personal, {
    signal,
  });
};

const updateMetrics = async ({ axios, signal }: RestOptions, userId: UserId, metrics: MetricsV1): Promise<void> => {
  await axios.put(`${userUrl()}/${userId}/metrics`, metrics, {
    signal,
  });
};

const addOccasion = async ({ axios, signal }: RestOptions, userId: UserId, occasion: OccasionV1): Promise<void> => {
  await axios.post(`${userUrl()}/${userId}/occasion`, occasion, {
    signal,
  });
};

const deleteOccasion = async ({ axios, signal }: RestOptions, userId: UserId, occasionType: string): Promise<void> => {
  await axios.delete(`${userUrl()}/${userId}/occasion/${occasionType}`, {
    signal,
  });
};

const updateOccasion = async ({ axios, signal }: RestOptions, userId: UserId, occasion: OccasionV1): Promise<void> => {
  await axios.put(`${userUrl()}/${userId}/occasion`, occasion, {
    signal,
  });
};

const updateContactPoint = async (
  { axios, signal }: RestOptions,
  userId: UserId,
  contactPointId: string,
  contactPointInV1: ContactPointInV1,
): Promise<void> => {
  const url = `${userUrl()}/${userId}/contact/${contactPointId}`;
  await axios.put(url, contactPointInV1, {
    signal,
  });
};

const addContactPoint = async (
  { axios, signal }: RestOptions,
  userId: UserId,
  contactPointInV1: ContactPointInV1,
): Promise<void> => {
  const url = `${userUrl()}/${userId}/contact`;
  await axios.post(url, contactPointInV1, {
    signal,
  });
};

const deleteContactPoint = async (
  { axios, signal }: RestOptions,
  userId: UserId,
  contactPointId: string,
): Promise<void> => {
  const url = `${userUrl()}/${userId}/contact/${contactPointId}`;
  await axios.delete(url, {
    signal,
  });
};

const upsertCustomStatus = async (
  { axios, signal }: RestOptions,
  userId: UserId,
  type: string,
  statusInV1: CustomStatusValueInV1,
): Promise<void> => {
  const url = `${userUrl()}/${userId}/customStatus/${type}`;
  await axios.put(url, statusInV1, {
    signal,
  });
};

const upsertCustomStatuses = async (
  { axios, signal }: RestOptions,
  userId: UserId,
  statusesInV1: CustomStatusesInV1,
): Promise<void> => {
  const url = `${userUrl()}/${userId}/customStatus`;
  await axios.put(url, statusesInV1, {
    signal,
  });
};

const deleteCustomStatus = async ({ axios, signal }: RestOptions, userId: UserId, type: string): Promise<void> => {
  const url = `${userUrl()}/${userId}/customStatus/${type}`;
  await axios.delete(url, {
    signal,
  });
};

const upsertExternalId = async (
  { axios, signal }: RestOptions,
  userId: UserId,
  idKey: string,
  externalIdInV1: ExternalIdValueV1,
): Promise<void> => {
  const url = `${userUrl()}/${userId}/externalId/${idKey}`;
  await axios.put(url, externalIdInV1, {
    signal,
  });
};

const deleteExternalId = async ({ axios, signal }: RestOptions, userId: UserId, idKey: string): Promise<void> => {
  const url = `${userUrl()}/${userId}/externalId/${idKey}`;
  await axios.delete(url, {
    signal,
  });
};

const updateMeta = async ({ axios, signal }: RestOptions, userId: UserId, userMetaV1: UserMetaV1): Promise<void> => {
  const url = `${userUrl()}/${userId}/meta`;
  await axios.put(url, userMetaV1, {
    signal,
  });
};

const addMeta = async ({ axios, signal }: RestOptions, userId: UserId, userMetaV1: UserMetaV1): Promise<void> => {
  const url = `${userUrl()}/${userId}/meta`;
  await axios.post(url, userMetaV1, {
    signal,
  });
};

const addOrUpdateMeta = async (apiOptions: RestOptions, userV1: UserV1, userMetaV1: UserMetaV1): Promise<void> => {
  const existingMeta = userV1.metas
    ? userV1.metas.find(existingMetaV1 => existingMetaV1.type === userMetaV1.type)
    : undefined;
  return existingMeta
    ? updateMeta(apiOptions, userV1.userId, userMetaV1)
    : addMeta(apiOptions, userV1.userId, userMetaV1);
};

const deleteMeta = async ({ axios, signal }: RestOptions, userId: UserId, metaType: string): Promise<void> => {
  const url = `${userUrl()}/${userId}/meta/${metaType}`;
  await axios.delete(url, {
    signal,
  });
};

const updateIdpAccount = async (
  { axios, signal }: RestOptions,
  userId: UserId,
  idpAccountInV1: IdpAccountInV1,
): Promise<void> => {
  const url = `${userUrl()}/${userId}/idp-accounts`;
  await axios.put(url, idpAccountInV1, {
    signal,
  });
};

const updateIdpAccountAnonymous = async (
  { axios, signal }: RestOptions,
  userId: UserId,
  idpAccountAnonymousInV1: IdpAccountAnonymousInV1,
): Promise<IdpAccountAnonymousInV1> => {
  const url = `${userUrl()}/${userId}/idp-accounts/anonymous`;
  return await axios.put(url, idpAccountAnonymousInV1, {
    signal,
  });
};

const deleteIdpAccount = async (
  { axios, signal }: RestOptions,
  userId: UserId,
  providerId: string,
  idpSub: string,
): Promise<void> => {
  const url = `${userUrl()}/${userId}/idp-accounts/providers/${providerId}/subjects/${idpSub}`;
  await axios.delete(url, {
    signal,
  });
};

const updateLocalization = async (
  { axios, signal }: RestOptions,
  userId: UserId,
  localiazationV1: LocalizationV1,
): Promise<void> => {
  const url = `${userUrl()}/${userId}/localization`;
  await axios.put(url, localiazationV1, {
    signal,
  });
};

const query = async ({ axios, signal }: RestOptions, query: QueryV1, page: Page = largePage()): Promise<UsersV1> => {
  const resp = await axios.post(`${userUrl()}/query`, query, {
    params: page,
    signal,
  });
  return resp.data;
};

export const UserRestServiceV1 = {
  addContactPoint,
  addMeta,
  addMyContactPoint,
  addMyMeta,
  addOccasion,
  addOrUpdateMeta,
  addOrUpdateMyMeta,
  deleteContactPoint,
  deleteCustomStatus,
  deleteEcode,
  deleteIdpAccount,
  deleteMeta,
  deleteMyContactPoint,
  deleteMyCustomStatus,
  deleteMyMeta,
  deleteOccasion,
  deleteExternalId,
  getMine,
  getUserById,
  getUsersByIds,
  query,
  updateContactPoint,
  updateEcode,
  updateIdpAccount,
  updateIdpAccountAnonymous,
  updateHumanName,
  updateLocalization,
  updateMeta,
  updateMetrics,
  updateMyContactPoint,
  updateMyHumanName,
  updateMyMeta,
  updateMyPersonal,
  updateOccasion,
  updatePersonal,
  updateRandomizedTimestamp,
  updateScreenedTimestamp,
  upsertCustomStatus,
  upsertCustomStatuses,
  upsertMyCustomStatus,
  upsertMyCustomStatuses,
  upsertExternalId,
};
