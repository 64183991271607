import { ConsentType } from '@csp/dmdp-api-user-dto';

export type GenericConsentType = ConsentType.EULA | ConsentType.PRIVACY_POLICY;

export const GenericConsentTypes: GenericConsentType[] = [ConsentType.EULA, ConsentType.PRIVACY_POLICY];

export enum GenericConsentTypeUrlParam {
  EULA = 'eula',
  PRIVACY_POLICY = 'privacy-policy',
}
