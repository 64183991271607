import { RefV1 } from '@csp/dmdp-api-common-dto';
import { EnumUtil } from '@csp/csp-common-enum-util';
import { AssessmentRefKey } from './AssessmentRefKey';

export type AssessmentRef = RefV1 & {
  key: AssessmentRefKey;
  value: string;
};

const from = ({ key: keyV1, value }: RefV1): AssessmentRef => ({
  key: EnumUtil.fromStringOrError<AssessmentRefKey>(keyV1, AssessmentRefKey),
  value,
});

export const AssessmentRef = {
  from,
};
