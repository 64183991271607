import { HttpStatus } from '@csp/csp-common-axios';
import { ApiOptions, CspError, RestOptions } from '@csp/csp-common-model';
import { toRestOptions } from '@csp/csp-fe-auth';
import { CachePolicy, ContentstackConfig } from '../../config/model/ContentstackConfig';
import { ContentstackConfigService } from '../../config/service/ContentstackConfigService';
import { ContentPackageType } from '../../model/ContentPackageType';
import { contentstackPackageToUnifyContentPackage } from '../../service/contentstackPackageToUnifyContentPackage';
import { UnifyContentPackage } from '../../types/UnifyContentPackage';
import { getCachedPackage, setCachedPackage } from '../../utils/cacheUtil';
import {
  getCachePolicy,
  getContentClientType,
  getContentLocale,
  getContentVersion,
  getRole,
} from '../../utils/configUtil';
import { PreviewModeConfig } from '../../utils/getPreviewConfig';
import { ContentDeliveryClientError } from '../error/ContentDeliveryClientError';
import { ContentPackagesResponse } from '../model/ContentPackagesResponse';
import { applyMockedContent } from '../utils/applyMockedContent';
import { fetchSignedAssetUrls, signedUrlsHaveExpired } from '../utils/assetUtils';
import { fetchContentPackageWithRetry } from '../utils/fetchContentPackageWithRetry';
import { fetchPreviewConfiguration } from '../utils/previewUtils';
import { getSignedUrlExpirationDateFromContentPackage } from '../utils/signedUrlPackageUtils';
import { toContentRestOptions } from '../utils/toContentRestOptions';
import { ContentDeliveryRestServiceV1 } from './ContentDeliveryRestServiceV1';
import { ContentPreviewRestServiceV1 } from './ContentPreviewRestServiceV1';

/**
 * Fetches the content for all locales in the package. Is used by a  script
 * in csp-mobile to fetch and store all public content when the app is built.
 * @param packageType
 * @param apiOptions
 */
const getContentForAllLocales = async (
  packageType: ContentPackageType,
  apiOptions?: RestOptions,
): Promise<ContentPackagesResponse> =>
  ContentDeliveryRestServiceV1.getContentForAllLocales(
    toContentRestOptions(packageType, apiOptions),
    getContentClientType(),
    getContentVersion(packageType),
    packageType,
    getRole(),
  );

/**
 * Will fetch the package from the content delivery service, OR from directly from Contentstack if preview mode is
 * enabled. If a package exists in the content cache and the etag in the response matches the etag in the cache, it will
 * not fetch the package contents and instead return it from the cache.
 * @param packageType
 * @param apiOptions
 * @param config
 */
const fetchContent = async (
  packageType: ContentPackageType,
  apiOptions?: ApiOptions,
  config?: ContentstackConfig,
): Promise<UnifyContentPackage> => {
  try {
    const maybeCachedPackage = getCachedPackage(packageType, config);
    const contentPackageResponse = await fetchContentPackageWithRetry(
      packageType,
      getContentLocale(config),
      getCachePolicy(config) === CachePolicy.NETWORK_ONLY ? undefined : maybeCachedPackage?.etag,
      apiOptions,
      config,
    );

    if (maybeCachedPackage && contentPackageResponse.status === HttpStatus.NOT_MODIFIED) {
      if (signedUrlsHaveExpired(maybeCachedPackage)) {
        const contentPackage = await fetchSignedAssetUrls(maybeCachedPackage, packageType);
        return applyMockedContent(packageType, contentPackage);
      } else {
        return applyMockedContent(packageType, maybeCachedPackage.content);
      }
    } else if (contentPackageResponse.status === HttpStatus.OK) {
      const contentPackage = contentstackPackageToUnifyContentPackage(contentPackageResponse.content);

      if (getCachePolicy(config) !== CachePolicy.NETWORK_ONLY) {
        await setCachedPackage(
          packageType,
          contentPackageResponse.eTag,
          contentPackage,
          getSignedUrlExpirationDateFromContentPackage(contentPackage),
          config,
        );
      }
      return applyMockedContent(packageType, contentPackage);
    } else {
      throw CspError.badState(
        'Received 304 Not Modified status code without providing an eTag in the request, should not happen',
      );
    }
  } catch (e) {
    if (ContentDeliveryClientError.isNotFound(e) && ContentstackConfigService.get().allowMockedContent) {
      return applyMockedContent(packageType);
    } else {
      throw e;
    }
  }
};

/**
 * Will try to get the package from the cache, if it exists return it.
 * If the package does not exist fetches the package from the content delivery service.
 * @param packageType
 * @param apiOptions
 * @param config
 */
const fetchContentPackage = async (
  packageType: ContentPackageType,
  apiOptions?: ApiOptions,
  config?: ContentstackConfig,
): Promise<UnifyContentPackage> => {
  const previewConfiguration = await fetchPreviewConfiguration(packageType, config, apiOptions);
  if (previewConfiguration.previewModeEnabled) {
    return fetchPreviewContent(packageType, previewConfiguration, apiOptions, config);
  }

  const maybeCachedPackage = getCachedPackage(packageType, config);
  if (
    maybeCachedPackage !== undefined &&
    !maybeCachedPackage.expired &&
    getCachePolicy(config) === CachePolicy.CACHE_FIRST
  ) {
    return applyMockedContent(packageType, maybeCachedPackage.content);
  } else {
    return await fetchContent(packageType, apiOptions, config);
  }
};

/**
 * Fetches content directly from Contentstack.
 * @param packageType
 * @param previewConfiguration
 * @param apiOptions
 * @param config
 */
const fetchPreviewContent = async (
  packageType: ContentPackageType,
  previewConfiguration: PreviewModeConfig,
  apiOptions?: ApiOptions,
  config?: ContentstackConfig,
): Promise<UnifyContentPackage> => {
  if (packageType === 'study') {
    return await fetchStudyPreviewContent(apiOptions, config, previewConfiguration);
  } else {
    return await fetchGenericOrPublicPreviewContent(packageType, apiOptions, config, previewConfiguration);
  }
};

const fetchGenericOrPublicPreviewContent = async (
  packageType: Extract<ContentPackageType, 'public' | 'generic'>,
  apiOptions: Partial<RestOptions> | undefined,
  config: ContentstackConfig | undefined,
  previewConfiguration: PreviewModeConfig,
): Promise<UnifyContentPackage> => {
  const contentData = await ContentPreviewRestServiceV1.getPreviewContent(
    toContentRestOptions(packageType, apiOptions),
    packageType,
    getContentClientType(config),
    getContentLocale(config) as Lowercase<string>,
    { contentEnv: previewConfiguration.contentEnvironment },
  );

  return applyMockedContent(packageType, contentstackPackageToUnifyContentPackage(contentData));
};

const fetchStudyPreviewContent = async (
  apiOptions: Partial<RestOptions> | undefined,
  config: ContentstackConfig | undefined,
  previewConfiguration: PreviewModeConfig,
): Promise<UnifyContentPackage> => {
  const contentData = await ContentPreviewRestServiceV1.getStudyPreviewContent(
    toRestOptions(apiOptions),
    getContentClientType(config),
    getRole(config),
    getContentLocale(config) as Lowercase<string>,
    { contentEnv: previewConfiguration.contentEnvironment },
  );

  return applyMockedContent('study', contentstackPackageToUnifyContentPackage(contentData));
};

export const ContentDeliveryService = {
  fetchContentPackage,
  fetchPreviewContent,
  getContentForAllLocales,
};
