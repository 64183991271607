import { Maybe } from '../../common/model/Monadish';
import { ComplianceValue } from './ComplianceValue';
import { CountMetric } from './CountMetric';

export type ComplianceMetric = {
  totalCompliance: ComplianceValue;
  recentCompliance: Maybe<ComplianceValue>;
};

const from = (threshold: number, countMetric: CountMetric): ComplianceMetric => {
  const totalComplianceValue =
    countMetric.totalCount.expected > 0 ? countMetric.totalCount.actual / countMetric.totalCount.expected : 0;

  const recentComplianceValue =
    countMetric.recentCount !== undefined && countMetric.recentCount.expected
      ? countMetric.recentCount.actual / countMetric.recentCount.expected
      : undefined;

  const isTotalCompliant = totalComplianceValue >= threshold;
  const isRecentCompliant = recentComplianceValue ? recentComplianceValue >= threshold : false;

  return {
    totalCompliance: {
      isCompliant: isTotalCompliant,
      notCompliant: !isTotalCompliant,
      value: totalComplianceValue,
      percentage: totalComplianceValue * 100,
    },
    recentCompliance:
      recentComplianceValue !== undefined
        ? {
            isCompliant: isRecentCompliant,
            notCompliant: !isRecentCompliant,
            value: recentComplianceValue,
            percentage: recentComplianceValue * 100,
          }
        : undefined,
  };
};

export const ComplianceMetric = {
  from,
};
