import { MenuItem } from '@csp/csp-web-ui';
import { FC } from 'react';
import { ZonedDateTimeFormatter, ZonedDateTimeFactory } from '@csp/csp-common-date-util';
import { Callback } from '@csp/csp-common-model';
import { StringUtil } from '@csp/csp-common-util';
import { StaticContentService } from '../../../common/service/StaticContentService';

type Props = {
  onOpen: Callback;
};

export const TimezoneContainer: FC<Props> = ({ onOpen }) => {
  const timezoneDisplayString = ZonedDateTimeFormatter.formatTimezoneDisplayString(ZonedDateTimeFactory.now());
  const { topBar } = StaticContentService.getPublicEntry('webHcpNavigationHeader');
  const withLineBreakOpportunities = StringUtil.insertLineBreakOpportunitiesAfter(timezoneDisplayString, '/');

  return (
    <MenuItem
      onClick={onOpen}
      iconProps={{ name: 'globe' }}
      sx={{ maxWidth: '280px', width: '100%' }}
      title={topBar.button.timezone.label}
      subtitle={withLineBreakOpportunities}
    />
  );
};
