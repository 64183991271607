/* eslint-disable */
export const UnifyWebDeviceSensorMock = {
  title: '[M]Title',
  modelNumber: '[M]HEM-9200T',
  type: '[M]Blood pressure',
  manufacturer: '[M]OMRON',
  image: { url: 'https://via.placeholder.com/300' },
  observationColumnHeaders: {
    MEASUREMENT: '[M]Measurements',
    COMPLETION_STATUS: '[M]Completion status',
    VERIFIED: '[M]Verified',
    TIMEFRAME: '[M]Timeframe',
  },
  sessionColumnHeaders: { REVIEW_STATUS: '[M]Review Status', TIMESTAMP: '[M]Timestamp' },
  measurementValueColumns: [
    { columnKey: 'SYS', headerTitle: '[M]SYS' },
    { columnKey: 'DIA', headerTitle: '[M]DIA' },
    { columnKey: 'PULSE', headerTitle: '[M]PULSE' },
    { columnKey: 'SPO2', headerTitle: '[M]SPO2' },
    { columnKey: 'RR', headerTitle: '[M]RR' },
    { columnKey: 'ACTUATION', headerTitle: '[M]ACTUATION' },
    { columnKey: 'FEV1', headerTitle: '[M]FEV1' },
    { columnKey: 'PEF', headerTitle: '[M]PEF' },
    { columnKey: 'FVC', headerTitle: '[M]FVC' },
    { columnKey: 'FEV1_DIV_FVC', headerTitle: '[M]FEV1/FVC' },
    { columnKey: 'WEIGHT', headerTitle: '[M]WEIGHT' },
  ],
  measurementValueUnits: [
    { measurementName: 'fev1_L', units: [{ unitKey: 'l', unit: '[M] l' }] },
    { measurementName: 'pef_Ls', units: [{ unitKey: 'l_s', unit: '[M] l/s' }] },
    { measurementName: 'fvc_L', units: [{ unitKey: 'l', unit: '[M] l' }] },
    { measurementName: 'fev1_fvc_pcnt', units: [{ unitKey: 'perc', unit: '[M] %' }] },
    { measurementName: 'GROSS_WEIGHT', units: [{ unitKey: 'kg', unit: '[M] kg' }] },
  ],
};
