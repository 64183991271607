/* eslint-disable */
export const UnifyWebDevicesMasimoStreamSchema = {
  description: 'unify:webdevicesmasimostream',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'UnifyWebDevicesMasimoStream',
  type: 'object',
  properties: {
    pageErrors: {
      type: 'object',
      properties: {
        noCorrelationId: {
          type: 'string',
          title: 'No correlation id on the trend record',
          mock: 'No correlation id on the trend record.',
        },
      },
    },
    observationDetails: {
      type: 'object',
      properties: {
        title: { type: 'string', title: 'Observation details card title', mock: 'Details' },
        noStreamData: {
          type: 'string',
          title: 'No streamed data',
          mock: 'No streamed data collected during this measurement',
        },
        measurementVerificationLabel: {
          type: 'string',
          title: 'Measurement verification status label',
          mock: 'Measurement verification status',
        },
      },
    },
    observationValueGraphs: {
      type: 'object',
      title: 'Observation value graph information',
      properties: {
        enum: {
          type: 'object',
          title: 'Observation value graph enumerations',
          properties: {
            graphName: {
              type: 'object',
              title: 'Name of the observation value graphs',
              properties: {
                SPO2: { type: 'string', title: 'Spo2 graph name', mock: 'Spo2' },
                HEART_RATE: { type: 'string', title: 'Heart rate graph name', mock: 'Pulse' },
                RR: { type: 'string', title: 'Respiration rate graph name', mock: 'RR' },
              },
            },
          },
        },
      },
    },
    observationGraphsCard: {
      type: 'object',
      properties: {
        cardActionButton: {
          type: 'string',
          title: 'Button text on card action to view Frequently asked questions',
          mock: 'View FAQs',
        },
      },
    },
    faqDrawer: {
      type: 'object',
      properties: {
        drawerHeader: { type: 'string', title: 'Header of FAQ drawer', mock: 'Help' },
        drawerSubTitle: { type: 'string', title: 'Subtitle of FAQ drawer', mock: 'Frequently Asked Questions (FAQs)' },
        faqItems: {
          type: 'array',
          title: 'FAQ Entries in drawer',
          items: {
            title: 'FAQ Entries',
            type: 'object',
            properties: {
              question: { title: 'The FAQ question', type: 'string' },
              answer: { title: 'The FAQ anwser', type: 'string' },
            },
          },
          mock: [
            {
              question: 'Info 1',
              answer: "Here's some body text to explain in more detail what you want to elaborate on fromthe subtitle",
            },
            {
              question: 'Info 2',
              answer: "Here's some body text to explain in more detail what you want to elaborate on fromthe subtitle",
            },
            {
              question: 'Info 3',
              answer: "Here's some body text to explain in more detail what you want to elaborate on fromthe subtitle",
            },
          ],
        },
      },
    },
  },
};
