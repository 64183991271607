import { Dcode, Maybe, Study, TenantId, User } from '@csp/csp-common-model';
import { omitUndefined } from '@csp/csp-common-util';
import { TelemetryTags } from '@csp/csp-web-telemetry';
import { IdpType } from '@csp/dmdp-api-user-dto';

export type CspTelemetryTags = TelemetryTags<{
  dcode?: Dcode;
  tenantId?: TenantId;
  identityProvider?: IdpType;
  roles?: string;
  locale?: string;
}>;

export const from = (user: Maybe<User>, study: Maybe<Study>, locale: Maybe<string>): CspTelemetryTags =>
  omitUndefined({
    dcode: study?.dcode,
    tenantId: study?.tenantId,
    identityProvider: user?.firstAccount?.provider,
    roles: user?.roles.join(', '),
    locale,
  });

export const CspTelemetryTags = {
  from,
};
