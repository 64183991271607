/* eslint-disable */
export const UnifyPodEmailSchema = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  description: 'unify:podemail',
  _doc: 'c66bfbee7f402455d905',
  title: 'UnifyPodEmail',
  type: 'object',
  properties: {
    title: { type: 'string', title: 'Title' },
    healthEvent: {
      type: 'array',
      title: 'The health event emails is sent to CRA and HCPs when actions are needed',
      helper:
        'When a patient reports and health event the HCP must act quickly to follow up if the health issue was connected to the study or not. These emails will notify personnel to take action',
      items: {
        type: 'object',
        properties: {
          type: {
            type: 'string',
            title: 'Define the type of email. There are two different emails.',
            helper:
              'ONCE: The email is only sent once per patient and health event. Typically receivers are study assistant and investigators instantly. REPEATEDLY: The email will be sent until no more health events needs action. Typically receivers are study CRA and PI and once på day.',
            enum: ['ONCE', 'REPEATEDLY'],
          },
          text: {
            type: 'object',
            title: 'Texts for email',
            properties: {
              subject: {
                type: 'string',
                title: 'Email subject',
                helper:
                  'The subject supports one dynamic value ${studyCode}. Example: Potential health event needs review in Unfiy study ${studyCode}',
                maxLength: 78,
                mock: 'Potential health event needs review in Unify study ${studyCode}',
              },
              bodyTopSectionMd: {
                type: 'string',
                title: 'Text section above before the table containing the patient information',
                helper:
                  'The section has one dynamic attribute ${days} which can be used in REPEATEDLY email to tell number of days that had past for the health events in the email ',
                mock: 'A new potential health event has been reported.Please review this event in Unify and determine whether it is related to study or not.',
              },
              bodyGreetingMd: { type: 'string', title: 'Greeting text.', mock: 'Sincerely,  Unify Team' },
              bodyFooterSectionMd: {
                type: 'string',
                title: 'A text section after greetings.',
                mock: 'Note: This is an automatically generated email, which you cannot reply to. If you have questions please contact appropriate team.',
              },
            },
          },
          button: {
            type: 'object',
            title: 'Email links',
            properties: { unifyLink: { type: 'string', title: 'Link to Unify portal' } },
          },
          title: {
            type: 'object',
            title: 'Titles for email',
            properties: {
              bodyTitle: { type: 'string', title: 'Title for email body' },
              colSubject: { type: 'string', title: 'Subject table column', mock: 'Subject' },
              colTimestamp: { type: 'string', title: 'Timestamp table column', mock: 'Health event reported at' },
              colStatus: { type: 'string', title: 'Status table column', mock: 'Status ' },
              colSiteId: { type: 'string', title: 'Site Id table column', mock: 'Site Id' },
              colSiteName: { type: 'string', title: 'Site name column', mock: 'Site name' },
              colStudy: { type: 'string', title: 'Study D-code table column', mock: 'Study' },
            },
          },
        },
      },
      mock: [
        {
          type: 'ONCE',
          text: {
            subject: 'Action needed: Potential health event needs review in Unify study ${studyCode}',
            bodyTopSectionMd:
              'A new potential health event has been reported.\nPlease review this event in Unify and determine whether it is related to study or not.',
            bodyGreetingMd: 'Sincerely,\nUnify Team',
            bodyFooterSectionMd:
              '__Note__: This is an automatically generated email, which you cannot reply to. If you have questions please contact appropriate team.',
          },
          button: { unifyLink: 'Visit unify portal to review this events' },
          title: {
            bodyTitle: 'Unify Health Events',
            colSubject: 'Subject',
            colTimestamp: 'Reported at',
            colStatus: 'Status ',
            colSiteId: 'Site Id',
            colSiteName: 'Site name',
            colStudy: 'Study',
          },
        },
        {
          type: 'REPEATEDLY',
          text: {
            subject: 'Potential health event needs review in Unify study ${studyCode}',
            bodyTopSectionMd:
              'The following health events have been reported more than ${days} days ago and not yet been solved by responsible study site.\nPlease contact the responsible site staff to ensure these events are resolved as soon as possible',
            bodyGreetingMd: 'Sincerely,\nUnify Team',
            bodyFooterSectionMd:
              '__Note__: This is an automatically generated email, which you cannot reply to. If you have questions please contact appropriate team.',
          },
          button: { unifyLink: 'Visit unify portal to review this events' },
          title: {
            bodyTitle: 'Unify Health Events',
            colSubject: 'Subject',
            colTimestamp: 'Reported at',
            colStatus: 'Status ',
            colSiteId: 'Site Id',
            colSiteName: 'Site name',
            colStudy: 'Study',
          },
        },
      ],
    },
    rpmEvent: {
      type: 'object',
      title: 'The reported event emails is sent to HCPs when actions are needed',
      helper:
        'Unify users have to be reminded outside of Unify there are open symptom notifications for them in the Unify portal to ensure they are highlighted if one or several patients have severe symptoms so web roles (study configurable) can take action as appropriate. ',
      properties: {
        text: {
          type: 'object',
          title: 'Texts for email',
          properties: {
            subject: {
              type: 'string',
              title: 'Email subject',
              helper: 'Email subject to be displayed at the top of the email',
              maxLength: 78,
              mock: 'Reported event',
            },
            bodyTopSectionMd: {
              type: 'string',
              title: 'Text section above before the table containing the patient information',
              helper: 'Description of actions that needs to be taken (study specific) ',
              mock: 'A new event has been reported.Please review this event in Unify and take an action.',
            },
            bodyGreetingMd: { type: 'string', title: 'Greeting text.', mock: 'Sincerely,  Unify Team' },
            bodyFooterSectionMd: {
              type: 'string',
              title: 'A text section after greetings.',
              mock: 'Note: This is an automatically generated email, which you cannot reply to. If you have questions please contact appropriate team.',
            },
          },
        },
        button: {
          type: 'object',
          title: 'Email links',
          properties: { unifyLink: { type: 'string', title: 'Link to Unify portal' } },
        },
        title: {
          type: 'object',
          title: 'Titles for email',
          properties: {
            bodyTitle: { type: 'string', title: 'Title for email body' },
            colSubject: { type: 'string', title: 'Subject table column', mock: 'Subject' },
            colEventType: { type: 'string', title: 'Notice type table column', mock: 'Notice type' },
            colTimestamp: { type: 'string', title: 'Timestamp table column', mock: 'Recieved at' },
            colStatus: { type: 'string', title: 'Status table column', mock: 'Status ' },
            colSiteId: { type: 'string', title: 'Site Id table column', mock: 'Site Id' },
            colSiteName: { type: 'string', title: 'Site name column', mock: 'Site name' },
            colStudy: { type: 'string', title: 'Study D-code table column', mock: 'Study' },
          },
        },
      },
      mock: {
        text: {
          subject: 'Reported event',
          bodyTopSectionMd:
            'A new event has been reported.\nPlease review this event in Unify and take action as needed.',
          bodyGreetingMd: 'Sincerely,\nUnify Team',
          bodyFooterSectionMd:
            '__Note__: This is an automatically generated email, which you cannot reply to. If you have questions please contact appropriate team.',
        },
        button: { unifyLink: 'Visit unify portal to review this events' },
        title: {
          bodyTitle: 'New notices to view',
          colSubject: 'Subject',
          colEventType: 'Notice type',
          colTimestamp: 'Recieved on',
          colStatus: 'Status ',
          colSiteId: 'Site Id',
          colSiteName: 'Site name',
          colStudy: 'Study',
        },
      },
    },
    complianceNotification: {
      type: 'object',
      title: 'New compliance notices',
      helper:
        'When patient compliance is low the HCP must act quickly to follow up with them. These emails will notify personnel to take action',
      properties: {
        text: {
          type: 'object',
          title: 'Texts for email',
          properties: {
            subject: {
              type: 'string',
              title: 'Email subject',
              helper:
                'The subject supports one dynamic value ${studyCode}. Example: New compliance notices for study ${studyCode}.',
              maxLength: 78,
              mock: 'New compliance notices for study ${studyCode}.',
            },
            bodyTopSectionMd: {
              type: 'string',
              title: 'Text section above before the table containing the patient information',
              helper: 'Description of actions that needs to be taken (study specific)',
              mock: 'Compliance for the following patient(s) is outside of the agreed threshold for questionnaires or medical devices. Please contact all of these patients to check if they need support.',
            },
            bodySectionMd: {
              type: 'string',
              title: 'Text section above before the table containing the patient information',
              helper:
                'The subject supports one dynamic value ${numPatients}. Example: Number of patients: ${numPatients}.',
              mock: 'Number of patients: __${numPatients}__',
            },
            bodyGreetingMd: { type: 'string', title: 'Greeting text.', mock: 'From,\nUnify Team' },
            bodyFooterSectionMd: {
              type: 'string',
              title: 'A text section after greetings.',
              mock: '__Note__: This is an automatically generated email, which you cannot reply to. If you have questions please contact appropriate team.',
            },
          },
        },
        noticeTypes: {
          type: 'array',
          title: 'Notice types',
          helper: 'Defines each possible notice type',
          items: {
            type: 'object',
            title: 'Notice type Content',
            required: ['type', 'title'],
            properties: {
              type: {
                title: 'Type',
                type: 'string',
                helper: 'Key to find title',
                enum: ['COMPLIANCE_BELOW_THRESHOLD'],
              },
              title: { title: 'Title', helper: 'The title of the notice type', type: 'string' },
            },
          },
        },
        title: {
          type: 'object',
          title: 'Titles for email',
          properties: {
            bodyTitle: { type: 'string', title: 'Title for email body', mock: 'New compliance notices' },
            colPatientId: { type: 'string', title: 'Patient E-code', mock: 'Patient' },
            colNoticeType: { type: 'string', title: 'Notice type table column', mock: 'Notice type' },
            colLastReceivedData: {
              type: 'string',
              title: 'Last received data table column',
              mock: 'Last recieved data',
            },
            colSiteId: { type: 'string', title: 'Site Id table column', mock: 'Site Id' },
            colSiteName: { type: 'string', title: 'Site name column', mock: 'Site name' },
            colStudy: { type: 'string', title: 'Study D-code table column', mock: 'Study' },
          },
        },
        button: {
          type: 'object',
          title: 'Email links',
          properties: { unifyLink: { type: 'string', title: 'Link to Unify portal' } },
        },
      },
      mock: {
        text: {
          subject: 'New compliance notices for study ${studyCode}.',
          bodyTopSectionMd:
            'Compliance for the following patient(s) is outside of the agreed threshold for questionnaires or medical devices. Please contact all of these patients to check if they need support.',
          bodySectionMd: 'Number of patients: __${numPatients}__',
          bodyGreetingMd: 'From,\nUnify Team',
          bodyFooterSectionMd:
            '__Note__: This is an automatically generated email, which you cannot reply to. If you have questions please contact appropriate team.',
        },
        noticeTypes: [{ type: 'COMPLIANCE_BELOW_THRESHOLD', title: 'Compliance below threshold' }],
        title: {
          bodyTitle: 'New compliance notices',
          colPatientId: 'Patient',
          colNoticeType: 'Notice type',
          colLastReceivedData: 'Last recieved data',
          colSiteId: 'Site Id',
          colSiteName: 'Site name',
          colStudy: 'Study',
        },
        button: { unifyLink: 'Visit Unify for more information' },
      },
    },
    singleSymptomEventNotice: {
      type: 'object',
      title: 'New urgent symptom notice',
      helper:
        'Patient reported single symptom event triggers a notice to HCP, as a reminder to take action. Could be from a questionnaire or medical device that triggers a single event.',
      properties: {
        text: {
          type: 'object',
          title: 'Texts for email',
          properties: {
            subject: {
              type: 'string',
              title: 'Email subject',
              helper:
                'The subject supports one dynamic value ${studyCode}. Example: New urgent symptom notice for study ${studyCode}.',
              maxLength: 78,
              mock: 'New urgent symptom notice for study ${studyCode}.',
            },
            bodyTopSectionMd: {
              type: 'string',
              title: 'Text section above before the table containing general information',
              helper: 'Description of actions that needs to be taken (study specific)',
              mock: 'A symptom for this patient has been reported which requires your attention. Please visit the Unify portal to view the full symptom notice for this study.',
            },
            bodyGreetingMd: { type: 'string', title: 'Greeting text.', mock: 'From,\nUnify Team' },
            bodyFooterSectionMd: {
              type: 'string',
              title: 'A text section after greetings.',
              mock: '__Note__: This is an automatically generated email, which you cannot reply to. If you have questions please contact appropriate team.',
            },
          },
        },
        title: {
          type: 'object',
          title: 'Titles for email',
          properties: {
            bodyTitle: { type: 'string', title: 'Title for email body', mock: 'New urgent symptom notice' },
            colPatientId: { type: 'string', title: 'Patient E-code', mock: 'Patient' },
            colNoticeType: { type: 'string', title: 'Notice type table column', mock: 'Notice type' },
            colReceivedOn: { type: 'string', title: 'Received on table column', mock: 'Received on' },
            colSiteId: { type: 'string', title: 'Site Id table column', mock: 'Site Id' },
            colSiteName: { type: 'string', title: 'Site name column', mock: 'Site name' },
            colStudy: { type: 'string', title: 'Study D-code table column', mock: 'Study' },
          },
        },
        button: {
          type: 'object',
          title: 'Email links',
          properties: { unifyLink: { type: 'string', title: 'Visit Unify to view notice' } },
        },
      },
      mock: {
        text: {
          subject: 'New urgent symptom notice for study ${studyCode}.',
          bodyTopSectionMd:
            'A symptom for this patient has been reported which requires your attention. Please visit the Unify portal to view the full symptom notice for this study.',
          bodyGreetingMd: 'From,\nUnify Team',
          bodyFooterSectionMd:
            '__Note__: This is an automatically generated email, which you cannot reply to. If you have questions please contact appropriate team.',
        },
        title: {
          bodyTitle: 'New urgent symptom notice',
          colPatientId: 'Patient',
          colNoticeType: 'Notice type',
          colReceivedOn: 'Received on',
          colSiteId: 'Site Id',
          colSiteName: 'Site name',
          colStudy: 'Study',
        },
        button: { unifyLink: 'Visit Unify to view notice' },
      },
    },
  },
};
