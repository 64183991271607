import { PatientInfoMetaV1 } from '../../dto/UserMetaV1';
import { PatientInfoV1 } from './PatientInfoV1';

export const PATIENT_INFO_V1_META_TYPE = 'PATIENT_INFO_V1';

export const createPatientInfoMetaV1 = (patientInfoV1: PatientInfoV1): PatientInfoMetaV1 => ({
  type: PATIENT_INFO_V1_META_TYPE,
  name: PATIENT_INFO_V1_META_TYPE,
  data: patientInfoV1,
});
