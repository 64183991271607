import { fromTimezoneStrOrUndefined } from '@csp/csp-common-date-util';
import { emptyPatientAppInfo, Maybe, PatientAppInfo, PatientAppInfoIn } from '@csp/csp-common-model';
import { getMeta } from '@csp/csp-common-util';
import { axiosDmdp } from '@csp/csp-fe-auth';
import { UserRestServiceV1 } from '@csp/dmdp-api-client';
import { createPatientAppInfoMetaV1, PatientAppInfoV1, UserMetaV1, UserV1 } from '@csp/dmdp-api-user-dto';

const toPatientAppInfo = (patientAppInfoV1: PatientAppInfoV1): PatientAppInfo => ({
  ...patientAppInfoV1,
  updatedAt: fromTimezoneStrOrUndefined(patientAppInfoV1.updatedAt),
});

const updateMyPatientAppInfo = async (user: UserV1, patientAppInfo: PatientAppInfoIn): Promise<void> =>
  UserRestServiceV1.addOrUpdateMyMeta({ axios: axiosDmdp() }, user, createPatientAppInfoMetaV1(patientAppInfo));

const getPatientAppInfo = (userV1: UserV1): PatientAppInfo => {
  const metas = userV1.metas as Maybe<UserMetaV1[]>;
  const patientAppInfoV1 = getMeta(metas, 'PATIENT_APP_INFO_V1')?.data;
  return patientAppInfoV1 ? toPatientAppInfo(patientAppInfoV1) : emptyPatientAppInfo;
};

export const PatientAppInfoService = {
  updateMyPatientAppInfo,
  getPatientAppInfo,
};
