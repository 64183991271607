/* eslint-disable */
export const UnifyWebPatientTrainingMock = {
  viewMoreLabel: '[M]View more (${nrOfTrainingItems})',
  title: '[M]Training Status',
  outdatedLabel: '[M]Older Version',
  completionTime: '[M]Completion Time',
  noData: '[M]No Data',
  genericTrainingTitle: '[M]Patient Unify training',
  trainingType: '[M]Type',
  trainingTitle: '[M]Name',
};
