import { useEffect } from 'react';
import { useClientSessionTimer } from './useClientSessionTimer';

export const useIdleTimer = function (duration: number, callback: () => void): void {
  const { resetTimer } = useClientSessionTimer(duration, callback);

  useEffect(() => {
    const addEventListeners = (): void => {
      document.addEventListener('click', resetTimer, false);
      document.addEventListener('keydown', resetTimer, false);
    };

    const removeEventListeners = (): void => {
      document.removeEventListener('click', resetTimer);
      document.removeEventListener('keydown', resetTimer);
    };
    addEventListeners();

    return (): void => {
      removeEventListeners();
    };
  }, [resetTimer]);
};
