import { useCallback, useState } from 'react';
import { BioSampleCodeInputContextState } from './BioSampleCodeInputContextState';
import { BioSampleCodeInputBlocker } from './BioSampleCodeInputBlocker';

export const useBioSampleCodeInputContextProvider = (): BioSampleCodeInputContextState => {
  const [bioSampleCodeInputBlockers, setBioSampleCodeInputBlockers] = useState<BioSampleCodeInputBlocker[]>([]);

  const handleRegisterBlocker = useCallback(
    (blocker: BioSampleCodeInputBlocker): void => {
      setBioSampleCodeInputBlockers(current => [
        ...current.filter(currentBlocker => currentBlocker.id !== blocker.id),
        blocker,
      ]);
    },
    [setBioSampleCodeInputBlockers],
  );

  const isBioSampleCodeInputDisabled = bioSampleCodeInputBlockers.some(blocker => blocker.isBlocking);

  return {
    isBioSampleCodeInputDisabled,
    registerBlocker: handleRegisterBlocker,
  };
};
