import { StateAssert } from '@csp/csp-common-model';
import { isDefined } from '@csp/csp-common-util';
import {
  DataChangeCriteriaFieldsV1,
  DataChangeQueryCriterionV1,
  DataChangeQueryOperatorV1,
  DataChangeQueryTypeV1,
  DataChangeQueryV1,
} from '@csp/dmdp-api-data-change-dto';
import { isEmpty } from 'lodash';
import { DataChangeQueryByDataSource } from '../model/DataChangeQueryByDataSource';
import { DataChangeQueryFilter } from '../model/DataChangeQueryFilter';

const byDataSource = ({ dataSource, dataSourceId, sort }: DataChangeQueryByDataSource): DataChangeQueryV1 => {
  StateAssert.isTrue(!isEmpty(dataSource), 'Data source must be set');
  StateAssert.isTrue(!isEmpty(dataSourceId), 'Data source id must be set');

  return {
    criterion: {
      operator: DataChangeQueryOperatorV1.AND,
      criteria: [
        {
          fieldCriterion: {
            field: DataChangeCriteriaFieldsV1.DATA_SOURCE,
            type: DataChangeQueryTypeV1.EQ,
            values: [dataSource],
          },
        },
        {
          fieldCriterion: {
            field: DataChangeCriteriaFieldsV1.DATA_SOURCE_ID,
            type: DataChangeQueryTypeV1.EQ,
            values: [dataSourceId],
          },
        },
      ],
    },
    sort,
  };
};

const byFilter = ({ criteria, sort }: DataChangeQueryFilter): DataChangeQueryV1 => {
  StateAssert.isTrue(!isEmpty(criteria), 'Filter criteria must be set');

  const fieldCriteria: DataChangeQueryCriterionV1[] = Object.keys(criteria)
    .map(filterKey => {
      switch (filterKey) {
        case 'statuses':
          return criteria.statuses?.length
            ? {
                fieldCriterion: {
                  field: DataChangeCriteriaFieldsV1.REVIEW_STATUS,
                  type: DataChangeQueryTypeV1.IN,
                  values: criteria.statuses,
                },
              }
            : undefined;
        case 'siteIds':
          return criteria.siteIds?.length
            ? {
                fieldCriterion: {
                  field: DataChangeCriteriaFieldsV1.STUDY_SITE_ID,
                  type: DataChangeQueryTypeV1.IN,
                  values: criteria.siteIds,
                },
              }
            : undefined;
        case 'dataNames':
          return criteria.dataNames?.length
            ? {
                fieldCriterion: {
                  field: DataChangeCriteriaFieldsV1.DATA_SOURCE_GROUP,
                  type: DataChangeQueryTypeV1.IN,
                  values: criteria.dataNames,
                },
              }
            : undefined;
        case 'eCodes':
          return criteria.eCodes?.length
            ? {
                fieldCriterion: {
                  field: DataChangeCriteriaFieldsV1.ECODE,
                  type: DataChangeQueryTypeV1.IN,
                  values: criteria.eCodes,
                },
              }
            : undefined;
        default:
          throw new Error(`Invalid filter key: ${filterKey}`);
      }
    })
    .filter(isDefined);

  return {
    criterion: {
      operator: DataChangeQueryOperatorV1.AND,
      criteria: [...fieldCriteria],
    },
    sort,
  };
};

export const DataChangeQueryMapper = { byDataSource, byFilter };
