import { HealthEventStatusTypeV1 } from '@csp/dmdp-api-healthevent-dto';
import { CspError } from '../fault/error/CspError';
import { CspErrorType } from '../fault/error/CspErrorType';

export type HealthEventStatusType = 'NEW' | 'VIEWED';

const from = (status: HealthEventStatusTypeV1): HealthEventStatusType => {
  switch (status) {
    case 'NEW':
    case 'ONGOING':
    case 'NO_ENDPOINT':
      return 'NEW';
    case 'POT_ENDPOINT':
    case 'VIEWED':
      return 'VIEWED';
    default:
      throw new CspError(
        CspErrorType.UNHANDLED_CLIENT_ERROR,
        `Unknown HealthEventStatusTypeV1 '${status}'. Unable to map to HealthEventStatusType.`,
      );
  }
};

export const HealthEventStatusType = {
  from,
};
