/* eslint-disable */
export const UnifyWebDeviceSensorSchema = {
  description: 'unify:webdevicesensor',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'UnifyWebDeviceSensor',
  type: 'object',
  required: ['modelNumber', 'manufacturer'],
  properties: {
    title: { type: 'string', title: 'Title', mock: 'Title' },
    modelNumber: { type: 'string', title: 'DeviceModelNumber', mock: 'HEM-9200T' },
    type: { type: 'string', title: 'Sensor Type', mock: 'Blood pressure' },
    manufacturer: { type: 'string', title: 'Manufacturer Name', mock: 'OMRON' },
    image: {
      type: 'object',
      title: 'Sensor Image',
      helper: 'An image of the sensor',
      _attachment: { id: 'sensorImage' },
    },
    observationColumnHeaders: {
      type: 'object',
      title: 'Device Observation column headers',
      properties: {
        MEASUREMENT: {
          title: 'Measurement column header',
          type: 'string',
          mock: 'Measurements',
          helper: '*DEPRECATED* Not used in 3.0 and after.',
        },
        COMPLETION_STATUS: { title: 'Completion Status column header', type: 'string', mock: 'Completion status' },
        VERIFIED: { title: 'Verified column header', type: 'string', mock: 'Verified' },
        TIMEFRAME: { title: 'Timeframe column header', type: 'string', mock: 'Timeframe' },
      },
    },
    sessionColumnHeaders: {
      type: 'object',
      title: 'Device session column headers',
      properties: {
        REVIEW_STATUS: { title: 'Completion Status column header', type: 'string', mock: 'Review Status' },
        TIMESTAMP: { title: 'Timestamp column header', type: 'string', mock: 'Timestamp' },
      },
    },
    measurementValueColumns: {
      type: 'array',
      title: 'Measurement values headers',
      helper: 'Define each header for measurement values used by this device',
      items: {
        type: 'object',
        properties: {
          columnKey: {
            title: 'Key to configured column',
            type: 'string',
            helper: 'Each column is configured with a key to be identified with the same key in the CMS',
          },
          headerTitle: {
            title: 'Measurement value header text',
            helper: 'The text for the measurement values header',
            type: 'string',
          },
        },
      },
      mock: [
        { columnKey: 'SYS', headerTitle: '[M]SYS' },
        { columnKey: 'DIA', headerTitle: '[M]DIA' },
        { columnKey: 'PULSE', headerTitle: '[M]PULSE' },
        { columnKey: 'SPO2', headerTitle: '[M]SPO2' },
        { columnKey: 'RR', headerTitle: '[M]RR' },
        { columnKey: 'ACTUATION', headerTitle: '[M]ACTUATION' },
        { columnKey: 'FEV1', headerTitle: '[M]FEV1' },
        { columnKey: 'PEF', headerTitle: '[M]PEF' },
        { columnKey: 'FVC', headerTitle: '[M]FVC' },
        { columnKey: 'FEV1_DIV_FVC', headerTitle: '[M]FEV1/FVC' },
        { columnKey: 'WEIGHT', headerTitle: '[M]WEIGHT' },
      ],
    },
    measurementValueUnits: {
      type: 'array',
      title: 'Measurement value units',
      helper:
        'Define each units for each value produced by the device. If no unit is wanted to a specific value -> Leave that out.',
      items: {
        type: 'object',
        properties: {
          measurementName: {
            title: 'Name of measurement value',
            helper: 'The name of a specific measurement value produced by the device',
            type: 'string',
          },
          units: {
            title: 'Units used by measurement value',
            type: 'array',
            helper:
              'The units used by this measurement. If device does not specify unit, the one item specified in this list will apply for all',
            items: {
              type: 'object',
              properties: {
                unitKey: {
                  title: 'Key of unit',
                  helper: 'Key of unit defined in the configuration of device',
                  type: 'string',
                },
                unit: { title: 'Unit text', type: 'string' },
              },
            },
          },
        },
      },
      mock: [
        { measurementName: 'fev1_L', units: [{ unitKey: 'l', unit: '[M] l' }] },
        { measurementName: 'pef_Ls', units: [{ unitKey: 'l_s', unit: '[M] l/s' }] },
        { measurementName: 'fvc_L', units: [{ unitKey: 'l', unit: '[M] l' }] },
        { measurementName: 'fev1_fvc_pcnt', units: [{ unitKey: 'perc', unit: '[M] %' }] },
        { measurementName: 'GROSS_WEIGHT', units: [{ unitKey: 'kg', unit: '[M] kg' }] },
      ],
    },
  },
};
