import { StudyConfig, StudyConfigService } from '@csp/csp-common-config';
import { Maybe } from '@csp/csp-common-model';
import { toError } from '@csp/csp-common-util';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

export const loadStudyConfigThunk = createAsyncThunk<Maybe<StudyConfig>, undefined, { rejectValue: Error }>(
  'load_study_config',
  async (_, { rejectWithValue }) => {
    try {
      return await StudyConfigService.refreshStudyConfig();
    } catch (error) {
      return rejectWithValue(toError(error));
    }
  },
);

export const resetStudyConfigAction = createAction('reset_study_config');
