import { ScheduleVersionRequestRef } from '@csp/csp-common-scheduling';

export const DefaultScheduleCodes = {
  DEFAULT_SCHEDULE_CODE: 'DEFAULT_SCHEDULE',
  DEFAULT_VERSION_CODE: 'DEFAULT_VERSION',
  DEFAULT_UNSCHEDULED_REQUEST_CODE: 'DEFAULT_UNSCHEDULED',
};

export const DEFAULT_UNSCHEDULED_REQUEST_REF: ScheduleVersionRequestRef = {
  scheduleCode: DefaultScheduleCodes.DEFAULT_SCHEDULE_CODE,
  versionCode: DefaultScheduleCodes.DEFAULT_VERSION_CODE,
  requestCode: DefaultScheduleCodes.DEFAULT_UNSCHEDULED_REQUEST_CODE,
};
