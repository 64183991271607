import { CspErrorType } from '@csp/csp-common-model';

export type ErrorMessages = Partial<{
  checkboxRequired: string;
  containsLowerCase: string;
  containsNumber: string;
  containsSpecialCharacter: string;
  containsUpperCase: string;
  correctHumanMeasurement: string;
  date: string;
  dateTime: string;
  dateOfBirth: string;
  ecode: string;
  email: string;
  inFuture: string;
  inPast: string;
  internationalPhoneNumber: string;
  maxDate: string;
  maxLength: string;
  minDate: string;
  minTime: string;
  maxTime: string;
  minLength: string;
  minArrayLength: string;
  maxArrayLength: string;
  minValue: string;
  maxValue: string;
  notEqualTo: string;
  notPast?: string;
  notPastTime?: string;
  oneOf: string;
  phoneNumber: string;
  required: string;
  time: string;
  timeStep: string;
  numericStep: string;
  numberOfDecimals: string;
  unexpected: string;
  validNumber: string;
}> &
  Partial<Record<CspErrorType, string>>;

export const DEFAULT_ERROR_MESSAGES: ErrorMessages = {
  checkboxRequired: '[M]Required',
  containsLowerCase: '[M]Must contain one lowercase letter',
  containsNumber: '[M]Must contain one number',
  containsSpecialCharacter: '[M]Must contain special character',
  containsUpperCase: '[M]Must contain one uppercase letter',
  correctHumanMeasurement: '[M]Use a number between 1 and 999.999 and dot as decimal separator',
  date: '[M]Invalid date',
  dateTime: '[M]Invalid date time',
  dateOfBirth: '[M]Not acceptable format',
  ecode: '[M]Must be an E-code, format E1234567',
  email: '[M]Invalid email',
  inFuture: '[M]Value is not a future date',
  inPast: '[M]Value is a future date',
  internationalPhoneNumber: '[M]Invalid phone number',
  maxDate: '[M]Date must be ${ maxDate } or before',
  maxLength: '[M]Length must be at max ${ maxLength } characters',
  minDate: '[M]Date must be ${ minDate } or later',
  minTime: '[M]Time must be ${ minTime } or later',
  maxTime: '[M]Time must be ${ maxTime } or before',
  minLength: '[M]Length must be at least ${ minLength } characters',
  minArrayLength: '[M]At least ${ minLength } options must be selected',
  maxArrayLength: '[M]Max ${ maxLength } options can be selected',
  minValue: '[M]Value must be at least ${ minValue }',
  maxValue: '[M]Value can not be higher than ${ maxValue }',
  notEqualTo: '[M]New value must be different from current value.',
  notPast: '[M]Value is a past date',
  notPastTime: '[M]Time can not be in the past',
  oneOf: '[M]Must be one of: ${ allowedValues }',
  phoneNumber: '[M]Invalid phone number',
  required: '[M]Required',
  time: '[M]Invalid time format',
  timeStep: '[M]Time must be given in ${ step } minute steps',
  numericStep: '[M]Value must be given in steps of ${ step }',
  numberOfDecimals: '[M]Value must have ${ numberOfDecimals } decimals',
  unexpected: '[M]Unexpected error occurred',
  validNumber: '[M]Invalid number',
  [CspErrorType.AUTHENTICATION_FAILED]: '[M]Authentication failed',
  [CspErrorType.BAD_REQUEST]: '[M]Bad request',
  [CspErrorType.BAD_STATE]: '[M]Bad state',
  [CspErrorType.CANCELLED]: '[M]Cancelled',
  [CspErrorType.CONFLICT]: '[M]Conflict',
  [CspErrorType.EXPIRED]: '[M]Expired',
  [CspErrorType.FORBIDDEN]: '[M]Forbidden',
  [CspErrorType.GONE]: '[M]Gone',
  [CspErrorType.HTTP_COMMUNICATION]: '[M]HTTP Communication',
  [CspErrorType.INVALID_CONFIGURATION]: '[M]Invalid configuration',
  [CspErrorType.MALFORMED]: '[M]Malformed',
  [CspErrorType.NETWORK_ERROR]: '[M]Network error',
  [CspErrorType.NOT_FOUND]: '[M]Not found',
  [CspErrorType.SERVER_ERROR]: '[M]Server error',
  [CspErrorType.TIMEOUT]: '[M]Timeout',
  [CspErrorType.TOO_MANY_REQUESTS]: '[M]Too many requests',
  [CspErrorType.TRY_LATER]: '[M]Try later',
  [CspErrorType.UNAUTHORIZED]: '[M]You are not allowed to access this resource.',
  [CspErrorType.UNHANDLED_CLIENT_ERROR]: '[M]Unhandled client error',
  [CspErrorType.USER_NOT_ALLOWED]: '[M]User not allowed',
};
