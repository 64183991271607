import { DataChangeStatus } from '@csp/csp-common-dcf-model';
import { DataChangeSort } from './DataChangeSort';

export type DataChangeQueryFilterCriteria = {
  siteIds: string[];
  statuses: DataChangeStatus[];
  eCodes: string[];
  dataNames: string[];
};

export type DataChangeQueryFilter = {
  key: string;
  pageSize: number;
  criteria: Partial<DataChangeQueryFilterCriteria>;
  count?: boolean;
  sort?: DataChangeSort;
};

export type PartialDataChangeQueryFilter = Partial<DataChangeQueryFilter>;

export const EMPTY_DATA_CHANGE_QUERY_FILTER_CRITERIA: DataChangeQueryFilterCriteria = {
  siteIds: [],
  statuses: [],
  eCodes: [],
  dataNames: [],
};
