import { StateAssert } from '@csp/csp-common-model';

const DELIMITER = '-';

export type CacheKey = string;

const from = (key: string[] | string): CacheKey => {
  StateAssert.isTrue(!Array.isArray(key) || key.length > 0, 'Must at least contain one key part');
  return Array.isArray(key) ? key.join(DELIMITER) : key;
};

export const CacheKey = {
  from,
};
