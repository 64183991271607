/* eslint-disable */
export const UnifyAppStudyHelpQuestionSchema = {
  title: 'UnifyAppStudyHelpQuestion',
  type: 'object',
  properties: {
    title: { type: 'string' },
    categoryHeader: { type: 'string' },
    order: { required: true, type: 'number' },
    header: { type: 'string' },
    body: { type: 'string' },
    categoryId: { type: 'string' },
    pdfDownloadButtonText: { type: 'string' },
    pdf: { type: 'object', _attachment: { id: 'pdfAttachment' }, properties: {} },
  },
  _parent: 'n:node',
  description: 'unify:appstudyhelpquestion',
  $schema: 'http://json-schema.org/draft-04/schema#',
  _doc: '9ac24fd89cf151110218',
};
