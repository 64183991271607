/* eslint-disable */
export const UnifyConsentDocumentSchema = {
  title: 'UnifyConsentDocument',
  type: 'object',
  required: ['consentType', 'version', 'context'],
  properties: {
    title: { type: 'string' },
    consentType: { title: 'Consent type', type: 'string', mock: 'EULA' },
    version: { type: 'string', title: 'Version', helper: 'The version of the document', mock: '1.0' },
    context: {
      type: 'array',
      title: 'Document context',
      helper: 'Document will be accessible within these contexts',
      items: { type: 'string', enum: ['APP', 'PORTAL'] },
    },
    countryCodes: {
      type: 'array',
      title: 'Countries',
      helper: 'Document will be accessible for users in these countries',
      items: {
        type: 'string',
        enum: [
          'AR',
          'AT',
          'AU',
          'BE',
          'BG',
          'BR',
          'CA',
          'CL',
          'CN',
          'CO',
          'CZ',
          'DE',
          'DK',
          'EG',
          'ES',
          'FI',
          'FR',
          'GB',
          'GR',
          'HK',
          'HU',
          'IE',
          'IL',
          'IN',
          'IT',
          'JP',
          'KR',
          'MT',
          'MX',
          'MY',
          'NL',
          'NO',
          'NZ',
          'PA',
          'PE',
          'PH',
          'PL',
          'PT',
          'RO',
          'RU',
          'SA',
          'SE',
          'SG',
          'SK',
          'SW',
          'TH',
          'TR',
          'TW',
          'UA',
          'US',
          'VE',
          'VN',
          'XX',
          'ZA',
          'ZZ',
        ],
      },
    },
    header: { type: 'string' },
    subHeader: { type: 'string' },
    bodyMd: { type: 'string', title: 'Body Markdown' },
    declineAlertWarningText: { type: 'string', mock: 'You cannot continue without accepting the consent' },
    readMoreButtonText: { type: 'string', mock: 'Read more' },
    readMoreButtonTextAccessibilityLabel: { type: 'string', mock: 'Read more' },
    readMoreButtonTextAccessibilityHint: { type: 'string', mock: 'Read more accessibility hint' },
  },
  description: 'unify:consentdocument',
  $schema: 'http://json-schema.org/draft-07/schema#',
};
