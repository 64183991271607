import { CustomStatusType, StatusValue } from '@csp/csp-common-model';
import Joi from 'joi';

type PatientStatusUpdateV1 = {
  statusType: CustomStatusType;
  statusValue: StatusValue;
};
export type PatientStatusUpdatePayloadV1 = {
  updates: PatientStatusUpdateV1[];
};

const patientStatusUpdateSchema = Joi.object<PatientStatusUpdateV1>({
  statusType: Joi.string().required(),
  statusValue: Joi.string().required(),
});

const patientStatusUpdatePayloadSchema = Joi.object({
  updates: Joi.array().items(patientStatusUpdateSchema),
});

const isPatientStatusUpdatePayload = (unknownPayload: unknown): unknownPayload is PatientStatusUpdatePayloadV1 =>
  !patientStatusUpdatePayloadSchema.validate(unknownPayload, { stripUnknown: true }).error;

export const PatientStatusUpdatePayloadV1 = {
  isPatientStatusUpdatePayload,
};
