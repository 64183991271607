/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * The gender of the studysubject.
 */
export enum StudySubjectGenderV1 {
    FEMALE = 'FEMALE',
    MALE = 'MALE',
    THIRD = 'THIRD',
    UNKNOWN = 'UNKNOWN',
}