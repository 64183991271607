import { PatientConfigV1, PatientConfigV1Schema } from '@csp/config-schemas';
import { EnumUtil } from '@csp/csp-common-enum-util';
import { BioSampleCodeFormatType, StudyHbsConfig } from '@csp/csp-common-hbs-model';
import { BioSampleCodeFormatterMapper } from '@csp/csp-common-hbs';
import {
  AuthenticationType,
  CountryCode,
  DateOfBirthType,
  DomainName,
  EthnicityConfig,
  GenderConfig,
  HeightConfig,
  HeightUnitType,
  Maybe,
  QuestionnaireApiType,
  ShowVideoStartInAdvancesSecs,
  StateAssert,
  StudyAuthenticationConfig,
  StudyMultipleDevicesSignInConfig,
  StudyPatientAllowedUsernameEmailDomains,
  StudyPatientDateOfBirthFormats,
  StudyPatientValidation,
  StudyPeriodConfig,
  StudyQuestionnaireConfig,
  StudyRescreenConfig,
  StudySecondAuthenticationConfig,
  StudyVisitConfig,
  VisitLocationType,
  VisitLocationTypes,
  WeightConfig,
  WeightUnitType,
  ZonedDateTime,
} from '@csp/csp-common-model';
import { isDefined, JsonValidationService } from '@csp/csp-common-util';
import { StudyPatientConfig } from '../model/StudyPatientConfig';

const JSON_SCHEMA = 'patient-config-v1.json';
const DEFAULT_SHOW_START_VIDEO_SECS = 600;

const DEFAULT_VISIT_LOCATION_TYPES: VisitLocationType[] = [
  VisitLocationType.PHONE,
  VisitLocationType.LOCATION,
  VisitLocationType.HOME_VISIT,
];

const assertSingleDateOfBirthFormatPerCountry = (patientConfigV1: PatientConfigV1): void => {
  const allCountries =
    patientConfigV1.validation?.dateOfBirth?.localFormats?.flatMap(localFormat => localFormat.countries) ?? [];

  const onlyUniqueCountries = Array.from(new Set(allCountries));

  StateAssert.isTrue(
    allCountries.length === onlyUniqueCountries.length,
    'More than one date of birth format mapping per single country. Please check the config',
  );
};

const assertSingleEmailDomainValidationPerCountry = (patientConfigV1: PatientConfigV1): void => {
  const allCountries =
    patientConfigV1.validation?.usernameEmail?.localAllowedDomains?.flatMap(
      localAllowedDomain => localAllowedDomain.countries,
    ) ?? [];

  const onlyUniqueCountries = Array.from(new Set(allCountries));

  StateAssert.isTrue(
    allCountries.length === onlyUniqueCountries.length,
    'More than one allowed domains mapping per single country. Please check the config',
  );
};

const toStudyPatientDateOfBirthFormats = (patientConfigV1: PatientConfigV1): StudyPatientDateOfBirthFormats => {
  const defaultFormat = patientConfigV1.validation?.dateOfBirth?.defaultFormat ?? DateOfBirthType.YYYY;

  const getByCountryCodeOrDefault = (countryCode?: CountryCode): DateOfBirthType => {
    const formatOrUndefined = countryCode
      ? patientConfigV1.validation?.dateOfBirth?.localFormats?.find(localFormat =>
          localFormat.countries.includes(countryCode.toUpperCase()),
        )?.format
      : undefined;
    return formatOrUndefined ? formatOrUndefined : defaultFormat;
  };

  return {
    defaultFormat,
    getByCountryCodeOrDefault,
  };
};

const toStudyPatientAllowedUsernameEmailDomains = (
  patientConfigV1: PatientConfigV1,
): StudyPatientAllowedUsernameEmailDomains => {
  const defaultAllowedDomains = patientConfigV1.validation?.usernameEmail?.defaultAllowedDomains;

  const getByCountryCode = (countryCode?: CountryCode): Maybe<DomainName[]> => {
    const localAllowedDomains = countryCode
      ? patientConfigV1.validation?.usernameEmail?.localAllowedDomains?.find(localAllowedDomains =>
          localAllowedDomains.countries.includes(countryCode.toUpperCase()),
        )?.allowedDomains
      : undefined;
    return localAllowedDomains ?? defaultAllowedDomains;
  };

  return {
    defaultAllowedDomains,
    getByCountryCode,
  };
};

const toStudyPatientEthnicityValidation = (patientConfigV1: PatientConfigV1): EthnicityConfig => ({
  enabled: !!patientConfigV1.validation?.ethnicity?.default,
  required: patientConfigV1.validation?.ethnicity?.default?.required ?? false,
  options: patientConfigV1.validation?.ethnicity?.default?.options ?? [],
});

const toStudyPatientGenderValidation = (patientConfigV1: PatientConfigV1): GenderConfig => ({
  enabled: !!patientConfigV1.validation?.gender?.default,
  required: patientConfigV1.validation?.gender?.default?.required ?? false,
  options: patientConfigV1.validation?.gender?.default?.options ?? [],
});

const toStudyPatientHeight = (patientConfigV1: PatientConfigV1): HeightConfig => ({
  enabled: !!patientConfigV1.validation?.height?.default,
  required: patientConfigV1.validation?.height?.default?.required ?? false,
  displayUnit: HeightUnitType.CM,
});

const toStudyPatientWeight = (patientConfigV1: PatientConfigV1): WeightConfig => ({
  enabled: !!patientConfigV1.validation?.weight?.default,
  required: patientConfigV1.validation?.weight?.default?.required ?? false,
  displayUnit: WeightUnitType.KG,
});

const toStudyPatientValidation = (patientConfigV1: PatientConfigV1): StudyPatientValidation => {
  assertSingleDateOfBirthFormatPerCountry(patientConfigV1);
  assertSingleEmailDomainValidationPerCountry(patientConfigV1);
  return {
    dateOfBirthFormats: toStudyPatientDateOfBirthFormats(patientConfigV1),
    usernameEmailDomains: toStudyPatientAllowedUsernameEmailDomains(patientConfigV1),
    ethnicity: toStudyPatientEthnicityValidation(patientConfigV1),
    gender: toStudyPatientGenderValidation(patientConfigV1),
    heightConfig: toStudyPatientHeight(patientConfigV1),
    weightConfig: toStudyPatientWeight(patientConfigV1),
  };
};

// R2.0
const toQuestionnaireConfig = (patientConfigV1: PatientConfigV1): StudyQuestionnaireConfig => {
  const versionV1 = patientConfigV1.questionnaire?.apiVersion ?? QuestionnaireApiType.V1; // Default to V1 to be valid with configs prior R2.0
  const isApiVersion = (version: QuestionnaireApiType): boolean => version === versionV1;
  return {
    isApiVersion,
  };
};

// R2.0
const toPeriodConfig = (patientConfigV1: PatientConfigV1): StudyPeriodConfig => {
  const getNumberOfDaysByUnit = (unit: 'MONTH'): number => {
    switch (unit) {
      case 'MONTH':
        return patientConfigV1.period?.daysInMonth ?? StudyPeriodConfig.DEFAULT_DAYS_IN_MONTH;
      default:
        throw new Error(`Unsupported unit: ${unit}`);
    }
  };
  return {
    getNumberOfDaysByUnit,
  };
};

// R2.1
const toRescreenConfig = (patientConfigV1: PatientConfigV1): StudyRescreenConfig => ({
  enabled: patientConfigV1.rescreen?.enabled ?? StudyRescreenConfig.DEFAULT_CONFIG.enabled,
  reuseEcode: patientConfigV1.rescreen?.reuseEcode ?? StudyRescreenConfig.DEFAULT_CONFIG.reuseEcode,
});

// R3.0
const toSecondAuthenticationConfig = (patientConfigV1: PatientConfigV1): StudySecondAuthenticationConfig => ({
  patientSecondAuthenticationSessionInMinutes:
    patientConfigV1.secondAuthentication?.patientSecondAuthenticationSessionInMinutes ??
    StudySecondAuthenticationConfig.DEFAULT_SECOND_AUTHENTICATION_SESSION_IN_MINUTES,
});

// R3.1
const toDisplayVideo = (patientConfigV1: PatientConfigV1, startTime?: ZonedDateTime): ShowVideoStartInAdvancesSecs => {
  const showVideoStartInAdvancesSecs = patientConfigV1.visit?.joinVideoInAdvanceSecs ?? DEFAULT_SHOW_START_VIDEO_SECS;
  if (startTime) {
    const showJoinMeetingTimeMillis = startTime.unixTimeMillis - showVideoStartInAdvancesSecs * 1000;
    const now = Date.now();
    const diffMillis = showJoinMeetingTimeMillis - now;
    const timeLeftMillis = diffMillis > 0 ? diffMillis : 0;
    return {
      isShow: timeLeftMillis === 0,
      timeLeftMillis,
    };
  } else {
    return {
      isShow: true,
      timeLeftMillis: 0,
    };
  }
};

const toVisitConfig = (patientConfigV1: PatientConfigV1): StudyVisitConfig => ({
  showVideoStartInAdvancesSecs: (startTime?: ZonedDateTime): ShowVideoStartInAdvancesSecs => ({
    ...toDisplayVideo(patientConfigV1, startTime),
  }),
  joinVideoInAdvanceSecs: patientConfigV1.visit?.joinVideoInAdvanceSecs ?? DEFAULT_SHOW_START_VIDEO_SECS,
  supportedUnscheduledVisitLocationTypes:
    patientConfigV1.visit?.supportedVisitFormats?.unscheduled?.map(VisitLocationTypes.from).filter(isDefined) ??
    DEFAULT_VISIT_LOCATION_TYPES,
});

const toMultipleSignInConfig = (patientConfigV1: PatientConfigV1): StudyMultipleDevicesSignInConfig => ({
  patientMultipleDevicesSignIn:
    patientConfigV1.multipleDevicesSignIn?.patientMultipleDevicesSignIn ??
    StudyMultipleDevicesSignInConfig.ALLOW_MULTIPLE_DEVICES_SIGN_IN,
});

const toStudyHbsConfig = (patientConfigV1: PatientConfigV1): StudyHbsConfig => {
  const supportedBioSampleIdFormats =
    patientConfigV1.hbs?.supportedBioSampleIdFormats
      .map(formatV1 => EnumUtil.fromString<BioSampleCodeFormatType>(formatV1, BioSampleCodeFormatType))
      .filter(isDefined) ?? [];

  return {
    supportedBioSampleIdFormats,
    bioSampleCodeFormatter: BioSampleCodeFormatterMapper.from(supportedBioSampleIdFormats),
  };
};

const toStudyAuthenticationConfig = (patientConfigV1: PatientConfigV1): StudyAuthenticationConfig => {
  const defaultAuthenticationTypes = patientConfigV1.authentication?.defaultSupportedTypes ?? [
    AuthenticationType.EMAIL,
  ];

  const isSupportedByCountry = (authenticationType: AuthenticationType, countryCode: Maybe<CountryCode>): boolean => {
    const countryCodeEntry = patientConfigV1.authentication?.localSupportedTypes?.find(
      localType =>
        countryCode && localType.countries.map(country => country.toUpperCase()).includes(countryCode.toUpperCase()),
    );

    if (countryCodeEntry?.supportedTypes) {
      return countryCodeEntry.supportedTypes.includes(authenticationType);
    } else {
      return defaultAuthenticationTypes.includes(authenticationType);
    }
  };

  return {
    isSupportedByCountry,
  };
};

const toPatientConfig = (patientConfigV1: PatientConfigV1): StudyPatientConfig => {
  JsonValidationService.validateJsonNonStrict(JSON_SCHEMA, PatientConfigV1Schema, patientConfigV1);

  return {
    validation: toStudyPatientValidation(patientConfigV1),
    questionnaire: toQuestionnaireConfig(patientConfigV1),
    secondAuthentication: toSecondAuthenticationConfig(patientConfigV1),
    multipleDevicesSignIn: toMultipleSignInConfig(patientConfigV1),
    period: toPeriodConfig(patientConfigV1),
    rescreen: toRescreenConfig(patientConfigV1),
    visit: toVisitConfig(patientConfigV1),
    hbs: toStudyHbsConfig(patientConfigV1),
    authentication: toStudyAuthenticationConfig(patientConfigV1),
  };
};

export const PatientConfigServiceV1 = {
  toPatientConfig,
};
