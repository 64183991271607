import { createSelector } from '@reduxjs/toolkit';
import { getStudySelector, getUserSelector } from '../../security/ducks/principalContextSelectors';

export const displayCloseoutTrackerInfoDialogSelector = createSelector(
  getUserSelector,
  getStudySelector,
  (user, study) => {
    if (study?.isCloseoutPeriodActive) {
      return !user?.tenantPreferences.dismissCloseoutTrackerInfoDialog;
    }
    return false;
  },
);
