import { RpmEventStateType } from '@csp/dmdp-api-user-dto';
import { ZonedDateTime } from '../../../datetime/ZonedDateTime';

export type RpmEventStatus = {
  state: RpmEventStateType;
  updatedAt: ZonedDateTime;
};

export const emptyRpmEventStatus: RpmEventStatus = {
  state: RpmEventStateType.NO_DATA,
  updatedAt: {
    unixTimeMillis: 0,
    zone: 'UTC',
  },
};
