import { LocationAddressV1 } from '@csp/dmdp-api-appointment-dto';

const getCityAndPostal = (address: LocationAddressV1): string => {
  const country = address.country ? address.country.toLowerCase() : '';
  switch (country) {
    case 'sweden':
      return `${address.postalCode} ${address.city}`;
    default:
      return `${address.city} ${address.postalCode}`;
  }
};

export const toDisplayAddress = (address?: LocationAddressV1): string => {
  if (address) {
    const addressLine = address.lines ? address.lines.join(' ') : undefined;
    const cityAndPostalcode = getCityAndPostal(address);
    const country = address.country;
    return [addressLine, cityAndPostalcode, country].filter(v => v !== undefined).join(', ');
  } else {
    return '';
  }
};
