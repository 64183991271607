import { fill, identity, orderBy, slice, without } from 'lodash';
import { isEmpty, negate } from 'lodash/fp';

export const times = (count: number): number[] => fill(Array(count), 0).map((_, i) => i);

export const notEmpty = <T>(value: T | null | undefined): value is T => value != null;

export const isNotEmpty = negate(isEmpty);

/**
 * Wraps the value in an array if it is defined, otherwise returns an empty array.
 */
export const toArrayOrEmpty = <T>(value: T | null | undefined): T[] => (value != null ? [value] : []);

/**
 * This will not mutate the original array. Will give a new array each time, except for the empty array.
 *
 * @param array To rearrange the items in.
 * @param fromIndex move the item at fromIndex
 * @param toIndex move the item to toIndex.
 */
export const move = <T>(array: T[], fromIndex: number, toIndex: number): T[] => {
  if (isEmpty(array)) {
    return array;
  } else {
    const newArray = [...array];
    const element = newArray.splice(fromIndex, 1)[0];
    if (element) {
      newArray.splice(toIndex, 0, element);
    }
    return newArray;
  }
};

/**
 * Slices an array at given indexes.
 *
 * Eg. toSlices([0, 1, 2, 3, 4, 5, 6, 7, 8, 9], [2, 4, 9]) => [[0, 1], [2, 3], [4, 5, 6, 7, 8], [9]]
 *
 * Disregards any sliceIndexes which are 0 or equal or more to length of the list.
 * Sorts the list of given indexes before slicing.
 *
 * Returns a new array consisting of slices done on the passed in array.
 * Returns empty array if no idxs is passed in.
 *
 * @param array to slice.
 * @param sliceIndexes Indexes to make slices from.
 */
export const toSlices = <T>(array: T[], sliceIndexes: number[]): T[][] => {
  const cleanedSliceIndexes = without(sliceIndexes, 0, array.length).filter(idx => idx < array.length);

  if (isEmpty(cleanedSliceIndexes)) {
    return [];
  } else {
    const sortedSliceIndexes = orderBy(cleanedSliceIndexes, identity, 'asc');
    const slices: T[][] = [];

    let currentSliceIndex = 0;
    for (let i = 0; i < sortedSliceIndexes.length + 1; i++) {
      const slicedArr = slice(
        array,
        currentSliceIndex,
        i <= sortedSliceIndexes.length ? sortedSliceIndexes[i] : sortedSliceIndexes.length,
      );
      slices.push(slicedArr);
      const nextIndex = sortedSliceIndexes[i];
      if (!nextIndex) {
        break;
      }
      currentSliceIndex = nextIndex;
    }

    return slices;
  }
};

/**
 * Inserts an item into an array at a given index. Returns a new array.
 *
 * @param array to insert into.
 * @param index to insert at.
 * @param item to insert.
 * @throws Error if index is less than 0 or greater than array length.
 *
 * @returns new array with item inserted.
 *
 * @example
 * insert([1, 2, 3], 1, 4) => [1, 4, 2, 3]
 * insert([1, 2, 3], 0, 4) => [4, 1, 2, 3]
 **/
export const insertAt = <T>(array: T[], item: T, index: number): T[] => {
  if (index < 0) {
    throw new Error('Index must be 0 or greater');
  } else if (index > array.length) {
    throw new Error('Index must be less than or equal to array length');
  }

  const newArray = [...array];
  newArray.splice(index, 0, item);
  return newArray;
};

export const getSoleEntryOrUndefined = <T>(array: T[]): T | undefined => (array.length === 1 ? array[0] : undefined);
