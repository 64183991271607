import { Box, ListItemStudyDocument, Subtitle2, Subtitle3 } from '@csp/csp-web-ui';
import { FC } from 'react';
import { CspPath } from '../../../CspPath';
import { TrainingMaterialProps } from '../model/TrainingMaterialProps';

export const TrainingMaterialsContainer: FC<TrainingMaterialProps> = ({
  description,
  documents,
  header,
  linkText,
  versionPublishedLabel,
  video,
}) => {
  const videoUrl = `${CspPath.VIDEO_PLAYER}?title=${video?.header}&uri=${encodeURIComponent(
    video?.webVideo.video.url as string,
  )}`;

  return (
    <>
      <Box mb={3}>
        <Subtitle2 fontWeight={700} mb={1}>
          {header}
        </Subtitle2>
        <Subtitle3>{description}</Subtitle3>
      </Box>

      {documents?.map((doc, index) => (
        <Box mb={2} key={`${doc.title}-${index}`}>
          <ListItemStudyDocument
            title={doc.header}
            versionPublishedDate={doc.versionPublishedDate}
            versionPublishedLabel={versionPublishedLabel}
            linkText={linkText}
            file={doc.file}
            labelWidth={{ xs: '70%', sm: '80%' }}
          />
        </Box>
      ))}

      <Box mb={2}>
        <ListItemStudyDocument
          title={video?.header}
          versionPublishedLabel={versionPublishedLabel}
          linkText={linkText}
          versionPublishedDate={video?.versionPublishedDate}
          file={{ url: videoUrl }}
          labelWidth={{ xs: '70%', sm: '80%' }}
        />
      </Box>
    </>
  );
};
