import { PatientStatuses } from '@csp/csp-common-model';
import { createMockCreator } from '@csp/test-common-utils';
import { PatientStatusMock } from './PatientStatus.mock';

const patientStatusesMock: PatientStatuses = {
  appStatus: PatientStatusMock.createAppStatusMock(),
  medicationStatus: PatientStatusMock.createMedicationStatusMock(),
  studyStatus: PatientStatusMock.createStudyStatusMock(),
  rtsmStatus: PatientStatusMock.createRtsmStatusMock(),
  vitalStatus: PatientStatusMock.createVitalStatusTypeMock(),
  finalContactStatus: PatientStatusMock.createFinalContactStatusTypeMock(),
};

export const createPatientStatusesMock = createMockCreator(patientStatusesMock);
