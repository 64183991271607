import { TimeZoneId, UnixTimeMillis, ZonedDateTime, ZonedDateTimeStr } from '@csp/csp-common-model';
import { getTimeZoneId, getTimezoneOffset, matchTimezoneStr, parseIso } from '../util/DateUtil';

/**
 * Get the current time as a ZonedDateTime object.
 * @param {TimeZoneId} [zone] - Optional IANA timezone to use for formatting. Will use the system timezone if not provided.
 * @return {ZonedDateTime} - ZonedDateTime object
 */
const now = (zone: TimeZoneId = getTimeZoneId()): ZonedDateTime => ({
  unixTimeMillis: Date.now(),
  zone,
});

/**
 * Create a ZonedDateTime object from a string like "2020-07-01T09:04:11.597+01:00" or "2020-07-01T09:04:11.597+01:00[Europe/Stockholm]".
 * @param {ZonedDateTimeStr} zonedDateTimeString - Extended ISO 8601 datetime format with timezone
 * @throws Error Will throw an error if not valid ISO.
 * @return {ZonedDateTime} - ZonedDateTime object
 */
const fromZonedDateTimeString = (zonedDateTimeString: ZonedDateTimeStr): ZonedDateTime => {
  const match = matchTimezoneStr(zonedDateTimeString);
  if (match && match.length > 2 && match[1] != null && match[2] != null) {
    return {
      unixTimeMillis: parseIso(match[1]).getTime(),
      zone: match[2],
    };
  } else {
    const date = parseIso(zonedDateTimeString);
    const offset = getTimezoneOffset(zonedDateTimeString);
    const zone = offset.includes('00:00') ? 'UTC' : offset;
    return {
      unixTimeMillis: date.getTime(),
      zone,
    };
  }
};

/**
 * Create a ZonedDateTime object from unix time in milliseconds.
 * @param {UnixTimeMillis} unixTimeMillis - Unix time in milliseconds.
 * @param {TimeZoneId} [zone] - Optional IANA timezone to use for formatting. Will use the system timezone if not provided.
 * @return {ZonedDateTime} - ZonedDateTime object
 */
const fromUnixTimeMillis = (unixTimeMillis: UnixTimeMillis, zone: TimeZoneId = getTimeZoneId()): ZonedDateTime => ({
  unixTimeMillis,
  zone,
});

export const ZonedDateTimeFactory = {
  now,
  fromZonedDateTimeString,
  fromUnixTimeMillis,
};
