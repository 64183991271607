import { createETagHeader, HttpStatus, parseJsonWithBigInt } from '@csp/csp-common-axios';
import { ClientType, ObjectType, RestOptions } from '@csp/csp-common-model';
import { ContentRole } from '@csp/csp-common-content-utils';
import { ContentPackageType } from '../../model/ContentPackageType';
import { contentDeliveryUrl } from '../../utils/configUtil';
import { ContentPackageResponse } from '../model/ContentPackageResponse';
import { ContentAndLocale } from '../model/ContentAndLocale';
import { ContentPackagesResponse } from '../model/ContentPackagesResponse';

const getBaseUrl = (): string => `${contentDeliveryUrl()}/content-delivery/v1`;

const getContentPackage = async (
  { axios, signal }: RestOptions,
  clientType: ClientType,
  version: string,
  locale: string,
  packageType: ContentPackageType,
  eTag?: string,
  role?: ContentRole,
): Promise<NonNullable<ContentPackageResponse>> => {
  const url =
    packageType === 'study'
      ? `${getBaseUrl()}/${packageType}/${clientType}/${role}/${version}/${locale}`
      : `${getBaseUrl()}/${packageType}/${clientType}/${version}/${locale}`;

  const response = await axios.get<Record<string, ObjectType[]>>(url, {
    headers: eTag ? createETagHeader(eTag) : undefined,
    signal,
  });

  if (response.status === HttpStatus.NOT_MODIFIED && eTag) {
    return { status: HttpStatus.NOT_MODIFIED, eTag: response.headers?.etag ?? eTag };
  } else {
    return { status: HttpStatus.OK, content: response.data, eTag: response.headers?.etag };
  }
};

const getContentForAllLocales = async (
  { axios, signal }: RestOptions,
  clientType: ClientType,
  contentVersion: string,
  packageType: ContentPackageType,
  role: ContentRole,
): Promise<NonNullable<ContentPackagesResponse>> => {
  const url =
    packageType === 'study'
      ? `${getBaseUrl()}/${packageType}/${clientType}/${role}/${contentVersion}/all`
      : `${getBaseUrl()}/${packageType}/${clientType}/${contentVersion}/all`;

  const response = await axios.get<ContentAndLocale[]>(url, {
    transformResponse: parseJsonWithBigInt,
    signal,
  });

  const data = response.data && !Array.isArray(response.data) ? [response.data] : response.data;

  return { contentPackages: data };
};

export const ContentDeliveryRestServiceV1 = {
  getContentPackage,
  getContentForAllLocales,
};
