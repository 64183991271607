import { Maybe, ZonedDateTime } from '@csp/csp-common-model';
import { RequestVersionStatus } from './RequestVersionStatus';

export enum RequestUserStatus {
  /** User is done with all tasks for a given request */
  DONE = 'DONE',
  /** User is currently assigned to perform the request */
  ONGOING = 'ONGOING',
  /** User is scheduled to perform the request in the future */
  UPCOMING = 'UPCOMING',
  /** The request is part of a schedule version that has passed expiration */
  EXPIRED = 'EXPIRED',
  /**
   * This request is currently not applicable for the patient.
   * Either the start action has not occured, or the request is
   * scheduled to occur outside the version active interval.
   */
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}

const from = (
  requestVersionStatus: RequestVersionStatus,
  startDate: Maybe<ZonedDateTime>,
  endDate: Maybe<ZonedDateTime>,
): RequestUserStatus => {
  const now = Date.now();

  if (startDate) {
    if (now < startDate.unixTimeMillis || requestVersionStatus === 'FUTURE') {
      return RequestUserStatus.UPCOMING;
    } else if (endDate && endDate.unixTimeMillis < now) {
      return requestVersionStatus !== 'ACTIVE' ? RequestUserStatus.EXPIRED : RequestUserStatus.DONE;
    } else {
      return requestVersionStatus !== 'ACTIVE' ? RequestUserStatus.EXPIRED : RequestUserStatus.ONGOING;
    }
  } else {
    return RequestUserStatus.NOT_APPLICABLE;
  }
};

export const RequestUserStatuses = {
  from,
};
