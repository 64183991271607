import { QuestionnaireActiveVersionWindowV2, QuestionnaireRequestV2 } from '@csp/dmdp-api-questionnaire-dto';
import { QuestionnaireRequestV1, QuestionnaireVersionWindowV1 } from '@csp/dto';
import { GenericVersion, ScheduleCode, ScheduleVersionInfo } from '@csp/csp-common-scheduling';
import { QuestionnaireRequest } from './QuestionnaireRequest';
import { QuestionnaireActivationRef } from './QuestionnaireActivationRef';
import { QuestionnaireActivation } from './QuestionnaireActivation';

export type QuestionnaireVersion = GenericVersion<QuestionnaireRequest>;

const from = (
  scheduleCode: ScheduleCode,
  {
    activation,
    activationRef,
    activeFrom,
    activeTo,
    version,
  }: QuestionnaireActiveVersionWindowV2 | QuestionnaireVersionWindowV1,
): QuestionnaireVersion => {
  const scheduleVersionInfo = ScheduleVersionInfo.fromApi(version.versionCode, activeFrom, activeTo);
  return {
    scheduleCode,
    ...scheduleVersionInfo,
    activation: QuestionnaireActivation.from(activation),
    activationRef: QuestionnaireActivationRef.from(activationRef),
    requests: version.requests.map((request: QuestionnaireRequestV2 | QuestionnaireRequestV1) =>
      QuestionnaireRequest.from(scheduleCode, scheduleVersionInfo, request),
    ),
  };
};

export const QuestionnaireVersion = {
  from,
};
