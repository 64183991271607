import { UserFactory } from '@csp/csp-common-user';
import { Maybe, Site, SiteECApprovalStatus, SiteStatus, SITE_STATUSES_TO_SHOW, Study } from '@csp/csp-common-model';
import { createSelector, Selector } from '@reduxjs/toolkit';
import { compose, filter, flatMap, sortBy } from 'lodash/fp';
import { RootState } from '../../../state/store';
import { Principal } from '../model/Principal';
import { PrincipalContextState } from '../model/PrincipalContextState';

export type SitesSelector = Selector<RootState, Site[]>;

export const principalContextSelector = (state: RootState): PrincipalContextState => state.principalContext;

export const getPrincipalSelector = (state: RootState): Maybe<Principal> =>
  state.principalContext.principal ?? undefined;

export const getAuthStateErrorSelector = (state: RootState): Error | null => state?.principalContext.err;

export const isLoggedInSelector = (state: RootState): boolean => !!getPrincipalSelector(state);
export const isNotLoggedInSelector = (state: RootState): boolean => !getPrincipalSelector(state);

export const getUserSelector = createSelector(getPrincipalSelector, principal => {
  const user = principal?.user;
  return user && UserFactory.from(user.userV1, user.orgsV1);
});

export const getUserIdSelector = (state: RootState): Maybe<string> => getUserSelector(state)?.userId;

export const getStudySelector = (state: RootState): Maybe<Study> => getPrincipalSelector(state)?.study;

export const getStudyDcodeSelector = (state: RootState): Maybe<string> => getStudySelector(state)?.dcode;

export const getSitesSelector = createSelector(getStudySelector, study => study?.sites || []);

export const getSitesEnrollingPatientsSelector = createSelector(
  getSitesSelector,
  filter(site => site.canEnrollPatients),
);

export const getSitesOrgIdsIncludingParentsSelector = createSelector(
  getSitesSelector,
  flatMap(({ orgIdsIncludingParents }) => orgIdsIncludingParents),
);

export const getSitesWithValidStatusSelector = createSelector(
  getSitesSelector,
  compose(
    sortBy((site: Site) => site.siteNumber),
    filter((site: Site) => SITE_STATUSES_TO_SHOW.includes(site.siteStatus as SiteStatus)),
  ),
);

export const getSitesWithEcApprovalStatusNotApprovedSelector = createSelector(
  getSitesWithValidStatusSelector,
  filter(site => site.ecApprovalStatus?.status !== SiteECApprovalStatus.APPROVED),
);
