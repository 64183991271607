import { hoursToSeconds } from '@csp/csp-common-date-util';
import { ConfiguredUserDeviceRequest, DeviceObservationScheduleService } from '@csp/csp-common-devices';
import { MemCacheService } from '@csp/csp-common-memcache';
import { ApiOptions, SiteId, StateAssert, TenantId, UserId } from '@csp/csp-common-model';
import { ScheduleRef, ScheduleRequestInfo } from '@csp/csp-common-scheduling';
import { OrgService } from '@csp/csp-common-user';
import { negate } from 'lodash';
import { chunk } from 'lodash/fp';
import { ScheduleRequestComplianceRef } from '../../model/ScheduleRequestComplianceRef';
import { ScheduleRequestMetricCriterion } from '../../model/ScheduleRequestMetricCriterion';
import { ScheduleRequestMetricInfo } from '../../model/ScheduleRequestMetricInfo';
import { DeviceMetricCriteriaMapper } from '../mapper/DeviceMetricCriteriaMapper';
import { DeviceSessionAggregationService } from './DeviceSessionAggregationService';

const cacheKeyOf = (tenantId: TenantId): string[] => ['patient-device-compliance-req-ref', tenantId];

const toEmptyDeviceMetric = ({ ref, request }: ConfiguredUserDeviceRequest): ScheduleRequestMetricInfo[] =>
  ScheduleRef.toScheduleRequestRefs(ref).map(requestRef => ({
    scheduleRef: {
      scheduleCode: requestRef.scheduleCode,
      requestCodes: [requestRef.requestCode],
    },
    metricInfo: {
      hasCompliance: false,
      hasData: false,
      hasRecentData: false,
      countMetric: undefined,
      complianceMetric: undefined,
      thresholdPercentage: undefined,
      thresholdValue: undefined,
      recentPeriod: request.historicalMetricPeriod,
      lastSync: undefined,
    },
  }));

const getRequestComplianceRefs = async (
  siteIds: SiteId[],
  apiOptions?: ApiOptions,
): Promise<ScheduleRequestComplianceRef[]> => {
  const orgsSchedules = await DeviceObservationScheduleService.getCachedActivatedDeviceSchedulesForOrganizationIds(
    siteIds,
    apiOptions,
  );

  return orgsSchedules.getAllRequestsWithOrgId().flatMap(({ requests, orgId }) =>
    requests
      .filter(request => !!request.minComplianceThreshold)
      .map(({ scheduleCode, requestCode, minComplianceThreshold }) => {
        StateAssert.notNull(minComplianceThreshold);
        return {
          siteId: orgId,
          requestRef: {
            scheduleCode,
            requestCode,
          },
          minComplianceThreshold,
        };
      }),
  );
};

const getCachedRequestComplianceRefsByTenantId = async (
  tenantId: TenantId,
  apiOptions?: ApiOptions,
): Promise<ScheduleRequestComplianceRef[]> => {
  let complianceSummary = MemCacheService.getValue<ScheduleRequestComplianceRef[]>(cacheKeyOf(tenantId));
  if (!complianceSummary) {
    const siteIds = await OrgService.fetchAllSiteIds(apiOptions);
    complianceSummary = await DeviceMetricService.getRequestComplianceRefs(siteIds, apiOptions);
    MemCacheService.setValue(cacheKeyOf(tenantId), hoursToSeconds(24), complianceSummary);
  }
  return complianceSummary ?? [];
};

const getUserMetricInfos = (
  patientId: UserId,
  requestComplianceRefs: ScheduleRequestComplianceRef[],
  deviceRequests: ScheduleRequestInfo[],
  apiOptions?: ApiOptions,
): Promise<ScheduleRequestMetricInfo[]> => {
  const metricCriteria = ScheduleRequestMetricCriterion.toMetricCriteria(requestComplianceRefs, deviceRequests);
  return DeviceSessionAggregationService.getMetricInfo(patientId, metricCriteria, apiOptions);
};

const isAllowedToViewDeviceDetails = (configuredDeviceRequest: ConfiguredUserDeviceRequest): boolean =>
  configuredDeviceRequest.resolvedDeviceConfiguration.canViewDeviceDetails;

const isNotAllowedToViewDeviceDetails = negate(isAllowedToViewDeviceDetails);

/**
 * Fetches metric info for all configured device requests, with 20 criteria per request.
 * This is a limitation in the Boost backend.
 */
const chunksOf = chunk(20);

const getUserMetricInfosByConfiguredDeviceRequests = async (
  patientId: UserId,
  configureUserDeviceRequests: ConfiguredUserDeviceRequest[],
  apiOptions?: ApiOptions,
): Promise<ScheduleRequestMetricInfo[]> => {
  const requestsExcludedFromMetricsByConfig = configureUserDeviceRequests.filter(isNotAllowedToViewDeviceDetails);
  const requestsIncludedInMetrics = configureUserDeviceRequests.filter(isAllowedToViewDeviceDetails);

  const metricCriteria = DeviceMetricCriteriaMapper.toMetricCriteria(requestsIncludedInMetrics);

  const promises = chunksOf(metricCriteria).map(criteria =>
    DeviceSessionAggregationService.getMetricInfo(patientId, criteria, apiOptions),
  );

  const scheduleRequestMetricsInfoResponses = await Promise.all(promises);

  return [
    ...scheduleRequestMetricsInfoResponses.flat(),
    ...requestsExcludedFromMetricsByConfig.flatMap(toEmptyDeviceMetric),
  ];
};

export const DeviceMetricService = {
  getRequestComplianceRefs,
  getCachedRequestComplianceRefsByTenantId,
  getUserMetricInfosByConfiguredDeviceRequests,
  getUserMetricInfos,
};
