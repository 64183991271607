import { CriteriaOperatorType, CriteriaType, QueryV1 } from '@csp/dmdp-api-common-dto';
import { OrganizationCriteriaFieldV1, OrganizationIncludeFieldV1, OrgType } from '@csp/dmdp-api-org-dto';

const siteIdQuery = (): QueryV1 => ({
  criterion: {
    operator: CriteriaOperatorType.AND,
    criteria: [
      {
        fieldCriterion: {
          field: OrganizationCriteriaFieldV1.TYPE,
          type: CriteriaType.EQ,
          values: [OrgType.PRACTICE],
        },
      },
    ],
  },
  fields: [OrganizationIncludeFieldV1.ORG_ID],
});

export const OrgQueryFactory = {
  siteIdQuery,
};
