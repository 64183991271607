import { Decimal } from 'decimal.js-light';

/**
 * Add numbers, handling arbitrary-precision decimals.
 *
 * @param x
 * @param y
 * @returns number
 */
const add = (x: number, y: number): number => new Decimal(x).add(y).toNumber();

/**
 * Divide numbers, handling arbitrary-precision decimals.
 *
 * @param number
 * @param divider
 * @returns number
 */
const divide = (number: number, divider: number): number => new Decimal(number).dividedBy(divider).toNumber();

/**
 * Retrieve the modulo x of a number, handling arbitrary-precision decimals.
 *
 * @param number
 * @param x
 * @returns number
 */
const modulo = (number: number, x: number): number => new Decimal(number).modulo(x).toNumber();

/**
 * Subtract numbers, handling arbitrary-precision decimals.
 *
 * @param x
 * @param y
 * @returns number
 */
const subtract = (x: number, y: number): number => new Decimal(x).sub(y).toNumber();

export const MathUtil = {
  add,
  divide,
  modulo,
  subtract,
};
