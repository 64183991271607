/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum QuestionnaireResponseAggregateGroupFieldV2 {
    USER_ID = 'USER_ID',
    CURRENT_STATUS_VALUE = 'CURRENT_STATUS_VALUE',
    SCHEDULE_REQUEST_REF_REQUEST_CODE = 'SCHEDULE_REQUEST_REF_REQUEST_CODE',
    SCHEDULE_REQUEST_REF_VERSION_CODE = 'SCHEDULE_REQUEST_REF_VERSION_CODE',
    SCHEDULE_REQUEST_REF_SCHEDULE_CODE = 'SCHEDULE_REQUEST_REF_SCHEDULE_CODE',
}