import { ContentConfigV1 } from '../model/ContentConfigV1';

const singleton: { contentConfig: ContentConfigV1 } = {
  contentConfig: ContentConfigV1.empty(),
};

const set = (contentConfig: ContentConfigV1): void => {
  singleton.contentConfig = contentConfig;
};

const get = (): ContentConfigV1 => singleton.contentConfig;

export const ContentConfigService = {
  get,
  set,
};
