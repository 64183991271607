import * as RawAssessmentConsequenceActivityCardConfigV1 from './lib/assessment-consequence-activity-card/schema/RawAssessmentConsequenceActivityCardConfigV1.json';
import * as ComplianceConfigV1 from './lib/compliance/schema/ComplianceConfigV1.json';
import * as ContentstackConfigV1 from './lib/contentstack-config/schema/ContentstackConfigV1.json';
import * as DeviceConfigV1 from './lib/device/schema/DeviceConfigV1.json';
import * as DeviceConfigV2 from './lib/device/schema/DeviceConfigV2.json';
import * as RawCspFeatureConfigV1 from './lib/feature-config/schema/RawCspFeatureConfigV1.json';
import * as RawCspFeatureConfigV2 from './lib/feature-config/schema/RawCspFeatureConfigV2.json';
import * as NotificationJobConfigV1 from './lib/job/notification/schema/NotificationJobConfigV1.json';
import * as RawPatientCopingTipConfigV1 from './lib/patient-coping-tip-config/schema/RawPatientCopingTipConfigV1.json';
import * as PatientConfigV1 from './lib/patient/schema/PatientConfigV1.json';
import * as PatientStatusConfigV1 from './lib/patient/schema/PatientStatusConfigV1.json';
import * as RecommendedResourcesConfigV1 from './lib/recommended-resources-config/schema/RecommendedResourcesConfigV1.json';
import * as ReportsConfigV1 from './lib/reports-config/schema/ReportsConfigV1.json';
import * as RequestTagsStudyConfigV1 from './lib/request-tags/schema/RequestTagsStudyConfigV1.json';
import * as RpmConfigV1 from './lib/rpm-config/schema/RpmConfigV1.json';
import * as MetricsConfigV1 from './lib/study-metrics/schema/MetricsConfigV1.json';

/* This file was automatically generated by export-config-schemas.ts */

export const RawAssessmentConsequenceActivityCardConfigV1Schema = RawAssessmentConsequenceActivityCardConfigV1;
export const ComplianceConfigV1Schema = ComplianceConfigV1;
export const ContentstackConfigV1Schema = ContentstackConfigV1;
export const DeviceConfigV1Schema = DeviceConfigV1;
export const DeviceConfigV2Schema = DeviceConfigV2;
export const RawCspFeatureConfigV1Schema = RawCspFeatureConfigV1;
export const RawCspFeatureConfigV2Schema = RawCspFeatureConfigV2;
export const NotificationJobConfigV1Schema = NotificationJobConfigV1;
export const RawPatientCopingTipConfigV1Schema = RawPatientCopingTipConfigV1;
export const PatientConfigV1Schema = PatientConfigV1;
export const PatientStatusConfigV1Schema = PatientStatusConfigV1;
export const RecommendedResourcesConfigV1Schema = RecommendedResourcesConfigV1;
export const ReportsConfigV1Schema = ReportsConfigV1;
export const RequestTagsStudyConfigV1Schema = RequestTagsStudyConfigV1;
export const RpmConfigV1Schema = RpmConfigV1;
export const MetricsConfigV1Schema = MetricsConfigV1;
