import {
  HealthEventNotificationSetupV1,
  HealthEventNotificationV1,
  NotificationJobConfigV1,
  NotificationJobConfigV1Schema,
} from '@csp/config-schemas';
import { HealthEventNotificationJobConfig, HealthEventNotificationJobConfigs } from '@csp/csp-common-healthevent';
import { Maybe } from '@csp/csp-common-model';
import { JsonValidationService } from '@csp/csp-common-util';

const JSON_SCHEMA = 'notification-job-config-v1.json';

const toHealthEventNotificationJobConfig = (
  notificationJobConfigV1?: HealthEventNotificationSetupV1,
): Maybe<HealthEventNotificationJobConfig> =>
  notificationJobConfigV1
    ? {
        enabled: notificationJobConfigV1.enabled,
        roles: notificationJobConfigV1.roles,
        sinceSecs: notificationJobConfigV1.minAgeOfEventSecs,
      }
    : undefined;

const toHealthEventNotificationJobConfigs = (
  healthEventNotificationV1?: HealthEventNotificationV1,
): HealthEventNotificationJobConfigs => ({
  sendOnceNotificationConfig: toHealthEventNotificationJobConfig(healthEventNotificationV1?.once),
  sendRepeatedlyNotificationConfig: toHealthEventNotificationJobConfig(healthEventNotificationV1?.repeatedly),
});

const mapNotificationJobConfig = (
  notificationJobConfigV1: NotificationJobConfigV1,
): [HealthEventNotificationJobConfigs] => {
  JsonValidationService.validateJsonNonStrict(JSON_SCHEMA, NotificationJobConfigV1Schema, notificationJobConfigV1);

  return [toHealthEventNotificationJobConfigs(notificationJobConfigV1.healthEventNotification)];
};

export const NotificationJobConfigServiceV1 = {
  mapNotificationJobConfig,
};
