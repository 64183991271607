import { CopingTipV1, RawPatientCopingTipConfigV1 } from '@csp/config-schemas';
import {
  AlgorithmConfig,
  CopingTip,
  CopingTipAlgorithmConfig,
  CopingTipPriority,
  GiToxConfig,
  Maybe,
  PatientCopingTipConfig,
  RecommenderConfig,
} from '@csp/csp-common-model';
import { PatientCopingTipValidationService } from './PatientCopingTipValidationService';

const toCopingTips = (config: RawPatientCopingTipConfigV1): CopingTip[] => {
  const copingTips = config?.copingTips?.map((copingTip: CopingTipV1) => ({
    code: copingTip.code,
    copingTipPriorities: copingTip.copingTipPriorities.map((copingTip: CopingTipPriority) => ({
      symptomGroup: copingTip.symptomGroup,
      priority: copingTip.priority,
    })),
    screens: [],
  }));

  return copingTips;
};
const toCopingTipAlgorithmConfig = (config: RawPatientCopingTipConfigV1): CopingTipAlgorithmConfig => {
  const copingTipAlgorithmConfig = {
    algorithmCode: config?.algorithm?.algorithmCode,
    algorithmVersion: config?.algorithm?.algorithmVersion,
    nrOfCopingTipsToBePresented: config?.algorithm?.nrOfCopingTipsToBePresented,
    config: toAlgorithmConfig(config),
  };

  return copingTipAlgorithmConfig;
};

const toAlgorithmConfig = (config: RawPatientCopingTipConfigV1): AlgorithmConfig => {
  const algorithmConfig: GiToxConfig = {
    algorithmCode: config?.algorithm.algorithmCode,
    solver: {
      mapRule: config?.algorithm?.config?.solverV1?.mapRule,
    },
    recommender: toRecommenderConfig(config),
  };

  return algorithmConfig;
};

const toRecommenderConfig = (config: RawPatientCopingTipConfigV1): RecommenderConfig => {
  const recommenderConfig = {
    priorityGain: config?.algorithm?.config?.recommenderV1?.priorityGain,
    historyGain: config?.algorithm?.config?.recommenderV1?.historyGain,
    symptomGroupPrefixFlag: config?.algorithm?.config?.recommenderV1?.symptomGroupPrefixFlag,
    symptomGroupPriorities: config?.algorithm?.config?.recommenderV1?.symptomGroupPriorities,
    maxDaysInHistory: config?.algorithm?.config?.recommenderV1?.maxDaysInHistory,
    importanceFactorPriority: config?.algorithm?.config?.recommenderV1?.importanceFactorPriority,
    importanceFactorGroup: config?.algorithm?.config?.recommenderV1?.importanceFactorGroup,
  };

  return recommenderConfig;
};

const toPatientCopingTipConfig = (config: Maybe<RawPatientCopingTipConfigV1>): Maybe<PatientCopingTipConfig> => {
  if (!config) {
    return undefined;
  }
  PatientCopingTipValidationService.validatePatientCopingTipConfigV1(config);

  return {
    copingTips: toCopingTips(config),
    algorithm: toCopingTipAlgorithmConfig(config),
  };
};

export const PatientCopingTipService = {
  toPatientCopingTipConfig,
};
