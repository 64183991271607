import { Box, Subtitle2, Subtitle3 } from '@csp/csp-web-ui';
import { FC } from 'react';
import { ContactInformation } from '../component/ContactInformation';
import { OpeningHours } from '../component/OpeningHours';
import { ContactInformationProps } from '../model/ContactInformationProps';
import { OpeningHoursProps } from '../model/OpeningHoursProps';
import { TrainingMaterialProps } from '../model/TrainingMaterialProps';
import { TrainingMaterialsContainer } from './TrainingMaterialsContainer';

type Props = {
  contactInformation: ContactInformationProps;
  description?: string;
  header?: string;
  openingHours: OpeningHoursProps;
  showTrainingMaterials: boolean;
  trainingMaterials: TrainingMaterialProps;
};

export const TechnicalSupportContainer: FC<Props> = ({
  header,
  description,
  contactInformation,
  openingHours,
  trainingMaterials,
  showTrainingMaterials,
}) => (
  <>
    <Box mb={3}>
      <Subtitle2 fontWeight={700} mb={1}>
        {header}
      </Subtitle2>
      <Subtitle3>{description}</Subtitle3>
    </Box>

    <ContactInformation {...contactInformation} />
    <OpeningHours {...openingHours} />

    {showTrainingMaterials && <TrainingMaterialsContainer {...trainingMaterials} />}
  </>
);
