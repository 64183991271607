/* eslint-disable */
export const UnifyWebTrainingDocumentSchema = {
  title: 'UnifyWebTrainingDocument',
  type: 'object',
  properties: {
    title: { type: 'string', title: 'Title' },
    version: { type: 'string', title: 'Version', helper: 'The version of the document', mock: '1.0' },
    versionPublishedDate: {
      type: 'string',
      title: 'Version published date',
      helper: 'Use format YYYY-MM-DD HH:MM:SS, for example: 2021-03-20 12:00:00',
      mock: '2020-03-20 12:00:00',
    },
    countryCodes: {
      type: 'array',
      title: 'Countries',
      helper: 'Document will only be accessible for users in these countries',
      items: {
        type: 'string',
        enum: [
          'AR',
          'AT',
          'AU',
          'BE',
          'BG',
          'BR',
          'CA',
          'CL',
          'CN',
          'CO',
          'CZ',
          'DE',
          'DK',
          'EG',
          'ES',
          'FI',
          'FR',
          'GB',
          'GR',
          'HK',
          'HU',
          'IE',
          'IL',
          'IN',
          'IT',
          'JP',
          'KR',
          'MT',
          'MX',
          'MY',
          'NL',
          'NO',
          'NZ',
          'PA',
          'PE',
          'PH',
          'PL',
          'PT',
          'RO',
          'RU',
          'SA',
          'SE',
          'SG',
          'SK',
          'SW',
          'TH',
          'TR',
          'TW',
          'UA',
          'US',
          'VE',
          'VN',
          'XX',
          'ZA',
          'ZZ',
        ],
      },
    },
    header: { type: 'string', helper: 'Document title shown in the training lists', mock: 'Site user manual' },
    file: { type: 'object', title: 'Training material file', _attachment: { id: 'default' }, properties: {} },
  },
  description: 'unify:webtrainingdocument',
  $schema: 'http://json-schema.org/draft-07/schema#',
};
