/* eslint-disable */
export const UnifyWebDevicesComplianceMock = {
  complianceLearnMore: {
    complianceTitle: '[M]Compliance Calculation',
    complianceCalculationBodyMd:
      '[M]An explanation on how compliance is calculated, visible to web users in the portal on the learn more section. Total compliance is calculated according to the following formula. Number of completed tasks / number of maximum expected questionnaires from start of study for the patient until last time the web received responses from the app. Recent time period calculates compliance in the same way, but for tha last X day/week/months completed time period instead of the total period.',
    complianceRateTitle: '[M]Compliance rate',
    complianceRateBodyMd:
      '[M]A compliance rate explanation for the specific device request, visible to web users in the portal on the learn more section.',
    complianceThresholdTitle: '[M]Threshold',
    complianceThresholdBodyMd:
      '[M]A threshold explanation for the specific device request, visible to web users in the portal on the learn more section.',
  },
  complianceIssuesOverview: { issueDomain: '[M]Devices', viewDetailsBtnText: '[M]View Details' },
  metricsTexts: {
    completedSuffix: '[M]completed',
    expectedSuffix: '[M]expected',
    missedSuffix: '[M]missed',
    lastSyncedLabel: '[M]Last received data',
    totalComplianceTitle: '[M]Total compliance',
    totalCompletedTitle: '[M]Total completed',
    thresholdTitle: '[M]Threshold',
    periodComplianceSuffix: '[M]compliance',
    periodTimeUnit: { enum: { DAY: '[M]days', WEEK: '[M]weeks', MONTH: '[M]months', YEAR: '[M]years' } },
  },
};
