import { DataChangeDomain } from './DateChangeDomain';

export enum DataChangeType {
  MODIFY_SINGLE_ANSWER = 'MODIFY_SINGLE_ANSWER',
  INVALIDATE_RESPONSE = 'INVALIDATE_RESPONSE',
  CHANGE_ASSOCIATED_VISIT = 'CHANGE_ASSOCIATED_VISIT',
  MODIFY_LOCAL_TIMESTAMP = 'MODIFY_LOCAL_TIMESTAMP',
  RESTORE_INVALIDATED_RESPONSE = 'RESTORE_INVALIDATED_RESPONSE',
}

const domainDataChangeTypes = {
  [DataChangeDomain.QUESTIONNAIRE]: [
    DataChangeType.MODIFY_SINGLE_ANSWER,
    DataChangeType.INVALIDATE_RESPONSE,
    DataChangeType.CHANGE_ASSOCIATED_VISIT,
    DataChangeType.MODIFY_LOCAL_TIMESTAMP,
  ],
};

export const getDataChangeTypesFromDomain = (domain: DataChangeDomain): DataChangeType[] =>
  domainDataChangeTypes[domain];
