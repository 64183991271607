import { ConfigValue } from './ConfigValue';

export type ConfigValueInfo = {
  value: ConfigValue;
  isEnabled: boolean;
  isDisabled: boolean;
  isDisabledOnSite: boolean;
  isDisabledOnCountry: boolean;
};

const from = (configValue: ConfigValue): ConfigValueInfo => ({
  value: configValue,
  isEnabled: configValue === 'enabled',
  isDisabled: configValue === 'disabled',
  isDisabledOnSite: configValue === 'disabled_on_site',
  isDisabledOnCountry: configValue === 'disabled_on_country',
});

export const ConfigValueInfo = {
  from,
};
