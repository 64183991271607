import { OrgId, RestOptions } from '@csp/csp-common-model';
import { Page } from '@csp/dmdp-api-common-dto';
import {
  DeviceObservationActiveSchedulesOrganizationsQueryV1,
  DeviceObservationActiveSchedulesOrganizationsV1,
  DeviceObservationActiveSchedulesV1,
  DeviceObservationScheduleInV1,
  DeviceObservationSchedulesV1,
  DeviceObservationScheduleV1,
  DeviceObservationScheduleVersionRequestV1,
  DeviceObservationVersionInV1,
  DeviceObservationVersionV1,
} from '@csp/dmdp-api-device-dto';
import { parseJsonWithBigInt } from '@csp/csp-common-axios';
import { devicePodUrl } from '../../dmdp-config/dmdpConfig';

const deviceSchedulesUrl = (): string => `${devicePodUrl()}/device/v1/schedules`;
const deviceActiveSchedulesUrl = (): string => `${devicePodUrl()}/device/v1`;

const getObservationSchedules = async (
  { axios, signal }: RestOptions,
  page?: Page,
): Promise<DeviceObservationSchedulesV1> => {
  const resp = await axios.get<DeviceObservationSchedulesV1>(deviceSchedulesUrl(), {
    params: page,
    signal,
  });

  return resp.data;
};

const upsertObservationSchedule = async (
  { axios, signal }: RestOptions,
  scheduleCode: string,
  schedule: DeviceObservationScheduleInV1,
): Promise<DeviceObservationScheduleV1> => {
  const resp = await axios.put<DeviceObservationScheduleV1>(`${deviceSchedulesUrl()}/${scheduleCode}`, schedule, {
    signal,
  });

  return resp.data;
};

const upsertObservationVersion = async (
  { axios, signal }: RestOptions,
  scheduleCode: string,
  versionCode: string,
  version: DeviceObservationVersionInV1,
): Promise<DeviceObservationVersionV1> => {
  const resp = await axios.put<DeviceObservationVersionV1>(
    `${deviceSchedulesUrl()}/${scheduleCode}/versions/${versionCode}`,
    version,
    {
      signal,
    },
  );

  return resp.data;
};

const getObservationScheduleVersionRequest = async (
  { axios, signal }: RestOptions,
  scheduleCode: string,
  versionCode: string,
  requestCode: string,
): Promise<DeviceObservationScheduleVersionRequestV1> => {
  const resp = await axios.get<DeviceObservationScheduleVersionRequestV1>(
    `${deviceSchedulesUrl()}/${scheduleCode}/versions/${versionCode}/requests/${requestCode}`,
    {
      signal,
    },
  );
  return resp.data;
};

const getActiveSchedulesByOrgId = async (
  { axios, signal }: RestOptions,
  orgId: OrgId,
): Promise<DeviceObservationActiveSchedulesV1> => {
  const url = `${deviceActiveSchedulesUrl()}/organizations/${orgId}/active-schedules`;
  const response = await axios.get<DeviceObservationActiveSchedulesV1>(url, {
    signal,
  });

  return response.data;
};

const queryActiveSchedulesByOrganizationIds = async (
  { axios, signal }: RestOptions,
  queryV1: DeviceObservationActiveSchedulesOrganizationsQueryV1,
): Promise<DeviceObservationActiveSchedulesOrganizationsV1> => {
  const { data } = await axios.post<DeviceObservationActiveSchedulesOrganizationsV1>(
    `${deviceActiveSchedulesUrl()}/active-schedules:query-by-organizations`,
    queryV1,
    {
      transformResponse: parseJsonWithBigInt,
      signal,
    },
  );
  return data;
};

export const DeviceSchedulesRestServiceV1 = {
  getObservationSchedules,
  upsertObservationSchedule,
  upsertObservationVersion,
  getObservationScheduleVersionRequest,
  getActiveSchedulesByOrgId,
  queryActiveSchedulesByOrganizationIds,
};
