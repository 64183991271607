import { ApiOptions, CountValue, OrgId, RestOptions, UserId } from '@csp/csp-common-model';
import { ScheduleRef } from '@csp/csp-common-scheduling/src/lib/model/ScheduleRef';
import { ScheduleRequestComplianceSummary } from '../../model/ScheduleRequestComplianceSummary';
import { ScheduleRequestDateUntilCriteria } from '../../model/ScheduleRequestDateUntilCriteria';
import { ScheduleRequestMetricCriterion } from '../../model/ScheduleRequestMetricCriterion';
import { ScheduleRequestMetricInfo } from '../../model/ScheduleRequestMetricInfo';
import { ScheduleRequestReviewCount } from '../../model/ScheduleRequestReviewCount';
import { QuestionnaireV2MetricAggregationService } from './QuestionnaireV2MetricAggregationService';
import { QuestionnaireV2MetricService } from './QuestionnaireV2MetricService';

const getUserMetricsByScheduleRequest = async (
  userId: UserId,
  metricCriteria: ScheduleRequestMetricCriterion[],
  apiOptions: RestOptions,
): Promise<ScheduleRequestMetricInfo[]> =>
  QuestionnaireV2MetricAggregationService.getMetricInfo(userId, metricCriteria, apiOptions);

const countReviewNeeded = async (
  userId: UserId,
  scheduleRefs: ScheduleRef[],
  apiOptions: RestOptions,
): Promise<ScheduleRequestReviewCount[]> =>
  QuestionnaireV2MetricAggregationService.countReviewNeeded(userId, scheduleRefs, apiOptions);

const getRequestComplianceRefs = (
  onlySiteIds?: OrgId[],
  apiOptions?: ApiOptions,
): Promise<ScheduleRequestComplianceSummary> =>
  QuestionnaireV2MetricService.getRequestComplianceRefs(onlySiteIds, apiOptions);

const getAggregatedCountValueUntilDate = (
  userId: UserId,
  criteria: ScheduleRequestDateUntilCriteria,
  apiOptions?: ApiOptions,
): Promise<CountValue> => QuestionnaireV2MetricAggregationService.getCountValueUntilDate(userId, criteria, apiOptions);

export const QuestionnaireMetricService = {
  getUserMetricsByScheduleRequest,
  countReviewNeeded,
  getRequestComplianceRefs,
  getAggregatedCountValueUntilDate,
};
