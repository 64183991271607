import { fromTimezoneStr } from '@csp/csp-common-date-util';
import { EnumUtil } from '@csp/csp-common-enum-util';
import { EventIdStr, UserId, ZonedDateTime } from '@csp/csp-common-model';
import { AssessmentV1 } from '@csp/dmdp-api-assessment-dto';
import { AssessmentCode } from './AssessmentCode';
import { AssessmentComment } from './AssessmentComment';
import { AssessmentCustomStatus } from './AssessmentCustomStatus';
import { AssessmentId } from './AssessmentId';
import { AssessmentOutcome } from './AssessmentOutcome';
import { AssessmentRef } from './AssessmentRef';
import { AssessmentType } from './AssessmentType';
import { RpmAssessmentType } from './RpmAssessmentType';

export type Assessment = {
  assessmentCode?: AssessmentCode;
  assessmentId: AssessmentId;
  assessmentOutcomes: AssessmentOutcome[];
  assessmentType?: AssessmentType | RpmAssessmentType;
  comments: AssessmentComment[];
  createdTimestamp?: ZonedDateTime;
  customStatuses: AssessmentCustomStatus[];
  eventId: EventIdStr;
  refs: AssessmentRef[];
  userId?: UserId;
};

const from = ({
  assessmentCode,
  assessmentId,
  assessmentType,
  audit,
  comments,
  customStatuses,
  eventId,
  outcomes,
  refs,
  userId,
}: AssessmentV1): Assessment => {
  const createdTimestamp = audit?.createdAt ? fromTimezoneStr(audit?.createdAt) : undefined;

  return {
    assessmentCode,
    assessmentId,
    assessmentOutcomes: outcomes?.map(AssessmentOutcome.from) ?? [],
    assessmentType: EnumUtil.fromMaybeString<RpmAssessmentType>(assessmentType, RpmAssessmentType),
    comments: comments?.map(AssessmentComment.from) ?? [],
    createdTimestamp,
    customStatuses: customStatuses?.map(AssessmentCustomStatus.from) ?? [],
    eventId,
    refs: refs?.map(AssessmentRef.from) ?? [],
    userId,
  };
};

export const Assessment = {
  from,
};
