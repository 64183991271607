import { CmsSourceType } from '../model/type/CmsSourceType';
import { ContentConfigV1 } from '../config/model/ContentConfigV1';

export const getClientVersionBySourceType = (config: ContentConfigV1, type: CmsSourceType): string => {
  switch (type) {
    case 'documents':
      return config.documentsVersion;
    case 'questionnaires':
      return config.questionnairesVersion;
    case 'default':
    default:
      return config.clientVersion;
  }
};
