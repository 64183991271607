import { OrgId, Patient } from '@csp/csp-common-model';
import { OrgPermission } from '../model/OrgPermission';
import { PermissionType } from '../type/PermissionType';

const permissionsByOrgId = new Map<string, OrgPermission>();

const clearPermissions = (): void => {
  permissionsByOrgId.clear();
};

const setPermissions = (permissions: OrgPermission[]): void => {
  clearPermissions();
  permissions.forEach(orgPermission => permissionsByOrgId.set(orgPermission.orgId, orgPermission));
};

const hasPermission = (permission: PermissionType, orgId: string): boolean => {
  const orgPermission = permissionsByOrgId.get(orgId);
  return orgPermission != null && orgPermission.permissions.includes(permission);
};

const hasPatientPermission = (permission: PermissionType, patient?: Patient): boolean =>
  !!patient?.orgIdsIncludingParents.find(orgId => PermissionService.hasPermission(permission, orgId));

const hasOrgBranchPermission = (permission: PermissionType, orgIds: OrgId[]): boolean =>
  !!orgIds.find(orgId => hasPermission(permission, orgId));

export const PermissionService = {
  clearPermissions,
  setPermissions,
  hasPermission,
  hasPatientPermission,
  hasOrgBranchPermission,
};
