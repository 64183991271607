import { QuestionnaireMetricPeriodV1 } from './QuestionnaireMetricPeriodV1';
import { QuestionnaireMobileAppGraphicalPresentationV1 } from './QuestionnaireMobileAppGraphicalPresentationV1';

export const QUESTIONNAIRE_CONFIG_V1_META_NAME = 'CONFIG_V1';

export type QuestionnaireConfigMetaV1 = {
  historicalMetricPeriod?: QuestionnaireMetricPeriodV1;
  minComplianceThreshold?: number;
  patientReview: boolean;
  requireHcpReview: boolean;
  requireSecondAuth: boolean;
  selfReport?: boolean;
  type: string;
  mobileAppGraphicalPresentation?: QuestionnaireMobileAppGraphicalPresentationV1;
};
