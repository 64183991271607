import { ContactPointSystemType, ContactPointV1 } from '@csp/dmdp-api-user-dto';

const from = (contactPoint: ContactPointV1): ContactPoint => ({
  contactPointId: contactPoint.contactPointId,
  system: contactPoint.system as ContactPoint['system'],
  value: contactPoint.value,
  primary: contactPoint.primary,
});
export interface ContactPoint {
  contactPointId?: string;
  system: ContactPointSystemType;
  value: string;
  primary?: boolean;
}

export const ContactPoint = { from };
