/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum OrganizationIncludeFieldV1 {
    ORG_ID = 'ORG_ID',
    EVENT_ID = 'EVENT_ID',
    TYPE = 'TYPE',
    NAME = 'NAME',
    STATUS = 'STATUS',
    PARENT_ID = 'PARENT_ID',
    BRANCH_PARENT_IDS = 'BRANCH_PARENT_IDS',
    TEST = 'TEST',
    DETAILS = 'DETAILS',
    COUNTRY = 'COUNTRY',
    REGION = 'REGION',
    PRACTICE = 'PRACTICE',
    META = 'META',
    EXTERNAL_IDS = 'EXTERNAL_IDS',
    CUSTOM_STATUSES = 'CUSTOM_STATUSES',
    PREVIOUS_CUSTOM_STATUSES = 'PREVIOUS_CUSTOM_STATUSES',
}