import { emptyUserPreferences, Maybe, UserPreferences } from '@csp/csp-common-model';
import { getMeta } from '@csp/csp-common-util';
import { axiosDmdp } from '@csp/csp-fe-auth';
import { UserRestServiceV1 } from '@csp/dmdp-api-client';
import {
  createUserPreferencesMetaV1,
  USER_PREFERENCES_V1_META_TYPE,
  UserPreferencesV1,
  UserV1,
  UserMetaV1,
} from '@csp/dmdp-api-user-dto';

const toUserPreferencesV1 = (userPreferences: UserPreferences): UserPreferencesV1 => ({
  ...userPreferences,
});

const toUserPreferences = (userPreferencesV1: UserPreferencesV1): UserPreferences => ({
  ...userPreferencesV1,
});

const updateUserPreferences = async (user: UserV1, userPreferences: UserPreferences): Promise<void> => {
  const userPreferencesV1 = toUserPreferencesV1(userPreferences);
  return UserRestServiceV1.addOrUpdateMyMeta(
    { axios: axiosDmdp() },
    user,
    createUserPreferencesMetaV1(userPreferencesV1),
  );
};

const getUserPreferences = (userV1: UserV1): UserPreferences => {
  const metas = userV1.metas as Maybe<UserMetaV1[]>;
  const userPreferencesV1 = getMeta(metas, USER_PREFERENCES_V1_META_TYPE)?.data;
  return userPreferencesV1 ? toUserPreferences(userPreferencesV1) : emptyUserPreferences;
};

export const UserPreferencesService = {
  updateUserPreferences,
  getUserPreferences,
};
