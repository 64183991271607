import { Maybe } from '@csp/csp-common-model';
import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { CspPath } from '../../../CspPath';
import { NavigationContext } from './NavigationContext';

export const NavigationContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [pathMapping, setPathMapping] = useState<Record<string, Maybe<CspPath>>>({});

  const setSelectedSubPath = useCallback((parentUrl: CspPath, selectedSubNav: CspPath) => {
    setPathMapping(prev => ({
      ...prev,
      [parentUrl]: selectedSubNav,
    }));
  }, []);

  const getSelectedSubPath = useCallback((parentUrl: CspPath) => pathMapping[parentUrl], [pathMapping]);

  const navigationContext = useMemo(
    () => ({
      setSelectedSubPath,
      getSelectedSubPath,
    }),
    [getSelectedSubPath, setSelectedSubPath],
  );

  return <NavigationContext.Provider value={navigationContext}>{children}</NavigationContext.Provider>;
};
