/* eslint-disable */
export const UnifyScheduleDevicesMock = {
  schedules: [
    {
      scheduleCode: 'STUDY_PROTOCOL_DEVICE_OBSERVATIONS',
      requests: [
        {
          requestCode: 'Action-11.3',
          title: 'Omron-9210T',
          description:
            '[M] Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
          scheduleInfo:
            '[M] Lorem ipsum dolor sit amet, duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
          allowedVersions: [{ version: '1.0' }, { version: '1.1-b' }],
        },
        {
          requestCode: 'Action-11.4',
          title: 'Omron-9200T',
          description:
            '[M] Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
          scheduleInfo:
            '[M] Lorem ipsum dolor sit amet, duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
          allowedVersions: [{ version: '1.0' }, { version: '1.1-b' }],
        },
        {
          requestCode: 'Action-11.5',
          title: 'Masimo Mighty Sat Rx 9809',
          description:
            '[M] Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
          scheduleInfo:
            '[M] Lorem ipsum dolor sit amet, duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
          allowedVersions: [{ version: '1.0' }, { version: '1.1-b' }],
        },
        {
          requestCode: 'Action-11.6',
          title: 'Masimo Mighty Sat Rx 9909',
          description:
            '[M] Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
          scheduleInfo:
            '[M] Lorem ipsum dolor sit amet, duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
          allowedVersions: [{ version: '1.0' }, { version: '1.1-b' }],
        },
        {
          requestCode: 'Action-11.7',
          title: 'Adherium attachment',
          description:
            '[M] Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
          scheduleInfo:
            '[M] Lorem ipsum dolor sit amet, duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
          allowedVersions: [{ version: '1.0' }, { version: '1.1-b' }],
        },
        {
          requestCode: 'Action-11.8',
          title: 'MIR Smart One',
          description:
            '[M] Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
          scheduleInfo:
            '[M] Lorem ipsum dolor sit amet, duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
          allowedVersions: [{ version: '1.0' }, { version: '1.1-b' }],
        },
        {
          requestCode: 'Action-11.9',
          title: 'MIR Spirobank Smart',
          description:
            '[M] Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
          scheduleInfo:
            '[M] Lorem ipsum dolor sit amet, duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
          allowedVersions: [{ version: '1.0' }, { version: '1.1-b' }],
        },
        {
          requestCode: 'Weight_Marsden_DEFAULT',
          title: 'Marsden_M_550',
          thresholdInformation:
            "[M]A notice is triggered when the patient's weight increases more than 2kg compared to their average weight from the last 7 days.",
          description:
            '[M] Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
          scheduleInfo:
            '[M] Lorem ipsum dolor sit amet, duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
          allowedVersions: [{ version: '1.0' }, { version: '1.1-b' }],
        },
      ],
    },
  ],
};
