import { Paging } from '@csp/dmdp-api-common-dto';
import { HealthEventsV1 } from '@csp/dmdp-api-healthevent-dto';
import { HealthEvent } from './HealthEvent';

export interface HealthEvents {
  events: HealthEvent[];
  paging: Paging;
}

const from = (healthEvents: HealthEventsV1): HealthEvents => ({
  paging: healthEvents.paging,
  events: healthEvents.healthEvents.map(HealthEvent.from),
});

export const HealthEvents = { from };
