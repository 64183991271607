/* eslint-disable */
export const UnifyWebPatientHealthEventStudySchema = {
  title: 'UnifyWebPatientHealthEventStudy',
  type: 'object',
  properties: {
    title: { type: 'string' },
    patientHealthEvent: {
      type: 'object',
      title: 'Study specific patient health event',
      helper: 'Study specific content for patient health event oriented content. DCODE must be set',
      properties: {
        viewHealthEvent: {
          type: 'object',
          title: 'Study specific patient health event content',
          properties: {
            text: {
              type: 'object',
              title: 'Texts for study specific patient health event',
              properties: {
                information: {
                  type: 'array',
                  title: 'Information section describing the patient health event',
                  items: {
                    type: 'object',
                    title: 'Information section patient health event',
                    properties: {
                      header: { type: 'string', title: 'Information section header patient health event' },
                      bodyMd: { type: 'string', title: 'Information body (markdown is supported)' },
                    },
                  },
                  mock: [
                    {
                      header: 'Study Definition of Primary Endpoint',
                      bodyMd: 'Health event is defined as hospitalisation for heart failure.',
                    },
                    {
                      header: 'Guidance Information',
                      bodyMd:
                        'If this is a reportable event according to the Clinical Study Protocol please complete the report in the eCRF. Please complete the Transmittal form and upload the source document to the Ethical portal for adjudication.',
                    },
                    {
                      header: 'How to Follow Up',
                      bodyMd:
                        'Please book an un-scheduled visit (phone call or on-site visit) with the patient to follow up on the reported health event if needed.',
                    },
                  ],
                },
              },
            },
          },
        },
      },
    },
  },
  description: 'unify:webpatienthealtheventstudy',
  $schema: 'http://json-schema.org/draft-07/schema#',
};
