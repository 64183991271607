import { AxiosError } from 'axios';

const safelyCastUnknownResponseBody = <T>(errorResponse: AxiosError): T | undefined => {
  try {
    return errorResponse.response?.data as T;
  } catch (err) {
    return undefined;
  }
};

export const JsonUtil = {
  safelyCastUnknownResponseBody,
};
