import { ZoomConfig } from '../model/ZoomConfig';

const singleton: { config: ZoomConfig } = {
  config: ZoomConfig.empty(),
};

const getZoomClientId = (): string => singleton.config.clientId;

const set = (config: ZoomConfig): void => {
  singleton.config = config;
};

const get = (): ZoomConfig => singleton.config;

export const ZoomConfigService = {
  getZoomClientId,
  set,
  get,
};
