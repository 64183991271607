import { RequestWithVersionInfo } from './RequestWithVersionInfo';

export enum RequestVersionStatus {
  /** The request is part of an active version */
  ACTIVE = 'ACTIVE',
  /** The request is part of an expired version */
  EXPIRED = 'EXPIRED',
  /** The request is part of a version scheduled to start in the future */
  FUTURE = 'FUTURE',
  /**
   * The request is part of an expired version, and there are
   * newer versions in the schedule containing the same requestCode
   */
  SUPERSEDED = 'SUPERSEDED',
}

const from = (
  { versionInfo, requestCode, scheduleCode }: RequestWithVersionInfo,
  allRequestsWithVersionInfo: RequestWithVersionInfo[],
): RequestVersionStatus => {
  const now = Date.now();
  if (versionInfo.activeFrom.unixTimeMillis > now) {
    return RequestVersionStatus.FUTURE;
  } else {
    if (versionInfo.activeTo && versionInfo.activeTo.unixTimeMillis < now) {
      const newerVersionsThatHaveBeenOrIsActive = allRequestsWithVersionInfo.filter(
        request =>
          request.scheduleCode === scheduleCode &&
          request.requestCode === requestCode &&
          request.versionInfo.activeFrom.unixTimeMillis > versionInfo.activeFrom.unixTimeMillis &&
          request.versionInfo.activeFrom.unixTimeMillis < now,
      );
      return newerVersionsThatHaveBeenOrIsActive.length > 0
        ? RequestVersionStatus.SUPERSEDED
        : RequestVersionStatus.EXPIRED;
    } else {
      return RequestVersionStatus.ACTIVE;
    }
  }
};

export const RequestVersionStatuses = {
  from,
};
