import { localeActions, SignOutService, studyService } from '@csp/common';
import { OrgId, Site, Study, UnknownError, User } from '@csp/csp-common-model';
import { OrgService, UserService } from '@csp/csp-common-user';
import { TelemetryService } from '@csp/csp-web-telemetry';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { BroadcastChannelService } from '@csp/web-common';
import { LoginPayload } from '../model/LoginPayload';
import { Principal } from '../model/Principal';
import { RefreshStudyPayload } from '../model/RefreshStudyPayload';
import { AuthService } from '../service/AuthService';

export const loginPrincipalThunk = createAsyncThunk<Principal, LoginPayload, { rejectValue: UnknownError }>(
  'principal_login',
  async (payload: LoginPayload, { rejectWithValue, dispatch }) => {
    try {
      const principal = await AuthService.loginDmdpAndLoadUser(payload);
      dispatch(localeActions.setLocaleFromUser(principal.user.userV1));
      return principal;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const signOutPrincipalThunk = createAsyncThunk<void, undefined, { rejectValue: UnknownError }>(
  'sign_out_principal',
  async (_, { rejectWithValue }) => {
    try {
      BroadcastChannelService.postMessage('unify', { type: 'sign-out' });
      await SignOutService.signOut();
      await TelemetryService.reset();
    } catch (error) {
      rejectWithValue(error);
    }
  },
);

export const refreshUserThunk = createAsyncThunk<User, undefined, { rejectValue: UnknownError }>(
  'refresh_user',
  async (_, { rejectWithValue }) => {
    try {
      return await UserService.getMine();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const refreshSitesThunk = createAsyncThunk<Site[], OrgId[], { rejectValue: UnknownError }>(
  'refresh_sites',
  async (orgIds, { rejectWithValue }) => {
    try {
      return await OrgService.fetchSitesByIds(orgIds);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const refreshStudyThunk = createAsyncThunk<Study, RefreshStudyPayload, { rejectValue: UnknownError }>(
  'refresh_study',
  async ({ study, user }, { rejectWithValue }): Promise<Study> => {
    try {
      return await studyService.getStudyOrStudyInfo(study.tenantId, user);
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);
