import { RequestTagsStudyConfigV1, RequestTagsStudyConfigV1Schema } from '@csp/config-schemas';
import { RequestTagsStudyConfig } from '@csp/csp-common-model/src/lib/config/RequestTagsStudyConfig';
import { JsonValidationService } from '@csp/csp-common-util';

const toRequestTagsStudyConfig = (requestTagsStudyConfigV1: RequestTagsStudyConfigV1): RequestTagsStudyConfig => {
  JsonValidationService.validateJsonNonStrict(
    'RequestTagsStudyConfigV1.json',
    RequestTagsStudyConfigV1Schema,
    requestTagsStudyConfigV1,
  );

  return {
    enableAutomaticDeviceSessionsDefaultTagging:
      requestTagsStudyConfigV1?.enableAutomaticDeviceSessionsDefaultTagging ?? false,
  };
};

export const RequestTagsStudyConfigService = {
  toRequestTagsStudyConfig,
};
