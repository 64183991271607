/* eslint-disable */
export const UnifyWebPatientStudySchema = {
  title: 'UnifyWebPatientStudy',
  type: 'object',
  properties: {
    title: { type: 'string' },
    patientHealthEvent: {
      type: 'object',
      title: 'Study specific Wen patient',
      helper: 'Study specific content for patient oriented content. DCODE must be set',
      properties: {
        viewHealthEvent: {
          type: 'object',
          title: 'Study specific Health Event content',
          properties: {
            text: {
              type: 'object',
              title: 'Texts for study specific Health Event',
              properties: {
                information: {
                  type: 'array',
                  title: 'Information section describing the Health Events',
                  items: {
                    type: 'object',
                    title: 'Information section',
                    properties: {
                      header: { type: 'string', title: 'Information section header' },
                      bodyMd: { type: 'string', title: 'Information body (markdown is supported' },
                    },
                  },
                  mock: [
                    {
                      header: 'Study criteria',
                      bodyMd:
                        'Time to the first occurrence of any of the components of this composite:\n\n* CV death\n* Hypertensive heart failure (HHF)',
                    },
                    {
                      header: 'Guidance Information',
                      bodyMd:
                        '* Complete the report in the eCRF\n* Upload any documentation to sharepoint\n* Book a patient visit if required',
                    },
                    {
                      header: 'How to follow up',
                      bodyMd: 'If you need to follow up with the patient you can book an unscheduled visit here',
                    },
                  ],
                },
              },
            },
          },
        },
      },
    },
    ecoa: {
      type: 'object',
      title: 'Study specific eCOA content',
      properties: {
        ecoaList: {
          type: 'object',
          title: 'Study specific eCOA content eCOA list',
          properties: {
            schedule: {
              type: 'object',
              title: 'Study specific schedule content',
              properties: {
                title: {
                  type: 'object',
                  title: 'Title for schedule',
                  properties: { header: { type: 'string', title: 'Header for schedule', mock: 'Schedule' } },
                },
                text: {
                  type: 'object',
                  title: 'Texts for schedule',
                  properties: {
                    bodyMd: {
                      type: 'string',
                      title: 'Body for schedule',
                      mock: 'The study protocol requires the patient completes the EQ-5D-5L questionnaire to provide their overall status of health. ',
                    },
                  },
                },
              },
            },
            text: {
              type: 'object',
              title: 'Texts for study specific eCOA information (optional) in top of list',
              properties: {
                information: {
                  type: 'array',
                  title: 'Information section describing the eCOA',
                  helper: 'The text will be shown in sections with header and body. Max width 4 columns',
                  items: {
                    type: 'object',
                    title: 'Information section',
                    properties: {
                      header: { type: 'string', title: 'Information section header' },
                      bodyMd: { type: 'string', title: 'Information body (markdown is supported' },
                    },
                  },
                  mock: [
                    {
                      header: 'eCOA description',
                      bodyMd:
                        'The study protocol requires the patient to complete the EQ-5D-5L questionnaire to provide their overall status of health.',
                    },
                    {
                      header: 'Schedule',
                      bodyMd:
                        'The questionnaire is scheduled once per month, with an available date range in which the inquiry will be open to the patient',
                    },
                    {
                      header: 'Threshold',
                      bodyMd: 'If the percentage is below 85%, the patient is considered not compliant',
                    },
                  ],
                },
              },
            },
          },
        },
      },
    },
  },
  description: 'unify:webpatientstudy',
  $schema: 'http://json-schema.org/draft-07/schema#',
};
