import { isArray, memoize } from 'lodash';
import traverse, { TraverseContext } from 'traverse';

const skipDebugContentKeys = [
  'measurementValueUnits',
  'identifier',
  'questionnaireCode',
  'role',
  'country',
  'patient_medication_status',
];

/**
 * Returns true if the current key matches an entry in skipDebugContentKeys.
 * Also returns true if the current node that's being traversed is an array and the name of the array matches an entry in skipDebugContentKeys
 * @param traverseContext
 */
const shouldSkipDebugContent = (traverseContext: TraverseContext): boolean => {
  if (!traverseContext.key) {
    return false;
  }

  if (skipDebugContentKeys.includes(traverseContext.key)) {
    return true;
  }

  if (traverseContext.parent && traverseContext.parent.key) {
    if (isArray(traverseContext.parent.node) && skipDebugContentKeys.includes(traverseContext.parent.key)) {
      return true;
    }
  }

  return false;
};

/**
 * Used to create CMS debug content from real data, for debugging purposes.
 *
 * @param content a content instance (or mack content) that should be transformed
 * @param prefix name of the content type
 * @param cmsType name of the CMS which the content is fetched from, defaults to [CMS] (Cloud CMS)
 */
export const toDebugContent = <T>(content: unknown, prefix: string, cmsType = '[☁️]'): T =>
  traverse(content).map(function () {
    if (this.isLeaf) {
      // Skip content properties used for filtering, to prevent errors in runtime
      if (shouldSkipDebugContent(this)) {
        return;
      }

      this.update(getCmsDebugPath(cmsType, prefix, this.path.join('.')));
    }
  });

export const memoizedToDebugContent: typeof toDebugContent = memoize(toDebugContent, res => res);

export const getCmsDebugPath = (cmsType: string, prefix: string, path: string): string =>
  `${cmsType} ${prefix} > ${path}`;
