/* eslint-disable */
export const UnifyWebStudyPatientMedicationSchema = {
  description: 'unify:webstudypatientmedication',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'UnifyWebStudyPatientMedication',
  type: 'object',
  properties: {
    drawer: {
      type: 'object',
      title: 'Patient Medication Drawer content',
      properties: {
        title: {
          type: 'object',
          title: 'Drawer content texts',
          properties: {
            learnMore: {
              type: 'string',
              title: 'Learn more drawer header',
              mock: 'Learn more',
              helper: 'Learn more drawer header text',
            },
          },
        },
        learnMorePrescribedMedication: {
          type: 'object',
          title: 'Learn more about prescribed medication',
          properties: {
            title: {
              type: 'object',
              title: 'Titles for prescribed medication',
              properties: {
                availablePrescriptions: {
                  type: 'string',
                  title: 'Available prescriptions title',
                  placeholder: 'Available prescriptions',
                  mock: 'Available prescriptions',
                },
              },
            },
          },
        },
        learnMoreInfoShared: {
          type: 'object',
          title: 'Drawer content for info shared',
          properties: {
            alert: {
              type: 'object',
              title: 'Info shared alerts',
              properties: {
                disclaimer: {
                  type: 'object',
                  title: 'Disclaimer for info shared',
                  properties: {
                    title: { type: 'string', title: 'Title of disclaimer', mock: 'Disclaimer' },
                    message: {
                      type: 'string',
                      title: 'Disclaimer message',
                      mock: 'The patient app doesn’t replace direct communication with the patient. Never rely solely on the patient app to communicate any medication related issue with the patient.',
                    },
                  },
                },
              },
            },
            title: {
              type: 'object',
              title: 'Titles for info shared drawer content',
              properties: {
                mainContentTitle: {
                  type: 'string',
                  title: 'Title of the drawer content',
                  mock: 'Information shared with the patient app',
                },
              },
            },
            texts: {
              type: 'array',
              title: 'Drawer content section for info shared texts',
              items: {
                type: 'object',
                title: 'Content section',
                properties: {
                  header: { type: 'string', title: 'Content section header', allowOptionalEmpty: true },
                  bodyMd: { type: 'string', title: 'Content body (markdown is supported)', allowOptionalEmpty: true },
                  note: {
                    type: 'object',
                    title: 'Note relevant for this content section',
                    properties: {
                      title: { type: 'string', title: 'Title of note', allowOptionalEmpty: true },
                      message: { type: 'string', title: 'Note message', allowOptionalEmpty: true },
                    },
                  },
                  medicationCard: {
                    type: 'object',
                    title: 'Medication card example',
                    properties: {
                      cardLabel: { type: 'string', title: 'Card label', mock: 'Medication', allowOptionalEmpty: true },
                      nameOfMedicine: {
                        type: 'string',
                        title: 'Name of medicine',
                        mock: 'Iressa',
                        allowOptionalEmpty: true,
                      },
                      medicationInstruction: {
                        type: 'string',
                        title: 'Medication instructions',
                        mock: 'Take once daily. Swallow with water.',
                        allowOptionalEmpty: true,
                      },
                      dosageLabel: { type: 'string', title: 'Dosage label', mock: 'Dosage', allowOptionalEmpty: true },
                      dosageText: { type: 'string', title: 'Dosage', mock: '1 pill', allowOptionalEmpty: true },
                      timeLabel: { type: 'string', title: 'Time label', mock: 'Time', allowOptionalEmpty: true },
                      timeText: { type: 'string', title: 'Time', mock: '10:00', allowOptionalEmpty: true },
                    },
                  },
                },
              },
              mock: [
                {
                  header: 'Prerequesites',
                  bodyMd:
                    'Only patients who use the patient app after having accepted the invitation to the study (Unify App user status “Yes”) are receiving medication related information through the patient app. ',
                },
                {
                  header: 'What is shown to the patient?',
                  bodyMd:
                    'A medication card is shown to the patient on their home screen inside the patient app. This card contains medication name, timing, number of dosages and dosing instructions as specified by the physician in the Unify web portal.',
                  note: {
                    title: 'Note',
                    message:
                      'Patients have the ability to turn off reminders through the operating system on their phone. Never rely on reminders to replace direct patient communication and personal check-ins.',
                  },
                  medicationCard: {
                    cardLabel: 'Medication',
                    nameOfMedicine: 'Iressa',
                    medicationInstruction: 'Take once daily. Swallow with water.',
                    dosageLabel: 'Dosage',
                    dosageText: '1 pill',
                    timeLabel: 'Time',
                    timeText: '10:00',
                  },
                },
                {
                  header: 'Patient Reminders',
                  bodyMd:
                    'While medication is “ongoing”, reminders are sent to the patient to help ensure compliance. Refer to “Pausing of medication” to learn more.',
                },
                {
                  header: 'Pausing of medication',
                  bodyMd:
                    'If you are using Unify as a physician, you may pause medication manually, which will be reflected on the medication card in the patient app. Pausing medication also pauses patient reminders.',
                },
                {
                  header: 'Push notifications',
                  bodyMd:
                    'Any change to the medication such as start and stop of medication, the pausing of medication, triggers a push notification informing the patient about the change. Regardless of a notification being sent, make sure to have spoken to the patient before making changes to medication.',
                  note: {
                    title: 'Note',
                    message:
                      'Patients have the ability to turn off push notifications through the operating system on their phone. Never rely on notifications alone to communicate medication changes to your patients.',
                  },
                },
              ],
            },
          },
        },
      },
    },
  },
};
