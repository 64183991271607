import { forwardRef } from 'react';
import styled from '@emotion/styled';
import { compose, display, DisplayProps, sizing, SizingProps, spacing, SpacingProps } from '@mui/system';
import { Box } from '../layout/box/Box';
import { IllustrationType, svgs } from './model/IllustrationSvgs';

type IllustrationProps = {
  name: IllustrationType;
  altText?: string;
} & SpacingProps &
  SizingProps &
  DisplayProps;

const Illustration = forwardRef<HTMLOrSVGElement, IllustrationProps>(function Illustration(
  { name, altText, ...rest },
  ref,
) {
  const SelectedIllustration = svgs[name];

  if (!SelectedIllustration) {
    return null;
  }

  const StyledSelectedIllustration = styled(SelectedIllustration)`
    width: 100%;
    height: 100%;
  `;

  return (
    <Box ref={ref} {...rest}>
      <StyledSelectedIllustration role="img" title={altText} />
    </Box>
  );
});

const WithMuiSystemIllustration = styled(Illustration)<IllustrationProps>`
  ${compose(spacing, sizing, display)}
`;

export { WithMuiSystemIllustration as Illustration };
