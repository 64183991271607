/* eslint-disable */
export const UnifyHFE_V1Schema = {
  title: 'UnifyHFE_V1',
  type: 'object',
  properties: {
    title: { type: 'string' },
    version: { type: 'string', mock: '1', enum: ['1'] },
    HFE01: {
      type: 'object',
      title: 'Question 1',
      helper: 'HFE01',
      properties: {
        questionnaireItemType: {
          title: 'Questionnaire Item Type',
          type: 'string',
          mock: 'QUESTION',
          enum: ['QUESTION'],
        },
        questionType: { title: 'Question Type', type: 'string', mock: 'RADIO_BUTTON', enum: ['RADIO_BUTTON'] },
        headerMd: {
          type: 'string',
          mock: 'Have you been hospitalized due to heart failure within the last four weeks?',
        },
        optional: { title: 'Optional question', type: 'boolean', mock: false },
        answers: {
          type: 'object',
          title: 'Answers',
          helper: 'Available answer options',
          properties: {
            '1': {
              type: 'object',
              title: 'Answer Option 1 : Yes',
              properties: {
                text: { type: 'string', mock: 'Yes' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                nextId: {
                  title: 'Next Question Item Id',
                  helper: 'Which question to show next',
                  type: 'string',
                  mock: 'HFE02',
                  enum: ['HFE02'],
                },
              },
            },
            '2': {
              type: 'object',
              title: 'Answer Option 2 : No',
              properties: {
                text: { type: 'string', mock: 'No' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
              },
            },
          },
        },
      },
    },
    HFE02: {
      type: 'object',
      title: 'Question 2',
      helper: 'EQ5D0202',
      properties: {
        questionnaireItemType: {
          title: 'Questionnaire Item Type',
          type: 'string',
          mock: 'QUESTION',
          enum: ['QUESTION'],
        },
        questionType: { title: 'Question Type', type: 'string', mock: 'RADIO_BUTTON', enum: ['RADIO_BUTTON'] },
        headerMd: { type: 'string', mock: 'Did your hospitalisation include an overnight stay?' },
        optional: { title: 'Optional question', type: 'boolean', mock: false },
        answers: {
          type: 'object',
          title: 'Answers',
          helper: 'Available answer options',
          properties: {
            '1': {
              type: 'object',
              title: 'Answer Option 1 : Yes',
              properties: {
                text: { type: 'string', mock: 'Yes' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which question is before',
                  type: 'string',
                  mock: 'HFE01',
                  enum: ['HFE01'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which question is next',
                  type: 'string',
                  mock: 'HFE03',
                  enum: ['HFE03'],
                },
              },
            },
            '2': {
              type: 'object',
              title: 'Answer Option 2: No',
              properties: {
                text: { type: 'string', mock: 'No' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which question is before',
                  type: 'string',
                  mock: 'HFE01',
                  enum: ['HFE01'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which question is next',
                  type: 'string',
                  mock: 'HFE03',
                  enum: ['HFE03'],
                },
              },
            },
          },
        },
      },
    },
    HFE03: {
      type: 'object',
      title: 'Question 3',
      helper: 'HFE03',
      properties: {
        questionnaireItemType: {
          title: 'Questionnaire Item Type',
          type: 'string',
          mock: 'QUESTION',
          enum: ['QUESTION'],
        },
        questionType: { title: 'Question Type', type: 'string', mock: 'RADIO_BUTTON', enum: ['RADIO_BUTTON'] },
        headerMd: { type: 'string', mock: 'Prior to the hospitalisation, did you experience shortness of breath?' },
        optional: { title: 'Optional question', type: 'boolean', mock: false },
        answers: {
          type: 'object',
          title: 'Answers',
          helper: 'Available answer options',
          properties: {
            '1': {
              type: 'object',
              title: 'Answer Option 1 : Yes',
              properties: {
                text: { type: 'string', mock: 'Yes' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which question is before',
                  type: 'string',
                  mock: 'HFE02',
                  enum: ['HFE02'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which question is next',
                  type: 'string',
                  mock: 'HFE04',
                  enum: ['HFE04'],
                },
              },
            },
            '2': {
              type: 'object',
              title: 'Answer Option 2: No',
              properties: {
                text: { type: 'string', mock: 'No' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which question is before',
                  type: 'string',
                  mock: 'HFE02',
                  enum: ['HFE02'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which question is next',
                  type: 'string',
                  mock: 'HFE04',
                  enum: ['HFE04'],
                },
              },
            },
          },
        },
      },
    },
    HFE04: {
      type: 'object',
      title: 'Question 4',
      helper: 'HFE04',
      properties: {
        questionnaireItemType: {
          title: 'Questionnaire Item Type',
          type: 'string',
          mock: 'QUESTION',
          enum: ['QUESTION'],
        },
        questionType: { title: 'Question Type', type: 'string', mock: 'RADIO_BUTTON', enum: ['RADIO_BUTTON'] },
        headerMd: { type: 'string', mock: 'Prior to the hospitalisation, did you experience fatigue?' },
        optional: { title: 'Optional question', type: 'boolean', mock: false },
        answers: {
          type: 'object',
          title: 'Answers',
          helper: 'Available answer options',
          properties: {
            '1': {
              type: 'object',
              title: 'Answer Option 1 : Yes',
              properties: {
                text: { type: 'string', mock: 'Yes' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which question is before',
                  type: 'string',
                  mock: 'HFE03',
                  enum: ['HFE03'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which question is next',
                  type: 'string',
                  mock: 'HFE05',
                  enum: ['HFE05'],
                },
              },
            },
            '2': {
              type: 'object',
              title: 'Answer Option 2: No',
              properties: {
                text: { type: 'string', mock: 'No' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which question is before',
                  type: 'string',
                  mock: 'HFE05',
                  enum: ['HFE05'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which question is next',
                  type: 'string',
                  mock: 'HFE05',
                  enum: ['HFE05'],
                },
              },
            },
          },
        },
      },
    },
    HFE05: {
      type: 'object',
      title: 'Question 5',
      helper: 'HFE05',
      properties: {
        questionnaireItemType: {
          title: 'Questionnaire Item Type',
          type: 'string',
          mock: 'QUESTION',
          enum: ['QUESTION'],
        },
        questionType: { title: 'Question Type', type: 'string', mock: 'RADIO_BUTTON', enum: ['RADIO_BUTTON'] },
        headerMd: {
          type: 'string',
          mock: 'Prior to the hospitalisation, did you experience a limited ability to perform daily physical activities?',
        },
        optional: { title: 'Optional question', type: 'boolean', mock: false },
        answers: {
          type: 'object',
          title: 'Answers',
          helper: 'Available answer options',
          properties: {
            '1': {
              type: 'object',
              title: 'Answer Option 1 : Yes',
              properties: {
                text: { type: 'string', mock: 'Yes' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which question is before',
                  type: 'string',
                  mock: 'HFE04',
                  enum: ['HFE04'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which question is next',
                  type: 'string',
                  mock: 'HFE06',
                  enum: ['HFE06'],
                },
              },
            },
            '2': {
              type: 'object',
              title: 'Answer Option 2: No',
              properties: {
                text: { type: 'string', mock: 'No' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which question is before',
                  type: 'string',
                  mock: 'HFE04',
                  enum: ['HFE04'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which question is next',
                  type: 'string',
                  mock: 'HFE06',
                  enum: ['HFE06'],
                },
              },
            },
          },
        },
      },
    },
    HFE06: {
      type: 'object',
      title: 'Question 6',
      helper: 'HFE06',
      properties: {
        questionnaireItemType: {
          title: 'Questionnaire Item Type',
          type: 'string',
          mock: 'QUESTION',
          enum: ['QUESTION'],
        },
        questionType: { title: 'Question Type', type: 'string', mock: 'RADIO_BUTTON', enum: ['RADIO_BUTTON'] },
        headerMd: {
          type: 'string',
          mock: 'Did you receive additional diuretic treatment (water pill or as injection) to ease your symptoms during your hospitalisation?',
        },
        optional: { title: 'Optional question', type: 'boolean', mock: false },
        answers: {
          type: 'object',
          title: 'Answers',
          helper: 'Available answer options',
          properties: {
            '1': {
              type: 'object',
              title: 'Answer Option 1 : Yes',
              properties: {
                text: { type: 'string', mock: 'Yes' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which question is before',
                  type: 'string',
                  mock: 'HFE05',
                  enum: ['HFE05'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which question is next',
                  type: 'string',
                  mock: 'HFE07',
                  enum: ['HFE07'],
                },
              },
            },
            '2': {
              type: 'object',
              title: 'Answer Option 2: No',
              properties: {
                text: { type: 'string', mock: 'No' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which question is before',
                  type: 'string',
                  mock: 'HFE05',
                  enum: ['HFE05'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which question is next',
                  type: 'string',
                  mock: 'HFE07',
                  enum: ['HFE07'],
                },
              },
            },
            '3': {
              type: 'object',
              title: "Answer Option 3: Don't know",
              properties: {
                text: { type: 'string', mock: "Don't know" },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which question is before',
                  type: 'string',
                  mock: 'HFE05',
                  enum: ['HFE05'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which question is next',
                  type: 'string',
                  mock: 'HFE07',
                  enum: ['HFE07'],
                },
              },
            },
          },
        },
      },
    },
    HFE07: {
      type: 'object',
      title: 'Question 7',
      helper: 'HFE07',
      properties: {
        questionnaireItemType: {
          title: 'Questionnaire Item Type',
          type: 'string',
          mock: 'QUESTION',
          enum: ['QUESTION'],
        },
        questionType: { title: 'Question Type', type: 'string', mock: 'RADIO_BUTTON', enum: ['RADIO_BUTTON'] },
        headerMd: {
          type: 'string',
          mock: 'Was the main reason for your symptoms that led to the hospitalisation a diagnosis of heart failure?',
        },
        optional: { title: 'Optional question', type: 'boolean', mock: false },
        answers: {
          type: 'object',
          title: 'Answers',
          helper: 'Available answer options',
          properties: {
            '1': {
              type: 'object',
              title: 'Answer Option 1 : Yes',
              properties: {
                text: { type: 'string', mock: 'Yes' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which question is before',
                  type: 'string',
                  mock: 'HFE06',
                  enum: ['HFE06'],
                },
              },
            },
            '2': {
              type: 'object',
              title: 'Answer Option 2: No',
              properties: {
                text: { type: 'string', mock: 'No' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which question is before',
                  type: 'string',
                  mock: 'HFE06',
                  enum: ['HFE06'],
                },
              },
            },
            '3': {
              type: 'object',
              title: "Answer Option 3: Don't know",
              properties: {
                text: { type: 'string', mock: "Don't know" },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which question is before',
                  type: 'string',
                  mock: 'HFE06',
                  enum: ['HFE06'],
                },
              },
            },
          },
        },
      },
    },
  },
  description: 'unify:hfev1',
  $schema: 'http://json-schema.org/draft-07/schema#',
};
