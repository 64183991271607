import { StudyConfig, FeatureToggleConfig, StudyDeviceConfig } from '@csp/csp-common-config';
import { MetricsConfigV1, StudyMetricsConfigV1 } from '@csp/config-schemas';
import {
  isEnrolmentMetricType,
  Maybe,
  StudyMetricType,
  StudyPatientAllowedUsernameEmailDomains,
  StudyPatientDateOfBirthFormats,
  StudyPatientStatusMappings,
  StudyRescreenConfig,
} from '@csp/csp-common-model';
import { createSelector, Selector } from '@reduxjs/toolkit';
import { RootState } from '../../../state/store';
import { canViewOtherSitesMetricsSelector } from '../../security/ducks/principalPermissionSelectors';
import { StudyConfigState } from '../model/StudyConfigState';

/** Used for passing additional arguments to selector functions. */
const metricTypeSelector = (_: RootState, metricType: StudyMetricType): StudyMetricType => metricType;

const getStudyConfigSelector = (state: RootState): Maybe<StudyConfig> => state.studyConfig.config;

export const getStudyConfigStateSelector = (state: RootState): StudyConfigState => state.studyConfig;

export const getStudyConfigStateLoadingSelector = (state: RootState): boolean =>
  getStudyConfigStateSelector(state).isLoading || false;

export const getStudyConfigErrorSelector = (state: RootState): Maybe<Error> => getStudyConfigStateSelector(state).error;

export const hasStudyConfigSelector = (state: RootState): boolean => !!getStudyConfigSelector(state);

export const getPatientStatusMappings = (state: RootState): Maybe<StudyPatientStatusMappings> =>
  getStudyConfigSelector(state)?.patientStatusMappings;

export const getPatientDateOfBirthFormats = (state: RootState): Maybe<StudyPatientDateOfBirthFormats> =>
  getStudyConfigSelector(state)?.patientValidation.dateOfBirthFormats;

export const getPatientAllowedUsernameEmailDomains = (
  state: RootState,
): Maybe<StudyPatientAllowedUsernameEmailDomains> =>
  getStudyConfigSelector(state)?.patientValidation.usernameEmailDomains;

export const getFeatureConfigSelector = (state: RootState): Maybe<FeatureToggleConfig> =>
  getStudyConfigSelector(state)?.featureConfig;

export const getStudyRescreenConfigSelector = (state: RootState): Maybe<StudyRescreenConfig> =>
  getStudyConfigSelector(state)?.rescreenConfig;

export const getStudyMetricsConfigSelector: Selector<RootState, Maybe<StudyMetricsConfigV1>> = createSelector(
  canViewOtherSitesMetricsSelector,
  getStudyConfigSelector,
  (canViewOtherSitesMetrics, config) => (canViewOtherSitesMetrics ? config?.metricsConfig : undefined),
);

export const getMetricsConfigSelector: Selector<RootState, Maybe<MetricsConfigV1>> = createSelector(
  [getStudyMetricsConfigSelector, metricTypeSelector],
  (metricsConfig, metricType) =>
    metricsConfig && isEnrolmentMetricType(metricType) ? metricsConfig[metricType] : undefined,
);

export const getStudyDeviceConfigSelector = (state: RootState): Maybe<StudyDeviceConfig> =>
  getStudyConfigSelector(state)?.deviceConfig;
