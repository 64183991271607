import { HealthEventStatusTypeV1 } from '@csp/dmdp-api-healthevent-dto';
import { HealthEventStatusV1 } from '@csp/dto';

export const toHealthEventStatusTypeV1 = (status: HealthEventStatusV1): HealthEventStatusTypeV1 => {
  switch (status) {
    case HealthEventStatusV1.NEW:
      return 'NEW';
    case HealthEventStatusV1.NO_ENDPOINT:
      return 'NO_ENDPOINT';
    case HealthEventStatusV1.ONGOING:
      return 'ONGOING';
    case HealthEventStatusV1.POT_ENDPOINT:
      return 'POT_ENDPOINT';
    case HealthEventStatusV1.VIEWED:
      return 'VIEWED';
  }
};

export const toHealthEventStatus = (healthEventStatus: HealthEventStatusTypeV1): HealthEventStatusV1 => {
  switch (healthEventStatus) {
    case 'NEW':
      return HealthEventStatusV1.NEW;
    case 'ONGOING':
      return HealthEventStatusV1.ONGOING;
    case 'POT_ENDPOINT':
      return HealthEventStatusV1.POT_ENDPOINT;
    case 'NO_ENDPOINT':
      return HealthEventStatusV1.NO_ENDPOINT;
    case 'VIEWED':
      return HealthEventStatusV1.VIEWED;
  }
};
