import { Study } from '@csp/csp-common-model';
import {
  WebHcpNavigationHeaderTopBarBlock,
  WebHcpNavigationTabsTabsBlock,
  WebHcpPatientListNavigationTabBlock,
} from '@csp/csp-web-content-definitions';
import { NavTab } from '@csp/web-common';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useMatch, useNavigate } from 'react-router-dom';
import { useStudyContext } from '../../../common/context/study/useStudyContext';
import { StaticContentService } from '../../../common/service/StaticContentService';
import { CspPath, DEFAULT_AUTHENTICATED_PATH } from '../../../CspPath';
import { useCmsRemotePatientMonitoring } from '../../cms/hook/unify/useCmsRemotePatientMonitoring';
import { useQueryTotalRpmEventsNotViewed } from '../../patient-list/patient-list-rpm-events/hook/useQueryTotalRpmEventsNotViewed';
import { useVisitRequests } from '../../patient-list/patient-list-visit/hook/useVisitRequests';
import { hasMultipleStudiesSelector } from '../../studies/ducks/studiesSelectors';

type Texts = {
  topBar: WebHcpNavigationHeaderTopBarBlock;
  tabs: WebHcpNavigationTabsTabsBlock;
  navTabs: WebHcpPatientListNavigationTabBlock;
};

type Out = {
  goBack(): void;
  study: Study;
  patientListSubNavs: NavTab<CspPath>[];
  texts: Texts;
  hasMoreThanOneStudy: boolean;
};

export const useMainNavPages = (): Out => {
  const { study, clearSelectedStudy } = useStudyContext();

  const topBarBlock = StaticContentService.getPublicBlock('webHcpNavigationHeader', 'topBar');
  const tabsBlock = StaticContentService.getPublicBlock('webHcpNavigationTabs', 'tabs');
  const navigationTabTexts = StaticContentService.getGenericBlock('webHcpPatientList', 'navigationTab');
  const { data: rpmTexts } = useCmsRemotePatientMonitoring();

  const isAtCloseoutPath = !!useMatch(CspPath.PATIENT_LIST_CLOSEOUT);
  const isAtBookVisitPath = !!useMatch(CspPath.PATIENT_LIST_VISIT);
  const isAtHbsPath = !!useMatch(CspPath.PATIENT_LIST_HBS);
  const isAtRpmEventsPath = !!useMatch(CspPath.PATIENT_LIST_RPM_EVENTS);

  const navigate = useNavigate();

  const { hbsEnabled, isLoadingScheduleRequest } = useVisitRequests();
  const { totalRpmEventsNotViewed } = useQueryTotalRpmEventsNotViewed();

  const hasMoreThanOneStudy = useSelector(hasMultipleStudiesSelector);

  const goBack = (): void => {
    clearSelectedStudy();
    navigate(CspPath.STUDIES);
  };

  const isVisitEnabledOnAnySite = !!study.features?.summary.visits.isEnabledOnAnySite;

  const shouldNavigateToDefaultPath =
    (isAtCloseoutPath && !study.isCloseoutPeriodEnabled) ||
    (!isVisitEnabledOnAnySite && isAtBookVisitPath) ||
    (!hbsEnabled && !isLoadingScheduleRequest && isAtHbsPath) ||
    (!study.hasRpm && isAtRpmEventsPath);

  useEffect(() => {
    if (shouldNavigateToDefaultPath) {
      navigate(DEFAULT_AUTHENTICATED_PATH, { replace: true });
    }
  }, [shouldNavigateToDefaultPath, navigate]);

  const patientListSubNavs: NavTab<CspPath>[] = [
    {
      label: navigationTabTexts.textLabel.bookedVisits.label,
      path: CspPath.PATIENT_LIST_VISIT,
      isEnabledFeature: isVisitEnabledOnAnySite,
    },
    {
      label: navigationTabTexts.textLabel.closeoutTracker.label,
      path: CspPath.PATIENT_LIST_CLOSEOUT,
      isEnabledFeature: study.isCloseoutPeriodEnabled,
    },
    {
      label: navigationTabTexts.textLabel.bioSamples.label,
      path: CspPath.PATIENT_LIST_HBS,
      isEnabledFeature: hbsEnabled,
    },
    {
      label: rpmTexts?.rpmEvents.tab,
      path: CspPath.PATIENT_LIST_RPM_EVENTS,
      isEnabledFeature: study.hasRpm,
      badgeContent: totalRpmEventsNotViewed,
    },
  ].filter(tab => tab.isEnabledFeature);

  return {
    goBack,
    study,
    patientListSubNavs,
    texts: {
      topBar: topBarBlock,
      tabs: tabsBlock,
      navTabs: navigationTabTexts,
    },
    hasMoreThanOneStudy,
  };
};
