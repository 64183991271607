/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DeviceSessionCriteriaFieldV1 {
    DEVICE_SESSION_ID = 'DEVICE_SESSION_ID',
    SESSION_CODE = 'SESSION_CODE',
    USER_ID = 'USER_ID',
    EVENT_ID = 'EVENT_ID',
    SCHEDULE_REQUEST_STATUS = 'SCHEDULE_REQUEST_STATUS',
    SCHEDULE_REQUEST_REF_SCHEDULE_CODE = 'SCHEDULE_REQUEST_REF_SCHEDULE_CODE',
    SCHEDULE_REQUEST_REF_VERSION_CODE = 'SCHEDULE_REQUEST_REF_VERSION_CODE',
    SCHEDULE_REQUEST_REF_REQUEST_CODE = 'SCHEDULE_REQUEST_REF_REQUEST_CODE',
    REFS_KEY = 'REFS_KEY',
    REFS_VALUE = 'REFS_VALUE',
    SESSION_STATUS = 'SESSION_STATUS',
    SESSION_TIMESTAMP = 'SESSION_TIMESTAMP',
    VERIFICATION_METHOD = 'VERIFICATION_METHOD',
    META_VALUE = 'META_VALUE',
    ASSESSMENT_CODE = 'ASSESSMENT_CODE',
}