import { IconType } from '@csp-misc/web-ui-icons';
import { Maybe } from '@csp/csp-common-model';
import { BordersProps } from '@mui/system';
import { Property } from 'csstype';
import { FC } from 'react';
import { BrandedIcon } from '../brandedIcon/BrandedIcon';
import { BrandedIconSize } from '../brandedIcon/model/BrandedIconSize';
import { Box } from '../layout/box/Box';
import { Body3 } from '../typography/TextStyles';
import { ImageProps } from './model/ImageProps';

type Props = Omit<ImageProps, 'src'> &
  BordersProps & {
    marginRight?: number;
    borderRadius?: number;
    missingIconName?: IconType;
    size?: BrandedIconSize;
    aspectRatio?: Maybe<Property.AspectRatio>;
  };

const evalWidth = (value: number | string | undefined): string => {
  const expression = (value: string): string => `calc(${value} - 10px)`;

  if (typeof value === 'number') {
    return expression(`${value}px`);
  } else {
    return expression(value || '100%');
  }
};

export const MissingImage: FC<Props> = ({
  width,
  height,
  alt,
  marginRight,
  borderRadius,
  missingIconName = 'brokenLink',
  aspectRatio,
  size = 'small',
}) => (
  <Box
    borderRadius={borderRadius}
    mr={marginRight}
    bgcolor="grey.200"
    display="flex"
    justifyContent="center"
    flexDirection="column"
    alignItems="center"
    width={width}
    height={height}
    sx={{
      aspectRatio,
    }}
  >
    <BrandedIcon name={missingIconName} size={size} pb={0.5} />

    {alt && (
      <Body3 lineBreak={2} maxHeight={height} width={evalWidth(width)} align="center">
        {alt || ''}
      </Body3>
    )}
  </Box>
);
