import { getTimeZoneId, toTimezoneStr } from '@csp/csp-common-date-util';
import { Maybe, MetricPeriod, ZonedDateTime, ZonedDateTimeStr } from '@csp/csp-common-model';
import { ScheduleRef, ScheduleRequestInfo } from '@csp/csp-common-scheduling';
import { isDefined } from '@csp/csp-common-util';
import { uniqWith } from 'lodash';
import { MetricPeriodUtil } from '../util/MetricPeriodUtil';
import { ScheduleRequestComplianceRef } from './ScheduleRequestComplianceRef';

export type ScheduleRequestMetricCriterion = {
  scheduleRef: ScheduleRef;
  minComplianceThreshold: Maybe<number>;
  recentPeriod: Maybe<MetricPeriod>;
  recentPeriodZonedDateTime: Maybe<ZonedDateTime>;
  recentPeriodZonedDateTimeStr: Maybe<ZonedDateTimeStr>;
  daysPerMonth: number;
};

const from = ({
  scheduleRef,
  daysPerMonth,
  recentPeriod,
  minComplianceThreshold,
}: {
  scheduleRef: ScheduleRef;
  daysPerMonth: number;
  minComplianceThreshold?: number;
  recentPeriod?: MetricPeriod;
}): ScheduleRequestMetricCriterion => {
  const recentPeriodZonedDateTime = recentPeriod
    ? {
        unixTimeMillis: Date.now() - MetricPeriodUtil.periodToMillis(recentPeriod, daysPerMonth),
        zone: getTimeZoneId(),
      }
    : undefined;

  return {
    scheduleRef,
    daysPerMonth,
    recentPeriod,
    minComplianceThreshold,
    recentPeriodZonedDateTime,
    recentPeriodZonedDateTimeStr: recentPeriodZonedDateTime ? toTimezoneStr(recentPeriodZonedDateTime) : undefined,
  };
};

const toMetricCriteria = (
  requestComplianceRefs: ScheduleRequestComplianceRef[],
  allScheduleRequestInfos: ScheduleRequestInfo[],
  daysPerMonth = 30,
): ScheduleRequestMetricCriterion[] => {
  // Multiple compliance refs should potentially be mapped to the same metric criterion depending on the request info.
  const metricCriteriaWithDuplicates = requestComplianceRefs
    .map(complianceRef => {
      const matchingRequestInfo = allScheduleRequestInfos.find(requestInfo =>
        ScheduleRef.matchesRequestRef(requestInfo.ref, complianceRef.requestRef),
      );
      return matchingRequestInfo
        ? ScheduleRequestMetricCriterion.from({
            scheduleRef: matchingRequestInfo.ref,
            minComplianceThreshold: complianceRef.minComplianceThreshold,
            daysPerMonth,
          })
        : undefined;
    })
    .filter(isDefined);

  return uniqWith(metricCriteriaWithDuplicates, (criterion1, criterion2) =>
    ScheduleRef.isEqual(criterion1.scheduleRef, criterion2.scheduleRef),
  );
};

export const ScheduleRequestMetricCriterion = {
  from,
  toMetricCriteria,
};
