import { Duration } from 'date-fns';

export type DateTimeDuration = {
  years?: number;
  months?: number;
  weeks?: number;
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
};

const toDateDuration = (duration: Duration): DateTimeDuration => ({
  years: duration.years ?? 0,
  months: duration.months ?? 0,
  weeks: duration.weeks ?? 0,
  days: duration.days ?? 0,
});

const toTimeDuration = (duration: Duration): DateTimeDuration => ({
  hours: duration.hours ?? 0,
  minutes: duration.minutes ?? 0,
  seconds: duration.seconds ?? 0,
});

export const DateTimeDuration = {
  toDateDuration,
  toTimeDuration,
};
