/* eslint-disable */
export const UnifyConsentDocumentMock = {
  title: 'title',
  consentType: '[M]EULA',
  version: '[M]1.0',
  context: [],
  countryCodes: [],
  header: 'header',
  subHeader: 'subHeader',
  bodyMd: 'bodyMd',
  declineAlertWarningText: '[M]You cannot continue without accepting the consent',
  readMoreButtonText: '[M]Read more',
  readMoreButtonTextAccessibilityLabel: '[M]Read more',
  readMoreButtonTextAccessibilityHint: '[M]Read more accessibility hint',
};
