import { EnumUtil } from '@csp/csp-common-enum-util';
import { Maybe, StateAssert } from '@csp/csp-common-model';
import {
  GROUP_CONFIG_V1_META_NAME,
  RequestGroupMemberships,
  RequestInitiationConfig,
} from '@csp/csp-common-scheduling';
import {
  PatientCopingTipConfigV1 as PatientCopingTipConfigDTOV1,
  PatientCopingTipModuleV1 as PatientCopingTipModuleDTOV1,
  QuestionnaireMetricPeriodUnitTypeV1,
  QuestionnaireRequestGroupConfigV1,
  QuestionnaireRequestMetaV1,
  QuestionnaireTypeV1,
  RequestInitiationConfigV1,
  RequestInitiatorTypeV1,
} from '@csp/dto';
import { QuestionnaireMetricPeriod } from '../../model/QuestionnaireMetricPeriod';
import { QuestionnaireMobileAppGraphicalPresentation } from '../../model/QuestionnaireMobileAppGraphicalPresentation';
import { QuestionnaireRequestConfigMeta } from '../../model/QuestionnaireRequestConfigMeta';
import { QuestionnaireMetricPeriodUnitType } from '../../type/QuestionnaireMetricPeriodUnitType';
import { QuestionnaireType } from '../../type/QuestionnaireType';
import { PatientCopingTipConfigV1, PatientCopingTipModuleV1 } from '../model/PatientCopingTipConfigV1';
import { QUESTIONNAIRE_CONFIG_V1_META_NAME, QuestionnaireConfigMetaV1 } from '../model/QuestionnaireConfigMetaV1';
import { QuestionnaireMetricPeriodV1 } from '../model/QuestionnaireMetricPeriodV1';
import { QuestionnaireMobileAppGraphicalPresentationV1 } from '../model/QuestionnaireMobileAppGraphicalPresentationV1';

const mapMetricPeriodV1 = (v1?: QuestionnaireMetricPeriodV1): Maybe<QuestionnaireMetricPeriod> => {
  const unit = v1
    ? EnumUtil.fromString<QuestionnaireMetricPeriodUnitType>(v1.unit, QuestionnaireMetricPeriodUnitType)
    : undefined;

  return v1 && unit ? { amount: v1.amount, unit } : undefined;
};

const mapMobileAppGraphicalPresentationV1 = (
  mobileAppGraphicalPresentationV1?: QuestionnaireMobileAppGraphicalPresentationV1,
): QuestionnaireMobileAppGraphicalPresentation => {
  const defaultValueShowProgressbar = true;
  return {
    showProgressbar: mobileAppGraphicalPresentationV1?.showProgressbar ?? defaultValueShowProgressbar,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const toConfigFromMetaV1 = (code: string, meta?: Record<string, any>): QuestionnaireRequestConfigMeta => {
  const configMetaV1 = meta ? (meta[QUESTIONNAIRE_CONFIG_V1_META_NAME] as QuestionnaireConfigMetaV1) : undefined;

  StateAssert.notNull(configMetaV1, `Missing mandatory configuration for code: ${code}`);
  StateAssert.hasText(configMetaV1.type, `Mandatory type field is missing, Questionnaire code: ${code}`);

  return {
    historicalMetricPeriod: mapMetricPeriodV1(configMetaV1.historicalMetricPeriod),
    patientReview: configMetaV1.patientReview === true,
    minComplianceThreshold: configMetaV1.minComplianceThreshold,
    requireHcpReview: configMetaV1.requireHcpReview !== false,
    requireSecondAuth: configMetaV1.requireSecondAuth !== false,
    selfReport: configMetaV1.selfReport === true,
    type: configMetaV1.type as QuestionnaireType,
    mobileAppGraphicalPresentation: mapMobileAppGraphicalPresentationV1(configMetaV1.mobileAppGraphicalPresentation),
  };
};

const toQuestionnaireTypeV1 = (type: QuestionnaireType): QuestionnaireTypeV1 => {
  switch (type) {
    case QuestionnaireType.ECOA:
      return QuestionnaireTypeV1.ECOA;
    case QuestionnaireType.DIARY:
      return QuestionnaireTypeV1.DIARY;
    case QuestionnaireType.HEALTH_EVENT:
      return QuestionnaireTypeV1.HEALTH_EVENT;
    case QuestionnaireType.SPFQ:
      return QuestionnaireTypeV1.SPFQ;
    case QuestionnaireType.UNKNOWN:
      return QuestionnaireTypeV1.UNKNOWN;
    default:
      return QuestionnaireTypeV1.UNKNOWN;
  }
};

const toQuestionnaireMeticPeriodUnitTypeV1 = (
  type: QuestionnaireMetricPeriodUnitType,
): QuestionnaireMetricPeriodUnitTypeV1 => {
  switch (type) {
    case QuestionnaireMetricPeriodUnitType.DAY:
      return QuestionnaireMetricPeriodUnitTypeV1.DAY;
    case QuestionnaireMetricPeriodUnitType.MONTH:
      return QuestionnaireMetricPeriodUnitTypeV1.MONTH;
    case QuestionnaireMetricPeriodUnitType.WEEK:
      return QuestionnaireMetricPeriodUnitTypeV1.WEEK;
    case QuestionnaireMetricPeriodUnitType.YEAR:
      return QuestionnaireMetricPeriodUnitTypeV1.YEAR;
  }
};

const toPatientCopingTipModuleV1 = (module: PatientCopingTipModuleV1): PatientCopingTipModuleDTOV1 => {
  switch (module) {
    case PatientCopingTipModuleV1.GI_TOX:
      return PatientCopingTipModuleDTOV1.GI_TOX;
    case PatientCopingTipModuleV1.SYMPTOM_TRACKER:
      return PatientCopingTipModuleDTOV1.SYMPTOM_TRACKER;
  }
};

const toRequestInitiationConfigV1 = ({
  initiator,
  ttlSeconds,
}: RequestInitiationConfig): Maybe<RequestInitiationConfigV1> => {
  const initiatorV1 = EnumUtil.fromString<RequestInitiatorTypeV1>(initiator, RequestInitiatorTypeV1);

  return initiatorV1
    ? {
        initiator: initiatorV1,
        ttlSeconds,
      }
    : undefined;
};

const toRequestGroupConfigV1 = (groups: RequestGroupMemberships): QuestionnaireRequestGroupConfigV1 => ({
  memberOf: groups.memberships.map(membership => ({
    groupKey: membership.groupKey,
    order: membership.order,
  })),
});

const toPatientCopingTipConfigV1 = (config: PatientCopingTipConfigV1): PatientCopingTipConfigDTOV1 => ({
  module: toPatientCopingTipModuleV1(config.module),
});

const toMetaV1FromConfig = (
  {
    patientReview,
    requireHcpReview,
    minComplianceThreshold,
    requireSecondAuth,
    selfReport,
    historicalMetricPeriod,
    mobileAppGraphicalPresentation,
    type,
  }: QuestionnaireRequestConfigMeta,
  initiationConfig?: RequestInitiationConfig,
  groups?: RequestGroupMemberships,
  patientCopingTipConfig?: PatientCopingTipConfigV1,
): QuestionnaireRequestMetaV1 => {
  const patientCopingTipConfigMeta = patientCopingTipConfig
    ? toPatientCopingTipConfigV1(patientCopingTipConfig)
    : undefined;
  const initiationRequestConfig = initiationConfig ? toRequestInitiationConfigV1(initiationConfig) : undefined;
  const groupConfig = groups ? toRequestGroupConfigV1(groups) : undefined;
  return {
    CONFIG_V1: {
      type: toQuestionnaireTypeV1(type),
      requireHcpReview,
      minComplianceThreshold,
      requireSecondAuth,
      selfReport,
      patientReview,
      mobileAppGraphicalPresentation,
      historicalMetricPeriod: historicalMetricPeriod
        ? {
            amount: historicalMetricPeriod.amount,
            unit: toQuestionnaireMeticPeriodUnitTypeV1(historicalMetricPeriod.unit),
          }
        : undefined,
    },
    INITIATION_CONFIG_V1: initiationRequestConfig,
    [GROUP_CONFIG_V1_META_NAME]: groupConfig,
    PATIENT_COPING_TIP_CONFIG_V1: patientCopingTipConfigMeta,
  };
};

/**
 * Responsible for mapping meta section in Schedule => version window => version => request => meta
 */
export const QuestionnaireRequestMetaMapper = {
  toConfigFromMetaV1,
  toMetaV1FromConfig,
  toPatientCopingTipConfigV1,
};
