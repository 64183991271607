import { EnumUtil } from '@csp/csp-common-enum-util';
import { CspError, Maybe, ObjectType, StateAssert } from '@csp/csp-common-model';
import { RequestInitiationConfigV1 } from '../meta/RequestInitiationConfigV1';
import { INITIATION_CONFIG_V1_META_NAME, RequestInitiationMetaV1 } from '../meta/RequestInitiationMetaV1';
import { RequestInitiatorTypeV1 } from '../meta/RequestInitiatorTypeV1';
import { RequestInitiationConfig } from '../model/RequestInitiationConfig';
import { RequestInitiatorType } from '../model/RequestInitiatorType';

type AssertRequestInitiationConfigV1 = (config: unknown) => asserts config is RequestInitiationConfigV1;

const assertRequestInitiationConfigV1: AssertRequestInitiationConfigV1 = config => {
  const castedConfig = config as RequestInitiationConfigV1;

  if (typeof castedConfig === 'object') {
    EnumUtil.fromStringOrError(castedConfig.initiator, RequestInitiatorTypeV1);
    if (castedConfig.ttlSeconds) {
      StateAssert.isFiniteNumber(castedConfig.ttlSeconds, 'request initiation config ttlSeconds must be a number');
    }
  } else {
    throw CspError.badState(`invalid RequestInitiationConfigV1: ${castedConfig}`);
  }
};

/**
 * Validates correct {@link RequestInitiationMetaV1} type and maps to initiation config;
 * Takes ObjectType as a convenience.
 */
const fromRequestInitiationConfigMetaV1 = (
  meta: RequestInitiationMetaV1 | Maybe<ObjectType>,
  strict = false,
): Maybe<RequestInitiationConfig> => {
  const configV1 = meta?.[INITIATION_CONFIG_V1_META_NAME];
  if (configV1) {
    try {
      assertRequestInitiationConfigV1(configV1);
      return {
        initiator: EnumUtil.fromStringOrError(configV1.initiator, RequestInitiatorType),
        ttlSeconds: configV1.ttlSeconds,
      };
    } catch (err) {
      if (strict) {
        throw err;
      } else {
        return undefined;
      }
    }
  } else {
    return { initiator: RequestInitiatorType.SCHEDULE };
  }
};

export const RequestInitiationConfigMapper = { fromRequestInitiationConfigMetaV1 };
