import {
  ApiOptions,
  CallbackWith,
  CspError,
  DmdpError,
  ErrorHandlerCallback,
  ErrorMessage,
} from '@csp/csp-common-model';
import { noop } from 'lodash';
import { useCallback, useState } from 'react';
import { toCspError } from '@csp/csp-common-util';
import { useAbortController } from './useAbortController';

export type VarArgs = readonly unknown[];
export type DoFetch<P extends VarArgs> = (...params: [...P]) => Promise<void>;
export type FetchCallback<T, P extends VarArgs> = (...params: [...P, ApiOptions]) => Promise<T>;
export type ResetState = () => void;
export type Transformer<R, S> = (arg: R) => S;

export interface FetchState<T> {
  error?: ErrorMessage;
  isLoading: boolean;
  data?: T;
}

export type UseFetchReturnType<T, P extends VarArgs> = [DoFetch<P>, FetchState<T>, ResetState];

export const isLoading = { isLoading: true } as const;
export const isNotLoading = { isLoading: false } as const;
export const identity = <S>(i: S): S => i;

const pickMessage: CallbackWith<Error, string> = error => error.message;

interface UseFetch {
  <T, P extends VarArgs>(fetchCallback: FetchCallback<T, P>, initialState?: FetchState<T>): UseFetchReturnType<T, P>;
  <T, S, P extends VarArgs>(
    fetchCallback: FetchCallback<S, P>,
    initialState: FetchState<T>,
    transformer: Transformer<S, T>,
    getErrorMessage?: CallbackWith<Error, string>,
    errorHandler?: ErrorHandlerCallback,
  ): UseFetchReturnType<T, P>;
}

export const useFetch: UseFetch = <T, S, P extends VarArgs>(
  fetchCallback: FetchCallback<S, P>,
  initialState: FetchState<T | S> = isLoading,
  transformer: Transformer<S, T | S> = identity,
  getErrorMessage: CallbackWith<Error, string> = pickMessage,
  errorHandler: ErrorHandlerCallback = noop,
): UseFetchReturnType<T | S, P> => {
  const [fetchState, setFetchState] = useState(initialState);
  const { signal } = useAbortController();

  const doFetch = useCallback(
    async (...params: [...P]) => {
      try {
        setFetchState(currentState => ({ ...currentState, isLoading: true }));
        const data = await fetchCallback(...params, { signal });
        setFetchState({ data: transformer(data), isLoading: false });
      } catch (error) {
        if (!CspError.isCancelled(error) && !CspError.isHttpCommunication(error)) {
          const fetchStateError: ErrorMessage = {
            message: getErrorMessage(toCspError(error)),
          };
          if (DmdpError.isDmdpError(error)) {
            fetchStateError.details = error.errorDetails;
          }
          setFetchState(currentState => ({
            ...currentState,
            error: fetchStateError,
            isLoading: false,
          }));
          errorHandler({ error });
        }
      }
    },
    [fetchCallback, getErrorMessage, errorHandler, transformer, signal],
  );

  const resetState = useCallback(() => setFetchState(initialState), [setFetchState, initialState]);

  return [doFetch, fetchState, resetState];
};
