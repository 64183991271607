import {
  BioSampleRef,
  BioSampleCodeFormatType,
  BioSampleCodeInputError,
  BioSampleCodeInputValidator,
  BioSampleCodeValidationErrorMessages,
} from '@csp/csp-common-hbs-model';
import { CspError, Maybe, StateAssert } from '@csp/csp-common-model';
import { StringUtil, isDefined } from '@csp/csp-common-util';
import { InputValidators } from '@csp/csp-common-form';
import { SampleContainerNumber } from '@csp/dmdp-api-hbs-dto';

const LABCORP_SAMPLE_CODE_FORMAT_UI_INPUT_REGEX = '^[0-9]{12}$';
const LABCORP_SAMPLE_KIT_CODE_FORMAT_UI_INPUT_REGEX = '^[0-9]{10}$';
const LABCORP_SAMPLE_CONTAINER_NUMBER_FORMAT_UI_INPUT_REGEX = '^[0-9]{1,3}$';
const LABCORP_SAMPLE_CONTAINER_NUMBER_IN_MIN_LENGTH = 2;

const createUnsupportedFormatErrorMessage = (format: BioSampleCodeFormatType): string =>
  `Encountered an unsupported bio sample format type: ${format}`;

const validateLabcorpBioSampleCode: BioSampleCodeInputValidator = (
  sampleCodeIn: Maybe<string>,
  errorMessages: Maybe<BioSampleCodeValidationErrorMessages>,
): Maybe<BioSampleCodeInputError> => {
  const trimmedSampleIn = sampleCodeIn?.trim();
  const inputError =
    InputValidators.required(trimmedSampleIn, errorMessages?.LABCORP) ??
    InputValidators.pattern(LABCORP_SAMPLE_CODE_FORMAT_UI_INPUT_REGEX, errorMessages?.LABCORP)(trimmedSampleIn);

  return inputError ? { format: BioSampleCodeFormatType.LABCORP, message: inputError.message } : undefined;
};

const validateLabcorpBioSampleKitCode: BioSampleCodeInputValidator = (
  sampleKitCodeIn: Maybe<string>,
  errorMessages: Maybe<BioSampleCodeValidationErrorMessages>,
): Maybe<BioSampleCodeInputError> => {
  const trimmedSampleKitCodeIn = sampleKitCodeIn?.trim();
  const inputError =
    InputValidators.required(trimmedSampleKitCodeIn, errorMessages?.LABCORP) ??
    InputValidators.pattern(
      LABCORP_SAMPLE_KIT_CODE_FORMAT_UI_INPUT_REGEX,
      errorMessages?.LABCORP,
    )(trimmedSampleKitCodeIn);

  return inputError ? { format: BioSampleCodeFormatType.LABCORP, message: inputError.message } : undefined;
};

const validateLabcorpBioSampleContainerNumber: BioSampleCodeInputValidator = (
  sampleContainerNumberIn: Maybe<string>,
  errorMessages: Maybe<BioSampleCodeValidationErrorMessages>,
): Maybe<BioSampleCodeInputError> => {
  const trimmedSampleContainerNumberIn = sampleContainerNumberIn?.trim();
  const inputError =
    InputValidators.required(trimmedSampleContainerNumberIn, errorMessages?.LABCORP) ??
    InputValidators.pattern(
      LABCORP_SAMPLE_CONTAINER_NUMBER_FORMAT_UI_INPUT_REGEX,
      errorMessages?.LABCORP,
    )(trimmedSampleContainerNumberIn);

  return inputError ? { format: BioSampleCodeFormatType.LABCORP, message: inputError.message } : undefined;
};

const parseBioSampleCodeByFormat = (
  sampleCodeIn: Maybe<string>,
  format: BioSampleCodeFormatType,
): Maybe<BioSampleRef> => {
  const trimmedSampleIn = sampleCodeIn?.trim() ?? '';
  const numCharsInKitId = 10;

  if (format === BioSampleCodeFormatType.LABCORP) {
    return !validateLabcorpBioSampleCode(trimmedSampleIn, undefined)
      ? {
          format: BioSampleCodeFormatType.LABCORP,
          sampleCode: trimmedSampleIn,
          kitCode: trimmedSampleIn.slice(0, numCharsInKitId),
          containerNumber: trimmedSampleIn.slice(numCharsInKitId),
        }
      : undefined;
  } else {
    return undefined;
  }
};

const formatBioSampleContainerNumberInByFormat = (
  containerNumberIn: SampleContainerNumber,
  format: BioSampleCodeFormatType,
): SampleContainerNumber => {
  if (format === BioSampleCodeFormatType.LABCORP) {
    return StringUtil.padWithOrRemoveZeros(containerNumberIn, LABCORP_SAMPLE_CONTAINER_NUMBER_IN_MIN_LENGTH);
  } else {
    return containerNumberIn;
  }
};

const createBioSampleCodeValidator =
  (format: BioSampleCodeFormatType): BioSampleCodeInputValidator =>
  (
    sampleCodeIn: Maybe<string>,
    errorMessages: Maybe<BioSampleCodeValidationErrorMessages>,
  ): Maybe<BioSampleCodeInputError> => {
    if (format === BioSampleCodeFormatType.LABCORP) {
      return validateLabcorpBioSampleCode(sampleCodeIn, errorMessages);
    } else {
      throw CspError.badState(createUnsupportedFormatErrorMessage(format));
    }
  };

const createBioSampleKitCodeValidator =
  (format: BioSampleCodeFormatType): BioSampleCodeInputValidator =>
  (
    sampleKitCodeIn: Maybe<string>,
    errorMessages: Maybe<BioSampleCodeValidationErrorMessages>,
  ): Maybe<BioSampleCodeInputError> => {
    if (format === BioSampleCodeFormatType.LABCORP) {
      return validateLabcorpBioSampleKitCode(sampleKitCodeIn, errorMessages);
    } else {
      throw CspError.badState(createUnsupportedFormatErrorMessage(format));
    }
  };

const createBioSampleContainerNumberValidator =
  (format: BioSampleCodeFormatType): BioSampleCodeInputValidator =>
  (
    sampleContainerNumberIn: Maybe<string>,
    errorMessages: Maybe<BioSampleCodeValidationErrorMessages>,
  ): Maybe<BioSampleCodeInputError> => {
    if (format === BioSampleCodeFormatType.LABCORP) {
      return validateLabcorpBioSampleContainerNumber(sampleContainerNumberIn, errorMessages);
    } else {
      throw CspError.badState(createUnsupportedFormatErrorMessage(format));
    }
  };

const parseBioSampleCode = (sampleCodeIn: Maybe<string>, formats: BioSampleCodeFormatType[]): Maybe<BioSampleRef> =>
  formats.map(format => parseBioSampleCodeByFormat(sampleCodeIn, format)).filter(isDefined)[0];

const parseBioSampleCodeOrError = (sampleCodeIn: Maybe<string>, formats: BioSampleCodeFormatType[]): BioSampleRef => {
  const parsedFormat = parseBioSampleCode(sampleCodeIn, formats);
  StateAssert.notNull(
    parsedFormat,
    `Could not parse invalid bio sample ID '${sampleCodeIn}' with any of the following format(s) ${formats.join(', ')}`,
  );
  return parsedFormat;
};

const formatBioSampleContainerNumberIn = (
  containerNumberIn: SampleContainerNumber,
  formats: BioSampleCodeFormatType[],
): SampleContainerNumber =>
  formats.map(format => formatBioSampleContainerNumberInByFormat(containerNumberIn.trim(), format))[0] ??
  containerNumberIn.trim();

export const BioSampleCodeFormatterUtil = {
  createBioSampleCodeValidator,
  createBioSampleKitCodeValidator,
  createBioSampleContainerNumberValidator,
  parseBioSampleCode,
  parseBioSampleCodeOrError,
  formatBioSampleContainerNumberIn,
};
