import { RawPatientCopingTipConfigV1, RawPatientCopingTipConfigV1Schema } from '@csp/config-schemas';
import { JsonValidationService } from '@csp/csp-common-util';

const validatePatientCopingTipConfigV1 = (config: RawPatientCopingTipConfigV1): void => {
  JsonValidationService.validateJsonNonStrict('PatientCopingTip config V1', RawPatientCopingTipConfigV1Schema, config);
};

export const PatientCopingTipValidationService = {
  validatePatientCopingTipConfigV1,
};
