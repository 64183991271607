import { PublicPackageResourceContent } from '@csp/csp-web-content-definitions';
import { Maybe } from '@csp/csp-common-model';

/*
// common
type GetResourceContentEntryFn<T> = {
  <EntryKey extends keyof T>(content: T, entryIdentifier: EntryKey): Maybe<T[EntryKey]>;
};
type GetResourceContentBlockFn<T> = {
  <EntryKey extends keyof T, BlockKey extends keyof T[EntryKey]>(
    content: T,
    entryIdentifier: EntryKey,
    blockIdentifier: BlockKey,
  ): Maybe<T[EntryKey][BlockKey]>;
};

// common
export const getResourceContentEntry: GetResourceContentEntryFn<ResourceContent['resource']> = (
  content,
  entryIdentifier,
) => content?.[entryIdentifier];
export const getResourceContentBlock: GetResourceContentBlockFn<ResourceContent['resource']> = (
  content,
  entryIdentifier,
  blockIdentifier,
) => getResourceContentEntry(content, entryIdentifier)?.[blockIdentifier];

// web
export const getWebPublicResourceContentBlockGenerics =
  getResourceContentBlock as unknown as GetResourceContentBlockFn<PublicPackageResourceContent>;
export const getWebPublicResourceContentEntryGenerics =
  getResourceContentEntry as unknown as GetResourceContentEntryFn<PublicPackageResourceContent>;
*/

// TODO[anders och mattias]: kolla web content store

export const getWebPublicResourceContentEntry = <T extends PublicPackageResourceContent, EntryKey extends keyof T>(
  content: T,
  entryIdentifier: EntryKey,
): Maybe<T[EntryKey]> => content?.[entryIdentifier];

export const getWebPublicResourceContentBlock = <
  T extends PublicPackageResourceContent,
  EntryKey extends keyof T,
  BlockKey extends keyof T[EntryKey],
>(
  content: T,
  entryIdentifier: EntryKey,
  blockIdentifier: BlockKey,
): Maybe<T[EntryKey][BlockKey]> => getWebPublicResourceContentEntry(content, entryIdentifier)?.[blockIdentifier];
