import { Maybe } from '@csp/csp-common-model';
import { CacheKey } from '../model/CacheKey';
import { CacheValue } from '../model/CacheValue';
import { GetCacheValueOptions } from '../model/GetCacheValueOptions';
import { SetCacheValueOptions } from '../model/SetCacheValueOptions';

const MIN_TIME_BETWEEN_CLEAR_SECS = 60;

type Key = string[] | CacheKey | string;

type CachedValue<T> = {
  options: Maybe<SetCacheValueOptions>;
  expireUtcMillis: number;
  value: T;
};

const cache: {
  lastClear: Date;
  minTimeBetweenClearSecs: number;
  content: Map<CacheKey, CachedValue<unknown>>;
} = {
  lastClear: new Date(),
  minTimeBetweenClearSecs: MIN_TIME_BETWEEN_CLEAR_SECS,
  content: new Map(),
};

const isStale = <T>(cachedValue: CachedValue<T>): boolean => cachedValue.expireUtcMillis < Date.now();

const isValid = <T>(cachedValue?: CachedValue<T>): boolean =>
  !!(cachedValue && (!isStale(cachedValue) || !!cachedValue.options?.keepStale));

const clearExpired = (): void => {
  const now = new Date();
  if (cache.lastClear.getTime() + cache.minTimeBetweenClearSecs * 1000 < now.getTime()) {
    const expiredKeys: CacheKey[] = [];
    cache.content.forEach((value, key) => {
      if (!isValid(value)) {
        expiredKeys.push(key);
      }
    });

    expiredKeys.forEach(key => cache.content.delete(key));

    cache.lastClear = new Date();
  }
};

const getCacheValue = <T>(key: Key, options?: GetCacheValueOptions): Maybe<CacheValue<T>> => {
  clearExpired();

  const cachedValue: Maybe<CachedValue<T>> = cache.content.get(CacheKey.from(key)) as CachedValue<T>;

  if (cachedValue) {
    const isValueStale = isStale(cachedValue);
    if (isValueStale && !options?.includeStale) {
      return undefined;
    } else {
      return {
        value: cachedValue.value,
        isStale: isValueStale,
      };
    }
  } else {
    return undefined;
  }
};

const getValue = <T>(key: Key): Maybe<T> => getCacheValue<T>(key)?.value;

const setValue = <T>(key: Key, ttlSecs: number, value: T, options?: SetCacheValueOptions): void => {
  clearExpired();
  cache.content.set(CacheKey.from(key), {
    options,
    expireUtcMillis: Date.now() + ttlSecs * 1000,
    value,
  });
};

const setMinTimeBetweenClear = (secs: number): void => {
  cache.minTimeBetweenClearSecs = secs;
};

const size = (): number => cache.content.size;

const reset = (): void => cache.content.clear();

export const MemCacheService = {
  setValue,
  getValue,
  getCacheValue,
  reset,
  size, // For test
  setMinTimeBetweenClear, // For test
};
