import { memoizedToDebugContent } from '@csp/common';
import { ContentDeliveryService } from '@csp/csp-common-content-delivery-client';
import { CspError } from '@csp/csp-common-model';
import {
  LandingPageContentModel,
  PublicPackageResourceContent,
  PublicWebContentPackage,
} from '@csp/csp-web-content-definitions';
import { isDebugCmsModeActive } from '../util/debugCmsUtil';

let publicPackage: PublicWebContentPackage | undefined = undefined;

// TODO: [Erik]: Move this to @csp/csp-web-content
const init = async (): Promise<void> => {
  const isDebugEnabled = isDebugCmsModeActive();
  const unifyPublicPackage = await ContentDeliveryService.fetchContentPackage('public');
  publicPackage = unifyPublicPackage as unknown as PublicWebContentPackage;
  if (isDebugEnabled) {
    publicPackage = memoizedToDebugContent<PublicWebContentPackage>(publicPackage, '', '[CS]');
  }
};

const get = (): PublicWebContentPackage => {
  if (!publicPackage) {
    throw CspError.badState('Must call PublicContentService.init() before trying to access public content');
  }
  return publicPackage;
};

const getResource = (): PublicPackageResourceContent => get().resource;

const getLandingPage = (): LandingPageContentModel => {
  const landingPage = get().landing_page;
  if (landingPage.length !== 1 || !landingPage[0]) {
    throw CspError.badState(
      `Expected to get exactly one instance of the landingPage, instead got: ${landingPage.length}`,
    );
  }
  return landingPage[0];
};

export const PublicContentService = {
  init,
  get,
  getResource,
  getLandingPage,
};
