import { GenericVersion, ScheduleCode, ScheduleVersionInfo } from '@csp/csp-common-scheduling';
import { AppointmentActiveVersionWindowV1 } from '@csp/dmdp-api-appointment-dto';
import { VisitLocationType } from '@csp/csp-common-model';
import { zeroZonedDateTime } from '@csp/csp-common-date-util';
import { DefaultScheduleCodes } from '../type/DefaultScheduleCodes';
import { VisitRequest } from './VisitRequest';

export type VisitVersion = GenericVersion<VisitRequest>;

const fromV1 = (
  scheduleCode: ScheduleCode,
  { version, activation, activationRef, activeFrom, activeTo }: AppointmentActiveVersionWindowV1,
): VisitVersion => ({
  scheduleCode,
  ...ScheduleVersionInfo.fromApi(version.versionCode, activeFrom, activeTo),
  activation,
  activationRef,
  requests: version.requests.map(request => VisitRequest.from(scheduleCode, version.versionCode, request)),
});

const toDefaultVisitVersion = (supportedVisitLocationTypes: VisitLocationType[]): VisitVersion => ({
  scheduleCode: DefaultScheduleCodes.DEFAULT_SCHEDULE_CODE,
  versionCode: DefaultScheduleCodes.DEFAULT_VERSION_CODE,
  activation: undefined,
  activeFrom: zeroZonedDateTime,
  activeTo: undefined,
  activationRef: undefined,
  requests: [VisitRequest.toDefaultUnscheduledRequest(supportedVisitLocationTypes)],
});

export const VisitVersion = {
  fromV1,
  toDefaultVisitVersion,
};
