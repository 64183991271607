import { MedicationRequestCode, PatientMedicationRequestInfoMeta, PatientMedicationsMeta } from '@csp/csp-common-model';
import {
  createPatientMedicationMetaV1,
  PatientMedicationRequestInfoV1,
  PatientMedicationsMetaV1,
  PatientMedicationsV1,
} from '@csp/dmdp-api-user-dto';
import { fromTimezoneStr, ZonedDateTimeFormatter } from '@csp/csp-common-date-util';

const emptyPatientMedicationsV1 = (): PatientMedicationsV1 => ({
  requests: {},
});

const emptyPatientMedicationsMeta = (): PatientMedicationsMeta => ({
  requests: {},
  isStartedByRequestCode: (_): boolean => false,
});

const fromPatientMedicationsV1 = (medicationsV1: PatientMedicationsV1): PatientMedicationsMeta => {
  const requests: Record<MedicationRequestCode, PatientMedicationRequestInfoMeta> = {};

  Object.entries(medicationsV1.requests).forEach(([requestCode, requestInfoV1]) => {
    requests[requestCode] = {
      startTimestamp: fromTimezoneStr(requestInfoV1.startedTimestamp),
    };
  });

  const isStartedByRequestCode = (requestCode: MedicationRequestCode): boolean =>
    !!requests[requestCode]?.startTimestamp;

  return {
    requests,
    isStartedByRequestCode,
  };
};

const toPatientMedicationsMetaV1 = (
  medicationsMeta: PatientMedicationsMeta = emptyPatientMedicationsMeta(),
): PatientMedicationsMetaV1 => {
  const requestsInfoV1: Record<string, PatientMedicationRequestInfoV1> = {};

  Object.entries(medicationsMeta.requests).forEach(([requestCode, requestInfoMeta]) => {
    requestsInfoV1[requestCode] = {
      startedTimestamp: ZonedDateTimeFormatter.toZonedDateTimeString(requestInfoMeta.startTimestamp),
      startedUtc: requestInfoMeta.startTimestamp.unixTimeMillis,
    };
  });

  return createPatientMedicationMetaV1({
    requests: requestsInfoV1,
  });
};

export const PatientMedicationMetaFactory = {
  fromPatientMedicationsV1,
  toPatientMedicationsMetaV1,
  emptyPatientMedicationsV1,
};
