import { PatientAppInfoMetaV1 } from '../../dto/UserMetaV1';
import { PatientAppInfoV1 } from './PatientAppInfoV1';

export const PATIENT_APP_INFO_V1_META_TYPE = 'PATIENT_APP_INFO_V1';

export const createPatientAppInfoMetaV1 = (patientAppInfoV1: PatientAppInfoV1): PatientAppInfoMetaV1 => ({
  type: PATIENT_APP_INFO_V1_META_TYPE,
  name: PATIENT_APP_INFO_V1_META_TYPE,
  data: patientAppInfoV1,
});
