import Link from '@mui/material/Link/Link';
import { CSSProperties, FC } from 'react';
import { Link as ReactRouterLink, LinkProps } from 'react-router-dom';

type Props = {
  to: string;
  disabled?: boolean;
} & LinkProps;

export const RouterLink: FC<Props> = ({ children, onClick, style, to, disabled }) => (
  <Link
    component={ReactRouterLink}
    onClick={onClick}
    style={{ textDecoration: 'none', cursor: disabled ? 'auto' : 'pointer', ...style } as CSSProperties}
    to={to}
  >
    {children}
  </Link>
);
