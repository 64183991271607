export enum IdpType {
  COGNITO = 'userpod.idp.cognito',
  PING_ID = 'userpod.idp.cognito.pingid',
  EXOSTAR = 'userpod.idp.cognito.pingid.exostar',
}

export const IDENTITY_PROVIDERS: { [key in IdpType]: string } = {
  [IdpType.COGNITO]: 'Unify',
  [IdpType.PING_ID]: 'AstraZeneca',
  [IdpType.EXOSTAR]: 'Exostar',
};
