import { MetricsConfigV1Schema, StudyMetricsConfigV1 } from '@csp/config-schemas';
import { JsonValidationService } from '@csp/csp-common-util';

const validateMetricsConfig = (studyMetricsConfig: StudyMetricsConfigV1): void => {
  JsonValidationService.validateJsonNonStrict('Metric config', MetricsConfigV1Schema, studyMetricsConfig);
};

export const StudyMetricsConfigService = {
  validateMetricsConfig,
};
