import { StudyConfig } from '@csp/csp-common-config';
import { Maybe } from '@csp/csp-common-model';
import { CaseReducer, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { StudyConfigState } from '../model/StudyConfigState';
import { loadStudyConfigThunk, resetStudyConfigAction } from './studyConfigActions';

type RejectedAction = ReturnType<typeof loadStudyConfigThunk.rejected>;

const initialState: StudyConfigState = {};

const loadStudyConfigFulfilled: CaseReducer<StudyConfigState, PayloadAction<Maybe<StudyConfig>>> = (
  draft,
  { payload },
) => {
  draft.config = payload;
  draft.isLoading = false;
  draft.error = undefined;
};

const loadStudyConfigPending: CaseReducer<StudyConfigState> = draft => {
  draft.config = undefined;
  draft.isLoading = true;
  draft.error = undefined;
};

const loadStudyConfigRejected: CaseReducer<StudyConfigState, RejectedAction> = (draft, { payload }) => {
  draft.config = undefined;
  draft.isLoading = false;
  draft.error = payload as Error;
};

const clearStudyConfigReducer: CaseReducer<StudyConfigState> = state => {
  state.config = undefined;
  state.isLoading = false;
  state.error = undefined;
};

export const studyConfigReducer = createReducer(initialState, builder =>
  builder
    .addCase(loadStudyConfigThunk.fulfilled, loadStudyConfigFulfilled)
    .addCase(loadStudyConfigThunk.rejected, loadStudyConfigRejected)
    .addCase(loadStudyConfigThunk.pending, loadStudyConfigPending)
    .addCase(resetStudyConfigAction, clearStudyConfigReducer),
);
