/* eslint-disable */
export const UnifyWebStudyOverviewSchema = {
  title: 'UnifyWebStudyOverview',
  type: 'object',
  properties: {
    title: { type: 'string' },
    studyInformationCard: {
      type: 'object',
      title: 'Study information card',
      properties: {
        title: {
          type: 'string',
          title: 'Title',
          helper: 'The title of the card, shown in the study overview',
          mock: 'About this study',
        },
        body: {
          type: 'string',
          helper: 'Text to show below the title, should be a description of the study',
          mock: 'A registry-based, randomised, double-blind, placebo-controlled Cardiovascular Outcomes trial to evaluate the effect of Dapagliflozin on the incidence of heart failure or cardiovascular death in patients without diabetes with acute myocardial infarction at increased risk for subsequent development of hear failure.',
        },
        newStudy: {
          title: 'Chip text shown when a study site is new, and not that it is a newly added study',
          type: 'string',
          mock: 'New study',
        },
        studyStatus: {
          title: 'Study Overview Status',
          type: 'object',
          properties: {
            ACTNOREC: { title: 'ACTNOREC, Active (not recruiting)', type: 'string', mock: 'Active (not recruiting)' },
            ACTREC: { title: 'ACTREC, Active (Recruiting)', type: 'string', mock: 'Active (Recruiting)' },
            ARCH: { title: 'ARCH, Archived', type: 'string', mock: 'Archived' },
            CANCELLED: { title: 'CANCELLED, Cancelled', type: 'string', mock: 'Cancelled' },
            CAND: { title: 'CAND, Candidate', type: 'string', mock: 'Candidate' },
            CLOSED: { title: 'CLOSED, Closed', type: 'string', mock: 'Closed' },
            CLOSING: { title: 'CLOSING, Closing', type: 'string', mock: 'Closing' },
            CLOSINGTE: {
              title: 'CLOSINGTE, Closing (Treatment Extension)',
              type: 'string',
              mock: 'Closing (Treatment Extension)',
            },
            DELETED: { title: 'DELETED, Deleted', type: 'string', mock: 'Deleted' },
            INMIGR: { title: 'INMIGR, In Migration', type: 'string', mock: 'In Migration' },
            ONHOLD: { title: 'ONHOLD, On Hold', type: 'string', mock: 'On Hold' },
            OPENFORREC: { title: 'OPENFORREC, Open for Recruitment', type: 'string', mock: 'Open for Recruitment' },
            INIT: { title: 'INIT, Initiating', type: 'string', mock: 'Initiating' },
            POST: { title: 'POST, Postponed', type: 'string', mock: 'Postponed' },
            TERM: { title: 'TERM, Terminated', type: 'string', mock: 'Terminated' },
            UNKNOWN: { title: 'UNKN, Unknown', type: 'string', mock: 'Unknown' },
          },
        },
      },
    },
    documentsCard: {
      type: 'object',
      title: 'Documents card',
      properties: {
        title: {
          type: 'string',
          title: 'Title',
          helper: 'The title of the card, shown in the study overview',
          mock: 'Documents',
        },
        viewAllDocumentsLabel: {
          type: 'string',
          title: 'View all documents label',
          helper: 'Label for button in card header, that when clicked navigates to Study Documents page',
          mock: 'View all documents',
        },
        linkText: {
          type: 'string',
          title: 'Link text',
          helper: 'Text to be used for the link, which the user can click to open the document',
          mock: 'View',
        },
        versionPublishedLabel: {
          type: 'string',
          title: 'Version published label',
          helper: 'The label to show before the published date',
          mock: 'Published on',
        },
        versionLabel: {
          type: 'string',
          title: 'Version label',
          helper: 'The label to show before the version',
          mock: 'Version',
        },
      },
    },
    studyDocuments: {
      type: 'object',
      title: 'Study Documents page',
      properties: {
        title: {
          type: 'string',
          title: 'Title',
          helper: 'The title to be shown in the navigation header',
          mock: 'Documents',
        },
        subtitle: {
          type: 'string',
          title: 'Subtitle',
          helper: 'The subtitle to show above the title in the navigation header',
          mock: 'Study overview',
        },
        filters: {
          type: 'object',
          title: 'Filters',
          properties: {
            roles: { type: 'string', title: 'Roles', helper: 'The label for the role dropdown', mock: 'Roles' },
            countries: {
              type: 'string',
              title: 'Countries',
              helper: 'The label for the countries dropdown',
              mock: 'Countries',
            },
            startDate: {
              type: 'string',
              title: 'Start date label',
              helper: 'The label for the start date select',
              mock: 'Start date',
            },
            endDate: {
              type: 'string',
              title: 'Clear All label',
              helper: 'The label for the end date select',
              mock: 'End date',
            },
            all: {
              type: 'string',
              title: 'All label',
              helper: 'The label to show in the dropdown for selecting all options',
              mock: 'All',
            },
            clearAll: {
              type: 'string',
              title: 'Clear All label',
              helper: 'The label for the button to clear all filters',
              mock: 'Clear All',
            },
            noFilteredData: {
              type: 'string',
              title: 'No filtered data',
              helper: 'The text to show if no documents match the filters',
              mock: 'No filtered data',
            },
          },
        },
        defaultDocumentTypeHeader: {
          type: 'string',
          title: 'Default document type header',
          helper: 'Will be show as a title for all documents who are missing document types',
          mock: 'Other documents',
        },
        viewMoreLabel: { type: 'string', title: 'View more label', mock: 'View more' },
        viewLessLabel: { type: 'string', title: 'View less label', mock: 'View less' },
        roleTagsLabel: {
          type: 'object',
          title: 'role tags label',
          properties: {
            HCP: {
              type: 'string',
              title: 'HCP role tag',
              helper: 'when viewed by support role, role tag to show when the document can be accessed by HCP.',
              mock: 'HCP',
            },
            CRA: {
              type: 'string',
              title: 'CRA role tag',
              helper: 'when viewed by support role, role tag to show when the document can be accessed by CRA.',
              mock: 'CRA',
            },
          },
        },
        noDocumentsLabel: {
          type: 'string',
          title: 'No documents label',
          helper: 'No document label to be shown when there are no documents available.',
          mock: 'No Documents',
        },
      },
    },
    closeOutTracker: {
      type: 'object',
      title: 'Study Close Out Tracker Card',
      properties: {
        initiateCard: {
          type: 'object',
          title: 'Initiate Card',
          properties: {
            title: {
              type: 'string',
              title: 'Title',
              helper: 'The title of the Study Close Out Tracker card before the study close out period is started',
              mock: 'Start the Close out period',
            },
            infoTextMd: {
              type: 'string',
              title: 'Info text',
              helper:
                'Info text displayed on the the Study Close Out Tracker card before the study close out period is started',
              mock: 'Click the button and set a start and end date for the Close out visit time period.',
            },
            button: {
              type: 'string',
              title: 'CTA button',
              helper: 'Label of CTA button',
              mock: 'Set Close out visit time period',
            },
          },
        },
        drawer: {
          type: 'object',
          title: 'Enable Close out time period drawer',
          properties: {
            title: {
              type: 'string',
              title: 'Title',
              helper: 'The title of the Enable Close out time period drawer',
              mock: 'Enable the Close Out Tracker ',
            },
            cancel: {
              type: 'string',
              title: 'Cancel button',
              helper: 'Enable Close out time period drawer cancel button',
              mock: 'Cancel',
            },
            confirm: {
              type: 'string',
              title: 'Confirm button',
              helper: 'Enable Close out time period drawer confirm button',
              mock: 'Confirm',
            },
            notification: {
              type: 'string',
              title: 'Confirm notification',
              helper: 'Notification shown after close out time period has been selected',
              mock: 'Close Out Tracker is now live',
            },
            text: {
              type: 'object',
              title: 'Enable Close out time period drawer texts',
              properties: {
                requiredFields: {
                  type: 'string',
                  title: 'Is required',
                  mock: 'Required fields',
                  helper: "'*' is added in code",
                },
                subtitleSetDate: {
                  type: 'string',
                  title: 'Drawer set close out subtitle',
                  mock: 'Add the Primary Analysis Censoring Date (PACD)',
                  helper: 'Title above date picker in drawer',
                },
                subtitleTimeWindow: {
                  type: 'string',
                  title: 'Drawer time window subtitle',
                  mock: 'Close Out visit window - based on the PACD',
                  helper: 'Title above time window in drawer',
                },
              },
            },
            alert: {
              type: 'object',
              title: 'Enable Close out date alerts',
              properties: {
                setOnceWarning: {
                  type: 'string',
                  title: 'Enable Close out warning',
                  mock: 'Please only enter the PACD when it’s confirmed. You will not be able to go back and change it later.',
                  helper: 'This is only a warning and does not prevent form submission.',
                },
                enableCloseOutFailed: {
                  type: 'string',
                  title: 'Enable Close failed error',
                  mock: 'Failed to enable Close out time period',
                  helper: 'Failed to enable close out error message',
                },
              },
            },
            datePicker: {
              type: 'object',
              title: 'Enable Close out date picker',
              properties: { label: { type: 'string', title: 'Label', helper: 'PACD date picker label', mock: 'PACD' } },
            },
            timeWindow: {
              type: 'object',
              title: 'Enable Close out time window',
              properties: {
                date: { type: 'string', title: 'PACD date label', helper: 'PACD time window label', mock: 'PACD' },
                start: {
                  type: 'string',
                  title: 'Start label',
                  helper: 'Time window start label',
                  mock: 'Window start',
                },
                end: { type: 'string', title: 'End label', helper: 'Time window end label', mock: 'Window end' },
              },
            },
            confirmDialog: {
              type: 'object',
              title: 'Enable Close out date confirm dialog',
              properties: {
                title: {
                  type: 'string',
                  title: 'Title',
                  helper: 'The title of the Enable Close out time tracker confirm dialog',
                  mock: 'Enable Close Out Tracker',
                },
                descriptionMd: {
                  type: 'string',
                  title: 'Description as markdown',
                  mock: 'Once you click ‘Confirm’ you cannot change the PACD.\n\nPlease be aware the Close Out visit will be appear on all patients’ visit tab once you confirm.',
                  helper: 'The description of the Enable Close out time tracker confirm dialog as markdown',
                },
                cancel: {
                  type: 'string',
                  title: 'Cancel button',
                  helper: 'Enable Close out time tracker dialog cancel button',
                  mock: 'Cancel',
                },
                confirm: {
                  type: 'string',
                  title: 'Confirm button',
                  helper: 'Enable Close out time tracker dialog confirm button',
                  mock: 'Confirm',
                },
              },
            },
          },
        },
      },
    },
  },
  description: 'unify:webstudyoverview',
  $schema: 'http://json-schema.org/draft-07/schema#',
};
