import { fromTimezoneStr, nowToZonedDateTimeCurrentZone, toTimezoneStr } from '@csp/csp-common-date-util';
import { Compliance, ComplianceMetricItem, ComplianceMetricItemType, ZonedDateTime } from '@csp/csp-common-model';
import { ComplianceMetricV1, ComplianceV1, CompliantStateType } from '@csp/dmdp-api-user-dto';

const toComplianceValue = ({
  scheduleCode,
  requestCode,
  value,
  isCompliant,
  type,
  updatedAt,
}: ComplianceMetricV1): ComplianceMetricItem => ({
  scheduleRef: { scheduleCode, requestCodes: requestCode.split('/') },
  value: {
    value,
    isCompliant,
    notCompliant: !isCompliant,
    percentage: value * 100,
  },
  type: type as ComplianceMetricItemType,
  updatedAt: fromTimezoneStr(updatedAt),
});

const toCompliance = ({ updatedAt, state, metrics }: ComplianceV1): Compliance => ({
  updatedAt: fromTimezoneStr(updatedAt),
  state,
  metrics: metrics.map(toComplianceValue),
});

const toComplianceMetricV1 = ({ scheduleRef, type, value, updatedAt }: ComplianceMetricItem): ComplianceMetricV1 => ({
  requestCode: scheduleRef.requestCodes.join('/'),
  scheduleCode: scheduleRef.scheduleCode,
  type,
  updatedAt: toTimezoneStr(updatedAt),
  isCompliant: value.isCompliant,
  value: value.value,
});

const toComplianceV1 = ({ state, updatedAt, metrics }: Compliance): ComplianceV1 => ({
  state,
  timestamp: updatedAt.unixTimeMillis,
  updatedAt: toTimezoneStr(updatedAt),
  metrics: metrics.map(toComplianceMetricV1),
});

const toComplianceFromMetricItems = (metrics: ComplianceMetricItem[], updatedAt?: ZonedDateTime): Compliance => ({
  state: !metrics.length
    ? CompliantStateType.NO_DATA
    : metrics.find(({ value }) => !value.isCompliant)
    ? CompliantStateType.NOT_COMPLIANT
    : CompliantStateType.COMPLIANT,
  updatedAt: updatedAt ?? nowToZonedDateTimeCurrentZone(),
  metrics,
});

export const ComplianceMapper = {
  toCompliance,
  toComplianceV1,
  toComplianceFromMetricItems,
};
