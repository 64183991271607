import useTheme from '@mui/material/styles/useTheme';
import { FC } from 'react';
import { SxProps } from '../../../common/system/SxProps';
import { useMediaQ } from '../../../hooks/useMediaQ';
import { IconButton } from '../../buttons/IconButton';
import { Grid } from '../../layout/grid/Grid';
import { H3, H6 } from '../../typography/TextStyles';
import { DrawerHeaderProps } from './model/DrawerHeaderProps';

export const DrawerHeader: FC<DrawerHeaderProps> = ({ header, handleClose }) => {
  const { palette } = useTheme();
  const smDown = useMediaQ('sm', 'down');

  const sx: SxProps = {
    borderBottom: `1px solid ${palette.grey[300]}`,
    py: { xs: 2, md: 4 },
    pl: { xs: 3, sm: 14 },
    pr: { xs: 2, sm: 4 },
    justifyContent: 'space-between',
    alignItems: 'center',
  };
  const sxHeader: SxProps = {
    flex: '1 1',
  };

  return (
    <Grid container sx={sx}>
      {smDown ? <H6 sx={sxHeader}>{header}</H6> : <H3 sx={sxHeader}>{header}</H3>}
      <IconButton
        onClick={handleClose}
        iconProps={{ name: 'close', size: 'large', color: 'black' }}
        data-testid="close-drawer"
      />
    </Grid>
  );
};
