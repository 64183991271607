import { Body2, Box, Label2 } from '@csp/csp-web-ui';
import { FC } from 'react';
import { OpeningHoursProps } from '../model/OpeningHoursProps';

export const OpeningHours: FC<OpeningHoursProps> = ({ availabilityLabel, weekdays }) => (
  <Box mb={7}>
    <Label2 color="textSecondary" fontWeight={700} mb={1}>
      {availabilityLabel}
    </Label2>
    <Body2>{weekdays}</Body2>
  </Box>
);
