/* eslint-disable */
export const UnifyAppOnboardingSchema = {
  title: 'UnifyAppOnboarding',
  type: 'object',
  properties: {
    title: { type: 'string', title: 'Title' },
    setPin: {
      title: 'Set Pin',
      type: 'object',
      properties: {
        title: { title: 'Title', type: 'string', placeholder: 'Setup a Passcode', mock: 'Setup a Passcode' },
        confirmButtonAccessibilityText: {
          title: 'Confirm button accessibility text',
          type: 'string',
          placeholder: 'Confirm Passcode',
          mock: 'Confirm Passcode',
        },
        confirmButtonText: {
          title: 'Confirm button text',
          type: 'string',
          placeholder: 'Confirm Passcode',
          mock: 'Confirm Passcode',
        },
        error: {
          title: 'Error',
          type: 'string',
          helper:
            "Error message displayed if for some reason the internal secure storage isn't working. Very unlikely to ever happen",
          placeholder: 'Failed to set passcode',
          mock: 'Failed to set passcode',
        },
        subHeader: {
          title: 'Sub header text',
          type: 'string',
          helper: 'Explaiantion for why the user should choose a passcode',
          placeholder: 'Please set-up a passcode to further protect access and safeguard your data',
          mock: 'Please set-up a passcode to further protect access and safeguard your data',
        },
        pinNotMatchError: {
          title: 'Pin Not Matching Error',
          type: 'string',
          helper: 'Error message shown under the input field if the PIN is not matching',
          placeholder: 'The passcode you have just inserted is not matching with the previous one. Please try again.',
          mock: 'The passcode you have just inserted is not matching with the previous one. Please try again.',
        },
        pinToShortError: {
          title: 'Pin Too Short Error',
          type: 'string',
          helper: 'Error message shown under the input field if the PIN is too short',
          placeholder:
            'The passcode you have just inserted does not have enought digits, 4 is required. Please try again.',
          mock: 'The passcode you have just inserted does not have enought digits, 4 is required. Please try again.',
        },
      },
    },
    confirmPin: {
      title: 'Confirm Pin',
      type: 'object',
      properties: {
        title: { title: 'Title', type: 'string', placeholder: 'Confirm Passcode', mock: 'Confirm Passcode' },
        celebrationButtonText: {
          title: 'Set pin celebration button text',
          type: 'string',
          placeholder: 'I got it',
          mock: 'I got it',
        },
        celebrationHelper: {
          title: 'Set pin celebration helper',
          type: 'string',
          helper: 'Text displayed in the celebration text for successful pin setup',
          placeholder: 'You can only use one device at a time. This is now the primary device.',
          mock: 'You can only use one device at a time. This is now the primary device.',
        },
        celebrationDescription: {
          title: 'Set celebration description',
          type: 'string',
          helper: 'Description for the celebration page on succesful set pin',
          placeholder: 'Description for the celebration page on succesful set pin',
          mock: 'Description for the celebration page on succesfull set pin',
        },
        celebrationTitle: {
          title: 'Set pin celebration title',
          type: 'string',
          helper: 'Title for the celebration page on succesfull set pin',
          placeholder: 'Passcode set for this device',
          mock: 'Passcode set for this device',
        },
        confirmButtonAccessibilityText: {
          title: 'Confirm button accessibility text',
          type: 'string',
          placeholder: 'Confirm Passcode',
          mock: 'Confirm Passcode',
        },
        confirmButtonText: {
          title: 'Confirm button',
          type: 'string',
          helper: 'Confirm Passcode',
          placeholder: 'Confirm Passcode',
          mock: 'Confirm Passcode',
        },
        errorSave: {
          title: 'Error save',
          type: 'string',
          helper:
            "Error message displayed if for some reason the internal secure storage isn't working. Very unlikely to ever happen",
          placeholder: 'Failed to save passcode',
          mock: 'Failed to save passcode',
        },
        subHeader: {
          title: 'Sub header text',
          type: 'string',
          helper: 'Explaination text for the confirm pin step',
          placeholder: 'Please digit the Passcode you created to authorize and confirm this step.',
          mock: 'Please digit the Passcode you created to authorize and confirm this step.',
        },
      },
    },
    eula: {
      title: 'EULA',
      type: 'object',
      properties: {
        acceptButton: {
          title: 'Accept button',
          type: 'string',
          helper: 'Accept button below Eula text',
          placeholder: 'Accept',
          mock: 'Accept',
        },
        acceptButtonAccessibilityText: {
          title: 'Accept button accessibility text',
          type: 'string',
          helper: 'Accept button accessibility text',
          placeholder: 'Accept',
          mock: 'Accept',
        },
        agreementWarning: {
          title: 'Agreement warning',
          type: 'string',
          helper: 'Prompt text for user that they need to read and accept EULA to continue',
          placeholder: 'Please read and agree to our EULA. This step is required to use our services',
          mock: 'Please read and agree to our EULA. This step is required to use our services',
        },
        alertAcceptButton: {
          title: 'Alert accept button',
          type: 'string',
          helper: 'Accept button text displayed in Alert when user tries to decline Eula',
          placeholder: 'Accept',
          mock: 'Accept',
        },
        alertCloseButton: {
          title: 'Alert close button',
          type: 'string',
          helper: 'Close button text displayed in Alert when user tries to decline Eula',
          placeholder: 'Close',
          mock: 'Close',
        },
        alertDeclineText: {
          title: 'Alert decline text',
          type: 'string',
          helper: 'Text for alert when user tries to decline Eula',
          placeholder:
            'If you want to access this Unify to support you in this study then you will need to accept this agreement',
          mock: 'If you want to access this Unify to support you in this study then you will need to accept this agreement',
        },
        alertDeclineTitle: {
          title: 'Alert decline title',
          type: 'string',
          helper: 'Title for alert when user tries to decline Eula',
          placeholder: 'Are you sure you want to decline?',
          mock: 'Are you sure you want to decline?',
        },
        declineButton: {
          title: 'Decline button',
          type: 'string',
          helper: 'Decline button below Eula text',
          placeholder: 'Decline',
          mock: 'Decline',
        },
        declineButtonAccessibilityText: {
          title: 'Decline button accessibility text',
          type: 'string',
          helper: 'Decline button accessibility text',
          placeholder: 'Decline',
          mock: 'Decline',
        },
      },
    },
    privacyPolicy: {
      title: 'Privacy Policy',
      type: 'object',
      properties: {
        acceptButton: {
          title: 'Accept button',
          type: 'string',
          helper: 'Accept button below Privacy Policy text',
          placeholder: 'Accept',
          mock: 'Accept',
        },
        acceptButtonAccessibilityLabel: {
          title: 'Accept button accessibility label',
          type: 'string',
          helper: 'Accept button accessibility text',
          placeholder: 'Accept',
          mock: 'Accept',
        },
        agreementWarning: {
          title: 'Agreement warning',
          type: 'string',
          helper: 'Prompt text for user that they need to read and accept Privacy Policy to continue',
          placeholder: 'Please read and agree to our Privacy Policy. This step is required to use our services',
          mock: 'Please read and agree to our Privacy Policy. This step is required to use our services',
        },
        alertAcceptButton: {
          title: 'Alert accept button',
          type: 'string',
          helper: 'Accept button text displayed in Alert when user tries to decline Privacy Policy',
          placeholder: 'Accept',
          mock: 'Accept',
        },
        alertCloseButton: {
          title: 'Alert close button',
          type: 'string',
          helper: 'Close button text displayed in Alert when user tries to decline Privacy Policy',
          placeholder: 'Close',
          mock: 'Close',
        },
        alertDeclineText: {
          title: 'Alert decline text',
          type: 'string',
          helper: 'Text for alert when user tries to decline Privacy Policy',
          placeholder:
            'If you want to access this Unify to support you in this study then you will need to accept this agreement',
          mock: 'If you want to access this Unify to support you in this study then you will need to accept this agreement',
        },
        alertDeclineTitle: {
          title: 'Alert decline title',
          type: 'string',
          helper: 'Title for alert when user tries to decline Privacy Policy',
          placeholder: 'Are you sure you want to decline?',
          mock: 'Are you sure you want to decline?',
        },
        declineButton: {
          title: 'Decline button',
          type: 'string',
          helper: 'Decline button below Privacy Policy text',
          placeholder: 'Decline',
          mock: 'Decline',
        },
        declineButtonAccessibilityText: {
          title: 'Decline button accessibility text',
          type: 'string',
          helper: 'Decline button accessibility text',
          placeholder: 'Decline',
          mock: 'Decline',
        },
        privacyPolicyAcceptError: {
          title: 'Privacy Policy accept error',
          type: 'string',
          helper:
            'Error displayed if something goes wrong when the user tries to accept Privacy Policy. When accepting the Privacy Policy there is some backend communication storing the save as well as activating the user. If the app is unexpectedly offline or so there might be an error and this text is displayed',
          placeholder: 'An unknown error occurred when accepting Privacy Policy, please try again',
          mock: 'An unknown error occurred when accepting Privacy Policy, please try again',
        },
      },
    },
    notificationSettings: {
      title: 'Notification settings',
      type: 'object',
      properties: {
        title: { title: 'Title', type: 'string', placeholder: 'Stay on Track', mock: 'Stay on Track' },
        subHeader: {
          title: 'Sub header text',
          type: 'string',
          helper: 'Motivation text explaining why the user should enable notifications',
          placeholder: "We'll remind you about anything that you need to do, and any updates on the trial",
          mock: "We'll remind you about anything that you need to do, and any updates on the trial",
        },
        acceptButtonText: {
          title: 'Accept button text',
          type: 'string',
          placeholder: 'Keep on track',
          mock: 'Keep on track',
        },
        acceptButtonAccessibilityText: {
          title: 'Accept button accessibility text',
          type: 'string',
          placeholder: 'Keep on track',
          mock: 'Keep on track',
        },
        declineButtonText: { title: 'Decline button text', type: 'string', placeholder: 'Not now', mock: 'Not now' },
        declineButtonAccessibilityText: {
          title: 'Decline button accessibility text',
          type: 'string',
          placeholder: 'Not now',
          mock: 'Not now',
        },
        declineAlert: {
          title: 'Decline alert',
          helper: 'Displayed when the user choose to decline notifications',
          type: 'object',
          properties: {
            alertDeclineTitle: {
              title: 'Alert decline title',
              type: 'string',
              helper:
                'This text is displayed in the title for the alert displayed if the user tries to decline notifications',
              placeholder: 'Enable Notifications',
              mock: 'Enable Notifications',
            },
            alertDeclineText: {
              title: 'Alert decline text',
              type: 'string',
              helper:
                'This text is displayed in the text for the alert displayed if the user tries to decline notifications',
              placeholder:
                'Enabling notifications is recommended so that we can remind you about important activities and appointments',
              mock: 'Enabling notifications is recommended so that we can remind you about important activities and appointments',
            },
            alertAcceptButtonText: {
              title: 'Alert accept button text',
              type: 'string',
              helper:
                'This text is displayed in the accept button for the alert displayed if the user tries to decline notifications',
              placeholder: 'Enable',
              mock: 'Enable',
            },
            alertCancelButtonText: {
              title: 'Alert cancel button',
              type: 'string',
              helper:
                'This text is displayed in the cancel button for the alert displayed if the user tries to decline notifications',
              placeholder: 'Not now',
              mock: 'Not now',
            },
          },
        },
        image: { type: 'object', _attachment: { id: 'notificationSettings' } },
      },
    },
    geolocationSettings: {
      title: 'Geolocation Settings',
      type: 'object',
      properties: {
        title: { title: 'Title', type: 'string', placeholder: 'Geolocation', mock: 'Geolocation' },
        subHeader: {
          title: 'Sub header text',
          type: 'string',
          helper: 'Motivation text explaining why the user should enable geolocation services',
          placeholder:
            'By allowing the reasearch team at AstraZeneca to see your location, we will learn more about when ypu are near hospitals, helping us to stramline clinical trials in the future.',
          mock: 'By allowing the reasearch team at AstraZeneca to see your location, we will learn more about when ypu are near hospitals, helping us to stramline clinical trials in the future.',
        },
        acceptButtonText: {
          title: 'Accept button',
          type: 'string',
          placeholder: 'Allow Geolocation',
          mock: 'Allow Geolocation',
        },
        acceptButtonAccessibilityText: {
          title: 'Accept button accessibility text',
          type: 'string',
          placeholder: 'Allow Geolocation',
          mock: 'Allow Geolocation',
        },
        declineButtonText: { title: 'Decline button', type: 'string', placeholder: 'Not now', mock: 'Not now' },
        declineButtonAccessibilityText: {
          title: 'Decline button accessibility text',
          type: 'string',
          placeholder: 'Not now',
          mock: 'Not now',
        },
        declineAlert: {
          title: 'Decline alert',
          helper: 'Displayed when the user choose to decline geolocation services',
          type: 'object',
          properties: {
            alertDeclineTitle: {
              title: 'Alert decline title',
              type: 'string',
              helper:
                'This text is displayed in the title for the alert displayed if the user tries to decline geolocation services',
              placeholder: 'Allow Geolocation Services',
              mock: 'Allow Geolocation Services',
            },
            alertDeclineText: {
              title: 'Alert decline text',
              type: 'string',
              helper:
                'This text is displayed in the text for the alert displayed if the user tries to decline geolocation text',
              placeholder: 'Not recommended to proceed without accepting geolocation settings.',
              mock: 'Not recommended to proceed without accepting geolocation settings.',
            },
            alertAcceptButtonText: {
              title: 'Alert accept button text',
              type: 'string',
              helper:
                'This text is displayed in the accept button for the alert displayed if the user tries to decline geolocation services',
              placeholder: 'Allow',
              mock: 'Allow',
            },
            alertCancelButton: {
              title: 'Alert cancel button',
              type: 'string',
              helper:
                'This text is displayed in the cancel button for the alert displayed if the user tries to decline geolocation services',
              placeholder: 'Not now',
              mock: 'Not now',
            },
          },
        },
        image: { type: 'object', _attachment: { id: 'geolocationSettings' } },
      },
    },
    welcomeToStudy: {
      title: 'Welcome to study',
      type: 'object',
      properties: {
        header: { title: 'Header', type: 'string', placeholder: 'Welcome to the study', mock: 'Welcome to the study' },
        button: { title: 'Get started button', type: 'string', placeholder: 'Get started', mock: 'Get started' },
        buttonAccessibility: {
          title: 'Get started button accessibility',
          type: 'string',
          placeholder: 'Get started',
          mock: 'Get started',
        },
      },
    },
    reConsent: {
      title: 'Reconsent',
      type: 'object',
      properties: {
        header: {
          title: 'Header',
          type: 'string',
          placeholder: 'Terms and Condition Updates',
          mock: 'Terms and Condition Updates',
        },
        headerAccessibilityLabel: {
          title: 'Header Accessibility Label',
          type: 'string',
          placeholder: 'Terms and Condition Updates',
          mock: 'Terms and Condition Updates',
        },
        subHeader: {
          title: 'Sub Header',
          type: 'string',
          placeholder: 'Please accept the updated Terms and Conditions before continuing',
          mock: 'Please accept the updated Terms and Conditions before continuing',
        },
        subHeaderAccessibilityLabel: {
          title: 'Sub Header Accessibility Label',
          type: 'string',
          placeholder: 'Please accept the updated Terms and Conditions before continuing',
          mock: 'Please accept the updated Terms and Conditions before continuing',
        },
        button: { title: 'Continue Button', type: 'string', placeholder: 'Continue', mock: 'Continue' },
        buttonAccessibilityLabel: {
          title: 'Continue Button Accessibility Label',
          type: 'string',
          placeholder: 'Continue',
          mock: 'Continue',
        },
        buttonAccessibilityHint: {
          title: 'Continue Button Accessibility Hint',
          type: 'string',
          placeholder: 'Continue to updated policies',
          mock: 'Continue to updated policies',
        },
      },
    },
  },
  _parent: 'n:node',
  description: 'unify:apponboarding',
  $schema: 'http://json-schema.org/draft-04/schema#',
  _system: {
    deleted: false,
    changeset: '854:1ae198239748f3140624',
    modified_on: { iso_8601: '2020-07-01T05:12:16Z' },
    previousChangeset: '684:d36d7af35d5a7f1d9673',
  },
  _doc: '91807267d0a7060bc15b',
  _qname: 'unify:apponboarding',
  _features: { 'f:publishable': { state: 'draft' } },
  _type: 'd:type',
  _statistics: { 'a:has_role': 1, 'a:has_role_INCOMING': 1, 'a:has_form': 1, 'a:has_form_OUTGOING': 1 },
  _is_association: false,
};
