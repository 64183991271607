/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DeviceSessionAggregateCriteriaFieldV1 {
    USER_ID = 'USER_ID',
    SESSION_CODE = 'SESSION_CODE',
    SESSION_STATUS = 'SESSION_STATUS',
    SESSION_TIMESTAMP = 'SESSION_TIMESTAMP',
    EVENT_ID = 'EVENT_ID',
    SCHEDULE_REQUEST_REF_REQUEST_CODE = 'SCHEDULE_REQUEST_REF_REQUEST_CODE',
    SCHEDULE_REQUEST_REF_VERSION_CODE = 'SCHEDULE_REQUEST_REF_VERSION_CODE',
    SCHEDULE_REQUEST_REF_SCHEDULE_CODE = 'SCHEDULE_REQUEST_REF_SCHEDULE_CODE',
    SCHEDULE_REQUEST_STATUS = 'SCHEDULE_REQUEST_STATUS',
}