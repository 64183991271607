export enum PatientAppStatusType {
  UNKNOWN = 'UNKNOWN',
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  INVITED = 'INVITED',
  RETIRED = 'RETIRED',
  ALUMNI = 'ALUMNI',
}

const values = Object.keys(PatientAppStatusType).filter(val => val !== PatientAppStatusType.UNKNOWN);

const isPatientAppStatus = (value: string): value is PatientAppStatusType =>
  values.includes(value as PatientAppStatusType);

export const PatientAppStatusTypes = {
  values,
  isPatientAppStatus,
};
