/* eslint-disable */
export const UnifyMedicationMock = {
  title: 'title',
  countryCodes: [],
  schedules: [
    {
      scheduleCode: 'STUDY_PROTOCOL_MEDICATION',
      medicationGroups: [
        {
          requestCode: 'SZC',
          medicationGroupTitle: 'Sodium Zirconium Cyclosilicate',
          appAboutMedicationGroup: 'SZC description',
          hcpAboutMedicationGroup: {
            title: 'About Sodium Zirconium Cyclosilicate',
            descriptionMd:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
          },
          allowedVersions: [{ version: '1.0' }],
          medicationOptions: [
            {
              medicationCode: 'SZC_5G_POWDER_PLACEBO',
              dosageInstructions: [
                {
                  dosageInstructionCode: '1_WHITE_LABEL_SACHET_MORNING_WITH_WATER',
                  dose: '5 g or placebo',
                  numberOfDosages: '1',
                  medicationForm: 'Powder for oral suspension',
                  timingInstruction: 'Once daily on non-dialyze days',
                  dosingInstruction:
                    'Take 1 white label sachet (5 g) or placebo. Suspend the sachet in 45 ml of water and swallow.',
                },
              ],
            },
            {
              medicationCode: 'SZC_10G_POWDER_PLACEBO',
              dosageInstructions: [
                {
                  dosageInstructionCode: '1_BLUE_LABEL_SACHET_MORNING_WITH_WATER',
                  dose: '10 g or placebo',
                  numberOfDosages: '1',
                  medicationForm: 'Powder for oral suspension',
                  timingInstruction: 'Once daily on non-dialyze days',
                  dosingInstruction:
                    'Take 1 blue label sachet (10 g) or placebo. Suspend the sachet in 45 ml of water and swallow.',
                },
              ],
            },
            {
              medicationCode: 'SZC_15G_POWDER_PLACEBO',
              dosageInstructions: [
                {
                  dosageInstructionCode: '1_BLUE_AND_WHITE_LABEL_SACHET_MORNING_WITH_WATER',
                  dose: '15 g or placebo',
                  numberOfDosages: '1',
                  medicationForm: 'Powder for oral suspension',
                  timingInstruction: 'Once daily on non-dialyze days',
                  dosingInstruction:
                    'Take 1 white label sachet (5 g) and 1 blue label sachet (10 g) or placebo. Suspend the sachet in 45 ml of water and swallow.',
                },
              ],
            },
          ],
        },
        {
          requestCode: 'IRESSA',
          medicationGroupTitle: 'Iressa',
          appAboutMedicationGroup: 'Iressa description',
          hcpAboutMedicationGroup: {
            title: 'About Iressa',
            descriptionMd:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
          },
          allowedVersions: [{ version: '1.0' }],
          medicationOptions: [
            {
              medicationCode: 'IRESSA_5MG_PILL_PLACEBO',
              dosageInstructions: [
                {
                  dosageInstructionCode: '1_RED_PILL_MORNING_WITH_WATER',
                  dose: '5 mg or placebo',
                  numberOfDosages: '1',
                  medicationForm: 'Pill for oral adminstration',
                  timingInstruction: 'Once daily on non-dialyze days',
                  dosingInstruction: 'Take 1 red pill. Swallow with water.',
                },
              ],
            },
            {
              medicationCode: 'IRESSA_10MG_PILL_PLACEBO',
              dosageInstructions: [
                {
                  dosageInstructionCode: '1_BLUE_PILL_MORNING_WITH_WATER',
                  dose: '10 mg or placebo',
                  numberOfDosages: '1',
                  medicationForm: 'Pill for oral adminstration',
                  timingInstruction: 'Once daily on non-dialyze days',
                  dosingInstruction: 'Take 1 blue pill. Swallow with water.',
                },
              ],
            },
            {
              medicationCode: 'IRESSA_15MG_PILL_PLACEBO',
              dosageInstructions: [
                {
                  dosageInstructionCode: '1_BLUE_AND_1_RED_PILL_MORNING_WITH_WATER',
                  dose: '15 mg or placebo',
                  numberOfDosages: '1',
                  medicationForm: 'Pill for oral adminstration',
                  timingInstruction: 'Once daily on non-dialyze days',
                  dosingInstruction: 'Take 1 blue pill and one red pill. Swallow with water.',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
