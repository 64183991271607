import { CloseoutTrackerStatusType, Org, StudyOrgStatuses } from '@csp/csp-common-model';
import { isAfter } from 'date-fns';
import { StudyStatusMapper } from '../mapper/StudyStatusMapper';
import { StudyStatusesFactory } from '../model/StudyStatusesFactory';

const getStudyOrgStatuses = ({ customStatuses }: Org): StudyOrgStatuses =>
  customStatuses.hasStatuses
    ? StudyStatusMapper.toStudyOrgStatuses(customStatuses)
    : StudyStatusesFactory.emptyStudyStatuses;

const isCloseoutPeriodActive = ({ closeoutTrackerStatus }: StudyOrgStatuses): boolean =>
  closeoutTrackerStatus.status === CloseoutTrackerStatusType.ACTIVE &&
  isAfter(new Date(), closeoutTrackerStatus.timestamp.unixTimeMillis);

export const StudyStatusService = {
  getStudyOrgStatuses,
  isCloseoutPeriodActive,
};
