import styled from '@emotion/styled';
import Grid, { GridDirection, GridSize } from '@mui/material/Grid';
import { spacing, SpacingProps } from '@mui/system';
import { cloneElement, Children, Attributes, FC, ReactElement, ReactNode } from 'react';
import { ButtonProps } from '../model/ButtonProps';
import { useMediaQ } from '../../../hooks/useMediaQ';

const StyledGrid = styled(Grid)<SpacingProps>`
  ${spacing}
`;

type ButtonGroupProps = {
  buttonProps?: Partial<ButtonProps> & Attributes;
  children?: ReactNode;
};

export const ButtonGroup: FC<ButtonGroupProps> = ({ children, buttonProps, ...restProps }) => {
  const xsDown = useMediaQ('xs', 'down');
  const direction: GridDirection = xsDown ? 'column' : 'row';

  const renderButton = (button: ReactElement<ButtonProps>, count: number): ReactNode => {
    const buttonClone = cloneElement(button, buttonProps);
    const calculateSizeFromCount = (12 / count) as GridSize;
    return (
      <StyledGrid item xs={12} sm={calculateSizeFromCount} p={1}>
        {buttonClone}
      </StyledGrid>
    );
  };
  const numButtons = Children.count(children);

  return (
    <Grid container direction={direction} {...restProps}>
      {Children.map(children, button => renderButton(button as ReactElement<ButtonProps>, numButtons))}
    </Grid>
  );
};
