import libphonenumber, {
  CountryCode,
  getCountries,
  getCountryCallingCode,
  parsePhoneNumberWithError,
} from 'libphonenumber-js';
import type { Maybe } from '@csp/csp-common-model';
import { CountryUtil } from './CountryUtil';

export interface CountryDialCode {
  name: string;
  dialCode: string;
  countryCode: string;
}

/** Returns sorted list of supported countries incl the dial code */
const isoCountryDialCodes: CountryDialCode[] = getCountries()
  .map(countryCode => CountryUtil.getCountryByCode(countryCode))
  .filter(country => country.name !== '')
  .map(country => ({
    name: country.name,
    countryCode: country.code,
    dialCode: getCountryCallingCode(country.code as CountryCode),
  }))
  .sort((cnt1, cnt2) => (cnt1.name > cnt2.name ? 1 : -1));

/** Returns sorted list of supported countries incl the dial code. List is started with an empty country */
const isoCountryDialCodesStartWithEmpty: CountryDialCode[] = [
  {
    countryCode: '',
    dialCode: '',
    name: '',
  },
  ...isoCountryDialCodes,
];

export class PhoneNumberUtil {
  static getIsoCountryDialCodes(): CountryDialCode[] {
    return isoCountryDialCodes;
  }

  static getIsoCountryDialCodesStartWithEmpty(): CountryDialCode[] {
    return isoCountryDialCodesStartWithEmpty;
  }

  static toInternalFormat(countryCode: string, number: string): string {
    const phoneNumber = parsePhoneNumberWithError(number, countryCode as CountryCode);
    return phoneNumber.format('E.164');
  }

  static toNationalFormat(number: string): string {
    const phoneNumber = parsePhoneNumberWithError(number);
    return phoneNumber.format('NATIONAL');
  }

  static toDisplayFormat(number: string): string {
    const phoneNumber = parsePhoneNumberWithError(number);
    return phoneNumber.formatInternational();
  }

  static toDisplayFormatSafe(number: string): string {
    try {
      return this.toDisplayFormat(number);
    } catch (err) {
      return number;
    }
  }

  static getCountryCodeSafe(number: string): Maybe<string> {
    return libphonenumber(number)?.country;
  }

  static isValidNumber(number: string): boolean {
    return !!libphonenumber(number)?.isValid();
  }
}
