import { fromTimezoneStrOrUndefined } from '@csp/csp-common-date-util';
import { PatientStatusService } from '@csp/csp-common-patient-status';
import {
  ApiOptions,
  Maybe,
  OccasionType,
  PatientAppStatusType,
  PatientOccasions,
  PatientStatusType,
  User,
} from '@csp/csp-common-model';
import { toRestOptions } from '@csp/csp-fe-auth';
import { UserRestServiceV1 } from '@csp/dmdp-api-client';
import { OccasionV1, UserV1 } from '@csp/dmdp-api-user-dto';

const getPatientOccasions = (user: User, occasions: Maybe<Array<OccasionV1>>): PatientOccasions => {
  const inviteOccasion = occasions?.find(occasion => occasion.type === OccasionType.INVITE);
  const trainingOccasion = occasions?.find(occasion => occasion.type === OccasionType.TRAINING);
  const appActiveStatus = PatientStatusService.findPastPatientStatus(
    user.customStatuses,
    PatientStatusType.APP,
    PatientAppStatusType.ACTIVE,
  );
  const appRetiredStatus = PatientStatusService.findPastPatientStatus(
    user.customStatuses,
    PatientStatusType.APP,
    PatientAppStatusType.RETIRED,
  );
  const appAlumniStatus = PatientStatusService.findPastPatientStatus(
    user.customStatuses,
    PatientStatusType.APP,
    PatientAppStatusType.ALUMNI,
  );
  const inactivationTime = appRetiredStatus ? appRetiredStatus.timestamp : appAlumniStatus?.timestamp;

  return {
    inviteTime: fromTimezoneStrOrUndefined(inviteOccasion?.localTime),
    trainingDate: fromTimezoneStrOrUndefined(trainingOccasion?.localTime),
    activationTime: appActiveStatus?.timestamp,
    inactivationTime,
  };
};

const deletePatientOccasion = async (
  userV1: UserV1,
  occasionType: OccasionType,
  apiOptions?: ApiOptions,
): Promise<void> => {
  if (userV1.occasions?.find(occasion => occasion.type === occasionType)) {
    await UserRestServiceV1.deleteOccasion(toRestOptions(apiOptions), userV1.userId, occasionType);
  }
};

const addOrUpdatePatientOccasion = async (
  userV1: UserV1,
  occasionV1: OccasionV1,
  apiOptions?: ApiOptions,
): Promise<void> => {
  if (userV1.occasions?.find(occasion => occasion.type === occasionV1.type)) {
    await UserRestServiceV1.updateOccasion(toRestOptions(apiOptions), userV1.userId, occasionV1);
  } else {
    await UserRestServiceV1.addOccasion(toRestOptions(apiOptions), userV1.userId, occasionV1);
  }
};

export const PatientOccasionService = {
  getPatientOccasions,
  deletePatientOccasion,
  addOrUpdatePatientOccasion,
};
