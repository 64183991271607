/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AssessmentIncludeFieldV1 {
    ASSESSMENT_ID = 'ASSESSMENT_ID',
    ASSESSMENT_CODE = 'ASSESSMENT_CODE',
    ASSESSMENT_TYPE = 'ASSESSMENT_TYPE',
    CUSTOM_STATUSES = 'CUSTOM_STATUSES',
    USER_ID = 'USER_ID',
    REFS = 'REFS',
    META = 'META',
    EVENT_ID = 'EVENT_ID',
    AUDIT = 'AUDIT',
    COMMENTS = 'COMMENTS',
    OUTCOMES = 'OUTCOMES',
}