import { ErrorInfo, Maybe, OrgId, toErrorInfo } from '@csp/csp-common-model';
import { useEffect, useMemo } from 'react';
import { VisitRequest, VisitSchedule, VisitScheduleService } from '@csp/csp-common-visits';
import { useFetch } from '@csp/fe-hooks';
import { OrgsSchedules, RequestGroups } from '@csp/csp-common-scheduling';
import { useStudyContext } from '../../../../common/context/study/useStudyContext';

type Out = {
  scheduleRequestError: Maybe<ErrorInfo>;
  isLoadingScheduleRequest: boolean;
  visitRequests: VisitRequest[];
  hbsVisitRequests: VisitRequest[];
  requestGroups: RequestGroups<VisitRequest>;
  requireConfirmation: boolean;
  hbsEnabled: boolean;
};

const resolveRequireConfirmation = (visitRequests: VisitRequest[]): boolean =>
  visitRequests?.some(visit => visit.requireConfirmation) ?? false;

const resolveHbsEnabled = (visitRequests: VisitRequest[]): boolean =>
  visitRequests?.some(visit => visit.hbs.enabled) ?? false;

export const useVisitRequests = (orgIdsIn?: OrgId[]): Out => {
  const { study } = useStudyContext();

  const orgIds: OrgId[] = useMemo(() => orgIdsIn ?? study.sites.map(site => site.orgId), [study.sites, orgIdsIn]);
  const [fetchActiveVisits, { data: orgSchedules, isLoading: isLoadingScheduleRequest, error }] = useFetch(
    VisitScheduleService.getCachedActivatedVisitSchedulesForOrganizationIds,
  );

  useEffect(() => {
    if (orgIds.length && !orgSchedules) {
      fetchActiveVisits(orgIds).then();
    }
  }, [fetchActiveVisits, orgIds, orgSchedules]);

  const orgSchedulesOrDefault = orgSchedules ?? OrgsSchedules.from<VisitSchedule, VisitRequest>([]);
  const visitRequests = useMemo(() => orgSchedulesOrDefault.getAllRequests(), [orgSchedulesOrDefault]);
  const hbsVisitRequests = useMemo(
    () => orgSchedulesOrDefault.getAllRequests().filter(request => request.hbs.enabled),
    [orgSchedulesOrDefault],
  );

  return {
    scheduleRequestError: toErrorInfo({ message: error?.message }),
    isLoadingScheduleRequest,
    visitRequests,
    hbsVisitRequests,
    requestGroups: orgSchedulesOrDefault.getRequestGroups(),
    requireConfirmation: resolveRequireConfirmation(visitRequests),
    hbsEnabled: resolveHbsEnabled(visitRequests),
  };
};
