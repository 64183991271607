import { useContext } from 'react';
import { StudyContext } from './StudyContext';
import { StudyContextState } from './StudyContextState';

export const useStudyContext = (): StudyContextState => {
  const studyContext = useContext(StudyContext);
  if (studyContext) {
    return studyContext;
  } else {
    throw new Error('Use only from children of StudyContext');
  }
};
