import { parseJsonWithBigInt } from '@csp/csp-common-axios';
import { Maybe, RestOptions } from '@csp/csp-common-model';
import {
  AssessmentAggregateResultV1,
  AssessmentAggregateV1,
  AssessmentCommentInV1,
  AssessmentCommentV1,
  AssessmentInV1,
  AssessmentOutcomeInV1,
  AssessmentQueryV1,
  AssessmentsV1,
  AssessmentV1,
  CustomStatusesInV1,
} from '@csp/dmdp-api-assessment-dto';
import { Page } from '@csp/dmdp-api-common-dto';
import { AxiosRequestConfig } from 'axios';
import { omit } from 'lodash/fp';
import { journalpodUrl } from '../../dmdp-config/dmdpConfig';

export const assessmentsUrl = (): string => `${journalpodUrl()}/assessment/v1/assessments`;

const createAxiosRequestConfig = (signal: Maybe<AbortSignal>, params?: Page): AxiosRequestConfig => ({
  params,
  signal,
  transformResponse: parseJsonWithBigInt,
});

const addOrUpdateCustomStatuses = async (
  { axios, signal }: RestOptions,
  assessmentId: string,
  customStatusesInV1: CustomStatusesInV1,
): Promise<void> => {
  await axios.post<CustomStatusesInV1>(
    `${assessmentsUrl()}/${assessmentId}/custom-statuses`,
    customStatusesInV1,
    createAxiosRequestConfig(signal),
  );
};

const aggregateAssessments = async (
  { axios, signal }: RestOptions,
  query: AssessmentAggregateV1,
): Promise<AssessmentAggregateResultV1> => {
  const { data } = await axios.post<AssessmentAggregateResultV1>(
    `${assessmentsUrl()}:aggregate`,
    query,
    createAxiosRequestConfig(signal),
  );
  return data;
};

const getAssessments = async (
  { axios, signal }: RestOptions,
  query: AssessmentQueryV1,
  page: Page,
): Promise<AssessmentsV1> => {
  const { data } = await axios.post<AssessmentsV1>(
    `${assessmentsUrl()}:query`,
    query,
    createAxiosRequestConfig(signal, page),
  );
  return data;
};

const getAssessmentById = async ({ axios, signal }: RestOptions, assessmentId: string): Promise<AssessmentV1> => {
  const { data } = await axios.get<AssessmentV1>(
    `${assessmentsUrl()}/${assessmentId}`,
    createAxiosRequestConfig(signal),
  );
  return data;
};

const createAssessment = async (
  { axios, signal }: RestOptions,
  assessmentInV1: AssessmentInV1,
): Promise<AssessmentV1> => {
  const { data } = await axios.post<AssessmentV1>(assessmentsUrl(), assessmentInV1, createAxiosRequestConfig(signal));
  return data;
};

const createAssessmentComment = async (
  { axios, signal }: RestOptions,
  assessmentId: string,
  assessmentComment: AssessmentCommentInV1,
): Promise<AssessmentCommentV1> => {
  const { data } = await axios.post<AssessmentCommentV1>(
    `${assessmentsUrl()}/${assessmentId}/comments`,
    assessmentComment,
    createAxiosRequestConfig(signal),
  );
  return data;
};

const updateAssessmentOutcome = async (
  { axios, signal }: RestOptions,
  assessmentId: string,
  assessmentOutcome: AssessmentOutcomeInV1,
): Promise<AssessmentV1> => {
  const { data } = await axios.put<AssessmentV1>(
    `${assessmentsUrl()}/${assessmentId}/outcomes/${assessmentOutcome.key}`,
    omit(['key'], assessmentOutcome),
    createAxiosRequestConfig(signal),
  );
  return data;
};

const deleteAssessmentOutcome = async (
  { axios, signal }: RestOptions,
  assessmentId: string,
  assessmentOutcomeKey: string,
): Promise<AssessmentV1> => {
  const { data } = await axios.delete<AssessmentV1>(
    `${assessmentsUrl()}/${assessmentId}/outcomes/${assessmentOutcomeKey}`,
    createAxiosRequestConfig(signal),
  );
  return data;
};

export const AssessmentRestServiceV1 = {
  addOrUpdateCustomStatuses,
  aggregateAssessments,
  createAssessment,
  createAssessmentComment,
  deleteAssessmentOutcome,
  getAssessmentById,
  getAssessments,
  updateAssessmentOutcome,
};
