import { negate } from 'lodash/fp';
import { ErrorMessages } from '../model/ErrorMessages';
import { InputError } from '../model/InputError';
import { InputValue } from '../model/InputValue';

const touched = (inputValue: InputValue): boolean => inputValue.touched === true;

const notTouched = negate(touched);

const validateValue = (inputValue: InputValue, errorMessages: ErrorMessages | string): InputError[] =>
  inputValue.validators
    .map(validator => validator(inputValue.value, errorMessages))
    .filter(err => err != null) as InputError[];

const validate = (inputValue: InputValue, errorMessages: ErrorMessages | string): InputError[] => {
  inputValue.errors = validateValue(inputValue, errorMessages);
  return inputValue.errors;
};

const updateAndValidate = (
  value: string,
  inputValue: InputValue,
  errorMessages: ErrorMessages | string,
): InputValue => {
  const clone = { ...inputValue, value, touched: true };
  validate(clone, errorMessages);
  return clone;
};

export const InputService = {
  touched,
  notTouched,
  validateValue,
  updateAndValidate,
};
