import { SignOutService } from '@csp/common';
import { Hub } from '@aws-amplify/core';
import { useEffect, useState } from 'react';
import { CognitoContextProps } from '../context/CognitoContext';
import { CognitoHubCapsule } from '../model/CognitoHubCapsule';

export const useCognitoContextProvider = (logoutOnTokenRefreshFailure?: boolean): CognitoContextProps => {
  const [context, setContext] = useState<CognitoContextProps>({ isSignedIn: false });

  useEffect(() => {
    const authListener = ({ channel, payload: { event, data } }: CognitoHubCapsule): void => {
      if (channel === 'auth') {
        switch (event) {
          case 'signIn':
            setContext(prevState => (prevState.isSignedIn ? prevState : { ...prevState, isSignedIn: true }));
            break;
          case 'signOut':
            setContext(prevState => (prevState.isSignedIn ? prevState : { ...prevState, isSignedIn: false }));
            break;
          case 'customOAuthState':
            setContext(prevState => ({ ...prevState, customState: data }));
            break;
          case 'tokenRefresh_failure':
            if (logoutOnTokenRefreshFailure) {
              SignOutService.signOut();
            }
            break;
        }
      }
    };

    const unsubscribeCallback = Hub.listen('auth', authListener);

    return (): void => {
      unsubscribeCallback();
    };
  }, [logoutOnTokenRefreshFailure]);

  return context;
};
