/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DeviceSessionAggregateIncludeFieldV1 {
    COUNT = 'COUNT',
    MIN_SESSION_TIMESTAMP = 'MIN_SESSION_TIMESTAMP',
    MAX_SESSION_TIMESTAMP = 'MAX_SESSION_TIMESTAMP',
    MIN_SESSION_CREATED_AT = 'MIN_SESSION_CREATED_AT',
    MAX_SESSION_CREATED_AT = 'MAX_SESSION_CREATED_AT',
    MIN_EVENT_ID = 'MIN_EVENT_ID',
    MAX_EVENT_ID = 'MAX_EVENT_ID',
}