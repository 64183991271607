export enum DmdpUserStatusType {
  REGISTERED = 'REGISTERED',
  VERIFIED = 'VERIFIED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  WITHDRAWN = 'WITHDRAWN',
}

const VISIBLE_AND_INACTIVATION_STATUSES: DmdpUserStatusType[] = [
  DmdpUserStatusType.ACTIVE,
  DmdpUserStatusType.REGISTERED,
  DmdpUserStatusType.VERIFIED,
];

const isUserStatusVisibleAndInactivatable = (value: string): value is DmdpUserStatusType =>
  VISIBLE_AND_INACTIVATION_STATUSES.includes(value as DmdpUserStatusType);

const isDmdpUserStatus = (value: string): value is DmdpUserStatusType =>
  Object.values(DmdpUserStatusType).includes(value as DmdpUserStatusType);

export const DmdpUserStatusTypes = {
  isDmdpUserStatus,
  isUserStatusVisibleAndInactivatable,
};
