import { Callback } from '@csp/csp-common-model';
import { useCallback } from 'react';
import { To, useLocation, useNavigate } from 'react-router-dom';
import { CspPath } from '../../../CspPath';

type NavigateState = {
  navBackPath: CspPath;
};

type NavBackPath = CspPath | string;

type UseContextualNavBackHookResult = {
  contextualNavBackPath: CspPath;
  handleBackButtonClick: Callback;
  navigateWithContextualNavBack: (to: To, navBackPath?: NavBackPath, additionalState?: object) => void;
};

export const useContextualNavBack = (defaultNavBackPath?: NavBackPath): UseContextualNavBackHookResult => {
  const navigate = useNavigate();
  const location = useLocation();
  const contextualNavBackPath = (location?.state as NavigateState)?.navBackPath ?? defaultNavBackPath;

  const navigateWithContextualNavBack = useCallback(
    (to: To, navBackPath?: NavBackPath, additionalState?: object): void => {
      navigate(to, {
        state: {
          navBackPath: navBackPath ?? contextualNavBackPath,
          ...additionalState,
        },
      });
    },
    [contextualNavBackPath, navigate],
  );

  const handleBackButtonClick = useCallback((): void => {
    navigate(contextualNavBackPath);
  }, [contextualNavBackPath, navigate]);

  return {
    contextualNavBackPath,
    handleBackButtonClick,
    navigateWithContextualNavBack,
  };
};
