import { PatientStatusConfigV1, RtsmStatusMappingV1, StatusMappingV1 } from '@csp/config-schemas';
import {
  Maybe,
  Patient,
  PatientMedicationStatusType,
  PatientMedicationStatusTypes,
  PatientSkipStatusType,
  PatientStatusType,
  PatientStudyStatusType,
  PatientStudyStatusTypeValues,
  RtsmStatus,
  RtsmStatuses,
  RtsmStatusInfo,
  RtsmStatusMapping,
  RtsmStatusMappings,
  StateAssert,
  StudyDefinedStatusesConfig,
  StudyPatientStatusMappings,
  StudyStatusMapping,
  ZonedDateTime,
} from '@csp/csp-common-model';
import { sortBy, uniq } from 'lodash';
import { isDefined } from '@csp/csp-common-util';
import { PatientStatusRuleService } from '../service/PatientStatusRuleService';
import { PatientStatusService } from '../service/PatientStatusService';
import { StudyDefinedStatusesConfigMapper } from './StudyDefinedStatusesConfigMapper';

const EMPTY_STUDY_STATUS_MAPPING: StudyStatusMapping = {
  medicationStatus: PatientSkipStatusType.IGNORE,
  appStatus: PatientSkipStatusType.IGNORE,
};

const toStudyStatusMapping = (statusMappingV1: StatusMappingV1): StudyStatusMapping => ({
  medicationStatus: statusMappingV1.medicationStatus ?? PatientSkipStatusType.IGNORE,
  appStatus: statusMappingV1.appStatus ?? PatientSkipStatusType.IGNORE,
});

const toRtsmStatusMapping = (
  rtsmStatusMappingV1: RtsmStatusMappingV1,
  studyStatusMapping: StudyStatusMapping,
): RtsmStatusMapping => ({
  studyStatus: PatientStudyStatusTypeValues.isPatientStudyStatus(rtsmStatusMappingV1.studyStatus)
    ? rtsmStatusMappingV1.studyStatus
    : PatientSkipStatusType.IGNORE,
  studyStatusMapping,
  order: rtsmStatusMappingV1.order,
  ...toStudyStatusMapping(rtsmStatusMappingV1),
});

const toRtsmStatusMappings = (mappings: RtsmStatusMapping[]): RtsmStatusMappings => {
  if (mappings.length > 1) {
    StateAssert.isTrue(
      mappings.length === uniq(mappings.map(mapping => mapping.order).filter(isDefined)).length,
      'The "order" attribute must be set when having more than one RTSM status of same type configured',
    );
  }
  const orderedMappings = sortBy(mappings, mapping => mapping.order);

  return {
    orderedMappings,
  };
};

const EMPTY_RTSM_STATUS_MAPPING: RtsmStatusMappings = toRtsmStatusMappings([
  {
    studyStatus: PatientSkipStatusType.IGNORE,
    studyStatusMapping: EMPTY_STUDY_STATUS_MAPPING,
    medicationStatus: PatientSkipStatusType.IGNORE,
    appStatus: PatientSkipStatusType.IGNORE,
    order: undefined,
  },
]);

const getInitialAndSupportedStudyStatuses = (
  patientStatusConfigV1: PatientStatusConfigV1,
): PatientStudyStatusType[] => [
  ...(patientStatusConfigV1.allowedToEdit.studyStatus ?? []),
  ...(patientStatusConfigV1.initial?.studyStatus ? [patientStatusConfigV1.initial.studyStatus] : []),
];

const getInitialAndSupportedMedicationStatuses = (
  patientStatusConfigV1: PatientStatusConfigV1,
): PatientMedicationStatusType[] => [
  ...(patientStatusConfigV1.allowedToEdit.medicationStatus ?? []),
  ...(patientStatusConfigV1.initial?.medicationStatus ? [patientStatusConfigV1.initial.medicationStatus] : []),
];

const getManualPatientStudyStatusTransitions = (
  newStatus: PatientStudyStatusType,
  isAutoStatusUpdateEnabled: boolean,
  patientStatusConfigV1: PatientStatusConfigV1,
): PatientStudyStatusType[] => {
  // Collect each study status defined in config, to be mapped from an external.
  const externallySyncedStudyStatuses =
    patientStatusConfigV1.mappings?.rtsmStatus
      ?.map(rtsmStatus => rtsmStatus.studyStatus)
      .filter(PatientStudyStatusTypeValues.isPatientStudyStatus) ?? [];

  return PatientStatusRuleService.getManualPatientStudyStatusTransitions(
    newStatus,
    patientStatusConfigV1.allowedToEdit.studyStatus ?? [],
    isAutoStatusUpdateEnabled,
    externallySyncedStudyStatuses,
  );
};

const getInitialStudyStatusTimestamp = (
  patient: Patient,
  initialPatientStudyStatus: Maybe<PatientStudyStatusType>,
): Maybe<ZonedDateTime> => {
  if (initialPatientStudyStatus === PatientStudyStatusType.RANDOMIZED) {
    return patient.randomizedTimestamp;
  } else if (initialPatientStudyStatus === PatientStudyStatusType.SCREENED) {
    return patient.screenedTimestamp;
  } else {
    return undefined;
  }
};

const isPatientStudyStatusTransitionAllowed = (
  prevStatus: PatientStudyStatusType | undefined,
  newStatus: PatientStudyStatusType,
): boolean => {
  if (prevStatus && prevStatus !== PatientStudyStatusType.UNKNOWN) {
    const validPreviousStudyStatuses = PatientStatusRuleService.getValidPreviousPatientStudyStatuses(newStatus);
    return validPreviousStudyStatuses.includes(prevStatus);
  } else {
    return PatientStatusRuleService.isInitialStudyStatus(newStatus);
  }
};

const isPatientMedicationStatusTransitionAllowed = (
  prevStatus: PatientMedicationStatusType | undefined,
  newStatus: PatientMedicationStatusType,
): boolean => {
  if (prevStatus && prevStatus !== PatientMedicationStatusType.UNKNOWN) {
    const validPreviousStudyStatuses = PatientStatusRuleService.getValidPreviousMedicationStatuses(newStatus);
    return validPreviousStudyStatuses.includes(prevStatus);
  } else {
    return PatientStatusRuleService.isInitialMedicationStatus(newStatus);
  }
};

const getAllManualPatientMedicationStatusTransitions = (
  medicationStatus: PatientMedicationStatusType,
  patientStatusConfigV1: PatientStatusConfigV1,
): PatientMedicationStatusType[] =>
  PatientStatusRuleService.getPatientMedicationStatusTransitions(
    medicationStatus,
    patientStatusConfigV1.allowedToEdit.medicationStatus ?? [],
    patientStatusConfigV1.allowedTransitions?.medicationStatus,
  );

const statusExists = (
  statusType: string,
  statusValue: string,
  rtsmStatuses: RtsmStatus[],
  studyDefinedStatuses: StudyDefinedStatusesConfig,
): boolean => {
  if (statusType === PatientStatusType.RTSM) {
    return rtsmStatuses.includes(statusValue);
  } else {
    return (
      studyDefinedStatuses.isTypeAndValueConfigured(statusType, statusValue) ||
      PatientStatusService.isPatientStatus(statusType, statusValue)
    );
  }
};

const fromV1 = (patientStatusConfigV1: PatientStatusConfigV1): StudyPatientStatusMappings => {
  const supportedStudyStatuses = getInitialAndSupportedStudyStatuses(patientStatusConfigV1);
  const supportedMedicationStatuses = getInitialAndSupportedMedicationStatuses(patientStatusConfigV1);

  const studyStatusMappings = new Map<PatientStudyStatusType, StudyStatusMapping>();
  patientStatusConfigV1.mappings?.studyStatus?.forEach(studyStatusMappingV1 => {
    studyStatusMappings.set(studyStatusMappingV1.from, toStudyStatusMapping(studyStatusMappingV1));
    supportedStudyStatuses.push(studyStatusMappingV1.from);

    if (PatientMedicationStatusTypes.isPatientMedicationStatus(studyStatusMappingV1.medicationStatus)) {
      supportedMedicationStatuses.push(studyStatusMappingV1.medicationStatus);
    }
  });

  const rtsmStatusMappings = new Map<RtsmStatus, RtsmStatusMappings>();
  patientStatusConfigV1.mappings?.rtsmStatus?.forEach(rtsmStatusMappingV1 => {
    const studyStatus = rtsmStatusMappingV1.studyStatus;
    const studyStatusMapping: StudyStatusMapping = PatientStudyStatusTypeValues.isPatientStudyStatus(studyStatus)
      ? studyStatusMappings.get(studyStatus) ?? EMPTY_STUDY_STATUS_MAPPING
      : EMPTY_STUDY_STATUS_MAPPING;

    const from = rtsmStatusMappingV1.from;
    const currentMappings = rtsmStatusMappings.get(from)?.orderedMappings ?? [];
    rtsmStatusMappings.set(
      from,
      toRtsmStatusMappings([...currentMappings, toRtsmStatusMapping(rtsmStatusMappingV1, studyStatusMapping)]),
    );

    if (PatientStudyStatusTypeValues.isPatientStudyStatus(rtsmStatusMappingV1.studyStatus)) {
      supportedStudyStatuses.push(rtsmStatusMappingV1.studyStatus);
    }
    if (PatientMedicationStatusTypes.isPatientMedicationStatus(rtsmStatusMappingV1.medicationStatus)) {
      supportedMedicationStatuses.push(rtsmStatusMappingV1.medicationStatus);
    }
  });

  const initialPatientStudyStatus = patientStatusConfigV1.initial?.studyStatus;
  const initialPatientMedicationStatus = patientStatusConfigV1.initial?.medicationStatus;

  const getRtsmStatusMappings = (rtsmStatus: RtsmStatus): RtsmStatusMappings =>
    rtsmStatusMappings.get(rtsmStatus) ?? EMPTY_RTSM_STATUS_MAPPING;

  const getStudyStatusMapping = (studyStatus: PatientStudyStatusType): StudyStatusMapping =>
    studyStatusMappings.get(studyStatus) ?? EMPTY_STUDY_STATUS_MAPPING;

  const findRtsmStatusesMappedToStudyStatus = (
    rtsmStatuses: RtsmStatuses,
    studyStatus: PatientStudyStatusType,
  ): RtsmStatusInfo[] => {
    const allRtsmStatusInfos = rtsmStatuses.toListCurrentFirst();

    return allRtsmStatusInfos.filter(rtsmStatusInfo => {
      const rtsmStatusMapping = getRtsmStatusMappings(rtsmStatusInfo.rtsmStatus).orderedMappings.map(
        mapping => mapping.studyStatus,
      );
      return rtsmStatusMapping.includes(studyStatus);
    });
  };

  const getMappedRtsmStatuses = (): RtsmStatus[] => [...rtsmStatusMappings.keys()];

  const getNonFollowUpManualPatientMedicationStatusTransitions = (
    medicationStatus: PatientMedicationStatusType,
  ): PatientMedicationStatusType[] =>
    getAllManualPatientMedicationStatusTransitions(medicationStatus, patientStatusConfigV1).filter(
      medicationStatus => !PatientStatusRuleService.isFollowUpStatus(medicationStatus),
    );

  const getFollowUpManualPatientMedicationStatusTransitions = (
    medicationStatus: PatientMedicationStatusType,
  ): PatientMedicationStatusType[] =>
    getAllManualPatientMedicationStatusTransitions(medicationStatus, patientStatusConfigV1).filter(medicationStatus =>
      PatientStatusRuleService.isFollowUpStatus(medicationStatus),
    );

  const uniqueSupportedStudyStatuses = [...new Set(supportedStudyStatuses)].sort();
  const uniqueSupportedMedicationStatuses = [...new Set(supportedMedicationStatuses)].sort();

  const studyDefinedStatuses = StudyDefinedStatusesConfigMapper.fromV1(patientStatusConfigV1.studyDefined);

  return {
    initialPatientStudyStatus,
    initialPatientMedicationStatus,
    supportedStudyStatuses: uniqueSupportedStudyStatuses,
    supportedMedicationStatuses: uniqueSupportedMedicationStatuses,
    getRtsmStatusMappings,
    getStudyStatusMapping,
    getMappedRtsmStatuses,
    getManualPatientStudyStatusTransitions: (newStatus, isAutoStatusUpdateEnabled) =>
      getManualPatientStudyStatusTransitions(newStatus, isAutoStatusUpdateEnabled, patientStatusConfigV1),
    getFollowUpManualPatientMedicationStatusTransitions,
    getInitialStudyStatusTimestamp: patient => getInitialStudyStatusTimestamp(patient, initialPatientStudyStatus),
    isPatientStudyStatusTransitionAllowed,
    isPatientMedicationStatusTransitionAllowed,
    getNonFollowUpManualPatientMedicationStatusTransitions,
    findRtsmStatusesMappedToStudyStatus,
    studyDefinedStatuses,
    statusExists: (statusType, statusValue) =>
      statusExists(statusType, statusValue, getMappedRtsmStatuses(), studyDefinedStatuses),
  };
};

export const StudyPatientStatusMappingsMapper = {
  fromV1,

  // For test
  toRtsmStatusMappings,
};
