import { RefV1 } from '@csp/dmdp-api-common-dto';

export type AssessmentOutcomeRef = RefV1;

const from = ({ key, value }: RefV1): AssessmentOutcomeRef => ({
  key,
  value,
});

export const AssessmentOutcomeRef = {
  from,
};
