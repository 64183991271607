import { ComplianceConfigV1, ComplianceConfigV1Schema } from '@csp/config-schemas';
import { StudyComplianceConfig } from '@csp/csp-common-model';
import { JsonValidationService } from '@csp/csp-common-util';

const toComplianceConfig = (complianceConfigV1: ComplianceConfigV1): StudyComplianceConfig => {
  JsonValidationService.validateJsonNonStrict('ComplianceConfigV1.json', ComplianceConfigV1Schema, complianceConfigV1);

  return {
    patientAggregatedExternalReportUrl: complianceConfigV1.patientAggregatedCompliance.externalReportUrl,
    emailNotificationJobConfigs: complianceConfigV1.emailNotificationJobConfigs,
  };
};

export const ComplianceConfigService = {
  toComplianceConfig,
};
