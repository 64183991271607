import { ZoomClientId } from '@csp/csp-common-model';
import { DmdpHttpHeaderConst } from '../type/DmdpHttpHeaderConst';

const generateHeaders = (clientId: ZoomClientId): Record<string, string> => ({
  [DmdpHttpHeaderConst.X_CLIENT_ID]: clientId,
});

export const ZoomUtil = {
  generateHeaders,
};
