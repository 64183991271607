import { LearnMoreContentModel } from '../generated/types/LearnMoreContentModel';

const defaultLearnMoreContentMock: LearnMoreContentModel = {
  identifier: 'webHcpAccountOverviewGeneral',
  _content_type_uid: 'learn_more',
  client_application: { client_value: 'hcp-web' },
  learn_more: [
    {
      header_and_body: {
        header: '[M]Sample Title 1',
        body_text_md: '[M]This is an example of **Markdown** in the body for Sample Title 1.',
        metadata: {},
      },
    },
    {
      header_and_body: {
        header: '[M]Sample Title 2',
        body_text_md: '[M]This is an example of **Markdown** in the body for Sample Title 2.',
        metadata: {},
      },
    },
  ],
  title: 'Learn more',
  uid: '',
  metadata: {},
};

const create = (partial?: Partial<LearnMoreContentModel>): LearnMoreContentModel => ({
  ...defaultLearnMoreContentMock,
  ...partial,
});

export const LearnMoreContentModelMock = { create };
