import { HttpHeaderConst } from '@csp/dmdp-api-common-dto';
import { AxiosInstance, AxiosResponse } from 'axios';
import { createAxios, handleAxiosError } from '@csp/csp-common-axios';
import { IdpStateService } from './IdpStateService';

class AxiosIdpService {
  static instance: AxiosIdpService = new AxiosIdpService();
  axiosInstance: AxiosInstance;

  private constructor() {
    this.axiosInstance = this.idp();
  }

  private idp = (): AxiosInstance => {
    const idpAxios = createAxios();
    idpAxios.interceptors.request.use(
      async config => {
        const newConfig = { ...config };
        const idpToken = await IdpStateService.getOrRefreshIdpAuthorization();
        newConfig.headers[HttpHeaderConst.AUTHORIZATION] = String(idpToken);
        return newConfig;
      },
      error => handleAxiosError(error),
    );
    idpAxios.interceptors.response.use(
      (resp: AxiosResponse) => resp,
      error => handleAxiosError(error),
    );
    return idpAxios;
  };
}

export const axiosIdp = (): AxiosInstance => AxiosIdpService.instance.axiosInstance;
