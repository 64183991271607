/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ConfigurationEntryIncludeFieldV1 {
    EVENT_ID = 'EVENT_ID',
    AUDIT = 'AUDIT',
    ENTRY_CODE = 'ENTRY_CODE',
    DEFINITION_CODE = 'DEFINITION_CODE',
    DEFINITION_VERSION = 'DEFINITION_VERSION',
    DATA = 'DATA',
    DATA_RAW = 'DATA_RAW',
    META = 'META',
    CONTENT_HASH = 'CONTENT_HASH',
}