import { Maybe } from '@csp/csp-common-model';
import { noop } from 'lodash/fp';

type TimeChecker = {
  check: () => boolean;
  reset: () => void;
  setInactive: () => void;
  initiate: (duration: number, time?: number) => void;
  getLastActiveTime: () => number;
};

const now = (): number => new Date().valueOf();

export type TimeCheckerInput = {
  get: () => Maybe<number>;
  set: (time: number) => void;
};

const nullInput: TimeCheckerInput = {
  get: () => undefined,
  set: noop,
};

export const timeChecker = ({ get, set }: TimeCheckerInput = nullInput): TimeChecker => {
  let duration = 0;
  let lastActiveTime: number;
  let active = true;

  return {
    check: (): boolean => {
      lastActiveTime = get() || lastActiveTime;
      const diff = now() - lastActiveTime;
      return active && diff > duration;
    },
    setInactive: (): void => {
      active = false;
    },
    reset: (): void => {
      lastActiveTime = now();
      set(lastActiveTime);
      active = true;
    },
    initiate: (dur: number, time?: number): void => {
      duration = dur;
      lastActiveTime = time || get() || new Date().valueOf();
      set(lastActiveTime);
    },
    getLastActiveTime: (): number => lastActiveTime,
  };
};
