import { fromTimezoneStr } from '@csp/csp-common-date-util';
import {
  PatientAppStatusType,
  PatientFinalContactStatusType,
  PatientMedicationStatusType,
  PatientStatus,
  PatientStudyStatusType,
  PatientVitalStatusType,
  RtsmStatus,
} from '@csp/csp-common-model';
import { createMockCreator } from '@csp/test-common-utils';

const studyStatusMock: PatientStatus<PatientStudyStatusType> = {
  status: PatientStudyStatusType.RANDOMIZED,
  timestamp: fromTimezoneStr('2021-11-01T12:00:00+00:00[UTC]'),
};

const medicationStatusMock: PatientStatus<PatientMedicationStatusType> = {
  status: PatientMedicationStatusType.NO_TREATMENT,
  timestamp: fromTimezoneStr('2021-11-01T12:00:00+00:00[UTC]'),
};

const appStatusMock: PatientStatus<PatientAppStatusType> = {
  status: PatientAppStatusType.ACTIVE,
  timestamp: fromTimezoneStr('2021-11-01T12:00:00+00:00[UTC]'),
};

const rtsmStatusMock: PatientStatus<RtsmStatus> = {
  status: 'ENT_RAND',
  timestamp: fromTimezoneStr('2021-11-01T12:00:00+00:00[UTC]'),
};

const finalContactStatusTypeMock: PatientStatus<PatientFinalContactStatusType> = {
  status: PatientFinalContactStatusType.CONTACT_COMPLETED,
  timestamp: fromTimezoneStr('2021-11-01T12:00:00+00:00[UTC]'),
};

const vitalStatusTypeMock: PatientStatus<PatientVitalStatusType> = {
  status: PatientVitalStatusType.ALIVE,
  timestamp: fromTimezoneStr('2021-11-01T12:00:00+00:00[UTC]'),
};

export const PatientStatusMock = {
  createStudyStatusMock: createMockCreator(studyStatusMock),
  createMedicationStatusMock: createMockCreator(medicationStatusMock),
  createAppStatusMock: createMockCreator(appStatusMock),
  createRtsmStatusMock: createMockCreator(rtsmStatusMock),
  createVitalStatusTypeMock: createMockCreator(vitalStatusTypeMock),
  createFinalContactStatusTypeMock: createMockCreator(finalContactStatusTypeMock),
};
