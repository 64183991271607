/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ConsentArtifactCriteriaFieldV1 {
    ARTIFACT_CODE = 'ARTIFACT_CODE',
    ARTIFACT_ID = 'ARTIFACT_ID',
    NAME = 'NAME',
    TYPE = 'TYPE',
    TAGS = 'TAGS',
    DOCUMENTS = 'DOCUMENTS',
    EVENT_ID = 'EVENT_ID',
}