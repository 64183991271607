import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { hoursToSeconds, secondsToMillis } from '@csp/csp-common-date-util';
import { useIdleTimer } from '../../../common/hooks/useIdleTimer';
import { getUserIdSelector } from '../ducks/principalContextSelectors';
import { CspPath } from '../../../CspPath';
import { getSelectedStudyIdSelector } from '../../studies/ducks/studiesSelectors';
//TODO:[Saman M]: remove comment out codes when @zoomus/websdk package is installed
//import { useZoomContext } from '../../video-meeting/zoom/context/hook/useZoomContext';
import { useAutomaticLogoutContext } from '../context/hook/useAutomaticLogoutContext';
import { useAuthContext } from '../../auth/context/hook/useAuthContext';
import { useSignOut } from './useSignOut';

const IDLE_TIME_BEFORE_LOGOUT = secondsToMillis(hoursToSeconds(1));

export const useAutomaticLogout = (): void => {
  const { isAuthenticated } = useAuthContext();
  const userId = useSelector(getUserIdSelector);
  const { signOut } = useSignOut();
  const navigate = useNavigate();
  const selectedStudyId = useSelector(getSelectedStudyIdSelector);
  const { onPreAutomaticLogout } = useAutomaticLogoutContext();
  //const { endMeeting } = useZoomContext();

  const memoizedSignOutAndReload = useCallback(async () => {
    if (isAuthenticated) {
      onPreAutomaticLogout({
        userId,
        path: window.location.pathname,
        studyId: selectedStudyId,
      });
      // await endMeeting();
      await signOut();
      navigate(CspPath.LOGIN);
    }
  }, [isAuthenticated, onPreAutomaticLogout, userId, selectedStudyId, signOut /*endMeeting ,*/, navigate]);

  useIdleTimer(IDLE_TIME_BEFORE_LOGOUT, memoizedSignOutAndReload);
};
