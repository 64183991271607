import { Drawer as MuiDrawer, DrawerProps } from '@mui/material';
import { FC, ReactNode } from 'react';
import { LoadingOverlay } from '../../feedback/progress/LoadingOverlay';
import { AlertError } from '../../lab/alert/AlertError';
import { SxProps } from '../../../common/system/SxProps';
import { Box } from '../../layout/box/Box';
import { DrawerHeader } from './DrawerHeader';
import { DrawerHeaderProps } from './model/DrawerHeaderProps';

type Props = DrawerHeaderProps &
  DrawerProps & {
    actions?: ReactNode;
    id?: string;
    isLoading?: boolean;
    fullscreen?: boolean;
    error?: string;
  };

const smUpWidth = '48rem';

export const TemporaryDrawer: FC<Props> = ({
  header,
  handleClose,
  actions,
  anchor = 'right',
  id,
  isLoading,
  fullscreen,
  children,
  error,
  ...props
}) => {
  const sxDrawer: SxProps = {
    '.MuiDrawer-paperAnchorRight': {
      overflowX: 'hidden',
      width: { xs: '100%', md: smUpWidth },
    },
  };

  const sxBox: SxProps = {
    minHeight: { xs: 'calc(100vh - 5.7rem)', sm: 'unset' },
    height: { xs: 'auto', sm: 'unset' },
    justifyContent: 'center',
    position: { xs: 'relative', sm: 'absolute' },
    boxSizing: 'border-box',
    width: '100%',
    px: { xs: 3, sm: 14 },
    py: 1,
    ':after': {
      content: '""',
      display: 'block',
      height: '10rem',
    },
  };

  return (
    <MuiDrawer id={id} anchor={anchor} sx={sxDrawer} {...props}>
      <>
        <DrawerHeader header={header} handleClose={handleClose} />
        {isLoading && <LoadingOverlay width={smUpWidth} isLoading={isLoading} />}
        <Box mt={2}>
          {fullscreen ? (
            <>
              {children}
              {actions}
            </>
          ) : (
            <Box sx={sxBox}>
              {error && <AlertError fullWidth message={error} mb={2.5} />}
              {children}
              {actions}
            </Box>
          )}
        </Box>
      </>
    </MuiDrawer>
  );
};
