import { HttpStatus } from '@csp/csp-common-axios';
import { Maybe } from '@csp/csp-common-model';
import { ContentPackageType } from '../../model/ContentPackageType';
import { getContentVersion } from '../../utils/configUtil';

export class ContentDeliveryClientError extends Error {
  static isContentDeliveryClientError(error: unknown): error is ContentDeliveryClientError {
    return error instanceof ContentDeliveryClientError;
  }

  static isErrorOfType(error: unknown, httpStatus: HttpStatus): error is ContentDeliveryClientError {
    return this.isContentDeliveryClientError(error) && error.httpStatus === httpStatus;
  }

  static notFound(packageType: ContentPackageType, locale: Lowercase<string>): ContentDeliveryClientError {
    return new ContentDeliveryClientError(
      HttpStatus.NOT_FOUND,
      `Failed to fetch content package '${packageType}' using locale '${locale}' for release '${getContentVersion(
        packageType,
      )}'.`,
    );
  }

  static isNotFound(error: unknown): error is ContentDeliveryClientError {
    return this.isErrorOfType(error, HttpStatus.NOT_FOUND);
  }

  protected httpStatus: HttpStatus;

  constructor(httpStatus: HttpStatus, message: Maybe<string>) {
    super(message);

    this.httpStatus = httpStatus;

    Object.setPrototypeOf(this, ContentDeliveryClientError.prototype);
  }
}
