import {
  RawAssessmentConsequenceActivityCardConfigV1,
  RawAssessmentConsequenceActivityCardConfigV1Schema,
} from '@csp/config-schemas';
import { JsonValidationService } from '@csp/csp-common-util';

const validateAssessmentConsequenceActivityCardConfig = (
  config: RawAssessmentConsequenceActivityCardConfigV1,
): void => {
  JsonValidationService.validateJsonNonStrict(
    'AssessmentConsequenceActivityCard config V1',
    RawAssessmentConsequenceActivityCardConfigV1Schema,
    config,
  );
};

export const AssessmentConsequenceActivityCardValidationService = {
  validateAssessmentConsequenceActivityCardConfig,
};
