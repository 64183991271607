import { IdpState } from '@csp/dmdp-api-common-dto';
import { getMessage } from '@csp/csp-common-util';

const state: {
  idpState?: IdpState;
} = {};

const listeners: {
  onSet: Set<() => void>;
  onSignOut: Set<() => void>;
} = {
  onSet: new Set(),
  onSignOut: new Set(),
};

const callListener = (listener: () => void, name: string): void => {
  try {
    listener();
  } catch (e) {
    console.log(`Failed in ${name} callback: ${getMessage(e)}`);
  }
};

const getIdpState = (): IdpState | undefined => state.idpState;

const isSignedIn = (): boolean => !!getIdpState();

const getOrRefreshIdpAuthorization = async (): Promise<string | undefined> => getIdpState()?.getAuthorization();

const setIdpState = (idpState: IdpState): void => {
  state.idpState = idpState;
  listeners.onSet.forEach(listener => callListener(listener, 'onSet'));
};

const signOut = async (): Promise<void> => {
  const signOut = getIdpState()?.signOut;
  if (signOut) {
    await signOut();
  }
  state.idpState = undefined;
  listeners.onSignOut.forEach(listener => callListener(listener, 'onSignOut'));
};

const addOnSetListener = (listener: () => void): void => {
  listeners.onSet.add(listener);
};

const addOnSignOutListener = (listener: () => void): void => {
  listeners.onSignOut.add(listener);
};

const removeOnSetListener = (listener: () => void): void => {
  listeners.onSet.delete(listener);
};

const removeOnSignOutListener = (listener: () => void): void => {
  listeners.onSignOut.delete(listener);
};

export const IdpStateService = {
  isSignedIn,
  setIdpState,
  getIdpState,
  getOrRefreshIdpAuthorization,
  signOut,
  addOnSetListener,
  addOnSignOutListener,
  removeOnSetListener,
  removeOnSignOutListener,
};
