import styled from '@emotion/styled';
import { BoxProps } from '@mui/material/Box/Box';
import { FC, ReactNode } from 'react';
import { OnClick } from '../../../common/model/EventModels';
import { BrandedIcon } from '../../brandedIcon/BrandedIcon';
import { BrandedIconProps } from '../../brandedIcon/model/BrandedIconProps';
import { Button } from '../../buttons/Button';
import { Box } from '../../layout/box/Box';
import { Link } from '../../navigation/link/Link';
import { TextProps } from '../../typography/model/TextProps';
import { Body2, Body3 } from '../../typography/TextStyles';
import { LabelWidth } from './model/LabelWidth';

type actionVariant = 'contained' | 'outlined' | 'link';

type ListItemIconActionProps = {
  label?: string;
  labelProps?: TextProps;
  listItemProps?: BoxProps;
  iconProps?: BrandedIconProps;
  action?: ReactNode;
  actionVariant?: actionVariant;
  onClick?: OnClick;
  subtitle?: string;
  subtitleProps?: TextProps;
  labelWidth?: LabelWidth | string;
  actionTopDisabled?: boolean;
};

type SecondaryActionProps = { actionTopDisabled: boolean | undefined };

const ListItemAction = styled.div<SecondaryActionProps>`
  ${({ actionTopDisabled, theme }): string => `
  top: 50%;
  right: 0;
  position: absolute;
  transform: translateY(-50%);
  ${theme.breakpoints.down('xs')} {
    top: ${actionTopDisabled ? 'unset' : '50%'};
    }
  `}
`;

const defaultMaxWidth = { xs: '10rem', md: '100%' };

export const ListItemIconAction: FC<ListItemIconActionProps> = ({
  listItemProps,
  iconProps,
  label,
  actionVariant,
  action,
  onClick,
  subtitle,
  labelWidth,
  actionTopDisabled,
  labelProps,
  subtitleProps,
}) => (
  <Box display="inline-flex" alignItems="center" width="100%" position="relative" {...listItemProps}>
    {iconProps && <BrandedIcon mr={1} minWidth={0} {...iconProps} />}
    <Box flex="1 1 auto" maxWidth={labelWidth || defaultMaxWidth}>
      <Body2 fontWeight={500} {...labelProps}>
        {label}
      </Body2>
      <Body3 color="textSecondary" noWrap {...subtitleProps}>
        {subtitle}
      </Body3>
    </Box>

    {action && (
      <ListItemAction actionTopDisabled={actionTopDisabled}>
        {actionVariant === 'link' ? (
          <Link onClick={onClick}>{action}</Link>
        ) : (
          <Button variant={actionVariant} color="primary" size="small" onClick={onClick}>
            {action}
          </Button>
        )}
      </ListItemAction>
    )}
  </Box>
);
