import { FC, PropsWithChildren } from 'react';
import { BioSampleCodeInputContext } from './BioSampleCodeInputContext';
import { useBioSampleCodeInputContextProvider } from './useBioSampleCodeInputContextProvider';

type Props = PropsWithChildren;

export const BioSampleCodeInputContextProvider: FC<Props> = ({ children }) => (
  <BioSampleCodeInputContext.Provider value={useBioSampleCodeInputContextProvider()}>
    {children}
  </BioSampleCodeInputContext.Provider>
);
