import { getContentstackAudienceRole, supportUserRoles } from '@csp/csp-common-content-utils';
import { SystemLinkContentModel } from '@csp/csp-web-content-definitions';
import { RoleType } from '@csp/dmdp-api-common-dto';
import { toUpper } from 'lodash';
import { useMemo } from 'react';
import { StudyContentService } from '../../../common/service/StudyContentService';
import { useCurrentUser } from '../../security/hooks/useCurrentUser';
import { SystemLink } from '../model/SystemLink';

const filterSystemLinksByCountryAndRoles = (
  systemLinks: SystemLinkContentModel[],
  userCountryCode: string,
  userRoles: RoleType[],
): SystemLinkContentModel[] =>
  systemLinks.filter(
    link =>
      link.country.country?.some(countryCode => toUpper(userCountryCode) === countryCode) &&
      link?.role.some(linkRole => getContentstackAudienceRole(userRoles).includes(linkRole)),
  );

// If support user (SSTM or GSTM), add the intended target role (HCP, CRA or both) as suffix
const toSystemNameWithMaybeRoleSuffix = (link: SystemLinkContentModel, userRoles: RoleType[]): string =>
  userRoles.some(role => supportUserRoles.includes(role))
    ? `${link.link[0]?.title} (${link.role.sort().join(', ')})`
    : link.link[0]?.title ?? '';

const toSimplifiedSystemLink = (link: SystemLinkContentModel, userRoles: RoleType[]): SystemLink => ({
  systemName: toSystemNameWithMaybeRoleSuffix(link, userRoles),
  description: link.link[0]?.description ?? '',
  url: link.link[0]?.url ?? '',
});

export const useLocalizedSystemLinksByRole = (): SystemLink[] => {
  const systemLinks = StudyContentService.getSystemLinks();
  const user = useCurrentUser();
  const userCountryCode = user.localization.countryCode;
  const userRoles = user.roles;
  const filteredSystemLinks = useMemo(() => {
    if (userCountryCode && Array.isArray(systemLinks) && userRoles) {
      return filterSystemLinksByCountryAndRoles(systemLinks, userCountryCode, userRoles).map(
        (systemLink): SystemLink => toSimplifiedSystemLink(systemLink, userRoles),
      );
    } else {
      return [];
    }
  }, [systemLinks, userCountryCode, userRoles]);

  return filteredSystemLinks;
};
