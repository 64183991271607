import { Maybe, ZonedDateTime } from '@csp/csp-common-model';

const SIX_HOURS_MILLIS = 21_600_000;

const isVisitStartInFuture = (startTimestamp: Maybe<ZonedDateTime>): boolean => {
  const endTimestamp = !!startTimestamp && startTimestamp.unixTimeMillis + SIX_HOURS_MILLIS;
  return endTimestamp > Date.now();
};

export const BookedVisitUtil = {
  isVisitStartInFuture,
  visitPassedPeriodMillis: SIX_HOURS_MILLIS,
};
