import { EnumUtil } from '@csp/csp-common-enum-util';
import { Maybe, VisitFormatType, VisitLocationType, VisitLocationTypes } from '@csp/csp-common-model';
import { isDefined } from '@csp/csp-common-util';
import { AppointmentFormatV1, AppointmentRequestDetailsV1 } from '@csp/dmdp-api-appointment-dto';

// The formats supported by Unify. Add to this list when new supported formats are added.
const SUPPORTED_FORMATS: VisitFormatType[] = [
  VisitFormatType.PHONE,
  VisitFormatType.IN_PERSON,
  VisitFormatType.VIDEO,
  VisitFormatType.HOME_VISIT,
];

export type VisitRequestDetails = {
  visitLocationTypes: VisitLocationType[];
  visitKind?: string; // Study specific visit type. Example: 'SCREENING', 'CHECK_IN', 'RANDOMIZATION'. Used for mapping content
};

const toVisitFormat = (appointmentFormatV1: AppointmentFormatV1): VisitFormatType | undefined => {
  const visitFormat: Maybe<VisitFormatType> = EnumUtil.fromString(appointmentFormatV1, VisitFormatType);

  return visitFormat && SUPPORTED_FORMATS.includes(visitFormat) ? visitFormat : undefined;
};

const from = (appointmentRequestDetailsV1: AppointmentRequestDetailsV1): VisitRequestDetails => {
  const visitFormats: VisitFormatType[] =
    appointmentRequestDetailsV1.appointmentFormats?.map(toVisitFormat).filter(isDefined) || [];
  const visitLocationTypes = visitFormats.map(VisitLocationTypes.from).filter(isDefined);

  return {
    visitLocationTypes,
    visitKind: appointmentRequestDetailsV1.type,
  };
};

export const VisitRequestDetails = {
  from,
};
