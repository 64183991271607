import { ConsentContextState } from '../model/ConsentContextState';
import { useConsentApprovals } from './useConsentApprovals';

export const useConsentContextProvider = (): ConsentContextState => {
  const approvals = useConsentApprovals();

  return {
    ...approvals,
  };
};
