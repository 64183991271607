import { formatInTimeZone as formatInTimeZoneDateFnsTz, utcToZonedTime } from 'date-fns-tz';
import {
  DateAndTimeNumber,
  DateNumber,
  DisplayDateTimeStr,
  DisplayZonedDateTimeStr,
  IsoDateStr,
  Maybe,
  TimeStr,
  TimeZoneId,
  ZonedDateTime,
  ZonedDateTimeStr,
} from '@csp/csp-common-model';
import { IsoFormattedStr } from '@csp/dto';
import { startOfDay } from 'date-fns';
import { ZonedDateTimeFormat } from '../model/ZonedDateTimeFormat';
import { getTimeZoneId } from './DateUtil';

/**
 * Converts ZonedDate object to a formatted string in a given timezone using date-fns-tz.
 * @private
 * @param {ZonedDateTime} zonedDateTime - ZonedDateTime to format
 * @param {string} format - Date format string e.g. 'yyyy-MM-dd'
 * @param {TimeZoneId} [zone] - Optional IANA timezone to use for formatting. Will use the currently set timezone if not provided.
 */
const formatInTimeZoneWithDateFnsTz = (zonedDateTime: ZonedDateTime, format: string, zone = getTimeZoneId()): string =>
  formatInTimeZoneDateFnsTz(zonedDateTime.unixTimeMillis, zone, format);

/**
 * Converts ZonedDateTime object to a formatted string.
 * @param {ZonedDateTime} zonedDateTime - ZonedDateTime to format
 * @param {string} format - Date format string e.g. 'yyyy-MM-dd'
 * @param {TimeZoneId} [zone] - Optional IANA timezone to use for formatting. Will use the system timezone if not provided.
 * @returns {string} - String representation of the ZonedDate object
 */
const format = (zonedDateTime: ZonedDateTime, format: string, zone: TimeZoneId = getTimeZoneId()): string =>
  formatInTimeZoneWithDateFnsTz(zonedDateTime, format, zone);

/**
 * Converts ZonedDateTime object to an ISO datetime string in the system timezone.
 * @param {ZonedDateTime} zonedDateTime - ZonedDateTime to format
 * @returns {IsoFormattedStr} - ISO 8601 datetime format e.g. "2021-01-01T04:00:00.000+04:00"
 */
const toIsoFormattedString = (zonedDateTime: ZonedDateTime): IsoFormattedStr =>
  format(zonedDateTime, ZonedDateTimeFormat.ISO_8601.DATE_TIME_FORMAT);

/**
 * Converts ZonedDateTime object to a string in the system timezone.
 * @param {ZonedDateTime} zonedDateTime - ZonedDateTime to format
 * @param {TimeZoneId} [zone] - Optional IANA timezone to use for formatting.
 * @returns {ZonedDateTimeStr} - Extended ISO 8601 datetime format with timezone e.g. e.g. "2020-12-31T19:00:00.000+02:00[Europe/Stockholm]"
 */
const toZonedDateTimeString = (zonedDateTime: ZonedDateTime, zone: TimeZoneId = getTimeZoneId()): ZonedDateTimeStr =>
  `${toIsoFormattedString(zonedDateTime)}[${zone}]`;

/**
 * Converts ZonedDateTime object to an ISO date string in the system timezone.
 * @param {ZonedDateTime} zonedDateTime - ZonedDateTime to format
 * @return {IsoDateStr} - yyyy-MM-dd as string e.g. "2021-01-01"
 */
const toIsoDateString = (zonedDateTime: ZonedDateTime): IsoDateStr =>
  format(zonedDateTime, ZonedDateTimeFormat.ISO_8601.DATE_FORMAT);

/**
 * Converts ZonedDateTime object to a time string in the system timezone.
 * @param {ZonedDateTime} zonedDateTime - ZonedDateTime to format
 * @return "HH:mm" formatted time e.g. "13:30"
 */
const toTimeString = (zonedDateTime: ZonedDateTime): TimeStr =>
  format(zonedDateTime, ZonedDateTimeFormat.UNIFY.TIME_FORMAT);

/**
 * Converts ZonedDateTime object to a date time string in the system timezone.
 * @param {ZonedDateTime} zonedDateTime - ZonedDateTime to format
 * @param {TimeZoneId} [zone] - Optional IANA timezone to use for formatting.
 * @return "yyyy-MM-dd HH:mm" formatted time e.g. "2020-01-28 13:30"
 */
const toDisplayDateTimeString = (
  zonedDateTime: ZonedDateTime,
  zone: TimeZoneId = getTimeZoneId(),
): DisplayDateTimeStr => format(zonedDateTime, ZonedDateTimeFormat.UNIFY.DATE_TIME_FORMAT, zone);

/**
 * Converts ZonedDateTime object to unify datetime string in the given timezone.
 * @param {ZonedDateTime} zonedDateTime - ZonedDateTime to format
 * @returns yyyy-MM-dd HH:mm" formatted time followed by zone ID e.g. "2020-12-31 19:00 UTC"
 */
const toDisplayZonedDateTimeStringInGivenTimeZone = (zonedDateTime: ZonedDateTime): DisplayZonedDateTimeStr =>
  `${format(zonedDateTime, ZonedDateTimeFormat.UNIFY.DATE_TIME_FORMAT, zonedDateTime.zone)} ${zonedDateTime.zone}`;

/**
 * Converts an ISO 8601 date string to a number (sortable)  in the system timezone.
 * @param {ZonedDateTime} zonedDateTime - ZonedDateTime to format
 * @return {DateNumber} - YYYYMMDD as number e.g. 20210101
 */
const toDateNumber = (zonedDateTime: ZonedDateTime): DateNumber => Number.parseInt(format(zonedDateTime, 'yyyyMMdd'));

/**
 * Converts an ISO 8601 date string to a number including hours and minutes (sortable) in the system timezone
 * @param {ZonedDateTime} zonedDateTime - ZonedDateTime to format
 * @return {DateAndTimeNumber} - YYYYMMDDHHMM as number e.g. 202101011200
 */
const toDateAndTimeNumber = (zonedDateTime: ZonedDateTime): DateAndTimeNumber =>
  Number.parseInt(format(zonedDateTime, 'yyyyMMddHHmm'));

/**
 * Converts ZonedDateTime object to an ISO date string in the system timezone.
 * @param {ZonedDateTime} zonedDateTime - ZonedDateTime to format
 * @return {Date} - JS Date with time set to 00:00
 */
const toStartOfDayDate = (zonedDateTime: ZonedDateTime): Date =>
  startOfDay(utcToZonedTime(zonedDateTime.unixTimeMillis, zonedDateTime.zone));

/**
 * Formats a ZonedDateTime's time zone as an IANA time zone string or returns `undefined`.
 * Example: Europe/Stockholm
 *
 * @param {ZonedDateTime} zonedDateTime - The ZonedDateTime object to format the time zone from.
 * @returns {string | undefined} The IANA time zone string or `undefined` if not applicable.
 */
const formatIanaTimeZoneString = (zonedDateTime: ZonedDateTime): Maybe<string> => {
  // Target zone is UTC or Etc/UTC or string that contains UTC...
  const isUtcString = zonedDateTime.zone.toLowerCase().includes('UTC'.toLowerCase());
  // Target zone is UTC offset like -04:00 or +03:00
  const isOffsetString = /[+-]([01]\d|2[0-4])(:?[0-5]\d)?/.test(zonedDateTime.zone);

  // If the target zone is NOT utc string and NOT offset string, return it
  if (!isUtcString && !isOffsetString) {
    return zonedDateTime.zone;
  }

  return undefined;
};

/**
 * Formats a ZonedDateTime's UTC offset as a string in ISO 8601 format.
 * Example: UTC+01:00
 *
 * @param {ZonedDateTime} zonedDateTime - The ZonedDateTime object to format the UTC offset from.
 * @returns {string} The formatted UTC offset string.
 */
const formatUtcOffsetString = (zonedDateTime: ZonedDateTime): string =>
  `UTC${ZonedDateTimeFormatter.format(zonedDateTime, ZonedDateTimeFormat.ISO_8601.UTC_OFFSET, zonedDateTime.zone)}`;

/**
 * Formats a ZonedDateTime's time zone and UTC offset as a display string.
 * Example: UTC+01:00 • Europe/Stockholm
 *
 * @param {ZonedDateTime} zonedDateTime - The ZonedDateTime object to format the time zone and UTC offset.
 * @returns {string} The formatted display string containing UTC offset and IANA time zone (if applicable).
 */
const formatTimezoneDisplayString = (zonedDateTime: ZonedDateTime): string => {
  const SEPARATOR = ' • ';
  const utcOffsetString = formatUtcOffsetString(zonedDateTime);
  const ianaTimeZoneString = formatIanaTimeZoneString(zonedDateTime);
  return [utcOffsetString, ianaTimeZoneString].filter(Boolean).join(SEPARATOR);
};

export const ZonedDateTimeFormatter = {
  format,
  toIsoFormattedString,
  toZonedDateTimeString,
  toIsoDateString,
  toTimeString,
  toDisplayDateTimeString,
  toDateNumber,
  toDateAndTimeNumber,
  toStartOfDayDate,
  toDisplayZonedDateTimeStringInGivenTimeZone,
  formatIanaTimeZoneString,
  formatUtcOffsetString,
  formatTimezoneDisplayString,
};
