const DC_REF_PREFIX = 'DC';

/**
 * Generates a unique identifier based on the input (ecode) and the current time.
 * @param ecode - Patient eCode.
 * @returns A unique 8 character long string in the format "DCxxxxxx".
 */
export const generateDcRef = (ecode: string): string => {
  // Remove E character from ecode.
  const truncatedEcode = ecode.slice(1);
  // Generate hash values for the truncatedEcode and current time.
  const hash = hashCode(truncatedEcode);
  const timeHash = hashCode(Date.now().toString());
  // Combine the hashes to create a unique identifier.
  const finalHash = Math.abs((hash + timeHash) % 1000000);

  // Generate formatted final string, padded with leading zeros.
  return DC_REF_PREFIX + finalHash.toString().padStart(6, '0');
};

/**
 * Generates a hash code for a given string using multiplication.
 * @param str - Input string to be converted to a hash code.
 * @returns A 32-bit signed integer hash code.
 */
const hashCode = (str: string): number => {
  let hash = 0;
  let chr: number;

  // Iterate through each character of the input string.
  for (let i = 0; i < str.length; i++) {
    // Get the character's Unicode (char) code value.
    chr = str.charCodeAt(i);
    // Compute a unique hash by multiplying the existing hash by 31 and adding the character code.
    hash = (hash * 31 + chr) | 0;
  }

  // Return the computed hash code as a 32-bit signed integer.
  return hash;
};
