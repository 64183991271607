import { ContentEnvironment, RestOptions } from '@csp/csp-common-model';
import { ContentstackConfig } from '../config/model/ContentstackConfig';
import { ContentMode } from '../model/ContentMode';
import { getAllowContentPreview, getForceContentPreview } from './configUtil';
import { getContentstackConfigV1 } from './getContentstackConfigV1';

export type PreviewModeConfig = {
  previewModeEnabled: boolean;
  contentEnvironment?: ContentEnvironment;
};

export const getPreviewConfig = async (
  apiOptions: RestOptions,
  config?: ContentstackConfig,
): Promise<PreviewModeConfig> => {
  const allowContentPreview = getAllowContentPreview(config);
  const forceContentPreview = getForceContentPreview(config);

  if (!allowContentPreview) {
    return { previewModeEnabled: false };
  }

  const previewConfig = await getContentstackConfigV1(apiOptions);

  if (!previewConfig || !previewConfig.mode) {
    return { previewModeEnabled: forceContentPreview };
  } else {
    return {
      previewModeEnabled: previewConfig.mode === ContentMode.PREVIEW || forceContentPreview,
      contentEnvironment: previewConfig.contentEnvironment as ContentEnvironment,
    };
  }
};
