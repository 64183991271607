import { CustomStatusService } from '@csp/csp-common-custom-status';
import { Country, CustomStatuses, Maybe, Org, Site, StateAssert, StudyOrg } from '@csp/csp-common-model';
import { isDefined } from '@csp/csp-common-util';
import { OrgType, OrgV1 } from '@csp/dmdp-api-org-dto';
import { first, groupBy } from 'lodash/fp';
import { CountryMapper } from './CountryMapper';
import { OrgMapper } from './OrgMapper';
import { SiteMapper } from './SiteMapper';

const groupByType = groupBy<OrgV1>(o => o.type);

const fromOrgV1s = (orgs: OrgV1[]): StudyOrg => {
  const orgV1sByType = groupByType(orgs);

  const sites = orgV1sByType[OrgType.PRACTICE]?.map(orgV1 => SiteMapper.from(orgV1)) ?? [];
  const siteIds = sites.map(({ orgId }) => orgId);

  const countries = orgV1sByType[OrgType.COUNTRY]?.map(orgV1 => CountryMapper.fromOrgV1(orgV1)) ?? [];
  const countryIds = countries.map(({ orgId }) => orgId);
  const firstCountryOrg = first(countries);

  const studyOrgV1 = orgV1sByType[OrgType.TREE]?.[0];
  StateAssert.notNull(studyOrgV1, 'Need to provide 1 TREE node to create a StudyOrg');
  const studyOrg = OrgMapper.fromOrgV1(studyOrgV1);

  const getSiteById = (siteId: string): Maybe<Site> => sites.find(({ orgId }) => orgId === siteId);

  const getSiteByCtmsId = (ctmsId: string): Maybe<Site> => sites.find(site => site.ctmsId === ctmsId);

  const getCountryForSite = (site: Site): Maybe<Country> => {
    const matchedCountries = countries.filter(country => site.isChildOf(country.orgId));
    StateAssert.isFalse(matchedCountries.length > 1, 'Site is not expected to belong to more than one country');
    return matchedCountries.length === 1 ? matchedCountries[0] : undefined;
  };

  const getCountryBySiteId = (siteId: string): Maybe<Country> => {
    const site = getSiteById(siteId);
    return site ? getCountryForSite(site) : undefined;
  };

  const getOrgTreeForSite = (siteId: string): Org[] => {
    const site = getSiteById(siteId);
    const country = getCountryBySiteId(siteId);
    return [site, country, studyOrg].filter(isDefined);
  };

  const getMergedOrgCustomStatuses = (siteId: string): CustomStatuses => {
    const orgs = getOrgTreeForSite(siteId);
    return CustomStatusService.getMergedCustomStatusesFromOrgs(orgs);
  };

  return {
    ...studyOrg,
    countries,
    countryIds,
    firstCountryOrg,
    sites,
    siteIds,
    getCountryForSite,
    getCountryBySiteId,
    getSiteById,
    getMergedOrgCustomStatuses,
    getSiteByCtmsId,
  };
};

export const StudyOrgMapper = {
  fromOrgV1s,
};
