import { Maybe } from '@csp/csp-common-model';
import { useMap } from '@csp/csp-web-hooks';
import { QueryClient, QueryClientConfig, QueryKey, Updater } from '@tanstack/react-query';
import { noop } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { QueryContextState } from './QueryContextState';

export const useQueryContextProvider = (queryClientConfig?: QueryClientConfig): QueryContextState => {
  const [, { set, get, remove, reset }] = useMap();
  const [queryClient] = useState(
    () =>
      new QueryClient({
        ...queryClientConfig,
        logger: {
          // We handle error logging ourselves.
          error: noop,
          log: console.log,
          warn: console.warn,
        },
      }),
  );

  const setParam = <T>(paramKey: string, param: T): void => {
    set(paramKey, param);
  };

  const getParam = <T>(paramKey: string): Maybe<T> => get(paramKey);

  const clearParam = useCallback(
    (paramKey: string): void => {
      remove(paramKey);
    },
    [remove],
  );

  const clearAllParams = useCallback((): void => {
    reset();
  }, [reset]);

  const invalidateData = useCallback(
    async (cacheKey: string): Promise<void> => queryClient.invalidateQueries([cacheKey]),
    [queryClient],
  );

  const clearData = useCallback((cacheKey: string): void => queryClient.removeQueries([cacheKey]), [queryClient]);

  const setData = useCallback(
    <T>(cacheKeys: QueryKey, updater: Updater<Maybe<T>, T>): Maybe<T> => queryClient.setQueryData(cacheKeys, updater),
    [queryClient],
  );

  const clearAllByKey = useCallback(
    (paramKey: string): void => {
      clearData(paramKey);
      clearParam(paramKey);
    },
    [clearData, clearParam],
  );

  const invalidateAllData = useCallback((): Promise<void> => queryClient.invalidateQueries(), [queryClient]);

  const clearAllData = useCallback((): void => queryClient.removeQueries(), [queryClient]);

  const clearAll = useCallback((): void => {
    clearAllData();
    clearAllParams();
  }, [clearAllData, clearAllParams]);

  useEffect(() => clearAll, [clearAll]);

  return {
    queryClient,
    invalidateData,
    clearData,
    setData,
    invalidateAllData,
    clearAllData,
    setParam,
    getParam,
    clearAllParams,
    clearParam,
    clearAll,
    clearAllByKey,
  };
};
