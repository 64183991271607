/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AssessmentAggregateCriteriaFieldV1 {
    USER_ID = 'USER_ID',
    ASSESSMENT_ID = 'ASSESSMENT_ID',
    ASSESSMENT_TYPE = 'ASSESSMENT_TYPE',
    ASSESSMENT_CODE = 'ASSESSMENT_CODE',
    OUTCOME_KEY = 'OUTCOME_KEY',
    OUTCOME_VALUE = 'OUTCOME_VALUE',
    CUSTOM_STATUS_TYPE = 'CUSTOM_STATUS_TYPE',
    CUSTOM_STATUS_VALUE = 'CUSTOM_STATUS_VALUE',
    ALGORITHM_CODE = 'ALGORITHM_CODE',
}