import { memoizedToDebugContent } from '@csp/common';
import {
  ContentDeliveryService,
  ContentstackConfig,
  ContentstackConfigService,
} from '@csp/csp-common-content-delivery-client';
import { ApiOptions, CspError } from '@csp/csp-common-model';
import { toRestOptions } from '@csp/csp-fe-auth';
import {
  GenericPackageResourceContent,
  GenericWebContentPackage,
  LearnMoreContentModel,
  LearnMoreContentModelMock,
  LearnMoreIdentifier,
} from '@csp/csp-web-content-definitions';
import { isDebugCmsModeActive } from '../util/debugCmsUtil';

let genericPackage: GenericWebContentPackage | undefined = undefined;

const init = async (apiOptions?: ApiOptions): Promise<void> => {
  const isDebugEnabled = isDebugCmsModeActive();

  const unifyGenericPackage = await ContentDeliveryService.fetchContentPackage(
    'generic',
    toRestOptions(apiOptions),
    ContentstackConfigService.get(),
  );
  genericPackage = unifyGenericPackage as unknown as GenericWebContentPackage;
  if (isDebugEnabled) {
    genericPackage = memoizedToDebugContent<GenericWebContentPackage>(genericPackage, '', '[CS]');
  }
};

const get = (): GenericWebContentPackage => {
  if (!genericPackage) {
    throw CspError.badState('Must call GenericContentService.init() before trying to access generic content');
  }
  if (!genericPackage.resource) {
    throw CspError.badState('genericPackage.resource is not available in generic content package');
  }
  return genericPackage;
};
const getResource = (): GenericPackageResourceContent => get().resource;

const getLearnMore = (identifier: LearnMoreIdentifier): LearnMoreContentModel => {
  const learnMore = get().learn_more.find(learnMore => learnMore.identifier === identifier);
  if (learnMore === undefined) {
    const errorMessage = `Learn more content entry with identifier ${identifier} does not exist in the generic package`;
    if (ContentstackConfigService.get().allowMockedContent) {
      console.debug(`${errorMessage}. Using mock instead`);
      return LearnMoreContentModelMock.create();
    } else {
      throw CspError.badState(errorMessage);
    }
  }
  return learnMore;
};

const fetchByLocale = async (locale: string, apiOptions?: ApiOptions): Promise<GenericWebContentPackage> => {
  const defaultConfig = ContentstackConfigService.get();
  if (defaultConfig.locale === locale) {
    return get();
  } else {
    const localeConfig: ContentstackConfig = {
      ...defaultConfig,
      locale,
      cacheProvider: {
        get: () => undefined,
        set: () => Promise.resolve(),
      },
    };
    const unifyGenericPackage = await ContentDeliveryService.fetchContentPackage(
      'generic',
      toRestOptions(apiOptions),
      localeConfig,
    );
    return unifyGenericPackage as unknown as GenericWebContentPackage;
  }
};

const fetchResourceByLocale = async (locale: string, apiOptions?: ApiOptions): Promise<GenericPackageResourceContent> =>
  (await fetchByLocale(locale, apiOptions)).resource;

export const GenericContentService = {
  init,
  get,
  getResource,
  getLearnMore,
  fetchResourceByLocale,
};
