import { set } from 'lodash';
import type { ResourceContentstackContentPackageType } from '@csp/csp-common-content-definitions';
import type { UnifyResourceContentModel } from '../../../types/UnifyResourceContentModel';
import type { StaticContentDefinitions } from '../../../types/StaticContentDefinition';

const fromContentDefinition = (
  staticContentDefinitions: StaticContentDefinitions,
  { valuePrefix }: { valuePrefix?: string } = {},
): UnifyResourceContentModel => {
  if (!staticContentDefinitions?.definitions) {
    throw new Error('Invalid Input: Missing StaticContentDefinitions');
  }
  const resource = {};

  staticContentDefinitions.definitions.forEach(({ entry, block, key, value }) => {
    try {
      set(resource, `${entry}.${block}.${key}`, valuePrefix ? `${valuePrefix}${value.trim()}` : value.trim());
    } catch (e) {
      console.log('Got the following error while trying to generate a type for ', { entry, block, key, value });
      throw e;
    }
  });

  return { resource };
};

type KeyValuesType =
  | {
      value?: {
        key?: string;
        value?: string;
      }[];
    }
  | undefined;
const fromContentPackage = (
  contentPackageResources: ResourceContentstackContentPackageType,
): UnifyResourceContentModel => {
  const resource = {};

  contentPackageResources.resource.forEach(entry => {
    entry.resource_blocks?.forEach(block => {
      const keyValues = block?.block?.key_values as KeyValuesType;
      keyValues?.value?.forEach(keyValue => {
        if (entry.identifier) {
          if (block.block?.identifier) {
            if (keyValue?.key) {
              if (keyValue?.value) {
                set(resource, `${entry.identifier}.${block.block?.identifier}.${keyValue.key}`, keyValue.value);
              }
            }
          }
        }
      });
    });
  });

  return { resource };
};

export const ResourceContentFactory = {
  fromContentPackage,
  fromContentDefinition,
};
