export enum ConsentType {
  UNKNOWN = 'UNKNOWN',
  EULA = 'EULA',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  PATIENT_ICF = 'PATIENT_ICF',
  PATIENT_TRAINING = 'PATIENT_TRAINING',
  SHARE_EMAIL = 'SHARE_EMAIL',
  ECOA_TRAINING_PERFORMED = 'ECOA_TRAINING_PERFORMED',
  CONFIRM_ACCOUNT = 'CONFIRM_ACCOUNT',
  PRIVACY_POLICY_ZOOM = 'PRIVACY_POLICY_ZOOM',

  VIDEO_TRAINING_UNIFY_STUDY_INTRO_CRA = 'VIDEO_TRAINING_UNIFY_STUDY_INTRO_CRA',
  VIDEO_TRAINING_UNIFY_STUDY_INTRO_HCP = 'VIDEO_TRAINING_UNIFY_STUDY_INTRO_HCP',
  VIDEO_TRAINING_UNIFY_STUDY_INTRO_GSTM = 'VIDEO_TRAINING_UNIFY_STUDY_INTRO_GSTM',
  VIDEO_TRAINING_UNIFY_STUDY_INTRO_SSTM = 'VIDEO_TRAINING_UNIFY_STUDY_INTRO_SSTM',

  /** Used as a prefix, full ConsentType is ${ConsentType.VIDEO_TRAINING_PERFORMED_QUESTIONNAIRE}_${the_questionnaire_code}*/
  VIDEO_TRAINING_PERFORMED_QUESTIONNAIRE = 'VIDEO_TRAINING_PERFORMED_QUESTIONNAIRE',
  /** Used as a prefix, full ConsentType is ${ConsentType.VIDEO_TRAINING_PERFORMED_STUDY_INTRO}_${the_intro_name}*/
  VIDEO_TRAINING_PERFORMED_STUDY_INTRO = 'VIDEO_TRAINING_PERFORMED_STUDY_INTRO',
  /** Used as a prefix, full ConsentType is ${ConsentType.VIDEO_TRAINING_PERFORMED_DEVICES_PAIR}_${deviceModel}*/
  VIDEO_TRAINING_PERFORMED_DEVICES_PAIR = 'VIDEO_TRAINING_PERFORMED_DEVICES_PAIR',
  /** Used as a prefix, full ConsentType is ${ConsentType.VIDEO_TRAINING_PERFORMED_DEVICES_MEASURE}_${deviceModel}*/
  VIDEO_TRAINING_PERFORMED_DEVICES_MEASURE = 'VIDEO_TRAINING_PERFORMED_DEVICES_MEASURE',
}

export const VIDEO_TRAINING_UNIFY_STUDY_INTRO_PREFIX = 'VIDEO_TRAINING_UNIFY_STUDY_INTRO';
export const VIDEO_TRAINING_PERFORMED_PREFIX = 'VIDEO_TRAINING_PERFORMED';
