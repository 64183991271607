export const userMetricTypes = ['INVITED_UNIFY_APP_USERS', 'ACTIVE_UNIFY_APP_USERS'] as const;

const enrolmentMetricTypes = ['SCREENED_TOTAL', 'RANDOMIZED_TOTAL'] as const;

export type UserMetricType = typeof userMetricTypes[number];

export type EnrolmentMetricType = typeof enrolmentMetricTypes[number];

export type StudyMetricType =
  | 'IN_SCREENING_TOTAL'
  | 'SCREEN_FAILED_TOTAL'
  | 'DROP_OUT_RATE'
  | 'SCREEN_FAILURE_RATE'
  | 'COMPLETED'
  | EnrolmentMetricType
  | UserMetricType;

const VcvStudyMetricTypes = [
  'Total Consented/Screened',
  'Total Entered/Randomized',
  'Total In Screening',
  'Total Screen Failed',
  'Drop Out Rate (%)',
  'Screen Failure Rate (%)',
  'Total Completed Study',
] as const;

type VcvStudyMetricType = typeof VcvStudyMetricTypes[number];

export const TO_STUDY_METRIC_TYPE: { [key in VcvStudyMetricType]: StudyMetricType } = {
  'Total Consented/Screened': 'SCREENED_TOTAL',
  'Total Entered/Randomized': 'RANDOMIZED_TOTAL',
  'Total In Screening': 'IN_SCREENING_TOTAL',
  'Total Screen Failed': 'SCREEN_FAILED_TOTAL',
  'Drop Out Rate (%)': 'DROP_OUT_RATE',
  'Screen Failure Rate (%)': 'SCREEN_FAILURE_RATE',
  'Total Completed Study': 'COMPLETED',
};

export const isVcvStudyMetricType = (name: string): name is VcvStudyMetricType =>
  VcvStudyMetricTypes.includes(name as VcvStudyMetricType);

export const isUserMetricType = (type: StudyMetricType): type is UserMetricType =>
  userMetricTypes.includes(type as UserMetricType);

export const isEnrolmentMetricType = (type: StudyMetricType): type is EnrolmentMetricType =>
  enrolmentMetricTypes.includes(type as EnrolmentMetricType);
