export enum CriteriaType {
  EQ = 'EQ',
  NOT_EQ = 'NOT_EQ',
  LIKE = 'LIKE',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_EQ = 'LESS_THAN_EQ',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_EQ = 'GREATER_THAN_EQ',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  ALL = 'ALL',
  EXISTS = 'EXISTS',
}
