import { useSelector } from 'react-redux';
import { isLoggedInSelector } from '../../security/ducks/principalContextSelectors';
import { hasStudyConfigSelector } from '../../study-config/ducks/studyConfigSelectors';
import { getSelectedStudyIdSelector, hasStudiesSelector, isLoadingStudiesSelector } from '../ducks/studiesSelectors';
import { useAuthContext } from '../../auth/context/hook/useAuthContext';

export type StudyRedirectHook = {
  loading: boolean;
  shouldSelectStudy: boolean;
};

export const useStudyRedirect = (): StudyRedirectHook => {
  const { isAuthenticated } = useAuthContext();
  const isLoggedInOnStudy = useSelector(isLoggedInSelector);
  const hasStudies = useSelector(hasStudiesSelector);
  const isLoadingStudies = useSelector(isLoadingStudiesSelector);
  const selectedStudyId = useSelector(getSelectedStudyIdSelector);
  const hasStudyConfig = useSelector(hasStudyConfigSelector);

  const loading =
    !isAuthenticated ||
    (selectedStudyId && !isLoggedInOnStudy) ||
    (!selectedStudyId && !hasStudies && isLoadingStudies) ||
    (!!selectedStudyId && !hasStudyConfig);

  const shouldSelectStudy = !loading && isAuthenticated && !isLoggedInOnStudy;

  return {
    loading,
    shouldSelectStudy,
  };
};
