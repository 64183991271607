import { isAfter } from 'date-fns';
import { AccessRefType } from '../../user/type/AccessRefType';
import { JwtBodyV1c } from './JwtBodyV1c';
import { RoleAccessRefsV1c } from './RoleAccessRefsV1c';

export class DmdpToken {
  private readonly expDateTime: Date;
  private readonly roles: string[];
  private readonly orgIds: string[];

  constructor(private jwtBase64: string, private jwtBodyV1c: JwtBodyV1c) {
    this.expDateTime = new Date(jwtBodyV1c.exp * 1000);

    // Fetch roles and permissions
    const roleSet = new Set<string>();
    const orgIdSet = new Set<string>();

    jwtBodyV1c.roles?.forEach(role => {
      roleSet.add(role.ro);
      // Not all roles have access references

      role.ar
        .filter(accessRef => accessRef.t === AccessRefType.ORGANISATION)
        .forEach(accessRef => accessRef.rs.forEach(ref => orgIdSet.add(ref)));
    });

    this.roles = Array.from(roleSet);
    this.orgIds = Array.from(orgIdSet);
  }

  getJwtBase64(): string {
    return this.jwtBase64;
  }

  getCountryCode(): string {
    const locale = this.jwtBodyV1c.locale;
    return locale?.length === 5 ? locale.slice(3, 5).toUpperCase() : 'GB';
  }

  getLanguageCode(): string {
    const locale = this.jwtBodyV1c.locale;
    return locale?.length === 5 ? locale.slice(0, 2).toLowerCase() : 'en';
  }

  getLocale(): string | undefined {
    return this.jwtBodyV1c.locale;
  }

  getUserId(): string {
    return this.jwtBodyV1c.userId;
  }

  getTenantId(): string {
    return this.jwtBodyV1c.tenantId;
  }

  getExpInEpocSecs(): number {
    return this.jwtBodyV1c.exp;
  }

  getExpDateTime(): Date {
    return this.expDateTime;
  }

  isExpired(): boolean {
    return isAfter(new Date(), this.expDateTime);
  }

  getRoleAndRefs(): RoleAccessRefsV1c[] {
    return this.jwtBodyV1c.roles;
  }

  getRoles(): string[] {
    return this.roles;
  }

  hasRole(role: string): boolean {
    return this.getRoles().includes(role);
  }

  getMyOrgIds(): string[] {
    return this.orgIds;
  }

  isMyOrg(orgId: string): boolean {
    return this.orgIds.includes(orgId);
  }

  isAnyMyOrg(orgIds: string[] = []): boolean {
    return orgIds.find(orgId => this.isMyOrg(orgId)) != null;
  }
}
