import { LocationV1 } from '@csp/dmdp-api-org-dto';
import { Address } from './Address';
import { ContactPoint } from './ContactPoint';

const from = ({ locationId, name, primary, address, contactPoints }: LocationV1): Location => ({
  locationId,
  name,
  primary,
  address: address ? Address.from(address) : undefined,
  contactPoints: contactPoints?.map(ContactPoint.from) ?? [],
});

export interface Location {
  address?: Address;
  contactPoints?: ContactPoint[];
  locationId: string;
  name?: string;
  primary?: boolean;
}

export const Location = { from };
