import { Consent } from '@csp/csp-common-model';
import { ConsentV1, ConsentType } from '@csp/dmdp-api-user-dto';

const toConsentV1 = ({ type, ref, version, timestamp }: Consent): ConsentV1 => ({
  type,
  ref,
  version,
  timestamp,
});

const toConsentV1s = (consents: Consent[]): ConsentV1[] => consents.map(toConsentV1);

const toConsent = ({ type, ref, version, timestamp }: ConsentV1): Consent => ({
  type: type as ConsentType,
  ref,
  version,
  timestamp,
});

const toConsents = (consentV1s: ConsentV1[]): Consent[] => consentV1s.map(consentV1 => toConsent(consentV1));

export const ConsentMetaMapper = {
  toConsentV1,
  toConsentV1s,
  toConsent,
  toConsents,
};
