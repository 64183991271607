import { Snackbar } from '@csp/csp-web-ui';
import { FC } from 'react';
import { useNotificationsContext } from '../hooks/useNotificationsContext';

export const Notifications: FC = () => {
  const { notification, removeNotification } = useNotificationsContext();

  return notification ? (
    <Snackbar
      open={!!notification.message}
      onClose={removeNotification}
      message={notification.message}
      {...notification.options}
    />
  ) : null;
};
