import { CustomStatus } from '../../common/model/CustomStatus';
import { ZonedDateTime } from '../../datetime/ZonedDateTime';
import { UserId } from '../../user/model/UserId';
import { SiteECApprovalStatus } from '../type/SiteECApprovalStatus';

export type ECApprovalStatus = {
  confirmedAt: ZonedDateTime;
  confirmedBy?: UserId;
  status: SiteECApprovalStatus;
};

const from = (customStatus: CustomStatus): ECApprovalStatus => ({
  confirmedAt: customStatus.timestamp.unixTimeMillis !== 0 ? customStatus.timestamp : customStatus.createdTimestamp,
  confirmedBy: customStatus.createdUserId,
  status: customStatus.value as SiteECApprovalStatus,
});

export const ECApprovalStatus = {
  from,
};
