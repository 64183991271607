import { CspError, CspErrorType } from '@csp/csp-common-model';
import { ATagInjectedProps, ErrorPage } from '@csp/csp-web-ui';
import { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../common/hooks/useAppDispatch';
import { useTrackException } from '../../../common/hooks/useTrackException';
import { CspPath } from '../../../CspPath';
import { resetStudyConfigAction } from '../../study-config/ducks/studyConfigActions';
import { getStudyConfigErrorSelector } from '../../study-config/ducks/studyConfigSelectors';
import { faultActions } from '../ducks/faultReducer';
import { getFaultSelector, isFaultHandledSelector } from '../ducks/faultSelectors';
import { FaultPageErrorType } from '../model/FaultPageErrorType';
import { Fault404Page } from '../page/Fault404Page';
import { StaticContentService } from '../../../common/service/StaticContentService';

const renderBackToLandingPage: FC<ATagInjectedProps> = props => <Link {...props} to={CspPath.LANDING} />;

export const FaultHandlerContainer: FC<PropsWithChildren> = ({ children }) => {
  const { unexpectedError, unhandledServerError, unhandledClientError, forbiddenError } =
    StaticContentService.getPublicEntry('webHcpErrorPage');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const fault = useSelector(getFaultSelector);
  const faultIsHandled = useSelector(isFaultHandledSelector);
  const trackException = useTrackException();
  const studyConfigError = useSelector(getStudyConfigErrorSelector);

  useEffect(() => {
    if (faultIsHandled) {
      dispatch(faultActions.clearError());
    } else if (fault) {
      dispatch(faultActions.handleError());
      trackException(fault);
    }
  }, [location, dispatch, faultIsHandled, fault, trackException]);

  useEffect(() => {
    if (studyConfigError) {
      const errorUrl = generatePath(CspPath.ERROR, { type: FaultPageErrorType.INVALID_STUDY_CONFIGURATION });
      navigate(errorUrl);
      dispatch(resetStudyConfigAction());
    }
  }, [dispatch, navigate, studyConfigError]);

  if (fault) {
    const path = location.pathname;
    const cspError = fault as CspError;

    if (!faultIsHandled && path !== CspPath.ERROR) {
      return <Navigate replace to={CspPath.ERROR} />;
    }

    if (cspError.type) {
      switch (cspError.type) {
        case CspErrorType.NOT_FOUND:
          return <Fault404Page />;
        case CspErrorType.FORBIDDEN:
          return (
            <ErrorPage
              renderLogoContainer={renderBackToLandingPage}
              illustration="error"
              altText={forbiddenError.image.altText}
              label={forbiddenError.header.text}
              subLabel={forbiddenError.body.text}
            />
          );
        case CspErrorType.SERVER_ERROR:
          return (
            <ErrorPage
              renderLogoContainer={renderBackToLandingPage}
              illustration="error"
              altText={unhandledServerError.image.altText}
              label={unhandledServerError.header.text}
              subLabel={unhandledServerError.body.text}
            />
          );
        case CspErrorType.UNHANDLED_CLIENT_ERROR:
          return (
            <ErrorPage
              renderLogoContainer={renderBackToLandingPage}
              illustration="error"
              altText={unhandledClientError.image.altText}
              label={unhandledClientError.header.text}
              subLabel={unhandledClientError.body.text}
            />
          );
        default:
          return (
            <ErrorPage
              renderLogoContainer={renderBackToLandingPage}
              illustration="error"
              altText={unexpectedError.image.altText}
              label={unexpectedError.header.text}
              subLabel={unexpectedError.body.text}
            />
          );
      }
    } else {
      return (
        <ErrorPage
          renderLogoContainer={renderBackToLandingPage}
          illustration="error"
          altText={unexpectedError.image.altText}
          label={unexpectedError.header.text}
          subLabel={unexpectedError.body.text}
        />
      );
    }
  }
  return <>{children}</>;
};
