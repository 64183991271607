import { emptyPatientInfo, Maybe, PatientInfo } from '@csp/csp-common-model';
import { getMeta } from '@csp/csp-common-util';
import { axiosDmdp } from '@csp/csp-fe-auth';
import { UserRestServiceV1 } from '@csp/dmdp-api-client';
import {
  createPatientInfoMetaV1,
  PATIENT_INFO_V1_META_TYPE,
  PatientInfoMetaV1,
  PatientInfoV1,
  UserV1,
} from '@csp/dmdp-api-user-dto';

const toPatientInfoV1 = (patientInfo: PatientInfo): PatientInfoV1 => ({
  ...patientInfo,
});

const toPatientInfo = (patientInfoMetaV1: PatientInfoMetaV1, birthDate?: string): PatientInfo => ({
  ...patientInfoMetaV1.data,
  birthDate,
});

const updatePatientInfo = async (userId: string, patientInfo: PatientInfo): Promise<void> => {
  const patientInfoV1 = toPatientInfoV1(patientInfo);
  return UserRestServiceV1.updateMeta({ axios: axiosDmdp() }, userId, createPatientInfoMetaV1(patientInfoV1));
};

const getPatientInfo = ({ metas, personal }: UserV1): PatientInfo => {
  const patientInfoMetaV1: Maybe<PatientInfoMetaV1> = getMeta(metas, PATIENT_INFO_V1_META_TYPE);
  return patientInfoMetaV1 ? toPatientInfo(patientInfoMetaV1, personal?.birthDate) : emptyPatientInfo;
};

export const PatientInfoService = {
  updatePatientInfo,
  getPatientInfo,
};
