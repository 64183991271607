export enum MetricPeriodUnitType {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

const asStrings: string[] = Object.keys(MetricPeriodUnitType);
const asArray: MetricPeriodUnitType[] = Object.values(MetricPeriodUnitType);

export const MetricPeriodUnitTypes = {
  asStrings,
  asArray,
};
