import { MemCacheService } from '@csp/csp-common-memcache';
import { ApiOptions, Maybe, OrgId, TenantId } from '@csp/csp-common-model';
import { OrgsSchedules, ScheduleCachedService, ScheduleVersionRequestRef } from '@csp/csp-common-scheduling';
import { toRestOptions } from '@csp/csp-fe-auth';
import { QuestionnaireRequest } from '../model/QuestionnaireRequest';
import { QuestionnaireRequestInfo } from '../model/QuestionnaireRequestInfo';
import { QuestionnaireSchedule } from '../model/QuestionnaireSchedule';
import { QuestionnaireScheduleQueryService } from './QuestionnaireScheduleQueryService';

const Q_REQUESTS_CACHE_KEY = ['QuestionnaireScheduleService', 'all'];
const Q_SCHEDULES_BY_ORG_CACHE_KEY = ['QuestionnaireScheduleService', 'schedules'];
const Q_REQUESTS_BY_REF_CACHE_KEY = ['QuestionnaireScheduleService', 'requests'];
const Q_REQUESTS_BY_REF_AND_TENTANT_CACHE_KEY = ['QuestionnaireScheduleService', 'requests-and-tenant'];
const CACHE_TTL_SECS = 3600;

const getRequestCacheKey = ({ scheduleCode, versionCode, requestCode }: ScheduleVersionRequestRef): string[] => [
  ...Q_REQUESTS_BY_REF_CACHE_KEY,
  scheduleCode,
  versionCode,
  requestCode,
];

const setCachedRequest = (ref: ScheduleVersionRequestRef, request: QuestionnaireRequestInfo): void => {
  MemCacheService.setValue<QuestionnaireRequestInfo>(getRequestCacheKey(ref), CACHE_TTL_SECS, request);
};

const getCachedRequest = (ref: ScheduleVersionRequestRef): Maybe<QuestionnaireRequestInfo> =>
  MemCacheService.getValue<QuestionnaireRequestInfo>(getRequestCacheKey(ref));

const getRequestInfoByCodeAndVersion = async (
  reqRef: ScheduleVersionRequestRef,
  apiOptions?: ApiOptions,
): Promise<QuestionnaireRequestInfo> => {
  let request = getCachedRequest(reqRef);
  if (!request) {
    request = await QuestionnaireScheduleQueryService.getRequestByCodeAndVersion(
      reqRef.scheduleCode,
      reqRef.versionCode,
      reqRef.requestCode,
      toRestOptions(apiOptions),
    );
    setCachedRequest(reqRef, request);
  }
  return request;
};

const getRequestAndTenantCacheKey = (
  { scheduleCode, versionCode, requestCode }: ScheduleVersionRequestRef,
  tenantId: TenantId,
): string[] => [...Q_REQUESTS_BY_REF_AND_TENTANT_CACHE_KEY, scheduleCode, versionCode, requestCode, tenantId];

const setCachedRequestByTenant = (
  ref: ScheduleVersionRequestRef,
  tenantId: TenantId,
  request: QuestionnaireRequestInfo,
): void => {
  MemCacheService.setValue<QuestionnaireRequestInfo>(
    getRequestAndTenantCacheKey(ref, tenantId),
    CACHE_TTL_SECS,
    request,
  );
};

const getCachedRequestByTenant = (
  ref: ScheduleVersionRequestRef,
  tenantId: TenantId,
): Maybe<QuestionnaireRequestInfo> =>
  MemCacheService.getValue<QuestionnaireRequestInfo>(getRequestAndTenantCacheKey(ref, tenantId));

const getRequestInfoByCodeAndVersionAndTenant = async (
  ScheduleVersionRequestRef: ScheduleVersionRequestRef,
  tenantId: TenantId,
  apiOptions?: ApiOptions,
): Promise<QuestionnaireRequestInfo> => {
  let request = getCachedRequestByTenant(ScheduleVersionRequestRef, tenantId);
  if (!request) {
    request = await QuestionnaireScheduleQueryService.getRequestByCodeAndVersion(
      ScheduleVersionRequestRef.scheduleCode,
      ScheduleVersionRequestRef.versionCode,
      ScheduleVersionRequestRef.requestCode,
      toRestOptions(apiOptions),
    );
    setCachedRequestByTenant(ScheduleVersionRequestRef, tenantId, request);
  }
  return request;
};

const getAllRequestsIncludingInactive = async (apiOptions?: ApiOptions): Promise<QuestionnaireRequestInfo[]> => {
  const cacheKey = [...Q_REQUESTS_CACHE_KEY];
  let requests = MemCacheService.getValue<QuestionnaireRequestInfo[]>(cacheKey);
  if (!requests) {
    requests = await QuestionnaireScheduleQueryService.getAllRequestsIncludingInactive(apiOptions);
    MemCacheService.setValue(cacheKey, CACHE_TTL_SECS, requests);
  }
  return requests;
};

const getAllActivatedQuestionnaireSchedulesForOrganizationId = async (
  organizationId: OrgId,
  apiOptions?: ApiOptions,
): Promise<QuestionnaireSchedule[]> => {
  const cacheKey = [...Q_SCHEDULES_BY_ORG_CACHE_KEY, organizationId];
  let schedules = MemCacheService.getValue<QuestionnaireSchedule[]>(cacheKey);
  if (!schedules) {
    schedules = await QuestionnaireScheduleQueryService.getAllActivatedQuestionnaireSchedulesForOrganizationId(
      organizationId,
      toRestOptions(apiOptions),
    );

    MemCacheService.setValue(cacheKey, CACHE_TTL_SECS, schedules);
    schedules.forEach(schedule =>
      schedule.activeVersionWindows.forEach(activeVersion =>
        activeVersion.requests.forEach(request => setCachedRequest(request, request)),
      ),
    );
  }
  return schedules;
};

const getAllActivatedQuestionnaireSchedulesForOrganizationIds = async (
  organizationIds: OrgId[],
  apiOptions?: ApiOptions,
): Promise<OrgsSchedules<QuestionnaireSchedule, QuestionnaireRequest>> =>
  ScheduleCachedService.getAllActivatedSchedulesForOrganizationIds(
    'questionnaire',
    organizationIds,
    QuestionnaireScheduleQueryService.queryActivatedQuestionnaireSchedulesForOrganizationIds,
    apiOptions,
  );

export const QuestionnaireScheduleCachedQueryService = {
  getAllActivatedQuestionnaireSchedulesForOrganizationId,
  getAllActivatedQuestionnaireSchedulesForOrganizationIds,
  getAllRequestsIncludingInactive,
  getRequestInfoByCodeAndVersion,
  getRequestInfoByCodeAndVersionAndTenant,
};
