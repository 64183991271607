/* eslint-disable */
export const UnifyQuestionnairesSystemTextsSchema = {
  title: 'UnifyQuestionnairesSystemTexts',
  type: 'object',
  properties: {
    title: { type: 'string', title: 'Title', placeholder: 'Title' },
    nextButtonLabel: { type: 'string', title: 'Next Button Label', placeholder: 'Next Button Label' },
    nextButtonAccessibilityLabel: {
      type: 'string',
      title: 'Next Button Accessibility Label',
      placeholder: 'Next Button Accessibility Label',
    },
    nextButtonAccessibilityHint: {
      type: 'string',
      title: 'Next Button Accessibility Hint',
      placeholder: 'Next Button Accessibility Hint',
    },
    startButtonLabel: { type: 'string', title: 'Start Button Label', placeholder: 'Start Button Label' },
    startButtonAccessibilityLabel: {
      type: 'string',
      title: 'Start Button Accessibility Label',
      placeholder: 'Start Button Accessibility Label',
    },
    startButtonAccessibilityHint: {
      type: 'string',
      title: 'Start Button Accessibility Hint',
      placeholder: 'Start Button Accessibility Hint',
    },
    submitButtonLabel: { type: 'string', title: 'Submit Button Label', placeholder: 'Submit Button Label' },
    submitButtonAccessibilityLabel: {
      type: 'string',
      title: 'Submit Button Accessibility Label',
      placeholder: 'Submit Button Accessibility Label',
    },
    submitButtonAccessibilityHint: {
      type: 'string',
      title: 'Submit Button Accessibility Hint',
      placeholder: 'Submit Button Accessibility Hint',
    },
    previousButtonAccessibilityLabel: {
      type: 'string',
      title: 'Previous Button Accessibility Label',
      placeholder: 'Previous Button Accessibility Label',
    },
    previousButtonLabel: { type: 'string', title: 'Previous Button Label', placeholder: 'Previous' },
    previousButtonAccessibilityHint: {
      type: 'string',
      title: 'Previous Button Accessibility Hint',
      placeholder: 'Previous Button Accessibility Hint',
    },
    abortButtonAccessibilityLabel: {
      type: 'string',
      title: 'Abort Button Accessibility Label',
      placeholder: 'Abort Button Accessibility Label',
    },
    abortButtonAccessibilityHint: {
      type: 'string',
      title: 'Abort Button Accessibility Hint',
      placeholder: 'Abort Button Accessibility Hint',
    },
    abortQuestionnairePopupHeader: {
      type: 'string',
      title: 'Abort Questionnaire Popup Header',
      placeholder: 'Abort Questionnaire Popup Header',
    },
    abortQuestionnairePopupDescription: {
      type: 'string',
      title: 'Abort Questionnaire Popup Description',
      placeholder: 'Abort Questionnaire Popup Description',
    },
    abortQuestionnairePopupCancelButtonLabel: {
      type: 'string',
      title: 'Abort Questionnaire Popup Cancel Button Label',
      placeholder: 'Abort Questionnaire Popup Cancel Button Label',
    },
    abortQuestionnairePopupConfirmButtonLabel: {
      type: 'string',
      title: 'Abort Questionnaire Popup Confirm Button Label',
      placeholder: 'Abort Questionnaire Popup Confirm Button Label',
    },
    skipQuestionPopupHeader: {
      type: 'string',
      title: 'Skip Question Popup Header',
      placeholder: 'Skip Question Popup Header',
    },
    skipQuestionPopupDescription: {
      type: 'string',
      title: 'Skip Question Popup Description',
      placeholder: 'Skip Question Popup Description',
    },
    yesButtonLabel: { type: 'string', title: 'Yes Button Label', placeholder: 'Yes Button Label' },
    cancelButtonLabel: { type: 'string', title: 'Cancel Button Label', placeholder: 'Cancel Button Label' },
    clearButtonLabel: { type: 'string', title: 'Clear Button Label', placeholder: 'Clear' },
    offlineError: {
      title: 'Offline Error Texts',
      type: 'object',
      properties: {
        title: { type: 'string', title: 'Title', placeholder: 'Title' },
        description: { type: 'string', title: 'Description', placeholder: 'Description' },
        buttonLabel: { type: 'string', title: 'Button Label', placeholder: 'Button Label' },
      },
    },
    submit: {
      title: 'Submit texts',
      type: 'object',
      properties: {
        submitFailureTitle: { type: 'string', title: 'Submit Failure Title', placeholder: 'Submit Failure Title' },
        submitFailureDescription: {
          type: 'string',
          title: 'Submit Failure Description',
          placeholder: 'Submit Failure Description',
        },
        submitFailureRetryButtonLabel: {
          type: 'string',
          title: 'Submit Failure Retry Button Label',
          placeholder: 'Submit Failure Retry Button Label',
        },
        submitFailureCancelButtonLabel: {
          type: 'string',
          title: 'Submit Failure Cancel Button Label',
          placeholder: 'Submit Failure Cancel Button Label',
        },
        submitWindowPassedErrorTitle: {
          type: 'string',
          title: 'Submit Window Passed Error Title',
          placeholder: 'Submit Window Passed Error Title',
        },
        submitWindowPassedErrorDescription: {
          type: 'string',
          title: 'Submit Window Passed Error Description',
          placeholder: 'Submit Window Passed Error Description',
        },
        submitWindowPassedErrorButtonLabel: {
          type: 'string',
          title: 'Submit Window Passed Error Button Label',
          placeholder: 'Submit Window Passed Error Button Label',
        },
        successfullySubmittedButtonLabel: {
          type: 'string',
          title: 'Successfully Submitted Button Label',
          placeholder: 'Successfully Submitted Button Label',
        },
        successfullySubmittedNotification: {
          type: 'string',
          title: 'Successfully Submitted Notification',
          placeholder: 'Questionnaire submitted succesfully',
        },
      },
    },
  },
  description: 'unifyquestionnaires:systemtexts',
  $schema: 'http://json-schema.org/draft-07/schema#',
};
