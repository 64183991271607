/* eslint-disable */
export const UnifyScheduleDevicesSchema = {
  title: 'UnifyScheduleDevices',
  type: 'object',
  properties: {
    schedules: {
      title: 'Schedules',
      helper: "Click 'add new item' to add a new schedule.",
      type: 'array',
      items: {
        type: 'object',
        properties: {
          scheduleCode: {
            title: 'Schedule Code',
            type: 'string',
            helper: 'The schedule code that these device requests belongs to',
            placeholder: 'STUDY_PROTOCOL_DEVICE_OBSERVATIONS',
          },
          requests: {
            title: 'Requests',
            type: 'array',
            helper: 'Each request in the schedule is represented by an object here',
            items: {
              title: 'Device Request',
              type: 'object',
              properties: {
                requestCode: {
                  title: 'Request Code',
                  type: 'string',
                  helper: 'The request code for this request',
                  placeholder: 'Action-11.3',
                },
                title: {
                  title: 'Request title',
                  type: 'string',
                  helper: 'The title for the request- to be displayed on each request card',
                  placeholder: 'Spiro session measurement',
                },
                thresholdInformation: {
                  title: 'Request threshold information',
                  type: 'string',
                  helper:
                    'The threshold information for the request- if applicable, to be displayed in the request details',
                  placeholder: 'Description of the threshold information for this request',
                },
                description: {
                  title: 'Description',
                  type: 'string',
                  placeholder:
                    'This request asks the patient to use a device to take measurements for following patients pulse',
                  helper: 'Description about this request to take readings with a device.',
                },
                scheduleInfo: {
                  title: 'Description',
                  type: 'string',
                  placeholder: 'Te patient should take measurements once daily, between 9 am and 10 am',
                  helper: 'Description about this requests schedule on when to take measurements',
                },
                allowedVersions: {
                  title: 'Device schedule versions list',
                  type: 'array',
                  helper: 'A list of which versions this request ',
                  items: {
                    type: 'object',
                    properties: {
                      version: {
                        type: 'string',
                        placeholder: '1.0',
                        helper: 'A version number that these request descriptions applies to.',
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
      mock: [
        {
          scheduleCode: 'STUDY_PROTOCOL_DEVICE_OBSERVATIONS',
          requests: [
            {
              requestCode: 'Action-11.3',
              title: 'Omron-9210T',
              description:
                '[M] Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
              scheduleInfo:
                '[M] Lorem ipsum dolor sit amet, duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
              allowedVersions: [{ version: '1.0' }, { version: '1.1-b' }],
            },
            {
              requestCode: 'Action-11.4',
              title: 'Omron-9200T',
              description:
                '[M] Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
              scheduleInfo:
                '[M] Lorem ipsum dolor sit amet, duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
              allowedVersions: [{ version: '1.0' }, { version: '1.1-b' }],
            },
            {
              requestCode: 'Action-11.5',
              title: 'Masimo Mighty Sat Rx 9809',
              description:
                '[M] Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
              scheduleInfo:
                '[M] Lorem ipsum dolor sit amet, duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
              allowedVersions: [{ version: '1.0' }, { version: '1.1-b' }],
            },
            {
              requestCode: 'Action-11.6',
              title: 'Masimo Mighty Sat Rx 9909',
              description:
                '[M] Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
              scheduleInfo:
                '[M] Lorem ipsum dolor sit amet, duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
              allowedVersions: [{ version: '1.0' }, { version: '1.1-b' }],
            },
            {
              requestCode: 'Action-11.7',
              title: 'Adherium attachment',
              description:
                '[M] Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
              scheduleInfo:
                '[M] Lorem ipsum dolor sit amet, duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
              allowedVersions: [{ version: '1.0' }, { version: '1.1-b' }],
            },
            {
              requestCode: 'Action-11.8',
              title: 'MIR Smart One',
              description:
                '[M] Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
              scheduleInfo:
                '[M] Lorem ipsum dolor sit amet, duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
              allowedVersions: [{ version: '1.0' }, { version: '1.1-b' }],
            },
            {
              requestCode: 'Action-11.9',
              title: 'MIR Spirobank Smart',
              description:
                '[M] Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
              scheduleInfo:
                '[M] Lorem ipsum dolor sit amet, duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
              allowedVersions: [{ version: '1.0' }, { version: '1.1-b' }],
            },
            {
              requestCode: 'Weight_Marsden_DEFAULT',
              title: 'Marsden_M_550',
              thresholdInformation:
                "[M]A notice is triggered when the patient's weight increases more than 2kg compared to their average weight from the last 7 days.",
              description:
                '[M] Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
              scheduleInfo:
                '[M] Lorem ipsum dolor sit amet, duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
              allowedVersions: [{ version: '1.0' }, { version: '1.1-b' }],
            },
          ],
        },
      ],
    },
  },
  description: 'unify:scheduledevices',
  $schema: 'http://json-schema.org/draft-07/schema#',
};
