import { QuestionnaireActivationRefV2 } from '@csp/dmdp-api-questionnaire-dto';
import { QuestionnaireActivationRefV1 } from '@csp/dto';

export type QuestionnaireActivationRef = {
  type: string;
  value: string;
};

const from = ({
  type,
  value,
}: QuestionnaireActivationRefV2 | QuestionnaireActivationRefV1): QuestionnaireActivationRef => ({
  type,
  value,
});

export const QuestionnaireActivationRef = {
  from,
};
