import { Maybe } from '@csp/csp-common-model';
import { isDefined } from '@csp/csp-common-util';
import { SpinnerPage } from '@csp/csp-web-ui';
import { ConsentType } from '@csp/dmdp-api-user-dto';
import { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Navigate, useLocation } from 'react-router-dom';
import { CspPath } from '../../../CspPath';
import { displayCloseoutTrackerInfoDialogSelector } from '../../account/ducks/displayCloseoutTrackerInfoDialogSelector';
import { useConsentContext } from '../../consent/context/hook/useConsentContext';
import { canSkipConsentApprovalsSelector } from '../../consent/ducks/consentPermissionSelector';
import { FaultPageErrorType } from '../../fault/model/FaultPageErrorType';
import { getAuthStateErrorSelector, getUserSelector } from '../../security/ducks/principalContextSelectors';
import { useStudyRedirect } from '../../studies/hook/useStudyRedirect';
import { GenericConsentTypes } from '../../consent/model/GenericConsentType';
import { addQsToPath } from './urlBuilder';

type NextSetupUrlParams = {
  hasPendingConsentApprovals: boolean;
  hasPendingVideoTrainingApprovals: boolean;
  hasConfirmedAccount: boolean;
  shouldDisplayCloseoutTrackerInfoDialog: boolean;
  shouldSelectStudy: boolean;
};

export const getNextSetupUrl = ({
  hasPendingConsentApprovals,
  hasPendingVideoTrainingApprovals,
  hasConfirmedAccount,
  shouldDisplayCloseoutTrackerInfoDialog,
  shouldSelectStudy,
}: NextSetupUrlParams): Maybe<CspPath> => {
  if (shouldSelectStudy) {
    return CspPath.STUDIES;
  } else if (hasPendingConsentApprovals) {
    return CspPath.CONSENT;
  } else if (!hasConfirmedAccount) {
    return CspPath.CONFIRM_ACCOUNT;
  } else if (hasPendingVideoTrainingApprovals) {
    return CspPath.VIDEO_TRAINING;
  } else if (shouldDisplayCloseoutTrackerInfoDialog) {
    return CspPath.CLOSEOUT_TRACKER_NOTIFICATION;
  } else {
    return undefined;
  }
};

export const getRedirectUrl = (nextSetupUrl: Maybe<CspPath>, search: string, currentPath: string): Maybe<string> => {
  const redirectUrl = new URLSearchParams(search).get('redirectTo');

  if (nextSetupUrl && nextSetupUrl !== currentPath) {
    return addQsToPath(nextSetupUrl, {
      redirectTo: redirectUrl || currentPath,
    });
  } else if (!nextSetupUrl && redirectUrl) {
    return redirectUrl;
  }
  return undefined;
};

export const UserSetup: FC<PropsWithChildren> = ({ children }) => {
  const {
    loading: isLoadingConsentApprovals,
    error: consentsError,
    hasPendingApprovals,
    hasPendingVideoTrainingApprovals,
  } = useConsentContext();
  const authError = useSelector(getAuthStateErrorSelector);
  const studiesSetup = useStudyRedirect();
  const user = useSelector(getUserSelector);
  const location = useLocation();
  const shouldDisplayCloseoutTrackerInfoDialog = useSelector(displayCloseoutTrackerInfoDialogSelector);

  const isLoading = studiesSetup.loading || isLoadingConsentApprovals;

  const hasPermissionToSkipConsents = useSelector(canSkipConsentApprovalsSelector);
  const hasPermissionToSkipVideoTraining = process.env.NX_SKIP_VIDEO_TRAINING === 'true';
  const hasPendingConsentApprovals = hasPendingApprovals(GenericConsentTypes) && !hasPermissionToSkipConsents;
  const hasPendingVideoTrainingConsentApprovals =
    hasPendingVideoTrainingApprovals() && !hasPermissionToSkipVideoTraining;

  const error = [consentsError, authError].find(isDefined);

  if (error) {
    console.error(error);
    return (
      <Navigate replace to={generatePath(CspPath.ERROR, { type: FaultPageErrorType.INVALID_STUDY_CONFIGURATION })} />
    );
  }

  if (isLoading) {
    return <SpinnerPage />;
  }

  const nextSetupUrl = getNextSetupUrl({
    hasPendingConsentApprovals,
    hasPendingVideoTrainingApprovals: hasPendingVideoTrainingConsentApprovals,
    hasConfirmedAccount: !!user?.hasUserConsent(ConsentType.CONFIRM_ACCOUNT),
    shouldDisplayCloseoutTrackerInfoDialog,
    shouldSelectStudy: studiesSetup.shouldSelectStudy,
  });

  const redirectTo = getRedirectUrl(nextSetupUrl, location.search, location.pathname);

  if (redirectTo) {
    return <Navigate replace to={redirectTo} />;
  } else {
    return <>{children}</>;
  }
};
