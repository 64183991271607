import { zonedAddDuration } from '@csp/csp-common-date-util';
import { CspError, CustomStatuses, Maybe, ZonedDateTime } from '@csp/csp-common-model';
import { DayOneDates } from '../model/DayOneDates';
import { Duration } from '../model/schedulingModels/Duration';
import { FixedAction } from '../model/schedulingModels/FixedAction';
import { FixedActionType } from '../model/schedulingModels/FixedActionType';
import { GenericRequest } from '../model/schedulingModels/GenericRequest';
import { RequestAction } from '../model/schedulingModels/RequestAction';
import { ScheduleDateService } from '../service/ScheduleDateService';
import { SchedulingConst } from '../type/SchedulingConst';
import { timeOverrideIfApplicable } from './scheduleRequestUtil';

const getOldestCustomStatusSetTimestamp = (
  fixedAction: FixedAction,
  customStatuses: Maybe<CustomStatuses>,
): Maybe<ZonedDateTime> => {
  if (customStatuses) {
    const customStatusValue = customStatuses.findOldestCustomStatusValue(fixedAction.subType, fixedAction.value);
    return customStatusValue?.timestamp;
  } else {
    return undefined;
  }
};

const applyActionFixedTime = (requestAction: Maybe<RequestAction>, timestamp: ZonedDateTime): ZonedDateTime =>
  timeOverrideIfApplicable(timestamp, requestAction?.fixedTime);

const applyActionOffset = (requestAction: Maybe<RequestAction>, timestamp: ZonedDateTime): ZonedDateTime => {
  const actionOffset: Duration = requestAction?.offset ?? SchedulingConst.DEFAULT_ACTION_OFFSET;

  // Tsa    Tsa+offset
  // |---------|----------------------------------------------------------->
  return zonedAddDuration(timestamp, ScheduleDateService.toDateTimeDuration(actionOffset));
};

const applyActionOffsetAndFixedTime = (
  requestAction: Maybe<RequestAction>,
  timestamp: ZonedDateTime,
): ZonedDateTime => {
  const startActionTimestampWithFixedTime = timestamp && applyActionFixedTime(requestAction, timestamp);
  return startActionTimestampWithFixedTime && applyActionOffset(requestAction, startActionTimestampWithFixedTime);
};

const getRequestActionTimestamp = (requestAction: RequestAction, dayOneDates: DayOneDates): Maybe<ZonedDateTime> => {
  const fixedAction = requestAction?.fixedAction;

  if (fixedAction) {
    let timestamp: Maybe<ZonedDateTime> = undefined;
    const fixedActionType = fixedAction.type;

    if (fixedActionType === FixedActionType.USER_CUSTOM_STATUS_SET) {
      timestamp = getOldestCustomStatusSetTimestamp(fixedAction, dayOneDates.userCustomStatuses);
    } else if (fixedActionType === FixedActionType.ORG_CUSTOM_STATUS_SET) {
      timestamp = getOldestCustomStatusSetTimestamp(fixedAction, dayOneDates.orgCustomStatuses);
    } else {
      throw CspError.badState(`Unsupported actionCode: ${fixedActionType}`);
    }

    return timestamp;
  } else {
    return undefined;
  }
};

/**
 * Won't work with deprecated {@link DayOneDates} properties.
 */
const getStartActionTimestamp = ({ startAction }: GenericRequest, dayOneDates: DayOneDates): Maybe<ZonedDateTime> =>
  startAction && getRequestActionTimestamp(startAction, dayOneDates);

/**
 * Won't work with deprecated {@link DayOneDates} properties.
 */
const getEndActionTimestamp = ({ endAction }: GenericRequest, dayOneDates: DayOneDates): Maybe<ZonedDateTime> =>
  endAction && getRequestActionTimestamp(endAction, dayOneDates);

const getActionOffsetTimestamp = (requestAction: RequestAction, dayOneDates: DayOneDates): Maybe<ZonedDateTime> => {
  const startActionTimestamp = getRequestActionTimestamp(requestAction, dayOneDates);
  return startActionTimestamp && applyActionOffsetAndFixedTime(requestAction, startActionTimestamp);
};

const getStartActionOffsetTimestamp = (
  { startAction }: GenericRequest,
  dayOneDates: DayOneDates,
): Maybe<ZonedDateTime> => startAction && getActionOffsetTimestamp(startAction, dayOneDates);

const getEndActionOffsetTimestamp = ({ endAction }: GenericRequest, dayOneDates: DayOneDates): Maybe<ZonedDateTime> =>
  endAction && getActionOffsetTimestamp(endAction, dayOneDates);

export const RequestActionDateUtil = {
  getStartActionTimestamp,
  getEndActionTimestamp,
  getActionOffsetTimestamp,
  getStartActionOffsetTimestamp,
  getEndActionOffsetTimestamp,
  applyActionOffsetAndFixedTime,

  /** For testing purposes */
  getRequestActionTimestamp,
};
