import { combineReducers } from '@reduxjs/toolkit';
import { cmsReducer } from '../../dmdp/cmspod/redux/cmsReducer';
import { localeReducer } from '../../dmdp/userpod/ducks/locale/localeReducer';

export const commonReducer = combineReducers({
  cms: cmsReducer,
  locale: localeReducer,
});

export type CommonStoreState = ReturnType<typeof commonReducer>;
