import { Callback, CallbackWithArgs, Maybe } from '@csp/csp-common-model';
import { noop } from 'lodash';

export type AutomaticLogoutStatePayload = { userId: Maybe<string>; path: string; studyId: Maybe<string> };

export type AutomaticLogoutContextState = {
  lastActiveUserId: Maybe<string>;
  lastSelectedStudyId: Maybe<string>;
  lastVisitedPath: Maybe<string>;
  displayLoggedOutNotice: boolean;
  userWasAutomaticallyLoggedOut: boolean;
  showAutomaticLogoutNotice: Callback;
  hideAutomaticLogoutNotice: Callback;
  onPreAutomaticLogout: CallbackWithArgs<[AutomaticLogoutStatePayload]>;
  resetState: Callback;
};

const emptyState: AutomaticLogoutContextState = {
  lastActiveUserId: undefined,
  lastSelectedStudyId: undefined,
  lastVisitedPath: undefined,
  displayLoggedOutNotice: false,
  userWasAutomaticallyLoggedOut: false,
  showAutomaticLogoutNotice: noop,
  hideAutomaticLogoutNotice: noop,
  onPreAutomaticLogout: noop,
  resetState: noop,
};

export const AutomaticLogoutContextState = {
  emptyState,
};
