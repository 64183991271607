import { Patient } from '@csp/csp-common-model';
import { DayOneDates, WhileCriteriaUserValues } from '@csp/csp-common-scheduling';

const getCustomActionDates = (patient: Patient): DayOneDates => ({
  randomizedTimestamp: patient.randomizedTimestamp,
  screenedTimestamp: patient.screenedTimestamp,
  activatedTimestamp: patient.occasions.activationTime,
  userCustomStatuses: patient.customStatuses,
});

const getWhileCriteriaValues = (patient: Patient): WhileCriteriaUserValues => ({
  customStatuses: patient.customStatuses,
});

export const DeviceDateUtil = {
  getCustomActionDates,
  getWhileCriteriaValues,
};
