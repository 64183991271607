import { AxiosError, AxiosResponse } from 'axios';
import { HttpStatusConst } from '@csp/csp-common-axios';

/**
 *  Axios interprets all responses not in the 200-299 range as an error, however 304 NOT_MODIFIED just means that the server determined that the content is already cached on the client.
 *  Such as when an eTag has been provided in the request.
 * @param error
 */
export const notModifiedErrorResponseInterceptor = async (error: AxiosError): Promise<AxiosResponse> => {
  if (error.response?.status === HttpStatusConst.NOT_MODIFIED) {
    return Promise.resolve(error.response);
  } else {
    return Promise.reject(error);
  }
};
