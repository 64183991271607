import { isEqual, isPlainObject, omit } from 'lodash';
import { ObjectType } from '@csp/csp-common-model';

const omitRecursively = (object: ObjectType, paths: string[]): ObjectType => {
  const result: ObjectType = {};

  for (const [key, value] of Object.entries(object)) {
    result[key] = isPlainObject(value) ? omitRecursively(value as ObjectType, paths) : value;
  }

  return omit(result, paths);
};

/**
 * A way to get String from a key in an object
 *
 *  nameOf({property1: 'Hello' }, o => o.property1) returns 'property1'
 *
 * https://stackoverflow.com/a/66836940/3743985
 * @param obj - to extract key as string
 * @param expression - expression to find key
 */
const nameOf = <T extends object>(
  obj: T,
  expression: (x: { [Property in keyof T]: () => string }) => () => string,
): string => {
  const res: { [Property in keyof T]: () => string } = {} as { [Property in keyof T]: () => string };

  Object.keys(obj).forEach(k => (res[k as keyof T] = (): string => k));

  return expression(res)();
};

const removeUndefined = <T extends object>(obj: T): T =>
  Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) => v != null)
      .map(([k, v]) => {
        if (Array.isArray(v)) {
          return [k, v.map(val => (typeof val === 'object' ? removeUndefined(val) : val))];
        } else if (typeof v === 'object') {
          return [k, removeUndefined(v)];
        } else {
          return [k, v];
        }
      }),
  ) as T;

/**
 * A generic function to deep diff between two objects and returns the total number of diff and
 * current use case to diff between the current filter values and default filter values
 *
 * ObjectUtil(obj1, obj2) returns number '5'
 *
 * @param obj1 - to extract key as number
 * @param obj2 - to extract key as number
 *
 */

const objectValueDiffCount = <T extends ObjectType>(obj1: T, obj2: T): number =>
  (Object.keys(obj1) as Array<keyof typeof obj2>).reduce((total, key) => {
    if (!isEqual(obj1?.[key], obj2[key])) {
      return ++total;
    } else {
      return total;
    }
  }, 0);

export const ObjectUtil = {
  omitRecursively,
  nameOf,
  removeUndefined,
  objectValueDiffCount,
};
