import { Maybe } from '@csp/csp-common-model';
import { isDefined } from '@csp/csp-common-util';
import { orderBy, uniq } from 'lodash';
import { RequestGroupConfigV1 } from '../meta/RequestGroupConfigV1';
import { GROUP_CONFIG_V1_META_NAME } from '../meta/RequestGroupMetaV1';
import { RequestGroupKey } from '../model/RequestGroupKey';
import { RequestGroup } from '../model/RequestGroup';
import { RequestGroupMembership } from '../model/RequestGroupMembership';
import { RequestGroupMemberships } from '../model/RequestGroupMemberships';
import { RequestGroups } from '../model/RequestGroups';
import { RequestGroupItemBase } from '../model/RequestGroupItemBase';

const fromRequestGroupConfigV1 = (groupConfigV1: unknown): RequestGroupMemberships => {
  RequestGroupConfigV1.assert(groupConfigV1);

  const memberships: RequestGroupMembership[] = groupConfigV1.memberOf.map(assignmentV1 => ({
    groupKey: assignmentV1.groupKey,
    order: assignmentV1.order,
  }));

  const isMemberOf = (groupKey: RequestGroupKey): boolean =>
    !!memberships.find(assignment => assignment.groupKey === groupKey);

  const includesOneOf = (groupKeysOrAssignments: RequestGroupKey[] | RequestGroupMembership[]): boolean => {
    const groupKeys: RequestGroupKey[] = groupKeysOrAssignments.map(keyOrAssignment =>
      typeof keyOrAssignment === 'string' ? keyOrAssignment : keyOrAssignment.groupKey,
    );
    return groupKeys.some(isMemberOf);
  };

  const getMembership = (groupKey: RequestGroupKey): Maybe<RequestGroupMembership> =>
    memberships.find(assignment => assignment.groupKey === groupKey);

  return {
    memberships,
    isMemberOf,
    getMembership,
    includesOneOf,
  };
};

const toRequestGroupMemberships = (
  meta: Maybe<Record<string, unknown>>,
  strict = false,
): Maybe<RequestGroupMemberships> => {
  const requestGroupConfigV1 = meta?.[GROUP_CONFIG_V1_META_NAME];
  if (requestGroupConfigV1) {
    try {
      return fromRequestGroupConfigV1(requestGroupConfigV1);
    } catch (e) {
      if (strict) {
        throw e;
      } else {
        return undefined;
      }
    }
  } else {
    return undefined;
  }
};

const from = <Request extends RequestGroupItemBase>(requests: Request[]): RequestGroups<Request> => {
  const allGroupKeys = requests
    .map(request => request.groups)
    .filter(isDefined)
    .flatMap(group => group.memberships.map(membership => membership.groupKey));
  const uniqueGroupKeys = uniq(allGroupKeys);
  const groups: RequestGroup<Request>[] = uniqueGroupKeys.map(groupKey => ({
    groupKey,
    members: orderBy(
      requests.filter(request => request.groups?.isMemberOf(groupKey)),
      request => request.groups?.getMembership(groupKey)?.order,
    ),
  }));

  const getRequestGroup = (groupKey: RequestGroupKey): Maybe<RequestGroup<Request>> =>
    groups.find(group => group.groupKey === groupKey);

  return {
    groups,
    getRequestGroup,
  };
};

export const RequestGroupMapper = {
  toRequestGroupMemberships,
  from,
};
