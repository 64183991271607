// General formats
const YYYY_MM_DD = 'yyyy-MM-dd';
const HH_MM = `HH:mm`;
const YYYY_MM_DD_HH_MM = `${YYYY_MM_DD} ${HH_MM}`;

// ISO 8601 formats
const ISO_8601_DATE_FORMAT = YYYY_MM_DD;
const ISO_8601_TIME_FORMAT = `${HH_MM}:ss`;
const ISO_8601_UTC_OFFSET_FORMAT = 'xxx';
const ISO_8601_DATE_TIME_FORMAT = `${ISO_8601_DATE_FORMAT}'T'${ISO_8601_TIME_FORMAT}.SSS${ISO_8601_UTC_OFFSET_FORMAT}`;

export const ZonedDateTimeFormat = {
  UNIFY: {
    /**
     * General date format e.g. "2021-01-01"
     */
    DATE_FORMAT: YYYY_MM_DD,
    /**
     * General time format e.g. "04:00"
     */
    TIME_FORMAT: HH_MM,
    /**
     * General datetime format e.g. "2021-01-01 04:00"
     */
    DATE_TIME_FORMAT: YYYY_MM_DD_HH_MM,
  },

  ISO_8601: {
    /**
     * ISO 8601 date format e.g. "2021-01-01"
     */
    DATE_FORMAT: ISO_8601_DATE_FORMAT,
    /**
     * ISO 8601 time format e.g. "04:00:00"
     */
    TIME_FORMAT: ISO_8601_TIME_FORMAT,
    /**
     * ISO 8601 UTC offset e.g. "+04:00"
     */
    UTC_OFFSET: ISO_8601_UTC_OFFSET_FORMAT,
    /**
     * ISO 8601 datetime format e.g. "2021-01-01T04:00:00.000+04:00"
     */
    DATE_TIME_FORMAT: ISO_8601_DATE_TIME_FORMAT,
  },
} as const;
