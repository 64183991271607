import { Callback } from '@csp/csp-common-model';
import { DmdpPodName, DmdpPodUrl } from './model/DmdpPodConfigs';
import { M2mConfig } from './model/M2mConfig';
import { DmdpConfigService } from './service/DmdpConfigService';

export const dmdpBaseUrl = (): string => DmdpConfigService.getDmdpBaseUrl();
export const m2mConfig = (): M2mConfig => DmdpConfigService.getM2mConfig();

const getBaseUrlOf =
  (podName: DmdpPodName): Callback<DmdpPodUrl> =>
  (): DmdpPodUrl =>
    DmdpConfigService.getDmdpPodConfigs()[podName].baseUrl;

export const applicationpodUrl = getBaseUrlOf('applicationpod');
export const auditpodUrl = getBaseUrlOf('auditpod');
export const cmspodUrl = getBaseUrlOf('cmspod');
export const datachangerecordUrl = getBaseUrlOf('datachangerecord');
export const devicePodUrl = getBaseUrlOf('devicepod');
export const journalpodUrl = getBaseUrlOf('journalpod');
export const medicationPodUrl = getBaseUrlOf('medicationpod');
export const messagingpodUrl = getBaseUrlOf('messagingpod');
export const messagingpodemailUrl = getBaseUrlOf('messagingpodemail');
export const overreaderpodUrl = getBaseUrlOf('overreaderpod');
export const rdspodUrl = getBaseUrlOf('rdspod');
export const studypodUrl = getBaseUrlOf('studypod');
export const userpodUrl = getBaseUrlOf('userpod');
export const workflowpodUrl = getBaseUrlOf('workflowpod');
export const contentpodUrl = getBaseUrlOf('contentpod');
export const hbspodUrl = getBaseUrlOf('biosampling');
