import { RestOptions, ZoomClientId } from '@csp/csp-common-model';
import { ZoomUserV2, ZoomMeetingV2, ZoomMeetingInV2, MeetingInfoV2, ZoomUserInV2 } from '@csp/zoom-api-dto';
import { workflowpodUrl } from '../dmdp-config/dmdpConfig';
import { ZoomUtil } from '../util/ZoomUtil';

const zoomProxyUrl = (): string => `${workflowpodUrl()}/zoom/v1/proxy/zoom/v2`;

const createUser = async (
  { axios, signal }: RestOptions,
  clientId: ZoomClientId,
  user: ZoomUserInV2,
): Promise<ZoomUserV2> => {
  const { data } = await axios.post<ZoomUserV2>(`${zoomProxyUrl()}/users`, user, {
    headers: ZoomUtil.generateHeaders(clientId),
    signal,
  });
  return data;
};

const createMeeting = async (
  { axios, signal }: RestOptions,
  clientId: ZoomClientId,
  userId: string,
  zoomMeetingInV2: ZoomMeetingInV2,
): Promise<ZoomMeetingV2> => {
  const { data } = await axios.post<ZoomMeetingV2>(`${zoomProxyUrl()}/users/${userId}/meetings`, zoomMeetingInV2, {
    headers: ZoomUtil.generateHeaders(clientId),
    signal,
  });
  return data;
};

const getMeetingById = async (
  { axios, signal }: RestOptions,
  clientId: ZoomClientId,
  meetingId: number,
): Promise<MeetingInfoV2> => {
  const { data } = await axios.get<MeetingInfoV2>(`${zoomProxyUrl()}/meetings/${meetingId}`, {
    headers: ZoomUtil.generateHeaders(clientId),
    signal,
  });

  return data;
};

const deleteMeeting = async (
  { axios, signal }: RestOptions,
  clientId: ZoomClientId,
  meetingId: number,
): Promise<void> => {
  const { data } = await axios.delete(`${zoomProxyUrl()}/meetings/${meetingId}`, {
    headers: ZoomUtil.generateHeaders(clientId),
    signal,
  });
  return data;
};

export const ZoomProxyRestServiceV1 = {
  createUser,
  createMeeting,
  getMeetingById,
  deleteMeeting,
};
