import { Divider, MenuItem, OnClick } from '@csp/csp-web-ui';
import { FC } from 'react';

type Items = {
  title: string;
  onClick: OnClick;
};

type Props = {
  items: Items[];
};

export const PopoverFooter: FC<Props> = ({ items }) => (
  <>
    <Divider />
    {items.map(item => (
      <MenuItem
        iconProps={{ name: 'newTab', color: 'textSecondary', size: 'small' }}
        titleProps={{ color: 'textSecondary' }}
        titleBody="body2"
        onClick={item.onClick}
        title={item.title}
        key={`PopoverFooter-item-${item.title}`}
      />
    ))}
  </>
);
