import { nowToZonedDateTimeCurrentZone, zeroZonedDateTime } from '@csp/csp-common-date-util';
import { CspError, ZonedDateTime } from '@csp/csp-common-model';
import { ScheduleWindowInterval } from '../model/ScheduleWindowInterval';
import { GenericRequest } from '../model/schedulingModels/GenericRequest';
import { RequestWhile } from '../model/schedulingModels/RequestWhile';
import { RequestWhileCriterion } from '../model/schedulingModels/RequestWhileCriterion';
import { RequestWhileCriterionType } from '../model/schedulingModels/RequestWhileCriterionType';
import { RequestWhileOperator } from '../model/schedulingModels/RequestWhileOperator';
import { WhileCriteriaUserValues } from '../model/WhileCriteriaUserValues';
import { isWindowActiveNow } from '../util/scheduleUtil';
import { ScheduleIntervalService } from './ScheduleIntervalService';

const appendIntervalIfLastClosed = (
  intervals: ScheduleWindowInterval[],
  windowStart: ZonedDateTime,
): ScheduleWindowInterval[] => {
  const previousInterval = intervals[intervals.length - 1];
  if (!previousInterval || previousInterval.windowEnd) {
    // If the previous window has been closed, create a new
    return [...intervals, ScheduleWindowInterval.from(windowStart, undefined)];
  } else {
    // If the previous window has not been closed, just continue
    return intervals;
  }
};

const closeLastIntervalIfOpen = (
  intervals: ScheduleWindowInterval[],
  windowEnd: ZonedDateTime,
): ScheduleWindowInterval[] => {
  const previousWindow = intervals[intervals.length - 1];
  if (previousWindow && !previousWindow.windowEnd) {
    // If the previous window has not been closed, close it
    return [...intervals.slice(0, -1), ScheduleIntervalService.updateWindowEnd(previousWindow, windowEnd)];
  } else {
    // If we don't have an open window, just continue
    return intervals;
  }
};

const evaluateInvertedWindowIntervals = (windowIntervals: ScheduleWindowInterval[]): ScheduleWindowInterval[] => {
  if (!windowIntervals.length) {
    return [ScheduleWindowInterval.from(zeroZonedDateTime, undefined)]; // An open window from time zero
  } else {
    const invertedIntervals: ScheduleWindowInterval[] = [
      ScheduleWindowInterval.from(zeroZonedDateTime, windowIntervals[0]?.windowStart), // A window from time zero closing when first window starts
    ];

    for (let idx = 0; idx < windowIntervals.length; idx++) {
      const interval = windowIntervals[idx];
      const nextInterval = windowIntervals[idx + 1];
      if (interval?.windowEnd) {
        invertedIntervals.push(ScheduleWindowInterval.from(interval.windowEnd, nextInterval?.windowStart));
      }
    }
    return invertedIntervals;
  }
};

const calculateIntervalsForCriterion = (
  criterion: RequestWhileCriterion,
  userValues: WhileCriteriaUserValues,
): ScheduleWindowInterval[] => {
  if (criterion.type === RequestWhileCriterionType.USER_CUSTOM_STATUS) {
    const customStatusValues =
      userValues.customStatuses.getByType(criterion.subType)?.getStatusValuesInSequenceOldestFirst() ?? [];

    const scheduleWindowIntervals = customStatusValues.reduce<ScheduleWindowInterval[]>((intervals, status) => {
      const isMatchingStatusValue = criterion.values.includes(status.value);
      if (isMatchingStatusValue) {
        return appendIntervalIfLastClosed(intervals, status.timestamp);
      } else {
        return closeLastIntervalIfOpen(intervals, status.timestamp);
      }
    }, []);

    return criterion.operator === RequestWhileOperator.NOT_IN
      ? evaluateInvertedWindowIntervals(scheduleWindowIntervals)
      : scheduleWindowIntervals;
  } else {
    throw CspError.badState(`Unsupported while criterion type: ${criterion.type}`);
  }
};

const getIntervalIntersections = (intervals: ScheduleWindowInterval[][]): ScheduleWindowInterval[] => {
  if (intervals.length <= 1) {
    // Nothing to intersect, just return the first list of intervals
    return intervals[0] ?? [];
  } else {
    let result = intervals.pop() ?? [];
    for (const interval of intervals) {
      result = ScheduleIntervalService.intersectionOfIntervalLists(result, interval);
    }
    return result;
  }
};

const getMatchingIntervals = (
  requestWhile: RequestWhile,
  userValues: WhileCriteriaUserValues,
): ScheduleWindowInterval[] => {
  const criteria = requestWhile.criteria ?? [];

  const intervalsPerCriteria = criteria.map(criterion => calculateIntervalsForCriterion(criterion, userValues));

  // Implicit AND operator, should be union if OR is added
  return getIntervalIntersections(intervalsPerCriteria);
};

/**
 * Whether the input interval overlaps with a matching "while criteria"-interval.
 * Can be used to determine whether the user has had a chance to complete the task.
 */
const isOverlappingWithMatchingInterval = (
  request: GenericRequest,
  userValues: WhileCriteriaUserValues,
  intervalIn: ScheduleWindowInterval,
): boolean => {
  if (request.while) {
    const intervals = getMatchingIntervals(request.while, userValues);
    return intervals.some(interval => ScheduleIntervalService.intersection(interval, intervalIn));
  } else {
    return true;
  }
};

/**
 * Whether the input interval is fully contained by a matching "while criteria"-interval.
 * Can be used to determine if the user has had the entire request window to
 * complete the task.
 */
const isContainedWithinMatchingInterval = (
  request: GenericRequest,
  userValues: WhileCriteriaUserValues,
  intervalIn: ScheduleWindowInterval,
): boolean => {
  if (request.while) {
    const matchingIntervals = getMatchingIntervals(request.while, userValues);
    const nonMatchingIntervals = evaluateInvertedWindowIntervals(matchingIntervals);
    return !nonMatchingIntervals.some(interval => ScheduleIntervalService.intersection(interval, intervalIn));
  } else {
    // If no while config is defined the request is
    // always applicable for the patient, hence considered within matching interval.
    // while config only affects the request when defined.
    return true;
  }
};

const isWithinMatchingIntervalNow = (
  request: GenericRequest,
  userValues: WhileCriteriaUserValues,
  now: ZonedDateTime = nowToZonedDateTimeCurrentZone(),
): boolean => {
  if (request.while) {
    const intervals = getMatchingIntervals(request.while, userValues);
    return intervals.some(interval => isWindowActiveNow(interval, now));
  } else {
    return true;
  }
};

export const RequestWhileService = {
  getMatchingIntervals,
  isOverlappingWithMatchingInterval,
  isContainedWithinMatchingInterval,
  isWithinMatchingIntervalNow,
};
