import { ApiOptions } from '@csp/csp-common-model';
import { isAxiosError } from 'axios';
import { HttpStatus } from '@csp/csp-common-axios';
import { isError } from 'lodash';
import { getContentClientType, getContentVersion, getRole } from '../../utils/configUtil';
import { ContentDeliveryRestServiceV1 } from '../service/ContentDeliveryRestServiceV1';
import { ContentPackageResponse } from '../model/ContentPackageResponse';
import { ContentstackConfig } from '../../config/model/ContentstackConfig';
import { ContentPackageType } from '../../model/ContentPackageType';
import { DEFAULT_CONTENT_LOCALE } from '../../utils/defaultContentLocale';
import { ContentDeliveryClientError } from '../error/ContentDeliveryClientError';
import { toContentRestOptions } from './toContentRestOptions';

/**
 * Fetches the content and schema URLs for a specific package and locale.
 * If the package cannot be found, it will be retried using the DEFAULT_LOCALE
 * @param packageType
 * @param locale
 * @param eTag
 * @param apiOptions
 * @param config
 */
export const fetchContentPackageWithRetry = async (
  packageType: ContentPackageType,
  locale: Lowercase<string>,
  eTag?: string,
  apiOptions?: ApiOptions,
  config?: ContentstackConfig,
): Promise<ContentPackageResponse> => {
  try {
    return await ContentDeliveryRestServiceV1.getContentPackage(
      toContentRestOptions(packageType, apiOptions),
      getContentClientType(config),
      getContentVersion(packageType, config),
      locale,
      packageType,
      eTag,
      getRole(config),
    );
  } catch (e) {
    if (isAxiosError(e) && locale !== DEFAULT_CONTENT_LOCALE && e?.response?.status === HttpStatus.NOT_FOUND) {
      //Try again with default locale
      return await fetchContentPackageWithRetry(packageType, DEFAULT_CONTENT_LOCALE, eTag, apiOptions, config);
    } else {
      if (isError(e)) {
        throw ContentDeliveryClientError.notFound(packageType, locale);
      } else {
        throw e;
      }
    }
  }
};
