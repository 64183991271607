import {
  PreconditionsV1,
  StatusValueMappingV1,
  StatusValueTransitionV1,
  StudyDefinedStatusV1,
  ToValueV1,
} from '@csp/config-schemas';
import { EnumUtil } from '@csp/csp-common-enum-util';
import {
  CspError,
  CustomStatusMutation,
  CustomStatusType,
  CustomStatusValueLookup,
  Maybe,
  PatientCommonStatusType,
  PatientStatusType,
  StateAssert,
  StatusConditions,
  StatusValue,
  StudyDefinedStatusConfig,
  StudyDefinedStatusPresentationConfig,
  toErrorInfo,
} from '@csp/csp-common-model';
import { StatusConditionsMapper } from './StatusConditionsMapper';

type ToValue = {
  value: StatusValue;
  conditions?: StatusConditions;
  isConditionsFulfilled(current: CustomStatusValueLookup): boolean;
};

type ValueTransition = {
  fromValue: StatusValue;
  toValues: ToValue[];
};

type FromStatus = {
  type: CustomStatusType;
  value: StatusValue;
};

type StatusMapping = {
  fromValue: FromStatus;
  toValue: ToValue;
  getNewValueIfMatch(
    fromStatusType: CustomStatusType,
    nextValues: StatusValue[],
    allStatuses: CustomStatusValueLookup,
  ): Maybe<StatusValue>;
};

const checkConditionsFulfilled = (preconditions: Maybe<PreconditionsV1>, current: CustomStatusValueLookup): boolean => {
  const conditions = preconditions ? StatusConditionsMapper.fromV1(preconditions) : undefined;

  return !conditions || !!conditions?.isConditionsFulfilled(current);
};

const toToValue = (v1: ToValueV1): ToValue => {
  const { value, preconditions } = v1;

  const conditions = preconditions ? StatusConditionsMapper.fromV1(preconditions) : undefined;

  const isConditionsFulfilled = (current: CustomStatusValueLookup): boolean =>
    checkConditionsFulfilled(preconditions, current);

  return {
    value,
    conditions,
    isConditionsFulfilled,
  };
};

const toValueTransition = (v1: StatusValueTransitionV1): ValueTransition => {
  const fromValue = v1.fromValue;
  const toValues = v1.toValues?.map(toToValue);

  StateAssert.isTrue(!!fromValue, 'From value must be set in transition');
  StateAssert.isTrue(
    !!toValues,
    'To values array must defined in a transition. Leave empty if not values to transition to',
  );

  return {
    fromValue,
    toValues,
  };
};

const toStatusMapping = (type: CustomStatusType, v1: StatusValueMappingV1): StatusMapping => {
  const fromType = v1.fromStatus.type;
  const fromValue = v1.fromStatus.value;
  const toValue = toToValue(v1.toValue);

  const getNewValueIfMatch = (
    fromStatusType: CustomStatusType,
    nextValues: StatusValue[],
    allStatuses: CustomStatusValueLookup,
  ): Maybe<StatusValue> => {
    if (
      // Match status type
      // Don't update value if the current value is same
      // Check that value transition is supported
      // Ensure that preconditions are fulfilled (if any)
      fromType === fromStatusType &&
      !!fromValue &&
      fromValue === allStatuses.getValueByType(fromType) &&
      toValue.value !== allStatuses.getValueByType(type) &&
      nextValues.includes(toValue.value) &&
      toValue.isConditionsFulfilled(allStatuses)
    ) {
      return toValue.value;
    } else {
      return undefined;
    }
  };

  return {
    fromValue: {
      type: fromType,
      value: fromValue,
    },
    toValue,
    getNewValueIfMatch,
  };
};

const assertType = (type: CustomStatusType): void => {
  StateAssert.isTrue(!!type, 'Study defined status type must be set');
  StateAssert.isNull(
    EnumUtil.fromString(type, PatientStatusType),
    `Study defined status cannot use reserved status type name. Got: ${type}. Reserved: ${EnumUtil.asStrings(
      PatientStatusType,
    ).join(', ')}`,
  );
};

const assertValues = (values: StatusValue[]): void => {
  StateAssert.isTrue(!!values?.length, 'At least one value must be defined for a study defined status');
  StateAssert.isTrue(
    values.length === Array.from(new Set(values)).length,
    'Study define status value name duplications is not allowed',
  );
  values.forEach(value =>
    StateAssert.isFalse(value === 'UNKNOWN', 'UNKNOWN is reserved and not allowed as status value'),
  );
};

const assertValueTransition = (supportedValues: StatusValue[], transitions: ValueTransition[]): void => {
  const transitionFromValues: StatusValue[] = transitions.map(transition => transition.fromValue);

  // Ensure no duplicates of fromValue
  StateAssert.isTrue(
    transitionFromValues.length === Array.from(new Set(transitionFromValues)).length,
    'Study define status transition from value name duplications is not allowed',
  );

  // Ensure that all supported values has a transition config
  supportedValues.forEach(value =>
    StateAssert.isTrue(
      transitionFromValues.includes(value) || value === PatientCommonStatusType.UNKNOWN, // UNKNOWN from value is supported
      `Transition configuration is missing for value: ${value}`,
    ),
  );

  // Ensure no duplications on toValues in same from-to config section
  transitions.forEach(transition => {
    const toValues = transition.toValues.map(toValue => toValue.value);
    StateAssert.isTrue(
      toValues.length === Array.from(new Set(toValues)).length,
      'Study define status transition to value name duplications is not allowed',
    );
  });

  // Ensure that toValues are defined and supported
  const transitionToValues: StatusValue[] = transitions.flatMap(transition =>
    transition.toValues.map(toValue => toValue.value),
  );
  const transitionFromValuesExceptUNKNOWN = transitionFromValues.filter(
    value => value !== PatientCommonStatusType.UNKNOWN,
  );
  const allValuesInTransitions: StatusValue[] = [...transitionFromValuesExceptUNKNOWN, ...transitionToValues];
  allValuesInTransitions.forEach(transitionValue =>
    StateAssert.isTrue(
      supportedValues.includes(transitionValue),
      `Transition values must be defined. Definition is missing for transition value: ${transitionValue}`,
    ),
  );
};

const assertAllowedToSetManually = (values: StatusValue[], allowedToSetManually: StatusValue[]): void => {
  StateAssert.isTrue(!!allowedToSetManually, 'Manual values array must be set even if no values are defined');
  allowedToSetManually.forEach(manuallyValue =>
    StateAssert.isTrue(
      values.includes(manuallyValue),
      `Invalid value in manually value configuration. Missing definition for value: ${manuallyValue}`,
    ),
  );
};

const assertStatusMappings = (values: StatusValue[], mappings: StatusMapping[]): void => {
  mappings.forEach(mapping =>
    StateAssert.isTrue(
      values.includes(mapping.toValue.value),
      `Mapped to-value is not defined. Got: ${mapping.toValue.value}. Available: ${values.join(', ')}`,
    ),
  );
};

const fromV1 = (statusV1: StudyDefinedStatusV1): StudyDefinedStatusConfig => {
  const { type, values, preconditions, allowedTransitions, allowedToSetManually, mappings, presentation } = statusV1;

  try {
    assertType(type);
    StateAssert.isTrue(!!preconditions, 'Precondition must be set for study defined statuses');
    assertValues(values);

    const conditions = StatusConditionsMapper.fromV1(preconditions);

    const valueTransitions = allowedTransitions.map(transitionV1 => toValueTransition(transitionV1));
    assertValueTransition(values, valueTransitions);
    const valueTransitionsByValue: Map<StatusValue, ToValue[]> = new Map();
    valueTransitions.forEach(transition => {
      valueTransitionsByValue.set(transition.fromValue, transition.toValues);
    });

    assertAllowedToSetManually(values, allowedToSetManually);

    const statusMappings = mappings?.map(mappingV1 => toStatusMapping(type, mappingV1)) ?? [];
    assertStatusMappings(values, statusMappings);

    const presentationConfig: StudyDefinedStatusPresentationConfig = {
      type,
      isShowOverviewCard: !!presentation?.showOnOverview,
      isFutureStartDateAllowed: !!presentation?.allowFutureDate,
      isPastStartDateDisallowed: !!presentation?.disallowPastDate,
      isNotSequentialDate: !!presentation?.notSequentialDate,
    };

    const getNextValues = (currentStatuses: CustomStatusValueLookup): StatusValue[] => {
      const currentValue = currentStatuses.getValueByType(type) ?? PatientCommonStatusType.UNKNOWN;
      return currentValue && conditions.isConditionsFulfilled(currentStatuses)
        ? valueTransitionsByValue
            .get(currentValue)
            ?.filter(toValue => toValue.isConditionsFulfilled(currentStatuses))
            .map(toValue => toValue.value) ?? []
        : [];
    };

    const getNextValuesForManualUpdate = (currentStatuses: CustomStatusValueLookup): StatusValue[] =>
      getNextValues(currentStatuses).filter(nextValue => allowedToSetManually.includes(nextValue));

    const isTransitionAllowed = (newValue: StatusValue, currentStatuses: CustomStatusValueLookup): boolean =>
      getNextValues(currentStatuses).includes(newValue);

    const isManualTransitionAllowed = (newValue: StatusValue, currentStatuses: CustomStatusValueLookup): boolean =>
      getNextValuesForManualUpdate(currentStatuses).includes(newValue);

    const isShowStatus = (currentStatuses: CustomStatusValueLookup): boolean =>
      presentationConfig.isShowOverviewCard &&
      (conditions.isConditionsFulfilled(currentStatuses) || !!currentStatuses.getValueByType(type));

    // Mutation
    // Iterate through all mapping and check if from status type and value preconditions are met
    // Ensure that transition to new value is allowed and preconditions is met
    // Append new value to statuses to be targeted for update with same timestamp as the status is depends on
    // Abort after first match to enable mappings to be based on the updated value
    // Returns true if any mutation occurred
    // ignoreTransitionRules - is used to be able to map statuses when the patient is created for first time and not yet have any status
    const mutateIfNeedAndAbortAfterFirstMutation = (
      mutation: CustomStatusMutation,
      ignoreTransitionRules = false,
    ): boolean => {
      let newValue: Maybe<StatusValue> = undefined;
      const mutationClone: CustomStatusMutation = mutation.clone();
      mutationClone.updatedStatuses.forEach(updatedStatus => {
        statusMappings.forEach(statusMapping => {
          if (!newValue) {
            newValue = statusMapping.getNewValueIfMatch(
              updatedStatus.type,
              ignoreTransitionRules ? values : getNextValues(mutationClone),
              mutationClone,
            );

            if (newValue) {
              mutation.addUpdatedStatus({
                type,
                value: newValue,
                timestamp: updatedStatus.timestamp,
              });
            }
          }
        });
      });
      return !!newValue;
    };

    const isValueConfigured = (value: StatusValue): boolean => values.includes(value);

    return {
      type,
      isValueConfigured,
      getNextValues,
      getNextValuesForManualUpdate,
      isTransitionAllowed,
      isManualTransitionAllowed,
      isShowStatus,
      mutateIfNeedAndAbortAfterFirstMutation,
      presentation: presentationConfig,
    };
  } catch (error) {
    throw CspError.badState(
      `Failed to parse study defined status config for status: ${type ?? 'undefined'}. Reason: ${
        toErrorInfo({ error }).reasonMessage
      }`,
    );
  }
};

export const StudyDefinedStatusConfigMapper = {
  fromV1,

  // For test
  toStatusMapping,
};
