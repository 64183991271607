/* eslint-disable */
export const UnifyWebPatientStatusStudyMock = {
  title: 'title',
  followUp: { title: { FOLLOW_UP: '[M]Custom follow-up', SURVIVAL_FOLLOW_UP: '[M]Custom survival' } },
  studyDefined: {
    statuses: [
      {
        type: 'DOSING',
        statusValues: [
          { value: 'DOSE_1', name: 'Dose one - Cycle 1' },
          { value: 'DOSE_2', name: 'Dose two - Cycle 1' },
          { value: 'DOSE_3', name: 'Dose three - Cycle 1' },
        ],
        title: {
          currentStatus: 'Current dose',
          card: 'Dosing confirmation',
          learnMoreSections: 'About',
          learnMoreTransitions: 'Status values and transitions',
          previousStatusValueName: 'Dose',
          confirmNewStatus: 'Selected dose',
        },
        text: {
          nextStatusMd: "Select the patient's new dosing",
          startDateTimeDescriptionMd:
            "The new dose will be added to the patient's dosing history.\n\n**Note:** Ensure that patient has taken the dose before confirm.",
        },
        alert: { startDateTimeMd: 'Dose must have been taken' },
        learnMoreSections: [
          {
            title: 'Section A',
            bodyMd:
              'Lorem ipsum dolor sit amet, **consectetur** _adipiscing elit_, sed do \n\neiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad ',
          },
          { title: 'Section B', bodyMd: '**Body B**' },
        ],
      },
      {
        type: 'DECEASE_PROGRESSION',
        statusValues: [
          { value: 'NO_CHANGE', name: 'No changes observed' },
          { value: 'INCREASE', name: 'Tumour increased' },
          { value: 'DECREASE', name: 'Tumour decreased' },
        ],
        title: {
          currentStatus: 'Current tumour growth',
          card: 'Decease progression',
          learnMoreSections: 'About',
          previousStatusValueName: 'Tumor change',
          confirmNewStatus: 'Tumor change',
        },
        text: {
          nextStatusMd: 'Update tumour growth',
          startDateTimeDescriptionMd: 'Each decease progression are defined in the study protocol',
          dialogBodyMd: 'Are you sure the you want to change tumor growth?',
        },
        alert: {
          startDateTimeMd: 'Make sure that you have checked all preconditions before setting decease progression',
        },
        learnMoreSections: [
          { title: 'Section A', bodyMd: '**Body A**' },
          { title: 'Section B', bodyMd: '**Body B**' },
        ],
      },
      {
        type: 'CBP',
        statusValues: [
          { value: 'CBP_YES', name: 'Yes' },
          { value: 'CBP_NO', name: 'No' },
        ],
        title: {
          currentStatus: 'CBP status',
          card: 'CBP status',
          learnMoreSections: 'About',
          previousStatusValueName: 'CBP change',
          confirmNewStatus: 'New CBP status',
        },
        text: {
          nextStatusMd: 'Update CBP status',
          startDateTimeDescriptionMd: 'CBP statuses are defined in the study protocol',
          dialogBodyMd: 'Are you sure the you want to change CBP status?',
        },
        learnMoreSections: [
          {
            title: 'CBP - Yes',
            bodyMd:
              'CBP- Child Bearing Potential is set on patients that are female and within age to potential have a child. If patient meets the criterias but for other medical reasons are not able to reproduce, patient is not considered to be CBP.  ',
          },
          {
            title: 'CBP - No',
            bodyMd:
              'CBP- Child Bearing Potential is NOT set on patients that are male. And also not on female patients that are no longer able to reproduce.',
          },
        ],
      },
      {
        type: 'COHORT',
        statusValues: [
          { value: 'COHORT_A', name: 'Cohort A' },
          { value: 'COHORT_B', name: 'Cohort B' },
        ],
        title: {
          currentStatus: 'Cohort',
          card: 'Cohort',
          learnMoreSections: 'About',
          previousStatusValueName: 'Cohort',
          confirmNewStatus: 'New cohort',
        },
        text: { nextStatusMd: 'Update Cohort', noPossibleToSetStatusYetMd: 'No cohort to select yet' },
        alert: { statusUpdated: 'Cohort has been set' },
        learnMoreSections: [
          { title: 'Cohort A', bodyMd: 'If patient belongs to Cohort A, select Cohort A.  ' },
          { title: 'Cohort B', bodyMd: 'If patient belongs to Cohort B, select Cohort B.' },
        ],
      },
    ],
  },
};
