import { createAction, createAsyncThunk, createSlice, PayloadAction, Store } from '@reduxjs/toolkit';
import debug from 'debug';
import { CmsContentStatus, CmsService } from '@csp/csp-fe-content';
import { toError } from '@csp/csp-common-util';
import { CmsStoreEntity } from '../model/CmsStoreEntity';
import { CmsGetContentActionPayload } from '../model/CmsGetContentActionPayload';
import { cmsAdapter } from './cmsAdapter';
import { getCmsKey } from './cmsUtils';

const log = debug('Common:Cms:Ducks');

// actions
const getCmsContent = createAction<CmsGetContentActionPayload>('cms/get_content');
const clearCmsByTag = createAction<string>('cms/clear_content_by_tag');
const clearCmsState = createAction('cms/clear_state');

const cmsContentRequestThunk = createAsyncThunk<CmsStoreEntity, CmsGetContentActionPayload, { rejectValue: Error }>(
  'cms/content_request',
  async (input, { rejectWithValue }) => {
    try {
      log('REQUEST', input);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data = await CmsService.getContent<any>(input.cmsContentArguments);
      log('SUCCESS', input, data);
      return data ? data : rejectWithValue(new Error('No CMS response'));
    } catch (error) {
      log('FAILED', input, error);
      return rejectWithValue(toError(error));
    }
  },
);

// reducer
const getIdFromAction = (action: PayloadAction<unknown, string, { arg: CmsGetContentActionPayload }>): string =>
  getCmsKey(action.meta.arg.cmsContentArguments);

export const cmsSlice = createSlice({
  name: 'cms',
  initialState: cmsAdapter.getInitialState(),
  reducers: {
    addEntities: cmsAdapter.upsertMany,
  },
  extraReducers: builder =>
    builder
      .addCase(cmsContentRequestThunk.pending, (state, action) => {
        cmsAdapter.upsertOne(state, {
          id: getIdFromAction(action),
          status: CmsContentStatus.PENDING,
          tag: action.meta.arg.tag,
        });
      })
      .addCase(cmsContentRequestThunk.fulfilled, (state, action) =>
        cmsAdapter.upsertOne(state, {
          id: getIdFromAction(action),
          data: action.payload,
          status: CmsContentStatus.SUCCESS,
        }),
      )
      .addCase(cmsContentRequestThunk.rejected, (state, action) => {
        cmsAdapter.upsertOne(state, {
          id: getIdFromAction(action),
          error: action.payload,
          status: CmsContentStatus.FAILED,
        });
      })
      .addCase(clearCmsByTag, (state, { payload: tag }) => {
        cmsAdapter.removeMany(
          state,
          state.ids.filter(id => state.entities[id]?.tag === tag),
        );
      })
      .addCase(clearCmsState, state => {
        cmsAdapter.removeAll(state);
      }),
});

export const cmsReducer = cmsSlice.reducer;

export const cmsActions = {
  getCmsContent,
  clearCmsByTag,
  cmsContentRequestThunk,
  addEntities: cmsSlice.actions.addEntities,
  clearCmsState,
};

const loadData = (store: Store, data: CmsStoreEntity[]): void => {
  store.dispatch(cmsActions.addEntities(data));
};

export const CmsStoreUtils = {
  loadData,
};
