import { ArrayNotEmpty } from '../../common/model/ArrayNotEmpty';
import { CspError } from '../error/CspError';

function isTrue(state: boolean, message?: string): asserts state {
  if (!state) {
    throw CspError.badState(message);
  }
}

const isFalse = (state: boolean, message?: string): void => {
  isTrue(!state, message);
};

const hasText = (str: string, message?: string): void => {
  isTrue(str != null && str.trim().length > 0, message);
};

const notNull = <T>(val: T, message?: string): asserts val is NonNullable<T> => {
  isTrue(val != null, message);
};

const isNull = <T>(val: T | null | undefined, message?: string): asserts val is null | undefined => {
  isTrue(val == null, message);
};

const notEmpty = <T>(val: T[], message?: string): asserts val is ArrayNotEmpty<T> => {
  isTrue(val.length > 0, message);
};

const isNumber = (val: unknown, message?: string): asserts val is number => {
  isTrue(typeof val === 'number', message);
};

const isFiniteNumber = (val: unknown, message?: string): asserts val is number => {
  isTrue(typeof val === 'number' && Number.isFinite(val), message);
};

const isDefined = <T>(val: T, message?: string): asserts val is NonNullable<T> => {
  isTrue(val !== null && val !== undefined, message);
};

/**
 * Assertion functions API, with throw if condition is not met.
 *
 * Assertions require every name in the call target to be declared with an explicit type annotation.
 */
export interface AssertionFunctions {
  isTrue: typeof isTrue;
  isFalse: typeof isFalse;
  hasText: typeof hasText;
  notNull: typeof notNull;
  isNull: typeof isNull;
  notEmpty: typeof notEmpty;
  isNumber: typeof isNumber;
  isFiniteNumber: typeof isFiniteNumber;
  isDefined: typeof isDefined;
}

export const StateAssert: AssertionFunctions = {
  isTrue,
  isFalse,
  hasText,
  notNull,
  isNull,
  notEmpty,
  isNumber,
  isFiniteNumber,
  isDefined,
};
