import { useBoolean, useOnMount } from '@csp/fe-hooks';
import { IdpStateService } from '@csp/csp-fe-auth';
import { AuthContextState } from '../model/AuthContextState';

export const useAuthContextProvider = (): AuthContextState => {
  const [isAuthenticated, setIsAuthenticated, setIsNotAuthenticated] = useBoolean(IdpStateService.isSignedIn());

  useOnMount(
    () => {
      IdpStateService.addOnSetListener(setIsAuthenticated);
      IdpStateService.addOnSignOutListener(setIsNotAuthenticated);
    },
    () => {
      IdpStateService.removeOnSetListener(setIsAuthenticated);
      IdpStateService.removeOnSignOutListener(setIsNotAuthenticated);
    },
  );

  return {
    isAuthenticated,
    isNotAuthenticated: !isAuthenticated,
  };
};
