import { FC } from 'react';
import { File } from '@csp/csp-content-schema';
import { StringUtil } from '@csp/csp-common-util';
import { LabelWidth } from './model/LabelWidth';
import { ListItemIconAction } from './ListItemIconAction';

type Props = {
  title?: string;
  version?: string; // Not used for anything right now
  versionPublishedDate?: string;
  versionPublishedLabel?: string;
  linkText?: string;
  file?: File;
  labelWidth?: LabelWidth;
};

export const ListItemStudyDocument: FC<Props> = ({
  title,
  versionPublishedDate,
  versionPublishedLabel,
  linkText,
  labelWidth,
  file,
}) => {
  const clickHandler = (): void => {
    if (file?.url) {
      window.open(file.url as string, '_blank');
    }
  };

  return (
    <ListItemIconAction
      listItemProps={{ alignItems: 'flex-start' }}
      iconProps={{ name: 'document', size: 'xl' }}
      label={title}
      labelProps={{ wordBreak: 'break-all' }}
      subtitle={`${versionPublishedLabel} ${StringUtil.removeSeconds(versionPublishedDate)}`}
      action={linkText}
      actionVariant="link"
      onClick={clickHandler}
      labelWidth={labelWidth}
    />
  );
};
