import { CspError, CspErrorType } from '@csp/csp-common-model';
import { storageFactory } from './StorageFactory';

const local = storageFactory(() => localStorage);
const session = storageFactory(() => sessionStorage);

export const StorageService = {
  localStorage: {
    get length(): number {
      return local.length;
    },

    getItem: (key: string): string | undefined => {
      try {
        return local.getItem(key) ?? undefined;
      } catch (e) {
        throw new CspError(CspErrorType.UNHANDLED_CLIENT_ERROR, `Failed to retrieve item (${key}) from localStorage`);
      }
    },

    setItem(key: string, value: string | boolean): void {
      try {
        local.setItem(key, String(value));
      } catch (e) {
        throw new CspError(
          CspErrorType.UNHANDLED_CLIENT_ERROR,
          `Failed to store item  { ${key}: ${value} } in localStorage`,
        );
      }
    },

    removeItem(key: string): void {
      try {
        local.removeItem(key);
      } catch (e) {
        throw new CspError(CspErrorType.UNHANDLED_CLIENT_ERROR, `Failed to remove item (${key}) from localStorage`);
      }
    },

    clear(): void {
      try {
        local.clear();
      } catch (e) {
        throw new CspError(CspErrorType.UNHANDLED_CLIENT_ERROR, `Failed to clear localStorage`);
      }
    },
  },

  sessionStorage: {
    get length(): number {
      return session.length;
    },

    getItem: (key: string): string | undefined => {
      try {
        return session.getItem(key) ?? undefined;
      } catch (e) {
        throw new CspError(CspErrorType.UNHANDLED_CLIENT_ERROR, `Failed to retrieve item (${key}) from sessionStorage`);
      }
    },

    setItem(key: string, value: string | boolean): void {
      try {
        session.setItem(key, String(value));
      } catch (e) {
        throw new CspError(
          CspErrorType.UNHANDLED_CLIENT_ERROR,
          `Failed to store  { ${key}: ${value} } in sessionStorage`,
        );
      }
    },

    removeItem(key: string): void {
      try {
        session.removeItem(key);
      } catch (e) {
        throw new CspError(CspErrorType.UNHANDLED_CLIENT_ERROR, `Failed to remove item (${key}) from sessionStorage`);
      }
    },

    clear(): void {
      try {
        session.clear();
      } catch (e) {
        throw new CspError(CspErrorType.UNHANDLED_CLIENT_ERROR, `Failed to clear sessionStorage`);
      }
    },
  },
};
