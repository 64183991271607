export type DmdpPodUrl = `${string}/${DmdpPodName}` | '';

export type DmdpPodConfig = {
  baseUrl: DmdpPodUrl;
};

export type DmdpPodConfigs = {
  applicationpod: DmdpPodConfig;
  auditpod: DmdpPodConfig;
  cmspod: DmdpPodConfig;
  devicepod: DmdpPodConfig;
  datachangerecord: DmdpPodConfig;
  journalpod: DmdpPodConfig;
  medicationpod: DmdpPodConfig;
  messagingpod: DmdpPodConfig;
  messagingpodemail: DmdpPodConfig;
  overreaderpod: DmdpPodConfig;
  rdspod: DmdpPodConfig;
  resourcepod: DmdpPodConfig;
  studypod: DmdpPodConfig;
  userpod: DmdpPodConfig;
  workflowpod: DmdpPodConfig;
  contentpod: DmdpPodConfig;
  biosampling: DmdpPodConfig;
};

export type DmdpPodName = keyof DmdpPodConfigs;

const empty = (): DmdpPodConfigs => ({
  applicationpod: { baseUrl: '' },
  auditpod: { baseUrl: '' },
  cmspod: { baseUrl: '' },
  datachangerecord: { baseUrl: '' },
  devicepod: { baseUrl: '' },
  journalpod: { baseUrl: '' },
  medicationpod: { baseUrl: '' },
  messagingpod: { baseUrl: '' },
  messagingpodemail: { baseUrl: '' },
  overreaderpod: { baseUrl: '' },
  rdspod: { baseUrl: '' },
  resourcepod: { baseUrl: '' },
  studypod: { baseUrl: '' },
  userpod: { baseUrl: '' },
  workflowpod: { baseUrl: '' },
  contentpod: { baseUrl: '' },
  biosampling: { baseUrl: '' },
});

export const DmdpPodConfigs = {
  empty,
};
