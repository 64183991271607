import { ApiOptions, User, UserId } from '@csp/csp-common-model';
import { toRestOptions } from '@csp/csp-fe-auth';
import { UserRestServiceV1 } from '@csp/dmdp-api-client';
import { Page, QueryV1, largePage } from '@csp/dmdp-api-common-dto';
import { UserV1, UsersV1 } from '@csp/dmdp-api-user-dto';
import { UserFactory } from '../model/UserFactory';

const getMine = async (apiOptions?: ApiOptions): Promise<User> => {
  const user = await UserRestServiceV1.getMine(toRestOptions(apiOptions));
  return UserFactory.from(user);
};

const getUsers = async (userIds: UserId[], apiOptions?: ApiOptions): Promise<User[]> => {
  const { users = [] } = await UserRestServiceV1.getUsersByIds(toRestOptions(apiOptions), userIds);
  return users.map(user => UserFactory.from(user));
};

const getUserV1s = async (userIds: UserId[], apiOptions?: ApiOptions): Promise<UserV1[]> => {
  const { users = [] } = await UserRestServiceV1.getUsersByIds(toRestOptions(apiOptions), userIds);
  return users;
};

const getUserById = async (userId: string, apiOptions?: ApiOptions): Promise<User> => {
  const user = await UserRestServiceV1.getUserById(toRestOptions(apiOptions), userId);
  return UserFactory.from(user);
};

const getUserV1ById = async (userId: string, apiOptions?: ApiOptions): Promise<UserV1> =>
  await UserRestServiceV1.getUserById(toRestOptions(apiOptions), userId);

const query = async (query: QueryV1, page: Page = largePage(), apiOptions?: ApiOptions): Promise<UsersV1> =>
  UserRestServiceV1.query(toRestOptions(apiOptions), query, page);

export const UserService = {
  getMine,
  getUserById,
  getUserV1ById,
  getUsers,
  getUserV1s,
  query,
};
