import { AssessmentOutcomeKey, AssessmentAlgorithmResult, AssessmentRefKey } from '@csp/csp-common-assessment';
import {
  ZonedDateTimeFormatter,
  nowToZonedDateTimeCurrentZone,
  zonedSubtractDuration,
} from '@csp/csp-common-date-util';
import {
  DeviceObservation,
  DeviceObservationValue,
  createDeviceObservationMock,
  createDeviceObservationV1Mock,
} from '@csp/csp-common-devices';
import { MirPefAlgorithmV1, RpmAlgorithmCode, ThresholdUnit } from '@csp/csp-common-rpm-model';
import { DeviceObservationAttributes, DeviceObservationName } from '@csp/device-catalog';
import { AssessmentV1 } from '@csp/dmdp-api-assessment-dto';
import { DeviceObservationV1 } from '@csp/dmdp-api-device-dto';
import { createMockCreator } from '@csp/test-common-utils';

const mockedAssessmentV1: AssessmentV1 = {
  assessmentId: 'assessmentId-123',
  assessmentCode: 'assessmentCode-123',
  userId: 'userId-123',
  eventId: 'eventId-123',
  refs: [],
  meta: {},
  comments: [],
  outcomes: [],
};

export const mirPefAlgorithm: MirPefAlgorithmV1 = {
  algorithmCode: RpmAlgorithmCode.MIR_PEF,
  versionCode: '1',
  payload: {
    threshold: 25,
    unit: ThresholdUnit.PERCENTAGE,
    runInDays: 10,
    consecutiveEvents: 2,
    consecutiveDays: 3,
  },
};

const createDevicePeakFlowObservationValues = (pefValue: number): DeviceObservationValue[] => [
  { name: DeviceObservationAttributes.testType as DeviceObservationName, strVal: 'PeakFlowFev1' },
  { name: DeviceObservationAttributes.pef_cLs as DeviceObservationName, intVal: pefValue },
];

export const createDevicePeakFlowObservationMock = (pefValue: number): DeviceObservation =>
  createDeviceObservationMock({
    id: 'observation-0',
    values: createDevicePeakFlowObservationValues(pefValue),
  });

export const createDevicePeakFlowObservationV1Mock = ({
  observationId = 0,
  sessionCode = 0,
  daysBack = 0,
  pefValue,
}: {
  observationId?: number | string;
  sessionCode?: number | string;
  daysBack?: number;
  pefValue: number;
}): DeviceObservationV1 =>
  createDeviceObservationV1Mock({
    deviceObservationId: `observation-${observationId}`,
    sessionCode: `session-${sessionCode}`,
    synchronizationTimestamp: ZonedDateTimeFormatter.toZonedDateTimeString(
      zonedSubtractDuration(nowToZonedDateTimeCurrentZone(), {
        days: daysBack,
        minutes: Math.floor(Math.random()) * 60,
      }),
    ),
    values: createDevicePeakFlowObservationValues(pefValue),
  });

export const createMirPefAssessmentV1Mock = ({
  isOutOfRange = true,
  observationId = 0,
  assessmentCode = 0,
  runInAverage = 100,
  algorithm = mirPefAlgorithm,
}: {
  isOutOfRange?: boolean;
  observationId?: number | string;
  assessmentCode?: number | string;
  runInAverage?: number;
  algorithm?: MirPefAlgorithmV1;
}): AssessmentV1 => ({
  ...createMockCreator(mockedAssessmentV1)(),
  assessmentId: `assessmentCode-${assessmentCode}`,
  assessmentCode: `assessmentCode-${assessmentCode}`,
  meta: { runInAverage, isOutOfRange },
  outcomes: [
    {
      key: AssessmentOutcomeKey.algorithmResult,
      refs: [{ key: AssessmentRefKey.DEVICE_OBSERVATION_ID, value: `observation-${observationId}` }],
      algorithm: {
        name: algorithm.algorithmCode,
        code: algorithm.algorithmCode,
        version: algorithm.versionCode,
      },
      output: JSON.stringify({
        result: AssessmentAlgorithmResult.ALGORITHM_RESULT_NOT_OK,
      }),
      value: AssessmentAlgorithmResult.ALGORITHM_RESULT_NOT_OK,
    },
  ],
});

export const createMirPefAlgorithmMock = createMockCreator(mirPefAlgorithm);

const createDeviceMasimoObservationValues = (value: number): DeviceObservationValue[] => [
  { name: DeviceObservationAttributes.SPO2 as DeviceObservationName, val: value },
];

export const createDeviceMasimoObservationMock = (value: number): DeviceObservation =>
  createDeviceObservationMock({
    id: 'observation-0',
    values: createDeviceMasimoObservationValues(value),
  });
