import { HealthEventCriteriaFieldV1, HealthEventInV1, HealthEventStatusTypeV1 } from '@csp/dmdp-api-healthevent-dto';
import { HealthEventRestServiceV1, UserRestServiceV1 } from '@csp/dmdp-api-client';
import { ApiOptions, ZonedDateTimeStr } from '@csp/csp-common-model';
import { CriteriaType, Page, pageWithOneItem, QueryUserIdsV1, QueryV1, RefV1 } from '@csp/dmdp-api-common-dto';
import { createPatientHealthEventMetaV1 } from '@csp/dmdp-api-user-dto';
import { HealthEventReference, HealthEventStatusV1, HealthEventV1 } from '@csp/dto';
import { toRestOptions } from '@csp/csp-fe-auth';
import { HealthEvent } from '../model/HealthEvent';
import { HealthEvents } from '../model/HealthEvents';
import { PatientHealthEventFactory } from '../model/PatientHealthEventFactory';
import { toHealthEventStatus, toHealthEventStatusTypeV1 } from './healthEventStatusTypeMapper';

const createHealthEvent = async (
  status: HealthEventStatusV1,
  localTimestamp: string,
  healthEventType: string,
  healthEventReferences: HealthEventReference[],
  apiOptions?: ApiOptions,
): Promise<HealthEventV1> => {
  const request: HealthEventInV1 = {
    type: healthEventType,
    status: toHealthEventStatusTypeV1(status),
    timestamp: localTimestamp,
    refs: healthEventReferences.map(ref => ({ key: ref.referenceType, value: ref.value } as RefV1)),
  };
  const healthEventResponse = await HealthEventRestServiceV1.createHealthEvent(toRestOptions(apiOptions), request);

  const user = await UserRestServiceV1.getMine(toRestOptions(apiOptions));
  const newEvent = PatientHealthEventFactory.from(healthEventResponse.healthEventId, 'NEW', localTimestamp);
  await UserRestServiceV1.addOrUpdateMyMeta(
    toRestOptions(apiOptions),
    user,
    createPatientHealthEventMetaV1(newEvent.toPatientHealthEventV1()),
  );

  return {
    ...healthEventResponse,
    status: toHealthEventStatus(healthEventResponse.status),
  };
};

const updateHealthEventStatus = async (
  healthEventId: string,
  status: HealthEventStatusTypeV1,
  localTimestamp: ZonedDateTimeStr,
  apiOptions?: ApiOptions,
): Promise<void> =>
  await HealthEventRestServiceV1.updateStatusOfHealthEvent(toRestOptions(apiOptions), healthEventId, {
    status,
    localTimestamp,
  });

const queryHealthEvents = async (
  query: QueryUserIdsV1,
  apiOptions?: ApiOptions,
  page?: Page,
): Promise<HealthEvents> => {
  const response = await HealthEventRestServiceV1.query(toRestOptions(apiOptions), query, page);
  return HealthEvents.from(response);
};

const queryMyHealthEvents = async (query: QueryV1, apiOptions?: ApiOptions, page?: Page): Promise<HealthEvents> => {
  const response = await HealthEventRestServiceV1.queryMine(toRestOptions(apiOptions), query, page);
  return HealthEvents.from(response);
};

const getByHealthEventId = async (healthEventId: string, apiOptions?: ApiOptions): Promise<HealthEvent> => {
  const healthEventV1 = await HealthEventRestServiceV1.getByHealthEventId(toRestOptions(apiOptions), healthEventId);
  return HealthEvent.from(healthEventV1);
};

/**
 * User is only allowed to report a new health event if they don't have an existing one
 */
const getCanIReportHealthEvent = async (apiOptions?: ApiOptions): Promise<boolean> => {
  const statusesRepresentingExistingHealthEvents: HealthEventStatusTypeV1[] = ['NEW', 'ONGOING'];
  const query = {
    criterion: {
      criteria: [
        {
          fieldCriterion: {
            field: HealthEventCriteriaFieldV1.STATUS,
            type: CriteriaType.IN,
            values: statusesRepresentingExistingHealthEvents,
          },
          criteria: [],
        },
      ],
    },
  };
  const myEvents = await queryMyHealthEvents(query, apiOptions, pageWithOneItem());

  return myEvents.events.length === 0;
};

export const HealthEventService = {
  createHealthEvent,
  updateHealthEventStatus,
  queryHealthEvents,
  queryMyHealthEvents,
  getCanIReportHealthEvent,
  getByHealthEventId,
};
