import { EventIdStr, Maybe, StateAssert, UserId } from '@csp/csp-common-model';
import { ScheduleRef } from '@csp/csp-common-scheduling';
import {
  DeviceSessionAggregateCriteriaFieldV1,
  DeviceSessionAggregateGroupFieldV1,
  DeviceSessionAggregateIncludeFieldV1,
  DeviceSessionAggregateQueryCriterionV1,
  DeviceSessionAggregateQueryOperatorV1,
  DeviceSessionAggregateQueryTypeV1,
  DeviceSessionAggregateV1,
} from '@csp/dmdp-api-device-dto';
import { groupBy, uniq } from 'lodash';
import { toTimezoneStr } from '@csp/csp-common-date-util';
import { ScheduleRequestMetricCriterion } from '../../model/ScheduleRequestMetricCriterion';
import { DeviceScheduleRequestStatusType } from '../model/DeviceScheduleRequestStatusTypes';
import { DeviceSessionStatus } from '../model/DeviceSessionStatus';
import { ScheduleRequestDateUntilCriteria } from '../../model/ScheduleRequestDateUntilCriteria';

type ScheduleCodeGroup = {
  scheduleCode: string;
  requestCodes: string[];
};

const toRequestRefCriteria = ({
  scheduleCode,
  requestCodes,
}: ScheduleRef): DeviceSessionAggregateQueryCriterionV1[] => [
  {
    fieldCriterion: {
      field: DeviceSessionAggregateCriteriaFieldV1.SCHEDULE_REQUEST_REF_SCHEDULE_CODE,
      type: DeviceSessionAggregateQueryTypeV1.EQ,
      values: [scheduleCode],
    },
  },
  {
    fieldCriterion: {
      field: DeviceSessionAggregateCriteriaFieldV1.SCHEDULE_REQUEST_REF_REQUEST_CODE,
      type: DeviceSessionAggregateQueryTypeV1.IN,
      values: requestCodes,
    },
  },
];

const createScheduleAndMissedSessionIncludeCriteria = (): DeviceSessionAggregateQueryCriterionV1 => ({
  operator: DeviceSessionAggregateQueryOperatorV1.OR,
  criteria: [
    {
      fieldCriterion: {
        field: DeviceSessionAggregateCriteriaFieldV1.SCHEDULE_REQUEST_STATUS,
        type: DeviceSessionAggregateQueryTypeV1.EQ,
        values: [DeviceScheduleRequestStatusType.MISSED],
      },
    },
    {
      operator: DeviceSessionAggregateQueryOperatorV1.AND,
      criteria: [
        {
          fieldCriterion: {
            field: DeviceSessionAggregateCriteriaFieldV1.SCHEDULE_REQUEST_STATUS,
            type: DeviceSessionAggregateQueryTypeV1.EQ,
            values: [DeviceScheduleRequestStatusType.SCHEDULED],
          },
        },
        {
          fieldCriterion: {
            field: DeviceSessionAggregateCriteriaFieldV1.SESSION_STATUS,
            type: DeviceSessionAggregateQueryTypeV1.EQ,
            values: [DeviceSessionStatus.COMPLETE],
          },
        },
      ],
    },
  ],
});

const toTotalMetricsCriterion = ({
  scheduleCode,
  requestCodes,
}: ScheduleCodeGroup): DeviceSessionAggregateQueryCriterionV1 => ({
  operator: DeviceSessionAggregateQueryOperatorV1.AND,
  criteria: [
    {
      fieldCriterion: {
        field: DeviceSessionAggregateCriteriaFieldV1.SCHEDULE_REQUEST_REF_SCHEDULE_CODE,
        type: DeviceSessionAggregateQueryTypeV1.EQ,
        values: [scheduleCode],
      },
    },
    {
      fieldCriterion: {
        field: DeviceSessionAggregateCriteriaFieldV1.SCHEDULE_REQUEST_REF_REQUEST_CODE,
        type: DeviceSessionAggregateQueryTypeV1.IN,
        values: requestCodes,
      },
    },
  ],
});

const toRecentMetricsCriterion = ({
  scheduleRef,
  recentPeriodZonedDateTimeStr,
}: ScheduleRequestMetricCriterion): DeviceSessionAggregateQueryCriterionV1 => {
  StateAssert.isTrue(!!recentPeriodZonedDateTimeStr, 'Recent time period must be set');

  return {
    operator: DeviceSessionAggregateQueryOperatorV1.AND,
    criteria: [
      ...toRequestRefCriteria(scheduleRef),
      {
        fieldCriterion: {
          field: DeviceSessionAggregateCriteriaFieldV1.SESSION_TIMESTAMP,
          type: DeviceSessionAggregateQueryTypeV1.GREATER_THAN,
          values: [recentPeriodZonedDateTimeStr],
        },
      },
    ],
  };
};

const createTotalAggregateCountQuery = (
  criteria: ScheduleRequestMetricCriterion[],
): DeviceSessionAggregateQueryCriterionV1[] => {
  const groupByScheduleCode = groupBy(criteria, c => c.scheduleRef.scheduleCode);
  return Object.entries(groupByScheduleCode)
    .map(([key, scheduleCodes]) => ({
      scheduleCode: key,
      requestCodes: uniq(scheduleCodes.flatMap(criteria => criteria.scheduleRef.requestCodes)),
    }))
    .map(toTotalMetricsCriterion);
};

const createRecentPeriodAggregateCountQuery = (
  criteria: ScheduleRequestMetricCriterion[],
): DeviceSessionAggregateQueryCriterionV1[] => criteria.map(toRecentMetricsCriterion);

const createMaxEventIdAggregateQuery = (
  maxEventId: Maybe<EventIdStr>,
  scheduleRequestCriteria: ScheduleRequestMetricCriterion[],
): DeviceSessionAggregateV1 => {
  const criteria: DeviceSessionAggregateQueryCriterionV1[] = [
    createScheduleAndMissedSessionIncludeCriteria(),
    {
      operator: DeviceSessionAggregateQueryOperatorV1.OR,
      criteria: createTotalAggregateCountQuery(scheduleRequestCriteria),
    },
  ];

  if (maxEventId) {
    criteria.push({
      fieldCriterion: {
        field: DeviceSessionAggregateCriteriaFieldV1.EVENT_ID,
        type: DeviceSessionAggregateQueryTypeV1.GREATER_THAN,
        values: [maxEventId],
      },
    });
  }

  return {
    match: {
      criterion: {
        operator: DeviceSessionAggregateQueryOperatorV1.AND,
        criteria,
      },
    },
    aggregateFields: [DeviceSessionAggregateIncludeFieldV1.MAX_EVENT_ID],
    group: {
      fields: [
        DeviceSessionAggregateGroupFieldV1.USER_ID,
        DeviceSessionAggregateGroupFieldV1.SCHEDULE_REQUEST_REF_SCHEDULE_CODE,
        DeviceSessionAggregateGroupFieldV1.SCHEDULE_REQUEST_REF_REQUEST_CODE,
      ],
    },
  };
};

const createMetricCountAggregateQuery = (
  userId: UserId,
  criteria: ScheduleRequestMetricCriterion[],
  isTotal: boolean,
): DeviceSessionAggregateV1 => {
  StateAssert.notEmpty(criteria, 'At least one criterion must be included');

  return {
    match: {
      criterion: {
        operator: DeviceSessionAggregateQueryOperatorV1.AND,
        criteria: [
          {
            fieldCriterion: {
              field: DeviceSessionAggregateCriteriaFieldV1.USER_ID,
              type: DeviceSessionAggregateQueryTypeV1.EQ,
              values: [userId],
            },
          },
          createScheduleAndMissedSessionIncludeCriteria(),
          {
            operator: DeviceSessionAggregateQueryOperatorV1.OR,
            criteria: isTotal
              ? createTotalAggregateCountQuery(criteria)
              : createRecentPeriodAggregateCountQuery(criteria),
          },
        ],
      },
    },
    aggregateFields: [
      DeviceSessionAggregateIncludeFieldV1.MAX_SESSION_TIMESTAMP,
      DeviceSessionAggregateIncludeFieldV1.COUNT,
    ],
    group: {
      fields: [
        DeviceSessionAggregateGroupFieldV1.USER_ID,
        DeviceSessionAggregateGroupFieldV1.SCHEDULE_REQUEST_REF_SCHEDULE_CODE,
        DeviceSessionAggregateGroupFieldV1.SCHEDULE_REQUEST_REF_REQUEST_CODE,
        DeviceSessionAggregateGroupFieldV1.SCHEDULE_REQUEST_STATUS,
      ],
    },
  };
};
const createMetricCountUntilDateAggregateQuery = (
  userId: UserId,
  criteria: ScheduleRequestDateUntilCriteria,
): DeviceSessionAggregateV1 => ({
  match: {
    criterion: {
      operator: DeviceSessionAggregateQueryOperatorV1.AND,
      criteria: [
        ...toRequestRefCriteria(criteria.scheduleRef),
        {
          fieldCriterion: {
            field: DeviceSessionAggregateCriteriaFieldV1.USER_ID,
            type: DeviceSessionAggregateQueryTypeV1.EQ,
            values: [userId],
          },
        },
        createScheduleAndMissedSessionIncludeCriteria(),
        {
          fieldCriterion: {
            field: DeviceSessionAggregateCriteriaFieldV1.SESSION_TIMESTAMP,
            type: DeviceSessionAggregateQueryTypeV1.LESS_THAN_EQ,
            values: [toTimezoneStr(criteria.maxDate)],
          },
        },
      ],
    },
  },
  aggregateFields: [DeviceSessionAggregateIncludeFieldV1.COUNT],
  group: {
    fields: [
      DeviceSessionAggregateGroupFieldV1.USER_ID,
      DeviceSessionAggregateGroupFieldV1.SCHEDULE_REQUEST_REF_SCHEDULE_CODE,
      DeviceSessionAggregateGroupFieldV1.SCHEDULE_REQUEST_REF_REQUEST_CODE,
      DeviceSessionAggregateGroupFieldV1.SESSION_STATUS,
    ],
  },
});

export const DeviceSessionAggregateQueryMapperV1 = {
  createMaxEventIdAggregateQuery,
  createMetricCountAggregateQuery,
  createMetricCountUntilDateAggregateQuery,
};
