import { RestOptions } from '@csp/csp-common-model';
import { CustomStatusValueInV1, largePage, Page, QueryV1 } from '@csp/dmdp-api-common-dto';
import {
  TreeInV1,
  CountryInV1,
  CountryUpdateInV1,
  LocationInV1,
  LocationV1,
  OrgMetaV1,
  OrgsV1,
  OrgUpdateInBaseV1,
  OrgV1,
  PracticeInV1,
  PracticeUpdateInV1,
  RegionInV1,
  RegionUpdateInV1,
  ContactPointInV1,
  ContactPointV1,
} from '@csp/dmdp-api-org-dto';

import { userpodUrl } from '../../dmdp-config/dmdpConfig';

const orgUrl = (): string => `${userpodUrl()}/organization/v1`;

const query = async ({ axios, signal }: RestOptions, query: QueryV1, page: Page = largePage()): Promise<OrgsV1> => {
  const { data } = await axios.post<OrgsV1>(`${orgUrl()}/query`, query, {
    params: page,
    signal,
  });
  return data;
};

const getById = async ({ axios, signal }: RestOptions, orgId: string): Promise<OrgV1> => {
  const resp = await axios.get<OrgV1>(`${orgUrl()}/${orgId}`, {
    signal,
  });
  return resp.data;
};

const addTree = async ({ axios, signal }: RestOptions, treeInV1: TreeInV1): Promise<OrgV1> => {
  const resp = await axios.post<OrgV1>(`${orgUrl()}/tree`, treeInV1, {
    signal,
  });
  return resp.data;
};

const updateOrg = async (
  { axios, signal }: RestOptions,
  orgId: string,
  orgUpdateInBaseV1: OrgUpdateInBaseV1,
): Promise<void> => {
  await axios.put(`${orgUrl()}/${orgId}`, orgUpdateInBaseV1, {
    signal,
  });
};

const upsertMeta = async ({ axios, signal }: RestOptions, orgId: string, orgMetaV1: OrgMetaV1): Promise<OrgMetaV1> => {
  const url = `${orgUrl()}/meta/${orgId}`;
  const resp = await axios.put(url, orgMetaV1, {
    signal,
  });
  return resp.data;
};

const addCountry = async ({ axios, signal }: RestOptions, countryInV1: CountryInV1): Promise<OrgV1> => {
  const resp = await axios.post<OrgV1>(`${orgUrl()}/country`, countryInV1, {
    signal,
  });
  return resp.data;
};

const updateCountry = async (
  { axios, signal }: RestOptions,
  orgId: string,
  countryUpdateInV1: CountryUpdateInV1,
): Promise<void> => {
  await axios.put(`${orgUrl()}/country/${orgId}`, countryUpdateInV1, {
    signal,
  });
};

const updateRegion = async (
  { axios, signal }: RestOptions,
  orgId: string,
  regionUpdateInV1: RegionUpdateInV1,
): Promise<void> => {
  await axios.put(`${orgUrl()}/region/${orgId}`, regionUpdateInV1, {
    signal,
  });
};

const addRegion = async ({ axios, signal }: RestOptions, regionInV1: RegionInV1): Promise<OrgV1> => {
  const resp = await axios.post<OrgV1>(`${orgUrl()}/region`, regionInV1, {
    signal,
  });
  return resp.data;
};

const addPractice = async ({ axios, signal }: RestOptions, practiceInV1: PracticeInV1): Promise<OrgV1> => {
  const resp = await axios.post<OrgV1>(`${orgUrl()}/practice`, practiceInV1, {
    signal,
  });
  return resp.data;
};

const updatePractice = async (
  { axios, signal }: RestOptions,
  orgId: string,
  practiceUpdateInV1: PracticeUpdateInV1,
): Promise<void> => {
  await axios.put(`${orgUrl()}/practice/${orgId}`, practiceUpdateInV1, {
    signal,
  });
};

const activate = async ({ axios, signal }: RestOptions, orgId: string): Promise<void> => {
  await axios.put(`${orgUrl()}/${orgId}/activate`, null, {
    signal,
  });
};

const lock = async ({ axios, signal }: RestOptions, orgId: string): Promise<void> => {
  await axios.put(`${orgUrl()}/${orgId}/lock`, null, {
    signal,
  });
};

const enableTestState = async ({ axios, signal }: RestOptions, orgId: string): Promise<void> => {
  await axios.put(`${orgUrl()}/${orgId}/test`, null, {
    signal,
  });
};

const disableTestState = async ({ axios, signal }: RestOptions, orgId: string): Promise<void> => {
  await axios.delete(`${orgUrl()}/${orgId}/test`, {
    signal,
  });
};

const updateContactPoint = async (
  { axios, signal }: RestOptions,
  contactPointInput: ContactPointInV1,
  orgId: string,
  locationId: string,
  contactPointUpdateId: string,
): Promise<ContactPointV1 | void> => {
  await axios.put(
    `${orgUrl()}/practice/${orgId}/location/${locationId}/contactPoint/${contactPointUpdateId}`,
    contactPointInput,
    {
      signal,
    },
  );
  return { contactPointId: contactPointUpdateId, ...contactPointInput };
};

const addContactPoint = async (
  { axios, signal }: RestOptions,
  contactPointInput: ContactPointInV1,
  orgId: string,
  locationId: string,
): Promise<ContactPointV1 | void> => {
  const rest = await axios.post(
    `${orgUrl()}/practice/${orgId}/location/${locationId}/contactPoint`,
    contactPointInput,
    {
      signal,
    },
  );
  return rest.data;
};

const addAdditionalLocation = async (
  { axios, signal }: RestOptions,
  orgId: string,
  location: LocationInV1,
): Promise<LocationV1> => {
  const resp = await axios.post(`${orgUrl()}/practice/${orgId}/location`, location, {
    signal,
  });
  return resp.data;
};

const updateAdditionalLocation = async (
  { axios, signal }: RestOptions,
  orgId: string,
  location: LocationInV1,
  locationId: string,
): Promise<void> =>
  await axios.put(`${orgUrl()}/practice/${orgId}/location/${locationId}`, location, {
    signal,
  });

const upsertCustomStatus = async (
  { axios, signal }: RestOptions,
  orgId: string,
  customStatusType: string,
  customStatusValue: CustomStatusValueInV1,
): Promise<void> =>
  await axios.put(`${orgUrl()}/${orgId}/customStatus/${customStatusType}`, customStatusValue, {
    signal,
  });

export const OrgRestServiceV1 = {
  activate,
  addContactPoint,
  addCountry,
  addPractice,
  addRegion,
  addTree,
  disableTestState,
  enableTestState,
  getById,
  lock,
  query,
  updateCountry,
  updateOrg,
  updatePractice,
  updateRegion,
  updateContactPoint,
  addAdditionalLocation,
  updateAdditionalLocation,
  upsertCustomStatus,
  upsertMeta,
};
