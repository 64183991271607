/* eslint-disable */
export const UnifyAppVideoTrainingConsentDocumentSchema = {
  title: 'UnifyAppVideoTrainingConsentDocument',
  type: 'object',
  properties: {
    title: { type: 'string' },
    consentType: { title: 'Consent type', type: 'string' },
    version: { type: 'string', title: 'Version', helper: 'The version of the document', mock: '1.0' },
    context: {
      type: 'array',
      title: 'Document context',
      helper: 'Document will be accessible within these contexts',
      items: { type: 'string', enum: ['APP'] },
    },
    countryCodes: {
      type: 'array',
      title: 'Countries',
      helper: 'Link will only be shown for users in these countries',
      items: {
        type: 'string',
        enum: [
          'AR',
          'AT',
          'AU',
          'BE',
          'BG',
          'BR',
          'CA',
          'CL',
          'CN',
          'CO',
          'CZ',
          'DE',
          'DK',
          'EG',
          'ES',
          'FI',
          'FR',
          'GB',
          'GR',
          'HK',
          'HU',
          'IE',
          'IL',
          'IN',
          'IT',
          'JP',
          'KR',
          'MT',
          'MX',
          'MY',
          'NL',
          'NO',
          'NZ',
          'PA',
          'PE',
          'PH',
          'PL',
          'PT',
          'RO',
          'RU',
          'SA',
          'SE',
          'SG',
          'SK',
          'SW',
          'TH',
          'TR',
          'TW',
          'UA',
          'US',
          'VE',
          'VN',
          'XX',
          'ZA',
          'ZZ',
        ],
      },
    },
    header: { type: 'string', mock: 'Training title', placeholder: 'Training title' },
    subHeader: { type: 'string', mock: 'Training sub header', placeholder: 'Training sub header' },
    video: {
      type: 'object',
      title: 'Video',
      properties: {
        video: { type: 'object', title: 'Video file', helper: 'Video to be played', _attachment: { id: 'videoUrl' } },
        coverImage: {
          type: 'object',
          title: 'Video cover image',
          helper: 'Image that will be shown while video is not playing',
          _attachment: { id: 'videoCover' },
        },
      },
    },
    consentCheckboxAccessibilityLabel: {
      type: 'string',
      mock: 'Confirmation of training performed',
      placeholder: 'Confirmation of training performed',
    },
    consentCheckboxLabel: {
      type: 'string',
      mock: "I've performed training for ...",
      placeholder: "I've performed training for ...",
    },
    category: {
      type: 'string',
      title: 'Document category',
      helper: 'Document category to be displayed in web portal',
      mock: 'APP',
      enum: ['APP', 'DEVICE'],
    },
  },
  description: 'unify:appvideotrainingconsentdocument',
  $schema: 'http://json-schema.org/draft-07/schema#',
};
