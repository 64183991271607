import { ActionV2, ZoomUserInV2 } from '@csp/zoom-api-dto';

const FIRST_NAME = 'Unify';

const toZoomUserInV2 = (email: string): ZoomUserInV2 => ({
  action: ActionV2.CUST_CREATE,
  user_info: {
    type: 1,
    email,
    first_name: FIRST_NAME,
  },
});

export const ZoomUserMapper = {
  toZoomUserInV2,
};
