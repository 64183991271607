import { GenericSchedule } from '@csp/csp-common-scheduling';
import { DeviceObservationActiveScheduleV1, DeviceObservationActiveVersionWindowV1 } from '@csp/dmdp-api-device-dto';
import { DeviceObservationVersion } from './DeviceObservationVersion';

export type DeviceObservationSchedule = GenericSchedule<DeviceObservationVersion>;

const from = ({
  scheduleCode,
  activeVersionWindows,
}: DeviceObservationActiveScheduleV1): DeviceObservationSchedule => ({
  scheduleCode,
  activeVersionWindows: activeVersionWindows.map((version: DeviceObservationActiveVersionWindowV1) =>
    DeviceObservationVersion.from(scheduleCode, version),
  ),
});

export const DeviceObservationSchedule = {
  from,
};
