import { FlexRow } from '@csp/csp-web-ui';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { isLoggedInSelector } from '../../security/ducks/principalContextSelectors';
import { SystemLinksDrawerContainer } from '../../system-links/container/SystemLinksDrawerContainer';
import { TechnicalSupportDrawerContainer } from '../../technical-support/container/TechnicalSupportDrawerContainer';
import { Avatar, AvatarProps } from './Avatar';

export type HeaderNavToolsBoxProps = {
  avatarProps?: AvatarProps;
  isHidden?: boolean;
  showSystemLinks?: boolean;
  showTechnicalSupport?: boolean;
};

export const HeaderNavToolsBox: FC<HeaderNavToolsBoxProps> = ({
  avatarProps,
  isHidden = false,
  showSystemLinks = true,
  showTechnicalSupport = true,
}) => {
  const hasChosenStudy = useSelector(isLoggedInSelector);

  if (isHidden) {
    return null;
  } else {
    return (
      <FlexRow>
        {hasChosenStudy && (
          <>
            {showTechnicalSupport && <TechnicalSupportDrawerContainer />}
            {showSystemLinks && <SystemLinksDrawerContainer />}
          </>
        )}
        <Avatar {...avatarProps} />
      </FlexRow>
    );
  }
};
