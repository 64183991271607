/* eslint-disable */
export const UnifyWebRemotePatientMonitoringSchema = {
  title: 'UnifyWebRemotePatientMonitoring',
  type: 'object',
  properties: {
    rpmEvents: {
      type: 'object',
      title: 'RPM Events',
      properties: {
        tab: { type: 'string', title: 'Patient List RPM Tab', mock: 'Data events' },
        noData: { type: 'string', title: 'No Available Data', mock: 'No data reported' },
      },
    },
    rpmModules: {
      type: 'array',
      title: 'RPM Modules',
      helper: 'Defines each modules values',
      items: {
        type: 'object',
        title: 'RPM Module Content',
        required: ['algorithmCode', 'headerTitle'],
        properties: {
          algorithmCode: {
            title: 'Algorithm Code',
            type: 'string',
            helper: 'Key to find headerTitle',
            enum: ['GI_TOX', 'MARSDEN_WEIGHT_SCALE', 'MASIMO_SPO2', 'MIR_PEF'],
          },
          headerTitle: { title: 'Header Title', helper: 'The title of the RPM module tab', type: 'string' },
          detailsHeader: {
            title: 'Details header',
            helper: 'The title of the details view for a specific RPM event',
            type: 'string',
          },
          tracker: {
            type: 'object',
            title: 'RPM Tracker Content',
            helper: 'Defines an RPM tracker',
            required: ['disclaimerBodyMd', 'cards'],
            properties: {
              disclaimerBodyMd: {
                title: 'Disclaimer Body Markdown',
                helper: 'Markdown displayed in the tracker disclaimer',
                type: 'string',
              },
              cards: {
                type: 'array',
                title: 'RPM Tracker Cards Content',
                helper: 'Defines each card in the tracker',
                items: {
                  type: 'object',
                  title: 'RPM Tracker Card Content',
                  required: ['cardCode', 'charts'],
                  properties: {
                    cardCode: { title: 'Card Code', type: 'string' },
                    charts: {
                      type: 'array',
                      title: 'RPM Tracker Card Charts',
                      helper: 'Defines each chart in a card',
                      items: {
                        type: 'object',
                        title: 'RPM Tracker Chart Content',
                        required: ['chartCode', 'parameters'],
                        properties: {
                          chartCode: { title: 'Chart Code', type: 'string' },
                          chartTitle: { title: 'Chart Title', type: 'string', helper: 'Optional title of the chart' },
                          parameters: {
                            type: 'array',
                            title: 'RPM Tracker Chart Parameters',
                            helper: 'Defines each parameter (y-axis values) in a chart',
                            items: {
                              type: 'object',
                              title: 'RPM Tracker Parameter Content',
                              required: ['parameterCode', 'parameterLabel'],
                              properties: {
                                parameterCode: { title: 'Parameter Code', type: 'string' },
                                parameterLabel: { title: 'Parameter Label', type: 'string' },
                                parameterTooltipLabel: { title: 'Parameter Tooltip Label', type: 'string' },
                                parameterDetailsLabel: { title: 'Parameter Details Label', type: 'string' },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
      mock: [
        {
          algorithmCode: 'GI_TOX',
          headerTitle: 'Digestive symptoms',
          detailsHeader: 'Digestive symptoms ${suffix}',
          tracker: {
            disclaimerBodyMd:
              'This remote patient monitoring solution only captures the following gastrointestinal symptoms: change in appetite, nausea, vomiting, and constipation, as well as symptoms associated with dehydration. This patient might experience additional symptoms that are not reported here. Please follow up with your patient if you would like to learn more about their symptoms. In the absence of medical follow up, this data does not constitute a reportable safety event.',
            cards: [
              {
                cardCode: 'GI_TOX_CARD',
                charts: [
                  {
                    chartCode: 'GI_TOX_OVERVIEW_CHART',
                    chartTitle: 'Symptoms overview',
                    parameters: [
                      { parameterCode: 'APPETITE', parameterLabel: 'Change in appetite' },
                      { parameterCode: 'NAUSEA', parameterLabel: 'Nausea' },
                      { parameterCode: 'VOMITING', parameterLabel: 'Vomiting' },
                      { parameterCode: 'CONSTIPATION', parameterLabel: 'Constipation' },
                      { parameterCode: 'DIARRHEA', parameterLabel: 'Diarrhea' },
                      { parameterCode: 'NONE', parameterLabel: 'None' },
                    ],
                  },
                  {
                    chartCode: 'GI_TOX_MEDICATION_CHART',
                    parameters: [{ parameterCode: 'MEDICATION', parameterLabel: 'Medication taken' }],
                  },
                ],
              },
            ],
          },
        },
        {
          algorithmCode: 'MARSDEN_WEIGHT_SCALE',
          headerTitle: 'Weight events',
          tracker: { disclaimerBodyMd: 'Disclaimer markdown for weight events', cards: [] },
        },
        {
          algorithmCode: 'MIR_PEF',
          headerTitle: 'MIR PEF events',
          tracker: { disclaimerBodyMd: 'Disclaimer markdown for weight events', cards: [] },
        },
        {
          algorithmCode: 'MASIMO_SPO2',
          headerTitle: 'Masimo SPO2 events',
          tracker: { disclaimerBodyMd: 'Disclaimer markdown for weight events', cards: [] },
        },
      ],
    },
    symptomEventDetails: {
      type: 'object',
      title: 'Symptom event details content',
      helper: 'Study specific content for symptom event details oriented content.',
      properties: {
        learnMore: {
          type: 'array',
          title: 'Symptom event details learn more content',
          items: {
            type: 'object',
            title: 'Symptom event details learn more item',
            properties: {
              header: { type: 'string', title: 'Section header' },
              bodyMd: { type: 'string', title: 'Section body (markdown is supported)' },
            },
          },
          mock: [
            {
              header: 'Lorem ipsum dolor sit amet',
              bodyMd:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et do',
            },
            {
              header: 'Lorem ipsum dolor sit amet 2',
              bodyMd:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et do',
            },
            {
              header: 'Lorem ipsum dolor sit amet 3',
              bodyMd:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et do',
            },
          ],
        },
      },
    },
  },
  description: 'unify:webremotepatientmonitoring',
  $schema: 'http://json-schema.org/draft-07/schema#',
};
