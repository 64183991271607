import { DataChangeStatus } from '@csp/csp-common-dcf-model';
import { Box } from '@csp/csp-web-ui';
import type { NavTab } from '@csp/web-common';
import type { FC } from 'react';
import { CspPath } from '../../../CspPath';
import { StaticContentService } from '../../../common/service/StaticContentService';
import { useDataChangeQuery } from '../../data-change/data-change-list/hook/useDataChangeQuery';
import { DataChangeQueryFilterUtil } from '../../data-change/data-change-list/util/DataChangeQueryFilterUtil';
import { NavTabsComponent } from '../component/NavTabsComponent';
import { useSelectedNavTab } from '../hooks/useSelectedNavTab';

export const ReportsNavSubTabsContainer: FC = () => {
  const reportsSubNavigationBlock = StaticContentService.getGenericBlock('webHcpReportsPage', 'tabs');

  const initialQueryFilter = DataChangeQueryFilterUtil.toQueryFilter('reports-nav-sub-tabs', {
    pageSize: 100,
    criteria: {
      statuses: [DataChangeStatus.PENDING_REVIEW],
    },
  });

  const { data: pendingReviews } = useDataChangeQuery(initialQueryFilter);

  const mainNavTabs: NavTab<CspPath>[] = [
    {
      label: reportsSubNavigationBlock.button.dataChanges.label,
      path: CspPath.REPORTS_DATA_CHANGES,
      badgeContent: pendingReviews.length,
    },
    {
      label: reportsSubNavigationBlock.button.studyData.label,
      path: CspPath.REPORTS_STUDY_DATA,
    },
  ];
  const selectedTab = useSelectedNavTab(mainNavTabs, CspPath.REPORTS_DATA_CHANGES);

  return (
    <>
      <Box height="0.5rem" bgcolor="transparent.grey" />
      <NavTabsComponent isSubTab tabs={mainNavTabs} selectedPath={selectedTab} />
    </>
  );
};
