import { toTimezoneStr } from '@csp/csp-common-date-util';
import { HealthEvent, HealthEventService, PatientHealthEventFactory } from '@csp/csp-common-healthevent';
import { ApiOptions, Maybe, Patient, PatientHealthEvent, User, ZonedDateTime } from '@csp/csp-common-model';
import { getMeta, isDefined } from '@csp/csp-common-util';
import { toRestOptions } from '@csp/csp-fe-auth';
import { UserRestServiceV1 } from '@csp/dmdp-api-client';
import { CriteriaOperatorType, CriteriaType, pageWithOneItem, SortOrderType } from '@csp/dmdp-api-common-dto';
import {
  HealthEventCriteriaFieldV1,
  HealthEventSortFieldV1,
  HealthEventStatusTypeV1,
} from '@csp/dmdp-api-healthevent-dto';
import { createPatientHealthEventMetaV1, PATIENT_HEALTH_EVENT_V1, UserMetaV1, UserV1 } from '@csp/dmdp-api-user-dto';

const getPrioritizedHistoricalHealthEvent = async (
  patient: Patient,
  healthEventId: string,
  apiOptions?: ApiOptions,
): Promise<Maybe<PatientHealthEvent>> => {
  const healthEvents = await HealthEventService.queryHealthEvents(
    {
      userIds: [patient.userId],
      criterion: {
        operator: CriteriaOperatorType.AND,
        criteria: [
          {
            fieldCriterion: {
              field: HealthEventCriteriaFieldV1.STATUS,
              type: CriteriaType.IN,
              values: ['NEW', 'ONGOING', 'NO_ENDPOINT'],
            },
          },
          {
            fieldCriterion: {
              field: HealthEventCriteriaFieldV1.HEALTH_EVENT_ID,
              type: CriteriaType.NOT_EQ,
              values: [healthEventId],
            },
          },
        ],
      },
      sort: [{ field: HealthEventSortFieldV1.HEALTH_EVENT_ID, order: SortOrderType.DESC }],
    },
    toRestOptions(apiOptions),
    pageWithOneItem(),
  );
  if (healthEvents.events.length === 0 || !healthEvents.events[0]) {
    return undefined;
  } else {
    const healthEvent = healthEvents.events[0];
    return PatientHealthEventFactory.fromHealthEvent(healthEvent);
  }
};

const updateOthersPatientHealthEventMeta = async (
  patient: Patient,
  healthEvent: PatientHealthEvent,
  apiOptions?: ApiOptions,
): Promise<void> =>
  UserRestServiceV1.addOrUpdateMeta(
    toRestOptions(apiOptions),
    patient.userV1,
    createPatientHealthEventMetaV1(healthEvent.toPatientHealthEventV1()),
  );

const updateMyOwnPatientHealthEventMeta = async (
  mySelf: UserV1,
  healthEvent: PatientHealthEvent,
  apiOptions?: ApiOptions,
): Promise<void> =>
  await UserRestServiceV1.addOrUpdateMyMeta(
    toRestOptions(apiOptions),
    mySelf,
    createPatientHealthEventMetaV1(healthEvent.toPatientHealthEventV1()),
  );

const getPatientHealthEvent = (userV1: UserV1): Maybe<PatientHealthEvent> => {
  const metas = userV1.metas as Maybe<UserMetaV1[]>;
  const patientHealthEventMetaV1 = getMeta(metas, PATIENT_HEALTH_EVENT_V1)?.data;
  return patientHealthEventMetaV1
    ? PatientHealthEventFactory.fromPatientHealthEventV1(patientHealthEventMetaV1)
    : undefined;
};

const getMostPrioritizedHealthEvent = async (
  patient: Patient,
  healthEventId: string,
  newStatus: HealthEventStatusTypeV1,
  timestamp: ZonedDateTime,
  author: User,
): Promise<Maybe<PatientHealthEvent>> => {
  const historicalHealthEvent = await getPrioritizedHistoricalHealthEvent(patient, healthEventId);
  const updatedHealthEvent = PatientHealthEventFactory.from(
    healthEventId,
    newStatus,
    toTimezoneStr(timestamp),
    author.userId,
    author.displayName,
  );

  return historicalHealthEvent?.hasSameOrHigherPrioThan(updatedHealthEvent) &&
    historicalHealthEvent?.isAfter(updatedHealthEvent)
    ? historicalHealthEvent
    : updatedHealthEvent;
};

const setOthersHealthEventStatus = async (
  patient: Patient,
  newStatus: HealthEventStatusTypeV1,
  timestamp: ZonedDateTime,
  healthEventId: string,
  author: User,
): Promise<void> => {
  const healthEvent = await getMostPrioritizedHealthEvent(patient, healthEventId, newStatus, timestamp, author);

  if (healthEvent) {
    await updateOthersPatientHealthEventMeta(patient, healthEvent);
  }
};

const addEventStatusNewToMyself = async (mySelf: Patient, healthEventId: string, timestamp: string): Promise<void> => {
  const newEvent = PatientHealthEventFactory.from(healthEventId, 'NEW', timestamp);
  await updateMyOwnPatientHealthEventMeta(mySelf.userV1, newEvent);
};

const removeReviewedHealthEventIfNeeded = async (
  patient: Patient,
  viewedHealthEvents: HealthEvent[],
  apiOptions?: ApiOptions,
): Promise<void> => {
  if (isDefined(patient.healthEvent)) {
    const { status, healthEventId } = patient.healthEvent;

    const patientHasNewHealthEvent = status === 'NEW';
    const correspondingReviewedEventExists = viewedHealthEvents.some(
      event => event.healthEventId === healthEventId && event.status.value === 'VIEWED',
    );

    if (patientHasNewHealthEvent && correspondingReviewedEventExists) {
      await UserRestServiceV1.deleteMeta(toRestOptions(apiOptions), patient.userId, PATIENT_HEALTH_EVENT_V1);
    }
  }
};

export const PatientHealthEventService = {
  addEventStatusNewToMyself,
  setOthersHealthEventStatus,
  getPatientHealthEvent,
  removeReviewedHealthEventIfNeeded,
};
