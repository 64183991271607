import { DeviceModelRefV2 } from '@csp/config-schemas';
import { DeviceObservationRequestDetailsV1 } from '@csp/dmdp-api-device-dto';

export type DeviceObservationRequestDetails = {
  deviceModelReferences: DeviceModelRefV2[];
};

const from = (requestDetails: DeviceObservationRequestDetailsV1): DeviceObservationRequestDetails => ({
  deviceModelReferences: requestDetails.deviceModelReferences as DeviceModelRefV2[],
});

export const DeviceObservationRequestDetails = {
  from,
};
