import { useMemo } from 'react';
import { CallbackAsync, Maybe } from '@csp/csp-common-model';
import { mapUserRolesToVideoTrainingConsentTypes } from '@csp/common';
import { useSelector } from 'react-redux';
import { first } from 'lodash';
import { ConsentType } from '@csp/dmdp-api-user-dto';
import { getCurrentUserSelector } from '../../security/ducks/currentUserSelector';
import { useConsentContext } from '../context/hook/useConsentContext';

type VideoTrainingConsentApproval = {
  consentType: Maybe<ConsentType>;
  version: string;
  artifactCode: Maybe<string>;
};

type PendingVideoTrainingConsentApproval = VideoTrainingConsentApproval & {
  approveConsent: CallbackAsync;
  hasOutdatedApprovals: boolean;
};

type Out = {
  loading: boolean;
  approval: Maybe<VideoTrainingConsentApproval>;
  pendingApproval: Maybe<PendingVideoTrainingConsentApproval>;
};

/**
 * Hook for accessing formatted video training consent data from context.
 * @returns {Out} - Object containing loading state, first approval and first pending approval.
 */
export const useVideoTrainingConsent = (): Out => {
  const { approvals, loading, hasOutdatedVideoTrainingApprovals } = useConsentContext();
  const user = useSelector(getCurrentUserSelector);

  const videoTrainingApprovals = useMemo(() => {
    const userRoles = user?.roles ?? [];
    const types = mapUserRolesToVideoTrainingConsentTypes(userRoles);
    return approvals.filter(approval => types.includes(approval.consentType));
  }, [approvals, user?.roles]);

  const firstApproval = useMemo(
    () => first(videoTrainingApprovals.filter(approval => approval.hasApproval)),
    [videoTrainingApprovals],
  );

  const firstPendingApproval = useMemo(
    () => first(videoTrainingApprovals.filter(approval => !approval.hasApproval)),
    [videoTrainingApprovals],
  );

  return useMemo(
    () => ({
      loading,
      approval: firstApproval
        ? {
            consentType: firstApproval.consentType,
            version: firstApproval.version,
            artifactCode: firstApproval.artifact.artifactCode,
          }
        : undefined,
      pendingApproval: firstPendingApproval
        ? {
            consentType: firstPendingApproval.consentType,
            version: firstPendingApproval.version,
            artifactCode: firstPendingApproval?.artifact.artifactCode,
            approveConsent: async () => await firstPendingApproval.approveConsent(),
            hasOutdatedApprovals: hasOutdatedVideoTrainingApprovals(),
          }
        : undefined,
    }),
    [loading, firstApproval, firstPendingApproval, hasOutdatedVideoTrainingApprovals],
  );
};
