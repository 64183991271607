/* eslint-disable */
export const UnifyAppFaqQuestionMock = {
  title: 'title',
  categoryHeader: 'categoryHeader',
  header: 'header',
  body: 'body',
  category: 'category',
  pdfDownloadButtonText: 'pdfDownloadButtonText',
  pdf: {},
};
