export type ZoomConfig = {
  clientId: string;
};

const empty = (): ZoomConfig => ({
  clientId: '',
});

export const ZoomConfig = {
  empty,
};
