import { fromTimezoneStr, fromTimezoneStrOrUndefined, nowToZonedDateTimeCurrentZone } from '@csp/csp-common-date-util';
import {
  CustomStatus,
  CustomStatuses,
  CustomStatusType,
  CustomStatusValue,
  Maybe,
  StatusValue,
  ZonedDateTime,
} from '@csp/csp-common-model';
import { StringUtil } from '@csp/csp-common-util';
import { CustomStatusV1 as BioSamplingCustomStatusV1 } from '@csp/dmdp-api-hbs-dto';
import { CustomStatusV1 as AppointmentCustomStatusV1 } from '@csp/dmdp-api-appointment-dto';
import { CustomStatusV1 as UserCustomStatusV1, PreviousCustomStatusV1 } from '@csp/dmdp-api-common-dto';
import { findLast } from 'lodash/fp';

type CustomStatusV1 =
  | BioSamplingCustomStatusV1
  | UserCustomStatusV1
  | (AppointmentCustomStatusV1 & {
      reason?: string;
    }); // Workaround until reason is available in Appointment API

const toCustomStatusValue = (valueV1: PreviousCustomStatusV1 | CustomStatusV1): CustomStatusValue => {
  const localTimestamp = fromTimezoneStrOrUndefined(valueV1.localTimestamp);
  const createdTimestamp = fromTimezoneStr(valueV1.createdAt);

  return {
    timestamp: localTimestamp ?? createdTimestamp,
    localTimestamp,
    createdTimestamp,
    createdUserId: valueV1.createdBy,
    value: valueV1.value,
    reasonText: valueV1.reason,
  };
};

const getSequentialValuesOnly = (
  currentValue: CustomStatusValue,
  previous: CustomStatusValue[],
): CustomStatusValue[] => {
  const valuesInSequence: CustomStatusValue[] = [];
  let compareValue: Maybe<CustomStatusValue> = undefined;
  [currentValue, ...previous].forEach(value => {
    if (
      !compareValue ||
      (compareValue.timestamp.unixTimeMillis >= value.timestamp.unixTimeMillis && compareValue.value !== value.value)
    ) {
      valuesInSequence.push(value);
      compareValue = value;
    }
  });
  return valuesInSequence;
};

const fromCustomStatusV1 = (statusV1: CustomStatusV1): CustomStatus => {
  const type = statusV1.type;

  const currentValue = toCustomStatusValue(statusV1);

  const previous =
    statusV1.previousCustomStatuses?.map(prevStatusV1 => ({
      ...toCustomStatusValue(prevStatusV1),
    })) ?? [];

  const getStatusValuesInSequenceNewestFirst = (): CustomStatusValue[] =>
    getSequentialValuesOnly(currentValue, previous);

  const getStatusValuesInSequenceOldestFirst = (): CustomStatusValue[] =>
    getStatusValuesInSequenceNewestFirst().slice().reverse();

  const getSequentialValueByNow = (): Maybe<CustomStatusValue> => {
    const now = nowToZonedDateTimeCurrentZone().unixTimeMillis;
    return getStatusValuesInSequenceNewestFirst().find(value => value.timestamp.unixTimeMillis < now);
  };

  return {
    type,
    ...currentValue,
    previous,
    getStatusValuesInSequenceNewestFirst,
    getStatusValuesInSequenceOldestFirst,
    getSequentialValueByNow,
  };
};

const fromCustomStatusesV1 = (customStatusesV1: CustomStatusV1[]): CustomStatuses =>
  CustomStatusMapper.from(customStatusesV1.map(fromCustomStatusV1));

const from = (statuses: Maybe<CustomStatus[]> = []): CustomStatuses => {
  const getByType = <Value = StatusValue>(type: CustomStatusType): Maybe<CustomStatus<Value>> =>
    statuses.find(status => status.type === type) as Maybe<CustomStatus<Value>>;

  const findOldestCustomStatusValue = (type: CustomStatusType, value: StatusValue): Maybe<CustomStatusValue> => {
    const customStatusWithMatchingType = getByType(type);
    const oldestPreviousValueMatch = findLast(
      statusValue => statusValue.value === value,
      customStatusWithMatchingType?.previous,
    );
    return (
      oldestPreviousValueMatch ??
      (customStatusWithMatchingType?.value === value ? customStatusWithMatchingType : undefined)
    );
  };

  const getCustomStatusValuesOldestFirst = (type: CustomStatusType): CustomStatusValue[] => {
    const customStatusOfMatchingType = getByType(type);
    if (customStatusOfMatchingType) {
      return customStatusOfMatchingType.previous
        .slice()
        .reverse()
        .concat([
          {
            createdTimestamp: customStatusOfMatchingType.createdTimestamp,
            timestamp: customStatusOfMatchingType.timestamp,
            localTimestamp: customStatusOfMatchingType.localTimestamp,
            value: customStatusOfMatchingType.value,
            reasonText: customStatusOfMatchingType.reasonText,
            createdUserId: customStatusOfMatchingType.createdUserId,
          },
        ]);
    } else {
      return [];
    }
  };

  const mutateOldestStatusTimestamp = (
    type: CustomStatusType,
    value: StatusValue,
    timestamp: Maybe<ZonedDateTime>,
  ): void => {
    const status = findOldestCustomStatusValue(type, value);
    if (status && timestamp) {
      status.timestamp = timestamp;
    }
  };

  const getValueByType = (type: CustomStatusType): Maybe<StatusValue> => getByType(type)?.value;

  const hash = StringUtil.deterministicStringify(statuses);

  return {
    statuses,
    hasStatuses: !!statuses.length,
    hash,
    getByType,
    getValueByType,
    findOldestCustomStatusValue,
    getCustomStatusValuesOldestFirst,
    mutateOldestStatusTimestamp,
  };
};

const fromStatuses = (customStatuses: Maybe<CustomStatuses>): CustomStatuses => from(customStatuses?.statuses);

const empty = (): CustomStatuses => from([]);

export const CustomStatusMapper = {
  fromCustomStatusesV1,
  fromCustomStatusV1,
  from,
  empty,
  fromStatuses,
};
