import { RawCspFeatureConfigV2 } from '@csp/config-schemas';
import {
  ConfigInfoSummary,
  ConfigValueInfo,
  CountryCode,
  CspFeatureConfigV2,
  FeatureConfigInfoSummary,
  FeatureFlagsSummary,
  Maybe,
  SiteFeatureFlags,
  SiteNumber,
  SiteNumberAndFeatureFlags,
  StateAssert,
  StudyOrg,
} from '@csp/csp-common-model';
import { memoize } from 'lodash';
import { FeatureToggleValidationService } from '../service/FeatureToggleValidationService';
import { FeatureToggleParserUtil } from '../util/FeatureToggleParserUtil';
import { FeatureToggleConfig } from './FeatureToggleConfig';

const from = (config: RawCspFeatureConfigV2): FeatureToggleConfig => {
  FeatureToggleValidationService.validateFeatureConfigV2(config);

  const memoizedParseFeatureConfig = memoize(
    FeatureToggleParserUtil.parseFeatureConfig,
    (_, country, site) => `${country}_${site}`,
  );

  const getFeatureConfig = (
    countryCode: Maybe<CountryCode>,
    siteNumber: Maybe<SiteNumber>,
  ): Maybe<CspFeatureConfigV2> =>
    countryCode ? memoizedParseFeatureConfig<CspFeatureConfigV2>(config, countryCode, siteNumber) : undefined;

  const getSiteFeatureFlags = (countryCode: CountryCode, siteNumber: Maybe<SiteNumber>): SiteFeatureFlags => {
    const siteConfig = memoizedParseFeatureConfig<CspFeatureConfigV2>(config, countryCode, siteNumber);

    return {
      clinRO: ConfigValueInfo.from(siteConfig.clinRO?.clinRO || 'disabled'),
      closeOutTracker: ConfigValueInfo.from(siteConfig.closeOutTracker?.closeOutTracker || 'disabled'),
      devices: ConfigValueInfo.from(siteConfig.devices || 'disabled'),
      eCOA: ConfigValueInfo.from(siteConfig.eCOA || 'disabled'),
      healthEvent: ConfigValueInfo.from(siteConfig.healthEvent || 'disabled'),
      homeSupply: ConfigValueInfo.from(siteConfig.homeSupply?.homeSupply || 'disabled'),
      homeSupplyScanning: ConfigValueInfo.from(siteConfig.homeSupply?.scanning || 'disabled'),
      medication: ConfigValueInfo.from(siteConfig.medication.medication || 'disabled'),
      patientTrainingCompletion: ConfigValueInfo.from(
        siteConfig.patientTrainingCompletion?.manualPatientTrainingCompletionDateEntry || 'disabled',
      ),
      visits: ConfigValueInfo.from(siteConfig.visits?.visits || 'disabled'),
      rpmEventFeedback: ConfigValueInfo.from(siteConfig.rpmEventFeedback || 'disabled'),
    };
  };

  const toConfigInfoSummary = (configValueInfos: ConfigValueInfo[]): ConfigInfoSummary => ({
    isEnabledOnAnySite: configValueInfos.some(configInfo => configInfo.isEnabled),
  });

  const toFeatureInfoConfigSummary = (siteFeatureFlags: SiteNumberAndFeatureFlags[]): FeatureConfigInfoSummary => ({
    clinRO: toConfigInfoSummary(siteFeatureFlags.map(config => config.features.clinRO)),
    closeOutTracker: toConfigInfoSummary(siteFeatureFlags.map(config => config.features.closeOutTracker)),
    devices: toConfigInfoSummary(siteFeatureFlags.map(config => config.features.devices)),
    eCOA: toConfigInfoSummary(siteFeatureFlags.map(config => config.features.eCOA)),
    healthEvent: toConfigInfoSummary(siteFeatureFlags.map(config => config.features.healthEvent)),
    homeSupply: toConfigInfoSummary(siteFeatureFlags.map(config => config.features.homeSupply)),
    homeSupplyScanning: toConfigInfoSummary(siteFeatureFlags.map(config => config.features.homeSupplyScanning)),
    medication: toConfigInfoSummary(siteFeatureFlags.map(config => config.features.medication)),
    patientTrainingCompletion: toConfigInfoSummary(
      siteFeatureFlags.map(config => config.features.patientTrainingCompletion),
    ),
    visits: toConfigInfoSummary(siteFeatureFlags.map(config => config.features.visits)),
    rpmEventFeedback: toConfigInfoSummary(siteFeatureFlags.map(config => config.features.rpmEventFeedback)),
  });

  const getFeatureFlagsSummary = (studyOrg: StudyOrg): FeatureFlagsSummary => {
    const siteFeatureFlags: SiteNumberAndFeatureFlags[] = studyOrg.sites.map(site => {
      const country = studyOrg.countries.find(country => site.isChildOf(country.orgId));

      StateAssert.notNull(country, `Site ${site.name} does not have a parent country`);

      return {
        siteNumber: site.siteNumber ?? '',
        features: getSiteFeatureFlags(country.countryCode, site.siteNumber),
      };
    });

    return {
      siteFeatureFlags,
      summary: toFeatureInfoConfigSummary(siteFeatureFlags),
    };
  };

  return {
    getFeatureConfig,
    getSiteFeatureFlags,
    getFeatureFlagsSummary,
  };
};

export const FeatureToggleFactory = {
  from,
};
