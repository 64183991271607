/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum OrganizationCriteriaFieldV1 {
    ORG_ID = 'ORG_ID',
    EVENT_ID = 'EVENT_ID',
    TYPE = 'TYPE',
    NAME = 'NAME',
    STATUS = 'STATUS',
    PARENT_ID = 'PARENT_ID',
    BRANCH_PARENT_IDS = 'BRANCH_PARENT_IDS',
    COUNTRY_CODE = 'COUNTRY_CODE',
    COUNTRY_LANG_CODES = 'COUNTRY_LANG_CODES',
    REGION_CODE = 'REGION_CODE',
    REGION_TYPE = 'REGION_TYPE',
    PRACTICE_TYPE = 'PRACTICE_TYPE',
    META_TYPE = 'META_TYPE',
    META_VALUE = 'META_VALUE',
    EXTERNAL_ID_KEY = 'EXTERNAL_ID_KEY',
    EXTERNAL_ID_VALUE = 'EXTERNAL_ID_VALUE',
    CUSTOM_STATUS_VALUE = 'CUSTOM_STATUS_VALUE',
    CUSTOM_STATUS_TYPE = 'CUSTOM_STATUS_TYPE',
    PRACTICE_CODE = 'PRACTICE_CODE',
    PRACTICE_LOCATION_ID = 'PRACTICE_LOCATION_ID',
    PRACTICE_LOCATION_EID = 'PRACTICE_LOCATION_EID',
}