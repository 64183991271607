/* eslint-disable */
export const UnifyWebSitesSchema = {
  title: 'UnifyWebSites',
  type: 'object',
  properties: {
    title: { type: 'string', title: 'Sites info' },
    sitesList: {
      type: 'object',
      title: 'Sites list',
      properties: {
        buttons: {
          type: 'object',
          title: 'Site list action button texts',
          properties: {
            addAdditionalLocation: {
              type: 'string',
              title: 'Add additional location button',
              mock: 'Add additional location',
              helper: 'Text on add additional location button in site list',
            },
          },
        },
        texts: {
          type: 'object',
          title: 'Sites list texts',
          properties: {
            emptyResult: {
              type: 'string',
              title: 'Empty search',
              mock: 'No matching sites found',
              helper: 'Empty search info text',
            },
            editPhone: {
              type: 'string',
              title: 'Edit phone number label',
              mock: 'Edit',
              helper: 'Label on button for edit phone number',
            },
            editAdditionalLocation: {
              type: 'string',
              title: 'Edit additional location label',
              mock: 'Edit',
              helper: 'Label on button for edit additional location',
            },
            replaceAdditionalLocation: {
              type: 'string',
              title: 'Replace additional location button label',
              mock: 'Replace',
            },
            additionalLocationHeader: {
              type: 'string',
              title: 'Additional Locations Header',
              mock: '${numberOfAdditionalLocations} additional locations',
              helper: 'Header for the additional locations on site lists',
            },
            siteStatuses: {
              type: 'object',
              title: 'Site statuses',
              properties: {
                activeRecruiting: {
                  type: 'string',
                  title: 'Active (Recruiting) state',
                  mock: 'Active (Recruiting)',
                  helper: 'Name for Unify status [Active (Recruiting)] on site',
                },
                activeNotRecruiting: {
                  type: 'string',
                  title: 'Active (not recruiting) state',
                  mock: 'Active (not recruiting)',
                  helper: 'Name for Unify status [Active (not recruiting)] on site',
                },
                closing: {
                  type: 'string',
                  title: 'Closing state',
                  mock: 'Closing',
                  helper: 'Name for Unify status [Closing] on site',
                },
                closingTreatmentExtension: {
                  type: 'string',
                  title: 'Closing (Treatment Extension) state',
                  mock: 'Closing (Treatment Extension)',
                  helper: 'Name for Unify status [Closing (Treatment Extension)] on site',
                },
                initiating: {
                  type: 'string',
                  title: 'Initiating state',
                  mock: 'Initiating',
                  helper: 'Name for Unify status [Initiating] on site',
                },
                openForRecruitment: {
                  type: 'string',
                  title: 'Open for Recruitment state',
                  mock: 'Open for Recruitment',
                  helper: 'Name for Unify status [Open for Recruitment] on site',
                },
                archived: {
                  type: 'string',
                  title: 'Archived state',
                  mock: 'Archived',
                  helper: 'Name for Unify status [Archived] on site',
                },
                cancelled: {
                  type: 'string',
                  title: 'Cancelled state',
                  mock: 'Cancelled',
                  helper: 'Name for Unify status [Cancelled] on site',
                },
                candidate: {
                  type: 'string',
                  title: 'Candidate state',
                  mock: 'Candidate',
                  helper: 'Name for Unify status [Candidate] on site',
                },
                closed: {
                  type: 'string',
                  title: 'Closed state',
                  mock: 'Closed',
                  helper: 'Name for Unify status [Closed] on site',
                },
                inactive: {
                  type: 'string',
                  title: 'Inactive state',
                  mock: 'Inactive',
                  helper: 'Name for Unify status [Inactive] on site',
                },
                initiatingHold: {
                  type: 'string',
                  title: 'Initiating Hold state',
                  mock: 'Initiating Hold',
                  helper: 'Name for Unify status [Initiating Hold] on site',
                },
                inMigration: {
                  type: 'string',
                  title: 'In Migration state',
                  mock: 'In Migration',
                  helper: 'Name for Unify status [In Migration] on site',
                },
                onHold: {
                  type: 'string',
                  title: 'On Hold state',
                  mock: 'On Hold',
                  helper: 'Name for Unify status [On Hold] on site',
                },
                reEvaluate: {
                  type: 'string',
                  title: 'Re-evaluate state',
                  mock: 'Re-evaluate',
                  helper: 'Name for Unify status [Re-evaluate] on site',
                },
                terminated: {
                  type: 'string',
                  title: 'Terminated state',
                  mock: 'Terminated',
                  helper: 'Name for Unify status [Terminated] on site',
                },
                ecApprovalTitleLabel: {
                  type: 'string',
                  title: 'EC Approval Title',
                  mock: 'UNIFY EC/IRB APPROVAL',
                  helper: 'Displayed if a site has been approved by EC',
                },
                ecApprovedLabel: {
                  type: 'string',
                  title: 'EC Approved Label',
                  mock: 'Approved',
                  helper: 'Approved by EC',
                },
                ecNotApprovedLabel: {
                  type: 'string',
                  title: 'EC Not Approved Label',
                  mock: 'Not approved',
                  helper: 'Not approved by EC',
                },
                changeEcStatusLink: {
                  type: 'string',
                  title: 'Change EC Status Link',
                  mock: 'Change status',
                  helper: 'Link that opens drawer to change status',
                },
                viewSiteDetailsLink: {
                  type: 'string',
                  title: 'View site details',
                  mock: 'View details',
                  helper: 'Link that opens drawer to view site details',
                },
              },
            },
            addressLabel: { type: 'string', title: 'Address Label', mock: 'Address', helper: 'Title for address' },
            siteContactNumberLabel: {
              type: 'string',
              title: 'Site Contact Number Label',
              mock: 'Site Contact Number',
              helper: 'Title for site contact number',
            },
            statusLabel: { type: 'string', title: 'Status Label', mock: 'Status', helper: 'Title for status' },
          },
        },
        inputs: {
          type: 'object',
          title: 'Sites list inputs',
          properties: {
            searchBox: {
              type: 'string',
              title: 'Search by name or site number input',
              mock: 'Search sites (Name or No.)',
              helper: 'Free text search textfield',
            },
            editMultipleSitesLink: {
              type: 'string',
              title: 'Edit Multiple Sites Link',
              mock: 'Edit Multiple Sites',
              helper: 'Link to open drawer for editing multiple sites',
            },
          },
        },
      },
    },
    editPhone: {
      type: 'object',
      title: 'Edit phone number form',
      properties: {
        buttons: {
          type: 'object',
          title: 'Sites edit phone number button texts',
          properties: {
            cancel: {
              type: 'string',
              title: 'Cancel button',
              mock: 'Cancel',
              helper: 'Text on cancel button in edit phone number form',
            },
            confirm: {
              type: 'string',
              title: 'Confirm button',
              mock: 'Confirm',
              helper: 'Text on confirm button in edit phone number form',
            },
          },
        },
        texts: {
          type: 'object',
          title: 'Edit phone number form texts',
          properties: {
            title: {
              type: 'string',
              title: 'Edit phone number form title',
              mock: 'Add contact number',
              helper: 'Title on form when editing site phone number',
            },
            requiredField: {
              type: 'string',
              title: 'Required field info',
              mock: 'Required field',
              helper: 'Info text after *, telling that the fields having that symbol is required',
            },
            infoText: {
              type: 'string',
              title: 'Informative text when editing phone number',
              mock: 'This number will be shown to patients when they need to contact the study team.',
              helper:
                'Text is shown before input fields for entering phone number telling some info about the phone number',
            },
            contactUpdated: {
              type: 'string',
              title: 'Success message edit phone number',
              mock: 'Contact phone number updated',
              helper: 'Message shown when successfully updated contact phone number on site',
            },
            updateErrorMessage: {
              type: 'string',
              title: 'Update phone number failed',
              mock: 'Failed to update phone number. Please contact support if problem persists.',
              helper: 'Message when failing to update phone number',
            },
          },
        },
        inputs: {
          type: 'object',
          title: 'Sites edit phone number inputs',
          properties: {
            countryCode: {
              type: 'string',
              title: 'Country code input field',
              mock: 'Country code',
              helper: 'Text in country code input field',
            },
            phoneNumber: {
              type: 'string',
              title: 'Phone number input field',
              mock: 'Phone number',
              helper: 'Text in phone number input field',
            },
          },
        },
      },
    },
    addAdditionalLocationForm: {
      type: 'object',
      title: 'Add additional location form',
      properties: {
        texts: {
          type: 'object',
          title: 'Add additional location form texts',
          properties: {
            header: {
              type: 'string',
              title: 'Add additional location form header',
              mock: 'Add additional location',
              helper: 'Header in Add additional location form',
            },
            requiredFields: {
              type: 'string',
              title: 'Legend text for Required fields *',
              mock: 'Required fields',
              helper: 'Used as helper text to explain the meaning of a * in an input field',
            },
            infoText: {
              type: 'string',
              title: 'Information text in Add additional location form',
              mock: 'An additional location can be selected when booking a patient appointment, giving the patient the exact location they need to go to.',
              helper: 'Body text to explain the concept of an additional location in a site',
            },
            additionalLocationAdded: {
              type: 'string',
              title: 'Success notification for add additional location',
              mock: 'Additional location added.',
              helper: 'Success notification text to display for when an additional location is added.',
            },
            addAdditionalLocationErrorMessage: {
              type: 'string',
              title: 'Add additional location failed',
              mock: 'Failed to add an additional location. Please contact support if problem persists.',
              helper: 'Message when failing to add an additional location.',
            },
          },
        },
        inputs: {
          type: 'object',
          title: 'Add additional location form inputs',
          properties: {
            locationName: {
              type: 'string',
              title: 'Location name input',
              mock: 'Location name',
              helper: 'Field for location name input',
            },
            address1: {
              type: 'string',
              title: 'Address line 1 input',
              mock: 'Address line 1',
              helper: 'Field for address line 1 input',
            },
            address2: {
              type: 'string',
              title: 'Address line 2 input',
              mock: 'Address line 2',
              helper: 'Field for address line 2 input',
            },
            address3: {
              type: 'string',
              title: 'Address line 3 input',
              mock: 'Address line 3',
              helper: 'Field for address line 3 input',
            },
            city: { type: 'string', title: 'City input', mock: 'City', helper: 'Field for city input' },
            postalCode: {
              type: 'string',
              title: 'postalCode input',
              mock: 'Postcode',
              helper: 'Field for postalCode input',
            },
          },
        },
        buttons: {
          type: 'object',
          title: 'Add additional location form button',
          properties: {
            cancel: {
              type: 'string',
              title: 'Cancel button',
              mock: 'Cancel',
              helper: 'Text on cancel button in Add additional location form',
            },
            confirm: {
              type: 'string',
              title: 'Confirm button',
              mock: 'Confirm',
              helper: 'Text on confirm button in Add additional location form',
            },
          },
        },
      },
    },
    editAdditionalLocationForm: {
      type: 'object',
      title: 'edit Additional location Form',
      properties: {
        texts: {
          type: 'object',
          title: 'Edit additional location form texts',
          properties: {
            header: {
              type: 'string',
              title: 'Edit additional location form header',
              mock: 'Edit additional location',
              helper: 'Header in Edit additional location form',
            },
            additionalLocationEdited: {
              type: 'string',
              title: 'Success notification for edit additional location',
              mock: 'Additional location edited.',
              helper: 'Success notification text to display for when an additional location is edited.',
            },
            editAdditionalLocationErrorMessage: {
              type: 'string',
              title: 'Edit additional location failed',
              mock: 'Failed to edit an additional location. Please contact support if problem persists.',
              helper: 'Message when failing to edit an additional location.',
            },
          },
        },
        buttons: {
          type: 'object',
          title: 'Edit additional location form button',
          properties: {
            cancel: {
              type: 'string',
              title: 'Cancel button',
              mock: 'Cancel',
              helper: 'Text on cancel button in edit additional location form',
            },
            confirm: {
              type: 'string',
              title: 'Confirm button',
              mock: 'Confirm',
              helper: 'Text on confirm button in edit additional location form',
            },
          },
        },
      },
    },
    deleteAdditionalLocationForm: {
      type: 'object',
      title: 'Delete additional location form',
      properties: {
        drawer: {
          type: 'object',
          title: 'Delete Additional location drawer content',
          properties: {
            texts: {
              type: 'object',
              title: 'Delete Additional location drawer content texts',
              properties: {
                header: { type: 'string', title: 'Drawer header', mock: 'Replace site location' },
                requiredInfoLabel: { title: 'Required fields info label', type: 'string', mock: '*Required fields' },
                replacementLocationTitle: {
                  title: 'Replacement location section title',
                  type: 'string',
                  mock: 'Replacement location',
                },
                replacementLocationInfo: {
                  title: 'Replacement location info text',
                  type: 'string',
                  mock: 'Please select a new location as a substitute. This is necessary because patient visits are affected by the deletion.',
                },
                patientsNotification: {
                  title: 'Notification text',
                  type: 'string',
                  mock: 'Patients will see an updated address for their visit.',
                },
                selectReplacementLocationLabel: {
                  title: 'Select replacement location dropdown label',
                  type: 'string',
                  mock: 'Select replacement location',
                },
                selectLocationHelperText: {
                  type: 'string',
                  title: 'Site location input helper text',
                  mock: 'Locations at ${siteName}',
                  helper:
                    'The helper text supports that site name is dynamically replaced in the string, e.g Locations at ${siteName}',
                },
                exportCsvTitle: {
                  title: 'Export to CSV section title',
                  type: 'string',
                  mock: 'Please export the list of affected patient visits',
                },
                exportCsvAlert: {
                  title: 'Export to CSV alert text',
                  type: 'string',
                  mock: 'You will not be able to access the file listing affected patient visits after the site has been deleted.',
                },
                exportCsvInfo: {
                  title: 'Export to CSV info text',
                  type: 'string',
                  mock: 'Before replacing the site location, be aware that there are patient visits affected. The patients will have to be informed about the new location. You can export the list of affected visits as CSV to follow up with the patients.',
                },
                totalNumberOfAppointments: {
                  title: 'Total number of booked appointments on the location to be replaced',
                  type: 'string',
                  mock: '${totalNumberOfVisits} booked appointments',
                  helper:
                    'Total number of appointments to be displayed above the appointment list on replace additional locations.',
                },
              },
            },
            buttons: {
              type: 'object',
              title: 'Drawer button texts',
              properties: {
                exportCsv: { type: 'string', title: 'Export to CSV button', mock: 'Export CSV' },
                cancel: { type: 'string', title: 'Cancel button', mock: 'Cancel' },
                confirm: { type: 'string', title: 'Confirm replace button', mock: 'Confirm replacement' },
              },
            },
          },
        },
        snackbar: {
          type: 'object',
          title: "Snackbar message related to replacement of the appointments' location",
          properties: {
            deleteSuccess: {
              type: 'string',
              title: 'Snackbar shown after location was replaced',
              mock: '${oldLocation} was replaced by ${newLocation} in the future appointments.',
            },
          },
        },
        alertDialog: {
          type: 'object',
          title:
            'Alert dialog displayed to inform there are no linked appointments to the location to perform replacement.',
          properties: {
            texts: {
              type: 'object',
              title: 'Alert dialog texts',
              properties: {
                header: { type: 'string', title: 'Alert dialog header', mock: 'No appointments found' },
                description: {
                  title: 'Description',
                  type: 'string',
                  mock: 'There are no appointments booked at ${locationToBeRemoved} and can be removed in the future. Please don’t use this location anymore.',
                },
              },
            },
            buttons: {
              type: 'object',
              title: 'Alert dialog button texts',
              properties: { yes: { type: 'string', title: 'Ok button text', mock: 'Ok' } },
            },
          },
        },
      },
    },
    ecApprovalStatusForm: {
      type: 'object',
      title: 'EC/IRB approval status form',
      properties: {
        texts: {
          type: 'object',
          title: 'EC/IRB approval drawer texts',
          properties: {
            drawerHeader: {
              type: 'string',
              title: 'EC approval drawer header',
              mock: 'Change Unify EC/IRB approval status',
            },
            formHeaderSingleSite: {
              type: 'string',
              title: 'Radio button group header',
              mock: 'EC status for this site',
            },
            formHeaderMultipleSites: {
              type: 'string',
              title: 'Radio button group header',
              mock: 'EC status for selected sites',
            },
            toggleApprovalStatusAriaLabel: {
              type: 'string',
              title: 'ARIA label of toggle approval status radio group',
              mock: 'Toggle site approval status',
            },
            notApprovedLabel: {
              type: 'string',
              title: 'Label of NOT approved radio button',
              mock: "This site is NOT approved to invite patients to be 'app users'",
            },
            approvedLabel: {
              type: 'string',
              title: 'Label of approved radio button',
              mock: "This site is approved to invite patients to be both 'app users' or 'non-app users'",
            },
            approvalWarning: {
              type: 'string',
              title: 'Warning text displayed when marking a site as approved',
              mock: "Make sure you have selected the correct status. You will not be able to change the EC/IRB approval status once you have pressed 'Confirm'.",
            },
            stepSelect: { type: 'string', title: 'Label of first step in the wizard', mock: 'Select site' },
            stepConfirm: { type: 'string', title: 'Label of second step in the wizard', mock: 'Confirm site' },
            multipleInfo: {
              type: 'string',
              title: 'Info shown above list of sites without EC approval',
              mock: "The list only shows sites that are not approved to invite patients to be 'app users'",
            },
            selectAllSitesLabel: {
              type: 'string',
              title: "Label for 'Select all sites' checkbox",
              mock: 'Select all sites',
            },
            ecNotApprovedLabel: { type: 'string', title: 'EC not approved label', mock: 'EC not approved' },
            differentCountriesSelectedWarning: {
              type: 'string',
              title: 'Warning displayed when sites in different countries are selected.',
              mock: 'You have selected sites belonging to multiple countries. Please make sure your selection is correct.',
            },
          },
        },
        buttons: {
          type: 'object',
          title: 'EC/IRB approval drawer button texts',
          properties: {
            cancel: { type: 'string', title: 'Cancel button', mock: 'Cancel' },
            confirm: { type: 'string', title: 'Confirm approval status button', mock: 'Confirm' },
            next: { type: 'string', title: 'Next approval status button', mock: 'Next' },
          },
        },
      },
    },
    ecDetailsDrawer: {
      type: 'object',
      title: 'EC/IRB approval details drawer',
      properties: {
        texts: {
          type: 'object',
          title: 'EC/IRB approval drawer texts',
          properties: {
            drawerHeader: { type: 'string', title: 'Drawer header', mock: 'Unify EC/IRB approval details' },
            drawerSubtitle: { type: 'string', title: 'Drawer subtitle', mock: 'EC approval change status details' },
            dateLabel: { type: 'string', title: 'Date label', mock: 'Unify EC/IRB approval date' },
            confirmedByLabel: { type: 'string', title: 'Confirmed by label', mock: 'Confirmed by' },
          },
        },
      },
    },
    ecConfirmationDialog: {
      type: 'object',
      title: 'EC/IRB alert dialog',
      properties: {
        texts: {
          type: 'object',
          title: 'EC/IRB alert dialog texts',
          properties: {
            dialogHeader: { type: 'string', title: 'Dialog header', mock: 'Change Unify EC/IRB approval status' },
            dialogDescription: {
              type: 'string',
              title: 'Dialog subtitle',
              mock: "Make sure you have selected the correct status. You will not be able to change the selected status once you have pressed 'Confirm'.",
            },
            viewMoreLabel: { type: 'string', title: 'View More Label', mock: 'View selected sites (${nrOfSites})' },
            updateEcApprovalErrorMessage: {
              type: 'string',
              title: 'Update EC approval status failed',
              mock: 'Failed to update EC approval status. Please contact support if problem persists.',
            },
          },
        },
        buttons: {
          type: 'object',
          title: 'EC/IRB alert dialog buttons',
          properties: {
            cancel: { type: 'string', title: 'Cancel button', mock: 'Cancel' },
            confirm: { type: 'string', title: 'Confirm approval status button', mock: 'Confirm' },
          },
        },
      },
    },
  },
  description: 'unify:websites',
  $schema: 'http://json-schema.org/draft-07/schema#',
};
