/* eslint-disable */
export const UnifyQuestionnairesSystemTextsMock = {
  title: 'title',
  nextButtonLabel: 'nextButtonLabel',
  nextButtonAccessibilityLabel: 'nextButtonAccessibilityLabel',
  nextButtonAccessibilityHint: 'nextButtonAccessibilityHint',
  startButtonLabel: 'startButtonLabel',
  startButtonAccessibilityLabel: 'startButtonAccessibilityLabel',
  startButtonAccessibilityHint: 'startButtonAccessibilityHint',
  submitButtonLabel: 'submitButtonLabel',
  submitButtonAccessibilityLabel: 'submitButtonAccessibilityLabel',
  submitButtonAccessibilityHint: 'submitButtonAccessibilityHint',
  previousButtonAccessibilityLabel: 'previousButtonAccessibilityLabel',
  previousButtonLabel: 'previousButtonLabel',
  previousButtonAccessibilityHint: 'previousButtonAccessibilityHint',
  abortButtonAccessibilityLabel: 'abortButtonAccessibilityLabel',
  abortButtonAccessibilityHint: 'abortButtonAccessibilityHint',
  abortQuestionnairePopupHeader: 'abortQuestionnairePopupHeader',
  abortQuestionnairePopupDescription: 'abortQuestionnairePopupDescription',
  abortQuestionnairePopupCancelButtonLabel: 'abortQuestionnairePopupCancelButtonLabel',
  abortQuestionnairePopupConfirmButtonLabel: 'abortQuestionnairePopupConfirmButtonLabel',
  skipQuestionPopupHeader: 'skipQuestionPopupHeader',
  skipQuestionPopupDescription: 'skipQuestionPopupDescription',
  yesButtonLabel: 'yesButtonLabel',
  cancelButtonLabel: 'cancelButtonLabel',
  clearButtonLabel: 'clearButtonLabel',
  offlineError: { title: 'title', description: 'description', buttonLabel: 'buttonLabel' },
  submit: {
    submitFailureTitle: 'submitFailureTitle',
    submitFailureDescription: 'submitFailureDescription',
    submitFailureRetryButtonLabel: 'submitFailureRetryButtonLabel',
    submitFailureCancelButtonLabel: 'submitFailureCancelButtonLabel',
    submitWindowPassedErrorTitle: 'submitWindowPassedErrorTitle',
    submitWindowPassedErrorDescription: 'submitWindowPassedErrorDescription',
    submitWindowPassedErrorButtonLabel: 'submitWindowPassedErrorButtonLabel',
    successfullySubmittedButtonLabel: 'successfullySubmittedButtonLabel',
    successfullySubmittedNotification: 'successfullySubmittedNotification',
  },
};
