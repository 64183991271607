import { fromTimezoneStr, ZonedDateTimeFormatter } from '@csp/csp-common-date-util';
import { EnumUtil } from '@csp/csp-common-enum-util';
import {
  Maybe,
  PatientVisitMeta,
  StateAssert,
  UnixTimeMillis,
  VisitType,
  VisitVariantType,
} from '@csp/csp-common-model';
import { AppointmentV1 } from '@csp/dmdp-api-appointment-dto';
import { PatientVisitV1 } from '@csp/dmdp-api-user-dto';

const fromPatientVisitV1 = (patientVisitV1: PatientVisitV1): PatientVisitMeta => {
  const appointmentId = patientVisitV1.appointmentId;
  const type = patientVisitV1.type;
  const localDateTime = patientVisitV1.localDateTime;
  const dateTime = fromTimezoneStr(localDateTime);
  const dateNumber = patientVisitV1.dateNumber
    ? patientVisitV1.dateNumber
    : ZonedDateTimeFormatter.toDateNumber(dateTime);
  const formattedDateTime = ZonedDateTimeFormatter.toDisplayDateTimeString(dateTime) || '';
  const isAfter = (timestamp: UnixTimeMillis): boolean => dateTime.unixTimeMillis > timestamp;
  const isScheduledVisit = (): boolean => type === 'SCHEDULED';
  const hasScheduleRequestRef = (): boolean =>
    !!(patientVisitV1?.scheduleCode && patientVisitV1?.requestCode && patientVisitV1?.versionCode);
  const variant = EnumUtil.fromMaybeString<VisitVariantType>(patientVisitV1.variant, VisitVariantType);

  const toPatientVisitV1 = (): PatientVisitV1 => {
    const simplePatientVisitV1: PatientVisitV1 = {
      appointmentId,
      type,
      localDateTime,
      variant: patientVisitV1.variant,
      dateNumber,
      unixMillis: dateTime.unixTimeMillis,
    };

    const toPatientVisitV1WithScheduleInfo = (): PatientVisitV1 => ({
      ...simplePatientVisitV1,
      ...{
        scheduleCode: patientVisitV1.scheduleCode,
        requestCode: patientVisitV1.requestCode,
        versionCode: patientVisitV1.versionCode,
      },
    });

    return isScheduledVisit() && hasScheduleRequestRef() ? toPatientVisitV1WithScheduleInfo() : simplePatientVisitV1;
  };

  return {
    appointmentId,
    type: EnumUtil.fromStringOrDefault(type, VisitType, VisitType.UNKNOWN),
    localDateTime,
    formattedDateTime,
    dateTime,
    dateNumber,
    isAfter,
    isScheduledVisit,
    hasScheduleRequestRef,
    toPatientVisitV1,
    variant,
  };
};

const fromAppointmentV1 = (appointmentV1: AppointmentV1, variant: Maybe<VisitVariantType>): PatientVisitMeta => {
  StateAssert.notNull(appointmentV1.appointmentId, 'Appointment Id must be set');
  const dateTime = appointmentV1.startTimestamp ? fromTimezoneStr(appointmentV1.startTimestamp) : undefined;
  const dateNumber = dateTime ? ZonedDateTimeFormatter.toDateNumber(dateTime) : undefined;
  return fromPatientVisitV1({
    type: (appointmentV1.type as VisitType) ?? 'UNKNOWN',
    appointmentId: appointmentV1.appointmentId,
    localDateTime: appointmentV1.startTimestamp ?? '',
    scheduleCode: appointmentV1.scheduleRequestRef?.scheduleCode,
    requestCode: appointmentV1.scheduleRequestRef?.requestCode,
    versionCode: appointmentV1.scheduleRequestRef?.versionCode,
    variant,
    dateNumber,
    unixMillis: dateTime?.unixTimeMillis,
  });
};

export const PatientVisitMetaFactory = {
  fromPatientVisitV1,
  fromAppointmentV1,
};
