import { FC } from 'react';
import { StudyContext } from './StudyContext';
import { StudyContextState } from './StudyContextState';

type Props = {
  state: StudyContextState;
  children?: React.ReactNode;
};

export const StudyContextProvider: FC<Props> = ({ state, children }) => (
  <StudyContext.Provider value={state}>{children}</StudyContext.Provider>
);
