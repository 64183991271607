import { useEffect } from 'react';
import { Callback } from '@csp/csp-common-model';
import { StorageService } from '@csp/web-common';
import { timeChecker } from '../util/timeChecker';

const CLIENT_SESSION_TIMEOUT = 'CLIENT_SESSION_TIMEOUT';

type UseClientSession = {
  resetTimer: () => void;
};

const getValueFromStorage = (): number | undefined => {
  const timeFromStorage = StorageService.localStorage.getItem(CLIENT_SESSION_TIMEOUT);
  return timeFromStorage ? parseInt(timeFromStorage, 10) : undefined;
};

const setValueInStorage = (value: number): void =>
  StorageService.localStorage.setItem(CLIENT_SESSION_TIMEOUT, value.toString());

const checker = timeChecker({ get: getValueFromStorage, set: setValueInStorage });

export const useClientSessionTimer = (duration: number, callback: Callback): UseClientSession => {
  const intervalSpeedMs = 1000;
  checker.initiate(duration);

  useEffect(() => {
    const interval: number = window.setInterval(() => {
      if (checker.check()) {
        checker.setInactive();
        StorageService.localStorage.removeItem(CLIENT_SESSION_TIMEOUT);
        callback();
      }
    }, intervalSpeedMs);
    return (): void => {
      clearInterval(interval);
    };
  }, [callback, duration]);

  return {
    resetTimer: checker.reset,
  };
};
