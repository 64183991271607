import { Maybe, ObjectType } from '@csp/csp-common-model';
import { RequestTagsConfigMetaV1 } from '../meta/RequestTagsConfigMetaV1';
import { RequestTagsConfig } from '../model/RequestTagsConfig';

const fromRequestTagsConfigMetaV1 = (meta: RequestTagsConfigMetaV1 | Maybe<ObjectType>): Maybe<RequestTagsConfig> => {
  const configV1 = meta?.['TAGS_CONFIGURATION_V1'];
  if (configV1) {
    return configV1 as RequestTagsConfig;
  } else {
    return undefined;
  }
};

export const RequestTagsConfigMetaMapper = {
  fromRequestTagsConfigMetaV1,
};
