/* eslint-disable */
export const UnifyWebSitesMock = {
  title: 'title',
  sitesList: {
    buttons: { addAdditionalLocation: '[M]Add additional location' },
    texts: {
      emptyResult: '[M]No matching sites found',
      editPhone: '[M]Edit',
      editAdditionalLocation: '[M]Edit',
      replaceAdditionalLocation: '[M]Replace',
      additionalLocationHeader: '[M]${numberOfAdditionalLocations} additional locations',
      siteStatuses: {
        activeRecruiting: '[M]Active (Recruiting)',
        activeNotRecruiting: '[M]Active (not recruiting)',
        closing: '[M]Closing',
        closingTreatmentExtension: '[M]Closing (Treatment Extension)',
        initiating: '[M]Initiating',
        openForRecruitment: '[M]Open for Recruitment',
        archived: '[M]Archived',
        cancelled: '[M]Cancelled',
        candidate: '[M]Candidate',
        closed: '[M]Closed',
        inactive: '[M]Inactive',
        initiatingHold: '[M]Initiating Hold',
        inMigration: '[M]In Migration',
        onHold: '[M]On Hold',
        reEvaluate: '[M]Re-evaluate',
        terminated: '[M]Terminated',
        ecApprovalTitleLabel: '[M]UNIFY EC/IRB APPROVAL',
        ecApprovedLabel: '[M]Approved',
        ecNotApprovedLabel: '[M]Not approved',
        changeEcStatusLink: '[M]Change status',
        viewSiteDetailsLink: '[M]View details',
      },
      addressLabel: '[M]Address',
      siteContactNumberLabel: '[M]Site Contact Number',
      statusLabel: '[M]Status',
    },
    inputs: { searchBox: '[M]Search sites (Name or No.)', editMultipleSitesLink: '[M]Edit Multiple Sites' },
  },
  editPhone: {
    buttons: { cancel: '[M]Cancel', confirm: '[M]Confirm' },
    texts: {
      title: '[M]Add contact number',
      requiredField: '[M]Required field',
      infoText: '[M]This number will be shown to patients when they need to contact the study team.',
      contactUpdated: '[M]Contact phone number updated',
      updateErrorMessage: '[M]Failed to update phone number. Please contact support if problem persists.',
    },
    inputs: { countryCode: '[M]Country code', phoneNumber: '[M]Phone number' },
  },
  addAdditionalLocationForm: {
    texts: {
      header: '[M]Add additional location',
      requiredFields: '[M]Required fields',
      infoText:
        '[M]An additional location can be selected when booking a patient appointment, giving the patient the exact location they need to go to.',
      additionalLocationAdded: '[M]Additional location added.',
      addAdditionalLocationErrorMessage:
        '[M]Failed to add an additional location. Please contact support if problem persists.',
    },
    inputs: {
      locationName: '[M]Location name',
      address1: '[M]Address line 1',
      address2: '[M]Address line 2',
      address3: '[M]Address line 3',
      city: '[M]City',
      postalCode: '[M]Postcode',
    },
    buttons: { cancel: '[M]Cancel', confirm: '[M]Confirm' },
  },
  editAdditionalLocationForm: {
    texts: {
      header: '[M]Edit additional location',
      additionalLocationEdited: '[M]Additional location edited.',
      editAdditionalLocationErrorMessage:
        '[M]Failed to edit an additional location. Please contact support if problem persists.',
    },
    buttons: { cancel: '[M]Cancel', confirm: '[M]Confirm' },
  },
  deleteAdditionalLocationForm: {
    drawer: {
      texts: {
        header: '[M]Replace site location',
        requiredInfoLabel: '[M]*Required fields',
        replacementLocationTitle: '[M]Replacement location',
        replacementLocationInfo:
          '[M]Please select a new location as a substitute. This is necessary because patient visits are affected by the deletion.',
        patientsNotification: '[M]Patients will see an updated address for their visit.',
        selectReplacementLocationLabel: '[M]Select replacement location',
        selectLocationHelperText: '[M]Locations at ${siteName}',
        exportCsvTitle: '[M]Please export the list of affected patient visits',
        exportCsvAlert:
          '[M]You will not be able to access the file listing affected patient visits after the site has been deleted.',
        exportCsvInfo:
          '[M]Before replacing the site location, be aware that there are patient visits affected. The patients will have to be informed about the new location. You can export the list of affected visits as CSV to follow up with the patients.',
        totalNumberOfAppointments: '[M]${totalNumberOfVisits} booked appointments',
      },
      buttons: { exportCsv: '[M]Export CSV', cancel: '[M]Cancel', confirm: '[M]Confirm replacement' },
    },
    snackbar: { deleteSuccess: '[M]${oldLocation} was replaced by ${newLocation} in the future appointments.' },
    alertDialog: {
      texts: {
        header: '[M]No appointments found',
        description:
          '[M]There are no appointments booked at ${locationToBeRemoved} and can be removed in the future. Please don’t use this location anymore.',
      },
      buttons: { yes: '[M]Ok' },
    },
  },
  ecApprovalStatusForm: {
    texts: {
      drawerHeader: '[M]Change Unify EC/IRB approval status',
      formHeaderSingleSite: '[M]EC status for this site',
      formHeaderMultipleSites: '[M]EC status for selected sites',
      toggleApprovalStatusAriaLabel: '[M]Toggle site approval status',
      notApprovedLabel: "[M]This site is NOT approved to invite patients to be 'app users'",
      approvedLabel: "[M]This site is approved to invite patients to be both 'app users' or 'non-app users'",
      approvalWarning:
        "[M]Make sure you have selected the correct status. You will not be able to change the EC/IRB approval status once you have pressed 'Confirm'.",
      stepSelect: '[M]Select site',
      stepConfirm: '[M]Confirm site',
      multipleInfo: "[M]The list only shows sites that are not approved to invite patients to be 'app users'",
      selectAllSitesLabel: '[M]Select all sites',
      ecNotApprovedLabel: '[M]EC not approved',
      differentCountriesSelectedWarning:
        '[M]You have selected sites belonging to multiple countries. Please make sure your selection is correct.',
    },
    buttons: { cancel: '[M]Cancel', confirm: '[M]Confirm', next: '[M]Next' },
  },
  ecDetailsDrawer: {
    texts: {
      drawerHeader: '[M]Unify EC/IRB approval details',
      drawerSubtitle: '[M]EC approval change status details',
      dateLabel: '[M]Unify EC/IRB approval date',
      confirmedByLabel: '[M]Confirmed by',
    },
  },
  ecConfirmationDialog: {
    texts: {
      dialogHeader: '[M]Change Unify EC/IRB approval status',
      dialogDescription:
        "[M]Make sure you have selected the correct status. You will not be able to change the selected status once you have pressed 'Confirm'.",
      viewMoreLabel: '[M]View selected sites (${nrOfSites})',
      updateEcApprovalErrorMessage:
        '[M]Failed to update EC approval status. Please contact support if problem persists.',
    },
    buttons: { cancel: '[M]Cancel', confirm: '[M]Confirm' },
  },
};
