import { Maybe, PatientVisitMeta, PatientVisitsMeta, VisitVariantType } from '@csp/csp-common-model';
import { PatientVisitsV1 } from '@csp/dmdp-api-user-dto';
import { BookedVisitUtil } from '../../visit/util/BookedVisitUtil';
import { PatientVisitMetaFactory } from './PatientVisitMetaFactory';

const sortVisits = (patientVisits: PatientVisitMeta[] = []): PatientVisitMeta[] =>
  [...patientVisits].sort((visit1, visit2) => visit1.dateTime.unixTimeMillis - visit2.dateTime.unixTimeMillis);

const fromPatientVisits = (
  closeoutVisit: Maybe<PatientVisitMeta>,
  patientVisits: PatientVisitMeta[] = [],
): PatientVisitsMeta => {
  const visits: {
    list: PatientVisitMeta[];
    closeout: Maybe<PatientVisitMeta>;
  } = {
    list: sortVisits(patientVisits),
    closeout: closeoutVisit,
  };

  const getNext = (): Maybe<PatientVisitMeta> =>
    visits.list.find(visit => BookedVisitUtil.isVisitStartInFuture(visit.dateTime));

  const removeInternal = (appointmentId: string, reason: 'old' | 'cancelled'): void => {
    const idx = visits.list.findIndex(visit => visit.appointmentId === appointmentId);
    if (idx !== -1) {
      visits.list.splice(idx, 1);
    }
    if (reason === 'cancelled' && appointmentId === visits.closeout?.appointmentId) {
      visits.closeout = undefined;
    }
  };

  const remove = (appointmentId: string): void => removeInternal(appointmentId, 'cancelled');

  const removeOld = (): number => {
    const oldVisits = visits.list.filter(visit => !BookedVisitUtil.isVisitStartInFuture(visit.dateTime));
    oldVisits.forEach(visit => removeInternal(visit.appointmentId, 'old'));
    return oldVisits.length;
  };

  const set = (patientVisit: PatientVisitMeta): void => {
    const idx = visits.list.findIndex(visit => visit.appointmentId === patientVisit.appointmentId);
    if (idx !== -1) {
      visits.list[idx] = patientVisit;
    } else {
      visits.list.push(patientVisit);
    }
    visits.list = sortVisits(visits.list);

    if (patientVisit.variant === VisitVariantType.CLOSE_OUT) {
      visits.closeout = patientVisit;
    }
  };

  const toPatientVisitsV1 = (): PatientVisitsV1 => ({
    visits: visits.list.map(visit => visit.toPatientVisitV1()),
    closeout: visits.closeout?.toPatientVisitV1(),
  });

  return {
    visits,
    getNext,
    remove,
    removeOld,
    set,
    toPatientVisitsV1,
  };
};

const fromPatientVisitsV1 = (patientVisitsV1?: PatientVisitsV1): PatientVisitsMeta => {
  const visits = patientVisitsV1?.visits || [];

  const patientVisits = visits.map(PatientVisitMetaFactory.fromPatientVisitV1);
  const closeoutVisit = patientVisitsV1?.closeout
    ? PatientVisitMetaFactory.fromPatientVisitV1(patientVisitsV1.closeout)
    : undefined;
  return fromPatientVisits(closeoutVisit, patientVisits);
};

export const PatientVisitsMetaFactory = {
  fromPatientVisitsV1,
};
