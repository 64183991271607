import { PatientStatusConfigV1, PatientStatusConfigV1Schema } from '@csp/config-schemas';
import { StudyPatientStatusMappings } from '@csp/csp-common-model';
import { StudyPatientStatusMappingsMapper } from '@csp/csp-common-patient-status';
import { JsonValidationService } from '@csp/csp-common-util';

const JSON_SCHEMA = 'patient-status-config-v1.json';

const validateJson = (patientStatusConfigV1: PatientStatusConfigV1): void => {
  JsonValidationService.validateJsonNonStrict(JSON_SCHEMA, PatientStatusConfigV1Schema, patientStatusConfigV1);
};

const toPatientStatusConfiguration = (patientStatusConfigV1: PatientStatusConfigV1): StudyPatientStatusMappings => {
  validateJson(patientStatusConfigV1);

  return StudyPatientStatusMappingsMapper.fromV1(patientStatusConfigV1);
};

export const PatientStatusConfigServiceV1 = {
  toPatientStatusConfiguration,
};
