import { ZonedDateTimeStr } from '@csp/csp-common-model';
import { AssessmentCommentV1, Refs } from '@csp/dmdp-api-assessment-dto';

export type AssessmentComment = {
  refs?: Refs;
  content: string;
  createdAt?: ZonedDateTimeStr;
  commentId: string;
};

const from = ({ refs, content, audit, commentId }: AssessmentCommentV1): AssessmentComment => ({
  commentId,
  refs,
  content,
  createdAt: audit?.createdAt,
});

export const AssessmentComment = {
  from,
};
