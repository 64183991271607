import { PatientHealthEvent, PatientHealthEventSortOrder, HealthEventStatusType } from '@csp/csp-common-model';
import { HealthEventStatusTypeV1 } from '@csp/dmdp-api-healthevent-dto';
import { PatientHealthEventV1 } from '@csp/dmdp-api-user-dto';
import { toTimezoneStr, fromTimezoneStr } from '@csp/csp-common-date-util';
import { PatientHealthEventSortOrderType } from '@csp/csp-common-user';
import { HealthEvent } from './HealthEvent';

const evalSortOrder = (status: HealthEventStatusTypeV1): PatientHealthEventSortOrder =>
  status === 'NEW' ? PatientHealthEventSortOrderType.HIGHEST_PRIORITY : PatientHealthEventSortOrderType.NONE;

const from = (
  healthEventId: string,
  statusV1: HealthEventStatusTypeV1,
  timestamp: string,
  solvedByUserId?: string,
  solvedByDisplayName?: string,
): PatientHealthEvent => {
  const status = HealthEventStatusType.from(statusV1);
  const healthEventSortOrder = evalSortOrder(statusV1);
  const timestampUnixMillis = fromTimezoneStr(timestamp).unixTimeMillis;

  const isAfter = (patientHealthEvent: PatientHealthEvent | undefined): boolean =>
    !patientHealthEvent || patientHealthEvent.timestampUnixMillis < timestampUnixMillis;

  const toPatientHealthEventV1 = (): PatientHealthEventV1 => ({
    healthEventId,
    status: statusV1,
    timestamp,
    healthEventSortOrder,
    solvedByUserId,
    solvedByDisplayName,
  });

  const hasSameOrHigherPrioThan = (patientHealthEvent: PatientHealthEvent): boolean => {
    const thisHealthEventStatus = status;
    const otherHealthEventStatus = patientHealthEvent.status;

    return (
      thisHealthEventStatus === otherHealthEventStatus ||
      (thisHealthEventStatus === 'NEW' && otherHealthEventStatus !== 'NEW')
    );
  };

  return {
    healthEventId,
    status,
    timestamp,
    timestampUnixMillis,
    healthEventSortOrder,
    isAfter,
    solvedByUserId,
    solvedByDisplayName,
    toPatientHealthEventV1,
    hasSameOrHigherPrioThan,
  };
};

const fromHealthEvent = ({ healthEventId, status, timestamp }: HealthEvent): PatientHealthEvent =>
  from(healthEventId, status.value, toTimezoneStr(timestamp));

const fromPatientHealthEventV1 = ({
  healthEventId,
  status,
  timestamp,
  solvedByUserId,
  solvedByDisplayName,
}: PatientHealthEventV1): PatientHealthEvent =>
  from(healthEventId, status, timestamp, solvedByUserId, solvedByDisplayName);

export const PatientHealthEventFactory = {
  from,
  fromHealthEvent,
  fromPatientHealthEventV1,
};
