/* eslint-disable */
export const UnifyWebStudyOverviewMock = {
  title: 'title',
  studyInformationCard: {
    title: '[M]About this study',
    body: '[M]A registry-based, randomised, double-blind, placebo-controlled Cardiovascular Outcomes trial to evaluate the effect of Dapagliflozin on the incidence of heart failure or cardiovascular death in patients without diabetes with acute myocardial infarction at increased risk for subsequent development of hear failure.',
    newStudy: '[M]New study',
    studyStatus: {
      ACTNOREC: '[M]Active (not recruiting)',
      ACTREC: '[M]Active (Recruiting)',
      ARCH: '[M]Archived',
      CANCELLED: '[M]Cancelled',
      CAND: '[M]Candidate',
      CLOSED: '[M]Closed',
      CLOSING: '[M]Closing',
      CLOSINGTE: '[M]Closing (Treatment Extension)',
      DELETED: '[M]Deleted',
      INMIGR: '[M]In Migration',
      ONHOLD: '[M]On Hold',
      OPENFORREC: '[M]Open for Recruitment',
      INIT: '[M]Initiating',
      POST: '[M]Postponed',
      TERM: '[M]Terminated',
      UNKNOWN: '[M]Unknown',
    },
  },
  documentsCard: {
    title: '[M]Documents',
    viewAllDocumentsLabel: '[M]View all documents',
    linkText: '[M]View',
    versionPublishedLabel: '[M]Published on',
    versionLabel: '[M]Version',
  },
  studyDocuments: {
    title: '[M]Documents',
    subtitle: '[M]Study overview',
    filters: {
      roles: '[M]Roles',
      countries: '[M]Countries',
      startDate: '[M]Start date',
      endDate: '[M]End date',
      all: '[M]All',
      clearAll: '[M]Clear All',
      noFilteredData: '[M]No filtered data',
    },
    defaultDocumentTypeHeader: '[M]Other documents',
    viewMoreLabel: '[M]View more',
    viewLessLabel: '[M]View less',
    roleTagsLabel: { HCP: '[M]HCP', CRA: '[M]CRA' },
    noDocumentsLabel: '[M]No Documents',
  },
  closeOutTracker: {
    initiateCard: {
      title: '[M]Start the Close out period',
      infoTextMd: '[M]Click the button and set a start and end date for the Close out visit time period.',
      button: '[M]Set Close out visit time period',
    },
    drawer: {
      title: '[M]Enable the Close Out Tracker ',
      cancel: '[M]Cancel',
      confirm: '[M]Confirm',
      notification: '[M]Close Out Tracker is now live',
      text: {
        requiredFields: '[M]Required fields',
        subtitleSetDate: '[M]Add the Primary Analysis Censoring Date (PACD)',
        subtitleTimeWindow: '[M]Close Out visit window - based on the PACD',
      },
      alert: {
        setOnceWarning:
          '[M]Please only enter the PACD when it’s confirmed. You will not be able to go back and change it later.',
        enableCloseOutFailed: '[M]Failed to enable Close out time period',
      },
      datePicker: { label: '[M]PACD' },
      timeWindow: { date: '[M]PACD', start: '[M]Window start', end: '[M]Window end' },
      confirmDialog: {
        title: '[M]Enable Close Out Tracker',
        descriptionMd:
          '[M]Once you click ‘Confirm’ you cannot change the PACD.\n\nPlease be aware the Close Out visit will be appear on all patients’ visit tab once you confirm.',
        cancel: '[M]Cancel',
        confirm: '[M]Confirm',
      },
    },
  },
};
