import { clone, isObject, set } from 'lodash';
import { CspError } from '@csp/csp-common-model';

const isEnvConfigParam = (name: string): boolean => /^env\./.test(name);
const toEnvPath = (name: string): string => name.replace(/^env\./, '');

export const overridesEnvConfigFromParams = <T extends object>(source: T, urlSearchParams?: URLSearchParams): T => {
  if (!isObject(source)) {
    throw CspError.internal('Type Error: source is required to be an object');
  }
  if (urlSearchParams) {
    const withOverrides = clone(source);
    urlSearchParams.forEach((value, name) => {
      if (isEnvConfigParam(name)) {
        const envPath = toEnvPath(name);
        let valueJson = value;
        try {
          // handles booleans, numbers and objects
          valueJson = JSON.parse(value);
        } catch (e) {
          // if not json, just use string value
        }
        console.info(`Overriding env-config.json - ${envPath}`, valueJson);
        set(withOverrides, envPath, valueJson);
      }
    });
    return withOverrides;
  } else {
    return source;
  }
};
