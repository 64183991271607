/* eslint-disable */
export const UnifyScheduleVisitsSchema = {
  title: 'UnifyScheduleVisits',
  type: 'object',
  properties: {
    schedules: {
      title: 'Schedules',
      helper: "Click 'add new item' to add a new schedule.",
      type: 'array',
      items: {
        type: 'object',
        properties: {
          scheduleCode: {
            title: 'Schedule Code',
            type: 'string',
            helper: 'The schedule code this visit belongs to',
            placeholder: 'DAPA_MI',
          },
          visits: {
            title: 'Visits',
            type: 'array',
            items: {
              title: 'Visit',
              type: 'object',
              properties: {
                requestCode: {
                  title: 'Request Code',
                  type: 'string',
                  helper: 'The request code for this visit.',
                  placeholder: 'VISIT_1',
                },
                title: {
                  title: 'Visit Title',
                  type: 'string',
                  helper: 'Title of visit.',
                  placeholder: 'Hospital appointment / Study visit',
                },
                visitType: { title: 'Visit Type', type: 'string', helper: 'Type of visit.', placeholder: 'Screening' },
                hcpDescriptionMd: {
                  title: 'HCP Description',
                  type: 'string',
                  placeholder:
                    'The RIKS-HIA/MINAP variables are recorded according to clinical routine including local blood samples as dictated by the registry, of which the following samples...',
                  helper: 'Description shown to the HCP in the portal.',
                },
                patientDescriptionMd: {
                  title: 'Patient Description',
                  type: 'string',
                  placeholder:
                    'This is your routine follow up study visit. The doctor will review how you are getting on with the study. The doctor will ask you if you have been hospitalized or feel any symptoms of heart disease.\nPlease remember to bring all of your study medication and the CleverCap LiteTM Clever Cap  device with you.\nYou will be dispensed study drug during this visit.',
                  helper: 'Description shown to the patient.',
                },
                preActivitiesMd: {
                  title: 'Pre activities Description',
                  type: 'string',
                  placeholder: 'Please bring your medication, your Clever cap, and your Unify mobile',
                  helper: 'Pre activities shown for the patient.',
                },
                allowedVersions: {
                  title: 'Visit Version List',
                  type: 'array',
                  items: {
                    type: 'object',
                    properties: {
                      version: {
                        type: 'string',
                        placeholder: '1',
                        helper: 'A version number that these visit descriptions applies to.',
                      },
                    },
                  },
                },
                visitConfirmationLearnMore: {
                  type: 'object',
                  title: 'Visit confirmation Learn more drawer study specific and unique visit content',
                  helper: 'The content items are unique for each visit',
                  properties: {
                    labelsAndDescriptions: {
                      title: 'Visit confirmation Learn more content items',
                      type: 'array',
                      items: {
                        title: 'Visit confirmation Learn more content item',
                        type: 'object',
                        properties: {
                          label: { type: 'string', title: 'Visit confirmation Learn more content item label' },
                          bodyMd: {
                            type: 'string',
                            title: 'Visit confirmation Learn more content item description. Markdown is supported',
                          },
                        },
                      },
                    },
                  },
                },
                bioSampleKitDescriptions: {
                  title: 'BioSample kit descriptions visit study specific and unique visit content items',
                  type: 'array',
                  helper: "It's optional to define BioSample kit descriptions",
                  items: {
                    title: 'BioSample ki descriptions visit study specific and unique visit content item',
                    type: 'object',
                    properties: { body: { type: 'string', title: 'BioSample kit description item' } },
                  },
                },
              },
            },
          },
        },
      },
      mock: [
        {
          scheduleCode: 'STUDY_PROTOCOL_APPOINTMENTS',
          visits: [
            {
              requestCode: 'VISIT_1',
              title: '[M] Randomization Visit',
              visitType: '[M] Randomization',
              hcpDescriptionMd:
                'HCP DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_1 - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              patientDescriptionMd:
                'PATIENT DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_1 - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              preActivitiesMd:
                'PATIENT PRE-ACTIVITIES for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_1 - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              allowedVersions: [{ version: 'v1.0' }, { version: 'v1.0a' }],
              visitConfirmationLearnMore: {
                labelsAndDescriptions: [
                  {
                    label: '[M]Label 1',
                    bodyMd:
                      "[M]Here's a line for us to start with.\n\nThis line is separated from the one above by two newlines, so it will be a *separate paragraph*.\n\nThis line is also a separate paragraph, but...\nThis line is only separated by a single newline, so it's a separate line in the *same paragraph*.",
                  },
                  {
                    label: '[M]Label 2',
                    bodyMd:
                      "[M]Here's a line for us to start with.\n\nThis line is separated from the one above by two newlines, so it will be a *separate paragraph*.\n\nThis line is also a separate paragraph, but...\nThis line is only separated by a single newline, so it's a separate line in the *same paragraph*.",
                  },
                ],
              },
              bioSampleKitDescriptions: [{ body: 'Tumor kit' }],
            },
            {
              requestCode: 'VISIT_2',
              title: '[M] Visit 2',
              visitType: '[M] Routine',
              hcpDescriptionMd:
                'HCP DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_2 - versions [v1.0] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              patientDescriptionMd:
                'PATIENT DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_2 - versions [v1.0] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              preActivitiesMd:
                'PATIENT PRE-ACTIVITIES for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_2 - versions [v1.0] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              allowedVersions: [{ version: 'v1.0' }],
            },
            {
              requestCode: 'VISIT_2',
              title: '[M] Visit 2',
              visitType: '[M] Routine',
              hcpDescriptionMd:
                'HCP DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_2 - versions [v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              patientDescriptionMd:
                'PATIENT DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_2 - versions [v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              preActivitiesMd:
                'PATIENT PRE-ACTIVITIES for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_2 - versions [v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              allowedVersions: [{ version: 'v1.0a' }],
            },
            {
              requestCode: 'VISIT_2A',
              title: '[M] Visit 2A (Lab Test)',
              visitType: '[M] Lab Test',
              hcpDescriptionMd:
                'HCP DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_2A - versions [v1.0] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              patientDescriptionMd:
                'PATIENT DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_2A - versions [v1.0] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              preActivitiesMd:
                'PATIENT PRE-ACTIVITIES for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_2A - versions [v1.0] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              allowedVersions: [{ version: 'v1.0' }],
            },
            {
              requestCode: 'VISIT_2A',
              title: '[M] Visit 2A (Lab Test)',
              visitType: '[M] Lab Test',
              hcpDescriptionMd:
                'HCP DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_2A - versions [v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              patientDescriptionMd:
                'PATIENT DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_2A - versions [v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              preActivitiesMd:
                'PATIENT PRE-ACTIVITIES for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_2A - versions [v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              allowedVersions: [{ version: 'v1.0a' }],
            },
            {
              requestCode: 'VISIT_3',
              title: '[M] Visit 3',
              visitType: '[M] Routine',
              hcpDescriptionMd:
                'HCP DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_3 - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              patientDescriptionMd:
                'PATIENT DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_3 - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              preActivitiesMd:
                'PATIENT PRE-ACTIVITIES for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_3 - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              allowedVersions: [{ version: 'v1.0' }, { version: 'v1.0a' }],
            },
            {
              requestCode: 'VISIT_3A',
              title: '[M] Visit 3A (Lab Test)',
              visitType: '[M] Routine',
              hcpDescriptionMd:
                'HCP DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_3A - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              patientDescriptionMd:
                'PATIENT DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_3A - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              preActivitiesMd:
                'PATIENT PRE-ACTIVITIES for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_3A - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              allowedVersions: [{ version: 'v1.0' }, { version: 'v1.0a' }],
            },
            {
              requestCode: 'VISIT_4',
              title: '[M]Visit 4',
              visitType: '[M] Re-occuring Routine',
              hcpDescriptionMd:
                'HCP DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_4 - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              patientDescriptionMd:
                'PATIENT DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_4 - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              preActivitiesMd:
                'PATIENT PRE-ACTIVITIES for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_4 - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              allowedVersions: [{ version: 'v1.0' }, { version: 'v1.0a' }],
              bioSampleKitDescriptions: [{ body: 'BRCA KNOWN' }, { body: 'ctDNA' }],
            },
            {
              requestCode: 'VISIT_4_Plus',
              title: '[M] Visit 4',
              visitType: '[M] Re-occuring Routine',
              hcpDescriptionMd:
                'HCP DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_4_Plus - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              patientDescriptionMd:
                'PATIENT DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_4_Plus - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              preActivitiesMd:
                'PATIENT PRE-ACTIVITIES for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_4_Plus - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              allowedVersions: [{ version: 'v1.0' }, { version: 'v1.0a' }],
            },
            {
              requestCode: 'VISIT_4A_Plus',
              title: '[M] Visit 4A (Lab Test)',
              visitType: '[M] Re-occuring Routine',
              hcpDescriptionMd:
                'HCP DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_4A_Plus - versions [v1.0] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              patientDescriptionMd:
                'PATIENT DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_4A_Plus - versions [v1.0] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              preActivitiesMd:
                'PATIENT PRE-ACTIVITIES for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_4A_Plus - versions [v1.0] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              allowedVersions: [{ version: 'v1.0' }],
            },
            {
              requestCode: 'VISIT_4A_Plus',
              title: '[M] Visit 4A (Lab Test)',
              visitType: '[M] Re-occuring Routine',
              hcpDescriptionMd:
                'HCP DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_4A_Plus - versions [v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              patientDescriptionMd:
                'PATIENT DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_4A_Plus - versions [v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              preActivitiesMd:
                'PATIENT PRE-ACTIVITIES for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode VISIT_4A_Plus - versions [v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              allowedVersions: [{ version: 'v1.0a' }],
            },
            {
              requestCode: 'PTDV',
              title: '[M] PTDV',
              visitType: '[M] Study Closure',
              hcpDescriptionMd:
                'HCP DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode PTDV - versions [v1.0] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              patientDescriptionMd:
                'PATIENT DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode PTDV - versions [v1.0] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              preActivitiesMd:
                'PATIENT PRE-ACTIVITIES for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode PTDV - versions [v1.0] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              allowedVersions: [{ version: 'v1.0' }],
            },
            {
              requestCode: 'PTDV',
              title: '[M] PTDV',
              visitType: '[M] Study Closure',
              hcpDescriptionMd:
                'HCP DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode PTDV - versions [v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              patientDescriptionMd:
                'PATIENT DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode PTDV - versions [v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              preActivitiesMd:
                'PATIENT PRE-ACTIVITIES for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode PTDV - versions [v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              allowedVersions: [{ version: 'v1.0a' }],
            },
            {
              requestCode: 'Study_Closure_Visit',
              title: '[M] Study Closure Visit',
              visitType: '[M] Study Closure',
              hcpDescriptionMd:
                'HCP DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode Study_Closure_Visit - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              patientDescriptionMd:
                'PATIENT DESCRIPTION for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode Study_Closure_Visit - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              preActivitiesMd:
                'PATIENT PRE-ACTIVITIES for scheduleCode STUDY_PROTOCOL_APPOINTMENTS - requestCode Study_Closure_Visit - versions [v1.0, v1.0a] - At himenaeos lacus lorem luctus netus nullam penatibus phasellus placerat praesent risus semper sit. At condimentum facilisis fusce justo mattis molestie phasellus quisque sapien tincidunt viverra. Consequat conubia cras egestas himenaeos id lacus lobortis lorem luctus mi nibh parturient phasellus placerat platea porta potenti quis quisque scelerisque tellus tincidunt turpis ultricies velit vitae.',
              allowedVersions: [{ version: 'v1.0' }, { version: 'v1.0a' }],
            },
          ],
        },
      ],
    },
  },
  description: 'unify:schedulevisits',
  $schema: 'http://json-schema.org/draft-07/schema#',
};
