import { RpmAlgorithmCode } from '@csp/csp-common-rpm-model';
import { useMemo } from 'react';
import { RpmStudyConfigUtil } from '@csp/csp-common-rpm-event';
import { useStudyContext } from '../../../../common/context/study/useStudyContext';

export const useRpmConfigAlgorithmCodes = (): RpmAlgorithmCode[] => {
  const { studyConfig } = useStudyContext();

  return useMemo(() => RpmStudyConfigUtil.getConfiguredAlgorithmCodes(studyConfig), [studyConfig]);
};
