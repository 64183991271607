import { FormControlLabel as MuiFormControlLabel, FormControlLabelProps } from '@mui/material';
import styled from '@emotion/styled';
import { Property } from 'csstype';
import { notIncludes } from '@csp/csp-common-util';
import { css, SerializedStyles } from '@emotion/react';

type ForwardProps = {
  alignItems?: Property.AlignItems;
  display?: Property.Display;
};

type Props = FormControlLabelProps & ForwardProps;

export const FormControlLabel = styled(MuiFormControlLabel, {
  shouldForwardProp: notIncludes(['alignItems', 'display']),
})<Props>`
  ${({ alignItems, display }): SerializedStyles => css`
    &.MuiFormControlLabel-root {
      align-items: ${alignItems ?? 'center'};
      .MuiFormControlLabel-label {
        padding-top: 1px;
        display: ${display ?? 'inline-flex'};
      }
    }
  `}
`;
