import { ApiOptions, Maybe, Patient, PatientVisitsMeta, VisitId, VisitVariantType } from '@csp/csp-common-model';
import { getMeta } from '@csp/csp-common-util';
import { toRestOptions } from '@csp/csp-fe-auth';
import { AppointmentV1 } from '@csp/dmdp-api-appointment-dto';
import { UserRestServiceV1 } from '@csp/dmdp-api-client';
import { createPatientVisitsMetaV1, PATIENT_VISIT_V1_META_TYPE, UserMetaV1, UserV1 } from '@csp/dmdp-api-user-dto';
import { PatientVisitMetaFactory } from '../../../model/PatientVisitMetaFactory';
import { PatientVisitsMetaFactory } from '../../../model/PatientVisitsMetaFactory';

const addOrUpdateMeta = async (
  patient: Patient,
  patientVisits: PatientVisitsMeta,
  apiOptions?: ApiOptions,
): Promise<void> => {
  patientVisits.removeOld();

  await UserRestServiceV1.addOrUpdateMeta(
    toRestOptions(apiOptions),
    patient.userV1,
    createPatientVisitsMetaV1(patientVisits.toPatientVisitsV1()),
  );
};

/** Returns the visit information located in user's meta data */
const getPatientVisits = (userV1: UserV1): PatientVisitsMeta => {
  const metas = userV1.metas as Maybe<UserMetaV1[]>;
  const patientVisitMetaV1 = getMeta(metas, PATIENT_VISIT_V1_META_TYPE)?.data;
  return PatientVisitsMetaFactory.fromPatientVisitsV1(patientVisitMetaV1);
};

/** Removes the visit if existing. Removes visits in the past. Update the user's meta data in the database. Note that the patient's meta data is mutated */
const removeVisit = async (
  patient: Patient,
  appointmentId: string,
  apiOptions?: ApiOptions,
): Promise<PatientVisitsMeta> => {
  const patientVisits = getPatientVisits(patient.userV1);
  patientVisits.remove(appointmentId);
  await addOrUpdateMeta(patient, patientVisits, apiOptions);
  return patientVisits;
};

/** Updates the visit if already existing. Otherwise the visit will be added. Visits are sorted and visits in past are removed then is the user's meta data stored in the database. Note that the patient's meta data is mutated */
const upsertVisit = async (
  patient: Patient,
  appointmentV1: AppointmentV1,
  variant: Maybe<VisitVariantType>,
  apiOptions?: ApiOptions,
): Promise<PatientVisitsMeta> => {
  const patientVisits = getPatientVisits(patient.userV1);
  patientVisits.set(PatientVisitMetaFactory.fromAppointmentV1(appointmentV1, variant));
  await addOrUpdateMeta(patient, patientVisits, apiOptions);
  return patientVisits;
};

const clearVisits = async (patient: Patient, visitIds: VisitId[] = [], apiOptions?: ApiOptions): Promise<void> => {
  try {
    patient.visits.visits.list
      .filter(visit => visitIds.includes(visit.appointmentId))
      .map(visit => visit.appointmentId)
      .forEach(visitId => patient.visits.remove(visitId));
    await addOrUpdateMeta(patient, patient.visits, apiOptions);
  } catch {
    // Do not fail on this
  }
};

export const PatientVisitMetaService = {
  getPatientVisits,
  removeVisit,
  upsertVisit,
  clearVisits,
};
