/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum QuestionnaireResponseAggregateIncludeFieldV2 {
    COUNT = 'COUNT',
    MIN_CURRENT_STATUS_TIMESTAMP = 'MIN_CURRENT_STATUS_TIMESTAMP',
    MAX_CURRENT_STATUS_TIMESTAMP = 'MAX_CURRENT_STATUS_TIMESTAMP',
    MIN_CURRENT_STATUS_CREATED_AT = 'MIN_CURRENT_STATUS_CREATED_AT',
    MAX_CURRENT_STATUS_CREATED_AT = 'MAX_CURRENT_STATUS_CREATED_AT',
    MIN_EVENT_ID = 'MIN_EVENT_ID',
    MAX_EVENT_ID = 'MAX_EVENT_ID',
}