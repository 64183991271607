import styled from '@emotion/styled';
import { IconButton as MuiIconButton, IconButtonProps as Props, TooltipProps } from '@mui/material';
import { spacing, SpacingProps } from '@mui/system';
import { Ref, forwardRef } from 'react';
import { BrandedIcon } from '../brandedIcon/BrandedIcon';
import { Tooltip } from '../dataDisplay/tooltip/Tooltip';
import { OnClickButton } from '../../common/model/EventModels';
import { BrandedIconProps } from '../brandedIcon/model/BrandedIconProps';

type IconButtonProps = Props &
  SpacingProps & {
    iconProps?: BrandedIconProps;
    onClick?: OnClickButton;
    tooltip?: Pick<TooltipProps, 'title' | 'arrow' | 'placement'>;
  };

const MuiIconButtonStyled = styled(MuiIconButton)<SpacingProps>`
  ${spacing}
  &:hover {
    box-shadow: none;
  }
`;

export const IconButton = forwardRef(
  (
    { iconProps, onClick, disabled, tooltip, children, ...props }: IconButtonProps,
    ref: Ref<HTMLButtonElement> | null,
  ) =>
    !disabled && tooltip ? (
      <Tooltip arrow={tooltip.arrow ?? true} placement={tooltip.placement ?? 'bottom'} title={tooltip.title}>
        <MuiIconButtonStyled onClick={onClick} {...props} ref={ref}>
          {iconProps && <BrandedIcon {...iconProps} />}
          {children}
        </MuiIconButtonStyled>
      </Tooltip>
    ) : (
      <MuiIconButtonStyled onClick={onClick} {...props} ref={ref}>
        {iconProps && <BrandedIcon {...iconProps} />}
        {children}
      </MuiIconButtonStyled>
    ),
);
IconButton.displayName = 'IconButton';
