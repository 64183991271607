import { CallbackAsync, Maybe, StateAssert, User } from '@csp/csp-common-model';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { noop } from 'lodash/fp';
import { ExceptionTracker } from '../model/ExceptionTracker';
import { SentryOptions } from '../model/SentryOptions';
import { TelemetryContext } from '../model/TelemetryContext';
import { TelemetryException } from '../model/TelemetryException';
import { TelemetryServicePlugin } from '../model/TelemetryServicePlugin';
import { TelemetryTags } from '../model/TelemetryTags';

/** Exported just for testing purposes. */
export const emptyExceptionTracker: ExceptionTracker = { trackException: noop };

const assertHasText = (dsn: string): string => {
  StateAssert.hasText(dsn, 'No Data Source Name (DSN) configured.');
  return dsn;
};

const createTelemetryServicePlugin = (): TelemetryServicePlugin => {
  const isSentryEnabled = (): boolean => !!Sentry.getCurrentHub().getClient();

  const init = ({ dsn = '', ...otherOptions }: SentryOptions): void => {
    try {
      Sentry.init({
        dsn: assertHasText(dsn),
        integrations: [
          new Integrations.BrowserTracing(),
          new Sentry.Integrations.Breadcrumbs({
            console: false,
          }),
        ],
        ignoreErrors: ['Non-Error promise rejection captured with value:'],
        ...otherOptions,
      });
      console.debug('[Sentry.io] is enabled.');
    } catch (e) {
      console.debug('[Sentry.io] could not be loaded.', e);
    }
  };

  const configureScope = (user: Maybe<User>, telemetryTags: Maybe<TelemetryTags>): void => {
    Sentry.configureScope(scope => {
      scope.setUser({ id: user?.userId });
      scope.setTags(telemetryTags ?? {});
    });
  };

  const getExceptionTracker = (): ExceptionTracker => {
    if (isSentryEnabled()) {
      return {
        trackException: ({ error, context }: TelemetryException): void => {
          Sentry.withScope(scope => {
            if (context !== undefined) {
              const { extra, tags } = context as TelemetryContext;
              if (extra) {
                scope.setExtras(extra);
              }
              if (tags) {
                scope.setTags(tags);
              }
            }
            Sentry.captureException(error);
          });
        },
      };
    } else {
      console.debug('Please call init(). [Sentry.io] has not been loaded.');
      return emptyExceptionTracker;
    }
  };

  const reset: CallbackAsync = async () => {
    if (isSentryEnabled()) {
      Sentry.configureScope(scope => scope.clear());
      await Sentry.flush();
    }
  };

  const trackException = (exception: TelemetryException): void =>
    getExceptionTracker().trackException({
      ...exception,
    });

  return {
    init,
    configureScope,
    reset,
    trackException,
  };
};

export const TelemetryService = createTelemetryServicePlugin();
