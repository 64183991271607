import type { PartialDeep } from 'type-fest';
import { merge } from 'lodash';

export const createMockCreator =
  <T>(baseMock: T) =>
  (partialMock: Partial<T> = {}): T =>
    Object.assign({}, baseMock, partialMock);

export const createDeepMockCreator =
  <T>(baseMock: T) =>
  (partialMock?: PartialDeep<T>): T =>
    merge({}, baseMock, partialMock);
