import { CspError, DmdpError } from '@csp/csp-common-model';
import { isError as isErrorInstance } from 'lodash/fp';

export const isError = (error: unknown): error is Error => isErrorInstance(error);

export const getErrorStack = (e: unknown): string[] => (isError(e) ? e.stack || '' : '').split('\n');

export const getMessage = (error: unknown): string => (isError(error) ? error.message : String(error));

export const getMessages = (errors: unknown[]): string => errors.map(getMessage).join('\n');

export const toError = (error: unknown): Error => (isError(error) ? error : new Error(String(error)));

// TODO: Johan L - Add tests and move to CspError
export const toCspError = (error: unknown): CspError =>
  CspError.isCspError(error) ? error : CspError.internal(getMessage(error));

export const isErrorWithCode = (error: unknown): error is { code: string | number } =>
  isError(error) && 'code' in error;

export const isErrorWithId = (error: unknown): error is { id: number } => isError(error) && 'id' in error;

export const isErrorWithUserId = (error: unknown): error is { userId: string } => isError(error) && 'userId' in error;

export function assertIsError(e: unknown): asserts e is Error {
  if (!isError(e)) {
    throw CspError.badState('Wrong error type: is not Error');
  }
}

export function assertIsCspError(e: unknown): asserts e is CspError {
  if (!CspError.isCspError(e)) {
    throw CspError.badState('Wrong error type: is not CspError');
  }
}

export function assertIsDmdpError(e: unknown): asserts e is DmdpError {
  if (!DmdpError.isDmdpError(e)) {
    throw CspError.badState('Wrong error type: is not DmdpError');
  }
}
