import { CognitoService } from '@csp/common';
import { StorageService } from '../../../service/StorageService';

const clearStoredTokens = (): void => {
  for (const key in StorageService.localStorage) {
    if (key.indexOf('CognitoIdentityServiceProvider') !== -1) {
      StorageService.localStorage.removeItem(key);
    }
  }
};

const webSignOutCognito = async (global = false): Promise<void> => {
  await CognitoService.signOut(global);
  clearStoredTokens();
};

export const WebSignOutService = {
  webSignOutCognito,
};
