import { Maybe } from '@csp/csp-common-model';
import { isDefined } from '@csp/csp-common-util';
import { DataChangeIdRef } from '@csp/csp-common-dcf-model';
import { DmdpHttpHeaderConst } from '../type/DmdpHttpHeaderConst';

const generateHeaders = (
  dcIdRef: Maybe<DataChangeIdRef>,
): Maybe<Record<typeof DmdpHttpHeaderConst.BOOST_DC_ID, DataChangeIdRef>> =>
  isDefined(dcIdRef)
    ? {
        [DmdpHttpHeaderConst.BOOST_DC_ID]: dcIdRef,
      }
    : undefined;

export const DataChangeUtil = {
  generateHeaders,
};
