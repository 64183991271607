import { AssessmentCode } from '@csp/csp-common-assessment';
import { fromTimezoneStr } from '@csp/csp-common-date-util';
import { EventIdStr, UserId, VisitId, ZonedDateTime } from '@csp/csp-common-model';
import { RequestTagCode } from '@csp/csp-common-scheduling';
import { DeviceSessionV1 } from '@csp/dmdp-api-device-dto';
import { DeviceObservationRequestRef } from './DeviceObservationRequestRef';
import { DeviceSessionCode } from './DeviceSessionCode';
import { DeviceSessionId } from './DeviceSessionId';
import { DeviceSessionRefsKey } from './DeviceSessionRefsKey';
import { DeviceSessionRequestStatus } from './DeviceSessionRequestStatus';
import { DeviceSessionReviewStatus } from './DeviceSessionReviewStatus';
import { DeviceSessionStatus } from './DeviceSessionStatus';
import { DeviceSessionVerificationMethod } from './DeviceSessionVerificationMethod';

export type DeviceSession = {
  assessmentCode?: AssessmentCode;
  createdAtTimestamp: ZonedDateTime;
  deviceSessionId: DeviceSessionId;
  eventId: EventIdStr;
  messageId?: string;
  requestTag?: RequestTagCode;
  scheduleRequestRef?: DeviceObservationRequestRef;
  scheduleRequestStatus?: DeviceSessionRequestStatus;
  sessionCode: DeviceSessionCode;
  sessionReviewStatus: DeviceSessionReviewStatus;
  sessionStatus: DeviceSessionStatus;
  userId: UserId;
  verificationMethod: DeviceSessionVerificationMethod;
  visitId?: VisitId;
};

const from = ({
  assessmentCode,
  deviceSessionId,
  eventId,
  meta,
  refs,
  scheduleRequestRef,
  scheduleRequestStatus,
  sessionCode,
  sessionStatus,
  sessionTimestamp,
  userId,
  verificationMethod,
}: DeviceSessionV1): DeviceSession => ({
  assessmentCode,
  createdAtTimestamp: fromTimezoneStr(sessionTimestamp),
  deviceSessionId,
  eventId,
  messageId: meta?.MESSAGE_ID,
  requestTag: refs?.find(ref => ref.key === DeviceSessionRefsKey.REQUEST_TAG_V1)?.value,
  scheduleRequestRef: DeviceObservationRequestRef.from(scheduleRequestRef),
  scheduleRequestStatus: scheduleRequestStatus as DeviceSessionRequestStatus,
  sessionCode,
  sessionReviewStatus: meta?.REVIEW_STATUS ?? 'UNKNOWN',
  sessionStatus: sessionStatus as DeviceSessionStatus,
  userId,
  verificationMethod: verificationMethod as DeviceSessionVerificationMethod,
  visitId: refs?.find(ref => ref.key === DeviceSessionRefsKey.VISIT_ID)?.value,
});

export const DeviceSession = {
  from,
};
