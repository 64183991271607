import styled from '@emotion/styled';
import { BoxProps } from '@mui/material/Box/Box';
import { FC } from 'react';
import { useTheme } from '../../../utils/MaterialUtils';
import { Box } from '../../layout/box/Box';
import { useMediaQ } from '../../../hooks/useMediaQ';
import { Spinner } from './Spinner';

type LoadingOverlayProps = BoxProps & {
  width?: string;
  left?: string;
  position?: string;
  isLoading?: boolean;
  bgcolor?: string;
};

const LoadingOverlayStyled = styled(Box)`
  ${({ theme }): string => `
  z-index: ${theme.zIndex.snackbar};
  top: 0;
  right: 0;
  bottom: 0;
  ${theme.breakpoints.down('sm')} {
    width:100%;
  }
 `}
`;

export const LoadingOverlay: FC<LoadingOverlayProps> = ({
  width = '100%',
  left,
  position = 'fixed',
  isLoading,
  bgcolor,
  children,
}) => {
  const theme = useTheme();
  const xsDown = useMediaQ('xs', 'down');
  return isLoading ? (
    <LoadingOverlayStyled
      alignItems="center"
      bgcolor={bgcolor ?? theme.palette.transparent.white}
      display="flex"
      width={width}
      left={left}
      justifyContent="center"
      position={position}
    >
      <Spinner size={xsDown ? 'small' : 'medium'} />
    </LoadingOverlayStyled>
  ) : (
    <>{children}</>
  );
};
