import { createSelector } from '@reduxjs/toolkit';
import { getStudyDcodeSelector, getUserSelector } from '../../security/ducks/principalContextSelectors';
import { PermissionService } from '../../security/service/PermissionService';
import { PermissionType } from '../../security/type/PermissionType';

export const canSkipConsentApprovalsSelector = createSelector(getUserSelector, user =>
  user
    ? user.orgIds.some(orgId => PermissionService.hasPermission(PermissionType.CAN_SKIP_CONSENT_APPROVALS, orgId))
    : false,
);

export const shouldApproveConsentsSelector = createSelector(
  getStudyDcodeSelector,
  getUserSelector,
  (dcode, user) => !!(dcode && user),
);
