import { CustomStatusMapper } from '@csp/csp-common-custom-status';
import { Org } from '@csp/csp-common-model';
import { OrgStatusType, OrgType, OrgV1 } from '@csp/dmdp-api-org-dto';

const fromOrgV1 = (orgV1: OrgV1): Org => ({
  orgId: orgV1.orgId,
  name: orgV1.name ?? '',
  isChildOf: (orgId: string): boolean => !!orgV1?.branchParentIds?.includes(orgId),
  orgIdsIncludingParents: [orgV1.orgId, ...(orgV1?.branchParentIds ?? [])],
  status: orgV1.status as OrgStatusType,
  type: orgV1.type as OrgType,
  customStatuses: CustomStatusMapper.fromCustomStatusesV1(orgV1.customStatuses ?? []),
  orgV1,
});

export const OrgMapper = {
  fromOrgV1,
};
