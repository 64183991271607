import { MenuItem, OnClick, OnClickButton, OnClickWith, Popover } from '@csp/csp-web-ui';
import { useBoolean } from '@csp/fe-hooks';
import { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { useToggle } from '@csp/csp-web-hooks';
import { CspPath } from '../../../CspPath';
import { StaticContentService } from '../../../common/service/StaticContentService';
import { canSkipConsentApprovalsSelector } from '../../consent/ducks/consentPermissionSelector';
import { GenericConsentTypeUrlParam } from '../../consent/model/GenericConsentType';
import { getUserIdSelector } from '../../security/ducks/principalContextSelectors';
import { SettingsDrawerContainer } from '../../settings/container/SettingsDrawerContainer';
import { getSelectedStudyIdSelector } from '../../studies/ducks/studiesSelectors';
import { LinkToProfileContainer } from '../container/LinkToProfileContainer';
import { SignOutContainer } from '../container/SignOutContainer';
import { useCanDebugCms } from '../../settings/hook/useCanDebugCms';
import { TimezoneContainer } from '../container/TimezoneContainer';
import { TimezoneDrawerContainer } from '../../timezone/container/TimezoneDrawerContainer';
import { ProfileIconButton } from './ProfileIconButton';
import { PopoverFooter } from './PopoverFooter';

export type AvatarProps = {
  hideMyProfile?: boolean;
  hidePopoverFooter?: boolean;
};

export const Avatar: FC<AvatarProps> = ({ hideMyProfile = false, hidePopoverFooter = false }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const selectedStudyId = useSelector(getSelectedStudyIdSelector);
  const userId = useSelector(getUserIdSelector);
  const canDebugCms = useCanDebugCms();
  const hasPermissionToSkipConsents = useSelector(canSkipConsentApprovalsSelector);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [isSettingsDrawerOpen, openSettingsDrawer, closeSettingsDrawer] = useBoolean();
  const { isOn: isTimezoneDrawerOpen, setOn: openTimezoneDrawer, setOff: closeTimezoneDrawer } = useToggle();
  const topBarBlock = StaticContentService.getPublicBlock('webHcpNavigationHeader', 'topBar');

  const popoverFooterItems = useMemo(() => {
    if (hasPermissionToSkipConsents) {
      return [];
    } else {
      const openInNewWindow: OnClickWith<GenericConsentTypeUrlParam> = consentType => {
        window.open(generatePath(CspPath.VIEW_CONSENT, { consentType }), '_blank');
      };

      const viewEula: OnClick = () => openInNewWindow(GenericConsentTypeUrlParam.EULA);
      const viewPrivacyPolicy: OnClick = () => openInNewWindow(GenericConsentTypeUrlParam.PRIVACY_POLICY);

      const { eula, privacyPolicy } = topBarBlock.button;

      return [
        { title: privacyPolicy.label, onClick: viewPrivacyPolicy },
        { title: eula.label, onClick: viewEula },
      ];
    }
  }, [topBarBlock, hasPermissionToSkipConsents]);

  const showMenu: OnClickButton = event => setAnchorEl(event.currentTarget);
  const hideMenu: OnClick = () => setAnchorEl(null);

  return (
    <>
      <ProfileIconButton popoverIsOpen={open} onClick={showMenu} data-testid="avatar-button" />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={hideMenu}
        onClick={hideMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {!hideMyProfile && selectedStudyId && userId && <LinkToProfileContainer userId={userId} />}

        {canDebugCms && (
          <MenuItem
            onClick={openSettingsDrawer}
            title={topBarBlock.button.settings.label}
            iconProps={{ name: 'settings' }}
          />
        )}

        <TimezoneContainer onOpen={openTimezoneDrawer} />

        <SignOutContainer />

        {!hidePopoverFooter && selectedStudyId && userId && <PopoverFooter items={popoverFooterItems} />}
      </Popover>

      <TimezoneDrawerContainer handleClose={closeTimezoneDrawer} open={isTimezoneDrawerOpen} />
      <SettingsDrawerContainer handleClose={closeSettingsDrawer} open={isSettingsDrawerOpen} />
    </>
  );
};
