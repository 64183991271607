import {
  DateAndTimeNumber,
  DateNumber,
  DateTimeDuration,
  DisplayDateTimeStr,
  IsoDateStr,
  IsoFormattedStr,
  IsoZonedDateTime,
  Maybe,
  StateAssert,
  TimeDifference,
  TimeStr,
  TimeZoneId,
  UnixTimeMillis,
  ZonedDateTime,
  ZonedDateTimeStr,
} from '@csp/csp-common-model';
import {
  add as addDateFns,
  differenceInCalendarDays as differenceInCalendarDaysDateFns,
  Duration,
  eachDayOfInterval as eachDayOfIntervalDateFns,
  eachWeekOfInterval as eachWeekOfIntervalDateFns,
  endOfDay as endOfDayDateFns,
  endOfTomorrow as endOfTomorrowDateFns,
  format as formatDateFns,
  getDay as getDayDateFns,
  getWeekOfMonth as getWeekOfMonthDateFns,
  isAfter as isAfterDateFns,
  isBefore as isBeforeDateFns,
  isFuture as isFutureDateFns,
  isPast as isPastDateFns,
  isSameDay as isSameDayFns,
  isToday as isTodayDateFns,
  isWithinInterval as isBetweenDateFns,
  parse as parseDateFns,
  parseISO as parseISODateFns,
  set,
  startOfDay as startOfDayDateFns,
  startOfHour as startOfHourDateFns,
  startOfISOWeek as startOfISOWeekDateFns,
  startOfMinute as startOfMinuteDateFns,
  startOfMonth as startOfMonthDateFns,
  startOfQuarter as startOfQuarterDateFns,
  startOfSecond as startOfSecondDateFns,
  startOfYear as startOfYearDateFns,
  sub as subDateFns,
} from 'date-fns';
import { format as formatDateFnsTz, utcToZonedTime as utcToZonedTimeDateFnsTz, zonedTimeToUtc } from 'date-fns-tz';
import jstz from 'jstimezonedetect';

// Week starts on monday
const dateFnsOptions: { weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6 } = {
  weekStartsOn: 1,
};

// RegExp for timezone in extended ISO 8601 datetime format with timezone
export const zoneIdRegex = /(.*)\[(.*)\]/;

// RegExp for UTC offset
export const utcOffsetRegExp = /(([+-])(\d\d):?(\d\d))$/g;

export const matchTimezoneStr = (str: string): RegExpMatchArray | null => str.match(zoneIdRegex);

export const matchUtcOffsetStr = (str: string): RegExpMatchArray | null => str.match(utcOffsetRegExp);

/**
 * Returns all the days between startDate and endDate (inclusive).
 *
 * @param startDate ZonedDateTime for start of interval
 * @param endDate ZonedDateTime for end of interval
 * @returns Array of dates for every day in the given interval
 */
export const eachDayOfInterval = (startDate: ZonedDateTime, endDate: ZonedDateTime): ZonedDateTime[] => {
  const start = utcToZonedTimeDateFnsTz(startDate.unixTimeMillis, startDate.zone);
  const end = utcToZonedTimeDateFnsTz(endDate.unixTimeMillis, endDate.zone);

  if (isAfterDateFns(end, start)) {
    return eachDayOfIntervalDateFns({
      start,
      end,
    }).map(date => ({
      unixTimeMillis: date.getTime(),
      zone: startDate.zone,
    }));
  } else {
    return [];
  }
};

/**
 * Returns all the weeks between startDate and endDate (inclusive).
 * If start date is not a monday, the first date will still be on the monday of that week.
 *
 * @param startDate ZonedDateTime for start of interval
 * @param endDate ZonedDateTime for end of interval
 * @returns Array of dates for every week start (monday) in the given interval
 */
export const eachWeekOfInterval = (startDate: ZonedDateTime, endDate: ZonedDateTime): ZonedDateTime[] =>
  eachWeekOfIntervalDateFns(
    {
      start: utcToZonedTimeDateFnsTz(startDate.unixTimeMillis, startDate.zone),
      end: utcToZonedTimeDateFnsTz(endDate.unixTimeMillis, endDate.zone),
    },
    dateFnsOptions,
  ).map(date => ({
    unixTimeMillis: date.getTime(),
    zone: startDate.zone,
  }));

/**
 * Check if passed argument is a valid JS isoDate string. Expects format: yyyy-MM-dd.
 *
 * @param yyyyMmDd - Any value to check if its a valid isoDate.
 */

export const isValidDateYyyyMmDd = (yyyyMmDd = ''): boolean =>
  yyyyMmDd.split('-').length === 3 && timezoneStrToUnixTime(yyyyMmDd) !== undefined;

/**
 * Check if passed argument is a valid JS Date object
 * @param d - Any value to check if its a valid date object.
 */
export const isValidDate = (d: Date): boolean => !isNaN(d.getTime());

/**
 * Check if passed argument is a valid JS Time object. Expects format: HH:mm
 * @param time - Any value to check if its a valid time .
 */
export const isValidTime = (time: TimeStr): boolean => !isNaN(parseDateFns(time, 'HH:mm', new Date()).getTime());

/**
 * Gets the current time zone identifier.
 *
 * Under the hood, the native `Intl.DateTimeFormat().resolvedOptions().timeZone`
 * is used for all modern browsers. For IE11, a guessing algorithm
 * is used to determine the ID.
 */
export const getTimeZoneId = (): TimeZoneId => jstz.determine().name();

/**
 * Returns the timezone offset from a datetime string.
 *
 * @param isoDateTimeStr An ISO 8601 formatted datetime string
 * @returns The timezone offset, defaults to +00:00 (UTC)
 */
export const getTimezoneOffset = (isoDateTimeStr: IsoFormattedStr): string => {
  const match = isoDateTimeStr.match(utcOffsetRegExp);
  if (match && match.length === 1 && match[0]) {
    let offset = match[0];
    const colonIndex = 3;
    if (offset[colonIndex] !== ':') {
      offset = `${offset.substring(0, colonIndex)}:${offset.substring(colonIndex)}`;
    }
    return offset;
  } else {
    return '+00:00';
  }
};

export const getWeekOfMonth = (zonedDateTime: ZonedDateTime): number => {
  const zonedDate = utcToZonedTimeDateFnsTz(zonedDateTime.unixTimeMillis, zonedDateTime.zone);
  return getWeekOfMonthDateFns(zonedDate, dateFnsOptions);
};

/**
 * Returns a valid JS Date object or throws an error.
 * @param isoString - ISO 8601 formatted string
 */
export const parseIso = (isoString: IsoFormattedStr): Date => {
  const date = parseISODateFns(isoString);
  StateAssert.isTrue(isValidDate(date), `Not valid ISO 8601 date: ${isoString}`);
  return date;
};

/**
 * Returns a valid JS Date object or undefined.
 * @param isoString - ISO 8601 formatted string
 */
export const parseIsoOrUndefined = (isoString: IsoFormattedStr): Maybe<Date> => {
  const date = parseISODateFns(isoString);
  return isValidDate(date) ? date : undefined;
};

/**
 * Returns a valid ZonedDateTimeStr or undefined.
 * @param isoDateStr - ISO 8601 formatted string
 * @returns {ZonedDateTimeStr}
 *
 * @deprecated Use <code>isoDateStr ? ZonedDateTimeFactory.fromZonedDateTimeString(isoDateStr) : undefined</code> instead
 */
export const fromIsoToZonedDateTimeCurrentZone = (isoDateStr: IsoDateStr): Maybe<ZonedDateTime> => {
  const asDateInUnix = parseIsoOrUndefined(isoDateStr)?.getTime();
  return asDateInUnix ? { unixTimeMillis: asDateInUnix, zone: getTimeZoneId() } : undefined;
};

/**
 * Returns a valid JS Date object or undefined.
 * @param {TimeStr} time - Time string in format HH:mm
 * @param {Date} referenceDate - A reference date, defines values missing from the parsed time.
 */
export const parseTimeOrUndefined = (time: TimeStr, referenceDate?: Date): Maybe<Date> => {
  const date = parseDateFns(time, 'HH:mm', referenceDate ?? new Date());
  return isValidDate(date) ? date : undefined;
};

/**
 * Converts from a zoned date-time string to a custom zoned date-time object.
 *
 * @param {ZonedDateTimeStr} zonedDateTimeStr - Extended ISO 8601 datetime format with timezone
 * @throws Will throw an error if not valid ISO.
 * @return {ZonedDateTime} - Zoned date-time object
 *
 * @deprecated Use <code>ZonedDateTimeFactory.fromZonedDateTimeString(zonedDateTimeStr)</code> instead
 */
export const fromTimezoneStr = (zonedDateTimeStr: ZonedDateTimeStr): ZonedDateTime => {
  const match = matchTimezoneStr(zonedDateTimeStr);
  if (match && match.length > 2 && match[1] != null && match[2] != null) {
    return {
      unixTimeMillis: parseIso(match[1]).getTime(),
      zone: match[2],
    };
  } else {
    const date = parseIso(zonedDateTimeStr);
    const offset = getTimezoneOffset(zonedDateTimeStr);
    const zone = offset.includes('00:00') ? 'UTC' : offset;
    return {
      unixTimeMillis: date.getTime(),
      zone,
    };
  }
};

/**
 * Returns a {ZonedDateTime} on local Zone Id and millis set to zero
 *
 * @return {ZonedDateTime} - with unixTimeMillis === 0
 *
 * @deprecated Use <code>ZonedDateTimeFactory.fromUnixTimeMillis(0)</code> instead
 */
export const zeroZonedDateTime: ZonedDateTime = {
  unixTimeMillis: 0,
  zone: getTimeZoneId(),
};

/**
 * Converts from a zoned date-time string to a custom zoned date-time object.
 * If undefined is supplied as argument, undefined will be return.
 *
 * @param {ZonedDateTimeStr} zonedDateTimeStr - Extended ISO 8601 datetime format with timezone
 * @throws Will throw an error if date-time part is not valid ISO.
 * @return {ZonedDateTime} - Zoned date-time object
 *
 * @deprecated Use <code>zonedDateTimeStr ? ZonedDateTimeFactory.fromZonedDateTimeString(zonedDateTimeStr) : undefined</code> instead
 */
export const fromTimezoneStrOrUndefined = (zonedDateTimeStr?: ZonedDateTimeStr): Maybe<ZonedDateTime> =>
  zonedDateTimeStr ? fromTimezoneStr(zonedDateTimeStr) : undefined;

/**
 * Checks if a ISO 8601 string is formatted correctly
 *
 * @param {IsoFormattedStr} IsoFormattedStr - ISO 8601 datetime format
 * @returns {boolean}
 */

export const isValidIsoFormattedStr = (IsoFormattedStr: IsoFormattedStr): boolean => {
  const regex = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}).\d{3}(\+|-)\d{2}:\d{2}$/g;
  const match = IsoFormattedStr.match(regex);

  return !!match && match.length === 1;
};

/**
 * Converts custom zoned date time object to an ISO datetime string.
 *
 * @param {ZonedDateTime} zonedDateTime - Zoned date-time object
 * @returns {IsoFormattedStr} - ISO 8601 datetime format
 *
 * @deprecated Use <code>ZonedDateTimeFormatter.toIsoFormattedString(zonedDateTime)</code> instead
 */
export const toZonedDateStr = (zonedDateTime: ZonedDateTime): IsoFormattedStr => {
  const zonedDate = utcToZonedTimeDateFnsTz(zonedDateTime.unixTimeMillis, zonedDateTime.zone);
  return formatDateFnsTz(zonedDate, `yyyy-MM-dd'T'HH:mm:ss.SSSxxx`, {
    timeZone: zonedDateTime.zone,
  });
};

/**
 * Given a @see {ZonedDateTime} it returns a copy but set to the end of the target timezone day.
 * i.e. 2020-01-01T13:00:00.000-05:00 -> 2020-01-01T23:59:59.999-05:00
 *
 * @param zonedDateTime the zoned date time you want the end of day of.
 */
export const zonedEndOfDay = (zonedDateTime: ZonedDateTime): ZonedDateTime => {
  const zonedDate = utcToZonedTimeDateFnsTz(zonedDateTime.unixTimeMillis, zonedDateTime.zone);
  const endOfDayDate = endOfDayDateFns(zonedDate);
  const utcDate = zonedTimeToUtc(endOfDayDate, zonedDateTime.zone);
  return {
    unixTimeMillis: utcDate.getTime(),
    zone: zonedDateTime.zone,
  };
};

/**
 * Given a @see {ZonedDateTime} it returns a copy but set to the start of the target timezone second.
 * i.e. 2020-01-01T13:00:00.000-05:00 -> 2020-01-01T00:00:00.000-05:00
 *
 * @param zonedDateTime the zoned date time you want the start of second of.
 */
export const zonedStartOfSecond = (zonedDateTime: ZonedDateTime): ZonedDateTime => {
  const zonedDate = utcToZonedTimeDateFnsTz(zonedDateTime.unixTimeMillis, zonedDateTime.zone);
  const startOfSecondDate = startOfSecondDateFns(zonedDate);
  const utcDate = zonedTimeToUtc(startOfSecondDate, zonedDateTime.zone);
  return {
    unixTimeMillis: utcDate.getTime(),
    zone: zonedDateTime.zone,
  };
};

/**
 * Given a @see {ZonedDateTime} it returns a copy but set to the start of the target timezone minute.
 * i.e. 2020-01-01T13:00:00.000-05:00 -> 2020-01-01T00:00:00.000-05:00
 *
 * @param zonedDateTime the zoned date time you want the start of minute of.
 */
export const zonedStartOfMinute = (zonedDateTime: ZonedDateTime): ZonedDateTime => {
  const zonedDate = utcToZonedTimeDateFnsTz(zonedDateTime.unixTimeMillis, zonedDateTime.zone);
  const startOfMinuteDate = startOfMinuteDateFns(zonedDate);
  const utcDate = zonedTimeToUtc(startOfMinuteDate, zonedDateTime.zone);
  return {
    unixTimeMillis: utcDate.getTime(),
    zone: zonedDateTime.zone,
  };
};

/**
 * Given a @see {ZonedDateTime} it returns a copy but set to the start of the target timezone hour.
 * i.e. 2020-01-01T13:00:00.000-05:00 -> 2020-01-01T00:00:00.000-05:00
 *
 * @param zonedDateTime the zoned date time you want the start of hour of.
 */
export const zonedStartOfHour = (zonedDateTime: ZonedDateTime): ZonedDateTime => {
  const zonedDate = utcToZonedTimeDateFnsTz(zonedDateTime.unixTimeMillis, zonedDateTime.zone);
  const startOfHourDate = startOfHourDateFns(zonedDate);
  const utcDate = zonedTimeToUtc(startOfHourDate, zonedDateTime.zone);
  return {
    unixTimeMillis: utcDate.getTime(),
    zone: zonedDateTime.zone,
  };
};

/**
 * Given a @see {ZonedDateTime} it returns a copy but set to the start of the target timezone day.
 * i.e. 2020-01-01T13:00:00.000-05:00 -> 2020-01-01T00:00:00.000-05:00
 *
 * @param zonedDateTime the zoned date time you want the start of day of.
 */
export const zonedStartOfDay = (zonedDateTime: ZonedDateTime): ZonedDateTime => {
  const zonedDate = utcToZonedTimeDateFnsTz(zonedDateTime.unixTimeMillis, zonedDateTime.zone);
  const startOfDayDate = startOfDayDateFns(zonedDate);
  const utcDate = zonedTimeToUtc(startOfDayDate, zonedDateTime.zone);
  return {
    unixTimeMillis: utcDate.getTime(),
    zone: zonedDateTime.zone,
  };
};

/**
 * Given a @see {ZonedDateTime} it returns a copy but set to the start of the target timezone week.
 * i.e. 2020-01-01T13:00:00.000-05:00 -> 2020-01-01T00:00:00.000-05:00
 *
 * @param zonedDateTime the zoned date time you want the start of week of.
 *
 * @note Weeks starts on monday
 */
export const zonedStartOfWeek = (zonedDateTime: ZonedDateTime): ZonedDateTime => {
  const zonedDate = utcToZonedTimeDateFnsTz(zonedDateTime.unixTimeMillis, zonedDateTime.zone);
  const startOfWeekDate = startOfISOWeekDateFns(zonedDate); // Monday start of week
  const utcDate = zonedTimeToUtc(startOfWeekDate, zonedDateTime.zone);
  return {
    unixTimeMillis: utcDate.getTime(),
    zone: zonedDateTime.zone,
  };
};

/**
 * Given a @see {ZonedDateTime} it returns a copy but set to the start of the target timezone month.
 * i.e. 2020-01-01T13:00:00.000-05:00 -> 2020-01-01T00:00:00.000-05:00
 *
 * @param zonedDateTime the zoned date time you want the start of month of.
 */
export const zonedStartOfMonth = (zonedDateTime: ZonedDateTime): ZonedDateTime => {
  const zonedDate = utcToZonedTimeDateFnsTz(zonedDateTime.unixTimeMillis, zonedDateTime.zone);
  const startOfMonthDate = startOfMonthDateFns(zonedDate);
  const utcDate = zonedTimeToUtc(startOfMonthDate, zonedDateTime.zone);
  return {
    unixTimeMillis: utcDate.getTime(),
    zone: zonedDateTime.zone,
  };
};

/**
 * Given a @see {ZonedDateTime} it returns a copy but set to the start of the target timezone quarter.
 * i.e. 2020-01-01T13:00:00.000-05:00 -> 2020-01-01T00:00:00.000-05:00
 *
 * @param zonedDateTime the zoned date time you want the start of quarter of.
 */
export const zonedStartOfQuarter = (zonedDateTime: ZonedDateTime): ZonedDateTime => {
  const zonedDate = utcToZonedTimeDateFnsTz(zonedDateTime.unixTimeMillis, zonedDateTime.zone);
  const startOfQuarterDate = startOfQuarterDateFns(zonedDate);
  const utcDate = zonedTimeToUtc(startOfQuarterDate, zonedDateTime.zone);
  return {
    unixTimeMillis: utcDate.getTime(),
    zone: zonedDateTime.zone,
  };
};

/**
 * Given a @see {ZonedDateTime} it returns a copy but set to the start of the target timezone year.
 * i.e. 2020-01-01T13:00:00.000-05:00 -> 2020-01-01T00:00:00.000-05:00
 *
 * @param zonedDateTime the zoned date time you want the start of year of.
 */
export const zonedStartOfYear = (zonedDateTime: ZonedDateTime): ZonedDateTime => {
  const zonedDate = utcToZonedTimeDateFnsTz(zonedDateTime.unixTimeMillis, zonedDateTime.zone);
  const startOfYearDate = startOfYearDateFns(zonedDate);
  const utcDate = zonedTimeToUtc(startOfYearDate, zonedDateTime.zone);
  return {
    unixTimeMillis: utcDate.getTime(),
    zone: zonedDateTime.zone,
  };
};

/**
 * Returns local start of today. Local today's date plus time 00:00.
 * Example 2020-01-01T00:00:00.000-05:00
 */
export const nowStartOfToday = (): ZonedDateTime => zonedStartOfDay(nowToZonedDateTimeCurrentZone());

/**
 * Adds a duration to a zoned date time in the target timezone.
 * @param zonedDateTime The zoned date time to add a duration to
 * @param duration Object to specify the duration
 */
export const zonedAddDuration = (zonedDateTime: ZonedDateTime, duration: DateTimeDuration): ZonedDateTime => {
  const dateDuration = DateTimeDuration.toDateDuration(duration);
  const timeDuration = DateTimeDuration.toTimeDuration(duration);

  // Have to add date and time durations separately to handle
  // DST transitions correctly.
  const futureTime = addDateFns(zonedDateTime.unixTimeMillis, timeDuration);
  const zonedTime = utcToZonedTimeDateFnsTz(futureTime, zonedDateTime.zone);
  const futureDateTime = addDateFns(zonedTime, dateDuration);
  const utcDateTime = zonedTimeToUtc(futureDateTime, zonedDateTime.zone);

  return {
    unixTimeMillis: utcDateTime.getTime(),
    zone: zonedDateTime.zone,
  };
};

/**
 * Subtracts a duration to a zoned date time in the target timezone.
 * @param zonedDateTime The zoned date time to subtract a duration to
 * @param duration Object to specify the duration
 */
export const zonedSubtractDuration = (zonedDateTime: ZonedDateTime, duration: Duration): ZonedDateTime => {
  const dateDuration = DateTimeDuration.toDateDuration(duration);
  const timeDuration = DateTimeDuration.toTimeDuration(duration);

  // Have to subtract date and time durations separately to handle
  // DST transitions correctly.
  const futureTime = subDateFns(zonedDateTime.unixTimeMillis, timeDuration);
  const zonedTime = utcToZonedTimeDateFnsTz(futureTime, zonedDateTime.zone);
  const futureDateTime = subDateFns(zonedTime, dateDuration);
  const utcDateTime = zonedTimeToUtc(futureDateTime, zonedDateTime.zone);

  return {
    unixTimeMillis: utcDateTime.getTime(),
    zone: zonedDateTime.zone,
  };
};

/**
 * Get the day of week as an index 0-6. 0 is Monday
 */
export const zonedGetDay = (zonedDateTime: ZonedDateTime): number => {
  const zonedDate = utcToZonedTimeDateFnsTz(zonedDateTime.unixTimeMillis, zonedDateTime.zone);
  const dayOfWeek = getDayDateFns(zonedDate);

  if (Number.isNaN(dayOfWeek)) {
    throw new Error(`Not a valid date. unixMillis: '${zonedDateTime.unixTimeMillis}', zone: '${zonedDateTime.zone}'`);
  }
  // Date fns uses sunday as start of week, shift so that monday is start of week
  return dayOfWeek === 0 ? 6 : dayOfWeek - 1;
};

/**
 * Converts custom zoned date time object to a string.
 *
 * @param {ZonedDateTime} zonedDateTime - Zoned date-time object
 * @returns {ZonedDateTimeStr} - Extended ISO 8601 datetime format with timezone
 *
 * @deprecated Use <code>ZonedDateTimeFormatter.toZonedDateTimeString(zonedDateTime)</code> instead
 */
export const toTimezoneStr = (zonedDateTime: ZonedDateTime): ZonedDateTimeStr => {
  const zonedDateStr = toZonedDateStr(zonedDateTime);
  return `${zonedDateStr}[${zonedDateTime.zone}]`;
};

/**
 * Converts from unix time to a ZonedDateTime
 *
 * @return {ZonedDateTime}
 *
 * @deprecated Use <code>ZonedDateTimeFactory.fromUnixTimeMillis(unixTimeMillis)</code> instead
 */
export const toZonedDateTimeCurrentZone = (unixTimeMillis: UnixTimeMillis): ZonedDateTime => ({
  unixTimeMillis,
  zone: getTimeZoneId(),
});

/**
 * Converts from unix time to a string
 *
 * @return {ZonedDateTimeStr} - Extended ISO 8601 datetime format with timezone
 *
 * @deprecated Use <code>ZonedDateTimeFormatter.toZonedDateTimeString(ZonedDateTimeFactory.fromUnixTimeMillis(unixTimeMillis))</code> instead
 */
export const toTimezoneStrCurrentZone = (unixTimeMillis: UnixTimeMillis): ZonedDateTimeStr =>
  toTimezoneStr(toZonedDateTimeCurrentZone(unixTimeMillis));

/**
 * Get the current date-time as a string
 *
 * @return {ZonedDateTime} - Zoned date-time object
 *
 * @deprecated Use <code>ZonedDateTimeFactory.now()</code> instead
 */
export const nowToZonedDateTimeCurrentZone = (): ZonedDateTime => ({
  unixTimeMillis: Date.now(),
  zone: getTimeZoneId(),
});

/**
 * Get the current date-time, with a custom zone, as a string
 *
 * @param {string} zone - The custom zone for the ZonedDateTime
 * @return {ZonedDateTime} - Zoned date-time object
 *
 * @deprecated Use <code>ZonedDateTimeFactory.now(zone)</code> instead
 */
export const nowToZonedDateTimeCustomZone = (zone: string): ZonedDateTime => ({
  unixTimeMillis: Date.now(),
  zone,
});

/**
 * Get the current date-time as a string
 *
 * @return {ZonedDateTimeStr} - Extended ISO 8601 datetime format with timezone
 *
 * @deprecated Use <code>ZonedDateTimeFormatter.toZonedDateTimeString(ZonedDateTimeFactory.now())</code> instead
 */
export const nowToTimezoneStrCurrentZone = (): ZonedDateTimeStr => toTimezoneStr(nowToZonedDateTimeCurrentZone());

/**
 * Converts an ISO 8601 date string to a number (sortable)
 *
 * @param {IsoFormattedStr} isoDateStr - ISO 8601 date string
 * @throws Will throw an error if argument is not valid ISO.
 * @return {DateNumber} - YYYYMMDD as number
 *
 * @deprecated Use <code>ZonedDateTimeFormatter.toDateNumber(...)</code> instead
 */
export const toDateNumber = (isoDateStr: IsoFormattedStr): DateNumber => {
  const date = parseIso(isoDateStr);
  const yyyyyMmDd = formatDateFns(date, 'yyyyMMdd');
  return parseInt(yyyyyMmDd);
};

/**
 * Converts an ISO 8601 date string to a number including hours and minutes (sortable)
 *
 * @param {IsoFormattedStr} isoDateStr - ISO 8601 date string
 * @throws Will throw an error if argument is not valid ISO.
 * @return {DateAndTimeNumber} - YYYYMMDDHHMM as number
 *
 * @deprecated Use <code>ZonedDateTimeFormatter.toDateAndTimeNumber(...)</code> instead
 */
export const toDateAndTimeNumber = (isoDateStr: ZonedDateTime): DateAndTimeNumber => {
  const formattedDate = formatDateFns(isoDateStr.unixTimeMillis, 'yyyyMMddHHmm');
  return parseInt(formattedDate);
};

/**
 * @param zonedDateTime The zoned date time to format in Iso format
 * @return {IsoDateStr} - yyyy-MM-dd as string
 *
 * @deprecated Use <code>ZonedDateTimeFormatter.toIsoDateString(zonedDateTime)</code> instead
 */
export const toIsoDate = (zonedDateTime: ZonedDateTime): IsoDateStr =>
  formatDateFns(zonedDateTime.unixTimeMillis, 'yyyy-MM-dd');

/**
 * now() to a date in Iso format
 * @return {IsoDateStr} - yyyy-MM-dd as string
 */
export const nowToIsoDate = (): IsoDateStr => formatDateFns(new Date(), 'yyyy-MM-dd');

/**
 * now() to a date number
 * @return {DateNumber} - YYYYMMDD as number
 */
export const nowToDateNumber = (): DateNumber => toDateNumber(nowToIsoDate());

/**
 * now() to a date with time number
 * @return {DateAndTimeNumber} - YYYYMMDDHHMM as number
 */
export const nowToDateAndTimeNumber = (): DateAndTimeNumber => toDateAndTimeNumber(nowToZonedDateTimeCurrentZone());

/**
 * Converts a ISO 8601 valid date string with/without zone ID to unix time
 *
 * @return {UnixTimeMillis} - unix time as number
 *
 * @deprecated Use <code>ZonedDateTimeFactory.fromZonedDateTimeString(timezoneStr).unixTimeMillis</code> instead
 */
export const timezoneStrToUnixTime = (timezoneStr: ZonedDateTimeStr | IsoFormattedStr): Maybe<UnixTimeMillis> => {
  const idx = timezoneStr.indexOf('[');
  const timezoneStrNoZoneId = idx !== -1 ? timezoneStr.substring(0, idx) : timezoneStr;
  const date = parseISODateFns(timezoneStrNoZoneId);
  if (isValidDate(date)) {
    return date.getTime();
  } else {
    return undefined;
  }
};

/**
 * @return "HH:mm" formatted time or undefined.
 *
 * @deprecated Use <code>ZonedDateTimeFormatter.toTimeString(ZonedDateTimeFactory.fromUnixTimeMillis(unixTimesMillis))</code> instead
 */
export const toFormatTime = (unixTimesMillis?: UnixTimeMillis): Maybe<TimeStr> =>
  unixTimesMillis ? formatDateFns(unixTimesMillis, 'HH:mm') : undefined;

/**
 * @deprecated Use <code>ZonedDateTimeFormatter.format(..., format, ...)</code> instead
 */
const formatDate = (
  unixTimeMillis: Maybe<UnixTimeMillis | unknown>,
  zone: Maybe<string>,
  format = 'yyyy-MM-dd HH:mm',
): Maybe<DisplayDateTimeStr | TimeStr> => {
  if (!!unixTimeMillis && typeof unixTimeMillis === 'number') {
    return zone
      ? formatZonedDateTimeToDateTimeStr({ unixTimeMillis, zone }, format)
      : formatDateFns(unixTimeMillis, format);
  } else {
    return undefined;
  }
};

/**
 * @return "yyyy-MM-dd HH:mm:ss" formatted date time or undefined.
 *
 * @deprecated Use <code>ZonedDateTimeFormatter.format(..., ZonedDateTimeFormat.ISO_8601.DATE_TIME_FORMAT)</code> instead or define this format properly on the ZonedDateTimeFormatter
 */
export const toFormatIsoDateTimeIncludingSeconds = (
  unixTimeMillis: Maybe<UnixTimeMillis | unknown>,
  zone?: TimeZoneId,
): Maybe<DisplayDateTimeStr> => formatDate(unixTimeMillis, zone, 'yyyy-MM-dd HH:mm:ss');

/**
 * @return "HH:mm:ss" formatted time or undefined.
 *
 * @deprecated Use <code>ZonedDateTimeFormatter.format(..., ZonedDateTimeFormat.ISO_8601.TIME_FORMAT)</code> instead or define this format properly on the ZonedDateTimeFormatter
 */
export const toFormatTimeIncludingSeconds = (
  unixTimeMillis?: UnixTimeMillis | unknown,
  zone?: TimeZoneId,
): Maybe<TimeStr> => formatDate(unixTimeMillis, zone, 'HH:mm:ss');

/**
 * now() to a time in format HH:mm
 * @return HH:mm as string
 */
export const nowToTimeStr = (): TimeStr => {
  const time = toFormatTime(Date.now());
  StateAssert.notNull(time, 'Bad time format'); // Should never happen only to fulfill the API
  return time;
};

/**
 * Converts from unix time number to ISO 8601 date string
 *
 * @throws Will throw an error if unix time is not valid.
 * @return Returns a Iso formatted date: YYYY-MM-DD
 *
 * @deprecated Use <code>ZonedDateTimeFormatter.toIsoDateString(...)</code> instead
 * */
export const toFormatIsoDate = (unixTimeMillis: UnixTimeMillis): IsoDateStr => {
  const date = new Date(unixTimeMillis);
  StateAssert.isTrue(isValidDate(date), `Not a valid date: ${unixTimeMillis}`);
  return formatDateFns(date, 'yyyy-MM-dd');
};

/**
 * Formats a timestamp as an "exclusive" iso date string in local time.
 * For example used when displaying an interval "from" (inclusive) - "to" (exclusive).
 * e.g start: 2020-02-17T00:00:00.000, end: 2020-02-20T00:00:00.000
 * should be displayed as:
 * 2020-02-17 - 2020-02-19
 *
 * @throws Will throw an error if unix time is not valid.
 * @return Returns a Iso formatted date: YYYY-MM-DD
 * */
export const toFormatIsoDateExclusive = (unixTimeMillis: UnixTimeMillis): IsoDateStr =>
  // Remove one millisecond from the timestamp.
  toFormatIsoDate(unixTimeMillis - 1);

/**
 * Convert and format unix time to a display string
 *
 * @param {UnixTimeMillis} unixTimesMillis - unix time as number
 * @return {DisplayDateTimeStr} - "YYYY-MM-DD HH:mm" formatted date or undefined.
 *
 * @deprecated Use <code>ZonedDateTimeFormatter.toDisplayDateTimeString(...)</code> instead
 */
export const toFormatDateTime = (unixTimesMillis?: UnixTimeMillis): Maybe<DisplayDateTimeStr> =>
  unixTimesMillis ? formatDateFns(unixTimesMillis, 'yyyy-MM-dd HH:mm') : undefined;

/**
 * Convert and format unix time to a display string including seconds
 *
 * @param {UnixTimeMillis} unixTimesMillis - unix time as number
 * @return {DisplayDateTimeStr} - "YYYY-MM-DD HH:mm:ss" formatted date or undefined.
 *
 * @deprecated Use <code>ZonedDateTimeFormatter.toDisplayDateTimeString(...)</code> instead
 */
export const toFormatDateTimeInclSeconds = (unixTimesMillis?: UnixTimeMillis | unknown): Maybe<DisplayDateTimeStr> =>
  !!unixTimesMillis && typeof unixTimesMillis === 'number'
    ? formatDateFns(unixTimesMillis, 'yyyy-MM-dd HH:mm:ss')
    : undefined;

/**
 * Converts and format unix time to a date, time and timezone display string
 *  *
 * @param {ZonedDateTime} zonedDateTime - unix time as number
 * @return {string} - "YYYY-MM-DD HH:mm TZ" formatted date or empty string.
 *
 * @deprecated Use <code>ZonedDateTimeFormatter.format(zonedDateTime, `${ZonedDateTimeFormat.UNIFY.DATE_TIME_FORMAT} xxx`, zonedDateTime.zone)</code> instead or define this properly on the ZonedDateTimeFormatter
 */
export const toFormatDateTimeWithTimeZoneStr = (zonedDateTime: ZonedDateTime): string => {
  try {
    const zonedDateTimeStr = formatZonedDateTimeToDateTimeStr(zonedDateTime) ?? '';
    const timeZoneOffset = formatDateFnsTz(zonedDateTime.unixTimeMillis, 'xxx', { timeZone: zonedDateTime.zone });
    return zonedDateTimeStr ? `${zonedDateTimeStr} ${timeZoneOffset}` : '';
  } catch (e) {
    return '';
  }
};

/**
 * Returns whether a ISO formatted date is today or not
 *
 * @param {ZonedDateTimeStr | IsoFormattedStr} date - ISO 8601 valid date-time string
 */
export const isIsoDateToday = (date: ZonedDateTimeStr | IsoFormattedStr): boolean => {
  const zonedDateTime = fromTimezoneStr(date);
  return isDateToday(zonedDateTime);
};

export const isDateToday = (zonedDateTime: Maybe<ZonedDateTime>): boolean =>
  !!zonedDateTime && isTodayDateFns(zonedDateTime.unixTimeMillis);

/**
 * Returns whether an ISO formatted date is in future or not
 *
 * @param {ZonedDateTimeStr | IsoFormattedStr} date - ISO 8601 valid date-time string
 * @returns {boolean} - true if in future
 */
export const isFutureDateTime = (date: ZonedDateTimeStr | IsoFormattedStr): boolean => {
  const unix = timezoneStrToUnixTime(date);
  return !!unix && isFutureDateFns(unix);
};

/**
 * Returns whether an ISO formatted date is in the past or not.
 *
 * @param {ZonedDateTimeStr | IsoFormattedStr} date - ISO 8601 valid date-time string
 * @returns {boolean} - true if in the past
 */
export const isPastDateTime = (date: ZonedDateTimeStr | IsoFormattedStr): boolean => {
  const unix = timezoneStrToUnixTime(date);
  return !!unix && isPastDateFns(unix);
};

/**
 * Returns whether an unix time is in the past or not.
 *
 * @param {UnixTimeMillis} unixTime - ISO 8601 valid date-time string
 * @returns {boolean} - true if in the past
 */
export const isPastUnixTime = (unixTime?: UnixTimeMillis): boolean => !!unixTime && isPastDateFns(unixTime);

/**
 * Compare if one iso formatted date string is after a comparison date (exclusive).
 *
 * @param date - the date that should be after the other one to return true
 * @param minDate - the date to compare with
 */
export const isAfter = (date: ZonedDateTimeStr | IsoFormattedStr | ZonedDateTime, minDate: Date): boolean => {
  const dateUnix = typeof date === 'string' ? timezoneStrToUnixTime(date) : date.unixTimeMillis;
  return !!dateUnix && isAfterDateFns(dateUnix, minDate);
};

/**
 * Compare if one iso formatted date string is before a comparison date (exclusive).
 *
 * @param date - the date that should be before the other one to return true
 * @param maxDate - the date to compare with
 */
export const isBefore = (date: ZonedDateTimeStr | IsoFormattedStr | ZonedDateTime, maxDate: Date): boolean => {
  const dateUnix = typeof date === 'string' ? timezoneStrToUnixTime(date) : date.unixTimeMillis;
  return !!dateUnix && isBeforeDateFns(dateUnix, maxDate);
};

/**
 * Check if a date is between two dates (inclusive).
 * @param date - the date to check
 * @param startDate - the start date (included)
 * @param endDate - the min date (included)
 */
export const isBetween = (
  date: ZonedDateTimeStr | IsoFormattedStr | ZonedDateTime,
  startDate: Date,
  endDate: Date,
): boolean => {
  const dateUnix = typeof date === 'string' ? timezoneStrToUnixTime(date) : date.unixTimeMillis;
  return !!dateUnix && isBetweenDateFns(dateUnix, { start: startDate, end: endDate });
};

/**
 * Compare if the two dates is the same YYYY-MM-DD.
 */
export const isSameDay = (
  dateOne: ZonedDateTimeStr | IsoFormattedStr,
  dateTwo: ZonedDateTimeStr | IsoFormattedStr,
): boolean => {
  const dateOneUnix = timezoneStrToUnixTime(dateOne);
  const dateTwoUnix = timezoneStrToUnixTime(dateTwo);
  return !!dateOneUnix && !!dateTwoUnix && isSameDayFns(dateOneUnix, dateTwoUnix);
};

/**
 * Check if a day is the start of a week.
 * Note: Week starts on Monday.
 */
export const isStartOfWeek = (date: ZonedDateTime): boolean =>
  isSameDay(toTimezoneStr(date), toTimezoneStr(zonedStartOfWeek(date)));

/**
 * Check if a day is the start of a month.
 */
export const isStartOfMonth = (date: ZonedDateTime): boolean =>
  isSameDay(toTimezoneStr(date), toTimezoneStr(zonedStartOfMonth(date)));

/**
 * Check if a day is the start of a quarter.
 */
export const isStartOfQuarter = (date: ZonedDateTime): boolean =>
  isSameDay(toTimezoneStr(date), toTimezoneStr(zonedStartOfQuarter(date)));

/**
 * Compare if one time string is after a comparison time (exclusive).
 *
 * @param time - the time that should be after the other one to return true
 * @param minTime - the time to compare with
 */
export const isTimeAfter = (time: TimeStr, minTime: Date): boolean => {
  const dateTime = parseTimeOrUndefined(time, minTime);
  return !!dateTime && isAfterDateFns(dateTime, minTime);
};

/**
 * Compare if one time string is before a comparison time (exclusive).
 *
 * @param time - the time that should be before the other one to return true
 * @param maxTime - the time to compare with
 */
export const isTimeBefore = (time: TimeStr | IsoFormattedStr, maxTime: Date): boolean => {
  const dateTime = parseTimeOrUndefined(time, maxTime);
  return !!dateTime && isBeforeDateFns(dateTime, maxTime);
};

/**
 * Calculates time difference from unix time time to now.
 *
 * @param {UnixTimeMillis} time - unix time as number
 * @return {TimeDifference} - time difference as an object
 */
export const timeDiff = (time: UnixTimeMillis): TimeDifference => {
  const before = new Date(time);
  const now = Date.now();

  const seconds = Math.abs((now - before.getTime()) / 1000);
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const days = hours / 24;
  const asFlooredHours = Math.floor(hours);
  const asFlooredDays = Math.floor(days);
  const asCeiledMinutes = Math.ceil(minutes);
  const asCeiledHours = Math.ceil(hours);
  const asCeiledDays = Math.ceil(days);

  return {
    asRoundedSeconds: Math.round(seconds),
    asFlooredMinutes: Math.floor(minutes),
    asFlooredHours,
    asFlooredDays,
    asCeiledHours,
    asCeiledDays,
    asCeiledMinutes,
    pastEvent: now - before.getTime() > 0,
    moreThanDays: (xDays): boolean => asFlooredDays >= xDays,
    moreThanHours: (xHours): boolean => asFlooredHours >= xHours,
  };
};

/**
 * Returns tomorrows date or the day after input param date if specified.
 */
export const tomorrow = endOfTomorrowDateFns;

/**
 * Returns tomorrows date formatted as yyyy-MM-dd.
 */
export const tomorrowAsFormattedIsoDate = (): IsoDateStr => toFormatIsoDate(tomorrow().getTime());

/**
 * General formatting function from unicode date pattern.
 *
 * @deprecated Use <code>ZonedDateTimeFormatter.format(..., pattern)</code> instead
 */
export const formatCustom =
  (date: Date) =>
  (pattern = 'yyyy-MM-dd'): string =>
    formatDateFns(date, pattern);

/**
 * Formats to date in local timezone
 *
 * @param timezoneStr - A ISO 8601 date string with or without zone ID
 * @return {IsoDateStr} - A Iso formatted date: YYYY-MM-DD or undefined
 *
 * @deprecated Use <code>ZonedDateTimeFormatter.toIsoDateString(...)</code> instead
 */
export const formatIsoDate = (timezoneStr: ZonedDateTimeStr | IsoFormattedStr): Maybe<IsoDateStr> => {
  const unixTimeMillis = timezoneStrToUnixTime(timezoneStr);
  return unixTimeMillis !== undefined ? formatDateFns(unixTimeMillis, 'yyyy-MM-dd') : undefined;
};

/**
 * Formats to time in local timezone
 *
 * @param timezoneStr - A ISO 8601 date string with or without zone ID
 * @return {IsoDateStr} - A Iso formatted time: HH:mm or undefined
 *
 * @deprecated Use <code>ZonedDateTimeFormatter.toTimeString(...)</code> instead
 */
export const formatLocalTime = (timezoneStr: ZonedDateTimeStr | IsoFormattedStr): Maybe<TimeStr> => {
  const unixTimeMillis = timezoneStrToUnixTime(timezoneStr);
  return toFormatTime(unixTimeMillis);
};

/**
 * Formats to date time in local timezone.
 *
 * @param timezoneStr - A ISO 8601 date string with or without zone ID

 * @return {DisplayDateTimeStr} - Local date time "YYYY-MM-DD HH:mm" or undefined.
 *
 * @deprecated Use <code>ZonedDateTimeFormatter.toDisplayDateTimeString(...)</code> instead
 */
export const formatLocalDateTime = (timezoneStr: ZonedDateTimeStr | IsoFormattedStr): Maybe<DisplayDateTimeStr> => {
  const date = formatIsoDate(timezoneStr);
  const localTime = formatLocalTime(timezoneStr);
  return date && localTime ? `${date} ${localTime}` : undefined;
};

/**
 * Formats a zoned date time object to a display friendly format.
 * The specified time zone is used
 *
 * @return {DisplayDateTimeStr} - Local date time "YYYY-MM-DD HH:mm" (or other specified format) or undefined.
 *
 * @deprecated Use <code>ZonedDateTimeFormatter.toDisplayDateTimeString(zonedDateTime)</code> instead
 */
export const formatZonedDateTimeToDateTimeStr = (
  zonedDateTime: ZonedDateTime,
  format = 'yyyy-MM-dd HH:mm',
): Maybe<DisplayDateTimeStr> => {
  try {
    const zonedDate = utcToZonedTimeDateFnsTz(zonedDateTime.unixTimeMillis, zonedDateTime.zone);
    return formatDateFns(zonedDate, format);
  } catch (e) {
    return undefined;
  }
};

/**
 * Formats a zoned date time object to a display friendly format.
 * The specified time zone is used
 *
 * @return {IsoDateStr} - Local date time "YYYY-MM-DD" or undefined.
 *
 * @deprecated Use <code>ZonedDateTimeFormatter.toIsoDateString(zonedDateTime)</code> or <code>ZonedDateTimeFormatter.format(zonedDateTime, ZonedDateTimeFormat.UNIFY.DATE_FORMAT, zonedDateTime.zone)</code> instead
 */
export const formatZonedDateTimeToDateStr = (zonedDateTime: ZonedDateTime): Maybe<IsoDateStr> => {
  try {
    const zonedDate = utcToZonedTimeDateFnsTz(zonedDateTime.unixTimeMillis, zonedDateTime.zone);
    return formatDateFns(zonedDate, 'yyyy-MM-dd');
  } catch (e) {
    return undefined;
  }
};

/**
 * Formats a zoned date time object to a display friendly format.
 * The specified time zone is used
 *
 * @return {TimeStr} - Local time "HH:mm" or undefined.
 *
 * @deprecated Use <code>ZonedDateTimeFormatter.toTimeString(zonedDateTime)</code> or <code>ZonedDateTimeFormatter.format(zonedDateTime, ZonedDateTimeFormat.UNIFY.TIME_FORMAT, zonedDateTime.zone)</code> instead
 */
export const formatZonedDateTimeToTimeStr = (zonedDateTime: ZonedDateTime): Maybe<TimeStr> => {
  try {
    const zonedDate = utcToZonedTimeDateFnsTz(zonedDateTime.unixTimeMillis, zonedDateTime.zone);
    return formatDateFns(zonedDate, 'HH:mm');
  } catch (e) {
    return undefined;
  }
};

/**
 * Formats a zoned iso date-time string to a display friendly format.
 * The specified time zone is used
 *
 * @return {DisplayDateTimeStr} - Local date time "YYYY-MM-DD HH:mm" (or other specified format) or undefined.
 *
 * @deprecated Use <code>ZonedDateTimeFormatter.toDisplayDateTimeString(...)</code> instead
 */
export const formatZonedDateTime = (zonedDateTimeStr: ZonedDateTimeStr, format?: string): Maybe<DisplayDateTimeStr> => {
  try {
    return formatZonedDateTimeToDateTimeStr(fromTimezoneStr(zonedDateTimeStr), format);
  } catch (e) {
    return undefined;
  }
};

/**
 * Formats a zoned iso date-time string to a display friendly format.
 * The specified time zone is used
 *
 * @return {ZonedDateTimeStr} - Local time "YYYY-MM-DD HH:mm" or undefined.
 */
export const formatZonedTime = (timezoneStr: ZonedDateTimeStr): Maybe<TimeStr> => {
  try {
    const zonedDateTime = fromTimezoneStr(timezoneStr);
    const zonedDate = utcToZonedTimeDateFnsTz(zonedDateTime.unixTimeMillis, zonedDateTime.zone);
    return formatDateFns(zonedDate, 'HH:mm');
  } catch (e) {
    return undefined;
  }
};

/**
 * Formats a zoned iso date-time string to a display friendly format.
 * The specified time zone is used
 *
 * @return {ZonedDateTimeStr} - Local time "YYYY-MM-DD HH:mm" or undefined.
 *
 * @deprecated Use <code>ZonedDateTimeFormatter.toIsoDateString(zonedDateTime)</code> or <code>ZonedDateTimeFormatter.format(zonedDateTime, ZonedDateTimeFormat.UNIFY.DATE_FORMAT, zonedDateTime.zone)</code> instead
 */
export const formatZonedDate = (timezoneStr: ZonedDateTimeStr): Maybe<IsoDateStr> => {
  try {
    const zonedDateTime = fromTimezoneStr(timezoneStr);
    const zonedDate = utcToZonedTimeDateFnsTz(zonedDateTime.unixTimeMillis, zonedDateTime.zone);
    return formatDateFns(zonedDate, 'yyyy-MM-dd');
  } catch (e) {
    return undefined;
  }
};

/**
 * Converts an Iso date (yyyy-MM-dd) and a time (HH:mm) into a zoned time for current timezone
 * Example: '2020-11-24', '14:45'
 *
 * @return {ZonedDateTime}
 */
export const dateAndTimeToZonedDateTimeCurrentZone = (isoDateStr: IsoDateStr, time: TimeStr): Maybe<ZonedDateTime> => {
  const date = parseIsoOrUndefined(isoDateStr);
  const datetime = parseTimeOrUndefined(time, date);
  if (date && datetime) {
    return toZonedDateTimeCurrentZone(datetime.valueOf());
  } else {
    return undefined;
  }
};

/**
 * Converts an Iso date (yyyy-MM-dd) and a time (HH:mm) into a zoned time string.
 * Example: '2020-11-24', '14:45'
 *
 * @return {ZonedDateTimeStr} - YYYY-MM-DDThHH:mm:ss.SSS[${zone}]
 */
export const dateAndTimeToTimezoneStrCurrentZone = (isoDateStr: IsoDateStr, time: TimeStr): Maybe<ZonedDateTimeStr> => {
  const zonedDateTime = dateAndTimeToZonedDateTimeCurrentZone(isoDateStr, time);
  return zonedDateTime ? toTimezoneStr(zonedDateTime) : undefined;
};

/**
 * Creates a ZonedDateTime from a date or unix timestamp and a timezone id.
 *
 * @return {ZonedDateTime} - the date in ZonedDateTime format
 */
export const dateOrUnixTimeMillisToZonedDateTime = (date: Date | UnixTimeMillis, zone: string): ZonedDateTime => ({
  unixTimeMillis: new Date(date).getTime(),
  zone,
});

/**
 * Creates a ZonedDateTime from a date or unix timestamp and current timezone id.
 *
 * @return {ZonedDateTime} - the date in ZonedDateTime format
 */
export const dateOrUnixTimeMillisToZonedDateTimeCurrentZone = (date: Date | UnixTimeMillis): ZonedDateTime =>
  dateOrUnixTimeMillisToZonedDateTime(date, getTimeZoneId());

export const toIsoZonedDateTime = (zonedDateTimeStr: string): IsoZonedDateTime => {
  const match = matchTimezoneStr(zonedDateTimeStr);
  if (match && match.length > 2 && match[2] != null) {
    return {
      timestampIso: zonedDateTimeStr,
      timezone: match[2],
    };
  } else {
    const offset = getTimezoneOffset(zonedDateTimeStr);
    const zone = offset.includes('00:00') ? 'UTC' : offset;
    return {
      timezone: zone,
      timestampIso: zonedDateTimeStr,
    };
  }
};

/**
 * Sets a specific time to a zoned date time in the target timezone.
 * @param zonedDateTime The zoned date time to subtract a duration to
 * @param hours hour to specify
 * @param minutes minutes to specify
 */
export const zonedSetTime = (zonedDateTime: ZonedDateTime, hours: number, minutes: number): ZonedDateTime => {
  const zonedDate = utcToZonedTimeDateFnsTz(zonedDateTime.unixTimeMillis, zonedDateTime.zone);
  const timeAdjusted = set(zonedDate, { hours, minutes, seconds: 0, milliseconds: 0 });
  const utcDate = zonedTimeToUtc(timeAdjusted, zonedDateTime.zone);
  return {
    unixTimeMillis: utcDate.getTime(),
    zone: zonedDateTime.zone,
  };
};

/**
 * Creates a ZonedDateTime from a DisplayDateTimeStr, with a custom zone
 *
 * @param {DisplayDateTimeStr} displayDateTimeStr
 * @param {string} zone - The custom zone for the ZonedDateTime
 * @return {ZonedDateTime} - Zoned date-time object
 */
export const applyTimeZoneToZonelessDateTimeStr = (
  displayDateTimeStr: DisplayDateTimeStr,
  zone = 'UTC',
): ZonedDateTime => dateOrUnixTimeMillisToZonedDateTime(zonedTimeToUtc(displayDateTimeStr, zone), zone);

/**
 * Returns the earliest date in an array of ZonedDateTimes.
 *
 * @param zonedDateTimes - array of ZoneDateTimes
 * @returns {Maybe<ZonedDateTimeStr>} - the earliest date in the array
 */
export const zonedMin = (zonedDateTimes: ZonedDateTime[]): Maybe<ZonedDateTime> => {
  if (!zonedDateTimes.length) {
    return undefined;
  }
  return zonedDateTimes.reduce((minDate, currentDate) =>
    currentDate.unixTimeMillis < minDate.unixTimeMillis ? currentDate : minDate,
  );
};

/**
 * Returns the latest date in an array of ZonedDateTimes.
 *
 * @param zonedDateTimes - array of ZoneDateTimes
 * @returns {Maybe<ZonedDateTimeStr>} - the latest date in the array
 */
export const zonedMax = (zonedDateTimes: ZonedDateTime[]): Maybe<ZonedDateTime> => {
  if (!zonedDateTimes.length) {
    return undefined;
  }
  return zonedDateTimes.reduce((maxDate, currentDate) =>
    currentDate.unixTimeMillis > maxDate.unixTimeMillis ? currentDate : maxDate,
  );
};

/**
 * Returns the difference in calendar days between two dates
 *
 * @param {ZonedDateTime} zonedDateLeft The later date
 * @param {ZonedDateTime} zonedDateRight The earlier date
 * @return {number} The difference in calendar days
 */
export const differenceInCalendarDays = (zonedDateLeft: ZonedDateTime, zonedDateRight: ZonedDateTime): number => {
  const dateLeft = new Date(zonedDateLeft.unixTimeMillis);
  const dateRight = new Date(zonedDateRight.unixTimeMillis);

  return differenceInCalendarDaysDateFns(dateLeft, dateRight);
};

/**
 * Returns a formatted ISO date string from a provided Date
 *
 * @param {Datr} date Date object to format
 * @return {IsoDateStr} formatted ISO date string
 *
 * @deprecated Use <code>ZonedDateTimeFormatter.toIsoDateString(...)</code> instead
 */
export const formatDateToIsoDateStr = (date: Date): Maybe<IsoDateStr> => formatIsoDate(date.toISOString());
