import { useContext } from 'react';
import { ConsentContext } from '../ConsentContext';
import { ConsentContextState } from '../model/ConsentContextState';

export const useConsentContext = (): ConsentContextState => {
  const ctx = useContext(ConsentContext);

  if (ctx) {
    return ctx;
  } else {
    throw new Error('Must be child of ConsentContext');
  }
};
