import { ObjectType } from '@csp/csp-common-model';
import { Button, ButtonGroup, ErrorPage, OnClick, OnClickAsync, RouterLink } from '@csp/csp-web-ui';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../common/hooks/useAppDispatch';
import { CspPath } from '../../../CspPath';
import { useConsentContext } from '../../consent/context/hook/useConsentContext';
import { useSignOut } from '../../security/hooks/useSignOut';
import { clearSelectedStudyId } from '../../studies/ducks/studiesActions';
import { hasMultipleStudiesSelector } from '../../studies/ducks/studiesSelectors';
import { FaultPageErrorType } from '../model/FaultPageErrorType';
import { useAuthContext } from '../../auth/context/hook/useAuthContext';
import { StaticContentService } from '../../../common/service/StaticContentService';

type UrlParams = {
  type?: FaultPageErrorType;
};

export const Fault404Page: FC = () => {
  const { isAuthenticated } = useAuthContext();
  const url = isAuthenticated ? CspPath.STUDY_OVERVIEW : CspPath.LANDING;

  const { type: errorTypeFromUrl } = useParams<UrlParams>();
  const errorPage = StaticContentService.getPublicEntry('webHcpErrorPage');
  const { resetConsentApprovals } = useConsentContext();
  const { signOut } = useSignOut();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const hasMultipleStudies = useSelector(hasMultipleStudiesSelector);

  const handleNavigateToStudies: OnClick = () => {
    dispatch(clearSelectedStudyId());
    resetConsentApprovals();

    navigate(CspPath.STUDIES);
  };

  const handleSignOut: OnClickAsync = async () => {
    await signOut();
    navigate(CspPath.LOGIN);
  };

  const renderButtons = (): ReactNode => (
    <>
      {hasMultipleStudies ? (
        <ButtonGroup>
          <Button onClick={handleSignOut} size="large" fullWidth variant="contained" color="primary">
            {errorPage.invalidStudyConfiguration.button.logout.label}
          </Button>
          <Button onClick={handleNavigateToStudies} size="large" fullWidth variant="outlined" color="primary">
            {errorPage.invalidStudyConfiguration.button.goBack.label}
          </Button>
        </ButtonGroup>
      ) : (
        <Button onClick={handleSignOut} size="large" variant="contained" color="primary">
          {isAuthenticated
            ? errorPage.invalidStudyConfiguration.button.logout.label
            : errorPage.generic.button.goBack.label}
        </Button>
      )}
    </>
  );

  if (errorTypeFromUrl) {
    const renderLinkToLogin = (props: PropsWithChildren<ObjectType>): JSX.Element => (
      <RouterLink {...props} to={CspPath.LOGIN} />
    );

    switch (errorTypeFromUrl) {
      case FaultPageErrorType.MISSING_EULA:
        return (
          <ErrorPage
            renderLogoContainer={renderLinkToLogin}
            label={errorPage.eulaNotFound.header.text}
            subLabel={errorPage.eulaNotFound.body.text}
            illustration="applicationError"
          >
            {renderButtons()}
          </ErrorPage>
        );
      case FaultPageErrorType.MISSING_PRIVACY_POLICY:
        return (
          <ErrorPage
            renderLogoContainer={renderLinkToLogin}
            label={errorPage.privacyPolicyNotFound.header.text}
            subLabel={errorPage.privacyPolicyNotFound.body.text}
            illustration="applicationError"
          >
            {renderButtons()}
          </ErrorPage>
        );
      case FaultPageErrorType.INVALID_STUDY_CONFIGURATION:
        return (
          <ErrorPage
            renderLogoContainer={renderLinkToLogin}
            label={errorPage.invalidStudyConfiguration.header.text}
            subLabel={errorPage.invalidStudyConfiguration.body.text}
            illustration="applicationError"
          >
            {renderButtons()}
          </ErrorPage>
        );
    }
  }

  return (
    <ErrorPage
      renderLogoContainer={(props): JSX.Element => <RouterLink {...props} to={url} />}
      altText={errorPage.pageNotFound.image.altText}
      label={errorPage.pageNotFound.header.text}
      subLabel={errorPage.pageNotFound.body.text}
      illustration="applicationError"
    >
      {renderButtons()}
    </ErrorPage>
  );
};
