import { toRestOptions } from '@csp/csp-fe-auth';
import { ApiOptions } from '@csp/csp-common-model';
import { ContentPackageType } from '../../model/ContentPackageType';
import { ContentstackConfig } from '../../config/model/ContentstackConfig';
import { getPreviewConfig, PreviewModeConfig } from '../../utils/getPreviewConfig';
import { getForceContentPreview } from '../../utils/configUtil';

export const fetchPreviewConfiguration = async (
  packageType: ContentPackageType,
  config?: ContentstackConfig,
  apiOptions?: ApiOptions,
): Promise<PreviewModeConfig> => {
  if (packageType === 'public') {
    return { previewModeEnabled: getForceContentPreview(config) };
  }

  return await getPreviewConfig(toRestOptions(apiOptions), config);
};
