import { DeviceObservationActivationV1 } from '@csp/dmdp-api-device-dto';

export type DeviceObservationActivation = {
  activationId: string;
  versionCode: string;
  windowStart: string;
  windowEnd?: string;
};

const from = ({
  activationId,
  versionCode,
  windowStart,
  windowEnd,
}: DeviceObservationActivationV1): DeviceObservationActivation => ({
  activationId,
  versionCode,
  windowStart,
  windowEnd,
});

export const DeviceObservationActivation = {
  from,
};
