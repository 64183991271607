import { isPastUnixTime, nowToZonedDateTimeCurrentZone } from '@csp/csp-common-date-util';
import { ZonedDateTime } from '@csp/csp-common-model';
import { GenericSchedule } from '../model/schedulingModels/GenericSchedule';
import { GenericVersion } from '../model/schedulingModels/GenericVersion';
import { ScheduleWindowInterval } from '../model/ScheduleWindowInterval';

/**
 * Checks for active version windows that are active now.
 *
 * @param schedules ActiveSchedules for and organization.
 * @param now {ZonedDateTime} optional default nowToZonedDateTimeCurrentZone()
 */
export const findScheduleVersionsActiveNow = <TVersion extends GenericVersion = GenericVersion>(
  schedules: GenericSchedule<TVersion>[],
  now: ZonedDateTime = nowToZonedDateTimeCurrentZone(),
): TVersion[] =>
  schedules
    .flatMap(schedule => schedule.activeVersionWindows)
    .filter(({ activeFrom: windowStart, activeTo: windowEnd }) => isWindowActiveNow({ windowStart, windowEnd }, now));

/**
 * Checks for active version windows that are active now or have been in the past.
 *
 * @param schedules ActiveSchedules for and organization.
 */
export const findScheduleActiveNowOrInPast = <TVersion extends GenericVersion = GenericVersion>(
  schedules: GenericSchedule<TVersion>[],
): TVersion[] =>
  schedules
    .flatMap(schedule => schedule.activeVersionWindows)
    .filter(({ activeFrom }) => isPastUnixTime(activeFrom.unixTimeMillis));

/**
 * Checks if time now is in between the window for when the schedule is active.
 * @param window {WindowStartAndEndDates}
 * @param now {ZonedDateTime} default nowToZonedDateTimeCurrentZone
 */
export const isWindowActiveNow = (
  window: ScheduleWindowInterval,
  now: ZonedDateTime = nowToZonedDateTimeCurrentZone(),
): boolean => {
  const { windowStart, windowEnd } = window;
  const unixTimeStart = windowStart.unixTimeMillis;
  const unixTimeEnd = windowEnd ? windowEnd.unixTimeMillis : Number.MAX_VALUE;

  return now.unixTimeMillis < unixTimeEnd && now.unixTimeMillis >= unixTimeStart;
};
