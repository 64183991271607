import { Maybe } from '@csp/csp-common-model';
import { GenericRequest } from '../model/schedulingModels/GenericRequest';
import { RequestWhile } from '../model/schedulingModels/RequestWhile';
import { RequestWhileCriterion } from '../model/schedulingModels/RequestWhileCriterion';

const isIncludedInCriterion = (
  templateCriterion: RequestWhileCriterion,
  includedInCriterion: RequestWhileCriterion,
): boolean => {
  if (templateCriterion.operator !== includedInCriterion.operator) {
    return false;
  } else if (templateCriterion.type !== includedInCriterion.type) {
    return false;
  } else if (templateCriterion.subType !== includedInCriterion.subType) {
    return false;
  } else if (!templateCriterion.values.every(value => includedInCriterion.values.includes(value))) {
    return false;
  } else {
    return true;
  }
};

const isCriterionPartOfWhile = (templateCriterion: RequestWhileCriterion, requestWhile: Maybe<RequestWhile>): boolean =>
  !!requestWhile?.criteria?.some(criterion => RequestWhileUtil.isIncludedInCriterion(templateCriterion, criterion));

const filterRequestsWithMatchingCriteria = <T extends GenericRequest>(
  matchingCriterionTemplates: RequestWhileCriterion[],
  requests: T[],
): T[] =>
  requests.filter(request =>
    matchingCriterionTemplates.every(template => isCriterionPartOfWhile(template, request.while)),
  );

export const RequestWhileUtil = {
  isIncludedInCriterion,
  isCriterionPartOfWhile,
  filterRequestsWithMatchingCriteria,
};
