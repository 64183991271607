/* eslint-disable */
export const UnifyWebPatientComplianceSchema = {
  title: 'UnifyWebPatientCompliance',
  type: 'object',
  properties: {
    title: { type: 'string' },
    noStudyCompliance: {
      type: 'object',
      title: 'No Compliance available messages',
      properties: {
        noComplianceTitle: {
          title: 'No compliance title',
          type: 'string',
          mock: 'Compliance is not enabled for this study.',
        },
      },
    },
    noComplianceData: {
      type: 'string',
      title: 'Empty Compliance messages',
      mock: 'No questionnaires or device data found.',
    },
    navTabs: {
      type: 'object',
      title: 'Navigation tabs',
      properties: {
        overallComplianceLabel: { title: 'Overall compliance tab', type: 'string', mock: 'Overall compliance' },
        detailedComplianceLabel: { title: 'Detailed compliance tab', type: 'string', mock: 'Detailed compliance' },
      },
    },
    legend: {
      type: 'object',
      title: 'Legend',
      properties: {
        aboveThreshold: { type: 'string', title: 'Above threshold', mock: 'Above threshold' },
        belowThreshold: { type: 'string', title: 'Below threshold', mock: 'Below threshold' },
        threshold: { type: 'string', title: 'Threshold', mock: 'Threshold' },
        paused: { type: 'string', title: 'Paused', mock: 'Paused' },
      },
    },
    overallCompliance: {
      type: 'object',
      title: 'Patient Overall Compliance',
      properties: {
        learnMore: {
          type: 'object',
          title: 'Overall Compliance Learn more drawer',
          properties: {
            title: {
              type: 'object',
              title: 'Overall Compliance Learn more drawer titles',
              properties: {
                drawerHeader: {
                  type: 'string',
                  title: 'Overall Compliance Learn more drawer header',
                  mock: 'Learn more',
                },
                subtitle: { type: 'string', title: 'subtitle', mock: 'Overall compliance' },
              },
            },
            labelsAndDescriptions: {
              title: 'General overall compliance learn more content items',
              type: 'array',
              items: {
                title: 'General overall compliance learn more content item',
                type: 'object',
                properties: {
                  label: { type: 'string', title: 'Learn more content item label' },
                  bodyMd: { type: 'string', title: 'Learn more content item description. Markdown is supported' },
                },
              },
              mock: [
                {
                  label: '[M]Purpose of Overall compliance section',
                  bodyMd:
                    '[M]In Overall compliance you will find an aggregated view of a patient’s total compliance for questionnaire responses and device readings from across the whole study period including the most recent time period. Compliance information can be used to check that patients are reporting data in the way that is expected by patients in the study, but also to find out if the patients need support to keep up with their assigned tasks.',
                },
                {
                  label: '[M]What is compliance?',
                  bodyMd:
                    '[M]Compliance is the percentage of time a patient follows the instructions of their study protocol. It is calculated by dividing the number of days a patient has been compliant by the total number of days in the study. For example, if a patient has been compliant for 10 days out of 30 days, their compliance is 33%.',
                },
              ],
            },
          },
        },
        filters: {
          type: 'object',
          title: 'Filtering section',
          properties: {
            label: {
              type: 'object',
              title: 'Labels',
              properties: {
                sortBy: { type: 'string', title: 'Sort by', mock: 'Sort by' },
                complianceStatus: { type: 'string', title: 'Compliance status', mock: 'Compliance status' },
                questionnaires: { type: 'string', title: 'Questionnaires', mock: 'Questionnaires' },
                devices: { type: 'string', title: 'Devices', mock: 'Devices' },
                all: { type: 'string', title: 'All', mock: 'All' },
                hideQuestionnaires: {
                  type: 'string',
                  title: 'Hide questionnaires checkbox label',
                  mock: 'Hide questionnaires',
                },
                hideDevices: { type: 'string', title: 'Hide devices checkbox label', mock: 'Hide devices' },
              },
            },
            button: {
              type: 'object',
              title: 'Filtering section buttons',
              properties: {
                clearAll: { type: 'string', title: 'Clear all', mock: 'Clear all' },
                export: { type: 'string', title: 'Export', mock: 'Export' },
                filters: { type: 'string', title: 'Filters', mock: 'Filters' },
              },
            },
            enum: {
              type: 'object',
              title: 'Enum overall compliance filters',
              properties: {
                sortBy: {
                  type: 'object',
                  title: 'Sort by filter enum',
                  properties: {
                    ASC: { type: 'string', title: 'Title for ASC sort order', mock: 'Compliance low' },
                    DESC: { type: 'string', title: 'Title for DESC sort order', mock: 'Compliance high' },
                    ALPHABETICAL: { type: 'string', title: 'Title for ALPHABETICAL sort order', mock: 'Alphabetical' },
                  },
                },
                complianceStatus: {
                  type: 'object',
                  title: 'Compliance status enum',
                  properties: {
                    ALL: { type: 'string', title: 'All', mock: 'All' },
                    COMPLIANT: { type: 'string', title: 'Compliant status', mock: 'Compliant' },
                    NOT_COMPLIANT: { type: 'string', title: 'Not compliant status', mock: 'Not compliant' },
                  },
                },
              },
            },
          },
        },
        card: {
          type: 'object',
          title: 'Patient Overall Compliance Card',
          properties: {
            header: {
              type: 'object',
              title: 'Headers',
              properties: {
                cardHeader: { type: 'string', title: 'Card Header', mock: 'Overall compliance' },
                compliantQuestionnaires: {
                  type: 'string',
                  title: 'Compliant Questionnaire',
                  mock: 'Compliant Questionnaires',
                },
                compliantDevices: { type: 'string', title: 'Compliant Devices', mock: 'Compliant Devices' },
                lastReceivedDataQuestionnaire: {
                  type: 'string',
                  title: 'Last Received Data',
                  mock: 'Last Received Data',
                },
                lastReceivedDataDevice: { type: 'string', title: 'Device Last Synced', mock: 'Device Last Synced' },
                totalCompliance: { type: 'string', title: 'Total', mock: 'Total' },
                targetThreshold: { type: 'string', title: 'Threshold', mock: 'Threshold' },
                paused: { type: 'string', title: 'Paused', mock: 'Paused' },
              },
            },
            button: {
              type: 'object',
              title: 'Buttons and links',
              properties: {
                viewDetails: { type: 'string', title: 'View Details', mock: 'View details' },
                learnMore: { type: 'string', title: 'Learn More', mock: 'Learn more' },
              },
            },
          },
        },
        emptyState: {
          type: 'object',
          title: 'Empty state',
          properties: {
            graphNoDataMessage: {
              type: 'string',
              title: 'No data available on graph message',
              mock: 'No data available.',
            },
            patientMustBeActiveMessage: {
              type: 'string',
              title: 'Patient must be active message',
              mock: 'Patient must be active to view compliance.',
            },
            filterNoDataFoundTitle: {
              type: 'string',
              title: 'No filter results found title',
              mock: 'No results found.',
            },
            filterNoDataFoundMessage: {
              type: 'string',
              title: 'No filter results found message',
              mock: 'Please change the filters and try again.',
            },
          },
        },
      },
    },
    detailedCompliance: {
      type: 'object',
      title: 'Patient Detailed Compliance',
      properties: {
        learnMore: {
          type: 'object',
          title: 'Detailed Compliance Learn more drawer',
          properties: {
            title: {
              type: 'object',
              title: 'Detailed Compliance Learn more drawer titles',
              properties: {
                drawerHeader: {
                  type: 'string',
                  title: 'Detailed Compliance Learn more drawer header',
                  mock: 'Learn more',
                },
                subtitle: { type: 'string', title: 'subtitle', mock: 'Detailed compliance' },
              },
            },
            labelsAndDescriptions: {
              title: 'General detailed compliance learn more content items',
              type: 'array',
              items: {
                title: 'General detailed compliance learn more content item',
                type: 'object',
                properties: {
                  label: { type: 'string', title: 'Learn more content item label' },
                  bodyMd: { type: 'string', title: 'Learn more content item description. Markdown is supported' },
                },
              },
              mock: [
                {
                  label: '[M]Purpose of Detailed compliance section',
                  bodyMd:
                    '[M]In Detailed compliance you will find an aggregated view of a patient’s total compliance for questionnaire responses and device readings from a selected time period (default past 3 months). Compliance information can be used to check that patients are reporting data in the way that is expected by patients in the study, but also to find out if the patients need support to keep up with their assigned tasks.',
                },
                {
                  label: '[M]What is compliance?',
                  bodyMd:
                    '[M]Compliance is the percentage of time a patient follows the instructions of their study protocol. It is calculated by dividing the number of days a patient has been compliant by the total number of days in the study. For example, if a patient has been compliant for 10 days out of 30 days, their compliance is 33%.',
                },
              ],
            },
          },
        },
        header: {
          type: 'object',
          title: 'Headers',
          properties: {
            detailedCompliance: { type: 'string', title: 'Detailed compliance', mock: 'Detailed compliance' },
            totalCompliance: { type: 'string', title: 'Total Compliance', mock: 'Total' },
            targetThreshold: { type: 'string', title: 'Target Threshold', mock: 'Threshold' },
            completed: { type: 'string', title: 'Completed', mock: 'Completed' },
            expected: { type: 'string', title: 'Expected', mock: 'Expected' },
            lastReceivedData: { type: 'string', title: 'Last Received Data', mock: 'Last Received Data' },
            compliance: { type: 'string', title: 'Compliance', mock: 'Compliance' },
            emptyChart: { type: 'string', title: 'No data available', mock: 'No data available.' },
          },
        },
        enum: {
          type: 'object',
          title: 'Enums',
          properties: {
            complianceStatus: {
              type: 'object',
              title: 'Compliance Status',
              properties: {
                COMPLIANT: { type: 'string', title: 'Compliant', mock: 'Compliant' },
                NOT_COMPLIANT: { type: 'string', title: 'Not compliant', mock: 'Not compliant' },
                NO_DATA: { type: 'string', title: 'No data', mock: 'No data' },
              },
            },
          },
        },
        button: {
          type: 'object',
          title: 'Buttons and links',
          properties: {
            viewDetails: { type: 'string', title: 'View Details', mock: 'View details' },
            filters: { type: 'string', title: 'Filters', mock: 'Filters' },
            reportDashboard: { type: 'string', title: 'Report Dashboard', mock: 'Report Dashboard' },
            clearAll: { type: 'string', title: 'Clear all', mock: 'Clear all' },
          },
        },
        filters: {
          type: 'object',
          title: 'Filtering section',
          properties: {
            sortBy: {
              type: 'object',
              title: 'Sort by',
              properties: {
                label: { type: 'string', title: 'Label', mock: 'Sort by' },
                complianceLow: { type: 'string', title: 'Compliance low', mock: 'Compliance low' },
                complianceHigh: { type: 'string', title: 'Compliance high', mock: 'Compliance high' },
                name: { type: 'string', title: 'Name', mock: 'Name' },
              },
            },
            label: {
              type: 'object',
              title: 'Labels',
              properties: {
                startDate: { type: 'string', title: 'Start date', mock: 'Start date' },
                endDate: { type: 'string', title: 'End date', mock: 'End date' },
                startVisit: { type: 'string', title: 'Start visit', mock: 'Start visit' },
                endVisit: { type: 'string', title: 'End visit', mock: 'End visit' },
                questionnaires: { type: 'string', title: 'Questionnaires', mock: 'Questionnaires' },
                devices: { type: 'string', title: 'Devices', mock: 'Devices' },
                all: { type: 'string', title: 'All', mock: 'All' },
                hideQuestionnaires: { type: 'string', title: 'Hide questionnaires', mock: 'Hide questionnaires' },
                hideDevices: { type: 'string', title: 'Hide devices', mock: 'Hide devices' },
              },
            },
            emptyResult: {
              type: 'object',
              title: 'Empty result',
              properties: {
                header: { type: 'string', title: 'Header', mock: 'No results found' },
                message: { type: 'string', title: 'Message', mock: 'Please change the filters and try again.' },
              },
            },
          },
        },
      },
    },
  },
  description: 'unify:webpatientcompliance',
  $schema: 'http://json-schema.org/draft-07/schema#',
};
