import { FC } from 'react';
import { SpacingProps } from '@mui/system';
import { Maybe } from '@csp/csp-common-model';
import { SxProps } from '@mui/material';
import { Markdown, MarkdownProps } from '../markdown/Markdown';
import { MarkdownColor } from '../markdown/model/MarkdownColor';
import { Dash } from '../dataDisplay/dash/Dash';
import { LabelWithText } from './LabelWithText';
import { LabelWithTextProps } from './model/LabelWithTextProps';

type Props = Omit<LabelWithTextProps, 'text' | 'textProps' | 'dash'> &
  Pick<SpacingProps, 'pt' | 'mt'> &
  Pick<MarkdownProps, 'paragraph' | 'body' | 'span'> & {
    markdown: Maybe<string>;
    markdownColor?: MarkdownColor;
    markdownSx?: SxProps;
    labelFontWeight?: number;
  };

export const LabelWithMarkdown: FC<Props> = ({
  markdown,
  mb,
  pt,
  paragraph,
  label,
  markdownColor = 'secondary',
  labelFontWeight = 700,
  body = 'body2',
  span = 'body2',
  large = true,
  markdownSx,
  labelProps,
}) => (
  <LabelWithText
    label={label}
    large={large}
    mb={mb}
    pt={pt}
    labelProps={{ fontWeight: labelFontWeight }}
    {...labelProps}
  >
    {markdown && (
      <Markdown sx={markdownSx} color={markdownColor} paragraph={paragraph} body={body} span={span}>
        {markdown}
      </Markdown>
    )}
    {label && !markdown && <Dash />}
  </LabelWithText>
);
