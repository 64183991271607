/* eslint-disable */
export const UnifyWebDevicesMock = {
  noDevices: '[M]No devices.',
  noAppUser: '[M]This patient is not a Unify app user',
  emptyPage: {
    disabledInCountry: { title: '[M]Devices are disabled in your country' },
    disabledOnSite: { title: '[M]Devices are disabled on your site' },
  },
  navigation: { title: '[M]Device details' },
  requestList: { ongoingRequestsTitle: '[M]Ongoing', expiredRequestsTitle: '[M]Expired' },
  learnMore: {
    titles: { drawerTitle: '[M]Learn More', descriptionTitle: '[M]Description', scheduleInfoTitle: '[M]Schedule' },
    buttons: { learnMoreButton: '[M]Learn More' },
  },
  scheduleInfo: {
    enum: {
      deviceActionTypes: { RANDOMIZATION: '[M]Randomization', SCREENING: '[M]Screening', ACTIVATION: '[M]Activation' },
      actionOffsetUnitOfTime: {
        S: '[M]seconds',
        MIN: '[M]minutes',
        H: '[M]hours',
        D: '[M]days',
        WK: '[M]weeks',
        MO: '[M]months',
        A: '[M]years',
      },
    },
  },
  deviceCard: {
    modelNameLabel: '[M]Model',
    batteryPercentageLabel: '[M]Battery level',
    firmwareLabel: '[M]Firmware',
    lastSyncLabel: '[M]Last synced',
    unregisterLabel: '[M]Unregister',
    unregisterDialog: {
      modalHeader: '[M]Are you sure?',
      modalDescription:
        '[M]By unregistering this device you are confirming it has been returned by the patient and is no longer in use.',
      confirmUnregister: '[M]Unregister',
      cancelUnregister: '[M]Go Back',
      alert: { unhandledError: '[M]Unexpected error occurred' },
      snackbar: { unregisterDeviceSuccess: '[M]You set ${deviceName} to Returned' },
    },
    userDevicesList: {
      modelHeader: '[M]Model',
      serialNumberHeader: '[M]Serial Number',
      batteryLevelHeader: '[M]Battery level',
      firmwareHeader: '[M]Firmware',
      statusHeader: '[M]Status',
    },
    button: { seeDetails: '[M]See details' },
    dataUse: {
      title: '[M]Data use',
      text: '[M]The summary information provided below is not intended to aid in treatment or diagnosis and should not be used to inform clinical decision making.',
    },
    deviceRequestStatus: {
      enum: { DONE: '[M]Ended', UPCOMING: '[M]Upcoming', ONGOING: '[M]Ongoing', NOT_APPLICABLE: '[M]Not applicable' },
    },
    statusTooltips: {
      REGISTERED:
        '[M]Notifications are being sent to the patient to take measurements according to the schedule defined in the protocol.',
      RETURNED:
        '[M]The device has been returned by the patient and reminders to take measurements have been stopped. To restart measurements provide the patient with a device and ask them to pair their device to the Unify App.',
      NOT_REGISTERED:
        '[M]Notifications are not being sent to the patient to take measurement according to the schedule defined in the protocol. Pair the device to the Unify App. If this fails to register the device review the FAQs and try again or contact the technical support desk.',
    },
    deviceStatus: { REGISTERED: '[M]Registered', RETURNED: '[M]Returned', NOT_REGISTERED: '[M]Not registered' },
    batteryStatus: { HIGH: '[M]High', MEDIUM: '[M]Medium', LOW: '[M]Low', NOT_SUPPORTED: '[M]Not supported' },
    disabledInCountry: { title: '[M]Devices', body: '[M]Devices are disabled in your country' },
    disabledOnSite: { title: '[M]Devices', body: '[M]Devices are disabled on your site' },
    doubleBlindedMessage: '[M]Measurements for double blinded devices are hidden.',
    title: {
      lastSynced: '[M]Last synced',
      startDate: '[M]Start date',
      endDate: '[M]End date',
      status: '[M]Status',
      startAction: '[M]Start action',
      endAction: '[M]End action',
    },
    connectDeviceWarning: '[M]Please ask the patient to pair their device with Unify.',
    noConnectedDeviceText: '[M]No device connected.',
    viewAllUserDevices: '[M]view all devices',
    requestInitiation: {
      initiatedByTitle: '[M]Initiated by',
      hcpInitiatedRequestLabel: '[M]HCP Initiated',
      patientScheduleRequestLabel: '[M]Patient schedule',
      initiationFailure: '[M]Failed to start activity. Please contact support.',
      pendingRequest: '[M]Pending request',
    },
  },
  deviceObservations: {
    second: '[M]second',
    seconds: '[M]seconds',
    observationDurationTemplate: '[M]${minutes} ${minutesText} and ${seconds} ${secondsText}',
    observationWindowDateTimeTemplate: '[M]${startDate} ${startTime} - ${endDate} ${endTime}',
    verifiedLabel: '[M]Verified',
    notVerifiedLabel: '[M]Not verified',
    measurementHiddenLabel: '[M]Measurement hidden',
    statusTooltip:
      '[M]The status is dependent on the schedule of assessments. Unscheduled measurements are additional measurements.',
    verificationTooltip:
      '[M]Verification status is determined by the patient. The patient enters their unique pin code in the Application to verify the result.',
    missingTimeStampTooltip: '[M]Device has not recorded any timestamp for this observation.',
    missingTimeStampReplacement: '[M]No data synced',
    observationConfigurationError: '[M]Invalid device configuration. Please contact support.',
    pagination: { countInfoMiddle: '[M]of', countInfoText: '[M]readings shown', loadMoreButton: '[M]Load more' },
    noObservationsLabel:
      '[M]No measurements have been recorded. Please ask the patient to pair their device using the patient Application and take a measurement.',
    observationStatus: { SCHEDULED: '[M]Scheduled', UNSCHEDULED: '[M]Unscheduled', MISSED: '[M]Missed' },
    observationSort: { title: '[M]Sort by', ascending: '[M]Most recent last', descending: '[M]Most recent first' },
    observationFilter: {
      emptyResult: '[M]No filtered data',
      startDateFilter: '[M]Start date',
      endDateFilter: '[M]End date',
      statusFilter: '[M]Status',
      scheduledStatus: '[M]Scheduled',
      unscheduleStatus: '[M]Unscheduled',
      missedStatus: '[M]Missed',
      allStatusesFilter: '[M]All',
      clearAllFilters: '[M]Clear All',
    },
  },
  deviceSessions: {
    spirometrySessionTable: {
      tableHeaders: {
        actual: '[M]Actual',
        predicted: '[M]Pred',
        predictedPercent: '[M]Pred %',
        lln: '[M]LLN',
        zScore: '[M]Z Score',
      },
      tableRows: {
        fev1: { rowTitle: '[M]FEV1 (L)', unit: '[M]L' },
        fvc: { rowTitle: '[M]FVC (L)', unit: '[M]L' },
        fev1_fvc: { rowTitle: '[M]FEV1 / FVC' },
        fef2575: { rowTitle: '[M]FEF2575 (L/s)', unit: '[M]L/s' },
        pef: { rowTitle: '[M]PEF (L/s)', unit: '[M]L/s' },
        fivc: { rowTitle: '[M]FIVC (L)', unit: '[M]L' },
        pif: { rowTitle: '[M]PIF (L/s)', unit: '[M]L/s' },
      },
    },
    sessionsList: {
      title: '[M]All sessions (only best data shown here)',
      missedSession: '[M]Patient missed to complete this session',
      emptySession: '[M]No measurements yet collected for this session',
    },
    reviewSessionStatus: { ACCEPTED: '[M]ACCEPTED', REJECTED: '[M]REJECTED', TO_BE_REVIEWED: '[M]TO_BE_REVIEWED' },
    sessionFilter: {
      reviewStatusFilter: '[M]Review Status',
      acceptedStatus: '[M]Accepted',
      rejectedStatus: '[M]Rejected',
      toBeReviewedStatus: '[M]To be reviewed',
      allReviewStatusesFilter: '[M]All',
    },
    sessionLegend: { item: '[M]Test ${testNumber}', bestMarked: '[M]best' },
    exportSession: {
      title: '[M]Export Session Data',
      body: '[M]I would like to export a PDF report containing all session data.',
      button: '[M]Export Data',
    },
    comments: {
      commentForm: {
        infoAlert: '[M]Notes for all tests will be available once the session is completed.',
        inputPlaceHolder: '[M]Write your test notes here.',
        snackbar: { addCommentSuccess: '[M]Your note was successfully added' },
        inputHelper: '[M]${characterCount} / 4096 characters',
        button: '[M]Save',
      },
      cardHeader: '[M]Test ${testNumber} - Operator Comments',
      emptyCardContent: '[M]No notes has been collected during this test.',
      viewMore: '[M]View more comments (${additionalCommentsLength})',
      timestampToolTip: '[M]The time that the comment was added.',
    },
    navigation: { title: '[M]${sessionTestType} Session Details ', sessionTestType: '[M]Spirometry' },
    spirometryGraph: {
      flowVolumeGraph: { title: '[M]Flow volume graph', xAxisLabel: '[M]Volume (L)', yAxisLabel: '[M]Flow (L/s)' },
      volumeTimeGraph: { title: '[M]Volume time graph', xAxisLabel: '[M]Time (s)', yAxisLabel: '[M]Volume (L)' },
    },
    sessionDetails: { header: '[M]Session details', timestampLabel: '[M]Session started at' },
    sessionAcceptReject: {
      markBestTitle: '[M]Mark test as the best data from the session',
      markBestSwitchLabel: '[M]Mark this test as the best data and accept the spirometry session',
      rejectTitle: '[M]Reject session data',
      rejectInfo: '[M]I have reviewed the entire spirometry session and I want to reject all data.',
      rejectSwitchLabel: '[M]Reject this session',
      rejectSessionInfoLabel: '[M]This session has been rejected and cannot be re-approved',
      markBestDialog: {
        cardTitle: '[M]Mark test as the best data from the session',
        dialogDescriptionMd:
          '[M]Are you sure that **TEST ${testNumber}** provided the best data from the spirometry session?',
        checkBoxText: '[M]All data has been reviwed and I accept the session.',
        dialogConfirmButton: '[M]Confirm',
        dialogCancelButton: '[M]Cancel',
      },
      rejectSessionDialog: {
        cardTitle: '[M]Reject spriometry session data',
        dialogDescriptionMd:
          '[M]Are you sure you want to reject all data from this session? <br/> <br/> Please note this action cannot be reversed once confirmed.',
        dialogConfirmButton: '[M]Confirm',
        dialogCancelButton: '[M]Cancel',
      },
    },
  },
  devicesInUse: {
    noDevices:
      '[M]No devices are registered. Please ask the patient to pair their device using the patient mobile app.',
    cardTitle: '[M]Devices',
    viewMore: '[M]View more',
    viewDetailsPage: '[M]View details',
  },
  sensorReadingDetails: {
    measurementLabelPageTitle: '[M]Measurement',
    statusLabel: '[M]Status',
    timeAndDateLabel: '[M]Time and date of submission',
    durationLabel: '[M]Duration',
  },
  observationLabels: [
    { name: 'SYSTOLIC_PRESSURE', label: 'SYS' },
    { name: 'DIASTOLIC_PRESSURE', label: 'DIA' },
    { name: 'PULSE_RATE', label: 'Pulse' },
    { name: 'DURATION', label: 'Duration' },
    { name: 'SPO2', label: 'SpO2' },
    { name: 'MIN_SPO2', label: 'SpO2', labelInGroupedObservation: 'Min' },
    { name: 'AVG_SPO2', label: 'SpO2', labelInGroupedObservation: 'Avg' },
    { name: 'MAX_SPO2', label: 'SpO2', labelInGroupedObservation: 'Max' },
    { name: 'LAST_SPO2', label: 'SpO2', labelInGroupedObservation: 'Last' },
    { name: 'MIN_PULSE_RATE', label: 'Pulse', labelInGroupedObservation: 'Min' },
    { name: 'AVG_PULSE_RATE', label: 'Pulse', labelInGroupedObservation: 'Avg' },
    { name: 'MAX_PULSE_RATE', label: 'Pulse', labelInGroupedObservation: 'Max' },
    { name: 'LAST_PULSE_RATE', label: 'Pulse', labelInGroupedObservation: 'Last' },
    { name: 'MIN_RESPIRATION_RATE_FROM_PLETH', label: 'RR', labelInGroupedObservation: 'Min' },
    { name: 'AVERAGE_RESPIRATION_RATE_FROM_PLETH', label: 'RR', labelInGroupedObservation: 'Avg' },
    { name: 'MAX_RESPIRATION_RATE_FROM_PLETH', label: 'RR', labelInGroupedObservation: 'Max' },
    { name: 'LAST_RESPIRATION_RATE_FROM_PLETH', label: 'RR', labelInGroupedObservation: 'Last' },
    { name: 'ACTUATION', label: 'actuation' },
    { name: 'HEART_RATE', label: 'Heart Rate' },
    { name: 'PERFUSION_INDEX', label: 'Perfusion Index' },
  ],
  groupedObservationLabels: [
    { name: 'SPO2', label: 'SpO2' },
    { name: 'PULSE_RATE', label: 'Pulse' },
    { name: 'RESPIRATION_RATE', label: 'RR' },
  ],
  liveSpiroMetrySession: {
    sessionTitle: '[M]Spirometry Test',
    alerts: {
      noRefreshInfo:
        '[M]Do not close this tab or refresh the page during the session, otherwise the test will need to be restarted.',
    },
    popUps: {
      closeSession: {
        header: '[M]Close the session',
        description:
          '[M]Are you sure you want to close the spirometry session? Once the session has been closed you cannot add further comments or tests.',
        checkbox: '[M]I understand and confirm I want to close the session',
        confirm: '[M]Close',
        goBack: '[M]Go back',
      },
      maxNumTests: {
        header: '[M]Maximum number of tests reached',
        description: '[M]Your patient has completed the maximum number of allowed tests. Please end the session.',
        confirm: '[M]Close',
      },
      timoutWarning: {
        header: '[M]Timeout warning',
        description: '[M]We have not received any data from your patient recently.\n\nDo you want to end the session?',
        confirm: '[M]End session',
        goBack: '[M]Go back',
      },
      startSession: {
        header: '[M]Start the session',
        ecode: '[M]E-code',
        testType: { title: '[M]Test', value: '[M]Spirometry Test' },
        descriptionMd:
          '[M]You are about to start a remote spirometry session with the patient.\n\n Before continuing, please check:\n\n - You have a good internet connection.\n - You have already started the video call with the patient.\n - You have confirmed with the patient they are able to do the test. \n\nDo you want to continue? \n\nThis will prompt the patient to start the spirometry session in the patient app.',
        confirm: '[M]Continue',
        cancel: '[M]Cancel',
        button: '[M]Start the spirometry session',
        pendingHeader: '[M]Error',
        pendingDescriptionMd:
          '[M]A test request has already been sent to the patient. You cannot send a new request until the current request has expired.',
        pendingDateInformation: '[M]The pending request will expire on ${dateTime}',
        pendingCloseButton: '[M]Ok',
      },
      viewSessionReport: {
        header: '[M]View report',
        descriptionMd: '[M]Once you view the report you will not be able to restart the session or add new comments.',
        confirm: '[M]View report',
        cancel: '[M]Go back',
      },
      missingPatientInfo: {
        header: '[M]Patient information missing',
        descriptionMd:
          '[M]Some patient information is missing or incorrect. <br/> Please check the Patient overview and complete or update patient information before starting the spirometry session.',
        close: '[M]Close',
      },
    },
    faqDrawer: {
      drawerHeader: '[M]Help',
      drawerSubTitle: '[M]Frequently Asked Questions (FAQs)',
      faqItems: [
        { question: 'Info 1', answer: 'Here is a simple answer to a simple qustion' },
        { question: 'Info 2', answer: 'Here is a simple answer to a simple qustion' },
        { question: 'Info 3', answer: 'Here is a simple answer to a simple qustion' },
      ],
    },
    sessionReport: {
      title: '[M]Session Report',
      body: '[M]Once the patient has completed 3 acceptable tests (or the maximum number of allowed tests) you can view the report.',
      viewReportButton: '[M]Save and View Session Report',
    },
    sessionWarnings: {
      notAvailable: '[M]Unknown warning',
      dontHesitate: '[M]Hesitation',
      blowOutFaster: '[M]Did not blow out fast enough',
      blowOutLonger: '[M]Did not blow out long enough',
      abruptEnd: '[M]Abrupt ending',
      dontStartTooEarly: '[M]Started too early',
      avoidCoughing: '[M]Caoughing detected',
      hesitationAtMaxVolume: '[M]Hesitation at max volume',
      slowFilling: '[M]Slow filling',
      lowFinalInspiration: '[M]Low final inspiration',
      incompleteInspirationPriorToFvc: '[M]Incomplete inspiration prior to FVC',
      lowForcedExpirationVolume: '[M]Low forced expiration volume',
      goodBlow: '[M]Good blow',
      viewAllWarnings: '[M]View all warnings (${numberOfWarnings})',
    },
    sessionManeuvers: {
      sessionManeuversSectionTitle: '[M]Test ${testNumber} data',
      noMeasurements: '[M]No data has been collected from your patient yet. Please wait.',
    },
    sessionAcceptability: {
      fev1AcceptabilityHeader: '[M]FEV 1 acceptability Criteria',
      fvcAcceptabilityHeader: '[M]FVC acceptability Criteria',
      acceptabilityCriteriaValues: {
        notApplicable: '[M]Not Applicable',
        acceptable: '[M]Acceptable',
        notAcceptable: '[M]Not Acceptable',
        notAcceptableAndUsable: '[M]Usable',
        notAcceptableAndNotUsable: '[M]Not Usable',
        unknown: '[M]UKNOWN',
      },
    },
    spirometryGraph: {
      spirometryGraphCard: {
        cardTitle: '[M]Spirometry session - Test ${testNumber}',
        cardSubTitle: '[M]started at ${sessionStartTime}',
        cardActionButton: '[M]View FAQs',
      },
    },
    sessionSummary: { sessionHeader: '[M]Session Summary', columnHeader: '[M]Test ${testNumber}' },
  },
};
