/* eslint-disable */
export const UnifyWebVideoTrainingConsentDocumentSchema = {
  title: 'UnifyWebVideoTrainingConsentDocument',
  type: 'object',
  properties: {
    title: { type: 'string', title: 'Title' },
    consentType: { title: 'Consent type', type: 'string' },
    version: { type: 'string', title: 'Version', helper: 'The version of the document', mock: '1.0' },
    versionPublishedDate: {
      type: 'string',
      title: 'Version published date',
      helper: 'Use format YYYY-MM-DD HH:MM:SS, for example: 2021-03-20 12:00:00',
      mock: '2020-03-20 12:00:00',
    },
    context: {
      type: 'array',
      title: 'Document context',
      helper: 'Document will be accessible within these contexts',
      items: { type: 'string', enum: ['PORTAL'] },
    },
    countryCodes: {
      type: 'array',
      title: 'Countries',
      helper: 'Link will only be shown for users in these countries',
      items: {
        type: 'string',
        enum: [
          'CA',
          'CN',
          'DK',
          'DE',
          'HU',
          'IL',
          'JP',
          'PL',
          'ES',
          'SE',
          'GB',
          'US',
          'BE',
          'NL',
          'SK',
          'CZ',
          'AR',
          'AT',
          'BR',
          'BG',
          'IT',
          'MX',
          'PE',
          'RU',
          'TW',
          'UA',
          'VN',
          'ZZ',
          'AU',
          'FR',
          'SW',
          'TR',
          'CL',
          'CO',
          'EG',
          'FI',
          'GR',
          'HK',
          'IE',
          'IN',
          'KR',
          'MT',
          'MY',
          'NO',
          'NZ',
          'PA',
          'PH',
          'PT',
          'RO',
          'SA',
          'SG',
          'TH',
          'VE',
          'ZA',
          'XX',
          'CR',
        ],
      },
    },
    header: { type: 'string', mock: 'Training title', placeholder: 'Training title' },
    subHeader: { type: 'string', mock: 'Training sub header', placeholder: 'Training sub header' },
    webVideo: {
      type: 'object',
      title: 'Web Video',
      properties: {
        video: {
          type: 'object',
          title: 'Web Video file',
          helper: 'Video to be played',
          _attachment: { id: 'videoUrl' },
        },
        coverImage: {
          type: 'object',
          title: 'Web Video cover image',
          helper: 'Image that will be shown while video is not playing',
          _attachment: { id: 'videoCover' },
        },
      },
      mock: {
        video: {
          type: 'object',
          title: 'Test Video',
          helper: 'Test video to be played',
          _attachment: { id: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4' },
        },
        coverImage: {
          type: 'object',
          title: 'Test cover image',
          helper: 'Test image that will be shown while video is not playing',
          _attachment: { id: 'videoCover' },
        },
      },
    },
  },
  required: ['title', 'consentType', 'version', 'context'],
  description: 'unify:webvideotrainingconsentdocument',
  $schema: 'http://json-schema.org/draft-07/schema#',
};
