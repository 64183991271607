import { Breakpoints, useMediaQuery, useTheme } from '@mui/material';
import { Breakpoint } from '@mui/system/createTheme/createBreakpoints';

/**
 * Use like
 *
 * const mdUp = useMediaQ('md', 'up');
 */
export const useMediaQ = (
  breakpoint: Breakpoint,
  scope: keyof Pick<Breakpoints, 'down' | 'up' | 'only' | 'not'>,
): boolean => {
  const { breakpoints } = useTheme();
  return useMediaQuery(breakpoints[scope](breakpoint));
};
