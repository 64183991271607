import { ApiOptions, OrgId, Patient, StateAssert } from '@csp/csp-common-model';
import { findScheduleVersionsActiveNow, ScheduleRequestInfoMapper } from '@csp/csp-common-scheduling';
import { DeviceObservationRequest } from '../model/DeviceObservationRequest';
import { DeviceObservationScheduleRequestInfo } from '../model/DeviceObservationScheduleRequestInfo';
import { DeviceDateUtil } from '../util/deviceDateUtil';
import { DeviceRequestUtil } from '../util/DeviceRequestUtil';
import { DeviceObservationScheduleService } from './DeviceObservationScheduleService';

const getActiveRequests = async (
  { firstOrgId }: Patient,
  apiOptions?: ApiOptions,
): Promise<DeviceObservationRequest[]> => {
  StateAssert.notNull(firstOrgId, 'Patient firstOrgId not set in getActiveRequests');

  const activeSchedules = await DeviceObservationScheduleService.getCachedActiveSchedules(firstOrgId, apiOptions);
  return findScheduleVersionsActiveNow(activeSchedules).flatMap(v => v.requests);
};

const getAllRequestsByOrgId = async (orgId: OrgId, apiOptions?: ApiOptions): Promise<DeviceObservationRequest[]> => {
  const requests = await DeviceObservationScheduleService.getCachedActivatedDeviceSchedulesForOrganizationIds(
    [orgId],
    apiOptions,
  );

  return requests.getRequestsByOrgId(orgId);
};

const getAllRequests = async (
  { firstOrgId }: Patient,
  apiOptions?: ApiOptions,
): Promise<DeviceObservationRequest[]> => {
  StateAssert.notNull(firstOrgId, 'Patient firstOrgId not set in getAllRequests');

  return getAllRequestsByOrgId(firstOrgId, apiOptions);
};

const getActiveRequestsWithInfo = async (
  patient: Patient,
  apiOptions?: ApiOptions,
): Promise<DeviceObservationScheduleRequestInfo[]> => {
  StateAssert.notNull(patient.firstOrgId, 'Patient firstOrgId not set in getActiveRequestsWithInfo');

  const orgSchedules = await DeviceObservationScheduleService.getCachedActivatedDeviceSchedulesForOrganizationIds(
    [patient.firstOrgId],
    apiOptions,
  );

  const scheduleRequests: DeviceObservationScheduleRequestInfo[] = ScheduleRequestInfoMapper.from(
    orgSchedules.getAllSchedules(),
    DeviceDateUtil.getCustomActionDates(patient),
    DeviceDateUtil.getWhileCriteriaValues(patient),
  );

  return DeviceRequestUtil.filterOnlyVisibleStatuses(scheduleRequests);
};

export const DeviceObservationRequestService = {
  getActiveRequests,
  getAllRequests,
  getAllRequestsByOrgId,
  getActiveRequestsWithInfo,
};
