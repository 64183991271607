import { FormControlLabel, FormHeader2, OnClick, Switch, TemporaryDrawer } from '@csp/csp-web-ui';
import * as React from 'react';
import { FC } from 'react';
import { useBoolean } from '@csp/fe-hooks';
import { isDebugCmsModeActive, toggleDebugCmsMode } from '../../../common/util/debugCmsUtil';
import { StaticContentService } from '../../../common/service/StaticContentService';
import { useCanDebugCms } from '../hook/useCanDebugCms';

type Props = {
  open: boolean;
  handleClose: OnClick;
};

type OnSwitch = (event: React.SyntheticEvent, checked: boolean) => void;

export const SettingsDrawerContainer: FC<Props> = props => {
  const debugDrawerBlock = StaticContentService.getPublicBlock('webHcpSettings', 'drawer');

  const [defaultChecked, , , toggleDefaultChecked] = useBoolean(isDebugCmsModeActive());
  const canDebugCms = useCanDebugCms();

  const switchLabel = isDebugCmsModeActive()
    ? debugDrawerBlock.switch.debugCmsMode.activeLabel
    : debugDrawerBlock.switch.debugCmsMode.inactiveLabel;

  const handleToggleCmsDebugMode: OnSwitch = () => {
    toggleDefaultChecked();
    toggleDebugCmsMode();
    window.location.reload();
  };

  return (
    <TemporaryDrawer header={debugDrawerBlock.header.text} {...props}>
      <FormHeader2>{debugDrawerBlock.header.debugCmsMode.text}</FormHeader2>

      {canDebugCms && (
        <FormControlLabel
          control={<Switch color="primary" onChange={handleToggleCmsDebugMode} defaultChecked={defaultChecked} />}
          display="block"
          label={switchLabel}
        />
      )}
    </TemporaryDrawer>
  );
};
