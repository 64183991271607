import { CspError, CspErrorType } from '@csp/csp-common-model';
import { getMessage } from '@csp/csp-common-util';
import { isObject } from 'lodash';
import { CognitoException } from '../model/CognitoException';
import { CognitoExceptionType } from '../type/CognitoExceptionType';

const authFailed = [
  CognitoExceptionType.CodeMismatchException,
  CognitoExceptionType.NotAuthorizedException,
  CognitoExceptionType.UserNotFoundException,
];
const malformed = [CognitoExceptionType.InvalidPasswordException];
const tryLater = [
  CognitoExceptionType.LimitExceededException,
  CognitoExceptionType.TooManyFailedAttemptsException,
  CognitoExceptionType.TooManyRequestsException,
];
const expired = [CognitoExceptionType.ExpiredCodeException];
const network = [CognitoExceptionType.NetworkError];

export const mapCognitoException = ({ code: errorCode, message }: CognitoException): CspError => {
  const code = errorCode as CognitoExceptionType;
  let errorType;
  if (authFailed.includes(code)) {
    errorType = CspErrorType.AUTHENTICATION_FAILED;
    // I feel like this should be its own code and so do others (see GitHub link)
    // However it isn't, and we have the requirement to handle this, thus this hacky solution
    // https://github.com/aws-amplify/amplify-js/issues/1234
    if (message && message.toLowerCase().includes('password attempts exceeded')) {
      errorType = CspErrorType.TRY_LATER;
    } else if (message && message.toLowerCase().includes('temporary password has expired')) {
      errorType = CspErrorType.EXPIRED;
    }
  } else if (malformed.includes(code)) {
    errorType = CspErrorType.MALFORMED;
  } else if (tryLater.includes(code)) {
    errorType = CspErrorType.TRY_LATER;
  } else if (expired.includes(code)) {
    errorType = CspErrorType.EXPIRED;
  } else if (network.includes(code)) {
    errorType = CspErrorType.NETWORK_ERROR;
  } else {
    errorType = CspErrorType.BAD_STATE;
  }
  return new CspError(errorType, message);
};

export const isCognitoError = (error: unknown): error is CognitoException =>
  isObject(error) && 'message' in error && 'name' in error && 'code' in error;

const formattedErrorTypes: CspErrorType[] = [
  CspErrorType.AUTHENTICATION_FAILED,
  CspErrorType.BAD_STATE,
  CspErrorType.MALFORMED,
  CspErrorType.EXPIRED,
];

export const handleCognitoError = (error: unknown): CspError =>
  isCognitoError(error) ? mapCognitoException(error) : CspError.badState(getMessage(error));

/**
 * @deprecated will no longer use this to resolve error message. Text are retrieved and resolved using some other
 *   external source, such as CMS.
 */
export const resolveCognitoErrorMessage = <E extends CspError>(error: E): string => {
  if (formattedErrorTypes.some(type => type === error.type)) {
    return error.message;
  } else if (error.type === CspErrorType.TRY_LATER) {
    return 'Password attempts exceeded, try again later';
  } else {
    return `Unexpected error: ${error.message}`;
  }
};
