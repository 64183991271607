import { PatientRescreenMetaV1 } from '../../dto/UserMetaV1';
import { PatientRescreenInfoV1 } from './PatientRescreenInfoV1';

export const PATIENT_RESCREEN_V1_META_TYPE = 'PATIENT_RESCREEN_V1';

export const createPatientRescreenMetaV1 = (patientRescreenInfoV1: PatientRescreenInfoV1): PatientRescreenMetaV1 => ({
  type: PATIENT_RESCREEN_V1_META_TYPE,
  name: PATIENT_RESCREEN_V1_META_TYPE,
  data: patientRescreenInfoV1,
});
