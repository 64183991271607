import { CspError, CspErrorType, StateAssert } from '@csp/csp-common-model';
import {
  DayOfWeek,
  Duration,
  GenericRequest,
  GenericRequestMapper,
  ReminderNotificationMetaMapper,
  RequestGroupMapper,
  RequestInitiationConfigMapper,
  RpmEventConfigMapper,
  ScheduleCode,
  ScheduleValidationService,
  RequestTagsConfigMetaMapper,
  Timing,
  UnitOfTime,
  VersionCode,
} from '@csp/csp-common-scheduling';

import {
  QuestionnaireDayOfWeekV2,
  QuestionnaireDurationV2,
  QuestionnaireRequestV2,
  QuestionnaireTimingV2,
  QuestionnaireUnitOfTimeV2,
} from '@csp/dmdp-api-questionnaire-dto';
import {
  PatientCopingTipConfigV1,
  QuestionnaireDayOfWeekV1,
  QuestionnaireDurationV1,
  QuestionnaireRequestV1,
  QuestionnaireTimingV1,
  QuestionnaireUnitOfTimeV1,
} from '@csp/dto';
import { QuestionnaireRequestMetaMapper } from '../meta/mapper/QuestionnaireRequestMetaMapper';
import { QuestionnaireRequestType } from '../type/QuestionnaireRequestType';
import { PatientCopingTipMetaMapper } from '../meta/mapper/PatientCopingTipMetaMapper';
import { QuestionnaireRequestConfigMeta } from './QuestionnaireRequestConfigMeta';
import { QuestionnaireRequestDetails } from './QuestionnaireRequestDetails';

// Omit meta and represent the meta information via domain types instead, eg QuestionnaireConfigMeta
export type QuestionnaireRequestInfo = GenericRequest<QuestionnaireRequestDetails> & {
  config: QuestionnaireRequestConfigMeta;
  patientCopingTipConfig?: PatientCopingTipConfigV1;
  questionnaireCode: string;
  requestType: QuestionnaireRequestType;
};

// Only export for testing
export const toUnitOfTime = (
  questionnaireUnitOfTime: QuestionnaireUnitOfTimeV2 | QuestionnaireUnitOfTimeV1,
): UnitOfTime => {
  switch (questionnaireUnitOfTime) {
    case QuestionnaireUnitOfTimeV2.D:
    case QuestionnaireUnitOfTimeV1.D:
      return UnitOfTime.D;
    case QuestionnaireUnitOfTimeV2.H:
    case QuestionnaireUnitOfTimeV1.H:
      return UnitOfTime.H;
    case QuestionnaireUnitOfTimeV2.MIN:
    case QuestionnaireUnitOfTimeV1.MIN:
      return UnitOfTime.MIN;
    default:
      throw CspError.badState(`Unsupported unit of time '${questionnaireUnitOfTime}'`);
  }
};

const toDuration = ({
  unit,
  value,
}: QuestionnaireDurationV2 | Omit<QuestionnaireDurationV1, '__typename'>): Duration => ({
  unit: toUnitOfTime(unit),
  value,
});

// Only export for testing
export const toDayOfWeek = (dayOfWeek: QuestionnaireDayOfWeekV2 | QuestionnaireDayOfWeekV1): DayOfWeek => {
  const dmdpDayOfWeek = dayOfWeek as QuestionnaireDayOfWeekV2;
  const dtoDayOfWeek = dayOfWeek as QuestionnaireDayOfWeekV1;
  if (
    Object.values(QuestionnaireDayOfWeekV2).includes(dmdpDayOfWeek) ||
    Object.values(QuestionnaireDayOfWeekV1).includes(dtoDayOfWeek)
  ) {
    return dayOfWeek;
  } else {
    throw CspError.badState(`Unsupported day of week '${dayOfWeek}'`);
  }
};

const toTiming = ({ windowAfter, windowBefore, repeat }: QuestionnaireTimingV2 | QuestionnaireTimingV1): Timing => ({
  windowBefore: windowBefore ? toDuration(windowBefore) : undefined,
  windowAfter: windowAfter ? toDuration(windowAfter) : undefined,
  repeat: {
    period: repeat?.period,
    periodUnit: repeat?.periodUnit ? toUnitOfTime(repeat.periodUnit) : undefined,
    frequency: repeat?.frequency,
    frequencyMax: repeat?.frequencyMax,
    timesOfDay: repeat?.timesOfDay,
    daysOfWeek: repeat?.daysOfWeek ? repeat?.daysOfWeek.map(toDayOfWeek) : undefined,
  },
});

const assertConfig = (
  requestCode: string,
  config: QuestionnaireRequestConfigMeta,
  timing: QuestionnaireTimingV2 | QuestionnaireTimingV1 | undefined,
): void => {
  if (!config.selfReport && (timing?.repeat?.frequency ?? 0) > 1) {
    throw new CspError(
      CspErrorType.CONFLICT,
      `Questionnaire request timing frequency greater than one is only allowed for self-reporting questionnaires. Request code: ${requestCode}`,
    );
  }
  if (config.selfReport && config.minComplianceThreshold) {
    throw new CspError(
      CspErrorType.CONFLICT,
      `Questionnaire request cannot not have compliance and self-reporting activated at same time . Request code: ${requestCode}`,
    );
  }
};

const evaluateQuestionnaireType = (config: QuestionnaireRequestConfigMeta): QuestionnaireRequestType => {
  if (config.selfReport) {
    return QuestionnaireRequestType.SELF_REPORT;
  } else if (config.minComplianceThreshold) {
    return QuestionnaireRequestType.COMPLIANCE;
  } else {
    return QuestionnaireRequestType.SCHEDULED;
  }
};

const from = (
  scheduleCode: ScheduleCode,
  versionCode: VersionCode,
  {
    requestCode,
    details,
    startAction,
    endAction,
    meta,
    timing,
    while: requestWhile,
  }: QuestionnaireRequestV2 | QuestionnaireRequestV1,
  strict?: boolean,
): QuestionnaireRequestInfo => {
  StateAssert.notNull(details, `Details must be set. RequestCode: ${requestCode}`);
  const config = QuestionnaireRequestMetaMapper.toConfigFromMetaV1(requestCode, meta);
  const patientCopingTipConfig = PatientCopingTipMetaMapper.toPatientCopingTipConfigFromMetaV1(meta);
  const notificationConfig = ReminderNotificationMetaMapper.toNotificationConfigFromMetaV1(meta);
  const initiationConfig = RequestInitiationConfigMapper.fromRequestInitiationConfigMetaV1(meta);
  const requestGroupConfig = RequestGroupMapper.toRequestGroupMemberships(meta, strict);
  const rpmEventConfig = RpmEventConfigMapper.fromRpmEventConfigMetaV1(meta);
  const tagsConfig = RequestTagsConfigMetaMapper.fromRequestTagsConfigMetaV1(meta);

  assertConfig(requestCode, config, timing);

  const request: QuestionnaireRequestInfo = {
    requestCode,
    versionCode,
    scheduleCode,
    questionnaireCode: details?.questionnaireCode ?? '',
    details: details ? QuestionnaireRequestDetails.from(details) : undefined,
    startAction: {
      fixedAction: startAction?.customAction
        ? GenericRequestMapper.customActionDateToFixedAction(startAction?.customAction)
        : GenericRequestMapper.toFixedAction(startAction?.fixedAction),
      // TODO Sebastian: Remove if it is no longer needed by the app
      customAction: startAction?.customAction ? startAction.customAction : undefined,
      offset: startAction?.offset ? toDuration(startAction.offset) : undefined,
      fixedTime: startAction?.customAction
        ? GenericRequestMapper.customActionTimeToFixedTime(startAction.customAction)
        : startAction?.fixedTime,
    },
    endAction: {
      fixedAction: endAction?.customAction
        ? GenericRequestMapper.customActionDateToFixedAction(endAction?.customAction)
        : GenericRequestMapper.toFixedAction(endAction?.fixedAction),
      // TODO Sebastian: Remove if it is no longer needed by the app
      customAction: endAction?.customAction ? endAction.customAction : undefined,
      offset: endAction?.offset ? toDuration(endAction.offset) : undefined,
      fixedTime: endAction?.customAction
        ? GenericRequestMapper.customActionTimeToFixedTime(endAction.customAction)
        : endAction?.fixedTime,
    },
    config,
    while: GenericRequestMapper.toRequestWhile(requestWhile),
    timing: timing ? toTiming(timing) : undefined,
    requestType: evaluateQuestionnaireType(config),
    notificationConfig,
    initiationConfig,
    patientCopingTipConfig,
    groups: requestGroupConfig,
    rpmEventConfig,
    tagsConfig,
  };

  ScheduleValidationService.validateRequest(request);
  return request;
};

export const QuestionnaireRequestInfo = {
  from,
};
