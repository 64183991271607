import { parseJsonWithBigInt } from '@csp/csp-common-axios';
import { RestOptions } from '@csp/csp-common-model';
import { DeviceSessionAggregateResultV1, DeviceSessionAggregateV1 } from '@csp/dmdp-api-device-dto';
import { devicePodUrl } from '../../dmdp-config/dmdpConfig';

const deviceSessionsUrl = (): string => `${devicePodUrl()}/device/v1/sessions`;

const aggregate = async (
  { axios, signal }: RestOptions,
  aggregateCriteria: DeviceSessionAggregateV1,
): Promise<DeviceSessionAggregateResultV1> => {
  const { data } = await axios.post<DeviceSessionAggregateResultV1>(
    `${deviceSessionsUrl()}:aggregate`,
    aggregateCriteria,
    {
      transformResponse: parseJsonWithBigInt,
      signal,
    },
  );

  return data;
};

export const DeviceSessionAggregationRestServiceV1 = {
  aggregate,
};
