export class HttpStatusConst {
  static NOT_MODIFIED = 304;

  // Client errors
  static BAD_REQUEST = 400;
  static UNAUTHORIZED = 401;
  static FORBIDDEN = 403;
  static NOT_FOUND = 404;
  static CONFLICT = 409;
  static GONE = 410;

  // Server errors
  static INTERNAL_SERVER_ERROR = 500;
  static SERVICE_UNAVAILABLE = 503;
}
