import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../common/hooks/useAppDispatch';
import { useConsentContext } from '../../consent/context/hook/useConsentContext';
import { shouldApproveConsentsSelector } from '../../consent/ducks/consentPermissionSelector';
import { loginPrincipalThunk } from '../../security/ducks/principalContextActions';
import { getStudyDcodeSelector, isLoggedInSelector } from '../../security/ducks/principalContextSelectors';
import { loadStudyConfigThunk, resetStudyConfigAction } from '../../study-config/ducks/studyConfigActions';
import { studiesThunk } from '../ducks/studiesActions';
import { getSelectedStudyIdSelector } from '../ducks/studiesSelectors';
import { useAutomaticLogoutContext } from '../../security/context/hook/useAutomaticLogoutContext';
import { useAuthContext } from '../../auth/context/hook/useAuthContext';

const useFetchStudyConfig = (): void => {
  const dispatch = useAppDispatch();
  const dcode = useSelector(getStudyDcodeSelector);

  useEffect(() => {
    if (dcode) {
      dispatch(loadStudyConfigThunk());
    } else {
      dispatch(resetStudyConfigAction());
    }
  }, [dispatch, dcode]);
};

export const useFetchAndSelectStudy = (selectedStudyId?: string): void => {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAuthContext();
  const isLoggedInOnStudy = useSelector(isLoggedInSelector);
  const { lastSelectedStudyId } = useAutomaticLogoutContext();
  const {
    approvals,
    fetchConsentApprovals,
    hasPendingApprovals: hasPendingConsentApprovals,
    error: consentsError,
  } = useConsentContext();
  const shouldApproveConsents = useSelector(shouldApproveConsentsSelector);

  const tenantId = selectedStudyId || lastSelectedStudyId;

  useEffect(() => {
    if (isAuthenticated && tenantId && !isLoggedInOnStudy) {
      dispatch(loginPrincipalThunk({ tenantId }));
    }
  }, [dispatch, isAuthenticated, isLoggedInOnStudy, selectedStudyId, tenantId]);

  useEffect(() => {
    if (isAuthenticated && !selectedStudyId) {
      dispatch(studiesThunk());
    }
  }, [dispatch, selectedStudyId, isAuthenticated]);

  useEffect(() => {
    (async (): Promise<void> => {
      if (shouldApproveConsents && isEmpty(approvals) && !consentsError) {
        await fetchConsentApprovals();
      }
    })();
  }, [approvals, consentsError, fetchConsentApprovals, hasPendingConsentApprovals, shouldApproveConsents]);

  useFetchStudyConfig();
};

export const useFetchStudiesAndLogin = (): void => {
  const selectedStudyId = useSelector(getSelectedStudyIdSelector);
  useFetchAndSelectStudy(selectedStudyId);
};
