export type AuthContextState = {
  isAuthenticated: boolean;
  isNotAuthenticated: boolean;
};

const emptyState: AuthContextState = {
  isAuthenticated: false,
  isNotAuthenticated: true,
};

export const AuthContextState = {
  emptyState,
};
