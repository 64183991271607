import { Maybe, ObjectType } from '@csp/csp-common-model';
import { DeviceMetricsConfig } from '../model/DeviceMetricsConfig';
import { DeviceMetricsConfigMetaV1 } from '../model/DeviceMetricsConfigMetaV1';

const fromRequestMetricsConfigsMetaV1 = (
  meta: DeviceMetricsConfigMetaV1 | Maybe<ObjectType>,
): Maybe<DeviceMetricsConfig> => {
  const configV1 = meta?.['METRICS_CONFIGURATION_V1'];
  if (configV1) {
    return configV1 as DeviceMetricsConfig;
  } else {
    return undefined;
  }
};

export const DeviceMetricsConfigMapper = {
  fromRequestMetricsConfigsMetaV1,
};
