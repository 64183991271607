//import { createLogger } from '@csp/csp-be-util';
import { CacheKey, MemCacheService } from '@csp/csp-common-memcache';
import { ApiOptions, Maybe, OrgId } from '@csp/csp-common-model';
import { AsyncLock } from '@csp/csp-common-util';
import { OrgSchedules } from '../model/OrgSchedules';
import { OrgsSchedules } from '../model/OrgsSchedules';
import { GenericRequest } from '../model/schedulingModels/GenericRequest';
import { GenericSchedule } from '../model/schedulingModels/GenericSchedule';

const Q_SCHEDULES_CACHE_KEY = ['ScheduleCache', 'schedules'];
const CACHE_TTL_SECS = 3600;

const asyncLock = AsyncLock.from();

// TODO: perhaps enable logging here?
//const logger = createLogger('ScheduleCachedService');

const getAllActivatedSchedulesForOrganizationIds = async <T extends GenericSchedule, S extends GenericRequest>(
  cacheSuffix: string,
  organizationIds: OrgId[],
  fetchFunc: (orgIds: OrgId[], apiOptions?: ApiOptions) => Promise<OrgsSchedules<T, S>>,
  apiOptions?: ApiOptions,
): Promise<OrgsSchedules<T, S>> => {
  const orgSchedules: OrgSchedules<T>[] = [];
  const missingOrgIds: OrgId[] = [];
  const pendingCacheKeys: OrgId[] = [];

  const getCacheKey = (orgId: OrgId): string => CacheKey.from([...Q_SCHEDULES_CACHE_KEY, cacheSuffix, orgId]);
  const getCacheValue = (orgId: OrgId): Maybe<OrgSchedules<T>> =>
    MemCacheService.getValue<OrgSchedules<T>>(getCacheKey(orgId));

  organizationIds.forEach(id => {
    // check if its cached
    const schedules = getCacheValue(id);
    if (schedules) {
      orgSchedules.push(schedules);
    } else if (asyncLock.isPending(getCacheKey(id))) {
      pendingCacheKeys.push(getCacheKey(id));
    } else {
      missingOrgIds.push(id);
    }
  });

  const missingCacheKeys = missingOrgIds.map(orgId => getCacheKey(orgId));
  if (missingOrgIds.length) {
    await asyncLock.acquire(missingCacheKeys, async (): Promise<void> => {
      try {
        const fetchOrgSchedules = await fetchFunc(missingOrgIds, apiOptions);

        fetchOrgSchedules.orgSchedules.forEach(orgSchedule => {
          const cacheKey = [...Q_SCHEDULES_CACHE_KEY, cacheSuffix, orgSchedule.orgId];
          MemCacheService.setValue(cacheKey, CACHE_TTL_SECS, orgSchedule);
        });
      } catch (e) {
        //logger.error(`Error fetching schedules for missing orgIds: ${missingOrgIds.join(',')}`, e);
      }
    });
  }

  const remainingCacheKeys = [...missingCacheKeys, ...pendingCacheKeys];
  await asyncLock.waitForKeys(remainingCacheKeys);

  remainingCacheKeys.forEach(cacheKey => {
    const schedules = MemCacheService.getValue<OrgSchedules<T>>(cacheKey);
    if (schedules) {
      orgSchedules.push(schedules);
    }
  });

  return OrgsSchedules.from<T, S>(orgSchedules);
};

export const ScheduleCachedService = {
  getAllActivatedSchedulesForOrganizationIds,
};
