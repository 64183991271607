import { HttpHeaderConst } from '@csp/dmdp-api-common-dto';
import { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import {
  createAxios,
  handleAxiosError,
  handleAxiosErrorOrThrow,
  handleNotAuthorizedOrThrow,
  HttpStatusConst,
} from '@csp/csp-common-axios';
import { notModifiedErrorResponseInterceptor } from '../util/notModifiedErrorResponseInterceptor';
import { DmdpTokenService } from './DmdpTokenService';
class AxiosDmdpService {
  static instance: AxiosDmdpService = new AxiosDmdpService();
  axiosInstance: AxiosInstance;

  private constructor() {
    this.axiosInstance = this.dmdp();
  }

  private dmdp = (retry = true): AxiosInstance => {
    const dmdpAxios = createAxios();
    dmdpAxios.interceptors.request.use(
      config => this.configWithDmdpToken(config),
      async (error: AxiosError) => this.handleDmdpRetry(error, retry),
    );
    dmdpAxios.interceptors.response.use((resp: AxiosResponse) => resp, notModifiedErrorResponseInterceptor);
    dmdpAxios.interceptors.response.use(
      (resp: AxiosResponse) => resp,
      async (error: AxiosError) => this.handleDmdpRetry(error, retry),
    );
    return dmdpAxios;
  };

  /**
   * If a 4XX error happens during re-login, the user has been removed from the study, something the client needs
   * to know about.
   */
  private handleDmdpRetry = async (error: AxiosError, retry: boolean): Promise<AxiosResponse> => {
    if (error.config && error.response?.status === HttpStatusConst.UNAUTHORIZED && retry) {
      try {
        await DmdpTokenService.reLogin();
      } catch (err) {
        return handleAxiosErrorOrThrow(err);
      }
      try {
        const newConfig = await this.configWithDmdpToken(error.config);
        return this.dmdp(false).request(newConfig);
      } catch (err) {
        return handleNotAuthorizedOrThrow(err);
      }
    } else {
      return handleAxiosError(error);
    }
  };

  private configWithDmdpToken = async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
    try {
      const token = (await DmdpTokenService.getTokenAsync()).getJwtBase64();
      config.headers[HttpHeaderConst.AUTHORIZATION] = `BEARER ${token}`;
    } catch (e) {
      console.error(e);
    }

    return config;
  };
}

export const axiosDmdp = (): AxiosInstance => AxiosDmdpService.instance.axiosInstance;
