import {
  BioSampleCodeFormatter,
  BioSampleCodeFormatType,
  BioSampleCodeInputError,
  BioSampleCodeInputValidator,
  BioSampleRef,
} from '@csp/csp-common-hbs-model';
import { Maybe } from '@csp/csp-common-model';
import { isDefined } from '@csp/csp-common-util';
import { SampleContainerNumber } from '@csp/dmdp-api-hbs-dto';
import { BioSampleCodeFormatterUtil } from '../util/BioSampleCodeFormatterUtil';

const from = (supportedBioSampleIdFormats: BioSampleCodeFormatType[]): BioSampleCodeFormatter => {
  const bioSampleCodeValidators = supportedBioSampleIdFormats.map(format =>
    BioSampleCodeFormatterUtil.createBioSampleCodeValidator(format),
  );
  const bioSampleKitCodeValidators = supportedBioSampleIdFormats.map(format =>
    BioSampleCodeFormatterUtil.createBioSampleKitCodeValidator(format),
  );
  const bioSampleContainerNumberValidators = supportedBioSampleIdFormats.map(format =>
    BioSampleCodeFormatterUtil.createBioSampleContainerNumberValidator(format),
  );

  const parseBioSampleCode = (sampleCodeIn: string): Maybe<BioSampleRef> =>
    BioSampleCodeFormatterUtil.parseBioSampleCode(sampleCodeIn, supportedBioSampleIdFormats);

  const parseBioSampleCodeOrError = (sampleCodeIn: string): BioSampleRef =>
    BioSampleCodeFormatterUtil.parseBioSampleCodeOrError(sampleCodeIn, supportedBioSampleIdFormats);

  const handleBioSampleIdentifierValidationResult = ({
    inputErrors,
  }: {
    inputErrors: Maybe<BioSampleCodeInputError>[];
  }): Maybe<BioSampleCodeInputError> => {
    if (inputErrors.some(error => !error)) {
      return undefined;
    } else {
      return inputErrors.filter(isDefined)[0];
    }
  };

  const validateBioSampleCode: BioSampleCodeInputValidator = (sampleCodeIn, errorMessages) => {
    const inputErrors = bioSampleCodeValidators.map(validator => validator(sampleCodeIn, errorMessages));
    return handleBioSampleIdentifierValidationResult({ inputErrors });
  };

  const validateBioSampleKitCode: BioSampleCodeInputValidator = (sampleKitCodeIn, errorMessages) => {
    const inputErrors = bioSampleKitCodeValidators.map(validator => validator(sampleKitCodeIn, errorMessages));
    return handleBioSampleIdentifierValidationResult({ inputErrors });
  };

  const validateBioSampleContainerNumber: BioSampleCodeInputValidator = (sampleContainerNumberIn, errorMessages) => {
    const inputErrors = bioSampleContainerNumberValidators.map(validator =>
      validator(sampleContainerNumberIn, errorMessages),
    );
    return handleBioSampleIdentifierValidationResult({ inputErrors });
  };

  const formatBioSampleContainerNumberIn = (containerNumberIn: SampleContainerNumber): SampleContainerNumber =>
    BioSampleCodeFormatterUtil.formatBioSampleContainerNumberIn(containerNumberIn, supportedBioSampleIdFormats);

  return {
    parseBioSampleCode,
    parseBioSampleCodeOrError,
    validateBioSampleCode,
    validateBioSampleKitCode,
    validateBioSampleContainerNumber,
    formatBioSampleContainerNumberIn,
  };
};

export const BioSampleCodeFormatterMapper = { from };
