import { studyService } from '@csp/common';
import { Study } from '@csp/csp-common-model';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toError } from '@csp/csp-common-util';

export const studiesThunk = createAsyncThunk<Study[], undefined, { rejectValue: Error }>(
  'studies',
  async (_, { rejectWithValue }) => {
    try {
      return await studyService.getMyStudies();
    } catch (error) {
      return rejectWithValue(toError(error));
    }
  },
);

export const setSelectedStudyId = createAction<string>('set_selected_study_id');

export const clearSelectedStudyId = createAction('clear_selected_study_id');
