import joi from 'joi';
import { CspError, Maybe, ObjectType } from '@csp/csp-common-model';
import { ScheduleConfig } from '../model/ScheduleConfig';
import { ScheduleConfigMetaV1, SCHEDULE_CONFIG_V1_META_NAME } from '../meta/ScheduleConfigMetaV1';
import { ScheduleConfigV1 } from '../meta/ScheduleConfigV1';

const scheduleConfigV1Schema = joi.object({
  groupByScheduleCode: joi.boolean().required(),
});
const scheduleConfigMetaV1Schema = joi
  .object<ScheduleConfigMetaV1>({
    [SCHEDULE_CONFIG_V1_META_NAME]: scheduleConfigV1Schema.optional(),
  })
  .optional();

const fromScheduleConfigV1 = (scheduleConfigV1: Maybe<ScheduleConfigV1>): ScheduleConfig =>
  scheduleConfigV1 ?? ScheduleConfig.default;

const from = (meta?: ObjectType, strict = false): ScheduleConfig => {
  const { value, error } = scheduleConfigMetaV1Schema.validate(meta, { stripUnknown: true, convert: false });
  if (error) {
    if (strict) {
      throw CspError.badState(`ScheduleConfig mapping error: ${error.message}`);
    } else {
      return ScheduleConfig.default;
    }
  } else if (value) {
    return fromScheduleConfigV1(value[SCHEDULE_CONFIG_V1_META_NAME]);
  } else {
    return ScheduleConfig.default;
  }
};

export const ScheduleConfigMapper = { from };
