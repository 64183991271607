import { EntityState } from '@reduxjs/toolkit';
import { CmsContentArguments } from '@csp/csp-fe-content';
import { CmsStoreEntity } from '../model/CmsStoreEntity';
import { CommonStoreState } from '../../../system/redux/ducks';
import { cmsAdapter } from './cmsAdapter';
import { getCmsKey } from './cmsUtils';

const adapterSelectors = cmsAdapter.getSelectors();

export type CmsState = EntityState<CmsStoreEntity>;

export const getCmsState = (state: CommonStoreState): CmsState => state?.cms;
export const getContentForQuery = (state: CmsState, query: CmsContentArguments): CmsStoreEntity | undefined =>
  adapterSelectors.selectById(state, getCmsKey(query));
