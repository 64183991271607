import { StudyDefinedStatusesV1 } from '@csp/config-schemas';
import {
  CustomStatusMutation,
  CustomStatusType,
  CustomStatusValueLookup,
  Maybe,
  StateAssert,
  StatusValue,
  StudyDefinedStatusConfig,
  StudyDefinedStatusesConfig,
} from '@csp/csp-common-model';
import { StudyDefinedStatusConfigMapper } from './StudyDefinedStatusConfigMapper';

const MAX_MUTATION_RECURSIONS = 20;

const fromV1 = (studyDefinedV1: Maybe<StudyDefinedStatusesV1>): StudyDefinedStatusesConfig => {
  const configByType: Map<CustomStatusType, StudyDefinedStatusConfig> = new Map();

  if (studyDefinedV1) {
    studyDefinedV1.statuses.map(StudyDefinedStatusConfigMapper.fromV1).forEach(statusConfig => {
      configByType.set(statusConfig.type, statusConfig);
    });
  }

  const configAsArray = (): StudyDefinedStatusConfig[] => Array.from(configByType.values());

  const getByType = (type: CustomStatusType): StudyDefinedStatusConfig => {
    const config = configByType.get(type);
    StateAssert.notNull(
      config,
      `Study defined status not configured. Got: ${type}. Available: ${Array.from(configByType.keys()).join(', ')}`,
    );
    return config;
  };

  const isTypeConfigured = (type: CustomStatusType): boolean => !!configByType.get(type);

  const isTypeAndValueConfigured = (type: CustomStatusType, value: StatusValue): boolean =>
    !!configByType.get(type)?.isValueConfigured(value);

  const getNextValues = (type: CustomStatusType, currentStatuses: CustomStatusValueLookup): StatusValue[] =>
    getByType(type).getNextValues(currentStatuses);

  const getNextValuesForManualUpdate = (
    type: CustomStatusType,
    currentStatuses: CustomStatusValueLookup,
  ): StatusValue[] => getByType(type).getNextValuesForManualUpdate(currentStatuses) ?? [];

  const isTransitionAllowed = (
    type: CustomStatusType,
    newValue: StatusValue,
    currentStatuses: CustomStatusValueLookup,
  ): boolean => getByType(type).isTransitionAllowed(newValue, currentStatuses) ?? false;

  const isManualTransitionAllowed = (
    type: CustomStatusType,
    newValue: StatusValue,
    currentStatuses: CustomStatusValueLookup,
  ): boolean => getByType(type).isManualTransitionAllowed(newValue, currentStatuses) ?? false;

  const getStatusConfigsToBeShown = (currentStatuses: CustomStatusValueLookup): StudyDefinedStatusConfig[] =>
    configAsArray().filter(config => config.isShowStatus(currentStatuses));

  // NOTE: Recursive calls until all mappings are done.
  // Needed to not be dependent of config order and also support multiple updates on same type of status
  const mutateIfNeededInternal = (
    mutation: CustomStatusMutation,
    ignoreTransitionRules: boolean,
    count: number,
  ): void => {
    StateAssert.isTrue(
      count < MAX_MUTATION_RECURSIONS,
      'Max number of patient status updates has been added. Please review the configuration',
    );
    configAsArray().forEach(config => {
      const isMutated = config.mutateIfNeedAndAbortAfterFirstMutation(mutation, ignoreTransitionRules);
      if (isMutated) {
        mutateIfNeededInternal(mutation, ignoreTransitionRules, ++count);
      }
    });
  };

  const mutateIfNeeded = (mutation: CustomStatusMutation, ignoreTransitionRules = false): void =>
    mutateIfNeededInternal(mutation, ignoreTransitionRules, 0);

  return {
    hasAnyStatuses: !!configByType.size,
    isTypeConfigured,
    isTypeAndValueConfigured,
    getNextValues,
    getNextValuesForManualUpdate,
    isTransitionAllowed,
    isManualTransitionAllowed,
    getStatusConfigsToBeShown,
    mutateIfNeeded,
  };
};

export const StudyDefinedStatusesConfigMapper = {
  fromV1,
};
