import { Body2, H4, H6, TemporaryDrawer, useMediaQ } from '@csp/csp-web-ui';
import { useBoolean } from '@csp/fe-hooks';
import { FC } from 'react';
import { StaticContentService } from '../../../common/service/StaticContentService';
import { AppsIconButton } from '../../navigation/component/HeaderNavToolsIcons';
import { SystemLinkItem } from '../component/SystemLinkItem';
import { useLocalizedSystemLinksByRole } from '../hook/useLocalizedSystemLinksByRole';
import { SystemLink } from '../model/SystemLink';

export const SystemLinksDrawerContainer: FC = () => {
  const linkDrawer = StaticContentService.getGenericBlock('webHcpLinkDrawer', 'drawer');
  const localizedSystemLinks = useLocalizedSystemLinksByRole();
  const [isOpen, setOpen, setClose] = useBoolean();
  const smDown = useMediaQ('sm', 'down');

  return (
    <>
      <TemporaryDrawer
        handleClose={setClose}
        header={linkDrawer.header.text}
        open={isOpen}
        onClose={setClose}
        id="system-link-drawer"
      >
        {localizedSystemLinks?.length ? (
          <Body2 color="textSecondary" mb={5}>
            {linkDrawer.body.text}
          </Body2>
        ) : null}

        {localizedSystemLinks?.map(({ systemName, description, url }: SystemLink, index: number) => (
          <SystemLinkItem
            systemName={systemName}
            description={description}
            url={url}
            buttonLabel={linkDrawer.button.label}
            key={index}
            linkProps={{ target: '_blank', rel: 'noopener noreferrer' }}
          />
        ))}

        {smDown ? (
          <H6 mb={3} mt={2}>
            {linkDrawer.header.general.text}
          </H6>
        ) : (
          <H4 mb={5} mt={4}>
            {linkDrawer.header.general.text}
          </H4>
        )}

        <SystemLinkItem
          systemName={linkDrawer.header.cookiePolicy.text}
          url={linkDrawer.body.cookiePolicy.url}
          description={linkDrawer.body.cookiePolicy.text}
          buttonLabel={linkDrawer.button.label}
          linkProps={{ className: 'CookieReportsLink' }}
        />
      </TemporaryDrawer>
      <AppsIconButton onClick={setOpen} data-testid="system-link-drawer-button" />
    </>
  );
};
