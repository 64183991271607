import { RandomString } from '@csp/csp-common-util';
import { Component, ComponentType } from 'react';
import { ErrorService } from '../../service/ErrorService';

export type ErrorComponentProps = {
  errorId?: string;
};

type Props = {
  ErrorComponent: ComponentType<ErrorComponentProps>;
  children?: React.ReactNode;
};

type State = {
  errorId: string;
};

export class ErrorBoundary extends Component<Props, State> {
  state: State = { errorId: '' };

  componentDidCatch = (error: Error): void => {
    const errorId = RandomString.ofLength(8);
    this.setState({ errorId });
    ErrorService.handleError({ error, errorId });
  };

  render = (): React.ReactNode => {
    const { children, ErrorComponent } = this.props;
    const { errorId } = this.state;

    return errorId ? <ErrorComponent errorId={errorId} /> : children;
  };
}
