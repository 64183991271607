export enum BioSampleProcessedValueType {
  CDNA_MICROARRAY = 'CDNA_MICROARRAY',
  CELL_LYSATE = 'CELL_LYSATE',
  CELL_MICROARRAY = 'CELL_MICROARRAY',
  CELL_PELLET = 'CELL_PELLET',
  CELL_SUPENSION = 'CELL_SUPENSION',
  CELL_SUPERNATANT = 'CELL_SUPERNATANT',
  CYTOSPIN_PREPARATION = 'CYTOSPIN_PREPARATION',
  DNA_MICROARRAY = 'DNA_MICROARRAY',
  FFPE_TISSUE_BLOCK = 'FFPE_TISSUE_BLOCK',
  FFPE_TISSUE_BLOCK_SHAVINGS = 'FFPE_TISSUE_BLOCK_SHAVINGS',
  FFPE_TISSUE_MICROARRAY = 'FFPE_TISSUE_MICROARRAY',
  FFPE_TISSUE_SAMPLE = 'FFPE_TISSUE_SAMPLE',
  FFPE_TISSUE_SLIDE = 'FFPE_TISSUE_SLIDE',
  FRESH_DISSOCIATED_TISSUE_SAMPLE = 'FRESH_DISSOCIATED_TISSUE_SAMPLE',
  H_AND_E_STAINED_FFPE_TISSUE_SLIDE = 'H_AND_E_STAINED_FFPE_TISSUE_SLIDE',
  IHC_STAINED_FFPE_TISSUE_SLIDE = 'IHC_STAINED_FFPE_TISSUE_SLIDE',
  MICROSOMES = 'MICROSOMES',
  OCT_EMBEDDED_TISSUE_SAMPLE = 'OCT_EMBEDDED_TISSUE_SAMPLE',
  OCT_TISSUE_BLOCK = 'OCT_TISSUE_BLOCK',
  OCT_TISSUE_SLIDE = 'OCT_TISSUE_SLIDE',
  PERIPHERAL_BLOOD_MONONUCLEAR_CELL = 'PERIPHERAL_BLOOD_MONONUCLEAR_CELL',
  PLASMA = 'PLASMA',
  PROTEIN_MICROARRAY = 'PROTEIN_MICROARRAY',
  SERUM = 'SERUM',
  SINGLE_TISSUE_CORE_FROM_TISSUE_MICROARRAY_TMA_SLIDE = 'SINGLE_TISSUE_CORE_FROM_TISSUE_MICROARRAY_TMA_SLIDE',
  SORTED_CELLS = 'SORTED_CELLS',
  UNSTAINED_FFPE_TISSUE_SLIDE = 'UNSTAINED_FFPE_TISSUE_SLIDE',
  UNSTAINED_OCT_EMBEDDED_TISSUE_SLIDE = 'UNSTAINED_OCT_EMBEDDED_TISSUE_SLIDE',
  UNKNOWN = 'UNKNOWN',
}
