import {
  differenceInCalendarDays,
  fromTimezoneStr,
  fromTimezoneStrOrUndefined,
  nowToZonedDateTimeCurrentZone,
} from '@csp/csp-common-date-util';
import {
  DataChangeDomain,
  DataChangeItem,
  DataChangeReason,
  DataChangeSource,
  DataChangeStatus,
  DataChangeType,
} from '@csp/csp-common-dcf-model';
import { EnumUtil } from '@csp/csp-common-enum-util';
import { CspError, Patient, StateAssert, Study } from '@csp/csp-common-model';
import { DataChangeInV1, DataChangeV1 } from '@csp/dmdp-api-data-change-dto';
import { DataSourceIn } from '../model/DataSourceIn';
import { generateDcRef } from '../util/GenerateDcRef';

const toDataChangeItem = (dc: DataChangeV1, study: Study): DataChangeItem => {
  const {
    domain,
    dataSourceGroup,
    dcRef,
    reasonForChange,
    typeOfChange,
    reviewStatus,
    createdAt: createdAtProp,
    reviewedAt,
    dataSource,
    dataSourceId,
    reviewedBy,
    createdBy,
    dcId,
    eCode,
    studySiteId,
    userId,
    evidenceForChangeExists,
  } = dc;

  const createdAt = fromTimezoneStr(createdAtProp);
  const orgId = study.getSiteByCtmsId(studySiteId)?.orgId;

  StateAssert.isDefined(orgId, 'orgId is undefined');

  return {
    dataName: dataSourceGroup,
    domain: EnumUtil.fromStringOrError(domain, DataChangeDomain),
    reasonForChange: EnumUtil.fromStringOrError(reasonForChange, DataChangeReason),
    typeOfChange: EnumUtil.fromStringOrError(typeOfChange, DataChangeType),
    status: EnumUtil.fromStringOrDefault(reviewStatus, DataChangeStatus, DataChangeStatus.UNKNOWN),
    isPending: reviewStatus === DataChangeStatus.PENDING_REVIEW,
    dataChangeReference: dcRef,
    isReviewed: reviewStatus === DataChangeStatus.REVIEWED,
    reviewedAt: fromTimezoneStrOrUndefined(reviewedAt),
    createdAt,
    daysSinceCreated: differenceInCalendarDays(nowToZonedDateTimeCurrentZone(), createdAt),
    dataSource: EnumUtil.fromStringOrError(dataSource, DataChangeSource),
    dataSourceId,
    reviewedBy,
    createdBy,
    id: dcId,
    ecode: eCode,
    siteId: orgId,
    patientId: userId,
    hasEvidence: !!evidenceForChangeExists,
  };
};

const toDataChangeItems = (dcs: DataChangeV1[], study: Study): DataChangeItem[] =>
  dcs.map(dc => toDataChangeItem(dc, study));

const toDataChangeInV1 = (
  patient: Patient,
  study: Study,
  typeOfChange: DataChangeType,
  reasonForChange: DataChangeReason,
  evidenceForChangeExists: boolean,
  dataSourceIn: DataSourceIn,
): DataChangeInV1 => {
  const studySiteId = patient.firstOrgId ? study.getSiteById(patient.firstOrgId)?.ctmsId : undefined;

  if (studySiteId) {
    return {
      dcRef: generateDcRef(patient.ecode),
      reviewStatus: DataChangeStatus.PENDING_REVIEW,
      studySiteId,
      userId: patient.userId,
      eCode: patient.ecode,
      typeOfChange,
      reasonForChange,
      evidenceForChangeExists,
      ...dataSourceIn,
    };
  } else {
    throw CspError.badState(`Got patient without site Id. PatientId: ${patient.userId}`);
  }
};

export const DataChangeMapper = {
  toDataChangeItem,
  toDataChangeItems,
  toDataChangeInV1,
};
