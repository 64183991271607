import { PaletteOptions } from '@mui/material/styles/createPalette';

export const paletteOptions: PaletteOptions = {
  common: {
    white: '#FFFFFF',
    black: '#000000',
  },
  primary: {
    main: '#D0006F',
    light: '#FEC3E6',
  },
  secondary: {
    light: '#E6CCDC',
    main: '#830051',
  },
  error: {
    main: '#EC0E0E',
    light: '#FBE6E3',
    dark: '#691C11',
  },
  warning: {
    main: '#FF9800',
    light: '#FFECD0',
    dark: '#704400',
  },
  info: {
    main: '#2270EC',
    light: '#DEECFF',
    dark: '#10456E',
  },
  success: {
    main: '#40A862',
    light: '#EBF3EE',
    dark: '#264F20',
  },
  pending: {
    main: '#5435DF',
  },
  inactive: {
    main: '#B3B3B3',
  },
  text: {
    primary: '#000000',
    secondary: '#656969',
  },
  grey: {
    50: '#F7F7F7',
    100: '#F5F5F5',
    200: '#EBECEC',
    300: '#DDE0E2',
    400: '#CED0D0',
    500: '#656969',
    600: 'rgba(0,0,0,0.38)',
    700: '#767676',
  },
  divider: '#BDBDBD',
  icon: {
    dark: '#3C3C3C',
    main: '#3C4242',
    disabled: '#BDBDBD',
  },
  alwaysSelected: 'rgba(208, 0, 111, 0.5)',
  transparent: {
    grey: 'rgba(60, 66, 66 , 0.1)',
    default: 'rgba(0, 0, 0, 0.0)',
    white: 'rgba(255,255,255, 0.6)',
  },
  action: {
    hover: 'rgba(208, 0, 111, 0.04)',
    selected: 'rgba(0, 0, 0, 0)',
  },
  background: {
    default: '#F7F7F7',
  },
  graph: {
    grey: {
      main: 'rgba(0, 0, 0, 0.11)',
    },
    blue: {
      light: '#9CCEFF',
      main: '#003865',
      dark: '#00123A',
    },
    teal: {
      light: '#C7E6EB',
      main: '#009CB3',
      dark: '#006D83',
    },
    yellow: {
      light: '#FAEDD3',
      main: '#FFBE3E',
      dark: '#C88E00',
    },
    orange: {
      light: '#F9E1D7',
      main: '#F88351',
      dark: '#C05425',
    },
    green: {
      main: '#B1B401',
      dark: '#264F20',
    },
    violet: {
      main: '#5435DF',
      light: '#ECE8FF',
    },
    brown: {
      main: '#704400',
    },
    background: {
      main: '#FCF2F8',
    },
    stroke: {
      main: '#EBECEC',
      dark: '#3C4242',
    },
  },
};
