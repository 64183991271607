import { toTimezoneStr } from '@csp/csp-common-date-util';
import { StateAssert, UserId } from '@csp/csp-common-model';
import { ScheduleRef } from '@csp/csp-common-scheduling';
import {
  QuestionnaireResponseAggregateCriteriaFieldV2,
  QuestionnaireResponseAggregateGroupFieldV2,
  QuestionnaireResponseAggregateIncludeFieldV2,
  QuestionnaireResponseAggregateQueryCriterionV2,
  QuestionnaireResponseAggregateQueryOperatorV2,
  QuestionnaireResponseAggregateQueryTypeV2,
  QuestionnaireResponseAggregateV2,
  QuestionnaireResponseStatusType,
} from '@csp/dmdp-api-questionnaire-dto';
import { groupBy, uniq } from 'lodash';
import { ScheduleRequestDateUntilCriteria } from '../../model/ScheduleRequestDateUntilCriteria';
import { ScheduleRequestMetricCriterion } from '../../model/ScheduleRequestMetricCriterion';

type ScheduleCodeGroup = {
  scheduleCode: string;
  requestCodes: string[];
};

const toRequestRefCriteria = ({
  scheduleCode,
  requestCodes,
}: ScheduleRef): QuestionnaireResponseAggregateQueryCriterionV2[] => [
  {
    fieldCriterion: {
      field: QuestionnaireResponseAggregateCriteriaFieldV2.SCHEDULE_REQUEST_REF_SCHEDULE_CODE,
      type: QuestionnaireResponseAggregateQueryTypeV2.EQ,
      values: [scheduleCode],
    },
  },
  {
    fieldCriterion: {
      field: QuestionnaireResponseAggregateCriteriaFieldV2.SCHEDULE_REQUEST_REF_REQUEST_CODE,
      type: QuestionnaireResponseAggregateQueryTypeV2.IN,
      values: requestCodes,
    },
  },
];

const toTotalMetricsCriterion = ({
  scheduleCode,
  requestCodes,
}: ScheduleCodeGroup): QuestionnaireResponseAggregateQueryCriterionV2 => ({
  operator: QuestionnaireResponseAggregateQueryOperatorV2.AND,
  criteria: [
    {
      fieldCriterion: {
        field: QuestionnaireResponseAggregateCriteriaFieldV2.SCHEDULE_REQUEST_REF_SCHEDULE_CODE,
        type: QuestionnaireResponseAggregateQueryTypeV2.EQ,
        values: [scheduleCode],
      },
    },
    {
      fieldCriterion: {
        field: QuestionnaireResponseAggregateCriteriaFieldV2.SCHEDULE_REQUEST_REF_REQUEST_CODE,
        type: QuestionnaireResponseAggregateQueryTypeV2.IN,
        values: requestCodes,
      },
    },
  ],
});

const toRecentMetricsCriterion = ({
  scheduleRef,
  recentPeriodZonedDateTimeStr,
}: ScheduleRequestMetricCriterion): QuestionnaireResponseAggregateQueryCriterionV2 => {
  StateAssert.isTrue(!!recentPeriodZonedDateTimeStr, 'Recent time period must be set');

  return {
    operator: QuestionnaireResponseAggregateQueryOperatorV2.AND,
    criteria: [
      ...toRequestRefCriteria(scheduleRef),
      {
        fieldCriterion: {
          field: QuestionnaireResponseAggregateCriteriaFieldV2.SCHEDULE_REQUEST_REF_REF_TIMESTAMP,
          type: QuestionnaireResponseAggregateQueryTypeV2.GREATER_THAN,
          values: [recentPeriodZonedDateTimeStr],
        },
      },
    ],
  };
};

const toUserIdCriterion = (userId: UserId): QuestionnaireResponseAggregateQueryCriterionV2 => ({
  fieldCriterion: {
    field: QuestionnaireResponseAggregateCriteriaFieldV2.USER_ID,
    type: QuestionnaireResponseAggregateQueryTypeV2.EQ,
    values: [userId],
  },
});

const toStatusCriterion = (
  responseStatusTypes: QuestionnaireResponseStatusType[],
): QuestionnaireResponseAggregateQueryCriterionV2 => ({
  fieldCriterion: {
    field: QuestionnaireResponseAggregateCriteriaFieldV2.CURRENT_STATUS_VALUE,
    type: QuestionnaireResponseAggregateQueryTypeV2.IN,
    values: responseStatusTypes,
  },
});

const createTotalAggregateCountQuery = (
  criteria: ScheduleRequestMetricCriterion[],
): QuestionnaireResponseAggregateQueryCriterionV2[] => {
  const groupByScheduleCode = groupBy(criteria, c => c.scheduleRef.scheduleCode);
  const criteriaGroups = Object.entries(groupByScheduleCode)
    .map(([key, scheduleRequestMetricCriterion]) => ({
      scheduleCode: key,
      requestCodes: uniq(scheduleRequestMetricCriterion.flatMap(criteria => criteria.scheduleRef.requestCodes)),
    }))
    .map(toTotalMetricsCriterion);
  return criteriaGroups;
};

const createRecentPeriodAggregateCountQuery = (
  criteria: ScheduleRequestMetricCriterion[],
): QuestionnaireResponseAggregateQueryCriterionV2[] => criteria.map(toRecentMetricsCriterion);

const toMetricCountAggregateV2 = (
  userId: UserId,
  criteria: ScheduleRequestMetricCriterion[],
  isTotal: boolean,
): QuestionnaireResponseAggregateV2 => {
  StateAssert.isTrue(!!criteria.length, 'At least one criterion must be included');
  return {
    match: {
      criterion: {
        operator: QuestionnaireResponseAggregateQueryOperatorV2.AND,
        criteria: [
          toUserIdCriterion(userId),
          toStatusCriterion([QuestionnaireResponseStatusType.MISSED, QuestionnaireResponseStatusType.COMPLETED]),
          {
            operator: QuestionnaireResponseAggregateQueryOperatorV2.OR,
            criteria: isTotal
              ? createTotalAggregateCountQuery(criteria)
              : createRecentPeriodAggregateCountQuery(criteria),
          },
        ],
      },
    },
    aggregateFields: [
      QuestionnaireResponseAggregateIncludeFieldV2.MAX_CURRENT_STATUS_CREATED_AT,
      QuestionnaireResponseAggregateIncludeFieldV2.COUNT,
    ],

    group: {
      fields: [
        QuestionnaireResponseAggregateGroupFieldV2.USER_ID,
        QuestionnaireResponseAggregateGroupFieldV2.SCHEDULE_REQUEST_REF_SCHEDULE_CODE,
        QuestionnaireResponseAggregateGroupFieldV2.SCHEDULE_REQUEST_REF_REQUEST_CODE,
        QuestionnaireResponseAggregateGroupFieldV2.CURRENT_STATUS_VALUE,
      ],
    },
  };
};

const toReviewNeededCountAggregateV2 = (
  userId: UserId,
  scheduleRefs: ScheduleRef[],
): QuestionnaireResponseAggregateV2 => {
  StateAssert.isTrue(!!scheduleRefs.length, 'At least one request must be included');
  return {
    match: {
      criterion: {
        operator: QuestionnaireResponseAggregateQueryOperatorV2.AND,
        criteria: [
          toUserIdCriterion(userId),
          {
            fieldCriterion: {
              field: QuestionnaireResponseAggregateCriteriaFieldV2.REVIEW_COUNT,
              type: QuestionnaireResponseAggregateQueryTypeV2.EQ,
              values: [0],
            },
          },
          {
            fieldCriterion: {
              field: QuestionnaireResponseAggregateCriteriaFieldV2.CURRENT_STATUS_VALUE,
              type: QuestionnaireResponseAggregateQueryTypeV2.EQ,
              values: [QuestionnaireResponseStatusType.COMPLETED],
            },
          },
          {
            operator: QuestionnaireResponseAggregateQueryOperatorV2.OR,
            criteria: scheduleRefs.map(scheduleRef => ({
              operator: QuestionnaireResponseAggregateQueryOperatorV2.AND,
              criteria: toRequestRefCriteria(scheduleRef),
            })),
          },
        ],
      },
    },
    aggregateFields: [QuestionnaireResponseAggregateIncludeFieldV2.COUNT],
    group: {
      fields: [
        QuestionnaireResponseAggregateGroupFieldV2.USER_ID,
        QuestionnaireResponseAggregateGroupFieldV2.SCHEDULE_REQUEST_REF_SCHEDULE_CODE,
        QuestionnaireResponseAggregateGroupFieldV2.SCHEDULE_REQUEST_REF_REQUEST_CODE,
      ],
    },
  };
};

const toUntilDateCountAggregateV2 = (
  userId: UserId,
  { maxDate, scheduleRef }: ScheduleRequestDateUntilCriteria,
): QuestionnaireResponseAggregateV2 => ({
  match: {
    criterion: {
      operator: QuestionnaireResponseAggregateQueryOperatorV2.AND,
      criteria: [
        ...toRequestRefCriteria(scheduleRef),
        toUserIdCriterion(userId),
        toStatusCriterion([QuestionnaireResponseStatusType.MISSED, QuestionnaireResponseStatusType.COMPLETED]),
        {
          fieldCriterion: {
            field: QuestionnaireResponseAggregateCriteriaFieldV2.SCHEDULE_REQUEST_REF_REF_TIMESTAMP,
            type: QuestionnaireResponseAggregateQueryTypeV2.LESS_THAN_EQ,
            values: [toTimezoneStr(maxDate)],
          },
        },
      ],
    },
  },
  aggregateFields: [QuestionnaireResponseAggregateIncludeFieldV2.COUNT],
  group: {
    fields: [
      QuestionnaireResponseAggregateGroupFieldV2.USER_ID,
      QuestionnaireResponseAggregateGroupFieldV2.SCHEDULE_REQUEST_REF_SCHEDULE_CODE,
      QuestionnaireResponseAggregateGroupFieldV2.CURRENT_STATUS_VALUE,
    ],
  },
});

export const QuestionnaireV2AggregateQueryMapper = {
  toMetricCountAggregateV2,
  toReviewNeededCountAggregateV2,
  toUntilDateCountAggregateV2,
};
