export type ContentConfigV1 = {
  clientType: 'WEB' | 'APP';
  clientVersion: string;
  documentsVersion: string;
  questionnairesVersion: string;
  allowMockedContent?: boolean;
  validateContent: boolean;
  hideSchemaValidationMessages?: boolean;
  cacheTimeoutSecs?: number;
};

const empty = (): ContentConfigV1 => ({
  clientType: 'WEB',
  clientVersion: '2.1',
  documentsVersion: 'documents_1.0',
  questionnairesVersion: 'questionnaires_2.0',
  allowMockedContent: false,
  validateContent: false,
  hideSchemaValidationMessages: true,
});

export const ContentConfigV1 = {
  empty,
};
