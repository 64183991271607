import { HbsMeta } from './HbsMeta';

export const VISIT_CONFIG_V1_META_NAME = 'CONFIG_V1';

export type VisitConfigMetaV1 = {
  variant?: string; // "CLOSE_OUT"
  requireConfirmation?: boolean;
  preventBookingBeforeWindow?: boolean;
  preventBookingAfterWindow?: boolean;
  hbs?: HbsMeta;
};
