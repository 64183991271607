/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Format of the appointment, i.e. meet in person or by other ways of communication.
 */
export enum AppointmentFormatV1 {
    PHONE = 'PHONE',
    VIRTUAL = 'VIRTUAL',
    IN_PERSON = 'IN_PERSON',
    VIDEO = 'VIDEO',
    HOME_VISIT = 'HOME_VISIT',
}