import { IconButton, OnClickButton } from '@csp/csp-web-ui';
import { FC } from 'react';
import { StaticContentService } from '../../../common/service/StaticContentService';

type Props = {
  onClick?: OnClickButton;
};

const margin = { xs: 0, sm: 1, lg: 1.25 };
const marginLeft = { xs: 0, sm: 1, lg: 2 };

export const AppsIconButton: FC<Props> = props => {
  const { button: topBarButtons } = StaticContentService.getPublicBlock('webHcpNavigationHeader', 'topBar');

  return (
    <IconButton
      tooltip={{
        title: topBarButtons.tools.label,
      }}
      {...props}
      iconProps={{ name: 'menu', size: 'large', color: 'black' }}
      my={margin}
      ml={marginLeft}
    />
  );
};

export const HelpIconButton: FC<Props> = props => {
  const { button: topBarButtons } = StaticContentService.getPublicBlock('webHcpNavigationHeader', 'topBar');

  return (
    <IconButton
      tooltip={{
        title: topBarButtons.support.label,
      }}
      {...props}
      iconProps={{ name: 'support', size: 'large', color: 'black' }}
      my={margin}
    />
  );
};
