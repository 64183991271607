/* eslint-disable */
export const UnifyArticleMock = {
  title: 'title',
  articleId: 'articleId',
  countryCodes: [],
  pageHeader: 'pageHeader',
  header: 'header',
  subHeader: 'subHeader',
  ImageAttachment1: { url: 'https://via.placeholder.com/300' },
  imageAttachment2: {},
  imageAttachment3: {},
  bodyMarkdown1: 'bodyMarkdown1',
  bodyMarkdown2: 'bodyMarkdown2',
  bodyMarkdown3: 'bodyMarkdown3',
};
