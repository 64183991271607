/* eslint-disable */
export const UnifyAppOnboardingMock = {
  title: 'title',
  setPin: {
    title: '[M]Setup a Passcode',
    confirmButtonAccessibilityText: '[M]Confirm Passcode',
    confirmButtonText: '[M]Confirm Passcode',
    error: '[M]Failed to set passcode',
    subHeader: '[M]Please set-up a passcode to further protect access and safeguard your data',
    pinNotMatchError: '[M]The passcode you have just inserted is not matching with the previous one. Please try again.',
    pinToShortError:
      '[M]The passcode you have just inserted does not have enought digits, 4 is required. Please try again.',
  },
  confirmPin: {
    title: '[M]Confirm Passcode',
    celebrationButtonText: '[M]I got it',
    celebrationHelper: '[M]You can only use one device at a time. This is now the primary device.',
    celebrationDescription: '[M]Description for the celebration page on succesfull set pin',
    celebrationTitle: '[M]Passcode set for this device',
    confirmButtonAccessibilityText: '[M]Confirm Passcode',
    confirmButtonText: '[M]Confirm Passcode',
    errorSave: '[M]Failed to save passcode',
    subHeader: '[M]Please digit the Passcode you created to authorize and confirm this step.',
  },
  eula: {
    acceptButton: '[M]Accept',
    acceptButtonAccessibilityText: '[M]Accept',
    agreementWarning: '[M]Please read and agree to our EULA. This step is required to use our services',
    alertAcceptButton: '[M]Accept',
    alertCloseButton: '[M]Close',
    alertDeclineText:
      '[M]If you want to access this Unify to support you in this study then you will need to accept this agreement',
    alertDeclineTitle: '[M]Are you sure you want to decline?',
    declineButton: '[M]Decline',
    declineButtonAccessibilityText: '[M]Decline',
  },
  privacyPolicy: {
    acceptButton: '[M]Accept',
    acceptButtonAccessibilityLabel: '[M]Accept',
    agreementWarning: '[M]Please read and agree to our Privacy Policy. This step is required to use our services',
    alertAcceptButton: '[M]Accept',
    alertCloseButton: '[M]Close',
    alertDeclineText:
      '[M]If you want to access this Unify to support you in this study then you will need to accept this agreement',
    alertDeclineTitle: '[M]Are you sure you want to decline?',
    declineButton: '[M]Decline',
    declineButtonAccessibilityText: '[M]Decline',
    privacyPolicyAcceptError: '[M]An unknown error occurred when accepting Privacy Policy, please try again',
  },
  notificationSettings: {
    title: '[M]Stay on Track',
    subHeader: "[M]We'll remind you about anything that you need to do, and any updates on the trial",
    acceptButtonText: '[M]Keep on track',
    acceptButtonAccessibilityText: '[M]Keep on track',
    declineButtonText: '[M]Not now',
    declineButtonAccessibilityText: '[M]Not now',
    declineAlert: {
      alertDeclineTitle: '[M]Enable Notifications',
      alertDeclineText:
        '[M]Enabling notifications is recommended so that we can remind you about important activities and appointments',
      alertAcceptButtonText: '[M]Enable',
      alertCancelButtonText: '[M]Not now',
    },
    image: { url: 'https://via.placeholder.com/300' },
  },
  geolocationSettings: {
    title: '[M]Geolocation',
    subHeader:
      '[M]By allowing the reasearch team at AstraZeneca to see your location, we will learn more about when ypu are near hospitals, helping us to stramline clinical trials in the future.',
    acceptButtonText: '[M]Allow Geolocation',
    acceptButtonAccessibilityText: '[M]Allow Geolocation',
    declineButtonText: '[M]Not now',
    declineButtonAccessibilityText: '[M]Not now',
    declineAlert: {
      alertDeclineTitle: '[M]Allow Geolocation Services',
      alertDeclineText: '[M]Not recommended to proceed without accepting geolocation settings.',
      alertAcceptButtonText: '[M]Allow',
      alertCancelButton: '[M]Not now',
    },
    image: { url: 'https://via.placeholder.com/300' },
  },
  welcomeToStudy: {
    header: '[M]Welcome to the study',
    button: '[M]Get started',
    buttonAccessibility: '[M]Get started',
  },
  reConsent: {
    header: '[M]Terms and Condition Updates',
    headerAccessibilityLabel: '[M]Terms and Condition Updates',
    subHeader: '[M]Please accept the updated Terms and Conditions before continuing',
    subHeaderAccessibilityLabel: '[M]Please accept the updated Terms and Conditions before continuing',
    button: '[M]Continue',
    buttonAccessibilityLabel: '[M]Continue',
    buttonAccessibilityHint: '[M]Continue to updated policies',
  },
};
