import { MenuItem, OnClick } from '@csp/csp-web-ui';
import { FC } from 'react';
import { generatePath, useLocation } from 'react-router-dom';
import { CspPath } from '../../../CspPath';
import { StaticContentService } from '../../../common/service/StaticContentService';
import { useContextualNavBack } from '../hooks/useContextualNavBack';

type Props = { userId: string };

export const LinkToProfileContainer: FC<Props> = ({ userId }) => {
  const location = useLocation();
  const topBarBlock = StaticContentService.getPublicBlock('webHcpNavigationHeader', 'topBar');

  const url = generatePath(CspPath.TEAM_MEMBER_OVERVIEW, { userId });
  const { navigateWithContextualNavBack } = useContextualNavBack();

  const onClick: OnClick = () => navigateWithContextualNavBack(url, location.pathname);

  return <MenuItem onClick={onClick} title={topBarBlock.button.viewProfile.label} iconProps={{ name: 'profile' }} />;
};
