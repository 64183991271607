import styled from '@emotion/styled';
import { FlexColumn } from '@csp/csp-web-ui';
import { FC, PropsWithChildren } from 'react';

export const HeaderNavLarge = styled(FlexColumn)`
  ${({ theme: { breakpoints, palette, spacing } }): string => `
    background-color: ${palette.background.paper};
    overflow: hidden;
    padding: ${spacing(0, 5, 0, 5)};

    ${breakpoints.up('lg')} {
      max-width: 120rem;
      margin: 0 auto;
     }
  `}
`;

const HeaderNavStyled = styled(HeaderNavLarge)`
  ${({ theme: { breakpoints, spacing } }): string => `
    ${breakpoints.down('sm')} {
      padding: ${spacing(0, 2.5)};
    }
  `}
`;

type Props = {
  id?: string;
};

export const HeaderNav: FC<PropsWithChildren<Props>> = ({ children, id }) => (
  <HeaderNavStyled id={id}>{children}</HeaderNavStyled>
);
