import { fromTimezoneStr } from '@csp/csp-common-date-util';
import { Audit } from '@csp/csp-common-model';
import { AuditV1 } from '@csp/dmdp-api-common-dto';

const fromV1 = (v1: AuditV1): Audit => ({
  createdAt: fromTimezoneStr(v1.createdAt),
  createdBy: v1.createdBy,
  lastModified: fromTimezoneStr(v1.lastModified),
  modifiedBy: v1.modifiedBy,
});

export const AuditMapper = {
  fromV1,
};
