import { ApiOptions, Maybe } from '@csp/csp-common-model';
import { toRestOptions } from '@csp/csp-fe-auth';
import {
  AssessmentCommentInV1,
  AssessmentCommentV1,
  AssessmentInV1,
  AssessmentOutcomeInV1,
  AssessmentQueryV1,
  AssessmentV1,
} from '@csp/dmdp-api-assessment-dto';
import { AssessmentRestServiceV1 } from '@csp/dmdp-api-client';
import { Page, Paging } from '@csp/dmdp-api-common-dto';
import { first } from 'lodash';
import { v4 as uuid } from 'uuid';
import { Assessment } from '../model/Assessment';
import { AssessmentCode } from '../model/AssessmentCode';
import { AssessmentId } from '../model/AssessmentId';
import { AssessmentOutcomeKey } from '../model/AssessmentOutcomeKey';
import { AssessmentOutcomeRefKey } from '../model/AssessmentOutcomeRefKey';

type AssessmentsData = {
  assessments: Assessment[];
  paging?: Paging;
};

const generateAssessmentCodeWithPrefix = (): AssessmentCode => uuid();

const getAssessmentByCode = async (
  query: AssessmentQueryV1,
  page: Page,
  apiOptions?: ApiOptions,
): Promise<Maybe<Assessment>> => {
  const { assessments } = await AssessmentRestServiceV1.getAssessments(toRestOptions(apiOptions), query, page);
  const firstAssessment = first(assessments);
  return firstAssessment ? Assessment.from(firstAssessment) : undefined;
};

const getAssessmentsByCodes = async (
  query: AssessmentQueryV1,
  page: Page,
  apiOptions?: ApiOptions,
): Promise<AssessmentsData> => {
  const { assessments, paging } = await AssessmentRestServiceV1.getAssessments(toRestOptions(apiOptions), query, page);

  return {
    assessments: assessments?.map(Assessment.from) ?? [],
    paging,
  };
};

const createAssessment = async (assessmentInV1: AssessmentInV1, apiOptions?: ApiOptions): Promise<AssessmentV1> =>
  await AssessmentRestServiceV1.createAssessment(toRestOptions(apiOptions), assessmentInV1);

const createAssessmentComment = async (
  assessmentId: AssessmentId,
  assessmentCommentInV1: AssessmentCommentInV1,
  apiOptions?: ApiOptions,
): Promise<AssessmentCommentV1> =>
  await AssessmentRestServiceV1.createAssessmentComment(toRestOptions(apiOptions), assessmentId, assessmentCommentInV1);

const updateAssessmentOutcome = async (
  assessmentId: AssessmentId,
  assessmentOutcomeKey: AssessmentOutcomeKey,
  assessmentOutcomeRefKey: AssessmentOutcomeRefKey,
  value: string,
  apiOptions?: ApiOptions,
): Promise<Assessment> => {
  const assessmentOutcomeInV1: AssessmentOutcomeInV1 = {
    key: assessmentOutcomeKey,
    value: true,
    refs: [{ key: assessmentOutcomeRefKey, value }],
  };

  const response = await AssessmentRestServiceV1.updateAssessmentOutcome(
    toRestOptions(apiOptions),
    assessmentId,
    assessmentOutcomeInV1,
  );

  return Assessment.from(response);
};

const removeAssessmentOutcome = async (
  assessmentId: AssessmentId,
  assessmentOutcomeKey: AssessmentOutcomeKey,
  apiOptions?: ApiOptions,
): Promise<Assessment> => {
  const response = await AssessmentRestServiceV1.deleteAssessmentOutcome(
    toRestOptions(apiOptions),
    assessmentId,
    assessmentOutcomeKey,
  );

  return Assessment.from(response);
};

export const AssessmentService = {
  generateAssessmentCodeWithPrefix,
  createAssessment,
  getAssessmentsByCodes,
  getAssessmentByCode,
  createAssessmentComment,
  updateAssessmentOutcome,
  removeAssessmentOutcome,
};
