/* eslint-disable */
export const UnifyWebPatientStatusStudySchema = {
  title: 'UnifyWebPatientStatusStudy',
  type: 'object',
  properties: {
    title: { type: 'string' },
    followUp: {
      title: 'Study specific related texts for follow-up phase',
      type: 'object',
      properties: {
        title: {
          title: 'Study specific titles for follow-up phase',
          type: 'object',
          properties: {
            FOLLOW_UP: { title: 'Title label for follow-up status', type: 'string', mock: 'Custom follow-up' },
            SURVIVAL_FOLLOW_UP: {
              title: 'Title label for survival follow-up status',
              type: 'string',
              mock: 'Custom survival',
            },
          },
        },
      },
    },
    studyDefined: {
      title: 'Study defined statuses content',
      type: 'object',
      properties: {
        statuses: {
          title: 'Single status',
          type: 'array',
          items: {
            type: 'object',
            properties: {
              type: {
                title: 'Status type mapping',
                helper:
                  'Used for map configuration to content. Must have corresponding value in patient-status-config file. Example: DOSING, HEALTH_EVENT, VISIT_CONFIRMATION',
                type: 'string',
              },
              title: {
                title: 'Titles related to study defined statuses overview card',
                type: 'object',
                properties: {
                  currentStatus: { title: 'The current status title', type: 'string' },
                  card: { title: 'The overview card title', type: 'string' },
                  learnMoreSections: { title: 'Learn more sections header', type: 'string' },
                  previousStatusValueName: {
                    title: 'Value name column header for previous status list',
                    type: 'string',
                  },
                  confirmNewStatus: { title: 'Confirm dialog new status section', type: 'string' },
                },
              },
              text: {
                title: 'Status texts',
                type: 'object',
                properties: {
                  nextStatusMd: {
                    title: 'Text show above next status CTA',
                    type: 'string',
                    helper: 'Markdown is supported',
                  },
                  startDateTimeDescriptionMd: {
                    title: 'Show in start date drawer',
                    type: 'string',
                    allowOptionalEmpty: true,
                    helper:
                      'Optional. Markdown is supported. Can be used for give some extra information to HCP about what happens when setting start date to the status',
                  },
                },
              },
              alert: {
                title: 'Study define alerts',
                type: 'object',
                properties: {
                  startDateTimeMd: {
                    title: 'Show in start date drawer',
                    type: 'string',
                    allowOptionalEmpty: true,
                    helper:
                      'Optional. Markdown is supported. Can be used for give some extra attention to HCP about when setting start date to the status',
                  },
                },
              },
              statusValues: {
                title: 'The status values',
                type: 'array',
                items: {
                  type: 'object',
                  properties: {
                    value: {
                      title: 'The value definition',
                      helper:
                        'Used for map configuration to content. Must have corresponding value in patient-status-config file. Example: DOSE_1, VISIT_1',
                      type: 'string',
                    },
                    name: { title: 'Status value display name', type: 'string' },
                  },
                },
              },
              learnMoreSections: {
                title: 'Learn more information sections',
                type: 'array',
                items: {
                  type: 'object',
                  properties: {
                    title: { title: 'Section header', type: 'string' },
                    bodyMd: { title: 'Section body', type: 'string', helper: 'Markdown is supported' },
                  },
                },
              },
            },
          },
          mock: [
            {
              type: 'DOSING',
              statusValues: [
                { value: 'DOSE_1', name: 'Dose one - Cycle 1' },
                { value: 'DOSE_2', name: 'Dose two - Cycle 1' },
                { value: 'DOSE_3', name: 'Dose three - Cycle 1' },
              ],
              title: {
                currentStatus: 'Current dose',
                card: 'Dosing confirmation',
                learnMoreSections: 'About',
                learnMoreTransitions: 'Status values and transitions',
                previousStatusValueName: 'Dose',
                confirmNewStatus: 'Selected dose',
              },
              text: {
                nextStatusMd: "Select the patient's new dosing",
                startDateTimeDescriptionMd:
                  "The new dose will be added to the patient's dosing history.\n\n**Note:** Ensure that patient has taken the dose before confirm.",
              },
              alert: { startDateTimeMd: 'Dose must have been taken' },
              learnMoreSections: [
                {
                  title: 'Section A',
                  bodyMd:
                    'Lorem ipsum dolor sit amet, **consectetur** _adipiscing elit_, sed do \n\neiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad ',
                },
                { title: 'Section B', bodyMd: '**Body B**' },
              ],
            },
            {
              type: 'DECEASE_PROGRESSION',
              statusValues: [
                { value: 'NO_CHANGE', name: 'No changes observed' },
                { value: 'INCREASE', name: 'Tumour increased' },
                { value: 'DECREASE', name: 'Tumour decreased' },
              ],
              title: {
                currentStatus: 'Current tumour growth',
                card: 'Decease progression',
                learnMoreSections: 'About',
                previousStatusValueName: 'Tumor change',
                confirmNewStatus: 'Tumor change',
              },
              text: {
                nextStatusMd: 'Update tumour growth',
                startDateTimeDescriptionMd: 'Each decease progression are defined in the study protocol',
                dialogBodyMd: 'Are you sure the you want to change tumor growth?',
              },
              alert: {
                startDateTimeMd: 'Make sure that you have checked all preconditions before setting decease progression',
              },
              learnMoreSections: [
                { title: 'Section A', bodyMd: '**Body A**' },
                { title: 'Section B', bodyMd: '**Body B**' },
              ],
            },
            {
              type: 'CBP',
              statusValues: [
                { value: 'CBP_YES', name: 'Yes' },
                { value: 'CBP_NO', name: 'No' },
              ],
              title: {
                currentStatus: 'CBP status',
                card: 'CBP status',
                learnMoreSections: 'About',
                previousStatusValueName: 'CBP change',
                confirmNewStatus: 'New CBP status',
              },
              text: {
                nextStatusMd: 'Update CBP status',
                startDateTimeDescriptionMd: 'CBP statuses are defined in the study protocol',
                dialogBodyMd: 'Are you sure the you want to change CBP status?',
              },
              learnMoreSections: [
                {
                  title: 'CBP - Yes',
                  bodyMd:
                    'CBP- Child Bearing Potential is set on patients that are female and within age to potential have a child. If patient meets the criterias but for other medical reasons are not able to reproduce, patient is not considered to be CBP.  ',
                },
                {
                  title: 'CBP - No',
                  bodyMd:
                    'CBP- Child Bearing Potential is NOT set on patients that are male. And also not on female patients that are no longer able to reproduce.',
                },
              ],
            },
            {
              type: 'COHORT',
              statusValues: [
                { value: 'COHORT_A', name: 'Cohort A' },
                { value: 'COHORT_B', name: 'Cohort B' },
              ],
              title: {
                currentStatus: 'Cohort',
                card: 'Cohort',
                learnMoreSections: 'About',
                previousStatusValueName: 'Cohort',
                confirmNewStatus: 'New cohort',
              },
              text: { nextStatusMd: 'Update Cohort', noPossibleToSetStatusYetMd: 'No cohort to select yet' },
              alert: { statusUpdated: 'Cohort has been set' },
              learnMoreSections: [
                { title: 'Cohort A', bodyMd: 'If patient belongs to Cohort A, select Cohort A.  ' },
                { title: 'Cohort B', bodyMd: 'If patient belongs to Cohort B, select Cohort B.' },
              ],
            },
          ],
        },
      },
    },
  },
  description: 'unify:webpatientstatusstudy',
  $schema: 'http://json-schema.org/draft-07/schema#',
};
