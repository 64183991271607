export enum PatientFinalContactStatusType {
  CONTACT_COMPLETED = 'CONTACT_COMPLETED',
  UNKNOWN = 'UNKNOWN',
}

const values = Object.values(PatientFinalContactStatusType).filter(
  val => val !== PatientFinalContactStatusType.UNKNOWN,
);

const isPatientFinalContactStatus = (value: string): value is PatientFinalContactStatusType =>
  values.includes(value as PatientFinalContactStatusType);

export const PatientFinalContactStatusTypes = {
  values,
  isPatientFinalContactStatus,
};
