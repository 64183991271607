import { LocationTypeV1 } from '@csp/dmdp-api-appointment-dto';
import { VisitFormatType } from './VisitFormatType';

export enum VisitLocationType {
  ADDRESS = LocationTypeV1.ADDRESS,
  LOCATION = LocationTypeV1.LOCATION,
  ORG = LocationTypeV1.ORG,
  PHONE = LocationTypeV1.PHONE,
  VIDEO = LocationTypeV1.VIDEO,
  HOME_VISIT = LocationTypeV1.HOME_VISIT,
  UNKNOWN = -1,
}

const from = (visitFormat: VisitFormatType): VisitLocationType | undefined => {
  switch (visitFormat) {
    case VisitFormatType.PHONE:
      return VisitLocationType.PHONE;
    case VisitFormatType.IN_PERSON:
      return VisitLocationType.LOCATION;
    case VisitFormatType.VIDEO:
      return VisitLocationType.VIDEO;
    case VisitFormatType.HOME_VISIT:
      return VisitLocationType.HOME_VISIT;
    default:
      return undefined;
  }
};

export const VisitLocationTypes = {
  from,
};
