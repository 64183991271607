import {
  CmsSourceType,
  ContentConfigV1,
  ContentConfigService,
  ContentCriteria,
  ContentService,
  getClientVersionBySourceType,
} from '@csp/csp-common-content';
import { ApiOptions, CspError, CspErrorType, ObjectType } from '@csp/csp-common-model';
import * as contentSchemas from '@csp/csp-content-schema';
import { toRestOptions } from '@csp/csp-fe-auth';
import { TelemetryService } from '@csp/csp-web-telemetry';
import debug from 'debug';
import { CmsContentArguments } from '../model/CmsContentArguments';
import { CmsCustomProperty } from '../model/CmsCustomProperty';

const log = debug('FeContent:Service:CmsService');

const getContentConfiguration = (contentSourceType: CmsSourceType): ContentConfigV1 => {
  const config = ContentConfigService.get();

  return {
    ...config,
    clientVersion: getClientVersionBySourceType(config, contentSourceType),
  };
};

const getContentCriteria = (
  contentType: string,
  locale: string,
  customProperties: CmsCustomProperty[],
  limit?: number,
): ContentCriteria => {
  const schema = Object.values<ObjectType>(contentSchemas).find(({ description }) => description === contentType);

  if (!schema) {
    log(`Could not find matching JSON content schema for "${contentType}"`);
  }

  return {
    contentType,
    locale,
    matchProperties: customProperties,
    limit,
    schema: schema ?? {},
  };
};

const getContent = async <T extends ObjectType | ObjectType[]>(
  {
    contentType,
    locale,
    returnAllRows = false,
    customProperties = [],
    contentSourceType = 'default',
    limit,
  }: CmsContentArguments,
  apiOptions?: ApiOptions,
): Promise<T> => {
  const contentConfiguration = getContentConfiguration(contentSourceType);
  const criteria = getContentCriteria(contentType, locale, customProperties, limit);

  const contentList = await ContentService.getContents(
    criteria,
    contentConfiguration,
    undefined,
    toRestOptions(apiOptions),
  );

  if (!returnAllRows && (!contentList || contentList.length === 0)) {
    const error = new CspError(
      CspErrorType.NOT_FOUND,
      `Query returned no items: ${JSON.stringify({ contentType, locale, customProperties }, null, 2)}`,
    );
    TelemetryService.trackException({
      error,
      context: {
        extra: {
          clientVersion: contentConfiguration.clientVersion,
          clientType: contentConfiguration.clientType,
          contentType,
          locale,
        },
      },
    });
    return Promise.reject(error);
  }
  if (!returnAllRows) {
    return contentList[0] as T;
  } else {
    return contentList as T;
  }
};

export const CmsService = {
  getContent,
  getContentCriteria,
  getContentConfiguration,
};
