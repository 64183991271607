import { PatientHealthEventMetaV1 } from '../../dto/UserMetaV1';
import { PatientHealthEventV1 } from './PatientHealthEventV1';

export const PATIENT_HEALTH_EVENT_V1 = 'PATIENT_HEALTH_EVENT_V1';

export const createPatientHealthEventMetaV1 = (
  patientHealthEventV1: PatientHealthEventV1,
): PatientHealthEventMetaV1 => ({
  type: PATIENT_HEALTH_EVENT_V1,
  name: PATIENT_HEALTH_EVENT_V1,
  data: patientHealthEventV1,
});
