/* eslint-disable */
export const UnifyArticleSchema = {
  title: 'UnifyArticle',
  type: 'object',
  properties: {
    title: { type: 'string' },
    articleId: { type: 'string' },
    countryCodes: {
      type: 'array',
      title: 'Countries',
      helper: 'Link will only be shown for users in these countries',
      items: {
        type: 'string',
        enum: [
          'AR',
          'AT',
          'AU',
          'BE',
          'BG',
          'BR',
          'CA',
          'CL',
          'CN',
          'CO',
          'CZ',
          'DE',
          'DK',
          'EG',
          'ES',
          'FI',
          'FR',
          'GB',
          'GR',
          'HK',
          'HU',
          'IE',
          'IL',
          'IN',
          'IT',
          'JP',
          'KR',
          'MT',
          'MX',
          'MY',
          'NL',
          'NO',
          'NZ',
          'PA',
          'PE',
          'PH',
          'PL',
          'PT',
          'RO',
          'RU',
          'SA',
          'SE',
          'SG',
          'SK',
          'SW',
          'TH',
          'TR',
          'TW',
          'UA',
          'US',
          'VE',
          'VN',
          'XX',
          'ZA',
          'ZZ',
        ],
      },
    },
    pageHeader: { type: 'string' },
    header: { type: 'string' },
    subHeader: { type: 'string' },
    ImageAttachment1: { type: 'object', _attachment: { id: 'default' } },
    imageAttachment2: { type: 'object', _attachment: { id: 'default' }, properties: {} },
    imageAttachment3: { type: 'object', _attachment: { id: 'default' }, properties: {} },
    bodyMarkdown1: { type: 'string' },
    bodyMarkdown2: { type: 'string' },
    bodyMarkdown3: { type: 'string' },
  },
  description: 'unify:article',
  $schema: 'http://json-schema.org/draft-07/schema#',
  required: ['articleId'],
};
