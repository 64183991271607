import { StudyInfoV1 } from '@csp/dmdp-api-study-dto';
import { StudyInfo } from '@csp/csp-common-model';
import debug from 'debug';

const log = debug('Common:Study:model');

const from = (tenantId: string, { dcode, name }: StudyInfoV1): StudyInfo => {
  const studyInfo: StudyInfo = {
    name: name ?? '',
    dcode,
    tenantId,
  };

  log('Study.from output', studyInfo);

  return studyInfo;
};

export const StudyInfoFactory = {
  from,
};
