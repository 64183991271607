import { DeviceModelNumberKey } from '@csp/config-schemas';
import { CspError, Maybe, MetricPeriod, StateAssert } from '@csp/csp-common-model';
import {
  Duration,
  GenericRequest,
  GenericRequestMapper,
  ReminderNotificationMetaMapper,
  RequestAction,
  RequestInitiationConfigMapper,
  RpmEventConfigMapper,
  ScheduleCode,
  ScheduleValidationService,
  ScheduleVersionInfo,
  RequestTagsConfigMetaMapper,
  Timing,
  UnitOfTime,
  RequestGroupMapper,
} from '@csp/csp-common-scheduling';
import { DeviceModelNumberType } from '@csp/device-catalog';
import {
  DeviceObservationDurationV1,
  DeviceObservationRelatedActionV1,
  DeviceObservationRequestDetailsV1,
  DeviceObservationRequestV1,
  DeviceObservationTimingV1,
  DeviceObservationUnitOfTimeV1,
} from '@csp/dmdp-api-device-dto';
import { DeviceMetricsConfigMapper } from '../mapper/DeviceMetricsConfigMapper';
import { DeviceObservationRequestDetails } from './DeviceObservationRequestDetails';

export type DeviceObservationRequest = GenericRequest & {
  versionInfo: ScheduleVersionInfo;
  details: DeviceObservationRequestDetails;
  referencedModelNumbers: DeviceModelNumberType[];
  historicalMetricPeriod?: MetricPeriod;
};

export const toUnitOfTime = (unitOfTime: DeviceObservationUnitOfTimeV1): UnitOfTime => {
  switch (unitOfTime) {
    case DeviceObservationUnitOfTimeV1.D:
      return UnitOfTime.D;
    case DeviceObservationUnitOfTimeV1.H:
      return UnitOfTime.H;
    case DeviceObservationUnitOfTimeV1.MIN:
      return UnitOfTime.MIN;
    default:
      throw CspError.badState(`Unsupported unit of time '${unitOfTime}'`);
  }
};

const toDuration = ({ unit, value }: DeviceObservationDurationV1): Duration => ({
  unit: toUnitOfTime(unit),
  value,
});

const toTiming = ({ windowAfter, windowBefore, repeat }: DeviceObservationTimingV1): Timing => ({
  windowBefore: windowBefore ? toDuration(windowBefore) : undefined,
  windowAfter: windowAfter ? toDuration(windowAfter) : undefined,
  repeat: {
    period: repeat?.period,
    periodUnit: repeat?.periodUnit ? toUnitOfTime(repeat.periodUnit) : undefined,
    frequency: repeat?.frequency,
    frequencyMax: repeat?.frequencyMax,
    timesOfDay: repeat?.timesOfDay,
    daysOfWeek: repeat?.daysOfWeek ? repeat?.daysOfWeek : undefined,
  },
});

const toReferencedModelNumbers = ({
  deviceModelReferences,
}: DeviceObservationRequestDetailsV1): DeviceModelNumberType[] =>
  deviceModelReferences
    .filter(({ ref }) => ref.key === DeviceModelNumberKey)
    .flatMap(({ ref }) => ref.value as DeviceModelNumberType);

const requestActionOf = (action: Maybe<DeviceObservationRelatedActionV1>): RequestAction => ({
  fixedAction: action?.customAction
    ? GenericRequestMapper.customActionDateToFixedAction(action?.customAction)
    : GenericRequestMapper.toFixedAction(action?.fixedAction),
  offset: action?.offset ? toDuration(action.offset) : undefined,
  fixedTime: action?.customAction ? GenericRequestMapper.customActionTimeToFixedTime(action.customAction) : undefined,
});

const from = (
  scheduleCode: ScheduleCode,
  versionInfo: ScheduleVersionInfo,
  { requestCode, startAction, endAction, timing, details, meta, while: requestWhile }: DeviceObservationRequestV1,
): DeviceObservationRequest => {
  StateAssert.notNull(details, `DeviceObservationRequest details not set: ${requestCode}`);
  const referencedModelNumbers = toReferencedModelNumbers(details);
  const notificationConfig = ReminderNotificationMetaMapper.toNotificationConfigFromMetaV1(meta);
  const initiationConfig = RequestInitiationConfigMapper.fromRequestInitiationConfigMetaV1(meta);
  const metricConfig = DeviceMetricsConfigMapper.fromRequestMetricsConfigsMetaV1(meta);
  const rpmEventConfig = RpmEventConfigMapper.fromRpmEventConfigMetaV1(meta);
  const tagsConfig = RequestTagsConfigMetaMapper.fromRequestTagsConfigMetaV1(meta);
  const groups = RequestGroupMapper.toRequestGroupMemberships(meta);

  const minComplianceThreshold = metricConfig?.minComplianceThreshold;
  const historicalMetricPeriod = metricConfig?.historicalMetricPeriod;

  StateAssert.notEmpty(referencedModelNumbers, `DeviceObservationRequest device models not set: ${requestCode}`);

  const request: DeviceObservationRequest = {
    versionInfo,
    requestCode,
    versionCode: versionInfo.versionCode,
    scheduleCode,
    startAction: requestActionOf(startAction),
    endAction: requestActionOf(endAction),
    while: GenericRequestMapper.toRequestWhile(requestWhile),
    timing: timing ? toTiming(timing) : undefined,
    details: DeviceObservationRequestDetails.from(details),
    referencedModelNumbers: toReferencedModelNumbers(details),
    notificationConfig,
    initiationConfig,
    minComplianceThreshold,
    historicalMetricPeriod,
    rpmEventConfig,
    tagsConfig,
    groups,
  };

  ScheduleValidationService.validateRequest(request);
  return request;
};

export const DeviceObservationRequest = {
  from,
};
