import { Maybe } from '../../../common/model/Monadish';
import { ZonedDateTime } from '../../../datetime/ZonedDateTime';

export type PatientAppInfo = {
  /**
   * ios, android
   */
  system?: string;
  version?: string;
  buildNo?: string;
  updatedAt?: Maybe<ZonedDateTime>;
};

export const emptyPatientAppInfo: PatientAppInfo = {};
