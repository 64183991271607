import { createAxiosWithJwtToken, createListener, Listener } from '@csp/csp-common-axios';
import { ApiOptions, CspError, StateAssert, Study, StudyInfo, User } from '@csp/csp-common-model';
import { OrgService, UserService } from '@csp/csp-common-user';
import { isDefined } from '@csp/csp-common-util';
import { axiosIdp, DmdpTokenService, toRestOptions } from '@csp/csp-fe-auth';
import { JwtRestServiceV1, StudyRestServiceV1, UserInfoRestServiceV1 } from '@csp/dmdp-api-client';
import { CSP_PERMISSION_ROLES, RoleType } from '@csp/dmdp-api-common-dto';
import { OrgType, OrgV1 } from '@csp/dmdp-api-org-dto';
import { DmdpToken, DmdpUserStatusType } from '@csp/dmdp-api-user-dto';
import debug from 'debug';
import { groupBy } from 'lodash/fp';
import { StudyFactory } from '../model/StudyFactory';
import { StudyInfoFactory } from '../model/StudyInfoFactory';

const log = debug('Common:Study:StudyService');

const userHasPermission = (roles: RoleType[]): boolean => roles.some(role => CSP_PERMISSION_ROLES.includes(role));

const groupByType = groupBy<OrgV1>(o => o.type);

export class StudyService {
  activeStudy: Study | undefined = undefined;
  changeListeners = createListener<Study>();

  onChange(listener: Listener<Study>): () => void {
    this.changeListeners.add(listener);
    return (): void => {
      this.changeListeners.delete(listener);
    };
  }

  setActiveStudy(study: Study): void {
    this.activeStudy = study;
    log('Active study was changed', study);
    this.changeListeners.trigger(study);
  }

  clearActiveStudy(): void {
    this.activeStudy = undefined;
  }

  getActiveStudy(): Study | undefined {
    return this.activeStudy;
  }

  async getStudyOrStudyInfo(tenantId: string, user: User, apiOptions?: ApiOptions): Promise<Study> {
    const orgsV1s = await OrgService.fetchOrgsIncludingParentsByIds(
      user.orgIds,
      user.orgIdsIncludingParents,
      apiOptions,
    );
    const orgV1sByType = groupByType(orgsV1s);
    const studyOrgV1 = orgV1sByType[OrgType.TREE]?.[0];
    StateAssert.isDefined(studyOrgV1, 'Missing study org');

    const sitesAndCountries: OrgV1[] = [
      ...(orgV1sByType[OrgType.PRACTICE] ?? []),
      ...(orgV1sByType[OrgType.COUNTRY] ?? []),
    ];

    if (user.isActive) {
      const study = await StudyRestServiceV1.getStudy(toRestOptions(apiOptions));
      return StudyFactory.fromStudyV1(tenantId, studyOrgV1, sitesAndCountries, study, user);
    } else {
      const studyInfoV1 = await StudyRestServiceV1.getStudyInfo(toRestOptions(apiOptions));
      return StudyFactory.fromStudyInfoV1(tenantId, studyOrgV1, sitesAndCountries, studyInfoV1, user);
    }
  }

  async getStudyInfo(tenantId: string, apiOptions?: ApiOptions): Promise<StudyInfo> {
    const studyInfoV1 = await StudyRestServiceV1.getStudyInfo(toRestOptions(apiOptions));
    return StudyInfoFactory.from(tenantId, studyInfoV1);
  }

  async getStudy(user: User, token?: DmdpToken, apiOptions?: ApiOptions): Promise<Study> {
    const dmdpToken = token ?? DmdpTokenService.getToken();

    if (dmdpToken) {
      return await this.getStudyOrStudyInfo(dmdpToken.getTenantId(), user, apiOptions);
    } else {
      throw CspError.badState('Token is not set');
    }
  }

  /** This action is made using the Idp token. User will be logged out. */
  async getMyStudies(): Promise<Study[]> {
    const axiosForJwt = axiosIdp();
    const userInfos = await UserInfoRestServiceV1.getUserInfoByIdpJwt({ axios: axiosForJwt });

    const studyIds = userInfos.users.filter(u => u.status !== DmdpUserStatusType.INACTIVE).map(it => it.tenantId);

    const studies = await Promise.all(
      studyIds.map(async studyId => {
        const dmdpToken = await JwtRestServiceV1.issueDmdpTokenByIdpToken({ axios: axiosForJwt }, studyId);
        const axios = createAxiosWithJwtToken(dmdpToken.getJwtBase64());

        const user = await UserService.getMine({ axios });
        if (user.isVisible && userHasPermission(user.roles)) {
          const study = await this.getStudy(user, dmdpToken, { axios });
          if (study.name) {
            return study;
          } else {
            log('ERROR: Study name is undefined for tenant: ', studyId);
          }
        }

        return undefined;
      }),
    );

    return studies.filter(isDefined);
  }
}

export const studyService = new StudyService();
