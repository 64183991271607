/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Valid values for a field-criterion field in a Query
 */
export enum DataChangeCriteriaFieldsV1 {
    STUDY_SITE_ID = 'STUDY_SITE_ID',
    USER_ID = 'USER_ID',
    ECODE = 'ECODE',
    DOMAIN = 'DOMAIN',
    DATA_SOURCE_GROUP = 'DATA_SOURCE_GROUP',
    DATA_SOURCE = 'DATA_SOURCE',
    DATA_SOURCE_ID = 'DATA_SOURCE_ID',
    REVIEW_STATUS = 'REVIEW_STATUS',
    EVENT_ID = 'EVENT_ID',
}