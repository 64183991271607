import { CspBaseConfig, CspConfig, CspConfigurations, CspEnvironment } from '../model/CspConfig';

type CspConfigFactory = (env: CspEnvironment) => CspBaseConfig & CspConfig;

let currentEnv: CspEnvironment = 'dev';

const config: CspConfigurations = {
  dev: {
    tealiumEnv: 'dev',
    allowMockedConsents: true,
    hideSchemaValidationMessages: true,
  },
  sit: {
    tealiumEnv: 'dev',
  },
  ppt: {
    tealiumEnv: 'dev',
  },
  prd: {
    tealiumEnv: 'prod',
  },
};

export const createCspConfig: CspConfigFactory = env => {
  currentEnv = config[env] ? env : 'dev';
  return {
    env: currentEnv,
    ...config[currentEnv],
  };
};

export const getCspEnv = (): CspEnvironment => currentEnv;

export const getCspConfig = (): CspBaseConfig & CspConfig => createCspConfig(getCspEnv());

export const isDevEnv = (): boolean => getCspEnv() === 'dev';

export const isProdLikeEnv = (): boolean => getCspEnv() === 'ppt' || getCspEnv() === 'prd';
