import { ApiOptions, CountValue, Patient, RestOptions, UserId } from '@csp/csp-common-model';
import { ScheduleRef, ScheduleRequestInfoMapper } from '@csp/csp-common-scheduling';
import { toRestOptions } from '@csp/csp-fe-auth';
import { isNotEmpty } from '@csp/csp-common-util';
import { QuestionnaireRestServiceV2 } from '@csp/dmdp-api-client';
import { QuestionnaireResponseAggregateV2 } from '@csp/dmdp-api-questionnaire-dto';
import { chunk } from 'lodash/fp';
import {
  QuestionnaireDateUtil,
  QuestionnaireScheduleRequestInfo,
  QuestionnaireScheduleCachedQueryService,
  QuestionnaireType,
} from '@csp/csp-common-questionnaire';
import { QuestionnaireMetricCriteriaMapper } from '../mapper/QuestionnaireMetricCriteriaMapper';
import { ScheduleRequestDateUntilCriteria } from '../../model/ScheduleRequestDateUntilCriteria';
import { ScheduleRequestMetricCriterion } from '../../model/ScheduleRequestMetricCriterion';
import { ScheduleRequestMetricInfo } from '../../model/ScheduleRequestMetricInfo';
import { ScheduleRequestReviewCount } from '../../model/ScheduleRequestReviewCount';
import { QuestionnaireV2AggregateQueryMapper } from '../mapper/QuestionnaireV2AggregateQueryMapper';
import { QuestionnaireV2AggregateResultMapper } from '../mapper/QuestionnaireV2AggregateResultMapper';

// BOOST has a limit of 20 criterias per group, allow us to split it into as many requests as necessary
const chunksOf = chunk(20);

const getMetricInfoBase = async (
  userId: UserId,
  criteria: ScheduleRequestMetricCriterion[],
  { axios }: RestOptions,
): Promise<ScheduleRequestMetricInfo[]> => {
  if (!criteria.length) {
    return [];
  } else {
    const totalQueryV2 = QuestionnaireV2AggregateQueryMapper.toMetricCountAggregateV2(userId, criteria, true);
    const queries: QuestionnaireResponseAggregateV2[] = [totalQueryV2];

    const criteriaWithRecentPeriod = criteria.filter(crit => !!crit.recentPeriod);
    if (isNotEmpty(criteriaWithRecentPeriod)) {
      const recentQueryV2 = QuestionnaireV2AggregateQueryMapper.toMetricCountAggregateV2(
        userId,
        criteriaWithRecentPeriod,
        false,
      );
      queries.push(recentQueryV2);
    }

    const promises = queries.map(query => QuestionnaireRestServiceV2.aggregate({ axios }, query));
    const [totalResultV2, recentResultV2] = await Promise.all(promises);

    return QuestionnaireV2AggregateResultMapper.toScheduleRequestMetricInfos(
      criteria,
      totalResultV2?.groups ?? [],
      recentResultV2?.groups ?? [],
    );
  }
};

const getMetricInfo = async (
  userId: UserId,
  criteria: ScheduleRequestMetricCriterion[],
  { axios }: RestOptions,
): Promise<ScheduleRequestMetricInfo[]> => {
  const promises = chunksOf(criteria).map(criteria => getMetricInfoBase(userId, criteria, { axios }));
  const metricInfoChunks = await Promise.all(promises);
  const metricInfo = metricInfoChunks.flat();
  return metricInfo;
};

const countReviewNeeded = async (
  userId: UserId,
  scheduleRefs: ScheduleRef[],
  { axios }: RestOptions,
): Promise<ScheduleRequestReviewCount[]> => {
  if (!scheduleRefs.length) {
    return [];
  } else {
    const results = await Promise.all(
      chunksOf(scheduleRefs).map(async scheduleRefsChunk => {
        const queryV2 = QuestionnaireV2AggregateQueryMapper.toReviewNeededCountAggregateV2(userId, scheduleRefsChunk);
        const aggregateResultV2 = await QuestionnaireRestServiceV2.aggregate({ axios }, queryV2);

        return QuestionnaireV2AggregateResultMapper.toScheduleRequestReviewCounts(scheduleRefsChunk, aggregateResultV2);
      }),
    );

    return results.flat();
  }
};

const getMetricInfosForPatient = async (
  patient: Patient,
  apiOptions?: RestOptions,
): Promise<ScheduleRequestMetricInfo[]> => {
  if (patient.firstOrgId) {
    const restOptions = toRestOptions(apiOptions);
    const orgSchedules =
      await QuestionnaireScheduleCachedQueryService.getAllActivatedQuestionnaireSchedulesForOrganizationIds(
        [patient.firstOrgId],
        restOptions,
      );

    const scheduleRequests: QuestionnaireScheduleRequestInfo[] = ScheduleRequestInfoMapper.from(
      orgSchedules.getAllSchedules(),
      QuestionnaireDateUtil.getCustomActionDates(patient),
      QuestionnaireDateUtil.getWhileCriteriaValues(patient),
    );

    const questionnaireTypes = Object.keys(QuestionnaireType).filter(type => type !== QuestionnaireType.CLINRO);

    const filteredScheduleRequests = scheduleRequests.filter(({ request }) =>
      questionnaireTypes.includes(request.config.type),
    );

    const criteria = QuestionnaireMetricCriteriaMapper.toMetricCriteria(filteredScheduleRequests);

    const metricInfo = await getMetricInfo(patient.userId, criteria, restOptions);
    return metricInfo;
  }

  return [];
};

const getCountValueUntilDate = async (
  userId: UserId,
  criteria: ScheduleRequestDateUntilCriteria,
  apiOptions?: ApiOptions,
): Promise<CountValue> => {
  const queryV2 = QuestionnaireV2AggregateQueryMapper.toUntilDateCountAggregateV2(userId, criteria);
  const aggregateResultV2 = await QuestionnaireRestServiceV2.aggregate(toRestOptions(apiOptions), queryV2);

  return QuestionnaireV2AggregateResultMapper.toCountValue(aggregateResultV2);
};

export const QuestionnaireV2MetricAggregationService = {
  getMetricInfo,
  getMetricInfosForPatient,
  countReviewNeeded,
  getCountValueUntilDate,
};
