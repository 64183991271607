import { ErrorMessages } from '@csp/csp-common-form';
import { CspErrorType } from '@csp/csp-common-model';
import {
  WebHcpDefaultErrorEntry,
  WebHcpErrorMessagesEntry,
  WebHcpErrorPageEntry,
  WebHcpLoginPageEntry,
  WebHcpPasswordPageEntry,
} from '@csp/csp-web-content-definitions';
import { useMemo } from 'react';
import { StaticContentService } from '../service/StaticContentService';

export const errorMessagesFactory = (
  errorOnboardingPassword: WebHcpPasswordPageEntry,
  errorOnboardingLogin: WebHcpLoginPageEntry,
  errorMessages: WebHcpErrorMessagesEntry,
  errorPage: WebHcpErrorPageEntry,
): ErrorMessages => ({
  email: errorMessages.formValidation.input.email.validationError,
  minLength: errorMessages.formValidation.input.minLength.validationError,
  maxLength: errorMessages.formValidation.input.maxLength.validationError,
  correctHumanMeasurement: errorMessages.formValidation.input.correctHumanMeasurement.validationError,
  containsUpperCase: errorOnboardingPassword.formValidation.body.upperCaseViolation.text,
  containsLowerCase: errorOnboardingPassword.formValidation.body.lowerCaseViolation.text,
  containsNumber: errorOnboardingPassword.formValidation.body.oneNumberViolation.text,
  containsSpecialCharacter: errorOnboardingPassword.formValidation.body.specialCharacterViolation.text,
  internationalPhoneNumber: errorMessages.formValidation.input.internationalPhoneNumber.validationError,
  phoneNumber: errorMessages.formValidation.input.phoneNumber.validationError,
  required: errorMessages.formValidation.input.required.validationError,
  checkboxRequired: errorMessages.formValidation.input.checkBoxRequired.validationError,
  date: errorMessages.formValidation.input.date.validationError,
  ecode: errorMessages.formValidation.input.ecode.validationError,
  dateOfBirth: errorMessages.formValidation.input.dateOfBirth.validationError,
  notPast: errorMessages.formValidation.input.notPast.validationError,
  time: errorMessages.formValidation.input.time.validationError,
  inFuture: errorMessages.formValidation.input.inFuture.validationError,
  inPast: errorMessages.formValidation.input.inPast.validationError,
  minDate: errorMessages.formValidation.input.minDate.validationError,
  minTime: errorMessages.formValidation.input.minTime.validationError,
  maxTime: errorMessages.formValidation.input.maxTime.validationError,
  unexpected: errorPage.unexpectedError.header.text,

  [CspErrorType.AUTHENTICATION_FAILED]: errorOnboardingLogin.loginAuthError.header.text,
  [CspErrorType.FORBIDDEN]: errorPage.forbiddenError.header.text,
  [CspErrorType.BAD_STATE]: errorPage.unhandledClientError.header.text,
  [CspErrorType.UNAUTHORIZED]: errorMessages.requests.body.unauthorised.text,
  [CspErrorType.EXPIRED]: errorMessages.requests.body.expired.text,
  [CspErrorType.MALFORMED]: errorMessages.requests.body.malformed.text,
  [CspErrorType.TRY_LATER]: errorMessages.requests.body.tryLater.text,
});

const defaultErrorFactory = (defaultErrors: WebHcpDefaultErrorEntry): ErrorMessages => ({
  checkboxRequired: defaultErrors.defaultError.input.checkboxRequired.validationError,
  containsLowerCase: defaultErrors.defaultError.input.containsLowerCase.validationError,
  containsNumber: defaultErrors.defaultError.input.containsNumber.validationError,
  containsSpecialCharacter: defaultErrors.defaultError.input.containsSpecialCharacter.validationError,
  containsUpperCase: defaultErrors.defaultError.input.containsUpperCase.validationError,
  correctHumanMeasurement: defaultErrors.defaultError.input.correctHumanMeasurement.validationError,
  date: defaultErrors.defaultError.input.date.validationError,
  dateTime: defaultErrors.defaultError.input.dateTime.validationError,
  dateOfBirth: defaultErrors.defaultError.input.dateOfBirth.validationError,
  ecode: defaultErrors.defaultError.input.ecode.validationError,
  email: defaultErrors.defaultError.input.email.validationError,
  inFuture: defaultErrors.defaultError.input.inFuture.validationError,
  inPast: defaultErrors.defaultError.input.inPast.validationError,
  internationalPhoneNumber: defaultErrors.defaultError.input.internationalPhoneNumber.validationError,
  maxDate: defaultErrors.defaultError.input.maxDate.validationError,
  maxLength: defaultErrors.defaultError.input.maxLength.validationError,
  minDate: defaultErrors.defaultError.input.minDate.validationError,
  minTime: defaultErrors.defaultError.input.minTime.validationError,
  maxTime: defaultErrors.defaultError.input.maxTime.validationError,
  minLength: defaultErrors.defaultError.input.minLength.validationError,
  minArrayLength: defaultErrors.defaultError.input.minArrayLength.validationError,
  maxArrayLength: defaultErrors.defaultError.input.maxArrayLength.validationError,
  minValue: defaultErrors.defaultError.input.minValue.validationError,
  maxValue: defaultErrors.defaultError.input.maxValue.validationError,
  notPast: defaultErrors.defaultError.input.notPast.validationError,
  notPastTime: defaultErrors.defaultError.input.notPastTime.validationError,
  oneOf: defaultErrors.defaultError.input.oneOf.validationError,
  phoneNumber: defaultErrors.defaultError.input.phoneNumber.validationError,
  required: defaultErrors.defaultError.input.required.validationError,
  time: defaultErrors.defaultError.input.time.validationError,
  timeStep: defaultErrors.defaultError.input.timeStep.validationError,
  numericStep: defaultErrors.defaultError.input.numericStep.validationError,
  numberOfDecimals: defaultErrors.defaultError.input.numberOfDecimals.validationError,
  unexpected: defaultErrors.defaultError.body.unexpected.text,
  [CspErrorType.MALFORMED]: defaultErrors.cspErrorType.enum.MALFORMED,
  [CspErrorType.EXPIRED]: defaultErrors.cspErrorType.enum.EXPIRED,
  [CspErrorType.GONE]: defaultErrors.cspErrorType.enum.GONE,
  [CspErrorType.NOT_FOUND]: defaultErrors.cspErrorType.enum.NOT_FOUND,
  [CspErrorType.BAD_STATE]: defaultErrors.cspErrorType.enum.BAD_STATE,
  [CspErrorType.BAD_REQUEST]: defaultErrors.cspErrorType.enum.BAD_REQUEST,
  [CspErrorType.FORBIDDEN]: defaultErrors.cspErrorType.enum.FORBIDDEN,
  [CspErrorType.AUTHENTICATION_FAILED]: defaultErrors.cspErrorType.enum.AUTHENTICATION_FAILED,
  [CspErrorType.TRY_LATER]: defaultErrors.cspErrorType.enum.TRY_LATER,
  [CspErrorType.UNAUTHORIZED]: defaultErrors.cspErrorType.enum.UNAUTHORIZED,
  [CspErrorType.HTTP_COMMUNICATION]: defaultErrors.cspErrorType.enum.HTTP_COMMUNICATION,
  [CspErrorType.CONFLICT]: defaultErrors.cspErrorType.enum.CONFLICT,
  [CspErrorType.SERVER_ERROR]: defaultErrors.cspErrorType.enum.SERVER_ERROR,
  [CspErrorType.UNHANDLED_CLIENT_ERROR]: defaultErrors.cspErrorType.enum.UNHANDLED_CLIENT_ERROR,
  [CspErrorType.USER_NOT_ALLOWED]: defaultErrors.cspErrorType.enum.USER_NOT_ALLOWED,
  [CspErrorType.TIMEOUT]: defaultErrors.cspErrorType.enum.TIMEOUT,
  [CspErrorType.NETWORK_ERROR]: defaultErrors.cspErrorType.enum.NETWORK_ERROR,
  [CspErrorType.CANCELLED]: defaultErrors.cspErrorType.enum.CANCELLED,
  [CspErrorType.TOO_MANY_REQUESTS]: defaultErrors.cspErrorType.enum.TOO_MANY_REQUESTS,
  [CspErrorType.INVALID_CONFIGURATION]: defaultErrors.cspErrorType.enum.INVALID_CONFIGURATION,
});

export const useErrorMessages = (additionalErrorMessages?: ErrorMessages): ErrorMessages => {
  const webHcpDefaultError = StaticContentService.getPublicEntry('webHcpDefaultError');
  const errorOnboardingPassword = StaticContentService.getPublicEntry('webHcpPasswordPage');
  const errorOnboardingLogin = StaticContentService.getPublicEntry('webHcpLoginPage');
  const errorMessages = StaticContentService.getPublicEntry('webHcpErrorMessages');
  const errorPage = StaticContentService.getPublicEntry('webHcpErrorPage');
  return useMemo(
    () => ({
      ...defaultErrorFactory(webHcpDefaultError),
      ...errorMessagesFactory(errorOnboardingPassword, errorOnboardingLogin, errorMessages, errorPage),
      ...additionalErrorMessages,
    }),
    [
      errorOnboardingPassword,
      errorOnboardingLogin,
      errorMessages,
      errorPage,
      additionalErrorMessages,
      webHcpDefaultError,
    ],
  );
};
