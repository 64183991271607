import { Maybe } from '@csp/csp-common-model';
import { isString } from 'lodash';
import traverse from 'traverse';
import { ContentCacheObject } from '../../model/ContentCacheService';
import { ContentPackageType } from '../../model/ContentPackageType';
import { UnifyContentPackage } from '../../types/UnifyContentPackage';
import { setCachedPackage } from '../../utils/cacheUtil';
import { getContentClientType, getContentLocale, getContentVersion, getRole } from '../../utils/configUtil';
import { ContentstackAsset } from '../../utils/models/contentstackAsset';
import { BulkAssetSignedUrls } from '../model/BulkAssetSignedUrls';
import { ContentPackageParams } from '../model/ContentPackageParams';
import { AssetDeliveryServiceV1 } from '../service/AssetDeliveryServiceV1';

export const signedUrlsHaveExpired = (maybeCachedPackage: ContentCacheObject): boolean =>
  !!maybeCachedPackage.signedUrlExpirationTimeUnixMillis &&
  new Date().getTime() > maybeCachedPackage.signedUrlExpirationTimeUnixMillis;

export const fetchSignedAssetUrls = async (
  contentCacheObject: ContentCacheObject,
  packageType: ContentPackageType,
): Promise<UnifyContentPackage> => {
  const [contentPackage, expirationDate] = await maybeFetchAssetUrls(
    {
      packageType,
      clientType: getContentClientType(),
      version: getContentVersion(packageType),
      locale: getContentLocale(),
      role: getRole(),
    },
    contentCacheObject,
  );
  await setCachedPackage(packageType, contentCacheObject.etag, contentPackage, expirationDate);
  return contentPackage;
};

const maybeFetchAssetUrls = async (
  contentPackageDescriptor: ContentPackageParams,
  contentCacheObject: ContentCacheObject,
): Promise<[UnifyContentPackage, Maybe<number>]> => {
  const assetUrls = getAssetPathsFromPackage<string>(contentCacheObject.content, 'relative_path');
  if (assetUrls.length > 0) {
    const assetUrlsResponse = await AssetDeliveryServiceV1.fetchAssetUrls(contentPackageDescriptor, assetUrls);
    return [setAssetUrls(contentCacheObject.content, assetUrlsResponse.signedUrls), assetUrlsResponse.expirationDate];
  }
  return [contentCacheObject.content, undefined];
};

export const getAssetPathsFromPackage = <T>(contentPackage: UnifyContentPackage, matchKey: string): T[] => {
  const assetUrls: T[] = [];

  traverse(contentPackage).forEach(function (value) {
    if (this.key === matchKey) {
      if (isString(value)) {
        value = value.replace(/^assets\//, '');
      }
      assetUrls.push(value);
    }
  });

  return assetUrls;
};

export const isAsset = (maybeAssetNode: unknown): maybeAssetNode is ContentstackAsset =>
  (maybeAssetNode as ContentstackAsset)?.filename !== undefined &&
  (maybeAssetNode as ContentstackAsset)?.content_type !== undefined &&
  (maybeAssetNode as ContentstackAsset)?.file_size !== undefined &&
  (maybeAssetNode as ContentstackAsset)?.relative_path !== undefined;

export const setAssetUrls = (
  contentPackage: UnifyContentPackage,
  assetUrls: BulkAssetSignedUrls,
): UnifyContentPackage => {
  traverse(contentPackage).forEach(function (value) {
    if (isAsset(value)) {
      const path = value.relative_path.replace(/^assets\//, '');
      this.update({ ...value, url: assetUrls[path] }, true);
    }
  });

  return contentPackage;
};
