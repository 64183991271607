import {
  RpmConfigV1,
  RpmConfigV1Schema,
  RpmTrackerCardConfigV1,
  RpmTrackerCardTypeV1,
  RpmTrackerChartV1,
  RpmTrackerConfigV1,
  RpmTrackerParameterConfigV1,
  RpmTrackerParameterDataConfigV1,
} from '@csp/config-schemas';
import {
  Maybe,
  RpmConfig,
  RpmTrackerCardConfig,
  RpmTrackerCardType,
  RpmTrackerChartConfig,
  RpmTrackerConfig,
  RpmTrackerParameterConfig,
  RpmTrackerParameterDataConfig,
} from '@csp/csp-common-model';
import { isDefined, JsonValidationService } from '@csp/csp-common-util';

const toParameterDataConfig = (
  parameterDataConfigV1: RpmTrackerParameterDataConfigV1,
): Maybe<RpmTrackerParameterDataConfig> => {
  switch (parameterDataConfigV1.type) {
    case 'QUESTIONNAIRE_DATA':
      if (parameterDataConfigV1.dataQuery && parameterDataConfigV1.valueQuery) {
        return {
          type: parameterDataConfigV1.type,
          dataQuery: parameterDataConfigV1.dataQuery,
          valueQuery: parameterDataConfigV1.valueQuery,
        };
      }
      break;
    case 'INTERNAL_CHARTS':
      if (parameterDataConfigV1.chartDataRefs) {
        return {
          type: parameterDataConfigV1.type,
          chartDataRefs: parameterDataConfigV1.chartDataRefs,
        };
      }
      break;
  }
  return undefined;
};

const toParameterConfig = (parameterConfigV1: RpmTrackerParameterConfigV1): Maybe<RpmTrackerParameterConfig> => {
  const dataConfig = toParameterDataConfig(parameterConfigV1.dataConfig);
  return dataConfig
    ? {
        parameterCode: parameterConfigV1.parameterCode,
        type: parameterConfigV1.type,
        dataConfig,
      }
    : undefined;
};

const toChartConfig = (chartV1: RpmTrackerChartV1): RpmTrackerChartConfig => ({
  chartCode: chartV1.chartCode,
  type: chartV1.chartConfig.type,
  parameters: chartV1.chartConfig.parameters.map(toParameterConfig).filter(isDefined),
});

const toCardType = (type: RpmTrackerCardTypeV1): RpmTrackerCardType => {
  switch (type) {
    case 'DEFAULT':
      return 'OVERVIEW';
    default:
      return type;
  }
};

const toCardConfig = (cardConfigV1: RpmTrackerCardConfigV1): RpmTrackerCardConfig => ({
  cardCode: cardConfigV1.cardCode,
  type: toCardType(cardConfigV1.type),
  charts: cardConfigV1.charts.map(toChartConfig),
});

const toTrackerConfig = (trackerConfigV1: Maybe<RpmTrackerConfigV1>): Maybe<RpmTrackerConfig> => {
  if (trackerConfigV1) {
    return {
      daysToTrack: trackerConfigV1.daysToTrack,
      externalReportUrl: trackerConfigV1.externalReportUrl,
      cards: trackerConfigV1.cards.map(toCardConfig),
    };
  } else {
    return undefined;
  }
};
const toRpmConfig = (rpmConfigV1: RpmConfigV1): RpmConfig => {
  JsonValidationService.validateJsonNonStrict('RpmConfigV1.json', RpmConfigV1Schema, rpmConfigV1);

  return {
    algorithms: rpmConfigV1.patientRemoteMonitoring.algorithms.map(algorithm => ({
      algorithmCode: algorithm.algorithmCode,
      trackerConfig: toTrackerConfig(algorithm.trackerConfig),
    })),
    emailNotificationJobConfig: rpmConfigV1.emailNotificationJobConfig,
  };
};

export const RpmConfigService = {
  toRpmConfig,
};
