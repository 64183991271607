/* eslint-disable */
export const UnifyMedicationSchema = {
  title: 'UnifyMedication',
  type: 'object',
  properties: {
    title: { type: 'string' },
    countryCodes: {
      type: 'array',
      title: 'Countries',
      helper: 'Link will only be shown for users in these countries',
      items: {
        type: 'string',
        enum: [
          'AR',
          'AT',
          'AU',
          'BE',
          'BG',
          'BR',
          'CA',
          'CL',
          'CN',
          'CO',
          'CZ',
          'DE',
          'DK',
          'EG',
          'ES',
          'FI',
          'FR',
          'GB',
          'GR',
          'HK',
          'HU',
          'IE',
          'IL',
          'IN',
          'IT',
          'JP',
          'KR',
          'MT',
          'MX',
          'MY',
          'NL',
          'NO',
          'NZ',
          'PA',
          'PE',
          'PH',
          'PL',
          'PT',
          'RO',
          'RU',
          'SA',
          'SE',
          'SG',
          'SK',
          'SW',
          'TH',
          'TR',
          'TW',
          'UA',
          'US',
          'VE',
          'VN',
          'XX',
          'ZA',
          'ZZ',
        ],
      },
    },
    schedules: {
      title: 'Schedules',
      helper: "Click 'add new item' to add a new schedule",
      type: 'array',
      items: {
        type: 'object',
        properties: {
          scheduleCode: {
            title: 'Schedule Code',
            type: 'string',
            helper: 'The schedule code this medication content belongs to',
            placeholder: 'STUDY_PROTOCOL_MEDICATION',
          },
          medicationGroups: {
            title: 'Medication Groups',
            type: 'array',
            items: {
              title: 'Medication Group',
              type: 'object',
              properties: {
                requestCode: {
                  title: 'Request Code',
                  type: 'string',
                  helper: 'The request code for this medication group',
                  placeholder: 'SZC',
                },
                medicationGroupTitle: {
                  title: 'Name of Medication Group',
                  type: 'string',
                  helper: 'The name of the mediction group',
                  placeholder: 'Zomig',
                },
                appAboutMedicationGroup: {
                  title: 'Description of Medication Group',
                  type: 'string',
                  helper: 'The description of the medication group',
                },
                hcpAboutMedicationGroup: {
                  title: 'About Medication Group',
                  type: 'object',
                  helper: 'Section presenting info about the medication for HCP',
                  properties: {
                    title: {
                      title: 'Title of About Medication Group',
                      type: 'string',
                      helper: 'Title of about medication section',
                      placeholder: 'About Zomig',
                    },
                    descriptionMd: {
                      title: 'Description of About Medication Group',
                      type: 'string',
                      helper: 'Body text for about medication section',
                    },
                  },
                },
                allowedVersions: {
                  title: 'Medication Version List',
                  type: 'array',
                  items: {
                    type: 'object',
                    properties: {
                      version: {
                        type: 'string',
                        placeholder: '1',
                        helper: 'A version number that this medication applies to',
                      },
                    },
                  },
                },
                medicationOptions: {
                  title: 'Medication Options',
                  type: 'array',
                  helper: 'Available medication options for this medication',
                  items: {
                    title: 'Medication Option',
                    type: 'object',
                    properties: {
                      medicationCode: {
                        title: 'Medication Code',
                        type: 'string',
                        helper: 'Unique medication code this medication option',
                        placeholder: 'SZC_5G_POWDER_PLACEBO',
                      },
                      dosageInstructions: {
                        title: 'Dosage Instructions',
                        type: 'array',
                        helper: 'Available dosage instructions',
                        items: {
                          title: 'Dosage Instruction',
                          type: 'object',
                          properties: {
                            dosageInstructionCode: {
                              title: 'Dosage Instruction Code',
                              type: 'string',
                              helper: '',
                              placeholder: '1_RED_PILL_MORNING_WITH_WATER',
                            },
                            dose: { title: 'Dose', type: 'string', placeholder: '5 g or placebo' },
                            numberOfDosages: { title: 'Number of Dosages', type: 'string', placeholder: '1' },
                            medicationForm: {
                              title: 'Medication Form',
                              type: 'string',
                              placeholder: '1 Powder for oral suspension',
                            },
                            timingInstruction: {
                              title: 'Timing Instruction',
                              type: 'string',
                              placeholder: 'Once daily on non-dialyze days',
                            },
                            dosingInstruction: {
                              title: 'Dosing Instruction',
                              type: 'string',
                              placeholder:
                                'Take 1 white label sachet (5 g) placebo. Suspend the sachet in 45 ml of water and swallow.',
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
      mock: [
        {
          scheduleCode: 'STUDY_PROTOCOL_MEDICATION',
          medicationGroups: [
            {
              requestCode: 'SZC',
              medicationGroupTitle: 'Sodium Zirconium Cyclosilicate',
              appAboutMedicationGroup: 'SZC description',
              hcpAboutMedicationGroup: {
                title: 'About Sodium Zirconium Cyclosilicate',
                descriptionMd:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
              },
              allowedVersions: [{ version: '1.0' }],
              medicationOptions: [
                {
                  medicationCode: 'SZC_5G_POWDER_PLACEBO',
                  dosageInstructions: [
                    {
                      dosageInstructionCode: '1_WHITE_LABEL_SACHET_MORNING_WITH_WATER',
                      dose: '5 g or placebo',
                      numberOfDosages: '1',
                      medicationForm: 'Powder for oral suspension',
                      timingInstruction: 'Once daily on non-dialyze days',
                      dosingInstruction:
                        'Take 1 white label sachet (5 g) or placebo. Suspend the sachet in 45 ml of water and swallow.',
                    },
                  ],
                },
                {
                  medicationCode: 'SZC_10G_POWDER_PLACEBO',
                  dosageInstructions: [
                    {
                      dosageInstructionCode: '1_BLUE_LABEL_SACHET_MORNING_WITH_WATER',
                      dose: '10 g or placebo',
                      numberOfDosages: '1',
                      medicationForm: 'Powder for oral suspension',
                      timingInstruction: 'Once daily on non-dialyze days',
                      dosingInstruction:
                        'Take 1 blue label sachet (10 g) or placebo. Suspend the sachet in 45 ml of water and swallow.',
                    },
                  ],
                },
                {
                  medicationCode: 'SZC_15G_POWDER_PLACEBO',
                  dosageInstructions: [
                    {
                      dosageInstructionCode: '1_BLUE_AND_WHITE_LABEL_SACHET_MORNING_WITH_WATER',
                      dose: '15 g or placebo',
                      numberOfDosages: '1',
                      medicationForm: 'Powder for oral suspension',
                      timingInstruction: 'Once daily on non-dialyze days',
                      dosingInstruction:
                        'Take 1 white label sachet (5 g) and 1 blue label sachet (10 g) or placebo. Suspend the sachet in 45 ml of water and swallow.',
                    },
                  ],
                },
              ],
            },
            {
              requestCode: 'IRESSA',
              medicationGroupTitle: 'Iressa',
              appAboutMedicationGroup: 'Iressa description',
              hcpAboutMedicationGroup: {
                title: 'About Iressa',
                descriptionMd:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
              },
              allowedVersions: [{ version: '1.0' }],
              medicationOptions: [
                {
                  medicationCode: 'IRESSA_5MG_PILL_PLACEBO',
                  dosageInstructions: [
                    {
                      dosageInstructionCode: '1_RED_PILL_MORNING_WITH_WATER',
                      dose: '5 mg or placebo',
                      numberOfDosages: '1',
                      medicationForm: 'Pill for oral adminstration',
                      timingInstruction: 'Once daily on non-dialyze days',
                      dosingInstruction: 'Take 1 red pill. Swallow with water.',
                    },
                  ],
                },
                {
                  medicationCode: 'IRESSA_10MG_PILL_PLACEBO',
                  dosageInstructions: [
                    {
                      dosageInstructionCode: '1_BLUE_PILL_MORNING_WITH_WATER',
                      dose: '10 mg or placebo',
                      numberOfDosages: '1',
                      medicationForm: 'Pill for oral adminstration',
                      timingInstruction: 'Once daily on non-dialyze days',
                      dosingInstruction: 'Take 1 blue pill. Swallow with water.',
                    },
                  ],
                },
                {
                  medicationCode: 'IRESSA_15MG_PILL_PLACEBO',
                  dosageInstructions: [
                    {
                      dosageInstructionCode: '1_BLUE_AND_1_RED_PILL_MORNING_WITH_WATER',
                      dose: '15 mg or placebo',
                      numberOfDosages: '1',
                      medicationForm: 'Pill for oral adminstration',
                      timingInstruction: 'Once daily on non-dialyze days',
                      dosingInstruction: 'Take 1 blue pill and one red pill. Swallow with water.',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  description: 'unify:medication',
  $schema: 'http://json-schema.org/draft-07/schema#',
};
