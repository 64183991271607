import {
  fromTimezoneStr,
  fromTimezoneStrOrUndefined,
  nowToTimezoneStrCurrentZone,
  zeroZonedDateTime,
  ZonedDateTimeFormatter,
} from '@csp/csp-common-date-util';
import { Patient, PatientRescreenMeta } from '@csp/csp-common-model';
import { getMeta } from '@csp/csp-common-util';
import {
  createPatientRescreenMetaV1,
  PATIENT_RESCREEN_V1_META_TYPE,
  PatientRescreenMetaV1,
  PatientRescreenV1,
  UserV1,
} from '@csp/dmdp-api-user-dto';
import { orderBy } from 'lodash';

const emptyPatientRescreenMetaV1 = (): PatientRescreenMetaV1 =>
  createPatientRescreenMetaV1({
    rescreens: [],
  });

const toPatientRescreenV1 = (patient: Patient): PatientRescreenV1 => ({
  userId: patient.userId,
  localTimestamp: nowToTimezoneStrCurrentZone(),
  screenedTimestamp:
    patient.screenedTimestamp && ZonedDateTimeFormatter.toZonedDateTimeString(patient.screenedTimestamp),
});

const getPatientRescreenMetaV1 = (patient: Patient): PatientRescreenMetaV1 =>
  getMeta(patient.userV1.metas, PATIENT_RESCREEN_V1_META_TYPE) ?? emptyPatientRescreenMetaV1();

const createUpdatedPatientRescreenMetaV1 = (patient: Patient): PatientRescreenMetaV1 =>
  createPatientRescreenMetaV1({
    rescreens: [toPatientRescreenV1(patient), ...getPatientRescreenMetaV1(patient).data.rescreens],
  });

const fromPatientRescreenMetaV1 = ({ data }: PatientRescreenMetaV1): PatientRescreenMeta => {
  const latestRescreen = orderBy(
    data.rescreens,
    rescreen => fromTimezoneStr(rescreen.localTimestamp).unixTimeMillis,
    'desc',
  )[0];

  const numRescreens = data.rescreens.length;

  if (numRescreens > 0 && latestRescreen) {
    const previousScreeningDates = data.rescreens.map(
      patientRescreenV1 => fromTimezoneStrOrUndefined(patientRescreenV1.screenedTimestamp) ?? zeroZonedDateTime,
    );

    return {
      hasBeenRescreened: true,
      numRescreens,
      previousUserId: latestRescreen.userId,
      rescreenedTimestamp: fromTimezoneStr(latestRescreen.localTimestamp),
      previousScreeningDates,
    };
  } else {
    return {
      hasBeenRescreened: false,
    };
  }
};

const fromUserV1 = (userV1: UserV1): PatientRescreenMeta => {
  const rescreenMetaV1 = getMeta(userV1.metas, PATIENT_RESCREEN_V1_META_TYPE) ?? emptyPatientRescreenMetaV1();
  return fromPatientRescreenMetaV1(rescreenMetaV1);
};

export const PatientRescreenMetaFactory = {
  toPatientRescreenV1,
  createUpdatedPatientRescreenMetaV1,
  fromPatientRescreenMetaV1,
  fromUserV1,
};
