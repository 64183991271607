/* eslint-disable */
export const UnifyWebTechnicalSupportSchema = {
  title: 'UnifyWebTechnicalSupport',
  type: 'object',
  properties: {
    title: { type: 'string', title: 'Title' },
    drawerHeader: { type: 'string', title: 'Header', mock: 'Support' },
    header: { type: 'string', title: 'Header', mock: 'Do you have techincal issues?' },
    description: {
      type: 'string',
      title: 'description',
      mock: 'Please contact the technical support to get help or visit the FAQs',
    },
    contactInformation: {
      title: 'Contact information',
      type: 'object',
      properties: {
        label: { type: 'string', title: 'Contact Label', mock: 'CONTACT' },
        phoneNumber: { type: 'string', title: 'Support phone number', mock: '1-800-236-9933' },
        email: { type: 'string', title: 'Support email', mock: 'supportemail@astrazeneca.com' },
      },
    },
    openingHours: {
      title: 'Opening Hours',
      type: 'object',
      properties: {
        label: { type: 'string', title: 'Availability label', mock: 'AVAILABILITY' },
        weekdays: { type: 'string', title: 'Weekday support phone number', mock: 'Monday - Friday 8 a.m. - 6 p.m.' },
        weekend: { type: 'string', title: 'Weekend support phone number', mock: 'Saturday - Sunday 2 p.m. - 6 p.m.' },
      },
    },
    trainingMaterials: {
      title: 'Training Materials',
      type: 'object',
      properties: {
        header: { type: 'string', title: 'Header', mock: 'Do you need Unify training materials' },
        description: {
          type: 'string',
          title: 'Description',
          mock: 'Find below the Unify user guide and user training for the study',
        },
        versionPublishedLabel: { type: 'string', title: 'Version Published Label', mock: 'Published on' },
        linkText: { type: 'string', title: 'Link Text', mock: 'View' },
      },
    },
  },
  description: 'unify:webtechnicalsupport',
  $schema: 'http://json-schema.org/draft-07/schema#',
};
