import { daysToSeconds } from '@csp/csp-common-date-util';
import { MemCacheService } from '@csp/csp-common-memcache';
import { ApiOptions, SiteId, TenantId } from '@csp/csp-common-model';
import { isEmpty, uniq } from 'lodash/fp';
import { DeviceMetricService } from '../device/service/DeviceMetricService';
import { ScheduleRequestComplianceRef } from '../model/ScheduleRequestComplianceRef';
import { QuestionnaireMetricService } from '../questionnaire/service/QuestionnaireMetricService';

type CacheKey = [string, TenantId];

const getSiteIdsWithCompliance = async (studySiteIds: SiteId[], apiOptions?: ApiOptions): Promise<SiteId[]> => {
  const [qComplianceSummary, deviceComplianceSummary] = await Promise.all([
    QuestionnaireMetricService.getRequestComplianceRefs(studySiteIds, apiOptions),
    DeviceMetricService.getRequestComplianceRefs(studySiteIds, apiOptions),
  ]);

  const requestComplianceRefs: ScheduleRequestComplianceRef[] = [
    ...qComplianceSummary.requestsWithCompliance,
    ...deviceComplianceSummary,
  ];

  return uniq(requestComplianceRefs).map(r => r.siteId);
};

const getSiteIdsWithComplianceCachedByTenant = async (
  tenantId: TenantId,
  studySiteIds: SiteId[],
  cacheTtlSecs = daysToSeconds(365),
  apiOptions?: ApiOptions,
): Promise<SiteId[]> => {
  if (isEmpty(studySiteIds)) {
    return [];
  } else {
    const key: CacheKey = ['compliance.study.siteIds', tenantId];
    const cachedSiteIdsWithCompliance = MemCacheService.getValue<SiteId[]>(key);
    if (cachedSiteIdsWithCompliance) {
      return cachedSiteIdsWithCompliance;
    } else {
      const siteIdsWithCompliance = await getSiteIdsWithCompliance(studySiteIds, apiOptions);
      MemCacheService.setValue(key, cacheTtlSecs, siteIdsWithCompliance);
      return siteIdsWithCompliance;
    }
  }
};

export const StudyComplianceService = {
  getSiteIdsWithComplianceCachedByTenant,
};
