import { FeatureToggleCountriesFilter, RawConfigValue, RawCspFeatureConfigV2 } from '@csp/config-schemas';
import { ConfigValue, Maybe } from '@csp/csp-common-model';
import { DeepConfigValue } from '../model/DeepConfigValue';
import { DeepRawConfigValue } from '../model/DeepRawConfigValue';

const isCountryFilter = (arg: unknown): arg is FeatureToggleCountriesFilter =>
  (arg as FeatureToggleCountriesFilter).operator !== undefined;

const evalCountryExcludeConfig = (
  operator: Maybe<FeatureToggleCountriesFilter['operator']>,
  siteFound: boolean,
): ConfigValue => {
  switch (operator) {
    case 'exclude':
      return siteFound ? 'enabled' : 'disabled_on_site';
    case 'include':
      return siteFound ? 'disabled_on_site' : 'enabled';
    default:
      return 'disabled_on_country';
  }
};

const evalCountryIncludeConfig = (
  operator: Maybe<FeatureToggleCountriesFilter['operator']>,
  siteFound: boolean,
): ConfigValue => {
  switch (operator) {
    case 'exclude':
      return siteFound ? 'disabled_on_site' : 'enabled';
    case 'include':
      return siteFound ? 'enabled' : 'disabled_on_site';
    default:
      return 'enabled';
  }
};

const findNotOrOnlyCountry = (
  filter: FeatureToggleCountriesFilter,
  countryCode: string,
  userSite?: string,
): ConfigValue => {
  const country = filter.countries?.find(x => x.country.toLocaleLowerCase() === countryCode.toLocaleLowerCase());
  const site = country?.sites?.find(site => site.toLocaleLowerCase() === userSite?.toLocaleLowerCase());
  const countryOperator = filter.operator;
  const siteOperator = country?.operator;

  switch (countryOperator) {
    case 'exclude':
      return country ? evalCountryExcludeConfig(siteOperator, !!site) : 'enabled';
    case 'include':
      return country ? evalCountryIncludeConfig(siteOperator, !!site) : 'disabled_on_country';
    default:
      return 'enabled';
  }
};

const parseFeature = (
  feature: RawConfigValue | DeepRawConfigValue | unknown,
  country: string,
  site?: string,
): ConfigValue | DeepConfigValue => {
  if (feature && typeof feature === 'object') {
    if (isCountryFilter(feature)) {
      return findNotOrOnlyCountry(feature, country, site);
    }

    return Object.entries(feature).reduce(
      (parsedFeature, [key, value]) => ({ ...parsedFeature, [key]: parseFeature(value, country, site) }),
      {},
    );
  }

  return feature ? 'enabled' : 'disabled';
};

const parseFeatureConfig = <T>(
  cspConfig: RawCspFeatureConfigV2 | DeepRawConfigValue,
  country: string,
  site?: string,
): T =>
  Object.entries(cspConfig).reduce(
    (config, [key, value]) => ({
      ...config,
      [key]: parseFeature(value, country, site),
    }),
    {},
  ) as T;

export const FeatureToggleParserUtil = {
  parseFeatureConfig,
};
