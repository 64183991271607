import { DataChangeItem, DataChangeReason, DataChangeStatus, DataChangeType } from '@csp/csp-common-dcf-model';
import { PagedResultMapper } from '@csp/csp-common-mapper';
import { ApiOptions, PagedResult, Patient, Study } from '@csp/csp-common-model';
import { toRestOptions } from '@csp/csp-fe-auth';
import { DataChangeRestServiceV1 } from '@csp/dmdp-api-client';
import { Page } from '@csp/dmdp-api-common-dto';
import { DataChangeMapper } from '../mapper/DataChangeMapper';
import { DataChangeQueryMapper } from '../mapper/DataChangeQueryMapper';
import { DataChangeQueryByDataSource } from '../model/DataChangeQueryByDataSource';
import { DataChangeQueryFilter } from '../model/DataChangeQueryFilter';
import { DataSourceIn } from '../model/DataSourceIn';

export type DataChangeItemsPage = PagedResult<DataChangeItem>;

const getDataChangeById = async (id: string, study: Study, apiOptions?: ApiOptions): Promise<DataChangeItem> => {
  const dataChange = await DataChangeRestServiceV1.getDataChangeById(toRestOptions(apiOptions), id);

  return DataChangeMapper.toDataChangeItem(dataChange, study);
};

const getDataChanges = async (study: Study, page?: Page, apiOptions?: ApiOptions): Promise<DataChangeItem[]> => {
  const { dataChanges } = await DataChangeRestServiceV1.queryDataChanges(toRestOptions(apiOptions), {}, page);

  return DataChangeMapper.toDataChangeItems(dataChanges, study);
};

const getDataChangesByDataSource = async (
  { dataSource, dataSourceId, sort }: DataChangeQueryByDataSource,
  study: Study,
  page?: Page,
  apiOptions?: ApiOptions,
): Promise<DataChangeItem[]> => {
  const query = DataChangeQueryMapper.byDataSource({ dataSource, dataSourceId, sort });
  const { dataChanges } = await DataChangeRestServiceV1.queryDataChanges(toRestOptions(apiOptions), query, page);

  return DataChangeMapper.toDataChangeItems(dataChanges, study);
};

const getDataChangesByFilter = async (
  filter: DataChangeQueryFilter,
  study: Study,
  next?: string,
  apiOptions?: ApiOptions,
): Promise<DataChangeItemsPage> => {
  const query = DataChangeQueryMapper.byFilter(filter);
  const { dataChanges, paging } = await DataChangeRestServiceV1.queryDataChanges(toRestOptions(apiOptions), query, {
    next,
    limit: filter.pageSize,
    count: !!filter.count,
  });

  const data = DataChangeMapper.toDataChangeItems(dataChanges, study);

  return PagedResultMapper.toPagedResult(paging || {}, data);
};

const getAllDataChangesByFilter = async (
  filter: DataChangeQueryFilter,
  study: Study,
  apiOptions?: ApiOptions,
): Promise<DataChangeItem[]> => {
  const dataChanges: DataChangeItem[] = [];
  let resultPage: DataChangeItemsPage | undefined;
  do {
    resultPage = await getDataChangesByFilter(
      { ...filter, pageSize: Page.maxLimit, count: true },
      study,
      resultPage?.next ?? '0',
      apiOptions,
    );
    dataChanges.push(...resultPage.data);
  } while (dataChanges.length < (resultPage?.count ?? 0));

  return dataChanges;
};

const createDataChange = async (
  patient: Patient,
  study: Study,
  typeOfChange: DataChangeType,
  reasonForChange: DataChangeReason,
  evidenceForChangeExists: boolean,
  dataSourceIn: DataSourceIn,
  apiOptions?: ApiOptions,
): Promise<DataChangeItem> => {
  const dataChangeInV1 = DataChangeMapper.toDataChangeInV1(
    patient,
    study,
    typeOfChange,
    reasonForChange,
    evidenceForChangeExists,
    dataSourceIn,
  );
  const dataChange = await DataChangeRestServiceV1.createDataChange(toRestOptions(apiOptions), dataChangeInV1);

  return DataChangeMapper.toDataChangeItem(dataChange, study);
};

const deleteDataChangeById = async (id: string, apiOptions?: ApiOptions): Promise<void> => {
  await DataChangeRestServiceV1.deleteDataChangeById(toRestOptions(apiOptions), id);
};

const reviewDataChangeById = async (id: string, apiOptions?: ApiOptions): Promise<void> => {
  await DataChangeRestServiceV1.updateReviewStatus(toRestOptions(apiOptions), id, {
    reviewStatus: DataChangeStatus.REVIEWED,
  });
};

export const DataChangeService = {
  getDataChanges,
  getDataChangesByDataSource,
  getDataChangesByFilter,
  getAllDataChangesByFilter,
  getDataChangeById,
  createDataChange,
  deleteDataChangeById,
  reviewDataChangeById,
};
