/* eslint-disable */
export const UnifyEQ5D5L_V1Schema = {
  title: 'UnifyEQ5D5L_V1',
  type: 'object',
  properties: {
    title: { type: 'string' },
    version: { type: 'string', mock: '1', enum: ['1'] },
    copyright: {
      type: 'string',
      mock: '© EuroQol Research Foundation. EQ-5D™ is a trade mark of the EuroQol Research Foundation. UK (English) v2.1',
    },
    startItem: {
      title: 'First Questionnaire Item',
      helper: 'The first screen to show in the app',
      type: 'string',
      mock: 'EQ5D_INFO',
      enum: ['EQ5D_INFO', 'EQ5D_TRAINING'],
    },
    EQ5D_TRAINING: {
      title: 'Questionnaire Training EQ5D5LV1',
      type: 'object',
      properties: {
        questionnaireItemType: {
          title: 'Questionnaire Item Type',
          type: 'string',
          mock: 'TRAINING',
          enum: ['TRAINING'],
        },
        headerMd: { title: 'Header', type: 'string', mock: 'Questionnaire training' },
        subheaderMd: { title: 'Sub-Header', type: 'string', mock: '' },
        body: { title: 'Body', type: 'string', mock: '' },
        video: { type: 'object', _attachment: { id: 'trainingVideo' } },
        nextId: {
          title: 'Next Questionnaire Item',
          helper: 'Which screen is shown before',
          type: 'string',
          mock: 'EQ5D_INFO',
          enum: ['EQ5D_INFO'],
        },
      },
    },
    EQ5D_INFO: {
      title: 'Questionnaire Info EQ5D5LV1',
      type: 'object',
      properties: {
        questionnaireItemType: {
          title: 'Questionnaire Item Type',
          type: 'string',
          mock: 'QUESTIONNAIRE_INFO',
          enum: ['QUESTIONNAIRE_INFO'],
        },
        headerMd: { title: 'Header', type: 'string', mock: 'Health questionnaire' },
        subheaderMd: { title: 'Sub-Header', type: 'string', mock: 'Health questionnaire' },
        nextId: {
          title: 'Next Questionnaire Item',
          helper: 'Which screen is shown before',
          type: 'string',
          mock: 'EQ5D0201_INSTRUCTION',
          enum: ['EQ5D0201_INSTRUCTION'],
        },
      },
    },
    EQ5D0201_INSTRUCTION: {
      title: 'Radio Button Question Instruction Header EQ5D5LV1',
      type: 'object',
      properties: {
        questionnaireItemType: {
          title: 'Questionnaire Item Type',
          type: 'string',
          mock: 'INSTRUCTION',
          enum: ['INSTRUCTION'],
        },
        body: {
          title: 'Instruction body',
          type: 'string',
          mock: 'Please select the one box that describes your health TODAY',
        },
        previousId: {
          title: 'Previous Questionnaire Item',
          helper: 'Which screen is shown before',
          type: 'string',
          mock: 'EQ5D_INFO',
          enum: ['EQ5D_INFO'],
        },
        nextId: {
          title: 'Next Questionnaire Item',
          helper: 'Which screen is shown before',
          type: 'string',
          mock: 'EQ5D0201',
          enum: ['EQ5D0201'],
        },
      },
    },
    EQ5D0201: {
      type: 'object',
      title: 'Question 1 EQ5D5LV1',
      helper: 'EQ5D0201',
      properties: {
        questionnaireItemType: {
          title: 'Questionnaire Item Type',
          type: 'string',
          mock: 'QUESTION',
          enum: ['QUESTION'],
        },
        questionType: { title: 'Question Type', type: 'string', mock: 'RADIO_BUTTON', enum: ['RADIO_BUTTON'] },
        headerMd: { type: 'string', mock: 'MOBILITY' },
        optional: { title: 'Optional question', type: 'boolean', mock: false },
        answers: {
          type: 'object',
          title: 'Answers EQ5D5LV1',
          helper: 'Available answer options',
          properties: {
            '1': {
              type: 'object',
              title: 'Answer Option 1 EQ5D5LV1',
              properties: {
                text: { type: 'string', mock: 'I have no problems in walking about' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0201_INSTRUCTION',
                  enum: ['EQ5D0201_INSTRUCTION'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0202',
                  enum: ['EQ5D0202'],
                },
              },
            },
            '2': {
              type: 'object',
              title: 'Answer Option 2 EQ5D5LV1',
              properties: {
                text: { type: 'string', mock: 'I have slight problems in walking about' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0201_INSTRUCTION',
                  enum: ['EQ5D0201_INSTRUCTION'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0202',
                  enum: ['EQ5D0202'],
                },
              },
            },
            '3': {
              type: 'object',
              title: 'Answer Option 3 EQ5D5LV1',
              properties: {
                text: { type: 'string', mock: 'I have moderate problems in walking about' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0201_INSTRUCTION',
                  enum: ['EQ5D0201_INSTRUCTION'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0202',
                  enum: ['EQ5D0202'],
                },
              },
            },
            '4': {
              type: 'object',
              title: 'Answer Option 4 EQ5D5LV1',
              properties: {
                text: { type: 'string', mock: 'I have severe problems in walking about' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0201_INSTRUCTION',
                  enum: ['EQ5D0201_INSTRUCTION'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0202',
                  enum: ['EQ5D0202'],
                },
              },
            },
            '5': {
              type: 'object',
              title: 'Answer Option 5 EQ5D5LV1',
              properties: {
                text: { type: 'string', mock: 'I am unable to walk about' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0201_INSTRUCTION',
                  enum: ['EQ5D0201_INSTRUCTION'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0202',
                  enum: ['EQ5D0202'],
                },
              },
            },
          },
        },
      },
    },
    EQ5D0202: {
      type: 'object',
      title: 'Question 2 EQ5D5LV1',
      helper: 'EQ5D0202',
      properties: {
        questionnaireItemType: {
          title: 'Questionnaire Item Type',
          type: 'string',
          mock: 'QUESTION',
          enum: ['QUESTION'],
        },
        questionType: { title: 'Question Type', type: 'string', mock: 'RADIO_BUTTON', enum: ['RADIO_BUTTON'] },
        headerMd: { type: 'string', mock: 'SELF CARE' },
        optional: { title: 'Optional question', type: 'boolean', mock: false },
        answers: {
          type: 'object',
          title: 'Answers EQ5D5LV1',
          helper: 'Available answer options',
          properties: {
            '1': {
              type: 'object',
              title: 'Answer Option 1 EQ5D5LV1',
              properties: {
                text: { type: 'string', mock: 'I have no problems in walking about' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0201',
                  enum: ['EQ5D0201'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0203',
                  enum: ['EQ5D0203'],
                },
              },
            },
            '2': {
              type: 'object',
              title: 'Answer Option 2 EQ5D5LV1',
              properties: {
                text: { type: 'string', mock: 'I have slight problems in walking about' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0201',
                  enum: ['EQ5D0201'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0203',
                  enum: ['EQ5D0203'],
                },
              },
            },
            '3': {
              type: 'object',
              title: 'Answer Option 3 EQ5D5LV1',
              properties: {
                text: { type: 'string', mock: 'I have moderate problems in walking about' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0201',
                  enum: ['EQ5D0201'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0203',
                  enum: ['EQ5D0203'],
                },
              },
            },
            '4': {
              type: 'object',
              title: 'Answer Option 4 EQ5D5LV1',
              properties: {
                text: { type: 'string', mock: 'I have severe problems in walking about' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0201',
                  enum: ['EQ5D0201'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0203',
                  enum: ['EQ5D0203'],
                },
              },
            },
            '5': {
              type: 'object',
              title: 'Answer Option 5 EQ5D5LV1',
              properties: {
                text: { type: 'string', mock: 'I am unable to walk about' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0201',
                  enum: ['EQ5D0201'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0203',
                  enum: ['EQ5D0203'],
                },
              },
            },
          },
        },
      },
    },
    EQ5D0203: {
      type: 'object',
      title: 'Question 3 EQ5D5LV1',
      helper: 'EQ5D0203',
      properties: {
        questionnaireItemType: {
          title: 'Questionnaire Item Type',
          type: 'string',
          mock: 'QUESTION',
          enum: ['QUESTION'],
        },
        questionType: { title: 'Question Type', type: 'string', mock: 'RADIO_BUTTON', enum: ['RADIO_BUTTON'] },
        headerMd: { type: 'string', mock: 'USUAL ACTIVITIES' },
        subHeader: {
          type: 'string',
          title: 'Sub-Header',
          mock: '(e.g. work, study, housework, family or leisure activities)',
        },
        optional: { title: 'Optional question', type: 'boolean', mock: false },
        answers: {
          type: 'object',
          title: 'Answers EQ5D5LV1',
          helper: 'Available answer options',
          properties: {
            '1': {
              type: 'object',
              title: 'Answer Option 1 EQ5D5LV1',
              properties: {
                text: { type: 'string', mock: 'I have no problems in walking about' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0202',
                  enum: ['EQ5D0202'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0204',
                  enum: ['EQ5D0204'],
                },
              },
            },
            '2': {
              type: 'object',
              title: 'Answer Option 2 EQ5D5LV1',
              properties: {
                text: { type: 'string', mock: 'I have slight problems in walking about' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0202',
                  enum: ['EQ5D0202'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0204',
                  enum: ['EQ5D0204'],
                },
              },
            },
            '3': {
              type: 'object',
              title: 'Answer Option 3 EQ5D5LV1',
              properties: {
                text: { type: 'string', mock: 'I have moderate problems in walking about' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0202',
                  enum: ['EQ5D0202'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0204',
                  enum: ['EQ5D0204'],
                },
              },
            },
            '4': {
              type: 'object',
              title: 'Answer Option 4 EQ5D5LV1',
              properties: {
                text: { type: 'string', mock: 'I have severe problems in walking about' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0202',
                  enum: ['EQ5D0202'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0204',
                  enum: ['EQ5D0204'],
                },
              },
            },
            '5': {
              type: 'object',
              title: 'Answer Option 5 EQ5D5LV1',
              properties: {
                text: { type: 'string', mock: 'I am unable to walk about' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0202',
                  enum: ['EQ5D0202'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0204',
                  enum: ['EQ5D0204'],
                },
              },
            },
          },
        },
      },
    },
    EQ5D0204: {
      type: 'object',
      title: 'Question 4 EQ5D5LV1',
      helper: 'EQ5D0204',
      properties: {
        questionnaireItemType: {
          title: 'Questionnaire Item Type',
          type: 'string',
          mock: 'QUESTION',
          enum: ['QUESTION'],
        },
        questionType: { title: 'Question Type', type: 'string', mock: 'RADIO_BUTTON', enum: ['RADIO_BUTTON'] },
        headerMd: { type: 'string', mock: 'PAIN / DISCOMFORT' },
        optional: { title: 'Optional question', type: 'boolean', mock: false },
        answers: {
          type: 'object',
          title: 'Answers EQ5D5LV1',
          helper: 'Available answer options',
          properties: {
            '1': {
              type: 'object',
              title: 'Answer Option 1 EQ5D5LV1',
              properties: {
                text: { type: 'string', mock: 'I have no problems in walking about' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0203',
                  enum: ['EQ5D0203'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0205',
                  enum: ['EQ5D0205'],
                },
              },
            },
            '2': {
              type: 'object',
              title: 'Answer Option 2 EQ5D5LV1',
              properties: {
                text: { type: 'string', mock: 'I have slight problems in walking about' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0203',
                  enum: ['EQ5D0203'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0205',
                  enum: ['EQ5D0205'],
                },
              },
            },
            '3': {
              type: 'object',
              title: 'Answer Option 3 EQ5D5LV1',
              properties: {
                text: { type: 'string', mock: 'I have moderate problems in walking about' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0203',
                  enum: ['EQ5D0203'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0205',
                  enum: ['EQ5D0205'],
                },
              },
            },
            '4': {
              type: 'object',
              title: 'Answer Option 4 EQ5D5LV1',
              properties: {
                text: { type: 'string', mock: 'I have severe problems in walking about' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0203',
                  enum: ['EQ5D0203'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0205',
                  enum: ['EQ5D0205'],
                },
              },
            },
            '5': {
              type: 'object',
              title: 'Answer Option 5 EQ5D5LV1',
              properties: {
                text: { type: 'string', mock: 'I am unable to walk about' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0203',
                  enum: ['EQ5D0203'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0205',
                  enum: ['EQ5D0205'],
                },
              },
            },
          },
        },
      },
    },
    EQ5D0205: {
      type: 'object',
      title: 'Question 5 EQ5D5LV1',
      helper: 'EQ5D0205',
      properties: {
        questionnaireItemType: {
          title: 'Questionnaire Item Type',
          type: 'string',
          mock: 'QUESTION',
          enum: ['QUESTION'],
        },
        questionType: { title: 'Question Type', type: 'string', mock: 'RADIO_BUTTON', enum: ['RADIO_BUTTON'] },
        headerMd: { type: 'string', mock: 'ANXIETY / DEPRESSION' },
        optional: { title: 'Optional question', type: 'boolean', mock: false },
        answers: {
          type: 'object',
          title: 'Answers EQ5D5LV1',
          helper: 'Available answer options',
          properties: {
            '1': {
              type: 'object',
              title: 'Answer Option 1 EQ5D5LV1',
              properties: {
                text: { type: 'string', mock: 'I have no problems in walking about' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0204',
                  enum: ['EQ5D0204'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0206_INSTRUCTION',
                  enum: ['EQ5D0206_INSTRUCTION'],
                },
              },
            },
            '2': {
              type: 'object',
              title: 'Answer Option 2 EQ5D5LV1',
              properties: {
                text: { type: 'string', mock: 'I have slight problems in walking about' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0204',
                  enum: ['EQ5D0204'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0206_INSTRUCTION',
                  enum: ['EQ5D0206_INSTRUCTION'],
                },
              },
            },
            '3': {
              type: 'object',
              title: 'Answer Option 3 EQ5D5LV1',
              properties: {
                text: { type: 'string', mock: 'I have moderate problems in walking about' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0204',
                  enum: ['EQ5D0204'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0206_INSTRUCTION',
                  enum: ['EQ5D0206_INSTRUCTION'],
                },
              },
            },
            '4': {
              type: 'object',
              title: 'Answer Option 4 EQ5D5LV1',
              properties: {
                text: { type: 'string', mock: 'I have severe problems in walking about' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0204',
                  enum: ['EQ5D0204'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0206_INSTRUCTION',
                  enum: ['EQ5D0206_INSTRUCTION'],
                },
              },
            },
            '5': {
              type: 'object',
              title: 'Answer Option 5 EQ5D5LV1',
              properties: {
                text: { type: 'string', mock: 'I am unable to walk about' },
                type: { type: 'string', mock: 'TEXT_WITH_NAVIGATION', enum: ['TEXT_WITH_NAVIGATION'] },
                freeTextAnswerSupport: { title: 'Free text answer support', type: 'boolean', mock: false },
                previousId: {
                  title: 'Previous Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0204',
                  enum: ['EQ5D0204'],
                },
                nextId: {
                  title: 'Next Questionnaire Item',
                  helper: 'Which screen is shown before',
                  type: 'string',
                  mock: 'EQ5D0206_INSTRUCTION',
                  enum: ['EQ5D0206_INSTRUCTION'],
                },
              },
            },
          },
        },
      },
    },
    EQ5D0206_INSTRUCTION: {
      title: 'Scale Slider Instruction EQ5D5LV1',
      type: 'object',
      properties: {
        questionnaireItemType: {
          title: 'Questionnaire Item Type',
          type: 'string',
          mock: 'INSTRUCTION',
          enum: ['INSTRUCTION'],
        },
        headerMd: {
          type: 'string',
          mock: 'On the following screen we would like to know how good or bad your health is today',
        },
        previousId: {
          title: 'Previous Questionnaire Item',
          helper: 'Which screen is shown before',
          type: 'string',
          mock: 'EQ5D0205',
          enum: ['EQ5D0205'],
        },
        nextId: {
          title: 'Next Questionnaire Item',
          helper: 'Which screen is shown before',
          type: 'string',
          mock: 'EQ5D0206',
          enum: ['EQ5D0206'],
        },
        sections: {
          title: 'Instruction sections EQ5D5LV1',
          type: 'object',
          properties: {
            EQ5D0206_SECTION01: {
              title: 'Section text EQ5D5LV1',
              type: 'object',
              properties: {
                body: { title: 'Section body', type: 'string', mock: 'You will see a scale numbered from 0 to 100' },
              },
            },
            EQ5D0206_SECTION02: {
              title: 'Section text EQ5D5LV1',
              type: 'object',
              properties: {
                body: { title: 'Section body', type: 'string', mock: '100 means the best health you can imagine' },
                underlinedWords: {
                  title: 'Which words in the body to underline',
                  helper: 'The string must be comma separated',
                  type: 'string',
                  mock: 'best',
                },
              },
            },
            EQ5D0206_SECTION03: {
              title: 'Section text EQ5D5LV1',
              type: 'object',
              properties: {
                body: { title: 'Section body', type: 'string', mock: '0 means the worst health you can imagine' },
                underlinedWords: {
                  title: 'Which words in the body to underline',
                  helper: 'The string must be comma separated',
                  type: 'string',
                  mock: 'worst',
                },
              },
            },
          },
        },
      },
    },
    EQ5D0206: {
      type: 'object',
      title: 'Health Scale Question EQ5D5LV1',
      helper: 'EQ5D0206',
      properties: {
        questionnaireItemType: {
          title: 'Questionnaire Item Type',
          type: 'string',
          mock: 'QUESTION',
          enum: ['QUESTION'],
        },
        questionType: { title: 'Question Type', type: 'string', mock: 'SCALE_SLIDER', enum: ['SCALE_SLIDER'] },
        headerMd: { type: 'string', title: 'Header', mock: 'HEALTH SCALE' },
        subHeader: {
          type: 'string',
          title: 'Sub-Header',
          mock: 'Please indicate on the scale how your health is TODAY',
        },
        previousId: {
          title: 'Previous Questionnaire Item',
          helper: 'Which screen is shown before',
          type: 'string',
          mock: 'EQ5D0206_INSTRUCTION',
          enum: ['EQ5D0206_INSTRUCTION'],
        },
        nextId: {
          title: 'Next Questionnaire Item',
          helper: 'Which screen is shown before',
          type: 'string',
          mock: 'EQ5D_completion',
          enum: ['EQ5D_completion'],
        },
        optional: { title: 'Optional question', type: 'boolean', mock: false },
        scale: {
          type: 'object',
          title: 'Scale properties EQ5D5LV1',
          properties: {
            type: { title: 'Type', type: 'string', mock: 'SCALE', enum: ['SCALE'] },
            minValue: { title: 'Minimum value', type: 'number', mock: 0 },
            maxValue: { title: 'Maximum value', type: 'number', mock: 100 },
            step: { title: 'Step', type: 'number', mock: 1 },
            minValueLabel: { title: 'Minimum value label', type: 'string', mock: 'Minimum value label' },
            maxValueLabel: { title: 'Maximum value label', type: 'string', mock: 'Maximum value label' },
            currentValueLabel: { title: 'Current value label', type: 'string', mock: 'YOUR HEALTH TODAY' },
          },
        },
      },
    },
    EQ5D_completion: {
      type: 'object',
      title: 'Completed Instruction EQ5D5LV1',
      properties: {
        questionnaireItemType: {
          title: 'Questionnaire Item Type',
          type: 'string',
          mock: 'COMPLETION',
          enum: ['COMPLETION'],
        },
        headerMd: { title: 'Header', type: 'string', mock: 'You have now completed the questionnaire' },
        body: {
          title: 'Body',
          type: 'string',
          mock: 'If you would like to change any of your answers, you may do so by pressing the "Previous" button prior to finalizing \nthe questionnaire.\n\nPlease finalize the questionnaire by pressing the ”Finalize" button. \nOnce you press ”Finalize", you will not be able to go back to review or change your answers.',
        },
        previousId: {
          title: 'Previous Questionnaire Item',
          helper: 'Which screen is shown before',
          type: 'string',
          mock: 'EQ5D0206',
          enum: ['EQ5D0206'],
        },
      },
    },
  },
  description: 'unify:eq5d5lv1',
  $schema: 'http://json-schema.org/draft-07/schema#',
};
