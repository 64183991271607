import { without } from 'lodash/fp';

export enum PatientStudyStatusType {
  UNKNOWN = 'UNKNOWN',
  PRE_SCREENED = 'PRE_SCREENED',
  SCREENED = 'SCREENED',
  SCREEN_FAILED = 'SCREEN_FAILED',
  RANDOMIZED = 'RANDOMIZED',
  EARLY_WITHDRAWAL = 'EARLY_WITHDRAWAL',
  COMPLETED = 'COMPLETED',
  LOST_TO_FOLLOW_UP = 'LOST_TO_FOLLOW_UP',
  DECEASED = 'DECEASED',
  /**
   * @deprecated since v2.1
   */
  FOLLOW_UP = 'FOLLOW_UP',
  /**
   * @deprecated since v2.1
   */
  POTENTIAL_LOST_TO_FOLLOW_UP = 'POTENTIAL_LOST_TO_FOLLOW_UP',
  /**
   * @deprecated since v2.1
   */
  RE_SCREENED = 'RE_SCREENED',
}

const AllPatientStudyStatusTypeValues = Object.keys(PatientStudyStatusType) as PatientStudyStatusType[];

const UNHANDLED_PATIENT_STUDY_STATUSES: PatientStudyStatusType[] = [
  PatientStudyStatusType.FOLLOW_UP,
  PatientStudyStatusType.POTENTIAL_LOST_TO_FOLLOW_UP,
  PatientStudyStatusType.RE_SCREENED,
  PatientStudyStatusType.UNKNOWN,
];

const HANDLED_PATIENT_STUDY_STATUSES = without(UNHANDLED_PATIENT_STUDY_STATUSES, AllPatientStudyStatusTypeValues);

const isPatientStudyStatus = (status: unknown): status is PatientStudyStatusType =>
  !!status && HANDLED_PATIENT_STUDY_STATUSES.includes(status as PatientStudyStatusType);

export const PatientStudyStatusTypeValues = {
  isPatientStudyStatus,
  allValidValues: HANDLED_PATIENT_STUDY_STATUSES,
  allValuesIncludingDeprecated: AllPatientStudyStatusTypeValues,
};
