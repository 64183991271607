import { DeviceId, EventIdStr, ObservationId, UserId } from '@csp/csp-common-model';
import { isNotEmpty } from '@csp/csp-common-util';
import { DeviceObservationType, DeviceObservation as DeviceObservationEnum } from '@csp/device-catalog';
import { pageWithOneItem } from '@csp/dmdp-api-common-dto';
import {
  DeviceEventCriteriaFieldV1,
  DeviceEventQueryCriterionV1,
  DeviceEventQueryOperatorV1,
  DeviceEventQuerySortOrderV1,
  DeviceEventQueryTypeV1,
  DeviceEventQueryV1,
  DeviceEventSortFieldV1,
  DeviceObservationCriteriaFieldV1,
  DeviceObservationIncludeFieldV1,
  DeviceObservationQueryCriterionV1,
  DeviceObservationQuerySortOrderV1,
  DeviceObservationQueryTypeV1,
  DeviceObservationSortFieldV1,
  UserDeviceCriteriaFieldV1,
  UserDeviceQueryCriterionV1,
  UserDeviceQueryTypeV1,
} from '@csp/dmdp-api-device-dto';
import { DeviceEventType } from '../model/DeviceEventType';
import { DeviceObservationsQuery } from '../model/DeviceObservationsQuery';
import { DeviceSessionCode } from '../model/DeviceSessionCode';

export const createUserDeviceQueryCriterion = (patientId: UserId): UserDeviceQueryCriterionV1 => ({
  fieldCriterion: {
    field: UserDeviceCriteriaFieldV1.USER_ID,
    type: UserDeviceQueryTypeV1.EQ,
    values: [patientId],
  },
});

export const createDeviceObservationQueryCriterion = (deviceId: DeviceId): DeviceObservationQueryCriterionV1 => ({
  fieldCriterion: {
    field: DeviceObservationCriteriaFieldV1.USER_DEVICE_ID,
    type: DeviceObservationQueryTypeV1.EQ,
    values: [deviceId],
  },
});

export const createDeviceObservationQueryCriterionByObservationIds = (
  observationIds: ObservationId[],
): DeviceObservationQueryCriterionV1 => ({
  fieldCriterion: {
    field: DeviceObservationCriteriaFieldV1.DEVICE_OBSERVATION_ID,
    type: DeviceObservationQueryTypeV1.IN,
    values: observationIds,
  },
});

export const createDeviceObservationEventQueryCriterion = (eventId: EventIdStr): DeviceObservationQueryCriterionV1 => ({
  fieldCriterion: {
    field: DeviceObservationCriteriaFieldV1.EVENT_ID,
    type: DeviceObservationQueryTypeV1.EQ,
    values: [eventId],
  },
});

export const createDeviceObservationsQueryCriterionBySessionCode = (
  sessionCode: DeviceSessionCode,
): DeviceObservationQueryCriterionV1 => ({
  fieldCriterion: {
    field: DeviceObservationCriteriaFieldV1.SESSION_CODE,
    type: DeviceObservationQueryTypeV1.EQ,
    values: [sessionCode],
  },
});

export const createDeviceEventQueryCriterionByUserDeviceIds = (
  userDeviceIds: DeviceId[],
): DeviceEventQueryCriterionV1 => ({
  fieldCriterion: {
    field: DeviceEventCriteriaFieldV1.USER_DEVICE_ID,
    type: DeviceEventQueryTypeV1.IN,
    values: userDeviceIds,
  },
});

export const createDeviceEventTypeQueryCriterion = (values: DeviceEventType[]): DeviceEventQueryCriterionV1 => ({
  fieldCriterion: {
    field: DeviceEventCriteriaFieldV1.TYPE,
    type: DeviceEventQueryTypeV1.IN,
    values,
  },
});

export const createDeviceEventQuery = (criteria: DeviceEventQueryCriterionV1[]): DeviceEventQueryV1 => ({
  sort: [
    {
      field: DeviceEventSortFieldV1.TIMESTAMP,
      order: DeviceEventQuerySortOrderV1.ASC,
    },
  ],
  criterion: {
    operator: DeviceEventQueryOperatorV1.AND,
    criteria,
  },
});

export const createDeviceObservationsWithSyncTimestampQuery = (deviceId: string): DeviceObservationsQuery => ({
  deviceId,
  fields: [DeviceObservationIncludeFieldV1.SYNCHRONIZATION_TIMESTAMP],
  page: pageWithOneItem(),
  sort: [
    {
      order: DeviceObservationQuerySortOrderV1.DESC,
      field: DeviceObservationSortFieldV1.SYNCHRONIZATION_TIMESTAMP,
    },
  ],
});

/**
 * When there `excludedDeviceObservationTypes` is empty, the query will not include the `REFS_VALUE`
 * fields in the criteria. Only the `SESSION_CODE` field will be included.
 *
 * Note: refs never user for observations prior to 6.0. In 6.0 refs started to be used and to have amongst other thing
 * the type for the observation.
 *
 * @param sessionCodes All session codes for the selected schedule request
 * @param excludedDeviceObservationTypes Device observation types that should be excluded from the query.
 */
export const createRefsAndSessionCodeCriteria = (
  sessionCodes: DeviceSessionCode[],
  excludedDeviceObservationTypes: DeviceObservationType[],
): DeviceObservationQueryCriterionV1[] => {
  const refsCriteria: DeviceObservationQueryCriterionV1[] = [
    {
      fieldCriterion: {
        field: DeviceObservationCriteriaFieldV1.REFS_VALUE,
        type: DeviceObservationQueryTypeV1.NOT_IN,
        values: excludedDeviceObservationTypes,
      },
    },
  ];

  const sessionCodeCriteria: DeviceObservationQueryCriterionV1[] = [
    {
      fieldCriterion: {
        field: DeviceObservationCriteriaFieldV1.SESSION_CODE,
        type: DeviceObservationQueryTypeV1.IN,
        values: sessionCodes,
      },
    },
  ];

  return [...sessionCodeCriteria, ...(isNotEmpty(excludedDeviceObservationTypes) ? refsCriteria : [])];
};

export const createUserIdCriteria = (userId: UserId): DeviceObservationQueryCriterionV1 => ({
  fieldCriterion: {
    field: DeviceObservationCriteriaFieldV1.USER_ID,
    type: DeviceObservationQueryTypeV1.EQ,
    values: [userId],
  },
});

export const createDeviceObservationTrendsCriterion = (): DeviceObservationQueryCriterionV1 => ({
  fieldCriterion: {
    field: DeviceObservationCriteriaFieldV1.REFS_VALUE,
    type: DeviceObservationQueryTypeV1.NOT_IN,
    values: [DeviceObservationEnum.MASIMO_9809_PULSE_OX_STREAM, DeviceObservationEnum.MASIMO_9909_PULSE_OX_STREAM],
  },
});
