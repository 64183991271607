export class JsonValidationError extends Error {
  static isJsonValidationError(err: unknown): err is JsonValidationError {
    return err instanceof JsonValidationError;
  }

  schemaPath: string;
  schema: unknown;
  json: unknown;
  validationErrors: unknown;

  constructor(message: string, schemaPath: string, schema: unknown, json: unknown, validationErrors?: unknown) {
    super(message);
    this.schemaPath = schemaPath;
    this.schema = schema;
    this.json = json;
    this.validationErrors = validationErrors;

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, JsonValidationError.prototype);
  }
}
