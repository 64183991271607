import { CmsHookResult, useStudySpecificCmsQuery } from '@csp/common';
import {
  UnifyWebTechnicalSupport,
  UnifyWebTechnicalSupportMock,
  UnifyWebTechnicalSupportSchema,
} from '@csp/csp-content-schema';
import { toHookConfig } from '../../util/toHookConfig';

export const useCmsWebTechnicalSupport = (): CmsHookResult<UnifyWebTechnicalSupport> =>
  useStudySpecificCmsQuery<UnifyWebTechnicalSupport>(
    'unify:webtechnicalsupport',
    toHookConfig({
      defaultData: UnifyWebTechnicalSupportMock,
    }),
    UnifyWebTechnicalSupportSchema,
  );
