import { GenericVersion, ScheduleCode, ScheduleVersionInfo } from '@csp/csp-common-scheduling';
import { isDefined } from '@csp/csp-common-util';
import { DeviceObservationActiveVersionWindowV1 } from '@csp/dmdp-api-device-dto';
import { DeviceObservationActivation } from './DeviceObservationActivation';
import { DeviceObservationActivationRef } from './DeviceObservationActivationRef';
import { DeviceObservationRequest } from './DeviceObservationRequest';

export type DeviceObservationVersion = GenericVersion<DeviceObservationRequest>;

const from = (
  scheduleCode: ScheduleCode,
  { activation, activationRef, activeFrom, activeTo, version }: DeviceObservationActiveVersionWindowV1,
): DeviceObservationVersion => {
  const { requests, versionCode } = version;
  const versionInfo = ScheduleVersionInfo.fromApi(versionCode, activeFrom, activeTo);
  return {
    ...versionInfo,
    scheduleCode,
    activation: DeviceObservationActivation.from(activation),
    activationRef: DeviceObservationActivationRef.from(activationRef),
    requests: requests
      .map(request => {
        try {
          return DeviceObservationRequest.from(scheduleCode, versionInfo, request);
        } catch (e) {
          return undefined;
        }
      })
      .filter(isDefined),
  };
};

export const DeviceObservationVersion = {
  from,
};
