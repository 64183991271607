import { Maybe } from '@csp/csp-common-model';
import { Badge, BrandedIcon, Tab, Tabs, useMediaQ } from '@csp/csp-web-ui';
import { NavTab } from '@csp/web-common';
import { FC, ReactElement } from 'react';
import { CspPath } from '../../../CspPath';
import { useContextualNavBack } from '../hooks/useContextualNavBack';
import { HeaderNav } from './HeaderNav';

type Props = {
  tabs: NavTab<CspPath>[];
  selectedPath: CspPath;
  id?: string;
  isSubTab?: boolean;
};

const toIcon = ({ icon, disabled, badgeContent }: NavTab<CspPath>, mdUp: boolean): Maybe<ReactElement> => {
  if (icon && mdUp) {
    return <BrandedIcon name={icon} color={disabled ? 'textSecondary' : 'black'} />;
  }

  if (badgeContent) {
    return <Badge badgeContent={badgeContent} sx={{ marginLeft: '1.5rem !important' }} />;
  }

  return undefined;
};

export const NavTabsComponent: FC<Props> = ({ tabs, selectedPath, id, isSubTab }) => {
  const mdUp = useMediaQ('md', 'up');
  const { navigateWithContextualNavBack } = useContextualNavBack();

  const onClick = (tab: NavTab<CspPath>): void => {
    tab.onClick?.();
    if (tab.path) {
      navigateWithContextualNavBack(tab.path);
    }
  };

  return (
    <HeaderNav id={id}>
      <Tabs value={selectedPath} variant="scrollable">
        {tabs.map((tab, index) => (
          <Tab
            isSubTab={isSubTab}
            className={isSubTab ? 'Mui-sub-tab' : undefined}
            key={`tab-${index}`}
            icon={toIcon(tab, mdUp)}
            iconPosition={tab.badgeContent ? 'end' : 'start'}
            label={tab.label}
            value={tab.path}
            onClick={(): void => onClick(tab)}
            disabled={tab.disabled}
            data-testid={`tab${tab.path}`}
          />
        ))}
      </Tabs>
    </HeaderNav>
  );
};
