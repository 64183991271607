import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Fault } from '../model/Fault';
import { FaultState } from '../model/FaultState';

const initialState: FaultState = {};

export const { reducer: faultReducer, actions: faultActions } = createSlice({
  name: 'fault',
  initialState,
  reducers: {
    setUnhandledError: (_, { payload }: PayloadAction<Fault>): Fault => payload,
    handleError: (draft): void => {
      draft.isHandled = true;
    },
    clearError: (): FaultState => ({}),
  },
});
