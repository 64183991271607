import Url, { qs } from 'url-parse';
import { CspPath } from '../../../CspPath';

export const addQsToPath = (path: CspPath, qsObj: { redirectTo: string }): string => {
  const { query } = new Url(path);
  const newQuery = {
    ...qs.parse(query),
    ...qsObj,
  };

  return `${path}?${qs.stringify(newQuery)}`;
};
