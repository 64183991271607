import { CharCode, Maybe } from '@csp/csp-common-model';
import stringify from 'json-stable-stringify';

const asNumberOrUndefined = (v: string): Maybe<number> => {
  const number = Number(v);
  if (v.length === 0) {
    return undefined;
  } else {
    return number;
  }
};

const chop = (text: string, maxLength: number, suffix = '...'): string => {
  if (text) {
    return text.length <= maxLength || maxLength - suffix.length < 1
      ? text
      : text.substring(0, maxLength - suffix.length) + suffix;
  } else {
    return text;
  }
};

const removeSeconds = (dateTime: Maybe<string>): Maybe<string> => {
  if (dateTime?.match(/(:.*){2}/)) {
    return dateTime?.replace(/:[^:]*$/, '');
  } else {
    return dateTime;
  }
};

const equalsInsensitive = (a: Maybe<string>, b: Maybe<string>): boolean => a?.toLowerCase() === b?.toLowerCase();

const deterministicStringify = (obj: unknown): string => stringify(obj);

const removeLeadingZeros = (value: string, minLength: number): string => {
  while (value.startsWith('0') && value.length > minLength) {
    value = value.substring(1);
  }
  return value;
};

const padWithZeros = (value: string, minLength: number): string => {
  const zerosToPad = Math.max(0, minLength - value.length);
  const padding = Array(zerosToPad).fill('0').join('');
  return `${padding}${value}`;
};

const padWithOrRemoveZeros = (value: string, minLength: number): string => {
  if (value.length >= minLength) {
    return removeLeadingZeros(value, minLength);
  } else {
    return padWithZeros(value, minLength);
  }
};

/**
 * This function takes a string and inserts zero-width whitespace characters immediately
 * after all occurrences of the specified substring within the input string. The returned string
 * will linebreak afrer the specified substring when used with css: white-space: normal
 *
 * @param {string} value - The input string in which zero-width whitespace will be inserted.
 * @param {string} insertAfter - The substring after which zero-width whitespace characters will be inserted.
 * @returns {string} A new string with zero-width whitespace characters inserted after the specified substrings.
 */
const insertLineBreakOpportunitiesAfter = (value: string, insertAfter: string): string =>
  value.replaceAll(insertAfter, `${insertAfter}${CharCode.U_ZERO_WIDTH_WHITESPACE}`);

export const StringUtil = {
  chop,
  equalsInsensitive,
  removeSeconds,
  asNumberOrUndefined,
  deterministicStringify,
  removeLeadingZeros,
  padWithZeros,
  padWithOrRemoveZeros,
  insertLineBreakOpportunitiesAfter,
};
