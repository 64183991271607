import { Maybe } from '../../common/model/Monadish';
import { ZonedDateTime } from '../../datetime/ZonedDateTime';
import { ComplianceMetric } from './ComplianceMetric';
import { CountMetric } from './CountMetric';
import { MetricIn } from './MetricIn';
import { MetricPeriod } from './MetricPeriod';

export type MetricInfo = {
  countMetric: Maybe<CountMetric>;
  complianceMetric: Maybe<ComplianceMetric>;
  thresholdValue: Maybe<number>;
  thresholdPercentage: Maybe<number>;
  hasCompliance: boolean;
  recentPeriod: Maybe<MetricPeriod>;
  lastSync: Maybe<ZonedDateTime>;
  hasData: boolean;
  hasRecentData: boolean;
};

const from = ({
  totalActual,
  totalExpected,
  recentActual,
  recentExpected,
  recentPeriod,
  threshold,
  lastSync,
}: MetricIn): MetricInfo => {
  const hasData = !!lastSync;
  const countMetric: Maybe<CountMetric> =
    totalActual !== undefined && totalExpected !== undefined
      ? {
          totalCount: {
            actual: totalActual,
            expected: totalExpected,
            missed: totalExpected - totalActual,
          },
          recentCount:
            recentPeriod && recentActual !== undefined && recentExpected !== undefined
              ? {
                  actual: recentActual,
                  expected: recentExpected,
                  missed: recentExpected - recentActual,
                }
              : undefined,
        }
      : undefined;

  const complianceMetric =
    hasData && threshold && countMetric ? ComplianceMetric.from(threshold, countMetric) : undefined;

  const hasRecentData = hasData && !!countMetric?.recentCount?.expected;

  return {
    countMetric,
    complianceMetric,
    hasCompliance: !!threshold,
    recentPeriod,
    lastSync,
    hasData,
    hasRecentData,
    thresholdValue: threshold,
    thresholdPercentage: threshold ? threshold * 100 : undefined,
  };
};

export const MetricInfo = {
  from,
};
