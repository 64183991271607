import { CustomStatusMapper } from '@csp/csp-common-custom-status';
import {
  CustomSiteStatusResponseType,
  ECApprovalStatus,
  EDITABLE_SITE_STATUSES,
  Location,
  OPEN_SITE_STATUSES,
  RESPONSE_STATUS_TO_SITE_STATUS_MAP,
  Site,
  SITE_STATUS,
  SITE_STATUSES_CAN_ADD_PATIENTS,
  SITE_STATUSES_CAN_ADD_TEAM_MEMBERS,
  SITE_STATUSES_CAN_BOOK_VISITS,
  SITE_STATUSES_CAN_EDIT_PATIENTS,
  SITE_STATUSES_CAN_EDIT_TEAM_MEMBERS,
  SITE_STATUSES_IS_READ_ONLY,
  SiteActions,
  SiteStatus,
  SiteStatusType,
} from '@csp/csp-common-model';
import { PhoneNumberUtil } from '@csp/csp-common-util';
import { CustomStatusV1, ExternalIdKeyType } from '@csp/dmdp-api-common-dto';
import { ExternalIdV1, LocationV1, OrgStatusType, OrgType, OrgV1 } from '@csp/dmdp-api-org-dto';
import { ContactPointSystemType } from '@csp/dmdp-api-user-dto';
import { OrgMapper } from '../mapper/OrgMapper';
import { toDisplayAddress } from '../utils/AddressUtil';

const findSiteLifecycleCustomStatus = (customStatuses: CustomStatusV1[]): CustomStatusV1 | undefined =>
  customStatuses.find(status => status.type === SiteStatusType.CTMS_STATUS);

const siteLifecycleStatus = (orgV1: OrgV1): SiteStatus | undefined => {
  const siteCustomStatus = findSiteLifecycleCustomStatus(orgV1.customStatuses || []);
  if (siteCustomStatus) {
    return (
      RESPONSE_STATUS_TO_SITE_STATUS_MAP[siteCustomStatus.value as CustomSiteStatusResponseType] || SITE_STATUS.UNKNOWN
    );
  } else {
    return undefined;
  }
};

const evalSiteIsOpen = (siteStatus: SiteStatus): boolean => OPEN_SITE_STATUSES.includes(siteStatus);

const isSiteEditable = (siteStatus?: SiteStatus): boolean =>
  !!siteStatus && EDITABLE_SITE_STATUSES.includes(siteStatus);

const evalIfActivePractice = (orgV1: OrgV1): boolean =>
  orgV1.type === OrgType.PRACTICE && orgV1.status === OrgStatusType.ACTIVE;

const getLocationPrimaryPhoneNumber = (location?: LocationV1): string | undefined => {
  if (location) {
    let phoneNumber;
    const phoneContactPoints = location.contactPoints?.filter(
      contactPoint => contactPoint.system === ContactPointSystemType.PHONE,
    );
    const primaryPhoneContactPoint = phoneContactPoints?.find(contactPoint => contactPoint.primary);

    if (primaryPhoneContactPoint) {
      phoneNumber = primaryPhoneContactPoint.value;
    } else if (phoneContactPoints && phoneContactPoints.length > 0) {
      phoneNumber = phoneContactPoints[0]?.value;
    }

    return phoneNumber;
  } else {
    return undefined;
  }
};

const getPrimaryLocation = (locations?: LocationV1[]): LocationV1 | undefined =>
  locations?.find(location => location.primary);

const getPrimaryLocationPhoneNumber = (locations?: LocationV1[]): string | undefined => {
  const primaryLocation = getPrimaryLocation(locations);
  return getLocationPrimaryPhoneNumber(primaryLocation);
};

const getECApprovalStatus = (customStatusesV1: CustomStatusV1[]): ECApprovalStatus | undefined => {
  const customStatus = CustomStatusMapper.fromCustomStatusesV1(customStatusesV1).statuses.find(
    status => status.type === SiteStatusType.EC_APPROVAL_STATUS,
  );

  return customStatus ? ECApprovalStatus.from(customStatus) : undefined;
};

const getSiteActions = (siteStatus: SiteStatus): SiteActions => ({
  canEnrollPatients: SITE_STATUSES_CAN_ADD_PATIENTS.includes(siteStatus),
  canEditPatients: SITE_STATUSES_CAN_EDIT_PATIENTS.includes(siteStatus),
  canBookVisits: SITE_STATUSES_CAN_BOOK_VISITS.includes(siteStatus),
  canAddTeamMembers: SITE_STATUSES_CAN_ADD_TEAM_MEMBERS.includes(siteStatus),
  canEditTeamMembers: SITE_STATUSES_CAN_EDIT_TEAM_MEMBERS.includes(siteStatus),
  isReadOnly: SITE_STATUSES_IS_READ_ONLY.includes(siteStatus),
});

const getCtmsId = (externalIds: ExternalIdV1[]): string | undefined =>
  externalIds.find(externalId => externalId.key === ExternalIdKeyType.CTMS_SITE_ID)?.value;

const from = (orgV1: OrgV1): Site => {
  const org = OrgMapper.fromOrgV1(orgV1);

  const siteStatus = siteLifecycleStatus(orgV1);
  const isActivePractice = evalIfActivePractice(orgV1);
  const isEditable = isActivePractice && isSiteEditable(siteStatus);
  const open = isActivePractice && !!siteStatus && evalSiteIsOpen(siteStatus);
  const siteNumber = orgV1.practice?.practiceCode || '';
  const displaySiteNumber = orgV1.practice?.practiceCode?.padStart(4, '0') || '';
  const primaryPhoneNumber = getPrimaryLocationPhoneNumber(org.orgV1.practice?.locations);
  const primaryLocation = getPrimaryLocation(org.orgV1.practice?.locations);
  const displayAddress = toDisplayAddress(primaryLocation?.address);
  const displayNameLeadingSiteNumber = displaySiteNumber ? `${displaySiteNumber} \u00B7 ${org.name}` : org.name;
  const locations = org.orgV1.practice?.locations?.map(Location.from) || [];
  const ecApprovalStatus = getECApprovalStatus(orgV1.customStatuses ?? []);
  const ctmsId = getCtmsId(orgV1.externalIds ?? []);

  const siteActions: SiteActions = getSiteActions(siteStatus as SiteStatus);

  const primaryPhoneNumberDisplayFormat = primaryPhoneNumber
    ? PhoneNumberUtil.toDisplayFormatSafe(primaryPhoneNumber)
    : undefined;

  if (siteNumber === '') {
    console.debug(`Site ${org.name} (OrgId: ${org.orgId}) has no siteNumber`);
  }

  return {
    ...org,
    displayAddress,
    isActivePractice,
    isEditable,
    locations,
    open,
    primaryLocation,
    primaryPhoneNumber,
    primaryPhoneNumberDisplayFormat,
    siteNumber,
    displaySiteNumber,
    displayNameLeadingSiteNumber,
    siteStatus,
    ecApprovalStatus,
    ctmsId,
    ...siteActions,
  };
};

export const SiteMapper = {
  from,

  // Exposed for test
  getSiteActions,
  getPrimaryLocationPhoneNumber,
  siteLifecycleStatus,
  evalSiteIsOpen,
  isSiteEditable,
  getPrimaryLocation,
};
