import { nowToZonedDateTimeCurrentZone, ZonedDateTimeFormatter } from '@csp/csp-common-date-util';
import {
  CustomStatuses,
  CustomStatusValue,
  Maybe,
  Patient,
  PatientAccountInfo,
  PatientAccountStatus,
  PatientAppStatusType,
  PatientAppStatusTypes,
  PatientFinalContactStatusTypes,
  PatientMedicationStatusType,
  PatientMedicationStatusTypes,
  PatientStatus,
  PatientStatuses,
  PatientStatusType,
  PatientStudyStatusType,
  PatientStudyStatusTypeValues,
  PatientVitalStatusTypes,
  RtsmStatus,
  User,
} from '@csp/csp-common-model';
import { isDefined } from '@csp/csp-common-util';
import { CustomStatusInV1 } from '@csp/dmdp-api-common-dto';
import { DmdpUserStatusType, UserV1 } from '@csp/dmdp-api-user-dto';
import { cloneDeep, orderBy } from 'lodash';
import { PatientAccountStatusMapper } from '../mapper/PatientAccountStatusMapper';
import { PatientStatusMapper } from '../mapper/PatientStatusMapper';
import { PatientStatusesFactory } from '../model/PatientStatusesFactory';
import { PatientStatusRuleService } from './PatientStatusRuleService';

const getPatientStatus = (userV1: UserV1): PatientStatuses =>
  userV1.customStatuses
    ? PatientStatusMapper.toPatientStatuses(userV1.customStatuses)
    : PatientStatusesFactory.emptyPatientStatuses;

const isPatientReadonly = (statuses: PatientStatuses): boolean =>
  PatientStatusRuleService.isEndStudyStatus(statuses.studyStatus.status);

const wasUserCreatedWithNoAccount = (userV1: UserV1): boolean => {
  const patientAppStatus = userV1.customStatuses?.find(customStatus => customStatus.type === PatientStatusType.APP);

  if (patientAppStatus) {
    return (
      patientAppStatus?.value === PatientAppStatusType.INACTIVE ||
      !!patientAppStatus.previousCustomStatuses?.find(
        customStatus => customStatus.value === PatientAppStatusType.INACTIVE,
      )
    );
  } else {
    return false;
  }
};

const getPatientAccountInfo = (
  user: User,
  statuses: PatientStatuses,
  temporaryPasswordExpiresInDays: number,
): PatientAccountInfo => {
  const status = PatientAccountStatusMapper.fromAppAndDmdpStatus(
    statuses.appStatus,
    user.dmdpStatus,
    temporaryPasswordExpiresInDays,
  );
  const isPending =
    status === PatientAccountStatus.INVITATION_SENT ||
    status === PatientAccountStatus.INVITATION_EXPIRED ||
    status === PatientAccountStatus.VERIFIED;
  const isActive = status === PatientAccountStatus.ACTIVATED;
  const hasNoAccount = status === PatientAccountStatus.NO_ACCOUNT;
  const wasCreatedWithNoAccount = wasUserCreatedWithNoAccount(user.userV1);
  const isInvitationExpired = status === PatientAccountStatus.INVITATION_EXPIRED;
  return {
    status,
    isPending,
    isActive,
    hasNoAccount,
    wasCreatedWithNoAccount,
    isInvitationExpired,
  };
};

/**
 * Finds the @see {PatientStatus} in the patients current or previous custom statuses.
 *
 * @returns the status or undefined if not found.
 */
const findPastPatientStatus = <
  T extends PatientStudyStatusType | PatientMedicationStatusType | PatientAppStatusType | RtsmStatus,
>(
  customStatuses: CustomStatuses,
  statusType: PatientStatusType,
  statusValue: T,
): Maybe<PatientStatus<T>> => {
  const customStatus = customStatuses.getByType(statusType);
  const customStatusValue: Maybe<CustomStatusValue> =
    customStatus?.value === statusValue
      ? customStatus
      : customStatus?.previous?.find(prevStatus => prevStatus.value === statusValue);
  return customStatusValue
    ? {
        status: customStatusValue.value as T,
        timestamp: customStatusValue.timestamp,
      }
    : undefined;
};

/**
 * Finds the @see {PatientStatus} in the patients current or previous custom statuses.
 *
 * @returns the status or undefined if not found.
 */
const findFirstPastPatientStatusByValues = <
  T extends PatientStudyStatusType | PatientMedicationStatusType | PatientAppStatusType | RtsmStatus,
>(
  customStatuses: CustomStatuses,
  statusType: PatientStatusType,
  statusValues: Readonly<T[]>,
): Maybe<PatientStatus<T>> => {
  const previousStatuses = orderBy(
    statusValues.map(statusValue => findPastPatientStatus(customStatuses, statusType, statusValue)).filter(isDefined),
    status => status.timestamp.unixTimeMillis,
    ['desc'],
  );
  return previousStatuses[0];
};

const getLastNonFollowUpMedicationStatus = (patient: Patient): Maybe<PatientStatus<PatientMedicationStatusType>> => {
  if (PatientStatusRuleService.followUpStatuses.includes(patient.statuses.medicationStatus.status)) {
    return findFirstPastPatientStatusByValues(
      patient.customStatuses,
      PatientStatusType.MEDICATION,
      PatientMedicationStatusTypes.excludeUnknownAndFollowUpStatuses,
    );
  } else {
    return patient.statuses.medicationStatus;
  }
};

const mapFollowUpMedicationStatusIfPossible = (
  patient: Patient,
): Maybe<
  | PatientStatus<PatientMedicationStatusType.TREATMENT_COMPLETED>
  | PatientStatus<PatientMedicationStatusType.TREATMENT_DISCONTINUED>
> => {
  if (PatientStatusRuleService.followUpStatuses.includes(patient.statuses.medicationStatus.status)) {
    return findFirstPastPatientStatusByValues<
      PatientMedicationStatusType.TREATMENT_COMPLETED | PatientMedicationStatusType.TREATMENT_DISCONTINUED
    >(patient.customStatuses, PatientStatusType.MEDICATION, [
      PatientMedicationStatusType.TREATMENT_COMPLETED,
      PatientMedicationStatusType.TREATMENT_DISCONTINUED,
    ]);
  } else {
    return undefined;
  }
};

const hasPastStudyStatus = (patient: Patient, studyStatus: PatientStudyStatusType): boolean =>
  !!findPastPatientStatus(patient.customStatuses, PatientStatusType.STUDY, studyStatus);

/**
 * @deprecated Use PatientStatusMapper instead
 */
const toCustomStatusInV1s = (patientStatuses: PatientStatuses): CustomStatusInV1[] => [
  {
    type: PatientStatusType.RTSM,
    value: patientStatuses.rtsmStatus?.status || 'UNKNOWN',
    localTimestamp: ZonedDateTimeFormatter.toZonedDateTimeString(patientStatuses.rtsmStatus.timestamp),
  },
  {
    type: PatientStatusType.STUDY,
    value: patientStatuses.studyStatus?.status || PatientStudyStatusType.UNKNOWN,
    localTimestamp: ZonedDateTimeFormatter.toZonedDateTimeString(patientStatuses.studyStatus.timestamp),
  },
  {
    type: PatientStatusType.MEDICATION,
    value: patientStatuses.medicationStatus?.status || PatientMedicationStatusType.UNKNOWN,
    localTimestamp: ZonedDateTimeFormatter.toZonedDateTimeString(patientStatuses.medicationStatus.timestamp),
  },
  {
    type: PatientStatusType.APP,
    value: patientStatuses.appStatus?.status || PatientAppStatusType.UNKNOWN,
    localTimestamp: ZonedDateTimeFormatter.toZonedDateTimeString(patientStatuses.appStatus.timestamp),
  },
];

const isAppStatusEditable = (prevPatientStatuses: PatientStatuses): boolean =>
  prevPatientStatuses.appStatus.status !== PatientAppStatusType.INACTIVE;

/**
 * @deprecated Use PatientStatusMapper instead
 */
const fromDmdpStatus = (dmdpStatus: DmdpUserStatusType, prevPatientStatuses: PatientStatuses): PatientStatuses => {
  if (
    dmdpStatus === DmdpUserStatusType.ACTIVE &&
    prevPatientStatuses.appStatus.status !== PatientAppStatusType.ACTIVE &&
    isAppStatusEditable(prevPatientStatuses)
  ) {
    const newPatientStatuses = cloneDeep(prevPatientStatuses);
    newPatientStatuses.appStatus.status = PatientAppStatusType.ACTIVE;
    newPatientStatuses.appStatus.timestamp = nowToZonedDateTimeCurrentZone();
    return newPatientStatuses;
  } else {
    return prevPatientStatuses;
  }
};

const isPatientStatus = (statusType: string, statusValue: string): boolean => {
  if (statusType === PatientStatusType.APP) {
    return PatientAppStatusTypes.isPatientAppStatus(statusValue);
  } else if (statusType === PatientStatusType.FINAL_CONTACT) {
    return PatientFinalContactStatusTypes.isPatientFinalContactStatus(statusValue);
  } else if (statusType === PatientStatusType.MEDICATION) {
    return PatientMedicationStatusTypes.isPatientMedicationStatus(statusValue);
  } else if (statusType === PatientStatusType.STUDY) {
    return PatientStudyStatusTypeValues.isPatientStudyStatus(statusValue);
  } else if (statusType === PatientStatusType.VITAL) {
    return PatientVitalStatusTypes.isPatientVitalStatus(statusValue);
  } else {
    return false;
  }
};

export const PatientStatusService = {
  getPatientStatus,
  isPatientReadonly,
  hasPastStudyStatus,
  findPastPatientStatus,
  getPatientAccountInfo,
  findFirstPastPatientStatusByValues,
  getLastNonFollowUpMedicationStatus,
  mapFollowUpMedicationStatusIfPossible,
  isPatientStatus,
  toCustomStatusInV1s,
  fromDmdpStatus,
};
