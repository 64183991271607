import { StudySubjectGenderV1 } from '@csp/dmdp-api-study-dto';

export enum GenderType {
  FEMALE = StudySubjectGenderV1.FEMALE,
  MALE = StudySubjectGenderV1.MALE,
  THIRD = StudySubjectGenderV1.THIRD,
  UNKNOWN = StudySubjectGenderV1.UNKNOWN,
}

export const toGenderType = (gender: StudySubjectGenderV1 | undefined): GenderType | undefined => {
  switch (gender) {
    case StudySubjectGenderV1.FEMALE:
      return GenderType.FEMALE;
    case StudySubjectGenderV1.MALE:
      return GenderType.MALE;
    case StudySubjectGenderV1.THIRD:
      return GenderType.THIRD;
    case StudySubjectGenderV1.UNKNOWN:
      return GenderType.UNKNOWN;
    default:
      return;
  }
};
