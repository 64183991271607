import { Grid } from '@csp/csp-web-ui';
import { FC } from 'react';
import { Outlet, useMatch } from 'react-router-dom';
import { MainContent } from '../../../common/component/layout/MainContent';
import { HeaderNavBar } from '../component/HeaderNavBar';
import { MainNavTabsContainer } from '../container/MainNavTabsContainer';
import { PatientNavSubTabsContainer } from '../container/PatientNavSubTabsContainer';
import { ReportsNavSubTabsContainer } from '../container/ReportsNavSubTabsContainer';
import { useMainNavPages } from '../hooks/useMainNavPages';
import { CspPath } from '../../../CspPath';

export const MainNavPages: FC = () => {
  const { goBack, hasMoreThanOneStudy, study, texts, patientListSubNavs } = useMainNavPages();

  const shouldRenderReportsTab = !!useMatch(`${CspPath.REPORTS}/*`);
  const shouldRenderPatientTab = !!useMatch(`${CspPath.PATIENT_LIST}/*`) && !!patientListSubNavs?.length;

  return (
    <Grid container>
      <HeaderNavBar
        onBackButtonClick={goBack}
        replaceLogoWithBackButton={hasMoreThanOneStudy}
        title={study.name ?? ''}
        subtitle={study.dcode ?? texts.topBar.button.myStudies.label}
      >
        <MainNavTabsContainer tabs={texts.tabs} />

        {shouldRenderPatientTab && (
          <PatientNavSubTabsContainer subNavs={patientListSubNavs} navTabTexts={texts.navTabs} />
        )}

        {shouldRenderReportsTab && <ReportsNavSubTabsContainer />}
      </HeaderNavBar>
      <MainContent>
        <Outlet />
      </MainContent>
    </Grid>
  );
};
