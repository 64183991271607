/* eslint-disable */
export const UnifyStudyRequestTagsSchema = {
  title: 'UnifyStudyRequestTags',
  type: 'object',
  properties: {
    tagGroups: {
      title: 'StudyRequestTags',
      helper: "Click 'add new item' to add a new group of study tags",
      type: 'array',
      items: {
        type: 'object',
        properties: {
          tagGroupKey: {
            title: 'Tag Group Key',
            type: 'string',
            helper: 'The unique key for the group of tags',
            placeholder: 'Tag Group Key',
          },
          tagGroupName: {
            title: 'Tag Group Name',
            type: 'string',
            helper: 'The name of the group of tags',
            placeholder: 'Tag Group Name',
          },
          tags: {
            title: 'StudyRequestTags',
            type: 'array',
            items: {
              title: 'Tag',
              type: 'object',
              properties: {
                tagCode: {
                  title: 'Tag Code',
                  type: 'string',
                  helper: 'The unique code for the tag',
                  placeholder: 'tagCode',
                },
                name: { title: 'Tag Name', type: 'string', helper: 'Name of the tag', placeholder: 'Tag Name' },
              },
            },
          },
        },
      },
      mock: [
        {
          tagGroupKey: 'LOCATION',
          tagGroupName: '[M] Location',
          tags: [
            { tagCode: 'F2F', name: '[M] Face to Face' },
            { tagCode: 'REMOTE', name: '[M] Remote' },
            { tagCode: 'HYBRID', name: '[M] Hybrid' },
          ],
        },
      ],
    },
  },
  description: 'unify:studyrequesttags',
  $schema: 'http://json-schema.org/draft-07/schema#',
};
