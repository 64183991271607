import { useBoolean } from '@csp/fe-hooks';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getStudiesSelector } from '../../../studies/ducks/studiesSelectors';
import { AutomaticLogoutContextState, AutomaticLogoutStatePayload } from '../model/AutomaticLogoutContextState';

export const useAutomaticLogoutContextProvider = (): AutomaticLogoutContextState => {
  const [lastActiveUserId, setLastActiveUserId] = useState<string>();
  const [lastSelectedStudyId, setLastSelectedStudyId] = useState<string>();
  const [lastVisitedPath, setLastVisitedPath] = useState<string>();
  const [displayLoggedOutNotice, showAutomaticLogoutNotice, hideAutomaticLogoutNotice] = useBoolean();
  const { studies } = useSelector(getStudiesSelector);

  const onPreAutomaticLogout = useCallback(
    ({ userId, studyId, path }: AutomaticLogoutStatePayload) => {
      setLastActiveUserId(userId);
      setLastSelectedStudyId(studyId);
      setLastVisitedPath(path);
      showAutomaticLogoutNotice();
    },
    [showAutomaticLogoutNotice],
  );

  const resetState = useCallback(() => {
    setLastActiveUserId(undefined);
    setLastSelectedStudyId(undefined);
    setLastVisitedPath(undefined);
    hideAutomaticLogoutNotice();
  }, [hideAutomaticLogoutNotice]);

  const userWasAutomaticallyLoggedOut: boolean = useMemo(() => {
    const study = studies.find(study => study.tenantId === lastSelectedStudyId);
    const userIdMatch = lastActiveUserId && lastActiveUserId === study?.userInfo.userId;

    return !!userIdMatch;
  }, [studies, lastActiveUserId, lastSelectedStudyId]);

  return {
    lastActiveUserId,
    lastSelectedStudyId: userWasAutomaticallyLoggedOut ? lastSelectedStudyId : undefined,
    lastVisitedPath: userWasAutomaticallyLoggedOut ? lastVisitedPath : undefined,
    displayLoggedOutNotice,
    userWasAutomaticallyLoggedOut,
    showAutomaticLogoutNotice,
    hideAutomaticLogoutNotice,
    onPreAutomaticLogout,
    resetState,
  };
};
