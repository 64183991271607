import {
  dateAndTimeToZonedDateTimeCurrentZone,
  dateOrUnixTimeMillisToZonedDateTimeCurrentZone,
  isAfter,
  isBefore,
  isFutureDateTime,
  isIsoDateToday,
  isPastDateTime,
  isTimeAfter,
  isTimeBefore,
  isValidTime,
  parseTimeOrUndefined,
  timezoneStrToUnixTime,
  toFormatIsoDate,
  toFormatTime,
} from '@csp/csp-common-date-util';
import { DateOfBirthType, IsoDateStr, Maybe, StateAssert } from '@csp/csp-common-model';
import { isDefined, MathUtil, PhoneNumberUtil } from '@csp/csp-common-util';
import { endOfDay, startOfDay } from 'date-fns';
import { inRange, isArray, isEqual, range } from 'lodash';
import template from 'lodash/template';
import { ErrorMessages } from '../model/ErrorMessages';
import { InputError } from '../model/InputError';
import { InputType } from '../model/InputType';
import { InputValidator } from '../model/InputValidator';
import { ValidationType } from '../type/ValidationType';

const EMAIL_REGEXP =
  /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

const SPECIAL_CHAR_REGEXP = /[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`]/;
const NUMBER_REGEXP = /^\d+$/;

const numberWithDecimalsRegexp = (numberOfDecimals: number): RegExp =>
  new RegExp(`^\\d+(\\.\\d{${numberOfDecimals}})?$`);

const formatErrorMessage = (key: keyof ErrorMessages, fallbackMessage: string, obj: ErrorMessages | string): string => {
  if (obj) {
    return typeof obj === 'string' ? obj : (Object.keys(obj).length && obj[key]) || fallbackMessage;
  }
  return fallbackMessage;
};

const isDatePartInvalid = (part: Maybe<string>, vaildLength: number, minValue: number, maxValue?: number): boolean => {
  const partNum = Number(part);
  return (
    isDefined(part) &&
    (part.length !== vaildLength || isNaN(partNum) || partNum < minValue || (isDefined(maxValue) && partNum > maxValue))
  );
};

const parseNumber = (val: Maybe<string | number>): number | null => {
  if (val == null || val === '' || val === Number.MIN_SAFE_INTEGER) {
    return null;
  }
  return Number(val);
};

export class InputValidators {
  static email(val: Maybe<string>, errorMessages = {}): InputError | null {
    const isValid: boolean = val == null || val === '' || EMAIL_REGEXP.test(String(val).toLowerCase());
    const inputError: InputError = {
      message: formatErrorMessage('email', 'Invalid email', errorMessages),
      type: ValidationType.EMAIL,
    };

    return isValid ? null : inputError;
  }

  /** Supply with input value pointing to the region, eg US, SE */
  static internationalPhoneNumber(countryCode: string): InputValidator {
    StateAssert.notNull(countryCode);
    return (val, errorMessages = {}) => {
      let isValid = true;
      if (val != null && val.trim().length > 0 && countryCode != null && countryCode.trim().length > 0) {
        try {
          const number = PhoneNumberUtil.toInternalFormat(countryCode, val);
          isValid = PhoneNumberUtil.isValidNumber(number);
        } catch (e) {
          isValid = false;
        }
      }
      const inputError: InputError = {
        message: formatErrorMessage('internationalPhoneNumber', 'Invalid phone number', errorMessages),
        type: ValidationType.PHONE_NUMBER,
      };
      return isValid ? null : inputError;
    };
  }

  static phoneNumber(val: Maybe<string>, errorMessages = {}): InputError | null {
    const isValid: boolean = val == null || val === '' || PhoneNumberUtil.isValidNumber(val);
    const inputError: InputError = {
      message: formatErrorMessage('phoneNumber', 'Invalid phone number', errorMessages),
      type: ValidationType.PHONE_NUMBER,
    };
    return isValid ? null : inputError;
  }

  static required(val: Maybe<InputType>, errorMessages = {}): InputError | null {
    const inputError: InputError = {
      message: formatErrorMessage('required', 'Required', errorMessages),
      type: ValidationType.REQUIRED,
    };

    if (typeof val === 'string') {
      return val.trim().length > 0 ? null : inputError;
    } else if (typeof val === 'number') {
      return null;
    } else if (isArray(val)) {
      return val.length > 0 ? null : inputError;
    } else {
      return inputError;
    }
  }

  static enumRequired<T extends string | number = string>(emptyEnumValue: T): InputValidator<T> {
    return (val, errorMessages = {}) => {
      const inputError: InputError = {
        message: formatErrorMessage('required', 'Required', errorMessages),
        type: ValidationType.REQUIRED,
      };
      return val === emptyEnumValue ? inputError : null;
    };
  }

  static checkBoxRequired(val: Maybe<string>, errorMessages = {}): InputError | null {
    const inputError: InputError = {
      message: formatErrorMessage('checkboxRequired', 'Required', errorMessages),
      type: ValidationType.REQUIRED,
    };
    return val?.toUpperCase() === 'TRUE' ? null : inputError;
  }

  static minLength(minLength: number): InputValidator {
    return (val, errorMessages = {}) => {
      const errorMessage = formatErrorMessage(
        'minLength',
        'Length must be at least ${ minLength } characters',
        errorMessages,
      );

      return val == null || val === '' || val.trim().length >= minLength
        ? null
        : {
            message: template(errorMessage)({ minLength }),
            type: ValidationType.MIN_LENGTH,
          };
    };
  }

  static maxLength =
    (maxLength: number): InputValidator =>
    (val, errorMessages = {}) => {
      const errorMessage = formatErrorMessage(
        'maxLength',
        'Length must be at max ${ maxLength } characters',
        errorMessages,
      );

      return val == null || val === '' || val.length <= maxLength
        ? null
        : {
            message: template(errorMessage)({ maxLength }),
            type: ValidationType.MAX_LENGTH,
          };
    };

  static isoDate(val: Maybe<string>, errorMessages = {}): InputError | null {
    let invalid = false;
    const inputError: InputError = {
      message: formatErrorMessage('date', 'Invalid date', errorMessages),
      type: ValidationType.DATE,
    };

    if (val?.trim().length) {
      try {
        invalid = !timezoneStrToUnixTime(val);
      } catch (e) {
        invalid = true;
      }
    }
    return invalid ? inputError : null;
  }

  static date(val: Maybe<string>, errorMessages = {}): InputError | null {
    let invalid = false;
    const inputError: InputError = {
      message: formatErrorMessage('date', 'Invalid date', errorMessages),
      type: ValidationType.DATE,
    };

    if (val?.trim().length) {
      try {
        const timestamp = Date.parse(val);
        invalid = isNaN(timestamp);
      } catch (e) {
        invalid = true;
      }
    }
    return invalid ? inputError : null;
  }

  /** Expects HH:mm */
  static time(val: Maybe<string>, errorMessages = {}): InputError | null {
    let invalid = false;
    const inputError: InputError = {
      message: formatErrorMessage('time', 'Invalid time format', errorMessages),
      type: ValidationType.TIME,
    };

    if (val?.trim().length) {
      try {
        invalid = !isValidTime(val);
      } catch (e) {
        invalid = true;
      }
    }
    return invalid ? inputError : null;
  }

  /** Expects YYYY-MM-DD HH:mm */
  static dateTime(val: Maybe<string>, errorMessages = {}): InputError | null {
    let invalid = false;
    const inputError: InputError = {
      message: formatErrorMessage('dateTime', 'Invalid date time', errorMessages),
      type: ValidationType.DATE_TIME,
    };

    if (val?.trim().length) {
      try {
        const splitVals = val.split(' ');
        const [dateVal, timeVal] = splitVals;
        invalid =
          splitVals.filter(val => val.length).length === 1 ||
          !!InputValidators.date(dateVal, errorMessages) ||
          !!InputValidators.time(timeVal, errorMessages);
      } catch (e) {
        invalid = true;
      }
    }
    return invalid ? inputError : null;
  }

  /**
   * Expects YYYY, YYYY-MM or YYYY-MM-DD
   */
  static birthDate(format: DateOfBirthType = DateOfBirthType.YYYY_MM_DD): InputValidator {
    return (val, errorMessages = {}) => {
      if (val?.trim().length) {
        const parts = val.split('-');
        const [year, month, day, rest] = parts;

        const isDateFormatOrPartsLengthInvalid /*?*/ =
          (format === DateOfBirthType.YYYY_MM && parts.length > 2) ||
          (format === DateOfBirthType.YYYY && parts.length > 1) ||
          val.endsWith('-') ||
          rest;

        const isYearMonthOrDayInvalid = [
          isDatePartInvalid(year, 4, 1900),
          isDatePartInvalid(month, 2, 1, 12),
          isDatePartInvalid(day, 2, 1, 31),
        ].includes(true);

        if (isDateFormatOrPartsLengthInvalid || isYearMonthOrDayInvalid) {
          const validFormatsMap = {
            [DateOfBirthType.YYYY_MM_DD]: [DateOfBirthType.YYYY, DateOfBirthType.YYYY_MM, DateOfBirthType.YYYY_MM_DD],
            [DateOfBirthType.YYYY_MM]: [DateOfBirthType.YYYY, DateOfBirthType.YYYY_MM],
            [DateOfBirthType.YYYY]: [DateOfBirthType.YYYY],
          };

          const errorMessage = formatErrorMessage('dateOfBirth', 'Not acceptable', errorMessages);
          const validFormatsString = validFormatsMap[format].join(', ');
          return {
            message: `${errorMessage} (${validFormatsString})`,
            type: ValidationType.BIRTH_DATE,
          };
        }
      }
      return null;
    };
  }

  /** Expects HH:mm */
  static minTime(minTime: Date, options?: { includesDate?: boolean }): InputValidator {
    return (val, errorMessages = {}) => {
      let invalid = false;
      if (val?.trim().length) {
        const includesDate = options?.includesDate;

        const timeVal = includesDate ? val.split(' ')[1] ?? val : val;

        invalid = !!InputValidators.time(timeVal, errorMessages) || isTimeBefore(timeVal, minTime);
      }
      const errorMessage = formatErrorMessage('minTime', 'Time must be ${ minTime } or later', errorMessages);
      return invalid
        ? {
            message: template(errorMessage)({ minTime: toFormatTime(minTime.valueOf()) }),
            type: ValidationType.MIN_TIME,
          }
        : null;
    };
  }

  static minTimestamp(minTimestamp: Date, currentDate: IsoDateStr): InputValidator {
    return (val, errorMessages = {}) => {
      let invalid = false;
      if (val?.trim().length) {
        const dateAndTimeValue = dateAndTimeToZonedDateTimeCurrentZone(currentDate, val);
        const minZonedDateTime = dateOrUnixTimeMillisToZonedDateTimeCurrentZone(minTimestamp.valueOf());

        const isDateAndTimeValBeforeMinTime =
          minZonedDateTime && dateAndTimeValue && minZonedDateTime.unixTimeMillis > dateAndTimeValue.unixTimeMillis;

        invalid = !!isDateAndTimeValBeforeMinTime;
      }
      const errorMessage = formatErrorMessage('minTime', 'Time must be ${ minTime } or later', errorMessages);
      return invalid
        ? {
            message: template(errorMessage)({ minTime: toFormatTime(minTimestamp.valueOf()) }),
            type: ValidationType.MIN_TIME,
          }
        : null;
    };
  }

  /** Expects HH:mm */
  static maxTime(maxTime: Date, includesDate?: boolean): InputValidator {
    return (val, errorMessages = {}) => {
      let invalid = false;
      if (val?.trim().length) {
        const timeVal = includesDate ? val.split(' ')[1] ?? val : val;
        invalid = !!InputValidators.time(timeVal, errorMessages) || isTimeAfter(timeVal, maxTime);
      }
      const errorMessage = formatErrorMessage('maxTime', 'Time must be ${ maxTime } or before', errorMessages);
      return invalid
        ? {
            message: template(errorMessage)({ maxTime: toFormatTime(maxTime.valueOf()) }),
            type: ValidationType.MAX_TIME,
          }
        : null;
    };
  }

  /** Expects HH:mm */
  static timeStep(minutesStep: number, includesDate?: boolean): InputValidator {
    return (val, errorMessages = {}) => {
      const validSteps = range(0, 60, minutesStep);
      let invalid = false;

      if (val?.trim().length) {
        const timeVal = includesDate ? val.split(' ')[1] ?? val : val;
        const dateTime = parseTimeOrUndefined(timeVal);

        if (dateTime) {
          invalid = !validSteps.includes(dateTime.getMinutes());
        } else {
          invalid = true;
        }
      }

      const errorMessage = formatErrorMessage(
        'timeStep',
        'Time must be given in ${ step } minute steps',
        errorMessages,
      );

      return invalid
        ? {
            message: template(errorMessage)({ step: minutesStep }),
            type: ValidationType.TIME_STEP,
          }
        : null;
    };
  }

  static minDate(minDate: Date): InputValidator {
    /** Expects ISO date string */
    return (val, errorMessages = {}) => {
      let invalid = false;
      if (val?.trim().length) {
        invalid = isBefore(val, startOfDay(minDate));
      }
      const errorMessage = formatErrorMessage('minDate', 'Date must be ${ minDate } or later', errorMessages);
      return invalid
        ? {
            message: template(errorMessage)({ minDate: toFormatIsoDate(minDate.valueOf()) }),
            type: ValidationType.MIN_DATE,
          }
        : null;
    };
  }

  static maxDate(maxDate: Date): InputValidator {
    /** Expects ISO date string */
    return (val, errorMessages = {}) => {
      let invalid = false;
      if (val?.trim().length) {
        invalid = isAfter(val, endOfDay(maxDate));
      }
      const errorMessage = formatErrorMessage('maxDate', 'Date must be ${ maxDate } or before', errorMessages);
      return invalid
        ? {
            message: template(errorMessage)({ maxDate: toFormatIsoDate(maxDate.valueOf()) }),
            type: ValidationType.MAX_DATE,
          }
        : null;
    };
  }

  static inPast(): InputValidator {
    return (val, errorMessages = {}) => {
      let invalid = false;
      if (val?.trim().length) {
        const unixTime = timezoneStrToUnixTime(val);
        invalid = !unixTime || Date.now() < unixTime;
      }
      return invalid
        ? {
            message: formatErrorMessage('inPast', 'Value is not a past date', errorMessages),
            type: ValidationType.IN_PAST,
          }
        : null;
    };
  }

  static inFuture(): InputValidator {
    return (val, errorMessages = {}) => {
      let invalid = false;
      if (val?.trim().length) {
        const unixTime = timezoneStrToUnixTime(val);
        invalid = !unixTime || Date.now() > unixTime;
      }
      return invalid
        ? {
            message: formatErrorMessage('inFuture', 'Value is not a future date', errorMessages),
            type: ValidationType.IN_FUTURE,
          }
        : null;
    };
  }

  static isNotInThePast(): InputValidator {
    return (val, errorMessages = {}) => {
      let invalid = false;
      if (val?.trim().length) {
        invalid = !isFutureDateTime(val) && !isIsoDateToday(val);
      }
      return invalid
        ? {
            message: formatErrorMessage('notPast', 'Value is a past date', errorMessages),
            type: ValidationType.NOT_PAST,
          }
        : null;
    };
  }

  static isNotPastDateTime(val: Maybe<string>, errorMessages = {}): InputError | null {
    let invalid = false;
    if (val?.trim().length) {
      invalid = isPastDateTime(val);
    }
    return invalid
      ? {
          message: formatErrorMessage('notPastTime', 'Time can not be in the past', errorMessages),
          type: ValidationType.NOT_PAST_TIME,
        }
      : null;
  }

  static pattern(pattern: string | RegExp, message?: string): InputValidator {
    StateAssert.notNull(pattern);
    let regex: RegExp;
    let regexStr: string;
    if (typeof pattern === 'string') {
      regexStr = '';

      if (!pattern.startsWith('^')) {
        regexStr += '^';
      }

      regexStr += pattern;

      if (!pattern.endsWith('$')) {
        regexStr += '$';
      }

      regex = new RegExp(regexStr);
    } else {
      regexStr = pattern.toString();
      regex = pattern;
    }
    return (val: Maybe<string>) =>
      val == null || val === '' || regex.test(val)
        ? null
        : {
            message: message ?? `Expected pattern ${regexStr}`,
            type: ValidationType.PATTERN,
          };
  }

  static ecode(errorMessages: ErrorMessages | string = 'Must be an E-code, format E1234567'): InputValidator {
    return this.pattern(/^E\d{7}$/, typeof errorMessages === 'string' ? errorMessages : errorMessages?.ecode);
  }

  static correctHumanMeasurement(): InputValidator<number> {
    return (val, errorMessages = {}) => {
      const isValid: boolean = val !== undefined ? inRange(val, 1, 1000) : false;
      const inputError: InputError = {
        message: formatErrorMessage('correctHumanMeasurement', 'Use a value between 1 and 999.999', errorMessages),
        type: ValidationType.CORRECT_HUMAN_MEASUREMENT,
      };
      return isValid || val === undefined ? null : inputError;
    };
  }

  static containsUpperCase(): InputValidator {
    return (val, errorMessages = {}) => {
      const patternValidator = this.pattern(
        /[A-Z]/,
        formatErrorMessage('containsUpperCase', 'Must contain upper case letter', errorMessages),
      );

      return patternValidator(val, errorMessages);
    };
  }

  static containsLowerCase(): InputValidator {
    return (val, errorMessages = {}) => {
      const patternValidator = this.pattern(
        /[a-z]/,
        formatErrorMessage('containsLowerCase', 'Must contain lower case letter', errorMessages),
      );

      return patternValidator(val, errorMessages);
    };
  }

  static containsNumber(): InputValidator {
    return (val, errorMessages = {}) => {
      const patternValidator = this.pattern(
        /\d/,
        formatErrorMessage('containsNumber', 'Must contain number', errorMessages),
      );

      return patternValidator(val, errorMessages);
    };
  }

  static containsSpecialCharacter(): InputValidator {
    return (val, errorMessages = {}) => {
      const patternValidator = this.pattern(
        SPECIAL_CHAR_REGEXP,
        formatErrorMessage('containsSpecialCharacter', 'Must contain special character', errorMessages),
      );

      return patternValidator(val, errorMessages);
    };
  }

  static oneOf(allowedValues: string[], caseSensitive?: boolean): InputValidator {
    return (val, errorMessages = {}) => {
      const processedVal = caseSensitive ? val?.trim() : val?.trim().toLocaleLowerCase();
      const errorMessage = formatErrorMessage(
        'oneOf',
        `Must be one of allowed values: { allowedValues }`,
        errorMessages,
      );
      const inputError: InputError = {
        message: template(errorMessage)({ allowedValues: allowedValues.join(', ') }),
        type: ValidationType.ONE_OF,
      };

      return !!processedVal && !allowedValues.includes(processedVal) ? inputError : null;
    };
  }

  static minArrayLength(
    minLength: number,
    exclusiveOptions: string[] = [],
    allowEmpty?: boolean,
  ): InputValidator<string[]> {
    return (val: Maybe<string[]>, errorMessages = {}) => {
      const errorMessage = formatErrorMessage(
        'minArrayLength',
        'At least ${ minLength } options must be selected',
        errorMessages,
      );

      const isExclusiveOptionSelected = (val ?? []).some(option => exclusiveOptions.includes(option));

      return val == null || (allowEmpty && !val.length) || val.length >= minLength || isExclusiveOptionSelected
        ? null
        : {
            message: template(errorMessage)({ minLength }),
            type: ValidationType.MIN_ARRAY_LENGTH,
          };
    };
  }

  static maxArrayLength =
    (maxLength: number): InputValidator<string[]> =>
    (val: Maybe<string[]>, errorMessages = {}) => {
      const errorMessage = formatErrorMessage(
        'maxArrayLength',
        'Max ${ maxLength } options can be selected',
        errorMessages,
      );

      return val == null || val.length <= maxLength
        ? null
        : {
            message: template(errorMessage)({ maxLength }),
            type: ValidationType.MAX_ARRAY_LENGTH,
          };
    };

  static minValue =
    (minValue: number): InputValidator<number | string> =>
    (val, errorMessages = {}) => {
      const errorMessage = formatErrorMessage('minValue', 'Value must be at least ${ minValue }', errorMessages);

      const parsedVal = parseNumber(val);
      return parsedVal === null || parsedVal >= minValue
        ? null
        : {
            message: template(errorMessage)({ minValue }),
            type: ValidationType.MIN_VALUE,
          };
    };

  static maxValue =
    (maxValue: number): InputValidator<number | string> =>
    (val, errorMessages = {}) => {
      const errorMessage = formatErrorMessage('maxValue', 'Value can not be higher than ${ maxValue }', errorMessages);

      const parsedVal = parseNumber(val);
      return parsedVal === null || parsedVal <= maxValue
        ? null
        : {
            message: template(errorMessage)({ maxValue }),
            type: ValidationType.MAX_VALUE,
          };
    };

  static numericStep =
    (step: number): InputValidator<number | string> =>
    (val, errorMessages = {}) => {
      const errorMessage = formatErrorMessage(
        'numericStep',
        'Value must be given in steps of ${ step }',
        errorMessages,
      );

      const parsedVal = parseNumber(val);
      const result = parsedVal ? MathUtil.divide(parsedVal, step) : 0;

      return parsedVal === null || MathUtil.modulo(result, 1) === 0
        ? null
        : {
            message: template(errorMessage)({ step }),
            type: ValidationType.NUMERIC_STEP,
          };
    };

  static numberOfDecimals =
    (numberOfDecimals: number): InputValidator<number | string> =>
    (val, errorMessages = {}) => {
      const errorMessage = formatErrorMessage(
        'numberOfDecimals',
        'Value must have ${ numberOfDecimals } decimals',
        errorMessages,
      );

      const value = val?.toString() ?? undefined;
      const error = {
        message: template(errorMessage)({ numberOfDecimals }),
        type: ValidationType.NUMBER_OF_DECIMALS,
      };

      if (value === undefined || value === '' || value === `${Number.MIN_SAFE_INTEGER}`) {
        return null;
      } else if (numberOfDecimals === 0 && !NUMBER_REGEXP.test(value)) {
        return error;
      } else if (numberOfDecimals > 0 && !numberWithDecimalsRegexp(numberOfDecimals).test(value)) {
        return error;
      } else {
        return null;
      }
    };

  static notEqualTo =
    <T extends InputType>(notEqValue: Maybe<T>): InputValidator<T> =>
    (val, errorMessages = {}) => {
      const errorMessage = formatErrorMessage(
        'notEqualTo',
        'New value must be different from current value.',
        errorMessages,
      );

      const error = {
        message: errorMessage,
        type: ValidationType.EQUALITY,
      };

      if (val === undefined) {
        return null;
      }

      if (typeof val !== typeof notEqValue) {
        if (typeof val === 'number' || typeof notEqValue === 'number') {
          return Number(val) === Number(notEqValue) ? error : null;
        } else {
          return val.toString() === notEqValue?.toString() ? error : null;
        }
      }

      if (typeof val === 'string' || typeof val === 'number') {
        return val === notEqValue ? error : null;
      }

      if (Array.isArray(val) && Array.isArray(notEqValue)) {
        const sortedNotEqValue = [...notEqValue].sort();
        const sortedVal = [...val].sort();
        const sameEntries = isEqual(sortedNotEqValue, sortedVal);
        return sameEntries ? error : null;
      }

      return null;
    };

  static validNumber(val: Maybe<string>, errorMessages = {}): InputError | null {
    if (val == null || val === '') {
      return null;
    }

    const isValid = !Number.isNaN(Number(val));
    if (isValid) {
      return null;
    } else {
      const inputError: InputError = {
        message: formatErrorMessage('validNumber', 'Invalid number', errorMessages),
        type: ValidationType.VALID_NUMBER,
      };

      return inputError;
    }
  }
}
