/* eslint-disable */
export const UnifyWebDevicesComplianceSchema = {
  description: 'unify:webdevicescompliance',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'UnifyWebDevicesCompliance',
  type: 'object',
  properties: {
    complianceLearnMore: {
      type: 'object',
      title: 'Compliance learn more',
      properties: {
        complianceTitle: { type: 'string', title: 'Compliance Calculation', mock: 'Compliance Calculation' },
        complianceCalculationBodyMd: {
          type: 'string',
          title: 'Compliance calculation body text',
          helper:
            'Text to be displayed explaining compliance calculation and to be displayed when there is no compliance data.',
          mock: 'An explanation on how compliance is calculated, visible to web users in the portal on the learn more section. Total compliance is calculated according to the following formula. Number of completed tasks / number of maximum expected questionnaires from start of study for the patient until last time the web received responses from the app. Recent time period calculates compliance in the same way, but for tha last X day/week/months completed time period instead of the total period.',
        },
        complianceRateTitle: { type: 'string', title: 'Compliance rate title', mock: 'Compliance rate' },
        complianceRateBodyMd: {
          type: 'string',
          title: 'Compliance Rate body text',
          mock: 'A compliance rate explanation for the specific device request, visible to web users in the portal on the learn more section.',
        },
        complianceThresholdTitle: { type: 'string', title: 'Threshold', mock: 'Threshold' },
        complianceThresholdBodyMd: {
          type: 'string',
          title: 'Compliance Threshold body text',
          mock: 'A threshold explanation for the specific device request, visible to web users in the portal on the learn more section.',
        },
      },
    },
    complianceIssuesOverview: {
      type: 'object',
      title: 'Compliance Issues overview',
      properties: {
        issueDomain: { type: 'string', title: 'Issue item domain title', mock: 'Devices' },
        viewDetailsBtnText: { type: 'string', title: 'View details button text', mock: 'View Details' },
      },
    },
    metricsTexts: {
      type: 'object',
      title: 'Device metrics texts',
      properties: {
        completedSuffix: { type: 'string', title: 'Completed suffix', mock: 'completed' },
        expectedSuffix: { type: 'string', title: 'Expected suffix', mock: 'expected' },
        missedSuffix: { type: 'string', title: 'Missed suffix', mock: 'missed' },
        lastSyncedLabel: { type: 'string', title: 'Last sync label', mock: 'Last received data' },
        totalComplianceTitle: { type: 'string', title: 'Title Total Compliance', mock: 'Total compliance' },
        totalCompletedTitle: { type: 'string', title: 'Title total completed', mock: 'Total completed' },
        thresholdTitle: { type: 'string', title: 'Title Threshold', mock: 'Threshold' },
        periodComplianceSuffix: { type: 'string', title: 'Period Compliance suffix', mock: 'compliance' },
        periodTimeUnit: {
          type: 'object',
          title: 'Titles for period time unit in the compliance overview card for devices',
          properties: {
            enum: {
              type: 'object',
              title: 'Titles for period time unit compliance devices',
              properties: {
                DAY: { type: 'string', title: 'Title for days', mock: 'days' },
                WEEK: { type: 'string', title: 'Title for weeks', mock: 'weeks' },
                MONTH: { type: 'string', title: 'Title for months', mock: 'months' },
                YEAR: { type: 'string', title: 'Title for years', mock: 'years' },
              },
            },
          },
        },
      },
    },
  },
};
