import { ErrorDetailV1, ErrorV1 } from '@csp/dmdp-api-common-dto';
import { Maybe } from '../../common/model/Monadish';
import { CspError } from './CspError';
import { CspErrorType } from './CspErrorType';
import { DmdpErrorCodeType } from './DmdpErrorCodeType';

const toDmdpErrorDetails = (details: Maybe<ErrorDetailV1[]>): Maybe<DmdpErrorDetail[]> => details;

type DmdpErrorDetail = {
  key: string;
  message: string;
};

export class DmdpError extends CspError {
  errorCode: string | undefined | DmdpErrorCodeType;
  errorId: string | undefined;
  displayErrorId: string | undefined;
  errorDetails: DmdpErrorDetail[] | undefined;

  constructor(
    type: CspErrorType,
    errorId?: string,
    message?: string,
    errorCode?: string | DmdpErrorCodeType,
    details?: ErrorDetailV1[],
  ) {
    super(type, message);
    this.errorCode = errorCode;
    this.errorId = errorId;
    this.displayErrorId = errorId ? ` (${this.errorId})` : '';
    this.errorDetails = toDmdpErrorDetails(details);

    Object.setPrototypeOf(this, DmdpError.prototype);
  }

  static isDmdpError(err: unknown): err is DmdpError {
    return err instanceof DmdpError;
  }

  static from(cspError: CspErrorType, errorV1?: ErrorV1): DmdpError {
    return new DmdpError(cspError, errorV1?.errorId, errorV1?.message, errorV1?.errorCode, errorV1?.details);
  }
}
