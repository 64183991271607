import {
  ApiOptions,
  Maybe,
  MedicationRequestCode,
  Patient,
  PatientMedicationsMeta,
  RestOptions,
  ZonedDateTime,
} from '@csp/csp-common-model';
import { getMeta } from '@csp/csp-common-util';
import { toRestOptions } from '@csp/csp-fe-auth';
import { UserRestServiceV1 } from '@csp/dmdp-api-client';
import { PatientMedicationsMetaV1, UserMetaV1, UserV1 } from '@csp/dmdp-api-user-dto';
import { cloneDeep } from 'lodash';
import { PatientMedicationMetaFactory } from '../../../model/PatientMedicationMetaFactory';

const addOrUpdateMeta = async (
  patient: Patient,
  patientMedications: PatientMedicationsMetaV1,
  apiOptions: RestOptions,
): Promise<void> => {
  await UserRestServiceV1.addOrUpdateMeta(apiOptions, patient.userV1, patientMedications);
};

const getMedication = (userV1: UserV1): PatientMedicationsMeta => {
  const metas = userV1.metas as Maybe<UserMetaV1[]>;
  const patientMedicationsV1 =
    getMeta(metas, 'PATIENT_MEDICATION_V1')?.data ?? PatientMedicationMetaFactory.emptyPatientMedicationsV1();

  return PatientMedicationMetaFactory.fromPatientMedicationsV1(patientMedicationsV1);
};

/** Clears all visits in the patient's meta */
const clearMedications = async (userId: string, apiOptions?: ApiOptions): Promise<void> => {
  try {
    await UserRestServiceV1.deleteMeta(toRestOptions(apiOptions), userId, 'PATIENT_MEDICATION_V1');
  } catch {
    // Do not fail on this
  }
};

const startMedication = async (
  patient: Patient,
  requestCode: MedicationRequestCode,
  startTimestamp: ZonedDateTime,
  apiOptions?: ApiOptions,
): Promise<PatientMedicationsMeta> => {
  const patientMedicationsMeta: PatientMedicationsMeta = cloneDeep(patient.medications);
  patientMedicationsMeta.requests[requestCode] = {
    startTimestamp,
  };

  const patientMedicationsMetaV1 = PatientMedicationMetaFactory.toPatientMedicationsMetaV1(patientMedicationsMeta);

  await addOrUpdateMeta(patient, patientMedicationsMetaV1, toRestOptions(apiOptions));

  return patientMedicationsMeta;
};

export const PatientMedicationMetaService = {
  startMedication,
  getMedication,
  clearMedications,
};
