import { v4 as uuid } from 'uuid';
import CryptoJS from 'crypto-js';
import { DmdpHttpHeaderConst } from '../type/DmdpHttpHeaderConst';

const generateHeaders = (
  clientId: string,
  clientSecret: string,
  method: 'PUT' | 'GET' | 'POST',
): Record<string, string> => {
  const timestamp: number = new Date().getTime();
  const uid = uuid();
  const digestStr = `${method}${clientId}${uid}${timestamp}`;
  const digest = CryptoJS.HmacSHA256(digestStr, clientSecret);
  const clientIdAndSecret = `${clientId}:${digest}`;

  const accessToken = `HMAC ${clientIdAndSecret}`;

  return {
    [DmdpHttpHeaderConst.HMAC_DMDP_TIMESTAMP]: `${timestamp}`,
    [DmdpHttpHeaderConst.HMAC_DMDP_NONCE]: uid,
    [DmdpHttpHeaderConst.AUTHORIZATION]: accessToken,
  };
};

export const HmacUtil = {
  generateHeaders,
};
