import { PatientCopingTipConfigV1 } from '@csp/dto';
import { Maybe, ObjectType } from '@csp/csp-common-model';
import { PATIENT_COPING_TIP_CONFIG_V1 } from '../model/PatientCopingTipConfigV1';

const toPatientCopingTipConfigFromMetaV1 = (meta?: Maybe<ObjectType>): Maybe<PatientCopingTipConfigV1> => {
  const patientCopingTipConfig = meta ? (meta[PATIENT_COPING_TIP_CONFIG_V1] as PatientCopingTipConfigV1) : undefined;
  if (!patientCopingTipConfig) {
    return undefined;
  }
  if (!patientCopingTipConfig.module) {
    throw new Error(`Not a valid PatientCopingTipConfigV1, missing: module in config`);
  }
  return {
    module: patientCopingTipConfig.module,
  };
};

export const PatientCopingTipMetaMapper = {
  toPatientCopingTipConfigFromMetaV1,
};
