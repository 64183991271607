import { GenericPackageResourceContent, PublicPackageResourceContent } from '@csp/csp-web-content-definitions';
import {
  getWebGenericResourceContentBlock,
  getWebGenericResourceContentEntry,
  getWebPublicResourceContentBlock,
  getWebPublicResourceContentEntry,
} from '@csp/cps-web-content';
import { ApiOptions, CspError } from '@csp/csp-common-model';
import { GenericContentService } from './GenericContentService';
import { PublicContentService } from './PublicContentService';

const getGenericEntry = <EntryKey extends keyof GenericPackageResourceContent>(
  entryIdentifier: EntryKey,
): GenericPackageResourceContent[EntryKey] => {
  const content = GenericContentService.getResource();

  const entry = getWebGenericResourceContentEntry(content, entryIdentifier);

  if (!entry) {
    throw CspError.badState(`Entry ${entryIdentifier} does not exist`);
  }

  return entry;
};

const getGenericBlock = <
  EntryKey extends keyof GenericPackageResourceContent,
  BlockKey extends keyof GenericPackageResourceContent[EntryKey],
>(
  entryIdentifier: EntryKey,
  blockIdentifier: BlockKey,
): GenericPackageResourceContent[EntryKey][BlockKey] => {
  const content = GenericContentService.getResource();
  const block = getWebGenericResourceContentBlock(content, entryIdentifier, blockIdentifier);

  if (!block) {
    throw CspError.badState(`Block ${String(blockIdentifier)} in entry ${entryIdentifier} does not exist`);
  }

  return block;
};

const getPublicBlock = <
  EntryKey extends keyof PublicPackageResourceContent,
  BlockKey extends keyof PublicPackageResourceContent[EntryKey],
>(
  entryIdentifier: EntryKey,
  blockIdentifier: BlockKey,
): PublicPackageResourceContent[EntryKey][BlockKey] => {
  const content = PublicContentService.getResource();
  const block = getWebPublicResourceContentBlock(content, entryIdentifier, blockIdentifier);

  if (!block) {
    throw CspError.badState(`Block ${String(blockIdentifier)} in entry ${entryIdentifier} does not exist`);
  }

  return block;
};

const getPublicEntry = <EntryKey extends keyof PublicPackageResourceContent>(
  entryIdentifier: EntryKey,
): PublicPackageResourceContent[EntryKey] => {
  const content = PublicContentService.getResource();

  const entry = getWebPublicResourceContentEntry(content, entryIdentifier);

  if (!entry) {
    throw CspError.badState(`Entry ${entryIdentifier} does not exist`);
  }

  return entry;
};

const fetchGenericEntryByLocale = async <EntryKey extends keyof GenericPackageResourceContent>(
  locale: string,
  entryIdentifier: EntryKey,
  apiOptions?: ApiOptions,
): Promise<GenericPackageResourceContent[EntryKey]> => {
  const content = await GenericContentService.fetchResourceByLocale(locale, apiOptions);

  const entry = getWebGenericResourceContentEntry(content, entryIdentifier);

  if (!entry) {
    throw CspError.badState(`Entry ${entryIdentifier} does not exist for locale ${locale}`);
  }

  return entry;
};

export const StaticContentService = {
  getPublicBlock,
  getPublicEntry,
  getGenericBlock,
  getGenericEntry,
  fetchGenericEntryByLocale,
};
