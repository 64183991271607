import { ConsentService, mapUserRolesToVideoTrainingConsentTypes } from '@csp/common';
import { Maybe } from '@csp/csp-common-model';
import { ConsentType } from '@csp/dmdp-api-user-dto';
import { isNotLoading, useFetch } from '@csp/fe-hooks';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getStudySelector, getUserSelector } from '../../../security/ducks/principalContextSelectors';
import { canSkipConsentApprovalsSelector } from '../../ducks/consentPermissionSelector';
import { GenericConsentTypes } from '../../model/GenericConsentType';
import { ConsentApproval } from '../model/ConsentApproval';
import { ConsentContextState } from '../model/ConsentContextState';
import { toConsentApprovals } from '../util/ConsentApprovalUtil';

export const useConsentApprovals = (): ConsentContextState => {
  const study = useSelector(getStudySelector);
  const countryCode = study?.firstCountryOrg?.countryCode;
  const user = useSelector(getUserSelector);
  const hasPermissionToSkipConsents = useSelector(canSkipConsentApprovalsSelector);

  const [doFetch, fetchState, resetState] = useFetch(
    ConsentService.getActiveOutcomesAndArtifacts,
    isNotLoading,
    toConsentApprovals,
  );

  const [doFetchPreviousApprovals, previousApprovalsFetchState, resetPreviousApprovalsState] = useFetch(
    ConsentService.getPreviousAndCurrentApprovals,
    isNotLoading,
  );

  const fetchConsentApprovals = useCallback(async () => {
    if (countryCode && !fetchState.isLoading && user) {
      const consentTypes = mapUserRolesToVideoTrainingConsentTypes(user.roles);

      if (!hasPermissionToSkipConsents) {
        consentTypes.unshift(...GenericConsentTypes);
      }

      await doFetch(consentTypes, countryCode.toUpperCase());
    }
  }, [countryCode, doFetch, fetchState.isLoading, hasPermissionToSkipConsents, user]);

  const getConsentApproval = useCallback(
    (type: ConsentType): Maybe<ConsentApproval> => fetchState.data?.find(approval => approval.consentType === type),
    [fetchState.data],
  );

  const hasPendingApprovals = useCallback(
    (types: ConsentType[]): boolean => {
      const approvals = fetchState.data ?? [];

      return approvals.some(approval => types.includes(approval.consentType) && !approval.hasApproval);
    },
    [fetchState.data],
  );

  const hasPendingVideoTrainingApprovals = useCallback((): boolean => {
    const userRoles = user?.roles ?? [];
    const types = mapUserRolesToVideoTrainingConsentTypes(userRoles);

    return hasPendingApprovals(types);
  }, [hasPendingApprovals, user?.roles]);

  const hasOutdatedApprovals = useCallback(
    (types: ConsentType[]): boolean => {
      const previousAndCurrentApprovals = previousApprovalsFetchState.data ?? [];

      return previousAndCurrentApprovals.some(({ artifactType }) => {
        const consentType = artifactType as ConsentType;
        return types.includes(consentType) && hasPendingApprovals([consentType]);
      });
    },
    [hasPendingApprovals, previousApprovalsFetchState.data],
  );

  const hasOutdatedVideoTrainingApprovals = useCallback((): boolean => {
    const userRoles = user?.roles ?? [];
    const types = mapUserRolesToVideoTrainingConsentTypes(userRoles);

    return hasOutdatedApprovals(types);
  }, [hasOutdatedApprovals, user?.roles]);

  const firstPendingApproval = useCallback(
    (types: ConsentType[]): Maybe<ConsentApproval> =>
      fetchState.data?.find(approval => types.includes(approval.consentType) && !approval.hasApproval),
    [fetchState.data],
  );

  const resetConsentApprovals = useCallback((): void => {
    resetState();
    resetPreviousApprovalsState();
  }, [resetPreviousApprovalsState, resetState]);

  useEffect(() => {
    if (user) {
      const consentTypes = [...GenericConsentTypes, ...mapUserRolesToVideoTrainingConsentTypes(user.roles)];

      if (hasPendingApprovals(consentTypes)) {
        doFetchPreviousApprovals(consentTypes, user.userId);
      }
    }
  }, [doFetchPreviousApprovals, hasPendingApprovals, user]);

  return useMemo(
    () => ({
      approvals: fetchState.data ?? [],
      fetchConsentApprovals,
      getConsentApproval,
      hasPendingApprovals,
      hasOutdatedApprovals,
      hasOutdatedVideoTrainingApprovals,
      hasPendingVideoTrainingApprovals,
      firstPendingApproval,
      loading: fetchState.isLoading || (hasPendingVideoTrainingApprovals() && previousApprovalsFetchState.isLoading),
      error: fetchState.error || previousApprovalsFetchState.error,
      resetConsentApprovals,
    }),
    [
      fetchConsentApprovals,
      fetchState.data,
      fetchState.error,
      fetchState.isLoading,
      firstPendingApproval,
      getConsentApproval,
      hasOutdatedApprovals,
      hasOutdatedVideoTrainingApprovals,
      hasPendingApprovals,
      hasPendingVideoTrainingApprovals,
      previousApprovalsFetchState.error,
      previousApprovalsFetchState.isLoading,
      resetConsentApprovals,
    ],
  );
};
