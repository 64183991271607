import { FC } from 'react';
import { BrandedIcon, OnClickButton, useMediaQ } from '@csp/csp-web-ui';
import { IconButton } from '@mui/material';

type Props = {
  onClick?: OnClickButton;
  popoverIsOpen: boolean;
};

export const ProfileIconButton: FC<Props> = ({ onClick, popoverIsOpen = false, ...rest }) => {
  const smUp = useMediaQ('sm', 'up');

  return (
    <IconButton
      sx={{ height: '100%', borderRadius: '60px', ml: { xs: 0, sm: 1, lg: 2 } }}
      centerRipple={false}
      onClick={onClick}
      {...rest}
    >
      <BrandedIcon name={smUp ? 'profileAvatar' : 'profile'} size={smUp ? 'xxl' : 'large'} color={'black'} />

      {smUp && (
        <>
          {popoverIsOpen ? (
            <BrandedIcon ml={1} name="chevronUpRound" size="large" color="black" />
          ) : (
            <BrandedIcon ml={1} name="chevronDownRound" size="large" color="black" />
          )}
        </>
      )}
    </IconButton>
  );
};
