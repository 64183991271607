import { CspPodConfig } from './CspPodConfig';

export type CspPodConfigs = {
  csppod: CspPodConfig;
  contentdeliverypod: CspPodConfig;
};

const empty = (): CspPodConfigs => ({
  csppod: {
    baseUrl: '',
  },
  contentdeliverypod: {
    baseUrl: '',
  },
});

export const CspPodConfigs = {
  empty,
};
