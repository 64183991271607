/* eslint-disable */
export const UnifyWebPatientComplianceStudySchema = {
  title: 'UnifyWebPatientComplianceStudy',
  type: 'object',
  properties: {
    overallCompliance: {
      type: 'object',
      title: 'Study specific Patient Overall Compliance',
      properties: {
        learnMore: {
          type: 'object',
          title: 'Study specific overall compliance learn more drawer',
          properties: {
            labelsAndDescriptions: {
              title: 'Study specific general overall compliance learn more content items',
              type: 'array',
              items: {
                title: 'Study specific general overall compliance learn more content item',
                type: 'object',
                properties: {
                  label: { type: 'string', title: 'Study specific learn more content item label' },
                  bodyMd: {
                    type: 'string',
                    title: 'Study specific learn more content item description. Markdown is supported',
                  },
                },
              },
              mock: [],
            },
          },
        },
      },
    },
    detailedCompliance: {
      type: 'object',
      title: 'Study specific Patient Detailed Compliance',
      properties: {
        learnMore: {
          type: 'object',
          title: 'Study specific detailed compliance learn more drawer',
          properties: {
            labelsAndDescriptions: {
              title: 'Study specific general detailed compliance learn more content items',
              type: 'array',
              items: {
                title: 'Study specific general detailed compliance learn more content item',
                type: 'object',
                properties: {
                  label: { type: 'string', title: 'Study specific learn more content item label' },
                  bodyMd: {
                    type: 'string',
                    title: 'Study specific learn more content item description. Markdown is supported',
                  },
                },
              },
              mock: [],
            },
          },
        },
      },
    },
  },
  description: 'unify:webpatientcompliancestudy',
  $schema: 'http://json-schema.org/draft-07/schema#',
};
