/* eslint-disable */
export const UnifyWebStudyMetricsSchema = {
  title: 'UnifyWebStudyMetrics',
  type: 'object',
  properties: {
    drawer: {
      type: 'object',
      title: 'Study Metrics Drawer content',
      properties: {
        buttons: {
          type: 'object',
          title: 'Study Metrics Drawer button texts',
          properties: {
            learnMore: {
              type: 'string',
              title: 'Learn more button',
              mock: 'Learn more',
              helper: 'Learn more button text',
            },
          },
        },
        texts: {
          type: 'object',
          title: 'Study Metrics Drawer content texts',
          properties: {
            learnMoreHeader: {
              type: 'string',
              title: 'Learn more drawer header',
              mock: 'Learn more',
              helper: 'Learn more drawer header text',
            },
            learnMoreContentMd: {
              type: 'string',
              title: 'Content for learn more as markdown',
              mock: "\n\n#####How to read the recruitment data \n <br/> **Invited Unify app users** \n\n The accumulated actual number of Unify app users with Unify app user status 'Invited+Yes+Retired+Alumni'. \n\n <br/> **Active Unify app users** \n\n The accumulated actual number of Unify app users with Unify app user status 'Invited+Yes+Retired+Alumni'. \n\n <br/> **Screened** \n\n The accumulated actual number of Unify app users with Unify app user status 'Invited+Yes+Retired+Alumni'. \n\n  <br/> **Screen failed** \n\n The accumulated actual number of Unify app users with Unify app user status 'Invited+Yes+Retired+Alumni'. \n\n <br/> **Randomized** \n\n The accumulated actual number of Unify app users with Unify app user status 'Invited+Yes+Retired+Alumni'. \n\n <br/> **Withdrawn** \n\n The accumulated actual number of Unify app users with Unify app user status 'Invited+Yes+Retired+Alumni'. \n\n <br/> **Potential Lost to Follow Up** \n\n The accumulated actual number of Unify app users with Unify app user status 'Invited+Yes+Retired+Alumni'. \n\n <br/> **Completed** \n\n The accumulated actual number of Unify app users with Unify app user status 'Invited+Yes+Retired+Alumni'.",
              helper: 'Content for learn more as markdown',
            },
          },
        },
      },
    },
    recruitmentStatus: {
      type: 'object',
      title: 'Study Metrics Recruitment status',
      properties: {
        texts: {
          type: 'object',
          title: 'Recruitment status texts',
          properties: {
            title: {
              type: 'string',
              title: 'Recruitment status card header',
              mock: 'Recruitment status',
              helper: 'Card header title texts for recruitment status',
            },
            metricFilters: {
              type: 'string',
              title: 'Metric filters',
              mock: 'Metrics filters',
              helper: 'Text to display in the metric filter dropdown component.',
            },
            lastSync: {
              type: 'string',
              title: 'Last sync date and time',
              mock: 'Last sync: ${lastSyncAt}',
              helper: 'Text to display in each metric card about sync date.',
            },
            emptyResult: {
              type: 'string',
              title: 'Empty result',
              mock: 'No metrics available',
              helper: 'Empty results texts for metrics cards',
            },
            metricTypeLabels: {
              type: 'object',
              title: 'Study Metrics type labels',
              properties: {
                SCREENED_TOTAL: {
                  type: 'string',
                  title: 'Total number of Screened Patients',
                  mock: 'Screened',
                  helper: 'Screened patients has given their Informed Consent and been assigned an Enrolment Code',
                },
                RANDOMIZED_TOTAL: {
                  type: 'string',
                  title: 'Total number of Randomized Patients',
                  mock: 'Randomized',
                  helper: 'Randomized patients is assigned a Randomization Code',
                },
                IN_SCREENING_TOTAL: {
                  type: 'string',
                  title: 'Total number of Patients In Screening',
                  mock: 'In screening',
                  helper: 'Current number of patients Screened but not yet Randomized',
                },
                SCREEN_FAILED_TOTAL: {
                  type: 'string',
                  title: 'Total number of Screen Failures',
                  mock: 'Screen failed',
                  helper: 'Accumulated number of screened patients that are not eligible to be randomized to the study',
                },
                COMPLETED: {
                  type: 'string',
                  title: 'Total number of Completed Patients',
                  mock: 'Completed',
                  helper: 'Accumulated number of patients who have completed all visits and assessments of the study',
                },
                DROP_OUT_RATE: {
                  type: 'string',
                  title: 'Patient Dropout Rate',
                  mock: 'Dropout rate',
                  helper: 'Ratio between number of patients withdrawn from study and the total amount of patients',
                },
                INVITED_UNIFY_APP_USERS: {
                  type: 'string',
                  title: 'Total number of invited app users',
                  mock: 'Invited Unify App Users',
                },
                ACTIVE_UNIFY_APP_USERS: {
                  type: 'string',
                  title: 'Total number of active app users',
                  mock: 'Active Unify App Users',
                },
              },
            },
          },
        },
        errors: {
          type: 'object',
          title: 'Custom Error Texts',
          properties: {
            notFound: {
              type: 'string',
              title: 'Http status 404 Not found',
              mock: 'Selected organisation is not part of study, e.g. not synced with VCV.',
              helper: 'Specific error message for http error code 404 Organisation is not part of Study',
            },
          },
        },
      },
    },
    multiSiteComparison: {
      type: 'object',
      title: 'Multi sites comparison',
      properties: {
        texts: {
          type: 'object',
          title: 'Labels for multi sites comparison section',
          properties: {
            title: {
              type: 'string',
              title: 'Multi sites comparison card header',
              mock: 'Multi-sites comparison',
              helper: 'Card header title texts for Multi site comparison.',
            },
            dropdownLabel: {
              type: 'string',
              title: 'Multi sites comparison dropdown label',
              mock: 'Data points',
              helper: 'label text for dropdown with different data points to choose from.',
            },
          },
        },
      },
    },
  },
  description: 'unify:webstudymetrics',
  $schema: 'http://json-schema.org/draft-07/schema#',
};
