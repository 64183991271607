import styled from '@emotion/styled';
import { Box, Container, Grid } from '@mui/material';
import { FC, ReactNode } from 'react';
import { sizing, SizingProps, spacing, SpacingProps } from '@mui/system';
import { Illustration } from '../../components/illustration/Illustration';
import { IllustrationType } from '../../components/illustration/model/IllustrationSvgs';
import { Body1, H1, H5 } from '../../components/typography/TextStyles';
import { Button } from '../../components/buttons/Button';
import { OnClick } from '../../common/model/EventModels';
import { Page } from '../../components/layout/page/Page';
import { useTheme } from '../../utils/MaterialUtils';
import { useMediaQ } from '../../hooks/useMediaQ';
import { PageHeaderUnifyLogo } from '../../components/layout/page/PageHeaderUnifyLogo';

export type ATagInjectedProps = {
  children: JSX.Element;
};

type Props = {
  label?: string;
  subLabel?: string;
  body?: string;
  altText?: string;
  illustration: IllustrationType;
  renderLogoContainer?: FC<ATagInjectedProps>;
  useRelativeHeight?: boolean;
  action?: OnClick;
  actionLabel?: string;
  errorId?: string;
  children?: ReactNode;
};

const StyledContainer = styled(Container)<SizingProps>`
  ${sizing}
`;
const StyledGrid = styled(Grid)<SizingProps>`
  ${sizing}
`;

const PageHeader = styled(H1)<SpacingProps>`
  ${spacing}
`;

const PageSubTitle = styled(H5)<SpacingProps>`
  ${({ theme }): string => `
    color: ${theme.palette.text.secondary};
  `};
  font-weight: 400;
  ${spacing}
`;

const StyledDiv = styled.div<SpacingProps>`
  ${spacing}
`;

export const ErrorPage: FC<Props> = ({
  label,
  subLabel,
  children,
  body,
  illustration,
  altText,
  renderLogoContainer,
  useRelativeHeight,
  action,
  actionLabel,
  errorId,
}) => {
  const { palette, spacing } = useTheme();
  const lgDown = useMediaQ('lg', 'down');

  const renderContainer = renderLogoContainer?.({
    children: <PageHeaderUnifyLogo />,
  });

  const ActionButton = (
    <Box textAlign={{ xs: 'center', lg: 'left' }}>
      <Button onClick={action} size="large" variant="contained" color="primary" mt={{ xs: spacing(2), lg: 0 }}>
        {actionLabel}
      </Button>
    </Box>
  );

  return (
    <Page style={{ background: palette.grey[50] }}>
      <StyledContainer height={{ md: `100${useRelativeHeight ? '%' : 'vh'}` }}>
        <StyledGrid container height={{ lg: '100%' }}>
          {renderLogoContainer && (
            <Grid item xs={12} style={{ position: 'absolute' }}>
              <StyledDiv mt={2}>{renderContainer}</StyledDiv>
            </Grid>
          )}

          <StyledGrid xs={12} lg={6} container item justifyContent="center" alignItems="center" height={{ lg: '100%' }}>
            <Box
              textAlign="center"
              maxWidth={{ xs: '70vw', lg: '50vh' }}
              maxHeight={{ xs: '70vw', lg: '50vh' }}
              mt={{ xs: 10, lg: 0 }}
              mb={{ xs: 5, lg: 0 }}
            >
              <Illustration
                name={illustration}
                altText={altText}
                maxWidth={{ xs: '50vw', lg: '60vh' }}
                maxHeight={{ xs: '50vw', lg: '60vh' }}
                pr={{ xs: 0, lg: 4 }}
              />
            </Box>
          </StyledGrid>

          <Grid container item xs={12} lg={6} justifyContent="center" direction="column">
            <>
              {label && (
                <PageHeader align={lgDown ? 'center' : undefined} mb={3}>
                  {label}
                </PageHeader>
              )}

              {subLabel && (
                <PageSubTitle align={lgDown ? 'center' : undefined} color="textSecondary" mb={2}>
                  {subLabel}
                </PageSubTitle>
              )}
              {body && (
                <PageSubTitle align={lgDown ? 'center' : undefined} mb={2}>
                  {body}
                </PageSubTitle>
              )}
              {errorId && (
                <Body1 align={lgDown ? 'center' : undefined} mb={lgDown ? 4 : 5} color="textSecondary">
                  Error Id: {errorId}
                </Body1>
              )}
              {action && ActionButton}
            </>

            {children}
          </Grid>
        </StyledGrid>
      </StyledContainer>
    </Page>
  );
};
