import { Maybe } from '@csp/csp-common-model';
import { DeviceObservationType } from '@csp/device-catalog';
import { RefV1 } from '@csp/dmdp-api-device-dto';
import { DeviceObservationCorrelationId } from '../model/DeviceObservationCorrelationId';
import { DeviceObservationRefsKey } from '../model/DeviceObservationRefsKey';

export class DeviceObservationRefs {
  static getObservationType(refs: RefV1[]): Maybe<DeviceObservationType> {
    return refs.find(({ key }) => key === DeviceObservationRefsKey.DEVICE_OBSERVATION_TYPE)
      ?.value as DeviceObservationType;
  }

  static getCorrelationId(refs: RefV1[]): Maybe<DeviceObservationCorrelationId> {
    return refs.find(({ key }) => key === DeviceObservationRefsKey.CORRELATION_ID)?.value;
  }
}
