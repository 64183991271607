const UPPER = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const LOWER = UPPER.toLowerCase();
const DIGITS = '0123456789';
const ALPHA_NUM = UPPER + LOWER + DIGITS;

const ofLength = (length: number, charset = ALPHA_NUM): string => {
  let result = '';
  const charsetLength = charset.length;
  for (let i = 0; i < length; i++) {
    result += charset.charAt(Math.floor(Math.random() * charsetLength));
  }
  return result;
};

export const RandomString = {
  ofLength,
};
