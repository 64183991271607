import { cloneDeep, isObject, mergeWith } from 'lodash';

const mergeFunc = (mockedValue: unknown, fetchedValue: unknown): unknown => {
  if (isObject(mockedValue)) {
    return mergeWith(mockedValue, fetchedValue, mergeFunc);
  } else {
    return fetchedValue || mockedValue;
  }
};

// Best guess merge - not bullet proof when it comes to arrays
const mergeContent = <T>(mockedContents: T[], fetchedContents: T[]): T[] => {
  const len = mockedContents.length > fetchedContents.length ? mockedContents.length : fetchedContents.length;
  const mergedContents: T[] = [];
  for (let i = 0; i < len; i++) {
    const mockedContent = mockedContents[i];
    const fetchedContent = fetchedContents[i];
    const mergedContent = cloneDeep(mockedContent ?? ({} as T));
    mergeWith(mergedContent, fetchedContent, mergeFunc);
    mergedContents.push(mergedContent);
  }
  return mergedContents;
};

const mapEnum = <T extends Record<string, string>>(
  value: string | number,
  content: { enum: T },
  defaultValue = '',
): keyof T => content.enum[value] ?? defaultValue;

export const ContentUtil = {
  mergeContent,
  mapEnum,
};
