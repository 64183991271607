import { EnumUtil } from '@csp/csp-common-enum-util';
import {
  Maybe,
  PatientAppStatusType,
  PatientStatusType,
  PatientStudyStatusType,
  StateAssert,
  TimeStr,
} from '@csp/csp-common-model';
import { GenericFixedActionV2 } from '../model/__internal__/GenericFixedActionV2';
import { GenericWhileV2 } from '../model/__internal__/GenericWhileV2';
import { CustomActionDateType } from '../model/custom-action/CustomActionDateType';
import { CustomActionKeyAcceptedValues, CustomActionMetaKeys } from '../model/custom-action/CustomActionMeta';
import { CustomAction } from '../model/schedulingModels/CustomAction';
import { FixedAction } from '../model/schedulingModels/FixedAction';
import { FixedActionType } from '../model/schedulingModels/FixedActionType';
import { RequestWhile } from '../model/schedulingModels/RequestWhile';
import { RequestWhileCriterionType } from '../model/schedulingModels/RequestWhileCriterionType';
import { RequestWhileOperator } from '../model/schedulingModels/RequestWhileOperator';

const customActionDateTypeToFixedAction = (customActionDateType: CustomActionDateType): Maybe<FixedAction> => {
  if (customActionDateType === 'ACTIVATION') {
    return {
      type: FixedActionType.USER_CUSTOM_STATUS_SET,
      subType: PatientStatusType.APP,
      value: PatientAppStatusType.ACTIVE,
    };
  } else if (customActionDateType === 'RANDOMIZATION') {
    return {
      type: FixedActionType.USER_CUSTOM_STATUS_SET,
      subType: PatientStatusType.STUDY,
      value: PatientStudyStatusType.RANDOMIZED,
    };
  } else if (customActionDateType === 'SCREENING') {
    return {
      type: FixedActionType.USER_CUSTOM_STATUS_SET,
      subType: PatientStatusType.STUDY,
      value: PatientStudyStatusType.SCREENED,
    };
  } else {
    return undefined;
  }
};

const getCustomActionDateType = (customAction: CustomAction): CustomActionDateType => {
  if (customAction.meta) {
    switch (customAction.meta[CustomActionMetaKeys.DATE]) {
      case CustomActionKeyAcceptedValues.DATE.RANDOMIZATION: {
        return 'RANDOMIZATION';
      }
      case CustomActionKeyAcceptedValues.DATE.SCREENING: {
        return 'SCREENING';
      }
      case CustomActionKeyAcceptedValues.DATE.ACTIVATION: {
        return 'ACTIVATION';
      }
      default: {
        return 'UNKNOWN';
      }
    }
  } else {
    return 'UNKNOWN';
  }
};

const customActionTimeToFixedTime = (customAction: CustomAction): Maybe<TimeStr> => {
  if (customAction.meta) {
    const timeMetaProp = customAction.meta[CustomActionMetaKeys.TIME];
    return timeMetaProp !== undefined ? (timeMetaProp as string) : undefined;
  } else {
    return undefined;
  }
};

const customActionDateToFixedAction = (customAction: CustomAction): Maybe<FixedAction> => {
  const customActionDateType = getCustomActionDateType(customAction);
  return customActionDateTypeToFixedAction(customActionDateType);
};

const toRequestWhile = (requestWhileV2: Maybe<GenericWhileV2>): Maybe<RequestWhile> =>
  requestWhileV2?.criteria
    ? {
        criteria: requestWhileV2.criteria.map(criteriaV2 => {
          StateAssert.notNull(
            criteriaV2.type,
            `While criteria type must be specified. while config: ${JSON.stringify(requestWhileV2)}`,
          );
          StateAssert.notNull(
            criteriaV2.value,
            `While criteria value must be specified. while config: ${JSON.stringify(requestWhileV2)}`,
          );
          StateAssert.notNull(
            criteriaV2.subType,
            `While criteria subType must be specified. while config: ${JSON.stringify(requestWhileV2)}`,
          );
          return {
            operator: EnumUtil.fromMaybeStringOrDefault(
              criteriaV2.operator,
              RequestWhileOperator,
              RequestWhileOperator.IN,
            ),
            type: EnumUtil.fromStringOrError(criteriaV2.type, RequestWhileCriterionType),
            subType: criteriaV2.subType,
            values: criteriaV2.value,
          };
        }),
      }
    : undefined;

const toFixedAction = (requestFixedActionV2: Maybe<GenericFixedActionV2>): Maybe<FixedAction> => {
  if (requestFixedActionV2) {
    StateAssert.notNull(requestFixedActionV2.subType, 'Request fixedAction must have a subType specified');
    StateAssert.notNull(requestFixedActionV2.value, 'Request fixedAction must have a value specified');
    return {
      type: EnumUtil.fromStringOrError(requestFixedActionV2.type, FixedActionType),
      subType: requestFixedActionV2.subType,
      value: requestFixedActionV2.value,
    };
  } else {
    return undefined;
  }
};

export const GenericRequestMapper = {
  customActionDateToFixedAction,
  customActionTimeToFixedTime,
  toRequestWhile,
  toFixedAction,
};
