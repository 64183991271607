import { Maybe } from '@csp/csp-common-model';
import { useCallback, useState } from 'react';
import { Notification } from '../model/Notification';
import { NotificationOptions } from '../model/NotificationOptions';
import { NotificationsContextState } from '../model/NotificationsContextState';

export const useNotificationsContextProvider = (): NotificationsContextState => {
  const [notification, setNotification] = useState<Notification>();

  const showNotification = useCallback((message: Maybe<string>, options?: NotificationOptions): void => {
    if (message) {
      setNotification({
        message,
        options,
      });
    }
  }, []);

  const removeNotification = useCallback((): void => {
    setNotification(undefined);
  }, []);

  return {
    notification,
    showNotification,
    removeNotification,
  };
};
