import { useSelector } from 'react-redux';
import { PermissionService } from '../../security/service/PermissionService';
import { PermissionType } from '../../security/type/PermissionType';
import { isProdLikeEnv } from '../../../system/service/CspConfigService';
import { getUserSelector } from '../../security/ducks/principalContextSelectors';

export const useCanDebugCms = (): boolean => {
  const user = useSelector(getUserSelector);

  const userCanAlwaysDebugCms = !!user?.orgIdsIncludingParents.some(orgId =>
    PermissionService.hasPermission(PermissionType.CAN_DEBUG_CMS, orgId),
  );

  return userCanAlwaysDebugCms || !isProdLikeEnv();
};
