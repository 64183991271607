/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ConsentApprovalIncludeFieldV1 {
    USER_ID = 'USER_ID',
    ARTIFACT_CODE = 'ARTIFACT_CODE',
    ARTIFACT_TYPE = 'ARTIFACT_TYPE',
    APPROVAL_ID = 'APPROVAL_ID',
    DOCUMENT_REFERENCE = 'DOCUMENT_REFERENCE',
    SCHEDULE_REQUEST_REFERENCE = 'SCHEDULE_REQUEST_REFERENCE',
    EVENT_ID = 'EVENT_ID',
    WITHDRAWN = 'WITHDRAWN',
}