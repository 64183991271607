import { cmsMiddleware, commonReducer as common, CommonStoreState } from '@csp/common';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import { faultReducer as fault } from '../feature/fault/ducks/faultReducer';
import { principalContext } from '../feature/security/ducks/principalContextReducer';
import { studies } from '../feature/studies/ducks/studiesReducer';
import { studyConfigReducer as studyConfig } from '../feature/study-config/ducks/studyConfigReducer';

const reducer = combineReducers({
  principalContext,
  studies,
  fault,
  common,
  studyConfig,
});

export const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }).concat(cmsMiddleware({})),
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const commonSelector = (state: RootState): CommonStoreState => state.common;
