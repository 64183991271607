import { ContentRole } from '@csp/csp-common-content-utils';
import { ContentCacheService } from '../../model/ContentCacheService';
import { CachePolicy, ContentstackConfig } from '../model/ContentstackConfig';

const singleton: { contentstackConfig: ContentstackConfig } = {
  contentstackConfig: ContentstackConfig.empty(),
};

const set = (contentstackConfig: ContentstackConfig): void => {
  singleton.contentstackConfig = contentstackConfig;
};

const setLocale = (locale: string): void => {
  set({ ...get(), locale });
};

const setRole = (role: ContentRole): void => {
  set({ ...get(), role });
};

const setCacheProvider = (cacheProvider: ContentCacheService): void => {
  set({ ...get(), cacheProvider });
};

const setForceContentPreview = (forceContentPreview: boolean): void => {
  set({ ...get(), forceContentPreview });
};

const setCachePolicy = (cachePolicy: CachePolicy): void => {
  set({ ...get(), cachePolicy });
};

const get = (): ContentstackConfig => singleton.contentstackConfig;

export const ContentstackConfigService = {
  get,
  set,
  setLocale,
  setRole,
  setCacheProvider,
  setForceContentPreview,
  setCachePolicy,
};
