import { CmsContentTag } from '@csp/csp-fe-content';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { cmsActions } from '../redux/cmsReducer';

/**
 * Cleanup any old study specific content, done once during mounting.
 */
export const useClearStudySpecificCmsContent = (): (() => void) => {
  const dispatch = useDispatch();

  return useCallback((): void => {
    dispatch(cmsActions.clearCmsByTag(CmsContentTag.STUDY_SPECIFIC));
  }, [dispatch]);
};
