import { CustomStatusMapper } from '@csp/csp-common-custom-status';
import { isFutureDateTime, isPastDateTime, toFormatDateTime, toFormatIsoDate } from '@csp/csp-common-date-util';
import { EnumUtil } from '@csp/csp-common-enum-util';
import { AuditMapper, LocalizationMapper } from '@csp/csp-common-mapper';
import {
  Consent,
  ContactPoint,
  EthnicityType,
  GenderType,
  IdpFlowType,
  Maybe,
  OccasionType,
  OrgId,
  UnixTimeMillis,
  User,
  UserIdpAccount,
  ZoomIdType,
} from '@csp/csp-common-model';
import { getMeta, isDefined } from '@csp/csp-common-util';
import { RoleType } from '@csp/dmdp-api-common-dto';
import { OrgType, OrgV1 } from '@csp/dmdp-api-org-dto';
import {
  AccessRefType,
  AccessRefV1,
  ConsentType,
  ContactPointSystemType,
  ContactPointV1,
  DmdpUserStatusType,
  DmdpUserStatusTypes,
  IDENTITY_PROVIDERS,
  IdpAccountV1,
  IdpType,
  PreviousStatus,
  RoleAccessRefsV1,
  UserMetaV1,
  UserV1,
} from '@csp/dmdp-api-user-dto';
import debug from 'debug';
import { filter, first, flatMap, flow, map } from 'lodash/fp';
import { ConsentMetaMapper } from '../mapper/ConsentMetaMapper';
import { UserPreferencesService } from '../service/UserPreferencesService';

const log = debug('Common:User:model');

const isFutureDate: (unixTimeMillis: UnixTimeMillis) => boolean = flow(toFormatIsoDate, isFutureDateTime);
const isPastDate: (unixTimeMillis: UnixTimeMillis) => boolean = flow(toFormatIsoDate, isPastDateTime);

const getOrgAccessRefs = flow(
  flatMap<RoleAccessRefsV1, AccessRefV1>(value => value.accessRefs ?? []),
  filter(({ type }) => type === AccessRefType.ORGANISATION),
  filter(({ fromUtc }) => (fromUtc ? isPastDate(fromUtc) : true)),
  filter(({ toUtc }) => (toUtc ? isFutureDate(toUtc) : true)),
);

const findByRefIn =
  (organisations: OrgV1[]) =>
  (ref: string): OrgV1 | undefined =>
    organisations.find(({ orgId }) => orgId === ref);

const isVerified = ({ status }: PreviousStatus): boolean => status === DmdpUserStatusType.VERIFIED;

const getVerifiedSortOrder = (verified1: boolean, verified2: boolean): number => {
  if (verified1 === verified2) {
    return 0;
  } else if (verified1) {
    return -1;
  } else {
    return 1;
  }
};

const sortOnVerifiedAndLastModifiedIdpAccount = (acc1: IdpAccountV1, acc2: IdpAccountV1): number => {
  // verified true first
  const verifiedSortValue = getVerifiedSortOrder(acc1.verified, acc2.verified);

  // secondary sort on lastModified
  const acc1LastModified = acc1.idpStatus?.lastModified ?? 0;
  const acc2LastModified = acc2.idpStatus?.lastModified ?? 0;
  return verifiedSortValue === 0 ? acc2LastModified - acc1LastModified : verifiedSortValue;
};

/**
 * IE11 bug!!! Work-around!
 * Calling sort() with a sort function on this particular "array" caused a crash.
 * Hence, we need to create a "real" array before colling sort()
 */
const sortedIdpAccounts = (idpAccountV1s?: IdpAccountV1[]): IdpAccountV1[] =>
  Array.from(idpAccountV1s ?? []).sort(sortOnVerifiedAndLastModifiedIdpAccount);

const getFirstIdpAccount = flow(sortedIdpAccounts, first);

const toFirstUserIdpAccount = ({ idpAccounts }: UserV1): Maybe<UserIdpAccount> => {
  const idpAccount = getFirstIdpAccount(idpAccounts);

  return idpAccount
    ? {
        username: idpAccount.identity ?? '',
        idpSubject: idpAccount.idpSubject ?? '',
        provider: idpAccount.provider as IdpType,
        isAnonymous: idpAccount.flowType === IdpFlowType.USER_REGISTRATION_ANONYMOUS,
        flowType: EnumUtil.fromStringOrDefault(idpAccount.flowType, IdpFlowType, IdpFlowType.UNKNOWN),
      }
    : undefined;
};

const getFirstVerifiedStatusIn = flow(
  filter(isVerified),
  map(prevStatus => prevStatus.time),
  first,
);

const mapIdpName = ({ provider }: IdpAccountV1): string => IDENTITY_PROVIDERS[provider as IdpType] || '';

const createFirstNameWithTitle = (title: Maybe<string>, firstName?: string): string =>
  `${title ?? ''} ${firstName ?? ''}`.trim();

const toOrganisationDisplayName = (orgIds: OrgId[], organisations: OrgV1[]): string =>
  orgIds
    .map(findByRefIn(organisations))
    .filter(isDefined)
    .map(({ name }) => name)
    .join(', ');

const from = (userV1: UserV1, orgsV1: OrgV1[] = []): User => {
  const evalDisplayName = (): string => {
    const given = userV1.humanName?.given;
    const family = userV1.humanName?.family;
    let name = '';
    if (given) {
      name += given;
    }
    if (given && family) {
      name += ' ';
    }
    if (family) {
      name += family;
    }
    return name;
  };

  const gender = EnumUtil.fromMaybeStringOrDefault(userV1.personal?.gender, GenderType, GenderType.UNKNOWN);
  const ethnicity = EnumUtil.fromMaybeStringOrDefault(userV1.personal?.ethnicity, EthnicityType, EthnicityType.UNKNOWN);

  const metrics = {
    heightMetres: userV1.metrics?.heightMetres,
    weightKg: userV1.metrics?.weightKg,
  };

  const orgAccessRefs = getOrgAccessRefs(userV1.roleAccessRefs);

  const getParentOrgIds = (): OrgId[] => Array.from(new Set<OrgId>(orgAccessRefs.flatMap(({ partOf }) => partOf)));

  const getDirectOrgIds = (): OrgId[] => Array.from(new Set<OrgId>(orgAccessRefs.map(({ ref }) => ref)));

  const getRolesForOrg = (orgId: OrgId): RoleType[] =>
    // Optional chaining needed as roleAccessRefs is potentially falsy
    userV1.roleAccessRefs
      ?.filter(({ accessRefs }) => !!accessRefs?.find(({ ref }) => ref === orgId))
      .map(({ role }) => role as RoleType) ?? [];

  const getFirstContactPointV1 = (system: ContactPointSystemType, primary?: boolean): ContactPointV1 | undefined => {
    if (primary != null) {
      return userV1.contactPoints?.find(
        contactPointV1 =>
          contactPointV1.system === system &&
          (contactPointV1.primary === primary || (contactPointV1.primary == null && !primary)),
      );
    } else {
      return userV1.contactPoints?.find(contactPointV1 => contactPointV1.system === system);
    }
  };

  const getContactPointsOfType = (type: ContactPointSystemType): ContactPoint[] =>
    userV1.contactPoints
      ?.filter(contactPointV1 => contactPointV1.system === type)
      .map(contactPoint => ContactPoint.from(contactPoint)) ?? [];

  // attributes
  const displayName = evalDisplayName();

  const title = userV1.humanName?.prefix?.trim();
  const firstName = userV1.humanName?.given;
  const lastName = userV1.humanName?.family;
  const firstNameWithTitle = createFirstNameWithTitle(title, firstName);
  const fullNameWithTitle = `${firstNameWithTitle} ${lastName || ''}`.trim();
  const orgIds = getDirectOrgIds();
  const firstOrgId = first(orgIds);
  const orgIdsIncludingParents = Array.from(new Set<OrgId>([...getParentOrgIds(), ...orgIds]));

  const email = getFirstContactPointV1(ContactPointSystemType.EMAIL, true)?.value;
  const phoneNumber = getFirstContactPointV1(ContactPointSystemType.PHONE)?.value;
  const displayIdpNames = userV1.idpAccounts?.map(mapIdpName).join(', ') ?? '';
  const displayUserNames = userV1.idpAccounts?.map(({ identity }) => identity).join(', ') ?? '';

  const firstAccount = toFirstUserIdpAccount(userV1);
  const firstUsername = firstAccount?.username;

  // Optional chaining needed to guard against potentially falsy roleAccessRefs
  const displayRoles = userV1.roleAccessRefs?.map(({ role }) => role).join(', ');
  const roles = userV1.roleAccessRefs?.map(({ role }) => role as RoleType);

  const zoomUserId = userV1.externalIds?.find(extId => extId.key === ZoomIdType.ZOOM_USER_ID)?.value;

  const displaySiteNames = toOrganisationDisplayName(
    orgIdsIncludingParents,
    orgsV1.filter(({ type }) => type === OrgType.PRACTICE),
  );

  const displayCountry = toOrganisationDisplayName(
    orgIdsIncludingParents,
    orgsV1.filter(({ type }) => type === OrgType.COUNTRY),
  );

  const hasRole = (...roleArgs: RoleType[]): boolean => roleArgs.some(role => roles.includes(role));

  const firstLoginAtUnixMillis = getFirstVerifiedStatusIn(userV1.previousStatuses);

  const firstLoginAt = toFormatDateTime(firstLoginAtUnixMillis);

  const localization = LocalizationMapper.from(userV1.localization);

  let metas = userV1.metas;
  if (!metas) {
    metas = [];
    userV1.metas = metas;
  }
  const getMetas = (): UserMetaV1[] => metas as UserMetaV1[];

  const setMeta = (userMetaV1: UserMetaV1): void => {
    const idx = getMetas().findIndex(meta => meta.type === userMetaV1.type);
    if (idx !== -1) {
      getMetas()[idx] = userMetaV1;
    } else {
      getMetas().push(userMetaV1);
    }
  };

  const userConsentMetaV1 = getMeta(getMetas(), 'CONSENTS_V1');

  const getUserConsent = (type: ConsentType): Maybe<Consent> => {
    const consents = userConsentMetaV1?.data.consents;
    const consentV1 = consents?.find(consentV1 => consentV1.type === type);
    return consentV1 ? ConsentMetaMapper.toConsent(consentV1) : undefined;
  };

  const hasUserConsent = (type: ConsentType): boolean => getUserConsent(type) != null;
  const hasOccasion = (type: OccasionType): boolean => userV1.occasions?.find(it => it.type === type) != null;
  const hasActiveStatus = (): boolean => userV1.status === DmdpUserStatusType.ACTIVE;
  const hasVisibleStatus = (): boolean =>
    !!userV1.status && DmdpUserStatusTypes.isUserStatusVisibleAndInactivatable(userV1.status);
  const isActive = hasActiveStatus();
  const isVisible = hasVisibleStatus();
  const customStatuses = CustomStatusMapper.fromCustomStatusesV1(userV1.customStatuses ?? []);
  const tenantPreferences = UserPreferencesService.getUserPreferences(userV1);

  const audit = userV1.audit ? AuditMapper.fromV1(userV1.audit) : undefined;
  const dmdpStatus = userV1.status as DmdpUserStatusType;

  const user: User = {
    audit,
    displayCountry,
    displayIdpNames,
    displayUserNames,
    displayName,
    displayRoles,
    displaySiteNames,
    roles,
    dmdpStatus,
    email,
    firstUsername,
    firstAccount,
    firstLoginAtUnixMillis,
    firstLoginAt,
    firstNameWithTitle,
    fullNameWithTitle,
    firstOrgId,
    localization,
    getFirstContactPointV1,
    getContactPointsOfType,
    getRolesForOrg,
    getUserConsent,
    hasUserConsent,
    hasOccasion,
    hasRole,
    isActive,
    isVisible,
    firstName,
    lastName,
    orgIds,
    orgIdsIncludingParents,
    phoneNumber,
    setMeta,
    title,
    userConsentMetaV1,
    gender,
    ethnicity,
    metrics,
    userId: userV1.userId,
    lastModified: userV1.audit?.lastModified,
    customStatuses,
    zoomUserId,
    userV1,
    orgsV1,
    tenantPreferences,
  };

  log('User.from output', user);

  return user;
};

export const UserFactory = { from };
