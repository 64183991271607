export type M2mConfig = {
  clientId: string;
  clientSecret: string;
};

const empty = (): M2mConfig => ({
  clientId: '',
  clientSecret: '',
});

export const M2mConfig = {
  empty,
};
