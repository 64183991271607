import { isDefined, isNotEmpty } from '@csp/csp-common-util';
import { RpmAlgorithmCode } from '@csp/csp-common-rpm-model';
import { EnumUtil } from '@csp/csp-common-enum-util';
import { StudyConfig } from '@csp/csp-common-config';

const isRpmConfigured = (studyConfig: StudyConfig): boolean => isNotEmpty(studyConfig.rpmConfig?.algorithms);

const getConfiguredAlgorithmCodes = ({ rpmConfig }: StudyConfig): RpmAlgorithmCode[] =>
  rpmConfig?.algorithms
    .map(({ algorithmCode }) => EnumUtil.fromString<RpmAlgorithmCode>(algorithmCode, RpmAlgorithmCode))
    .filter(isDefined);

export const RpmStudyConfigUtil = {
  getConfiguredAlgorithmCodes,
  isRpmConfigured,
};
