import { StudyConfigService } from '@csp/csp-common-config';
import { EnumUtil } from '@csp/csp-common-enum-util';
import { MetricPeriodUnitType, StudyPeriodConfig } from '@csp/csp-common-model';
import { QuestionnaireScheduleRequestInfo } from '@csp/csp-common-questionnaire';
import { ScheduleRequestMetricCriterion } from '../../model/ScheduleRequestMetricCriterion';

const toMetricCriteria = (
  questionnaireRequests: QuestionnaireScheduleRequestInfo[],
): ScheduleRequestMetricCriterion[] => {
  const daysPerMonth =
    StudyConfigService.getStudyConfig()?.periodConfig.getNumberOfDaysByUnit('MONTH') ??
    StudyPeriodConfig.DEFAULT_DAYS_IN_MONTH;

  return questionnaireRequests.map(({ ref, request }) =>
    ScheduleRequestMetricCriterion.from({
      recentPeriod: request.config.historicalMetricPeriod
        ? {
            unit: EnumUtil.fromStringOrError<MetricPeriodUnitType>(
              request.config.historicalMetricPeriod.unit,
              MetricPeriodUnitType,
            ),
            amount: request.config.historicalMetricPeriod.amount,
          }
        : undefined,
      daysPerMonth,
      scheduleRef: ref,
      minComplianceThreshold: request.config.minComplianceThreshold,
    }),
  );
};

export const QuestionnaireMetricCriteriaMapper = {
  toMetricCriteria,
};
