import { CustomStatusMapper } from '@csp/csp-common-custom-status';
import { DayOneDates, WhileCriteriaUserValues } from '@csp/csp-common-scheduling';
import { Maybe, Patient, PatientPauseStatusType, ZonedDateTime } from '@csp/csp-common-model';
import { format as formatDateFnsTz, utcToZonedTime } from 'date-fns-tz';
import { fromTimezoneStr } from '@csp/csp-common-date-util';

/**
 * replaceTimezoneNameInZonedDateOrUndefined
 * @param zonedDateTime {Maybe<ZonedDateTime>}
 * @param timeZoneName IANA name
 */
const replaceTimezoneNameInZonedDateOrUndefined = (
  zonedDateTime: Maybe<ZonedDateTime>,
  timeZoneName: string,
): Maybe<ZonedDateTime> => {
  if (!zonedDateTime) {
    return undefined;
  }

  return replaceTimezoneNameInZonedDate(zonedDateTime, timeZoneName);
};

/**
 * replaceTimezoneNameInZonedDate - only replace offset and timezone name - will not convert date and time to target timezone name
 * @param zonedDateTime {ZonedDateTime}
 * @param timeZoneName IANA name
 */
const replaceTimezoneNameInZonedDate = (zonedDateTime: ZonedDateTime, timeZoneName: string): ZonedDateTime => {
  const zonedDate = utcToZonedTime(zonedDateTime.unixTimeMillis, zonedDateTime.zone);
  const convertedDate = formatDateFnsTz(zonedDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", {
    timeZone: timeZoneName,
  });
  const convertedDateWithZoneName = `${convertedDate}[${timeZoneName}]`;
  return fromTimezoneStr(convertedDateWithZoneName);
};

/**
 * replaceDayOneOptionsToTimezoneName - replace all defined timestamps in dayOneDates with timezone name and offset of that timezone
 * @param dayOneDates {DayOneDates}
 * @param timeZoneName IANA name
 */
const replaceDayOneOptionsToTimezoneName = (dayOneDates: DayOneDates, timeZoneName: string): DayOneDates => ({
  screenedTimestamp: replaceTimezoneNameInZonedDateOrUndefined(dayOneDates.screenedTimestamp, timeZoneName),
  randomizedTimestamp: replaceTimezoneNameInZonedDateOrUndefined(dayOneDates.randomizedTimestamp, timeZoneName),
  activatedTimestamp: replaceTimezoneNameInZonedDateOrUndefined(dayOneDates.activatedTimestamp, timeZoneName),
});

const getCustomActionDates = (patient: Patient): DayOneDates => ({
  randomizedTimestamp: patient.randomizedTimestamp,
  screenedTimestamp: patient.screenedTimestamp,
  activatedTimestamp: patient.occasions.activationTime,
  userCustomStatuses: patient.customStatuses,
});

const getWhileCriteriaValues = (patient: Patient): WhileCriteriaUserValues => ({
  customStatuses: patient.customStatuses,
});

const getCustomActionDatesWithoutPauseStatus = (patient: Patient): DayOneDates => {
  const dayOneDates = getCustomActionDates(patient);
  return {
    ...dayOneDates,
    userCustomStatuses: dayOneDates.userCustomStatuses
      ? CustomStatusMapper.from(
          dayOneDates.userCustomStatuses.statuses
            // The reason for remove PAUSE_QUESTIONNAIRE is that pause status is handled separately (OFRAZU-23502)
            .filter(status => status.type !== PatientPauseStatusType.PAUSE_QUESTIONNAIRE),
        )
      : undefined,
  };
};

export const QuestionnaireDateUtil = {
  replaceTimezoneNameInZonedDateOrUndefined,
  replaceDayOneOptionsToTimezoneName,
  replaceTimezoneNameInZonedDate,
  getCustomActionDates,
  getWhileCriteriaValues,
  getCustomActionDatesWithoutPauseStatus,
};
