import { RestOptions, ObjectType } from '@csp/csp-common-model';
import { ContentPageV1, ContentParamsV1, ContentPropertyQueryV1, ContentResponseV1 } from '@csp/dmdp-api-content-dto';
import { cmspodUrl } from '../../dmdp-config/dmdpConfig';

const cmsContentUrl = (): string => `${cmspodUrl()}/content/v1`;

const getContent = async <T extends ObjectType>(
  { axios, signal }: RestOptions,
  query: ContentPropertyQueryV1,
  params: ContentParamsV1,
  page: ContentPageV1,
): Promise<ContentResponseV1<T>> => {
  const response = await axios.post<ContentResponseV1<T>>(`${cmsContentUrl()}/nodes`, query, {
    params: {
      ...params,
      ...page,
    },
    timeout: 30 * 1000,
    signal,
  });
  return response.data;
};

export const ContentRestServiceV1 = {
  getContent,
};
