/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AssessmentCriteriaFieldV1 {
    ASSESSMENT_ID = 'ASSESSMENT_ID',
    ASSESSMENT_CODE = 'ASSESSMENT_CODE',
    ASSESSMENT_TYPE = 'ASSESSMENT_TYPE',
    CUSTOM_STATUS_TYPE = 'CUSTOM_STATUS_TYPE',
    CUSTOM_STATUS_VALUE = 'CUSTOM_STATUS_VALUE',
    ALGORITHM_NAME = 'ALGORITHM_NAME',
    ALGORITHM_CODE = 'ALGORITHM_CODE',
    OUTCOME_KEY = 'OUTCOME_KEY',
    OUTCOME_VALUE = 'OUTCOME_VALUE',
    USER_ID = 'USER_ID',
    REFS_KEY = 'REFS_KEY',
    REFS_VALUE = 'REFS_VALUE',
    EVENT_ID = 'EVENT_ID',
    CREATED_BY = 'CREATED_BY',
    CREATED_AT = 'CREATED_AT',
}