import { parseJsonWithBigInt } from '@csp/csp-common-axios';
import { Maybe, RestOptions } from '@csp/csp-common-model';
import { Page } from '@csp/dmdp-api-common-dto';
import {
  DeviceSessionIdsOrErrorsV1,
  DeviceSessionInV1,
  DeviceSessionQueryV1,
  DeviceSessionsAndIdsInV1,
  DeviceSessionsV1,
  DeviceSessionV1,
  MetaInV1,
  RefV1,
} from '@csp/dmdp-api-device-dto';
import { AxiosRequestConfig } from 'axios';
import { devicePodUrl } from '../../dmdp-config/dmdpConfig';

const deviceSessionsUrl = (): string => `${devicePodUrl()}/device/v1/sessions`;

const createAxiosRequestConfig = (signal: Maybe<AbortSignal>, params?: Page): AxiosRequestConfig => ({
  params,
  signal,
  transformResponse: parseJsonWithBigInt,
});

const createDeviceSession = async (
  { axios, signal }: RestOptions,
  deviceSessionInV1: DeviceSessionInV1,
): Promise<DeviceSessionV1> => {
  const { data } = await axios.post<DeviceSessionV1>(
    deviceSessionsUrl(),
    deviceSessionInV1,
    createAxiosRequestConfig(signal),
  );
  return data;
};

const getDeviceSession = async (
  { axios, signal }: RestOptions,
  deviceSessionId: string,
): Promise<Maybe<DeviceSessionV1>> => {
  const { data } = await axios.get<DeviceSessionV1>(
    `${deviceSessionsUrl()}/${deviceSessionId}`,
    createAxiosRequestConfig(signal),
  );
  return data;
};

const queryDeviceSessions = async (
  { axios, signal }: RestOptions,
  query: DeviceSessionQueryV1,
  page: Page,
): Promise<DeviceSessionsV1> => {
  const { data } = await axios.post<DeviceSessionsV1>(
    `${deviceSessionsUrl()}:query`,
    query,
    createAxiosRequestConfig(signal, page),
  );
  return data;
};

const batchDeviceSessions = async (
  { axios, signal }: RestOptions,
  sessions: DeviceSessionsAndIdsInV1,
  page: Page,
): Promise<DeviceSessionIdsOrErrorsV1> => {
  const { data } = await axios.post<DeviceSessionIdsOrErrorsV1>(
    `${deviceSessionsUrl()}:batch`,
    sessions,
    createAxiosRequestConfig(signal, page),
  );
  return data;
};

const updateDeviceSessionMeta = async (
  { axios, signal }: RestOptions,
  deviceSessionId: string,
  meta: MetaInV1,
): Promise<DeviceSessionV1> => {
  const { data } = await axios.put<DeviceSessionV1>(
    `${deviceSessionsUrl()}/${deviceSessionId}/meta`,
    meta,
    createAxiosRequestConfig(signal),
  );
  return data;
};

const updateDeviceSessionRefs = async (
  { axios, signal }: RestOptions,
  deviceSessionId: string,
  { key, value }: RefV1,
): Promise<void> => {
  await axios.put<void>(
    `${deviceSessionsUrl()}/${deviceSessionId}/refs/${key}`,
    { value },
    createAxiosRequestConfig(signal),
  );
};

export const DeviceSessionsRestServiceV1 = {
  batchDeviceSessions,
  createDeviceSession,
  getDeviceSession,
  queryDeviceSessions,
  updateDeviceSessionMeta,
  updateDeviceSessionRefs,
};
