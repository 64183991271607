import { CspConfig } from '../../system/model/CspConfig';

const BASE_SCRIPT_URL = '//tags.tiqcdn.com/utag/astrazeneca/global-unifytrialspro';

const appendTealiumBodyScript = (envVar: string): void => {
  const url = `${BASE_SCRIPT_URL}/${envVar}/utag.js`;
  const selector = document.querySelector(`script[src="${url}"]`);
  if (selector === null) {
    const script = document.createElement('script');
    script.src = url;
    script.type = `text/javascript`;
    script.async = true;
    const element = document.getElementsByTagName('script')[0];
    element?.parentNode?.insertBefore(script, element);
  }
};

const appendTealiumHeadScript = (envVar: string): void => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = `${BASE_SCRIPT_URL}/${envVar}/utag.sync.js`;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const writer: any = document.write;
  // eslint-disable-next-line
  eval(writer.START);
  document.head.appendChild(script);
  // eslint-disable-next-line
  setTimeout((): void => eval(writer.END), 5000);
};

const init = (tealiumEnv: CspConfig['tealiumEnv']): void => {
  appendTealiumHeadScript(tealiumEnv);
  appendTealiumBodyScript(tealiumEnv);
};

export const TealiumService = { init };
