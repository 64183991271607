import { notIncludes } from '@csp/csp-common-util';
import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { Typography as MuiTypography } from '@mui/material';
import { compose, sizing, spacing } from '@mui/system';
import { FC } from 'react';
import { TextProps } from './model/TextProps';

const StyledTypography = styled(MuiTypography, {
  shouldForwardProp: notIncludes(['maxWidth', 'minWidth', 'maxHeight', 'lineBreak', 'wordBreak']),
})<TextProps>`
  ${compose(spacing, sizing)}
  ${({ lineBreak }): SerializedStyles => css`
    ${lineBreak &&
    `overflow: hidden;
       text-overflow: ellipsis;
       display: -webkit-box;
       -webkit-line-clamp: ${lineBreak};
       -webkit-box-orient: vertical;`}
  `}
`;

export const H1: FC<TextProps> = ({ children, ...props }) => (
  <StyledTypography variant="h1" {...props}>
    {children}
  </StyledTypography>
);

export const H2: FC<TextProps> = ({ children, ...props }) => (
  <StyledTypography variant="h2" {...props}>
    {children}
  </StyledTypography>
);

export const H3: FC<TextProps> = ({ children, ...props }) => (
  <StyledTypography variant="h3" {...props}>
    {children}
  </StyledTypography>
);

export const H4: FC<TextProps> = ({ children, ...props }) => (
  <StyledTypography variant="h4" {...props}>
    {children}
  </StyledTypography>
);

export const H5: FC<TextProps> = ({ children, ...props }) => (
  <StyledTypography variant="h5" {...props}>
    {children}
  </StyledTypography>
);

export const H6: FC<TextProps> = ({ children, ...props }) => (
  <StyledTypography variant="h6" {...props}>
    {children}
  </StyledTypography>
);

export const Subtitle1: FC<TextProps> = ({ children, ...props }) => (
  <StyledTypography variant="subtitle1" {...props}>
    {children}
  </StyledTypography>
);

export const Subtitle2: FC<TextProps> = ({ children, fontWeight, ...props }) => (
  <StyledTypography style={{ fontWeight }} variant="subtitle2" {...props}>
    {children}
  </StyledTypography>
);

export const Subtitle3: FC<TextProps> = ({ children, fontWeight, ...props }) => (
  <StyledTypography style={{ fontWeight, fontSize: '1rem', lineHeight: '1.375' }} variant="subtitle2" {...props}>
    {children}
  </StyledTypography>
);

export const Body1: FC<TextProps> = ({ children, direction, fontWeight, wordWrap, overflowWrap, ...props }) => (
  <StyledTypography sx={{ direction, fontWeight, wordWrap, overflowWrap }} variant="body1" {...props}>
    {children}
  </StyledTypography>
);

/** normal text size */
export const Body2: FC<TextProps> = ({
  children,
  fontWeight,
  direction,
  wordWrap,
  overflowWrap,
  wordBreak,
  ...props
}) => (
  <StyledTypography sx={{ fontWeight, direction, wordWrap, overflowWrap, wordBreak }} variant="body2" {...props}>
    {children}
  </StyledTypography>
);

export const Body3: FC<TextProps> = ({ children, direction, ...props }) => (
  <StyledTypography variant="body2" style={{ direction, fontSize: '0.75rem' }} {...props}>
    {children}
  </StyledTypography>
);

export const Caption1: FC<TextProps> = ({ children, fontWeight, ...props }) => (
  <StyledTypography style={{ fontWeight, fontSize: '1rem' }} variant="caption" {...props}>
    {children}
  </StyledTypography>
);

export const Caption2: FC<TextProps> = ({ children, fontWeight, overflowWrap, ...props }) => (
  <StyledTypography style={{ fontWeight, overflowWrap }} variant="caption" {...props}>
    {children}
  </StyledTypography>
);

export const Label1: FC<TextProps> = ({ children, component = 'label', ...props }) => (
  <StyledTypography
    sx={{
      fontSize: '0.75rem',
      fontWeight: 500,
      letterSpacing: '0.063em',
      textTransform: 'uppercase',
    }}
    component={component}
    {...props}
  >
    {children}
  </StyledTypography>
);

export const Label2: FC<TextProps> = ({ children, fontWeight = 500, component = 'label', ...props }) => (
  <StyledTypography
    sx={{ fontSize: '0.625rem', letterSpacing: '0.1em', textTransform: 'uppercase', lineHeight: 1.5, fontWeight }}
    component={component}
    {...props}
  >
    {children}
  </StyledTypography>
);

export const InputLabel1: FC<TextProps> = ({ children, component = 'label', ...props }) => (
  <StyledTypography
    sx={{ fontSize: '0.75rem', fontWeight: 400, letterSpacing: '0.063em', lineHeight: 1.5 }}
    component={component}
    {...props}
  >
    {children}
  </StyledTypography>
);

export const ButtonText: FC<TextProps> = ({ children, component = 'span', ...props }) => (
  <StyledTypography sx={{ textTransform: 'capitalize' }} variant="button" component={component} {...props}>
    {children}
  </StyledTypography>
);

export const LinkText: FC<TextProps> = ({ children, component = 'span', ...props }) => (
  <StyledTypography
    sx={{ fontSize: '1rem', fontWeight: 400, letterSpacing: '0.0315em' }}
    component={component}
    {...props}
  >
    {children}
  </StyledTypography>
);
