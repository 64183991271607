import { getLocale, InitializationService } from '@csp/common';
import { TelemetryService } from '@csp/csp-web-telemetry';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getStudySelector, getUserSelector } from '../../feature/security/ducks/principalContextSelectors';
import { CspTelemetryTags } from '../model/CspTelemetryTags';

export const useUpdateTelemetryScope = (): void => {
  const user = useSelector(getUserSelector);
  const study = useSelector(getStudySelector);
  const locale = useSelector(state => getLocale(InitializationService.commonSelector(state)));

  useEffect(() => {
    if (user || study || locale) {
      TelemetryService.configureScope(user, CspTelemetryTags.from(user, study, locale));
    }
  }, [user, study, locale]);
};
