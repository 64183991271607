import { Maybe } from '@csp/csp-common-model';
import { RpmEventStateType } from '@csp/dmdp-api-user-dto';
import { isEmpty } from 'lodash';
import { RpmAssessment } from './RpmAssessment';

const fromAssessments = (rpmAssessments: Maybe<RpmAssessment[]> = []): RpmEventStateType => {
  const isAllViewed = rpmAssessments.every(assessment => assessment.isViewed);
  if (isEmpty(rpmAssessments)) {
    return RpmEventStateType.NO_DATA;
  } else if (isAllViewed) {
    return RpmEventStateType.ALL_VIEWED;
  } else {
    return RpmEventStateType.SOME_NOT_VIEWED;
  }
};

export const RpmEventState = {
  fromAssessments,
};
