export interface Page {
  prev?: string;
  next?: string;
  limit: number;
  count: boolean;
}

export const MAX_LIMIT = 5000;

export const firstPage = (): Page => ({
  limit: 50,
  count: false,
});

export const firstPageWithCount = (): Page => ({
  ...firstPage(),
  count: true,
});

export const largePage = (): Page => ({
  limit: MAX_LIMIT,
  count: false,
});

export const pageWithOneItem = (): Page => ({
  limit: 1,
  count: false,
});

export const pageWithTenItemsAndCount = (): Page => ({
  limit: 10,
  count: true,
});

export const Page = {
  maxLimit: MAX_LIMIT,
};
