import { FC } from 'react';
import { useAutomaticLogout } from '../hooks/useAutomaticLogout';

const AutomaticLogoutWrapper: FC = () => {
  useAutomaticLogout();
  return null;
};

export const AutomaticLogout: FC = () => {
  const enableAutomaticLogout =
    process.env.NX_ENABLE_AUTOMATIC_LOGOUT === undefined || process.env.NX_ENABLE_AUTOMATIC_LOGOUT === 'true';
  return enableAutomaticLogout ? <AutomaticLogoutWrapper /> : null;
};
