// Duplicates exists due to that the exposed status from DMDP is the "status type name" and not the VCV Picklist value
// It has been requested to use the VCV Picklist value.
// This redundacy helps in case that happens.

export const VcvStatusCodeEditable = ['ACTNOREC', 'ACTREC', 'CLOSING', 'CLOSINGTE', 'INIT', 'OPENREC'] as const;

const VcvStatusCodeReadOnly = [
  'QUAL',
  'QUALHOLD',
  'INITHOLD',
  'CLOSED',
  'CANDIDATE',
  'ARCHIVED',
  'REEVAL',
  'ONHOLD',
  'INACTIVE',
  'CANCELLED',
  'TERMINATED',
  'INMIGR',
] as const;

export const StatusTypeNameEditable = [
  'Active (Not recruiting)',
  'Active (Recruiting)',
  'Closing',
  'Closing (Treatment Extension)',
  'Initiating',
  'Open for Recruitment',
] as const;

const StatusTypeNameReadOnly = [
  'Archived',
  'Cancelled',
  'Candidate',
  'Closed',
  'Inactive',
  'In Migration',
  'Initiating Hold',
  'On Hold',
  'Qualifying',
  'Qualifying Hold',
  'Re-evaluate',
  'Terminated',
] as const;

type SiteLifecycleStatusVcvStatusCodeEditable = typeof VcvStatusCodeEditable[number];
type SiteLifecycleStatusVcvStatusCodeReadOnly = typeof VcvStatusCodeReadOnly[number];
type SiteLifecycleStatusTypeNameEditable = typeof StatusTypeNameEditable[number];
type SiteLifecycleStatusTypeNameReadOnly = typeof StatusTypeNameReadOnly[number];

export type CustomSiteStatusResponseType =
  | SiteLifecycleStatusVcvStatusCodeEditable
  | SiteLifecycleStatusVcvStatusCodeReadOnly
  | SiteLifecycleStatusTypeNameEditable
  | SiteLifecycleStatusTypeNameReadOnly;

export const SITE_STATUS = {
  ACTIVE_NOT_RECRUITING: 'Active (Not recruiting)',
  ACTIVE_RECRUITING: 'Active (Recruiting)',
  CLOSING: 'Closing',
  CLOSING_TREATMENT_EXTENSION: 'Closing (Treatment Extension)',
  INITIATING: 'Initiating',
  OPEN_FOR_RECRUITMENT: 'Open for Recruitment',
  ARCHIVED: 'Archived',
  CANCELLED: 'Cancelled',
  CANDIDATE: 'Candidate',
  CLOSED: 'Closed',
  INACTIVE: 'Inactive',
  INITIATING_HOLD: 'Initiating Hold',
  IN_MIGRATION: 'In Migration',
  ON_HOLD: 'On Hold',
  QUALIFYING: 'Qualifying', // Not visible in Unify
  QUALIFYING_HOLD: 'Qualifying Hold', // Not visible in Unify
  RE_EVALUATE: 'Re-evaluate',
  TERMINATED: 'Terminated',
  UNKNOWN: 'UNKNOWN',
} as const;

export type SiteStatus = typeof SITE_STATUS[keyof typeof SITE_STATUS];

export const RESPONSE_STATUS_TO_SITE_STATUS_MAP: { [key in CustomSiteStatusResponseType]: SiteStatus } = {
  'Active (Recruiting)': SITE_STATUS.ACTIVE_RECRUITING,
  'Active (Not recruiting)': SITE_STATUS.ACTIVE_NOT_RECRUITING,
  'Closing (Treatment Extension)': SITE_STATUS.CLOSING_TREATMENT_EXTENSION,
  'In Migration': SITE_STATUS.IN_MIGRATION,
  'Initiating Hold': SITE_STATUS.INITIATING_HOLD,
  'On Hold': SITE_STATUS.ON_HOLD,
  'Open for Recruitment': SITE_STATUS.OPEN_FOR_RECRUITMENT,
  'Qualifying Hold': SITE_STATUS.QUALIFYING_HOLD,
  'Re-evaluate': SITE_STATUS.RE_EVALUATE,
  Archived: SITE_STATUS.ARCHIVED,
  Cancelled: SITE_STATUS.CANCELLED,
  Candidate: SITE_STATUS.CANDIDATE,
  Closed: SITE_STATUS.CLOSED,
  Closing: SITE_STATUS.CLOSING,
  Inactive: SITE_STATUS.INACTIVE,
  Initiating: SITE_STATUS.INITIATING,
  Qualifying: SITE_STATUS.QUALIFYING,
  Terminated: SITE_STATUS.TERMINATED,
  ACTNOREC: SITE_STATUS.ACTIVE_NOT_RECRUITING,
  ACTREC: SITE_STATUS.ACTIVE_RECRUITING,
  ARCHIVED: SITE_STATUS.ARCHIVED,
  REEVAL: SITE_STATUS.RE_EVALUATE,
  CANDIDATE: SITE_STATUS.CANDIDATE,
  CLOSED: SITE_STATUS.CLOSED,
  CLOSING: SITE_STATUS.CLOSING,
  CLOSINGTE: SITE_STATUS.CLOSING_TREATMENT_EXTENSION,
  INMIGR: SITE_STATUS.IN_MIGRATION,
  INACTIVE: SITE_STATUS.INACTIVE,
  INITHOLD: SITE_STATUS.INITIATING_HOLD,
  INIT: SITE_STATUS.INITIATING,
  CANCELLED: SITE_STATUS.CANCELLED,
  ONHOLD: SITE_STATUS.ON_HOLD,
  OPENREC: SITE_STATUS.OPEN_FOR_RECRUITMENT,
  QUALHOLD: SITE_STATUS.QUALIFYING_HOLD,
  QUAL: SITE_STATUS.QUALIFYING,
  TERMINATED: SITE_STATUS.TERMINATED,
};

export const OPEN_SITE_STATUSES: SiteStatus[] = [SITE_STATUS.OPEN_FOR_RECRUITMENT, SITE_STATUS.ACTIVE_RECRUITING];
export const EDITABLE_SITE_STATUSES: SiteStatus[] = [
  SITE_STATUS.INITIATING,
  SITE_STATUS.OPEN_FOR_RECRUITMENT,
  SITE_STATUS.ACTIVE_RECRUITING,
  SITE_STATUS.ACTIVE_NOT_RECRUITING,
  SITE_STATUS.CLOSING,
  SITE_STATUS.CLOSING_TREATMENT_EXTENSION,
];
export const SITE_STATUSES_TO_SHOW: SiteStatus[] = [
  SITE_STATUS.ACTIVE_NOT_RECRUITING,
  SITE_STATUS.ACTIVE_RECRUITING,
  SITE_STATUS.ARCHIVED,
  SITE_STATUS.CANCELLED,
  SITE_STATUS.CLOSED,
  SITE_STATUS.CLOSING,
  SITE_STATUS.CLOSING_TREATMENT_EXTENSION,
  SITE_STATUS.INACTIVE,
  SITE_STATUS.INITIATING,
  SITE_STATUS.INITIATING_HOLD,
  SITE_STATUS.IN_MIGRATION,
  SITE_STATUS.ON_HOLD,
  SITE_STATUS.OPEN_FOR_RECRUITMENT,
  SITE_STATUS.RE_EVALUATE,
  SITE_STATUS.TERMINATED,
];

export const SITE_STATUSES_CAN_ADD_PATIENTS: SiteStatus[] = [
  SITE_STATUS.OPEN_FOR_RECRUITMENT,
  SITE_STATUS.ACTIVE_RECRUITING,
  SITE_STATUS.ACTIVE_NOT_RECRUITING,
];
export const SITE_STATUSES_CAN_EDIT_PATIENTS: SiteStatus[] = [
  SITE_STATUS.OPEN_FOR_RECRUITMENT,
  SITE_STATUS.ACTIVE_RECRUITING,
  SITE_STATUS.ACTIVE_NOT_RECRUITING,
  SITE_STATUS.CLOSED,
  SITE_STATUS.CLOSING,
  SITE_STATUS.CLOSING_TREATMENT_EXTENSION,
  SITE_STATUS.ARCHIVED,
  SITE_STATUS.RE_EVALUATE,
  SITE_STATUS.ON_HOLD,
  SITE_STATUS.INACTIVE,
  SITE_STATUS.CANCELLED,
  SITE_STATUS.TERMINATED,
  SITE_STATUS.IN_MIGRATION,
];
export const SITE_STATUSES_CAN_BOOK_VISITS: SiteStatus[] = [
  SITE_STATUS.OPEN_FOR_RECRUITMENT,
  SITE_STATUS.ACTIVE_RECRUITING,
  SITE_STATUS.ACTIVE_NOT_RECRUITING,
  SITE_STATUS.CLOSING,
  SITE_STATUS.CLOSING_TREATMENT_EXTENSION,
];
export const SITE_STATUSES_CAN_ADD_TEAM_MEMBERS: SiteStatus[] = [
  SITE_STATUS.INITIATING,
  SITE_STATUS.OPEN_FOR_RECRUITMENT,
  SITE_STATUS.ACTIVE_RECRUITING,
  SITE_STATUS.ACTIVE_NOT_RECRUITING,
  SITE_STATUS.CLOSING,
  SITE_STATUS.CLOSING_TREATMENT_EXTENSION,
];
export const SITE_STATUSES_CAN_EDIT_TEAM_MEMBERS: SiteStatus[] = [
  SITE_STATUS.INITIATING,
  SITE_STATUS.OPEN_FOR_RECRUITMENT,
  SITE_STATUS.ACTIVE_RECRUITING,
  SITE_STATUS.ACTIVE_NOT_RECRUITING,
  SITE_STATUS.CLOSING,
  SITE_STATUS.CLOSING_TREATMENT_EXTENSION,
];

export const SITE_STATUSES_IS_READ_ONLY: SiteStatus[] = [
  SITE_STATUS.INITIATING_HOLD,
  SITE_STATUS.CLOSED,
  SITE_STATUS.ARCHIVED,
  SITE_STATUS.RE_EVALUATE,
  SITE_STATUS.ON_HOLD,
  SITE_STATUS.INACTIVE,
  SITE_STATUS.CANCELLED,
  SITE_STATUS.TERMINATED,
  SITE_STATUS.IN_MIGRATION,
];
