import { css, SerializedStyles } from '@emotion/react';
import styled, { StyledComponent } from '@emotion/styled';
import MuiLink from '@mui/material/Link';
import { FC } from 'react';
import { LinkProps } from './model/LinkProps';

const toTextDecoration = (disabled?: boolean, hasUnderLine?: boolean): string =>
  hasUnderLine && !disabled ? 'underline' : 'none';

export const LinkStyled: StyledComponent<LinkProps> = styled(MuiLink, {
  shouldForwardProp: prop => prop !== 'fontWeight' && prop !== 'hasUnderLine',
})<LinkProps>`
  ${({ theme, fontWeight, disabled, hasUnderLine }): SerializedStyles => css`
    color: ${disabled ? theme.palette.grey[600] : undefined};
    cursor: ${disabled && 'default'};

    &:hover, &:focus, &:active {
      color: ${disabled ? theme.palette.grey[600] : undefined};
    }

    &:hover {
      text-decoration: ${toTextDecoration(disabled, hasUnderLine)};
    }
    font-weight: ${fontWeight ? fontWeight : theme.typography.fontWeightRegular}};
  `}
`;

export const Link: FC<LinkProps> = ({ hasUnderLine = true, ...props }) => {
  const component = props.component || props.href ? 'a' : 'button';
  return <LinkStyled component={component} hasUnderLine={hasUnderLine} {...props} />;
};
