/* eslint-disable */
export const UnifyWebVideoTrainingConsentDocumentMock = {
  title: 'title',
  consentType: 'consentType',
  version: '[M]1.0',
  versionPublishedDate: '[M]2020-03-20 12:00:00',
  context: [],
  countryCodes: [],
  header: '[M]Training title',
  subHeader: '[M]Training sub header',
  webVideo: {
    video: {
      type: 'object',
      title: 'Test Video',
      helper: 'Test video to be played',
      _attachment: { id: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4' },
    },
    coverImage: {
      type: 'object',
      title: 'Test cover image',
      helper: 'Test image that will be shown while video is not playing',
      _attachment: { id: 'videoCover' },
    },
  },
};
