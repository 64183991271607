import { DmdpToken, JwtBodyV1c, JwtCriteriaV1c } from '@csp/dmdp-api-user-dto';
import { RestOptions } from '@csp/csp-common-model';
import jstz from 'jstimezonedetect';
import JwtDecode from 'jwt-decode';
import { userpodUrl } from '../../dmdp-config/dmdpConfig';
import { HmacUtil } from '../../util/HmacUtil';

const jwtUrl = (): string => `${userpodUrl()}/jwt/v1`;

const toDmdpToken = (jwtBase64: string): DmdpToken => {
  const jwtBodyV1c = JwtDecode<JwtBodyV1c>(jwtBase64);
  return new DmdpToken(jwtBase64, jwtBodyV1c);
};

const issueDmdpTokenByHmac = async (
  { axios, signal }: RestOptions,
  clientId: string,
  clientSecret: string,
  jwtCriteria: JwtCriteriaV1c,
): Promise<DmdpToken> => {
  const httpHeaders = HmacUtil.generateHeaders(clientId, clientSecret, 'POST');

  const { data } = await axios.post(jwtUrl(), jwtCriteria, {
    headers: {
      ...httpHeaders,
    },
    signal,
  });
  return toDmdpToken(data);
};

const issueDmdpTokenByIdpTokenAndCriteria = async (
  { axios, signal }: RestOptions,
  jwtCriteria: JwtCriteriaV1c,
): Promise<DmdpToken> => {
  const { data } = await axios.post(jwtUrl(), jwtCriteria, {
    signal,
  });
  return toDmdpToken(data);
};

const issueDmdpTokenByIdpToken = async (
  apiOptions: RestOptions,
  tenantId: string,
  ttlSecs?: number,
): Promise<DmdpToken> => {
  const jwtCriteria: JwtCriteriaV1c = {
    zoneId: jstz.determine().name(),
    tenantId,
    expireTimeSecs: ttlSecs,
    version: 'v1c',
  };
  return issueDmdpTokenByIdpTokenAndCriteria(apiOptions, jwtCriteria);
};

const revokeUserTokens = async ({ axios, signal }: RestOptions, userId: string): Promise<void> => {
  await axios.delete(`${jwtUrl()}/user/${userId}`, {
    signal,
  });
};

const revokeMyTokens = async ({ axios, signal }: RestOptions): Promise<void> => {
  await axios.delete(`${jwtUrl()}/user/mine/all`, {
    signal,
  });
};

export const JwtRestServiceV1 = {
  issueDmdpTokenByHmac,
  issueDmdpTokenByIdpToken,
  issueDmdpTokenByIdpTokenAndCriteria,
  revokeUserTokens,
  revokeMyTokens,
};
