/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Please check documentation for full supported list. Some supported
 * values: EQ, NOT_EQ, LESS_THAN, LESS_THAN_EQ, GREATER_THAN, GREATER_THAN_EQ,
 * IN. Note that ''IN'' is currently the only type that support more than one value'
 *
 */
export enum AssessmentQueryTypeV1 {
    EQ = 'EQ',
    NOT_EQ = 'NOT_EQ',
    LIKE = 'LIKE',
    LESS_THAN = 'LESS_THAN',
    LESS_THAN_EQ = 'LESS_THAN_EQ',
    GREATER_THAN = 'GREATER_THAN',
    GREATER_THAN_EQ = 'GREATER_THAN_EQ',
    IN = 'IN',
    NOT_IN = 'NOT_IN',
    ALL = 'ALL',
    EXISTS = 'EXISTS',
}