export enum CspPath {
  LANDING = '/',
  LOGIN = '/login',
  UNIFY_LOGIN = '/login/unify',
  COGNITO_CALLBACK = '/idp/callback',
  COGNITO_INVITE = '/confirm-invite',
  CONSENT = '/consent',
  VIEW_CONSENT = '/consent/:consentType',
  VIDEO_TRAINING = '/video-training',
  VIDEO_PLAYER = '/video-player',
  CONFIRM_ACCOUNT = '/account/confirm',
  CLOSEOUT_TRACKER_NOTIFICATION = '/closeout-tracker',
  ERROR = '/error/:type',
  SITES = '/sites',
  STUDIES = '/studies',
  STUDY_OVERVIEW = '/study-overview',
  STUDY_DOCUMENTS = '/study-overview/documents',
  PATIENT_LIST = '/patient/list',
  PATIENT_LIST_CLOSEOUT = '/patient/list/closeout',
  PATIENT_LIST_STANDARD = '/patient/list/standard',
  PATIENT_LIST_VISIT = '/patient/list/visit',
  PATIENT_LIST_HBS = '/patient/list/hbs',
  PATIENT_LIST_HBS_DETAILS = '/patient/list/hbs/:sampleId',
  PATIENT_LIST_RPM_EVENTS = '/patient/list/rpm-events',
  PATIENT_NAV = '/patient/:userId',
  PATIENT_SUBJECT_LIST = '/patient/subject/list',
  HBS = '/hbs',
  HBS_COLLECT_SAMPLES = '/hbs/collect',
  HBS_ALIQUOT_SAMPLES = '/hbs/aliquot',
  HBS_PROCESS_SAMPLES = '/hbs/process',
  HBS_SHIP_SAMPLES = '/hbs/ship',
  PATIENT_MEDICATION = '/patient/:userId/medication',
  PATIENT_OVERVIEW = '/patient/:userId/overview',
  PATIENT_VISITS = '/patient/:userId/visits',
  PATIENT_VISIT_DETAILS = '/patient/:userId/visits/:visitId',
  PATIENT_VISIT_DETAILS_SAMPLE_DETAILS = '/patient/:userId/visits/:visitId/hbs/:sampleId',
  PATIENT_VISIT_DETAILS_UNBOOKED_SCHEDULED = '/patient/:userId/visits/scheduled/unbooked/:visitUniqueId',
  PATIENT_COMPLIANCE = '/patient/:userId/compliance',
  PATIENT_COMPLIANCE_DETAILS = '/patient/:userId/compliance/detailed',
  PATIENT_DEVICES = '/patient/:userId/devices',
  PATIENT_DEVICE_REQUEST_DETAILS = '/patient/:userId/device/:scheduleCode/:versionCode/:requestCode',
  PATIENT_DEVICE_OBSERVATION_REDIRECT = '/patient/:userId/device/observation/redirect/:observationId',
  PATIENT_DEVICE_OBSERVATION_DETAILS_PAGE = '/patient/:userId/device/observation/:scheduleCode/:versionCode/:requestCode/:eventId/:view',
  PATIENT_DEVICE_SESSION_DETAILS = '/patient/:userId/device/session/:scheduleCode/:versionCode/:requestCode/:sessionId',
  PATIENT_DEVICE_LIVE_SESSION = '/patient/:userId/device/live-session/:scheduleCode/:versionCode/:requestCode/:assessmentCode',
  PATIENT_QUESTIONNAIRE_LIST = '/patient/:userId/questionnaires',
  PATIENT_QUESTIONNAIRE_LIST_COMPILE_QUESTIONNAIRE_RESPONSE = '/patient/:userId/questionnaires/:questionnaireId/:scheduleCode/:requestCode/compile',
  PATIENT_QUESTIONNAIRE_RESPONSE_LIST = '/patient/:userId/questionnaires/:questionnaireId/:scheduleCode/:requestCode/responses',
  PATIENT_QUESTIONNAIRE_RESPONSE_LIST_SELECTED_RESPONSE = '/patient/:userId/questionnaires/:questionnaireId/:scheduleCode/:requestCode/responses/:questionnaireResponseId',
  PATIENT_QUESTIONNAIRE_RESPONSE_LIST_EDIT_RESPONSE = '/patient/:userId/questionnaires/:questionnaireId/:scheduleCode/:requestCode/responses/:questionnaireResponseId/edit',
  PATIENT_QUESTIONNAIRE_RESPONSE_LIST_COMPILE_QUESTIONNAIRE_RESPONSE = '/patient/:userId/questionnaires/:questionnaireId/:scheduleCode/:requestCode/responses/compile',
  PATIENT_RPM = '/patient/:userId/remote-patient-monitoring/',
  PATIENT_RPM_ALGORITHM_PAGE = '/patient/:userId/remote-patient-monitoring/:algorithmCode',
  PATIENT_RPM_EVENT_DETAILS = '/patient/:userId/remote-patient-monitoring/:algorithmCode/event/:symptomEventId',
  PATIENT_HEALTH_EVENT_VIEW = '/patient/:userId/healthEvent/:healthEventId/view',
  TEAM_MEMBER_LIST = '/team-members',
  TEAM_MEMBER_NAV = '/team-member/:userId',
  TEAM_MEMBER_OVERVIEW = '/team-member/:userId/overview',
  PAGE_NOT_FOUND = '/404',
  FALLBACK = '/*',
  RELATIVE_PATIENT_VISITS = '/visits/*',
  RELATIVE_PATIENT_VISIT_DETAILS = ':visitId',
  RELATIVE_PATIENT_VISIT_DETAILS_SAMPLE_DETAILS = ':visitId/hbs/:sampleId',
  RELATIVE_PATIENT_VISIT_DETAILS_UNBOOKED_SCHEDULED = 'scheduled/unbooked/:visitUniqueId',
  RELATIVE_PATIENT_QUESTIONNAIRE_LIST = '/patient/:userId/questionnaires/*',
  RELATIVE_PATIENT_QUESTIONNAIRE_LIST_COMPILE_QUESTIONNAIRE_RESPONSE = ':questionnaireId/:scheduleCode/:requestCode/compile',
  RELATIVE_PATIENT_QUESTIONNAIRE_RESPONSE_LIST = ':questionnaireId/:scheduleCode/:requestCode/responses/*',
  RELATIVE_PATIENT_QUESTIONNAIRE_RESPONSE_LIST_SELECTED_RESPONSE = ':questionnaireResponseId',
  RELATIVE_PATIENT_QUESTIONNAIRE_RESPONSE_LIST_EDIT_RESPONSE = ':questionnaireResponseId/edit',
  RELATIVE_PATIENT_QUESTIONNAIRE_RESPONSE_LIST_COMPILE_QUESTIONNAIRE_RESPONSE = 'compile',
  DEPRECATED_PATIENT_QUESTIONNAIRE_LIST = '/ecoa/*',
  REPORTS = '/reports',
  REPORTS_DATA_CHANGES = '/reports/data-changes',
  REPORTS_DATA_CHANGE_DETAILS = '/reports/data-changes/:dataChangeId',
  REPORTS_STUDY_DATA = '/reports/study-data',
}

export const DEFAULT_AUTHENTICATED_PATH = CspPath.STUDY_OVERVIEW;
