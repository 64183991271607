/* eslint-disable */
export const UnifyWebStudyDocumentTypeSchema = {
  title: 'UnifyWebStudyDocumentType',
  type: 'object',
  required: ['documentType'],
  properties: {
    title: { type: 'string', title: 'Title of document type', mock: 'Study protocol' },
    documentType: {
      label: 'Value of document type',
      type: 'string',
      mock: 'protocol',
      enum: [
        'patient_study_journey',
        'protocol',
        'newsletter',
        'investigator_brochure',
        'informed_consent_form',
        'safety_letter',
        'case_report_form',
        'manual',
        'training_material',
        'meeting_material',
        'other',
      ],
    },
    sortOrder: {
      type: 'string',
      title: 'Sort order',
      helper: 'Number for sorting the document type. 1 is first, 2 is second etc.',
    },
  },
  description: 'unify:webstudydocumenttype',
  $schema: 'http://json-schema.org/draft-07/schema#',
};
