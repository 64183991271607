/* eslint-disable */
export const UnifyWebPatientComplianceMock = {
  title: 'title',
  noStudyCompliance: { noComplianceTitle: '[M]Compliance is not enabled for this study.' },
  noComplianceData: '[M]No questionnaires or device data found.',
  navTabs: { overallComplianceLabel: '[M]Overall compliance', detailedComplianceLabel: '[M]Detailed compliance' },
  legend: {
    aboveThreshold: '[M]Above threshold',
    belowThreshold: '[M]Below threshold',
    threshold: '[M]Threshold',
    paused: '[M]Paused',
  },
  overallCompliance: {
    learnMore: {
      title: { drawerHeader: '[M]Learn more', subtitle: '[M]Overall compliance' },
      labelsAndDescriptions: [
        {
          label: '[M]Purpose of Overall compliance section',
          bodyMd:
            '[M]In Overall compliance you will find an aggregated view of a patient’s total compliance for questionnaire responses and device readings from across the whole study period including the most recent time period. Compliance information can be used to check that patients are reporting data in the way that is expected by patients in the study, but also to find out if the patients need support to keep up with their assigned tasks.',
        },
        {
          label: '[M]What is compliance?',
          bodyMd:
            '[M]Compliance is the percentage of time a patient follows the instructions of their study protocol. It is calculated by dividing the number of days a patient has been compliant by the total number of days in the study. For example, if a patient has been compliant for 10 days out of 30 days, their compliance is 33%.',
        },
      ],
    },
    filters: {
      label: {
        sortBy: '[M]Sort by',
        complianceStatus: '[M]Compliance status',
        questionnaires: '[M]Questionnaires',
        devices: '[M]Devices',
        all: '[M]All',
        hideQuestionnaires: '[M]Hide questionnaires',
        hideDevices: '[M]Hide devices',
      },
      button: { clearAll: '[M]Clear all', export: '[M]Export', filters: '[M]Filters' },
      enum: {
        sortBy: { ASC: '[M]Compliance low', DESC: '[M]Compliance high', ALPHABETICAL: '[M]Alphabetical' },
        complianceStatus: { ALL: '[M]All', COMPLIANT: '[M]Compliant', NOT_COMPLIANT: '[M]Not compliant' },
      },
    },
    card: {
      header: {
        cardHeader: '[M]Overall compliance',
        compliantQuestionnaires: '[M]Compliant Questionnaires',
        compliantDevices: '[M]Compliant Devices',
        lastReceivedDataQuestionnaire: '[M]Last Received Data',
        lastReceivedDataDevice: '[M]Device Last Synced',
        totalCompliance: '[M]Total',
        targetThreshold: '[M]Threshold',
        paused: '[M]Paused',
      },
      button: { viewDetails: '[M]View details', learnMore: '[M]Learn more' },
    },
    emptyState: {
      graphNoDataMessage: '[M]No data available.',
      patientMustBeActiveMessage: '[M]Patient must be active to view compliance.',
      filterNoDataFoundTitle: '[M]No results found.',
      filterNoDataFoundMessage: '[M]Please change the filters and try again.',
    },
  },
  detailedCompliance: {
    learnMore: {
      title: { drawerHeader: '[M]Learn more', subtitle: '[M]Detailed compliance' },
      labelsAndDescriptions: [
        {
          label: '[M]Purpose of Detailed compliance section',
          bodyMd:
            '[M]In Detailed compliance you will find an aggregated view of a patient’s total compliance for questionnaire responses and device readings from a selected time period (default past 3 months). Compliance information can be used to check that patients are reporting data in the way that is expected by patients in the study, but also to find out if the patients need support to keep up with their assigned tasks.',
        },
        {
          label: '[M]What is compliance?',
          bodyMd:
            '[M]Compliance is the percentage of time a patient follows the instructions of their study protocol. It is calculated by dividing the number of days a patient has been compliant by the total number of days in the study. For example, if a patient has been compliant for 10 days out of 30 days, their compliance is 33%.',
        },
      ],
    },
    header: {
      detailedCompliance: '[M]Detailed compliance',
      totalCompliance: '[M]Total',
      targetThreshold: '[M]Threshold',
      completed: '[M]Completed',
      expected: '[M]Expected',
      lastReceivedData: '[M]Last Received Data',
      compliance: '[M]Compliance',
      emptyChart: '[M]No data available.',
    },
    enum: { complianceStatus: { COMPLIANT: '[M]Compliant', NOT_COMPLIANT: '[M]Not compliant', NO_DATA: '[M]No data' } },
    button: {
      viewDetails: '[M]View details',
      filters: '[M]Filters',
      reportDashboard: '[M]Report Dashboard',
      clearAll: '[M]Clear all',
    },
    filters: {
      sortBy: {
        label: '[M]Sort by',
        complianceLow: '[M]Compliance low',
        complianceHigh: '[M]Compliance high',
        name: '[M]Name',
      },
      label: {
        startDate: '[M]Start date',
        endDate: '[M]End date',
        startVisit: '[M]Start visit',
        endVisit: '[M]End visit',
        questionnaires: '[M]Questionnaires',
        devices: '[M]Devices',
        all: '[M]All',
        hideQuestionnaires: '[M]Hide questionnaires',
        hideDevices: '[M]Hide devices',
      },
      emptyResult: { header: '[M]No results found', message: '[M]Please change the filters and try again.' },
    },
  },
};
