import { RootState } from '../../../state/store';
import { StudiesState } from '../model/StudiesState';

export const getStudiesSelector = ({ studies }: RootState): StudiesState => studies;

export const hasStudiesSelector = ({ studies }: RootState): boolean => studies.studiesCount > 0;

export const isLoadingStudiesSelector = ({ studies }: RootState): boolean => !!studies.isLoading;

export const hasJustSingleStudySelector = ({ studies }: RootState): boolean => studies.studiesCount === 1;

export const hasMultipleStudiesSelector = ({ studies }: RootState): boolean => studies.studiesCount > 1;

export const getSelectedStudyIdSelector = ({ studies }: RootState): string | undefined => studies.selectedStudyId;
