import { Maybe } from '../../common/model/Monadish';

export enum PatientMedicationStatusType {
  UNKNOWN = 'UNKNOWN',
  IN_SCREENING = 'IN_SCREENING',
  IN_TREATMENT = 'IN_TREATMENT',
  NO_TREATMENT = 'NO_TREATMENT',
  TREATMENT_COMPLETED = 'TREATMENT_COMPLETED',
  TREATMENT_DISCONTINUED = 'TREATMENT_DISCONTINUED',
  FOLLOW_UP = 'FOLLOW_UP',
  SURVIVAL_FOLLOW_UP = 'SURVIVAL_FOLLOW_UP',
  UNBLINDED = 'UNBLINDED',
}

const followUpStatuses = [PatientMedicationStatusType.FOLLOW_UP, PatientMedicationStatusType.SURVIVAL_FOLLOW_UP];

const excludeUnknownAsString = Object.keys(PatientMedicationStatusType).filter(
  val => val !== PatientMedicationStatusType.UNKNOWN,
);

const excludeUnknown = Object.values(PatientMedicationStatusType).filter(
  val => val !== PatientMedicationStatusType.UNKNOWN,
);

const excludeUnknownAndFollowUpStatuses = excludeUnknown.filter(val => !followUpStatuses.includes(val));

const isPatientMedicationStatus = (value: Maybe<string>): value is PatientMedicationStatusType =>
  !!value && excludeUnknown.includes(value as PatientMedicationStatusType);

export const PatientMedicationStatusTypes = {
  followUpStatuses,
  excludeUnknown,
  excludeUnknownAsString,
  excludeUnknownAndFollowUpStatuses,
  isPatientMedicationStatus,
};
