import { useOnMount } from '@csp/fe-hooks';
import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useAutomaticLogoutContext } from '../../security/context/hook/useAutomaticLogoutContext';

export const AutomaticLogoutRedirect: FC = () => {
  const { lastVisitedPath, resetState } = useAutomaticLogoutContext();

  useOnMount(() => resetState());

  return lastVisitedPath ? <Navigate replace to={lastVisitedPath} /> : <></>;
};
