import { parseJsonWithBigInt } from '@csp/csp-common-axios';
import { RestOptions } from '@csp/csp-common-model';
import { Page } from '@csp/dmdp-api-common-dto';
import {
  DeviceObservationIdsOrErrorsV1,
  DeviceObservationInV1,
  DeviceObservationQueryV1,
  DeviceObservationsAndIdsInV1,
  DeviceObservationsInfoV1,
  DeviceObservationsV1,
  DeviceObservationV1,
} from '@csp/dmdp-api-device-dto';
import { devicePodUrl } from '../../dmdp-config/dmdpConfig';

const deviceObservationsUrl = (): string => `${devicePodUrl()}/device/v1/observations`;

/**
 * Batch creation of DeviceObservations. Expects list of device observations along with unique id.
 * Returns as response with the same unique id and created device observation ids, or error describing the reason for
 * failure.
 */
const batchCreateDeviceObservations = async (
  { axios, signal }: RestOptions,
  requestPayload: DeviceObservationsAndIdsInV1,
): Promise<DeviceObservationIdsOrErrorsV1> => {
  const { data } = await axios.post<DeviceObservationIdsOrErrorsV1>(
    `${deviceObservationsUrl()}:batch`,
    requestPayload,
    {
      transformResponse: parseJsonWithBigInt,
      signal,
    },
  );
  return data;
};

const createDeviceObservation = async (
  { axios, signal }: RestOptions,
  deviceObservationInV1: DeviceObservationInV1,
): Promise<DeviceObservationV1> => {
  const { data } = await axios.post<DeviceObservationV1>(deviceObservationsUrl(), deviceObservationInV1, {
    transformResponse: parseJsonWithBigInt,
    signal,
  });
  return data;
};

const queryDeviceObservations = async (
  { axios, signal }: RestOptions,
  query: DeviceObservationQueryV1,
  page: Page,
): Promise<DeviceObservationsV1> => {
  const { data } = await axios.post<DeviceObservationsV1>(`${deviceObservationsUrl()}:query`, query, {
    params: page,
    transformResponse: parseJsonWithBigInt,
    signal,
  });
  return data;
};

/**
 * Use this within sessions querying observations when not allowed to see values.
 */
const queryDeviceObservationsInfo = async (
  { axios, signal }: RestOptions,
  query: DeviceObservationQueryV1,
  page: Page,
): Promise<DeviceObservationsInfoV1> => {
  const { data } = await axios.post<DeviceObservationsInfoV1>(`${deviceObservationsUrl()}/info:query`, query, {
    params: page,
    transformResponse: parseJsonWithBigInt,
    signal,
  });
  return data;
};

export const DeviceObservationsRestServiceV1 = {
  batchCreateDeviceObservations,
  createDeviceObservation,
  queryDeviceObservations,
  queryDeviceObservationsInfo,
};
