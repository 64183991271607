import { ContentstackConfigV1 } from '@csp/config-schemas';
import { RestOptions } from '@csp/csp-common-model';
import { ConfigurationRestServiceV1 } from '@csp/dmdp-api-client';
import { ConfigurationEntryIncludeFieldV1, ConfigurationEntryV1 } from '@csp/dmdp-api-configuration-dto';
import { Maybe } from '@graphql-tools/utils';

export const getContentstackConfigV1 = async (apiOptions: RestOptions): Promise<ContentstackConfigV1> => {
  const { entries } = await ConfigurationRestServiceV1.query(apiOptions, {
    fields: [
      ConfigurationEntryIncludeFieldV1.DEFINITION_CODE,
      ConfigurationEntryIncludeFieldV1.DEFINITION_VERSION,
      ConfigurationEntryIncludeFieldV1.DATA,
    ],
  });

  const config: Maybe<ConfigurationEntryV1> = entries.find(entry => entry.definitionCode === 'CONTENTSTACK_CONFIG');
  return config?.data as ContentstackConfigV1;
};
