import { uniq } from 'lodash';
import { RequestCode } from './codes/RequestCode';
import { ScheduleCode } from './codes/ScheduleCode';
import { ScheduleRequestRef } from './ScheduleRequestRef';
import { GenericRequest } from './schedulingModels/GenericRequest';
import { RequestRef } from './schedulingModels/RequestRef';

export type ScheduleRef = {
  scheduleCode: ScheduleCode;
  requestCodes: RequestCode[];
};

const fromRequest = (request: GenericRequest): ScheduleRef => ({
  scheduleCode: request.scheduleCode,
  requestCodes: [request.requestCode],
});

const fromRequestRef = (requestRef: ScheduleRequestRef): ScheduleRef => ({
  scheduleCode: requestRef.scheduleCode,
  requestCodes: [requestRef.requestCode],
});

/**
 *
 * @param requests All requests within the same schedule
 */
const fromSchedule = (scheduleCode: ScheduleCode, requests: GenericRequest[]): ScheduleRef => ({
  scheduleCode,
  requestCodes: uniq(requests.map(request => request.requestCode)),
});

const toScheduleRequestRefs = ({ scheduleCode, requestCodes }: ScheduleRef): ScheduleRequestRef[] =>
  requestCodes.map(requestCode => ({
    scheduleCode,
    requestCode,
  }));

const matchesRequestRef = (scheduleRef: ScheduleRef, requestRef: Partial<RequestRef>): boolean =>
  scheduleRef.scheduleCode === requestRef.scheduleCode &&
  scheduleRef.requestCodes.includes(requestRef.requestCode ?? '');

const isEqual = (scheduleRef1: ScheduleRef, scheduleRef2: ScheduleRef): boolean =>
  scheduleRef1.scheduleCode === scheduleRef2.scheduleCode &&
  scheduleRef1.requestCodes.every(requestCode => scheduleRef2.requestCodes.indexOf(requestCode) !== -1);

export const ScheduleRef = {
  fromRequest,
  fromSchedule,
  fromRequestRef,
  matchesRequestRef,
  toScheduleRequestRefs,
  isEqual,
};
