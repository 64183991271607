import { RoleType } from '@csp/dmdp-api-common-dto';
import { PermissionType } from '../type/PermissionType';

const permissionsByRole: Map<RoleType, PermissionType[]> = new Map([
  [
    RoleType.CRA,
    [
      PermissionType.MANAGE_SITES,
      PermissionType.SITE_TEAM_ADMIN,
      PermissionType.READ_PATIENT,
      PermissionType.VIEW_PATIENT_ACCOUNT,
      PermissionType.VIEW_QUESTIONNAIRE_RESPONSE,
      PermissionType.CAN_APPROVE_EC_STATUS,
      PermissionType.CAN_EXPORT_CLOSEOUT_DATA,
      PermissionType.VIEW_USER_DETAILS_REPORT,
    ],
  ],
  [
    RoleType.PI,
    [
      PermissionType.MANAGE_VISIT,
      PermissionType.MANAGE_PATIENT,
      PermissionType.SITE_TEAM_ADMIN,
      PermissionType.MANAGE_SITE_PERSONNEL,
      PermissionType.MANAGE_SITE_LOCATIONS,
      PermissionType.READ_PATIENT,
      PermissionType.VIEW_PATIENT_CONTACT_POINT,
      PermissionType.VIEW_PATIENT_ACCOUNT,
      PermissionType.MANAGE_MEDICATION,
      PermissionType.VIEW_QUESTIONNAIRE_RESPONSE,
      PermissionType.MANAGE_QUESTIONNAIRE_RESPONSE,
      PermissionType.VIEW_HEALTH_EVENT_RESPONSE,
      PermissionType.MANAGE_HEALTH_EVENT,
      PermissionType.VIEW_OTHER_SITES_METRICS,
      PermissionType.CAN_START_SPIROMETRY_SESSION,
      PermissionType.CAN_MANAGE_SPIROMETRY_SESSION,
      PermissionType.CAN_EXPORT_CLOSEOUT_DATA,
      PermissionType.CAN_COMPILE_QUESTIONNAIRE,
      PermissionType.VIEW_DATA_CHANGE_EDIT,
      PermissionType.MANAGE_DATA_CHANGE_EDIT,
      PermissionType.MANAGE_HBS,
      PermissionType.CAN_REVIEW_DATA_CHANGE,
      PermissionType.MANAGE_RPM_EVENT,
      PermissionType.MANAGE_REQUEST_TAGS,
    ],
  ],
  [
    RoleType.INVESTIGATOR,
    [
      PermissionType.MANAGE_VISIT,
      PermissionType.MANAGE_PATIENT,
      PermissionType.MANAGE_SITE_LOCATIONS,
      PermissionType.MANAGE_SITE_PERSONNEL,
      PermissionType.READ_PATIENT,
      PermissionType.VIEW_PATIENT_CONTACT_POINT,
      PermissionType.VIEW_PATIENT_ACCOUNT,
      PermissionType.MANAGE_MEDICATION,
      PermissionType.VIEW_QUESTIONNAIRE_RESPONSE,
      PermissionType.MANAGE_QUESTIONNAIRE_RESPONSE,
      PermissionType.VIEW_HEALTH_EVENT_RESPONSE,
      PermissionType.MANAGE_HEALTH_EVENT,
      PermissionType.VIEW_OTHER_SITES_METRICS,
      PermissionType.CAN_MANAGE_SPIROMETRY_SESSION,
      PermissionType.CAN_START_SPIROMETRY_SESSION,
      PermissionType.CAN_EXPORT_CLOSEOUT_DATA,
      PermissionType.CAN_COMPILE_QUESTIONNAIRE,
      PermissionType.VIEW_DATA_CHANGE_EDIT,
      PermissionType.MANAGE_DATA_CHANGE_EDIT,
      PermissionType.MANAGE_HBS,
      PermissionType.MANAGE_RPM_EVENT,
      PermissionType.MANAGE_REQUEST_TAGS,
      PermissionType.CAN_REVIEW_DATA_CHANGE,
    ],
  ],
  [
    RoleType.STUDY_ASST,
    [
      PermissionType.MANAGE_PATIENT,
      PermissionType.MANAGE_VISIT,
      PermissionType.MANAGE_SITE_LOCATIONS,
      PermissionType.READ_PATIENT,
      PermissionType.VIEW_PATIENT_CONTACT_POINT,
      PermissionType.VIEW_PATIENT_ACCOUNT,
      PermissionType.VIEW_QUESTIONNAIRE_RESPONSE,
      PermissionType.MANAGE_QUESTIONNAIRE_RESPONSE,
      PermissionType.VIEW_HEALTH_EVENT_RESPONSE,
      PermissionType.MANAGE_HEALTH_EVENT,
      PermissionType.VIEW_OTHER_SITES_METRICS,
      PermissionType.CAN_MANAGE_SPIROMETRY_SESSION,
      PermissionType.CAN_START_SPIROMETRY_SESSION,
      PermissionType.CAN_EXPORT_CLOSEOUT_DATA,
      PermissionType.CAN_COMPILE_QUESTIONNAIRE,
      PermissionType.VIEW_DATA_CHANGE_EDIT,
      PermissionType.MANAGE_DATA_CHANGE_EDIT,
      PermissionType.MANAGE_HBS,
      PermissionType.MANAGE_RPM_EVENT,
    ],
  ],
  [
    RoleType.READ_ALL,
    [
      PermissionType.READ_PATIENT,
      PermissionType.VIEW_OTHER_SITES_METRICS,
      PermissionType.VIEW_PATIENT_CONTACT_POINT,
      PermissionType.VIEW_PATIENT_ACCOUNT,
      PermissionType.CAN_EXPORT_CLOSEOUT_DATA,
      PermissionType.VIEW_HEALTH_EVENT_RESPONSE,
      PermissionType.VIEW_QUESTIONNAIRE_RESPONSE,
    ],
  ],
  [
    RoleType.SSTM,
    [
      PermissionType.READ_PATIENT,
      PermissionType.VIEW_PATIENT_CONTACT_POINT,
      PermissionType.VIEW_PATIENT_ACCOUNT,
      PermissionType.VIEW_QUESTIONNAIRE_RESPONSE,
      PermissionType.VIEW_HEALTH_EVENT_RESPONSE,
      PermissionType.CAN_SKIP_CONSENT_APPROVALS,
      PermissionType.CAN_VIEW_ALL_STUDY_DOCUMENTS,
      PermissionType.CAN_DEBUG_CMS,
      PermissionType.VIEW_DATA_CHANGE_EDIT,
      PermissionType.VIEW_USER_DETAILS_REPORT,
    ],
  ],
  [
    RoleType.GSTM,
    [
      PermissionType.CAN_SKIP_CONSENT_APPROVALS,
      PermissionType.CAN_VIEW_ALL_STUDY_DOCUMENTS,
      PermissionType.VIEW_PATIENT_ACCOUNT,
      PermissionType.VIEW_PATIENT_CONTACT_POINT,
      PermissionType.VIEW_QUESTIONNAIRE_RESPONSE,
      PermissionType.MANAGE_STUDY_CLOSE_OUT_TRACKER,
      PermissionType.CAN_EXPORT_CLOSEOUT_DATA,
      PermissionType.VIEW_USER_DETAILS_REPORT,
    ],
  ],
]);

const getPermissionsByRole = (role: RoleType): PermissionType[] => {
  const permissions = permissionsByRole.get(role);
  return permissions ? permissions : [];
};

export const PermissionConfigService = {
  getPermissionsByRole,
};
