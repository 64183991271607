import { useCallback, useState } from 'react';

type SetTrue = () => void;
type SetFalse = () => void;
type Toggle = () => void;

type BooleanHook = [boolean, SetTrue, SetFalse, Toggle];

export const useBoolean = (initialState = false): BooleanHook => {
  const [isOpen, setValue] = useState(initialState);
  const setTrue = useCallback(() => setValue(true), []);
  const setFalse = useCallback(() => setValue(false), []);
  const toggle = useCallback(() => setValue(value => !value), []);

  return [isOpen, setTrue, setFalse, toggle];
};
