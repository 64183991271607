import { DeviceObservationActivationRefV1 } from '@csp/dmdp-api-device-dto';

export type DeviceObservationActivationRef = {
  type: string;
  value: string;
};

const from = ({ type, value }: DeviceObservationActivationRefV1): DeviceObservationActivationRef => ({
  type,
  value,
});

export const DeviceObservationActivationRef = {
  from,
};
