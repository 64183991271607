export enum PatientVitalStatusType {
  ALIVE = 'ALIVE',
  DEAD = 'DEAD',
  UNSPECIFIED = 'UNSPECIFIED',
  UNKNOWN = 'UNKNOWN',
}

const values = Object.values(PatientVitalStatusType).filter(val => val !== PatientVitalStatusType.UNKNOWN);

const isPatientVitalStatus = (value: string): value is PatientVitalStatusType =>
  values.includes(value as PatientVitalStatusType);

export const PatientVitalStatusTypes = {
  values,
  isPatientVitalStatus,
};
