import { CommonStoreState } from '../../../../system/redux/ducks';
import { LocaleState } from './localeReducer';

export const getLocaleState = (state: CommonStoreState): LocaleState => state.locale;
export const getCountryCode = (state: CommonStoreState): string => getLocaleState(state).countryCode;
export const getLangCode = (state: CommonStoreState): string => getLocaleState(state).langCode;
export const getLocale = (state: CommonStoreState, delimiter = '_'): string => {
  const { langCode, countryCode } = getLocaleState(state);
  return `${langCode}${delimiter}${countryCode}`;
};
