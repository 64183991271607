import { Maybe, RecommendedResourcesConfig } from '@csp/csp-common-model';

const toRecommendedResourcesConfig = (config: Maybe<RecommendedResourcesConfig>): Maybe<RecommendedResourcesConfig> => {
  if (!config) {
    return undefined;
  }

  return {
    recommendedResourcesActivated: config.recommendedResourcesActivated,
  };
};

export const RecommendedResourcesConfigService = {
  toRecommendedResourcesConfig,
};
