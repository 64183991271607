import { memoize } from 'lodash';
import { ContentConfigService } from '@csp/csp-common-content';
import { JSONSchema, JsonValidationService } from '@csp/csp-common-util';
import { JsonValidationError } from '@csp/csp-common-model';

export const makeSchemaStrict = (schema: JSONSchema): JSONSchema => {
  if (schema.properties && Object.keys(schema.properties).length > 0) {
    const required = Object.keys(schema.properties).map(key => key);
    let requiredProperties = schema.properties;
    Object.entries(schema.properties).forEach(([key, childSchema]) => {
      requiredProperties = { ...requiredProperties, [key]: makeSchemaStrict(childSchema) };
    });
    return {
      ...schema,
      required,
      properties: requiredProperties,
    };
  } else if (schema.items) {
    return {
      ...schema,
      items: makeSchemaStrict(schema.items),
    };
  } else {
    return schema;
  }
};

export const validateSchema = (schema: JSONSchema, data: unknown): boolean | PromiseLike<boolean> => {
  const strictSchema = makeSchemaStrict(schema);

  try {
    JsonValidationService.validateJson(schema.title ?? '', strictSchema, data);

    return true;
  } catch (error) {
    const showValidationMessage = !ContentConfigService.get().hideSchemaValidationMessages;

    if (showValidationMessage && process.env.NODE_ENV !== 'test') {
      const errorMessage = JsonValidationError.isJsonValidationError(error) ? error.validationErrors : error;
      const validationMessage = `CMS Schema match Warning!
Bundled "${schema.title}.json" schema and CMS schema does not match:
`;

      console.warn(validationMessage, errorMessage);
    }

    return false;
  }
};

export const memoizedValidateSchema: typeof validateSchema = memoize(validateSchema, (_, data: unknown) =>
  JSON.stringify(data),
);
