import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInWeeks,
  differenceInYears,
  differenceInCalendarISOWeeks as differenceInCalendarWeeksDateFns,
} from 'date-fns';
import {
  formatZonedTime,
  toTimezoneStr,
  zonedAddDuration,
  zonedStartOfDay,
  zonedStartOfHour,
  zonedStartOfMinute,
  zonedStartOfMonth,
  zonedStartOfSecond,
  zonedStartOfWeek,
  zonedStartOfYear,
} from '@csp/csp-common-date-util';
import { DateTimeDuration, DateTimeInterval, StateAssert, TimeStr, ZonedDateTime } from '@csp/csp-common-model';
import { UnitOfTime } from '../model/schedulingModels/UnitOfTime';
import { Duration } from '../model/schedulingModels/Duration';

const toDateTimeDuration = (duration: Duration): DateTimeDuration => {
  switch (duration.unit) {
    case UnitOfTime.S:
      return { seconds: duration.value };
    case UnitOfTime.MIN:
      return { minutes: duration.value };
    case UnitOfTime.H:
      return { hours: duration.value };
    case UnitOfTime.D:
      return { days: duration.value };
    case UnitOfTime.WK:
      return { weeks: duration.value };
    case UnitOfTime.MO:
      return { months: duration.value };
    case UnitOfTime.A:
      return { years: duration.value };
    default:
      throw new Error(`Unhandled unit of time: ${duration.unit}`);
  }
};

const differenceInUnitOfTime = (interval: DateTimeInterval, unit: UnitOfTime): Duration => {
  switch (unit) {
    case UnitOfTime.S:
      return { unit, value: differenceInSeconds(interval.end.unixTimeMillis, interval.start.unixTimeMillis) };
    case UnitOfTime.MIN:
      return { unit, value: differenceInMinutes(interval.end.unixTimeMillis, interval.start.unixTimeMillis) };
    case UnitOfTime.H:
      return { unit, value: differenceInHours(interval.end.unixTimeMillis, interval.start.unixTimeMillis) };
    case UnitOfTime.D:
      return { unit, value: differenceInDays(interval.end.unixTimeMillis, interval.start.unixTimeMillis) };
    case UnitOfTime.WK:
      return { unit, value: differenceInWeeks(interval.end.unixTimeMillis, interval.start.unixTimeMillis) };
    case UnitOfTime.MO:
      return { unit, value: differenceInMonths(interval.end.unixTimeMillis, interval.start.unixTimeMillis) };
    case UnitOfTime.A:
      return { unit, value: differenceInYears(interval.end.unixTimeMillis, interval.start.unixTimeMillis) };
    default:
      throw new Error(`Unhandled unit of time: ${unit}`);
  }
};

const differenceInCalendarWeeks = (interval: DateTimeInterval): Duration => ({
  unit: UnitOfTime.WK,
  value: differenceInCalendarWeeksDateFns(interval.end.unixTimeMillis, interval.start.unixTimeMillis),
});

const startOfNext = (zonedDateTime: ZonedDateTime, unit: UnitOfTime): ZonedDateTime => {
  const next = zonedAddDuration(zonedDateTime, toDateTimeDuration({ unit, value: 1 }));
  return startOf(next, unit);
};

const startOf = (zonedDateTime: ZonedDateTime, unit: UnitOfTime): ZonedDateTime => {
  switch (unit) {
    case UnitOfTime.S:
      return zonedStartOfSecond(zonedDateTime);
    case UnitOfTime.MIN:
      return zonedStartOfMinute(zonedDateTime);
    case UnitOfTime.H:
      return zonedStartOfHour(zonedDateTime);
    case UnitOfTime.D:
      return zonedStartOfDay(zonedDateTime);
    case UnitOfTime.WK:
      return zonedStartOfWeek(zonedDateTime);
    case UnitOfTime.MO:
      return zonedStartOfMonth(zonedDateTime);
    case UnitOfTime.A:
      return zonedStartOfYear(zonedDateTime);
    default:
      throw new Error(`Unhandled unit of time: ${unit}`);
  }
};
const getTimeStr = (zonedDateTime: ZonedDateTime): TimeStr => {
  const timeStr = formatZonedTime(toTimezoneStr(zonedDateTime));
  StateAssert.notNull(timeStr, `Failed to format to time, invalid date ${toTimezoneStr(zonedDateTime)}`);
  return timeStr;
};

const getHoursOfTimeStr = (time: TimeStr): number => Number(time.split(':')[0]);

const getMinutesOfTimeStr = (time: TimeStr): number => Number(time.split(':')[1]);

const isBeforeDateTime = (zonedDateTime: ZonedDateTime, maxDateTime: ZonedDateTime): boolean =>
  zonedDateTime.unixTimeMillis <= maxDateTime.unixTimeMillis;

export const ScheduleDateService = {
  toDateTimeDuration,
  differenceInUnitOfTime,
  differenceInCalendarWeeks,
  startOfNext,
  startOf,
  getHoursOfTimeStr,
  getMinutesOfTimeStr,
  getTimeStr,
  isBeforeDateTime,
};
