import { MenuItem, RouterLink, useTheme } from '@csp/csp-web-ui';
import { FC } from 'react';
import { CspPath } from '../../../CspPath';
import { useSignOut } from '../../security/hooks/useSignOut';
import { StaticContentService } from '../../../common/service/StaticContentService';

export const SignOutContainer: FC = () => {
  const { palette } = useTheme();
  const { signOut } = useSignOut();
  const topBarBlock = StaticContentService.getPublicBlock('webHcpNavigationHeader', 'topBar');

  return (
    <RouterLink to={CspPath.LOGIN} onClick={signOut} style={{ color: palette.common.black }}>
      <MenuItem title={topBarBlock.button.signOut.label} iconProps={{ name: 'logout', color: 'black' }} />
    </RouterLink>
  );
};
