import { parseJsonWithBigInt } from '@csp/csp-common-axios';
import { largePage, Page, PodConfig, TenantConfigResponse } from '@csp/dmdp-api-common-dto';
import {
  QuestionnaireActiveSchedulesOrganizationsQueryV2,
  QuestionnaireActiveSchedulesOrganizationsV2,
  QuestionnaireActiveSchedulesV2,
  QuestionnaireInV2,
  QuestionnaireResponseAggregateResultV2,
  QuestionnaireResponseAggregateV2,
  QuestionnaireResponseAnswersInV2,
  QuestionnaireResponseIdsOrErrorsV2,
  QuestionnaireResponseInfoQueryV2,
  QuestionnaireResponseInfosV2,
  QuestionnaireResponseInV2,
  QuestionnaireResponseQueryV2,
  QuestionnaireResponsesAndIdsInV2,
  QuestionnaireResponseStatusValueInV2,
  QuestionnaireResponsesV2,
  QuestionnaireResponseV2,
  QuestionnaireSchedulesV2,
  QuestionnaireScheduleVersionRequestV2,
  QuestionnairesV2,
  QuestionnaireV2,
  RefV2,
} from '@csp/dmdp-api-questionnaire-dto';
import { RestOptions } from '@csp/csp-common-model';
import { DataChangeIdRef } from '@csp/csp-common-dcf-model';
import { AuditApiQueryV2, AuditQueryEntriesResponseV2 } from '@csp/dmdp-api-audit-dto';
import { journalpodUrl } from '../../dmdp-config/dmdpConfig';
import { DataChangeUtil } from '../../util/DataChangeUtil';

const questionnaireUrl = (): string => `${journalpodUrl()}/questionnaire/v2`;
const auditUrl = (): string => `${journalpodUrl()}/audit/v2`;

const getQuestionnaireByCode = async (
  { axios, signal }: RestOptions,
  questionnaireCode: string,
): Promise<QuestionnaireV2> => {
  const { data } = await axios.get<QuestionnaireV2>(`${questionnaireUrl()}/questionnaires/${questionnaireCode}`, {
    transformResponse: parseJsonWithBigInt,
    signal,
  });

  return data;
};

const getQuestionnaireResponseById = async (
  { axios, signal }: RestOptions,
  responseId: string,
): Promise<QuestionnaireResponseV2> => {
  const { data } = await axios.get<QuestionnaireResponseV2>(
    `${questionnaireUrl()}/questionnaire-responses/${responseId}`,
    {
      signal,
    },
  );

  return data;
};

const queryQuestionnaireResponses = async (
  { axios, signal }: RestOptions,
  queryV2: QuestionnaireResponseQueryV2,
  page?: Page,
): Promise<QuestionnaireResponsesV2> => {
  const { data } = await axios.post<QuestionnaireResponsesV2>(
    `${questionnaireUrl()}/questionnaire-responses:query`,
    queryV2,
    {
      params: page,
      signal,
    },
  );

  return data;
};

const queryQuestionnaireResponseInfos = async (
  { axios, signal }: RestOptions,
  queryV2: QuestionnaireResponseInfoQueryV2,
  page?: Page,
): Promise<QuestionnaireResponseInfosV2> => {
  const { data } = await axios.post<QuestionnaireResponseInfosV2>(
    `${questionnaireUrl()}/questionnaire-response-infos:query`,
    queryV2,
    {
      params: page,
      signal,
    },
  );

  return data;
};

const createQuestionnaireResponse = async (
  { axios, signal }: RestOptions,
  questionnaireResponse: QuestionnaireResponseInV2,
): Promise<QuestionnaireResponseV2> => {
  const { data } = await axios.post<QuestionnaireResponseV2>(
    `${questionnaireUrl()}/questionnaire-responses/`,
    questionnaireResponse,
    {
      signal,
    },
  );

  return data;
};

const createQuestionnaireBulkResponses = async (
  { axios, signal }: RestOptions,
  questionnaireResponses: QuestionnaireResponsesAndIdsInV2,
): Promise<QuestionnaireResponseIdsOrErrorsV2> => {
  const { data } = await axios.post<QuestionnaireResponseIdsOrErrorsV2>(
    `${questionnaireUrl()}/questionnaire-responses:batch`,
    questionnaireResponses,
    {
      signal,
    },
  );

  return data;
};

const createQuestionnaireResponseForUser = async (
  { axios, signal }: RestOptions,
  userId: string,
  questionnaireResponse: QuestionnaireResponseInV2,
): Promise<QuestionnaireResponseV2> => {
  const { data } = await axios.post<QuestionnaireResponseV2>(
    `${questionnaireUrl()}/users/${userId}/questionnaire-responses/`,
    questionnaireResponse,
    {
      signal,
    },
  );

  return data;
};

const updateQuestionnaireResponseStatusById = async (
  { axios, signal }: RestOptions,
  questionnaireResponseId: string,
  questionnaireResponseStatus: QuestionnaireResponseStatusValueInV2,
  dcIdRef?: DataChangeIdRef,
): Promise<void> => {
  await axios.put(
    `${questionnaireUrl()}/questionnaire-responses/${questionnaireResponseId}/status`,
    questionnaireResponseStatus,
    {
      headers: DataChangeUtil.generateHeaders(dcIdRef),
      signal,
    },
  );
};

const updateQuestionnaireResponseAnswersById = async (
  { axios, signal }: RestOptions,
  questionnaireResponseId: string,
  questionnaireResponseAnswers: QuestionnaireResponseAnswersInV2,
  dcIdRef?: DataChangeIdRef,
): Promise<void> => {
  await axios.put(
    `${questionnaireUrl()}/questionnaire-responses/${questionnaireResponseId}/answers`,
    questionnaireResponseAnswers,
    {
      headers: DataChangeUtil.generateHeaders(dcIdRef),
      signal,
    },
  );
};

const updateQuestionnaireResponseRefById = async (
  { axios, signal }: RestOptions,
  questionnaireResponseId: string,
  ref: RefV2,
  dcIdRef: DataChangeIdRef,
): Promise<void> => {
  await axios.put(
    `${questionnaireUrl()}/questionnaire-responses/${questionnaireResponseId}/refs/${ref.key}`,
    { refs: ref },
    {
      headers: DataChangeUtil.generateHeaders(dcIdRef),
      signal,
    },
  );
};

const deleteQuestionnaireResponseRefById = async (
  { axios, signal }: RestOptions,
  questionnaireResponseId: string,
  refKey: RefV2['key'],
  dcIdRef: DataChangeIdRef,
): Promise<void> => {
  await axios.delete(`${questionnaireUrl()}/questionnaire-responses/${questionnaireResponseId}/refs/${refKey}`, {
    headers: DataChangeUtil.generateHeaders(dcIdRef),
    signal,
  });
};

const reviewQuestionnaireResponse = async (
  { axios, signal }: RestOptions,
  questionnaireResponseId: string,
): Promise<void> => {
  await axios.put(`${questionnaireUrl()}/questionnaire-responses/${questionnaireResponseId}:review`, {
    signal,
  });
};

/**
 * Get active schedule windows by organization id
 * Get active schedules based on the supplied organization and its parents.
 *
 * @param axiosDmdp
 * @param organizationId
 */
const getActiveSchedulesByOrganizationId = async (
  { axios, signal }: RestOptions,
  organizationId: string,
): Promise<QuestionnaireActiveSchedulesV2> => {
  const { data } = await axios.get<QuestionnaireActiveSchedulesV2>(
    `${questionnaireUrl()}/organizations/${organizationId}/active-schedules`,
    {
      transformResponse: parseJsonWithBigInt,
      signal,
    },
  );
  return data;
};

const queryActiveSchedulesByOrganizationIds = async (
  { axios, signal }: RestOptions,
  queryV2: QuestionnaireActiveSchedulesOrganizationsQueryV2,
): Promise<QuestionnaireActiveSchedulesOrganizationsV2> => {
  const { data } = await axios.post<QuestionnaireActiveSchedulesOrganizationsV2>(
    `${questionnaireUrl()}/active-schedules:query-by-organizations`,
    queryV2,
    {
      transformResponse: parseJsonWithBigInt,
      signal,
    },
  );
  return data;
};

const getAllSchedules = async ({ axios, signal }: RestOptions): Promise<QuestionnaireSchedulesV2> => {
  const { data } = await axios.get<QuestionnaireSchedulesV2>(`${questionnaireUrl()}/schedules`, {
    signal,
  });
  return data;
};

const getQuestionnaires = async ({ axios, signal }: RestOptions): Promise<QuestionnairesV2> => {
  const { data } = await axios.get<QuestionnairesV2>(`${questionnaireUrl()}/questionnaires`, {
    signal,
  });
  return {
    ...data,
    questionnaires: data.questionnaires.sort((a, b) => a.questionnaireCode.localeCompare(b.questionnaireCode)),
  };
};

const getScheduleByCodeAndVersion = async (
  { axios, signal }: RestOptions,
  scheduleCode: string,
  versionCode: string,
  requestCode: string,
): Promise<QuestionnaireScheduleVersionRequestV2> => {
  const { data } = await axios.get<QuestionnaireScheduleVersionRequestV2>(
    `${questionnaireUrl()}/schedules/${scheduleCode}/versions/${versionCode}/requests/${requestCode}`,
    {
      transformResponse: parseJsonWithBigInt,
      signal,
    },
  );
  return data;
};

//TODO: Move this to separate file?
const getTenantConfig = async ({ axios, signal }: RestOptions): Promise<TenantConfigResponse> => {
  const url = `${journalpodUrl()}/config/tenant/v1`;
  const response = await axios.get<PodConfig>(url, {
    signal,
  });
  return { data: response.data, url };
};

const validate = async ({ axios, signal }: RestOptions, questionnaire: QuestionnaireInV2): Promise<void> => {
  await axios.post<void>(`${questionnaireUrl()}/questionnaires:validate`, questionnaire, {
    signal,
  });
};

const aggregate = async (
  { axios, signal }: RestOptions,
  aggregateV2: QuestionnaireResponseAggregateV2,
): Promise<QuestionnaireResponseAggregateResultV2> => {
  const { data } = await axios.post(`${questionnaireUrl()}/questionnaire-responses:aggregate`, aggregateV2, {
    signal,
  });
  return data;
};

const queryAudit = async (
  { axios, signal }: RestOptions,
  query: AuditApiQueryV2,
  page: Page = largePage(),
): Promise<AuditQueryEntriesResponseV2> => {
  const { data } = await axios.post<AuditQueryEntriesResponseV2>(`${auditUrl()}/audit:query`, query, {
    params: page,
    transformResponse: parseJsonWithBigInt,
    signal,
  });

  return data;
};

export const QuestionnaireRestServiceV2 = {
  getQuestionnaireByCode,
  getQuestionnaireResponseById,
  queryQuestionnaireResponses,
  queryQuestionnaireResponseInfos,
  queryActiveSchedulesByOrganizationIds,
  reviewQuestionnaireResponse,
  createQuestionnaireResponse,
  createQuestionnaireResponseForUser,
  getActiveSchedulesByOrganizationId,
  getAllSchedules,
  getScheduleByCodeAndVersion,
  getTenantConfig,
  getQuestionnaires,
  updateQuestionnaireResponseStatusById,
  updateQuestionnaireResponseAnswersById,
  updateQuestionnaireResponseRefById,
  deleteQuestionnaireResponseRefById,
  validate,
  aggregate,
  createQuestionnaireBulkResponses,
  queryAudit,
};
