import { EventIdStr, HealthEventStatusType, ZonedDateTime } from '@csp/csp-common-model';
import { HealthEventV1 } from '@csp/dmdp-api-healthevent-dto';
import { fromTimezoneStr } from '@csp/csp-common-date-util';
import { HealthEventReferenceType } from '@csp/dto';
import { HealthEventStatus } from './HealthEventStatus';

export interface HealthEvent {
  type: string;
  eventId: EventIdStr;
  status: HealthEventStatus;
  timestamp: ZonedDateTime;
  healthEventId: string;
  questionnaireResponseId?: string;
  questionnaireResponseExternalId?: string;
  userId: string;
  modifiedBy?: string;
  resolved: boolean;
}

const from = (healthEventV1: HealthEventV1): HealthEvent => {
  const status = HealthEventStatusType.from(healthEventV1.status);

  return {
    eventId: healthEventV1.eventId,
    type: healthEventV1.type,
    status: HealthEventStatus.fromV1(healthEventV1),
    timestamp: fromTimezoneStr(healthEventV1.timestamp),
    healthEventId: healthEventV1.healthEventId,
    questionnaireResponseId: healthEventV1.refs?.find(
      ref => ref.key === HealthEventReferenceType.QUESTIONNAIRE_RESPONSE_ID, // Questionnaire v1
    )?.value,
    questionnaireResponseExternalId: healthEventV1.refs?.find(
      ref => ref.key === HealthEventReferenceType.CLIENT_GENERATED_QUESTIONNAIRE_REF, // Questionnaire v2
    )?.value,
    userId: healthEventV1.userId,
    modifiedBy: healthEventV1.audit?.modifiedBy,
    resolved: status === 'VIEWED',
  };
};

export const HealthEvent = { from };
