import { ApiOptions, RestOptions } from '@csp/csp-common-model';
import { AxiosPublicService, toRestOptions } from '@csp/csp-fe-auth';
import { ContentPackageType } from '../../model/ContentPackageType';

/**
 * Returns rest options suitable for fetching content. Uses a public axios instance without a token to fetch the public content.
 * @param packageType
 * @param apiOptions
 */
export const toContentRestOptions = (packageType: ContentPackageType, apiOptions?: ApiOptions): RestOptions =>
  packageType === 'public' ? toRestOptions(apiOptions, AxiosPublicService.axios()) : toRestOptions(apiOptions);
