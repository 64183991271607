import { EnumUtil } from '@csp/csp-common-enum-util';
import { Maybe } from '@csp/csp-common-model';
import { RpmAlgorithmCode } from '@csp/csp-common-rpm-model';
import { AssessmentAlgorithmV1 } from '@csp/dmdp-api-assessment-dto';
import { AssessmentAlgorithmVersion } from './AssessmentAlgorithmVersion';

export type AssessmentAlgorithm = {
  version: AssessmentAlgorithmVersion;
  code: RpmAlgorithmCode;
  hashCodeSignature?: string;
};

const from = (algorithmV1?: AssessmentAlgorithmV1): Maybe<AssessmentAlgorithm> => {
  const algorithmCode = EnumUtil.fromMaybeString<RpmAlgorithmCode>(algorithmV1?.code, RpmAlgorithmCode);
  return algorithmCode && algorithmV1?.version
    ? {
        code: algorithmCode,
        version: algorithmV1?.version,
        hashCodeSignature: algorithmV1?.hashCodeSignature,
      }
    : undefined;
};

export const AssessmentAlgorithm = {
  from,
};
