import {
  ComplianceConfigV1,
  DeviceConfigV2,
  NotificationJobConfigV1,
  PatientConfigV1,
  PatientStatusConfigV1,
  RawAssessmentConsequenceActivityCardConfigV1,
  RawCspFeatureConfigV2,
  RawPatientCopingTipConfigV1,
  RequestTagsStudyConfigV1,
  RpmConfigV1,
  StudyMetricsConfigV1,
} from '@csp/config-schemas';
import { StudyConfigRestServiceV2 } from '@csp/csp-api-client';
import { ApiOptions, Maybe } from '@csp/csp-common-model';
import { toRestOptions } from '@csp/csp-fe-auth';
import { RecommendedResourcesConfigV1, StudyConfigurationEntryV2 } from '@csp/dto';
import { StudyDefinitionCodes } from '../model/StudyDefinitionCodes';
import { StudyConfig } from '../model/StudyConfig';
import { FeatureToggleFactory } from '../../feature-toggle/model/FeatureToggleFactory';
import { PatientConfigServiceV1 } from '../../patient/service/PatientConfigServiceV1';
import { PatientStatusConfigServiceV1 } from '../../patient/service/PatientStatusConfigServiceV1';
import { StudyMetricsConfigService } from '../../study-metrics/service/StudyMetricsConfigService';
import { NotificationJobConfigServiceV1 } from '../../notification-job/service/NotificationJobConfigServiceV1';
import { DeviceConfigServiceV1 } from '../../device/service/DeviceConfigServiceV1';
import { ComplianceConfigService } from '../../compliance/service/ComplianceConfigService';
import { ReportsConfigService } from '../../reports-config/service/ReportsConfigService';
import { RpmConfigService } from '../../rpm/service/RpmConfigService';
import { PatientCopingTipService } from '../../patient-care-tip/PatientCopingTipService';
import { RecommendedResourcesConfigService } from '../../recommended-resources-config/service/RecommendedResourcesConfigService';
import { RequestTagsStudyConfigService } from '../../request-tags-config/service/RequestTagsStudyConfigSerivce';
import { AssessmentConsequenceActivityCardService } from '../../assessment-consequence-activity-card/AssessmentConsequenceActivityCardService';

export const getConfigurationByCode = <T>(
  entries: StudyConfigurationEntryV2[],
  definitionCode: StudyDefinitionCodes,
): T => entries.find(e => e.definitionCode === definitionCode)?.data as T;

let currentConfiguration: Maybe<StudyConfig>;

const getStudyConfig = (): Maybe<StudyConfig> => currentConfiguration;

const setStudyConfig = (studyConfig: StudyConfig): void => {
  currentConfiguration = studyConfig;
};

// TODO Works for 1.2 and 2.0 but will break in the future if we add new implicit dependencies in StudyConfigurationEntryV2
const toStudyConfig = (entries: StudyConfigurationEntryV2[]): StudyConfig => {
  const patientConfigV1 = getConfigurationByCode<PatientConfigV1>(entries, 'PATIENT_CONFIG');
  const rawFeatureConfig = getConfigurationByCode<RawCspFeatureConfigV2>(entries, 'FEATURE_TOGGLE_CONFIG');
  const patientStatusConfigV1 = getConfigurationByCode<PatientStatusConfigV1>(entries, 'PATIENT_STATUS_CONFIG');
  const metricsConfig = getConfigurationByCode<StudyMetricsConfigV1>(entries, 'METRICS_CONFIG');
  const notificationJobConfigV1 = getConfigurationByCode<NotificationJobConfigV1>(entries, 'NOTIFICATION_JOB_CONFIG');
  const deviceConfigV2 = getConfigurationByCode<DeviceConfigV2>(entries, 'DEVICE_CONFIG');
  const complianceConfigV1 = getConfigurationByCode<ComplianceConfigV1>(entries, 'COMPLIANCE_CONFIG');
  const reportsConfigV1 = getConfigurationByCode<ComplianceConfigV1>(entries, 'REPORTS_CONFIG');
  const requestTagConfigV1 = getConfigurationByCode<RequestTagsStudyConfigV1>(entries, 'REQUEST_TAG_CONFIG');
  const rpmConfigV1 = getConfigurationByCode<RpmConfigV1>(entries, 'RPM_CONFIG');
  const rawPatientCopingTipConfigV1 = getConfigurationByCode<Maybe<RawPatientCopingTipConfigV1>>(
    entries,
    'PATIENT_COPING_TIP_CONFIG',
  );
  const recommendedResourcesConfigV1 = getConfigurationByCode<Maybe<RecommendedResourcesConfigV1>>(
    entries,
    'RECOMMENDED_RESOURCES_CONFIG',
  );
  const assessmentConsequenceActivityCardConfigV1 = getConfigurationByCode<
    Maybe<RawAssessmentConsequenceActivityCardConfigV1>
  >(entries, 'ASSESSMENT_CONSEQUENCE_ACTIVITY_CARD');

  const featureConfig = FeatureToggleFactory.from(rawFeatureConfig);
  const patientConfig = PatientConfigServiceV1.toPatientConfig(patientConfigV1);
  const patientStatusMappings = PatientStatusConfigServiceV1.toPatientStatusConfiguration(patientStatusConfigV1);
  StudyMetricsConfigService.validateMetricsConfig(metricsConfig);
  const [healthEventNotificationJobConfigs] =
    NotificationJobConfigServiceV1.mapNotificationJobConfig(notificationJobConfigV1);
  const deviceConfig = DeviceConfigServiceV1.toDeviceConfig(deviceConfigV2);
  const complianceConfig = ComplianceConfigService.toComplianceConfig(complianceConfigV1);
  const reportsConfig = ReportsConfigService.toReportsConfig(reportsConfigV1);
  const requestTagsConfig = RequestTagsStudyConfigService.toRequestTagsStudyConfig(requestTagConfigV1);
  const rpmConfig = RpmConfigService.toRpmConfig(rpmConfigV1);
  const patientCopingTipConfig = PatientCopingTipService.toPatientCopingTipConfig(rawPatientCopingTipConfigV1);
  const recommendedResourcesConfig =
    RecommendedResourcesConfigService.toRecommendedResourcesConfig(recommendedResourcesConfigV1);
  const assessmentConsequenceActivityCardConfig =
    AssessmentConsequenceActivityCardService.toAssessmentConsequenceActivityCardConfig(
      assessmentConsequenceActivityCardConfigV1,
    );

  return {
    featureConfig,
    metricsConfig,
    questionnaireConfig: patientConfig.questionnaire,
    patientValidation: patientConfig.validation,
    periodConfig: patientConfig.period,
    rescreenConfig: patientConfig.rescreen,
    visitConfig: patientConfig.visit,
    hbsConfig: patientConfig.hbs,
    authenticationConfig: patientConfig.authentication,
    patientStatusMappings,
    healthEventNotificationJobConfigs,
    deviceConfig,
    complianceConfig,
    reportsConfig,
    requestTagsConfig,
    rpmConfig,
    patientCopingTipConfig,
    recommendedResourcesConfig,
    assessmentConsequenceActivityCardConfig,
  };
};

const fetchStudyConfig = async (apiOptions?: ApiOptions): Promise<StudyConfig> => {
  const configs = await StudyConfigRestServiceV2.getConfigs(toRestOptions(apiOptions));
  return toStudyConfig(configs);
};

const refreshStudyConfig = async (apiOptions?: ApiOptions): Promise<StudyConfig> => {
  currentConfiguration = await fetchStudyConfig(toRestOptions(apiOptions));
  return currentConfiguration;
};

export const StudyConfigService = {
  getStudyConfig,
  setStudyConfig,
  refreshStudyConfig,
  fetchStudyConfig,
  toStudyConfig,
};
