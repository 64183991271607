import { RawCspFeatureConfigV2, RawCspFeatureConfigV2Schema } from '@csp/config-schemas';
import { JsonValidationService } from '@csp/csp-common-util';

const validateFeatureConfigV2 = (config: RawCspFeatureConfigV2): void => {
  JsonValidationService.validateJsonNonStrict('Csp FeatureToggle config V2', RawCspFeatureConfigV2Schema, config);
};

export const FeatureToggleValidationService = {
  validateFeatureConfigV2,
};
