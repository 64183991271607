import { RestOptions } from '@csp/csp-common-model';
import { largePage, Page } from '@csp/dmdp-api-common-dto';
import { ConfigurationEntriesV1, ConfigurationEntryQueryV1 } from '@csp/dmdp-api-configuration-dto';
import { applicationpodUrl } from '../../dmdp-config/dmdpConfig';

const configurationUrl = (): string => `${applicationpodUrl()}/configuration/v1`;

const query = async (
  { axios, signal }: RestOptions,
  query: ConfigurationEntryQueryV1,
  page: Page = largePage(),
): Promise<ConfigurationEntriesV1> => {
  const resp = await axios.post(`${configurationUrl()}/entries:query`, query, {
    params: page,
    signal,
  });
  return resp.data;
};

export const ConfigurationRestServiceV1 = { query };
