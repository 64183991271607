import styled from '@emotion/styled';
import { Alert as MuiAlert, AlertTitle } from '@mui/material';
import { forwardRef, useEffect, useState } from 'react';
import { compose, sizing, spacing } from '@mui/system';
import { OnClick } from '../../../common/model/EventModels';
import { BrandedIcon } from '../../brandedIcon/BrandedIcon';
import { Markdown } from '../../markdown/Markdown';
import { AlertProps } from './model/AlertProps';
import { AlertMapper } from './mapper/AlertMapper';
import { AlertCloseButton } from './AlertCloseButton';

const AlertStyled = styled(MuiAlert, {
  shouldForwardProp: prop => prop !== 'maxWidth',
})<AlertProps>(compose(spacing, sizing));

export const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  {
    message,
    markdown,
    severity = 'info',
    color,
    action,
    onClose,
    title,
    fullWidth,
    variant = 'standard',
    iconProps,
    ...props
  },
  ref,
) {
  useEffect(() => {
    setShowAlert(!!message);
  }, [message]);

  const [showAlert, setShowAlert] = useState<boolean>(!!message);
  const defaultOnClose: OnClick = (): void => setShowAlert(false);

  if (message) {
    const iconName = AlertMapper.toIconName(severity, variant);
    const markDownColor = AlertMapper.toMarkdownColor(variant, severity);

    return showAlert ? (
      <AlertStyled
        ref={ref}
        sx={AlertMapper.toSx(fullWidth, action, onClose, title, variant)}
        variant={variant}
        severity={severity}
        color={color}
        action={onClose ? <AlertCloseButton defaultOnClose={defaultOnClose} onClose={onClose} /> : action}
        icon={<BrandedIcon color={severity} name={iconName} {...iconProps} />}
        {...props}
      >
        {title && <AlertTitle>{title}</AlertTitle>}
        {markdown && typeof message === 'string' ? (
          <Markdown paragraph={false} color={markDownColor}>
            {message}
          </Markdown>
        ) : (
          message
        )}
      </AlertStyled>
    ) : null;
  } else {
    return null;
  }
});
