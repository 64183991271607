import { zeroZonedDateTime } from '@csp/csp-common-date-util';
import { CloseoutTrackerStatusType, StudyOrgStatuses } from '@csp/csp-common-model';

const emptyStudyStatuses: Readonly<StudyOrgStatuses> = {
  closeoutTrackerStatus: {
    status: CloseoutTrackerStatusType.UNKNOWN,
    timestamp: zeroZonedDateTime,
  },
};

export const StudyStatusesFactory = {
  emptyStudyStatuses,
};
