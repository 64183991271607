import { FC } from 'react';
import { OnClick } from '../../../common/model/EventModels';
import { IconButton } from '../../buttons/IconButton';
import { CloseIcon } from '../../mui-icons/MuiIcons';

type Props = {
  defaultOnClose: OnClick;
  onClose?: OnClick | 'default';
};

export const AlertCloseButton: FC<Props> = ({ defaultOnClose, onClose }) => (
  <IconButton
    aria-label="close"
    color="inherit"
    size="small"
    onClick={onClose === 'default' ? defaultOnClose : onClose}
  >
    <CloseIcon fontSize="inherit" />
  </IconButton>
);
