import { ZonedDateTimeFormatter, ZonedDateTimeFactory } from '@csp/csp-common-date-util';
import { Alert, LabelWithMarkdown, OnClick, TemporaryDrawer } from '@csp/csp-web-ui';
import { FC } from 'react';
import { StaticContentService } from '../../../common/service/StaticContentService';

type Props = {
  open: boolean;
  handleClose: OnClick;
};

export const TimezoneDrawerContainer: FC<Props> = props => {
  const { drawer } = StaticContentService.getPublicEntry('webHcpTimezone');
  const timezoneDisplayString = ZonedDateTimeFormatter.formatTimezoneDisplayString(ZonedDateTimeFactory.now());

  return (
    <TemporaryDrawer header={drawer.header.title.text} {...props}>
      <Alert
        mb={3}
        variant="outlined"
        style={{ fontWeight: 700 }}
        iconProps={{ name: 'info', color: 'black', size: 'large' }}
        message={timezoneDisplayString}
      />

      <LabelWithMarkdown
        markdownSx={{ mt: 3 }}
        label={drawer.header.subTitle.text}
        markdown={drawer.body.description.markdown}
      />
    </TemporaryDrawer>
  );
};
