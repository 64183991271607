import { notIncludes } from '@csp/csp-common-util';
import styled from '@emotion/styled';
import MuiSkeleton from '@mui/material/Skeleton';
import { spacing } from '@mui/system';
import { SkeletonProps } from './model/SkeletonProps';

export const Skeleton = styled(MuiSkeleton, { shouldForwardProp: notIncludes(['aspectRatio']) })<SkeletonProps>`
  ${spacing}
  ${(props): string => (props.aspectRatio ? `aspect-ratio: ${props.aspectRatio};` : '')}
`;
