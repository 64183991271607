import styled from '@emotion/styled';
import { spacing } from '@mui/system';
import { FC, isValidElement, memo, useMemo } from 'react';
import { Dash } from '../dataDisplay/dash/Dash';
import { Box } from '../layout/box/Box';
import { LabelWithTextProps } from './model/LabelWithTextProps';
import { Body1, Body2, Caption1, Label2 } from './TextStyles';

const StyledDiv = styled.div`
  ${spacing}
`;

export const LabelWithText: FC<LabelWithTextProps> = memo(
  ({
    label,
    text,
    children,
    large,
    labelProps,
    textProps,
    dash = false,
    displayLineBreaks = false,
    body = 'body1',
    ...props
  }) => {
    const Label = large ? Caption1 : Label2;
    const Body = body === 'body1' ? Body1 : Body2;

    const content = useMemo(() => {
      if (children) {
        return <Box mt={large ? 0.5 : 0}>{children}</Box>;
      } else if (isValidElement(text)) {
        return <Box mt={large ? 0.5 : 0}>{text}</Box>;
      } else {
        return (
          <Body
            color={large ? 'textSecondary' : 'textPrimary'}
            whiteSpace={displayLineBreaks ? 'pre-wrap' : undefined}
            mt={large ? 0.5 : 0}
            {...textProps}
          >
            {text || (dash && <Dash />)}
          </Body>
        );
      }
    }, [Body, children, dash, displayLineBreaks, large, text, textProps]);

    return (
      <StyledDiv {...props}>
        <Label fontWeight={700} color={large ? 'textPrimary' : 'textSecondary'} {...labelProps}>
          {label}
        </Label>
        {content}
      </StyledDiv>
    );
  },
);

LabelWithText.displayName = 'LabelWithText';
