import { nowToZonedDateTimeCurrentZone, zeroZonedDateTime } from '@csp/csp-common-date-util';
import {
  CustomStatusIn,
  CustomStatusMutation,
  PatientAppStatusType,
  PatientFinalContactStatusType,
  PatientMedicationStatusType,
  PatientStatus,
  PatientStatuses,
  PatientStatusType,
  PatientStudyStatusType,
  PatientVitalStatusType,
  ZonedDateTime,
} from '@csp/csp-common-model';

const createPatientStatuses = (
  appStatus: PatientAppStatusType.INVITED | PatientAppStatusType.INACTIVE,
  studyStatus: PatientStudyStatusType = PatientStudyStatusType.UNKNOWN,
  medicationStatus: PatientMedicationStatusType = PatientMedicationStatusType.UNKNOWN,
  vitalStatus: PatientVitalStatusType = PatientVitalStatusType.UNKNOWN,
  finalContactStatus: PatientFinalContactStatusType = PatientFinalContactStatusType.UNKNOWN,
  enrollmentDateTime: ZonedDateTime = nowToZonedDateTimeCurrentZone(),
): PatientStatuses => {
  const now = nowToZonedDateTimeCurrentZone();

  return {
    rtsmStatus: {
      status: 'UNKNOWN',
      timestamp: now,
    },
    studyStatus: {
      status: studyStatus,
      timestamp: enrollmentDateTime,
    },
    medicationStatus: {
      status: medicationStatus,
      timestamp: now,
    },
    appStatus: {
      status: appStatus,
      timestamp: now,
    },
    vitalStatus: {
      status: vitalStatus,
      timestamp: now,
    },
    finalContactStatus: {
      status: finalContactStatus,
      timestamp: now,
    },
  };
};

const emptyPatientStatuses: Readonly<PatientStatuses> = {
  rtsmStatus: {
    status: 'UNKNOWN',
    timestamp: zeroZonedDateTime,
  },
  studyStatus: {
    status: PatientStudyStatusType.UNKNOWN,
    timestamp: zeroZonedDateTime,
  },
  medicationStatus: {
    status: PatientMedicationStatusType.UNKNOWN,
    timestamp: zeroZonedDateTime,
  },
  appStatus: {
    status: PatientAppStatusType.UNKNOWN,
    timestamp: zeroZonedDateTime,
  },
  vitalStatus: {
    status: PatientVitalStatusType.UNKNOWN,
    timestamp: zeroZonedDateTime,
  },
  finalContactStatus: {
    status: PatientFinalContactStatusType.UNKNOWN,
    timestamp: zeroZonedDateTime,
  },
};

const toPatientStatus = <T>(customStatusIn: CustomStatusIn): PatientStatus<T> => ({
  status: customStatusIn.value as T,
  timestamp: customStatusIn.timestamp ?? zeroZonedDateTime,
});

/**
 * We use a custom status mutation to update patient statuses in Boost. There is currently (R9.0)
 * no way for us to reliably get the updated patient object back since the endpoint does not return
 * the updated entity. If we try to fetch the patient object after update we might hit an db instance
 * that hasn't replicated the updates yet (this is done asynchronously).
 *
 * As a workaround, we can eagerly create the presumed PatientStatuses locally based on the same mutation
 * that we use to update the patient.
 */
const fromCustomStatusMutation = (mutation: CustomStatusMutation): PatientStatuses => {
  const studyStatusIn = mutation.getCurrentStatusValueAfterUpdate(PatientStatusType.STUDY);
  const medicationStatusIn = mutation.getCurrentStatusValueAfterUpdate(PatientStatusType.MEDICATION);
  const appStatusIn = mutation.getCurrentStatusValueAfterUpdate(PatientStatusType.APP);
  const rtsmStatusIn = mutation.getCurrentStatusValueAfterUpdate(PatientStatusType.RTSM);
  const vitalStatusIn = mutation.getCurrentStatusValueAfterUpdate(PatientStatusType.VITAL);
  const finalContactStatusIn = mutation.getCurrentStatusValueAfterUpdate(PatientStatusType.FINAL_CONTACT);

  return {
    studyStatus: studyStatusIn ? toPatientStatus(studyStatusIn) : emptyPatientStatuses.studyStatus,
    medicationStatus: medicationStatusIn ? toPatientStatus(medicationStatusIn) : emptyPatientStatuses.medicationStatus,
    appStatus: appStatusIn ? toPatientStatus(appStatusIn) : emptyPatientStatuses.appStatus,
    finalContactStatus: finalContactStatusIn
      ? toPatientStatus(finalContactStatusIn)
      : emptyPatientStatuses.finalContactStatus,
    vitalStatus: vitalStatusIn ? toPatientStatus(vitalStatusIn) : emptyPatientStatuses.vitalStatus,
    rtsmStatus: rtsmStatusIn ? toPatientStatus(rtsmStatusIn) : emptyPatientStatuses.rtsmStatus,
  };
};

export const PatientStatusesFactory = {
  createPatientStatuses,
  fromCustomStatusMutation,
  emptyPatientStatuses,
};
