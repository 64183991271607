import { createTheme, responsiveFontSizes, Theme, ThemeProvider } from '@mui/material/styles';
import createPalette from '@mui/material/styles/createPalette';
import { makeStyles } from '@mui/styles';
import { WithTheme } from '@mui/styles/withTheme';
import { createSpacing } from '@mui/system';
import { paletteOptions } from './palette';
import { shadows } from './shadows';
import { typography } from './typography';
import { zIndex } from './zIndex';

const spacing = createSpacing((factor: number) => `${0.5 * factor}rem`);
const palette = createPalette(paletteOptions);

const theme: Theme = createTheme({
  spacing,
  typography,
  palette,
  shadows,
  zIndex,
  components: {
    MuiCardHeader: {
      styleOverrides: {
        action: {
          marginTop: 0,
          marginRight: 0,
        },
        title: {
          fontSize: '0.875rem',
          fontWeight: 700,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-root': {
            '.MuiAutocomplete-endAdornment': {
              '.MuiButtonBase-root': {
                '&.Mui-disabled .MuiSvgIcon-root': {
                  fill: palette.grey[400],
                },
                '.MuiSvgIcon-root': {
                  fill: palette.common.black,
                },
              },
            },
          },
        },
        groupUl: {
          '.MuiAutocomplete-option': {
            paddingLeft: spacing(0.5),
          },
        },
        option: {
          '&.MuiAutocomplete-option.Mui-focused': {
            backgroundColor: palette.grey[100],
          },
          '&.MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: palette.info.light,
            '&.Mui-focused': {
              backgroundColor: palette.info.light,
            },
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          '&.MuiAlert-outlined': {
            border: `1px solid ${palette.grey[400]}`,
          },
          padding: spacing(0.75, 2, 0.75, 2),
          alignItems: 'center',
        },
        standardSuccess: {
          backgroundColor: palette.success.light,
          color: palette.success.dark,
        },
        standardInfo: {
          backgroundColor: palette.info.light,
          color: palette.info.dark,
        },
        standardWarning: {
          backgroundColor: palette.warning.light,
          color: palette.warning.dark,
        },
        standardError: {
          backgroundColor: palette.error.light,
          color: palette.error.dark,
        },
        action: {
          padding: spacing(1),
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: `${typography.body2?.fontSize || '0.875rem'}`,
          color: palette.common.black,
        },
        root: {
          marginRight: 0,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderTop: `0.8px solid ${palette.grey[400]}`,
          '&:before': {
            backgroundColor: palette.grey[400],
          },
          '&.Mui-expanded': {
            margin: 0,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: '4rem',
        },
        content: {
          '&.Mui-expanded': {
            margin: spacing(1.5, 0),
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          '&.MuiTab-root': {
            '&.Mui-selected': {
              color: palette.common.black,
            },
          },
          '&.MuiCheckbox-root': {
            '&.Mui-disabled': {
              color: palette.icon.disabled,
            },
          },
          '&.MuiRadio-root': {
            '&.Mui-disabled': {
              color: palette.icon.disabled,
            },
          },
          '&.MuiMenuItem-root': {
            '&.Mui-disabled': {
              backgroundColor: palette.grey[200],
            },
            '&.Mui-selected': {
              backgroundColor: palette.info.light,
              '&.Mui-selected:hover': {
                backgroundColor: palette.grey[50],
              },
              '&.Mui-selected:active': {
                backgroundColor: palette.info.light,
              },
            },
            '&.MuiMenuItem-divider': {
              borderBottomColor: palette.grey[300],
            },
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: palette.grey[100],
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          lineHeight: '100%',
          textTransform: 'none',
          height: 42,
          padding: '2px 20px 0 20px',
        },
        text: {
          borderRadius: 30,
        },
        contained: {
          borderRadius: 30,
          backgroundColor: palette.primary.main,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          '&$disabled': {
            backgroundColor: palette.grey[200],
          },
        },
        outlined: {
          borderRadius: 30,
        },

        sizeLarge: {
          padding: '0 60px',
          height: 52,
        },
        sizeSmall: {
          height: 32,
          padding: '2px 20px 0 20px',
        },
        fullWidth: {
          paddingLeft: 'inherit',
          paddingRight: 'inherit',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: palette.icon.main,
          '&:hover': {
            color: palette.icon.main,
            boxShadow: 'none',
            backgroundColor: 'transparent',
          },
          '&$checked': {
            color: palette.icon.dark,
          },
          '&$checked&:hover': {
            color: palette.common.black,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: palette.icon.main,
          '&:hover': {
            color: palette.icon.main,
            boxShadow: 'none',
            backgroundColor: 'transparent',
          },
          '&.Mui-checked': {
            color: palette.icon.dark,
          },
          '&$checked&:hover': {
            color: palette.common.black,
          },
        },
        colorSecondary: {
          '&.Mui-checked': {
            color: palette.icon.dark,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: '0.75rem',
        },
        label: {
          padding: spacing(0, 1.25, 0, 1.25),
        },
        filled: {
          color: palette.common.white,
        },
        outlined: {
          border: `1px solid ${palette.grey[700]}`,
          color: palette.common.black,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: palette.info.main,
          },
          '&.Mui-error': {
            color: palette.error.main,
          },
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: palette.icon.main,
          boxShadow: shadows[6],
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '0.75rem',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.MuiMenuItem-gutters': {
            minHeight: 48,
          },
          '&:hover': {
            backgroundColor: palette.background.default,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          textDecoration: 'none',
          '&:active': {
            color: palette.primary.dark,
          },
        },
        underlineHover: {
          textDecoration: 'none',
          fontSize: '0.875rem',
        },
      },
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          '&.MuiListItemAvatar-alignItemsFlexStart': {
            marginTop: 2,
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          marginBottom: 0,
          marginTop: 0,
          '&.MuiListItemText-multiline': {
            marginTop: 0,
            marginBottom: 0,
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        button: {
          '&:hover': {
            backgroundColor: palette.grey[200],
          },
        },
        root: {
          '&$selected': {
            fontWeight: 500,
            '&:hover': {
              backgroundColor: palette.grey[200],
            },
            '&.MuiTypography-root': {
              fontWeight: 500,
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        alignItemsFlexStart: {
          marginTop: 0,
        },
        root: {
          minWidth: 36,
        },
      },
    },
    MuiListItemSecondaryAction: {
      styleOverrides: {
        root: {
          right: 0,
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          color: palette.common.black,
          fontSize: '0.875rem',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
          '&.MuiMenu-list': {
            maxHeight: 400,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: palette.grey[300],
          borderColor: palette.grey[300],
        },
      },
    },
    // https://stackoverflow.com/questions/53764626/how-to-change-outline-color-of-material-ui-react-input-component
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          position: 'relative',
          backgroundColor: palette.common.white,
          paddingRight: spacing(2),
          '& $notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
          },
          '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.info.main,
            borderWidth: 2,
          },
          '&.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.error.main,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.875rem',
          fontWeight: 400,
          padding: spacing(1),
          backgroundColor: palette.common.black,
        },
        arrow: {
          color: palette.common.black,
          '&::before': {
            backgroundColor: palette.common.black,
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: '3.4375rem',
          height: '2.375rem',
          padding: spacing(1.125),
          marginRight: spacing(0.75),
        },
        sizeSmall: {
          width: '2.5rem',
          height: '1.5rem',
          padding: spacing(0.5),
          marginRight: spacing(0.5),
          switchBase: {
            '&$checked': {
              transform: 'translateX(1.0625rem)',
            },
          },
        },
        switchBase: {
          '&$checked': {
            transform: 'translateX(1.0625rem)',
          },
        },
        colorPrimary: {
          color: palette.common.white,
          '&$disabled': {
            '& + $track': {
              backgroundColor: palette.grey[200],
              opacity: 1,
            },
          },
          '&$checked': {
            color: palette.primary.main,
            '& + $track': {
              backgroundColor: palette.primary.light,
              border: 'none',
            },
          },
        },
        thumb: {
          boxShadow: shadows[3],
        },
        track: {
          borderRadius: 26,
          backgroundColor: palette.grey[400],
          opacity: 1,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          display: 'flex',
          alignItems: 'center',
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
        icon: {
          '&.Mui-disabled': {
            fill: palette.grey[400],
          },
        },
        iconOutlined: {
          right: '0.5rem',
          fill: palette.common.black,
          '&$disabled': {
            fill: palette.grey[600],
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          lineHeight: 1,
          padding: `${spacing(1, 2)}`,
        },
        head: {
          fontWeight: 'bold',
          fontSize: '1rem',
          padding: `${spacing(3, 2)}`,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: typography.body1?.fontSize,
          fontWeight: 500,
          '&.Mui-sub-tab': {
            minHeight: 48,
          },
        },
        labelIcon: {
          minHeight: 58,
        },
        textColorInherit: {
          color: palette.text.primary,
          '&$selected': {
            color: palette.common.black,
          },
          '&:hover': {
            color: palette.common.black,
          },
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: palette.grey[100],
            input: {
              color: palette.grey[600],
            },
          },
        },
      },
    },
    // Because of wrapping issues:
    MuiInputLabel: {
      styleOverrides: {
        root: {
          paddingRight: spacing(2.5),
          '&.Mui-disabled': {
            color: palette.grey[600],
          },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        sx: { '& *:focus': { outline: 'none' } },
        elevation: 2,
      },
    },

    MuiTabs: {
      defaultProps: {
        indicatorColor: 'primary',
      },
    },
  },
});
const responsiveOptions = { disableAlign: true };
const responsiveTheme = responsiveFontSizes(theme, responsiveOptions);

export { ThemeProvider, responsiveTheme as theme, makeStyles };
export type { WithTheme, Theme };
