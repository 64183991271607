import { Callback, CallbackAsync } from '@csp/csp-common-model';
import { useEffect } from 'react';

export const useOnMount = (callback: Callback | CallbackAsync, cleanup?: Callback): void => {
  useEffect(() => {
    (async (): Promise<void> => await callback())();
    return (): void => cleanup && cleanup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
