import { CspConfigService } from '@csp/csp-api-client';
import { ContentConfigService, ContentConfigV1 } from '@csp/csp-common-content';
import { ZoomConfig, ZoomConfigService } from '@csp/csp-common-zoom';
import { DmdpConfigService, PodsConfig } from '@csp/dmdp-api-client';
import { CognitoConfig } from '../../auth/cognito/model/CognitoConfig';
import { CognitoService } from '../../auth/cognito/service/CognitoService';
import { CommonStoreState } from '../redux/ducks';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CommonSelector = (state: any) => CommonStoreState;

export interface InitializationConfig {
  getCognitoConfig: () => Promise<CognitoConfig>;
  getDmdpConfig: () => Promise<PodsConfig>;
  getContentConfig: () => Promise<ContentConfigV1>;
  commonSelector: CommonSelector;
  getZoomConfig: () => Promise<ZoomConfig>;
}

export interface InitializationState {
  commonSelector: CommonSelector;
  hasBeenInitialized: boolean;
}

const state: InitializationState = {
  commonSelector: _ => {
    console.warn(
      'No common selector has been provided before usage. Make sure InitializationService.init has been run before accessing common things in redux',
    );

    return { cms: { ids: [], entities: {} }, locale: { langCode: 'en', countryCode: 'GB' } };
  },
  hasBeenInitialized: false,
};

const init = async ({
  getZoomConfig,
  getDmdpConfig,
  getCognitoConfig,
  getContentConfig,
  commonSelector,
}: InitializationConfig): Promise<void> => {
  const [zoomConfig, dmdpConfig, cognitoConfig, contentConfig] = await Promise.all([
    getZoomConfig(),
    getDmdpConfig(),
    getCognitoConfig(),
    getContentConfig(),
  ]);

  await CognitoService.init(cognitoConfig);
  ZoomConfigService.set(zoomConfig);

  DmdpConfigService.setDmdpBaseUrl(dmdpConfig.baseUrl);
  DmdpConfigService.setDmdpPodConfigs(dmdpConfig.pods);

  CspConfigService.setCspPodConfigs({
    csppod: { baseUrl: dmdpConfig.pods.csppod?.baseUrl ?? '' },
    contentdeliverypod: { baseUrl: dmdpConfig.pods.contentdeliverypod?.baseUrl ?? '' },
  });

  ContentConfigService.set(contentConfig);

  state.commonSelector = commonSelector;
  state.hasBeenInitialized = true;
};

const commonSelector: CommonSelector = appReduxState => state.commonSelector(appReduxState);
const hasBeenInitialized: () => boolean = () => state.hasBeenInitialized;

export const InitializationService = {
  init,
  commonSelector,
  hasBeenInitialized,
};
