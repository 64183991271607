import { LoadingOverlay, theme } from '@csp/csp-web-ui';
import { NotificationsContextProvider } from '@csp/web-common';
import React, { FC, ReactNode } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { CspPath } from './CspPath';
import { environment } from './environments/environment.prod';
import { AuthenticatedRoute, UnauthenticatedRoute } from './feature/auth/utils/AuthRoutes';
import { FaultHandlerContainer } from './feature/fault/container/FaultHandlerContainer';
import { MainNavPages } from './feature/navigation/page/MainNavPages';
import { StudyNavPages } from './feature/navigation/page/StudyNavPages';
import { useFetchStudiesAndLogin } from './feature/studies/hook/useFetchAndSelectStudy';
import { useUpdateTelemetryScope } from './system/hooks/useUpdateTelemetryScope';
// TODO:[Saman M]: remove comment out codes when @zoomus/websdk package is installed
//import { VideoContainer } from './feature/video-meeting/zoom/container/VideoContainer';
import { Fault404Page } from './feature/fault/page/Fault404Page';
import { AutomaticLogout } from './feature/security/components/AutomaticLogout';
import { ZoomContextProvider } from './feature/video-meeting/zoom/context/ZoomContextProvider';
import { BioSamplingRoutes } from './feature/navigation/page/BioSamplingRoutes';
import { useFetchPubContentOnMount } from './system/hooks/useFetchPubContentOnMount';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const { pathname: BASENAME } = new URL(environment.baseUrl, window.location.origin);

const LoginPage = React.lazy(() => import(/* webpackPrefetch: true */ './feature/auth/page/LoginPage'));
const CognitoCallbackContainer = React.lazy(
  () => import(/* webpackPrefetch: true */ './feature/auth/cognito-invite/container/CognitoCallbackContainer'),
);
const CognitoContainer = React.lazy(
  () => import(/* webpackPrefetch: true */ './feature/auth/cognito/container/CognitoContainer'),
);
const CognitoInviteContainer = React.lazy(
  () => import(/* webpackPrefetch: true */ './feature/auth/cognito-invite/container/CognitoInviteContainer'),
);
const CloseoutTrackerNotificationContainer = React.lazy(
  () => import(/* webpackPrefetch: true */ './feature/account/container/CloseoutTrackerNotificationContainer'),
);
const LandingPage = React.lazy(() => import(/* webpackPrefetch: true */ './feature/landing-page/page/LandingPage'));
const ConsentsPage = React.lazy(() => import(/* webpackPrefetch: true */ './feature/consent/page/ConsentsPage'));
const ConfirmAccountContainer = React.lazy(
  () => import(/* webpackPrefetch: true */ './feature/account/container/ConfirmAccountContainer'),
);
const StudyOverviewContainer = React.lazy(
  () => import(/* webpackPrefetch: true */ './feature/study/container/StudyOverviewContainer'),
);
const TeamMembersContainer = React.lazy(
  () => import(/* webpackPrefetch: true */ './feature/team-member/team-member-list/container/TeamMembersContainer'),
);
const TeamMemberRoutes = React.lazy(
  () => import(/* webpackPrefetch: true */ './feature/navigation/page/TeamMemberRoutes'),
);
const ReportsRoutes = React.lazy(() => import(/* webpackPrefetch: true */ './feature/navigation/page/ReportsRoutes'));
const StudySubjectListPage = React.lazy(
  () => import(/* webpackPrefetch: true */ './feature/patient-management/add-patient/page/StudySubjectListPage'),
);
const PatientNavPages = React.lazy(
  () => import(/* webpackPrefetch: true */ './feature/navigation/page/PatientNavPages'),
);
const PatientListRoutes = React.lazy(
  () => import(/* webpackPrefetch: true */ './feature/navigation/page/PatientListRoutes'),
);
const SitesContainer = React.lazy(
  () => import(/* webpackPrefetch: true */ './feature/site/site-list/container/SitesContainer'),
);
const StudiesPage = React.lazy(() => import(/* webpackPrefetch: true */ './feature/studies/page/StudiesPage'));
const StudyDocumentsPage = React.lazy(
  () => import(/* webpackPrefetch: true */ './feature/study-documents/page/StudyDocumentsPage'),
);
const DataChangeDetailsNavPage = React.lazy(
  () => import(/* webpackPrefetch: true */ './feature/data-change/data-change-details/page/DataChangeDetailsNavPage'),
);
const VideoTrainingPage = React.lazy(
  () => import(/* webpackPrefetch: true */ './feature/video-training/page/VideoTrainingPage'),
);
const VideoPlayerPage = React.lazy(
  () => import(/* webpackPrefetch: true */ './feature/video-player/page/VideoPlayerPage'),
);

const LoadingComponent: FC = () => <LoadingOverlay isLoading bgcolor={theme.palette.transparent.default} />;

const LazyElement: FC<{ element: ReactNode }> = ({ element }) => (
  <React.Suspense fallback={<LoadingComponent />}>{element}</React.Suspense>
);

export const CspRoutes: FC = () => {
  useFetchStudiesAndLogin();
  useUpdateTelemetryScope();
  const { isPubContentInitialized } = useFetchPubContentOnMount();

  if (!isPubContentInitialized) {
    return <LoadingComponent />;
  } else {
    return (
      <Router basename={BASENAME}>
        <NotificationsContextProvider>
          <ZoomContextProvider>
            <AutomaticLogout />
            <FaultHandlerContainer>
              <Routes>
                {/* Unauthenticated routes */}
                <Route element={<UnauthenticatedRoute />}>
                  <Route path={CspPath.LANDING} element={<LazyElement element={<LandingPage />} />} />
                  <Route path={CspPath.LOGIN} element={<LazyElement element={<LoginPage />} />} />
                  <Route path={CspPath.UNIFY_LOGIN} element={<LazyElement element={<CognitoContainer />} />} />
                  <Route path={CspPath.COGNITO_INVITE} element={<LazyElement element={<CognitoInviteContainer />} />} />
                </Route>

                {/* Authenticated routes */}
                <Route element={<AuthenticatedRoute />}>
                  <Route path={CspPath.CONSENT} element={<LazyElement element={<ConsentsPage />} />} />
                  <Route path={CspPath.VIEW_CONSENT} element={<LazyElement element={<ConsentsPage />} />} />
                  <Route
                    path={CspPath.CONFIRM_ACCOUNT}
                    element={<LazyElement element={<ConfirmAccountContainer />} />}
                  />
                  <Route
                    path={CspPath.CLOSEOUT_TRACKER_NOTIFICATION}
                    element={<LazyElement element={<CloseoutTrackerNotificationContainer />} />}
                  />
                  {/* Main navigation, level 1 */}
                  <Route element={<StudyNavPages />}>
                    <Route element={<MainNavPages />}>
                      <Route
                        path={CspPath.STUDY_OVERVIEW}
                        element={<LazyElement element={<StudyOverviewContainer />} />}
                      />
                      <Route
                        path={`${CspPath.PATIENT_LIST}/*`}
                        element={<LazyElement element={<PatientListRoutes />} />}
                      />
                      <Route
                        path={CspPath.TEAM_MEMBER_LIST}
                        element={<LazyElement element={<TeamMembersContainer />} />}
                      />
                      <Route path={CspPath.SITES} element={<LazyElement element={<SitesContainer />} />} />
                      <Route path={`${CspPath.REPORTS}/*`} element={<LazyElement element={<ReportsRoutes />} />} />
                    </Route>
                    <Route path={CspPath.VIDEO_TRAINING} element={<LazyElement element={<VideoTrainingPage />} />} />
                    <Route
                      path={CspPath.PATIENT_SUBJECT_LIST}
                      element={<LazyElement element={<StudySubjectListPage />} />}
                    />
                    <Route path={`${CspPath.HBS}/*`} element={<LazyElement element={<BioSamplingRoutes />} />} />
                    {/* Patient, level 2 */}
                    <Route path={`${CspPath.PATIENT_NAV}/*`} element={<LazyElement element={<PatientNavPages />} />} />
                    {/* Teams, level 2 */}
                    <Route
                      path={`${CspPath.TEAM_MEMBER_NAV}/*`}
                      element={<LazyElement element={<TeamMemberRoutes />} />}
                    />
                    {/* Study, level 2 */}
                    <Route path={CspPath.STUDY_DOCUMENTS} element={<LazyElement element={<StudyDocumentsPage />} />} />
                    {/* Data Change Details, level 2 */}
                    <Route
                      path={CspPath.REPORTS_DATA_CHANGE_DETAILS}
                      element={<LazyElement element={<DataChangeDetailsNavPage />} />}
                    />
                  </Route>
                  <Route path={CspPath.STUDIES} element={<LazyElement element={<StudiesPage />} />} />
                  <Route path={CspPath.VIDEO_PLAYER} element={<LazyElement element={<VideoPlayerPage />} />} />
                </Route>

                {/* General routes */}
                <Route
                  path={CspPath.COGNITO_CALLBACK}
                  element={<LazyElement element={<CognitoCallbackContainer />} />}
                />
                <Route path={CspPath.ERROR} element={<LazyElement element={<Fault404Page />} />} />
                <Route path={CspPath.FALLBACK} element={<Fault404Page />} />
              </Routes>
            </FaultHandlerContainer>
            {/* <VideoContainer />*/}
          </ZoomContextProvider>
        </NotificationsContextProvider>
      </Router>
    );
  }
};
