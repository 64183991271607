import { bindActionCreators, Middleware } from '@reduxjs/toolkit';
import { InitializationService } from '../../../system/service/InitializationService';
import { cmsActions } from './cmsReducer';
import { getCmsState, getContentForQuery } from './cmsSelectors';

type CmsMiddlewareOptions = {
  preRequest?: () => Promise<void>;
};

export const cmsMiddleware =
  (options: CmsMiddlewareOptions): Middleware =>
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  store => {
    const actions = bindActionCreators(cmsActions, store.dispatch);
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    return next => action => {
      next(action);

      if (cmsActions.getCmsContent.match(action)) {
        // TODO: remove async stuff when there is no need for preRequest (to active users)
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        (async () => {
          if (options?.preRequest) {
            try {
              await options.preRequest();
            } catch (e) {
              // empty
            }
          }
          const cmsEntity = getContentForQuery(
            getCmsState(InitializationService.commonSelector(store.getState())),
            action.payload.cmsContentArguments,
          );
          if (!cmsEntity) {
            actions.cmsContentRequestThunk(action.payload);
          }
        })();
      }
    };
  };
