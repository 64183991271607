import { createSelector } from '@reduxjs/toolkit';
import { filter } from 'lodash/fp';
import { PermissionService } from '../service/PermissionService';
import { PermissionType } from '../type/PermissionType';
import {
  getSitesOrgIdsIncludingParentsSelector,
  getSitesWithEcApprovalStatusNotApprovedSelector,
} from './principalContextSelectors';

export const canViewOtherSitesMetricsSelector = createSelector(
  getSitesOrgIdsIncludingParentsSelector,
  orgIdsIncludingParents =>
    PermissionService.hasOrgBranchPermission(PermissionType.VIEW_OTHER_SITES_METRICS, orgIdsIncludingParents),
);

export const canManageStudyCloseoutTrackerSelector = createSelector(
  getSitesOrgIdsIncludingParentsSelector,
  orgIdsIncludingParents =>
    PermissionService.hasOrgBranchPermission(PermissionType.MANAGE_STUDY_CLOSE_OUT_TRACKER, orgIdsIncludingParents),
);

export const canExportCloseoutPatientsDataSelector = createSelector(
  getSitesOrgIdsIncludingParentsSelector,
  orgIdsIncludingParents =>
    PermissionService.hasOrgBranchPermission(PermissionType.CAN_EXPORT_CLOSEOUT_DATA, orgIdsIncludingParents),
);

export const getSitesWithApproveEcStatusPermission = createSelector(
  getSitesWithEcApprovalStatusNotApprovedSelector,
  filter(({ orgIdsIncludingParents }) =>
    PermissionService.hasOrgBranchPermission(PermissionType.CAN_APPROVE_EC_STATUS, orgIdsIncludingParents),
  ),
);

export const canManageHbsSelector = createSelector(getSitesOrgIdsIncludingParentsSelector, orgIdsIncludingParents =>
  PermissionService.hasOrgBranchPermission(PermissionType.MANAGE_HBS, orgIdsIncludingParents),
);
