import { Maybe, PagedResult } from '@csp/csp-common-model';
import { Paging } from '@csp/dmdp-api-common-dto';

const toPagedResult = <T>(paging: Maybe<Paging>, data: T[]): PagedResult<T> => ({
  limit: paging?.limit,
  next: paging?.next,
  count: paging?.count,
  data,
});

export const PagedResultMapper = {
  toPagedResult,
};
