import { RandomString } from '@csp/csp-common-util';
import { MeetingInfoV2, ZoomMeetingInV2, ZoomMeetingV2 } from '@csp/zoom-api-dto';
import { dateAndTimeToZonedDateTimeCurrentZone } from '@csp/csp-common-date-util';
import { StateAssert } from '@csp/csp-common-model';
import { ZoomMeeting } from '../model/ZoomMeeting';
import { ZoomMeetingOptions } from '../model/ZoomMeetingOptions';

const toZoomMeetingInV2 = ({ topic, date, time }: ZoomMeetingOptions): ZoomMeetingInV2 => {
  const startTimestamp = dateAndTimeToZonedDateTimeCurrentZone(date, time);
  StateAssert.isDefined(startTimestamp, `Invalid meeting start date: ${date} ${time}`);
  return {
    type: 2, // Scheduled
    topic,
    password: RandomString.ofLength(10),
    start_time: new Date(startTimestamp.unixTimeMillis).toISOString(),
    timezone: 'UTC',
    settings: {
      participant_video: false,
      host_video: false,
      mute_upon_entry: true,
      auto_recording: 'none',
      email_notification: false,
      join_before_host: false,
      waiting_room: false,
    },
  };
};

const toZoomMeeting = ({ id, start_url, join_url, password }: ZoomMeetingV2 | MeetingInfoV2): ZoomMeeting => ({
  meetingId: id,
  startUrl: start_url,
  joinUrl: join_url,
  password,
});

export const ZoomMeetingMapper = {
  toZoomMeetingInV2,
  toZoomMeeting,
};
