import { flatten, merge } from 'lodash/fp';
import type { ObjectType } from '@csp/csp-common-model';
import type { ResourceContentstackContentPackageType } from '@csp/csp-common-content-definitions';
import type { UnifyContentPackage } from '../types/UnifyContentPackage';
import { ResourceContentFactory } from '../utils/models/resource/ResourceContentFactory';

export const contentstackPackageToUnifyContentPackage = (contentPackage?: ObjectType): UnifyContentPackage => {
  if (typeof contentPackage !== 'object') {
    return {};
  }
  const transformedContentEntries = Object.keys(contentPackage).map(contentModelKey => {
    if (contentModelKey === 'resource') {
      return ResourceContentFactory.fromContentPackage(
        contentPackage as unknown as ResourceContentstackContentPackageType,
      );
    }
    return { [contentModelKey]: contentPackage[contentModelKey] };
  });
  return flatten(transformedContentEntries).reduce(merge, {}) as UnifyContentPackage;
};
