import { Localization, Maybe } from '@csp/csp-common-model';
import { LocalizationV1 } from '@csp/dmdp-api-user-dto';

const from = (localizationV1: Maybe<LocalizationV1>): Localization => ({
  countryCode: localizationV1?.countryCode ?? '',
  langCode: localizationV1?.langCode ?? '',
  locale: `${localizationV1?.langCode.toLowerCase()}_${localizationV1?.countryCode.toUpperCase()}`,
});

export const LocalizationMapper = { from };
