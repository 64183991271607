import { UserInfosV1 } from '@csp/dmdp-api-user-dto';
import { RestOptions } from '@csp/csp-common-model';
import { userpodUrl } from '../../dmdp-config/dmdpConfig';
import { HmacUtil } from '../../util/HmacUtil';

const userInfoUrl = (): string => `${userpodUrl()}/user/info/v1`;

// TODO: [Vidar] add documentation, axios param should be axiosIdp instance..
const getUserInfoByIdpJwt = async ({ axios, signal }: RestOptions): Promise<UserInfosV1> => {
  const { data } = await axios.get(`${userInfoUrl()}/mine`, {
    signal,
  });
  return data;
};

const getUserInfoByHmac = async (
  { axios, signal }: RestOptions,
  clientId: string,
  clientSecret: string,
): Promise<UserInfosV1> => {
  const httpHeaders = HmacUtil.generateHeaders(clientId, clientSecret, 'GET');
  const resp = await axios.get(`${userInfoUrl()}/mine`, {
    headers: {
      ...httpHeaders,
    },
    signal,
  });
  return resp.data;
};

export const UserInfoRestServiceV1 = {
  getUserInfoByIdpJwt,
  getUserInfoByHmac,
};
