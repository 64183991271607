/* eslint-disable */
export const UnifyWebOnboardingMock = {
  title: 'title',
  consent: { viewConsentPageSubHeader: '[M]Accepted version' },
  eula: {
    updatedConsentPageHeader: '[M]We have updated our End-user license agreement (EULA)',
    agreementWarning: '[M]Please read and agree to our EULA. This step is required to use our services',
    declineButton: '[M]Decline',
    declineButtonAccessibilityLabel: '[M]Decline End-user license agreement',
    acceptButton: '[M]Accept',
    acceptButtonAccessibilityLabel: '[M]Accept End-user license agreement',
    error: '[M]An unknown error occurred when accepting the EULA, please try again',
  },
  privacyPolicy: {
    updatedConsentPageHeader: '[M]We have updated our Privacy Policy',
    agreementWarning: '[M]Please read and agree to our Privacy Policy. This step is required to use our services',
    declineButton: '[M]Decline',
    declineButtonAccessibilityLabel: '[M]Decline to our Privacy Policy',
    acceptButton: '[M]Accept',
    acceptButtonAccessibilityLabel: '[M]Accept our Privacy Policy',
    error: '[M]An unknown error occurred when accepting Privacy Policy, please try again',
  },
  closeoutTracker: {
    modalHeader: '[M]Close Out Tracker is now live',
    modalDescription:
      '[M]Closeout Tracker is now available under the patient tab. Site team users should start booking Study Closure Visit (SCV) with patients.',
    confirmButtonLabel: '[M]Ok',
  },
};
