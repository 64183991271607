import { IconType } from '@csp-misc/web-ui-icons';
import { useBoolean } from '@csp/fe-hooks';
import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { borders, BordersProps, compose, sizing, SizingProps, spacing, SpacingProps } from '@mui/system';
import { FC, useCallback, useEffect } from 'react';
import { BrandedIconSize } from '../brandedIcon/model/BrandedIconSize';
import { LoadingSkeleton } from '../lab/skeleton/LoadingSkeleton';
import { CustomSkeletonProps } from '../lab/skeleton/model/CustomSkeletonProps';
import { Box } from '../layout/box/Box';
import { MissingImage } from './MissingImage';
import { ImageProps } from './model/ImageProps';

const ImgStyled = styled.img<ImageProps & { isLoading: boolean }>`
  ${compose(spacing, sizing, borders)}
  ${({ isLoading, fullWidth }): SerializedStyles =>
    css(
      css`
        object-fit: contain;
      `,
      isLoading &&
        css`
          display: none;
        `,
      fullWidth &&
        css`
          max-width: 100%;
        `,
    )}
`;

export const LoadingSkeletonStyle = styled(LoadingSkeleton)`
  &.MuiSkeleton-text {
    transform: none;
  }
`;

type Props = ImageProps &
  SizingProps &
  SpacingProps &
  CustomSkeletonProps &
  BordersProps & {
    missingIconName?: IconType;
    missingIconSize?: BrandedIconSize;
  };

export type CspWebUiImageProps = Props;

export const Image: FC<Props> = ({
  height,
  width,
  alt,
  src,
  fullWidth = true,
  borderRadius,
  missingIconName,
  missingIconSize,
  aspectRatio,
  hasPlaceholder = true,
  ...props
}) => {
  const [isLoading, setIsLoading, clearLoading] = useBoolean(true);
  const [hasError, setHasError, clearError] = useBoolean(false);

  useEffect(() => {
    clearError();
    if (src && !isLoading) {
      setIsLoading();
    }
    // Don't run this when loading changes, only when src changes (e.g. initially empty string)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearError, clearLoading, setIsLoading, src]);

  const handleOnLoaded = useCallback(() => {
    clearLoading();
  }, [clearLoading]);

  const handleOnError = useCallback(() => {
    clearLoading();
    setHasError();
  }, [clearLoading, setHasError]);

  const sharedProps: Pick<ImageProps, 'alt' | 'width' | 'height' | 'borderRadius'> = {
    alt,
    width,
    height,
    borderRadius,
  };

  const getImage = (): JSX.Element => {
    if (!hasError) {
      return (
        <>
          <ImgStyled
            isLoading={isLoading}
            src={src}
            {...sharedProps}
            fullWidth={fullWidth}
            onLoad={handleOnLoaded}
            onError={handleOnError}
            {...props}
          />
          {hasPlaceholder && (
            <LoadingSkeletonStyle aspectRatio={aspectRatio} width={width} height={height} isLoading={isLoading} />
          )}
        </>
      );
    } else {
      // TODO: Fix missing image height and width, sometimes only one is set
      return (
        <MissingImage
          {...sharedProps}
          height={height ?? '100%'}
          width={width ?? '100%'}
          missingIconName={missingIconName}
          size={missingIconSize}
          aspectRatio={aspectRatio}
        />
      );
    }
  };

  return (
    <Box role="img" width={width} height={height}>
      {getImage()}
    </Box>
  );
};
