/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DeviceEventCriteriaFieldV1 {
    DEVICE_MODEL_ID = 'DEVICE_MODEL_ID',
    DEVICE_MODEL_CODE = 'DEVICE_MODEL_CODE',
    TYPE = 'TYPE',
    TIMESTAMP = 'TIMESTAMP',
    DEVICE_EVENT_ID = 'DEVICE_EVENT_ID',
    USER_ID = 'USER_ID',
    EVENT_ID = 'EVENT_ID',
    USER_DEVICE_ID = 'USER_DEVICE_ID',
}