import { forwardRef } from 'react';
import { Alert } from './Alert';
import { AlertProps } from './model/AlertProps';

export const AlertError = forwardRef<HTMLDivElement, Omit<AlertProps, 'severity'>>(function AlertError(
  { ...props },
  ref,
) {
  return <Alert severity="error" ref={ref} {...props} />;
});
