import { Maybe } from '@csp/csp-common-model';
import { GenericRequest } from './schedulingModels/GenericRequest';
import { ScheduleRequestRef } from './ScheduleRequestRef';

export type ScheduleVersionRequestRef = ScheduleRequestRef & {
  versionCode: string;
};

const fromGenericRequest = ({ scheduleCode, versionCode, requestCode }: GenericRequest): ScheduleVersionRequestRef => ({
  scheduleCode,
  versionCode,
  requestCode,
});

const isSameRequestRef = (ref1: ScheduleVersionRequestRef, ref2: Maybe<ScheduleVersionRequestRef>): boolean =>
  ref1.scheduleCode === ref2?.scheduleCode &&
  ref1.versionCode === ref2.versionCode &&
  ref1.requestCode === ref2.requestCode;

const toKey = ({ scheduleCode, versionCode, requestCode }: ScheduleVersionRequestRef): string =>
  `${scheduleCode}-${versionCode}-${requestCode}`;

export const ScheduleVersionRequestRef = {
  fromGenericRequest,
  isSameRequestRef,
  toKey,
};
