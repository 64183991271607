import { StudyConfigurationEntryV2 } from '@csp/dto';
import { RestOptions } from '@csp/csp-common-model';
import { cspPodUrl } from '../../csp-config/CspConfig';

const studyConfigUrl = (): string => `${cspPodUrl()}/study/config/v2`;

const getConfigs = async ({ axios, signal }: RestOptions): Promise<StudyConfigurationEntryV2[]> => {
  const resp = await axios.get<StudyConfigurationEntryV2[]>(`${studyConfigUrl()}`, {
    signal,
  });
  return resp.data;
};

export const StudyConfigRestServiceV2 = {
  getConfigs,
};
