import { useState } from 'react';
import { useOnUnmount } from './useOnUnmount';

/**
 * When a component unmounts, we need to cancel any potentially
 * ongoing Axios calls that result in a state update on success / fail.
 * This function sets up the appropriate useEffect to handle the canceling.
 *
 * @returns {signal: AbortSignal, abort: function}
 * signal - returns a AbortSignal from a new AbortController
 * abort - abort the current request.
 */

type Out = {
  signal: AbortSignal;
  abort: (reason?: string) => void;
};

export const useAbortController = (): Out => {
  const [abortController, setAbortController] = useState(new AbortController());

  useOnUnmount(() => abort('Aborted on unmount'));

  const abort = (reason?: string): void => {
    abortController.abort(reason);
    setAbortController(new AbortController());
  };

  return { signal: abortController.signal, abort };
};
