import { Maybe, PagedResult, toErrorInfo } from '@csp/csp-common-model';
import { ErrorService } from '@csp/web-common';
import {
  InfiniteData,
  QueryFunctionContext,
  useInfiniteQuery as useInfiniteQueryInternal,
} from '@tanstack/react-query';
import { sumBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useGetErrorMessage } from '../../hooks/useGetErrorMessage';
import { PagedQueryState } from '../models/PagedQueryState';
import { QueryKey } from '../models/QueryKey';

type QueryFunction<T> = (nextCursor: Maybe<string>) => Promise<PagedResult<T>>;
type QueryOptions<TData, TMappedData = TData> = {
  mapperFn?: (data: TData) => TMappedData;
  enabled?: boolean;
  staleTimeMillis?: number;
  refetchOnMount?: 'always' | boolean;
  refetchOnWindowFocus?: 'always' | boolean;
};

export const usePagedQuery = <TData, TMappedData = TData>(
  queryKey: QueryKey,
  queryFn: QueryFunction<TData>,
  options?: QueryOptions<TData, TMappedData>,
): PagedQueryState<TMappedData> => {
  const getErrorMessage = useGetErrorMessage();
  const queryKeyInternal = [queryKey.cacheKey, ...(queryKey.cacheParams ?? [])];
  const queryFnInternal = async (context: QueryFunctionContext): Promise<PagedResult<TData>> =>
    queryFn(context.pageParam);

  const selectFn = (data: InfiniteData<PagedResult<TData>>): InfiniteData<PagedResult<TMappedData>> => ({
    ...data,
    pages: data.pages.map(page => ({
      ...page,
      data: options?.mapperFn ? page.data.map(options.mapperFn) : (page.data as unknown as TMappedData[]),
    })),
  });

  const hasNextPage = (lastPage: PagedResult<TData>, allPages: PagedResult<TData>[]): boolean =>
    (lastPage.count ?? 0) > sumBy(allPages, page => page.data.length);

  const {
    error: queryError,
    data,
    isLoading,
    isFetching,
    hasNextPage: hasNext = false,
    isFetchingNextPage: isLoadingNext,
    fetchNextPage,
    refetch,
  } = useInfiniteQueryInternal(queryKeyInternal, queryFnInternal, {
    select: selectFn,
    getNextPageParam: (lastPage, allPages) => (hasNextPage(lastPage, allPages) ? lastPage.next : undefined),
    enabled: options?.enabled,
    staleTime: options?.staleTimeMillis ?? 0,
    refetchOnMount: options?.refetchOnMount ?? true,
    refetchOnWindowFocus: options?.refetchOnWindowFocus ?? true,
    onError: error => {
      ErrorService.handleError({ error });
    },
  });

  const errorInfo = useMemo(
    () => (queryError ? toErrorInfo({ error: queryError, message: getErrorMessage(queryError) }) : undefined),
    [getErrorMessage, queryError],
  );

  const mappedDataItems = useMemo(() => data?.pages.flatMap(page => page.data) ?? [], [data]);
  const count = data?.pages[0]?.count ?? 0;

  const refresh = useCallback(async (): Promise<void> => {
    await refetch();
  }, [refetch]);

  const loadNext = useCallback(async (): Promise<void> => {
    await fetchNextPage();
  }, [fetchNextPage]);

  const tooLargeQuery = data?.pages?.[0]?.tooLargeQuery ?? false;

  return {
    error: errorInfo,
    isLoadingInitial: isLoading && isFetching,
    tooLargeQuery,
    data: mappedDataItems,
    refresh,
    loadNext,
    hasNext,
    isLoadingNext,
    count,
  };
};
