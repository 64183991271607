export enum QuestionnaireType {
  /** ePRO */
  ECOA = 'ECOA',
  /** ClinRO */
  CLINRO = 'CLINRO',
  HEALTH_EVENT = 'HEALTH_EVENT',
  SPFQ = 'SPFQ',
  DIARY = 'DIARY',
  ENGAGEMENT = 'ENGAGEMENT',
  UNKNOWN = 'UNKNOWN',
}
