import { IconType } from '@csp-misc/web-ui-icons';
import { ReactNode } from 'react';
import { MarkdownColor } from '../../../markdown/model/MarkdownColor';
import { AlertColor } from '../model/AlertColor';
import { AlertVariant } from '../model/AlertVariant';
import { OnClick } from '../../../../common/model/EventModels';
import { SxProps } from '../../../../common/system/SxProps';

const toIconName = (severity: AlertColor, variant: AlertVariant): IconType => {
  if (variant === 'outlined') {
    switch (severity) {
      case 'success':
        return 'verified';
      case 'warning':
        return 'warning';
      case 'info':
        return 'infoFilled';
      case 'error':
        return 'cancel';
    }
  } else {
    switch (severity) {
      case 'success':
        return 'success';
      case 'warning':
        return 'warningRound';
      case 'info':
        return 'info';
      case 'error':
        return 'error';
    }
  }
};

const toMarkdownColor = (variant: AlertVariant, alertColor: AlertColor): MarkdownColor => {
  if (variant === 'outlined') {
    return 'secondary';
  } else {
    switch (alertColor) {
      case 'info':
        return 'infoDark';
      case 'error':
        return 'errorDark';
      case 'warning':
        return 'warningDark';
      case 'success':
        return 'successDark';
      default:
        return 'infoDark';
    }
  }
};

const toSx = (
  fullWidth?: boolean,
  action?: ReactNode,
  onClose?: OnClick | 'default',
  title?: string,
  variant?: 'standard' | 'outlined',
): SxProps => ({
  padding: 1,
  width: fullWidth ? '100%' : undefined,
  display: action ? 'flex' : undefined,
  flexWrap: action ? 'wrap' : undefined,
  '.MuiAlert-action': {
    flex: { xs: action ? '1 1 100%' : undefined, sm: 'inherit' },
    alignSelf: { xs: 'baseline' },
    paddingLeft: { xs: action ? 2.5 : 1 },
    paddingRight: action || onClose ? 2 : 0,
    padding: onClose ? 0 : undefined,
  },
  '.MuiAlert-message': {
    padding: 0,
    color: variant === 'outlined' ? 'common.black' : 'inherit',
    flexBasis: {
      xs: action ? '80%' : undefined,
      sm: action ? '65%' : undefined,
    },
  },
  '.MuiAlert-icon': {
    padding: 0,
    alignSelf: { xs: (onClose && !title) || (action && !title) ? 'center' : 'flex-start' },
  },
});

export const AlertMapper = {
  toIconName,
  toMarkdownColor,
  toSx,
};
