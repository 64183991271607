import { getMessage } from '@csp/csp-common-util';

export type Listener<T> = (value: T) => void;

export type ListenerUtil<T> = {
  add: (listener: Listener<T>) => void;
  delete: (listener: Listener<T>) => void;
  trigger: (value: T) => void;
};

const trigger = <T>(listeners: Set<(value: T) => void>, value: T, name?: string): void => {
  listeners.forEach(listener => {
    try {
      listener(value);
    } catch (e) {
      console.log(`Failed in ${name ? `${name} ` : ''}callback: ${getMessage(e)}`);
    }
  });
};

export const createListener = <T>(name?: string): ListenerUtil<T> => {
  const listeners: Set<(value: T) => void> = new Set();

  const addListener = (listener: Listener<T>): void => {
    listeners.add(listener);
  };
  const deleteListener = (listener: Listener<T>): void => {
    listeners.delete(listener);
  };
  const triggerListener = (value: T): void => trigger<T>(listeners, value, name);

  return {
    add: addListener,
    delete: deleteListener,
    trigger: triggerListener,
  };
};
